import _ from 'lodash';
import { Message, ValidationErrors } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { useState } from 'react';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import { addToastAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import { createProceedingsCPV } from 'modules/Proceedings/repository';
import { createProceedingsCPVToastSuccess } from 'modules/Proceedings/toasts';

const useCPVCreateModal = (proceeding_id: string | number, onSuccess?: () => void) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);

  const cancelToken = useCancelToken();
  const dispatchAddCPVToast = () => dispatch(addToastAction(createProceedingsCPVToastSuccess()));

  const addCPVAction = async (data: { name: string }) => {
    setLoading(true);

    const {
      cancelled,
      data: resData,
      message: messageRequest,
      error
    } = await updateRequestWithData(createProceedingsCPV, proceeding_id, data, cancelToken);

    if (cancelled) return;
    if (resData?.data) {
      dispatchAddCPVToast();
      setDisplayAddModal(false);
      if (onSuccess) onSuccess();
    }
    if (error) setErrors(error);
    if (messageRequest) setMessage(messageRequest);
    setLoading(false);
  };

  const clearErrors = () => {
    setErrors(null);
    setMessage(null);
  };

  return {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addCPVAction,
    loading,
    displayAddModal,
    setDisplayAddModal,
    message,
    errors,
    clearErrors
  };
};

export default useCPVCreateModal;
