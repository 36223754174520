import { SelectOption } from 'modules/Shared/type';

export const PERSPECTIVE_OLD = 1;
export const PERSPECTIVE_NEW = 2;

export const perspectiveOptions: SelectOption[] = [
  { value: 1, label: 'Perspektywa 2014 – 2020' },
  { value: 2, label: 'Perspektywa 2021 – 2027' }
];

export const getPerspectiveName = (value: number): string => {
  const perspective = perspectiveOptions.find(
    (option) => option.value === value
  );
  return perspective?.label || '';
};