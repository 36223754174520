import { addToastAction, managePageAction } from 'modules/Layout/action';
import Loader from 'modules/Layout/component/Loader';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Alert from 'modules/Layout/component/Alert';
import { Message, ValidationErrors } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import updateRequest from 'modules/Shared/helper/APIRequests/updateRequest';
import Form from 'modules/ExecutorOrder/component/OrderForm';
import { ROUTE_ORDER } from 'modules/ExecutorOrder/routes';
import { fetchOrder, updateOrder } from 'modules/ExecutorOrder/repository';
import { breadcrumbRouteOrderUpdate } from 'modules/ExecutorOrder/breadcrumbs';
import Order, { createOrder, OrderEntity } from 'modules/ExecutorOrder/model/Order';
import { updateOrderToastSuccess } from 'modules/ExecutorOrder/toasts';
import { fetchAdminExecutor } from 'modules/Executor/repository';

export interface Props {
  params?: any;
}

const OrderBasicUpdateView: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { executor_id, id } = useParams<{ executor_id: string; id: string }>();
  const { state } = useLocation<{ order: Order; executorName: string }>();
  const [order, setOrder] = useState<Order>(null);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  const scrollToTopOnce = useRef<boolean>(true);

  const dispatchDeleteUpdateOrderToast = () => dispatch(addToastAction(updateOrderToastSuccess()));

  const cancelToken = useCancelToken();
  useEffect(() => {
    if (state?.order) {
      setOrder(createOrder(state.order));
    } else {
      (async () => {
        setFetching(true);
        const {
          data,
          cancelled,
          message: messageResponse
        } = await fetchRequest(fetchOrder, executor_id, id, cancelToken);

        if (cancelled) return;
        if (data) setOrder(createOrder(data.data));
        if (messageResponse) setMessage(messageResponse);
        setFetching(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const urlLink = getPathUrl(ROUTE_ORDER, { executor_id, id });
  const managePage = (executorName: string) => {
    const title = `Edycja postępowania${order.name ? ` - ${order.name}` : ''}`;
    dispatch(
      managePageAction({
        title,
        breadcrumb: breadcrumbRouteOrderUpdate(executor_id, order.name, executorName, urlLink)
      })
    );
  };

  useEffect(() => {
    if (order) {
      if (state?.executorName) {
        managePage(state.executorName);
      } else {
        (async () => {
          const { data, cancelled } = await fetchRequest(fetchAdminExecutor, executor_id, cancelToken);
          if (cancelled) return;
          if (data) {
            managePage(data.data.name || data.data.nip);
          }
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const updateOrderAction = async (data: OrderEntity) => {
    setLoading(true);
    const {
      cancelled,
      message: messageRes,
      success,
      error
    } = await updateRequest(updateOrder, executor_id, id, data, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteUpdateOrderToast();
      dispatch(push(urlLink));
    } else {
      if (messageRes) setMessage(messageRes);
      if (error) setErrors(error);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  if (fetching) return <Loader />;
  if (!fetching && !order && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  if (!fetching && order) {
    return (
      <div className="profile-update-view row justify-content-center">
        <div className="col-12">
          <div className="card-box position-relative">
            <p className="h3 header-title mb-2">Edytuj postępowanie</p>
            {loading && <Loader />}
            <Alert message={message} />
            {order && (
              <Form
                officeData={order}
                errors={errors}
                submit={updateOrderAction}
                disabled={loading}
                isEditForm
                cancelRedirect={urlLink}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default OrderBasicUpdateView;
