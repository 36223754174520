import React from 'react';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_INSURANCE } from 'modules/Agreements/step';
import { AgreementStepInsuranceValues } from 'modules/Agreements/components/Steps/Default/Insurance';

const AgreementStepPreviewInsurance = (props: AgreementStepPreviewProps<AgreementStepInsuranceValues>): JSX.Element => {
  const { steps, modifiedStep } = props;
  const insurance = getStepValues<AgreementStepInsuranceValues>(steps, AGREEMENT_STEP_INSURANCE);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={insurance}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    insurance,
    modifiedStep,
    `checkboxes.general`
  );

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_INSURANCE)}</div>
      <div className="agreement-step-preview-content reset-preview-point">
        {[
          renderCheckbox('insurance'),
          renderCheckbox('consortium'),
          renderCheckbox('document'),
          renderCheckbox('insurance_expires'),
          renderCheckbox('no_insurance'),
          renderCheckbox('claims')
        ]}
        {generalCheckboxes &&
          generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-point wysiwyg-preview', {
                  modified: generalCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AgreementStepPreviewInsurance;
