import React, { useEffect, useState } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { Message, PartialSearchingProps } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { deleteAgreement } from 'modules/Agreements/repository';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import Agreement from 'modules/Agreements/model/Agreements';
import { breadcrumbAgreements } from 'modules/Agreements/breadcrumbs';
import { deleteAgreementToastSuccess } from 'modules/Agreements/toasts';
import { setAgreementsListParamsAction } from 'modules/Agreements/action/list';
import AgreementsTable from 'modules/Agreements/container/AgreementsList/Table';
import AgreementsFilter from 'modules/Agreements/container/AgreementsList/Filter';
import AgreementsPagination from 'modules/Agreements/container/AgreementsList/Pagination';
import AgreementsPerPage from 'modules/Agreements/container/AgreementsList/PerPage';
import { ROUTE_AGREEMENTS } from 'modules/Agreements/routes';

const Agreements = () => {
  const dispatch = useDispatch();
  const { fetching, meta } = useSelector((state: RootState) => state.agreements.list);
  const [displayModal, setDisplayModal] = useState<Agreement>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const setParams = (payload: PartialSearchingProps) => dispatch(setAgreementsListParamsAction(payload));

  useEffect(() => {
    managePage({
      title: 'Umowy - lista',
      breadcrumb: [breadcrumbAgreements()]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchDeleteAgreementToast = () => dispatch(addToastAction(deleteAgreementToastSuccess()));

  const cancelToken = useCancelToken();
  const deleteAgreementAction = async (agreement_id: number | string) => {
    setLoading(true);
    const {
      cancelled,
      success,
      message: messageRequest
    } = await deleteRequest(deleteAgreement, agreement_id, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteAgreementToast();
      setDisplayModal(null);
      setParams({});
    }
    if (messageRequest) setMessage(messageRequest);
    setLoading(false);
  };

  return (
    <div className="row offices-view">
      <div className="col-12">
        {displayModal && (
          <GenericModalDelete
            value={displayModal}
            title="Usuwanie umowy"
            content={() => <div>Czy na pewno chcesz usunąć wybraną umowę?</div>}
            onDeleteClick={(value) => deleteAgreementAction(value.id)}
            isOpen={!!displayModal}
            toggle={() => setDisplayModal(null)}
            loading={loading}
            message={message}
            resetMessage={() => setMessage(null)}
          />
        )}
        <Listing
          meta={meta}
          table={<AgreementsTable onDeleteClick={(agreement) => setDisplayModal(agreement)} />}
          filter={<AgreementsFilter />}
          pagination={<AgreementsPagination path={ROUTE_AGREEMENTS} />}
          perPage={<AgreementsPerPage />}
          loading={fetching}
        />
      </div>
    </div>
  );
};

export default Agreements;
