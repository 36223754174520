import _has from 'lodash/has';
import { ValidationErrors } from 'modules/Shared/type';
import React, { useState } from 'react';
import { Button, Form as FormStrap } from 'reactstrap';
import { Link } from 'react-router-dom';
import { OfficeFormValues } from 'modules/Office/type';
import OfficeFieldset from 'modules/Office/component/Fieldset/OfficeBasic';
import Office from 'modules/Office/model/Office';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_OFFICE, ROUTE_OFFICES } from 'modules/Office/routes';

export interface Props {
  errors?: ValidationErrors;
  disabled?: boolean;
  officeData?: Office;
  submit: (data: OfficeFormValues) => void;
}

const Form: React.FC<Props> = ({ errors, disabled, submit, officeData }) => {
  const [values, setValue] = useState({
    name: officeData?.name ?? '',
    street: officeData?.street ?? '',
    postal_code: officeData?.postal_code ?? '',
    city: officeData?.city ?? '',
    post_office: officeData?.post_office ?? '',
    phone: officeData?.phone ?? '',
    regon: officeData?.regon ?? '',
    email: officeData?.email ?? '',
    can_send_email: officeData?.can_send_email ?? false
  });
  const [officeFromRegon, setOfficeFromRegon] = useState<Record<any, any>>(null);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = event.target;

    if (_has(values, key)) {
      setValue({ ...values, [key]: value });
    }
  };

  const setMultipleValues = (data: Record<any, any>) => {
    setValue({ ...values, ...data });
  };

  const clearFields = () => {
    setValue({
      ...values,
      name: '',
      street: '',
      postal_code: '',
      city: '',
      post_office: '',
      phone: '',
      regon: '',
      email: '',
      can_send_email: false
    });

    setOfficeFromRegon(null);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    submit(values);
  };

  const hasValue = () => !Object.values(values).some(Boolean);
  return (
    <FormStrap onSubmit={onSubmit}>
      <OfficeFieldset
        setOfficeFromRegon={setOfficeFromRegon}
        officeFromRegon={officeFromRegon}
        setMultipleValues={setMultipleValues}
        disabled={disabled}
        errors={errors}
        values={values}
        onChange={onChange}
        required
      />

      <div className="form-actions">
        {officeFromRegon !== null && (
          <Button
            type="button"
            color="secondary"
            outline
            className="pzpeu-btn-disabled waves-effect waves-light mb-3"
            onClick={clearFields}
          >
            Wyczyść
          </Button>
        )}
        <Button
          type="submit"
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light"
          disabled={hasValue() || disabled}
        >
          Zapisz
        </Button>
        <Link
          to={officeData ? getPathUrl(ROUTE_OFFICE, { id: officeData.id }) : ROUTE_OFFICES}
          className="cancel waves-effect waves-light"
        >
          Anuluj
        </Link>
      </div>
    </FormStrap>
  );
};

export default Form;
