import { addToastAction, managePageAction } from 'modules/Layout/action';
import React, { useEffect, useState, useRef } from 'react';
import Loader from 'modules/Layout/component/Loader';
import { useDispatch } from 'react-redux';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import { breadcrumbRouteViolationCreate } from 'modules/ExecutorOrder/breadcrumbs';
import { useParams } from 'react-router-dom';
import { createViolationToastSuccess } from 'modules/ExecutorOrder/toasts';
import { ROUTE_EXECUTOR_ADMINS } from 'modules/Executor/routes';
import { createViolation } from 'modules/Executor/repository';
import CardBox from 'modules/Layout/component/CardBox';
import { ExecutorViolation, ExecutorViolationSlug, getViolationQuestion } from '../../../model/Violation';
import ViolationForm from '../../../../ExecutorOrder/component/ViolationForm';

const ViolationCreateView = () => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const { executor_id, slug } = useParams<{ executor_id: string; slug: ExecutorViolationSlug }>();
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState(null);
  const scrollToTopOnce = useRef<boolean>(true);

  const managePage = () =>
    dispatch(
      managePageAction({
        title: 'Dodawanie nariszenia postępowania',
        breadcrumb: breadcrumbRouteViolationCreate(executor_id)
      })
    );

  const cancelToken = useCancelToken();
  useEffect(() => {
    managePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchCreateViolationToast = () => dispatch(addToastAction(createViolationToastSuccess()));

  const setCreateViolationAction = async (data: ExecutorViolation) => {
    setFetching(true);

    if (message) setMessage(null);
    if (errors) setErrors(null);

    const {
      message: messageResponse,
      error,
      cancelled,
      data: responseData
    } = await updateRequestWithData(createViolation, executor_id, data, cancelToken);

    if (cancelled) return;

    if (responseData) {
      dispatchCreateViolationToast();
      const { id } = responseData.data;
      if (id) dispatch(push(getPathUrl(ROUTE_EXECUTOR_ADMINS, { id: executor_id })));
      else setFetching(false);
    } else {
      if (error) setErrors(error);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <div className="user-create-view row justify-content-center">
      <CardBox childrenClassName="p-0 mt-4" heading={getViolationQuestion(slug)} form="small">
        {fetching && <Loader />}
        <Alert message={message} />
        <ViolationForm
          errors={errors}
          slug={slug}
          submit={(data) => setCreateViolationAction(data)}
          cancelRedirect={getPathUrl(ROUTE_EXECUTOR_ADMINS, { id: executor_id })}
        />
      </CardBox>
    </div>
  );
};

export default ViolationCreateView;
