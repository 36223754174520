import React from 'react';
import ActionDelete from '../../Action/Delete';
import './basicChip.scss';

type Props = {
  chipContentElement: { content: string; id: number };
  chipStyle?: Record<any, any>;
  onDeleteChipClicked?: (id: number) => void;
  onChipClicked?: () => void;
};

const BasicChip: React.FC<Props> = ({ chipContentElement, chipStyle = {}, onDeleteChipClicked, onChipClicked }) => {
  return (
    // eslint-disable-next-line
    <div
      className="basic-chip"
      style={chipStyle}
      onClick={(e) => (onChipClicked ? onChipClicked() : e.stopPropagation())}
    >
      {chipContentElement.content}
      {onDeleteChipClicked && (
        <ActionDelete
          className="delete-chip"
          title="Usuń filtr"
          onClick={() => onDeleteChipClicked(chipContentElement.id)}
        />
      )}
      {/* {onDeleteChipClicked && (
        <i className="fas fa-times-circle delete-chip" onClick={() => onDeleteChipClicked(chipContentElement.id)} />
      )} */}
    </div>
  );
};

export default BasicChip;
