import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function ExpandIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 5.745 10.49">
      <g id="Group_375" data-name="Group 375" transform="translate(-537.548 -1302.757)">
        <path
          id="Path_1280"
          data-name="Path 1280"
          d="M538.048,1313.247a.5.5,0,0,1-.354-.854l4.391-4.392-4.391-4.391a.5.5,0,1,1,.707-.707l4.745,4.745a.5.5,0,0,1,0,.707L538.4,1313.1A.5.5,0,0,1,538.048,1313.247Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
