import React, { useEffect, useState } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { Message, PartialSearchingProps } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { setJudgementListParamsAction } from 'modules/Judgement/action/JudgementList';
import { JudgementEntity } from 'modules/Judgement/model/Judgement';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import { push } from 'connected-react-router';
import JudgementPerPage from 'modules/Judgement/container/JudgementList/PerPage';
import JudgementListingPagination from 'modules/Judgement/container/JudgementList/Pagination';
import { addClauseJudgementToastSuccess } from 'modules/Clause/toasts';
import ClauseAddConnectedJudgements from 'modules/Clause/component/Modal/ClauseAddConnectedJudgements';
import { addClauseConnectedJudgements, fetchClause } from 'modules/Clause/repository';
import { useLocation, useParams } from 'react-router-dom';
import { ClauseEntity, createClause } from 'modules/Clause/model/Clause';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_CLAUSE, ROUTE_CLAUSE_JUDGEMENT_ADD_LIST } from 'modules/Clause/routes';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { breadcrumbRouteClauseAddJudgementList } from 'modules/Clause/breadcrumbs';
import ConnectedJudgementAddListFilter from 'modules/Clause/container/ConnectedJudgmentAddList/Filter';
import ConnectedJudgementAddListTable from 'modules/Clause/container/ConnectedJudgmentAddList/Table';
import { Button } from 'reactstrap';

const ConnectedJudgementListView = () => {
  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [addModalLoading, setAddModalLoading] = useState(false);
  const [clauseFetching, setClauseFetching] = useState(false);
  const [addModalMessage, setAddModalMessage] = useState<Message>(null);
  const [selectedJudgementId, setSelectedJudgementId] = useState<number>(null);
  const dispatch = useDispatch();
  const { fetching } = useSelector((state: RootState) => state.judgement.judgementList);
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<{
    clause?: ClauseEntity;
  }>();
  const [clause, setClause] = useState<ClauseEntity>(null);

  const cancelToken = useCancelToken();
  const fetchClauseAction = async () => {
    setClauseFetching(true);
    const { data, cancelled, message: messageResponse } = await fetchRequest(fetchClause, id, cancelToken);

    if (cancelled || messageResponse || !data?.data) dispatch(push(`${getPathUrl(ROUTE_CLAUSE, { id })}?t=judgments`));
    else {
      setClause(createClause(data.data));
      setClauseFetching(false);
    }
  };

  useEffect(() => {
    if (!clause) {
      if (state?.clause) setClause(state.clause);
      else fetchClauseAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const setParams = (payload: PartialSearchingProps) => dispatch(setJudgementListParamsAction(payload));

  useEffect(() => {
    if (clause) {
      managePage({
        title: 'Wybierz orzeczenie',
        breadcrumb: breadcrumbRouteClauseAddJudgementList(clause?.name, clause?.id)
      });

      setParams({ filter: { clause_id: id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clause]);

  const dispatchAddClauseJudgmentToast = () => dispatch(addToastAction(addClauseJudgementToastSuccess()));

  const addClauseJudgementAction = async (data: { judgment_id: number; content?: string }) => {
    setAddModalLoading(true);
    const {
      cancelled,
      message: messageRes,
      success
    } = await deleteRequest(addClauseConnectedJudgements, clause.id, data, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchAddClauseJudgmentToast();
      setDisplayAddModal(false);
      dispatch(push(`${getPathUrl(ROUTE_CLAUSE, { id })}?t=judgments`));
    } else {
      if (messageRes) setAddModalMessage(messageRes);
      setAddModalLoading(false);
    }
  };

  const onSelectedJudgmentClick = (judgment: JudgementEntity = {}) => {
    const { id: judgment_id } = judgment;
    if (judgment_id) {
      if (selectedJudgementId && judgment_id === selectedJudgementId) setSelectedJudgementId(null);
      else setSelectedJudgementId(judgment_id);
    }
  };

  return (
    <div className="row users-view">
      {displayAddModal && (
        <ClauseAddConnectedJudgements
          isOpen={displayAddModal && !!selectedJudgementId}
          toggle={() => setDisplayAddModal(false)}
          onSubmit={addClauseJudgementAction}
          message={addModalMessage}
          loading={addModalLoading}
          clearErrorsOnUnmount={() => setAddModalMessage(null)}
          title="Dodaj wybrane orzeczenie"
          judgementId={selectedJudgementId}
          clause={clause}
        />
      )}
      <div className="col-12">
        <Listing
          table={
            <ConnectedJudgementAddListTable
              onSelectedJudgmentClick={onSelectedJudgmentClick}
              selectedJudgment={selectedJudgementId}
            />
          }
          filter={<ConnectedJudgementAddListFilter />}
          pagination={<JudgementListingPagination path={getPathUrl(ROUTE_CLAUSE_JUDGEMENT_ADD_LIST, { id })} />}
          perPage={<JudgementPerPage />}
          loading={fetching || clauseFetching}
          insertBetweenFilterTable={
            <div className="w-100 mt-3 d-flex justify-content-end">
              <Button
                color="primary"
                className={`pzpeu-btn-disabled waves-effect waves-light ml-auto${
                  selectedJudgementId ? '' : ' disabled'
                }`}
                onClick={() => selectedJudgementId && setDisplayAddModal(true)}
              >
                Zapisz wybrane orzeczenie
              </Button>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ConnectedJudgementListView;
