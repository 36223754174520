import User from 'modules/User/model/User';
import React from 'react';
import DetailsBasic from 'modules/User/component/Details/Basic';
import DetailsCompany from 'modules/User/component/Details/Company';
import { CLIENT_ROLES } from 'modules/User/model/UserRole';
import DetailsModules from 'modules/Module/component/ListDetails';
import { PAYMENY_HISTORY_ALL, USERS_EDIT } from 'modules/User/permissions';
import DetailsPaymentLogs from 'modules/Profile/component/PaymentLogs';
import RodoDetailsWrapper from 'modules/Regulation/container/RodoDetailsWrapper';
import DetailsChanges from 'modules/User/component/Details/Changes';
import './index.scss';

type Props = {
  user: User;
  updateUser: React.Dispatch<React.SetStateAction<User>>;
  permissions: string[];
  onDeleteClick: () => void;
  acceptUserNipAction: () => void;
};

const DetailsComponent: React.FC<Props> = ({ user, onDeleteClick, acceptUserNipAction, permissions, updateUser }) => {
  const isClient = CLIENT_ROLES.includes(user?.role?.slug);
  const isAdmin = !user?.hasPermissions([USERS_EDIT]);

  return (
    <div className="col-12 user-details-component">
      <div className="row">
        <div className="col-lg-6 user-data">
          <div className="user-data-card">
            <DetailsBasic user={user} onDeleteClick={onDeleteClick} acceptUserNipAction={acceptUserNipAction} />
          </div>
          {isClient && <RodoDetailsWrapper user={user} />}
        </div>

        {isAdmin && (
          <div className="col-lg-6 user-company-data">
            <DetailsCompany user={user} />
          </div>
        )}

        {isClient && (
          <>
            <div className="col-12">
              <DetailsModules user={user} updateUser={updateUser} />
            </div>
            {permissions?.includes(PAYMENY_HISTORY_ALL) && (
              <div className="col-12 payment-logs">
                <DetailsPaymentLogs user={user} adminUrl />
              </div>
            )}
          </>
        )}
        {isClient && (
          <div className="col-12 changes-logs">
            <DetailsChanges user={user} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailsComponent;
