import {
  SET_EXECUTOR_ADMIN_LIST_PARAMS,
  EXECUTOR_ADMIN_LIST,
  ExecutorAdminListAction
} from 'modules/Executor/action/adminList';
import { createExecutor } from 'modules/Executor/model/Executor';
import { initExecutorAdminListState, ExecutorAdminListState } from 'modules/Executor/state/adminList';

const initState = initExecutorAdminListState();

const reducer = (
  state: ExecutorAdminListState = initState,
  action: ExecutorAdminListAction
): ExecutorAdminListState => {
  switch (action.type) {
    case SET_EXECUTOR_ADMIN_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case EXECUTOR_ADMIN_LIST: {
      const { executors = [], meta } = action.payload;

      return { ...state, fetching: false, executors: executors.map(createExecutor), meta };
    }

    default:
      return state;
  }
};

export default reducer;
