import React from 'react';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_REALIZATION_PEOPLE } from 'modules/Agreements/step';
import { AgreementStepRealizationPeopleValues } from 'modules/Agreements/components/Steps/Default/RealizationPeople';
import PreviewFieldContent from 'modules/Agreements/components/PreviewField/Content';

const AgreementStepPreviewRealizationPeople = (
  props: AgreementStepPreviewProps<AgreementStepRealizationPeopleValues>
): JSX.Element => {
  const { agreement, steps, modifiedStep } = props;
  const realizationPeople = getStepValues<AgreementStepRealizationPeopleValues>(
    steps,
    AGREEMENT_STEP_REALIZATION_PEOPLE
  );

  const { value: parts } = getFieldForPreview<AgreementStepRealizationPeopleValues['realization_people']>(
    realizationPeople,
    modifiedStep,
    'realization_people'
  );
  const { value: sameParts } = getFieldForPreview<
    AgreementStepRealizationPeopleValues['same_realization_people_for_parts']
  >(realizationPeople, modifiedStep, 'same_realization_people_for_parts');

  const { value: realizationPeoplePossibility } = getFieldForPreview(
    realizationPeople,
    modifiedStep,
    'realization_people_possibility'
  );

  const isMultiple = agreement.few_parts && !sameParts;

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={realizationPeople}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const renderContent = (contentKey: string, className = 'preview-point') => {
    return (
      <PreviewFieldContent
        step={realizationPeople}
        modifiedStep={modifiedStep}
        contentKey={contentKey}
        className={className}
      />
    );
  };

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    realizationPeople,
    modifiedStep,
    `checkboxes.general`
  );

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_REALIZATION_PEOPLE)}</div>
      {realizationPeoplePossibility && (
        <div className="reset-preview-point">
          {parts?.map((part, index: number) => {
            const { value: dispositionChecked, modified: dispositionCheckedModified } = getFieldForPreview(
              realizationPeople,
              modifiedStep,
              `realization_people.${index}.disposition_checked`
            );

            const { value: dispositionContent, modified: dispositionContentModified } = getFieldForPreview(
              realizationPeople,
              modifiedStep,
              `realization_people.${index}.disposition_content`
            );

            const partObj = agreement.parts_ids.find((item) => item.id === part.part_id);

            return (
              <div
                key={index}
                className={classNames({ 'reset-preview-point': isMultiple }, 'agreement-step-preview-content')}
              >
                {isMultiple && partObj && <div className="h5">{partObj.getName(index)}:</div>}
                {renderContent(`realization_people.${index}.people_commitment_content`)}
                {!dispositionChecked ? (
                  <p
                    className={classNames('preview-point wysiwyg-preview', {
                      modified: dispositionCheckedModified || dispositionContentModified
                    })}
                    dangerouslySetInnerHTML={{ __html: String(dispositionContent) }}
                  />
                ) : (
                  <p className={classNames('preview-point', { modified: dispositionCheckedModified })}>
                    Zamawiający wymaga, aby ww. osoby pozostawały do dyspozycji zamawiającego w zakresie zadeklarowanym
                    przez wykonawcę na etapie postępowania o udzielenie zamówienia
                  </p>
                )}
                {isMultiple &&
                  generalCheckboxes &&
                  generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
                    if (!checkbox.checked) return null;

                    if (checkbox.part_id != part.part_id) return null;

                    return (
                      <p
                        className={classNames('preview-point wysiwyg-preview', {
                          modified: generalCheckboxesModified
                        })}
                        key={index}
                        dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                      />
                    );
                  })}
              </div>
            );
          })}
          <div className={classNames({ 'reset-preview-point': isMultiple }, 'agreement-step-preview-content')}>
            {isMultiple && <p className="h5">Ponadto dla każdego z zadań:</p>}
            {renderCheckbox('replacement')}
            {renderCheckbox('change')}
            {renderCheckbox('polish')}
            {generalCheckboxes &&
              generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
                if (!checkbox.checked) return null;

                if (checkbox.part_id) return null;

                return (
                  <p
                    className={classNames('preview-point wysiwyg-preview', {
                      modified: generalCheckboxesModified
                    })}
                    key={index}
                    dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                  />
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AgreementStepPreviewRealizationPeople;
