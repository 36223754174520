import React, { useEffect, useState } from 'react';
import Loader from 'modules/Layout/component/Loader';
import { DropdownMenu, FormGroup, Input, Label } from 'reactstrap';
import _debounce from 'lodash/debounce';
import { suffixLabel, suffixValue } from 'modules/Layout/helper/misc';
import './index.scss';

type Props = {
  currentValue: string;
  onChangeCurrentValue: (valueParam: string) => void;
  searchRequest: (search?: string) => void;
  fetching: boolean;
  required?: boolean;
  disabled?: boolean;
  list: { id: number; name: string; disabled?: boolean }[];
  clearList?: () => void;
  label?: string;
  placeholder?: string;
  alwaysClearInput?: boolean;
};

const Autocomplete: React.FC<Props> = ({
  currentValue,
  onChangeCurrentValue,
  searchRequest,
  fetching,
  required = false,
  disabled = false,
  list,
  clearList,
  label,
  alwaysClearInput,
  placeholder
}) => {
  const [value, setValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (currentValue && value !== currentValue) {
      setValue(currentValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchRequestDebounce = React.useMemo(() => _debounce(searchRequest, 500), []);

  const renderSearch = () => {
    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      searchRequestDebounce(event.target.value);
    };

    const onFocus = () => {
      setShowDropdown(true);
      searchRequest();
    };

    return (
      <FormGroup className="p-0">
        {Boolean(label) && <Label>{suffixLabel(label, required)}</Label>}
        <Input
          type="text"
          value={value}
          required={required}
          onChange={onInputChange}
          onFocus={onFocus}
          disabled={disabled}
          placeholder={placeholder ? suffixValue(placeholder, required) : null}
        />
        {/* {error && <FormFeedback>{error.getMessageValue()}</FormFeedback>} */}
      </FormGroup>
    );
  };

  const renderDropdown = () => {
    if (!showDropdown) return null;

    const setCurrentValue = (selectedName: string) => {
      setShowDropdown(false);
      onChangeCurrentValue(selectedName);
      if (alwaysClearInput) setValue('');
      else setValue(selectedName);
    };

    return (
      <DropdownMenu className="d-block w-100" style={{ overflowX: 'hidden', maxHeight: '300px', overflowY: 'auto' }}>
        {currentValue && (
          <button
            type="button"
            className="current-value dropdown-item value d-flex align-items-center justify-content-between"
            onClick={() => setCurrentValue('')}
          >
            <div>{currentValue}</div>
            <span className="hint">Usuń</span>
          </button>
        )}
        <div className="position-relative">
          {fetching && <Loader size="50px" />}
          {list.length === 0 && <div className="dropdown-item no-results">Brak wyników.</div>}
          {list.map((option) =>
            option.name === currentValue ? null : (
              <button
                key={option.id || option.name}
                type="button"
                className="dropdown-item d-flex align-items-center justify-content-between"
                onClick={() => setCurrentValue(option.name)}
                disabled={false}
              >
                <div>{option.name}</div>
                <span className="hint">Wybierz</span>
              </button>
            )
          )}
        </div>
      </DropdownMenu>
    );
  };

  const onBlur = (event: React.ChangeEvent) => {
    const { currentTarget } = event;

    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setValue(currentValue);
        setShowDropdown(false);
        if (clearList) clearList();
      }
    }, 0);
  };

  return (
    <div className="autocomplete position-relative" onBlur={onBlur}>
      {renderSearch()}
      {renderDropdown()}
    </div>
  );
};

export default Autocomplete;
/*

import Autocomplete, {
  AutocompleteOmitProps,
  AutocompleteOption
} from 'modules/Layout/component/Autocomplete';
import React, { ReactNode } from 'react';
import ApiError from 'modules/Shared/exception/ApiError';
import {
  fetchPlantGroupsAll,
  FetchPlantGroupsAllPayload
} from 'modules/PlantGroups/repository';

export interface AutocompletePlantGroup {
  id?: number;
  name?: string;
}

export interface Props extends AutocompleteOmitProps {
  plantGroupId?: number | string;
  disabledOption?: (plantGroup: AutocompletePlantGroup) => boolean;
}

export interface State {
  plantGroups?: AutocompletePlantGroup[];
  error?: ApiError;
  fetching?: boolean;
}

class PlantGroupsAutocomplete extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fetching: false,
      plantGroups: []
    };

    this.toOption = this.toOption.bind(this);
    this.fetchPlantGroups = this.fetchPlantGroups.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  toOption(
    plantGroupId?: number | string,
    disabledOption?: (plantGroup: AutocompletePlantGroup) => boolean
  ): AutocompleteOption {
    const { plantGroups } = this.state;

    const foundPlantGroup = plantGroups.find(
      (family) => String(family.id) === String(plantGroupId)
    );

    if (!foundPlantGroup) {
      return {
        label: '',
        value: ''
      };
    }

    return {
      label: foundPlantGroup.name,
      value: foundPlantGroup.id.toString(),
      disabled: disabledOption ? disabledOption(foundPlantGroup) : false
    };
  }

  async fetchPlantGroups(payload: FetchPlantGroupsAllPayload): Promise<void> {
    try {
      this.setState({
        fetching: true
      });

      const {
        data: { content }
      } = await fetchPlantGroupsAll(payload);

      this.setState({
        plantGroups: content
      });
    } catch (error) {
      this.setState({
        error
      });
    } finally {
      this.setState({
        fetching: false
      });
    }
  }

  render(): ReactNode {
    const {
      onChange,
      plantGroupId,
      required = false,
      disabled = false,
      disabledOption = null,
      label = 'Choose category'
    } = this.props;
    const { plantGroups, fetching, error } = this.state;

    const plantGroupsOptions = plantGroups.map((plantGroup) =>
      this.toOption(plantGroup.id, disabledOption)
    );

    return (
      <Autocomplete
        onChange={onChange}
        onReset={async () => this.fetchPlantGroups({ search: {} })}
        fetching={fetching}
        error={error}
        onSearch={async (name) => this.fetchPlantGroups({ search: { name } })}
        value={this.toOption(plantGroupId)}
        options={plantGroupsOptions}
        required={required}
        disabled={disabled}
        label={label}
      />
    );
  }
}

export default PlantGroupsAutocomplete;

*/
