import React from 'react';
import Authorize from 'modules/Auth/container/Authorize';
import { JUDGMENTS_DELETE, JUDGMENTS_EDIT } from 'modules/Judgement/permissions';
import CardBox from 'modules/Layout/component/CardBox';
import JudgementBasicDataset from 'modules/Judgement/component/Dataset/Judgement/Basic';
import Judgement from 'modules/Judgement/model/Judgement';
import { ROUTE_JUDGEMENT_UPDATE_CONTENT, ROUTE_JUDGEMENT_UPDATE_BASIC } from 'modules/Judgement/routes';
import { getPathUrl } from 'modules/Shared/helper/api';
import { Link } from 'react-router-dom';
import InformationList from 'modules/Layout/component/InformationList';
import { Button } from 'reactstrap';
import './index.scss';

type Props = {
  judgement: Judgement;
  setDisplayDeleteModal: () => void;
};

const JudgementBasicDetails: React.FC<Props> = ({ judgement, setDisplayDeleteModal }) => {
  return (
    <>
      <CardBox
        heading="Szczegóły orzeczenia"
        paddingRight="30px"
        paddingBottom="0"
        className="judgement-basic-details"
        actions={
          <>
            <Authorize permissions={[JUDGMENTS_EDIT]}>
              <Link className="link-with-border" to={getPathUrl(ROUTE_JUDGEMENT_UPDATE_BASIC, { id: judgement?.id })}>
                EDYTUJ
              </Link>
            </Authorize>
            <Authorize permissions={[JUDGMENTS_DELETE]}>
              <Button color="link" className="link-with-border p-0" onClick={setDisplayDeleteModal}>
                USUŃ ORZECZENIE
              </Button>
            </Authorize>
          </>
        }
      >
        <JudgementBasicDataset element={judgement} />
      </CardBox>
      <CardBox
        heading="Treść publikacji"
        paddingRight="30px"
        paddingBottom="0"
        className="judgement-basic-details"
        actions={
          <Authorize permissions={[JUDGMENTS_EDIT]}>
            <Link to={getPathUrl(ROUTE_JUDGEMENT_UPDATE_CONTENT, { id: judgement?.id })} className="link-with-border">
              EDYTUJ TREŚĆ ORZECZENIA
            </Link>
          </Authorize>
        }
      >
        <InformationList
          fields={[
            {
              label: '',
              value: judgement?.content,
              html: true,
              htmlFieldInsertedStyle: { maxHeight: '600px', overflowY: 'auto', marginBottom: '20px' }
            }
          ]}
        />
      </CardBox>
    </>
  );
};

export default JudgementBasicDetails;
