const traverseAndFlatten = (currentNode: Record<any, any>, target: Record<any, any>, flattenedKey?: String) => {
  for (const key in currentNode) {
    if (currentNode.hasOwnProperty(key)) {
      let newKey;
      if (flattenedKey == null) {
        newKey = key;
      } else {
        newKey = flattenedKey + '.' + key;
      }

      const value = currentNode[key];

      if (typeof value === 'object') {
        traverseAndFlatten(value, target, newKey);
      } else {
        target[newKey] = value;
      }
    }
  }
};

export const flattObject = (obj: Object) => {
  const flattenedObject = {};
  traverseAndFlatten(obj, flattenedObject);

  return flattenedObject;
};
