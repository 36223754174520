import { ProceedingsEntity } from 'modules/Proceedings/model/Proceedings';
import { PaginationMeta, SearchingProps } from 'modules/Shared/type';

export interface ProceedingsListState extends SearchingProps {
  list: ProceedingsEntity[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initProceedingsListState = (): ProceedingsListState => ({
  list: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
