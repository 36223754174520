import React from 'react';
import { RootState } from 'app/reducer';
import ListingPerPage from 'modules/Layout/component/Listing/PerPage';
import { PartialSearchingProps } from 'modules/Shared/type';
import { setTraditionalPaymentListParamsAction } from 'modules/Subscriptions/action/traditionalPaymentList';
import { useDispatch, useSelector } from 'react-redux';

const TraditionalPaymentWaitingListPerPage = () => {
  const dispatch = useDispatch();
  const { meta } = useSelector((state: RootState) => state.subscriptions.traditionalPayment);
  const setParams = (payload: PartialSearchingProps) => dispatch(setTraditionalPaymentListParamsAction(payload));

  return <ListingPerPage meta={meta} setParams={setParams} />;
};

export default TraditionalPaymentWaitingListPerPage;
