import React from 'react';
import { PopoverProps, Tooltip } from 'reactstrap';

interface Props extends PopoverProps {
  content: string;
  title?: string;
}

const PopoverComponent: React.FC<Props> = ({ className, isOpen, toggle, content, placement = 'bottom', target }) => {
  return (
    // <Popover isOpen={isOpen} target={target} trigger={trigger} toggle={toggle} placement={placement}>
    //   {title && <PopoverHeader>{title}</PopoverHeader>}
    //   <PopoverBody>
    //     <div dangerouslySetInnerHTML={{ __html: content }} />
    //   </PopoverBody>
    // </Popover>
    <Tooltip className={className} placement={placement} target={target} toggle={toggle} isOpen={isOpen}>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Tooltip>
  );
};

export default PopoverComponent;
