import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios, { CancelTokenSource } from 'axios';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { fetchJudgmentSAOSKeywordsAll } from 'modules/Judgement/repository';
import { fetchJudgementKeywordsToastError } from 'modules/Judgement/toasts';
import { addToastAction } from 'modules/Layout/action';
import { createSearchParams } from 'modules/Shared/helper/params';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import Autocomplete from 'modules/Layout/component/Autocomplete';

type Props = {
  keywordValue: string;
  onKeywordChange: (name: string) => void;
};

const SAOSKeywordAutocomplete: React.FC<Props> = React.memo(({ keywordValue, onKeywordChange }) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [keywordsList, setKeywordsList] = useState<JudgementElementEntity[]>([]);
  const [keywordValueName, setKeywordValueName] = useState('');
  const cancelToken: React.MutableRefObject<CancelTokenSource> = useRef(null);

  useEffect(() => {
    fetchKeywordsList();

    return () => {
      if (cancelToken.current) {
        cancelToken.current.cancel('AxiosCancel');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (keywordValue) {
      const currentValue = keywordsList.find((el) => el.name === keywordValue)?.name ?? '';
      if (currentValue) {
        setKeywordValueName(currentValue);
      }
    } else if (keywordValueName) {
      setKeywordValueName('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordValue]);

  const dispatchJudgementKeywordsToastError = (messageValue: string) =>
    dispatch(addToastAction(fetchJudgementKeywordsToastError(messageValue)));

  const fetchKeywordsList = async (name?: string) => {
    if (cancelToken.current) {
      cancelToken.current.cancel('Operation canceled due to new request.');
    }
    cancelToken.current = axios.CancelToken.source();

    const filter = name ? { name } : {};
    const searchParams = createSearchParams({ filter });

    setFetching(true);

    const { data, cancelled, message } = await fetchRequest(
      fetchJudgmentSAOSKeywordsAll,
      searchParams,
      cancelToken.current.token
    );

    if (cancelled) return;
    if (data) {
      setKeywordsList(data.data);
      setFetching(false);
    }
    if (message && message.value !== 'Operation canceled due to new request.') {
      if (fetching) setFetching(false);
      dispatchJudgementKeywordsToastError(message.value);
    }
  };

  const clearList = () => {
    if (cancelToken.current) {
      cancelToken.current.cancel('Operation canceled due to new request.');
    }
  };

  const onChangeValue = (name: string) => {
    const findKeyword = keywordsList.find((keyword) => keyword.name === name);
    if (findKeyword) onKeywordChange(findKeyword.name);
    else if (name === '') onKeywordChange(null);
  };

  return (
    <Autocomplete
      currentValue={keywordValueName}
      onChangeCurrentValue={onChangeValue}
      searchRequest={fetchKeywordsList}
      fetching={fetching}
      clearList={clearList}
      list={keywordsList as never}
      required
      label="Słowo kluczowe"
    />
  );
});

export default SAOSKeywordAutocomplete;
