import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { officeImportsListStatuses, OfficeImportsListValues } from 'modules/Office/model/Imports';
import { OFFICES_IMPORT } from 'modules/Office/permissions';
import Authorize from 'modules/Auth/container/Authorize';
import { Link } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_OFFICE_IMPORT } from 'modules/Office/routes';
import { formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';

type Props = {
  officeImportsList?: OfficeImportsListValues[];
};

const OfficeImportsTable: React.FC<Props> = ({ officeImportsList }) => {
  const redirectLink = (value: string, id: number) => (
    <Authorize permissions={[OFFICES_IMPORT]} fallback={value}>
      <Link className="fill-table-td" to={getPathUrl(ROUTE_OFFICE_IMPORT, { id })}>
        {value}
      </Link>
    </Authorize>
  );

  const cols: TableCol<OfficeImportsListValues>[] = [
    {
      property: 'file_name',
      label: 'Plik',
      value: (row) => redirectLink(row?.file_name, row?.id)
    },
    {
      property: 'status',
      label: 'Status',
      value: (row) => officeImportsListStatuses(row?.status)
    },
    {
      property: 'file_rows',
      label: 'Wpisy nowe / zaktualizowane',
      value: (row) => `${row?.new_rows} / ${row?.updated_rows}`
    },
    {
      property: 'errors_count',
      label: 'Liczba błędów',
      value: (row) => (row?.errors_count > 0 ? row.errors_count : 'Brak')
    },
    {
      property: 'created_at',
      label: 'Rozpoczęto',
      value: (row) => ifContainsDateFormat(row?.created_at, formatDatetimeSeconds)
    },
    {
      property: 'finished_at',
      label: 'Zakończono',
      value: (row) => ifContainsDateFormat(row?.finished_at, formatDatetimeSeconds)
    }
  ];

  return <Table cols={cols} rows={officeImportsList} />;
};

export default OfficeImportsTable;
