import React from 'react';
import { AGREEMENT_TYPE_PROJECT, AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementStepAgreementDateValues } from 'modules/Agreements/components/Steps/Default/AgreementDate';
import { getFieldForPreview, getStepValues, PREVIEW_PLACEHOLDER } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_CONTRACTOR_EXECUTOR } from 'modules/Agreements/step';
import classNames from 'classnames';
import { AgreementStepContractorExecutorValues } from 'modules/Agreements/components/Steps/Default/ContractorExecutor';

const AgreementStepPreviewContractorExecutor = (
  props: AgreementStepPreviewProps<AgreementStepContractorExecutorValues>
): JSX.Element => {
  const { steps, modifiedStep, agreement } = props;
  const agreementDate = getStepValues<AgreementStepAgreementDateValues>(steps, AGREEMENT_STEP_CONTRACTOR_EXECUTOR);

  const contractorFields = [
    {
      field: 'name',
      data: 'Nazwa zamawiającego: :field'
    },
    {
      field: 'address',
      data: 'Siedziba zamawiającego: :field'
    },
    {
      field: 'nip',
      data: 'NIP: :field'
    },
    {
      field: 'representative',
      data: 'Reprezentowana przez: :field'
    },
    {
      field: 'countersignature',
      data: 'Kontrasygnata: :field'
    }
  ];

  const executorFields = [
    {
      field: 'name',
      data: 'Nazwa wykonawcy: :field'
    },
    {
      field: 'address',
      data: 'Siedziba wykonawcy: :field'
    },
    {
      field: 'nip',
      data: 'NIP: :field'
    },
    {
      field: 'krs',
      data: 'KRS (jeśli dotyczy): :field'
    },
    {
      field: 'share_capital',
      data: 'Wysokość kapitału zakładowego (jeżeli dotyczy): :field'
    },
    {
      field: 'representative',
      data: 'Reprezentowany / reprezentowana / reprezentowane: :field'
    }
  ];

  const renderField = (field: { field: string; data: string; ifNotEmpty?: boolean }, type: string, index?: number) => {
    const formattedField =
      index === undefined ? `${type}.${field.field}` : `${type}.companies[${index}].${field.field}`;

    const { value, modified } = getFieldForPreview(agreementDate, modifiedStep, formattedField);

    if (!value && !(agreement.type === AGREEMENT_TYPE_PROJECT && type === 'executor')) {
      return null;
    }

    const content = field.data.replace(':field', value || PREVIEW_PLACEHOLDER);
    // @ts-ignore
    const formattedContent = content.replaceAll('....', `<span style='background-color:#d4d4d4'>....</span>`);

    return (
      <div key={field.field} className={classNames('mt-1', { modified })}>
        <p
          className={classNames('mb-0 wysiwyg-preview')}
          dangerouslySetInnerHTML={{ __html: String(formattedContent) }}
        />
      </div>
    );
  };

  const { value: contractorGroup } = getFieldForPreview(agreementDate, modifiedStep, 'contractor.group');

  const { value: contractorCompanies } = getFieldForPreview(agreementDate, modifiedStep, 'contractor.companies');

  const { value: executorGroupType } = getFieldForPreview(agreementDate, modifiedStep, 'executor.group_type');

  const { value: executorGroupOther } = getFieldForPreview(agreementDate, modifiedStep, 'executor.group_other');

  const { value: executorCompanies } = getFieldForPreview(agreementDate, modifiedStep, 'executor.companies');

  const renderContractorEndText = () => {
    return `${contractorGroup ? 'zwanych' : 'zwanym'} dalej, "zamawiajacym"`;
  };

  const renderExecutorBeginText = () => {
    let beginning;
    if (executorGroupType === 1) beginning = 'Spółka cywilna';
    if (executorGroupType === 2) beginning = 'Konsorcjum';
    if (executorGroupType === 3) beginning = executorGroupOther || 'Inna forma zgrupowania';

    return `${beginning} w składzie:`;
  };

  const renderExecutorEndText = () => {
    let beginning = 'zwanym';
    if (executorGroupType === 1) beginning = 'zwaną';
    if (executorGroupType === 2) beginning = 'zwane';
    if (executorGroupType === 3) beginning = 'zwane';

    return `${beginning} dalej „wykonawcą", a łącznie „stronami"`;
  };

  return (
    <div className="agreement-step-preview-wrapper">
      {contractorGroup &&
        contractorCompanies.map((_: any, index: number) => {
          return (
            <>
              <span>{index + 1}.</span>
              {contractorFields.map((field) => renderField(field, 'contractor', index))}
            </>
          );
        })}
      {!contractorGroup && (
        <div className="mt-3">{contractorFields.map((field) => renderField(field, 'contractor'))}</div>
      )}
      <div className="mt-2">{renderContractorEndText()}</div>
      {executorGroupType && <div className="mt-2">{renderExecutorBeginText()}</div>}
      {executorGroupType &&
        executorCompanies.map((_: any, index: number) => {
          return (
            <>
              <span>{index + 1}.</span>
              {executorFields.map((field) => renderField(field, 'executor', index))}
            </>
          );
        })}
      {!executorGroupType && (
        <div className="mt-3">{executorFields.map((field) => renderField(field, 'executor'))}</div>
      )}
      <div className="mt-2">{renderExecutorEndText()}</div>
    </div>
  );
};

export default AgreementStepPreviewContractorExecutor;
