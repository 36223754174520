import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import Authorize from 'modules/Auth/container/Authorize';
import { JUDGMENT_SAOS_KEYWORDS_DELETE } from 'modules/Judgement/permissions';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import { formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';

type Props = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SAOSKeywords: JudgementElementEntity[];
  onDeleteClick: (SAOSKeyword: JudgementElementEntity) => void;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const SAOSKeywordTable: React.FC<Props> = ({ SAOSKeywords, onDeleteClick }) => {
  const cols: TableCol<JudgementElementEntity>[] = [
    {
      property: 'name',
      label: 'Nazwa'
    },
    {
      property: 'created_at',
      label: 'Utworzono',
      value: (row) => ifContainsDateFormat(row.created_at, formatDatetimeSeconds)
    },
    {
      property: 'updated_at',
      label: 'Zaktualizowano',
      value: (row) => ifContainsDateFormat(row.updated_at, formatDatetimeSeconds)
    },
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            {onDeleteClick && (
              <Authorize permissions={[JUDGMENT_SAOS_KEYWORDS_DELETE]}>
                <ActionDelete className="mx-1" title="Usuń słowo kluczowe" onClick={() => onDeleteClick(row)} />
              </Authorize>
            )}
          </div>
        );
      }
    }
  ];

  return <Table cols={cols} rows={SAOSKeywords} />;
};

export default SAOSKeywordTable;
