import { CancelTokenSource } from 'axios';
import { useEffect } from 'react';

function useSendPeriodicallyRequest<T>(
  request: () => void,
  isStatusAwaiting: boolean,
  source: CancelTokenSource,
  useEffectCondition?: T,
  time = 2000
) {
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isStatusAwaiting) {
      timer = setTimeout(async () => {
        if (source) {
          source.cancel('Operation canceled due to new request.');
        }
        await request();
      }, time);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useEffectCondition]);
}

export default useSendPeriodicallyRequest;
