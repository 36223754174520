import React, { useEffect } from 'react';
import CardBox from 'modules/Layout/component/CardBox';
import { useDispatch } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
import { breadcrumbRouteDeclarationOfAvailability } from 'modules/Layout/breadcrumbs';

const DeclarationOfAvailability: React.FC = () => {
  const dispatch = useDispatch();
  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  useEffect(() => {
    const title = 'Deklaracja dostępności';

    managePage({
      title,
      breadcrumb: breadcrumbRouteDeclarationOfAvailability()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const templateTheme = process.env.THEME_TEMPLATE as 'pzp' | 'bs';

  return (
    <div className="contact-view row justify-content-center pl-md-5 pr-md-5">
      <div className="col-12">
        <CardBox paddingRight="30px" paddingLeft="30px" paddingBottom="30px" childrenPaddingTop="5px">
          <strong>
            DEKLARACJA DOSTĘPNOŚCI {templateTheme === 'pzp' ? 'ASYSTENTA POSTĘPOWANIA' : 'SERWISU BŁAŻEJ SARNOWSKI'}
          </strong>
          <br />
          <span id="a11y-podmiot">
            Serwis „{templateTheme === 'pzp' ? 'Asystent Postępowania' : 'Błażej Sarnowski'}"
          </span>{' '}
          zobowiązuje się zapewnić dostępność swojej strony internetowej zgodnie z ustawą z dnia 4 kwietnia 2019 r. o
          dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych. Oświadczenie w sprawie
          dostępności ma zastosowanie do strony internetowej Serwisu „
          {templateTheme === 'pzp' ? 'Asystent Postępowania' : 'Błażej Sarnowski'}":{' '}
          {templateTheme === 'pzp' ? (
            <a href={`https://raporty.postepowania.pl/`} id="a11y-url" target="_blank">
              raporty.postepowania.pl
            </a>
          ) : (
            <>
              <a href={`https://raporty.sarnowski.postepowania.pl/`} id="a11y-url" target="_blank">
                raporty.sarnowski.postepowania.pl/
              </a>
            </>
          )}
          .
          <br />
          <br />
          Data publikacji strony internetowej:{' '}
          <span id="a11y-data-publikacja">{templateTheme === 'pzp' ? '2020-12-01' : '2024-06-01'}</span>
          <br />
          <br />
          Serwis „{templateTheme === 'pzp' ? 'Asystent Postępowania' : 'Błażej Sarnowski'}" przeprowadził samoocenę
          dostępności dla osób niepełnosprawnych cyfrowej strony internetowej w odniesieniu do wytycznych określonych w
          załączniku do ustawy z dnia 4 kwietnia 2019 r. oraz skorzystano z dostępnego narzędzia do oceny strony: Test –
          European Internet Inclusion Initiative (
          <a href="http://checkers.eiii.eu/" target="_blank">
            http://checkers.eiii.eu/
          </a>
          ).
          <br />
          <br />
          <strong>STATUS POD WZGLĘDEM DOSTĘPNOŚCI</strong>
          <br />
          Strona internetowa jest zgodna z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i
          aplikacji mobilnych podmiotów publicznych. <br />
          <br />
          Informacja o dostępności <br />
          Strony zostały zrealizowane z uwzględnieniem wytycznych WCAG 2.0 i zgodnie ze standardami sieciowymi. Serwis
          został zaprojektowany tak, aby był możliwy do obsłużenia dla jak najszerszej grupy użytkowników, niezależnie
          od używanej technologii, oprogramowania lub posiadanej dysfunkcji. Nieustannie poszukujemy rozwiązań, aby
          zwiększyć dostępność i użyteczność naszej strony internetowej. Jeżeli masz jakieś uwagi albo komentarze
          skontaktuj się z nami. <br />
          Zgodność ze standardami: <br />
          Serwis jest zgodny ze standardami W3C: <br />
          – HTML 5 <br />
          – WCAG 2.0 (Podwójne A) <br />
          Kompatybilność: <br />
          Obsługa serwisu możliwa jest zarówno przy pomocy myszki jak i klawiatury. <br />
          <br />
          Wygląd: <br />
          Serwis jest wyposażony w mechanizmy ułatwiające przeglądanie treści przez osoby niedowidzące. <br />
          Posiada przełączniki powiększenia czcionki, zmianę kontrastu strony, zmianę odcieni szarości, podkreślenie
          linków. <br />
          Posiada sprawnie działającą nawigację klawiaturą dla wszystkich elementów strony. <br />
          Ruch, animacje, banery – żaden element serwisu nie miga w tempie szybszym niż 3 razy na sekundę. <br />
          Serwis wykorzystuje technologię CSS (Cascading Style Sheets), JavaScript oraz język programowania PHP.
          <br />
          <br />
          Skróty klawiaturowe: Serwis nie jest wyposażony w skróty klawiaturowe, które mogłyby wchodzić w konflikt z
          technologiami asystującymi (np. programy czytające), systemem lub aplikacjami użytkowników.
          <br />
          <br />
          Wersja mobilna serwisu Serwis można przeglądać na ekranach urządzeń mobilnych, takich jak telefony komórkowe i
          tablety. Zbudowany został w oparciu o metodę Responsive Web Design, dzięki czemu automatycznie dostosowuje
          moduły strony do rozdzielczości ekranu urządzenia mobilnego.
          <br />
          <br />
          <strong>STATUS POD WZGLĘDEM ZGODNOŚCI Z USTAWĄ</strong>
          <br />
          Strona internetowa jest zgodna z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i
          aplikacji mobilnych podmiotów publicznych. Strona internetowa nie jest wyposażona w system audiodeskrypcji.{' '}
          <br />
          Istnieje możliwość wyświetlania treści na urządzeniach mobilnych. <br />
          {templateTheme === 'pzp' && (
            <>
              Serwis „{templateTheme === 'pzp' ? 'Asystent Postępowania' : 'Błażej Sarnowski'}" posiada także dedykowane
              aplikacje mobilne: <br />
              Linki do aplikacji:
              <br />
              <a href="https://play.google.com/store/apps/details?id=pl.vao.pzp_ue_mobile" target="_blank">
                https://play.google.com/store/apps/details?id=pl.vao.pzp_ue_mobile
              </a>
              <br />
              <a href="https://apps.apple.com/pl/app/asystent-post%C4%99powania/id1610325784?l=pl" target="_blank">
                https://apps.apple.com/pl/app/asystent-post%C4%99powania/id1610325784?l=pl
              </a>
            </>
          )}
          <br />
          <br />
          <strong>PRZYGOTOWANIE DEKLARACJI W SPRAWIE DOSTĘPNOŚCI</strong>
          <br />
          Oświadczenie sporządzono dnia:{' '}
          <span id="a11y-data-sporzadzenie">{templateTheme === 'pzp' ? '2023-02-10' : '2024-06-01'}</span> <br />
          Deklarację sporządzono na podstawie samooceny.
          <br />
          <br />
          <strong>INFORMACJE ZWROTNE I DANE KONTAKTOWE</strong> <br />W przypadku problemów z dostępnością strony
          internetowej prosimy o kontakt. Osobą kontaktową jest{' '}
          <span id="a11y-osoba">{templateTheme === 'pzp' ? 'Nikita Mitar' : 'Błażej Sarnowski'}</span>,{' '}
          <a href={`mailto:${templateTheme === 'pzp' ? 'n.mitar@postepowania.pl' : 'kancelaria@sarnowski.info.pl'}`}>
            <span id="a11y-email">
              {templateTheme === 'pzp' ? 'n.mitar@postepowania.pl' : 'kancelaria@sarnowski.info.pl'}
            </span>
          </a>
          . Kontaktować można się także dzwoniąc na numer telefonu{' '}
          <a href={`tel:${templateTheme === 'pzp' ? '+48 798 855 799' : '+48 63 248 21 75'}`}>
            <span id="a11y-telefon">{templateTheme === 'pzp' ? '+48 798 855 799' : '+48 63 248 21 75'}</span>
          </a>
          . Tą samą drogą można składać wnioski o udostępnienie informacji niedostępnej oraz składać skargi na brak
          zapewnienia dostępności.
          <br />
          <br />
          <p id="a11y-procedura">
            {' '}
            Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności cyfrowej strony internetowej, aplikacji
            mobilnej lub jakiegoś ich elementu. Można także zażądać udostępnienia informacji za pomocą alternatywnego
            sposobu dostępu, na przykład przez odczytanie niedostępnego cyfrowo dokumentu, opisanie zawartości filmu bez
            audiodeskrypcji itp. Żądanie powinno zawierać dane osoby zgłaszającej żądanie, wskazanie, o którą stronę
            internetową lub aplikację mobilną chodzi oraz sposób kontaktu. Jeżeli osoba żądająca zgłasza potrzebę
            otrzymania informacji za pomocą alternatywnego sposobu dostępu, powinna także określić dogodny dla niej
            sposób przedstawienia tej informacji. Podmiot publiczny powinien zrealizować żądanie niezwłocznie, nie
            później niż w ciągu 7 dni od dnia wystąpienia z żądaniem. Jeżeli dotrzymanie tego terminu nie jest możliwe,
            podmiot publiczny niezwłocznie informuje o tym wnoszącego żądanie, kiedy realizacja żądania będzie możliwa,
            przy czym termin ten nie może być dłuższy niż 2 miesiące od dnia wystąpienia z żądaniem. Jeżeli zapewnienie
            dostępności cyfrowej nie jest możliwe, podmiot publiczny może zaproponować alternatywny sposób dostępu do
            informacji. W przypadku, gdy podmiot publiczny odmówi realizacji żądania zapewnienia dostępności lub
            alternatywnego sposobu dostępu do informacji, wnoszący żądanie możne złożyć skargę w sprawie zapewniana
            dostępności cyfrowej strony internetowej, aplikacji mobilnej lub elementu strony internetowej, lub aplikacji
            mobilnej. Po wyczerpaniu wskazanej wyżej procedury można także złożyć wniosek do Rzecznika Praw
            Obywatelskich.
          </p>
        </CardBox>
      </div>
    </div>
  );
};

export default DeclarationOfAvailability;
