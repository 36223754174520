export type AgreementStageSlug = string;

export const AGREEMENT_STAGE_PREPARING: AgreementStageSlug = 'preparing';
export const AGREEMENT_STAGE_AGREEMENT: AgreementStageSlug = 'agreement';

export type AgreementStepSlug = string;

export const AGREEMENT_STEP_TYPE: AgreementStepSlug = 'type';
export const AGREEMENT_STEP_PURPOSE: AgreementStepSlug = 'purpose';
export const AGREEMENT_STEP_AGREEMENT_DATE: AgreementStepSlug = 'agreement_date';
export const AGREEMENT_STEP_CONTRACTOR_EXECUTOR: AgreementStepSlug = 'contractor_executor';
export const AGREEMENT_STEP_PREAMBLE: AgreementStepSlug = 'preamble';
export const AGREEMENT_STEP_SUBJECT: AgreementStepSlug = 'subject';
export const AGREEMENT_STEP_SUBJECT_ORDER_FORM: AgreementStepSlug = 'subject_order_form';
export const AGREEMENT_STEP_SUPPLEMENTARY_ORDERS: AgreementStepSlug = 'supplementary_orders';
export const AGREEMENT_STEP_OPTION_RIGHT: AgreementStepSlug = 'option_right';
export const AGREEMENT_STEP_CONTRACT_SING: AgreementStepSlug = 'contract_sing';
export const AGREEMENT_STEP_DEADLINE: AgreementStepSlug = 'deadline';
export const AGREEMENT_STEP_WORK_REQUIREMENTS: AgreementStepSlug = 'work_requirements';
export const AGREEMENT_STEP_CONTRACTOR_DUTIES: AgreementStepSlug = 'contractor_duties';
export const AGREEMENT_STEP_EXECUTOR_DUTIES: AgreementStepSlug = 'executor_duties';
export const AGREEMENT_STEP_REALIZATION_PEOPLE: AgreementStepSlug = 'realization_people';
export const AGREEMENT_STEP_REWARD: AgreementStepSlug = 'reward';
export const AGREEMENT_STEP_SUBEXECUTORS: AgreementStepSlug = 'subexecutors';
export const AGREEMENT_STEP_EMPLOYMENT_RELATIONSHIP: AgreementStepSlug = 'employment_relationship';
export const AGREEMENT_STEP_ACCEPT: AgreementStepSlug = 'accept';
export const AGREEMENT_STEP_PERFORMANCE_SECURITY: AgreementStepSlug = 'performance_security';
export const AGREEMENT_STEP_INSURANCE: AgreementStepSlug = 'insurance';
export const AGREEMENT_STEP_QUALITY_GUARANTEE: AgreementStepSlug = 'quality_guarantee';
export const AGREEMENT_STEP_PENALTIES: AgreementStepSlug = 'penalties';
export const AGREEMENT_STEP_RENOUNCEMENT: AgreementStepSlug = 'renouncement';
export const AGREEMENT_STEP_CONTRACT_CHANGES: AgreementStepSlug = 'contract_changes';
export const AGREEMENT_STEP_INDEXATION_CLAUSES: AgreementStepSlug = 'indexation_clauses';
export const AGREEMENT_STEP_REPRESENTATIVES: AgreementStepSlug = 'representatives';
export const AGREEMENT_STEP_REPLACEMENT: AgreementStepSlug = 'replacement';
export const AGREEMENT_STEP_LICENSE: AgreementStepSlug = 'license';
export const AGREEMENT_STEP_PERSONAL_DATA_PROTECTION: AgreementStepSlug = 'personal_data_protection';
export const AGREEMENT_STEP_DISPUTES: AgreementStepSlug = 'disputes';
export const AGREEMENT_STEP_FINAL_PROVISIONS: AgreementStepSlug = 'final_provisions';
export const AGREEMENT_STEP_DELIVERY_REQUIREMENTS: AgreementStepSlug = 'delivery_requirements';
export const AGREEMENT_STEP_RECEIPT: AgreementStepSlug = 'receipt';
export const AGREEMENT_STEP_BASIC_INFORMATIONS: AgreementStepSlug = 'basic_informations';
export const AGREEMENT_STEP_ADDITIONAL_PROVISIONS: AgreementStepSlug = 'additional_provisions';
export const AGREEMENT_STEP_SPECIFIC_REQUIREMENTS: AgreementStepSlug = 'specific_requirements';
export const AGREEMENT_STEP_CO_FINANCING: AgreementStepSlug = 'co_financing';
