import { RootState } from 'app/reducer';
import Authorize from 'modules/Auth/container/Authorize';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import EditCompanyIcon from 'modules/Layout/component/Icon/EditCompany';
import EditUserIcon from 'modules/Layout/component/Icon/EditUser';
import Table, { TableCol } from 'modules/Layout/component/Table';
import actionDeleteTrashIconList from 'modules/Shared/helper/actionDeleteTrashIconList';
import { getPathUrl } from 'modules/Shared/helper/api';
import { formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { PartialSearchingProps } from 'modules/Shared/type';
import { setUserListParamsAction } from 'modules/User/action/list';
import User from 'modules/User/model/User';
import { ROLE_ADMIN } from 'modules/User/model/UserRole';
import { USERS_DELETE, USERS_EDIT, USER_DETAILS } from 'modules/User/permissions';
import { ROUTE_USER, ROUTE_USER_BASIC_UPDATE, ROUTE_USER_COMPANY_UPDATE } from 'modules/User/routes';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

type Props = { onDeleteClick?: (user: User) => void; isAdminList?: boolean };

const UserTable: React.FC<Props> = ({ onDeleteClick, isAdminList }) => {
  const dispatch = useDispatch();
  const { users, sort } = useSelector((state: RootState) => state.user.list);

  const setParams = (payload: PartialSearchingProps) => dispatch(setUserListParamsAction(payload));

  const redirectLink = (value: string, id: number) => (
    <Authorize permissions={[USER_DETAILS]} fallback={value}>
      <Link className="fill-table-td" to={getPathUrl(ROUTE_USER, { id })}>
        {value}
      </Link>
    </Authorize>
  );

  const activeUsersList = users?.findIndex((user) => user?.status) !== -1;

  let cols: TableCol<User>[] = [
    {
      property: 'first_name',
      label: 'Imię',
      sortable: true,
      value: (row) => redirectLink(row.first_name, row.id)
    },
    {
      property: 'last_name',
      label: 'Nazwisko',
      sortable: true,
      value: (row) => redirectLink(row.last_name, row.id)
    },
    {
      property: 'email',
      label: 'Email',
      sortable: true,
      value: (row) => redirectLink(row.email, row.id)
    }
  ];

  if (!isAdminList) {
    cols = [
      ...cols,
      {
        property: 'name',
        label: 'Firma',
        value: (row) => row.getCompanyName()
      }
    ];
  }

  cols = [
    ...cols,
    {
      property: 'last_login',
      label: 'Data ostatniego logowania',
      value: (row) => ifContainsDateFormat(row.getLastLogin(), formatDatetimeSeconds)
    }
  ];

  if (activeUsersList) {
    cols = [
      ...cols,
      {
        property: 'actions',
        label: 'Akcje',
        value: function Actions(row) {
          return (
            <div className="actions-wrapper">
              <Authorize permissions={[USERS_EDIT]}>
                <ActionUpdate
                  className="mx-1"
                  title="Edycja danych podstawowych"
                  label={<EditUserIcon height="20px" />}
                  to={getPathUrl(ROUTE_USER_BASIC_UPDATE, { id: row.id })}
                />
                {row?.role?.slug !== ROLE_ADMIN && (
                  <ActionUpdate
                    className="mx-1"
                    title="Edycja danych rozliczeniowych"
                    label={<EditCompanyIcon height="20px" />}
                    to={getPathUrl(ROUTE_USER_COMPANY_UPDATE, { id: row.id })}
                  />
                )}
              </Authorize>
              {onDeleteClick && (
                <Authorize permissions={[USERS_DELETE]}>
                  {actionDeleteTrashIconList(row, {
                    targetIdName: 'DeleteUserId',
                    onDeleteClick,
                    tooltipContent: 'Nie można usunąć własnego konta z poziomu listy.',
                    title: 'Usuń użytkownika'
                  })}
                </Authorize>
              )}
            </div>
          );
        }
      }
    ];
  }

  return <Table cols={cols} rows={users} sort={sort} onSort={(params) => setParams({ sort: params })} />;
};

export default UserTable;
