/* eslint-disable */
import React from 'react';
import { IconProps } from '../index';

export default function LoginLoginIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 30.695 22.107">
      <g id="Group_53" data-name="Group 53" transform="translate(0.5 0.5)">
        <path
          id="Path_51"
          data-name="Path 51"
          d="M1507.694,356.127c-3.324-1.313-7.012-2.944-7.323-3.96V351.1a6.781,6.781,0,0,0,1.555-2.9,1.75,1.75,0,0,0,.326-2.5v-2.387c0-2.463-1.341-4.15-4.408-4.15-2.988,0-4.409,1.688-4.409,4.15v2.387a1.752,1.752,0,0,0,.326,2.5,6.781,6.781,0,0,0,1.555,2.9v1.066c-.309,1.015-4,2.646-7.322,3.96a.759.759,0,0,0-.471.709V359.5a.746.746,0,0,0,.731.759h19.179a.746.746,0,0,0,.731-.759v-2.667A.757.757,0,0,0,1507.694,356.127Z"
          transform="translate(-1487.523 -339.155)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_52"
          data-name="Path 52"
          d="M1507.012,359.878h5.661a.694.694,0,0,0,.692-.693v-2.434a.693.693,0,0,0-.445-.648c-3.147-1.2-6.639-1.137-6.932-2.064v-1.4c3.141-.008,4.388-1.01,4.388-1.01-1.312-.735-1.727-5.867-1.727-5.867,0-4.273-4.828-6.835-7.992-4.293"
          transform="translate(-1483.67 -338.771)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
