import React from 'react';
import { Menu } from 'app/menu';
import { ROUTE_SUBSCRIPTIONS } from 'modules/Subscriptions/routes';
import User from 'modules/User/model/User';
import ShoppingIcon from '../Layout/component/Icon/Shopping';
import { SUBSCRIPTIONS_MODULE_ACCESS } from '../Auth/container/Guard/permissions';

const MenuSubscriptionShared: Menu[] = [
  {
    key: 'subscriptions-link',
    type: 'link',
    title: 'Zakup subskrypcji',
    icon: <ShoppingIcon />,
    href: ROUTE_SUBSCRIPTIONS,
    hasAccess: (user: User) => user?.permissions.includes(SUBSCRIPTIONS_MODULE_ACCESS),
    permissions: [SUBSCRIPTIONS_MODULE_ACCESS]
  }
];

export default MenuSubscriptionShared;
