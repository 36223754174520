import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import { setExecutorExtendedListParamsAction } from 'modules/User/action/list';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterInputType } from 'modules/Layout/type';
import { formatDatetimeLocalForInput } from 'modules/Shared/helper/utils';

export interface Props {
  values?: SearchParams;
}

const ExecutorExtendedFilter: React.FC<Props> = ({ values = {} }) => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.user.executorsExtendedList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setExecutorExtendedListParamsAction(payload));

  const staticInputsBase: FilterInputType[] = [
    {
      type: 'text',
      property: 'id',
      label: 'Numer zlecenia',
      value: _get(values, 'id')
    },
    {
      type: 'text',
      property: 'first_name',
      label: 'Imię klienta',
      value: _get(values, 'first_name')
    },
    {
      type: 'text',
      property: 'last_name',
      label: 'Nazwisko klienta',
      value: _get(values, 'last_name')
    },
    {
      type: 'text',
      property: 'name',
      label: 'Firma klienta',
      value: _get(values, 'name')
    },
    {
      type: 'text',
      property: 'nip',
      label: 'NIP weryfikowanego wykonawcy',
      value: _get(values, 'nip')
    },
    {
      type: 'select',
      property: 'status',
      label: 'Status wykonania raportu',
      value: _get(values, 'status'),
      options: [
        {
          label: '',
          value: 0
        },
        {
          label: 'w trakcie wykonania',
          value: 1
        },
        {
          label: 'wykonane',
          value: 2
        }
      ]
    }
  ];

  const inputsTime = (): FilterInputType[] => {
    let dynamicInputs: FilterInputType[] = [];

    const createdAtFrom = _get(filter, 'created_at_from') as string;
    const createdAtTo = _get(filter, 'created_at_to') as string;
    const updatedAtFrom = _get(filter, 'updated_at_from') as string;
    const updatedAtTo = _get(filter, 'updated_at_to') as string;

    dynamicInputs = [
      {
        beforeLineBreak: true,
        type: 'datetime',
        property: 'created_at_from',
        label: 'Data zlecenia raportu od',
        value: formatDatetimeLocalForInput(createdAtFrom)
      },
      {
        type: 'datetime',
        property: 'created_at_to',
        label: 'Data zlecenia raportu do',
        value: formatDatetimeLocalForInput(createdAtTo)
      },
      {
        beforeLineBreak: true,
        type: 'datetime',
        property: 'updated_at_from',
        label: 'Data ostatniej aktualizacji raportu od',
        value: formatDatetimeLocalForInput(updatedAtFrom)
      },
      {
        type: 'datetime',
        property: 'updated_at_to',
        label: 'Data ostatniej aktualizacji raportu do',
        value: formatDatetimeLocalForInput(updatedAtTo)
      }
    ];

    return dynamicInputs;
  };

  const staticInputs = [...staticInputsBase, ...inputsTime()];

  return <ListingFilter inputs={staticInputs} filter={filter} pagination={pagination} setParams={setParams} />;
};

export default ExecutorExtendedFilter;
