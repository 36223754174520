import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import BaseModal, { Props } from 'modules/Layout/component/Modal';
import { ResendEmailRequest } from 'modules/Office/type';
import { Message } from 'modules/Shared/type';
import React, { useEffect } from 'react';
import { Button } from 'reactstrap';

interface IProps extends Props {
  onSubmit: (data?: ResendEmailRequest) => void;
  loading?: boolean;
  message: Message;
  cleanMessage: () => void;
  options?: ResendEmailRequest;
}

const ResendEmailModal: React.FC<IProps> = (props) => {
  const { onSubmit, loading, toggle, message, cleanMessage, options } = props;
  const title = `Wymuszenie wysłania emaila`;

  useEffect(() => {
    if (message) cleanMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const officeContentCorectForm = () => {
    if (!options) return 'wskazanego urzędu';
    if (options?.offices && options.offices.length === 1) {
      return 'wskazanego urzędu';
    }
    return 'wskazanych urzędów';
  };

  const displayBodyContent = (mainContent: string) => {
    return (
      <>
        {loading && <Loader />}
        <Alert message={message} />
        <div className="modal-body-title">{mainContent}</div>
        <div className="modal-body-content mt-4">
          Spowoduje to zmianę terminu wysłania kolejnych wiadomości dla {officeContentCorectForm()}.
        </div>
      </>
    );
  };

  const displayBodyForAll = () => {
    return displayBodyContent('Czy chcesz wysłać wniosek o udostępnienie informacji publicznej do wszystkich urzędów?');
  };

  const displayBodyForUnsend = () => {
    return displayBodyContent(
      `Czy chcesz wysłać wniosek o udostępnienie informacji publicznej do wszystkich urzędów, które nigdy nie dostały
        zapytania?`
    );
  };

  const displayBodyForSelected = () => {
    return displayBodyContent(
      'Czy chcesz wysłać wniosek o udostępnienie informacji publicznej do wszystkich wybranych urzędów?'
    );
  };

  const displayBodyForOffice = () => {
    return displayBodyContent('Czy chcesz wysłać wniosek o udostępnienie informacji publicznej do wybranego urzędu?');
  };

  const displayFooter = () => {
    return (
      <div className="modal-actions">
        <Button
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light"
          disabled={loading}
          onClick={() => onSubmit(options)}
        >
          Wyślij
        </Button>
        <Button type="button" className="cancel waves-effect waves-light" onClick={toggle}>
          Anuluj
        </Button>
      </div>
    );
  };

  const diplayBody = () => {
    if (options?.send_to_all) return displayBodyForAll();
    if (options?.send_to_not_received_before) return displayBodyForUnsend();
    if (options?.offices) return displayBodyForSelected();
    return displayBodyForOffice();
  };

  return <BaseModal {...props} modalClassName="modal-lg" title={title} body={diplayBody()} footer={displayFooter()} />;
};

export default ResendEmailModal;
