import React from 'react';
import { MessagePage } from 'modules/Layout/component/Page/Message/index';

const RegisterMessagePage = (): JSX.Element => (
  <MessagePage
    message={
      <>
        Gratulujemy,
        <br />
        właśnie założyłeś
        <br />
        <span className="text-primary">nasze bezpłatne</span> konto!
        <br />
        <br />
        Sprawdź
        <br />
        skrzynkę pocztową,
        <br />
        aby aktywować konto.
      </>
    }
  />
);

export default RegisterMessagePage;
