import React, { Dispatch, SetStateAction } from 'react';
import { FormGroup } from 'reactstrap';
import { getError } from 'modules/Shared/helper/validation';
import CheckboxInput from 'modules/Layout/component/Input/Checkbox';
import { ValidationErrors } from 'modules/Shared/type';

export interface StepFieldPartEditableCheckboxProps<T> {
  checkbox: string;
  index: number;
  part: Record<any, any>;
  setStepValues: Dispatch<SetStateAction<T>>;
  mapState: (values: T) => T;
  errors?: ValidationErrors | null;
  partSlug?: string;
  initContentValues?: Record<string, string>;
  disableCheckbox?: boolean;
  disableEdit?: boolean;
}

const StepFieldPartEditableCheckbox = <T,>(props: StepFieldPartEditableCheckboxProps<T>): JSX.Element => {
  const {
    checkbox,
    index,
    part,
    setStepValues,
    mapState,
    errors,
    partSlug,
    initContentValues,
    disableCheckbox,
    disableEdit
  } = props;

  return (
    <FormGroup key={`${partSlug}.${index}.${checkbox}`}>
      <CheckboxInput
        id={`${checkbox}.${index}`}
        // @ts-ignore
        checked={part[`${checkbox}_checked`]}
        // @ts-ignore
        content={part[`${checkbox}_content`]}
        onChange={(checked, content) => {
          // @ts-ignore
          part[`${checkbox}_checked`] = checked;
          // @ts-ignore
          part[`${checkbox}_content`] = content;
          setStepValues((values) => mapState(values));
        }}
        onRevert={() => {
          // @ts-ignore
          part[`${checkbox}_content`] = initContentValues[`${checkbox}_content`];
          setStepValues((values) => mapState(values));
        }}
        disableCheckbox={disableCheckbox}
        disableEdit={disableEdit}
        error={
          getError(errors, `${partSlug}.${index}.${checkbox}_checked`) ||
          getError(errors, `${partSlug}.${index}.${checkbox}_content`)
        }
      />
    </FormGroup>
  );
};

export default StepFieldPartEditableCheckbox;
