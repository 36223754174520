import { managePageAction } from 'modules/Layout/action';
import Loader from 'modules/Layout/component/Loader';
import { breadcrumbRouteExecutorExtendedRequestBasicUpdate } from 'modules/User/breadcrumbs';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Alert from 'modules/Layout/component/Alert';
import { Message, ValidationErrors } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_USER_EXECUTOR_EXTENDED } from 'modules/User/routes';
import { fetchExecutorExtendedRequestDetails, updateExecutorExtendedRequestData } from 'modules/User/repository';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import updateRequest from 'modules/Shared/helper/APIRequests/updateRequest';
import CardBox from 'modules/Layout/component/CardBox';
import moment from 'moment/moment';
import { serialize } from 'object-to-formdata';
import { ExecutorExtendedRequestState } from '../../../../Executor/model/Violation';
import ExecutorExtendedRequestForm from '../../../component/ExecutorExtendedRequestForm';
import { formatTimeValue } from '../../../../Shared/helper/utils';

export interface Props {
  params?: any;
}

const ExecutorExtendedRequestUpdateView: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const momentFormat = moment().format();
  const { claim_id, request_id } = useParams<{ claim_id: string; request_id: string }>();
  const [executorExtendedRequest, setExecutorExtendedRequest] = useState<ExecutorExtendedRequestState>(null);
  const [message, setMessage] = useState<Message>(null);
  const [originalFiles, setOriginalFiles] = useState<number>(0);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  const scrollToTopOnce = useRef<boolean>(true);

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const {
        data: reqData,
        cancelled,
        message: messageResponse
      } = await fetchRequest(fetchExecutorExtendedRequestDetails, claim_id, request_id, cancelToken);

      if (cancelled) return;
      const { data } = reqData;
      if (data) {
        setExecutorExtendedRequest({
          ...data,
          files:
            data.files.length > 0
              ? data.files.map((fileItem) => {
                  return {
                    file: fileItem as any,
                    id: fileItem.id
                  };
                })
              : []
        });
        setOriginalFiles(data.files.length);
      }
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const title = `Edycja wniosku`;
    dispatch(
      managePageAction({
        title,
        breadcrumb: breadcrumbRouteExecutorExtendedRequestBasicUpdate(parseInt(claim_id, 10) || 0)
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateExecutorExtendedAction = async (executorExtendedRequestData: ExecutorExtendedRequestState) => {
    if (loading) return;

    if (errors) setErrors(null);
    setLoading(true);

    const { files, received_at, ...restData } = executorExtendedRequestData;

    const addTimeToReceivedAt = `${received_at} ${formatTimeValue(momentFormat)}`;

    const convertedData = serialize(
      {
        ...restData,
        received_at: addTimeToReceivedAt,
        status: executorExtendedRequestData.status.id,
        channel_id: executorExtendedRequestData.channel.id,
        category: executorExtendedRequestData.category.slug,
        files: files.length > 0 ? [] : files.map((file) => file.file)
      },
      {
        indices: true,
        booleansAsIntegers: true,
        nullsAsUndefineds: false,
        allowEmptyArrays: false
      }
    );

    const {
      cancelled,
      message: messageRes,
      success,
      error
    } = await updateRequest(updateExecutorExtendedRequestData, convertedData as any, claim_id, request_id, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatch(push(getPathUrl(ROUTE_USER_EXECUTOR_EXTENDED, { id: claim_id })));
    } else {
      if (messageRes) setMessage(messageRes);
      if (error) setErrors(error);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  if (fetching) return <Loader />;
  if (!fetching && !executorExtendedRequest && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  if (!fetching && executorExtendedRequest) {
    return (
      <div className="profile-update-view d-flex justify-content-center">
        <CardBox form="small" heading="Podstawowe dane użytkownika">
          {loading && <Loader />}
          <Alert message={message} />
          {executorExtendedRequest && (
            <ExecutorExtendedRequestForm
              executorExtendedRequest={executorExtendedRequest}
              errors={errors}
              submit={updateExecutorExtendedAction}
              originalFiles={originalFiles}
              momentFormat={momentFormat}
              disabled={loading}
              cancelRedirect={getPathUrl(ROUTE_USER_EXECUTOR_EXTENDED, { id: claim_id })}
            />
          )}
        </CardBox>
      </div>
    );
  }

  return null;
};

export default ExecutorExtendedRequestUpdateView;
