import PerPage from 'modules/Layout/component/Pagination/PerPage';
import { PaginationMeta, PartialSearchingProps } from 'modules/Shared/type';
import React from 'react';

export type Props = {
  meta: PaginationMeta;
  setParams: (params: PartialSearchingProps) => void;
};

const ListingPerPage: React.FC<Props> = ({ meta, setParams }) => {
  const onChange = (per_page: string) => {
    const pagination = { per_page };
    setParams({ pagination });
  };

  if (meta) {
    return <PerPage meta={meta} onChange={onChange} />;
  }

  return null;
};

export default ListingPerPage;
