export enum LastExecutorRequestStatusesOptions {
  NOT_SEND = 0,
  RECEIVED = 1,
  NOT_RECEIVED = 2
}

export enum LastExecutorRequestStatusesValues {
  NOT_SEND = 'Nigdy nie wysłano zapytania.',
  RECEIVED = 'Otrzymano odpowiedź na aktualne zapytanie.',
  NOT_RECEIVED = 'Nie otrzymano odpowiedzi na ostatnie zapytanie w wyznaczonym czasie.'
}

export type LastExecutorRequest = {
  status: LastExecutorRequestStatusesOptions;
  sent_at: string;
  received_at: string;
};

export type OfficeEntity = {
  id?: number;
  name?: string;
  email?: string;
  city?: string;
  street?: string;
  postal_code?: string;
  post_office?: string;
  phone?: string;
  regon?: string;
  category?: string;
  categories?: { slug: string; name: string }[];
  district?: string;
  voivodeship?: string;
  can_send_email?: boolean;
  last_executor_request?: LastExecutorRequest;
  next_request_at?: string;
  executor_request_status?: number;
  updated_at?: string;
  created_at?: string;
};

export default class Office {
  readonly id?: number;
  readonly name?: string;
  readonly email?: string;
  readonly city?: string;
  readonly street?: string;
  readonly postal_code?: string;
  readonly post_office?: string;
  readonly phone?: string;
  readonly regon?: string;
  readonly category?: string;
  readonly categories?: { slug: string; name: string }[];
  readonly district?: string;
  readonly voivodeship?: string;
  readonly can_send_email?: boolean;
  readonly last_executor_request?: LastExecutorRequest;
  readonly next_request_at?: string;
  readonly executor_request_status?: number;
  readonly updated_at?: string;
  readonly created_at?: string;

  constructor(entity: OfficeEntity) {
    this.id = entity.id;
    this.name = entity.name;
    this.email = entity.email;
    this.city = entity.city;
    this.street = entity.street;
    this.postal_code = entity.postal_code;
    this.post_office = entity.post_office;
    this.phone = entity.phone;
    this.regon = entity.regon;
    this.category = entity.category;
    this.categories = entity.categories;
    this.district = entity.district;
    this.voivodeship = entity.voivodeship;
    this.can_send_email = entity.can_send_email;
    this.last_executor_request = entity.last_executor_request;
    this.next_request_at = entity.next_request_at;
    this.executor_request_status = entity.executor_request_status;
    this.updated_at = entity.updated_at;
    this.created_at = entity.created_at;
  }

  static getFilterableAttributes(): string[] {
    return [
      'id',
      'name',
      'street',
      'postal_code',
      'city',
      'post_office',
      'phone',
      'regon',
      'category',
      'district',
      'voivodeship',
      'email',
      'can_send_email',
      'executor_request_status',
      'created_at_from',
      'created_at_to'
    ];
  }

  static getSortableAttributes(): string[] {
    return [
      'id',
      'name',
      'street',
      'postal_code',
      'city',
      'post_office',
      'phone',
      'district',
      'voivodeship',
      'regon',
      'email'
    ];
  }
}

export const createOffice = (entity: OfficeEntity): Office => new Office(entity);

export const lastExecutorRequestStatus = (status: number) => {
  switch (status) {
    case LastExecutorRequestStatusesOptions.NOT_SEND:
      return LastExecutorRequestStatusesValues.NOT_SEND;
    case LastExecutorRequestStatusesOptions.RECEIVED:
      return LastExecutorRequestStatusesValues.RECEIVED;
    case LastExecutorRequestStatusesOptions.NOT_RECEIVED:
      return LastExecutorRequestStatusesValues.NOT_RECEIVED;
    default:
      return '';
  }
};

export const canSendEmailOptions = () => {
  return [
    { value: 1, label: 'Tak' },
    { value: 0, label: 'Nie' }
  ];
};

export const executorRequestStatusOptions = () => {
  return [
    { value: LastExecutorRequestStatusesOptions.NOT_RECEIVED, label: LastExecutorRequestStatusesValues.NOT_RECEIVED },
    { value: LastExecutorRequestStatusesOptions.RECEIVED, label: LastExecutorRequestStatusesValues.RECEIVED },
    { value: LastExecutorRequestStatusesOptions.NOT_SEND, label: LastExecutorRequestStatusesValues.NOT_SEND }
  ];
};
