import React, { Dispatch, SetStateAction } from 'react';
import { FormGroup } from 'reactstrap';
import { getError } from 'modules/Shared/helper/validation';
import CheckboxInput from 'modules/Layout/component/Input/Checkbox';
import { ValidationErrors } from 'modules/Shared/type';

export interface StepFieldContentProps<T> {
  contentKey: string;
  stepValues: T;
  setStepValues: Dispatch<SetStateAction<T>>;
  mapState: (values: T) => T;
  errors?: ValidationErrors | null;
  initContentValues?: Record<string, string>;
  getRevertValue?: () => string;
  isEditDisabled?: boolean;
}

const StepFieldContent = <T,>(props: StepFieldContentProps<T>): JSX.Element => {
  const { contentKey, stepValues, setStepValues, mapState, errors, initContentValues, getRevertValue, isEditDisabled } =
    props;

  return (
    <FormGroup key={contentKey}>
      <CheckboxInput
        id={contentKey}
        checked={true}
        // @ts-ignore
        content={stepValues[contentKey]}
        onChange={(_, content) => {
          // @ts-ignore
          stepValues[contentKey] = content;
          setStepValues((values) => mapState(values));
        }}
        onRevert={() => {
          // @ts-ignore
          stepValues[contentKey] = initContentValues[contentKey] || getRevertValue();
          setStepValues((values) => mapState(values));
        }}
        hideRevert={!initContentValues[contentKey] && !getRevertValue}
        disableCheckbox={true}
        disableEdit={isEditDisabled}
        error={getError(errors, `${contentKey}`)}
      />
    </FormGroup>
  );
};

export default StepFieldContent;
