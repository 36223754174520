import CardBox from 'modules/Layout/component/CardBox';
import { ROUTE_SUBSCRIPTIONS } from 'modules/Subscriptions/routes';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PageProps } from 'modules/Layout/type';
import { useDispatch } from 'react-redux';
import { managePageAction } from 'modules/Layout/action';
import '../ExecutorsAccessDenied/index.scss';

const ForeignProceedingsAccessDenied = () => {
  const dispatch = useDispatch();

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  useEffect(() => {
    managePage({
      title: 'Wyszukiwarka zagranicznych postępowań'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex justify-content-center executors-access-denied">
      <CardBox
        heading="Brak dostępu"
        paddingLeft="30px"
        paddingRight="30px"
        paddingTop="25px"
        paddingBottom="40px"
        childrenPaddingTop="144px"
      >
        <div className="medium-margins">
          <div className="denied-title font-poppins">
            Aby korzystać z wyszukiwarki zagranicznych postępowań
            <br />
            musisz mieć wykupiony abonament.
          </div>
        </div>
        <div className="big-margins">
          <div className="d-flex">
            <Link to={ROUTE_SUBSCRIPTIONS} className="btn btn-primary buy-subscription w-100">
              Zakup subskrypcji
            </Link>
          </div>
        </div>
      </CardBox>
    </div>
  );
};

export default ForeignProceedingsAccessDenied;
