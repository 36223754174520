import Tooltip from 'modules/Layout/component/Tooltip';
import React from 'react';
import { Button } from 'reactstrap';

type ConfigObj = {
  tooltipContent: string;
  onDeleteClick: () => void;
  targetIdName: string;
  value: string;
};

const actionDeleteButtonDetails = (row: Partial<{ is_deletable?: boolean; id: number }>, configObj: ConfigObj) => {
  const isDeletable = row?.is_deletable === undefined ? true : row.is_deletable;

  const trashIcon = (
    <>
      {!isDeletable && (
        <Tooltip
          targetId={`${configObj.targetIdName}${row.id}`}
          tooltipContent={configObj.tooltipContent}
          tooltipPlacement="left"
        />
      )}
    </>
  );

  return (
    <>
      <Button
        color="link"
        className="p-0 link-with-border"
        onClick={isDeletable ? configObj.onDeleteClick : () => null}
        id={`${configObj.targetIdName}${row.id}`}
      >
        {configObj.value}
      </Button>
      {trashIcon}
    </>
  );
};

export default actionDeleteButtonDetails;
