import React, { useEffect } from 'react';
import { managePageAction } from 'modules/Layout/action';
import { PageProps } from 'modules/Layout/type';
import { breadcrumbRouteProfilePaymentLog } from 'modules/Profile/breadcrumbs';
import { useDispatch, useSelector } from 'react-redux';
import usePaymentLog from 'modules/Shared/components/PaymentLog/usePaymentLog';
import FetchingWrapper from 'modules/Shared/helper/FetchingWrapper';
import { RootState } from 'app/reducer';
import { useParams } from 'react-router';
import DetailsComponent from 'modules/Shared/components/PaymentLog/Details';
import { ROUTE_PROFILE } from 'modules/Profile/routes';

const PaymentLogsDetails = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const { id } = useParams<{ id: string }>();
  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  const { message, fetching, paymentLog } = usePaymentLog(user?.id, id);

  useEffect(() => {
    if (paymentLog) {
      const { payment_number } = paymentLog;
      const title = `Szczegóły transakcji${!!payment_number && ` - ${payment_number}`}`;
      managePage({
        title,
        breadcrumb: breadcrumbRouteProfilePaymentLog(payment_number)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentLog]);

  return (
    <FetchingWrapper fetching={fetching} message={message} data={paymentLog}>
      <DetailsComponent paymentLog={paymentLog} redirectUrl={ROUTE_PROFILE} />
    </FetchingWrapper>
  );
};

export default PaymentLogsDetails;
