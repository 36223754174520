import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { JUDGMENT_KEYWORDS_DELETE } from 'modules/Judgement/permissions';
import Authorize from 'modules/Auth/container/Authorize';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_JUDGEMENT_INCLUDED_KEYWORD } from 'modules/Judgement/routes';
import Judgement from 'modules/Judgement/model/Judgement';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import EditIcon from 'modules/Layout/component/Icon/Edit';

type Props = {
  keywordsIncluded: JudgementElementEntity[];
  onDeleteClick: (keywordIncluded: JudgementElementEntity) => void;
  setShowContent?: (keywordId: number) => void;
  judgement?: Judgement;
};

const KeywordsIncludedTable: React.FC<Props> = ({ keywordsIncluded, onDeleteClick, setShowContent, judgement }) => {
  const dispatch = useDispatch();

  const onDeleteClicked = (e: any, row: JudgementElementEntity) => {
    e?.stopPropagation?.();
    onDeleteClick(row);
  };

  const showContent = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: number) => {
    e.preventDefault();
    setShowContent(id);
  };

  const onItemClicked = (value: string, id: number) => (
    // eslint-disable-next-line
    <a className="fill-table-td" href="#" onClick={(e) => showContent(e, id)}>
      {value}
    </a>
  );

  const cols: TableCol<JudgementElementEntity>[] = [
    {
      property: 'name',
      label: 'Nazwa',
      classname: 'col-11',
      value: (row) => onItemClicked(row.name, row.id)
    },
    {
      property: 'actions',
      label: 'Akcje',
      classname: 'col-1',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            {judgement && (
              <Authorize permissions={[JUDGMENT_KEYWORDS_DELETE]}>
                <ActionUpdate
                  className="mx-1"
                  title="Edytuj słowo kluczowe"
                  label={<EditIcon height="20px" />}
                  onClick={() =>
                    dispatch(
                      push({
                        pathname: getPathUrl(ROUTE_JUDGEMENT_INCLUDED_KEYWORD, { id: judgement.id }),
                        state: { judgement, keyword: row.id, prevContent: row.content }
                      })
                    )
                  }
                />
              </Authorize>
            )}
            {onDeleteClick && (
              <Authorize permissions={[JUDGMENT_KEYWORDS_DELETE]}>
                <ActionDelete
                  className="mx-1"
                  title="Usuń słowo kluczowe"
                  onClick={((e: any) => onDeleteClicked(e, row)) as any}
                />
              </Authorize>
            )}
          </div>
        );
      }
    }
  ];

  return <Table cols={cols} rows={keywordsIncluded} />;
};

export default KeywordsIncludedTable;
