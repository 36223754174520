import { SET_ORDER_LIST_PARAMS, ORDER_LIST, OrderListAction } from 'modules/ExecutorOrder/action/list';
import { createOrder } from 'modules/ExecutorOrder/model/Order';
import { initOrderListState, OrderListState } from 'modules/ExecutorOrder/state/list';

const initState = initOrderListState();

const reducer = (state: OrderListState = initState, action: OrderListAction): OrderListState => {
  switch (action.type) {
    case SET_ORDER_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case ORDER_LIST: {
      const { orders = [], meta } = action.payload;

      return { ...state, fetching: false, orders: orders.map(createOrder), meta };
    }

    default:
      return state;
  }
};

export default reducer;
