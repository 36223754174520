import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import Order, { createOrder } from 'modules/ExecutorOrder/model/Order';
import {
  addOrderFile,
  deleteOrder,
  deleteOrderFile,
  fetchOrder,
  fetchOrderFile
} from 'modules/ExecutorOrder/repository';
import DetailsComponent from 'modules/ExecutorOrder/component/Details';
import { breadcrumbRouteOrderDetails } from 'modules/ExecutorOrder/breadcrumbs';
import {
  deleteOrderToastSuccess,
  orderFileAddToastSuccess,
  orderFileDeletedToastSuccess,
  orderFileDownloadToastSuccess
} from 'modules/ExecutorOrder/toasts';
import fetchFileRequest from 'modules/Shared/helper/APIRequests/fetchFileRequest';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import { push } from 'connected-react-router';
import { ROUTE_EXECUTOR_ADMINS } from 'modules/Executor/routes';
import { getPathUrl } from 'modules/Shared/helper/api';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import { fetchAdminExecutor } from 'modules/Executor/repository';
import serverErrorToast from 'modules/Shared/action/errorToast';
import { IFile } from '../../../Shared/helper/Files/AddFiles';

const Details = () => {
  const dispatch = useDispatch();
  const { executor_id, id } = useParams<{ executor_id: string; id: string }>();
  const { state } = useLocation<{ executorName: string }>();
  const [order, setOrder] = useState<Order>(null);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);

  const [displayOrderDeleteModal, setDisplayOrderDeleteModal] = useState(false);
  const [displayFileDeleteModal, setDisplayFileDeleteModal] = useState<{
    file: IFile;
    sectionId: string | number;
  } | null>(null);
  const [loading, setLoading] = useState(false);

  const dispatchErrorMessageToast = (errMessage: string) =>
    dispatch(addToastAction(serverErrorToast(errMessage, 'Błąd zarządzania plikami')));

  const dispatchDeleteOrderToast = () => dispatch(addToastAction(deleteOrderToastSuccess()));

  const dispatchOrderFileDownloadToast = () => dispatch(addToastAction(orderFileDownloadToastSuccess()));

  const dispatchOrderFileDeleteToast = () => dispatch(addToastAction(orderFileDeletedToastSuccess()));

  const dispatchOrderFileAddToast = () => dispatch(addToastAction(orderFileAddToastSuccess()));

  const cancelToken = useCancelToken();
  const fetchOrderRequest = async () => {
    setFetching(true);
    const { data, cancelled, message: messageResponse } = await fetchRequest(fetchOrder, executor_id, id, cancelToken);

    if (cancelled) return;
    if (data) {
      setOrder(createOrder(data.data));
    }
    if (messageResponse) setMessage(messageResponse);
    setFetching(false);
  };

  useEffect(() => {
    fetchOrderRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const managePage = (executorName: string) => {
    const title = `Postępowanie${order.name ? ` - ${order.name}` : ''}`;
    dispatch(
      managePageAction({
        title,
        breadcrumb: breadcrumbRouteOrderDetails(executor_id, order.name, executorName)
      })
    );
  };

  useEffect(() => {
    if (order) {
      if (state?.executorName) {
        managePage(state.executorName);
      } else {
        (async () => {
          const { data, cancelled } = await fetchRequest(fetchAdminExecutor, executor_id, cancelToken);
          if (cancelled) return;
          if (data) {
            managePage(data.data.name || data.data.nip);
          }
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const deleteOrderAction = async () => {
    setLoading(true);

    if (message) setMessage(null);

    const {
      cancelled,
      message: messageRes,
      success
    } = await deleteRequest(deleteOrder, executor_id, order.id, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteOrderToast();
      dispatch(push(getPathUrl(ROUTE_EXECUTOR_ADMINS, { id: executor_id })));
    } else {
      if (messageRes) {
        setMessage(messageRes);
        dispatchErrorMessageToast(messageRes.value);
      }
      setLoading(false);
    }
  };

  const deleteOrderFileAction = async (file_id: number | string, section_id: number | string) => {
    if (loadingFile) return;
    setLoadingFile(true);

    if (message) setMessage(null);

    const {
      cancelled,
      message: messageRes,
      data: resDeleteData
    } = await updateRequestWithData(deleteOrderFile, section_id, file_id, cancelToken);

    if (cancelled) return;
    if (resDeleteData) {
      dispatchOrderFileDeleteToast();
      setDisplayFileDeleteModal(null);
      setOrder(resDeleteData.data);
    }
    if (messageRes) {
      setMessage(messageRes);
      dispatchErrorMessageToast(messageRes.value);
    }
    setLoadingFile(false);
  };

  const orderDownloadFileRequest = async (fileId: number | string, section_id: number | string) => {
    if (loadingFile) return;

    if (message) setMessage(null);

    setLoadingFile(true);
    const {
      cancelled,
      message: messageRes,
      success
    } = await fetchFileRequest(fetchOrderFile, section_id, fileId, cancelToken);

    if (cancelled) return;
    if (success) dispatchOrderFileDownloadToast();
    if (messageRes) {
      dispatchErrorMessageToast(messageRes.value);
    }
    setLoadingFile(false);
  };

  const orderAddFileRequest = async (
    section_id: number | string,
    data: FormData,
    changeLoading: (curr: boolean) => void,
    setProgress: (value: React.SetStateAction<number>) => void
  ) => {
    changeLoading(true);

    const onUploadProgress = (progressEvent: any) => {
      const { loaded, total } = progressEvent;
      if (typeof loaded === 'number' && typeof total === 'number') setProgress(Math.round((100 * loaded) / total));
    };

    const {
      cancelled,
      message: messageRes,
      data: resAddData
    } = await updateRequestWithData(addOrderFile, section_id, data, onUploadProgress, cancelToken);

    if (cancelled) return;
    if (resAddData) {
      dispatchOrderFileAddToast();
      setOrder(resAddData.data);
    }
    if (messageRes) {
      dispatchErrorMessageToast(messageRes.value);
    }
    changeLoading(false);
    setProgress(null);
  };

  const resetMessage = () => setMessage(null);
  const redirectUrl = getPathUrl(ROUTE_EXECUTOR_ADMINS, { id: executor_id });

  if (fetching) return <Loader />;
  if (!fetching && !order && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  if (!fetching && order) {
    return (
      <div className="row user-details-view position-relative">
        {displayOrderDeleteModal && (
          <GenericModalDelete
            value={order}
            title="Usunięcie postępowania"
            content={(value) => `Czy na pewno chcesz usunąć postępowanie${value?.name ? ` - ${value?.name}` : ''}?`}
            onDeleteClick={() => deleteOrderAction()}
            isOpen={displayOrderDeleteModal}
            toggle={() => setDisplayOrderDeleteModal(null)}
            loading={loading}
            message={message}
            resetMessage={resetMessage}
          />
        )}
        {displayFileDeleteModal && (
          <GenericModalDelete
            value={displayFileDeleteModal.file}
            title="Usunięcie pliku"
            content={(value) => (
              <div className="modal-body-title">
                Czy na pewno chcesz usunąć plik - <span className="h5">{value?.name}?</span>
              </div>
            )}
            onDeleteClick={() =>
              deleteOrderFileAction(displayFileDeleteModal.file.id, displayFileDeleteModal.sectionId)
            }
            isOpen={!!displayFileDeleteModal}
            toggle={() => setDisplayFileDeleteModal(null)}
            loading={loading}
            message={message}
            resetMessage={resetMessage}
          />
        )}
        <DetailsComponent
          order={order}
          executor_id={executor_id}
          orderDownloadFileRequest={orderDownloadFileRequest}
          onDeleteOrder={() => setDisplayOrderDeleteModal(true)}
          onDeleteFile={(file: IFile, sectionId: string | number) => setDisplayFileDeleteModal({ file, sectionId })}
          onAddFilesRequest={orderAddFileRequest}
          loadingFile={loadingFile}
          redirectUrl={redirectUrl}
        />
      </div>
    );
  }

  return null;
};

export default Details;
