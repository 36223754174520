import BaseModal, { Props } from 'modules/Layout/component/Modal';
import React, { useState } from 'react';
import { Button, FormGroup } from 'reactstrap';
import Select from 'modules/Layout/component/Input/Select';
import { ExecutorViolationSlug, executorViolationsSlugSelectValues } from '../../../../Executor/model/Violation';

interface IProps extends Props {
  title: string;
  onSlugSubmit: (slug: string) => void;
}

const ModalAddExecutorViolation: React.FC<IProps> = (props) => {
  const { title, onSlugSubmit, toggle } = props;

  const [slug, setSlug] = useState<ExecutorViolationSlug>(null);

  const displayBody = () => {
    return (
      <>
        <div className="modal-body-title">{title}</div>
        <div className="modal-body-content">
          <FormGroup>
            <Select
              name="slug"
              id="slug"
              value={executorViolationsSlugSelectValues.find((option) => option.value === slug)}
              onChange={(event: { value: ExecutorViolationSlug; label: string }) => {
                setSlug(event.value);
              }}
              required
              options={[
                {
                  value: '',
                  label: ''
                },
                ...executorViolationsSlugSelectValues
              ]}
            />
          </FormGroup>
        </div>
      </>
    );
  };

  const displayFooter = () => {
    return (
      <div className="modal-actions">
        <Button
          type="submit"
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light"
          onClick={() => onSlugSubmit(slug)}
        >
          Dodaj
        </Button>
        <Button type="button" className="cancel waves-effect waves-light" onClick={toggle}>
          Anuluj
        </Button>
      </div>
    );
  };

  return <BaseModal {...props} title={title} body={displayBody()} footer={displayFooter()} />;
};

export default ModalAddExecutorViolation;
