import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function ContactIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 21 15.782">
      <g id="Group_319" data-name="Group 319" transform="translate(-383 -2331)">
        <path
          id="Path_1254"
          data-name="Path 1254"
          d="M393.715,2340.91a1.894,1.894,0,0,1-1.269-.48l-6.893-6.2a.5.5,0,0,1,.669-.744l6.893,6.2a.919.919,0,0,0,1.2,0l6.893-6.2a.5.5,0,0,1,.669.744l-6.893,6.2A1.894,1.894,0,0,1,393.715,2340.91Z"
          transform="translate(-0.311 -0.308)"
          fill={fill}
        />
        <path
          id="Line_12"
          data-name="Line 12"
          d="M4.617,4.341A.5.5,0,0,1,4.3,4.225L-.32.384a.5.5,0,0,1-.065-.7.5.5,0,0,1,.7-.065L4.937,3.457a.5.5,0,0,1-.32.884Z"
          transform="translate(396.614 2340.392)"
          fill={fill}
        />
        <path
          id="Line_13"
          data-name="Line 13"
          d="M0,4.341a.5.5,0,0,1-.385-.18.5.5,0,0,1,.065-.7L4.3-.384A.5.5,0,0,1,5-.32a.5.5,0,0,1-.065.7L.32,4.225A.5.5,0,0,1,0,4.341Z"
          transform="translate(385.576 2340.392)"
          fill={fill}
        />
        <path
          id="Path_1255"
          data-name="Path 1255"
          d="M385.536,2331h15.92a2.553,2.553,0,0,1,1.8.727,2.454,2.454,0,0,1,.742,1.765v10.8a2.45,2.45,0,0,1-.742,1.764,2.556,2.556,0,0,1-1.8.725h-15.92a2.545,2.545,0,0,1-1.8-.726,2.455,2.455,0,0,1-.738-1.763v-10.8a2.459,2.459,0,0,1,.738-1.764A2.543,2.543,0,0,1,385.536,2331Zm15.92,14.782a1.5,1.5,0,0,0,1.543-1.49v-10.8a1.5,1.5,0,0,0-1.543-1.492h-15.92a1.5,1.5,0,0,0-1.537,1.492v10.8a1.5,1.5,0,0,0,1.537,1.49Z"
          transform="translate(0)"
          fill={fill}
        />
      </g>
    </svg>
  );
}
