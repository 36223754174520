import React from 'react';
import { Input } from 'reactstrap';
import BasicChip from 'modules/Layout/component/Chips/Basic';
import './inputWithChips.scss';

type Props = {
  value: string;
  onChange: (value: string) => void;
  chipContentElements?: { content: string; id: number }[];
  onDeleteChipElement?: (id: number) => void;
  required?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  onFocus?: () => void;
  placeholder?: string;
};

const InputWithChips: React.FC<Props> = ({
  required = false,
  invalid = false,
  disabled = false,
  placeholder,
  value,
  onChange,
  chipContentElements = [],
  onDeleteChipElement,
  onFocus
}) => {
  const inputRef = React.useRef<HTMLInputElement>();

  const onWrapperClick = () => (inputRef.current ? inputRef.current.focus() : null);

  return (
    // eslint-disable-next-line
    <div
      className="form-control with-chips-wrapper"
      id={chipContentElements.length ? 'override-chip-inside-control' : 'override-chip-form-control'}
      onClick={onWrapperClick}
    >
      {chipContentElements.map((el) => (
        <BasicChip key={el.id} chipContentElement={el} onDeleteChipClicked={onDeleteChipElement} />
      ))}
      <div className="input-wrapper">
        <Input
          autoComplete="off"
          className="input-chip"
          id="overwrite-form-control"
          innerRef={inputRef}
          type="text"
          value={value}
          onChange={(event) => onChange(event.target.value)}
          maxLength={250}
          invalid={invalid}
          required={required}
          onFocus={onFocus}
          disabled={disabled}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default InputWithChips;
