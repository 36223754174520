import React, { ChangeEvent, useState } from 'react';
import { addToastAction } from 'modules/Layout/action';
import { useDispatch, useSelector } from 'react-redux';
import {
  AGREEMENT_MODE_CONSTRUCTION_WORKS,
  AGREEMENT_MODE_DELIVERIES,
  AGREEMENT_MODE_ORDER_FORM,
  AGREEMENT_MODE_SERVICES,
  AGREEMENT_TYPE_OUTSIDE_ORDER,
  AGREEMENT_TYPE_PROJECT,
  AGREEMENT_TYPE_SUPPLEMENT,
  AgreementMode,
  AgreementStepProps,
  AgreementType
} from 'modules/Agreements/type';
import { Button, CustomInput, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { getAgreementModeName, getAgreementTypeName } from 'modules/Agreements/helper/agreement';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import PreamblePublishedProceedingAutocomplete from 'modules/Agreements/container/PreambleAutocompleteInputs/PublishedProceedings';
import PreambleAgreementsAutocomplete from 'modules/Agreements/container/PreambleAutocompleteInputs/Agreements';
import Loader from 'modules/Layout/component/Loader';
import { fetchProceedingsDetails } from 'modules/Proceedings/repository';
import Proceedings, { ProceedingsEntity } from 'modules/Proceedings/model/Proceedings';
import { AgreementEntity } from 'modules/Agreements/model/Agreements';
import Part, { PartEntity } from 'modules/Agreements/model/Part';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import TrashIcon from 'modules/Layout/component/Icon/Trash';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { deleteAgreementPart } from 'modules/Agreements/repository';
import { deleteAgreementPartToastError, deleteAgreementPartToastSuccess } from 'modules/Agreements/toasts';
import Authorize from '../../../../../Auth/container/Authorize';
import { IMPORT_ORDERS_ACCESS } from '../../../../../Auth/container/Guard/permissions';
import { RootState } from '../../../../../../app/reducer';

export interface AgreementStepTypeValues {
  few_parts: boolean | null;
  mode: string | null;
  parts_items: Part[] | null;
  type: string | null;
  proceeding_id: number | null;
  agreement_id: number | null;
  name: string | null;
}

export interface AgreementStepTypeAdditionalValues {
  proceeding_name: string | null;
  agreement_name: string | null;
  perform_import: boolean | null;
}

const AgreementStepType = (props: AgreementStepProps): JSX.Element => {
  const { agreement, onSubmit, proceeding, errors, initFetch } = props;
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const [stepValues, setStepValues] = useState<AgreementStepTypeValues>({
    few_parts: agreement ? agreement.few_parts : null,
    mode: agreement?.mode || null,
    parts_items: agreement?.parts_ids || [new Part({ id: null, number: 1, name: '' })],
    type: agreement?.type || null,
    proceeding_id: agreement?.proceeding_id || null,
    agreement_id: null,
    name: agreement?.name || ''
  });
  const [additionalValues, setAdditionalValues] = useState<AgreementStepTypeAdditionalValues>({
    proceeding_name: null,
    agreement_name: null,
    perform_import: null
  });
  const [fetchingProceeding, setFetchingProceeding] = useState(false);
  const [selectedProceeding, setSelectedProceeding] = useState<ProceedingsEntity>(null);
  const [selectedAgreement, setSelectedAgreement] = useState<AgreementEntity>(null);
  const { few_parts, mode, type, parts_items, proceeding_id, agreement_id } = stepValues;
  const { proceeding_name, agreement_name, perform_import } = additionalValues;

  const setName = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setStepValues((values) => ({
      ...values,
      name: event.target.value
    }));
  };

  const setMode = (event: ChangeEvent<HTMLInputElement>, newMode: AgreementMode) => {
    event.persist();
    setStepValues((values) => ({
      ...values,
      mode: event.target.checked ? newMode : null,
      few_parts: null
    }));
  };

  const setType = (event: ChangeEvent<HTMLInputElement>, newType: AgreementType) => {
    event.persist();
    setStepValues((values) => ({
      ...values,
      type: event.target.checked ? newType : null,
      mode: null,
      few_parts: null,
      proceeding_id: null
    }));
    setAdditionalValues((values) => ({
      ...values,
      perform_import: null,
      proceeding_name: null
    }));
    setSelectedProceeding(null);
  };

  const setProceeding = async (newProceeding: { name: string; id: number }) => {
    const { id, name } = newProceeding;

    const createPartItems = (amount: number) => {
      const result = [];
      let index = amount;

      while (index > 0) {
        result.push(new Part({ id: null, number: amount - index + 1, name: '' }));
        index--;
      }

      return result;
    };

    if (id) {
      setFetchingProceeding(true);
      const {
        data: { data }
      } = await fetchProceedingsDetails(id);
      setSelectedProceeding(data);
      setAdditionalValues((values) => ({
        ...values,
        proceeding_name: name
      }));
      setStepValues((values) => ({
        ...values,
        proceeding_id: id,
        mode: Proceedings.getAgreementMode(data.type),
        parts_items: createPartItems(data?.parts),
        few_parts: Number(data?.parts) > 1
      }));
      setFetchingProceeding(false);
    } else {
      setSelectedProceeding(null);
      setAdditionalValues((values) => ({
        ...values,
        proceeding_name: null
      }));
      setStepValues((values) => ({
        ...values,
        proceeding_id: null,
        mode: null,
        parts_items: [new Part({ id: null, number: 1, name: '' })],
        few_parts: null
      }));
    }
  };

  const setAgreement = async (newAgreement: any) => {
    const { id, name } = newAgreement;

    if (id) {
      setFetchingProceeding(true);
      setSelectedAgreement(newAgreement);
      setAdditionalValues((values) => ({
        ...values,
        agreement_name: name
      }));
      setStepValues((values) => ({
        ...values,
        agreement_id: newAgreement.id,
        mode: newAgreement.mode,
        parts_items: newAgreement.parts_ids.map((part: PartEntity) => new Part(part)) || [
          new Part({ id: null, number: 1, name: '' })
        ],
        few_parts: Number(newAgreement.parts) > 1
      }));
      setFetchingProceeding(false);
    } else {
      setSelectedAgreement(null);
      setAdditionalValues((values) => ({
        ...values,
        agreement_name: null
      }));
      setStepValues((values) => ({
        ...values,
        agreement_id: null,
        mode: null,
        parts_items: [new Part({ id: null, number: 1, name: '' })],
        few_parts: null
      }));
    }
  };

  const setImport = (checked: boolean) => {
    setAdditionalValues((values) => ({
      ...values,
      perform_import: checked,
      proceeding_name: null,
      agreement_name: null
    }));
    setStepValues((values) => ({
      ...values,
      proceeding_id: null,
      agreement_id: null,
      mode: null,
      parts_items: [new Part({ id: null, number: 1, name: '' })],
      few_parts: null
    }));
    setSelectedProceeding(null);
    setSelectedAgreement(null);
  };

  const addPartItem = () => {
    const nextNumber =
      stepValues.parts_items
        .map((item) => item.number)
        .sort((a, b) => a - b)
        .reverse()[0] + 1 || 1;

    setStepValues((values) => ({
      ...values,
      parts_items: [...values.parts_items, new Part({ id: null, number: nextNumber, name: null })]
    }));
  };

  const deletePartItem = async (partItemIndex: number, partItem: Part) => {
    if (partItem.id) {
      try {
        await deleteAgreementPart(agreement.id, partItem.id);
        dispatch(addToastAction(deleteAgreementPartToastSuccess()));
        initFetch();
      } catch (error) {
        dispatch(addToastAction(deleteAgreementPartToastError()));
      }
    }

    setStepValues((values) => ({
      ...values,
      parts_items: values.parts_items.filter((_, index) => index !== partItemIndex)
    }));
  };

  const renderTasks = () => {
    return (
      <div className="pl-4 mt-3">
        <FormGroup>
          <CustomInput
            id="few_parts_yes"
            type="checkbox"
            name="few_parts_yes"
            label="Umowa dotyczy zamówienia niepodzielonego na zadania"
            checked={few_parts === false}
            onChange={(event) => {
              const { checked } = event.currentTarget;

              setStepValues((values) => ({
                ...values,
                few_parts: checked ? false : null
              }));
            }}
            required={few_parts === null}
            disabled={Boolean(agreement) || Boolean(selectedProceeding?.parts) || Boolean(selectedAgreement?.parts)}
          />
        </FormGroup>
        <FormGroup>
          <CustomInput
            id="few_parts_no"
            type="checkbox"
            name="few_parts_no"
            label="Umowa dotyczy kilku zadań"
            checked={few_parts === true}
            onChange={(event) => {
              const { checked } = event.currentTarget;

              setStepValues((values) => ({
                ...values,
                few_parts: checked ? true : null
              }));
            }}
            required={few_parts === null}
            disabled={Boolean(agreement) || Boolean(selectedProceeding?.parts) || Boolean(selectedAgreement?.parts)}
          />
        </FormGroup>
        {few_parts && renderTasksItems()}
      </div>
    );
  };

  const renderTasksItems = () => {
    return (
      <div className="pl-4 mt-3">
        {parts_items.map((partItem, partItemIndex) => {
          return (
            <div key={`part_item_wrapper_${partItemIndex}`}>
              <div className="d-flex flex-row align-items-center mb-2">
                <Label className="mr-2 mb-0">Zadanie</Label>
                <Input
                  id={`part_item_number_${partItemIndex}`}
                  type="number"
                  min="1"
                  max="99999"
                  placeholder="Numer"
                  style={{ maxWidth: 100 }}
                  value={partItem.number || ''}
                  required
                  onChange={(event) => {
                    event.persist();
                    setStepValues((values) => ({
                      ...values,
                      parts_items: values.parts_items.map((item, index) => {
                        if (index === partItemIndex) {
                          return {
                            ...item,
                            number: parseInt(event.target.value, 10)
                          } as unknown as Part;
                        }

                        return item;
                      })
                    }));
                  }}
                />
                <Input
                  className="ml-2"
                  id={`part_item_name_${partItemIndex}`}
                  type="text"
                  placeholder="Nazwa"
                  style={{ maxWidth: 400 }}
                  value={partItem.name || ''}
                  onChange={(event) => {
                    event.persist();
                    setStepValues((values) => ({
                      ...values,
                      parts_items: values.parts_items.map((item, index) => {
                        if (index === partItemIndex) {
                          return {
                            ...item,
                            name: event.target.value
                          } as unknown as Part;
                        }

                        return item;
                      })
                    }));
                  }}
                />
                <ActionDelete
                  className="ml-2"
                  title="Usuń zadanie"
                  label={<TrashIcon height="30px" />}
                  onClick={() => deletePartItem(partItemIndex, partItem)}
                />
              </div>
              {(hasError(errors, `parts_items.${partItemIndex}.number`) ||
                hasError(errors, `parts_items.${partItemIndex}.name`)) && (
                <FormFeedback className="d-block mb-2">
                  {getError(errors, `parts_items.${partItemIndex}.number`) ||
                    getError(errors, `parts_items.${partItemIndex}.name`)}
                </FormFeedback>
              )}
            </div>
          );
        })}
        {hasError(errors, 'parts_items') && (
          <FormFeedback className="d-block mb-2">{getError(errors, 'parts_items')}</FormFeedback>
        )}
        <Button type="button" color="primary" onClick={addPartItem}>
          Dodaj kolejne zadanie
        </Button>
      </div>
    );
  };

  const renderMode = (forMode: AgreementMode) => {
    return (
      <div className="pl-4">
        <FormGroup>
          <CustomInput
            id={forMode}
            type="checkbox"
            name={forMode}
            label={getAgreementModeName(forMode)}
            checked={mode === forMode}
            onChange={(event) => setMode(event, forMode)}
            required={mode === null}
            disabled={
              Boolean(agreement) ||
              Boolean(Proceedings.getAgreementMode(selectedProceeding?.type)) ||
              Boolean(selectedAgreement?.type)
            }
          />
          {mode === forMode && forMode !== AGREEMENT_MODE_ORDER_FORM && renderTasks()}
        </FormGroup>
      </div>
    );
  };

  const renderModes = () => {
    return (
      <>
        {renderMode(AGREEMENT_MODE_CONSTRUCTION_WORKS)}
        {renderMode(AGREEMENT_MODE_DELIVERIES)}
        {renderMode(AGREEMENT_MODE_SERVICES)}
      </>
    );
  };

  const renderImport = () => {
    return (
      <FormGroup className={!agreement && user.hasPermissions([IMPORT_ORDERS_ACCESS]) ? 'pl-4' : ''}>
        {!agreement && (
          <>
            <Authorize permissions={[IMPORT_ORDERS_ACCESS]}>
              <FormGroup>
                <CustomInput
                  id="perform_import_yes"
                  label={type === AGREEMENT_TYPE_SUPPLEMENT ? 'Zaimportuj umowę' : 'Zaimportuj postępowanie'}
                  type="radio"
                  required={perform_import == null}
                  checked={perform_import === true}
                  onChange={() => setImport(true)}
                />
              </FormGroup>
              {(Boolean(perform_import) || (Boolean(agreement) && proceeding_id != null)) && (
                <FormGroup className="position-relative pl-4">
                  {type === AGREEMENT_TYPE_SUPPLEMENT ? (
                    <>
                      <Label>Umowa:</Label>
                      <PreambleAgreementsAutocomplete
                        agreementValue={{ id: agreement_id || null, name: agreement_name || '' }}
                        onAgreementChange={setAgreement}
                        required
                      />
                    </>
                  ) : (
                    <>
                      <Label>Postępowanie:</Label>
                      <PreamblePublishedProceedingAutocomplete
                        proceedingValue={{ id: proceeding_id || null, name: proceeding_name || '' }}
                        onProceedingChange={setProceeding}
                        required
                        mode={type}
                      />
                    </>
                  )}
                  {fetchingProceeding && <Loader />}
                </FormGroup>
              )}
              {perform_import === true && (proceeding_id != null || agreement_id != null) && (
                <div className="pl-4">{renderModes()}</div>
              )}
            </Authorize>
            <Authorize permissions={[IMPORT_ORDERS_ACCESS]}>
              <FormGroup>
                <CustomInput
                  id="perform_import_no"
                  label={
                    type === AGREEMENT_TYPE_SUPPLEMENT
                      ? 'Kontynuuj bez importowania umowy'
                      : 'Kontynuuj bez importowania postępowania'
                  }
                  type="radio"
                  required={perform_import == null}
                  checked={perform_import === false}
                  onChange={() => setImport(false)}
                />
              </FormGroup>
            </Authorize>
          </>
        )}
        {(Boolean(agreement) || perform_import === false || !user.hasPermissions([IMPORT_ORDERS_ACCESS])) &&
          renderModes()}
      </FormGroup>
    );
  };

  return (
    <div className="agreement-step-type">
      <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
        {Boolean(proceeding) && (
          <FormGroup>
            <Label className="text-dark font-20">{`Umowa do postępowania: ${proceeding.name}`}</Label>
          </FormGroup>
        )}
        <FormGroup>
          <Label for="proceeding_name">Robocza nazwa umowy</Label>
          <Input id="proceeding_name" name="proceeding_name" value={stepValues.name} onChange={setName} />
        </FormGroup>
        <FormGroup>
          <CustomInput
            id={`type_project_${AGREEMENT_TYPE_PROJECT}`}
            type="checkbox"
            name={`type_project_${AGREEMENT_TYPE_PROJECT}`}
            label={getAgreementTypeName(AGREEMENT_TYPE_PROJECT)}
            checked={type === AGREEMENT_TYPE_PROJECT}
            onChange={(event) => setType(event, AGREEMENT_TYPE_PROJECT)}
            required={type === null}
            disabled={Boolean(agreement)}
          />
        </FormGroup>
        {type === AGREEMENT_TYPE_PROJECT && renderImport()}
        <FormGroup>
          <CustomInput
            id={`type_project_${AGREEMENT_TYPE_OUTSIDE_ORDER}`}
            type="checkbox"
            name={`type_project_${AGREEMENT_TYPE_OUTSIDE_ORDER}`}
            label={getAgreementTypeName(AGREEMENT_TYPE_OUTSIDE_ORDER)}
            checked={type === AGREEMENT_TYPE_OUTSIDE_ORDER && mode !== AGREEMENT_MODE_ORDER_FORM}
            onChange={(event) => setType(event, AGREEMENT_TYPE_OUTSIDE_ORDER)}
            required={type === null}
            disabled={Boolean(agreement)}
          />
        </FormGroup>
        {type === AGREEMENT_TYPE_OUTSIDE_ORDER && renderImport()}
        {
          <FormGroup>
            <CustomInput
              id={AGREEMENT_MODE_ORDER_FORM}
              type="checkbox"
              name={AGREEMENT_MODE_ORDER_FORM}
              label={getAgreementModeName(AGREEMENT_MODE_ORDER_FORM)}
              checked={mode === AGREEMENT_MODE_ORDER_FORM}
              onChange={(event) => {
                setType(event, AGREEMENT_TYPE_OUTSIDE_ORDER);
                setMode(event, AGREEMENT_MODE_ORDER_FORM);
              }}
              required={mode === null}
              disabled={
                Boolean(agreement) ||
                Boolean(Proceedings.getAgreementMode(selectedProceeding?.type)) ||
                Boolean(selectedAgreement?.type)
              }
            />
          </FormGroup>
        }
      </AgreementFormWrapper>
    </div>
  );
};

export default AgreementStepType;
