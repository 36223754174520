import React, { useState } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { createSearchingProps } from 'modules/Shared/helper/params';
import UserFilter from 'modules/User/container/Filter/User';
import UserPagination from 'modules/User/container/Pagination/User';
import UserPerPage from 'modules/User/container/PerPage/User';
import UserTable from 'modules/User/container/Table/User';
import User from 'modules/User/model/User';
import { ROUTE_USERS } from 'modules/User/routes';
import { useDispatch, useSelector } from 'react-redux';
import { addToastAction } from 'modules/Layout/action';
import { Message } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { deleteUserToastSuccess } from 'modules/User/toasts';
import { logoutAction } from 'modules/Auth/action';
import { setUserListParamsAction } from 'modules/User/action/list';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import { deleteUser } from 'modules/User/repository';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';

type Props = {
  isAdminList?: boolean;
};

const UsersList: React.FC<Props> = ({ isAdminList }) => {
  const dispatch = useDispatch();
  const { id: current_user_id } = useSelector((state: RootState) => state.auth.user);
  const { fetching, meta } = useSelector((state: RootState) => state.user.list);
  const [displayModal, setDisplayModal] = useState<User>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);

  const searchProps = createSearchingProps('', User.getFilterableAttributes(), User.getSortableAttributes());

  // const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  // const setParams = (payload: PartialSearchingProps) => dispatch(setUserListParamsAction(payload));

  // useEffect(() => {
  //   const title = isAdminList ? 'Administratorzy - lista' : 'Klienci - lista';

  //   managePage({
  //     title,
  //     breadcrumb: breadcrumbRouteUsers(isAdminList)
  //   });

  //   if (isAdminList) setParams({ ...searchProps, filter: { role_slug: ROLE_ADMIN } });
  //   else setParams({ ...searchProps, filter: { role_slug: ROLE_CLIENT } });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const dispatchDeleteUserToast = () => dispatch(addToastAction(deleteUserToastSuccess()));

  const cancelToken = useCancelToken();
  const deleteUserAction = async (user_id: number | string) => {
    setLoading(true);
    const { cancelled, success, message: messageRequest } = await await deleteRequest(deleteUser, user_id, cancelToken);

    if (cancelled) return;
    if (success) {
      if (success) dispatchDeleteUserToast();
      if (user_id === current_user_id) {
        dispatch(logoutAction());
      } else {
        setDisplayModal(null);
        dispatch(setUserListParamsAction({}));
        setLoading(false);
      }
    } else {
      if (messageRequest) setMessage(messageRequest);
      setLoading(false);
    }
  };

  return (
    <div className="row users-view">
      <div className="col-12">
        {displayModal && (
          <GenericModalDelete
            value={displayModal}
            title={`Usuwanie ${isAdminList ? 'administratora' : 'klienta'}`}
            content={(value) => (
              <div>
                Czy na pewno chcesz usunąć {isAdminList ? 'administratora' : 'klienta'} - {value?.getName()}?
              </div>
            )}
            onDeleteClick={(value) => deleteUserAction(value.id)}
            isOpen={!!displayModal}
            toggle={() => setDisplayModal(null)}
            loading={loading}
            message={message}
            resetMessage={() => setMessage(null)}
          />
        )}
        <Listing
          table={<UserTable isAdminList={isAdminList} onDeleteClick={(user) => setDisplayModal(user)} />}
          filter={<UserFilter values={searchProps.filter} isAdminList={isAdminList} />}
          pagination={<UserPagination path={ROUTE_USERS} />}
          perPage={<UserPerPage />}
          loading={fetching}
          meta={meta}
        />
      </div>
    </div>
  );
};

export default UsersList;
