import React from 'react';
import { ColorVariants } from 'modules/Shared/type';
import { Link } from 'react-router-dom';
import { ROUTE_RESEND_TOKEN } from 'modules/Auth/routes';

const resendTokenMessage = (): { value: React.ReactNode; variant: ColorVariants } => ({
  value: (
    <>
      Twoje konto nie zostało aktywowane. Otwórz wiadomość, która została wysłana na Twoją skrzynkę e-mail i aktywuj
      konto. Jeżeli nie widzisz wiadomości lub chcesz otrzymać nową wiadomość kliknij{' '}
      <Link to={ROUTE_RESEND_TOKEN}>tutaj</Link>.
    </>
  ),
  variant: ColorVariants.Info
});

export default resendTokenMessage;
