import { BreadcrumbItem } from 'modules/Layout/type';
import {
  ROUTE_SUBSCRIPTION_SUMMARY,
  ROUTE_SUBSCRIPTIONS,
  ROUTE_TRADITIONAL_PAYMENTS_WAITING
} from 'modules/Subscriptions/routes';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_PROFILE } from 'modules/Profile/routes';
import { ROUTE_USER } from 'modules/User/routes';
import { USERS_INDEX } from 'modules/User/permissions';

const breadcrumbSubscription = (): BreadcrumbItem => ({
  title: 'Zakup subskrypcji',
  link: ROUTE_SUBSCRIPTIONS
});

export const breadcrumbSubscriptions = (): BreadcrumbItem[] => [breadcrumbSubscription()];

export const breadcrumbSubscriptionsSelected = (): BreadcrumbItem[] => [
  breadcrumbSubscription(),
  {
    title: 'Potwierdzenie',
    link: ROUTE_SUBSCRIPTION_SUMMARY
  }
];

export const breadcrumbSubscriptionsTraditionalPayment = (): BreadcrumbItem[] => [
  ...breadcrumbSubscriptionsSelected(),
  {
    title: 'Tradycyjny przelew'
  }
];

export const breadcrumbRouteSubscriptions = (): BreadcrumbItem[] => [
  ...breadcrumbSubscriptions(),
  {
    title: 'Szczegóły',
    link: ''
  }
];

export const breadcrumbRoutePaymentDetails = (paymentId?: string): BreadcrumbItem[] => [
  {
    title: 'Profil użytkownika',
    link: getPathUrl(ROUTE_PROFILE)
  },
  {
    title: `Szczegóły transkacji${paymentId ? ` - ${paymentId}` : ''}`
  }
];

export const breadcrumbTraditionalPaymentWaiting = (): BreadcrumbItem[] => [
  {
    title: 'Oczekujący klienci - lista',
    link: ROUTE_TRADITIONAL_PAYMENTS_WAITING,
    permissions: [USERS_INDEX]
  }
];

export const breadcrumbRouteUserWaiting = (id?: string | number, userName?: string): BreadcrumbItem[] => [
  ...breadcrumbTraditionalPaymentWaiting(),
  {
    title: `${userName ?? 'Użytkownik'} - szczegóły`,
    link: getPathUrl(ROUTE_USER, { id })
  }
];

export const breadcrumbRouteUserWaitingListPaymentLog = (payment_number?: string): BreadcrumbItem[] => [
  ...breadcrumbTraditionalPaymentWaiting(),
  {
    title: `Szczegóły transakcji${!!payment_number && ` - ${payment_number}`}`
  }
];
