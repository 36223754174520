import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import { PartialSearchingProps } from 'modules/Shared/type';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { setJudgementListParamsAction } from 'modules/Judgement/action/JudgementList';
import JudgmentInputKeywordsAutocomplete from 'modules/Judgement/container/JudgementAutocomplete/FilterKeywords';

const JudgmentFilterKeywordsAutocomplete = () => {
  const dispatch = useDispatch();
  const { filter } = useSelector((state: RootState) => state.judgmentClient.judgementClientList);
  const [keywords, setKeywords] = useState<{ name: string; id: number }[]>([]);

  const setParams = (payload: PartialSearchingProps) => dispatch(setJudgementListParamsAction(payload));

  const saveKeyword = (newKeyword: { name: string; id: number }) => {
    if (!keywords.find((value) => value.id === newKeyword.id)) {
      setKeywords([...keywords, newKeyword]);
      filter['keywords[]'] = [...keywords.map((value) => value.id), newKeyword.id] as any;
      setParams({ ...filter });
    }
  };

  const deleteKeyword = (id: number) => {
    const newKeyword = keywords.filter((el) => el.id !== id);
    setKeywords(newKeyword);
    // eslint-disable-next-line
    if (filter.hasOwnProperty('keywords[]')) {
      const { 'keywords[]': _ignore, ...rest } = filter;
      if (newKeyword.length > 0) {
        rest['keywords[]'] = newKeyword.map((value) => value.id) as any;
      }
      setParams({ filter: rest });
    }
  };

  return (
    <div className="col-12 col-lg-6 col-xl-4">
      <JudgmentInputKeywordsAutocomplete
        keywords={keywords}
        onKeywordsChange={saveKeyword}
        alwaysClearInput
        chipContentElements={keywords.map(({ name, id }) => ({ content: name, id }))}
        onDeleteChipElement={deleteKeyword}
      />
    </div>
  );
};

export default JudgmentFilterKeywordsAutocomplete;
