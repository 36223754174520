import { ModuleSlug } from 'modules/Auth/type';
import { checkIfUserHasAccessToModule } from 'modules/Shared/helper/utils';
import User, { UserEntity } from 'modules/User/model/User';

export const MODULE_EXECUTOR = 'md_executor';
export const MODULE_EXECUTOR_EXTENDED = 'md_executor_extended';
export const MODULE_AGREEMENTS = 'md_agreements';
export const MODULE_CLAUSE = 'md_clause';
export const MODULE_JUDGEMENTS = 'md_clause';
export const MODULE_FOREIGN_PROCEEDINGS = 'md_foreign_proceedings';
export const MODULE_ORDER = 'md_orders';

export interface ModuleEntity {
  id?: number;
  name?: string;
  slug?: string;
  description?: string;
  subscription_description?: string;
  credits_description?: string;
  is_public?: boolean;
  date_from?: string;
  date_to?: string;
  has_credits?: boolean;
}

export default class Module {
  readonly id?: number;
  readonly name?: string;
  readonly slug?: string;
  readonly date_from?: string;
  readonly date_to?: string;
  readonly description?: string;
  readonly subscription_description?: string;
  readonly credits_description?: string;
  readonly is_public?: boolean;

  constructor(entity: ModuleEntity) {
    this.id = entity.id;
    this.name = entity.name;
    this.slug = entity.slug;
    this.date_from = entity.date_from;
    this.date_to = entity.date_to;
    this.description = entity.description;
    this.subscription_description = entity.subscription_description;
    this.credits_description = entity.credits_description;
    this.is_public = entity.is_public;
  }
}

export const createModule = (entity: ModuleEntity): Module => new Module(entity);

export const hasModule = (slug: ModuleSlug, user: User | UserEntity) => {
  if (slug) {
    const module = user?.modules?.find((mod) => mod?.slug === slug);

    if (!module) return false;

    if (module?.is_public) return true;

    return checkIfUserHasAccessToModule(module?.date_from, module?.date_to);
  }

  return false;
};

export const hasCreditModule = (slug: ModuleSlug, user: User | UserEntity) => {
  if (slug) {
    const module = user?.credits?.find((mod) => mod?.module_slug === slug);

    return !!module;
  }

  return false;
};

export const modulesAddOption = () => [
  { id: 1, description: 'Dostęp ograniczony czasowo, nieograniczona liczba wejść' },
  { id: 2, description: 'Dostęp nieograniczony czasowo, ograniczona liczba wejść' },
  { id: 3, description: 'Dostęp ograniczony czasowo, ograniczona liczba wejść' }
];
