import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import BaseModal, { Props } from 'modules/Layout/component/Modal';
import { IFile } from 'modules/Shared/helper/Files/AddFiles';
import { Message } from 'modules/Shared/type';
import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { serialize } from 'object-to-formdata';
import AddSibleFileDragDrop from '../../../../Shared/helper/Files/AddFilesDragDrop/AddSibleFileDragDrop';

interface IProps extends Props {
  title: string;
  content?: string;
  onAddFilesRequest: (data: FormData) => void;
  loading: boolean;
  message?: Message;
  resetMessage: () => void;
}

const ModalAddSingleFileDragDrop: React.FC<IProps> = (props) => {
  const { onAddFilesRequest, title, content, loading, toggle, message, resetMessage } = props;

  // State for a single file
  const [file, setFile] = useState<IFile | null>(null);

  const isValue = React.useRef(false);
  useEffect(() => {
    return () => {
      if (isValue.current) resetMessage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!isValue.current) isValue.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const dispalBody = () => {
    return (
      <>
        {loading && <Loader />}
        <Alert message={message} />
        <div className="modal-body-title">{content}</div>
        <div className="modal-body-content">
          <AddSibleFileDragDrop
            values={{ file }}
            setValues={({ file: newFile }: { file: IFile | null }) => setFile(newFile)}
            objKey="file"
          />
        </div>
      </>
    );
  };

  const displayFooter = () => {
    return (
      <div className="modal-actions">
        <Button
          type="submit"
          form="user-delete-form"
          color="danger"
          className="pzpeu-btn-disabled waves-effect waves-light"
          disabled={loading || !file} // Disable if no file is selected
          onClick={() => file && onAddFilesRequest(serialize({ file: file.file }))}
        >
          Dodaj
        </Button>
        <Button type="button" className="cancel waves-effect waves-light" disabled={loading} onClick={toggle}>
          Anuluj
        </Button>
      </div>
    );
  };

  return <BaseModal {...props} title={title} body={dispalBody()} footer={displayFooter()} />;
};

export default ModalAddSingleFileDragDrop;
