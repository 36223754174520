/* eslint-disable */
import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function EditCompanyIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px', className = 'icon-svg-table' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} height={height} viewBox="0 0 12.56 17.706">
      <g id="Group_441" data-name="Group 441" transform="translate(-890.047 -599.98)">
        <path
          id="Path_1323"
          data-name="Path 1323"
          d="M901.073,617.186h-9.492a1.041,1.041,0,0,1-1.034-1.047V601.527a1.042,1.042,0,0,1,1.034-1.047h9.492a1.042,1.042,0,0,1,1.034,1.047v14.612a1.041,1.041,0,0,1-1.034,1.047Z"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_69"
          data-name="Line 69"
          x2="2.809"
          transform="translate(892.706 609.203)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_70"
          data-name="Line 70"
          x2="2.809"
          transform="translate(897.059 609.203)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_71"
          data-name="Line 71"
          x2="2.809"
          transform="translate(897.059 613.555)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_72"
          data-name="Line 72"
          y2="2.809"
          transform="translate(894.111 607.798)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_73"
          data-name="Line 73"
          x2="2.494"
          y2="2.494"
          transform="translate(892.856 612.3)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_74"
          data-name="Line 74"
          x1="2.494"
          y2="2.494"
          transform="translate(892.864 612.309)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_75"
          data-name="Line 75"
          transform="translate(898.51 612.112)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_76"
          data-name="Line 76"
          transform="translate(898.51 615.014)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <rect
          id="Rectangle_80"
          data-name="Rectangle 80"
          width="7.254"
          height="2.902"
          transform="translate(892.683 602.671)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
