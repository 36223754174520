import React, { useEffect, useRef, useState } from 'react';
import { FormGroup, CustomInput, FormFeedback, Label } from 'reactstrap';
import { isEqual } from 'lodash';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_ACCEPT } from 'modules/Agreements/step';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import { getError, hasError } from 'modules/Shared/helper/validation';

export interface AgreementStepAcceptValues {
  acceptance_possibility: boolean;
  partial_checked: boolean;
  final_checked: boolean;
  post_warranty_checked: boolean;
  others_checked: boolean;
  others_content: string | null;
  ser_acceptance_with_schedule_checked: boolean;
  partial_schedule_checked: boolean;
  partial_schedule_content: string | null;
  partial_accept_checked: boolean;
  partial_accept_content: string | null;
  partial_procedure_possibility: boolean;
  partial_readiness_checked: boolean;
  partial_readiness_content: string | null;
  partial_participation_checked: boolean;
  partial_participation_content: string | null;
  partial_documents_checked: boolean;
  partial_documents_content: string | null;
  partial_tests_checked: boolean;
  partial_tests_content: string | null;
  partial_protocol_checked: boolean;
  partial_protocol_content: string | null;
  partial_others_checked: boolean;
  partial_others_content: string | null;
  final_procedure_possibility: boolean;
  final_subject_checked: boolean;
  final_subject_content: string | null;
  final_readiness_checked: boolean;
  final_readiness_content: string | null;
  final_date_checked: boolean;
  final_date_content: string | null;
  final_participate_checked: boolean;
  final_participate_content: string | null;
  final_protocol_checked: boolean;
  final_protocol_content: string | null;
  final_protocol_place_checked: boolean;
  final_protocol_place_content: string | null;
  final_protocol_date_checked: boolean;
  final_protocol_date_content: string | null;
  final_protocol_people_checked: boolean;
  final_protocol_people_content: string | null;
  final_protocol_documents_checked: boolean;
  final_protocol_documents_content: string | null;
  final_protocol_compatibility_checked: boolean;
  final_protocol_compatibility_content: string | null;
  final_protocol_defects_checked: boolean;
  final_protocol_defects_content: string | null;
  final_protocol_decisions_checked: boolean;
  final_protocol_decisions_content: string | null;
  final_protocol_statements_checked: boolean;
  final_protocol_statements_content: string | null;
  final_protocol_signatures_checked: boolean;
  final_protocol_signatures_content: string | null;
  final_others_checked: boolean;
  final_others_content: string | null;
  not_remove_defects_possibility: boolean;
  salary_reduction_checked: boolean;
  salary_reduction_content: string | null;
  closest_date_checked: boolean;
  closest_date_content: string | null;
  second_request_checked: boolean;
  second_request_content: string | null;
  not_remove_defects_others_checked: boolean;
  not_remove_defects_others_content: string | null;
  subject_take_over_checked: boolean;
  subject_take_over_content: string | null;
  representative_accept_checked: boolean;
  representative_accept_content: string | null;
  representation_checked: boolean;
  representation_content: string | null;
  representation_executor_checked: boolean;
  representation_executor_content: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const OTHERS_CONTENT = 'inne: ....';
const PARTIAL_SCHEDULE_CONTENT =
  'Podpisanie przez zamawiającego protokołów odbioru nie oznacza braku odpowiedzialności wykonawcy za wady, uszkodzenia lub usterki, które mogą się ujawnić w dalszym ciągu realizacji lub użytkowania jakiejkolwiek części lub całości Przedmiotu Umowy.';
const PARTIAL_ACCEPT_CONTENT =
  'Podpisanie przez zamawiającego protokołów odbioru częściowego zgodnie z Umową oraz zapłata związanego z tym wynagrodzenia, nie zwalnia wykonawcy z obowiązku należytego dbania, zabezpieczenia oraz ponoszenia wszelkich kosztów związanych z utrzymaniem Przedmiotem Umowy, do momentu dokonania Odbioru Ostatecznego przez zamawiającego. Oznacza to pełną odpowiedzialność wykonawcy wobec zamawiającego za właściwe utrzymanie wykonanego Przedmiotu Umowy, bądź jego części do momentu Odbioru Ostatecznego.';
const PARTIAL_READINESS_CONTENT = 'Wykonawca zgłosi na piśmie zamawiającemu gotowość do obioru częściowego.';
const PARTIAL_PARTICIPATION_CONTENT =
  'Wykonawca zobowiązuje się brać czynny udział – poprzez osoby uprawnione w odbiorze, w terminie wyznaczonym przez zamawiającego.';
const PARTIAL_DOCUMENTS_CONTENT =
  'Wykonawca przedłoży zamawiającemu komplet dokumentów dotyczących odbieranej części Przedmiotu Umowy, wymaganych przepisami prawa i Umową.';
const PARTIAL_TESTS_CONTENT = 'Zamawiający uprawniony jest do dokonania testów otrzymanego Przedmiotu Umowy';
const PARTIAL_PROTOCOL_CONTENT =
  'Z czynności odbioru częściowego sporządza się stosowny protokół, w którym uwzględnia się ewentualne wady i usterki i termin ich usunięcia. Protokół częściowy jest podstawą do wystawienia faktury częściowej i zapłaty części wynagrodzenia na warunkach określonych w Umowie.';
const PARTIAL_OTHERS_CONTENT = 'inne: ....';
const FINAL_SUBJECT_CONTENT = 'Przedmiotem Odbioru Ostatecznego będzie Przedmiot Umowy.';
const FINAL_READINESS_CONTENT = 'Wykonawca zgłosi na piśmie zamawiającemu gotowość do Obioru Ostatecznego.';
const FINAL_DATE_CONTENT =
  'Zamawiający wyznaczy datę i rozpocznie czynności odbioru przy udziale uprawnionych przedstawicieli wykonawcy w ciągu ........ dni od daty zgłoszenia gotowości do odbioru.';
const FINAL_PARTICIPATE_CONTENT =
  'Wykonawca zobowiązuje się brać czynny udział poprzez osoby uprawnione w odbiorze, w terminie wyznaczonym przez zamawiającego.';
const FINAL_PROTOCOL_CONTENT =
  'Z czynności Odbioru Ostatecznego sporządzony będzie Protokół Odbioru Ostatecznego, który powinien zawierać ustalenia poczynione w toku odbioru, a w szczególności:';
const FINAL_PROTOCOL_PLACE_CONTENT = 'Oznaczenie miejsca sporządzenia.';
const FINAL_PROTOCOL_DATE_CONTENT = 'Datę rozpoczęcia i zakończenia odbioru.';
const FINAL_PROTOCOL_PEOPLE_CONTENT = 'Oznaczenie osób uczestniczących w odbiorze i charakteru, w jakim uczestniczą.';
const FINAL_PROTOCOL_DOCUMENTS_CONTENT =
  'Wymienienie dokumentów przygotowanych przez wykonawcę i przekazanych zamawiającemu.';
const FINAL_PROTOCOL_COMPATIBILITY_CONTENT =
  'Ustalenia co do zgodności wykonanego Przedmiotu Umowy z Umową, przekazaną dokumentacją, zasadami wiedzy technicznej i powszechnie obowiązującymi przepisami prawa.';
const FINAL_PROTOCOL_DEFECTS_CONTENT = 'Wymienienie ujawnionych wad.';
const FINAL_PROTOCOL_DECISIONS_CONTENT =
  'Decyzje zamawiającego co do przyjęcia lub odmowy przyjęcia Przedmiotu Umowy, terminu usunięcia wad, propozycje obniżenia wynagrodzenia wykonawcy.';
const FINAL_PROTOCOL_STATEMENTS_CONTENT = 'Oświadczenia i wyjaśnienia wykonawcy i osób uczestniczących w odbiorze.';
const FINAL_PROTOCOL_SIGNATURES_CONTENT = 'Podpisy osób uczestniczących w odbiorze.';
const FINAL_OTHERS_CONTENT = 'inne: ....';
const SALARY_REDUCTION_CONTENT =
  'Odbioru Przedmiotu Umowy i obniżenia wynagrodzenia o kwotę, jaka zaniżyła wartość wykonanej usługi na skutek wykrytych wad.';
const CLOSEST_DATE_CONTENT =
  'Odbioru Przedmiotu Umowy pod warunkiem, że usunięcie wad nastąpi w dalszym terminie wskazanym przez zamawiającego. W takim razie, Pousterkowy Protokół Odbioru zostanie podpisany po usunięciu takich wad.';
const SECOND_REQUEST_CONTENT =
  'zażądać wykonania Przedmiotu Umowy (lub poszczególnych elementów wchodzących w zakres całego Przedmiotu Umowy) po raz drugi na koszt wykonawcy, jeżeli wady uniemożliwiają korzystania z Przedmiotu Umowy zgodnie z jego przeznaczeniem.';
const NOT_REMOVE_DEFECTS_OTHERS_CONTENT = 'inne: ....';
const SUBJECT_TAKE_OVER_CONTENT =
  'Z chwilą dokonania Odbioru Ostatecznego i podpisania Protokołu Odbioru zamawiający przejmie od wykonawcy Przedmiot Umowy.';
const REPRESENTATIVE_ACCEPT_CONTENT =
  'Odbioru pogwarancyjnego dokonuje przedstawiciel Zamawiającego w ciągu ........ dni od upływu terminu Gwarancji jakości i rękojmi wraz z przedstawicielem wykonawcy. Celem odbioru pogwarancyjnego jest pokwitowanie wypełnienia przez wykonawcę obowiązków z tytułu udzielonej Gwarancji jakości i rękojmi za Przedmiot Umowy.';
const REPRESENTATION_CONTENT = 'Zamawiającego przy odbiorach reprezentować będą: ........';
const REPRESENTATION_EXECUTOR_CONTENT = 'Wykonawcę przy odbiorach reprezentować będą: ........';

const initContentValues = {
  others_content: OTHERS_CONTENT,
  partial_schedule_content: PARTIAL_SCHEDULE_CONTENT,
  partial_accept_content: PARTIAL_ACCEPT_CONTENT,
  partial_readiness_content: PARTIAL_READINESS_CONTENT,
  partial_participation_content: PARTIAL_PARTICIPATION_CONTENT,
  partial_documents_content: PARTIAL_DOCUMENTS_CONTENT,
  partial_tests_content: PARTIAL_TESTS_CONTENT,
  partial_protocol_content: PARTIAL_PROTOCOL_CONTENT,
  partial_others_content: PARTIAL_OTHERS_CONTENT,
  final_subject_content: FINAL_SUBJECT_CONTENT,
  final_readiness_content: FINAL_READINESS_CONTENT,
  final_date_content: FINAL_DATE_CONTENT,
  final_participate_content: FINAL_PARTICIPATE_CONTENT,
  final_protocol_content: FINAL_PROTOCOL_CONTENT,
  final_protocol_place_content: FINAL_PROTOCOL_PLACE_CONTENT,
  final_protocol_date_content: FINAL_PROTOCOL_DATE_CONTENT,
  final_protocol_people_content: FINAL_PROTOCOL_PEOPLE_CONTENT,
  final_protocol_documents_content: FINAL_PROTOCOL_DOCUMENTS_CONTENT,
  final_protocol_compatibility_content: FINAL_PROTOCOL_COMPATIBILITY_CONTENT,
  final_protocol_defects_content: FINAL_PROTOCOL_DEFECTS_CONTENT,
  final_protocol_decisions_content: FINAL_PROTOCOL_DECISIONS_CONTENT,
  final_protocol_statements_content: FINAL_PROTOCOL_STATEMENTS_CONTENT,
  final_protocol_signatures_content: FINAL_PROTOCOL_SIGNATURES_CONTENT,
  final_others_content: FINAL_OTHERS_CONTENT,
  salary_reduction_content: SALARY_REDUCTION_CONTENT,
  closest_date_content: CLOSEST_DATE_CONTENT,
  second_request_content: SECOND_REQUEST_CONTENT,
  not_remove_defects_others_content: NOT_REMOVE_DEFECTS_OTHERS_CONTENT,
  subject_take_over_content: SUBJECT_TAKE_OVER_CONTENT,
  representative_accept_content: REPRESENTATIVE_ACCEPT_CONTENT,
  representation_content: REPRESENTATION_CONTENT,
  representation_executor_content: REPRESENTATION_EXECUTOR_CONTENT
};

const mapState = (step: AgreementStepAcceptValues): AgreementStepAcceptValues => {
  const {
    others_content,
    partial_schedule_content,
    partial_accept_content,
    partial_readiness_content,
    partial_participation_content,
    partial_documents_content,
    partial_tests_content,
    partial_protocol_content,
    partial_others_content,
    final_subject_content,
    final_readiness_content,
    final_date_content,
    final_participate_content,
    final_protocol_content,
    final_protocol_place_content,
    final_protocol_date_content,
    final_protocol_people_content,
    final_protocol_documents_content,
    final_protocol_compatibility_content,
    final_protocol_defects_content,
    final_protocol_decisions_content,
    final_protocol_statements_content,
    final_protocol_signatures_content,
    final_others_content,
    salary_reduction_content,
    closest_date_content,
    second_request_content,
    not_remove_defects_others_content,
    subject_take_over_content,
    representative_accept_content,
    representation_content,
    representation_executor_content,
    checkboxes,
    ...rest
  } = step;

  return {
    ...rest,
    others_content: others_content || OTHERS_CONTENT,
    partial_schedule_content: partial_schedule_content || PARTIAL_SCHEDULE_CONTENT,
    partial_accept_content: partial_accept_content || PARTIAL_ACCEPT_CONTENT,
    partial_readiness_content: partial_readiness_content || PARTIAL_READINESS_CONTENT,
    partial_participation_content: partial_participation_content || PARTIAL_PARTICIPATION_CONTENT,
    partial_documents_content: partial_documents_content || PARTIAL_DOCUMENTS_CONTENT,
    partial_tests_content: partial_tests_content || PARTIAL_TESTS_CONTENT,
    partial_protocol_content: partial_protocol_content || PARTIAL_PROTOCOL_CONTENT,
    partial_others_content: partial_others_content || PARTIAL_OTHERS_CONTENT,
    final_subject_content: final_subject_content || FINAL_SUBJECT_CONTENT,
    final_readiness_content: final_readiness_content || FINAL_READINESS_CONTENT,
    final_date_content: final_date_content || FINAL_DATE_CONTENT,
    final_participate_content: final_participate_content || FINAL_PARTICIPATE_CONTENT,
    final_protocol_content: final_protocol_content || FINAL_PROTOCOL_CONTENT,
    final_protocol_place_content: final_protocol_place_content || FINAL_PROTOCOL_PLACE_CONTENT,
    final_protocol_date_content: final_protocol_date_content || FINAL_PROTOCOL_DATE_CONTENT,
    final_protocol_people_content: final_protocol_people_content || FINAL_PROTOCOL_PEOPLE_CONTENT,
    final_protocol_documents_content: final_protocol_documents_content || FINAL_PROTOCOL_DOCUMENTS_CONTENT,
    final_protocol_compatibility_content: final_protocol_compatibility_content || FINAL_PROTOCOL_COMPATIBILITY_CONTENT,
    final_protocol_defects_content: final_protocol_defects_content || FINAL_PROTOCOL_DEFECTS_CONTENT,
    final_protocol_decisions_content: final_protocol_decisions_content || FINAL_PROTOCOL_DECISIONS_CONTENT,
    final_protocol_statements_content: final_protocol_statements_content || FINAL_PROTOCOL_STATEMENTS_CONTENT,
    final_protocol_signatures_content: final_protocol_signatures_content || FINAL_PROTOCOL_SIGNATURES_CONTENT,
    final_others_content: final_others_content || FINAL_OTHERS_CONTENT,
    salary_reduction_content: salary_reduction_content || SALARY_REDUCTION_CONTENT,
    closest_date_content: closest_date_content || CLOSEST_DATE_CONTENT,
    second_request_content: second_request_content || SECOND_REQUEST_CONTENT,
    not_remove_defects_others_content: not_remove_defects_others_content || NOT_REMOVE_DEFECTS_OTHERS_CONTENT,
    subject_take_over_content: subject_take_over_content || SUBJECT_TAKE_OVER_CONTENT,
    representative_accept_content: representative_accept_content || REPRESENTATIVE_ACCEPT_CONTENT,
    representation_content: representation_content || REPRESENTATION_CONTENT,
    representation_executor_content: representation_executor_content || REPRESENTATION_EXECUTOR_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepAccept = (props: AgreementStepProps): JSX.Element => {
  const { steps, onSubmit, onChange, errors } = props;
  const initState = useRef<AgreementStepAcceptValues>(mapState(getStepValues(steps, AGREEMENT_STEP_ACCEPT)));
  const [stepValues, setStepValues] = useState<AgreementStepAcceptValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_ACCEPT));
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderCheckbox, renderEditableCheckbox, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    initContentValues
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        {[
          renderCheckbox(
            'acceptance_possibility',
            'W ramach realizacji niniejszej Umowy występować będą następujące odbiory:'
          ),
          stepValues.acceptance_possibility && [
            <div className="pl-3">
              {[
                renderCheckbox('partial_checked', 'Odbiory częściowe'),
                renderCheckbox('final_checked', 'Odbiór Ostateczny Przedmiotu Umowy'),
                renderCheckbox('post_warranty_checked', 'Odbiór pogwarancyjny'),
                renderEditableCheckbox('others')
              ]}
            </div>,
            stepValues.partial_checked && [
              <FormGroup>
                <Label className="mb-2">Odbiory częściowe usługi dokonywane będą zgodnie z:</Label>
                <CustomInput
                  id="ser_acceptance_with_schedule_true"
                  label="1) etapami wymienionymi w Harmonogramie."
                  value={1}
                  type="radio"
                  checked={stepValues.ser_acceptance_with_schedule_checked === true}
                  onChange={() => {
                    stepValues.ser_acceptance_with_schedule_checked = true;
                    setStepValues((values) => mapState(values));
                  }}
                  invalid={hasError(errors, 'ser_acceptance_with_schedule_checked')}
                />
                <CustomInput
                  id="ser_acceptance_with_schedule_false"
                  label="2) etapami Przedmiotu Umowy określonymi w Umowie"
                  value={2}
                  type="radio"
                  checked={stepValues.ser_acceptance_with_schedule_checked === false}
                  onChange={() => {
                    stepValues.ser_acceptance_with_schedule_checked = false;
                    setStepValues((values) => mapState(values));
                  }}
                  invalid={hasError(errors, 'ser_acceptance_with_schedule_checked')}
                />
                {hasError(errors, 'ser_acceptance_with_schedule_checked') && (
                  <FormFeedback className="d-block">
                    {getError(errors, 'ser_acceptance_with_schedule_checked')}
                  </FormFeedback>
                )}
              </FormGroup>,
              <hr />,
              renderCheckbox('partial_schedule_checked', stepValues.partial_schedule_content),
              renderCheckbox('partial_accept_checked', stepValues.partial_accept_content),
              renderCheckbox('partial_procedure_possibility', 'Procedura odbioru częściowego:'),
              stepValues.partial_procedure_possibility && (
                <div className="pl-3">
                  {[
                    renderCheckbox('partial_readiness_checked', stepValues.partial_readiness_content),
                    renderCheckbox('partial_participation_checked', stepValues.partial_participation_content),
                    renderCheckbox('partial_documents_checked', stepValues.partial_documents_content),
                    renderCheckbox('partial_tests_checked', stepValues.partial_tests_content),
                    renderCheckbox('partial_protocol_checked', stepValues.partial_protocol_content),
                    renderEditableCheckbox('partial_others')
                  ]}
                </div>
              )
            ],
            stepValues.final_checked && [
              renderCheckbox('final_procedure_possibility', 'Procedura Odbioru Ostatecznego Przedmiotu Umowy:'),
              stepValues.final_procedure_possibility && (
                <div className="pl-3">
                  {[
                    renderCheckbox('final_subject_checked', stepValues.final_subject_content),
                    renderCheckbox('final_readiness_checked', stepValues.final_readiness_content),
                    renderEditableCheckbox('final_date', null),
                    renderCheckbox('final_participate_checked', stepValues.final_participate_content),
                    renderCheckbox('final_protocol_checked', stepValues.final_protocol_content),
                    stepValues.final_protocol_checked && (
                      <div className="pl-3">
                        {[
                          renderCheckbox('final_protocol_place_checked', stepValues.final_protocol_place_content),
                          renderCheckbox('final_protocol_date_checked', stepValues.final_protocol_date_content),
                          renderCheckbox('final_protocol_people_checked', stepValues.final_protocol_people_content),
                          renderCheckbox(
                            'final_protocol_documents_checked',
                            stepValues.final_protocol_documents_content
                          ),
                          renderCheckbox(
                            'final_protocol_compatibility_checked',
                            stepValues.final_protocol_compatibility_content
                          ),
                          renderCheckbox('final_protocol_defects_checked', stepValues.final_protocol_defects_content),
                          renderCheckbox(
                            'final_protocol_decisions_checked',
                            stepValues.final_protocol_decisions_content
                          ),
                          renderCheckbox(
                            'final_protocol_statements_checked',
                            stepValues.final_protocol_statements_content
                          ),
                          renderCheckbox(
                            'final_protocol_signatures_checked',
                            stepValues.final_protocol_signatures_content
                          )
                        ]}
                      </div>
                    ),
                    renderEditableCheckbox('final_others')
                  ]}
                </div>
              )
            ]
          ],
          <hr />,
          renderCheckbox(
            'not_remove_defects_possibility',
            'Jeżeli w terminie wyznaczonym przez zamawiającego wykonawca nie usunie wad ujawnionych w toku procedury Odbioru Ostatecznego, zamawiający będzie uprawniony do:'
          ),
          stepValues.not_remove_defects_possibility && (
            <div className="pl-3">
              {[
                renderCheckbox('salary_reduction_checked', stepValues.salary_reduction_content),
                renderCheckbox('closest_date_checked', stepValues.closest_date_content),
                renderCheckbox('second_request_checked', stepValues.second_request_content),
                renderEditableCheckbox('not_remove_defects_others')
              ]}
            </div>
          ),
          <hr />,
          renderCheckbox('subject_take_over_checked', stepValues.subject_take_over_content),
          stepValues.post_warranty_checked && renderEditableCheckbox('representative_accept'),
          renderEditableCheckbox('representation'),
          renderEditableCheckbox('representation_executor'),
          renderCustomCheckboxes()
        ]}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepAccept;
