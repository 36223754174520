import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterInputType } from 'modules/Layout/type';
import { setClauseListParamsAction } from 'modules/Clause/action/ClauseList';
import ClauseFilterKeywordsAutocomplete from 'modules/Clause/container/AdminClauseList/Filter/CustomKeywordAutocomplete';
import ClauseFilterCategoriesAutocomplete from 'modules/Clause/container/AdminClauseList/Filter/CustomCategoriesAutocomplete';

export interface Props {
  values?: SearchParams;
}

const ClauseFilter: React.FC<Props> = ({ values = {} }) => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.clause.clauseList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setClauseListParamsAction(payload));

  const staticInput: FilterInputType[] = [
    {
      type: 'number',
      property: 'id',
      label: 'ID',
      value: _get(values, 'id')
    },
    {
      type: 'text',
      property: 'name',
      className: 'col-12 col-lg-6 col-xl-4',
      label: 'Nazwa klauzuli',
      value: _get(values, 'name')
    },
    {
      type: 'text',
      property: 'description',
      label: 'Wyjaśnienie klauzuli',
      className: 'col-12 col-lg-6 col-xl-4',
      value: _get(values, 'description')
    }
  ];

  return (
    <ListingFilter
      inputs={staticInput}
      filter={filter}
      pagination={pagination}
      setParams={setParams}
      customInput={
        <>
          <ClauseFilterCategoriesAutocomplete />
          <ClauseFilterKeywordsAutocomplete />
        </>
      }
    />
  );
};

export default ClauseFilter;
