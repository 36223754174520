import React, { useEffect, useRef, useState } from 'react';
import { AGREEMENT_TYPE_SUPPLEMENT, AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_PERSONAL_DATA_PROTECTION } from 'modules/Agreements/step';
import { FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import useStepFields from 'modules/Agreements/hook/useStepFields';

export interface AgreementStepPersonalDataProtectionValues {
  rodo_technical_measures_checked: boolean;
  rodo_technical_measures_content: string | null;
  rodo_agreement_checked: boolean;
  rodo_agreement_content: string | null;
  rodo_inform_checked: boolean;
  rodo_inform_content: string | null;
  rodo_consents_checked: boolean;
  rodo_consents_content: string | null;
  checkboxes: AgreementCheckboxes | null;
}

const RODO_TECHNICAL_MEASURES_CONTENT =
  'Wykonawca oświadcza, że wdraża i stosuje adekwatne środki techniczne i organizacyjne, w celu zapewnienia stopnia bezpieczeństwa odpowiedniego do ryzyka naruszenia praw lub wolności osób fizycznych, których dane osobowe są przetwarzane na podstawie Umowy, w tym zapewniające możliwość ciągłego zapewnienia poufności, integralności, dostępności i odporności systemów służących do przetwarzania danych osobowych oraz usług przetwarzania oraz zapewniające możliwość szybkiego przywrócenia dostępności danych osobowych i dostępu do nich w razie incydentu fizycznego lub technicznego. Naruszenie tak określonego obowiązku przez wykonawcę uprawniać będzie zamawiającego do odstąpienia od Umowy z przyczyn leżących po stronie wykonawcy.';
const RODO_AGREEMENT_CONTENT =
  'W przypadku, gdy w trakcie realizacji Umowy konieczne będzie powierzenie wykonawcy przez zamawiającego przetwarzania danych osobowych, strony zobowiązują się zawrzeć umowę  o powierzeniu przetwarzania danych osobowych, zgodnie z wymogami artykułu 28 RODO (rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE - ogólne rozporządzenie o ochronie danych (Dz. Urz. UE L 119 z 04.05.2016, str. 1). Uchylanie się wykonawcy od zawarcia tej umowy, uznaje się za przerwę w realizacji Umowy leżącą po stronie wykonawcy.';
const RODO_INFORM_CONTENT =
  'Wykonawca oświadcza, iż został poinformowany przez zamawiającego, iż cały teren, na którym będą realizowane roboty objęte Przedmiotem Umowy może być monitorowany wyraża zgodę na nieograniczone czasowo wykorzystanie wizerunku w formie zdjęć, prezentacji video. Wykonawca nie wnosi oraz nie będzie wnosił jakichkolwiek roszczeń czy zastrzeżeń z tym związanych.';
const RODO_CONSENTS_CONTENT =
  'Wykonawca zobowiązuje się do przekazania zamawiającemu zgód pracowników zatrudnionych do realizacji Przedmiotu Umowy na wykorzystanie ich wizerunku, w związku z rejestracją obrazu o której mowa w ust. powyżej, najpóźniej w terminie ........ dni od daty zawarcia Umowy. Niniejszy obowiązek dotyczy również podwykonawców. W przypadku zatrudnienia nowych pracowników lub podwykonawców, ich zgody zostaną przekazane przez wykonawcę odpowiednio w ciągu ........ dni od dnia zatrudnienia nowego pracownika bądź w przypadku zawarcia umowy podwykonawczej w trakcie realizacji Przedmiotu Umowy, w ciągu ........ dni od jej zawarcia.';

const initContentValues = {
  rodo_technical_measures_content: RODO_TECHNICAL_MEASURES_CONTENT,
  rodo_agreement_content: RODO_AGREEMENT_CONTENT,
  rodo_inform_content: RODO_INFORM_CONTENT,
  rodo_consents_content: RODO_CONSENTS_CONTENT
};

const mapState = (step: AgreementStepPersonalDataProtectionValues): AgreementStepPersonalDataProtectionValues => {
  const {
    rodo_technical_measures_content,
    rodo_agreement_content,
    rodo_inform_content,
    rodo_consents_content,
    checkboxes,
    ...rest
  } = step;

  return {
    ...rest,
    rodo_technical_measures_content: rodo_technical_measures_content || RODO_TECHNICAL_MEASURES_CONTENT,
    rodo_agreement_content: rodo_agreement_content || RODO_AGREEMENT_CONTENT,
    rodo_inform_content: rodo_inform_content || RODO_INFORM_CONTENT,
    rodo_consents_content: rodo_consents_content || RODO_CONSENTS_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepPersonalDataProtection = (props: AgreementStepProps): JSX.Element => {
  const { steps, onSubmit, onChange, errors, agreement } = props;

  const initState = useRef<AgreementStepPersonalDataProtectionValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_PERSONAL_DATA_PROTECTION))
  );

  const [stepValues, setStepValues] = useState<AgreementStepPersonalDataProtectionValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_PERSONAL_DATA_PROTECTION));
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderCheckbox, renderEditableCheckbox, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    initContentValues
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        {renderCheckbox(
          'rodo_technical_measures_checked',
          stepValues.rodo_technical_measures_content,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderCheckbox(
          'rodo_agreement_checked',
          stepValues.rodo_agreement_content,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderCheckbox(
          'rodo_inform_checked',
          stepValues.rodo_inform_content,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'rodo_consents',
          null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderCustomCheckboxes(
          null,
          null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepPersonalDataProtection;
