import React, { useEffect, useRef, useState } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { isEqual } from 'lodash';
import { getError, hasError } from 'modules/Shared/helper/validation';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AGREEMENT_TYPE_SUPPLEMENT, AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_PERFORMANCE_SECURITY } from 'modules/Agreements/step';
import StepImportDataButton from 'modules/Agreements/components/StepImportDataButton';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import { IMPORT_ORDERS_ACCESS } from '../../../../../Auth/container/Guard/permissions';
import Authorize from '../../../../../Auth/container/Authorize';

export interface AgreementStepPerformanceSecurityValues {
  parts: {
    id: number;
    value: any;
  }[];
  safeguard_possibility: boolean;
  partial_refund_checked: boolean;
  partial_refund_content: string | null;
  safeguard_ways_possibility: boolean;
  money_checked: boolean;
  money_content: string | null;
  bank_surety_checked: boolean;
  bank_surety_content: string | null;
  bank_guarantee_checked: boolean;
  bank_guarantee_content: string | null;
  insurance_guarantee_checked: boolean;
  insurance_guarantee_content: string | null;
  parp_surety_checked: boolean;
  parp_surety_content: string | null;
  exchange_bills_checked: boolean;
  exchange_bills_content: string | null;
  securities_checked: boolean;
  securities_content: string | null;
  register_pledge_checked: boolean;
  register_pledge_content: string | null;
  others_checked: boolean;
  others_content: string | null;
  bank_account_checked: boolean;
  bank_account_content: string | null;
  over_year_checked: boolean;
  over_year_content: string | null;
  refund_date_checked: boolean;
  refund_date_content: string | null;
  claims_checked: boolean;
  claims_content: string | null;
  claims_part_checked: boolean;
  claims_part_content: string | null;
  postponement_checked: boolean;
  postponement_content: string | null;
  request_checked: boolean;
  request_content: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

export interface AgreementStepPerformanceSecurityImportValues {
  parts: {
    id: number;
    value: number;
  }[];
  safeguard_possibility: boolean;
  partial_refund_checked: boolean;
  partial_refund_content: string | null;
  safeguard_ways_possibility: boolean;
  money_checked: boolean;
  money_content: string | null;
  bank_surety_checked: boolean;
  bank_surety_content: string | null;
  bank_guarantee_checked: boolean;
  bank_guarantee_content: string | null;
  insurance_guarantee_checked: boolean;
  insurance_guarantee_content: string | null;
  parp_surety_checked: boolean;
  parp_surety_content: string | null;
  exchange_bills_checked: boolean;
  exchange_bills_content: string | null;
  securities_checked: boolean;
  securities_content: string | null;
  register_pledge_checked: boolean;
  register_pledge_content: string | null;
  others_checked: boolean;
  others_content: string | null;
  bank_account_checked: boolean;
  bank_account_content: string | null;
  over_year_checked: boolean;
  over_year_content: string | null;
  refund_date_checked: boolean;
  refund_date_content: string | null;
  claims_checked: boolean;
  claims_content: string | null;
  postponement_checked: boolean;
  postponement_content: string | null;
  request_checked: boolean;
  request_content: string | null;
  checkboxes: AgreementCheckboxes | null;
}

const PARTIAL_REFUND_CONTENT =
  '<p>Zamawiający dopuszcza dokonanie częściowego zwrotu zabezpieczenia po wykonaniu części zamówienia na następujących warunkach: <ol><li>...</li></ol><p>';
const MONEY_CONTENT = 'pieniądzu (przelewem na rachunek bankowy wskazany przez zamawiającego: ........';
const BANK_SURETY_CONTENT =
  'poręczeniach bankowych lub poręczeniach spółdzielczej kasy oszczędnościowo-kredytowej, z tym że zobowiązanie kasy jest zawsze zobowiązaniem pieniężnym';
const BANK_GUARANTEE_CONTENT = 'gwarancjach bankowych';
const INSURANCE_GUARANTEE_CONTENT = 'gwarancjach ubezpieczeniowych';
const PARP_SURETY_CONTENT =
  'poręczeniach udzielanych przez podmioty, o których mowa w art. 6b ust. 5 pkt 2 ustawy z dnia 9 listopada 2000 r. o utworzeniu Polskiej Agencji Rozwoju Przedsiębiorczości';
const EXCHANGE_BILLS_CONTENT =
  'w wekslach z poręczeniem wekslowym banku lub spółdzielczej kasy oszczędnościowo-kredytowej';
const SECURITIES_CONTENT =
  'przez ustanowienie zastawu na papierach wartościowych emitowanych przez Skarb Państwa lub jednostkę samorządu terytorialnego';
const REGISTER_PLEDGE_CONTENT =
  'przez ustanowienie zastawu rejestrowego na zasadach określonych w ustawie z dnia 6 grudnia 1996 r. o zastawie rejestrowym i rejestrze zastawów';
const OTHERS_CONTENT = 'inne:';
const BANK_ACCOUNT_CONTENT =
  'Jeżeli zabezpieczenie wniesiono w pieniądzu, zamawiający przechowuje je na oprocentowanym rachunku bankowym. Zamawiający zwraca zabezpieczenie wniesione w pieniądzu z odsetkami wynikającymi z Umowy rachunku bankowego, na którym było ono przechowywane, pomniejszone o koszt prowadzenia tego rachunku oraz prowizji bankowej za przelew pieniędzy na rachunek bankowy wykonawcy.';
const OVER_YEAR_CONTENT =
  'Jeżeli okres realizacji Umowy jest dłuższy niż rok, zamawiający wyraża zgodę na tworzenie zabezpieczenia  przez potrącenia z należności za częściowo wykonane roboty budowlane. W takim przypadku w dniu zawarcia umowy o udzielenie zamówienia wykonawca jest obowiązany wnieść co najmniej 30% kwoty zabezpieczenia. Wniesienie pełnej wysokości zabezpieczenia nie może nastąpić później niż do połowy okresu, na który została zawarta Umowa.';
export const REFUND_DATE_CONTENT =
  'Zamawiający zwraca zabezpieczenie w terminie do ... dni od dnia wykonania Umowy i podpisania Protokołu odbioru ostatecznego.';
export const CLAIMS_PART_CONTENT =
  'Zamawiający może pozostawić na zabezpieczenie roszczeń z tytułu Gwarancji jakości i rękojmi kwotę nie przekraczającą  ...% zabezpieczenia,';
export const CLAIMS_CONTENT = 'która zwracana jest nie później niż w ... dniu po upływie Gwarancji jakości i rękojmi.';
const POSTPONEMENT_CONTENT =
  'W przypadku przesunięcia terminu realizacji Umowy, wykonawca zobowiązany jest do przedłożenia zabezpieczenia na przedłużony termin realizacji zadania, aby zachować ciągłość w utrzymaniu zabezpieczenia.';
const REQUEST_CONTENT =
  'Wykonawca na pisemny wniosek skierowany do zamawiającego może w trakcie realizacji Umowy zmienić formę Zabezpieczenia, z tym zastrzeżeniem, że treść Zabezpieczenia w formie niepieniężnej, wykonawca zobowiązany jest uprzednio uzgodnić z zamawiającym. W przypadku zmiany Zabezpieczenia z formy pieniężnej na niepieniężną, zamawiający jest zobowiązany do zwrotu pieniężnej formy w terminie 30 dni od daty otrzymania Zabezpieczenia w formie niepieniężnej. Zmiana formy Zabezpieczenia musi zostać zaakceptowana przez zamawiającego w formie pisemnie pod rygorem nieważności.';

const initContentValues = {
  partial_refund_content: PARTIAL_REFUND_CONTENT,
  money_content: MONEY_CONTENT,
  bank_surety_content: BANK_SURETY_CONTENT,
  bank_guarantee_content: BANK_GUARANTEE_CONTENT,
  insurance_guarantee_content: INSURANCE_GUARANTEE_CONTENT,
  parp_surety_content: PARP_SURETY_CONTENT,
  exchange_bills_content: EXCHANGE_BILLS_CONTENT,
  securities_content: SECURITIES_CONTENT,
  register_pledge_content: REGISTER_PLEDGE_CONTENT,
  others_content: OTHERS_CONTENT,
  bank_account_content: BANK_ACCOUNT_CONTENT,
  over_year_content: OVER_YEAR_CONTENT,
  postponement_content: POSTPONEMENT_CONTENT,
  request_content: REQUEST_CONTENT
};

const mapState = (step: AgreementStepPerformanceSecurityValues): AgreementStepPerformanceSecurityValues => {
  const {
    safeguard_ways_possibility,
    partial_refund_content,
    money_content,
    bank_surety_content,
    bank_guarantee_content,
    insurance_guarantee_content,
    parp_surety_content,
    exchange_bills_content,
    securities_content,
    register_pledge_content,
    others_content,
    bank_account_content,
    over_year_content,
    postponement_content,
    request_content,
    checkboxes,
    parts,
    ...rest
  } = step;

  return {
    ...rest,
    safeguard_ways_possibility: safeguard_ways_possibility || true,
    partial_refund_content: partial_refund_content || PARTIAL_REFUND_CONTENT,
    money_content: money_content || MONEY_CONTENT,
    bank_surety_content: bank_surety_content || BANK_SURETY_CONTENT,
    bank_guarantee_content: bank_guarantee_content || BANK_GUARANTEE_CONTENT,
    insurance_guarantee_content: insurance_guarantee_content || INSURANCE_GUARANTEE_CONTENT,
    parp_surety_content: parp_surety_content || PARP_SURETY_CONTENT,
    exchange_bills_content: exchange_bills_content || EXCHANGE_BILLS_CONTENT,
    securities_content: securities_content || SECURITIES_CONTENT,
    register_pledge_content: register_pledge_content || REGISTER_PLEDGE_CONTENT,
    others_content: others_content || OTHERS_CONTENT,
    bank_account_content: bank_account_content || BANK_ACCOUNT_CONTENT,
    over_year_content: over_year_content || OVER_YEAR_CONTENT,
    postponement_content: postponement_content || POSTPONEMENT_CONTENT,
    request_content: request_content || REQUEST_CONTENT,
    checkboxes: checkboxes || {},
    parts
  };
};

const AgreementStepPerformanceSecurity = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, stepsImportData, onSubmit, onChange, errors } = props;
  const initState = useRef<AgreementStepPerformanceSecurityValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_PERFORMANCE_SECURITY))
  );
  const [stepValues, setStepValues] = useState<AgreementStepPerformanceSecurityValues>({ ...initState.current });
  const stepDataImportValues: AgreementStepPerformanceSecurityImportValues = getStepValues(
    stepsImportData,
    AGREEMENT_STEP_PERFORMANCE_SECURITY
  );
  const { parts_ids, few_parts } = agreement;
  const { parts } = stepValues;

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_PERFORMANCE_SECURITY));
    setStepValues({ ...initState.current });
  }, [steps]);

  const {
    renderCheckbox,
    renderEditableCheckbox,
    renderSwitch,
    renderCustomCheckboxes,
    renderContent,
    renderCheckboxWithNumberInput
  } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    initContentValues
  });

  const renderParts = () => {
    return parts.map((part, index) => {
      const partObj = parts_ids.find((item) => item.id == part.id);

      return (
        <FormGroup key={`part-${part.id}`}>
          <div className="d-flex">
            <Input
              type="number"
              min={0}
              disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
              name={`part-input-${part.id.toString()}`}
              id={`part-input-${part.id.toString()}`}
              value={stepValues.parts[index].value || ''}
              invalid={hasError(errors, `parts.${index}.value`)}
              onChange={(event) => {
                stepValues.parts = stepValues.parts.map((item) => {
                  return item.id === part.id ? { ...item, value: parseInt(event.target.value) } : item;
                });

                setStepValues((values) => mapState(values));
              }}
            />
            <Label className="pl-4">
              {`% ceny całkowitej podanej w ofercie albo maksymalnej wartości nominalnej zobowiązania zamawiającego
              wynikającego z Umowy${few_parts ? ` w zakresie ${partObj.getName(index, 'zadania')}` : ''}`}
            </Label>
          </div>
          {hasError(errors, `parts.${index}.value`) && (
            <FormFeedback className="d-block">{getError(errors, `parts.${index}.value`)}</FormFeedback>
          )}
        </FormGroup>
      );
    });
  };

  const onStepDataImportClick = () => {
    setStepValues((values) => {
      return {
        ...values,
        parts: parts.map((part, index) => {
          return { id: part.id, value: stepDataImportValues.parts[index].value };
        })
      };
    });
  };

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <Authorize permissions={[IMPORT_ORDERS_ACCESS]}>
        {agreement.type !== AGREEMENT_TYPE_SUPPLEMENT && (
          <StepImportDataButton onSubmit={onStepDataImportClick} stepDataImportValues={stepDataImportValues} />
        )}
      </Authorize>
      {[
        renderSwitch(
          'safeguard_possibility',
          'Czy zamawiający przewiduje wniesienie zabezpieczenie należytego wykonania umowy?',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        ),
        stepValues.safeguard_possibility && [
          <Label className="pl-4" key="test">
            Ustala się Zabezpieczenie należytego wykonania Umowy w wysokości:
          </Label>,
          renderParts(),
          renderSwitch(
            'partial_refund_checked',
            'Czy zamawiający dopuszcza dokonanie częściowego zwrotu zabezpieczenia po wykonaniu części zamówienia?',
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          stepValues.partial_refund_checked && renderContent('partial_refund_content'),
          renderCheckbox(
            'safeguard_ways_possibility',
            'Zabezpieczenie może być wnoszone, według wyboru wykonawcy, w jednej lub w kilku następujących formach:',
            true
          ),
          stepValues.safeguard_ways_possibility && (
            <div className="pl-3">
              {[
                renderEditableCheckbox(
                  'money',
                  null,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderCheckbox(
                  'bank_surety_checked',
                  stepValues.bank_surety_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderCheckbox(
                  'bank_guarantee_checked',
                  stepValues.bank_guarantee_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderCheckbox(
                  'insurance_guarantee_checked',
                  stepValues.insurance_guarantee_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderCheckbox(
                  'parp_surety_checked',
                  stepValues.parp_surety_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderCheckbox(
                  'exchange_bills_checked',
                  stepValues.exchange_bills_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderCheckbox(
                  'securities_checked',
                  stepValues.securities_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderCheckbox(
                  'register_pledge_checked',
                  stepValues.register_pledge_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderEditableCheckbox(
                  'others',
                  null,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                )
              ]}
            </div>
          ),
          renderCheckbox(
            'bank_account_checked',
            stepValues.bank_account_content,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          renderCheckbox(
            'over_year_checked',
            stepValues.over_year_content,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          renderCheckboxWithNumberInput(
            'refund_date',
            REFUND_DATE_CONTENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            true,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          <hr />,
          renderCheckboxWithNumberInput(
            'claims_part',
            CLAIMS_PART_CONTENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            true,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          renderCheckboxWithNumberInput(
            'claims',
            CLAIMS_CONTENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            true,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          <hr />,
          renderCheckbox(
            'postponement_checked',
            stepValues.postponement_content,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          renderCheckbox('request_checked', stepValues.request_content, agreement.type === AGREEMENT_TYPE_SUPPLEMENT),
          renderCustomCheckboxes(
            null,
            null,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )
        ]
      ]}
    </AgreementFormWrapper>
  );
};

export default AgreementStepPerformanceSecurity;
