import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  AGREEMENT_STAGE_AGREEMENT,
  AGREEMENT_STEP_TYPE,
  AGREEMENT_STEP_DEADLINE,
  AgreementStageSlug,
  AgreementStepSlug
} from 'modules/Agreements/step';
import { ValidationPayload } from 'modules/Shared/type';
import {
  createAgreement,
  downloadGeneratedForm,
  getAgreementSteps,
  saveAgreementStep,
  updateAgreement
} from 'modules/Agreements/repository';
import Agreement from 'modules/Agreements/model/Agreement';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { getAgreementModeName, getStepValues } from 'modules/Agreements/helper/agreement';
import { breadcrumbCreateAgreement, breadcrumbUpdateAgreement } from 'modules/Agreements/breadcrumbs';
import {
  createAgreementToastError,
  createAgreementToastSuccess,
  downloadAgreementToastError,
  downloadAgreementToastSuccess,
  saveAgreementStepToastError,
  saveAgreementStepToastSuccess,
  updateAgreementToastError,
  updateAgreementToastSuccess
} from 'modules/Agreements/toasts';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_AGREEMENT_UPDATE } from 'modules/Agreements/routes';
import Validation from 'modules/Shared/exception/Validation';
import fetchFileRequest from 'modules/Shared/helper/APIRequests/fetchFileRequest';
import _isEqual from 'lodash/isEqual';
import { PathParams, Props } from 'modules/Agreements/components/Form';
import { fetchProceedingsDetails } from 'modules/Proceedings/repository';
import Proceedings from 'modules/Proceedings/model/Proceedings';
import { analytics } from 'firebase';
import { serialize } from 'object-to-formdata';
import _isEmpty from 'lodash/isEmpty';
import { AgreementFormStep } from 'modules/Agreements/type';

const useAgreementForm = (props: Props) => {
  const {
    configuration: { stages },
    mode
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const { id, stage: activeStage, step: activeStep } = useParams<PathParams>();
  const [agreement, setAgreement] = useState(null);
  const [proceeding, setProceeding] = useState(null);
  const [steps, setSteps] = useState({});
  const [stepsImportData, setStepsImportData] = useState({});
  const [modifiedStep, setModifiedStep] = useState(null);
  const [isModified, setIsModified] = useState<boolean | null>(null);
  const [filledSteps, setFilledSteps] = useState<AgreementStepSlug[]>([]);
  const [hiddenSteps, setHiddenSteps] = useState<AgreementStepSlug[]>([]);
  const [downloadMissingSteps, setDownloadMissingSteps] = useState<AgreementStepSlug[]>([]);
  const [fetching, setFetching] = useState(false);
  const [fetchingDoc, setFetchingDoc] = useState(false);
  const [initFetching, setInitFetching] = useState(Boolean(id));
  const [validation, setValidation] = useState<ValidationPayload>(null);
  const [modifiedModal, setModifiedModal] = useState<{ stage: AgreementStageSlug; step: AgreementStepSlug } | null>(
    null
  );
  const [downloadMissingStepsModal, setDownloadMissingStepsModal] = useState<boolean>(false);
  const [hidePreview, setHidePreview] = useState(false);

  const currentStage = stages.find((obj) => !activeStage || obj.slug === activeStage);
  const currentStep = currentStage.steps.find((obj) => !activeStep || obj.slug === activeStep);

  const getNextStep = (nextIndex: number): AgreementFormStep => {
    const step = currentStage.steps[nextIndex];

    if (hiddenSteps.includes(step?.slug)) {
      return getNextStep(nextIndex + 1);
    }

    return step;
  };

  const nextStep = getNextStep(currentStage.steps.indexOf(currentStep) + 1);

  useEffect(() => {
    if (id) {
      (async () => {
        await initFetch();
      })();
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      dispatch(
        managePageAction({
          title: `Umowa - ${getAgreementModeName(mode)}`,
          breadcrumb: breadcrumbUpdateAgreement(mode)
        })
      );
    } else {
      dispatch(
        managePageAction({
          title: 'Tworzenie umowy',
          breadcrumb: breadcrumbCreateAgreement()
        })
      );
    }
  }, []);

  useEffect(() => {
    const wrappers = document.querySelectorAll<HTMLElement>('.agreement-step-preview-wrapper');
    const contents = document.querySelectorAll<HTMLElement>('.agreement-step-preview-content');

    const fn = (nodes: NodeListOf<HTMLElement>) => {
      nodes.forEach((node) => {
        const paragraphs = node.querySelectorAll('p');

        if (_.isEmpty(paragraphs)) {
          node.style.display = 'none';
        } else {
          node.style.display = 'block';
        }
      });
    };

    fn(wrappers);
    fn(contents);
  }, [modifiedStep]);

  const initFetch = async () => {
    try {
      const {
        data: { agreement: fetchedAgreement, data, data_proceedings, filled_steps, hidden_steps, missing_steps }
      } = await getAgreementSteps(id);
      setFilledSteps(filled_steps);
      setHiddenSteps(hidden_steps);
      setDownloadMissingSteps(missing_steps);
      setSteps(data);
      setStepsImportData(data_proceedings);
      setAgreement(new Agreement(fetchedAgreement));
      if (fetchedAgreement.proceeding_id != null) {
        const {
          data: { data: fetchedProceeding }
        } = await fetchProceedingsDetails(fetchedAgreement.proceeding_id);
        setProceeding(new Proceedings(fetchedProceeding));
      }
      setInitFetching(false);
    } catch (error) {
      throw error;
    }
  };

  const onSubmitStep = async (stepData: any): Promise<boolean> => {
    try {
      setFetching(true);
      setValidation(null);

      const reqData =
        currentStep.slug === AGREEMENT_STEP_DEADLINE
          ? serialize(stepData, { indices: true, booleansAsIntegers: true })
          : stepData;

      if (currentStep.slug === AGREEMENT_STEP_TYPE) {
        let id = agreement?.id;
        let mode = agreement?.mode;

        if (agreement) {
          await updateAgreement(id, reqData);
          await initFetch();
          dispatch(addToastAction(updateAgreementToastSuccess()));
        } else {
          const {
            data: {
              data: { id: newId, mode: agreementMode }
            }
          } = await createAgreement(reqData);

          id = newId;
          mode = agreementMode;

          dispatch(addToastAction(createAgreementToastSuccess()));
          analytics.logEvent('agreements_activation');
          history.push(getPathUrl(ROUTE_AGREEMENT_UPDATE, { id, mode, stage: AGREEMENT_STAGE_AGREEMENT }));
        }
        setFetching(false);
      } else {
        const {
          data: { filled_steps, hidden_steps, data, missing_steps }
        } = await saveAgreementStep(id, currentStep.slug, reqData);

        dispatch(addToastAction(saveAgreementStepToastSuccess(currentStep.slug, mode)));
        setFilledSteps(filled_steps);
        setHiddenSteps(hidden_steps);
        setDownloadMissingSteps(missing_steps);
        setSteps(data);
        setFetching(false);
        if (nextStep) {
          goTo(AGREEMENT_STAGE_AGREEMENT, nextStep.slug, true);
        }
      }

      return true;
    } catch (error) {
      if (error instanceof Validation) {
        setValidation(error.getPayload());
      }
      if (currentStep.slug === AGREEMENT_STEP_TYPE) {
        if (agreement) {
          dispatch(addToastAction(updateAgreementToastError()));
        } else {
          dispatch(addToastAction(createAgreementToastError()));
        }
      } else {
        dispatch(addToastAction(saveAgreementStepToastError(currentStep.slug, mode)));
      }
      setFetching(false);

      return false;
    }
  };

  const downloadAgreement = async () => {
    if (!_isEmpty(downloadMissingSteps)) {
      setDownloadMissingStepsModal(true);
      return;
    }

    setFetchingDoc(true);
    const { success } = await fetchFileRequest(downloadGeneratedForm, id);
    if (success) {
      dispatch(addToastAction(downloadAgreementToastSuccess()));
    } else {
      dispatch(addToastAction(downloadAgreementToastError()));
    }
    setFetchingDoc(false);
  };

  const scrollTo = (step: AgreementStepSlug) => {
    const className = `.agreement-step-${step}`;
    const element = document.querySelector(className);

    if (element instanceof HTMLElement) {
      element.parentElement.scrollTop = element.offsetTop - 190;
    }
  };

  const goTo = (stage: AgreementStageSlug, step: AgreementStepSlug, confirmed = false) => {
    const stepData = getStepValues(steps, currentStep.slug);
    let stepModified = modifiedStep ? !_isEqual(stepData, modifiedStep) : false;

    if (isModified != null) {
      stepModified = isModified;
    }

    if (stepModified && !confirmed) {
      setModifiedModal({ stage, step });
    } else {
      setModifiedStep(null);
      setIsModified(null);
      setValidation(null);
      history.push(getPathUrl(ROUTE_AGREEMENT_UPDATE, { id, mode, stage, step }));
      scrollTo(step);
    }
  };

  const onToggleModifiedModal = (confirmed: boolean) => {
    if (confirmed) {
      goTo(modifiedModal.stage, modifiedModal.step, true);
    }

    setModifiedModal(null);
  };

  const onSubmitModifiedModal = async () => {
    const target = { ...modifiedModal };
    setModifiedModal(null);
    const success = await onSubmitStep(modifiedStep);
    if (success) {
      goTo(target.stage, target.step, true);
    }
  };

  return {
    initFetching,
    currentStage,
    currentStep,
    filledSteps,
    hiddenSteps,
    agreement,
    steps,
    stepsImportData,
    modifiedStep,
    modifiedModal,
    fetching,
    stages,
    fetchingDoc,
    validation,
    mode,
    proceeding,
    hidePreview,
    onSubmitStep,
    initFetch,
    onToggleModifiedModal,
    onSubmitModifiedModal,
    goTo,
    setIsModified,
    setModifiedStep,
    downloadAgreement,
    setHidePreview,
    downloadMissingSteps,
    downloadMissingStepsModal,
    setDownloadMissingStepsModal
  };
};

export default useAgreementForm;
