import { suffixLabel } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import Date from 'modules/Layout/component/Input/Date';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { IOrderFormValues } from 'modules/ExecutorOrder/component/OrderForm';
import { setInputNumbers } from 'modules/Layout/component/Input/TextNumber/input';

export interface Props {
  values: IOrderFormValues;
  setValues: React.Dispatch<React.SetStateAction<IOrderFormValues>>;
  errors?: ValidationErrors;
  disabled?: boolean;
  required?: boolean;
}

const OrderBaseFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, setValues, errors, disabled, required } = props;

  const {
    name,
    identifier,
    principal_name,
    contract_number,
    contract_place,
    contract_value,
    contract_date,
    notice_number
  } = values;

  return (
    <fieldset className="m-0" disabled={disabled}>
      <FormGroup>
        <Label for="input-name">Nazwa postępowania</Label>
        <Input
          type="text"
          id="input-name"
          value={name}
          onChange={(event) => setValues({ ...values, name: event.target.value })}
          invalid={hasError(errors, 'name')}
        />
        {hasError(errors, 'name') && <FormFeedback>{getError(errors, 'name')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Label for="input-identifier">Numer postępowania</Label>
        <Input
          type="text"
          id="input-identifier"
          value={identifier}
          onChange={(event) => setValues({ ...values, identifier: event.target.value })}
          invalid={hasError(errors, 'identifier')}
        />
        {hasError(errors, 'identifier') && <FormFeedback>{getError(errors, 'identifier')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Label for="input-principal_name">{suffixLabel('Nazwa zamawiającego', required)}</Label>
        <Input
          type="text"
          id="input-principal_name"
          value={principal_name}
          onChange={(event) => setValues({ ...values, principal_name: event.target.value })}
          maxLength={500}
          invalid={hasError(errors, 'principal_name')}
          required={required}
        />
        {hasError(errors, 'principal_name') && <FormFeedback>{getError(errors, 'principal_name')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Label for="input-contract_number">Numer umowy o udzielenie zamówienia</Label>
        <Input
          type="text"
          id="input-contract_number"
          value={contract_number}
          onChange={(event) => setValues({ ...values, contract_number: event.target.value })}
          maxLength={255}
          invalid={hasError(errors, 'contract_number')}
        />
        {hasError(errors, 'contract_number') && <FormFeedback>{getError(errors, 'contract_number')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Label for="input-contract_place">Miejsce realizacji umowy</Label>
        <Input
          type="text"
          id="input-contract_place"
          value={contract_place}
          onChange={(event) => setValues({ ...values, contract_place: event.target.value })}
          maxLength={255}
          invalid={hasError(errors, 'contract_place')}
          disabled
        />
        {hasError(errors, 'contract_place') && <FormFeedback>{getError(errors, 'contract_place')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Label for="input-contract_value">Wartość zawartej umowy</Label>
        <Input
          type="number"
          id="input-contract_value"
          value={contract_value}
          onChange={(event) => setInputNumbers(event, values, setValues, 'contract_value')}
          step={0.01}
          min={0.01}
          max={999999999999.99}
          invalid={hasError(errors, 'contract_value')}
          disabled
        />
        {hasError(errors, 'contract_value') && <FormFeedback>{getError(errors, 'contract_value')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Label for="date_from">Data zawarcia umowy</Label>
        <Date
          name="contract_date"
          value={contract_date}
          min=""
          max=""
          onChange={(val) => setValues({ ...values, contract_date: val })}
          disabled
        />
      </FormGroup>
      <FormGroup>
        <Label for="input-notice_number">Numer ogłoszenia w Biuletynie Zamówień Publicznych</Label>
        <Input
          type="text"
          id="input-notice_number"
          value={notice_number}
          onChange={(event) => setValues({ ...values, notice_number: event.target.value })}
          maxLength={255}
          invalid={hasError(errors, 'notice_number')}
          disabled
        />
        {hasError(errors, 'notice_number') && <FormFeedback>{getError(errors, 'notice_number')}</FormFeedback>}
      </FormGroup>
    </fieldset>
  );
};

export default OrderBaseFieldset;
