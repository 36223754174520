import _has from 'lodash/has';
import { ProfileValues } from 'modules/Profile/type';
import { ValidationErrors } from 'modules/Shared/type';
import UserFieldset from 'modules/User/component/Fieldset';
import React, { useState } from 'react';
import { Button, Form as FormStrap } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ROUTE_PROFILE } from 'modules/Profile/routes';
import { UserIdentityValues } from 'modules/User/type';

export interface Props {
  profile: ProfileValues;
  errors?: ValidationErrors;
  disabled?: boolean;
  cancelRedirect?: string;
  awaitingEmail?: string;
  nipAcceptance?: boolean;
  submit: (profile: UserIdentityValues) => void;
  isAdmin?: boolean;
}

const Form: React.FC<Props> = ({
  profile,
  errors,
  disabled,
  submit,
  cancelRedirect,
  awaitingEmail,
  nipAcceptance,
  isAdmin
}) => {
  const [values, setValue] = useState({
    first_name: profile?.first_name ?? '',
    last_name: profile?.last_name ?? '',
    email: profile?.email ?? '',
    phone: profile?.phone ?? '',
    nip: profile?.nip ?? ''
  });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = event.target;

    if (_has(values, key)) {
      setValue({ ...values, [key]: value });
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submit(values);
  };

  const hasValue = () => !Object.values(values).some(Boolean);

  return (
    <FormStrap onSubmit={onSubmit}>
      <UserFieldset
        disabled={disabled}
        awaitingEmail={awaitingEmail}
        nipAcceptance={nipAcceptance}
        values={values}
        errors={errors}
        onChange={onChange}
        isAdmin={isAdmin}
        required
      />
      <div className="form-actions">
        <Button
          type="submit"
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light"
          disabled={hasValue() || disabled}
        >
          Zapisz
        </Button>
        <Link to={cancelRedirect || ROUTE_PROFILE} className="cancel waves-effect waves-light">
          Anuluj
        </Link>
      </div>
    </FormStrap>
  );
};

export default Form;
