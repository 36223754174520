/* eslint-disable */
import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function DateIcon(props: IconProps): JSX.Element {
  const { fill = '#666666', height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 35 35">
      <g id="Group_443" data-name="Group 443" transform="translate(-287 -432)">
        <path
          id="Path_1324"
          data-name="Path 1324"
          d="M317.964,432.5H290.807a3.184,3.184,0,0,0-3.307,3.348v27.244a3.233,3.233,0,0,0,3.307,3.407h27.157a3.42,3.42,0,0,0,3.536-3.407V435.848A3.373,3.373,0,0,0,317.964,432.5Z"
          fill="none"
          stroke={fill}
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <line
          id="Line_77"
          data-name="Line 77"
          x2="34"
          transform="translate(287.5 442.5)"
          fill="none"
          stroke={fill}
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <line
          id="Line_78"
          data-name="Line 78"
          y2="23.723"
          transform="translate(293.413 442.777)"
          fill="none"
          stroke={fill}
          strokeWidth="1"
        />
        <line
          id="Line_79"
          data-name="Line 79"
          y2="23.723"
          transform="translate(300.804 442.777)"
          fill="none"
          stroke={fill}
          strokeWidth="1"
        />
        <line
          id="Line_80"
          data-name="Line 80"
          y2="23.723"
          transform="translate(308.196 442.777)"
          fill="none"
          stroke={fill}
          strokeWidth="1"
        />
        <line
          id="Line_81"
          data-name="Line 81"
          y2="23.723"
          transform="translate(315.587 442.777)"
          fill="none"
          stroke={fill}
          strokeWidth="1"
        />
        <line
          id="Line_82"
          data-name="Line 82"
          x2="34"
          transform="translate(287.5 460.5)"
          fill="none"
          stroke={fill}
          strokeWidth="1"
        />
        <line
          id="Line_83"
          data-name="Line 83"
          x2="34"
          transform="translate(287.5 454.5)"
          fill="none"
          stroke={fill}
          strokeWidth="1"
        />
        <line
          id="Line_84"
          data-name="Line 84"
          x2="34"
          transform="translate(287.5 448.5)"
          fill="none"
          stroke={fill}
          strokeWidth="1"
        />
        <path
          id="Path_1325"
          data-name="Path 1325"
          d="M296.27,435.963a1.23,1.23,0,1,1-1.23-1.231A1.23,1.23,0,0,1,296.27,435.963Z"
          transform="translate(3.018 1.067)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1326"
          data-name="Path 1326"
          d="M304.986,435.963a1.23,1.23,0,1,1-1.23-1.231A1.23,1.23,0,0,1,304.986,435.963Z"
          transform="translate(7.186 1.067)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
