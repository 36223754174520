import React from 'react';
import ListingPerPage from 'modules/Layout/component/Listing/PerPage';
import { PaginationMeta, PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import { createSearchParams } from 'modules/Shared/helper/params';

type Props = { meta?: PaginationMeta; fetchList?: (payload?: SearchParams) => Promise<void> };

const PerPageTable: React.FC<Props> = ({ meta, fetchList }) => {
  const setParams = (payload: PartialSearchingProps) => fetchList(createSearchParams(payload));

  return <ListingPerPage meta={meta} setParams={setParams} />;
};

export default PerPageTable;
