import React, { useEffect } from 'react';
import { createSearchingProps } from 'modules/Shared/helper/params';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { clearProceedingsList, setProceedingsListParamsAction } from 'modules/Proceedings/action/ProceedingsList';
import Proceedings from 'modules/Proceedings/model/Proceedings';
import { breadcrumbRouteProceedings } from 'modules/Proceedings/breadcrumbs';
import useAdminProceedingsList from 'modules/Proceedings/component/Lists/AdminListHooks';
import useClientProceedingsList from 'modules/Proceedings/component/Lists/ClientListHooks';
import { ROUTE_PROCEEDINGS_PLANNING, ROUTE_PROCEEDINGS_OPEN } from 'modules/Proceedings/routes';
import { PROCEEDINGS_INIT_TYPES_VALUES } from 'modules/Proceedings/type';
import { RootState } from 'app/reducer';
import { ROLE_ADMIN, ROLE_CLIENT } from 'modules/User/model/UserRole';

function withProceedingsList<P>(
  AdminWrappedComponent: React.ComponentType<P>,
  ClientWrappedComponent: React.ComponentType<P>,
  proceeding_type: number,
  title: string
) {
  const Component = (props: P) => {
    const dispatch = useDispatch();
    const { role } = useSelector((state: RootState) => state.auth.user);

    const isAdmin = role?.slug === ROLE_ADMIN;

    const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
    const setParams = (payload: PartialSearchingProps) => dispatch(setProceedingsListParamsAction(payload));

    const searchProps = createSearchingProps(
      '',
      Proceedings.getFilterableAttributes(),
      Proceedings.getSortableAttributes()
    );

    useEffect(() => {
      dispatch(clearProceedingsList());

      managePage({
        title,
        breadcrumb: breadcrumbRouteProceedings(proceeding_type)
      });

      if (role?.slug !== ROLE_CLIENT) {
        setParams({ ...searchProps, filter: { proceeding_type } });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isAdmin ? <AdminWrappedComponent {...props} /> : <ClientWrappedComponent {...props} />;
  };
  return Component;
}

const AdminProceedingsPlanningList = () => {
  const { jsxFnc } = useAdminProceedingsList(ROUTE_PROCEEDINGS_PLANNING, PROCEEDINGS_INIT_TYPES_VALUES.PLANNING);

  return jsxFnc();
};

const ClientProceedingsPlanningList = () => {
  const { jsxFnc } = useClientProceedingsList(ROUTE_PROCEEDINGS_PLANNING, PROCEEDINGS_INIT_TYPES_VALUES.PLANNING);

  return jsxFnc();
};

export const ProceedingsPlanningListView = withProceedingsList(
  AdminProceedingsPlanningList,
  ClientProceedingsPlanningList,
  PROCEEDINGS_INIT_TYPES_VALUES.PLANNING,
  'Wyszukiwarka planowanych postępowań - lista'
);

const AdminProceedingsOpenList = () => {
  const { jsxFnc } = useAdminProceedingsList(ROUTE_PROCEEDINGS_OPEN, PROCEEDINGS_INIT_TYPES_VALUES.OPEN);

  return jsxFnc();
};

const ClientProceedingsOpenList = () => {
  const { jsxFnc } = useClientProceedingsList(ROUTE_PROCEEDINGS_OPEN, PROCEEDINGS_INIT_TYPES_VALUES.OPEN);

  return jsxFnc();
};

export const ProceedingsOpenListView = withProceedingsList(
  AdminProceedingsOpenList,
  ClientProceedingsOpenList,
  PROCEEDINGS_INIT_TYPES_VALUES.OPEN,
  'Wyszukiwarka prowadzonych postępowań - lista'
);
