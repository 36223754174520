import listReducer from 'modules/User/reducer/list';
import executorsExtendedReducer from 'modules/User/reducer/executorsExtended';
import executorsExtendedRequestsReducer from 'modules/User/reducer/executorsExtendedRequests';
import invoiceListReducer from 'modules/User/reducer/invoiceList';
import { combineReducers, Reducer } from 'redux';
import { UserState } from 'modules/User/state';

const reducer = (): Reducer<UserState> => {
  const reducers = {
    list: listReducer,
    executorsExtendedList: executorsExtendedReducer,
    executorsExtendedRequestsList: executorsExtendedRequestsReducer,
    invoiceList: invoiceListReducer
  };

  return combineReducers<UserState>(reducers);
};

export default reducer;
