import { ColorVariants, Message } from 'modules/Shared/type';
import { AxiosPromise } from 'axios';

type Response = {
  message?: Message;
  cancelled?: boolean;
  success?: boolean;
};

const deleteRequest = async (request: (...args: any[]) => AxiosPromise<any>, ...args: any[]): Promise<Response> => {
  let message: Message = null;

  try {
    await request(...args);

    return { success: true };
  } catch (error) {
    if (error?.message === 'AxiosCancel') {
      return { cancelled: true };
    }

    message = {
      value: error?.error?.response?.data?.message || error?.message || 'Something went wrong.',
      variant: ColorVariants.Danger
    };

    return { message, success: false };
  }
};

export default deleteRequest;
