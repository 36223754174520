import { RootState } from 'app/reducer';
import Authorize from 'modules/Auth/container/Authorize';
import Executor from 'modules/Executor/model/Executor';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { getPathUrl } from 'modules/Shared/helper/api';
import { PartialSearchingProps } from 'modules/Shared/type';
import { setExecutorAdminListParamsAction } from 'modules/Executor/action/adminList';
import { EXECUTORS_DELETE, EXECUTORS_EDIT, EXECUTORS_DETAILS } from 'modules/Executor/permissions';
import { ROUTE_EXECUTOR_ADMINS, ROUTE_EXECUTOR_ADMINS_UPDATE } from 'modules/Executor/routes';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import EditUserIcon from 'modules/Layout/component/Icon/EditUser';
import actionDeleteTrashIconList from 'modules/Shared/helper/actionDeleteTrashIconList';

type Props = { onDeleteClick?: (executor: Executor) => void };

const ExecutorAdminTable: React.FC<Props> = ({ onDeleteClick }) => {
  const dispatch = useDispatch();
  const { executors, sort } = useSelector((state: RootState) => state.executor.adminList);

  const setParams = (payload: PartialSearchingProps) => dispatch(setExecutorAdminListParamsAction(payload));

  const redirectLink = (value: string, id: number) => (
    <Authorize permissions={[EXECUTORS_DETAILS]} fallback={value}>
      <Link to={getPathUrl(ROUTE_EXECUTOR_ADMINS, { id })}>{value}</Link>
    </Authorize>
  );

  const cols: TableCol<Executor>[] = [
    {
      property: 'name',
      label: 'Nazwa',
      sortable: true,
      value: (row) => redirectLink(row.name, row.id)
    },
    {
      property: 'first_name',
      label: 'Imię',
      sortable: true,
      value: (row) => redirectLink(row.first_name, row.id)
    },
    {
      property: 'last_name',
      label: 'Nazwisko',
      sortable: true,
      value: (row) => redirectLink(row.last_name, row.id)
    },
    {
      property: 'email',
      label: 'Email',
      sortable: true,
      value: (row) => redirectLink(row.email, row.id)
    },
    {
      property: 'nip',
      label: 'NIP',
      sortable: true,
      value: (row) => redirectLink(row.nip, row.id)
    },
    {
      property: 'krs',
      label: 'KRS',
      sortable: true
    },
    {
      property: 'city',
      label: 'Miasto',
      sortable: true
    },
    {
      property: 'street',
      label: 'Ulica',
      sortable: true
    },
    {
      property: 'postal_code',
      label: 'Kod pocztowy',
      sortable: true
    },
    {
      property: 'post_office',
      label: 'Miejscowość poczty',
      sortable: true
    },
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            <Authorize permissions={[EXECUTORS_EDIT]}>
              <ActionUpdate
                className="mx-1"
                title="Edycja danych podstawowych"
                label={<EditUserIcon height="20px" />}
                to={getPathUrl(ROUTE_EXECUTOR_ADMINS_UPDATE, { id: row.id })}
              />
            </Authorize>
            {onDeleteClick && (
              <Authorize permissions={[EXECUTORS_DELETE]}>
                {actionDeleteTrashIconList(row, {
                  targetIdName: 'DeleteExecutorId',
                  onDeleteClick,
                  tooltipContent:
                    'Nie można usunąć, ponieważ co najmniej jeden klient posiada aktywny kredyt na dostęp do tego wykonawcy.',
                  title: 'Usuń wykonawcę'
                })}
              </Authorize>
            )}
          </div>
        );
      }
    }
  ];

  return <Table cols={cols} rows={executors} sort={sort} onSort={(params) => setParams({ sort: params })} />;
};

export default ExecutorAdminTable;
