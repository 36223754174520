import { Message, ValidationErrors } from 'modules/Shared/type';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './index.scss';
import CardBox from 'modules/Layout/component/CardBox';
import useBasicInput from 'modules/Shared/components/Documents/hooks/useBasicInput';
import useEditableInput from 'modules/Shared/components/Documents/hooks/useEditableInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/reducer';
import Date from 'modules/Layout/component/Input/Date';
import { CustomInput, Form as FormStrap, FormFeedback, FormGroup, Label } from 'reactstrap';
import { DATE_FORMAT_VALUE, formatDate, formatMoment, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import moment from 'moment';
import Proceedings from 'modules/Proceedings/model/Proceedings';
import Loader from 'modules/Layout/component/Loader';
import updateRequest from 'modules/Shared/helper/APIRequests/updateRequest';
import { downloadRequestProceedings, sendRequestProceedings } from 'modules/Proceedings/repository';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { addToastAction } from 'modules/Layout/action';
import { downloadRequestProceedingsToastSuccess, sendRequestProceedingsToastSuccess } from 'modules/Proceedings/toasts';
import Alert from 'modules/Layout/component/Alert';
import fetchFileRequest from 'modules/Shared/helper/APIRequests/fetchFileRequest';
import { suffixLabel } from 'modules/Layout/helper/misc';
import TooltipWithBtn from 'modules/Layout/component/Tooltip/WithButton';
import { getError, hasError } from 'modules/Shared/helper/validation';
import useWysiwyg from 'modules/Layout/component/Wysiwyg';

export interface Props {
  proceedings: Proceedings;
}

const checkBoxContent1 =
  '<p>Jednocześnie wykonawca przypomina, że jeżeli zamawiający nie udzieli wyjaśnień w terminach, o których mowa w 284 ust. 2 p.z.p., przedłuża termin składania ofert o czas niezbędny do zapoznania się wszystkich zainteresowanych wykonawców z wyjaśnieniami niezbędnymi do należytego przygotowania i złożenia ofert.</p>';
const checkBoxContent2 = '<p>Odpowiedzi należy udzielić na wskazany powyżej adres e-mail wykonawcy.</p>';

const SendMessageForm: React.FC<Props> = ({ proceedings }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [justificationValueWysiwygDisplayStatus, setJustificationValueWysiwygDisplayStatus] = useState(false);
  const [preScopeChekcedStatus, setPreScopeChekcedStatus] = useState(true);
  const { id } = useParams<{ id: string }>();
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();

  const { value: cityValue, inputJsx: cityInputJsx } = useBasicInput(
    'Miejscowość',
    { errors, errorName: 'send_place' },
    user?.company?.city ?? '',
    {
      required: true,
      type: 'text'
    }
  );

  const [date, setDate] = useState(formatMoment(moment(), DATE_FORMAT_VALUE));

  const { value: nameValue, inputJsx: nameJsx } = useBasicInput(
    'Wykonawca',
    { errors, errorName: 'executor_name' },
    user?.name ?? '',
    {
      required: true,
      type: 'text'
    }
  );

  const { value: emailValue, inputJsx: emailJsx } = useBasicInput(
    'Adres e-mail wykonawcy',
    { errors, errorName: 'executor_email' },
    user?.company?.email ?? '',
    {
      required: true,
      type: 'email'
    }
  );

  const { value: titleValue, inputJsx: titleJsx } = useBasicInput(
    'Tytuł wniosku',
    { errors, errorName: 'title' },
    'WNIOSEK O ZMIANĘ LUB WYJAŚNIENIE TREŚCI SWZ',
    {
      required: true,
      type: 'text'
    }
  );

  const { displayWysiwyg: scopeValueWysiwyg, getContent: scopeValueContent } = useWysiwyg({
    title: 'Zakres wyjaśnienia treści',
    tooltip: { specialSign: true },
    required: true,
    isInvalid: hasError(errors, 'scope')
  });
  const {
    displayWysiwyg: justificationValueWysiwyg,
    getContent: justificationValueContent,
    resetContent: resetJustificationValueContent
  } = useWysiwyg({
    title: 'Uzasadnienie',
    tooltip: { specialSign: true }
  });

  const scopeJsx = () => (
    <FormGroup className="documents mb-2 d-flex flex-column">
      {scopeValueWysiwyg && scopeValueWysiwyg()}

      {hasError(errors, 'scope') && <FormFeedback className="d-block">{getError(errors, 'scope')}</FormFeedback>}
    </FormGroup>
  );

  const justificationJsx = () => (
    <FormGroup className="documents d-flex flex-column mt-4">
      <CustomInput
        id="justification-value-wysiwyg-display-status"
        className="mb-1"
        type="switch"
        label="Dodaj uzasadnienie"
        checked={justificationValueWysiwygDisplayStatus}
        onChange={(event) => {
          event.persist();
          resetJustificationValueContent();
          setJustificationValueWysiwygDisplayStatus(event.currentTarget.checked);
        }}
      />

      {justificationValueWysiwyg && justificationValueWysiwygDisplayStatus && justificationValueWysiwyg()}

      {hasError(errors, 'justification') && (
        <FormFeedback className="d-block">{getError(errors, 'justification')}</FormFeedback>
      )}
    </FormGroup>
  );

  const {
    value: firstCheckboxValue,
    checkboxValue: firstCheck,
    inputJsx: firstCheckboxJsx
  } = useEditableInput('', checkBoxContent1, true);
  const {
    value: secondCheckboxValue,
    checkboxValue: secondCheck,
    inputJsx: secondCheckboxJsx
  } = useEditableInput('', checkBoxContent2, true);

  const data = {
    send_place: cityValue,
    send_date: date,
    executor_name: nameValue,
    executor_email: emailValue,
    title: titleValue,
    pre_scope_checked: preScopeChekcedStatus,
    scope: scopeValueContent(),
    justification: justificationValueContent(),
    longer_offers_send_date_checked: firstCheck,
    response_to_executor_email_checked: secondCheck,
    longer_offers_send_date_content: firstCheckboxValue,
    response_to_executor_email_content: secondCheckboxValue
  };

  const dispatchSendToast = () => dispatch(addToastAction(sendRequestProceedingsToastSuccess()));
  const dispatchDownloadToast = () => dispatch(addToastAction(downloadRequestProceedingsToastSuccess()));
  const cancelToken = useCancelToken();
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (loading) return;

    setLoading(true);
    if (message) setMessage(null);
    if (errors) setErrors(null);

    const {
      cancelled,
      message: messageRes,
      success,
      error
    } = await updateRequest(sendRequestProceedings, id, data, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchSendToast();
    }
    if (error) setErrors(error);
    if (messageRes) setMessage(messageRes);
    setLoading(false);
  };

  const onDownload = async () => {
    if (loading) return;

    setLoading(true);
    if (message) setMessage(null);
    if (errors) setErrors(null);

    const {
      cancelled,
      message: messageRes,
      success,
      error
    } = await fetchFileRequest(downloadRequestProceedings, id, data, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDownloadToast();
    }
    if (error) setErrors(error);
    if (messageRes) setMessage(messageRes);
    setLoading(false);
  };

  const invalid = !cityValue || !date || !nameValue || !emailValue;

  return (
    <div className="row">
      {loading && <Loader />}
      <div className="col-xl-6">
        <CardBox heading="Wypełnij pola" paddingRight="30px" paddingBottom="25px" className="send-message-column">
          {message && <Alert message={message} />}
          <FormStrap onSubmit={onSubmit}>
            {cityInputJsx()}
            <FormGroup className="mb-0">
              <Label for="input-send_date">{suffixLabel('Data', true)}</Label>
              <Date
                id="input-send_date"
                value={date ?? ''}
                onChange={(value) => setDate(value)}
                invalid={hasError(errors, 'send_date')}
                required
              />
              {hasError(errors, 'send_date') && (
                <FormFeedback className="d-block">{getError(errors, 'send_date')}</FormFeedback>
              )}
            </FormGroup>
            {nameJsx()}
            {emailJsx()}
            {titleJsx()}
            <FormGroup>
              <CustomInput
                id="pre_scope_checked"
                className="mb-1"
                type="switch"
                label="Dodaj wstęp do zakresu wyjaśnienia treści"
                checked={preScopeChekcedStatus}
                onChange={(event) => {
                  event.persist();
                  setPreScopeChekcedStatus(event.currentTarget.checked);
                }}
              />
            </FormGroup>
            {scopeJsx()}
            {justificationJsx()}
            {firstCheckboxJsx()}
            {secondCheckboxJsx()}
            <div className="d-flex flex-wrap justify-content-center">
              {proceedings?.email_contact_checked && (
                <TooltipWithBtn
                  type="submit"
                  targetId="ProceedingsSendFormBtn"
                  btnContent="Wyślij"
                  tooltipContent="Wypełnij wszystkie wymagane pola."
                  className="pzpeu-btn-disabled waves-effect waves-light btn btn-primary"
                  tooltipPlacement="top"
                  disabled={invalid}
                />
              )}
              <TooltipWithBtn
                targetId="ProceedingsDownloadFormBtn"
                btnContent="Pobierz"
                tooltipContent="Wypełnij wszystkie wymagane pola."
                className="pzpeu-btn-disabled waves-effect waves-light btn btn-primary"
                wrapperClassName=" ml-3"
                tooltipPlacement="top"
                onClick={onDownload}
                disabled={invalid}
              />
            </div>
          </FormStrap>
        </CardBox>
      </div>
      <div className="col-xl-6">
        <CardBox heading="Podgląd" paddingRight="30px" paddingBottom="25px" className="send-message-column">
          <FormStrap className="text-justify">
            <div className="w-100">
              <div className="d-flex justify-content-end">
                {cityValue}, dnia {ifContainsDateFormat(date, formatDate)} roku
              </div>

              <div className="mt-3">
                {proceedings?.contractor_name && (
                  <div>
                    <Label>
                      <strong style={{ fontSize: '14.4px' }}>Zamawiający:</strong>
                    </Label>
                    <div>{proceedings.contractor_name}</div>
                  </div>
                )}

                {nameValue && (
                  <div className="mt-2">
                    <Label>
                      <strong style={{ fontSize: '14.4px' }}>Wykonawca:</strong>
                    </Label>
                    <div>{nameValue}</div>
                  </div>
                )}
                {proceedings?.name && (
                  <div className="mt-2">
                    <Label>
                      <strong style={{ fontSize: '14.4px' }}>Nazwa postępowania:</strong>
                    </Label>
                    <div>{proceedings.name}</div>
                  </div>
                )}
                {proceedings?.identifier && (
                  <div className="mt-2">
                    <Label>
                      <strong style={{ fontSize: '14.4px' }}>Nr referencyjny postępowania:</strong>
                    </Label>
                    <div>{proceedings.identifier}</div>
                  </div>
                )}
                {proceedings?.contractor_email && (
                  <div className="mt-2">
                    <Label>
                      <strong style={{ fontSize: '14.4px' }}>Adres e-mail zamawiającego:</strong>
                    </Label>
                    <div>{proceedings.contractor_email}</div>
                  </div>
                )}
                {emailValue && (
                  <div className="mt-2">
                    <Label>
                      <strong style={{ fontSize: '14.4px' }}>Adres e-mail wykonawcy:</strong>
                    </Label>
                    <div>{emailValue}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-4 d-flex justify-content-center">
              <strong>{titleValue}</strong>
            </div>

            {preScopeChekcedStatus && (
              <p className="mt-3">
                Działając w imieniu wykonawcy, na podstawie art. 284 ust. 1 ustawy z dnia 11 września 2019 roku Prawo
                zamówień publicznych – dalej „p.z.p.” , zwracam się z wnioskiem o wyjaśnienie treści SWZ w następującym
                zakresie:
              </p>
            )}

            <div className="mt-3">
              <div className="wysiwyg-converter-display" dangerouslySetInnerHTML={{ __html: scopeValueContent() }} />
            </div>

            {justificationValueWysiwygDisplayStatus && (
              <>
                <div className="mt-3 d-flex justify-content-center">
                  <strong>UZASADNIENIE</strong>
                </div>

                <div className="mt-3">
                  <div
                    className="wysiwyg-converter-display"
                    dangerouslySetInnerHTML={{ __html: justificationValueContent() }}
                  />
                </div>
              </>
            )}

            {firstCheck && (
              <div className="mt-3">
                <div dangerouslySetInnerHTML={{ __html: firstCheckboxValue }} />
              </div>
            )}
            {secondCheck && (
              <div className="mt-3">
                <div dangerouslySetInnerHTML={{ __html: secondCheckboxValue }} />
              </div>
            )}
          </FormStrap>
        </CardBox>
      </div>
    </div>
  );
};

export default SendMessageForm;
