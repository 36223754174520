import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Collapse } from 'reactstrap';
import './index.scss';
import ArrowIcon from 'modules/Layout/component/Icon/Arrow';

export interface Props {
  entity: { title: string; content: JSX.Element };
  isInvalid?: boolean;
  style?: { [key: string]: string };
  headerStyle?: { [key: string]: string };
  accordionContentStyle?: { [key: string]: string };
  defaultCollapse?: boolean;
}

const Accordion: React.FC<Props> = (props) => {
  const { entity, isInvalid: isInvalidProp, style = {}, headerStyle = {}, accordionContentStyle = {}, defaultCollapse } = props;
  const [isCollapseOpen, setCollapseOpen] = useState(defaultCollapse);
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    setIsInvalid(isInvalidProp);
    if (isInvalidProp) {
      setCollapseOpen(true);
    }
  }, [isInvalidProp]);

  useEffect(() => {
    setCollapseOpen(defaultCollapse);
  }, [defaultCollapse]);

  return (
    <Card className={`pzpeu-accordion ${isInvalid ? 'invalid' : ''}`} style={style}>
      <CardHeader
        className={`accordion-trigger ${isCollapseOpen ? 'accordion-open' : ''} ${isInvalid ? 'invalid' : ''}`}
        onClick={() => setCollapseOpen(!isCollapseOpen)}
        style={headerStyle}
      >
        {entity.title}
        <ArrowIcon />
      </CardHeader>
      <Collapse isOpen={isCollapseOpen}>
        <div className="accordion-content" style={accordionContentStyle}>
          {entity.content}
        </div>
      </Collapse>
    </Card>
  );
};

export default Accordion;
