import { BreadcrumbItem } from 'modules/Layout/type';
import { ROUTE_JUDGEMENT_CLIENT, ROUTE_JUDGEMENT_CLIENT_LIST } from 'modules/JudgementClient/routes';
import { getPathUrl } from 'modules/Shared/helper/api';

export const breadcrumbRouteJudgementClientList = (): BreadcrumbItem[] => [
  {
    title: 'Orzeczenia sądowe - wyszukiwanie',
    link: ROUTE_JUDGEMENT_CLIENT_LIST
  }
];

export const breadcrumbRouteJudgementClientDetails = (identifier: string, id: number): BreadcrumbItem[] => [
  ...breadcrumbRouteJudgementClientList(),
  {
    title: `Szczegóły orzeczenia - ${identifier}`,
    link: getPathUrl(ROUTE_JUDGEMENT_CLIENT, { id })
  }
];
