import React, { Dispatch, SetStateAction } from 'react';
import { CustomInput, FormFeedback, FormGroup } from 'reactstrap';
import { ValidationErrors } from 'modules/Shared/type';
import { getError, hasError } from 'modules/Shared/helper/validation';

export interface StepFieldCheckboxProps<T> {
  checkbox: string;
  label: string;
  stepValues: T;
  setStepValues: Dispatch<SetStateAction<T>>;
  mapState: (values: T) => T;
  errors?: ValidationErrors | null;
  disabled: boolean;
}

const StepFieldCheckbox = <T,>(props: StepFieldCheckboxProps<T>): JSX.Element => {
  const { checkbox, label, stepValues, setStepValues, mapState, errors, disabled = false } = props;

  return (
    <FormGroup key={checkbox}>
      <CustomInput
        id={checkbox}
        type="checkbox"
        // @ts-ignore
        checked={stepValues[checkbox]}
        label={<div dangerouslySetInnerHTML={{ __html: String(label) }} />}
        onChange={(event) => {
          // @ts-ignore
          stepValues[checkbox] = event.target.checked;
          // @ts-ignore
          setStepValues((values: T) => mapState(values));
        }}
        invalid={hasError(errors, checkbox)}
        disabled={disabled}
      />
      {hasError(errors, checkbox) && <FormFeedback className="d-block">{getError(errors, checkbox)}</FormFeedback>}
    </FormGroup>
  );
};

export default StepFieldCheckbox;
