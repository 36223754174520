import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClauseListParamsAction } from 'modules/Clause/action/ClauseList';
import ClauseCategoriesAutocomplete from 'modules/Clause/container/ClauseAutocomplete/Categories';

export interface Props {
  values?: SearchParams;
}

const ClauseFilterCategoriesAutocomplete: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { filter } = useSelector((state: RootState) => state.clause.clauseList);
  const [categories, setCategories] = useState<{ name: string; id: number }[]>([]);

  const setParams = (payload: PartialSearchingProps) => dispatch(setClauseListParamsAction(payload));

  const saveCategory = (newCategory: { name: string; id: number }) => {
    if (!categories.find((value) => value.id === newCategory.id)) {
      setCategories([...categories, newCategory]);
      filter['categories[]'] = [...categories.map((value) => value.id), newCategory.id] as any;
      setParams({ ...filter });
    }
  };

  const deleteCategory = (id: number) => {
    const newCategory = categories.filter((el) => el.id !== id);
    setCategories(newCategory);
    // eslint-disable-next-line
    if (filter.hasOwnProperty('categories[]')) {
      const { 'categories[]': _ignore, ...rest } = filter;
      if (newCategory.length > 0) {
        rest['categories[]'] = newCategory.map((value) => value.id) as any;
      }
      setParams({ filter: rest });
    }
  };

  return (
    <div className="col-12 col-lg-6 col-xl-4">
      <ClauseCategoriesAutocomplete
        selectedCategories={categories}
        onSelectedCategoriesChange={saveCategory}
        alwaysClearInput
        chipContentElements={categories.map(({ name, id }) => ({ content: name, id }))}
        onDeleteChipElement={deleteCategory}
      />
    </div>
  );
};

export default ClauseFilterCategoriesAutocomplete;

// old version before 1123

// import _get from 'lodash/get';
// import { RootState } from 'app/reducer';
// import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { setClauseListParamsAction } from 'modules/Clause/action/ClauseList';
// import ClauseCategoryAutocomplete from 'modules/Clause/container/ClauseAutocomplete/Category';

// export interface Props {
//   values?: SearchParams;
// }

// const ClauseFilterCategoryAutocomplete: React.FC<Props> = () => {
//   const dispatch = useDispatch();
//   const { filter } = useSelector((state: RootState) => state.clause.clauseList);
//   const [category, setCategory] = useState({ name: '', id: null });

//   const setParams = (payload: PartialSearchingProps) => dispatch(setClauseListParamsAction(payload));

//   useEffect(() => {
//     if (category.id) setParams({ filter: { ...filter, category_id: category.id } });
//     // eslint-disable-next-line
//     if (!category.id && filter.hasOwnProperty('category_id')) {
//       const { category_id, ...rest } = filter;
//       setParams({ filter: rest });
//     }
//     // eslint-disable-next-line
//   }, [category]);

//   return (
//     <div className="col-12 col-lg-6 col-xl-4">
//       <ClauseCategoryAutocomplete category={category} onCategoryChange={setCategory} />
//     </div>
//   );
// };

// export default ClauseFilterCategoryAutocomplete;
