import React from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';

type Props = {
  options?: DropzoneOptions;
};

const DragDropInput: React.FC<Props> = ({ options = {} }) => {
  const { getRootProps, getInputProps } = useDropzone(options);

  return (
    <section>
      <div {...getRootProps({ className: 'custom-dropzone cursor-pointer' })}>
        <input {...getInputProps()} />
        <p className="m-0">Przeciągnij i upuść pliki tutaj lub kliknij, żeby je wybrać.</p>
      </div>
    </section>
  );
};

export default DragDropInput;
