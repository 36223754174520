/* eslint-disable */
import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function EditIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px', className = 'icon-svg-table' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} height={height} viewBox="0 0 16.48 16.411">
      <g id="Component_39_1" data-name="Component 39 – 1" transform="translate(0.5 0.5)">
        <path
          id="Path_1319"
          data-name="Path 1319"
          d="M160.1,339.7a.437.437,0,0,0,.128-.309.446.446,0,0,0-.128-.31l-2.219-2.219a.441.441,0,0,0-.311-.129.435.435,0,0,0-.309.129l-8.267,8.267c-.1.1-1.543,3.733-1.6,3.867a.438.438,0,0,0,.57.57c.128-.051,3.77-1.5,3.866-1.6Z"
          transform="translate(-144.749 -336.731)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_64"
          data-name="Line 64"
          x1="7.397"
          y2="7.387"
          transform="translate(6.058 2.821)"
          fill="none"
          stroke="#666666"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_65"
          data-name="Line 65"
          x2="2.838"
          y2="2.838"
          transform="translate(4.246 8.395)"
          fill="none"
          stroke="#666666"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_66"
          data-name="Line 66"
          x2="0.961"
          y2="0.961"
          transform="translate(2.91 11.612)"
          fill="none"
          stroke="#666666"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1320"
          data-name="Path 1320"
          d="M155.683,346.576v6.091c0,.6-.183,1.015-.78,1.015H144.515a.971.971,0,0,1-1.015-1.015V342.351c0-.634.451-.851,1.086-.851h6.021"
          transform="translate(-143.5 -338.272)"
          fill="none"
          stroke="#666666"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
