import { PaginationMeta, SearchingProps } from 'modules/Shared/type';
import Order from 'modules/ExecutorOrder/model/Order';

export interface OrderListState extends SearchingProps {
  orders: Order[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initOrderListState = (): OrderListState => ({
  orders: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
