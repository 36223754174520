export type TraditionalPaymentEntity = {
  id: number;
  payment_number: string;
  subscriptions: string;
  user_id: number;
  user_first_name: string;
  nip: string;
  user_last_name: string;
  user_last_login_at: string;
  client_type: number;
  user_email: string;
  company_name: string;
  wants_invoice: boolean;
  created_at: string;
};

export default class TraditionalPayment {
  readonly id?: number;
  readonly payment_number?: string;
  readonly subscriptions?: string;
  readonly user_id?: number;
  readonly user_first_name?: string;
  readonly nip?: string;
  readonly user_last_name?: string;
  readonly user_last_login_at?: string;
  readonly client_type?: number;
  readonly user_email?: string;
  readonly company_name?: string;
  readonly wants_invoice?: boolean;
  readonly created_at?: string;

  constructor(entity: TraditionalPaymentEntity) {
    this.id = entity.id;
    this.payment_number = entity.payment_number;
    this.subscriptions = entity.subscriptions;
    this.user_id = entity.user_id;
    this.user_first_name = entity.user_first_name;
    this.nip = entity.nip;
    this.user_last_name = entity.user_last_name;
    this.user_last_login_at = entity.user_last_login_at;
    this.client_type = entity.client_type;
    this.user_email = entity.user_email;
    this.company_name = entity.company_name;
    this.wants_invoice = entity.wants_invoice;
    this.created_at = entity.created_at;
  }

  static getFilterableAttributes(): string[] {
    return [
      'user_email',
      'user_first_name',
      'user_last_name',
      'company_name',
      'user_last_login_from',
      'user_last_login_to',
      'created_at_from',
      'created_at_to'
    ];
  }

  static getSortableAttributes(): string[] {
    return [
      'user_email',
      'user_first_name',
      'user_last_name',
      'company_name',
      'created_at',
      'nip',
      'user_last_login_at'
    ];
  }

  getLastLogin(): string {
    return this.user_last_login_at || 'Nigdy';
  }

  getFVRequestDate(): string {
    return this.wants_invoice ? this.created_at : null;
  }

  getCompanyName(): string {
    if (this.company_name && this.company_name.trim()) {
      return this.company_name;
    }
    return '';
  }
}

export const createTraditionalPayment = (entity: TraditionalPaymentEntity): TraditionalPayment =>
  new TraditionalPayment(entity);
