import { addToastAction, managePageAction } from 'modules/Layout/action';
import Form from 'modules/Judgement/component/Form/Judgment/Content';
import React, { useEffect, useState, useRef } from 'react';
import Loader from 'modules/Layout/component/Loader';
import { useDispatch } from 'react-redux';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import { breadcrumbRouteJudgementsUpdate } from 'modules/Judgement/breadcrumbs';
import { updateJudgementToastSuccess } from 'modules/Judgement/toasts';
import { JudgementFieldValues } from 'modules/Judgement/type';
import { fetchJudgment, updateJudgmentContent } from 'modules/Judgement/repository';
import { ROUTE_JUDGEMENT } from 'modules/Judgement/routes';
import { useParams } from 'react-router-dom';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { createJudgement, JudgementEntity } from 'modules/Judgement/model/Judgement';
import CardBox from 'modules/Layout/component/CardBox';

const JudgementUpdateCotentView = () => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const { id } = useParams<{ id: string }>();
  const [judgement, setJudgement] = useState<JudgementEntity>(null);
  const [errors, setErrors] = useState(null);
  const scrollToTopOnce = useRef<boolean>(true);

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const { data, cancelled, message: messageResponse } = await fetchRequest(fetchJudgment, id, cancelToken);

      if (cancelled) return;
      if (data) setJudgement(createJudgement(data.data));
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (judgement)
      dispatch(
        managePageAction({
          title: `Edycja orzeczenia o identyfikatorze: ${judgement.identifier}`,
          breadcrumb: breadcrumbRouteJudgementsUpdate(judgement.identifier, judgement.id)
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [judgement]);

  const dispatchUpdateJudgementToast = () => dispatch(addToastAction(updateJudgementToastSuccess()));
  const setUpdateJudgementAction = async (data: JudgementFieldValues) => {
    setFetching(true);

    if (message) setMessage(null);
    if (errors) setErrors(null);

    const {
      message: messageResponse,
      error,
      cancelled,
      data: responseData
    } = await updateRequestWithData(updateJudgmentContent, id, data, cancelToken);

    if (cancelled) return;

    if (responseData) {
      dispatchUpdateJudgementToast();
      dispatch(push(getPathUrl(ROUTE_JUDGEMENT, { id: responseData.data.id })));
      // else dispatch(push(ROUTE_OFFICES));
    } else {
      if (error) setErrors(error);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <div className="user-create-view d-flex justify-content-center">
      {fetching && <Loader />}
      <CardBox
        heading="Edycja orzeczenia"
        className="w-100"
        paddingLeft="30px"
        paddingRight="30px"
        paddingBottom="35px"
      >
        <Alert message={message} />
        {judgement && <Form judgement={judgement} errors={errors} submit={(data) => setUpdateJudgementAction(data)} />}
      </CardBox>
    </div>
  );
};

export default JudgementUpdateCotentView;
