import React, { useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import { suffixLabel, suffixValue } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import { Button, CustomInput, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import Select from 'modules/Layout/component/Input/Select';
import { addToastAction } from 'modules/Layout/action';
import { executorFromGusToastSuccess, executorFromGusToastError } from 'modules/Executor/toasts';
import { fetchExecutorFromGus } from 'modules/Executor/repository';
import { companyClientArrOptions, CompanyEntity } from 'modules/User/model/User';
import DisplayPopovers from 'modules/Layout/component/Popover/DisplayWrapper';

export type Props = {
  errors?: ValidationErrors;
  values: CompanyEntity;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, key: keyof CompanyEntity) => void;
  setMultipleValues: (data: Record<any, any>) => void;
  executorFromGus: Record<any, any>;
  setExecutorFromGus: (data: Record<any, any>) => void;
  setAutofills?: (key: string) => void;
  required?: boolean;
  placeholder?: boolean;
};

const FieldsetCompanyUser: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    values,
    errors,
    onChange,
    setMultipleValues,
    executorFromGus,
    setExecutorFromGus,
    setAutofills,
    required,
    placeholder = true
  } = props;
  const dispatch = useDispatch();
  const [fillByHandStatus, setFillByHandStatus] = useState<boolean>(false);
  const [dataCorrectnessStatus, setDataCorrectnessStatus] = useState<boolean>(false);

  const onCheckBoxCheckedChange = () => {
    const value = values?.correspondence_address_same;
    if (value !== undefined) {
      const event = { target: { value: !value } };

      onChange(event as any, 'correspondence_address_same');
    }
  };

  const userFieldRequired = values.client_type === 1;
  const companyFieldRequired = values.client_type === 2;

  let first_name_field = '';
  let last_name_field = '';
  let checkboxText = '';

  if (userFieldRequired) {
    first_name_field = 'Imię';
    last_name_field = 'Nazwisko';
    checkboxText = 'Taki sam jak dla danych rozliczeniowych';
  } else if (companyFieldRequired) {
    first_name_field = 'Imię właściciela';
    last_name_field = 'Nazwisko właściciela';
    checkboxText = 'taki sam jak siedziba';
  }

  const currentClientType = companyClientArrOptions().find((obj) => obj.id === values.client_type);

  const renderFields = (): Array<any> => {
    if (companyFieldRequired && !fillByHandStatus && executorFromGus === null) return [];

    return [
      companyFieldRequired && (
        <FormGroup>
          {!placeholder && <Label for="name">{suffixLabel('Nazwa firmy', required && companyFieldRequired)}</Label>}
          <Input
            type="text"
            name="name"
            id="name"
            placeholder={placeholder ? suffixValue('Nazwa firmy', required && companyFieldRequired) : null}
            value={values.name}
            onChange={(event) => onChange(event, 'name')}
            maxLength={500}
            invalid={hasError(errors, 'name')}
            required={required && companyFieldRequired}
          />
          {hasError(errors, 'name') && <FormFeedback>{getError(errors, 'name')}</FormFeedback>}
        </FormGroup>
      ),
      <FormGroup>
        {!placeholder && <Label for="first_name">{suffixLabel(first_name_field, required && userFieldRequired)}</Label>}
        <Input
          type="text"
          name="first_name"
          id="first_name"
          placeholder={placeholder ? suffixValue(first_name_field, required && userFieldRequired) : null}
          value={values.first_name}
          onChange={(event) => {
            onChange(event, 'first_name');
            if (setAutofills) {
              setAutofills('first_name');
            }
          }}
          maxLength={200}
          required={required && userFieldRequired}
          invalid={hasError(errors, 'first_name')}
        />
        {hasError(errors, 'first_name') && <FormFeedback>{getError(errors, 'first_name')}</FormFeedback>}
      </FormGroup>,
      <FormGroup>
        {!placeholder && <Label for="last_name">{suffixLabel(last_name_field, required && userFieldRequired)}</Label>}
        <Input
          type="text"
          name="last_name"
          id="last_name"
          placeholder={placeholder ? suffixValue(last_name_field, required && userFieldRequired) : null}
          value={values.last_name}
          onChange={(event) => {
            onChange(event, 'last_name');
            if (setAutofills) {
              setAutofills('last_name');
            }
          }}
          invalid={hasError(errors, 'last_name')}
          required={required && userFieldRequired}
        />
        {hasError(errors, 'last_name') && <FormFeedback>{getError(errors, 'last_name')}</FormFeedback>}
      </FormGroup>,
      <FormGroup>
        {!placeholder && <Label for="city">{suffixLabel('Miejscowość', required)}</Label>}
        <Input
          type="text"
          name="city"
          id="city"
          value={values.city}
          onChange={(event) => onChange(event, 'city')}
          maxLength={200}
          placeholder={placeholder ? suffixValue('Miejscowość', required) : null}
          invalid={hasError(errors, 'city')}
          required={required}
        />
        {hasError(errors, 'city') && <FormFeedback>{getError(errors, 'city')}</FormFeedback>}
      </FormGroup>,
      <FormGroup>
        {!placeholder && <Label for="street">{suffixLabel('Ulica', required)}</Label>}
        <Input
          type="text"
          name="street"
          id="street"
          placeholder={placeholder ? suffixValue('Ulica', required) : null}
          value={values.street}
          onChange={(event) => onChange(event, 'street')}
          maxLength={200}
          invalid={hasError(errors, 'street')}
          required={required}
        />
        {hasError(errors, 'street') && <FormFeedback>{getError(errors, 'street')}</FormFeedback>}
      </FormGroup>,
      <FormGroup>
        {!placeholder && <Label for="postal_code">{suffixLabel('Kod pocztowy', required)}</Label>}
        <Input
          type="text"
          name="postal_code"
          id="postal_code"
          value={values.postal_code}
          placeholder={placeholder ? suffixValue('Kod pocztowy', required) : null}
          onChange={(event) => onChange(event, 'postal_code')}
          maxLength={200}
          invalid={hasError(errors, 'postal_code')}
          required={required}
        />
        {hasError(errors, 'postal_code') && <FormFeedback>{getError(errors, 'postal_code')}</FormFeedback>}
      </FormGroup>,
      <FormGroup>
        {!placeholder && <Label for="post_office">{suffixLabel('Miejscowość poczty', required)}</Label>}
        <Input
          type="text"
          name="post_office"
          id="post_office"
          placeholder={placeholder ? suffixValue('Miejscowość poczty', required) : null}
          value={values?.post_office ?? ''}
          onChange={(event) => onChange(event, 'post_office')}
          maxLength={200}
          invalid={hasError(errors, 'post_office')}
          required={required}
        />
        {hasError(errors, 'post_office') && <FormFeedback>{getError(errors, 'post_office')}</FormFeedback>}
      </FormGroup>,
      <FormGroup>
        {!placeholder && <Label for="email">{suffixLabel('Email', required)}</Label>}
        <Input
          type="text"
          name="email"
          id="email"
          value={values.email ?? ''}
          placeholder={placeholder ? suffixValue('Email', required) : null}
          onChange={(event) => {
            onChange(event, 'email');
            if (setAutofills) {
              setAutofills('email');
            }
          }}
          maxLength={200}
          invalid={hasError(errors, 'email')}
          required={required}
        />
        {hasError(errors, 'email') && <FormFeedback>{getError(errors, 'email')}</FormFeedback>}
      </FormGroup>,
      <FormGroup>
        <Label for="correspondence_address_same">
          <span className="h5 pb-1">Adres korespondencyjny</span>
        </Label>
        <div className="d-flex flex-wrap align-items-center">
          <CustomInput
            id="correspondence_address_same"
            type="checkbox"
            name="correspondence_address_same"
            label={checkboxText}
            checked={values?.correspondence_address_same ?? false}
            onChange={() => onCheckBoxCheckedChange()}
          />
        </div>
        {hasError(errors, 'correspondence_address_same') && (
          <FormFeedback>{getError(errors, 'correspondence_address_same')}</FormFeedback>
        )}
      </FormGroup>,
      !values?.correspondence_address_same && (
        <>
          <FormGroup className="ml-2">
            {!placeholder && <Label for="correspondence_street">{suffixLabel('Ulica')}</Label>}
            <Input
              type="text"
              name="correspondence_street"
              id="correspondence_street"
              placeholder={placeholder ? 'Ulica' : null}
              value={values.correspondence_street ?? ''}
              onChange={(event) => onChange(event, 'correspondence_street')}
              maxLength={200}
              invalid={hasError(errors, 'correspondence_street')}
            />
            {hasError(errors, 'correspondence_street') && (
              <FormFeedback>{getError(errors, 'correspondence_street')}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup className="ml-2">
            {!placeholder && <Label for="correspondence_city">{suffixLabel('Miejscowość')}</Label>}
            <Input
              type="text"
              name="correspondence_city"
              id="correspondence_city"
              placeholder={placeholder ? 'Miejscowość' : ''}
              value={values.correspondence_city ?? ''}
              onChange={(event) => onChange(event, 'correspondence_city')}
              maxLength={200}
              invalid={hasError(errors, 'correspondence_city')}
            />
            {hasError(errors, 'correspondence_city') && (
              <FormFeedback>{getError(errors, 'correspondence_city')}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup className="ml-2">
            {!placeholder && <Label for="correspondence_postal_code">{suffixLabel('Kod pocztowy')}</Label>}
            <Input
              type="text"
              name="correspondence_postal_code"
              id="correspondence_postal_code"
              placeholder={placeholder ? 'Kod pocztowy' : null}
              value={values.correspondence_postal_code ?? ''}
              onChange={(event) => onChange(event, 'correspondence_postal_code')}
              maxLength={200}
              invalid={hasError(errors, 'correspondence_postal_code')}
            />
            {hasError(errors, 'correspondence_postal_code') && (
              <FormFeedback>{getError(errors, 'correspondence_postal_code')}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup className="ml-2">
            {!placeholder && <Label for="correspondence_post_office">{suffixLabel('Poczta')}</Label>}
            <Input
              type="text"
              name="correspondence_post_office"
              id="correspondence_post_office"
              placeholder={placeholder ? 'Poczta' : null}
              value={values.correspondence_post_office ?? ''}
              onChange={(event) => onChange(event, 'correspondence_post_office')}
              maxLength={200}
              invalid={hasError(errors, 'correspondence_post_office')}
            />
            {hasError(errors, 'correspondence_post_office') && (
              <FormFeedback>{getError(errors, 'correspondence_post_office')}</FormFeedback>
            )}
          </FormGroup>
        </>
      ),
      companyFieldRequired && executorFromGus !== null && (
        <FormGroup>
          <Label for="data_correctness_status">
            <span className="h5 pb-1">Zgodność poprawnych danych</span>
          </Label>
          <div className="d-flex flex-wrap align-items-center">
            <CustomInput
              id="data_correctness_status"
              type="checkbox"
              name="data_correctness_status"
              label="Potwierdzam poprawność pobranch danych"
              checked={dataCorrectnessStatus}
              required
              onChange={(event) => setDataCorrectnessStatus(event.target.checked)}
            />
          </div>
        </FormGroup>
      )
    ];
  };

  const fetchExecutor = async (): Promise<any> => {
    try {
      const {
        data: { data }
      } = await fetchExecutorFromGus(values.nip);

      if (_isEmpty(data)) {
        dispatch(addToastAction(executorFromGusToastError()));
        return null;
      }

      dispatch(addToastAction(executorFromGusToastSuccess()));

      setMultipleValues(data[0]);
      setExecutorFromGus(data[0]);
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <FormGroup>
        {!placeholder && <Label for="client_type">{suffixLabel('Rodzaj klienta')}</Label>}
        <Select
          className="pagination-page-selector"
          invalid={hasError(errors, 'client_type')}
          options={companyClientArrOptions().map((obj) => ({
            label: obj.value,
            value: obj.id
          }))}
          value={{
            label: currentClientType?.value || '',
            value: currentClientType?.id || ''
          }}
          onChange={(option) => onChange({ target: { value: option.value || 1 } } as any, 'client_type')}
          placeholder={placeholder ? 'Rodzaj klienta' : null}
          name="client_type"
          id="client_type"
        />
        {hasError(errors, 'client_type') && <FormFeedback>{getError(errors, 'client_type')}</FormFeedback>}
      </FormGroup>
      {companyFieldRequired && [
        <FormGroup>
          {!placeholder && <Label for="nip">{suffixLabel('NIP', required && companyFieldRequired)}</Label>}
          <div className="d-flex align-items-center">
            <Input
              type="text"
              name="nip"
              id="nip"
              value={values?.nip ?? ''}
              placeholder={placeholder ? suffixValue('NIP', required && companyFieldRequired) : null}
              onChange={(event) => onChange(event, 'nip')}
              maxLength={200}
              required={required && companyFieldRequired}
              invalid={hasError(errors, 'nip')}
              className="mr-1"
            />
            <DisplayPopovers
              popoverId="popover_nip"
              popoverContent="Dane są automatycznie pobierane z rejestru GUS"
              className="tooltip-light"
            />
          </div>
          {hasError(errors, 'nip') && <FormFeedback>{getError(errors, 'nip')}</FormFeedback>}
        </FormGroup>,
        <div className="w-100">
          {!fillByHandStatus && executorFromGus === null && (
            <Button
              type="button"
              color="primary"
              className="pzpeu-btn-disabled waves-effect waves-light w-100"
              disabled={!values.nip}
              onClick={fetchExecutor}
            >
              Wyszukaj
            </Button>
          )}
          {executorFromGus === null && (
            <FormGroup>
              <CustomInput
                id="fill_by_hand"
                type="checkbox"
                name="fill_by_hand"
                label="Wprowadź dane ręcznie."
                className="mt-4"
                checked={fillByHandStatus}
                onChange={(event) => setFillByHandStatus(event.target.checked)}
              />
            </FormGroup>
          )}
        </div>
      ]}
      {...renderFields()}
    </>
  );
};

export default FieldsetCompanyUser;
