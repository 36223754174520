import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import { setOfficeListParamsAction } from 'modules/Office/action/list';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterInputType } from 'modules/Layout/type';
import { canSendEmailOptions, executorRequestStatusOptions } from 'modules/Office/model/Office';
import { formatDatetimeLocalForInput } from 'modules/Shared/helper/utils';

export interface Props {
  values?: SearchParams;
  withoutCanSendEmail?: boolean;
}

const OfficeFilter: React.FC<Props> = ({ values = {}, withoutCanSendEmail }) => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.office.list);
  const setParams = (payload: PartialSearchingProps) => dispatch(setOfficeListParamsAction(payload));

  const staticInputs: FilterInputType[] = [
    {
      type: 'number',
      property: 'id',
      label: 'ID',
      value: _get(values, 'id')
    },
    {
      type: 'text',
      property: 'name',
      label: 'Nazwa',
      value: _get(values, 'name')
    },
    {
      type: 'text',
      property: 'email',
      label: 'Email',
      value: _get(values, 'email')
    },
    {
      type: 'text',
      property: 'phone',
      label: 'Telefon',
      value: _get(values, 'phone')
    },
    !withoutCanSendEmail && {
      type: 'select',
      property: 'can_send_email',
      label: 'Czy zapytania są wysyłane',
      options: [
        {
          value: '',
          label: ''
        },
        ...canSendEmailOptions()
      ],
      value: _get(values, 'can_send_email')
    },
    {
      type: 'select',
      property: 'executor_request_status',
      label: 'Obecny status zapytań',
      options: [
        {
          value: '',
          label: ''
        },
        ...executorRequestStatusOptions()
      ],
      value: _get(values, 'executor_request_status')
    },
    {
      beforeLineBreak: true,
      type: 'text',
      property: 'city',
      label: 'Miasto',
      value: _get(values, 'city')
    },
    {
      type: 'text',
      property: 'street',
      label: 'Ulica',
      value: _get(values, 'street')
    },
    {
      type: 'text',
      property: 'postal_code',
      label: 'Kod pocztowy',
      value: _get(values, 'postal_code')
    },
    {
      type: 'text',
      property: 'post_office',
      label: 'Miejscowość poczty',
      value: _get(values, 'post_office')
    },
    {
      type: 'text',
      property: 'regon',
      label: 'REGON',
      value: _get(values, 'regon')
    }
  ];

  const inputsTime = (): FilterInputType[] => {
    let dynamicInputs: FilterInputType[] = [];

    const lastRequestSentAtFrom = _get(filter, 'last_request_sent_at_from') as string;
    const lastRequestSentAtTo = _get(filter, 'last_request_sent_at_from') as string;

    const nextRequestAtFrom = _get(filter, 'next_request_at_from') as string;
    const nextRequestAtTo = _get(filter, 'next_request_at_to') as string;

    const createdAtFrom = _get(filter, 'created_at_from') as string;
    const createdAtTo = _get(filter, 'created_at_to') as string;

    dynamicInputs = [
      {
        beforeLineBreak: true,
        type: 'datetime',
        property: 'last_request_sent_at_from',
        label: 'Data ostatniego wysłania zapytania do urzędu od',
        value: lastRequestSentAtFrom,
        max: String(lastRequestSentAtTo)
      },
      {
        type: 'datetime',
        property: 'last_request_sent_at_to',
        label: 'Data ostatniego wysłania zapytania do urzędu do',
        value: lastRequestSentAtTo,
        min: String(lastRequestSentAtFrom)
      },
      {
        beforeLineBreak: true,
        type: 'date',
        property: 'next_request_at_from',
        label: 'Data następnej wysyłki zapytania do urzędu od',
        value: nextRequestAtFrom ? formatDatetimeLocalForInput(nextRequestAtFrom) : nextRequestAtFrom,
        max: String(nextRequestAtTo)
      },
      {
        type: 'date',
        property: 'next_request_at_to',
        label: 'Data następnej wysyłki zapytania do urzędu do',
        value: nextRequestAtTo ? formatDatetimeLocalForInput(nextRequestAtTo) : nextRequestAtTo,
        min: String(nextRequestAtFrom)
      },
      {
        beforeLineBreak: true,
        type: 'datetime',
        property: 'created_at_from',
        label: 'Data utworzenia od',
        value: createdAtFrom,
        max: String(createdAtTo)
      },
      {
        type: 'datetime',
        property: 'created_at_to',
        label: 'Data utworzenia do',
        value: createdAtTo,
        min: String(createdAtFrom)
      }
    ];

    return dynamicInputs;
  };

  return (
    <ListingFilter
      filtersName="offices"
      inputs={[...staticInputs, ...inputsTime()]}
      filter={filter}
      pagination={pagination}
      setParams={setParams}
    />
  );
};

export default OfficeFilter;
