import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { ExecutorConnection } from 'modules/Executor/model/ExecutorClient';

const ExecutorConnectionsTable = ({ connections }: { connections: ExecutorConnection[] }) => {
  const cols: TableCol<ExecutorConnection>[] = [
    {
      property: 'name',
      label: 'Nazwa',
      value: (row) => row.name || 'nie dotyczy'
    },
    {
      property: 'connection_qnt',
      label: 'Powiązana z następującą liczbą organizacji',
      value: (row) => row.connection_qnt || 'nie dotyczy'
    },
    {
      property: 'connection_types',
      label: 'Rodzaje powiązań',
      value: (row) =>
        row?.connection_types.length > 1 ? row?.connection_types.join(', ') : row?.connection_types.map((type) => type)
    }
  ];

  return <Table cols={cols} rows={connections} />;
};

export default ExecutorConnectionsTable;
