import ActionDelete from 'modules/Layout/component/Action/Delete';
import TrashIcon from 'modules/Layout/component/Icon/Trash';
import Listing from 'modules/Layout/component/Listing';
import Table, { TableCol } from 'modules/Layout/component/Table';
import AddFilesDragDrop from 'modules/Shared/helper/Files/AddFilesDragDrop/AddFilesDragDrop';
import React, { useState, useEffect } from 'react';
import { Label } from 'reactstrap';
import { FetchEmailRequest } from '../../../type';
import { IFile } from '../../../../Shared/helper/Files/AddFiles';

type Props = {
  setValues: React.Dispatch<React.SetStateAction<FetchEmailRequest[]>>;
  values: FetchEmailRequest;
  submited?: boolean;
};

const useManageFileAttachments = ({ values, setValues, submited }: Props) => {
  const { files, files_to_delete, group } = values;
  const [filesObj, setFilesObj] = useState<{ files: IFile[] }>({ files: [] });
  const [filesToDelete, setFilesToDelete] = useState<IFile[]>([]);
  const filterCurrentActiveFiles = (files || []).filter((el) => !(files_to_delete || []).includes(Number(el.id)));

  useEffect(() => {
    if (filesObj.files.length > 0) {
      setValues((prevValues) => {
        return prevValues.map((item) =>
          item.group === group
            ? {
                ...item,
                files: [
                  ...item.files,
                  ...filesObj.files.map((file) => ({
                    id: String(file.id),
                    name: file.file.name,
                    file: file.file
                  }))
                ]
              }
            : item
        );
      });
    }
  }, [filesObj, setValues, group]);

  useEffect(() => {
    setFilesObj({ files: [] });
    setFilesToDelete([]);
  }, [submited]);

  const handleDeleteFile = (row: IFile) => {
    setValues((prevValues) => {
      return prevValues.map((item) =>
        item.group === group
          ? {
              ...item,
              files_to_delete: [...(item.files_to_delete || []), Number(row.id)]
            }
          : item
      );
    });
    setFilesToDelete([...filesToDelete, row]);
  };

  const handleRemoveFromFilesToDelete = (row: IFile) => {
    setValues((prevValues) => {
      return prevValues.map((item) =>
        item.group === group
          ? {
              ...item,
              files_to_delete: item.files_to_delete?.filter((id) => id !== Number(row.id)) || []
            }
          : item
      );
    });
    setFilesToDelete(filesToDelete.filter((file) => Number(file.id) !== Number(row.id)));
  };

  const filesCols: TableCol<IFile>[] = [
    {
      property: 'name',
      label: 'Nazwa'
    },
    {
      property: 'actions',
      label: filterCurrentActiveFiles?.length ? 'Akcje' : '',
      value: function Actions(row: IFile) {
        return (
          <div className="actions-wrapper">
            <ActionDelete className="mx-1" title="Usuń plik" onClick={() => handleDeleteFile(row)} />
          </div>
        );
      }
    }
  ];

  const jsxAttachmentsDropbox = (
    <>
      <Label className="mt-4">Załączniki</Label>
      <p className="h5 mr-2">Obecnie wysyłane załączniki</p>
      <div className="pzpeu-table-list inside-card-box mb-0 mr-0 ml-0">
        <Listing table={<Table cols={filesCols} rows={filterCurrentActiveFiles} />} />
      </div>

      <div className="row justify-content-between mt-3">
        <div className="col-xl-5">
          <p className="h5 mr-2">Załączniki oczekujące dodania</p>
          <div className="mb-2 position-relative">
            <AddFilesDragDrop values={filesObj} setValues={setFilesObj} />
          </div>
        </div>
        <div className="col-xl-5">
          <p className="h5 mr-2">Załączniki oczekujące usunięcia</p>
          {filesToDelete?.length ? (
            filesToDelete?.map((file) => (
              <div key={file.id} className="my-2 d-flex flex-wrap">
                <span className="mr-2">{file.name}</span>
                <ActionDelete
                  label={<TrashIcon height="20px" />}
                  title="Usuń plik"
                  onClick={() => handleRemoveFromFilesToDelete(file)}
                />
              </div>
            ))
          ) : (
            <div className="my-2 d-flex flex-wrap">Brak</div>
          )}
        </div>
      </div>
    </>
  );

  return {
    jsxAttachmentsDropbox
  };
};

export default useManageFileAttachments;
