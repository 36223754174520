import React, { useEffect, useState } from 'react';
import User from 'modules/User/model/User';
import { useDispatch } from 'react-redux';
import { managePageAction } from 'modules/Layout/action';
import { Message } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { DashboardUserValues, PageProps } from 'modules/Layout/type';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { fetchDashboardClientData } from 'modules/Layout/repository';
import CardBox from 'modules/Layout/component/CardBox';
import ModuleDatasetDashboard from 'modules/Module/component/Dataset/ListDashboard';
import { ModuleEntity } from 'modules/Module/model/Module';
import { fetchModuleList } from 'modules/Module/repository';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import LastVisitedExcutor from 'modules/Layout/component/DashboardWidgets/LastVisitedExcutor';
import { Link } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_ACCESS_HISTORY } from 'modules/Module/routes';
import LastVisitedJudgments from 'modules/Layout/component/DashboardWidgets/LastVisitedJudgments';
import LastVisitedClauses from 'modules/Layout/component/DashboardWidgets/LastVisitedClauses';
import LastVisitedProceedings from 'modules/Layout/component/DashboardWidgets/LastVisitedProceedings';
import './index.scss';
import Authorize from '../../../../Auth/container/Authorize';
import { SUBSCRIPTIONS_MODULE_ACCESS } from '../../../../Auth/container/Guard/permissions';

type Props = {
  user?: User;
};

const ClientDashboard: React.FC<Props> = ({ user }) => {
  const dispatch = useDispatch();
  const [moduleList, setModuleList] = useState<ModuleEntity[]>([]);
  const [displayData, setDisplayData] = useState<DashboardUserValues>({});
  const [fetching, setFetching] = useState({ clientData: false, moduleList: false });
  const [message, setMessage] = useState<Message>(null);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  const cancelToken = useCancelToken();
  useEffect(() => {
    const name = user?.getName?.();

    if (name)
      managePage({
        title: `Witaj ${name}!`
      });

    (async () => {
      setFetching({ ...fetching, moduleList: true });
      const { data, cancelled, message: messageResponse } = await fetchRequest(fetchModuleList, cancelToken);

      if (cancelled) return;
      if (data) {
        setModuleList(data.data);
      }
      if (messageResponse) setMessage(messageResponse);
      setFetching({ ...fetching, moduleList: false });
    })();
    (async () => {
      setFetching({ ...fetching, clientData: true });
      const { data, cancelled, message: messageResponse } = await fetchRequest(fetchDashboardClientData, cancelToken);

      if (cancelled) return;
      if (data?.data) setDisplayData(data.data);
      if (messageResponse) setMessage(messageResponse);
      setFetching({ ...fetching, clientData: false });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { modules, credits, executors, judgments, clauses, proceedings } = displayData;

  const findSubscriptionModule = (slug: string) => modules?.find((module) => module?.slug === slug);
  const findCreditModules = (slug: string) => credits?.find((module) => module?.module_slug === slug);

  const isFetching = fetching.clientData || fetching.moduleList;

  if (isFetching) return <Loader />;

  if (message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );

  if (displayData && moduleList)
    return (
      <div className="row users-view">
        <div className="col-12">
          <Authorize permissions={[SUBSCRIPTIONS_MODULE_ACCESS]}>
            <CardBox
              heading="Zakupione moduły"
              paddingLeft="30px"
              paddingTop="25px"
              paddingRight="30px"
              paddingBottom="0"
              childrenPaddingTop="0"
              actions={
                <>
                  <Link to={getPathUrl(ROUTE_ACCESS_HISTORY, { id: user?.id })}>
                    <span className="link-with-border">SPRAWDŹ HISTORIĘ DOSTĘPÓW</span>
                  </Link>
                </>
              }
            >
              <div className="users-view">
                <div className="dashboard-modules flex-column flex-xl-row">
                  {moduleList &&
                    moduleList.map((moduleValue) => (
                      <ModuleDatasetDashboard
                        key={moduleValue?.id}
                        module={moduleValue}
                        subscriptionDateTo={findSubscriptionModule(moduleValue?.slug)?.date_to}
                        creditsByDates={findCreditModules(moduleValue?.slug)?.by_dates}
                        isPublic={moduleValue?.is_public}
                        linkToSubscription
                      />
                    ))}
                </div>
              </div>
            </CardBox>
          </Authorize>
          {!!proceedings?.length && (
            <CardBox heading="Ostatnio weryfikowane postępowania" childrenPaddingTop="0">
              <div className="last-visited-executors">
                {proceedings.map((proceeding) => (
                  <LastVisitedProceedings key={proceeding?.id} proceeding={proceeding} />
                ))}
              </div>
            </CardBox>
          )}
          {!!executors?.length && (
            <CardBox heading="Ostatnio weryfikowani wykonawcy" childrenPaddingTop="0">
              <div className="last-visited-executors">
                {executors.map((executor) => (
                  <LastVisitedExcutor key={executor?.id} executor={executor} />
                ))}
              </div>
            </CardBox>
          )}
          {!!judgments?.length && (
            <CardBox heading="Ostatnio weryfikowane orzeczenia" childrenPaddingTop="0">
              <div className="last-visited-executors">
                {judgments.map((judgment) => (
                  <LastVisitedJudgments key={judgment?.id} judgment={judgment} />
                ))}
              </div>
            </CardBox>
          )}
          {!!clauses?.length && (
            <CardBox heading="Ostatnio weryfikowane klauzule" childrenPaddingTop="0">
              <div className="last-visited-executors">
                {clauses.map((clause) => (
                  <LastVisitedClauses key={clause?.id} clause={clause} />
                ))}
              </div>
            </CardBox>
          )}
        </div>
      </div>
    );

  return null;
};

export default ClientDashboard;
