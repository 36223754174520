import React, { useState } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import ExecutorFilter from 'modules/Executor/container/adminList/Filter';
import ExecutorPagination from 'modules/Executor/container/adminList/Pagination';
import ExecutorPerPage from 'modules/Executor/container/adminList/PerPage';
import ExecutorTable from 'modules/Executor/container/adminList/Table';
import Executor from 'modules/Executor/model/Executor';
import { ROUTE_EXECUTORS_ADMINS } from 'modules/Executor/routes';
import { useDispatch, useSelector } from 'react-redux';
import { addToastAction } from 'modules/Layout/action';
import { Message } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { deleteExecutorToastSuccess } from 'modules/Executor/toasts';
import { setExecutorAdminListParamsAction } from 'modules/Executor/action/adminList';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import { deleteAdminExecutor } from 'modules/Executor/repository';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';

const ExecutorsAdminList = () => {
  const dispatch = useDispatch();
  const { fetching, meta } = useSelector((state: RootState) => state.executor.adminList);
  const [displayModal, setDisplayModal] = useState<Executor>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);

  const dispatchDeleteExecutorToast = () => dispatch(addToastAction(deleteExecutorToastSuccess()));

  const cancelToken = useCancelToken();
  const deleteExecutorAction = async (executor_id: number | string) => {
    setLoading(true);
    const {
      cancelled,
      success,
      message: messageRequest
    } = await deleteRequest(deleteAdminExecutor, executor_id, cancelToken);

    if (cancelled) return;
    if (success) {
      if (success) dispatchDeleteExecutorToast();
      setDisplayModal(null);
      dispatch(setExecutorAdminListParamsAction({}));
      setLoading(false);
    } else {
      if (messageRequest) setMessage(messageRequest);
      setLoading(false);
    }
  };

  return (
    <div className="row users-view">
      <div className="col-12">
        {displayModal && (
          <GenericModalDelete
            value={displayModal}
            title="Usuwanie wykonawcy"
            content={(value) => `Czy na pewno chcesz usunąć wykonawcę - ${value.name ?? value.nip}?`}
            onDeleteClick={(value) => deleteExecutorAction(value.id)}
            isOpen={!!displayModal}
            toggle={() => setDisplayModal(null)}
            loading={loading}
            message={message}
            resetMessage={() => setMessage(null)}
          />
        )}
        <Listing
          table={<ExecutorTable onDeleteClick={(executor) => setDisplayModal(executor)} />}
          filter={<ExecutorFilter />}
          pagination={<ExecutorPagination path={ROUTE_EXECUTORS_ADMINS} />}
          perPage={<ExecutorPerPage />}
          loading={fetching}
          meta={meta}
        />
      </div>
    </div>
  );
};

export default ExecutorsAdminList;
