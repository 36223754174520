import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { ClauseConnectedJudgementValues } from 'modules/Clause/type';
import CardBox from 'modules/Layout/component/CardBox';
import Listing from 'modules/Layout/component/Listing';
import ClauseConnectedJudgementsTable from 'modules/ClauseClient/container/ClauseConnectedJudgements/Table';
import Clause from 'modules/Clause/model/Clause';
import useLocalPagination from 'modules/Shared/helper/hooks/useLocalPagination';
import { fetchClientClauseConnectedJudgments } from 'modules/ClauseClient/repository';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_JUDGEMENT_CLIENT } from 'modules/JudgementClient/routes';

const ClauseDetailsConnectedJudgements: React.FC<{ clause: Clause }> = ({ clause }) => {
  const { id } = useParams<{ id: string }>();
  const [clauseJudgements, setClauseJudgements] = useState<ClauseConnectedJudgementValues[]>([]);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);
  // const [displayJudgmentIdContent, setDisplayJudgmentIdContent] = useState<number>(null);
  // const contentRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if (displayJudgmentIdContent && contentRef.current) {
  //     contentRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [displayJudgmentIdContent]);

  const cancelToken = useCancelToken();
  const fetchClauseJudgementAction = async () => {
    setFetching(true);
    const {
      data,
      cancelled,
      message: messageResponse
    } = await fetchRequest(fetchClientClauseConnectedJudgments, id, cancelToken);

    if (cancelled) return;
    if (data) setClauseJudgements(data.data);
    if (messageResponse) setMessage(messageResponse);
    setFetching(false);
  };

  useEffect(() => {
    fetchClauseJudgementAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { paginationComponent, selectedItemsFromArray } = useLocalPagination(clauseJudgements);

  // const hideContent = () => {
  //   contentRef.current = null;
  //   setDisplayJudgmentIdContent(null);
  // };

  // const displayContent = () => {
  //   if (displayJudgmentIdContent) {
  //     const selectedJudgment = clauseJudgements.find((el) => el.judgment.id === displayJudgmentIdContent);

  //     if (selectedJudgment) {
  //       return (
  //         <div className="col-12" ref={contentRef}>
  //           <CardBox
  //             heading={`Treść zaznaczonej publikacji - ${selectedJudgment.judgment.identifier}`}
  //             paddingBottom="0"
  //           >
  //             <InformationList
  //               fields={[
  //                 {
  //                   label: '',
  //                   value: selectedJudgment.content,
  //                   html: true,
  //                   htmlFieldInsertedStyle: { maxHeight: '700px', overflowY: 'auto', marginBottom: '20px' }
  //                 }
  //               ]}
  //             />
  //           </CardBox>
  //         </div>
  //       );
  //     }

  //     hideContent();
  //   }

  //   return null;
  // };

  const dispatch = useDispatch();
  const redirectShowJudgment = (judgmentId: number) => {
    dispatch(push(getPathUrl(ROUTE_JUDGEMENT_CLIENT, { id: judgmentId }, { clause: id })));
  };

  return (
    <>
      <div className="col-12">
        <CardBox heading="Połączone orzeczenia">
          <div className="row users-view">
            <div className="col-12">
              <Alert message={message} />
              <div className="pzpeu-table-list inside-card-box">
                <Listing
                  table={
                    <ClauseConnectedJudgementsTable
                      clauseJudgements={selectedItemsFromArray as any}
                      setShowContent={redirectShowJudgment}
                      clause={clause}
                    />
                  }
                  pagination={paginationComponent()}
                  loading={fetching}
                />
              </div>
            </div>
          </div>
        </CardBox>
      </div>
      {/* {displayContent()} */}
    </>
  );
};

export default ClauseDetailsConnectedJudgements;
