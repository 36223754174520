import React, { useEffect, useState } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import ExecutorFilter from 'modules/Executor/container/list/Filter';
import ExecutorPagination from 'modules/Executor/container/list/Pagination';
import ExecutorPerPage from 'modules/Executor/container/list/PerPage';
import ExecutorTable from 'modules/Executor/container/list/Table';
import { ROUTE_EXECUTOR, ROUTE_EXECUTORS_ADMINS } from 'modules/Executor/routes';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
import { breadcrumbRouteExecutors } from 'modules/Executor/breadcrumbs';
import { Message, PartialSearchingProps, ValidationErrors } from 'modules/Shared/type';
import CollapseCreditAccessList from 'modules/Shared/components/CollapseCreditAccessList';
import { MODULE_EXECUTOR } from 'modules/Module/model/Module';
import ModalUseCredit from 'modules/Executor/component/Modal/UseCreditExecutor';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import { useCreditExecutor } from 'modules/Executor/repository';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { SetAuthUserAction, SET_AUTH_USER } from 'modules/Auth/action';
import { checkIfUserHasAccessToModule, formatDate } from 'modules/Shared/helper/utils';
import { Button } from 'reactstrap';
import User from 'modules/User/model/User';
import { ByDatesValues } from 'modules/User/type';
import './index.scss';
import InformationList from 'modules/Layout/component/InformationList';
import { createSearchingProps } from 'modules/Shared/helper/params';
import { setExecutorListParamsAction } from 'modules/Executor/action/list';
import ExecutorClient from 'modules/Executor/model/ExecutorClient';

export const correctCredisDescriptionForm = (value: number) => {
  if (value === 1) return 'kredyt';
  if (value > 1 && value < 5) return 'kredyty';
  return 'kredytów';
};

const subtractCreditsFromUser = (user: User, data: { date_to: string; nip: string }) => {
  const { credits, ...restUser } = user;
  const creditIndex = credits.findIndex((obj) => obj.module_slug === MODULE_EXECUTOR);

  if (creditIndex !== -1) {
    const { by_dates, ...restCredit } = credits[creditIndex];
    const datesIndex = by_dates.findIndex((date) => date.date_to === data.date_to);

    if (datesIndex !== -1) {
      const { available_credits, used_nips, ...restByDates } = by_dates[datesIndex];

      const newCreditObj = {
        ...restCredit,
        by_dates: [
          ...by_dates.slice(0, datesIndex),
          {
            ...restByDates,
            used_nips: used_nips.includes(data.nip) ? used_nips : [...used_nips, data.nip],
            available_credits: available_credits - 1
          },
          ...by_dates.slice(datesIndex + 1)
        ]
      };

      return {
        ...restUser,
        credits: [...credits.slice(0, creditIndex), newCreditObj, ...credits.slice(creditIndex + 1)]
      };
    }
  }
  return null;
};

const Executors = () => {
  const [displayUseCreditModal, setDisplayUseCreditModal] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const dispatch = useDispatch();
  const { fetching, meta } = useSelector((state: RootState) => state.executor.list);
  const { user } = useSelector((state: RootState) => state.auth);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  const setParams = (payload: PartialSearchingProps) => dispatch(setExecutorListParamsAction(payload));

  const searchProps = createSearchingProps(
    '',
    ExecutorClient.getFilterableAttributes(),
    ExecutorClient.getSortableAttributes()
  );

  useEffect(() => {
    managePage({
      title: 'Baza wykonawców, w stosunku do których mogą wystąpić podstawy do wykluczenia - wyszukiwanie',
      breadcrumb: breadcrumbRouteExecutors()
    });

    setParams({ ...searchProps });
  }, []);

  const cancelToken = useCancelToken();
  const requestUseCreditExecutorAction = async (data: { date_to: string; nip: string; foreign_nip?: boolean }) => {
    if (loading) return;
    setLoading(true);
    const {
      data: resData,
      message: resMessage,
      error,
      cancelled
    } = await updateRequestWithData(useCreditExecutor, data, cancelToken);

    if (cancelled) return;
    if (resData?.data) {
      const { executor_id: id } = resData.data;
      if (id) {
        const newUser = subtractCreditsFromUser(user, data);
        if (newUser) {
          dispatch<SetAuthUserAction>({
            type: SET_AUTH_USER,
            payload: newUser
          });
          dispatch(push(getPathUrl(ROUTE_EXECUTOR, { id })));
        }
      }
    } else {
      if (resMessage) setMessage(resMessage);
      if (error) setErrors(error);
      setLoading(false);
    }
  };

  const cleanMessage = () => {
    setMessage(null);
    setErrors(null);
  };

  const displayCreditsByDates = (row: ByDatesValues) => {
    if (row?.available_credits)
      return (
        <div className="row justify-content-between" key={row?.date_to}>
          <div className="col-12 col-md-6">
            <div className="credit">
              <InformationList
                fields={[
                  {
                    label: 'Wykupione kredyty',
                    value: (
                      <span className="text-success">
                        do dnia <span className="font-weight-bold">{formatDate(row?.date_to)}</span> posiadasz
                        {` ${row?.available_credits} ${
                          row?.available_credits === 1 ? 'dostępny kredyt' : 'dostępne kredyty'
                        } z ${row?.all_credits_count}`}
                      </span>
                    )
                  }
                ]}
              />
            </div>
          </div>
          {row?.available_credits ? (
            <div className="col-12 col-md-6 d-flex justify-content-end">
              <Button
                color="dark"
                className="pzpeu-btn-flex waves-effect waves-light access-btn"
                onClick={() => setDisplayUseCreditModal(row.date_to)}
              >
                Uzyskaj dostęp
              </Button>
            </div>
          ) : null}
        </div>
      );

    return null;
  };

  const subscriptionModule = user?.modules?.find((module) => module?.slug === MODULE_EXECUTOR);
  const hasSubscriptionTo =
    checkIfUserHasAccessToModule(subscriptionModule?.date_from, subscriptionModule?.date_to) &&
    subscriptionModule?.date_to;
  const credits = user?.credits?.find((module) => module?.module_slug === MODULE_EXECUTOR);
  const hasAlreadyBoughtAccessTo = (nip: string) =>
    credits?.by_dates?.find((date) => date?.used_nips?.includes(nip))?.date_to;

  const listOfCredits = credits?.by_dates.reduce((prev, curr) => {
    if (curr?.available_credits) return [...prev, displayCreditsByDates(curr)];
    return prev;
  }, []);

  const renderCreditsInfo = () => {
    if (hasSubscriptionTo) return null;

    if (!credits || !listOfCredits.length) return <div className="col-12">Brak dostępnych kredytów.</div>;

    return (
      <>
        <div className="col-12 available-credits">Dostępne kredyty:</div>
        <div className="col-12 credits">{listOfCredits}</div>
      </>
    );
  };

  return (
    <div className="row users-view executor-access-list">
      {displayUseCreditModal && (
        <ModalUseCredit
          date_to={displayUseCreditModal}
          hasSubscriptionTo={hasSubscriptionTo}
          hasAlreadyBoughtAccessTo={hasAlreadyBoughtAccessTo}
          onUseCreditClick={(data: { date_to: string; nip: string }) => requestUseCreditExecutorAction(data)}
          loading={loading}
          message={message}
          errors={errors}
          cleanMessage={cleanMessage}
          isOpen={!!displayUseCreditModal}
          toggle={() => setDisplayUseCreditModal('')}
        />
      )}
      <div className="col-12">
        <CollapseCreditAccessList subscriptionTime={hasSubscriptionTo && subscriptionModule.date_to}>
          {renderCreditsInfo()}
        </CollapseCreditAccessList>
      </div>
      <div className="col-12 mt-50px">
        <Listing
          table={<ExecutorTable />}
          filter={<ExecutorFilter />}
          pagination={<ExecutorPagination path={ROUTE_EXECUTORS_ADMINS} />}
          perPage={<ExecutorPerPage />}
          loading={fetching}
          meta={meta}
        />
      </div>
    </div>
  );
};

export default Executors;
