import React, { useEffect, useRef, useState } from 'react';
import { FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AGREEMENT_TYPE_SUPPLEMENT, AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_REPLACEMENT } from 'modules/Agreements/step';
import useStepFields from 'modules/Agreements/hook/useStepFields';

export interface AgreementStepReplacementValues {
  information_possibility: boolean;
  info_construction_checked: boolean;
  info_construction_content: string | null;
  info_electric_checked: boolean;
  info_electric_content: string | null;
  info_sanitary_checked: boolean;
  info_sanitary_content: string | null;
  info_others_checked: boolean;
  info_others_content: string | null;
  entities_checked: boolean;
  entities_content: string | null;
  representatives_possibility: boolean;
  rep_construction_checked: boolean;
  rep_construction_content: string | null;
  rep_electric_checked: boolean;
  rep_electric_content: string | null;
  rep_sanitary_checked: boolean;
  rep_sanitary_content: string | null;
  rep_others_checked: boolean;
  rep_others_content: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const INFO_CONSTRUCTION_CONTENT = 'Konstrukcyjno – budowlanej.';
const INFO_ELECTRIC_CONTENT = 'Elektrycznej.';
const INFO_SANITARY_CONTENT = 'Sanitarnej.';
const INFO_OTHERS_CONTENT = 'inne:';
const ENTITIES_CONTENT =
  'Podmioty o których mowa powyżej są upoważnione do występowania w imieniu zamawiającego wobec wykonawcy we wszystkich wypadkach, w których Umowa przewiduje uprawienia lub obowiązki zamawiającego, w szczególności w zakresie czynności kontrolnych i odbiorowych.';
const REP_CONSTRUCTION_CONTENT = 'Konstrukcyjno – budowlanej - ........';
const REP_ELECTRIC_CONTENT = 'Elektrycznej - ........';
const REP_SANITARY_CONTENT = 'Sanitarnej - ........';
const REP_OTHERS_CONTENT = 'inne:';

const initContentValues = {
  info_construction_content: INFO_CONSTRUCTION_CONTENT,
  info_electric_content: INFO_ELECTRIC_CONTENT,
  info_sanitary_content: INFO_SANITARY_CONTENT,
  info_others_content: INFO_OTHERS_CONTENT,
  entities_content: ENTITIES_CONTENT,
  rep_construction_content: REP_CONSTRUCTION_CONTENT,
  rep_electric_content: REP_ELECTRIC_CONTENT,
  rep_sanitary_content: REP_SANITARY_CONTENT,
  rep_others_content: REP_OTHERS_CONTENT
};

const mapState = (step: AgreementStepReplacementValues): AgreementStepReplacementValues => {
  const {
    info_construction_content,
    info_electric_content,
    info_sanitary_content,
    info_others_content,
    entities_content,
    rep_construction_content,
    rep_electric_content,
    rep_sanitary_content,
    rep_others_content,
    checkboxes,
    ...rest
  } = step;

  return {
    ...rest,
    info_construction_content: info_construction_content || INFO_CONSTRUCTION_CONTENT,
    info_electric_content: info_electric_content || INFO_ELECTRIC_CONTENT,
    info_sanitary_content: info_sanitary_content || INFO_SANITARY_CONTENT,
    info_others_content: info_others_content || INFO_OTHERS_CONTENT,
    entities_content: entities_content || ENTITIES_CONTENT,
    rep_construction_content: rep_construction_content || REP_CONSTRUCTION_CONTENT,
    rep_electric_content: rep_electric_content || REP_ELECTRIC_CONTENT,
    rep_sanitary_content: rep_sanitary_content || REP_SANITARY_CONTENT,
    rep_others_content: rep_others_content || REP_OTHERS_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepReplacement = (props: AgreementStepProps): JSX.Element => {
  const { steps, onSubmit, onChange, errors, agreement } = props;

  const initState = useRef<AgreementStepReplacementValues>(mapState(getStepValues(steps, AGREEMENT_STEP_REPLACEMENT)));
  const [stepValues, setStepValues] = useState<AgreementStepReplacementValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_REPLACEMENT));
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderCheckbox, renderEditableCheckbox, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    initContentValues
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        {[
          renderCheckbox(
            'information_possibility',
            'Zamawiający informuje wykonawcę o podmiotach pełniących na rzecz zamawiającego funkcję Inspektorów nadzoru inwestorskiego w rozumieniu art. 17 pkt 2 ustawy z dnia 7 lipca 1994 r. Prawo budowlane (dalej pr. bud.) polegającą na świadczeniu usług nadzoru, kontroli i doradztwa technicznego, tj. w szczególności wykonywania czynności związanych z przygotowaniem, zarządzaniem, kontrolą, nadzorem, rozliczeniem finansowym budowy oraz nadzoru nad realizacją przez wykonawcę Przedmiotu Umowy, w zakresie branży:',
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          stepValues.information_possibility && (
            <div className="pl-3">
              {[
                renderCheckbox(
                  'info_construction_checked',
                  stepValues.info_construction_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderCheckbox(
                  'info_electric_checked',
                  stepValues.info_electric_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderCheckbox(
                  'info_sanitary_checked',
                  stepValues.info_sanitary_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderEditableCheckbox(
                  'info_others',
                  null,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                )
              ]}
            </div>
          ),
          <hr />,
          renderCheckbox('entities_checked', stepValues.entities_content, agreement.type === AGREEMENT_TYPE_SUPPLEMENT),
          <hr />,
          renderCheckbox(
            'representatives_possibility',
            'Przedstawicielami podmiotów, o których mowa w ust. 1 niniejszego paragrafu są ze strony nadzoru inwestorskiego branży:',
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          stepValues.representatives_possibility && (
            <div className="pl-3">
              {[
                renderEditableCheckbox(
                  'rep_construction',
                  null,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderEditableCheckbox(
                  'rep_electric',
                  null,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderEditableCheckbox(
                  'rep_sanitary',
                  null,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderEditableCheckbox(
                  'rep_others',
                  null,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                )
              ]}
            </div>
          ),
          renderCustomCheckboxes(
            null,
            null,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )
        ]}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepReplacement;
