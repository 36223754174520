import React from 'react';
import CardBox from 'modules/Layout/component/CardBox';
import JudgementClientBasicDataset from 'modules/JudgementClient/component/Dataset/Judgement/Basic';
import Judgement from 'modules/Judgement/model/Judgement';
import './index.scss';

type Props = {
  judgement: Judgement;
};

const JudgementBasicDetails: React.FC<Props> = ({ judgement }) => {
  return (
    <CardBox heading="Szczegóły orzeczenia" paddingRight="30px" paddingBottom="0" className="judgement-basic-details">
      <JudgementClientBasicDataset element={judgement} />
    </CardBox>
  );
};

export default JudgementBasicDetails;
