import _ from 'lodash';
import { Message } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { useState } from 'react';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { deleteJudgmentSAOSKeyword } from 'modules/Judgement/repository';
import { deleteJudgementSAOSKeywordToastSuccess } from 'modules/Judgement/toasts';
import { addToastAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';

const useSAOSKeywordDeleteModal = (category_id: string | number, onSuccess?: () => void) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState<JudgementElementEntity>(null);
  const [message, setMessage] = useState<Message>(null);

  const dispatchDeleteJudgementSAOSKeywordToast = () =>
    dispatch(addToastAction(deleteJudgementSAOSKeywordToastSuccess()));

  const cancelToken = useCancelToken();
  const deleteSAOSKeywordAction = async (id: number) => {
    setLoading(true);
    const {
      cancelled,
      success,
      message: messageRequest
    } = await deleteRequest(deleteJudgmentSAOSKeyword, category_id, id, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteJudgementSAOSKeywordToast();
      setDisplayDeleteModal(null);
      if (onSuccess) onSuccess();
    }
    if (messageRequest) setMessage(messageRequest);
    setLoading(false);
  };

  const clearErrors = () => {
    setMessage(null);
  };

  return {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleteSAOSKeywordAction,
    loading,
    displayDeleteModal,
    setDisplayDeleteModal,
    message,
    clearErrors
  };
};

export default useSAOSKeywordDeleteModal;
