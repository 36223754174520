import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import { MisrepresentationDataItem } from 'modules/ExecutorOrder/model/Order';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';

export interface Props {
  misrepresentationItem?: MisrepresentationDataItem;
}

const MisrepresentationProblem: React.FC<Props> = ({ misrepresentationItem = {} }) => {
  const fields = [
    {
      label: 'Przyczyny wykluczenia z postępowania',
      value: misrepresentationItem.violation_description ?? 'nie dotyczy',
      html: true
    },
    {
      label: 'Data wykluczenia',
      value: ifContainsDateFormat(misrepresentationItem.violation_date, formatDate) ?? 'nie dotyczy'
    },
    {
      label: 'Sygnatura wyroku Krajowej Izby Odwoławczej',
      value: misrepresentationItem.exclusion_identifier ?? 'nie dotyczy'
    }
  ];

  return <InformationList fields={fields} />;
};

export default MisrepresentationProblem;
