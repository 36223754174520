import { SelectOption } from 'modules/Shared/type';

export const SUBGROUP_REGIONAL = 2;
export const SUBGROUP_INTERREGIONAL = 1;
export const SUBGROUP_NULL = ' ';

export const subgroupOptions: SelectOption[] = [
  { value: SUBGROUP_INTERREGIONAL, label: 'programy interregionalne' },
  { value: SUBGROUP_REGIONAL, label: 'programy regionalne' },
  { value: SUBGROUP_NULL, label: 'brak' }
];

export const getSubgroupName = (value: number): string => {
  const subgroup = subgroupOptions.find((option) => option.value === value);
  return subgroup?.label || 'brak';
};
