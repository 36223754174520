import React from 'react';
import { useHistory } from 'react-router-dom';

interface IProps {
  booleanArr: boolean[];
  setBooleanArr: (state: boolean[]) => void;
  valueArr: string[];
  changeUrlParams?: boolean;
}

const urlValues = (element: string) => {
  switch (element) {
    case 'Dane podstawowe':
      return 'main';
    case 'Słowa kluczowe':
      return 'keywords';
    case 'Połączone klauzule':
      return 'clause';
    case 'Połączone orzeczenia':
      return 'judgments';
    default:
      return '';
  }
};

const NavTabs: React.FC<IProps> = ({ valueArr, booleanArr, setBooleanArr, changeUrlParams }) => {
  const history = useHistory();
  return (
    <div>
      <ul className="nav nav-tabs">
        {valueArr.map((el, index) => {
          return (
            // eslint-disable-next-line
            <li className="nav-item" key={index}>
              {/* eslint-disable-next-line */}
              <div
                className={`nav-link cursor-pointer${booleanArr[index] ? ' active' : ''}`}
                onClick={() => {
                  if (!booleanArr[index]) {
                    setBooleanArr(booleanArr.map((_, stateIndex) => stateIndex === index));
                    if (changeUrlParams) {
                      const urlParams = new URLSearchParams(window.location.search);
                      urlParams.set('t', urlValues(el));
                      history.push({ search: urlParams.toString() });
                    }
                  }
                }}
              >
                {el}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default NavTabs;
