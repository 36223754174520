import { RootState } from 'app/reducer';
import { managePageAction } from 'modules/Layout/action';
import Loader from 'modules/Layout/component/Loader';
import { breadcrumbRouteProfileRodo } from 'modules/Profile/breadcrumbs';
import Alert from 'modules/Layout/component/Alert';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLIENT_ROLES } from 'modules/User/model/UserRole';
import { ProfileRodo } from 'modules/Profile/container/Rodo/withProfieRodo';

const RodoView = () => {
  const dispatch = useDispatch();
  const { user, busy, message } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(
      managePageAction({
        title: 'RODO - edycja',
        breadcrumb: breadcrumbRouteProfileRodo()
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (busy) return <Loader />;

  if (message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  if (CLIENT_ROLES.includes(user?.role?.slug)) return <ProfileRodo user={user} />;

  return null;
};

export default RodoView;
