import React from 'react';
import BaseModal from 'modules/Layout/component/Modal';
import { Button } from 'reactstrap';

export interface StepModifiedModalProps {
  isOpen: boolean;
  toggle: (confirmed?: boolean) => void;
  submit: () => void;
}

const StepModifiedModal = (props: StepModifiedModalProps) => {
  const { isOpen, toggle, submit } = props;

  return (
    <BaseModal
      title="Niezapisane zmiany"
      isOpen={isOpen}
      toggle={() => toggle(false)}
      onClosed={() => toggle(false)}
      body="Wykryto niezapisane zmiany."
      footer={
        <div className="modal-actions">
          <Button type="button" color="secondary" className="waves-effect waves-light" onClick={() => toggle(false)}>
            Zostań na widoku
          </Button>
          <Button
            type="button"
            color="danger"
            className="pzpeu-btn-disabled waves-effect waves-light"
            onClick={() => toggle(true)}
          >
            Przejdź i porzuć zmiany
          </Button>
          <Button type="button" color="success" className="waves-effect waves-light" onClick={submit}>
            Zapisz i przejdź
          </Button>
        </div>
      }
    />
  );
};

export default StepModifiedModal;
