import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import Judgement from 'modules/Judgement/model/Judgement';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';

export type Props = {
  element?: Judgement;
};

const JudgementBasicDataset: React.FC<Props> = ({ element }) => {
  const fields = [
    {
      label: 'ID',
      value: element?.id
    },
    {
      label: 'Identyfikator',
      value: element?.identifier
    },
    {
      label: 'Nazwa organu publikującego dokument',
      value: element?.authority_name
    },
    {
      label: 'Rodzaj organu publikującego dokument',
      value: element?.authority_type
    },
    {
      label: 'Rodzaj dokumentu',
      value: element?.document_type
    },
    {
      label: 'Kategorie przypisane do orzeczenia',
      value: element?.categories_names
    },
    {
      label: 'Oryginalne miejsce publikacji',
      value: element?.place
    },
    {
      label: 'Termin publikacji',
      value: ifContainsDateFormat(element?.published_at, formatDate)
    },
    {
      label: 'Data utworzenia w systemie',
      value: ifContainsDateFormat(element?.created_at, formatDate)
    },
    {
      label: 'Data ostatniej edycji w systemie',
      value: ifContainsDateFormat(element?.updated_at, formatDate)
    }
    // {
    //   label: 'Treść publikacji:',
    //   value: element?.content,
    //   html: true
    // }
  ];

  return <InformationList fields={fields} />;
};

export default JudgementBasicDataset;
