import React, { useEffect, useState } from 'react';
import Proceedings from 'modules/Proceedings/model/Proceedings';
import ProceedingsBasicDetails from 'modules/Proceedings/component/Details/Basic';
import NavTabs from 'modules/Layout/component/NavTabs';
import SendMessageForm from 'modules/Proceedings/component/Details/SendMessage';
import { PROCEEDINGS_INIT_TYPES_VALUES } from 'modules/Proceedings/type';
import ProceedingsCPV from 'modules/Proceedings/component/Details/CPV';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import { useDispatch } from 'react-redux';
import { manageProceedingsFav } from 'modules/Proceedings/repository';
import { addToastAction } from 'modules/Layout/action';
import {
  removeProceedingsFromFavouriteToastSuccess,
  addProceedingsToFavouriteToastSuccess,
  proceedingsToastServerError
} from 'modules/Proceedings/toasts';

type Props = {
  proceedings: Proceedings;
  setDisplayDeleteModal?: () => void;
  isAdmin?: boolean;
  isPublic?: boolean;
  proceedingsType?: number;
};

const ProceedingsDetails: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    isAdmin,
    isPublic,
    proceedings,
    setDisplayDeleteModal,
    proceedingsType = PROCEEDINGS_INIT_TYPES_VALUES.OPEN
  } = props;
  const [nav, setNav] = useState([true, false, false]);
  const [navElements, setNavElements] = useState(['Dane podstawowe']);

  const displaySendNav = !isPublic && !isAdmin && proceedings?.proceeding_type === PROCEEDINGS_INIT_TYPES_VALUES.OPEN;

  useEffect(() => {
    const arr = [];
    if (isAdmin) arr.push('Kody CPV');

    if (displaySendNav) arr.push('Wyślij wiadomość');

    if (arr.length) setNavElements([...navElements, ...arr]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isSaved, setIsSaved] = useState(proceedings?.is_saved);
  const [isSaveLoading, setSsSaveLoading] = useState(false);

  const onFavouriteClick = async () => {
    if (isSaveLoading) return;
    setSsSaveLoading(true);

    try {
      const response = await manageProceedingsFav(proceedings.id);

      if (response.status === 204) {
        if (isSaved) dispatch(addToastAction(removeProceedingsFromFavouriteToastSuccess()));
        else dispatch(addToastAction(addProceedingsToFavouriteToastSuccess()));
        setIsSaved(!isSaved);
      }
    } catch (err) {
      dispatch(addToastAction(proceedingsToastServerError()));
    }
    setSsSaveLoading(false);
  };

  const actionSavedBtn = () => (
    <ActionUpdate
      title="Dodaj do ulubionych postępowań"
      label={<i className="far font-20 fa-heart text-danger" />}
      onClick={onFavouriteClick}
    />
  );
  const actionNotSavedBtn = () => (
    <ActionUpdate
      title="Usuń z ulubionych postępowań"
      label={<i className="fas font-20 fa-heart text-danger" />}
      onClick={onFavouriteClick}
    />
  );
  const aditionalActions = () => {
    if (!isPublic && !isAdmin) return <>{isSaved ? actionNotSavedBtn() : actionSavedBtn()}</>;
    return null;
  };

  return (
    <>
      {navElements.length > 1 && (
        <div className="details-top-nav mb-3 px-2">
          <NavTabs valueArr={navElements} booleanArr={nav} setBooleanArr={setNav} />
        </div>
      )}
      {nav[0] && (
        <ProceedingsBasicDetails
          proceedings={proceedings}
          setDisplayDeleteModal={setDisplayDeleteModal}
          isAdmin={isAdmin}
          aditionalActions={aditionalActions}
          proceedingsType={proceedingsType}
        />
      )}
      {isAdmin && nav[1] && <ProceedingsCPV isAdmin={isAdmin} />}
      {displaySendNav && nav[navElements.length - 1] && (
        <div className="user-details-view">
          <SendMessageForm proceedings={proceedings} />
        </div>
      )}
    </>
  );
};

export default ProceedingsDetails;
