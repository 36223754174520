import PZPlogo from 'modules/Layout/component/Navbar/logo/pzp/pzp_logo.png';
import BSlogo from 'modules/Layout/component/Navbar/logo/bs/bs_logo.png';
import NavbarLogout from 'modules/Layout/component/Navbar/Logout';
import NavbarUserInfo from 'modules/Layout/component/Navbar/UserInfo';
import { ROUTE_MAIN } from 'modules/Layout/routes';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { SCREEN_MOBILE_WIDTH } from 'modules/Layout/type';

export type Props = {
  toggleSidebar?: () => void;
  expanded?: boolean;
};

const Navbar: React.FC<Props> = (props) => {
  const { toggleSidebar, expanded } = props;
  const location = useLocation();

  const logos = {
    pzp: PZPlogo,
    bs: BSlogo
  };

  const themeTemplate = process.env.THEME_TEMPLATE as 'pzp' | 'bs';
  const logo = logos[themeTemplate];

  const prevLocationValue = React.useRef(location.pathname);

  React.useEffect(() => {
    const isMobile = window.innerWidth <= SCREEN_MOBILE_WIDTH;
    if (isMobile && expanded && prevLocationValue.current !== location.pathname) {
      prevLocationValue.current = location.pathname;
      toggleSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="navbar-custom d-flex">
      <div className="logo-box">
        <a target="_blank" href={ROUTE_MAIN} className="logo">
          <span className="logo-lg">
            <img src={logo} alt="logo Asystent Postępowania" height="36" />
          </span>
        </a>
      </div>
      <ul className="list-unstyled topnav-menu m-0 ml-auto">
        <NavbarUserInfo />
        <li>
          <NavbarLogout />
        </li>
        {toggleSidebar && (
          <li className="nav-link">
            <Button
              id="toggle-sidebar-btn"
              type="button"
              color="link"
              className="menu-toggle waves-effect waves-light p-0 m-0"
              onClick={toggleSidebar}
              aria-label={`${expanded ? 'Ukryj' : 'Pokaż'} menu`}
            >
              <i className="fe-menu font-24" />
            </Button>
            <UncontrolledTooltip className="toggle-sidebar-tooltip" placement="bottom-end" target="#toggle-sidebar-btn">
              {expanded ? 'Ukryj' : 'Pokaż'} menu
            </UncontrolledTooltip>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Navbar;
