import React, { ReactNode } from 'react';
import { Toast as ToastStrap, ToastBody } from 'reactstrap';
import { Toast as ToastType } from 'modules/Layout/type';
import BadCommunicateIcon from 'modules/Layout/component/Icon/BadCommunicate';
import InfoCommunicateIcon from 'modules/Layout/component/Icon/InfoCommunicate';
import GoodCommunicateIcon from 'modules/Layout/component/Icon/GoodCommunicate';

export interface Props extends ToastType {
  removeToast: (id: string) => void;
}

export interface State {
  isOpen: boolean;
}

class Toast extends React.Component<Props, State> {
  autoRemoveTimeout: NodeJS.Timeout = null;
  removeTimeout: NodeJS.Timeout = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: true
    };

    this.removeToast = this.removeToast.bind(this);
    this.closeToast = this.closeToast.bind(this);
  }

  componentDidMount(): void {
    const { closeIn } = this.props;

    if (closeIn) {
      this.autoRemoveTimeout = setTimeout(() => {
        this.closeToast();
      }, closeIn);
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.removeTimeout);
    clearTimeout(this.autoRemoveTimeout);
  }

  closeToast(): void {
    this.setState(
      {
        isOpen: false
      },
      () => {
        this.removeToast();
      }
    );
  }

  removeToast(): void {
    const { id, removeToast } = this.props;

    clearTimeout(this.autoRemoveTimeout);
    clearTimeout(this.removeTimeout);

    this.removeTimeout = setTimeout(() => {
      removeToast(id);
    }, 1000);
  }

  render(): ReactNode {
    const { body, color = 'info' } = this.props;
    const { isOpen } = this.state;

    let icon: string | React.ReactNode = null;

    if (color === 'danger') {
      icon = <BadCommunicateIcon />;
    } else if (color === 'info') {
      icon = <InfoCommunicateIcon />;
    } else if (color === 'success') {
      icon = <GoodCommunicateIcon />;
    }

    return (
      <ToastStrap isOpen={isOpen} onClick={this.closeToast}>
        <ToastBody className={`alert-${color}`}>
          {Boolean(icon) && <div className="toast-icon">{icon}</div>}
          <div className="toast-content">{body}</div>
        </ToastBody>
      </ToastStrap>
    );
  }
}

export default Toast;
