import React, { useEffect, useState } from 'react';
import User from 'modules/User/model/User';
import { useDispatch } from 'react-redux';
import { managePageAction } from 'modules/Layout/action';
import { Message } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { DashboardAdminValues, PageProps } from 'modules/Layout/type';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { fetchDashboardAdminData } from 'modules/Layout/repository';
import CardBox from 'modules/Layout/component/CardBox';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import LastCreatedUser from 'modules/Layout/component/DashboardWidgets/LastCreatedUser';
import LastNeededUpdateExecutor from 'modules/Layout/component/DashboardWidgets/LastNeededUpdateExecutor';
import OfficeResponseAwaiting from 'modules/Layout/component/DashboardWidgets/OfficeResponeAwaiting';
import LastNeededUpdateJudgment from 'modules/Layout/component/DashboardWidgets/LastNeededUpdateJudgment';
import './index.scss';

type Props = {
  user?: User;
};

const AdminDashboard: React.FC<Props> = ({ user }) => {
  const dispatch = useDispatch();
  const [displayData, setDisplayData] = useState<DashboardAdminValues>({});
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState<Message>(null);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  const cancelToken = useCancelToken();
  useEffect(() => {
    const name = user?.getName?.();

    if (name)
      managePage({
        title: `Witaj ${name}!`
      });

    (async () => {
      setFetching(true);
      const { data, cancelled, message: messageResponse } = await fetchRequest(fetchDashboardAdminData, cancelToken);

      if (cancelled) return;
      if (data?.data) setDisplayData(data.data);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { users, offices, executors, judgments } = displayData;

  if (fetching) return <Loader />;
  if (message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );

  if (displayData)
    return (
      <div className="row admin-dashboard">
        <div className="col-lg-6 first-row">
          {!!offices?.length && (
            <CardBox
              heading="Urzędy zalegające z wysłaniem odpowiedzi"
              paddingRight="30px"
              paddingBottom="13px"
              childrenPaddingTop="25px"
            >
              <div className="d-flex flex-wrap office-response-wrapper">
                {offices?.map((office) => (
                  <div key={office?.id} className="col-12 col-xl-6 p-0 office-response">
                    <OfficeResponseAwaiting office={office} />
                  </div>
                ))}
              </div>
            </CardBox>
          )}

          {!!users?.length && (
            <CardBox
              heading="Ostatnio utworzeni klienci"
              paddingRight="30px"
              paddingBottom="13px"
              childrenPaddingTop="25px"
            >
              <div className="d-flex flex-wrap last-created-user-wrapper">
                {users?.map((userValue) => (
                  <div key={userValue?.id} className="col-12 col-xl-6 last-created-user">
                    <LastCreatedUser user={userValue} />
                  </div>
                ))}
              </div>
            </CardBox>
          )}
        </div>

        <div className="col-lg-6 second-row">
          {!!executors?.length && (
            <CardBox
              heading="Wykonawcy, wymagający uzupełnienia danych"
              paddingRight="30px"
              paddingBottom="13px"
              childrenPaddingTop="25px"
            >
              <div className="d-flex flex-wrap last-needed-update-executor-wrapper">
                {executors?.map((executor) => (
                  <div key={executor?.id} className="col-12 col-xl-6 p-0 last-needed-update-executor">
                    <LastNeededUpdateExecutor executor={executor} />
                  </div>
                ))}
              </div>
            </CardBox>
          )}

          {!!judgments?.length && (
            <CardBox
              heading="Orzeczenia, wymagające uzupełnienia danych"
              paddingRight="30px"
              paddingBottom="13px"
              childrenPaddingTop="25px"
            >
              <div className="d-flex flex-wrap last-needed-update-executor-wrapper">
                {judgments?.map((judgment) => (
                  <div key={judgment?.id} className="col-12 col-xl-6 p-0 last-needed-update-executor">
                    <LastNeededUpdateJudgment judgment={judgment} />
                  </div>
                ))}
              </div>
            </CardBox>
          )}
        </div>
      </div>
    );

  return null;
};

export default AdminDashboard;
