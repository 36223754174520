import React from 'react';
import BaseModal from 'modules/Layout/component/Modal';
import { Button } from 'reactstrap';
import { AgreementStepSlug } from 'modules/Agreements/step';
import { getStepName } from 'modules/Agreements/helper/agreement';
import { AgreementMode } from 'modules/Agreements/type';

export interface DownloadMissingStepsProps {
  isOpen: boolean;
  downloadMissingSteps: AgreementStepSlug[];
  toggle: (status: boolean) => void;
  mode: AgreementMode;
}

const DownloadMissingStepsModal = (props: DownloadMissingStepsProps) => {
  const { isOpen, downloadMissingSteps, toggle, mode } = props;

  const renderMissingSteps = () => {
    return downloadMissingSteps.map((step, index) => (
      <p key={`download_missing_steps_${index}`}>- {getStepName(step, mode)}</p>
    ));
  };

  return (
    <BaseModal
      title="Niewypełnione kroki"
      isOpen={isOpen}
      toggle={() => toggle(false)}
      onClosed={() => toggle(false)}
      body={
        <div>
          <p>Nie można pobrać umowy, dopóki poniższe kroki nie zostaną wypełnione.</p>
          {renderMissingSteps()}
        </div>
      }
      footer={
        <div className="modal-actions">
          <Button
            type="button"
            color="danger"
            className="pzpeu-btn-disabled waves-effect waves-light"
            onClick={() => toggle(false)}
          >
            Rozumiem
          </Button>
        </div>
      }
    />
  );
};

export default DownloadMissingStepsModal;
