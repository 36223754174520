import { AxiosPromise } from 'axios';
import { ColorVariants, Message } from 'modules/Shared/type';

type Response = {
  message?: Message;
  error?: any;
  cancelled?: boolean;
  success?: boolean;
};

const updateRequest = async (request: (...args: any[]) => AxiosPromise, ...args: any[]): Promise<Response> => {
  let message: Message = null;

  try {
    await request(...args);
    return { success: true };
  } catch (error) {
    if (error?.message === 'AxiosCancel') {
      return { cancelled: true };
    }

    message = {
      value: error?.error?.response?.data?.message || error?.message || 'Something went wrong.',
      variant: ColorVariants.Danger
    };

    return { message, error: error?.error?.response?.data?.errors || {}, success: false };
  }
};

export default updateRequest;

type ResponseWithData<T> = {
  data?: T;
  message?: Message;
  error?: any;
  cancelled?: boolean;
};

export const updateRequestWithData = async <T>(
  request: (...args: any[]) => AxiosPromise<T>,
  ...args: any[]
): Promise<ResponseWithData<T>> => {
  let message: Message = null;

  try {
    const response = await request(...args);

    const { data } = response;

    return { data };
  } catch (error) {
    if (error?.message === 'AxiosCancel') {
      return { cancelled: true };
    }

    message = {
      value: error?.error?.response?.data?.message || error?.message || 'Something went wrong.',
      variant: ColorVariants.Danger
    };

    return { message, error: error?.error?.response?.data?.errors || {} };
  }
};
