import classNames from 'classnames';
import * as H from 'history';
import { ColorVariants } from 'modules/Shared/type';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

export interface ActionProps {
  id?: string;
  title?: string;
  label?: ReactNode;
  color?: ColorVariants;
  className?: string;
  disabled?: boolean;
  to?: H.LocationDescriptor | ((location: H.Location) => H.LocationDescriptor);
  onClick?: () => void;
  redirectWithState?: { [key: string]: any };
}

const Action: React.FC<ActionProps> = ({
  title = null,
  label = null,
  color,
  className = '',
  disabled = false,
  to = null,
  onClick = null,
  id = null,
  redirectWithState
}: ActionProps): JSX.Element => {
  if (onClick || disabled) {
    return (
      <Button
        id={id}
        type="button"
        color="link"
        className={classNames(
          'd-inline-flex',
          'align-items-center',
          'justify-content-center',
          'p-0',
          className,
          color && `text-${color}`
        )}
        title={title}
        disabled={disabled}
        onClick={onClick}
      >
        {label}
      </Button>
    );
  }

  if (to) {
    return (
      <Link
        type="button"
        color="link"
        id={id}
        title={title}
        className={classNames(
          'd-inline-flex',
          'align-items-center',
          'justify-content-center',
          className,
          color && `text-${color}`
        )}
        to={redirectWithState ? { pathname: to as string, state: redirectWithState } : to}
      >
        {label}
      </Link>
    );
  }

  return null;
};

export default Action;
