import React, { ReactElement, useRef, useState } from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { InputProps } from 'reactstrap/lib/Input';
import classNames from 'classnames';
import './index.scss';

export interface Props extends InputProps {
  icon: ReactElement;
  iconHeight?: string;
}

const WithIconInput: React.FC<Props> = (props: Props) => {
  const { icon, iconHeight = '22px', ...restProps } = props;
  const { className, invalid } = props;

  const [focused, setFocus] = useState(false);
  const inputRef = useRef(null);

  const clonedIcon = React.cloneElement(icon, {
    fill: focused ? '#000' : '#666666',
    height: iconHeight
  });

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      setFocus(false);
    }
  };

  return (
    <InputGroup
      className={classNames(
        {
          focused
        },
        'with-icon-input'
      )}
    >
      <InputGroupText
        onClick={() => inputRef?.current?.focus()}
        className={classNames({ 'is-invalid': invalid }, 'd-flex justify-content-center align-items-center')}
      >
        {clonedIcon}
      </InputGroupText>
      <Input
        {...restProps}
        innerRef={inputRef}
        className={classNames({ 'is-invalid': invalid }, `input-icon ${className || ''}`)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onKeyDown={onKeyDown}
      />
    </InputGroup>
  );
};

export default WithIconInput;
