import React from 'react';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import _uniqueId from 'lodash/uniqueId';
import DragDropInput from 'modules/Layout/component/DragDropInput';

export type IFile = {
  file?: File;
  id?: string;
};

type Props = {
  values: { [objKey: string]: IFile | null }; // Updated to support a single file
  setValues: (value: React.SetStateAction<any>) => void;
  objKey?: string;
};

const AddSibleFileDragDrop: React.FC<Props> = ({ values, setValues, objKey = 'file' }) => {
  const removeFile = () => {
    setValues({
      ...values,
      [objKey]: null // Set the file to null when removed
    });
  };

  const onDrop = (files: File[]) => {
    if (files.length > 0) {
      setValues({
        ...values,
        [objKey]: { file: files[0], id: _uniqueId(files[0]?.name ?? '') } // Only accept the first file
      });
    }
  };

  const displayFile = () => {
    const file = values[objKey];
    if (file) {
      return (
        <div className="my-2 d-flex flex-wrap">
          <span className="mr-2">{file?.file?.name}</span>
          <ActionDelete title="Usuń plik" onClick={removeFile} />
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <DragDropInput options={{ onDrop }} />
      <div className="cursor-pointer mb-0">{displayFile()}</div>
    </>
  );
};

export default AddSibleFileDragDrop;
