import React from 'react';
import { ModuleEntity } from 'modules/Module/model/Module';
import CardBox from 'modules/Layout/component/CardBox';
import { ModuleAccessHistoryEntity } from 'modules/Module/model/ModuleAccessHistory';
import ModulesAccessHistoryAbonamentTable from 'modules/Module/container/Details/Abonaments/Table';
import ModulesAccessHistoryCreditTable from 'modules/Module/container/Details/Credits/Table';

export interface Props {
  accessHistory: ModuleAccessHistoryEntity[];
  redirectUrl: string;
}

export type ModuleEditModalObj = {
  module?: ModuleEntity;
  values?: { all_credits_count?: number; available_credits?: number; date_to: string };
};

const DetailsModules: React.FC<Props> = ({ accessHistory, redirectUrl }) => {
  return (
    <>
      {accessHistory.map((moduleAccessHistory, index, arr) => {
        const { module, subscriptions, credits } = moduleAccessHistory;

        return (
          <CardBox
            heading={module?.name}
            redirectReturnBtn={index === 0 ? redirectUrl : null}
            redirectReturnBtnBottom={arr.length - 1 === index ? redirectUrl : null}
            key={module?.id}
          >
            <div className="row users-view">
              <div className="col-12">
                <div className="font-20 mb-2">Abonamenty</div>
                {subscriptions ? (
                  <div className="pzpeu-table-list inside-card-box mb-1">
                    <ModulesAccessHistoryAbonamentTable subscriptions={subscriptions} />
                  </div>
                ) : (
                  <div className="my-1">brak przypisanych abonamentów</div>
                )}
                {module?.has_credits && (
                  <>
                    <div className="font-20 mt-5 mb-2">Kredyty</div>
                    {credits ? (
                      <div className="pzpeu-table-list inside-card-box mb-1">
                        <ModulesAccessHistoryCreditTable credits={credits} />
                      </div>
                    ) : (
                      <div className="my-1">brak przypisanych kredytów</div>
                    )}
                  </>
                )}
              </div>
            </div>
          </CardBox>
        );
      })}
    </>
  );
};

export default DetailsModules;
