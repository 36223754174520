import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import { PaymentLogsValues } from 'modules/Profile/type';
import { paymentLogStatusValue } from 'modules/Shared/components/PaymentLog/helper';
import { formatDate, formatTimeValue } from 'modules/Shared/helper/utils';

export interface Props {
  paymentLog: PaymentLogsValues;
}

const PAYMENT_HISTORY_ONLINE_PAYMENT_STATUS = 0;

const PaymentLogDataset: React.FC<Props> = ({ paymentLog }) => {
  const fields = [
    {
      label: 'Identyfikator transakcji',
      value: paymentLog?.payment_number
    },
    {
      label: 'Typ transakcji',
      value:
        paymentLog?.type === PAYMENT_HISTORY_ONLINE_PAYMENT_STATUS
          ? 'Płatność online'
          : 'Płatność po otrzymaniu faktury'
    },
    {
      label: 'Identyfikator Stripe',
      value: paymentLog?.payment_id
    },
    {
      label: 'Status',
      value: paymentLogStatusValue(paymentLog?.status)
    },
    {
      label: 'Data zakupu',
      value: paymentLog?.finished_at ? (
        <>
          <span>{formatDate(paymentLog.finished_at)}</span>{' '}
          <span className="payment-time">{formatTimeValue(paymentLog.finished_at)}</span>
        </>
      ) : (
        ''
      )
    },
    {
      label: 'Kwota brutto',
      value: paymentLog?.amount ? `${paymentLog.amount.toFixed(2).replace('.', ',')} PLN` : ''
    },
    {
      label: 'Wartość opodatkowania',
      value: paymentLog?.tax_value ? `${paymentLog.tax_value.toFixed(2).replace('.', ',')} PLN` : ''
    },
    {
      label: 'Faktura',
      value: paymentLog?.wants_invoice ? 'Tak' : 'Nie'
    }
  ];

  return <InformationList fields={fields} />;
};

export default PaymentLogDataset;
