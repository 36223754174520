import React from 'react';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';

export interface Props<T> {
  message?: Message;
  fetching?: boolean;
  data?: T;
}

const FetchingWrapper = <T,>({ message, data, fetching, children }: React.PropsWithChildren<Props<T>>) => {
  if (fetching) return <Loader />;
  if (!fetching && !data && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  if (!fetching && data) {
    return <>{children}</>;
  }

  return null;
};

export default FetchingWrapper;
