import React from 'react';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_EXECUTOR_DUTIES } from 'modules/Agreements/step';
import { AgreementStepExecutorDutiesValues } from 'modules/Agreements/components/Steps/Default/ExecutorDuties';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';

const AgreementStepPreviewExecutorDuties = (
  props: AgreementStepPreviewProps<AgreementStepExecutorDutiesValues>
): JSX.Element => {
  const { steps, modifiedStep } = props;
  const executorDuties = getStepValues<AgreementStepExecutorDutiesValues>(steps, AGREEMENT_STEP_EXECUTOR_DUTIES);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={executorDuties}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    executorDuties,
    modifiedStep,
    `checkboxes.general`
  );

  const { value: recommendationsPossibility, modified: recommendationsPossibilityModified } = getFieldForPreview(
    executorDuties,
    modifiedStep,
    `recommendations_possibility`
  );

  const { value: properExecutionPossibility, modified: properExecutionPossibilityModified } = getFieldForPreview(
    executorDuties,
    modifiedStep,
    `proper_execution_possibility`
  );

  const renderFirstPointStatus = (): boolean => {
    return (
      getFieldForPreview(executorDuties, modifiedStep, `recommendations_possibility`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `proper_execution_possibility`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `preparatory_works_checked`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `schedule_checked`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `estimate_checked`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `energy_cost_checked`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `waste_checked`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `area_securing_checked`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `area_order_checked`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `contracting_authority_checked`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `property_drive_checked`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `subexecutors_works_checked`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `log_building_checked`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `technical_equipment_checked`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `securing_benefits_checked`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `square_liquidation_checked`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `responsibility_road_checked`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `responsibility_noise_checked`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `shortcomings_checked`).value ||
      getFieldForPreview(executorDuties, modifiedStep, `no_realization_checked`).value
    );
  };

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_EXECUTOR_DUTIES)}</div>
      <div className="agreement-step-preview-content reset-preview-point reset-nested-preview-point">
        {renderFirstPointStatus() && <p className={classNames('preview-point')}>Do obowiązków wykonawcy należy:</p>}
        {recommendationsPossibility && [
          <p
            className={classNames('preview-nested-alpha-point', {
              modified: recommendationsPossibilityModified
            })}
          >
            Wykonanie Przedmiotu Umowy zgodnie z:
          </p>,
          renderCheckbox('recommendations_executor', 'preview-nested-dash pl-4'),
          renderCheckbox('recommendations_rules', 'preview-nested-dash pl-4'),
          renderCheckbox('recommendations_law', 'preview-nested-dash pl-4'),
          renderCheckbox('recommendations_norms', 'preview-nested-dash pl-4'),
          renderCheckbox('recommendations_other', 'preview-nested-dash pl-4')
        ]}
        {properExecutionPossibility && [
          <p
            className={classNames('preview-nested-alpha-point', {
              modified: properExecutionPossibilityModified
            })}
          >
            Prawidłowe wykonanie wszystkich prac związanych z realizacją Przedmiotu Umowy, zgodnie z:
          </p>,
          renderCheckbox('proper_execution_agreement', 'preview-nested-dash pl-4'),
          renderCheckbox('proper_execution_project', 'preview-nested-dash pl-4'),
          renderCheckbox('proper_execution_specification', 'preview-nested-dash pl-4'),
          renderCheckbox('proper_execution_knowledge', 'preview-nested-dash pl-4'),
          renderCheckbox('proper_execution_conditions', 'preview-nested-dash pl-4'),
          renderCheckbox('proper_execution_norms', 'preview-nested-dash pl-4'),
          renderCheckbox('proper_execution_other', 'preview-nested-dash pl-4')
        ]}
        {renderCheckbox('preparatory_works', 'preview-nested-alpha-point')}
        {renderCheckbox('schedule', 'preview-nested-alpha-point')}
        {renderCheckbox('estimate', 'preview-nested-alpha-point')}
        {renderCheckbox('energy_cost', 'preview-nested-alpha-point')}
        {renderCheckbox('waste', 'preview-nested-alpha-point')}
        {renderCheckbox('area_securing', 'preview-nested-alpha-point')}
        {renderCheckbox('area_order', 'preview-nested-alpha-point')}
        {renderCheckbox('contracting_authority', 'preview-nested-alpha-point')}
        {renderCheckbox('property_drive', 'preview-nested-alpha-point')}
        {renderCheckbox('subexecutors_works', 'preview-nested-alpha-point')}
        {renderCheckbox('log_building', 'preview-nested-alpha-point')}
        {renderCheckbox('technical_equipment', 'preview-nested-alpha-point')}
        {renderCheckbox('securing_benefits', 'preview-nested-alpha-point')}
        {renderCheckbox('square_liquidation', 'preview-nested-alpha-point')}
        {renderCheckbox('responsibility_road', 'preview-nested-alpha-point')}
        {renderCheckbox('responsibility_noise', 'preview-nested-alpha-point')}
        {renderCheckbox('shortcomings')}
        {renderCheckbox('no_realization')}
        {generalCheckboxes &&
          generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-point wysiwyg-preview', {
                  modified: generalCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AgreementStepPreviewExecutorDuties;
