import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import Clause, { createClause } from 'modules/Clause/model/Clause';
import { deleteClause, fetchClause } from 'modules/Clause/repository';
import { breadcrumbRouteClauseDetails } from 'modules/Clause/breadcrumbs';
import ClauseDisplayDetails from 'modules/Clause/component/Details';
import { push } from 'connected-react-router';
import { ROUTE_CLAUSE_LIST } from 'modules/Clause/routes';
import { deleteClauseToastSuccess } from 'modules/Clause/toasts';

const ClauseDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [clause, setClause] = useState<Clause>(null);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatchDeleteClauseToast = () => dispatch(addToastAction(deleteClauseToastSuccess()));

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const { data, cancelled, message: messageResponse } = await fetchRequest(fetchClause, id, cancelToken);

      if (cancelled) return;
      if (data) setClause(createClause(data.data));
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clause)
      dispatch(
        managePageAction({
          title: 'Szczegóły klauzuli',
          breadcrumb: breadcrumbRouteClauseDetails(clause.name, clause.id)
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clause]);

  const deleteClauseAction = async (judgment_id: number | string) => {
    setLoading(true);
    const { cancelled, message: messageRes, success } = await deleteRequest(deleteClause, judgment_id, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteClauseToast();
      dispatch(push(ROUTE_CLAUSE_LIST));
    } else {
      if (messageRes) setMessage(messageRes);
      setLoading(false);
    }
  };

  if (fetching) return <Loader />;
  if (!fetching && !clause && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  if (!fetching && clause) {
    return (
      <div className="row user-details-view position-relative">
        {displayModal && (
          <GenericModalDelete
            value={clause}
            title="Usunięcie klauzuli"
            content={(value) => <div>Czy na pewno chcesz usunąć klauzulę o nazwie - {value?.name}?</div>}
            onDeleteClick={(modalClause) => deleteClauseAction(modalClause.id)}
            isOpen={displayModal}
            toggle={() => setDisplayModal(null)}
            loading={loading}
            message={message}
            resetMessage={() => setMessage(null)}
          />
        )}
        <ClauseDisplayDetails clause={clause} setDisplayDeleteModal={() => setDisplayModal(true)} />
      </div>
    );
  }

  return null;
};

export default ClauseDetails;
