import { AgreementsListAction, AGREEMENTS_LIST, SET_AGREEMENTS_LIST_PARAMS } from 'modules/Agreements/action/list';
import { AgreementListState, initAgreementListState } from 'modules/Agreements/state/list';
import { createAgreement } from 'modules/Agreements/model/Agreements';

const initState = initAgreementListState();

const reducer = (state: AgreementListState = initState, action: AgreementsListAction): AgreementListState => {
  switch (action.type) {
    case SET_AGREEMENTS_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case AGREEMENTS_LIST: {
      const { data = [], meta } = action.payload;

      return { ...state, fetching: false, data: data.map(createAgreement), meta };
    }

    default:
      return state;
  }
};

export default reducer;
