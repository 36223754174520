import { PaginationMeta, SearchingProps } from 'modules/Shared/type';
import { AlertList } from '../../model/Alert';

export interface ForeignProceedingsAlertsListState extends SearchingProps {
  list: AlertList[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initForeignProceedingsAlertsListState = (): ForeignProceedingsAlertsListState => ({
  list: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
