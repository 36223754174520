import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import { MisrepresentationDataItem, OrderEntity } from 'modules/ExecutorOrder/model/Order';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';

export interface Props {
  misrepresentation?: MisrepresentationDataItem;
  order?: OrderEntity;
}

const ExecutorDataClientMisrepresentationDataset: React.FC<Props> = ({ misrepresentation = {}, order = {} }) => {
  const fields = [
    {
      label: 'Podmiot udzielający informacji publicznej (zamawiający)',
      value: <strong>{order.principal_name || 'nie dotyczy'}</strong>
    },
    {
      label: 'Przyczyny wykluczenia z postępowania',
      value: misrepresentation.violation_description || 'nie dotyczy',
      html: true
    },
    {
      label: 'Data wykluczenia',
      value: ifContainsDateFormat(misrepresentation.violation_date, formatDate) || 'nie dotyczy'
    },
    {
      label: 'Nr umowy o udzielenie zamówienia',
      value: order.contract_number || 'nie dotyczy'
    },
    {
      label: 'Sygn. wyroku Krajowej Izby Odwoławczej',
      value: misrepresentation.exclusion_identifier || 'nie dotyczy'
    },
    {
      label: 'Nazwa oraz numer postępowania',
      value:
        order.name || order.identifier
          ? `${order.name || '-'}; numer postępowania: ${order.identifier || '-'}`
          : 'nie dotyczy'
    }
  ];

  return <InformationList fields={fields} />;
};

export default ExecutorDataClientMisrepresentationDataset;
