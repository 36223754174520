import { Routes } from 'app/routes';
import Unguarded from 'modules/Auth/component/Unguarded';
import Dashboard from 'modules/Layout/view/Dashboard';
import PublicMainView from 'modules/Layout/view/PublicMain';
import React from 'react';
import { Route } from 'react-router-dom';
import DeclarationOfAvailability from 'modules/Layout/view/DeclarationOfAvailability';
import { displayHeaderElement } from 'modules/Feedback/routes';
import PublicWrapper from './component/Wrapper/Public';
import AccountDeletion from './view/AccountDeletion';
import LoginView from '../Auth/view/Login';

const templateTheme = process.env.THEME_TEMPLATE as 'pzp' | 'bs';

export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_MAIN = templateTheme === 'pzp' ? 'https://postepowania.pl/' : 'https://sarnowski.info.pl/';
export const ROUTE_BASE = '/';
export const ROUTE_DECLARATION_OF_AVAILABILITY = '/declaration_of_availability';
export const ROUTE_PUBLIC_DECLARATION_OF_AVAILABILITY = '/public_declaration_of_availability';
export const ROUTE_PUBLIC_ACCOUNT_DELETION = '/public_account_deletion';

export const createLayoutRoutes = (): Routes => ({
  wrapped: [
    <Route key="dashboard" path={ROUTE_DASHBOARD} exact component={Dashboard} />,
    <Route
      key="declaration-of-availability"
      path={ROUTE_DECLARATION_OF_AVAILABILITY}
      exact
      component={DeclarationOfAvailability}
    />
  ],
  public: [
    <Route key="dashboard" path={ROUTE_BASE} exact>
      <Unguarded>{templateTheme === 'pzp' ? <PublicMainView /> : <LoginView />}</Unguarded>
    </Route>,
    <Route
      key="public-declaration-of-availability"
      path={ROUTE_PUBLIC_DECLARATION_OF_AVAILABILITY}
      exact
      render={() => (
        <PublicWrapper displayHeaderElement={displayHeaderElement}>
          <DeclarationOfAvailability />
        </PublicWrapper>
      )}
    />,
    <Route
      key="public_account_deletion"
      path={ROUTE_PUBLIC_ACCOUNT_DELETION}
      exact
      render={() => (
        <PublicWrapper displayHeaderElement={displayHeaderElement}>
          <AccountDeletion />
        </PublicWrapper>
      )}
    />
  ]
});
