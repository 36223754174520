import React, { useEffect, useState } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { createSearchingProps } from 'modules/Shared/helper/params';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { Message, PartialSearchingProps, ValidationErrors } from 'modules/Shared/type';
import { setClauseCategoryListParamsAction } from 'modules/Clause/action/CategoryList';
import { breadcrumbRouteClauseCategory } from 'modules/Clause/breadcrumbs';
import JudgementElementTable from 'modules/Judgement/container/JudgementElementList/Table';
import JudgementElementPerPage from 'modules/Judgement/container/JudgementElementList/PerPage';
import JudgementElementPagination from 'modules/Judgement/container/JudgementElementList/Pagination';
import JudgementElementFilter from 'modules/Judgement/container/JudgementElementList/Filter';
import { ROUTE_CLAUSE_CATEGORIES } from 'modules/Clause/routes';
import { Button } from 'reactstrap';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { createClauseCategory, deleteClauseCategory, updateClauseCategory } from 'modules/Clause/repository';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import Authorize from 'modules/Auth/container/Authorize';
import JudgementElement from 'modules/Judgement/model/JudgementElement';
import { TableCol } from 'modules/Layout/component/Table';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import {
  createClauseCategoryToastSuccess,
  deleteClauseCategoryToastSuccess,
  updateClauseCategoryToastSuccess
} from 'modules/Clause/toasts';
import useCategoryUpdateModal from 'modules/Judgement/component/Modal/JudgementElement/useCategoryUpdateModal';
import useCategoryDeleteModal from 'modules/Judgement/component/Modal/JudgementElement/useCategoryDeleteModal';
import { CLAUSE_CATEGORIES_ADD, CLAUSE_CATEGORIES_DELETE, CLAUSE_CATEGORIES_EDIT } from 'modules/Clause/permissions';
import ManageElementModal from 'modules/Judgement/component/Modal/JudgementElement';
import EditIcon from 'modules/Layout/component/Icon/Edit';
import actionDeleteTrashIconList from 'modules/Shared/helper/actionDeleteTrashIconList';

const ClauseCategories = () => {
  const dispatch = useDispatch();
  const { fetching, list, sort, filter, pagination, meta } = useSelector(
    (state: RootState) => state.clause.categoryList
  );
  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const setParams = (payload: PartialSearchingProps) => dispatch(setClauseCategoryListParamsAction(payload));

  const searchProps = createSearchingProps(
    '',
    JudgementElement.getFilterableAttributes(),
    JudgementElement.getSortableAttributes()
  );

  useEffect(() => {
    managePage({
      title: 'Klauzule - kategorie',
      breadcrumb: breadcrumbRouteClauseCategory()
    });

    setParams({ ...searchProps });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchCreateCategoryToast = () => dispatch(addToastAction(createClauseCategoryToastSuccess()));

  const cancelToken = useCancelToken();
  const createCategoryAction = async (data: { name: string }) => {
    setLoading(true);
    const {
      cancelled,
      data: resData,
      message: messageRequest,
      error
    } = await updateRequestWithData(createClauseCategory, data, cancelToken);

    if (cancelled) return;
    if (resData?.data) {
      dispatchCreateCategoryToast();
      setDisplayAddModal(false);
      setParams({});
    }
    if (error) setErrors(error);
    if (messageRequest) setMessage(messageRequest);
    setLoading(false);
  };

  const {
    updateCategoryAction,
    loading: updateLoading,
    displayEditModal,
    setDisplayEditModal,
    message: updateMessage,
    errors: updateErrors,
    clearErrors: cleanUpdate
  } = useCategoryUpdateModal(updateClauseCategory, updateClauseCategoryToastSuccess, () => {
    setDisplayEditModal(null);
    setParams({});
  });

  const {
    deleteCategoryAction,
    loading: deleteLoading,
    displayDeleteModal,
    setDisplayDeleteModal,
    message: deleteMessage,
    clearErrors: cleanDelete
  } = useCategoryDeleteModal(
    deleteClauseCategory,
    deleteClauseCategoryToastSuccess,
    () => {
      setDisplayDeleteModal(null);
      setParams({});
    },
    true
  );

  const toggleCreateModal = () => {
    if (!loading) setDisplayAddModal(false);
  };

  const toggleEditModal = () => {
    if (!updateLoading) setDisplayEditModal(null);
  };

  const toggleDeleteModal = () => {
    if (!deleteLoading) setDisplayDeleteModal(null);
  };

  const clearErrors = () => {
    setMessage(null);
    setErrors(null);
  };

  const onEditClicked = (category: JudgementElement) => setDisplayEditModal(category);
  const onDeleteClicked = (category: JudgementElement) => setDisplayDeleteModal(category);

  const tableActions: TableCol<JudgementElement> = {
    property: 'actions',
    label: 'Akcje',
    value: function Actions(row) {
      return (
        <div className="actions-wrapper">
          {onEditClicked && (
            <Authorize permissions={[CLAUSE_CATEGORIES_EDIT]}>
              <ActionUpdate
                className="mx-1"
                title="Edytuj kategorię"
                label={<EditIcon height="20px" />}
                onClick={() => onEditClicked(row)}
              />
            </Authorize>
          )}
          {onDeleteClicked && (
            <Authorize permissions={[CLAUSE_CATEGORIES_DELETE]}>
              {actionDeleteTrashIconList(row, {
                targetIdName: 'DeleteCategoryId',
                onDeleteClick: onDeleteClicked,
                tooltipContent:
                  'Nie można usunąć, ponieważ co najmniej jedna klauzula jest przypisana do tej kategorii.',
                title: 'Usuń kategorię'
              })}
            </Authorize>
          )}
        </div>
      );
    }
  };

  return (
    <div className="row offices-view">
      <div className="col-12">
        {displayAddModal && (
          <ManageElementModal
            title="Dodaj kategorię"
            bodyTitle="Nazwa kategorii"
            isOpen={displayAddModal}
            toggle={toggleCreateModal}
            loading={loading}
            message={message}
            errors={errors}
            clearErrorsOnUnmount={clearErrors}
            onSubmit={createCategoryAction}
          />
        )}
        {displayEditModal && (
          <ManageElementModal
            element={displayEditModal}
            title="Edytuj kategorię"
            bodyTitle="Nazwa kategorii"
            isOpen={!!displayEditModal}
            toggle={toggleEditModal}
            loading={updateLoading}
            message={updateMessage}
            errors={updateErrors}
            clearErrorsOnUnmount={cleanUpdate}
            onSubmit={updateCategoryAction}
          />
        )}
        {displayDeleteModal && (
          <GenericModalDelete
            value={displayDeleteModal}
            title="Usuń kategorię"
            content={(value) => (
              <div>
                Czy na pewno chcesz usunąć kategorię o nazwie <strong>{value?.name}</strong>?
              </div>
            )}
            onDeleteClick={(value) => deleteCategoryAction(value.id)}
            isOpen={!!displayDeleteModal}
            toggle={toggleDeleteModal}
            loading={deleteLoading}
            message={deleteMessage}
            resetMessage={cleanDelete}
          />
        )}
        <Listing
          table={
            <JudgementElementTable
              categoryList={{ list, sort }}
              actions={tableActions}
              reduxAction={setClauseCategoryListParamsAction}
              nameSortable
            />
          }
          filter={
            <JudgementElementFilter
              values={searchProps.filter}
              reduxAction={setClauseCategoryListParamsAction}
              categoryList={{ filter, pagination }}
            />
          }
          pagination={
            <JudgementElementPagination
              path={ROUTE_CLAUSE_CATEGORIES}
              listObj={{ sort, filter, pagination, meta }}
              reduxAction={setClauseCategoryListParamsAction}
            />
          }
          perPage={<JudgementElementPerPage meta={meta} reduxAction={setClauseCategoryListParamsAction} />}
          loading={fetching}
          childrenNextToPagination={
            <Authorize permissions={[CLAUSE_CATEGORIES_ADD]}>
              <Button
                color="link"
                className="link-with-border table-list-action p-0"
                onClick={() => setDisplayAddModal(true)}
              >
                Dodaj kategorię
              </Button>
            </Authorize>
          }
        />
      </div>
    </div>
  );
};

export default ClauseCategories;
