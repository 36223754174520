import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import DetailsComponent from 'modules/Office/component/Details/Office';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import { breadcrumbRouteOfficeExtended } from 'modules/Office/breadcrumbs';
import Office, { createOffice } from 'modules/Office/model/Office';
import { fetchOfficeExtended } from 'modules/Office/repository';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';

const DetailsExtended = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [office, setOffice] = useState<Office>(null);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);

  const cancelToken = useCancelToken();

  const fetchOfficeRequest = async () => {
    setFetching(true);
    const { data, cancelled, message: messageResponse } = await fetchRequest(fetchOfficeExtended, id, cancelToken);

    if (cancelled) return;
    if (data) setOffice(createOffice(data.data));
    if (messageResponse) setMessage(messageResponse);
    setFetching(false);
  };

  useEffect(() => {
    fetchOfficeRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (office) {
      const title = `${office.name?.trim() || 'Urzedy'} - szczegóły`;
      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteOfficeExtended(id, office.name?.trim())
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [office]);

  if (fetching && !office) return <Loader />;
  if (!fetching && !office && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  if (office) {
    return (
      <div className="row user-details-view position-relative">
        {fetching && <Loader />}
        <DetailsComponent office={office} />
      </div>
    );
  }

  return null;
};

export default DetailsExtended;
