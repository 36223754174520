import React from 'react';
import { getFieldForPreview, PREVIEW_PLACEHOLDER } from 'modules/Agreements/helper/agreement';
import classNames from 'classnames';

export interface PreviewFieldContentProps {
  step: any;
  modifiedStep: any;
  contentKey: string;
  // eslint-disable-next-line react/require-default-props
  className?: string;
}

const PreviewFieldContent = (props: PreviewFieldContentProps): JSX.Element => {
  const { step, modifiedStep, contentKey, className } = props;
  const { value, modified } = getFieldForPreview(step, modifiedStep, contentKey);

  const formattedContent = value?.replaceAll('....', `<span style='background-color:#d4d4d4'>....</span>`);
  return (
    <p
      key={contentKey}
      className={classNames('wysiwyg-preview', className, { modified })}
      dangerouslySetInnerHTML={{ __html: String(formattedContent || PREVIEW_PLACEHOLDER) }}
    />
  );
};

export default PreviewFieldContent;
