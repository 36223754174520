import { BreadcrumbItem } from 'modules/Layout/type';
import { ROUTE_PROFILE } from 'modules/Profile/routes';

export const breadcrumbProfile = (): BreadcrumbItem => ({
  title: 'Profil',
  link: ROUTE_PROFILE
});

export const breadcrumbRouteProfile = (): BreadcrumbItem[] => [breadcrumbProfile()];

export const breadcrumbRouteProfileRodo = (): BreadcrumbItem[] => [
  breadcrumbProfile(),
  {
    title: 'Edycja RODO'
  }
];

export const breadcrumbRouteProfileUpdate = (): BreadcrumbItem[] => [
  breadcrumbProfile(),
  {
    title: 'Edycja'
  }
];

export const breadcrumbRouteProfileBaseUpdate = (): BreadcrumbItem[] => [
  breadcrumbProfile(),
  {
    title: 'Edycja danych podstawowych'
  }
];

export const breadcrumbRouteProfileCompanyUpdate = (): BreadcrumbItem[] => [
  breadcrumbProfile(),
  {
    title: 'Edycja danych rozliczeniowych'
  }
];

export const breadcrumbRouteProfilePaymentLog = (payment_number?: string): BreadcrumbItem[] => [
  breadcrumbProfile(),
  {
    title: `Szczegóły transakcji${!!payment_number && ` - ${payment_number}`}`
  }
];
