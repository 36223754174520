import React, { useEffect, useState } from 'react';
import Authorize from 'modules/Auth/container/Authorize';
import { CLAUSE_DELETE, CLAUSE_EDIT } from 'modules/Clause/permissions';
import CardBox from 'modules/Layout/component/CardBox';
import ClauseBasicDataset from 'modules/Clause/component/Dataset';
import Clause from 'modules/Clause/model/Clause';
import { ROUTE_CLAUSE_UPDATE } from 'modules/Clause/routes';
import { getPathUrl } from 'modules/Shared/helper/api';
import NavTabs from 'modules/Layout/component/NavTabs';
import ClauseDetailsConnectedJudgements from 'modules/Clause/component/Details/ConnectedJudgements';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import InformationList from 'modules/Layout/component/InformationList';

type Props = {
  clause: Clause;
  setDisplayDeleteModal: () => void;
};

const navElements = ['Dane podstawowe', 'Połączone orzeczenia'];

const ClauseBasicDetails: React.FC<Props> = ({ clause, setDisplayDeleteModal }) => {
  const [nav, setNav] = useState([]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('t');

    switch (tab) {
      case 'judgments':
        return setNav(navElements.map((_, index) => index === 1));
      default:
        return setNav(navElements.map((_, index) => index === 0));
    }
  }, []);

  return (
    <>
      <div className="details-top-nav mb-3 px-2">
        <NavTabs valueArr={navElements} booleanArr={nav} setBooleanArr={setNav} changeUrlParams />
      </div>

      {nav[0] && (
        <div className="col-12">
          <CardBox
            heading="Szczegóły klauzuli"
            className="judgement-basic-details"
            paddingRight="30px"
            paddingBottom="0"
            actions={
              <>
                <Authorize permissions={[CLAUSE_EDIT]}>
                  <Link className="link-with-border" to={getPathUrl(ROUTE_CLAUSE_UPDATE, { id: clause?.id })}>
                    EDYTUJ
                  </Link>
                </Authorize>
                <Authorize permissions={[CLAUSE_DELETE]}>
                  <Button color="link" className="link-with-border p-0" onClick={setDisplayDeleteModal}>
                    USUŃ
                  </Button>
                </Authorize>
              </>
            }
          >
            <ClauseBasicDataset element={clause} />
          </CardBox>
          <CardBox
            heading="Wyjaśnienie klauzuli"
            paddingRight="30px"
            paddingBottom="0"
            className="judgement-basic-details"
          >
            <InformationList
              fields={[
                {
                  label: ' ',
                  value: clause?.description,
                  html: true,
                  htmlFieldInsertedStyle: { maxHeight: '700px', overflowY: 'auto' }
                }
              ]}
            />
          </CardBox>
        </div>
      )}
      {nav[1] && (
        <div className="col-12">
          <ClauseDetailsConnectedJudgements clause={clause} />
        </div>
      )}
    </>
  );
};

export default ClauseBasicDetails;
