import { Routes } from 'app/routes';
import Authorize from 'modules/Auth/container/Authorize';
import ProfileView from 'modules/Profile/view';
import ProfileUpdateView from 'modules/Profile/view/Update';
import ProfilePasswordUpdateView from 'modules/Profile/view/UpdatePassword';
import ProfileCompanyUpdateView from 'modules/Profile/view/UpdateCompany';
import ProfilePaymentLogView from 'modules/Profile/view/PaymentLogsDetails';
import { PAYMENY_HISTORY_OWN, UPDATE_OWN_COMPANY } from 'modules/User/permissions';
import ProfileRodoView from 'modules/Profile/view/Rodo';
import React from 'react';
import { Route } from 'react-router-dom';

export const ROUTE_PROFILE = '/profile';
export const ROUTE_PROFILE_UPDATE = '/profile/update';
export const ROUTE_PROFILE_PASSWORD_UPDATE = '/profile/update-password';
export const ROUTE_PROFILE_UPDATE_COMPANY = '/profile/update-company';
export const ROUTE_PROFILE_PAYMENT_LOG = '/profile/payment/:id';
export const ROUTE_PROFILE_RODO = '/profile/rodo';

export const createProfileRoutes = (): Routes => ({
  wrapped: [
    <Authorize key="profile">
      <Route path={ROUTE_PROFILE} exact component={ProfileView} />
    </Authorize>,
    <Authorize key="profile-update">
      <Route path={ROUTE_PROFILE_UPDATE} exact component={ProfileUpdateView} />
    </Authorize>,
    <Authorize key="profile-password-update">
      <Route path={ROUTE_PROFILE_PASSWORD_UPDATE} exact component={ProfilePasswordUpdateView} />
    </Authorize>,
    <Authorize key="profile-company-update" permissions={[UPDATE_OWN_COMPANY]}>
      <Route path={ROUTE_PROFILE_UPDATE_COMPANY} exact component={ProfileCompanyUpdateView} />
    </Authorize>,
    <Authorize key="profile-payment-log" permissions={[PAYMENY_HISTORY_OWN]}>
      <Route path={ROUTE_PROFILE_PAYMENT_LOG} exact component={ProfilePaymentLogView} />
    </Authorize>,
    <Route key="profile-rodo" path={ROUTE_PROFILE_RODO} exact component={ProfileRodoView} />
  ]
});
