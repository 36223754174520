import React, { useState } from 'react';
import { RootState } from 'app/reducer';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import updateRequest from 'modules/Shared/helper/APIRequests/updateRequest';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchingProps } from 'modules/Shared/helper/params';
import { addToastAction } from 'modules/Layout/action';
import { Message } from 'modules/Shared/type';
import { ROUTE_TRADITIONAL_PAYMENTS_WAITING } from 'modules/Subscriptions/routes';
import { setTraditionalPaymentListParamsAction } from 'modules/Subscriptions/action/traditionalPaymentList';
import { cancelTraditionalPayment, finishTraditionalPayment } from 'modules/Subscriptions/repository';
import { approveAccessTraditionalPaymentSuccess, cancelTraditionalPaymentSuccess } from 'modules/Subscriptions/toasts';
import TraditionalPayment from 'modules/Subscriptions/model/TraditionalPayment';

import Listing from 'modules/Layout/component/Listing';
import TraditionalPaymentWaitingListTable from 'modules/Subscriptions/container/TraditionalPaymentWaitingList/Table';
import TraditionalPaymentWaitingListFilter from 'modules/Subscriptions/container/TraditionalPaymentWaitingList/Filter';
import TraditionalPaymentWaitingListPagination from 'modules/Subscriptions/container/TraditionalPaymentWaitingList/Pagination';
import TraditionalPaymentWaitingListPerPage from 'modules/Subscriptions/container/TraditionalPaymentWaitingList/PerPage';
import AcceptAccessModal from 'modules/Subscriptions/component/TraditionalPaymentWaitingList/AcceptAccessModal';

const TraditionalPaymentWaitingList = () => {
  const dispatch = useDispatch();
  const { fetching } = useSelector((state: RootState) => state.subscriptions.traditionalPayment);
  const [displayDeleteModal, setDisplayDeleteModal] = useState<TraditionalPayment>(null);
  const [displayApproveAccessModal, setDisplayApproveAccessModal] = useState<TraditionalPayment>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);

  const searchProps = createSearchingProps(
    '',
    TraditionalPayment.getFilterableAttributes(),
    TraditionalPayment.getSortableAttributes()
  );

  const cancelToken = useCancelToken();

  const cancelPaymentAction = async () => {
    setLoading(true);
    const {
      cancelled,
      success,
      message: messageRequest
    } = await deleteRequest(cancelTraditionalPayment, displayDeleteModal.id, cancelToken);

    if (cancelled) return;

    if (success) {
      dispatch(addToastAction(cancelTraditionalPaymentSuccess()));
      setDisplayDeleteModal(null);
      dispatch(setTraditionalPaymentListParamsAction({}));
      setLoading(false);
    } else {
      if (messageRequest) setMessage(messageRequest);
    }
  };

  const approveAccessAction = async (paymentNumber: number) => {
    setLoading(true);

    const {
      cancelled,
      success,
      message: messageRequest
    } = await updateRequest(finishTraditionalPayment, paymentNumber, cancelToken);

    if (cancelled) return;

    if (success) {
      dispatch(addToastAction(approveAccessTraditionalPaymentSuccess()));

      dispatch(setTraditionalPaymentListParamsAction({}));
    } else {
      if (messageRequest) setMessage(messageRequest);
    }

    setLoading(false);
    setDisplayApproveAccessModal(null);
  };

  return (
    <div className="row users-view">
      <div className="col-12">
        {displayDeleteModal && (
          <GenericModalDelete
            value={displayDeleteModal}
            title="Anulowanie płatności"
            content={() => <div>Czy na pewno chcesz anulować płatność?</div>}
            submitButtonLabel="Zatwierdź"
            onDeleteClick={() => cancelPaymentAction()}
            isOpen={!!displayDeleteModal}
            toggle={() => setDisplayDeleteModal(null)}
            loading={loading}
            message={message}
            resetMessage={() => setMessage(null)}
          />
        )}
        {displayApproveAccessModal && (
          <AcceptAccessModal
            traditionalPayment={displayApproveAccessModal}
            onClick={approveAccessAction}
            loading={loading}
            message={message}
            isOpen={!!displayApproveAccessModal}
            toggle={() => setDisplayApproveAccessModal(null)}
          />
        )}
        <Listing
          table={
            <TraditionalPaymentWaitingListTable
              onDeleteClick={(traditionalPayment) => setDisplayDeleteModal(traditionalPayment)}
              onApproveAccessClick={(traditionalPayment) => setDisplayApproveAccessModal(traditionalPayment)}
            />
          }
          filter={<TraditionalPaymentWaitingListFilter values={searchProps.filter} />}
          pagination={<TraditionalPaymentWaitingListPagination path={ROUTE_TRADITIONAL_PAYMENTS_WAITING} />}
          perPage={<TraditionalPaymentWaitingListPerPage />}
          loading={fetching}
        />
      </div>
    </div>
  );
};

export default TraditionalPaymentWaitingList;
