import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, FormGroup, Label, Input } from 'reactstrap';
import { SelectOption } from '../../../../Shared/type';

interface FilterCheckboxSelectProps {
  property: string;
  label: string;
  options: SelectOption[];
  selectedValues: (string | number)[];
  onChange: (selected: (string | number)[]) => void;
  className?: string;
  disabled?: boolean;
}

const FilterCheckboxSelect: React.FC<FilterCheckboxSelectProps> = ({
  property,
  label,
  options,
  selectedValues,
  className,
  onChange,
  disabled
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const handleCheckboxChange = (checkValue: string | number) => {
    const newSelected = selectedValues.includes(checkValue)
      ? selectedValues.filter((value) => value !== checkValue)
      : [...selectedValues, checkValue];
    onChange(newSelected);
  };

  // Generate the display text for selected options
  const selectedLabels =
    options
      .filter((option) => selectedValues.includes(option.value))
      .map((option) => option.label)
      .join(', ') || 'Wybierz'; // Default to the main label if nothing is selected

  return (
    <FormGroup className={className}>
      <Label for={property}>{label}</Label>
      <Dropdown isOpen={isOpen} toggle={toggle} disabled={disabled}>
        <DropdownToggle className="filter-dropdown-toggle" caret>
          {selectedLabels}
        </DropdownToggle>
        <DropdownMenu>
          {options.map((option) => (
            <div key={option.value} style={{ alignItems: 'center', display: 'flex' }} className="dropdown-item">
              <Input
                type="checkbox"
                style={{ position: 'relative', margin: 0, marginRight: '1rem' }}
                checked={selectedValues.includes(option.value)}
                onChange={() => handleCheckboxChange(option.value)}
              />
              {option.label}
            </div>
          ))}
        </DropdownMenu>
      </Dropdown>
    </FormGroup>
  );
};

export default FilterCheckboxSelect;
