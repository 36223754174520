import React from 'react';
import Order from 'modules/ExecutorOrder/model/Order';
import PenaltiesProblem from 'modules/ExecutorOrder/component/Dataset/Violations/Penalties';
import TerminationProblem from 'modules/ExecutorOrder/component/Dataset/Violations/Termination';
import ComplainProblem from 'modules/ExecutorOrder/component/Dataset/Violations/Complaint';
import MisrepresentationProblem from 'modules/ExecutorOrder/component/Dataset/Violations/Misrepresentation';
import Loader from 'modules/Layout/component/Loader';
import ViolationsFiles from 'modules/ExecutorOrder/component/Dataset/Violations/ViolationsFiles';
import './index.scss';
import BidRiggingProblem from './BidRigging';
import UnfairCompetitionProblem from './UnfairCompetition';
import { IFile } from '../../../../Shared/helper/Files/AddFiles';

interface Props {
  order?: Order;
  orderDownloadFileRequest: (fileId: number | string, sectionId: string | number) => Promise<void>;
  onDeleteFile: (file: IFile, sectionId: string | number) => void;
  loadingFile: boolean;
  onAddFilesRequest: (
    section_id: number | string,
    data: FormData,
    changeLoading: (curr: boolean) => void,
    setProgress: (value: React.SetStateAction<number>) => void
  ) => Promise<void>;
}

type IDisplayViolation = {
  title: string;
  key: string | number;
  problem: React.ReactElement;
  fileProblem: React.ReactElement;
};

const displayViolation = ({ title, problem, fileProblem, key }: IDisplayViolation) => (
  <div key={key} className="order-violations-item">
    <div className="mb-3">
      <div className="d-flex flex-wrap justify-content-between mr-2">
        <div className="h4">{title}</div>
      </div>
      {problem}
    </div>
    {fileProblem}
  </div>
);

const ViolationWrapper: React.FC<Props> = ({ order, loadingFile, ...rest }) => {
  return (
    <div className="order-violations-wrapper">
      {loadingFile && <Loader />}
      {order?.penalties_checked &&
        order?.penalties.length > 0 &&
        order?.penalties.map((penalty) =>
          displayViolation({
            title: 'Kary umowne',
            problem: <PenaltiesProblem penalty={penalty} />,
            fileProblem: <ViolationsFiles orderFiles={penalty.files} sectionId={penalty.id} {...rest} />,
            key: penalty.id
          })
        )}
      {order?.termination_checked &&
        order?.termination.length > 0 &&
        order?.termination.map((terminationItem) =>
          displayViolation({
            title: 'Rozwiązanie umowy',
            problem: <TerminationProblem terminationItem={terminationItem} />,
            fileProblem: (
              <ViolationsFiles orderFiles={terminationItem.files} sectionId={terminationItem.id} {...rest} />
            ),
            key: terminationItem.id
          })
        )}
      {order?.complaint_checked &&
        order?.complaint.length > 0 &&
        order?.complaint.map((complaintItem) =>
          displayViolation({
            title: 'Wytoczenie powództwa',
            problem: <ComplainProblem complaintItem={complaintItem} />,
            fileProblem: <ViolationsFiles orderFiles={complaintItem.files} sectionId={complaintItem.id} {...rest} />,
            key: complaintItem.id
          })
        )}
      {order?.misrepresentation_checked &&
        order?.misrepresentation.length > 0 &&
        order?.misrepresentation.map((misrepresentationItem) =>
          displayViolation({
            title: 'Wprowadzenie zamawiającego w błąd',
            problem: <MisrepresentationProblem misrepresentationItem={misrepresentationItem} />,
            fileProblem: (
              <ViolationsFiles
                orderFiles={misrepresentationItem.files}
                sectionId={misrepresentationItem.id}
                {...rest}
              />
            ),
            key: misrepresentationItem.id
          })
        )}
      {order?.bid_rigging_checked &&
        order?.bid_rigging.length > 0 &&
        order?.bid_rigging.map((bidRiggingItem) =>
          displayViolation({
            title: 'Zmowy przetargowe',
            problem: <BidRiggingProblem bidRiggingItem={bidRiggingItem} />,
            fileProblem: <ViolationsFiles orderFiles={bidRiggingItem.files} sectionId={bidRiggingItem.id} {...rest} />,
            key: bidRiggingItem.id
          })
        )}
      {order?.unfair_competition_checked &&
        order?.unfair_competition.length > 0 &&
        order?.unfair_competition.map((unfairCompetitionItem) =>
          displayViolation({
            title: 'Czyny nieuczciwej konkurencji',
            problem: <UnfairCompetitionProblem unfairCompetitionItem={unfairCompetitionItem} />,
            fileProblem: (
              <ViolationsFiles
                orderFiles={unfairCompetitionItem.files}
                sectionId={unfairCompetitionItem.id}
                {...rest}
              />
            ),
            key: unfairCompetitionItem.id
          })
        )}
      {!order?.penalties_checked &&
        !order?.termination_checked &&
        !order?.complaint_checked &&
        !order?.unfair_competition_checked &&
        !order?.bid_rigging_checked &&
        !order?.misrepresentation_checked && <div className="h5">Brak</div>}
    </div>
  );
};

export default ViolationWrapper;
