import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';

export const API_FEEDBACK_CATEGORIES = '/api/feedback/categories';
export const API_FEEDBACK_SEND = '/api/feedback';

export type FetchFeedbackCategoriesResponse = {
  data: { id: number; name: string }[];
};

export const fetchFeedbackCategories = (cancelToken?: any): AxiosPromise<FetchFeedbackCategoriesResponse> =>
  axios.get(getAPIUrl(API_FEEDBACK_CATEGORIES), { cancelToken });

export type SendFeedbackRequest = {
  title: string;
  content: string;
  category_id: number;
  files: File[];
};

export const sendFeedback = (values: SendFeedbackRequest, cancelToken?: any): AxiosPromise =>
  axios.post(getAPIUrl(API_FEEDBACK_SEND), values, { cancelToken });
