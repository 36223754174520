import { RootState } from 'app/reducer';
import { setClauseListParamsAction } from 'modules/Clause/action/ClauseList';
import Clause, { ClauseEntity } from 'modules/Clause/model/Clause';
import Table, { TableCol } from 'modules/Layout/component/Table/AccordionTable';
import trimmedString from 'modules/Shared/helper/trimmedString';
import { PartialSearchingProps } from 'modules/Shared/type';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

type Props = { onSelectedClauseClick?: (clause: ClauseEntity) => void; selectedClause?: number };

const ConnectedClauseAddListTable: React.FC<Props> = ({ onSelectedClauseClick, selectedClause }) => {
  const dispatch = useDispatch();
  const { list, sort } = useSelector((state: RootState) => state.clause.clauseList);

  const setParams = (payload: PartialSearchingProps) => dispatch(setClauseListParamsAction(payload));

  const cols: TableCol<Clause>[] = [
    {
      property: 'id',
      label: 'ID',
      sortable: true
    },
    {
      property: 'name',
      label: 'Nazwa klauzuli',
      sortable: true
    },
    {
      property: 'description',
      label: 'Wyjaśnienie klauzuli',
      sortable: true,
      value: function Description(row) {
        return <div dangerouslySetInnerHTML={{ __html: trimmedString(row.description ?? '', 100) }} />;
      }
    },
    {
      property: 'category_name',
      label: 'Nazwa kategorii klauzuli',
      sortable: true,
      value: (row) => row.category?.name
    },
    {
      property: 'keywords_names',
      label: 'Słowa kluczowe'
    }
  ];

  return (
    <Table
      cols={cols}
      rows={list}
      sort={sort}
      onSort={(params) => setParams({ sort: params })}
      displayErrorRow={(row) => (selectedClause === row?.id ? 'success-table-field' : '')}
      onRowClick={onSelectedClauseClick}
    />
  );
};

export default ConnectedClauseAddListTable;
