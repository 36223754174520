import { AgreementMode, AgreementType } from 'modules/Agreements/type';
import Part, { PartEntity } from 'modules/Agreements/model/Part';

export type AgreementEntity = {
  id: number;
  mode: AgreementMode;
  type: AgreementType;
  few_parts: boolean;
  parts_ids: PartEntity[];
  parts: number;
  proceeding_id: number;
  name: string;
  created_at: string;
  updated_at: string;
};

export default class Agreement {
  readonly id?: number;
  readonly mode?: AgreementMode;
  readonly type?: AgreementType;
  readonly parts_ids?: Part[];
  readonly few_parts?: boolean;
  readonly parts?: number;
  readonly proceeding_id?: number;
  readonly updated_at?: string;
  readonly created_at?: string;
  readonly name?: string;

  constructor(entity: AgreementEntity) {
    this.id = entity.id;
    this.mode = entity.mode;
    this.type = entity.type;
    this.parts_ids = entity.parts_ids.map((part) => new Part(part));
    this.few_parts = entity.few_parts;
    this.parts = entity.parts;
    this.proceeding_id = entity.proceeding_id;
    this.name = entity.name;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
  }

  static getFilterableAttributes(): string[] {
    return ['mode', 'type', 'created_at_from', 'created_at_to'];
  }

  static getSortableAttributes(): string[] {
    return ['mode', 'type', 'created_at'];
  }
}
