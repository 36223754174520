import BadCommunicateIcon from 'modules/Layout/component/Icon/BadCommunicate';
import EditIcon from 'modules/Layout/component/Icon/Edit';
import GoodCommunicateIcon from 'modules/Layout/component/Icon/GoodCommunicate';
import RestoreIcon from 'modules/Layout/component/Icon/Restore';
import React, { useEffect, useState } from 'react';
import { CustomInput, Label } from 'reactstrap';
import useWysiwyg from 'modules/Layout/component/Wysiwyg';
import TrashIcon from 'modules/Layout/component/Icon/Trash';

const useEditableInput = (
  label: string,
  initValue?: string,
  displayCheckbox?: boolean,
  alwaysHideRestoreBtn?: boolean,
  onDisplayDeleteBtnClick?: () => void,
  initCheckboxValue?: boolean,
  initWysiwygValueValue?: string
) => {
  const [value, setValue] = useState('');
  const [wysiwygValue, setWysiwygValue] = useState<string>(null);
  const [checkboxValue, setCheckboxValue] = useState(true);

  const { displayWysiwyg, getContent } = useWysiwyg({ content: wysiwygValue, tooltip: { specialSign: true } });

  useEffect(() => {
    if (initValue) {
      setValue(initValue);
    }
    if (initCheckboxValue) {
      setCheckboxValue(initCheckboxValue);
    }
    if (initWysiwygValueValue) {
      setWysiwygValue(initWysiwygValueValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGoodCommunicateClick = () => {
    setValue(getContent());
    setWysiwygValue(null);
  };
  const onRestoreClick = () => {
    setValue(initValue);
    setWysiwygValue(null);
  };
  const onBadCommunicateClick = () => {
    setWysiwygValue(null);
    if (onDisplayDeleteBtnClick && !value) onDisplayDeleteBtnClick();
  };
  const onEditClick = () => setWysiwygValue(value);

  const wysiwygInput = displayWysiwyg();

  const wysiwygOptions = (
    <div className="content-actions m-0">
      <div className="toolbar-border p-1 pl-2">
        {/* eslint-disable-next-line */}
        <div className="d-inline cursor-pointer" onClick={onGoodCommunicateClick}>
          <GoodCommunicateIcon height="16px" fill="#087e8b" strokeWidth="2" />
        </div>
        {!alwaysHideRestoreBtn && initValue && (
          // eslint-disable-next-line
          <div className="d-inline cursor-pointer ml-2" onClick={onRestoreClick}>
            <RestoreIcon height="16px" fill="#f7b84b" strokeWidth="2" className="" />
          </div>
        )}
        {/* eslint-disable-next-line */}
        <div className="d-inline cursor-pointer ml-2" onClick={onBadCommunicateClick}>
          <BadCommunicateIcon height="16px" fill="#181a33" strokeWidth="2" />
        </div>
      </div>
    </div>
  );

  const presentInput = (
    <div className="content-wrapper pr-1">
      <div className="content-div">
        <div className="information-list-html font-18" dangerouslySetInnerHTML={{ __html: value }} />
      </div>
    </div>
  );

  const presentOptions = (
    <div className="content-actions d-flex">
      {/* eslint-disable-next-line */}
      <div className="cursor-pointer mx-2" onClick={onEditClick}>
        <EditIcon height="20px" className="icon-svg-table" />
      </div>
      {onDisplayDeleteBtnClick && (
        // eslint-disable-next-line
        <div className="cursor-pointer" onClick={onDisplayDeleteBtnClick}>
          <TrashIcon height="20px" className="icon-svg-table" />
        </div>
      )}
    </div>
  );

  const displayContent = () => (
    <>
      {displayCheckbox && (
        <div className="fieldset-rodo">
          {/* eslint-disable-next-line */}
          <div style={{ height: '30px' }} onClick={() => setCheckboxValue(!checkboxValue)}>
            <CustomInput
              type="checkbox"
              name={label}
              id={label}
              label=""
              className={`rodo-field mt-${wysiwygValue ? '4' : '3'}`}
              checked={checkboxValue}
              onChange={() => null}
            />
          </div>
        </div>
      )}
      {wysiwygValue ? (
        <>
          {wysiwygInput}
          {wysiwygOptions}
        </>
      ) : (
        <div>
          <Label>{label}</Label>
          <div className="documents">
            {presentInput}
            {presentOptions}
          </div>
        </div>
      )}
    </>
  );

  const inputJsx = () => {
    return (
      <>
        <div className="documents mb-5">{displayContent()}</div>
      </>
    );
  };

  return {
    value,
    checkboxValue,
    inputJsx
  };
};

export default useEditableInput;
