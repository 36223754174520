import { Routes } from 'app/routes';
import Authorize from 'modules/Auth/container/Authorize';
import ModuleAccessHistoryDetailsView from 'modules/Module/view/Details';
import React from 'react';
import { Route } from 'react-router-dom';
import { MODULE_USER_LOGS_ALL, MODULE_USER_LOGS_OWN } from 'modules/User/permissions';

export const ROUTE_ACCESS_HISTORY = '/access-history/:id(\\d+)';

export const createModuleRoutes = (): Routes => ({
  wrapped: [
    <Authorize key="access-history" permissions={[MODULE_USER_LOGS_OWN, MODULE_USER_LOGS_ALL]}>
      <Route path={ROUTE_ACCESS_HISTORY} exact component={ModuleAccessHistoryDetailsView} />
    </Authorize>
  ]
});
