import { suffixLabel } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import React from 'react';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { proceedingModeOptions, proceedingTypeOptions } from 'modules/Proceedings/model/Proceedings';
import Select from 'modules/Layout/component/Input/Select';
import _uniqueId from 'lodash/uniqueId';
import { Props } from 'modules/Proceedings/component/Fieldset/Main';

const ProceedingsFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, setValues, placeholder = false, required } = props;

  const { type = 1, mode = 1 } = values;

  return (
    <>
      <FormGroup>
        {!placeholder && <Label for="input-type">{suffixLabel('Rodzaj zamówienia', required)}</Label>}
        <Select
          id="input-type"
          className="default-text-color"
          onChange={(newValue) => setValues({ ...values, type: newValue.value })}
          value={proceedingTypeOptions().find((val) => val.value === type)}
          options={proceedingTypeOptions()}
        />
        {hasError(errors, 'type') && <FormFeedback>{getError(errors, 'type')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="input-mode">{suffixLabel('Tryb udzielania zamówienia', required)}</Label>}
        <Select
          id="input-mode"
          className="default-text-color"
          onChange={(newValue) => setValues({ ...values, mode: newValue.value })}
          value={proceedingModeOptions().find((val) => val.value === mode)}
          options={proceedingModeOptions()}
        />
        {hasError(errors, 'mode') && <FormFeedback>{getError(errors, 'mode')}</FormFeedback>}
      </FormGroup>
    </>
  );
};

export default ProceedingsFieldset;
