import { addToastAction, managePageAction } from 'modules/Layout/action';
import React, { useEffect, useRef, useState } from 'react';
import Loader from 'modules/Layout/component/Loader';
import { useDispatch } from 'react-redux';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import { fetchJudgment } from 'modules/Judgement/repository';
import { useLocation, useParams } from 'react-router';
import { JudgementEntity } from 'modules/Judgement/model/Judgement';
import KeywordIncludedMarkForm from 'modules/Judgement/component/Form/KeywordIncludedMark';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { breadcrumbRouteClauseJudgementContentMark } from 'modules/Clause/breadcrumbs';
import { ClauseEntity } from 'modules/Clause/model/Clause';
import { addClauseJudgementToastSuccess, updateClauseJudgementToastSuccess } from 'modules/Clause/toasts';
import { addClauseConnectedJudgements } from 'modules/Clause/repository';
import { ROUTE_CLAUSE } from 'modules/Clause/routes';
import { ROUTE_DASHBOARD } from 'modules/Layout/routes';

const ConnectedJudgementMarkContent = () => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const { state = {} } = useLocation<{
    prevContent?: string;
    judgementId?: number;
    clause?: ClauseEntity;
  }>();
  const [refreshContent, setRefreshContent] = useState(false);
  const { id } = useParams<{ id: string }>();
  const [judgement, setJudgement] = useState<JudgementEntity>(null);
  const [locationState, setLocationState] = useState<{
    clause?: ClauseEntity;
    judgementId?: number;
    prevContent?: string;
  }>(state);

  useEffect(() => {
    if (!locationState) {
      setLocationState(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshClicked = useRef(false);

  const cancelToken = useCancelToken();
  useEffect(() => {
    if (locationState?.judgementId) {
      (async () => {
        setFetching(true);
        const {
          data,
          cancelled,
          message: messageResponse
        } = await fetchRequest(fetchJudgment, locationState.judgementId, cancelToken);

        if (cancelled) return;
        if (data) setJudgement(data.data);
        if (messageResponse) setMessage(messageResponse);
        setFetching(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (locationState?.clause && judgement) {
      const { clause } = locationState;
      dispatch(
        managePageAction({
          title: 'Zaznaczenie treści orzeczenia',
          breadcrumb: breadcrumbRouteClauseJudgementContentMark(judgement.identifier, clause.name, clause.id)
        })
      );
      window.scrollTo();
    } else if (!locationState?.clause || !locationState?.judgementId) {
      let pushUrl = '';
      if (id) pushUrl = getPathUrl(ROUTE_CLAUSE, { id });
      else pushUrl = ROUTE_DASHBOARD;
      dispatch(push(pushUrl));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [judgement]);

  const dispatchAddClauseJudgmentToast = () =>
    dispatch(
      addToastAction(
        locationState?.prevContent ? updateClauseJudgementToastSuccess() : addClauseJudgementToastSuccess()
      )
    );

  const addClauseJudgement = async (content: string) => {
    setLoading(true);
    const { clause, judgementId } = locationState;
    const {
      data,
      cancelled,
      message: messageResponse
    } = await updateRequestWithData(
      addClauseConnectedJudgements,
      clause.id,
      { judgment_id: judgementId, content },
      cancelToken
    );

    if (cancelled) return;
    if (data) {
      dispatchAddClauseJudgmentToast();
      dispatch(push(`${getPathUrl(ROUTE_CLAUSE, { id: clause.id })}?t=judgments`));
    } else {
      if (messageResponse) setMessage(messageResponse);
      setLoading(false);
    }
  };

  if (!locationState?.judgementId) return null;

  const triggerRefresh = () => {
    if (!refreshClicked.current) refreshClicked.current = true;
    setRefreshContent(!refreshContent);
  };

  if (fetching) return <Loader />;

  return (
    <div className="user-create-view row justify-content-center">
      <div className="col-12">
        <div className="card-box position-relative">
          {loading && <Loader />}
          <Alert message={message} />
          {judgement && (
            <>
              <p className="h4 mb-2">Zaznaczenie treści orzeczenia</p>
              <KeywordIncludedMarkForm
                key={`${refreshContent}`}
                judgement={judgement}
                submit={addClauseJudgement}
                triggerRefresh={triggerRefresh}
                contentUpdate={!refreshClicked.current ? locationState?.prevContent : null}
                cancelUrl={`${getPathUrl(ROUTE_CLAUSE, { id: locationState.clause.id })}?t=judgments`}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConnectedJudgementMarkContent;
