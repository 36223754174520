import React, { useEffect, useState } from 'react';
import { Message } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import CardBox from 'modules/Layout/component/CardBox';
import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import Judgement from 'modules/Judgement/model/Judgement';
import { fetchJudgmentClientDetailsClauses } from 'modules/JudgementClient/repository';
import BasicChip from 'modules/Layout/component/Chips/Basic';
import { ContentObjType } from 'modules/JudgementClient/component/Details/Judgement';

export interface Props {
  judgment?: Judgement;
  clausesIncluded: JudgmentDetailsConnectedClausesValue[];
  setClausesIncluded: (arr: JudgmentDetailsConnectedClausesValue[]) => void;
  setContentObj: (obj: ContentObjType) => void;
  activeKeyword: ContentObjType;
  selectedClause?: number;
  chipStyle?: { [key: string]: string };
}

const ClauseListIncluded: React.FC<Props> = ({
  judgment = {},
  setContentObj,
  selectedClause,
  activeKeyword,
  clausesIncluded,
  setClausesIncluded,
  chipStyle = {}
}) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);

  const { id: judgmentId } = judgment;

  const cancelToken = useCancelToken();

  const fetchKeywords = async () => {
    setLoading(true);
    const {
      data,
      cancelled,
      message: messageResponse
    } = await fetchRequest(fetchJudgmentClientDetailsClauses, judgmentId, cancelToken);

    if (cancelled) return;
    if (data) {
      setClausesIncluded(data.data);
    }
    if (messageResponse) setMessage(messageResponse);
    setLoading(false);
  };

  useEffect(() => {
    if (judgment) {
      fetchKeywords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [judgment]);

  const setContent = (contentObj: { name: string; content: string; id?: number }) => {
    const { content, name, id } = contentObj;
    setContentObj({ content, headingInfo: `- klauzula: ${name}`, id, type: 'clause' });
  };

  useEffect(() => {
    if (selectedClause && clausesIncluded.length) {
      const findClause = clausesIncluded.find((el) => el.clause.id === selectedClause);
      if (findClause) {
        const { clause, content } = findClause;
        setContent({ name: clause.name, content, id: clause.id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clausesIncluded]);

  const isActive = (elId: number) => activeKeyword && activeKeyword?.type === 'clause' && activeKeyword?.id === elId;

  return (
    <>
      <CardBox heading="Klauzule">
        <div className="row users-view">
          <div className="col-12">
            <Alert message={message} />
            {loading && <Loader />}
            <div className="d-flex flex-wrap">
              {!clausesIncluded.length && 'brak powiązanych klauzul'}
              {clausesIncluded.map((el) => {
                // eslint-disable-next-line no-shadow
                const { name, id } = el.clause;

                const wrapperClassName = isActive(id) ? 'text-hover text-primary' : 'text-hover';
                const finalChipStype = isActive(id)
                  ? { ...chipStyle, boxShadow: '2px 2px 10px -4px black' }
                  : chipStyle;

                return (
                  <div className={wrapperClassName} key={id}>
                    <BasicChip
                      chipContentElement={{ id, content: name }}
                      chipStyle={finalChipStype}
                      onChipClicked={() => setContent({ name, content: el.content, id })}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </CardBox>
    </>
  );
};

export default ClauseListIncluded;

/*

1. ver
const { paginationComponent, selectedItemsFromArray } = useLocalPagination(clausesIncluded);

return (
    <>
      <CardBox heading="Klauzule">
        <div className="row users-view">
          <div className="col-12">
            <Alert message={message} />
            <div className="pzpeu-table-list inside-card-box">
              <Listing
                table={
                  <ClausesIncludedTable
                    clausesIncluded={selectedItemsFromArray as any}
                    onListElementClicked={setContent}
                  />
                }
                pagination={paginationComponent()}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </CardBox>
    </>
  );

*/
