import React from 'react';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_ACCEPT } from 'modules/Agreements/step';
import { AgreementStepAcceptValues } from 'modules/Agreements/components/Steps/Default/Accept';

const AgreementStepPreviewAccept = (props: AgreementStepPreviewProps<AgreementStepAcceptValues>): JSX.Element => {
  const { steps, modifiedStep } = props;
  const accept = getStepValues<AgreementStepAcceptValues>(steps, AGREEMENT_STEP_ACCEPT);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={accept}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const { value: partialChecked, modified: partialCheckedModified } = getFieldForPreview(
    accept,
    modifiedStep,
    'partial_checked'
  );

  const { value: finalChecked, modified: finalCheckedModified } = getFieldForPreview(
    accept,
    modifiedStep,
    'final_checked'
  );

  const { value: postWarrantyChecked, modified: postWarrantyCheckedModified } = getFieldForPreview(
    accept,
    modifiedStep,
    'post_warranty_checked'
  );

  const { value: acceptancePossibility, modified: acceptancePossibilityModified } = getFieldForPreview(
    accept,
    modifiedStep,
    'acceptance_possibility'
  );

  const { value: partialProcedurePossibility, modified: partialProcedurePossibilityModified } = getFieldForPreview(
    accept,
    modifiedStep,
    'partial_procedure_possibility'
  );

  const { value: finalProcedurePossibility, modified: finalProcedurePossibilityModified } = getFieldForPreview(
    accept,
    modifiedStep,
    'final_procedure_possibility'
  );

  const { value: documentsPossibility, modified: documentsPossibilityModified } = getFieldForPreview(
    accept,
    modifiedStep,
    'documents_possibility'
  );

  const { value: finalProtocolChecked } = getFieldForPreview(accept, modifiedStep, 'final_protocol_checked');

  const { value: documentsStatementsChecked } = getFieldForPreview(
    accept,
    modifiedStep,
    'documents_statements_checked'
  );

  const { value: notRemoveDefectsPossibility, modified: notRemoveDefectsPossibilityModified } = getFieldForPreview(
    accept,
    modifiedStep,
    'not_remove_defects_possibility'
  );

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    accept,
    modifiedStep,
    `checkboxes.general`
  );

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_ACCEPT)}</div>
      <div className="agreement-step-preview-content reset-preview-point">
        {acceptancePossibility && [
          <p
            className={classNames('preview-point', {
              modified: acceptancePossibilityModified
            })}
          >
            W ramach realizacji niniejszej Umowy występować będą następujące odbiory:
          </p>,
          partialChecked && (
            <p
              className={classNames('preview-nested-decimal-point', {
                modified: partialCheckedModified
              })}
            >
              Odbiory częściowe
            </p>
          ),
          finalChecked && (
            <p
              className={classNames('preview-nested-decimal-point', {
                modified: finalCheckedModified
              })}
            >
              Odbiór Ostateczny Przedmiotu Umowy
            </p>
          ),
          postWarrantyChecked && (
            <p
              className={classNames('preview-nested-decimal-point', {
                modified: postWarrantyCheckedModified
              })}
            >
              Odbiór pogwarancyjny
            </p>
          ),
          renderCheckbox('others', 'preview-nested-decimal-point'),
          partialChecked && [
            renderCheckbox('partial_schedule', 'preview-point'),
            renderCheckbox('partial_accept', 'preview-point'),
            partialProcedurePossibility && [
              <p
                className={classNames('preview-point', {
                  modified: partialProcedurePossibilityModified
                })}
              >
                Procedura odbioru częściowego:
              </p>,
              renderCheckbox('partial_readiness', 'preview-nested-decimal-point'),
              renderCheckbox('partial_participation', 'preview-nested-decimal-point'),
              renderCheckbox('partial_documents', 'preview-nested-decimal-point'),
              renderCheckbox('partial_protocol', 'preview-nested-decimal-point'),
              renderCheckbox('partial_others', 'preview-nested-decimal-point')
            ]
          ],
          finalProcedurePossibility && [
            <p
              className={classNames('preview-point', {
                modified: finalProcedurePossibilityModified
              })}
            >
              Procedura Odbioru Ostatecznego Przedmiotu Umowy:
            </p>,
            renderCheckbox('final_subject', 'preview-nested-decimal-point'),
            renderCheckbox('final_readiness', 'preview-nested-decimal-point'),
            renderCheckbox('final_date', 'preview-nested-decimal-point'),
            renderCheckbox('final_participate', 'preview-nested-decimal-point'),
            finalProtocolChecked && [
              renderCheckbox('final_protocol', 'preview-nested-decimal-point'),
              renderCheckbox('final_protocol_place', 'preview-nested-alpha-point'),
              renderCheckbox('final_protocol_date', 'preview-nested-alpha-point'),
              renderCheckbox('final_protocol_people', 'preview-nested-alpha-point'),
              renderCheckbox('final_protocol_documents', 'preview-nested-alpha-point'),
              renderCheckbox('final_protocol_compatibility', 'preview-nested-alpha-point'),
              renderCheckbox('final_protocol_defects', 'preview-nested-alpha-point'),
              renderCheckbox('final_protocol_decisions', 'preview-nested-alpha-point'),
              renderCheckbox('final_protocol_statements', 'preview-nested-alpha-point'),
              renderCheckbox('final_protocol_signatures', 'preview-nested-alpha-point')
            ],
            renderCheckbox('final_others', 'preview-nested-decimal-point')
          ]
        ]}
        {documentsPossibility && [
          <p
            className={classNames('preview-point', {
              modified: documentsPossibilityModified
            })}
          >
            Wraz z zawiadomieniem o gotowości do przekazania robót do Odbioru Ostatecznego, wykonawca przedłoży
            następujące dokumenty:
          </p>,
          renderCheckbox('documents_log_building', 'preview-nested-decimal-point'),
          renderCheckbox('documents_statements', 'preview-nested-decimal-point'),
          documentsStatementsChecked && [
            renderCheckbox('documents_drawings', 'preview-nested-alpha-point'),
            renderCheckbox('documents_cleaning', 'preview-nested-alpha-point')
          ],
          renderCheckbox('documents_documentation', 'preview-nested-decimal-point'),
          renderCheckbox('documents_decision_use', 'preview-nested-decimal-point'),
          renderCheckbox('documents_decision_water_law', 'preview-nested-decimal-point'),
          renderCheckbox('documents_certificates', 'preview-nested-decimal-point'),
          renderCheckbox('documents_post_warranty', 'preview-nested-decimal-point'),
          renderCheckbox('documents_others', 'preview-nested-decimal-point')
        ]}
        {renderCheckbox('accept_refuse', 'preview-point')}
        {notRemoveDefectsPossibility && [
          <p
            className={classNames('preview-point', {
              modified: notRemoveDefectsPossibilityModified
            })}
          >
            Jeżeli w terminie wyznaczonym przez zamawiającego wykonawca nie usunie wad ujawnionych w toku procedury
            Odbioru Ostatecznego, zamawiający będzie uprawniony do:
          </p>,
          renderCheckbox('salary_reduction', 'preview-nested-decimal-point'),
          renderCheckbox('closest_date', 'preview-nested-decimal-point'),
          renderCheckbox('second_request', 'preview-nested-decimal-point'),
          renderCheckbox('not_remove_defects_others', 'preview-nested-decimal-point')
        ]}
        {renderCheckbox('small_defects', 'preview-point')}
        {renderCheckbox('subject_take_over', 'preview-point')}
        {postWarrantyChecked && renderCheckbox('representative_accept', 'preview-point')}
        {renderCheckbox('representation', 'preview-point')}
        {renderCheckbox('representation_executor', 'preview-point')}
        {generalCheckboxes &&
          generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('wysiwyg-preview preview-point', {
                  modified: generalCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AgreementStepPreviewAccept;
