import React from 'react';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_LICENSE } from 'modules/Agreements/step';
import { AgreementStepLicenseValues } from 'modules/Agreements/components/Steps/Default/License';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import PreviewFieldContent from 'modules/Agreements/components/PreviewField/Content';

const AgreementStepPreviewLicense = (props: AgreementStepPreviewProps<AgreementStepLicenseValues>): JSX.Element => {
  const { steps, modifiedStep } = props;
  const license = getStepValues<AgreementStepLicenseValues>(steps, AGREEMENT_STEP_LICENSE);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={license}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const renderContent = (contentKey: string, className = 'preview-point') => {
    return (
      <PreviewFieldContent step={license} modifiedStep={modifiedStep} contentKey={contentKey} className={className} />
    );
  };

  const { value: licensePossibility } = getFieldForPreview(license, modifiedStep, 'license_possibility');

  const { value: tracksPossibility, modified: tracksPossibilityModified } = getFieldForPreview(
    license,
    modifiedStep,
    'tracks_possibility'
  );

  const { value: grantingLicensePossibility } = getFieldForPreview(
    license,
    modifiedStep,
    'granting_license_possibility'
  );

  const { value: salaryPossibility, modified: salaryPossibilityModified } = getFieldForPreview(
    license,
    modifiedStep,
    'salary_possibility'
  );

  const { value: protocolPossibility, modified: protocolPossibilityModified } = getFieldForPreview(
    license,
    modifiedStep,
    'protocol_possibility'
  );

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    license,
    modifiedStep,
    `checkboxes.general`
  );

  const { value: serRightsTransferProtocolChecked, modified: serRightsTransferProtocolCheckedModified } =
    getFieldForPreview(license, modifiedStep, 'ser_rights_transfer_protocol_checked');

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_LICENSE)}</div>
      <div className="agreement-step-preview-content reset-preview-point">
        {licensePossibility && (
          <>
            {tracksPossibility && [
              <p
                className={classNames('preview-point', {
                  modified: tracksPossibilityModified
                })}
              >
                Wykonawca oświadcza, że będą mu przysługiwały wszelkie prawa autorskie majątkowe do wszelkich utworów,
                wytworzonych przez wykonawcę w związku z wykonaniem Przedmiotu Umowy, w szczególności w zakresie
                dokumentacji powykonawczej oraz, że w ramach wynagrodzenia, przenosi na zamawiającego autorskie prawa
                majątkowe do wszelkich utworów będących przedmiotem Umowy i do wszelkich egzemplarzy dokumentacji tych
                utworów na wszystkich polach eksploatacji, w szczególności tych wymienionych w art. 50 ustawy z dnia 4
                lutego 1994 r. o prawie autorskim i prawach pokrewnych oraz:
              </p>,
              renderCheckbox('track_multiplication', 'preview-nested-alpha-point'),
              renderCheckbox('track_placing', 'preview-nested-alpha-point'),
              renderCheckbox('track_dissemination', 'preview-nested-alpha-point'),
              renderCheckbox('track_dependent', 'preview-nested-alpha-point'),
              renderCheckbox('track_further', 'preview-nested-alpha-point'),
              renderCheckbox('track_others', 'preview-nested-alpha-point')
            ]}
            {renderCheckbox('without_restrictions')}
            {serRightsTransferProtocolChecked === true && (
              <p
                className={classNames('preview-point', {
                  modified: serRightsTransferProtocolCheckedModified
                })}
              >
                Przeniesienie autorskich praw majątkowych do utworów na zamawiającego oraz zezwolenie bez ograniczeń
                zamawiającemu na wykonywanie zależnych praw autorskich nastąpi w ramach wynagrodzenia z chwilą
                podpisania przez niego Protokołu odbioru ostatecznego
              </p>
            )}
            {serRightsTransferProtocolChecked === false && (
              <p
                className={classNames('preview-point', {
                  modified: serRightsTransferProtocolCheckedModified
                })}
              >
                Przeniesienie autorskich praw majątkowych do utworów na zamawiającego oraz zezwolenie bez ograniczeń
                zamawiającemu na wykonywanie zależnych praw autorskich nastąpi za osobnym wynagrodzeniem w ramach
                osobnego porozumienia zawartego przez strony
              </p>
            )}
            {renderCheckbox('absolutely')}
            {renderCheckbox('time_unlimited')}
            {renderCheckbox('devices_usage')}
            {renderCheckbox('devices_ownership')}
            {renderCheckbox('creating_people')}
            {renderCheckbox('executor_no_cost')}
          </>
        )}
        {grantingLicensePossibility && (
          <>
            {salaryPossibility && [
              <p
                className={classNames('preview-point', {
                  modified: salaryPossibilityModified
                })}
              >
                Wykonawca w ramach wynagrodzenia:
              </p>,
              renderCheckbox('salary_non_exclusive', 'preview-nested-alpha-point'),
              renderCheckbox('salary_exclusive', 'preview-nested-alpha-point'),
              renderCheckbox('salary_move', 'preview-nested-alpha-point'),
              renderCheckbox('salary_others', 'preview-nested-alpha-point')
            ]}
            {protocolPossibility && [
              <p
                className={classNames('preview-point', {
                  modified: protocolPossibilityModified
                })}
              >
                Wykonawca oświadcza i gwarantuje, że w ramach wynagrodzenia udziela licencji od momentu podpisania
                protokołu odbioru ostatecznego na następujących polach eksploatacji:
              </p>,
              renderCheckbox('protocol_multiplication', 'preview-nested-alpha-point'),
              renderCheckbox('protocol_copy', 'preview-nested-alpha-point'),
              renderCheckbox('protocol_translate', 'preview-nested-alpha-point'),
              renderCheckbox('protocol_share', 'preview-nested-alpha-point'),
              renderCheckbox('protocol_copy_documentation', 'preview-nested-alpha-point'),
              renderCheckbox('protocol_modify', 'preview-nested-alpha-point'),
              renderCheckbox('protocol_execute', 'preview-nested-alpha-point'),
              renderCheckbox('protocol_others', 'preview-nested-alpha-point')
            ]}
            {renderContent('period_content')}
            {renderCheckbox('systems')}
            {renderCheckbox('full_realization')}
            {renderCheckbox('manufacturer_consent')}
            {renderCheckbox('handover')}
            {renderCheckbox('cannot_terminated')}
            {renderCheckbox('not_worse')}
            {renderCheckbox('new_license')}
            {renderCheckbox('create_tracks')}
            {renderCheckbox('importance')}
          </>
        )}
        {generalCheckboxes &&
          generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-point wysiwyg-preview', {
                  modified: generalCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AgreementStepPreviewLicense;
