import React, { useState, useEffect } from 'react';
import Form from 'modules/Regulation/component/FullRegulationForm';
import { useDispatch, useSelector } from 'react-redux';
import { managePageAction } from 'modules/Layout/action';
import { View } from 'modules/Auth/component/View';
import PublicWrapper from 'modules/Layout/component/Wrapper/Public';
import { RootState } from 'app/reducer';
import { push } from 'connected-react-router';
import Modal from 'modules/Regulation/component/Modal';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import './index.scss';

const Regulations = () => {
  const dispatch = useDispatch();
  const { user, busy, awatingRegulationApproval } = useSelector((state: RootState) => state.auth);
  const [displayModal, setDisplayModal] = useState(false);
  const [message, setMessage] = useState<Message>(null);

  const toggleModal = () => setDisplayModal(!displayModal);

  useEffect(() => {
    dispatch(managePageAction({ title: 'Akceptacja regulaminu' }));

    if (awatingRegulationApproval) {
      const html = document.getElementsByTagName('html')[0];
      html.classList.remove('layout-default', 'layout-contractor');
      html.classList.add(`layout-default`);
    } else {
      dispatch(push('/'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PublicWrapper hideHeader hideFooter>
      <View className="m-0 regulation-view" busy={busy} withoutLinks>
        {null}
        <div>
          <Modal isOpen={displayModal} toggle={toggleModal} user={user} />
          <Alert message={message} />
          <Form toggleModal={toggleModal} setMessage={setMessage} />
        </div>
      </View>
    </PublicWrapper>
  );
};

export default Regulations;

/*

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-11 col-lg-9 col-xl-7">
            <CardBox heading={user?.getName()} message={message}>
              <BasicDataset user={user} hideRole />
            </CardBox>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-11 col-lg-9 col-xl-7">
            <CardBox heading={user?.getCompanyName() || 'Dane rozliczeniowe'}>
              {user?.shouldRedirect() ? <CompanyDataset user={user} /> : 'Brak'}
            </CardBox>
          </div>
        </div>
      </div>

*/
