import axios, { CancelTokenSource } from 'axios';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { fetchJudgmentsCategories } from 'modules/Judgement/repository';
import { fetchJudgementCategoryToastError } from 'modules/Judgement/toasts';
import { addToastAction } from 'modules/Layout/action';
import AutocompleteWithChips from 'modules/Layout/component/Autocomplete/WithChips';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { createSearchParams } from 'modules/Shared/helper/params';
import renameDuplicateEntries from 'modules/Shared/helper/renameDuplicateEntries';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

type Props = {
  categories: { name: string; id: number }[];
  onCategoriesChange: (categoryValue: { name: string; id: number }[]) => void;
};

// eslint-disable-next-line react/display-name
const JudgementCategoriesInputAutocomplete: React.FC<Props> = React.memo(({ categories, onCategoriesChange }) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [categoryList, setCategoryList] = useState<JudgementElementEntity[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const categoriesListWithoutDuplicates = React.useMemo(
    () => renameDuplicateEntries(categoryList),
    [categoryList.length]
  );

  const dispatchJudgementCategoryToastError = (messageValue: string) =>
    dispatch(addToastAction(fetchJudgementCategoryToastError(messageValue)));

  const cancelToken: React.MutableRefObject<CancelTokenSource> = useRef(null);

  const fetchCategoryList = async (name?: string) => {
    if (cancelToken.current) {
      cancelToken.current.cancel('Operation canceled due to new request.');
    }
    cancelToken.current = axios.CancelToken.source();

    const filter = name ? { name } : {};
    const searchParams = createSearchParams({ pagination: { per_page: 9999 }, filter });

    setFetching(true);

    const { data, cancelled, message } = await fetchRequest(
      fetchJudgmentsCategories,
      searchParams,
      cancelToken.current.token
    );

    if (cancelled) return;
    if (data) {
      setCategoryList(data.data);
      setFetching(false);
    }
    if (message && message.value !== 'Operation canceled due to new request.') {
      if (fetching) setFetching(false);
      dispatchJudgementCategoryToastError(message.value);
    }
  };

  useEffect(() => {
    return () => {
      if (cancelToken.current) {
        cancelToken.current.cancel('AxiosCancel');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeValue = (name: string) => {
    const findCategory: any = categoriesListWithoutDuplicates.find((category) => category.name === name);
    if (findCategory) {
      onCategoriesChange([...categories, findCategory]);
    }
  };

  const clearList = () => {
    if (cancelToken.current) {
      cancelToken.current.cancel('Operation canceled due to new request.');
    }
  };

  const convertCategories = categories.map((categoryObj) => {
    const findCategory = categoriesListWithoutDuplicates.find((categoryValue) => categoryValue.id === categoryObj.id);

    if (findCategory) {
      return { id: findCategory.id, content: findCategory.name };
    }
    return { id: categoryObj.id, content: categoryObj.name };
  });

  const onDeleteClicked = (id: number) => {
    onCategoriesChange(categories.filter((categoryObj) => categoryObj.id !== id));
  };

  const filterCategoryList = categoriesListWithoutDuplicates.filter(
    (el) => !categories.find((categoryObj) => categoryObj.id === el.id)
  );

  return (
    <div className="judgement-keywords-autocomplete">
      <AutocompleteWithChips
        currentValue=""
        onChangeCurrentValue={onChangeValue}
        searchRequest={fetchCategoryList}
        fetching={fetching}
        clearList={clearList}
        list={filterCategoryList as never}
        label="Kategorie"
        onDeleteChipElement={onDeleteClicked}
        chipContentElements={convertCategories}
        alwaysClearInput
      />
    </div>
  );
});

export default JudgementCategoriesInputAutocomplete;
