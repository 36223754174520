/* eslint-disable */
import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';
import './index.scss';

export default function QuestionMarkIcon(props: IconProps): JSX.Element {
  const { height = '40px', className = 'question-mark-icon' } = props;

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 19.364 19.364">
      <g id="Component_30_9" data-name="Component 30 – 9" transform="translate(0.5 0.5)">
        <circle
          id="Ellipse_1"
          data-name="Ellipse 1"
          cx="9.182"
          cy="9.182"
          r="9.182"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_28"
          data-name="no-fill"
          y1="0.294"
          transform="translate(8.806 14.247)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1272"
          data-name="no-fill"
          d="M897.866,1741.7v-1.782a.657.657,0,0,1,.441-.615c1.609-.571,2.95-1.441,2.95-2.921a3.143,3.143,0,0,0-3.325-2.921,3.424,3.424,0,0,0-2.887,1.473"
          transform="translate(-889.061 -1729.565)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
