import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps } from 'modules/Shared/type';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterInputType } from 'modules/Layout/type';
import { setClauseListParamsAction } from 'modules/ClauseClient/action/ClauseList';
import ClauseFilterCategoriesAutocomplete from 'modules/ClauseClient/container/ClauseList/Filter/CustomCategoriesAutocomplete';
import ClauseFilterKeywordsAutocomplete from 'modules/ClauseClient/container/ClauseList/Filter/CustomKeywordsAutocomplete';
import useSaveFilter from 'modules/Shared/helper/hooks/useSaveFilter';
import { CLAUSE_SLUG_FILTER, FiltersEntity } from 'modules/Layout/model/Filters';
import _ from 'lodash';
import SaveFilterModal from 'modules/Layout/component/ListFilter/SaveFilterModal';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { createSearchingProps, createSearchParams } from 'modules/Shared/helper/params';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { fetchClauseAllCategories, fetchClauseAllKeywords } from 'modules/Clause/repository';
import { addToastAction } from 'modules/Layout/action';
import { fetchJudgementCategoryToastError, fetchJudgementKeywordsToastError } from 'modules/Judgement/toasts';
import Loader from 'modules/Layout/component/Loader';
import Clause from 'modules/Clause/model/Clause';

const ClauseFilter = () => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.clause.clauseList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setClauseListParamsAction(payload));
  const [fetching, setFetching] = useState(false);
  const [initCategories, setInitCategories] = useState<{ name: string; id: number }[]>([]);
  const [initKeywords, setInitKeywords] = useState<{ name: string; id: number }[]>([]);

  const cancelToken = useCancelToken();
  const onSavedFilterClick = async (saveFilter: FiltersEntity) => {
    if (
      // eslint-disable-next-line
      (saveFilter.hasOwnProperty('categories[]') && Array.isArray(saveFilter['categories[]'])) ||
      // eslint-disable-next-line
      (saveFilter.hasOwnProperty('keywords[]') && Array.isArray(saveFilter['keywords[]']))
    ) {
      setFetching(true);
    }

    // eslint-disable-next-line
    if (saveFilter.hasOwnProperty('categories[]') && Array.isArray(saveFilter['categories[]'])) {
      const { data, cancelled, message } = await fetchRequest(fetchClauseAllCategories, {}, cancelToken);

      if (cancelled) return;
      if (data) {
        const initCategoriesObj: { name: string; id: number }[] = [];
        saveFilter['categories[]'].forEach((num) => {
          const findElement = data.data.find((el) => el.id === num);
          if (findElement) {
            initCategoriesObj.push({ name: findElement.name, id: findElement.id });
          } else {
            const index = (saveFilter['categories[]'] as any).indexOf(num);
            if (index > -1) {
              (saveFilter['categories[]'] as any).splice(index, 1);
            }
          }
        });
        setInitCategories(initCategoriesObj);
      }
      if (message) {
        dispatch(addToastAction(fetchJudgementCategoryToastError(message.value)));
      }
    } else {
      setInitCategories([]);
    }

    // eslint-disable-next-line
    if (saveFilter.hasOwnProperty('keywords[]') && Array.isArray(saveFilter['keywords[]'])) {
      const searchParams = createSearchParams({});
      const { data, cancelled, message } = await fetchRequest(fetchClauseAllKeywords, searchParams, cancelToken);

      if (cancelled) return;

      if (cancelled) return;
      if (data) {
        const initKeywordsObj: { name: string; id: number }[] = [];
        saveFilter['keywords[]'].forEach((num) => {
          const findElement = data.data.find((el) => el.id === num);
          if (findElement) {
            initKeywordsObj.push({ name: findElement.name, id: findElement.id });
          } else {
            const index = Array(saveFilter['keywords[]']).indexOf(num);
            if (index > -1) {
              Array(saveFilter['keywords[]']).splice(index, 1);
            }
          }
        });
        setInitKeywords(initKeywordsObj);
      }
      if (message) {
        dispatch(addToastAction(fetchJudgementKeywordsToastError(message.value)));
      }
    } else {
      setInitKeywords([]);
    }

    setFetching(false);

    setParams({ filter: saveFilter });
  };

  const {
    saveModal,
    setSaveModal,
    cleanMessage,
    loading,
    message,
    filtersList,
    saveFilter,
    onDeleteFilterClick,
    onFilterSelectedClick,
    filterList1stFetchFinished
  } = useSaveFilter(filter, setParams, CLAUSE_SLUG_FILTER, ['categories', 'keywords'], {
    onFilterSelectedClickCustomFnc: onSavedFilterClick
  });

  const searchProps = createSearchingProps('', Clause.getFilterableAttributes(), Clause.getSortableAttributes());

  useEffect(() => {
    if (filterList1stFetchFinished) {
      const findDefault = filtersList.find((el) => el.default);
      if (findDefault) {
        onFilterSelectedClick(findDefault.id);
      } else {
        const localStorageParams = localStorage.getItem('localFilterParamsclause');

        if (localStorageParams) {
          const parsedParams = JSON.parse(localStorageParams);

          setParams({ ...parsedParams });
        } else {
          setParams({ ...searchProps });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterList1stFetchFinished]);

  const staticInput: FilterInputType[] = [
    {
      type: 'text',
      property: 'name',
      label: 'Nazwa klauzuli',
      value: _get(filter, 'name')
    },
    {
      type: 'text',
      property: 'description',
      label: 'Wyjaśnienie klauzuli',
      value: _get(filter, 'description')
    }
  ];

  const clientCustomInputs = () => (
    <>
      <div className="w-100 mt-1" />
      <ClauseFilterCategoriesAutocomplete initCategories={initCategories} />
      <ClauseFilterKeywordsAutocomplete initKeywords={initKeywords} />
    </>
  );

  let disableBtn = false;
  if (_.isEmpty(filter)) {
    disableBtn = true;
  }

  const setMessage = (value: boolean) => {
    if (message) cleanMessage();
    setSaveModal(value);
  };

  return (
    <div className="position-relative">
      {fetching && <Loader />}
      <ListingFilter
        inputs={staticInput}
        filter={filter}
        filtersName="clause"
        pagination={pagination}
        setParams={setParams}
        customInput={clientCustomInputs()}
        saveFilterBtn={() => setMessage(true)}
        disableBtn={disableBtn}
        filterName="Wyszukiwanie"
        filterObj={{ filtersList, onDeleteFilterClick, onFilterSelectedClick, loading }}
      />
      {saveModal && (
        <SaveFilterModal
          onSaveClick={saveFilter}
          isOpen={saveModal}
          toggle={() => setMessage(false)}
          message={message}
          loading={loading}
          displayDefaultCheckbox
          shouldRefetchList
        />
      )}
    </div>
  );
};

export default ClauseFilter;
