import _has from 'lodash/has';
import PasswordFieldset from 'modules/Layout/component/Password/Fieldset';
import { ValidationErrors } from 'modules/Shared/type';
import React, { useState } from 'react';
import { Button, Form as FormStrap } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ROUTE_PROFILE } from 'modules/Profile/routes';
import { PasswordValues } from 'modules/Layout/type';

export interface Props {
  errors?: ValidationErrors;
  disabled?: boolean;
  cancelRedirect?: string;
  submit: (profile: PasswordValues) => void;
}

const PasswordForm: React.FC<Props> = ({ errors, disabled, submit, cancelRedirect }) => {
  const [values, setValue] = useState({
    password: '',
    password_confirmation: ''
  });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = event.target;

    if (_has(values, key)) {
      setValue({ ...values, [key]: value });
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submit(values);
  };

  const hasValue = () => !Object.values(values).some(Boolean);

  return (
    <FormStrap onSubmit={onSubmit}>
      <PasswordFieldset disabled={disabled} errors={errors} onChange={onChange} required />
      <div className="form-actions">
        <Button
          type="submit"
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light"
          disabled={hasValue() || disabled}
        >
          Zapisz
        </Button>
        <Link to={cancelRedirect || ROUTE_PROFILE} className="cancel waves-effect waves-light">
          Anuluj
        </Link>
      </div>
    </FormStrap>
  );
};

export default PasswordForm;
