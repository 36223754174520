import CardBox from 'modules/Layout/component/CardBox';
import User from 'modules/User/model/User';
import React from 'react';
import CompanyDataset from 'modules/User/component/Dataset/Company';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_USER_COMPANY_UPDATE } from 'modules/User/routes';
import { ROLE_ADMIN } from 'modules/User/model/UserRole';
import { Link } from 'react-router-dom';

export interface Props {
  user: User;
}

const DetailsCompany: React.FC<Props> = ({ user }) => {
  return (
    <CardBox
      heading="Dane rozliczeniowe"
      paddingLeft="30px"
      paddingTop="25px"
      paddingRight="30px"
      paddingBottom="0"
      actions={
        !!user?.status &&
        user?.role?.slug !== ROLE_ADMIN && (
          <Link className="link-with-border" to={getPathUrl(ROUTE_USER_COMPANY_UPDATE, { id: user.id })}>
            EDYTUJ
          </Link>
        )
      }
    >
      {user?.company?.client_type ? (
        <CompanyDataset user={user} />
      ) : (
        <div className="p-3">Brak danych rozliczeniowych.</div>
      )}
    </CardBox>
  );
};

export default DetailsCompany;
