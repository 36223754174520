import React, { Dispatch, SetStateAction } from 'react';
import { FormGroup } from 'reactstrap';
import { getError } from 'modules/Shared/helper/validation';
import CheckboxInput from 'modules/Layout/component/Input/Checkbox';
import { ValidationErrors } from 'modules/Shared/type';

export interface StepFieldPartContentProps<T> {
  contentKey: string;
  index: number;
  part: Record<any, any>;
  setStepValues: Dispatch<SetStateAction<T>>;
  mapState: (values: T) => T;
  errors?: ValidationErrors | null;
  partSlug?: string;
  initContentValues?: Record<string, string>;
  isEditDisabled?: boolean;
}

const StepFieldPartContent = <T,>(props: StepFieldPartContentProps<T>): JSX.Element => {
  const { contentKey, index, part, setStepValues, mapState, errors, partSlug, initContentValues, isEditDisabled } =
    props;

  return (
    <FormGroup key={`${contentKey}.${index}`}>
      <CheckboxInput
        id={`${contentKey}.${index}`}
        checked={true}
        // @ts-ignore
        content={part[contentKey]}
        onChange={(_, content) => {
          // @ts-ignore
          part[contentKey] = content;
          setStepValues((values) => mapState(values));
        }}
        onRevert={() => {
          part[contentKey] = initContentValues[contentKey];
          setStepValues((values) => mapState(values));
        }}
        disableCheckbox={true}
        disableEdit={isEditDisabled}
        error={getError(errors, `${partSlug}.${index}.${contentKey}`)}
      />
    </FormGroup>
  );
};

export default StepFieldPartContent;
