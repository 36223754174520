function renameDuplicateEntries<T extends Partial<{ name: string }>>(arr: T[]) {
  const names: string[] = [];
  const newArr: T[] = [];

  arr.forEach((obj) => {
    const { name } = obj;

    const getName = function (currName: string, callCount = 0): string {
      if (names.indexOf(currName) === -1) {
        return currName;
      }
      if (callCount) {
        const getNumber = parseInt(currName.slice(currName.length - 2).charAt(0), 10) || 0;

        return getName(`${currName.slice(0, -3)}(${getNumber + 1})`, callCount + 1);
      }
      const firstCallCount = callCount + 2;
      return getName(`${currName} (${firstCallCount})`, firstCallCount);
    };

    const newName = getName(name);
    names.push(newName);
    newArr.push({ ...obj, name: newName });
  });

  return newArr;
}

export default renameDuplicateEntries;
