import { managePageAction } from 'modules/Layout/action';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { breadcrumbSubscriptionsTraditionalPayment } from 'modules/Subscriptions/breadcrumbs';
import TraditionalPaymentForm from 'modules/Subscriptions/component/TraditionalPaymentForm';
import { push } from 'connected-react-router';
import { ROUTE_SUBSCRIPTIONS } from 'modules/Subscriptions/routes';
import { RootState } from 'app/reducer';

const TraditionalPaymentView = () => {
  const { subscriptions_id } = useSelector((state: RootState) => state.subscriptions.selected);
  const { subscriptions } = useSelector((state: RootState) => state.subscriptions.list);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      managePageAction({
        title: 'Tradycyjny przelew',
        breadcrumb: breadcrumbSubscriptionsTraditionalPayment()
      })
    );

    if (!subscriptions_id.length || !subscriptions.length) {
      dispatch(push(ROUTE_SUBSCRIPTIONS));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="summary-view">
      <TraditionalPaymentForm subscriptions={subscriptions} subscriptionsId={subscriptions_id} />
    </div>
  );
};

export default TraditionalPaymentView;
