import React, { useEffect, useRef, useState } from 'react';
import { Button, CustomInput, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { isEqual } from 'lodash';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import AgreementsCustomCheckboxes from 'modules/Agreements/components/CustomCheckboxes';
import { AGREEMENT_TYPE_OUTSIDE_ORDER, AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_REWARD, AGREEMENT_STEP_SUBJECT } from 'modules/Agreements/step';
import CheckboxInput from 'modules/Layout/component/Input/Checkbox';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import { getError, hasError } from 'modules/Shared/helper/validation';
import Accordion from 'modules/Layout/component/Accordion';
import Select from 'modules/Layout/component/Input/Select';
import ActionDelete from 'modules/Layout/component/Action/Delete';

export interface AgreementStepRewardValues {
  total_content: string | null;
  reward_parts: {
    part_id: number | null;
    parent_part_id: number | null;
    reward_values_content: string | null;
    reward_max_values_content: string | null;
    bill_rules_checked: boolean;
    bill_part_final_checked: boolean;
    bill_part_final_content: string | null;
    bill_periods_checked: boolean;
    bill_periods_content: string | null;
    bill_after_protocol_checked: boolean;
    bill_after_protocol_content: string | null;
    bill_other_checked: boolean;
    bill_other_content: string | null;
    reward_type: number | null;
    reward_risk_statement_checked: boolean;
    reward_risk_statement_content: string | null;
    reward_max_values_type: number | null;
    reward_calculation_type_switch: boolean;
    reward_calculation_type: number | null;
    reward_calculation_type_estimation_content: string | null;
    reward_calculation_type_table_content: string | null;
    reward_calculation_type_other_content: string | null;
    reward_no_change_type_switch: boolean;
    reward_no_change_type: number | null;
    reward_no_change_type_estimation_content: string | null;
    reward_no_change_type_table_content: string | null;
    reward_no_change_type_other_content: string | null;
    reward_license_included_checked: boolean;
    reward_license_included_content: string | null;
    reward_calculation_method_content: string | null;
    del_reward_payment_time_checked: boolean;
    del_reward_payment_time_content: string | null;
    del_reward_payment_time_parts_checked: boolean;
    del_reward_payment_time_parts_content: string | null;
  }[];
  reward_bank_account_checked: boolean;
  reward_bank_account_content: string | null;
  reward_digital_invoice_checked: boolean;
  reward_digital_invoice_content: string | null;
  reward_digit_inv_buyer_checked: boolean;
  reward_digit_inv_buyer_content: string | null;
  reward_digit_inv_parts_checked: boolean;
  reward_digit_inv_parts_content: string | null;
  reward_digit_inv_delivery_checked: boolean;
  reward_digit_inv_delivery_content: string | null;
  reward_digit_inv_delivery_time_checked: boolean;
  reward_digit_inv_delivery_time_content: string | null;
  inv_attachments_possibility: boolean;
  inv_attachments_payment_proof_checked: boolean;
  inv_attachments_payment_proof_content: string | null;
  inv_attachments_subexec_statement_checked: boolean;
  inv_attachments_subexec_statement_content: string | null;
  inv_attachments_exec_statement_checked: boolean;
  inv_attachments_exec_statement_content: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const TOTAL_CONTENT =
  '<p>Za wykonanie Przedmiotu Umowy (dotyczy wszystkich zadań) wykonawcy przysługuje wynagrodzenie:</p><p>- ........ złotych brutto (słownie: ........)</p><p>- ........ złotych netto (słownie: ........)</p><p>- kwota podatku VAT - ........ zł (słownie: ........)</p>';
const REWARD_VALUES_CONTENT =
  '<p>- ........ złotych brutto (słownie: ........)</p><p>- ........ złotych netto (słownie: ........)</p><p>- kwota podatku VAT - ........ zł (słownie: ........)</p>';
const REWARD_MAX_VALUES_CONTENT =
  '<p>- ........ złotych brutto (słownie: ........)</p><p>- ........ złotych netto (słownie: ........)</p><p>- kwota podatku VAT - ........ zł (słownie: ........)</p>';
export const BILL_PART_CONTENT =
  '...% wynagrodzenia płatna będzie na podstawie faktury częściowej wystawionej na podstawie stwierdzonego i  podpisanego przez zamawiającego protokołu odbioru częściowego dostaw';
export const BILL_PART_FINAL_CONTENT =
  '...% wynagrodzenia płatna będzie na podstawie faktury wystawionej na podstawie stwierdzonego i  podpisanego przez zamawiającego Protokołu odbioru ostatecznego dostaw';
const BILL_PERIODS_CONTENT =
  '<p>płatności za wykonane dostawy będą rozliczane w ........ okresach na podstawie  faktury częściowej wystawionej na podstawie stwierdzonego i  podpisanego przez zamawiającego protokołu odbioru częściowego zgodnie z terminami i zakresem określonym w Harmonogramie rzeczowo – finansowym. Zamawiający zapłaci wykonawcy wynagrodzenie wyłącznie za rzeczywistą ilość dostarczonych prawidłowo i odebranych partii dostaw, sukcesywnie po prawidłowej dostawie każdej partii</p>';
const BILL_AFTER_PROTOCOL_CONTENT =
  '<p>Wynagrodzenie płatne będzie po podpisaniu protokołu odbioru bez uwag przez obie strony Umowy, na podstawie wystawionej przez wykonawcę faktury</p>';
const BILL_OTHER_CONTENT = '<p>inne: ........</p>';
const REWARD_RISK_STATEMENT_CONTENT =
  'Wynagrodzenie powyższe obejmuje wszelkie koszty dostawy, związane z prawidłową i właściwą realizacją Przedmiotu Umowy, oraz te, które nie zostały wymienione w sposób wyraźny, a są konieczne do prawidłowego wykonania Umowy. Wynagrodzenie obejmuje ryzyko i odpowiedzialność wykonawcy z tytułu prawidłowego oszacowania wszelkich kosztów związanych z realizacją Umowy';
const REWARD_CALCULATION_TYPE_ESTIMATION_CONTENT = 'kosztorysie cenowym';
const REWARD_CALCULATION_TYPE_TABLE_CONTENT =
  'zestawieniu cen złożonym przez wykonawcę przed zawarciem umowy i zaakceptowanym przez zmawiającego';
const REWARD_CALCULATION_TYPE_OTHER_CONTENT = '<p>inne: ....</p>';
const REWARD_NO_CHANGE_TYPE_ESTIMATION_CONTENT = 'kosztorysie cenowym';
const REWARD_NO_CHANGE_TYPE_TABLE_CONTENT =
  'zestawieniu cen złożonym przez wykonawcę przed zawarciem umowy i zaakceptowanym przez zmawiającego';
const REWARD_NO_CHANGE_TYPE_OTHER_CONTENT = '<p>inne: ...</p>';
const REWARD_LICENSE_INCLUDED_CONTENT =
  '<p>Wynagrodzenie obejmuje również zezwolenie na wykonywanie przez zamawiającego bez ograniczeń:</p><p>1) autorskich praw majątkowych,</p><p>2) zależnych praw autorskich,</p><p>3) licencji</p><p>4) zależnych praw do licencji</p><p>5) oraz upoważnienie zamawiającego do zezwalania bez ograniczeń osobom trzecim, na wykonywanie ww. praw na zasadach opisanych Umową</p><p>6) inne: ........</p>';
const REWARD_CALCULATION_METHOD_CONTENT =
  'i określona jest na podstawie: Zamawiający zapłaci wykonawcy wynagrodzenie wyłącznie za rzeczywistą ilość dostarczonych prawidłowo i odebranych partii dostaw, sukcesywnie po prawidłowej dostawie każdej partii';
export const DEL_REWARD_PAYMENT_TIME_CONTENT =
  'za zrealizowane dostawy będzie płatne w ciągu ... dni od podpisania przez zamawiającego protokołu odbioru częściowego dla danej części dostaw';
export const DEL_REWARD_PAYMENT_TIME_PARTS_CONTENT =
  'za dostarczony Przedmiot Umowy płatne będzie w ciągu ... dni od podpisania przez zamawiającego protokołu odbioru';
export const REWARD_BANK_ACCOUNT_CONTENT =
  'Faktury za poszczególne części realizacji Umowy należy wystawić na rachunek bankowy zamawiającego: ...';
const REWARD_DIGITAL_INVOICE_CONTENT =
  "<p>Wykonawca może wystawiać ustrukturyzowane faktury elektroniczne w rozumieniu przepisów ustawy z dnia 9 listopada 2018 roku o elektronicznym fakturowaniu w zamówieniach publicznych, koncesjach na roboty budowlane lub usługi oraz partnerstwie publiczno-prywatnym (Dz. U. poz. 2191, dalej – 'Ustawa o fakturowaniu'</p>";
const REWARD_DIGIT_INV_BUYER_CONTENT =
  '<p>W przypadku wystawienia faktury elektronicznej, wykonawca jest obowiązany do wysłania jej do zamawiającego za pośrednictwem Platformy Elektronicznego Fakturowania oraz wskazać w niej następujące dane:</p><p>NABYWCA: ........</p><p>ODBIORCA: ........</p>';
const REWARD_DIGIT_INV_PARTS_CONTENT =
  '<p>Wystawiona przez wykonawcę ustrukturyzowana faktura elektroniczna winna zawierać elementy, o których mowa w art. 6 Ustawy o fakturowaniu, a nadto faktura ta, lub załącznik do niej musi zawierać numer Umowy, których dotyczy.</p>';
const REWARD_DIGIT_INV_DELIVERY_CONTENT =
  '<p>Ustrukturyzowaną  fakturę elektroniczną należy wysyłać na adres zamawiającego na Platformie Elektronicznego Fakturowania.</p>';
const REWARD_DIGIT_INV_DELIVERY_TIME_CONTENT =
  '<p>Za chwilę doręczenia ustrukturyzowanej faktury elektronicznej uznawać się będzie chwilę wprowadzenia prawidłowo wystawionej faktury, zawierającej wszystkie elementy, do konta zamawiającego na Platformie Elektronicznego Fakturowania, w sposób umożliwiający zamawiającemu zapoznanie się z jej treścią.</p>';
const INV_ATTACHMENTS_PAYMENT_PROOF_CONTENT =
  'Oświadczenia oraz dowody zapłaty, potwierdzających, że dokonał zapłaty na rzecz wszystkich podwykonawców lub dalszych podwykonawców za roboty wykazane w protokole odbioru';
const INV_ATTACHMENTS_SUBEXEC_STATEMENT_CONTENT =
  'Oświadczenia wszystkich podwykonawców lub dalszych podwykonawców potwierdzających, że na dzień wystawienia faktury dla zamawiającego, otrzymali od wykonawcy całość wynagrodzenia należnego z tytułu zawartych z wykonawcą umów i nie zgłaszają roszczeń finansowych do wykonawcy oraz zamawiającego za roboty wykazane w protokole odbioru';
const INV_ATTACHMENTS_EXEC_STATEMENT_CONTENT =
  'Oświadczenia wykonawcy, podwykonawców i dalszych podwykonawców, o których mowa powyżej powinny zawierać opis robót i zestawienie kwot należnych podwykonawcom i dalszym podwykonawcom, a do oświadczeń tych winny zostać dołączone dokumenty potwierdzające uprawnienie osób, które je złożyły, do reprezentowania podmiotów, w których imieniu zostały te oświadczenia złożon';

const initContentValues = {
  total_content: TOTAL_CONTENT,
  reward_values_content: REWARD_VALUES_CONTENT,
  reward_max_values_content: REWARD_MAX_VALUES_CONTENT,
  bill_periods_content: BILL_PERIODS_CONTENT,
  bill_after_protocol_content: BILL_AFTER_PROTOCOL_CONTENT,
  bill_other_content: BILL_OTHER_CONTENT,
  reward_risk_statement_content: REWARD_RISK_STATEMENT_CONTENT,
  reward_calculation_type_estimation_content: REWARD_CALCULATION_TYPE_ESTIMATION_CONTENT,
  reward_calculation_type_table_content: REWARD_CALCULATION_TYPE_TABLE_CONTENT,
  reward_calculation_type_other_content: REWARD_CALCULATION_TYPE_OTHER_CONTENT,
  reward_no_change_type_estimation_content: REWARD_NO_CHANGE_TYPE_ESTIMATION_CONTENT,
  reward_no_change_type_table_content: REWARD_NO_CHANGE_TYPE_TABLE_CONTENT,
  reward_no_change_type_other_content: REWARD_NO_CHANGE_TYPE_OTHER_CONTENT,
  reward_license_included_content: REWARD_LICENSE_INCLUDED_CONTENT,
  reward_calculation_method_content: REWARD_CALCULATION_METHOD_CONTENT,
  reward_digital_invoice_content: REWARD_DIGITAL_INVOICE_CONTENT,
  reward_digit_inv_buyer_content: REWARD_DIGIT_INV_BUYER_CONTENT,
  reward_digit_inv_parts_content: REWARD_DIGIT_INV_PARTS_CONTENT,
  reward_digit_inv_delivery_content: REWARD_DIGIT_INV_DELIVERY_CONTENT,
  reward_digit_inv_delivery_time_content: REWARD_DIGIT_INV_DELIVERY_TIME_CONTENT,
  inv_attachments_payment_proof_content: INV_ATTACHMENTS_PAYMENT_PROOF_CONTENT,
  inv_attachments_subexec_statement_content: INV_ATTACHMENTS_SUBEXEC_STATEMENT_CONTENT,
  inv_attachments_exec_statement_content: INV_ATTACHMENTS_EXEC_STATEMENT_CONTENT
};

const getInitCheckboxes = (): AgreementCheckboxes => {
  return {
    payment: [
      {
        checked: false,
        content:
          '<p>Terminy zapłaty uważa się za dotrzymane przez zamawiającego, jeśli konto bankowe zamawiającego zostanie obciążane kwotą należną wykonawcy najpóźniej w ostatnim dniu terminu płatności</p>'
      },
      {
        checked: false,
        content:
          '<p>Faktury wystawione nieprawidłowo, przedwcześnie, bezpodstawnie bądź bez załączonych podpisanych protokołów potwierdzających odbiór danego etapu Umowy nie rodzą obowiązku zapłaty po stronie zamawiającego. Zamawiający zobowiązany jest zwrócić się do wykonawcy, z informacją o błędach w fakturze i koniecznością przedłożenia prawidłowej faktury</p>'
      },
      {
        checked: false,
        content:
          '<p>W przypadku, gdy wykonawca wystawi wadliwą fakturę, w szczególności, gdy:</p><p>1) kwota podatku wykazana na oryginale faktury lub faktury korygującej jest różna od kwoty wykazanej na kopii</p><p>2) więcej niż jeden raz dokumentującą te same roboty</p><p>3) stwierdzającą czynności, które nie zostały wykonane</p><p>4) podającą kwoty niezgodne ze stanem faktycznym lub niezgodne z Umową</p><p>5) potwierdzającą czynności sprzeczne z prawem lub dokonane w celu obejścia ustawy lub dla pozoru</p><p>6) inne: ........</p> zobowiązuje się on do wyrównania zamawiającemu szkody powstałej w wyniku ustalenia zobowiązania podatkowego, wraz z sankcjami i ustawowymi odsetkami nałożonymi na zamawiającego przez organ skarbowy w kwotach wynikających z doręczonych decyzji'
      }
    ],
    general: [
      {
        checked: false,
        content:
          '<p>Zapłata należności objętej Umową zostanie dokonana na rzecz wykonawcy przelewem na rachunek rozliczeniowy wykonawcy, związany z prowadzoną przez wykonawcę działalnością gospodarczą, zawarty na dzień zlecenia przelewu w wykazie podmiotów, o których mowa w art. 96b ust. 1 ustawy z dnia 11 marca 2004 r. o podatku od towarów i usług (Biała Lista Podatników VAT). W dacie zawarcia umowy sprawdzono kontrahenta weryfikując wykaz podmiotów, o którym mowa  art. 96b ust. 1 ustawy z dnia 11 marca 2004 r. o podatku od towarów i usług (Biała Lista Podatników VAT)</p>'
      },
      {
        checked: false,
        content:
          '<p>W przypadku uchylenia się od obowiązku zapłaty wynagrodzenia odpowiednio przez wykonawcę, podwykonawcę lub dalszego podwykonawcę zamówienia na dostawy, zamawiający dokonuje bezpośredniej zapłaty wymagalnego wynagrodzenia przysługującego podwykonawcy lub dalszemu podwykonawcy, który zawarł zaakceptowaną przez zamawiającego umowę o podwykonawstwo, której przedmiotem są dostawy</p>'
      },
      {
        checked: false,
        content:
          '<p>Wynagrodzenie będące przedmiotem bezpośredniej zapłaty dotyczy wyłącznie należności powstałych po zaakceptowaniu przez zamawiającego umowy o podwykonawstwo, której przedmiotem są dostawy</p>'
      },
      {
        checked: false,
        content:
          '<p>Przed dokonaniem bezpośredniej zapłaty zamawiający jest obowiązany umożliwić wykonawcy zgłoszenie pisemnych uwag dotyczących zasadności bezpośredniej zapłaty wynagrodzenia podwykonawcy lub dalszemu podwykonawcy, w terminie ........ dni od dnia poinformowania o tym wykonawcy. W przypadku zgłoszenia uwag zamawiający może:</p><p>1) nie dokonać bezpośredniej zapłaty wynagrodzenia podwykonawcy lub dalszemu podwykonawcy, jeżeli wykonawca wykaże niezasadność takiej zapłaty</p><p>2) złożyć do depozytu sądowego kwotę potrzebną na pokrycie wynagrodzenia podwykonawcy lub dalszego podwykonawcy w przypadku istnienia zasadniczej wątpliwości zamawiającego co do wysokości należnej  zapłaty lub podmiotu, któremu płatność się należy</p><p>3) dokonać bezpośredniej zapłaty wynagrodzenia podwykonawcy lub dalszemu podwykonawcy, jeżeli podwykonawca lub dalszy podwykonawca wykaże zasadność takiej zapłaty</p>'
      },
      {
        checked: false,
        content:
          '<p>W przypadku dokonania bezpośredniej zapłaty podwykonawcy lub dalszemu podwykonawcy zamawiający potrąca kwotę wypłaconego wynagrodzenia z wynagrodzenia należnego wykonawcy. W przypadku dokonania bezpośredniej zapłaty podwykonawcy lub dalszemu podwykonawcy, zmawiający potrąca kwotę wypłaconego wynagrodzenia z wynagrodzenia należnego wykonawcy</p>'
      },
      {
        checked: false,
        content:
          '<p>Umowa wykonawcy z podwykonawcą oraz umowa podwykonawcy z dalszym podwykonawcą lub między dalszymi podwykonawcami musi zawierać następujące zapisy dotyczące płatności: „wykonawca po każdorazowym wystawieniu faktury przez podwykonawcę na rzecz wykonawcy/dalszego podwykonawcy zawiadomi o tym zamawiającego, przesyłając mu do wiadomości kopię faktury potwierdzoną za zgodność z oryginałem„</p>'
      },
      {
        checked: false,
        content:
          '<p>W przypadku nieprzedstawienia przez wykonawcę wszystkich dowodów zapłaty, o których mowa w niniejszym paragrafie, wstrzymuje się wypłatę należnego wynagrodzenia za odebrane roboty budowlane w części równej sumie kwot wynikających z nieprzedstawionych dowodów zapłaty</p>'
      },
      {
        checked: false,
        content:
          '<p>Konieczność wielokrotnego (więcej niż jednokrotnego) dokonywania bezpośredniej zapłaty podwykonawcy lub dalszemu podwykonawcy, o którym mowa w niniejszym paragrafie lub konieczność dokonania bezpośrednich zapłat na sumę większą niż 5% wartości Umowy stanowi podstawę do odstąpienia od Umowy przez zamawiającego</p>'
      },
      {
        checked: false,
        content:
          '<p>Do czasu przedstawienia przez wykonawcę dokumentów (oświadczenia oraz dowody zapłaty na rzecz wszystkich podwykonawców lub dalszych podwykonawców), nie biegną terminy na zapłatę faktury wykonawcy przez zamawiającego, a wykonawcy nie przysługują za ten okres odsetki ustawowe</p>'
      },
      {
        checked: false,
        content:
          '<p>Wykonawca nie może dokonać cesji wierzytelności wynikających z Umowy, bez uzyskania pisemnej (pod rygorem nieważności) zgody Zamawiającego</p>'
      }
    ]
  };
};

const mapState = (step: AgreementStepRewardValues, defaultValueStatus?: boolean): AgreementStepRewardValues => {
  const { reward_parts, checkboxes, ...rest } = step;

  const {
    total_content,
    reward_digital_invoice_content,
    reward_digit_inv_buyer_content,
    reward_digit_inv_parts_content,
    reward_digit_inv_delivery_content,
    reward_digit_inv_delivery_time_content,
    inv_attachments_possibility,
    inv_attachments_payment_proof_content,
    inv_attachments_subexec_statement_content,
    inv_attachments_exec_statement_content
  } = rest;

  return {
    ...rest,
    total_content: total_content || TOTAL_CONTENT,
    reward_parts: reward_parts.map((part) => {
      const {
        reward_values_content,
        reward_max_values_content,
        bill_periods_content,
        bill_after_protocol_content,
        bill_other_content,
        reward_risk_statement_content,
        reward_calculation_type_estimation_content,
        reward_calculation_type_table_content,
        reward_calculation_type_other_content,
        reward_no_change_type_estimation_content,
        reward_no_change_type_table_content,
        reward_no_change_type_other_content,
        reward_license_included_content,
        reward_calculation_method_content
      } = part;
      return {
        ...part,
        bill_part_final_checked: true,
        reward_values_content: reward_values_content || REWARD_VALUES_CONTENT,
        reward_max_values_content: reward_max_values_content || REWARD_MAX_VALUES_CONTENT,
        bill_part_final_content: null,
        bill_periods_content: bill_periods_content || BILL_PERIODS_CONTENT,
        bill_after_protocol_content: bill_after_protocol_content || BILL_AFTER_PROTOCOL_CONTENT,
        bill_other_content: bill_other_content || BILL_OTHER_CONTENT,
        reward_risk_statement_content: reward_risk_statement_content || REWARD_RISK_STATEMENT_CONTENT,
        reward_calculation_type_estimation_content:
          reward_calculation_type_estimation_content || REWARD_CALCULATION_TYPE_ESTIMATION_CONTENT,
        reward_calculation_type_table_content:
          reward_calculation_type_table_content || REWARD_CALCULATION_TYPE_TABLE_CONTENT,
        reward_calculation_type_other_content:
          reward_calculation_type_other_content || REWARD_CALCULATION_TYPE_OTHER_CONTENT,
        reward_no_change_type_estimation_content:
          reward_no_change_type_estimation_content || REWARD_NO_CHANGE_TYPE_ESTIMATION_CONTENT,
        reward_no_change_type_table_content: reward_no_change_type_table_content || REWARD_NO_CHANGE_TYPE_TABLE_CONTENT,
        reward_no_change_type_other_content: reward_no_change_type_other_content || REWARD_NO_CHANGE_TYPE_OTHER_CONTENT,
        reward_license_included_content: reward_license_included_content || REWARD_LICENSE_INCLUDED_CONTENT,
        reward_calculation_method_content: reward_calculation_method_content || REWARD_CALCULATION_METHOD_CONTENT
      };
    }),
    reward_digital_invoice_content: reward_digital_invoice_content || REWARD_DIGITAL_INVOICE_CONTENT,
    reward_digit_inv_buyer_content: reward_digit_inv_buyer_content || REWARD_DIGIT_INV_BUYER_CONTENT,
    reward_digit_inv_parts_content: reward_digit_inv_parts_content || REWARD_DIGIT_INV_PARTS_CONTENT,
    reward_digit_inv_delivery_content: reward_digit_inv_delivery_content || REWARD_DIGIT_INV_DELIVERY_CONTENT,
    reward_digit_inv_delivery_time_content:
      reward_digit_inv_delivery_time_content || REWARD_DIGIT_INV_DELIVERY_TIME_CONTENT,
    inv_attachments_possibility: Boolean(inv_attachments_possibility || defaultValueStatus),
    inv_attachments_payment_proof_content:
      inv_attachments_payment_proof_content || INV_ATTACHMENTS_PAYMENT_PROOF_CONTENT,
    inv_attachments_subexec_statement_content:
      inv_attachments_subexec_statement_content || INV_ATTACHMENTS_SUBEXEC_STATEMENT_CONTENT,
    inv_attachments_exec_statement_content:
      inv_attachments_exec_statement_content || INV_ATTACHMENTS_EXEC_STATEMENT_CONTENT,
    checkboxes: checkboxes || getInitCheckboxes()
  };
};

const AgreementStepReward = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, onSubmit, onChange, errors } = props;
  const agreementFromPzp = agreement.type !== AGREEMENT_TYPE_OUTSIDE_ORDER;
  const initState = useRef<AgreementStepRewardValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_REWARD), agreementFromPzp)
  );
  const [stepValues, setStepValues] = useState<AgreementStepRewardValues>({ ...initState.current });
  const { same_subject_for_parts } = getStepValues(steps, AGREEMENT_STEP_SUBJECT);
  const { reward_parts } = stepValues;
  const { parts_ids, few_parts } = agreement;

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_REWARD), agreementFromPzp);
    setStepValues({ ...initState.current });
  }, [steps]);

  const isMultiple = few_parts && !same_subject_for_parts;

  const getParentPartsOptions = (excludedPartId?: number) => {
    const partsIdsWithParents = reward_parts.filter((part) => part.parent_part_id).map((item) => item.part_id);
    const parentsPartsIds = reward_parts.filter((part) => part.parent_part_id).map((item) => item.parent_part_id);

    const options = [
      {
        // @ts-ignore
        value: null,
        label: 'nie dotyczy'
      }
    ];

    if (parentsPartsIds.includes(excludedPartId)) return options;

    return [
      ...options,
      ...parts_ids
        .filter((partObj) => !partsIdsWithParents.includes(partObj.id) && partObj.id !== excludedPartId)
        .map((partObj, index) => {
          return {
            value: partObj.id,
            label: partObj.getName(index)
          };
        })
    ];
  };

  const renderBillParts = (partId?: number) => {
    const values = stepValues.checkboxes['bill_parts'] ? stepValues.checkboxes['bill_parts'] : [];

    const onAdd = () => {
      const newCheckboxObj = { checked: true, content: '', part_id: partId };
      const checkboxesTagValues = stepValues.checkboxes['bill_parts'] ? stepValues.checkboxes['bill_parts'] : [];

      setStepValues((values) => {
        return {
          ...values,
          checkboxes: {
            ...values.checkboxes,
            bill_parts: [...checkboxesTagValues, newCheckboxObj]
          }
        };
      });
    };

    const onEdit = (checked: boolean, content: string, index: number) => {
      const newCheckboxes = { ...stepValues.checkboxes };

      newCheckboxes['bill_parts'][index].checked = checked;
      newCheckboxes['bill_parts'][index].content = content;

      setStepValues((values) => {
        return {
          ...values,
          checkboxes: newCheckboxes
        };
      });
    };

    const onDelete = (index: number) => {
      const newCheckboxes = { ...stepValues.checkboxes };

      newCheckboxes.bill_parts = newCheckboxes.bill_parts.filter((_: any, i: number) => i !== index);

      setStepValues((values) => {
        return {
          ...values,
          checkboxes: newCheckboxes
        };
      });
    };

    const renderParts = () => {
      return values.map((item, index) => {
        return renderPart(item, index);
      });
    };

    const renderPart = (item: any, index: number) => {
      const { part_id, checked, content } = item;

      if (partId && part_id !== partId) return null;

      const isInvalid =
        hasError(errors, `checkboxes.bill_parts.${index}.checked`) ||
        hasError(errors, `checkboxes.bill_parts.${index}.content`);

      // @ts-ignore
      return (
        <FormGroup key={`bill-part-${part_id}-${index}`}>
          <div className="d-flex flex-column">
            <div className="d-flex align-items-start mb-2">
              <CustomInput
                id={`bill-part-${part_id}-${index}-checkbox`}
                type="checkbox"
                checked={checked}
                onChange={(event) => {
                  const { checked } = event.target;
                  onEdit(checked, content, index);
                }}
              />
              <Input
                type="number"
                min={0}
                max={100}
                step="0.01"
                style={{ width: '100px' }}
                name={`bill-part-${part_id}-${index}-content`}
                id={`bill-part-${part_id}-${index}-content`}
                value={content}
                invalid={isInvalid}
                onChange={(event) => {
                  const { value } = event.target;
                  onEdit(checked, value, index);
                }}
              />
              <Label className="pl-3" style={{ color: isInvalid && '#B7332D' }}>
                {
                  // @ts-ignore
                  BILL_PART_CONTENT.replace('...', content || '...')
                }
              </Label>
              <div className="mx-1">
                <ActionDelete
                  title="Usuń"
                  label={<i className="fa fa-times font-22" />}
                  onClick={() => onDelete(index)}
                />
              </div>
            </div>
            {isInvalid && (
              <FormFeedback className="d-block">
                {getError(errors, `checkboxes.bill_parts.${index}.checked`) ||
                  getError(errors, `checkboxes.bill_parts.${index}.content`)}
              </FormFeedback>
            )}
          </div>
        </FormGroup>
      );
    };

    const renderAddButton = () => (
      <div className="agreement-form-actions">
        <Button type="button" color="primary" onClick={onAdd}>
          Dodaj płatność częściową
        </Button>
      </div>
    );

    return (
      <div className="mb-2">
        {renderParts()}
        {renderAddButton()}
      </div>
    );
  };

  const renderParts = () => {
    return (isMultiple ? parts_ids : [null]).map((partObj, index) => {
      const part = isMultiple ? reward_parts[index] : reward_parts[0];

      const parentPart = (
        <div className="mb-2">
          <Label for={`parent_part_select${index}_${partObj?.id}`}>Warunki takie same jak w zadaniu:</Label>
          <Select
            id={`parent_part_select${index}_${partObj?.id}`}
            onChange={({ value }) => {
              part.parent_part_id = value;
              setStepValues((values) => mapState(values));
            }}
            value={getParentPartsOptions().find((val) => val.value === part.parent_part_id)}
            options={getParentPartsOptions(partObj?.id)}
            required={false}
          />
        </div>
      );

      const body = (
        <div key={`part_wrapper_${index}_${partObj?.id}`}>
          <FormGroup>
            <Label key="reward_type_option_label">Jaki rodzaj wynagrodzenia przewiduje zamawiający?</Label>
            <CustomInput
              key={`reward_type_option_label_1_${index}`}
              id={`reward_type_option_label_1_${index}`}
              label="ryczałtowe"
              value={1}
              type="radio"
              required
              checked={part.reward_type === 1}
              onChange={() => {
                part.reward_type = 1;
                setStepValues((values) => mapState(values));
              }}
              invalid={hasError(errors, `reward_parts.${index}.reward_type`)}
            />
            <CustomInput
              key={`reward_type_option_label_2_${index}`}
              id={`reward_type_option_label_2_${index}`}
              label="kosztorysowe"
              value={2}
              type="radio"
              required
              checked={part.reward_type === 2}
              onChange={() => {
                part.reward_type = 2;
                setStepValues((values) => mapState(values));
              }}
              invalid={hasError(errors, `reward_parts.${index}.reward_type`)}
            />
            {hasError(errors, `reward_parts.${index}.reward_type`) && (
              <FormFeedback className="d-block">{getError(errors, `reward_parts.${index}.reward_type`)}</FormFeedback>
            )}
          </FormGroup>
          <hr />
          <Accordion
            isInvalid={
              hasError(errors, `reward_parts.${index}.reward_values_content`) ||
              hasError(errors, `reward_parts.${index}.reward_risk_statement_checked`) ||
              hasError(errors, `reward_parts.${index}.reward_risk_statement_content`) ||
              hasError(errors, `reward_parts.${index}.reward_calculation_type_switch`) ||
              hasError(errors, `reward_parts.${index}.reward_calculation_type`) ||
              hasError(errors, `reward_parts.${index}.reward_max_values_content`) ||
              hasError(errors, `reward_parts.${index}.reward_max_values_type`) ||
              hasError(errors, `reward_parts.${index}.reward_no_change_type_switch`) ||
              hasError(errors, `reward_parts.${index}.reward_no_change_type`) ||
              hasError(errors, `reward_parts.${index}.reward_license_included_checked`) ||
              hasError(errors, `reward_parts.${index}.reward_license_included_content`)
            }
            accordionContentStyle={{ padding: '1rem' }}
            entity={{
              title: `Wynagrodzenie ${
                part.reward_type ? (part.reward_type === 1 ? 'ryczałtowe' : 'kosztorysowe') : ''
              }`,
              content: (
                <>
                  {part.reward_type === 1 && (
                    <>
                      <Label>Za wykonanie Przedmiotu Umowy wykonawcy przysługuje wynagrodzenie ryczałtowe:</Label>
                      {renderPartContent('reward_values_content', index, part)}
                      <hr />
                      {renderPartCheckbox(
                        'reward_risk_statement_checked',
                        part.reward_risk_statement_content,
                        index,
                        part
                      )}
                    </>
                  )}
                  {part.reward_type === 2 && (
                    <>
                      {renderPartSwitch(
                        'reward_calculation_type_switch',
                        'Maksymalna wysokość wynagrodzenia należnego wykonawcy zostanie obliczona na podstawie dostaw rzeczywiście zrealizowanych na rzecz zamawiającego, w oparciu o ceny jednostkowe podane przez wykonawcę w:',
                        index,
                        part
                      )}
                      {part.reward_calculation_type_switch && [
                        <CustomInput
                          id={`reward_calculation_type_estimation_content_${index}`}
                          type="checkbox"
                          checked={part.reward_calculation_type === 1}
                          label={part.reward_calculation_type_estimation_content}
                          invalid={hasError(errors, `reward_parts.${index}.reward_calculation_type`)}
                          onChange={() => {
                            part.reward_calculation_type = 1;
                            setStepValues((values) => mapState(values));
                          }}
                        />,
                        <CustomInput
                          id={`reward_calculation_type_table_content_${index}`}
                          type="checkbox"
                          checked={part.reward_calculation_type === 2}
                          label={part.reward_calculation_type_table_content}
                          invalid={hasError(errors, `reward_parts.${index}.reward_calculation_type`)}
                          onChange={() => {
                            part.reward_calculation_type = 2;
                            setStepValues((values) => mapState(values));
                          }}
                        />,
                        <CheckboxInput
                          id={`reward_calculation_type_other_content_${index}`}
                          checked={part.reward_calculation_type === 3}
                          content={part.reward_calculation_type_other_content}
                          error={getError(errors, `reward_parts.${index}.reward_calculation_type`)}
                          onChange={(checked, content) => {
                            part.reward_calculation_type = checked ? 3 : null;
                            part.reward_calculation_type_other_content = content;
                            setStepValues((values) => mapState(values));
                          }}
                        />,
                        <Label>
                          w oparciu o kosztorys zrealizowanych dostaw (wynagrodzenie kosztorysowe). Zamawiający zapłaci
                          wykonawcy wynagrodzenie wyłącznie za rzeczywistą ilość dostarczonych prawidłowo i odebranych
                          partii dostaw, sukcesywnie po prawidłowej dostawie każdej partii
                        </Label>
                      ]}
                      <hr />
                      <Label>Cena maksymalna wykonawcy za wykonanie Przedmiotu Umowy wynosi:</Label>
                      {renderPartContent('reward_max_values_content', index, part)}
                      <FormGroup>
                        <Label>i określona jest na podstawie:</Label>
                        <CustomInput
                          key={`reward_max_values_type_1_${index}`}
                          id={`reward_max_values_type_1_${index}`}
                          label="kosztorysie cenowym"
                          value={1}
                          type="radio"
                          required
                          checked={part.reward_max_values_type === 1}
                          onChange={() => {
                            part.reward_max_values_type = 1;
                            part.reward_calculation_method_content =
                              'i określona jest na podstawie: kosztorysu ofertowego. Zamawiający zapłaci wykonawcy wynagrodzenie wyłącznie za rzeczywistą ilość dostarczonych prawidłowo i odebranych partii dostaw, sukcesywnie po prawidłowej dostawie każdej partii';
                            setStepValues((values) => mapState(values));
                          }}
                          invalid={hasError(errors, `reward_parts.${index}.reward_max_values_type`)}
                        />
                        <CustomInput
                          key={`reward_max_values_type_2_${index}`}
                          id={`reward_max_values_type_2_${index}`}
                          label="zestawieniu cen złożonym przez wykonawcę przed zawarciem umowy i zaakceptowanym przez zmawiającego"
                          value={2}
                          type="radio"
                          required
                          checked={part.reward_max_values_type === 2}
                          onChange={() => {
                            part.reward_max_values_type = 2;
                            part.reward_calculation_method_content =
                              'i określona jest na podstawie: zestawieniu cen złożonym przez wykonawcę przed zawarciem umowy i zaakceptowanym przez zmawiającego. Zamawiający zapłaci wykonawcy wynagrodzenie wyłącznie za rzeczywistą ilość dostarczonych prawidłowo i odebranych partii dostaw, sukcesywnie po prawidłowej dostawie każdej partii';
                            setStepValues((values) => mapState(values));
                          }}
                          invalid={hasError(errors, `reward_parts.${index}.reward_max_values_type`)}
                        />
                        {hasError(errors, `reward_parts.${index}.reward_max_values_type`) && (
                          <FormFeedback className="d-block">
                            {getError(errors, `reward_parts.${index}.reward_max_values_type`)}
                          </FormFeedback>
                        )}
                      </FormGroup>
                      <Label className="mt-1">
                        Zamawiający zapłaci wykonawcy wynagrodzenie wyłącznie za rzeczywistą ilość dostarczonych
                        prawidłowo i odebranych partii dostaw, sukcesywnie po prawidłowej dostawie każdej partii
                      </Label>
                      <hr />
                      {renderPartSwitch(
                        'reward_no_change_type_switch',
                        'Nie przewiduje się możliwości wzrostu cen jednostkowych jak również składników cenotwórczych podanych w:',
                        index,
                        part
                      )}
                      {part.reward_no_change_type_switch && [
                        <CustomInput
                          id={`reward_no_change_type_estimation_content_${index}`}
                          type="checkbox"
                          checked={part.reward_no_change_type === 1}
                          label={part.reward_no_change_type_estimation_content}
                          invalid={hasError(errors, `reward_parts.${index}.reward_no_change_type`)}
                          onChange={() => {
                            part.reward_no_change_type = 1;
                            setStepValues((values) => mapState(values));
                          }}
                        />,
                        <CustomInput
                          id={`reward_no_change_type_table_content_${index}`}
                          type="checkbox"
                          checked={part.reward_no_change_type === 2}
                          label={part.reward_no_change_type_table_content}
                          invalid={hasError(errors, `reward_parts.${index}.reward_no_change_type`)}
                          onChange={() => {
                            part.reward_no_change_type = 2;
                            setStepValues((values) => mapState(values));
                          }}
                        />,
                        <CheckboxInput
                          id={`reward_no_change_type_other_content_${index}`}
                          checked={part.reward_no_change_type === 3}
                          content={part.reward_no_change_type_other_content}
                          error={getError(errors, `reward_parts.${index}.reward_no_change_type`)}
                          onChange={(checked, content) => {
                            part.reward_no_change_type = checked ? 3 : null;
                            part.reward_no_change_type_other_content = content;
                            setStepValues((values) => mapState(values));
                          }}
                        />
                      ]}
                      <hr />
                    </>
                  )}
                  {renderPartEditableCheckbox('reward_license_included', index, part)}
                </>
              )
            }}
          />
          <Accordion
            isInvalid={
              hasError(errors, `reward_parts.${index}.bill_rules_checked`) ||
              hasError(errors, `reward_parts.${index}.bill_part_final_checked`) ||
              hasError(errors, `reward_parts.${index}.bill_part_final_content`) ||
              hasError(errors, `reward_parts.${index}.bill_periods_checked`) ||
              hasError(errors, `reward_parts.${index}.bill_periods_content`) ||
              hasError(errors, `reward_parts.${index}.bill_after_protocol_checked`) ||
              hasError(errors, `reward_parts.${index}.bill_after_protocol_content`) ||
              hasError(errors, `reward_parts.${index}.bill_other_checked`) ||
              hasError(errors, `reward_parts.${index}.bill_other_content`) ||
              hasError(errors, `reward_parts.${index}.del_reward_payment_time_checked`) ||
              hasError(errors, `reward_parts.${index}.del_reward_payment_time_content`) ||
              hasError(errors, `reward_parts.${index}.del_reward_payment_time_parts_checked`) ||
              hasError(errors, `reward_parts.${index}.del_reward_payment_time_parts_content`)
            }
            accordionContentStyle={{ padding: '1rem' }}
            entity={{
              title: 'Szczegółowe warunki wypłaty wynagrodzenia',
              content: (
                <>
                  {renderPartSwitch(
                    'bill_rules_checked',
                    'Wynagrodzenie wykonawcy rozliczone zostanie w sposób następujący:',
                    index,
                    part
                  )}
                  {part.bill_rules_checked && [
                    renderBillParts(partObj?.id),
                    renderPartCheckbox(
                      'bill_part_final_checked',
                      getBillPartFinalLabel(partObj?.id),
                      index,
                      part,
                      true
                    ),
                    renderPartEditableCheckbox('bill_periods', index, part),
                    renderPartCheckbox('bill_after_protocol_checked', part.bill_after_protocol_content, index, part),
                    renderPartEditableCheckbox('bill_other', index, part)
                  ]}
                  <Label>Wynagrodzenie wykonawcy:</Label>
                  {renderPartCheckboxWithNumberInput(
                    'del_reward_payment_time',
                    DEL_REWARD_PAYMENT_TIME_CONTENT,
                    index,
                    part,
                    false,
                    false
                  )}
                  {renderPartCheckboxWithNumberInput(
                    'del_reward_payment_time_parts',
                    DEL_REWARD_PAYMENT_TIME_PARTS_CONTENT,
                    index,
                    part,
                    false,
                    false
                  )}
                </>
              )
            }}
          />
        </div>
      );

      if (isMultiple) {
        return (
          <Accordion
            key={`part_accordion_${index}_${partObj?.id}`}
            isInvalid={hasError(errors, `reward_parts.${index}`)}
            accordionContentStyle={{ padding: '1rem' }}
            entity={{
              title: partObj.getName(index),
              content: (
                <>
                  {parentPart}
                  {!part.parent_part_id && body}
                </>
              )
            }}
          />
        );
      }

      return body;
    });
  };

  const getBillPartFinalLabel = (partId: number) => {
    const values = stepValues.checkboxes['bill_parts'] ? stepValues.checkboxes['bill_parts'] : [];

    const sum = values.reduce((acc, item) => {
      if (item.part_id == partId && item.checked && item.content) {
        return acc + parseFloat(item.content);
      }

      return acc;
    }, 0);

    const finalValue = 100 - sum > 0 ? 100 - sum : 0;

    return BILL_PART_FINAL_CONTENT.replace('...', String(finalValue));
  };

  const {
    renderCheckbox,
    renderEditableCheckbox,
    renderContent,
    renderPartCheckbox,
    renderPartEditableCheckbox,
    renderPartContent,
    renderPartSwitch,
    renderCustomCheckboxes,
    renderCheckboxWithBankNumberInput,
    renderPartCheckboxWithNumberInput
  } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    partSlug: 'reward_parts',
    initContentValues
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      {few_parts && renderContent('total_content')}
      {renderParts()}
      <AgreementsCustomCheckboxes
        checkboxes={stepValues.checkboxes}
        tagName="payment"
        onChange={(checkboxes: any) => {
          stepValues.checkboxes = checkboxes;
          setStepValues((values) => mapState(values));
        }}
        errors={errors}
        hideDelete
        hideAdd
      />
      {renderCheckboxWithBankNumberInput('reward_bank_account', REWARD_BANK_ACCOUNT_CONTENT)}
      {renderEditableCheckbox('reward_digital_invoice')}
      {stepValues.reward_digital_invoice_checked && (
        <>
          {renderEditableCheckbox('reward_digit_inv_buyer')}
          {renderEditableCheckbox('reward_digit_inv_parts')}
          {renderEditableCheckbox('reward_digit_inv_delivery')}
          {renderEditableCheckbox('reward_digit_inv_delivery_time')}
          <hr />
        </>
      )}
      {renderCheckbox(
        'inv_attachments_possibility',
        'Wykonawca ma obowiązek załączenia do każdej wystawionej faktury dla zamawiającego:',
        agreementFromPzp
      )}
      <div className="pl-4">
        {stepValues.inv_attachments_possibility && [
          renderCheckbox('inv_attachments_payment_proof_checked', stepValues.inv_attachments_payment_proof_content),
          renderCheckbox(
            'inv_attachments_subexec_statement_checked',
            stepValues.inv_attachments_subexec_statement_content
          ),
          renderCheckbox('inv_attachments_exec_statement_checked', stepValues.inv_attachments_exec_statement_content)
        ]}
      </div>
      {renderCustomCheckboxes('general', null, true, true)}
    </AgreementFormWrapper>
  );
};

export default AgreementStepReward;
