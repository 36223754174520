import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClauseListParamsAction } from 'modules/Clause/action/ClauseList';
import ClauseKeywordsAutocomplete from 'modules/Clause/container/ClauseAutocomplete/Keywords';

export interface Props {
  values?: SearchParams;
}

const ClauseFilterKeywordsAutocomplete = () => {
  const dispatch = useDispatch();
  const { filter } = useSelector((state: RootState) => state.clause.clauseList);
  const [keywords, setKeywords] = useState<{ name: string; id: number }[]>([]);

  const setParams = (payload: PartialSearchingProps) => dispatch(setClauseListParamsAction(payload));

  const saveKeyword = (newKeyword: { name: string; id: number }) => {
    if (!keywords.find((value) => value.id === newKeyword.id)) {
      setKeywords([...keywords, newKeyword]);
      filter['keywords[]'] = [...keywords.map((value) => value.id), newKeyword.id] as any;
      setParams({ ...filter });
    }
  };

  const deleteKeyword = (id: number) => {
    const newKeyword = keywords.filter((el) => el.id !== id);
    setKeywords(newKeyword);
    // eslint-disable-next-line
    if (filter.hasOwnProperty('keywords[]')) {
      const { 'keywords[]': _ignore, ...rest } = filter;
      if (newKeyword.length > 0) {
        rest['keywords[]'] = newKeyword.map((value) => value.id) as any;
      }
      setParams({ filter: rest });
    }
  };

  return (
    <div className="col-12 col-lg-6 col-xl-4">
      <ClauseKeywordsAutocomplete
        keywords={keywords}
        onKeywordsChange={saveKeyword}
        alwaysClearInput
        chipContentElements={keywords.map(({ name, id }) => ({ content: name, id }))}
        onDeleteChipElement={deleteKeyword}
      />
    </div>
  );
};

export default ClauseFilterKeywordsAutocomplete;

// old version - pre PZP2UE-1076 task
// const ClauseFilterKeywordsAutocomplete: React.FC<Props> = () => {
//   const dispatch = useDispatch();
//   const { filter } = useSelector((state: RootState) => state.clause.clauseList);
//   const [keyword, setKeyword] = useState({ name: '', id: null });

//   const setParams = (payload: PartialSearchingProps) => dispatch(setClauseListParamsAction(payload));

//   useEffect(() => {
//     if (keyword.id) setParams({ filter: { ...filter, keyword_id: keyword.id } });
//     // eslint-disable-next-line
//     if (!keyword.id && filter.hasOwnProperty('keyword_id')) {
//       const { keyword_id, ...rest } = filter;
//       setParams({ filter: rest });
//     }
//     // eslint-disable-next-line
//   }, [keyword]);

//   return (
//     <div className="col-12 col-sm-6 col-md-5 col-xl-3 form-group">
//       <ClauseKeywordAutocomplete keywordValue={keyword} onKeywordChange={setKeyword} />
//     </div>
//   );
// };

// export default ClauseFilterKeywordsAutocomplete;
