import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import { formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import Proceedings, {
  proceedingsMode,
  proceedingsQuestionsDateOptions,
  proceedingsStatus,
  proceedingsType
} from 'modules/Proceedings/model/Proceedings';
import { PROCEEDINGS_INIT_TYPES_VALUES } from 'modules/Proceedings/type';

export type Props = {
  element?: Proceedings;
  isAdmin?: boolean;
};

const ProceedingsBasicDataset: React.FC<Props> = ({ element, isAdmin }) => {
  const isPlanning = element?.proceeding_type === PROCEEDINGS_INIT_TYPES_VALUES.PLANNING;

  const renderWadiumValue = (row: Proceedings) => {
    if (!row?.requires_deposit) return 'Zamawiający nie wymaga wniesienia wadium';

    if (row?.deposit === 0) {
      return 'Nie udało się zweryfikować prawidłowej wysokości wadium';
    }

    return `${row?.deposit.toFixed(2)} PLN`;
  };

  const fieldsPartOne = [
    {
      label: 'Rodzaj zamówienia',
      value: proceedingsType(element?.type) || 'brak danych'
    },
    !isPlanning && {
      label: 'Status postępowania',
      value: proceedingsStatus(element?.status) || 'brak danych'
    },
    {
      label: 'Tryb udzielania zamówienia',
      value: proceedingsMode(element?.mode) || 'brak danych'
    },
    {
      label: 'Nazwa postępowania',
      value: element?.name || 'brak danych'
    }
  ];

  const fieldsDependingOnProceedingType = isPlanning
    ? []
    : [
        {
          label: 'Numer postępowania',
          value: element?.identifier || 'brak danych'
        },
        {
          label: 'Numer ogłoszenia o zamówieniu w BZP',
          value: element?.bzp_identifier || 'brak danych'
        },
        {
          label: 'Data zamieszczenia ogłoszenia o zamówieniu w BZP',
          value: ifContainsDateFormat(element?.bzp_date, formatDatetimeSeconds) || 'brak danych'
        }
      ];

  const fieldsPartTwo = [
    {
      label: 'Nazwa zamawiającego',
      value: element?.contractor_name || 'brak danych'
    },
    {
      label: 'Adres zamawiającego',
      value: element?.contractor_address || 'brak danych'
    },
    {
      label: 'Email zamawiającego',
      value: element?.contractor_email || 'brak danych'
    },
    {
      label: 'NIP zamawiającego',
      value: element?.contractor_nip || 'brak danych'
    },
    {
      label: 'Wartość zamówienia',
      value: element?.value ? `${element.value.toFixed(2).replace('.', ',')} PLN` : 'brak danych'
    }
  ];

  const adminContactField = isAdmin
    ? [
        {
          label: 'Czy w postępowaniu jest możliwa forma kontaktu poprzez email?',
          value: element?.email_contact_checked
            ? 'Tak, w postępowaniu jest możliwa forma kontaktu poprzez email.'
            : 'Nie, w postępowaniu forma kontaktu poprzez email jest niemożliwa.'
        },
        {
          label: 'Czy w postępowaniu jest możliwa forma kontaktu poprzez system Asystent Postępowania?',
          value: element?.epd2_contact_checked
            ? 'Tak, w postępowaniu jest możliwa forma kontaktu poprzez system Asystent Postępowania.'
            : 'Nie, w postępowaniu forma kontaktu poprzez system Asystent Postępowania jest niemożliwa.'
        },
        {
          label: 'Czy w postępowaniu jest możliwa forma kontaktu pocztą?',
          value: element?.post_contact_checked
            ? 'Tak, w postępowaniu jest możliwa forma kontaktu pocztą.'
            : 'Nie, w postępowaniu forma kontaktu poprzez pocztę jest niemożliwa.'
        }
      ]
    : [
        {
          label: 'Kody CPV',
          value: element?.cpv_codes_string || 'brak danych'
        },
        {
          label: 'E-mail kontaktowy lub inna forma kontaktu z zamawiającym',
          value:
            element?.email_contact_checked ||
            element?.epd2_contact_checked ||
            element?.post_contact_checked ||
            element?.other_contact_checked ? (
              <div>
                Zamawiający dopuszcza następujące formy kontaktu:
                <ul className="pl-3 my-1">
                  {element?.email_contact_checked ? (
                    <li>
                      kontakt przez e-mail
                      {element?.contractor_email ? (
                        <div className="d-inline">
                          {' '}
                          (pod adresem:<a href={`mailto:${element.contractor_email}`}>{element.contractor_email}</a>)
                        </div>
                      ) : null}
                    </li>
                  ) : (
                    ''
                  )}
                  {element?.epd2_contact_checked ? <li>komunikacja poprzez system Asystenta Postępowań</li> : null}
                  {element?.post_contact_checked ? (
                    <li>
                      kontakt pocztą
                      {element?.contractor_address ? (
                        <div className="d-inline">
                          {' '}
                          (pod adresem: <strong>{element.contractor_address}</strong>)
                        </div>
                      ) : null}
                    </li>
                  ) : null}
                  {element?.other_contact_checked ? <li>inna forma kontaktu: forma podana w polu poniżej</li> : null}
                </ul>
              </div>
            ) : (
              'Zamawiający nie wskazał żadnej formy kontaktu.'
            )
        }
      ];

  const fieldsDependingOnProceedingTypePartTwo = isPlanning
    ? []
    : [
        {
          label: 'Wartość wadium',
          value: renderWadiumValue(element)
        },
        {
          label: 'Termin zadawania pytań',
          value: proceedingsQuestionsDateOptions(element?.questions_date)
        }
      ];

  const fieldsPartThree = [
    {
      label: 'Strona postępowania',
      value: element?.announcement_link ? (
        <a href={element.announcement_link} rel="noreferrer" target="_blank">
          {element.announcement_link}
        </a>
      ) : (
        'brak danych'
      )
    },
    !isPlanning && {
      label: 'Termin składania ofert',
      value: ifContainsDateFormat(element?.offers_send_date, formatDatetimeSeconds) || 'brak danych'
    },
    !isPlanning && {
      label: 'Platforma zakupowa',
      value: element?.shopping_platform || 'brak danych'
    }
  ];

  const fields = [
    ...fieldsPartOne,
    ...fieldsPartTwo,
    ...adminContactField,
    ...fieldsDependingOnProceedingType,
    ...fieldsDependingOnProceedingTypePartTwo,
    ...fieldsPartThree
  ];

  return <InformationList fields={fields} />;
};

export default ProceedingsBasicDataset;
