import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { PaginationMeta, SearchParams } from 'modules/Shared/type';
import { JudgementEntity } from 'modules/Judgement/model/Judgement';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';

export const API_JUDGEMENTS = '/api/client/judgments';
export const API_JUDGEMENT = '/api/client/judgments/:id';
export const API_JUDGEMENT_KEYWORDS = '/api/judgments/keywords/all';
export const API_JUDGEMENT_CATEGORIES = '/api/judgments/categories/all';
export const API_JUDGEMENT_DETAILS_KEYWORDS = '/api/client/judgments/:id/keywords';
export const API_JUDGEMENT_DETAILS_CLAUSES = '/api/client/judgments/:id/clauses';

export type FetchJudgement = {
  data: JudgementEntity;
};

export const fetchJudgmentClient = (id: string | number, cancelToken?: any): AxiosPromise<FetchJudgement> =>
  axios.get(getAPIUrl(API_JUDGEMENT, { id }), { cancelToken });

export type FetchJudgements = {
  data: JudgementEntity[];
  meta: PaginationMeta;
  links: any;
};

export const fetchJudgmentsClient = (params: SearchParams = {}): AxiosPromise<FetchJudgements> =>
  axios.get(getAPIUrl(API_JUDGEMENTS, null, params));

export type FetchJudgementElement = {
  data: JudgementElementEntity[];
};

export const fetchJudgmentClientKeywords = (params: SearchParams = {}): AxiosPromise<FetchJudgementElement> =>
  axios.get(getAPIUrl(API_JUDGEMENT_KEYWORDS, null, params));

export const fetchJudgmentClientCategories = (params: SearchParams = {}): AxiosPromise<FetchJudgementElement> =>
  axios.get(getAPIUrl(API_JUDGEMENT_CATEGORIES, null, params));

export type FetchJudgementEntity = {
  data: JudgementElementEntity[];
};

export const fetchJudgmentClientDetailsKeywords = (
  id: string | number,
  cancelToken?: any
): AxiosPromise<FetchJudgementEntity> => axios.get(getAPIUrl(API_JUDGEMENT_DETAILS_KEYWORDS, { id }), { cancelToken });

export const fetchJudgmentClientDetailsClauses = (
  id: string | number,
  cancelToken?: any
): AxiosPromise<{ data: JudgmentDetailsConnectedClausesValue[] }> =>
  axios.get(getAPIUrl(API_JUDGEMENT_DETAILS_CLAUSES, { id }), { cancelToken });
