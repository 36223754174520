import React, { useEffect, useRef, useState } from 'react';
import { CustomInput, FormFeedback, FormGroup, Label } from 'reactstrap';
import { isEqual } from 'lodash';
import { getError, hasError } from 'modules/Shared/helper/validation';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_RECEIPT, AGREEMENT_STEP_SUBJECT } from 'modules/Agreements/step';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import Accordion from 'modules/Layout/component/Accordion';

export const DEL_PERIODICITY_CYCLICAL = 'cyclical';
export const DEL_PERIODICITY_SINGLE = 'single';

export interface AgreementStepReceiptValues {
  del_same_receipt_requirements: boolean;
  del_periodicity: string | null;
  receipt_requirements: {
    part_id: number | null;
    del_date_checked: boolean;
    del_date_content: string | null;
    del_authorized_persons_checked: boolean;
    del_authorized_persons_content: string | null;
    del_communication_checked: boolean;
    del_communication_content: string | null;
    del_communication_personal_checked: boolean;
    del_communication_personal_content: string | null;
    del_communication_phone_checked: boolean;
    del_communication_phone_content: string | null;
    del_communication_email_checked: boolean;
    del_communication_email_content: string | null;
    del_communication_other_checked: boolean;
    del_communication_other_content: string | null;
    del_arrangements_checked: boolean;
    del_arrangements_content: string | null;
    del_executor_checked: boolean;
    del_executor_content: string | null;
    del_contractor_checked: boolean;
    del_contractor_content: string | null;
    del_max_time_checked: boolean;
    del_max_time_content: string | null;
    del_confirmation_checked: boolean;
    del_confirmation_content: string | null;
    del_rejection_checked: boolean;
    del_rejection_content: string | null;
    del_rejection_delay_checked: boolean;
    del_rejection_delay_content: string | null;
    del_rejection_costs_checked: boolean;
    del_rejection_costs_content: string | null;
    del_unload_checked: boolean;
    del_unload_content: string | null;
    del_new_date_checked: boolean;
    del_new_date_content: string | null;
    del_description_checked: boolean;
    del_description_content: string | null;
    del_transport_checked: boolean;
    del_transport_content: string | null;
    del_responsibility_checked: boolean;
    del_responsibility_content: string | null;
    del_guarantee_checked: boolean;
    del_guarantee_content: string | null;
    del_receipt_rules_checked: boolean;
    del_receipt_rules_content: string | null;
    del_documents_checked: boolean;
    del_documents_content: string | null;
    del_documents_check_checked: boolean;
    del_documents_check_content: string | null;
    del_defects_checked: boolean;
    del_defects_content: string | null;
    del_risk_checked: boolean;
    del_risk_content: string | null;
    representation_checked: boolean;
    representation_content: string | null;
    representation_executor_checked: boolean;
    representation_executor_content: string | null;
  }[];
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

export const getSinglePartsLabel = (sameSubjectForParts: boolean, isMultiple: boolean) => {
  if (sameSubjectForParts) {
    return 'Zamawiający ustala poniższe wymagania w zakresie odbioru, warunków i miejsca spełnienia świadczenia: ';
  } else if (isMultiple) {
    return 'Zamawiający przedstawia poniższe wymagania dotyczące odbioru, warunków i miejsca spełnienia świadczenia';
  } else {
    return 'Zamawiający ustala poniższe wymagania w zakresie odbioru, warunków i miejsca spełnienia świadczenia dla każdego z zadań:';
  }
};

const DEL_DATE_CONTENT =
  'Dostawy będące Przedmiotem Umowy realizowane będą sukcesywnie stosownie do zapotrzebowania zamawiającego od dnia ........ do ........ Dostawy realizowane są wyłącznie na podstawie każdorazowego zamówienia przez zamawiającego poszczególnych partii (dalej jako „partie”)';
const DEL_DATE_SINGLE_CONTENT =
  'Wykonawca jest obowiązany do dostarczenia Przedmiotu Umowy do wskazanego miejsca tj.: ........ w umownym terminie';
const DEL_AUTHORIZED_PERSONS_CONTENT =
  'Osobami uprawnionymi do złożenia zamówienia na partie oraz ich odbioru są upoważnieni pracownicy zamawiającego';
const DEL_COMMUNICATION_CONTENT = 'Strony zastrzegają, że zamówienie może być złożone przez pracowników zamawiającego:';
const DEL_COMMUNICATION_PERSONAL_CONTENT = 'osobiście';
const DEL_COMMUNICATION_PHONE_CONTENT = 'telefonicznie';
const DEL_COMMUNICATION_EMAIL_CONTENT = 'za pośrednictwem poczty elektronicznej';
const DEL_COMMUNICATION_OTHER_CONTENT = 'inne.';
const DEL_ARRANGEMENTS_CONTENT =
  '<p>Terminy oraz wielkość dostaw częściowych będą uzgadniane z odpowiednimi pracownikami zamawiającego. Zamawiający przy zleceniu zapotrzebowania dostawy wskaże wykonawcy:</p><p>a) miejsce dostawy</p><p>b) rodzaj oraz ilość zamówionego towaru</p><p>c)termin realizacji zamówienia</p>';
const DEL_EXECUTOR_CONTENT =
  'Wykonawca podaje dane do składania zamówień: ........ oraz miejsce gdzie dostawy mają być dostarczane';
const DEL_CONTRACTOR_CONTENT =
  'Zamawiający podaje dane pracowników upoważnionych do dokonywania zamówienia na partie, odbioru partii, kontroli partii';
const DEL_MAX_TIME_CONTENT =
  'Poszczególne dostawy partii wykonawca będzie realizował w terminie maksymalnie do ........ dni kalendarzowych od otrzymania zamówienia od zamawiającego';
const DEL_CONFIRMATION_CONTENT =
  'Odbiór każdej Partii zostanie potwierdzony przez upoważnionego pracownika zamawiającego. Na tę okoliczność sporządzony zostanie protokół odbioru danej partii. Zamawiający przy odbiorze towaru jest obowiązany do szczegółowego jakościowego oraz ilościowego badania Przedmiotu dostawy';
const DEL_REJECTION_CONTENT =
  '<p>Upoważniony pracownik zamawiającego może odmówić przyjęcia Partii lub jej części, z winy wykonawcy, jeżeli nie spełnia wymogów określonych w:</p><p>a) Umowie,</p><p>b) Specyfikacji warunków zamówienia oraz dokumentacji postępowania</p><p>c) inne: ........</p><p>w tym nie odpowiada ustalonej:</p><p>a) ilości</p><p>b) jakości</p><p>c) sposobowi i wielkości opakowania</p><p>d) oznaczenia</p><p>e) inne: ........';
const DEL_REJECTION_DELAY_CONTENT =
  'Odmowa przyjęcia partii lub jej części przez zamawiającego powoduje zwłokę wykonawcy w dostawie';
const DEL_REJECTION_COSTS_CONTENT =
  '<p>W przypadku gdy wykonawca nie zgadza się ze stanowiskiem zamawiającego co do odmowy przyjęcia partii, zamawiający zleci wykonanie stosownych badań laboratoryjnych. Koszt badań ostatecznie obciąża:</p><p>a) zamawiającego – w przypadku zgodności przedmiotu dostawy z wymaganiami</p><p>b) wykonawcę – w przypadku niezgodności przedmiotu dostawy z wymaganiami</p>';
const DEL_UNLOAD_CONTENT =
  'Rozładowanie partii leży po stronie wykonawcy. Obowiązkiem wykonawcy jest także przeniesienie partii w miejsce wskazane przez zamawiającego. Brak wykonania tych obowiązków przez wykonawcę uznaje się za zwłokę wykonawcy w dostawie';
const DEL_NEW_DATE_CONTENT =
  'W przypadku zwłoki wykonawcy w dostawie, zamawiający wyznacza nowy termin, nie krótszy niż ........ pełny dzień roboczy. W przypadku nie wykonania ponownej dostawy partii w wyznaczonym terminie, zamawiający ma prawo do zakupu zastępczego partii na koszt i ryzyko wykonawcy, bez dodatkowego wzywania i informowania o tym wykonawcy. Koszty zakupu zastępczego zostaną potrącone z należnego wykonawcy wynagrodzenia. W tym celu zamawiający przedstawi odpowiedni dokument zakupu oraz złoży stosowne oświadczenie o potrąceniu';
const DEL_DESCRIPTION_CONTENT =
  'Wykonawca jest zobowiązany zapewnić następujące oznaczenie dostarczanej partii: ........';
const DEL_TRANSPORT_CONTENT =
  'Koszty transportu ponosi wykonawca. Rozładunek odbywać się będzie przy pomocy pracowników zamawiającego w miejscach wskazanych przez zamawiającego';
const DEL_RESPONSIBILITY_CONTENT =
  'Wykonawca ponosi odpowiedzialność wobec zamawiającego oraz osób trzecich za szkody i inne zdarzenia powstałe w związku z wykonywaniem czynności będących Przedmiotem Umowy';
const DEL_GUARANTEE_CONTENT =
  'Postanowienia niniejszego paragrafu nie wyłączają ani nie ograniczają odpowiedzialności wykonawcy z tytułu gwarancji jakości bądź rękojmi za wady Przedmiotu Umowy';
const DEL_RECEIPT_RULES_CONTENT =
  '<p>Odbiór Przedmiotu Umowy będzie polegał na sprawdzeniu kompletności, braku uszkodzeń mechanicznych oraz zgodności z:</p><p>a) Umową</p><p>b) Specyfikacją Istotnych Warunków Zamówienia</p><p>c) Dokumentacją techniczną</p><p>d) Specyfikacją techniczną</p><p>e) Opisem Przedmiotu Zmówienia</p><p>f) inne: ........</p>';
const DEL_DOCUMENTS_CONTENT =
  '<p>Wykonawca w chwili wydania zamawiającemu Przedmiotu Umowy przekaże zamawiającemu poniżej wskazane dokumenty:</p><p>a) instrukcję obsługi (sporządzoną w języku polskim)</p><p>b) dokumentację techniczno-ruchowa oraz innych zamontowanych podzespołów sporządzoną w języku polskim</p><p>c) kartę gwarancyjną</p><p>d) inne: ........</p><p>w wersji:</p><p>a) papierowej w ilości ........ egzemplarzy</p><p>b) elektronicznej</p>';
const DEL_DOCUMENTS_CHECK_CONTENT =
  'Zamawiający sprawdzi także dokumenty przedstawione przez wykonawcę w związku z odbiorem';
const DEL_DEFECTS_CONTENT =
  'Jeżeli w trakcie odbioru zamawiający stwierdzi istnienie wad technicznych lub niezgodności parametrów z Umową, brak wymaganych dokumentów lub ich niezgodności z wymaganiami, zamawiający odnotuje w protokole odbioru, iż Przedmiot Umowy nie spełnia warunków i niezwłocznie powiadomi wykonawcę o stwierdzonych wadach lub nieprawidłowościach, a wykonawca dostarczy na własny koszt Przedmiot Dostawy wolny od wad w terminie nie dłuższym niż ........ dni robocze licząc od daty wysłania zawiadomienia';
const DEL_RISK_CONTENT =
  'Do czasu odbioru Przedmiotu Umowy przez zamawiającego, ryzyko wszelkich niebezpieczeństw związanych z ewentualnym uszkodzeniem, utratą lub szkodami powstałymi podczas transportu ponosi wykonawca';
const REPRESENTATION_CONTENT = 'Zamawiającego przy odbiorach reprezentować będą: ........';
const REPRESENTATION_EXECUTOR_CONTENT = 'Wykonawcę przy odbiorach reprezentować będą: ........';

const initContentValues = {
  del_authorized_persons_content: DEL_AUTHORIZED_PERSONS_CONTENT,
  del_communication_personal_content: DEL_COMMUNICATION_PERSONAL_CONTENT,
  del_communication_phone_content: DEL_COMMUNICATION_PHONE_CONTENT,
  del_communication_email_content: DEL_COMMUNICATION_EMAIL_CONTENT,
  del_communication_other_content: DEL_COMMUNICATION_OTHER_CONTENT,
  del_arrangements_content: DEL_ARRANGEMENTS_CONTENT,
  del_executor_content: DEL_EXECUTOR_CONTENT,
  del_contractor_content: DEL_CONTRACTOR_CONTENT,
  del_max_time_content: DEL_MAX_TIME_CONTENT,
  del_confirmation_content: DEL_CONFIRMATION_CONTENT,
  del_rejection_content: DEL_REJECTION_CONTENT,
  del_rejection_delay_content: DEL_REJECTION_DELAY_CONTENT,
  del_rejection_costs_content: DEL_REJECTION_COSTS_CONTENT,
  del_unload_content: DEL_UNLOAD_CONTENT,
  del_new_date_content: DEL_NEW_DATE_CONTENT,
  del_description_content: DEL_DESCRIPTION_CONTENT,
  del_transport_content: DEL_TRANSPORT_CONTENT,
  del_responsibility_content: DEL_RESPONSIBILITY_CONTENT,
  del_guarantee_content: DEL_GUARANTEE_CONTENT,
  del_receipt_rules_content: DEL_RECEIPT_RULES_CONTENT,
  del_documents_content: DEL_DOCUMENTS_CONTENT,
  del_documents_check_content: DEL_DOCUMENTS_CHECK_CONTENT,
  del_defects_content: DEL_DEFECTS_CONTENT,
  del_risk_content: DEL_RISK_CONTENT,
  representation_content: REPRESENTATION_CONTENT,
  representation_executor_content: REPRESENTATION_EXECUTOR_CONTENT
};

const getDelDateContent = (value: string, delPeriodicity: string) => {
  if (value) return value;

  return delPeriodicity === DEL_PERIODICITY_SINGLE ? DEL_DATE_SINGLE_CONTENT : DEL_DATE_CONTENT;
};

const mapState = (step: AgreementStepReceiptValues): AgreementStepReceiptValues => {
  const { receipt_requirements, checkboxes, del_periodicity, ...rest } = step;

  return {
    ...rest,
    del_periodicity,
    receipt_requirements: receipt_requirements.map((part) => {
      const {
        del_date_content,
        del_authorized_persons_content,
        del_communication_personal_content,
        del_communication_phone_content,
        del_communication_email_content,
        del_communication_other_content,
        del_arrangements_content,
        del_executor_content,
        del_contractor_content,
        del_max_time_content,
        del_confirmation_content,
        del_rejection_content,
        del_rejection_delay_content,
        del_rejection_costs_content,
        del_unload_content,
        del_new_date_content,
        del_description_content,
        del_transport_content,
        del_responsibility_content,
        del_guarantee_content,
        del_receipt_rules_content,
        del_documents_content,
        del_documents_check_content,
        del_defects_content,
        del_risk_content,
        representation_content,
        representation_executor_content
      } = part;

      return {
        ...part,
        del_date_content: getDelDateContent(del_date_content, del_periodicity),
        del_authorized_persons_content: del_authorized_persons_content || DEL_AUTHORIZED_PERSONS_CONTENT,
        del_communication_personal_content: del_communication_personal_content || DEL_COMMUNICATION_PERSONAL_CONTENT,
        del_communication_phone_content: del_communication_phone_content || DEL_COMMUNICATION_PHONE_CONTENT,
        del_communication_email_content: del_communication_email_content || DEL_COMMUNICATION_EMAIL_CONTENT,
        del_communication_other_content: del_communication_other_content || DEL_COMMUNICATION_OTHER_CONTENT,
        del_arrangements_content: del_arrangements_content || DEL_ARRANGEMENTS_CONTENT,
        del_executor_content: del_executor_content || DEL_EXECUTOR_CONTENT,
        del_contractor_content: del_contractor_content || DEL_CONTRACTOR_CONTENT,
        del_max_time_content: del_max_time_content || DEL_MAX_TIME_CONTENT,
        del_confirmation_content: del_confirmation_content || DEL_CONFIRMATION_CONTENT,
        del_rejection_content: del_rejection_content || DEL_REJECTION_CONTENT,
        del_rejection_delay_content: del_rejection_delay_content || DEL_REJECTION_DELAY_CONTENT,
        del_rejection_costs_content: del_rejection_costs_content || DEL_REJECTION_COSTS_CONTENT,
        del_unload_content: del_unload_content || DEL_UNLOAD_CONTENT,
        del_new_date_content: del_new_date_content || DEL_NEW_DATE_CONTENT,
        del_description_content: del_description_content || DEL_DESCRIPTION_CONTENT,
        del_transport_content: del_transport_content || DEL_TRANSPORT_CONTENT,
        del_responsibility_content: del_responsibility_content || DEL_RESPONSIBILITY_CONTENT,
        del_guarantee_content: del_guarantee_content || DEL_GUARANTEE_CONTENT,
        del_receipt_rules_content: del_receipt_rules_content || DEL_RECEIPT_RULES_CONTENT,
        del_documents_content: del_documents_content || DEL_DOCUMENTS_CONTENT,
        del_documents_check_content: del_documents_check_content || DEL_DOCUMENTS_CHECK_CONTENT,
        del_defects_content: del_defects_content || DEL_DEFECTS_CONTENT,
        del_risk_content: del_risk_content || DEL_RISK_CONTENT,
        representation_content: representation_content || REPRESENTATION_CONTENT,
        representation_executor_content: representation_executor_content || REPRESENTATION_EXECUTOR_CONTENT
      };
    }),
    checkboxes: checkboxes || {}
  };
};

const AgreementStepReceipt = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, onSubmit, onChange, errors } = props;
  const initState = useRef<AgreementStepReceiptValues>(mapState(getStepValues(steps, AGREEMENT_STEP_RECEIPT)));
  const { same_subject_for_parts } = getStepValues(steps, AGREEMENT_STEP_SUBJECT);
  const [stepValues, setStepValues] = useState<AgreementStepReceiptValues>({ ...initState.current });
  const { parts_ids, few_parts } = agreement;
  const { receipt_requirements, del_same_receipt_requirements } = stepValues;

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_RECEIPT));
    setStepValues({ ...initState.current });
  }, [steps]);

  const samePartsChange = (checked: boolean) => {
    setStepValues((values) =>
      mapState({
        ...values,
        del_same_receipt_requirements: checked,
        receipt_requirements: checked
          ? [
              {
                ...receipt_requirements[0],
                part_id: null
              }
            ]
          : parts_ids.map((part) => ({
              ...receipt_requirements[0],
              part_id: part.id
            }))
      })
    );
  };

  const delDateContentChange = () => {
    setStepValues((values) =>
      mapState({
        ...values,
        receipt_requirements: values.receipt_requirements.map((item) => ({
          ...item,
          del_date_content:
            values.del_periodicity === DEL_PERIODICITY_CYCLICAL ? DEL_DATE_CONTENT : DEL_DATE_SINGLE_CONTENT
        }))
      })
    );
  };

  const { renderPartCheckbox, renderPartEditableCheckbox, renderPartSwitch, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    partSlug: 'receipt_requirements',
    initContentValues
  });

  const renderCyclicalParts = () => {
    const isMultiple = few_parts && !same_subject_for_parts;

    const content = (isMultiple ? parts_ids : [null]).map((partObj, index) => {
      const part = isMultiple ? receipt_requirements[index] : receipt_requirements[0];

      if (!part) return null;

      const body = (
        <FormGroup key={`cyclical_parts_${index}`}>
          {[
            renderPartEditableCheckbox('del_date', index, part),
            renderPartCheckbox('del_authorized_persons_checked', part.del_authorized_persons_content, index, part),
            renderPartSwitch('del_communication_checked', DEL_COMMUNICATION_CONTENT, index, part),
            part.del_communication_checked && (
              <div className="pl-3">
                {[
                  renderPartCheckbox(
                    'del_communication_personal_checked',
                    part.del_communication_personal_content,
                    index,
                    part
                  ),
                  renderPartCheckbox(
                    'del_communication_phone_checked',
                    part.del_communication_phone_content,
                    index,
                    part
                  ),
                  renderPartCheckbox(
                    'del_communication_email_checked',
                    part.del_communication_email_content,
                    index,
                    part
                  ),
                  renderPartEditableCheckbox('del_communication_other', index, part)
                ]}
              </div>
            ),
            renderPartCheckbox('del_arrangements_checked', part.del_arrangements_content, index, part),
            renderPartEditableCheckbox('del_executor', index, part),
            renderPartCheckbox('del_contractor_checked', part.del_contractor_content, index, part),
            renderPartEditableCheckbox('del_max_time', index, part),
            renderPartCheckbox('del_confirmation_checked', part.del_confirmation_content, index, part),
            renderPartEditableCheckbox('del_rejection', index, part),
            renderPartCheckbox('del_rejection_delay_checked', part.del_rejection_delay_content, index, part),
            renderPartCheckbox('del_rejection_costs_checked', part.del_rejection_costs_content, index, part),
            renderPartCheckbox('del_unload_checked', part.del_unload_content, index, part),
            renderPartEditableCheckbox('del_new_date', index, part),
            renderPartEditableCheckbox('del_description', index, part),
            renderPartCheckbox('del_transport_checked', part.del_transport_content, index, part),
            renderPartCheckbox('del_responsibility_checked', part.del_responsibility_content, index, part),
            renderPartCheckbox('del_guarantee_checked', part.del_guarantee_content, index, part),
            renderPartEditableCheckbox('representation', index, part),
            renderPartEditableCheckbox('representation_executor', index, part)
          ]}
        </FormGroup>
      );

      if (isMultiple) {
        return (
          <Accordion
            key={`renderPartAccordion-${partObj.id}`}
            isInvalid={hasError(errors, `receipt_requirements.${index}`)}
            accordionContentStyle={{ padding: '1rem' }}
            entity={{
              title: `dla ${partObj.getName(index, 'zadania')}`,
              content: body
            }}
          />
        );
      }

      return body;
    });

    return (
      <>
        <Label>
          Zamawiający ustala poniższe wymagania w zakresie odbioru, warunków i miejsca spełnienia świadczenia:
        </Label>
        {content}
      </>
    );
  };

  const renderSingleParts = () => {
    const isMultiple = few_parts && !del_same_receipt_requirements;

    let label = getSinglePartsLabel(same_subject_for_parts, isMultiple);

    const content = (isMultiple ? parts_ids : [null]).map((partObj, index) => {
      const part = isMultiple ? receipt_requirements[index] : receipt_requirements[0];

      const body = (
        <FormGroup key={`single_parts_${index}`}>
          {[
            renderPartEditableCheckbox('del_date', index, part),
            renderPartEditableCheckbox('del_receipt_rules', index, part),
            renderPartEditableCheckbox('del_documents', index, part),
            renderPartCheckbox('del_documents_check_checked', part.del_documents_check_content, index, part),
            renderPartCheckbox('del_defects_checked', part.del_defects_content, index, part),
            renderPartCheckbox('del_risk_checked', part.del_risk_content, index, part),
            renderPartCheckbox('del_transport_checked', part.del_transport_content, index, part),
            renderPartCheckbox('del_responsibility_checked', part.del_responsibility_content, index, part),
            renderPartCheckbox('del_guarantee_checked', part.del_guarantee_content, index, part),
            renderPartEditableCheckbox('representation', index, part),
            renderPartEditableCheckbox('representation_executor', index, part)
          ]}
        </FormGroup>
      );

      if (isMultiple) {
        return (
          <Accordion
            key={`renderPartAccordion-${partObj.id}`}
            isInvalid={hasError(errors, `receipt_requirements.${index}`)}
            accordionContentStyle={{ padding: '1rem' }}
            entity={{
              title: `dla ${partObj.getName(index, 'zadania')}`,
              content: body
            }}
          />
        );
      }

      return body;
    });

    return (
      <>
        <Label>{label}</Label>
        {content}
      </>
    );
  };

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        <Label>Zamawiający przewiduje:</Label>
        <CustomInput
          key="cyclical"
          id="cyclical"
          label="1) cykliczne dostawy określonych produktów/towarów według zapotrzebowania zmawiającego"
          type="radio"
          required
          checked={stepValues.del_periodicity === DEL_PERIODICITY_CYCLICAL}
          onChange={() => {
            stepValues['del_periodicity'] = DEL_PERIODICITY_CYCLICAL;
            samePartsChange(!few_parts || same_subject_for_parts);
            delDateContentChange();
          }}
          invalid={hasError(errors, 'del_periodicity')}
        />
        <CustomInput
          key="single"
          id="single"
          label="2) dostarczenie jednego Przedmiotu dostawy/kilku Przedmiotów dostaw w ramach kilku zadań"
          type="radio"
          required
          checked={stepValues.del_periodicity === DEL_PERIODICITY_SINGLE}
          onChange={() => {
            stepValues['del_periodicity'] = DEL_PERIODICITY_SINGLE;
            setStepValues((values) => mapState(values));
            delDateContentChange();
          }}
          invalid={hasError(errors, 'del_periodicity')}
        />
        {hasError(errors, 'del_periodicity') && (
          <FormFeedback className="d-block">{getError(errors, 'del_periodicity')}</FormFeedback>
        )}
      </FormGroup>
      {stepValues.del_periodicity === DEL_PERIODICITY_SINGLE && !same_subject_for_parts && (
        <FormGroup>
          <Label>Zamawiający przewiduje:</Label>
          <CustomInput
            key="del_same_receipt_requirements_true"
            id="del_same_receipt_requirements_true"
            label="1) identyczne wymagania w zakresie odbioru, warunków i miejsca spełnienia świadczenia dla każdej części Umowy/wszystkich zadań"
            type="radio"
            required
            checked={stepValues.del_same_receipt_requirements}
            onChange={() => samePartsChange(true)}
          />
          <CustomInput
            key="del_same_receipt_requirements_false"
            id="del_same_receipt_requirements_false"
            label="2) różne wymagania w zakresie odbioru, warunków i miejsca spełnienia świadczenia dla każdej części Umowy/ wszystkich zadań"
            type="radio"
            required
            checked={!stepValues.del_same_receipt_requirements}
            onChange={() => samePartsChange(false)}
          />
          {hasError(errors, 'del_same_receipt_requirements') && (
            <FormFeedback className="d-block">{getError(errors, 'del_same_receipt_requirements')}</FormFeedback>
          )}
        </FormGroup>
      )}
      {stepValues.del_periodicity &&
        (stepValues.del_periodicity === DEL_PERIODICITY_CYCLICAL ? renderCyclicalParts() : renderSingleParts())}
      {renderCustomCheckboxes()}
    </AgreementFormWrapper>
  );
};

export default AgreementStepReceipt;
