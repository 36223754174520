import React from 'react';
import { getFieldForPreview, PREVIEW_PLACEHOLDER } from 'modules/Agreements/helper/agreement';
import classNames from 'classnames';

export interface PreviewFieldCheckboxProps {
  step: any;
  modifiedStep: any;
  checkedKey: string;
  contentKey: string;
  // eslint-disable-next-line react/require-default-props
  className?: string;
}

const PreviewFieldCheckbox = (props: PreviewFieldCheckboxProps): JSX.Element => {
  const { step, modifiedStep, checkedKey, contentKey, className } = props;
  const { value: checked, modified: checkedModified } = getFieldForPreview(step, modifiedStep, checkedKey);
  const { value: content, modified: contentModified } = getFieldForPreview(step, modifiedStep, contentKey);

  const formattedContent = content?.replaceAll('....', `<span style='background-color:#d4d4d4'>....</span>`);

  if (checked) {
    return (
      <p
        key={`${checkedKey}-${contentKey}`}
        className={classNames('wysiwyg-preview', className, { modified: checkedModified || contentModified })}
        dangerouslySetInnerHTML={{ __html: String(formattedContent || PREVIEW_PLACEHOLDER) }}
      />
    );
  }

  return null;
};

export default PreviewFieldCheckbox;
