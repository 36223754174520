import React, { useEffect, useRef, useState } from 'react';
import {
  AGREEMENT_TYPE_OUTSIDE_ORDER,
  AGREEMENT_TYPE_SUPPLEMENT,
  AgreementCheckboxes,
  AgreementStepProps
} from 'modules/Agreements/type';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_EXECUTOR_DUTIES } from 'modules/Agreements/step';
import { FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import useStepFields from 'modules/Agreements/hook/useStepFields';

export interface AgreementStepExecutorDutiesValues {
  recommendations_possibility: boolean;
  recommendations_executor_checked: boolean;
  recommendations_executor_content: string | null;
  recommendations_rules_checked: boolean;
  recommendations_rules_content: string | null;
  recommendations_law_checked: boolean;
  recommendations_law_content: string | null;
  recommendations_norms_checked: boolean;
  recommendations_norms_content: string | null;
  recommendations_other_checked: boolean;
  recommendations_other_content: string | null;
  proper_execution_possibility: boolean;
  proper_execution_agreement_checked: boolean;
  proper_execution_agreement_content: string | null;
  proper_execution_project_checked: boolean;
  proper_execution_project_content: string | null;
  proper_execution_specification_checked: boolean;
  proper_execution_specification_content: string | null;
  proper_execution_knowledge_checked: boolean;
  proper_execution_knowledge_content: string | null;
  proper_execution_conditions_checked: boolean;
  proper_execution_conditions_content: string | null;
  proper_execution_norms_checked: boolean;
  proper_execution_norms_content: string | null;
  proper_execution_other_checked: boolean;
  proper_execution_other_content: string | null;
  preparatory_works_checked: boolean;
  preparatory_works_content: string | null;
  schedule_checked: boolean;
  schedule_content: string | null;
  estimate_checked: boolean;
  estimate_content: string | null;
  energy_cost_checked: boolean;
  energy_cost_content: string | null;
  waste_checked: boolean;
  waste_content: string | null;
  area_securing_checked: boolean;
  area_securing_content: string | null;
  area_order_checked: boolean;
  area_order_content: string | null;
  contracting_authority_checked: boolean;
  contracting_authority_content: string | null;
  property_drive_checked: boolean;
  property_drive_content: string | null;
  subexecutors_works_checked: boolean;
  subexecutors_works_content: string | null;
  log_building_checked: boolean;
  log_building_content: string | null;
  technical_equipment_checked: boolean;
  technical_equipment_content: string | null;
  securing_benefits_checked: boolean;
  securing_benefits_content: string | null;
  square_liquidation_checked: boolean;
  square_liquidation_content: string | null;
  responsibility_road_checked: boolean;
  responsibility_road_content: string | null;
  responsibility_noise_checked: boolean;
  responsibility_noise_content: string | null;
  shortcomings_checked: boolean;
  shortcomings_content: string | null;
  no_realization_checked: boolean;
  no_realization_content: string | null;
  checkboxes: AgreementCheckboxes | null;
}

const RECOMMENDATIONS_EXECUTOR_CONTENT = 'zaleceniami zamawiającego';
const RECOMMENDATIONS_RULES_CONTENT = 'zasadami aktualnej wiedzy technicznej';
const RECOMMENDATIONS_LAW_CONTENT = 'obowiązującymi w tym zakresie przepisami';
const RECOMMENDATIONS_NORMS_CONTENT = 'Polskimi Normami';
const RECOMMENDATIONS_OTHER_CONTENT = 'inne';
const PROPER_EXECUTION_AGREEMENT_CONTENT = 'treścią niniejszej Umowy';
const PROPER_EXECUTION_PROJECT_CONTENT = 'Dokumentacją Projektową';
const PROPER_EXECUTION_SPECIFICATION_CONTENT = 'Specyfikacją Warunków Zamówienia';
const PROPER_EXECUTION_KNOWLEDGE_CONTENT = 'zasadami wiedzy technicznej';
const PROPER_EXECUTION_CONDITIONS_CONTENT = 'warunkami wykonania i odbiorów';
const PROPER_EXECUTION_NORMS_CONTENT = 'z aktualnie obowiązującymi normami';
const PROPER_EXECUTION_OTHER_CONTENT = 'inne';
const PREPARATORY_WORKS_CONTENT = 'Wykonanie wszelkich niezbędnych robót przygotowawczych';
const SCHEDULE_CONTENT =
  'Wykonanie i przedłożenie do akceptacji zamawiającego przed przystąpieniem do prac budowlanych w terminie do ........ od daty podpisania Umowy szczegółowego Harmonogramu rzeczowo - terminowo – finansowego, obejmującego harmonogram robót oraz terminy uzyskania pozwoleń, opracowany przez wykonawcę i zatwierdzony przez zamawiającego. W szczególności, przedmiotowy Harmonogram musi uwzględniać zasady płatności części wynagrodzenia. Przedmiotowy Harmonogram stanowi jako załącznik integralną część Umowy';
const ESTIMATE_CONTENT =
  'Dostarczenie Kosztorysu, który uwzględnia szczegółowe koszty inwestycji w ramach kwoty zaoferowanej przez Wykonawcę w Ofercie wraz z Przedmiarem robót, które jako załączniki, stanowią integralną część Umowy, najpóźniej w terminie ........ dni od daty zawarcia Umowy';
const ENERGY_COST_CONTENT =
  'Zapewnienie na własny koszt energii elektrycznej i wody oraz innych mediów na czas budowy, a jeśli będzie korzystał z mediów zamawiającego, ma obowiązek zapewnienia własnych  urządzeń pomiarowych i regularnego ponoszenia kosztów, na podstawie not obciążeniowych lub faktur wystawianych przez dostawcę mediów';
const WASTE_CONTENT =
  'Wykonawca jest wytwórcą odpadów w rozumieniu przepisów ustawy o odpadach. Wykonawca w trakcie realizacji Przedmiotu Umowy ma obowiązek poddania odpadów odzyskowi, a jeżeli z przyczyn technologicznych jest to niemożliwe lub nieuzasadnione z przyczyn ekologicznych lub ekonomicznych, to wykonawca zobowiązany jest do przekazania powstałych odpadów do utylizacji. Wykonawca zobowiązany jest do udokumentowania sposobu zagospodarowania odpadów oraz utylizacji, jako warunek dokonania odbioru ostatecznego';
const AREA_SECURING_CONTENT =
  'Odpowiednie zabezpieczenie terenu budowy wraz z bezpośrednim sąsiedztwem prowadzonych prac';
const AREA_ORDER_CONTENT = 'Zapewnienie należytego porządku na terenie budowy';
const CONTRACTING_AUTHORITY_CONTENT =
  'Współpraca ze  wskazanymi przez zamawiającego służbami zamawiającego, wyznaczonymi do pełnienia zastępstwa inwestycyjnego, jeśli zamawiający takie powołał.';
const PROPERTY_DRIVE_CONTENT = 'Zorganizowanie robót w sposób pozwalający na dojazd do posesji';
const SUBEXECUTORS_WORKS_CONTENT = 'Koordynacja prac realizowanych przez podwykonawców';
const LOG_BUILDING_CONTENT =
  'Prowadzenie Dziennika Budowy i udostępnianie go zamawiającemu i służbom zamawiającego wyznaczonymi do pełnienia zastępstwa inwestycyjnego, jeśli zamawiający takie powołał, celem dokonywania wpisów i potwierdzeń oraz weryfikacji.';
const TECHNICAL_EQUIPMENT_CONTENT =
  'Zapewnienie sprzętu technicznego spełniającego wymagania norm technicznych,  SWZ, Dokumentacji Projektowej oraz Umowy';
const SECURING_BENEFITS_CONTENT =
  'Zabezpieczenie wszelkich wykonanych świadczeń aż do odbioru, także w czasie ewentualnej przerwy w pracach, które leżą po stronie wykonawcy. Wykonawca zobowiązany jest chronić przed uszkodzeniem i kradzieżą wykonane roboty, wyposażenie i urządzenia. Dotyczy to również zabezpieczenia ich przed szkodliwym wpływem warunków atmosferycznych';
const SQUARE_LIQUIDATION_CONTENT =
  'Likwidacja placu budowy i zaplecza własnego wykonawcy po zakończeniu prac,  ponoszenie odpowiedzialności wobec zamawiającego i osób trzecich za szkody, szczególnie wynikłe z zaniechania, niedbalstwa, działania niezgodnego ze sztuką budowlaną, przepisami p.poż. swoich pracowników, a także nieprawidłowego zabezpieczenia narzędzi, materiałów oraz miejsca prowadzenia robót';
const RESPONSIBILITY_ROAD_CONTENT =
  'Ponoszenie odpowiedzialności wobec zamawiającego i osób trzecich za szkody powstałe na skutek korzystania przez wykonawcę oraz jego podwykonawców z dróg publicznych i dróg wewnętrznych w związku z realizacją Umowy';
const RESPONSIBILITY_NOISE_CONTENT =
  'Ponoszenie odpowiedzialności wobec zamawiającego i osób trzecich za szkody powstałe na skutek emisji hałasu, wywołanej prowadzonymi robotami';
const SHORTCOMINGS_CONTENT =
  'W przypadku uchybienia przez wykonawcę któremukolwiek obowiązkowi określonemu w tym paragrafie, zamawiający ma prawo zawrzeć niezbędne umowy na koszt i ryzyko wykonawcy, co nie zwalnia wykonawcy z obowiązku zapłaty kar umownych';
const NO_REALIZATION_CONTENT =
  'W przypadku braku realizacji lub nienależytej realizacji obowiązków wykonawcy ujętych w Umowie oraz w przypadku braku realizacji lub nienależytej realizacji robót budowlanych, po uprzednim wezwaniu wykonawcy do wykonania lub należytego wykonania Umowy oraz wyznaczenia dodatkowego co najmniej ........ terminu, zamawiający uprawniony jest do zlecenia wykonania tych prac, lub obowiązków wykonawcy ujętych w Umowie osobie trzeciej na koszt i ryzyko wykonawcy';

const initContentValues = {
  recommendations_executor_content: RECOMMENDATIONS_EXECUTOR_CONTENT,
  recommendations_rules_content: RECOMMENDATIONS_RULES_CONTENT,
  recommendations_law_content: RECOMMENDATIONS_LAW_CONTENT,
  recommendations_norms_content: RECOMMENDATIONS_NORMS_CONTENT,
  recommendations_other_content: RECOMMENDATIONS_OTHER_CONTENT,
  proper_execution_agreement_content: PROPER_EXECUTION_AGREEMENT_CONTENT,
  proper_execution_project_content: PROPER_EXECUTION_PROJECT_CONTENT,
  proper_execution_specification_content: PROPER_EXECUTION_SPECIFICATION_CONTENT,
  proper_execution_knowledge_content: PROPER_EXECUTION_KNOWLEDGE_CONTENT,
  proper_execution_conditions_content: PROPER_EXECUTION_CONDITIONS_CONTENT,
  proper_execution_norms_content: PROPER_EXECUTION_NORMS_CONTENT,
  proper_execution_other_content: PROPER_EXECUTION_OTHER_CONTENT,
  preparatory_works_content: PREPARATORY_WORKS_CONTENT,
  schedule_content: SCHEDULE_CONTENT,
  estimate_content: ESTIMATE_CONTENT,
  energy_cost_content: ENERGY_COST_CONTENT,
  waste_content: WASTE_CONTENT,
  area_securing_content: AREA_SECURING_CONTENT,
  area_order_content: AREA_ORDER_CONTENT,
  contracting_authority_content: CONTRACTING_AUTHORITY_CONTENT,
  property_drive_content: PROPERTY_DRIVE_CONTENT,
  subexecutors_works_content: SUBEXECUTORS_WORKS_CONTENT,
  log_building_content: LOG_BUILDING_CONTENT,
  technical_equipment_content: TECHNICAL_EQUIPMENT_CONTENT,
  securing_benefits_content: SECURING_BENEFITS_CONTENT,
  square_liquidation_content: SQUARE_LIQUIDATION_CONTENT,
  responsibility_road_content: RESPONSIBILITY_ROAD_CONTENT,
  responsibility_noise_content: RESPONSIBILITY_NOISE_CONTENT,
  shortcomings_content: SHORTCOMINGS_CONTENT,
  no_realization_content: NO_REALIZATION_CONTENT
};

const mapState = (
  step: AgreementStepExecutorDutiesValues,
  defaultValueStatus: boolean
): AgreementStepExecutorDutiesValues => {
  const {
    recommendations_possibility,
    recommendations_executor_checked,
    recommendations_rules_checked,
    recommendations_law_checked,
    recommendations_executor_content,
    recommendations_rules_content,
    recommendations_law_content,
    recommendations_norms_content,
    recommendations_other_content,
    proper_execution_possibility,
    proper_execution_agreement_checked,
    proper_execution_project_checked,
    proper_execution_specification_checked,
    proper_execution_knowledge_checked,
    proper_execution_agreement_content,
    proper_execution_project_content,
    proper_execution_specification_content,
    proper_execution_knowledge_content,
    proper_execution_conditions_content,
    proper_execution_norms_content,
    proper_execution_other_content,
    preparatory_works_content,
    schedule_content,
    estimate_content,
    energy_cost_content,
    waste_content,
    area_securing_content,
    area_order_content,
    contracting_authority_content,
    property_drive_content,
    subexecutors_works_content,
    log_building_content,
    technical_equipment_content,
    securing_benefits_content,
    square_liquidation_content,
    responsibility_road_content,
    responsibility_noise_content,
    shortcomings_content,
    no_realization_content,
    checkboxes,
    ...rest
  } = step;

  return {
    ...rest,
    recommendations_possibility: true,
    recommendations_executor_checked: true,
    recommendations_rules_checked: true,
    recommendations_law_checked: true,
    recommendations_executor_content: recommendations_executor_content || RECOMMENDATIONS_EXECUTOR_CONTENT,
    recommendations_rules_content: recommendations_rules_content || RECOMMENDATIONS_RULES_CONTENT,
    recommendations_law_content: recommendations_law_content || RECOMMENDATIONS_LAW_CONTENT,
    recommendations_norms_content: recommendations_norms_content || RECOMMENDATIONS_NORMS_CONTENT,
    recommendations_other_content: recommendations_other_content || RECOMMENDATIONS_OTHER_CONTENT,
    proper_execution_possibility: Boolean(proper_execution_possibility || defaultValueStatus),
    proper_execution_agreement_checked: Boolean(proper_execution_agreement_checked || defaultValueStatus),
    proper_execution_project_checked: Boolean(proper_execution_project_checked || defaultValueStatus),
    proper_execution_specification_checked: Boolean(proper_execution_specification_checked || defaultValueStatus),
    proper_execution_knowledge_checked: Boolean(proper_execution_knowledge_checked || defaultValueStatus),
    proper_execution_agreement_content: proper_execution_agreement_content || PROPER_EXECUTION_AGREEMENT_CONTENT,
    proper_execution_project_content: proper_execution_project_content || PROPER_EXECUTION_PROJECT_CONTENT,
    proper_execution_specification_content:
      proper_execution_specification_content || PROPER_EXECUTION_SPECIFICATION_CONTENT,
    proper_execution_knowledge_content: proper_execution_knowledge_content || PROPER_EXECUTION_KNOWLEDGE_CONTENT,
    proper_execution_conditions_content: proper_execution_conditions_content || PROPER_EXECUTION_CONDITIONS_CONTENT,
    proper_execution_norms_content: proper_execution_norms_content || PROPER_EXECUTION_NORMS_CONTENT,
    proper_execution_other_content: proper_execution_other_content || PROPER_EXECUTION_OTHER_CONTENT,
    preparatory_works_content: preparatory_works_content || PREPARATORY_WORKS_CONTENT,
    schedule_content: schedule_content || SCHEDULE_CONTENT,
    estimate_content: estimate_content || ESTIMATE_CONTENT,
    energy_cost_content: energy_cost_content || ENERGY_COST_CONTENT,
    waste_content: waste_content || WASTE_CONTENT,
    area_securing_content: area_securing_content || AREA_SECURING_CONTENT,
    area_order_content: area_order_content || AREA_ORDER_CONTENT,
    contracting_authority_content: contracting_authority_content || CONTRACTING_AUTHORITY_CONTENT,
    property_drive_content: property_drive_content || PROPERTY_DRIVE_CONTENT,
    subexecutors_works_content: subexecutors_works_content || SUBEXECUTORS_WORKS_CONTENT,
    log_building_content: log_building_content || LOG_BUILDING_CONTENT,
    technical_equipment_content: technical_equipment_content || TECHNICAL_EQUIPMENT_CONTENT,
    securing_benefits_content: securing_benefits_content || SECURING_BENEFITS_CONTENT,
    square_liquidation_content: square_liquidation_content || SQUARE_LIQUIDATION_CONTENT,
    responsibility_road_content: responsibility_road_content || RESPONSIBILITY_ROAD_CONTENT,
    responsibility_noise_content: responsibility_noise_content || RESPONSIBILITY_NOISE_CONTENT,
    shortcomings_content: shortcomings_content || SHORTCOMINGS_CONTENT,
    no_realization_content: no_realization_content || NO_REALIZATION_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepExecutorDuties = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, onSubmit, onChange, errors } = props;
  const agreementFromPzp = agreement.type !== AGREEMENT_TYPE_OUTSIDE_ORDER;

  const initState = useRef<AgreementStepExecutorDutiesValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_EXECUTOR_DUTIES), agreementFromPzp)
  );

  const [stepValues, setStepValues] = useState<AgreementStepExecutorDutiesValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_EXECUTOR_DUTIES), agreementFromPzp);
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderCheckbox, renderEditableCheckbox, renderSwitch, renderContent, renderCustomCheckboxes } = useStepFields(
    {
      stepValues,
      setStepValues,
      mapState: (values) => mapState(values, agreementFromPzp),
      errors,
      initContentValues
    }
  );

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        {renderCheckbox('recommendations_possibility', 'Wykonanie Przedmiotu Umowy zgodnie z:', true)}
        <div className="pl-4">
          {stepValues.recommendations_possibility && [
            renderCheckbox('recommendations_executor_checked', stepValues.recommendations_executor_content, true),
            renderCheckbox('recommendations_rules_checked', stepValues.recommendations_rules_content, true),
            renderCheckbox('recommendations_law_checked', stepValues.recommendations_law_content, true),
            renderCheckbox(
              'recommendations_norms_checked',
              stepValues.recommendations_norms_content,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT
            ),
            renderEditableCheckbox(
              'recommendations_other',
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT && null,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT
            )
          ]}
        </div>
        {renderCheckbox(
          'proper_execution_possibility',
          'Prawidłowe wykonanie wszystkich prac związanych z realizacją Przedmiotu Umowy, zgodnie z:',
          agreementFromPzp || agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        <div className="pl-4">
          {stepValues.proper_execution_possibility && [
            renderCheckbox(
              'proper_execution_agreement_checked',
              stepValues.proper_execution_agreement_content,
              agreementFromPzp || agreement.type === AGREEMENT_TYPE_SUPPLEMENT
            ),
            renderCheckbox(
              'proper_execution_project_checked',
              stepValues.proper_execution_project_content,
              agreementFromPzp || agreement.type === AGREEMENT_TYPE_SUPPLEMENT
            ),
            renderCheckbox(
              'proper_execution_specification_checked',
              stepValues.proper_execution_specification_content,
              agreementFromPzp || agreement.type === AGREEMENT_TYPE_SUPPLEMENT
            ),
            renderCheckbox(
              'proper_execution_knowledge_checked',
              stepValues.proper_execution_knowledge_content,
              agreementFromPzp || agreement.type === AGREEMENT_TYPE_SUPPLEMENT
            ),
            renderCheckbox(
              'proper_execution_conditions_checked',
              stepValues.proper_execution_conditions_content,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT
            ),
            renderCheckbox(
              'proper_execution_norms_checked',
              stepValues.proper_execution_norms_content,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT
            ),
            renderEditableCheckbox(
              'proper_execution_other',
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT && null,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT
            )
          ]}
        </div>
        {renderEditableCheckbox(
          'preparatory_works',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT && null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderSwitch(
          'schedule_checked',
          'Czy zamawiający wymaga aby wykonawca opracował i przedłożył harmonogram?',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {stepValues.schedule_checked && renderContent('schedule_content')}
        {renderSwitch(
          'estimate_checked',
          'Czy zamawiający wymaga aby wykonawca opracował i przedłożył kosztorys i przedmiar?',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {stepValues.estimate_checked && renderContent('estimate_content')}
        {renderEditableCheckbox(
          'energy_cost',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT && null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'waste',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT && null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'area_securing',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT && null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'area_order',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT && null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'contracting_authority',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT && null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'property_drive',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT && null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'subexecutors_works',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT && null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'log_building',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT && null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'technical_equipment',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT && null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'securing_benefits',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT && null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'square_liquidation',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT && null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'responsibility_road',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT && null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'responsibility_noise',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT && null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'shortcomings',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT && null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'no_realization',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT && null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderCustomCheckboxes(
          null,
          null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepExecutorDuties;
