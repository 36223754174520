import {
  JudgementCategoryListAction,
  SET_JUDGEMENT_CATEGORY_LIST_PARAMS,
  JUDGEMENT_CATEGORY_LIST
} from 'modules/Judgement/action/CategoryList';
import { createJudgementElement } from 'modules/Judgement/model/JudgementElement';
import { initJudgementCategoryListState, JudgementCategoryListState } from 'modules/Judgement/state/categoryList';

const initState = initJudgementCategoryListState();

const reducer = (
  state: JudgementCategoryListState = initState,
  action: JudgementCategoryListAction
): JudgementCategoryListState => {
  switch (action.type) {
    case SET_JUDGEMENT_CATEGORY_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case JUDGEMENT_CATEGORY_LIST: {
      const { list = [], meta } = action.payload;

      return { ...state, fetching: false, list: list.map(createJudgementElement), meta };
    }

    default:
      return state;
  }
};

export default reducer;
