import { suffixLabel } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import _uniqueId from 'lodash/uniqueId';
import { Props } from 'modules/Proceedings/component/Fieldset/Main';

const ProceedingsFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, setValues, placeholder = false, required } = props;

  const { announcement_link = '' } = values;

  return (
    <>
      <FormGroup>
        {!placeholder && <Label for="input-announcement_link">{suffixLabel('Strona postępowania', required)}</Label>}
        <Input
          type="text"
          name="announcement_link"
          id="input-announcement_link"
          placeholder={placeholder ? 'Strona postępowania' : null}
          value={announcement_link}
          onChange={(event) => setValues({ ...values, announcement_link: event.target.value })}
          invalid={hasError(errors, 'announcement_link')}
          required={required}
        />
        {hasError(errors, 'announcement_link') && <FormFeedback>{getError(errors, 'announcement_link')}</FormFeedback>}
      </FormGroup>
    </>
  );
};

export default ProceedingsFieldset;
