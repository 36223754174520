import React from 'react';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementStepSubexecutorsValues } from 'modules/Agreements/components/Steps/Default/Subexecutors';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_SUBEXECUTORS } from 'modules/Agreements/step';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import classNames from 'classnames';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';

const AgreementStepPreviewSubexecutors = (
  props: AgreementStepPreviewProps<AgreementStepSubexecutorsValues>
): JSX.Element => {
  const { steps, modifiedStep } = props;
  const subexecutors = getStepValues<AgreementStepSubexecutorsValues>(steps, AGREEMENT_STEP_SUBEXECUTORS);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={subexecutors}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const { value: serSubexecutorsAllowedChecked, modified: serSubexecutorsAllowedCheckedModified } = getFieldForPreview(
    subexecutors,
    modifiedStep,
    'ser_subexecutors_allowed_checked'
  );

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    subexecutors,
    modifiedStep,
    `checkboxes.general`
  );

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_SUBEXECUTORS)}</div>
      {serSubexecutorsAllowedChecked === false && (
        <p
          className={classNames('preview-point', {
            modified: serSubexecutorsAllowedCheckedModified
          })}
        >
          Wykonawca zobowiązuje się do osobistego świadczenia usług z wyłączeniem korzystania z podwykonawców
        </p>
      )}
      {serSubexecutorsAllowedChecked && [
        <p
          className={classNames('preview-point', {
            modified: serSubexecutorsAllowedCheckedModified
          })}
        >
          Wykonawca uprawniony jest do świadczenia usług z wykorzystaniem podwykonawców
        </p>,
        renderCheckbox('ser_coordination'),
        renderCheckbox('quality'),
        renderCheckbox('decision')
      ]}
      {generalCheckboxes &&
        generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
          if (!checkbox.checked) return null;

          return (
            <p
              className={classNames('preview-point wysiwyg-preview', {
                modified: generalCheckboxesModified
              })}
              key={index}
              dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
            />
          );
        })}
    </div>
  );
};

export default AgreementStepPreviewSubexecutors;
