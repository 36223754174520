import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import { ComplaintDataItem } from 'modules/ExecutorOrder/model/Order';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';

export interface Props {
  complaintItem?: ComplaintDataItem;
}

const ComplaintProblem: React.FC<Props> = ({ complaintItem = {} }) => {
  const fields = [
    {
      label: 'Przyczyny złożenia pozwu',
      value: complaintItem.violation_description ?? 'nie dotyczy',
      html: true
    },
    {
      label: 'Data złożenia pozwu',
      value: ifContainsDateFormat(complaintItem.violation_date, formatDate) ?? 'nie dotyczy'
    },
    {
      label: 'Sygnatura sprawy sądowej',
      value: complaintItem.complaint_identifier ?? 'nie dotyczy'
    },
    {
      label: 'Informacje o wyniku postępowania sądowego',
      value: complaintItem.complaint_result ?? 'nie dotyczy',
      html: true
    }
  ];

  return <InformationList fields={fields} />;
};

export default ComplaintProblem;
