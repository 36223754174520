import React, { useEffect, useState } from 'react';
import { RootState } from 'app/reducer';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import { PageProps, PasswordValues } from 'modules/Layout/type';
import { breadcrumbRouteProfileBaseUpdate } from 'modules/Profile/breadcrumbs';
import { useDispatch, useSelector } from 'react-redux';
import PasswordForm from 'modules/Profile/component/PasswordForm';
import { ColorVariants, Message, ValidationErrors } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { updatePasswordProfile } from 'modules/Profile/repository';
import { updateProfileToastError, updateProfileToastSuccess } from 'modules/Profile/toasts';
import { push } from 'connected-react-router';
import { ROUTE_PROFILE } from 'modules/Profile/routes';
import CardBox from 'modules/Layout/component/CardBox';

const PasswordUpdate = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const [updating, setUpdating] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>({});

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  const cancelToken = useCancelToken();
  useEffect(() => {
    managePage({
      title: 'Profil - edycja hasła',
      breadcrumb: breadcrumbRouteProfileBaseUpdate()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePassword = async (values: PasswordValues) => {
    setUpdating(true);
    try {
      await updatePasswordProfile(values, cancelToken);

      setMessage({
        value: 'data.message',
        variant: ColorVariants.Primary
      });

      dispatch(addToastAction(updateProfileToastSuccess()));
      dispatch(push(ROUTE_PROFILE));
    } catch (error) {
      setUpdating(false);
      setErrors(error?.error?.response?.data?.errors || {});
      setMessage({
        value: error?.error?.response?.data?.message || error?.message || 'Something went wrong.',
        variant: ColorVariants.Danger
      });
      dispatch(addToastAction(updateProfileToastError()));
    }
  };

  return (
    <div className="profile-update-view d-flex justify-content-center">
      <CardBox form="small" heading="Zmiana hasła">
        {updating && <Loader />}
        <Alert message={message} />
        {user && <PasswordForm errors={errors} submit={updatePassword} disabled={updating} />}
      </CardBox>
    </div>
  );
};

export default PasswordUpdate;
