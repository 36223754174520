import _get from 'lodash/get';
import _ from 'lodash';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps } from 'modules/Shared/type';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAgreementsListParamsAction } from 'modules/Agreements/action/list';
import useFilterValues from 'modules/Agreements/container/AgreementsList/Filter/FilterValues/useFilterValues';
import useSaveFilter from 'modules/Shared/helper/hooks/useSaveFilter';
import { AGREEMENTS_SLUG_FILTER } from 'modules/Layout/model/Filters';
import { createSearchingProps } from 'modules/Shared/helper/params';
import Agreements from 'modules/Agreements/model/Agreements';
import SaveFilterModal from 'modules/Layout/component/ListFilter/SaveFilterModal';

const AgreementsFilter = () => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.agreements.list);
  const setParams = (payload: PartialSearchingProps) => dispatch(setAgreementsListParamsAction(payload));
  const searchProps = createSearchingProps(
    '',
    Agreements.getFilterableAttributes(),
    Agreements.getSortableAttributes()
  );

  const { inputs } = useFilterValues({ values: filter });

  const {
    saveModal,
    setSaveModal,
    cleanMessage,
    loading,
    message,
    filtersList,
    saveFilter,
    onDeleteFilterClick,
    onFilterSelectedClick,
    filterList1stFetchFinished
  } = useSaveFilter(filter, setParams, AGREEMENTS_SLUG_FILTER);

  useEffect(() => {
    if (filterList1stFetchFinished) {
      const findDefault = filtersList.find((el) => el.default);
      if (findDefault) {
        onFilterSelectedClick(findDefault.id);
      } else {
        const localStorageParams = localStorage.getItem('localFilterParamsagreements');

        if (localStorageParams) {
          const parsedParams = JSON.parse(localStorageParams);

          setParams({ ...parsedParams });
        } else {
          setParams({ ...searchProps });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterList1stFetchFinished]);

  let disableBtn = false;

  if (_.isEmpty(filter)) {
    disableBtn = true;
  }

  const setMessage = (value: boolean) => {
    if (message) cleanMessage();
    setSaveModal(value);
  };

  return (
    <div className="position-relative">
      <ListingFilter
        inputs={inputs}
        filter={filter}
        filtersName={'agreements'}
        pagination={pagination}
        setParams={setParams}
        saveFilterBtn={() => setMessage(true)}
        disableBtn={disableBtn}
        filterObj={{ filtersList, onDeleteFilterClick, onFilterSelectedClick, loading }}
      />
      {saveModal && (
        <SaveFilterModal
          onSaveClick={saveFilter}
          isOpen={saveModal}
          toggle={() => setMessage(false)}
          message={message}
          loading={loading}
          displayDefaultCheckbox
          shouldRefetchList
        />
      )}
    </div>
  );
};

export default AgreementsFilter;
