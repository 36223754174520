import React, { useEffect } from 'react';
import { managePageAction } from 'modules/Layout/action';
import { PageProps } from 'modules/Layout/type';
import { useDispatch } from 'react-redux';
import { breadcrumbFeedback } from 'modules/Feedback/breadcrumbs';
import CardBox from 'modules/Layout/component/CardBox';
import { ROUTE_FEEDBACK, ROUTE_PUBLIC_FEEDBACK } from 'modules/Feedback/routes';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router';

type Props = {
  isPublic?: boolean;
};

const ThanksGiving: React.FC<Props> = ({ isPublic }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  useEffect(() => {
    managePage({
      title: 'Feedback',
      breadcrumb: [breadcrumbFeedback()]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="profile-update-view row justify-content-center">
      <div className="col-md-8 col-lg-6">
        <CardBox paddingRight="30px" paddingBottom="25px" paddingTop="0">
          <div className="d-flex flex-column justify-content-center">
            <div className="font-poppins font-20 text-primary text-center mb-4">
              Dziękujemy za przesłanie zgłoszenia. Nasz zespół zajmie się nim tak szybko jak to tylko możliwe.
            </div>
            <Button
              color="primary"
              onClick={() => history.push(isPublic ? ROUTE_PUBLIC_FEEDBACK : ROUTE_FEEDBACK)}
              className="pzpeu-btn-disabled waves-effect waves-light mx-auto"
            >
              Powrót do formularza
            </Button>
          </div>
        </CardBox>
      </div>
    </div>
  );
};

export default ThanksGiving;
