import React from 'react';
import classNames from 'classnames';
import { AGREEMENT_TYPE_OUTSIDE_ORDER, AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_SUBJECT } from 'modules/Agreements/step';
import { AgreementStepSubjectValues } from 'modules/Agreements/components/Steps/Default/Subject';
import PreviewFieldContent from 'modules/Agreements/components/PreviewField/Content';

const AgreementStepPreviewSubject = (props: AgreementStepPreviewProps<AgreementStepSubjectValues>): JSX.Element => {
  const { agreement, steps, modifiedStep } = props;
  const subjects = getStepValues<AgreementStepSubjectValues>(steps, AGREEMENT_STEP_SUBJECT);

  const { value: parts } = getFieldForPreview<AgreementStepSubjectValues['subjects']>(
    subjects,
    modifiedStep,
    'subjects'
  );
  const { value: sameParts } = getFieldForPreview<AgreementStepSubjectValues['same_subject_for_parts']>(
    subjects,
    modifiedStep,
    'same_subject_for_parts'
  );

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    subjects,
    modifiedStep,
    `checkboxes.general`
  );

  const isMultiple = agreement.few_parts && !sameParts;

  const renderContent = (contentKey: string, className = 'preview-point') => {
    return (
      <PreviewFieldContent step={subjects} modifiedStep={modifiedStep} contentKey={contentKey} className={className} />
    );
  };

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_SUBJECT)}</div>
      <div className="reset-preview-point">
        {parts?.map((part, index: number) => {
          const { value: personalDutyChecked } = getFieldForPreview(
            subjects,
            modifiedStep,
            `subjects.${index}.personal_duty_checked`
          );

          const partObj = agreement.parts_ids.find((item) => item.id === part.part_id);

          return (
            <div key={index} className="agreement-step-preview-content reset-preview-point">
              {isMultiple && partObj && <div className="h5">{partObj.getName(index)}:</div>}
              {renderContent(`subjects.${index}.agreement_subject`)}
              {agreement.type === AGREEMENT_TYPE_OUTSIDE_ORDER &&
                (personalDutyChecked ? (
                  renderContent(`subjects.${index}.personal_duty`)
                ) : (
                  <p key={`subjects.${index}.personal_duty`} className={classNames('preview-point')}>
                    Zamawiający nie przewiduje obowiązku osobistego wykonania przez wykonawcę kluczowych zadań (zgodnie
                    z art. 60 p.z.p. i art. 120 p.z.p.)
                  </p>
                ))}
              {renderContent(`subjects.${index}.agreement_subject_details`)}
              {generalCheckboxes &&
                generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
                  if (!checkbox.checked) return null;

                  if (checkbox.part_id != part.part_id) return null;

                  return (
                    <p
                      className={classNames('preview-point wysiwyg-preview', {
                        modified: generalCheckboxesModified
                      })}
                      key={index}
                      dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                    />
                  );
                })}
            </div>
          );
        })}
        {isMultiple && generalCheckboxes && (
          <div className="agreement-step-preview-content">
            <p className="h5 reset-preview-point">Ponadto dla każdego z zadań:</p>
            {generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
              if (!checkbox.checked) return null;

              if (checkbox.part_id) return null;

              return (
                <p
                  className={classNames('preview-point wysiwyg-preview', {
                    modified: generalCheckboxesModified
                  })}
                  key={index}
                  dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AgreementStepPreviewSubject;
