import Table from 'modules/Layout/component/Table';
import React from 'react';
import { BaseModuleAccessHistory } from 'modules/Module/model/ModuleAccessHistory';
import sharedCols from 'modules/Module/container/Details/sharedDetailsTableCol';
import { RootState } from 'app/reducer';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

type Props = {
  subscriptions: BaseModuleAccessHistory[];
};

const ModulesAccessHistoryAbonamentTable: React.FC<Props> = ({ subscriptions }) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { id: user_id } = useParams<{ id: string }>();

  return <Table cols={sharedCols(user, user_id)} rows={subscriptions} />;
};

export default ModulesAccessHistoryAbonamentTable;
