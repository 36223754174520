import { ForeignProceedingsListState, initForeignProceedingsListState } from '../../state/foreignProceedingsList';
import {
  FOREIGN_PROCEEDINGS_CLEAR_LIST,
  FOREIGN_PROCEEDINGS_LIST,
  ForeignProceedingsListAction,
  SET_FOREIGN_PROCEEDINGS_LIST_PARAMS
} from '../../action/ForeignProceedingsList';
import { createForeignProceedings } from '../../model/ForeignProceedings';

const initState = initForeignProceedingsListState();

const reducer = (
  state: ForeignProceedingsListState = initState,
  action: ForeignProceedingsListAction
): ForeignProceedingsListState => {
  switch (action.type) {
    case SET_FOREIGN_PROCEEDINGS_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case FOREIGN_PROCEEDINGS_LIST: {
      const { list = [], meta } = action.payload;

      return { ...state, fetching: false, list: list.map(createForeignProceedings), meta };
    }
    case FOREIGN_PROCEEDINGS_CLEAR_LIST: {
      return { ...state, fetching: false, list: [] };
    }

    default:
      return state;
  }
};

export default reducer;
