import React from 'react';
import { RootState } from 'app/reducer';
import ListingPerPage from 'modules/Layout/component/Listing/PerPage';
import { PartialSearchingProps } from 'modules/Shared/type';
import { useDispatch, useSelector } from 'react-redux';
import { setInvoiceListParamsAction } from 'modules/User/action/listInvoiceRequired';

const InvoicesRequiredPerPage = () => {
  const dispatch = useDispatch();
  const { meta } = useSelector((state: RootState) => state.user.invoiceList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setInvoiceListParamsAction(payload));

  return <ListingPerPage meta={meta} setParams={setParams} />;
};

export default InvoicesRequiredPerPage;
