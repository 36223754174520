import { CLAUSE_DETAILS } from 'modules/Clause/permissions';
import { ROUTE_CLIENT_CLAUSES, ROUTE_CLIENT_CLAUSE } from 'modules/ClauseClient/routes';
import { BreadcrumbItem } from 'modules/Layout/type';
import { getPathUrl } from 'modules/Shared/helper/api';

export const breadcrumbRouteClientClauseList = (): BreadcrumbItem[] => [
  {
    title: 'Rejestr klauzul niedozwolonych – wyszukiwanie',
    link: ROUTE_CLIENT_CLAUSES
  }
];

export const breadcrumbRouteClientClauseDetails = (name: string, id: number): BreadcrumbItem[] => [
  ...breadcrumbRouteClientClauseList(),
  {
    title: `Szczegóły klauzuli - ${name}`,
    link: getPathUrl(ROUTE_CLIENT_CLAUSE, { id }),
    permissions: [CLAUSE_DETAILS]
  }
];

export const breadcrumbRouteClauseJudgementContent = (
  identifier: string,
  name: string,
  id: number
): BreadcrumbItem[] => [
  ...breadcrumbRouteClientClauseDetails(name, id),
  {
    title: `Treść orzeczenia - ${identifier}`
  }
];
