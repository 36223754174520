import React, { useEffect, useState } from 'react';
import { Message, PaginationMeta, SearchParams } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import Listing from 'modules/Layout/component/Listing';
import User from 'modules/User/model/User';
import CardBox from 'modules/Layout/component/CardBox';
import { PaymentHistoryValues } from 'modules/Profile/type';
import PaymentLogTable from 'modules/Profile/container/Table';
import Alert from 'modules/Layout/component/Alert';
import PerPageTable from 'modules/Layout/component/Pagination/WithProps/PerPage';
import PaginationTable from 'modules/Layout/component/Pagination/WithProps';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { fetchPaymentLogs } from 'modules/Profile/repository';
import Authorize from '../../../Auth/container/Authorize';
import { SUBSCRIPTIONS_MODULE_ACCESS } from '../../../Auth/container/Guard/permissions';

export interface Props {
  user?: User;
  adminUrl?: boolean;
}

const DetailsPaymentLogs: React.FC<Props> = ({ user, adminUrl }) => {
  const [paymentLogs, setPaymentLogs] = useState<PaymentHistoryValues[]>([]);
  const [meta, setMeta] = useState<PaginationMeta>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);

  const cancelToken = useCancelToken();
  const fetchPayment = async (payload?: SearchParams) => {
    setLoading(true);
    const {
      data,
      cancelled,
      message: messageResponse
    } = await fetchRequest(fetchPaymentLogs, user?.id, payload, cancelToken);

    if (cancelled) return;
    if (data) {
      setPaymentLogs(data.data);
      setMeta(data.meta);
    }
    if (messageResponse) setMessage(messageResponse);
    setLoading(false);
  };

  useEffect(() => {
    if (user) {
      fetchPayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Authorize permissions={[SUBSCRIPTIONS_MODULE_ACCESS]}>
      <CardBox heading="Historia transakcji" paddingBottom="0" paddingLeft="30px" paddingRight="30px">
        <div className="row users-view">
          <div className="col-12">
            <Alert message={message} />
            <Listing
              table={<PaymentLogTable paymentLog={paymentLogs} user={adminUrl ? user : null} />}
              pagination={<PaginationTable fetchList={fetchPayment} meta={meta} />}
              perPage={<PerPageTable fetchList={fetchPayment} meta={meta} />}
              loading={loading}
            />
          </div>
        </div>
      </CardBox>
    </Authorize>
  );
};

export default DetailsPaymentLogs;
