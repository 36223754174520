import React from 'react';
import { calculateDiffInDays } from 'modules/Shared/helper/utils';
import { DashboardAdminExecutor } from 'modules/Layout/type';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_EXECUTOR_ADMINS, ROUTE_EXECUTOR_ADMINS_UPDATE } from 'modules/Executor/routes';
import { EXECUTORS_EDIT } from 'modules/Executor/permissions';
import Authorize from 'modules/Auth/container/Authorize';
import { Link } from 'react-router-dom';
import InformationListCollapsed from '../InformationList/Collapsed';

type Props = { executor: DashboardAdminExecutor };

const LastNeededUpdateExecutors: React.FC<Props> = ({ executor }) => {
  const daysLate = executor?.created_at ? calculateDiffInDays(executor.created_at) : null;

  const daysCorrectForm = () => {
    const roundedDays = Math.ceil(daysLate);

    switch (roundedDays) {
      case 0:
        return 'dzisiaj';
      case 1:
        return (
          <>
            <span className="highlighted">1</span> dzień temu
          </>
        );
      default:
        return (
          <>
            <span className="highlighted">{roundedDays}</span> dni temu
          </>
        );
    }
  };

  return (
    <div className="last-needed-update-executor-row">
      <InformationListCollapsed
        title={
          <Link className="pzpeu-link" to={getPathUrl(ROUTE_EXECUTOR_ADMINS, { id: executor?.id })}>
            {executor?.nip}
          </Link>
        }
        fields={[
          <>
            Liczba klientów próbująca uzyskać dane: <span className="highlighted">{executor?.usages}</span>
          </>,
          <>Utworzono: {daysCorrectForm()}</>,
          <Authorize key="edit" permissions={[EXECUTORS_EDIT]}>
            <Link className="link-with-border" to={getPathUrl(ROUTE_EXECUTOR_ADMINS_UPDATE, { id: executor?.id })}>
              EDYCJA DANYCH URZĘDÓW
            </Link>
          </Authorize>
        ]}
      />
    </div>
  );
};

export default LastNeededUpdateExecutors;
