import { RootState } from 'app/reducer';
import { setSearchingParamsAction, SetSearchingParamsAction } from 'modules/Shared/action';
import Validation from 'modules/Shared/exception/Validation';
import { createSearchParams } from 'modules/Shared/helper/params';
import { PaginationMeta, PartialSearchingProps } from 'modules/Shared/type';
import { Action, Dispatch } from 'redux';
import _ from 'lodash';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { fetchClauseCategories } from 'modules/Clause/repository';

export const SET_CLAUSE_CATEGORY_LIST_PARAMS = 'CLAUSE/CATEGORY/LIST/PARAMS/SET';
export const CLAUSE_CATEGORY_LIST = 'CLAUSE/CATEGORY/LIST';

export type ClauseCategoryListAction = SetClauseCategoryListParamsAction | ClauseCategoryListFetchedAction;

export type SetClauseCategoryListParamsAction = SetSearchingParamsAction<typeof SET_CLAUSE_CATEGORY_LIST_PARAMS>;

export type ClauseCategoryListFetchedPayload = {
  list: JudgementElementEntity[];
  meta: PaginationMeta;
};
export interface ClauseCategoryListFetchedAction extends Action<typeof CLAUSE_CATEGORY_LIST> {
  payload: ClauseCategoryListFetchedPayload;
}
export const setClauseCategoryListParamsAction =
  (payload: PartialSearchingProps) => async (dispatch: Dispatch, getState: () => RootState) => {
    const currentState = getState()?.clause?.categoryList;

    if (
      payload?.filter &&
      !payload?.pagination &&
      typeof currentState?.pagination?.page === 'number' &&
      currentState.pagination.page !== 1 &&
      !_.isEqual(currentState.filter, payload.filter)
    ) {
      currentState.pagination.page = 1;
    }

    const state = { ...currentState, ...payload };

    dispatch(setSearchingParamsAction(SET_CLAUSE_CATEGORY_LIST_PARAMS, payload));
    try {
      const { pagination, filter, sort } = state;

      const searchParams = createSearchParams({ pagination, filter, sort });

      const response = await fetchClauseCategories(searchParams);

      const { data, meta } = response.data;

      dispatch<ClauseCategoryListFetchedAction>({
        type: CLAUSE_CATEGORY_LIST,
        payload: { list: data, meta }
      });
    } catch (error) {
      if (error instanceof Validation) {
        dispatch<ClauseCategoryListFetchedAction>({
          type: CLAUSE_CATEGORY_LIST,
          payload: { list: [], meta: null }
        });
      } else {
        throw error;
      }
    }
  };
