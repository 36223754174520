import { suffixLabel, suffixValue } from 'modules/Layout/helper/misc';
import { OfficeFormValues } from 'modules/Office/type';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React, { useState } from 'react';
import { Button, CustomInput, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { fetchOfficeFromRegon } from '../../../repository';
import _isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import { addToastAction } from '../../../../Layout/action';
import { officeFromRegonToastError, officeFromRegonToastSuccess } from '../../../toasts';
import DisplayPopovers from '../../../../Layout/component/Popover/DisplayWrapper';

export interface Props {
  values: OfficeFormValues;
  errors?: ValidationErrors;
  disabled?: boolean;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, key: keyof OfficeFormValues) => void;
  placeholder?: boolean;
  officeFromRegon: Record<any, any>;
  setOfficeFromRegon: (data: Record<any, any>) => void;
  setMultipleValues: (data: Record<any, any>) => void;
}

const OfficeFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const {
    values,
    errors,
    disabled,
    onChange,
    placeholder = false,
    required,
    setOfficeFromRegon,
    officeFromRegon,
    setMultipleValues
  } = props;
  const [fillByHandStatus, setFillByHandStatus] = useState<boolean>(!!values.name);

  const {
    name = '',
    email = '',
    phone = '',
    regon = '',
    city = '',
    street = '',
    postal_code = '',
    post_office = '',
    can_send_email = false
  } = values;

  const fetchOfficce = async (): Promise<any> => {
    // eslint-disable-next-line no-useless-catch
    try {
      const {
        data: { data }
      } = await fetchOfficeFromRegon(values.regon);

      if (_isEmpty(data)) {
        dispatch(addToastAction(officeFromRegonToastError()));
        return null;
      }

      dispatch(addToastAction(officeFromRegonToastSuccess()));

      setMultipleValues(data[0]);
      setOfficeFromRegon(data[0]);
    } catch (error) {
      throw error;
    }
  };

  const onCheckBoxCheckedChange = () => {
    const value = can_send_email;
    if (value !== undefined) {
      const event = { target: { value: !value } };

      onChange(event as any, 'can_send_email');
    }
  };

  return (
    <fieldset className="m-0" disabled={disabled}>
      <FormGroup>
        {!placeholder && <Label for="input-nip">REGON</Label>}
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column w-100">
            <Input
              type={'text'}
              name="regon"
              id="input-regon"
              placeholder={placeholder ? 'REGON' : null}
              value={regon}
              onChange={(event) => onChange(event, 'regon')}
              invalid={hasError(errors, 'regon')}
              className="mr-1"
            />
            {hasError(errors, 'regon') && <FormFeedback>{getError(errors, 'regon')}</FormFeedback>}
          </div>
          <DisplayPopovers
            popoverId="popover_regon"
            popoverContent="Dane są automatycznie pobierane z rejestru GUS"
            className="tooltip-light"
          />
        </div>
      </FormGroup>
      <div className="w-100">
        {!fillByHandStatus && officeFromRegon === null && (
          <Button
            type="button"
            color="primary"
            className="pzpeu-btn-disabled waves-effect waves-light w-100"
            disabled={!values.regon}
            onClick={fetchOfficce}
          >
            Wyszukaj
          </Button>
        )}
        {officeFromRegon === null && (
          <FormGroup>
            <CustomInput
              id="fill_by_hand"
              type="checkbox"
              name="fill_by_hand"
              label="Wprowadź dane ręcznie."
              className="mt-4"
              checked={fillByHandStatus}
              onChange={(event) => setFillByHandStatus(event.target.checked)}
            />
          </FormGroup>
        )}
      </div>
      {(fillByHandStatus || officeFromRegon) && [
        <FormGroup>
          {!placeholder && <Label for="input-name">{suffixLabel('Nazwa', required)}</Label>}
          <Input
            type="text"
            name="name"
            id="input-name"
            placeholder={placeholder ? suffixValue('Nazwa', required) : null}
            value={name}
            onChange={(event) => onChange(event, 'name')}
            invalid={hasError(errors, 'name')}
            maxLength={200}
            required={required}
          />
          {hasError(errors, 'name') && <FormFeedback>{getError(errors, 'name')}</FormFeedback>}
        </FormGroup>,
        <FormGroup>
          {!placeholder && <Label for="input-email">{suffixLabel('Adres email', required)}</Label>}
          <Input
            type="email"
            name="email"
            id="input-email"
            placeholder={placeholder ? suffixValue('Adres email', required) : null}
            value={email}
            onChange={(event) => onChange(event, 'email')}
            invalid={hasError(errors, 'email')}
            required={required}
          />
          {hasError(errors, 'email') && <FormFeedback>{getError(errors, 'email')}</FormFeedback>}
        </FormGroup>,
        <FormGroup>
          {!placeholder && <Label for="input-phone">Telefon</Label>}
          <Input
            type="text"
            name="phone"
            id="input-phone"
            placeholder={placeholder ? 'Telefon' : null}
            value={phone}
            onChange={(event) => onChange(event, 'phone')}
            invalid={hasError(errors, 'phone')}
          />
          {hasError(errors, 'phone') && <FormFeedback>{getError(errors, 'phone')}</FormFeedback>}
        </FormGroup>,
        <FormGroup>
          {!placeholder && <Label for="input-city">Miasto</Label>}
          <Input
            type="text"
            name="city"
            id="input-city"
            placeholder={placeholder ? 'Miasto' : null}
            value={city}
            onChange={(event) => onChange(event, 'city')}
            invalid={hasError(errors, 'city')}
          />
          {hasError(errors, 'city') && <FormFeedback>{getError(errors, 'city')}</FormFeedback>}
        </FormGroup>,
        <FormGroup>
          {!placeholder && <Label for="input-street">Ulica</Label>}
          <Input
            type="text"
            name="street"
            id="input-street"
            placeholder={placeholder ? 'Ulica' : null}
            value={street}
            onChange={(event) => onChange(event, 'street')}
            invalid={hasError(errors, 'street')}
          />
          {hasError(errors, 'street') && <FormFeedback>{getError(errors, 'street')}</FormFeedback>}
        </FormGroup>,
        <FormGroup>
          {!placeholder && <Label for="input-postal_code">Kod pocztowy</Label>}
          <Input
            type="text"
            name="postal_code"
            id="input-postal_code"
            placeholder={placeholder ? 'Kod pocztowy' : null}
            value={postal_code}
            onChange={(event) => onChange(event, 'postal_code')}
            invalid={hasError(errors, 'postal_code')}
          />
          {hasError(errors, 'postal_code') && <FormFeedback>{getError(errors, 'postal_code')}</FormFeedback>}
        </FormGroup>,
        <FormGroup>
          {!placeholder && <Label for="input-post_office">Miejscowość poczty</Label>}
          <Input
            type="text"
            name="post_office"
            id="input-post_office"
            placeholder={placeholder ? 'Miejscowość poczty' : null}
            value={post_office}
            onChange={(event) => onChange(event, 'post_office')}
            invalid={hasError(errors, 'post_office')}
          />
          {hasError(errors, 'post_office') && <FormFeedback>{getError(errors, 'post_office')}</FormFeedback>}
        </FormGroup>,
        <FormGroup>
          <div className="d-flex flex-wrap align-items-center">
            <CustomInput
              type="checkbox"
              id="input-executor_requests"
              checked={can_send_email ?? false}
              label="Wysyłanie zapytań"
              onChange={() => onCheckBoxCheckedChange()}
            />
          </div>
        </FormGroup>
      ]}
    </fieldset>
  );
};

export default OfficeFieldset;
