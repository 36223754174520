import { managePageAction } from 'modules/Layout/action';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { breadcrumbSubscriptions } from 'modules/Subscriptions/breadcrumbs';
import { fetchSubscriptionsAction, subscriptionsSelectedAction } from 'modules/Subscriptions/action';
import { RootState } from 'app/reducer';
import Loader from 'modules/Layout/component/Loader';
import Subscriptions from 'modules/Subscriptions/component/Subscriptions';
import Alert from 'modules/Layout/component/Alert';
import { useLocation } from 'react-router-dom';
import TooltipWithBtn from 'modules/Layout/component/Tooltip/WithButton';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_SUBSCRIPTION_SUMMARY } from 'modules/Subscriptions/routes';

const SubscriptionsView = () => {
  const dispatch = useDispatch();
  const { subscriptions, fetching, errorMessage } = useSelector((state: RootState) => state.subscriptions.list);
  const { subscriptions_id } = useSelector((state: RootState) => state.subscriptions.selected);

  const { state } = useLocation<{ doNotClearData: boolean }>();

  useEffect(() => {
    dispatch(
      managePageAction({
        title: 'Zakup subskrypcji',
        breadcrumb: breadcrumbSubscriptions()
      })
    );

    if (!state?.doNotClearData && subscriptions.length) {
      dispatch(subscriptionsSelectedAction([]));
    }

    dispatch(fetchSubscriptionsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (fetching) return <Loader />;

  const onSummaryClick = () => {
    if (subscriptions_id.length) dispatch(push(getPathUrl(ROUTE_SUBSCRIPTION_SUMMARY)));
  };

  return (
    <div className="buy-subscription-view">
      {errorMessage && <Alert message={errorMessage} />}
      <Subscriptions subscriptionsList={subscriptions} />
      <div className="d-flex justify-content-center">
        <TooltipWithBtn
          targetId="SubscriptionBtn"
          onClick={onSummaryClick}
          color="primary"
          wrapperClassName="d-flex justify-content-center pzpeu-btn-flex"
          className="waves-effect waves-light pzpeu-btn-flex"
          btnContent="Przejdź do płatności"
          tooltipContent="Wybierz produkt"
          disabled={!subscriptions_id.length}
        />
      </div>
    </div>
  );
};

export default SubscriptionsView;
