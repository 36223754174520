import React from 'react';
import InformationList, { InformationField } from 'modules/Layout/component/InformationList';
import { formatDate, ifContainsDateFormat } from '../../../../Shared/helper/utils';
import { ExecutorExtendedRequest } from '../../../../Executor/model/Violation';

export interface Props {
  executorExtendedRequest: ExecutorExtendedRequest;
}

const ExecutorExtendedRequestBasicDataset: React.FC<Props> = ({ executorExtendedRequest }) => {
  const fields: InformationField[] = [
    {
      label: 'Nazwa organu',
      value: executorExtendedRequest?.name
    },
    {
      label: 'Kategoria organu',
      value: executorExtendedRequest?.category.name
    },
    {
      label: 'Status odpowiedzi',
      value: executorExtendedRequest?.status.name
    },
    {
      label: 'Kanał dostarczenia odpowiedzi',
      value: executorExtendedRequest?.channel.name
    },
    {
      label: 'Treść otrzymania odpowiedzi',
      value: executorExtendedRequest?.answer_content
    },
    {
      label: 'Data wysłania wniosku',
      value: ifContainsDateFormat(executorExtendedRequest?.sent_at, formatDate)
    },
    {
      label: 'Data otrzymania odpowiedzi',
      value: ifContainsDateFormat(executorExtendedRequest?.received_at, formatDate)
    }
  ];

  return <InformationList fields={fields} />;
};

export default ExecutorExtendedRequestBasicDataset;
