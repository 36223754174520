import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function ClientIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 23.663 23.663">
      <g id="Group_374" data-name="Group 374" transform="translate(-1247.168 -336.188)">
        <path
          id="Path_1278"
          data-name="Path 1278"
          d="M1259,336.188a11.832,11.832,0,1,1-11.831,11.832A11.845,11.845,0,0,1,1259,336.188Zm0,22.663a10.832,10.832,0,1,0-10.831-10.831A10.844,10.844,0,0,0,1259,358.851Z"
          fill={fill}
        />
        <path
          id="Path_1279"
          data-name="Path 1279"
          d="M1266.539,356.971a.5.5,0,0,1-.192-.039,35.846,35.846,0,0,1-3.422-1.6c-1.524-.841-1.836-1.326-1.938-1.654a.5.5,0,0,1-.023-.148v-.993a.5.5,0,0,1,.142-.35,5.763,5.763,0,0,0,1.357-2.491.5.5,0,0,1,.195-.275,1.108,1.108,0,0,0,.21-1.608.5.5,0,0,1-.112-.315v-2.224c0-2.2-1.279-3.366-3.7-3.366-2.387,0-3.7,1.2-3.7,3.366V347.5a.5.5,0,0,1-.112.315,1.111,1.111,0,0,0,.21,1.608.5.5,0,0,1,.193.274,5.769,5.769,0,0,0,1.359,2.491.5.5,0,0,1,.143.35v.993a.5.5,0,0,1-.023.149c-.181.578-.945,1.409-5.242,3.2a.5.5,0,0,1-.384-.923,33.952,33.952,0,0,0,3.274-1.525,3.988,3.988,0,0,0,1.375-1v-.69a6.809,6.809,0,0,1-1.418-2.609,2.115,2.115,0,0,1-.72-1.318,2.09,2.09,0,0,1,.345-1.473v-2.06c0-2.734,1.758-4.366,4.7-4.366,2.987,0,4.7,1.591,4.7,4.366v2.059a2.109,2.109,0,0,1-.376,2.793,6.8,6.8,0,0,1-1.416,2.609v.688c.118.179.821.944,4.767,2.587a.5.5,0,0,1-.192.962Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
