import axios, { CancelTokenSource } from 'axios';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { fetchJudgmentKeywords } from 'modules/Judgement/repository';
import { fetchJudgementKeywordsToastError } from 'modules/Judgement/toasts';
import { addToastAction } from 'modules/Layout/action';
import AutocompleteWithChips from 'modules/Layout/component/Autocomplete/WithChips';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { createSearchParams } from 'modules/Shared/helper/params';
import renameDuplicateEntries from 'modules/Shared/helper/renameDuplicateEntries';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import './index.scss';

type Props = {
  keywords: number[];
  onKeywordsChange: (keywordsValue: number[]) => void;
};

// eslint-disable-next-line react/display-name
const JudgementKeywordsInputAutocomplete: React.FC<Props> = React.memo(({ keywords, onKeywordsChange }) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [keywordsList, setKeywordsList] = useState<JudgementElementEntity[]>([]);
  // const [initKeywordsList, setInitKeywordsList] = useState<JudgementElementEntity[]>([]);
  const initKeywordsList = useRef([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const keywordsListWithoutDuplicates = React.useMemo(
    () => renameDuplicateEntries(keywordsList),
    [keywordsList.length]
  );

  const dispatchJudgementKeywordsToastError = (messageValue: string) =>
    dispatch(addToastAction(fetchJudgementKeywordsToastError(messageValue)));

  const cancelToken: React.MutableRefObject<CancelTokenSource> = useRef(null);

  const fetchKeywordsList = async (name?: string) => {
    if (cancelToken.current) {
      cancelToken.current.cancel('Operation canceled due to new request.');
    }
    cancelToken.current = axios.CancelToken.source();

    const filter = name ? { name } : {};
    //  w związku z taskiem PZP2UE-1177 zmienia się endpoint dla słów kluczowych i nie ma potrzeby dodawania query per_page: 9999
    // const searchParams = createSearchParams({ pagination: { per_page: 9999 }, filter });
    const searchParams = createSearchParams({ filter });

    setFetching(true);

    const { data, cancelled, message } = await fetchRequest(
      fetchJudgmentKeywords,
      searchParams,
      cancelToken.current.token
    );

    if (cancelled) return;
    if (data) {
      if (!initKeywordsList.current.length) initKeywordsList.current = renameDuplicateEntries(data.data);
      setKeywordsList(data.data);
      setFetching(false);
    }
    if (message && message.value !== 'Operation canceled due to new request.') {
      if (fetching) setFetching(false);
      dispatchJudgementKeywordsToastError(message.value);
    }
  };

  useEffect(() => {
    fetchKeywordsList();

    return () => {
      if (cancelToken.current) {
        cancelToken.current.cancel('AxiosCancel');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeValue = (name: string) => {
    const findKeyword = keywordsListWithoutDuplicates.find((keyword) => keyword.name === name);
    if (findKeyword) {
      onKeywordsChange([...keywords, findKeyword.id]);
    }
  };

  const clearList = () => {
    if (cancelToken.current) {
      cancelToken.current.cancel('Operation canceled due to new request.');
    }
  };

  const convertKeywords = initKeywordsList.current.length
    ? keywords.map((keywordNum) => {
        const findKeyword = initKeywordsList.current.find((keywordValue) => keywordValue.id === keywordNum);

        if (findKeyword) {
          return { id: findKeyword.id, content: findKeyword.name };
        }

        return { id: keywordNum, content: '' };
      })
    : [];

  const onDeleteClicked = (id: number) => {
    onKeywordsChange(keywords.filter((keywordNum) => keywordNum !== id));
  };

  const filterCategoryList = keywordsListWithoutDuplicates.filter((el) => !keywords.includes(el.id));

  return (
    <div className="judgement-keywords-autocomplete">
      <AutocompleteWithChips
        currentValue=""
        onChangeCurrentValue={onChangeValue}
        searchRequest={fetchKeywordsList}
        fetching={fetching}
        clearList={clearList}
        list={filterCategoryList as never}
        label="Słowa kluczowe"
        onDeleteChipElement={onDeleteClicked}
        chipContentElements={convertKeywords}
        alwaysClearInput
      />
    </div>
  );
});

export default JudgementKeywordsInputAutocomplete;

/*

/\/\/\/\/\/\/\ old working version if above doesn't work /\/\/\/\/\/\/\

import Autocomplete from 'modules/Layout/component/Autocomplete';
import SavedFiltersList from 'modules/Layout/component/ListFilter/SavedFiltersList';

  const savedKeywordsValue: React.MutableRefObject<{ name: string; id: number }[]> = useRef([]);

  const onChangeValue = (name: string) => {
    const findKeyword = keywordsListWithoutDuplicates.find((keyword) => keyword.name === name);
    if (findKeyword) {
      onKeywordsChange([...keywords, findKeyword.id]);
      savedKeywordsValue.current.push({ id: findKeyword.id, name });
    }
  };

    const onDeleteClicked = (id: number) => {
    onKeywordsChange(keywords.filter((keywordNum) => keywordNum !== id));
    savedKeywordsValue.current.filter((keywordValue) => keywordValue.id !== id);
  };

  const convertKeywords = keywords.map((keywordNum) =>
    savedKeywordsValue.current.find((keywordValue) => keywordValue.id === keywordNum)
  );

  return (
    <div className="judgement-keywords-autocomplete">
      <Autocomplete
        currentValue=""
        onChangeCurrentValue={onChangeValue}
        searchRequest={fetchKeywordsList}
        fetching={fetching}
        clearList={clearList}
        list={filterCategoryList as never}
        label="Słowa kluczowe"
        alwaysClearInput
      />
      {!!keywords.length && (
        <SavedFiltersList
          filtersList={convertKeywords}
          onDeleteClick={onDeleteClicked}
          btnTitle="Usuń słowo kluczowe"
        />
      )}
    </div>
  );

*/
