import listReducer from 'modules/Subscriptions/reducer/list';
import selectedReducer from 'modules/Subscriptions/reducer/selected';
import paymentsReducer from 'modules/Subscriptions/reducer/payments';
import traditionalPaymentReducer from 'modules/Subscriptions/reducer/traditionalPayments';
import { combineReducers, Reducer } from 'redux';
import { SubscriptionState } from 'modules/Subscriptions/state';

const reducer = (): Reducer<SubscriptionState> => {
  const reducers = {
    list: listReducer,
    selected: selectedReducer,
    payment: paymentsReducer,
    traditionalPayment: traditionalPaymentReducer
  };

  return combineReducers<SubscriptionState>(reducers);
};

export default reducer;
