import { RootState } from 'app/reducer';
import { setSearchingParamsAction, SetSearchingParamsAction } from 'modules/Shared/action';
import Validation from 'modules/Shared/exception/Validation';
import { createSearchParams } from 'modules/Shared/helper/params';
import { PaginationMeta, PartialSearchingProps } from 'modules/Shared/type';
import { Action, Dispatch } from 'redux';
import _ from 'lodash';
import { fetchAlerts } from '../../repository';
import { AlertList } from '../../model/Alert';

export const SET_FOREIGN_PROCEEDINGS_ALERTS_LIST_PARAMS = 'FOREIGN/PROCEEDINGS/ALERTS/LIST/PARAMS/SET';
export const FOREIGN_PROCEEDINGS_ALERTS_LIST = 'FOREIGN/PROCEEDINGS/ALERTS/LIST';
export const FOREIGN_PROCEEDINGS_CLEAR_ALERTS_LIST = 'FOREIGN/PROCEEDINGS/CLEAR/ALERTS/LIST';

export type ForeignProceedingsAlertsListAction =
  | SetForeignProceedingsAlertsListParamsAction
  | ForeignProceedingsAlertsListFetchedAction
  | ClearForeignProceedingsAlertsListAction;

export type SetForeignProceedingsAlertsListParamsAction = SetSearchingParamsAction<
  typeof SET_FOREIGN_PROCEEDINGS_ALERTS_LIST_PARAMS
>;

export type ClearForeignProceedingsAlertsListAction = {
  type: typeof FOREIGN_PROCEEDINGS_CLEAR_ALERTS_LIST;
};

export type ForeignProceedingsAlertsListFetchedPayload = {
  list: AlertList[];
  meta: PaginationMeta;
};

export interface ForeignProceedingsAlertsListFetchedAction extends Action<typeof FOREIGN_PROCEEDINGS_ALERTS_LIST> {
  payload: ForeignProceedingsAlertsListFetchedPayload;
}

export const setForeignProceedingsAlertsListParamsAction =
  (payload: PartialSearchingProps) => async (dispatch: Dispatch, getState: () => RootState) => {
    const currentState = getState()?.foreignProceedingsAlerts?.foreignProceedingsAlertsList;

    if (
      payload?.filter &&
      !payload?.pagination &&
      typeof currentState?.pagination?.page === 'number' &&
      currentState.pagination.page !== 1 &&
      !_.isEqual(currentState.filter, payload.filter)
    ) {
      currentState.pagination.page = 1;
    }

    if (currentState?.filter?.requires_deposit === '1' && payload?.filter?.requires_deposit !== '1') {
      if (currentState?.filter?.deposit_min) delete payload.filter.deposit_min; // eslint-disable-line no-param-reassign
      if (currentState?.filter?.deposit_max) delete payload.filter.deposit_max; // eslint-disable-line no-param-reassign
    }

    const state = { ...currentState, ...payload };

    dispatch(setSearchingParamsAction(SET_FOREIGN_PROCEEDINGS_ALERTS_LIST_PARAMS, payload));
    try {
      const { pagination, filter, sort } = state;

      const searchFilter = {
        ...filter
      };

      const searchParams = createSearchParams({ pagination, filter: searchFilter, sort });

      const response = await fetchAlerts(searchParams);

      const { data, meta } = response.data;

      dispatch<ForeignProceedingsAlertsListFetchedAction>({
        type: FOREIGN_PROCEEDINGS_ALERTS_LIST,
        payload: { list: data, meta }
      });
    } catch (error) {
      if (error instanceof Validation) {
        dispatch<ForeignProceedingsAlertsListFetchedAction>({
          type: FOREIGN_PROCEEDINGS_ALERTS_LIST,
          payload: { list: [], meta: null }
        });
      } else {
        throw error;
      }
    }
  };
