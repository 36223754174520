import { addToastAction, managePageAction } from 'modules/Layout/action';
import React, { useEffect, useRef, useState } from 'react';
import Loader from 'modules/Layout/component/Loader';
import { useDispatch } from 'react-redux';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import { breadcrumbRouteJudgementKeywordIncludesMark } from 'modules/Judgement/breadcrumbs';
import { updateJudgementKeywordToastSuccess, createJudgementKeywordToastSuccess } from 'modules/Judgement/toasts';
import { addJudgmentKeywordsIncluded } from 'modules/Judgement/repository';
import { ROUTE_JUDGEMENT } from 'modules/Judgement/routes';
import { useLocation, useParams } from 'react-router';
import Judgement from 'modules/Judgement/model/Judgement';
import { ROUTE_DASHBOARD } from 'modules/Layout/routes';
import KeywordIncludedMarkForm from 'modules/Judgement/component/Form/KeywordIncludedMark';

const JudgementKeywordIncludedMark = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const { state = {} } = useLocation<{
    judgement?: Judgement;
    keyword?: number;
    prevContent?: string;
  }>();
  const { id } = useParams<{ id: string }>();
  const [refreshContent, setRefreshContent] = useState(false);
  const [locationState, setLocationState] = useState<{
    judgement?: Judgement;
    keyword?: number;
    prevContent?: string;
  }>(state);

  useEffect(() => {
    if (!locationState) {
      setLocationState(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshClicked = useRef(false);

  const cancelToken = useCancelToken();
  useEffect(() => {
    if (locationState?.judgement) {
      const { judgement } = locationState;
      dispatch(
        managePageAction({
          title: 'Zaznaczenie treści orzeczenia',
          breadcrumb: breadcrumbRouteJudgementKeywordIncludesMark(judgement.identifier, judgement.id)
        })
      );
      window.scrollTo();
    } else {
      let pushUrl = '';
      if (id) pushUrl = getPathUrl(ROUTE_JUDGEMENT, { id }, { t: 'keywords' });
      else pushUrl = ROUTE_DASHBOARD;
      dispatch(push(pushUrl));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchAddKeywordToast = () =>
    dispatch(
      addToastAction(
        locationState?.prevContent ? updateJudgementKeywordToastSuccess() : createJudgementKeywordToastSuccess()
      )
    );

  const addKeyword = async (content: string) => {
    setLoading(true);
    const { judgement, keyword: keyword_id } = locationState;
    const {
      data,
      cancelled,
      message: messageResponse
    } = await updateRequestWithData(addJudgmentKeywordsIncluded, judgement.id, { keyword_id, content }, cancelToken);

    if (cancelled) return;
    if (data) {
      dispatchAddKeywordToast();
      dispatch(push(getPathUrl(ROUTE_JUDGEMENT, { id: judgement.id }, { t: 'keywords' })));
    } else {
      if (messageResponse) setMessage(messageResponse);
      setLoading(false);
    }
  };

  if (!locationState?.judgement) return null;

  const triggerRefresh = () => {
    if (!refreshClicked.current) refreshClicked.current = true;
    setRefreshContent(!refreshContent);
  };

  return (
    <div className="user-create-view row justify-content-center">
      <div className="col-12">
        <div className="card-box position-relative">
          {loading && <Loader />}
          <Alert message={message} />
          <p className="h4 mb-2">Zaznaczenie treści orzeczenia</p>
          <KeywordIncludedMarkForm
            key={`${refreshContent}-markContentKeyword`}
            judgement={locationState?.judgement}
            submit={addKeyword}
            triggerRefresh={triggerRefresh}
            contentUpdate={!refreshClicked.current ? locationState?.prevContent : null}
            cancelUrl={getPathUrl(ROUTE_JUDGEMENT, { id: locationState?.judgement.id }, { t: 'keywords' })}
          />
        </div>
      </div>
    </div>
  );
};

export default JudgementKeywordIncludedMark;
