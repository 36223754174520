interface Config {
  fontSize: number;
  contrast: boolean;
  greyHues: boolean;
  underlineLinks: boolean;
  wordSpacing: number;
  [key: string]: any;
}

const getConfig = (): Config => {
  const config: Config = JSON.parse(localStorage.getItem('accessibility'));

  if (config) return config;

  return {
    fontSize: 0,
    contrast: false,
    greyHues: false,
    underlineLinks: false,
    wordSpacing: 0
  };
};

const setConfig = (key: string, value: number | boolean): void => {
  const config = getConfig();
  config[key] = value;

  localStorage.setItem('accessibility', JSON.stringify(config));
};

const applyAccessibility = (): void => {
  const config: Config = JSON.parse(localStorage.getItem('accessibility'));

  if (!config) return;

  setFontSize(config.fontSize);
  setContrast(config.contrast);
  setGreyHues(config.greyHues);
  setUnderlineLinks(config.underlineLinks);
  setWordSpacing(config.wordSpacing);
};

const setInitFontSize = (elements: NodeListOf<HTMLElement>) => {
  elements.forEach((el) => {
    const style = window.getComputedStyle(el, null).getPropertyValue('font-size');

    if (!el.hasAttribute('data-init-font-size')) {
      el.setAttribute('data-init-font-size', style);
    }
  });
};

const setFontSize = (value: number) => {
  const elements = document.querySelectorAll<HTMLElement>('body *');

  setInitFontSize(elements);

  elements.forEach((el) => {
    const fontSize = parseFloat(el.getAttribute('data-init-font-size'));
    el.style.setProperty('font-size', fontSize + value + 'px');
  });
};

const resetFontSize = (): void => {
  const elements = document.querySelectorAll<HTMLElement>('body *');

  elements.forEach((el) => {
    if (el.hasAttribute('data-init-font-size')) {
      const fontSize = parseFloat(el.getAttribute('data-init-font-size'));

      el.style.setProperty('font-size', fontSize + 'px');

      el.removeAttribute('data-init-font-size');
    }
  });
};

const setContrast = (status: boolean): void => {
  const html = document.querySelector('html');

  status ? html.classList.add('accessibility-contrast') : html.classList.remove('accessibility-contrast');
};

const setGreyHues = (status: boolean): void => {
  const html = document.querySelector('html');

  status ? html.classList.add('accessibility-grey-hues') : html.classList.remove('accessibility-grey-hues');
};

const setUnderlineLinks = (status: boolean): void => {
  const html = document.querySelector('html');

  status ? html.classList.add('accessibility-underline-links') : html.classList.remove('accessibility-underline-links');
};

const setWordSpacing = (value: number): void => {
  const body = document.querySelector('body');

  body.style.wordSpacing = value + 'px';
};

const increaseFontSize = (): void => {
  const value = getConfig().fontSize + 2;

  setConfig('fontSize', value);
  setFontSize(value);
};

const decreaseFontSize = (): void => {
  const fontSize = getConfig().fontSize;

  if (fontSize <= 0) return;

  const value = fontSize - 2;

  setConfig('fontSize', value);
  setFontSize(value);
};

const toggleContrast = (): void => {
  const status = getConfig().contrast;
  setConfig('greyHues', false);
  setConfig('contrast', !status);
  setGreyHues(false);
  setContrast(!status);
};

const toggleGreyHues = (): void => {
  const status = getConfig().greyHues;
  setConfig('contrast', false);
  setConfig('greyHues', !status);
  setContrast(false);
  setGreyHues(!status);
};

const toggleUnderlineLinks = (): void => {
  const status = getConfig().underlineLinks;
  setConfig('underlineLinks', !status);
  setUnderlineLinks(!status);
};

const increaseWordSpacing = (): void => {
  const value = getConfig().wordSpacing + 2;
  setConfig('wordSpacing', value);
  setWordSpacing(value);
};

const decreaseWordSpacing = (): void => {
  const fontSize = getConfig().wordSpacing;

  if (fontSize <= 0) return;

  const value = fontSize - 2;

  setConfig('wordSpacing', value);
  setWordSpacing(value);
};

const resetAll = (): void => {
  const html = document.querySelector('html');
  const body = document.querySelector('body');

  html.classList.remove('accessibility-contrast');
  html.classList.remove('accessibility-grey-hues');
  html.classList.remove('accessibility-underline-links');
  body.style.wordSpacing = 'normal';
  resetFontSize();
  localStorage.removeItem('accessibility');
};

export {
  increaseFontSize,
  decreaseFontSize,
  toggleContrast,
  toggleGreyHues,
  toggleUnderlineLinks,
  increaseWordSpacing,
  decreaseWordSpacing,
  resetAll,
  applyAccessibility
};
