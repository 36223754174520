import { Routes } from 'app/routes';
import Authorize from 'modules/Auth/container/Authorize';
import ClauseClientListView from 'modules/ClauseClient/view';
import ClauseClientDetailsView from 'modules/ClauseClient/view/Details';
import React from 'react';
import { Route } from 'react-router-dom';
import { ROLE_CLIENT, ROLE_CLIENT_BS } from 'modules/User/model/UserRole';
import ModuleGuard from 'modules/Auth/container/Guard/ModuleGuard';
import { MODULE_CLAUSE } from 'modules/Module/model/Module';
import ConnectedJudgementContent from 'modules/ClauseClient/view/Details/ConnectedJudgmentContent';

export const ROUTE_CLIENT_CLAUSES = '/clause';
export const ROUTE_CLIENT_CLAUSE = '/clause/:id(\\d+)';
export const ROUTE_CLIENT_CLAUSE_CONTENT = '/clause/:id(\\d+)/content';

export const createClauseClientRoutes = (): Routes => ({
  wrapped: [
    <Authorize
      key="clause-client-list"
      hasAccess={(user) => user?.role?.slug === ROLE_CLIENT || user?.role?.slug === ROLE_CLIENT_BS}
    >
      <Route
        path={ROUTE_CLIENT_CLAUSES}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_CLAUSE}>
            <ClauseClientListView />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize
      key="clause-client-details"
      hasAccess={(user) => user?.role?.slug === ROLE_CLIENT || user?.role?.slug === ROLE_CLIENT_BS}
    >
      <Route
        path={ROUTE_CLIENT_CLAUSE}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_CLAUSE}>
            <ClauseClientDetailsView />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize
      key="clause-client-details-content"
      hasAccess={(user) => user?.role?.slug === ROLE_CLIENT || user?.role?.slug === ROLE_CLIENT_BS}
    >
      <Route
        path={ROUTE_CLIENT_CLAUSE_CONTENT}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_CLAUSE}>
            <ConnectedJudgementContent />
          </ModuleGuard>
        )}
      />
    </Authorize>
  ]
});
