import { RootState } from 'app/reducer';
import { setSearchingParamsAction, SetSearchingParamsAction } from 'modules/Shared/action';
import Validation from 'modules/Shared/exception/Validation';
import { createSearchParams } from 'modules/Shared/helper/params';
import { PaginationMeta, PartialSearchingProps } from 'modules/Shared/type';
import { OrderEntity } from 'modules/ExecutorOrder/model/Order';
import { fetchOrders } from 'modules/ExecutorOrder/repository';
import { Action, Dispatch } from 'redux';
import _ from 'lodash';

export const SET_ORDER_LIST_PARAMS = 'ORDER/LIST/PARAMS/SET';
export const ORDER_LIST = 'ORDER/LIST';

export type OrderListAction = SetOrderListParamsAction | OrderListFetchedAction;

export type SetOrderListParamsAction = SetSearchingParamsAction<typeof SET_ORDER_LIST_PARAMS>;

export type OrderListFetchedPayload = {
  orders: OrderEntity[];
  meta: PaginationMeta;
};
export interface OrderListFetchedAction extends Action<typeof ORDER_LIST> {
  payload: OrderListFetchedPayload;
}
export const setOrderListParamsAction =
  (payload: PartialSearchingProps, executor_id: number | string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const currentState = getState()?.order?.list;

    if (
      payload?.filter &&
      !payload?.pagination &&
      typeof currentState?.pagination?.page === 'number' &&
      currentState.pagination.page !== 1 &&
      !_.isEqual(currentState.filter, payload.filter)
    ) {
      currentState.pagination.page = 1;
    }

    const state = { ...currentState, ...payload };

    dispatch(setSearchingParamsAction(SET_ORDER_LIST_PARAMS, payload));
    try {
      const { pagination, filter, sort } = state;

      const searchParams = createSearchParams({ pagination, filter, sort });

      const response = await fetchOrders(executor_id, searchParams);

      const { data, meta } = response.data;

      dispatch<OrderListFetchedAction>({
        type: ORDER_LIST,
        payload: { orders: data, meta }
      });
    } catch (error) {
      if (error instanceof Validation) {
        dispatch<OrderListFetchedAction>({
          type: ORDER_LIST,
          payload: { orders: [], meta: null }
        });
      } else {
        throw error;
      }
    }
  };
