import React, { useEffect, useState } from 'react';
import { Message } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import Listing from 'modules/Layout/component/Listing';
import CardBox from 'modules/Layout/component/CardBox';
import Alert from 'modules/Layout/component/Alert';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import {
  addJudgmentKeywordsIncluded,
  deleteJudgmentKeywordsIncluded,
  fetchJudgmentKeywordsIncluded
} from 'modules/Judgement/repository';
import Judgement from 'modules/Judgement/model/Judgement';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import KeywordsIncludedTable from 'modules/Judgement/container/JudgementDetails/KeywordsIncludedTable';
import Authorize from 'modules/Auth/container/Authorize';
import { JUDGMENT_KEYWORDS_ADD } from 'modules/Judgement/permissions';
import JudgementAddKeywordIncludedModal from 'modules/Judgement/component/Modal/JudgementAddKeywordIncluded';
import { addToastAction } from 'modules/Layout/action';
import { createJudgementKeywordToastSuccess, deleteJudgementKeywordToastSuccess } from 'modules/Judgement/toasts';
import { useDispatch } from 'react-redux';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import { Button } from 'reactstrap';
import useLocalPagination from 'modules/Shared/helper/hooks/useLocalPagination';
import InformationList from 'modules/Layout/component/InformationList';

export interface Props {
  judgment?: Judgement;
}

const KeywordListIncluded: React.FC<Props> = ({ judgment = {} }) => {
  const dispatch = useDispatch();
  const [keywordsIncluded, setKeywordsIncluded] = useState<JudgementElementEntity[]>([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [addModalLoading, setAddModalLoading] = useState(false);
  const [addModalMessage, setAddModalMessage] = useState<Message>(null);
  const [displayDeleteModal, setDisplayDeleteModal] = useState<JudgementElementEntity>(null);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [deleteModalMessage, setDeleteModalMessage] = useState<Message>(null);
  const [displayKeywordIdContent, setDisplayKeywordIdContent] = useState<number>(null);

  const { id: judgement_id } = judgment;

  const dispatchAddKeywordToast = () => dispatch(addToastAction(createJudgementKeywordToastSuccess()));
  const dispatchDeleteKeywordToast = () => dispatch(addToastAction(deleteJudgementKeywordToastSuccess()));

  const cancelToken = useCancelToken();
  const addKeyword = async (keyword_id: number) => {
    setAddModalLoading(true);
    const {
      data,
      cancelled,
      message: messageResponse
    } = await fetchRequest(addJudgmentKeywordsIncluded, judgement_id, { keyword_id }, cancelToken);

    if (cancelled) return;
    if (data) {
      dispatchAddKeywordToast();
      setKeywordsIncluded([...keywordsIncluded, data.data]);
      setDisplayAddModal(false);
    }
    if (messageResponse) setAddModalMessage(messageResponse);
    setAddModalLoading(false);
  };

  const deleteKeyword = async (keyword_id: number) => {
    setDeleteModalLoading(true);
    const {
      success,
      cancelled,
      message: messageResponse
    } = await deleteRequest(deleteJudgmentKeywordsIncluded, judgement_id, keyword_id, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteKeywordToast();
      setDisplayDeleteModal(null);
      const keywordIndex = keywordsIncluded.findIndex((el) => el.id === keyword_id);
      if (keywordIndex)
        setKeywordsIncluded([...keywordsIncluded.slice(0, keywordIndex), ...keywordsIncluded.slice(keywordIndex + 1)]);
    }
    if (messageResponse) setDeleteModalMessage(messageResponse);
    setDeleteModalLoading(false);
  };

  const fetchKeywords = async () => {
    setLoading(true);
    const {
      data,
      cancelled,
      message: messageResponse
    } = await fetchRequest(fetchJudgmentKeywordsIncluded, judgement_id, cancelToken);

    if (cancelled) return;
    if (data) {
      setKeywordsIncluded(data.data);
    }
    if (messageResponse) setMessage(messageResponse);
    setLoading(false);
  };

  useEffect(() => {
    if (judgment) {
      fetchKeywords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [judgment]);

  const { paginationComponent, selectedItemsFromArray } = useLocalPagination(keywordsIncluded);

  const hideContent = () => setDisplayKeywordIdContent(null);

  const displayContent = () => {
    if (displayKeywordIdContent) {
      const selectedKeyword = keywordsIncluded.find((el) => el.id === displayKeywordIdContent);

      if (selectedKeyword) {
        return (
          <div className="col-xl-8">
            <CardBox
              heading={`Treść zaznaczonej publikacji - ${selectedKeyword.name}`}
              paddingBottom="0"
              actions={
                <Button color="link" className="d-xl-inline d-none link-with-border p-0" onClick={hideContent}>
                  Zamknij
                </Button>
              }
            >
              <InformationList
                fields={[
                  {
                    label: '',
                    value: selectedKeyword.content,
                    html: true,
                    htmlFieldInsertedStyle: { maxHeight: '800px', overflowY: 'auto', marginBottom: '20px' }
                  }
                ]}
              />
            </CardBox>
          </div>
        );
      }

      hideContent();
    }

    return null;
  };

  return (
    <div className="row">
      {displayAddModal && (
        <JudgementAddKeywordIncludedModal
          isOpen={displayAddModal}
          judgement={judgment}
          toggle={() => setDisplayAddModal(false)}
          onSubmit={addKeyword}
          message={addModalMessage}
          loading={addModalLoading}
          clearErrorsOnUnmount={() => setAddModalMessage(null)}
          title="Dodaj słowo kluczowe"
          keywordsIncluded={keywordsIncluded}
        />
      )}
      {displayDeleteModal && (
        <GenericModalDelete
          value={displayDeleteModal}
          title="Usunięcie słowa kluczowego"
          content={(value) => (
            <div>Czy na pewno chcesz usunąć słowo kluczowe o nazwie {value?.name} z tego orzeczenia?</div>
          )}
          onDeleteClick={(value) => deleteKeyword(value.id)}
          isOpen={!!displayDeleteModal}
          toggle={() => setDisplayDeleteModal(null)}
          loading={deleteModalLoading}
          message={deleteModalMessage}
          resetMessage={() => setMessage(null)}
        />
      )}
      <div className="flex-1 pr-2 pl-2">
        <CardBox
          heading="Słowa kluczowe"
          actions={
            <Authorize permissions={[JUDGMENT_KEYWORDS_ADD]}>
              <Button color="link" className="link-with-border p-0" onClick={() => setDisplayAddModal(true)}>
                Dodaj słowo kluczowe
              </Button>
            </Authorize>
          }
        >
          <div className="row users-view">
            <div className="col-12">
              <Alert message={message} />
              <div className="pzpeu-table-list inside-card-box">
                <Listing
                  table={
                    <KeywordsIncludedTable
                      keywordsIncluded={selectedItemsFromArray}
                      onDeleteClick={setDisplayDeleteModal}
                      setShowContent={setDisplayKeywordIdContent}
                      judgement={judgment}
                    />
                  }
                  pagination={paginationComponent()}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </CardBox>
      </div>
      {displayContent()}
    </div>
  );
};

export default KeywordListIncluded;
