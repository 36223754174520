import { SetAuthUserAction, SET_AUTH_USER } from 'modules/Auth/action';
import { addToastAction, AddToastPayload } from 'modules/Layout/action';
import { PartialProfileState } from 'modules/Profile/state';
import { ColorVariants } from 'modules/Shared/type';
import { Action, Dispatch } from 'redux';
import { updateProfileToastError, updateProfileToastSuccess } from 'modules/Profile/toasts';
import ApiError from 'modules/Shared/exception/ApiError';
import { CompanyEntity, UserEntity } from 'modules/User/model/User';
import { updateCompanyProfile, updateProfile } from 'modules/Profile/repository';
import { push } from 'connected-react-router';
import { ROUTE_PROFILE } from 'modules/Profile/routes';
import { UserIdentityValues } from 'modules/User/type';

export const UPDATE_PROFILE = 'PROFILE/UPDATE';
export const SET_PROFILE_STATE = 'PROFILE/STATE/SET';

export type ProfileAction = UpdateProfileAction | SetProfileStateAction;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdateProfileAction extends Action<typeof UPDATE_PROFILE> {}

export interface SetProfileStateAction extends Action<typeof SET_PROFILE_STATE> {
  payload: PartialProfileState;
}

export const setProfileStateAction = (payload: PartialProfileState): SetProfileStateAction => ({
  type: SET_PROFILE_STATE,
  payload
});

export const updateProfileAction = (payload: UserIdentityValues) => async (dispatch: Dispatch) => {
  dispatch<UpdateProfileAction>({
    type: UPDATE_PROFILE
  });
  let state: PartialProfileState = { updating: false };
  let toast: AddToastPayload;

  try {
    const { data } = await updateProfile(payload);

    state.message = {
      value: data.message,
      variant: ColorVariants.Success
    };

    dispatch<SetAuthUserAction>({
      type: SET_AUTH_USER,
      payload: data.user
    });

    toast = updateProfileToastSuccess();
    dispatch(push(ROUTE_PROFILE));
  } catch (error) {
    if (error instanceof ApiError) {
      state = { ...state, ...error.getPayload() };
    }

    toast = updateProfileToastError();
  }

  dispatch(addToastAction(toast));
  dispatch(setProfileStateAction(state));
};

export const updateCompanyProfileAction = (payload: CompanyEntity, user: UserEntity) => async (dispatch: Dispatch) => {
  dispatch<UpdateProfileAction>({
    type: UPDATE_PROFILE
  });
  let state: PartialProfileState = { updating: false };
  let toast: AddToastPayload;

  try {
    const { data } = await updateCompanyProfile(payload);

    const { company: _, ...rest } = user;
    dispatch<SetAuthUserAction>({
      type: SET_AUTH_USER,
      payload: { company: data.company, ...rest }
    });

    toast = updateProfileToastSuccess();
    dispatch(push(ROUTE_PROFILE));
  } catch (error) {
    if (error instanceof ApiError) {
      state = { ...state, ...error.getPayload() };
    }

    toast = updateProfileToastError();
  }

  dispatch(addToastAction(toast));
  dispatch(setProfileStateAction(state));
};
