import React from 'react';
import './index.scss';
import { ExecutorEntity } from '../../../Executor/model/Executor';
import ExecutorExtendedDetailsBasic from './Basic';

type Props = {
  executorExtended: ExecutorEntity;
};

const ExecutorExtendedDetailsComponent: React.FC<Props> = ({ executorExtended }) => {
  return (
    <div className="col-12 user-details-component">
      <div className="row">
        <div className="col-lg-6 user-data">
          <div className="user-data-card">
            <ExecutorExtendedDetailsBasic executorExtended={executorExtended} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExecutorExtendedDetailsComponent;
