import { getError, hasError } from 'modules/Shared/helper/validation';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import _uniqueId from 'lodash/uniqueId';
import { PROCEEDINGS_INIT_TYPES_VALUES } from 'modules/Proceedings/type';
import { Props } from 'modules/Proceedings/component/Fieldset/Main';

const ProceedingsFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, setValues, placeholder = false } = props;

  const { proceeding_type, shopping_platform } = values;

  return (
    <>
      {proceeding_type === PROCEEDINGS_INIT_TYPES_VALUES.OPEN && (
        <>
          <FormGroup>
            {!placeholder && <Label for="input-shopping_platform">Platforma zakupowa</Label>}
            <Input
              type="textarea"
              name="shopping_platform"
              id="input-shopping_platform"
              placeholder={placeholder ? 'Platforma zakupowa' : null}
              value={shopping_platform}
              onChange={(event) => setValues({ ...values, shopping_platform: event.target.value })}
              invalid={hasError(errors, 'shopping_platform')}
              maxLength={500}
              style={{ resize: 'none', height: '192px' }}
            />
            {hasError(errors, 'shopping_platform') && (
              <FormFeedback>{getError(errors, 'shopping_platform')}</FormFeedback>
            )}
          </FormGroup>
        </>
      )}
    </>
  );
};

export default ProceedingsFieldset;
