import React from 'react';
import {
  getClientStatusLabel,
  STATUS_ACTIVE,
  STATUS_DELETED,
  STATUS_NOT_VERIFIED,
  UserEntity
} from 'modules/User/model/User';
import { calculateDiffInDays } from 'modules/Shared/helper/utils';
import { Link } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_USER } from 'modules/User/routes';
import './lastCreatedUser.scss';
import classNames from 'classnames';
import InformationListCollapsed from '../InformationList/Collapsed';

type Props = { user: UserEntity };

const LastCreatedUsers: React.FC<Props> = ({ user }) => {
  const daysLate = user?.created_at ? calculateDiffInDays(user.created_at) : null;

  const daysCorrectForm = () => {
    const roundedDays = Math.ceil(daysLate);

    switch (roundedDays) {
      case 0:
        return 'dzisiaj';
      case 1:
        return (
          <>
            <span className="highlighted">1</span> dzień temu
          </>
        );
      default:
        return (
          <>
            <span className="highlighted">{roundedDays}</span> dni temu
          </>
        );
    }
  };

  return (
    <div className="last-created-user-row">
      <InformationListCollapsed
        title={
          <Link className="pzpeu-link" to={getPathUrl(ROUTE_USER, { id: user?.id })}>
            {user?.name}
          </Link>
        }
        fields={[
          <span
            key="user-status"
            className={classNames(
              {
                'status-deleted': user?.status === STATUS_DELETED,
                'status-active': user?.status === STATUS_ACTIVE,
                'status-not-verified': user?.status === STATUS_NOT_VERIFIED
              },
              'user-status'
            )}
          >
            {getClientStatusLabel(user?.status)}
          </span>,
          user?.company ? `Firma: ${user?.company}` : null,
          <>Utworzono: {daysCorrectForm()}</>
        ]}
      />
    </div>
  );
};

export default LastCreatedUsers;
