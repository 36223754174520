import { suffixLabel } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { Props } from 'modules/ForeignProceedings/component/Fieldset/Main';

const ForeignProceedingsFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, setValues, placeholder = false, required } = props;

  const { name = '', ref_nr, ordered_by, platform_mode, href } = values;

  return (
    <>
      <FormGroup>
        {!placeholder && <Label for="input-name">{suffixLabel('Nazwa postępowania', required)}</Label>}
        <Input
          type="text"
          name="name"
          id="input-name"
          placeholder={placeholder ? 'Nazwa postępowania' : null}
          value={name}
          onChange={(event) => setValues({ ...values, name: event.target.value })}
          invalid={hasError(errors, 'name')}
          required={required}
        />
        {hasError(errors, 'name') && <FormFeedback>{getError(errors, 'name')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="input-ref_nr">{suffixLabel('Numer postępowania', required)}</Label>}
        <Input
          type="text"
          name="ref_nr"
          id="input-ref_nr"
          placeholder={placeholder ? 'Numer postępowania' : null}
          value={ref_nr}
          onChange={(event) => setValues({ ...values, ref_nr: event.target.value })}
          invalid={hasError(errors, 'ref_nr')}
          required={required}
        />
        {hasError(errors, 'ref_nr') && <FormFeedback>{getError(errors, 'ref_nr')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="input-ordered_by">{suffixLabel('Zamawiający', required)}</Label>}
        <Input
          type="text"
          name="ordered_by"
          id="input-ordered_by"
          placeholder={placeholder ? 'Zamawiający' : null}
          value={ordered_by}
          onChange={(event) => setValues({ ...values, ordered_by: event.target.value })}
          invalid={hasError(errors, 'ordered_by')}
          required={required}
        />
        {hasError(errors, 'ordered_by') && <FormFeedback>{getError(errors, 'ordered_by')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="input-href">{suffixLabel('Link', required)}</Label>}
        <Input
          type="url"
          name="href"
          id="input-href"
          placeholder={placeholder ? 'Link' : null}
          value={href}
          onChange={(event) => setValues({ ...values, href: event.target.value })}
          invalid={hasError(errors, 'href')}
          required={required}
        />
        {hasError(errors, 'href') && <FormFeedback>{getError(errors, 'href')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="input-platform_mode">{suffixLabel('Platforma', required)}</Label>}
        <Input
          type="text"
          name="platform_mode"
          id="input-platform_mode"
          placeholder={placeholder ? 'Platforma' : null}
          value={platform_mode}
          onChange={(event) => setValues({ ...values, platform_mode: event.target.value })}
          invalid={hasError(errors, 'platform_mode')}
          required={required}
        />
        {hasError(errors, 'platform_mode') && <FormFeedback>{getError(errors, 'platform_mode')}</FormFeedback>}
      </FormGroup>
    </>
  );
};

export default ForeignProceedingsFieldset;
