import judgementListReducer from 'modules/JudgementClient/reducer/judgementList';
import { combineReducers, Reducer } from 'redux';
import { JudgementClientState } from 'modules/JudgementClient/state';

const reducer = (): Reducer<JudgementClientState> => {
  const reducers = {
    judgementClientList: judgementListReducer
  };

  return combineReducers<JudgementClientState>(reducers);
};

export default reducer;
