import { managePageAction } from 'modules/Layout/action';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { breadcrumbSubscriptionsSelected } from 'modules/Subscriptions/breadcrumbs';
import SummaryForm from 'modules/Subscriptions/component/SummaryForm';
import { push } from 'connected-react-router';
import { ROUTE_SUBSCRIPTIONS } from 'modules/Subscriptions/routes';
import { RootState } from 'app/reducer';
import './index.scss';

const SummaryView = () => {
  const { subscriptions_id } = useSelector((state: RootState) => state.subscriptions.selected);
  const { subscriptions } = useSelector((state: RootState) => state.subscriptions.list);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      managePageAction({
        title: 'Potwierdzenie wybranych subskrypcji',
        breadcrumb: breadcrumbSubscriptionsSelected()
      })
    );

    if (!subscriptions_id.length || !subscriptions.length) {
      dispatch(push(ROUTE_SUBSCRIPTIONS));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="summary-view">
      <SummaryForm subscriptions={subscriptions} subscriptionsId={subscriptions_id} />
    </div>
  );
};

export default SummaryView;
