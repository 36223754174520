import React, { useEffect } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
import ClauseTable from 'modules/ClauseClient/container/ClauseList/Table';
import ClausePerPage from 'modules/ClauseClient/container/ClauseList/PerPage';
import ClauseListingPagination from 'modules/ClauseClient/container/ClauseList/Pagination';
import ClauseFilter from 'modules/ClauseClient/container/ClauseList/Filter/clientFilter';
import { breadcrumbRouteClientClauseList } from 'modules/ClauseClient/breadcrumbs';
import { ROUTE_CLIENT_CLAUSES } from 'modules/ClauseClient/routes';

const ClauseListView = () => {
  const dispatch = useDispatch();
  const { fetching, meta } = useSelector((state: RootState) => state.clause.clauseList);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  // const setParams = (payload: PartialSearchingProps) => dispatch(setClauseListParamsAction(payload));

  // const searchProps = createSearchingProps('', Clause.getFilterableAttributes(), Clause.getSortableAttributes());

  useEffect(() => {
    managePage({
      title: 'Rejestr klauzul niedozwolonych - wyszukiwanie',
      breadcrumb: breadcrumbRouteClientClauseList()
    });

    // setParams({ ...searchProps });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row users-view">
      <div className="col-12">
        <Listing
          table={<ClauseTable />}
          filter={<ClauseFilter />}
          pagination={<ClauseListingPagination path={ROUTE_CLIENT_CLAUSES} />}
          perPage={<ClausePerPage />}
          loading={fetching}
          meta={meta}
        />
      </div>
    </div>
  );
};

export default ClauseListView;
