import { RootState } from 'app/reducer';
import { setSearchingParamsAction, SetSearchingParamsAction } from 'modules/Shared/action';
import Validation from 'modules/Shared/exception/Validation';
import { createSearchParams } from 'modules/Shared/helper/params';
import { PaginationMeta, PartialSearchingProps } from 'modules/Shared/type';
import { Action, Dispatch } from 'redux';
import _ from 'lodash';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { fetchJudgmentKeywordsWithCategories } from 'modules/Judgement/repository';

export const SET_KEYWORDS_WITH_CATEGORIES_LIST_PARAMS = 'JUDGEMENT/KEYWORD/LIST/PARAMS/SET';
export const KEYWORDS_WITH_CATEGORIES_LIST = 'JUDGEMENT/KEYWORD/LIST';

export type JudgementKeywordListAction = SetJudgementKeywordListParamsAction | JudgementKeywordListFetchedAction;

export type SetJudgementKeywordListParamsAction = SetSearchingParamsAction<
  typeof SET_KEYWORDS_WITH_CATEGORIES_LIST_PARAMS
>;

export type JudgementKeywordListFetchedPayload = {
  list: JudgementElementEntity[];
  meta: PaginationMeta;
};
export interface JudgementKeywordListFetchedAction extends Action<typeof KEYWORDS_WITH_CATEGORIES_LIST> {
  payload: JudgementKeywordListFetchedPayload;
}
export const setKeywordsWithCategoriesListParamsAction =
  (payload: PartialSearchingProps) => async (dispatch: Dispatch, getState: () => RootState) => {
    const currentState = getState()?.judgement?.keywordList;

    if (
      payload?.filter &&
      !payload?.pagination &&
      typeof currentState?.pagination?.page === 'number' &&
      currentState.pagination.page !== 1 &&
      !_.isEqual(currentState.filter, payload.filter)
    ) {
      currentState.pagination.page = 1;
    }

    const state = { ...currentState, ...payload };

    dispatch(setSearchingParamsAction(SET_KEYWORDS_WITH_CATEGORIES_LIST_PARAMS, payload));
    try {
      const { pagination, filter, sort } = state;

      const searchParams = createSearchParams({ pagination, filter, sort });

      const response = await fetchJudgmentKeywordsWithCategories(searchParams);

      const { data, meta } = response.data;

      dispatch<JudgementKeywordListFetchedAction>({
        type: KEYWORDS_WITH_CATEGORIES_LIST,
        payload: { list: data, meta }
      });
    } catch (error) {
      if (error instanceof Validation) {
        dispatch<JudgementKeywordListFetchedAction>({
          type: KEYWORDS_WITH_CATEGORIES_LIST,
          payload: { list: [], meta: null }
        });
      } else {
        throw error;
      }
    }
  };
