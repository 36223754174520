import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterInputType } from 'modules/Layout/type';
import { formatDateLocalForInput } from 'modules/Shared/helper/utils';
import { setJudgementListParamsAction } from 'modules/Judgement/action/JudgementList';
import JudgmentFilterCategoryAutocomplete from './customFields/FilterCategoriesAutocomplete';
import JudgmentFilterKeywordsAutocomplete from './customFields/FilterKeywordsAutocomplete';

export interface Props {
  values?: SearchParams;
}

const JudgementFilter: React.FC<Props> = ({ values = {} }) => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.judgement.judgementList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setJudgementListParamsAction(payload));

  const staticInputsBase: FilterInputType[] = [
    {
      type: 'number',
      property: 'id',
      label: 'ID',
      value: _get(values, 'id')
    },
    {
      type: 'text',
      property: 'identifier',
      label: 'Identyfikator publikacji',
      value: _get(values, 'identifier')
    },
    {
      type: 'text',
      property: 'authority_name',
      label: 'Nazwa organu publikującego dokument',
      value: _get(values, 'authority_name')
    },
    {
      type: 'text',
      property: 'authority_type',
      label: 'Rodzaj organu publikującego dokument',
      value: _get(values, 'authority_type')
    },
    {
      type: 'text',
      property: 'document_type',
      label: 'Rodzaj dokumentu',
      value: _get(values, 'document_type')
    },
    {
      type: 'text',
      property: 'place',
      label: 'Oryginalne miejsce publikacji',
      value: _get(values, 'place')
    },
    {
      type: 'checkbox',
      className: 'col-12 col-sm-6 col-md-5 col-xl-3',
      property: 'requires_action',
      label: 'Pokaż tylko orzeczenia, które wymagają uzupełnienia danych',
      value: _get(values, 'requires_action')
    }
  ];

  const inputsTime = (): FilterInputType[] => {
    let dynamicInputs: FilterInputType[] = [];

    const lastPublishedAtFrom = _get(filter, 'published_at_from') as string;
    const lastPublishedAtTo = _get(filter, 'published_at_to') as string;

    dynamicInputs = [
      {
        beforeLineBreak: true,
        type: 'date',
        property: 'published_at_from',
        label: 'Termin publikacji od',
        value: lastPublishedAtFrom ? formatDateLocalForInput(lastPublishedAtFrom) : lastPublishedAtFrom,
        max: String(lastPublishedAtTo)
      },
      {
        type: 'date',
        property: 'published_at_to',
        label: 'Termin publikacji do',
        value: lastPublishedAtTo ? formatDateLocalForInput(lastPublishedAtTo) : lastPublishedAtTo,
        min: String(lastPublishedAtFrom)
      }
    ];

    return dynamicInputs;
  };

  const staticInputs = [...staticInputsBase, ...inputsTime()];

  return (
    <ListingFilter
      inputs={staticInputs}
      filter={filter}
      pagination={pagination}
      setParams={setParams}
      customInput={
        <>
          <div className="w-100 mt-1" />
          <JudgmentFilterCategoryAutocomplete />
          <JudgmentFilterKeywordsAutocomplete />
        </>
      }
    />
  );
};

export default JudgementFilter;
