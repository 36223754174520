import { FilterEntity } from 'modules/Layout/model/Filters';
import React from 'react';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import TrashIcon from 'modules/Layout/component/Icon/Trash';

type Props = {
  filtersList: FilterEntity[];
  onDeleteClick?: (id: number) => Promise<void> | void;
  onItemClick?: (id: number) => void;
  title?: string;
  btnTitle?: string;
  displayBorder?: boolean;
};

const SavedFiltersList: React.FC<Props> = ({
  filtersList,
  onDeleteClick,
  onItemClick,
  btnTitle = 'Usuń filtr',
  displayBorder
}) => {
  return (
    <div className="flex-1">
      <div className="saved-filter-wrapper">
        <div className="saved-filter-list">
          {filtersList.map((filter) => (
            <div key={filter.id} className={`saved-filter-item mr-3${displayBorder ? '  border rounded p-1' : ''}`}>
              {onDeleteClick && (
                <ActionDelete
                  className="saved-filter-delete"
                  title={btnTitle}
                  label={<TrashIcon height="15px" className="" />}
                  onClick={() => onDeleteClick(filter?.id)}
                />
              )}
              <div
                className={`saved-filter-title${onItemClick ? ' interactive' : ''}`}
                onClick={() => onItemClick && onItemClick(filter?.id)}
                role="presentation"
              >
                {filter?.name}
                {filter?.default && '  (domyślny)'}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SavedFiltersList;
