import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import { setTraditionalPaymentListParamsAction } from 'modules/Subscriptions/action/traditionalPaymentList';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterInputType } from 'modules/Layout/type';
import { formatDatetimeLocalForInput } from 'modules/Shared/helper/utils';

export interface Props {
  values?: SearchParams;
}

const TraditionalPaymentWaitingListFilter: React.FC<Props> = ({ values = {} }) => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.subscriptions.traditionalPayment);
  const setParams = (payload: PartialSearchingProps) => dispatch(setTraditionalPaymentListParamsAction(payload));

  let staticInputsBase: FilterInputType[] = [
    {
      type: 'text',
      property: 'user_first_name',
      label: 'Imię',
      value: _get(values, 'user_first_name')
    },
    {
      type: 'text',
      property: 'user_last_name',
      label: 'Nazwisko',
      value: _get(values, 'user_last_name')
    },
    {
      type: 'text',
      property: 'user_email',
      label: 'Email',
      value: _get(values, 'user_email')
    },
    {
      type: 'text',
      property: 'company_name',
      label: 'Firma',
      value: _get(values, 'company_name')
    }
  ];

  const inputsTime = (): FilterInputType[] => {
    const lastLoginAtFrom = _get(filter, 'user_last_login_from') as string;
    const lastLoginAtTo = _get(filter, 'user_last_login_to') as string;

    const createdAtFrom = _get(filter, 'created_at_from') as string;
    const createdAtTo = _get(filter, 'created_at_to') as string;

    return [
      {
        beforeLineBreak: true,
        type: 'datetime',
        property: 'user_last_login_from',
        label: 'Ostatnie logowanie od',
        value: lastLoginAtFrom ? formatDatetimeLocalForInput(lastLoginAtFrom) : lastLoginAtFrom,
        max: String(lastLoginAtTo)
      },
      {
        type: 'datetime',
        property: 'user_last_login_to',
        label: 'Ostatnie logowanie do',
        value: lastLoginAtTo ? formatDatetimeLocalForInput(lastLoginAtTo) : lastLoginAtTo,
        min: String(lastLoginAtFrom)
      },
      {
        beforeLineBreak: true,
        type: 'datetime',
        property: 'created_at_from',
        label: 'Data złożenia prośby o FV od',
        value: createdAtFrom ? formatDatetimeLocalForInput(createdAtFrom) : createdAtFrom,
        max: String(createdAtTo)
      },
      {
        type: 'datetime',
        property: 'created_at_to',
        label: 'Data złożenia prośby o FV do',
        value: createdAtTo ? formatDatetimeLocalForInput(createdAtTo) : createdAtTo,
        min: String(createdAtFrom)
      }
    ];
  };

  const staticInputs = [...staticInputsBase, ...inputsTime()];

  return <ListingFilter inputs={staticInputs} filter={filter} pagination={pagination} setParams={setParams} />;
};

export default TraditionalPaymentWaitingListFilter;
