import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { OfficeExecutorRequestReceivedFile } from 'modules/Office/type';
import Authorize from 'modules/Auth/container/Authorize';
import { OFFICES_DETAILS } from 'modules/Office/permissions';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import { formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import DownloadIcon from 'modules/Layout/component/Icon/Download';

type Props = {
  filesList?: OfficeExecutorRequestReceivedFile[];
  downloadFileRequest: (fileId: number | string) => Promise<void>;
};

const ExecutorRequestFileListTable: React.FC<Props> = ({ filesList, downloadFileRequest }) => {
  const cols: TableCol<OfficeExecutorRequestReceivedFile>[] = [
    {
      property: 'name',
      label: 'Nazwa:'
    },
    {
      property: 'created_at',
      label: 'Dołączono:',
      value: (row) => ifContainsDateFormat(row?.created_at, formatDatetimeSeconds)
    },
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            <Authorize permissions={[OFFICES_DETAILS]}>
              <ActionUpdate
                className="mx-1"
                title="Pobierz załącznik"
                label={<DownloadIcon height="20px" />}
                onClick={() => downloadFileRequest(row?.id)}
              />
            </Authorize>
          </div>
        );
      }
    }
  ];

  return <Table cols={cols} rows={filesList} />;
};

export default ExecutorRequestFileListTable;
