import React, { useState, useEffect } from 'react';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { PageProps } from 'modules/Layout/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message, ValidationErrors } from 'modules/Shared/type';
import { useDispatch } from 'react-redux';
import Loader from 'modules/Layout/component/Loader';
import { Button, Form as FormStrap } from 'reactstrap';
import CardBox from 'modules/Layout/component/CardBox';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import { fetchExecutorExplanationSection, updateExecutorExplanationSection } from 'modules/Executor/repository';
import { ExecutorExplanationSectionValues } from 'modules/Executor/type';
import { breadcrumbRouteExecutorExplanationSection } from 'modules/Executor/breadcrumbs';
import { executorExplanationSectionUpdateToastSuccess } from 'modules/Executor/toasts';
import ExplanationSectionForm from 'modules/Executor/component/ExplanationSectionForm';

const ExplanationSectionView = () => {
  const dispatch = useDispatch();
  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  const [explanationSections, setExplanationSections] = useState<ExecutorExplanationSectionValues[]>([]);

  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  const cancelToken = useCancelToken();
  const getExecutorExplanationSection = async () => {
    setFetching(true);
    const {
      cancelled,
      message: messageResponse,
      data
    } = await fetchRequest(fetchExecutorExplanationSection, cancelToken);

    if (cancelled) return;
    if (data) {
      const stateObj = data?.data;
      setExplanationSections(stateObj);
    }
    if (messageResponse) setMessage(messageResponse);
    setFetching(false);
  };

  useEffect(() => {
    managePage({
      title: 'Modyfikacja wyjaśnień sekcji raportu',
      breadcrumb: breadcrumbRouteExecutorExplanationSection()
    });

    getExecutorExplanationSection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchUpdateExecutorExplanationSectionToast = () =>
    dispatch(addToastAction(executorExplanationSectionUpdateToastSuccess()));

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    if (message) setMessage(null);
    if (errors) setErrors(null);

    const {
      cancelled,
      message: messageResponse,
      data,
      error
    } = await updateRequestWithData(updateExecutorExplanationSection, { sections: explanationSections }, cancelToken);
    if (cancelled) return;
    if (data) {
      const stateObj = data?.data;
      setExplanationSections(stateObj);

      dispatchUpdateExecutorExplanationSectionToast();
    }
    if (error) setErrors(error);
    if (messageResponse) setMessage(messageResponse);
    setLoading(false);
  };

  const handleDescriptionChange = (slug: string, newContent: string) => {
    setExplanationSections((prevSections) =>
      prevSections.map((section) => (section.slug === slug ? { ...section, description: newContent } : section))
    );
  };

  if (fetching) return <Loader />;
  return (
    <div className="profile-update-view row justify-content-center">
      <div className="col-lg-10">
        <CardBox heading="Aktualizuj wyjaśnienia sekcji raportu" message={message}>
          {loading && <Loader />}
          <FormStrap onSubmit={onSubmit}>
            {explanationSections.map((section) => {
              return (
                <ExplanationSectionForm
                  onDescriptionChange={handleDescriptionChange}
                  key={section.id}
                  section={section}
                  errors={errors}
                />
              );
            })}
            <div className="d-flex justify-content-center">
              <Button
                type="submit"
                color="primary"
                className="pzpeu-btn-flex waves-effect waves-light"
                disabled={loading}
              >
                Zapisz
              </Button>
            </div>
          </FormStrap>
        </CardBox>
      </div>
    </div>
  );
};

export default ExplanationSectionView;
