import Table, { TableCol } from 'modules/Layout/component/Table/AccordionTable';
import Office from 'modules/Office/model/Office';
import React from 'react';
import { formatDate, formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { RootState } from 'app/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { PartialSearchingProps } from 'modules/Shared/type';
import { setOfficeListParamsAction } from 'modules/Office/action/list';

type Props = { onOfficeClick?: (office: Office) => void; selectedOffices: Office[] };

const ResendOfficeTable: React.FC<Props> = ({ onOfficeClick, selectedOffices }) => {
  const dispatch = useDispatch();
  const { offices, sort } = useSelector((state: RootState) => state.office.list);

  const setParams = (payload: PartialSearchingProps) => dispatch(setOfficeListParamsAction(payload));

  const cols: TableCol<Office>[] = [
    {
      property: 'name',
      label: 'Nazwa'
    },
    {
      property: 'email',
      label: 'Email'
    },
    {
      property: 'phone',
      label: 'Telefon'
    },
    {
      property: 'last_executor_request',
      label: 'Ostatnie zapytanie',
      value: (row) => ifContainsDateFormat(row?.last_executor_request?.sent_at, formatDatetimeSeconds)
    },
    {
      property: 'next_request_at',
      label: 'Następne zapytanie',
      value: (row) => ifContainsDateFormat(row?.next_request_at, formatDate)
    }
  ];

  return (
    <Table
      cols={cols}
      rows={offices}
      sort={sort}
      onSort={(params) => setParams({ sort: params })}
      displayErrorRow={(row) => (selectedOffices.find((val) => val.id === row?.id) ? 'success-table-field' : '')}
      onRowClick={onOfficeClick}
    />
  );
};

export default ResendOfficeTable;
