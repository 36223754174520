import {
  ExecutorExtendedRequestsListAction,
  SET_USER_EXECUTORS_EXTENDED_REQUESTS_LIST_PARAMS,
  USER_EXECUTORS_EXTENDED_REQUESTS_LIST
} from 'modules/User/action/list';
import {
  ExecutorsExtendedRequestsListState,
  initExecutorExtendedRequestsListState
} from '../../state/executorsExtendedRequests';

const initState = initExecutorExtendedRequestsListState();

const reducer = (
  state: ExecutorsExtendedRequestsListState = initState,
  action: ExecutorExtendedRequestsListAction
): ExecutorsExtendedRequestsListState => {
  switch (action.type) {
    case SET_USER_EXECUTORS_EXTENDED_REQUESTS_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case USER_EXECUTORS_EXTENDED_REQUESTS_LIST: {
      const { executorsExtendedRequests = [], meta } = action.payload;

      return {
        ...state,
        fetching: false,
        executorsExtendedRequests: executorsExtendedRequests.map((request) => request),
        meta
      };
    }

    default:
      return state;
  }
};

export default reducer;
