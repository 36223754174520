import { InvoiceListAction, INVOICE_LIST, SET_INVOICE_LIST_PARAMS } from 'modules/User/action/listInvoiceRequired';
import { createInvoiceRequired } from 'modules/User/model/Invoice';
import { initInvoiceListState, InvoiceListState } from 'modules/User/state/invoiceList';

const initState = initInvoiceListState();

const reducer = (state: InvoiceListState = initState, action: InvoiceListAction): InvoiceListState => {
  switch (action.type) {
    case SET_INVOICE_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case INVOICE_LIST: {
      const { invoices = [], meta } = action.payload;

      return { ...state, fetching: false, invoice: invoices.map(createInvoiceRequired), meta };
    }

    default:
      return state;
  }
};

export default reducer;
