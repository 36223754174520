import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const deleteProceedingsCategoryToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań',
  body: 'Postępowanie zostało pomyślnie usunięte.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createProceedingsToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań',
  body: 'Postępowanie zostało pomyślnie stworzone.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateProceedingsToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań',
  body: 'Postępowanie zostało pomyślnie zaktualizowana.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const sendRequestProceedingsToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań',
  body: 'Pomyślnie wysłano zapytanie.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const downloadRequestProceedingsToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań',
  body: 'Rozpoczęto pobieranie pliku.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const addProceedingsToFavouriteToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań',
  body: 'Pomyślnie dodano postępowanie do ulubionych.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createProceedingsCPVToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań',
  body: 'Kod CPV został pomyślnie dodany.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateProceedingsCPVToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań',
  body: 'Kod CPV został pomyślnie zaktualizowany.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const removeProceedingsFromFavouriteToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań',
  body: 'Pomyślnie usunięto postępowanie z ulubionych.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteProceedingsCPVToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań',
  body: 'Kod CPV został pomyślnie usunięty.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const proceedingsToastServerError = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań',
  body: 'Wystąpił błąd servera.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});
