import React, { useState } from 'react';
import { API_PAYMENT_CANCEL_PAYMENT_ERROR_CODE, startPayment } from 'modules/Subscriptions/repository';
import { Button, Form as FormStrap } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/reducer';
import Alert from 'modules/Layout/component/Alert';
import Subscription from 'modules/Subscriptions/model/Subscription';
import { ColorVariants, Message } from 'modules/Shared/type';
import CardBox from 'modules/Layout/component/CardBox';
import Loader from 'modules/Layout/component/Loader';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Link } from 'react-router-dom';
import { ROUTE_SUBSCRIPTION_TRADITIONAL_PAYMENT, ROUTE_SUBSCRIPTIONS } from 'modules/Subscriptions/routes';
import SummaryTable from 'modules/Subscriptions/container/SummaryTable';
import CompanyForSubscriptionSummaryDataset from 'modules/User/component/Dataset/CompanyForSubscriptionSummary';
import { getPathUrl } from 'modules/Shared/helper/api';
import { push } from 'connected-react-router';
import DisplayPopovers from 'modules/Layout/component/Popover/DisplayWrapper';
import './index.scss';
import { PaymentHistoryValues } from 'modules/Profile/type';
import CancelPaymentModal from 'modules/Subscriptions/component/CancelPaymentModal';

type Props = {
  subscriptions: Subscription[];
  subscriptionsId: number[];
};

const BILLING_DETAILS_NOT_FILLED_ERROR_MESSAGE =
  'Rozpoczęcie płatności jest niemożliwe. Dane rozliczeniowe nie zostały w pełni uzupełnione. W celu wypełnienia danych rozliczeniowych przejdź do Profilu użytkownika.';

const SummaryForm: React.FC<Props> = ({ subscriptionsId, subscriptions }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const [cancelPaymentModalStatus, setCancelPaymentModalStatus] = useState(false);
  const [cancelPaymentModalTransactions, setCancelPaymentModalTransactions] = useState([]);

  const setError = (error: string) =>
    setMessage({
      value: error,
      variant: ColorVariants.Danger
    });

  const cancelToken = useCancelToken();

  const showCancelPaymentModal = (payments: PaymentHistoryValues[]) => {
    setCancelPaymentModalTransactions(payments);
    return setCancelPaymentModalStatus(true);
  };

  const hideCancelPaymentModal = () => {
    setCancelPaymentModalTransactions([]);
    return setCancelPaymentModalStatus(false);
  };

  const checkBillingDetailsFilling = () => {
    return Boolean(user?.company?.client_type);
  };

  const onOnlinePaymentClick = async () => {
    if (!checkBillingDetailsFilling()) {
      setError(BILLING_DETAILS_NOT_FILLED_ERROR_MESSAGE);
      return;
    }

    await makeApiCall();
  };

  const onTraditionalPaymentClick = () => {
    if (!checkBillingDetailsFilling()) {
      setError(BILLING_DETAILS_NOT_FILLED_ERROR_MESSAGE);
      return;
    }

    dispatch(push(getPathUrl(ROUTE_SUBSCRIPTION_TRADITIONAL_PAYMENT)));
  };

  const makeApiCall = async (cancelPaymentStatus: boolean = null) => {
    setLoading(true);

    startPayment({ subscriptions: subscriptionsId, cancel_previous_payments: cancelPaymentStatus }, cancelToken)
      .then(async (response) => {
        const { data } = response.data;

        if (!data?.id || !data?.url) {
          setLoading(false);
          return;
        }

        window.location.href = data.url;
      })
      .catch((error) => {
        setLoading(false);
        if (error.error.response.data.status === API_PAYMENT_CANCEL_PAYMENT_ERROR_CODE) {
          return showCancelPaymentModal(error.response.data.data);
        }

        setError(error?.error?.response?.data?.message || error?.message || 'Something went wrong.');
      });
  };

  const selectedProducts = subscriptionsId.map((id) => subscriptions.find((subscription) => subscription.id === id));

  return (
    <>
      <CardBox
        className="mb-0"
        heading="Metody płatności"
        paddingRight="30px"
        paddingBottom="0"
        actions={
          <Link to={{ pathname: getPathUrl(ROUTE_SUBSCRIPTIONS), state: { doNotClearData: true } }}>
            <span className="link-with-border">ZMIEŃ SWÓJ WYBÓR</span>
          </Link>
        }
      >
        {loading && <Loader />}
        <Alert message={message} />
        <FormStrap id="summary-form">
          <SummaryTable selectedProducts={selectedProducts} userModules={user.modules} />
          <div className="billing-details-wrapper">
            <div className="billing-details-title">Dane rozliczeniowe</div>
            <DisplayPopovers
              popoverId="headlinePopover"
              popoverContent="Dane używane do wystawienia i wysłania faktury"
              className="tooltip-light subscriptions-tooltip-description"
            />
          </div>
          <div className="company-summary-dataset">
            <CompanyForSubscriptionSummaryDataset user={user} />
          </div>
        </FormStrap>
      </CardBox>
      <div className="summary-form-actions d-flex justify-content-center justify-content-lg-around flex-wrap ">
        <Button
          type="button"
          form="summary-form"
          color={checkBillingDetailsFilling() ? 'primary' : undefined}
          className="waves-effect summary-form-action waves-light pzpeu-btn-flex"
          disabled={loading}
          onClick={onTraditionalPaymentClick}
        >
          Tradycyjny przelew
        </Button>
        <Button
          type="button"
          form="summary-form"
          color={checkBillingDetailsFilling() ? 'primary' : undefined}
          className="waves-effect summary-form-action waves-light pzpeu-btn-flex"
          disabled={loading}
          onClick={onOnlinePaymentClick}
        >
          Płatność online
        </Button>
      </div>
      <CancelPaymentModal
        isOpen={cancelPaymentModalStatus}
        payments={cancelPaymentModalTransactions}
        toggle={hideCancelPaymentModal}
        submit={() => {
          hideCancelPaymentModal();
          makeApiCall(true);
        }}
      />
    </>
  );
};

export default SummaryForm;
