import listReducer from 'modules/Agreements/reducer/list';
import { combineReducers, Reducer } from 'redux';
import { AgreementsState } from 'modules/Agreements/state';

const reducer = (): Reducer<AgreementsState> => {
  const reducers = {
    list: listReducer
  };

  return combineReducers<AgreementsState>(reducers);
};

export default reducer;
