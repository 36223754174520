import { RootState } from 'app/reducer';
import Authorize from 'modules/Auth/container/Authorize';
import { setClauseListParamsAction } from 'modules/Clause/action/ClauseList';
import Clause from 'modules/Clause/model/Clause';
import { CLAUSE_DELETE, CLAUSE_DETAILS, CLAUSE_EDIT } from 'modules/Clause/permissions';
import { ROUTE_CLAUSE, ROUTE_CLAUSE_UPDATE } from 'modules/Clause/routes';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { getPathUrl } from 'modules/Shared/helper/api';
import { PartialSearchingProps } from 'modules/Shared/type';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import trimmedString from 'modules/Shared/helper/trimmedString';
import EditIcon from 'modules/Layout/component/Icon/Edit';

type Props = { onDeleteClick?: (clause: Clause) => void };

const ClauseTable: React.FC<Props> = ({ onDeleteClick }) => {
  const dispatch = useDispatch();
  const { list, sort } = useSelector((state: RootState) => state.clause.clauseList);

  const setParams = (payload: PartialSearchingProps) => dispatch(setClauseListParamsAction(payload));

  const redirectLink = (value: string, id: number) => (
    <Authorize permissions={[CLAUSE_DETAILS]} fallback={value}>
      <Link className="fill-table-td" to={getPathUrl(ROUTE_CLAUSE, { id })}>
        {value}
      </Link>
    </Authorize>
  );

  const cols: TableCol<Clause>[] = [
    {
      property: 'id',
      label: 'ID',
      sortable: true,
      value: (row) => redirectLink(`${row.id}`, row.id)
    },
    {
      property: 'name',
      label: 'Nazwa klauzuli',
      sortable: true,
      value: (row) => redirectLink(row.name, row.id)
    },
    {
      property: 'description',
      label: 'Wyjaśnienie klauzuli',
      sortable: true,
      value: function Description(row) {
        return <div dangerouslySetInnerHTML={{ __html: trimmedString(row.description ?? '', 100) }} />;
      }
    },
    {
      property: 'category_name',
      label: 'Nazwa kategorii klauzuli',
      sortable: true,
      value: (row) => row.category?.name
    },
    {
      property: 'keywords_names',
      label: 'Słowa kluczowe'
    },
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            <Authorize permissions={[CLAUSE_EDIT]}>
              <ActionUpdate
                title="Edytuj klauzulę"
                label={<EditIcon height="20px" />}
                to={getPathUrl(ROUTE_CLAUSE_UPDATE, { id: row.id })}
              />
            </Authorize>
            {onDeleteClick && (
              <Authorize permissions={[CLAUSE_DELETE]}>
                <ActionDelete className="ml-1" title="Usuń klauzulę" onClick={() => onDeleteClick(row)} />
              </Authorize>
            )}
          </div>
        );
      }
    }
  ];

  return <Table cols={cols} rows={list} sort={sort} onSort={(params) => setParams({ sort: params })} />;
};

export default ClauseTable;
