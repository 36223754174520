import { suffixLabel } from 'modules/Layout/helper/misc';
import AddFiles from 'modules/Shared/helper/Files/AddFiles';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { DATE_FORMAT_SLASH } from 'modules/Shared/helper/utils';
import moment from 'moment';
import { CommonDataItem } from '../../../model/Order';
import useWysiwyg from '../../../../Layout/component/Wysiwyg';

export interface Props {
  values: CommonDataItem;
  setValues: React.Dispatch<React.SetStateAction<CommonDataItem[]>>;
  isEditForm: boolean;
  handleDeleteTermination: (id: string | number | null, index: number) => void;
  i: number;
  errors?: ValidationErrors;
  disabled?: boolean;
  required?: boolean;
}

const OrderTerminationFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, setValues, errors, disabled, required, isEditForm, i, handleDeleteTermination } = props;

  const { violation_date, violation_description, id } = values;

  const { displayWysiwyg: displayTerminationWysiwyg } = useWysiwyg({
    title: 'Przyczyny rozwiązania umowy',
    content: violation_description,
    tooltip: { specialSign: true },
    onChange: (content) =>
      setValues((prevState) =>
        prevState.map((item, index) => (index === i ? { ...item, violation_description: content } : item))
      )
  });

  const handleViolationDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;
    setValues((prevState) =>
      prevState.map((item, index) => (index === i ? { ...item, violation_date: newDate } : item))
    );
  };

  const setTerminationFiles = (itemWithFiles: CommonDataItem) => {
    setValues((prevState) => prevState.map((item, index) => (index === i ? { ...item, ...itemWithFiles } : item)));
  };

  return (
    <div className="flex-row gap-2 d-flex justify-content-between align-items-start">
      <fieldset className="m-0 flex-grow-1" disabled={disabled}>
        <FormGroup>
          {displayTerminationWysiwyg && displayTerminationWysiwyg()}

          {hasError(errors, `termination[${i}].violation_description`) && (
            <FormFeedback className="d-block">
              {getError(errors, `termination[${i}].violation_description`)}
            </FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label for={`violation_date-${i}`}>{suffixLabel('Data rozwiązania umowy', required)}</Label>
          <Input
            type="text"
            name={`violation_date-${i}`}
            id={`violation_date-${i}`}
            placeholder={DATE_FORMAT_SLASH}
            value={violation_date}
            onChange={handleViolationDateChange}
            invalid={hasError(errors, `termination[${i}].violation_date`)}
          />
          {violation_date && !moment(violation_date, DATE_FORMAT_SLASH, true).isValid() && (
            <FormFeedback className="d-block">Obsługiwany format: {DATE_FORMAT_SLASH}.</FormFeedback>
          )}
          {hasError(errors, `termination[${i}].violation_date`) && (
            <FormFeedback className="d-block">{getError(errors, `termination[${i}].violation_date`)}</FormFeedback>
          )}
        </FormGroup>
        {!isEditForm && (
          <FormGroup>
            <AddFiles values={values as any} setValues={setTerminationFiles} objKey="files" maxSizeInMb={50} />
          </FormGroup>
        )}
      </fieldset>
      <button type="button" onClick={() => handleDeleteTermination(id, i)} className="btn btn-link p-0 text-danger">
        <i className="fa fa-times font-18" />
      </button>
    </div>
  );
};

export default OrderTerminationFieldset;
