import React from 'react';
import Bold from 'modules/Layout/component/Icon/WysiwygIcons/Bold';
import Italic from 'modules/Layout/component/Icon/WysiwygIcons/Italic';
import DeleteColumn from 'modules/Layout/component/Icon/WysiwygIcons/DeleteColumn';
import DeleteRow from 'modules/Layout/component/Icon/WysiwygIcons/DeleteRow';
import IndentIncrease from 'modules/Layout/component/Icon/WysiwygIcons/IndentIncrease';
import IndentDecrease from 'modules/Layout/component/Icon/WysiwygIcons/IndentDecrease';
import InsertColumnRight from 'modules/Layout/component/Icon/WysiwygIcons/InsertColumnRight';
import InsertColumnBottom from 'modules/Layout/component/Icon/WysiwygIcons/InsertColumnBottom';
import ListCheck from 'modules/Layout/component/Icon/WysiwygIcons/ListCheck';
import ListOrdered from 'modules/Layout/component/Icon/WysiwygIcons/ListOrdered';
import MarkPenFill from 'modules/Layout/component/Icon/WysiwygIcons/MarkPenFill';
import TableInsert from 'modules/Layout/component/Icon/WysiwygIcons/TableInsert';
import { Editor } from '@tiptap/react';
import { MenuBarOptions } from 'modules/Layout/component/Wysiwyg/WysiwygComponent';
import './styles.scss';

type Props = {
  editor: Editor;
  barOptions: MenuBarOptions;
};

// eslint-disable-next-line
export default ({ editor, barOptions }: Props) => {
  if (!editor) {
    return null;
  }

  return (
    <>
      <div className="rdw-inline-wrapper">
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={`menu-item${editor.isActive('bold') ? ' is-active' : ''}`}
          title="Pogrubienie"
        >
          <Bold />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={`menu-item${editor.isActive('italic') ? ' is-active' : ''}`}
          title="Kursywa"
        >
          <Italic />
        </button>
      </div>

      <div className="rdw-list-wrapper">
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={`menu-item${editor.isActive('bulletList') ? ' is-active' : ''}`}
          title="Lista nieuporządkowana"
        >
          <ListCheck />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={`menu-item${editor.isActive('orderedList') ? ' is-active' : ''}`}
          title="Lista uporządkowana"
        >
          <ListOrdered />
        </button>
        {barOptions.markPen && (
          <button
            type="button"
            onClick={() => editor.chain().focus().toggleHighlight({ color: '#ffff00' }).run()}
            className={`menu-item${editor.isActive('highlight', { color: '#ffff00' }) ? ' is-active' : ''}`}
            title="Wyróżnij tekst"
          >
            <MarkPenFill />
          </button>
        )}
        <button
          type="button"
          onClick={() => editor.chain().focus().sinkListItem('listItem').run()}
          className="menu-item"
          disabled={!editor.can().sinkListItem('listItem')}
          title="Zwiększ zagnieżdżenie listy"
        >
          <IndentIncrease />
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().liftListItem('listItem').run()}
          className="menu-item"
          disabled={!editor.can().liftListItem('listItem')}
          title="Zmniejsz zagnieżdżenie listy"
        >
          <IndentDecrease />
        </button>
        {barOptions.specialSign && (
          <button
            type="button"
            onClick={() => editor.commands.insertContent('§')}
            className="menu-item"
            title="Dodaj znak"
          >
            §
          </button>
        )}
      </div>

      {barOptions.tables && (
        <div className="rdw-list-wrapper">
          <div className="divider" />

          <button
            type="button"
            onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}
            className="menu-item"
            title="Dodaj tabele"
          >
            <TableInsert />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().addColumnAfter().run()}
            disabled={!editor.can().addColumnAfter()}
            className="menu-item"
            title="Dodaj kolumnę"
          >
            <InsertColumnRight />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().deleteColumn().run()}
            disabled={!editor.can().deleteColumn()}
            className="menu-item"
            title="Usuń kolumnę"
          >
            <DeleteColumn />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().addRowAfter().run()}
            disabled={!editor.can().addRowAfter()}
            className="menu-item"
            title="Dodaj wiersz"
          >
            <InsertColumnBottom />
          </button>
          <button
            type="button"
            onClick={() => editor.chain().focus().deleteRow().run()}
            disabled={!editor.can().deleteRow()}
            className="menu-item"
            title="Usuń wiersz"
          >
            <DeleteRow />
          </button>
        </div>
      )}
    </>
  );
};
