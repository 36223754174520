import axios, { CancelTokenSource } from 'axios';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { fetchJudgmentKeywords } from 'modules/Judgement/repository';
import { fetchJudgementKeywordsToastError } from 'modules/Judgement/toasts';
import { addToastAction } from 'modules/Layout/action';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { createSearchParams } from 'modules/Shared/helper/params';
import renameDuplicateEntries from 'modules/Shared/helper/renameDuplicateEntries';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

type Props = {
  keywordValue: number;
  onKeywordChange: (keywordValue: number) => void;
  keywordValueIncluded: number[];
};

// eslint-disable-next-line react/display-name
const JudgementKeywordAutocomplete: React.FC<Props> = React.memo(
  ({ keywordValue, onKeywordChange, keywordValueIncluded }) => {
    const dispatch = useDispatch();
    const [fetching, setFetching] = useState(false);
    const [keywordsList, setKeywordsList] = useState<JudgementElementEntity[]>([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const keywordsListWithoutDuplicates = React.useMemo(
      () => renameDuplicateEntries(keywordsList),
      [keywordsList.length]
    );

    const dispatchJudgementKeywordsToastError = (messageValue: string) =>
      dispatch(addToastAction(fetchJudgementKeywordsToastError(messageValue)));

    const cancelToken: React.MutableRefObject<CancelTokenSource> = useRef(null);

    const fetchKeywordsList = async (name?: string) => {
      if (cancelToken.current) {
        cancelToken.current.cancel('Operation canceled due to new request.');
      }
      cancelToken.current = axios.CancelToken.source();

      const filter = name ? { name } : {};
      //  w związku z taskiem PZP2UE-1177 zmienia się endpoint dla słów kluczowych i nie ma potrzeby dodawania query per_page: 9999
      // const searchParams = createSearchParams({ pagination: { per_page: 9999 }, filter });
      const searchParams = createSearchParams({ filter });

      setFetching(true);

      const { data, cancelled, message } = await fetchRequest(
        fetchJudgmentKeywords,
        searchParams,
        cancelToken.current.token
      );

      if (cancelled) return;
      if (data) {
        setKeywordsList(data.data);
        setFetching(false);
      }
      if (message && message.value !== 'Operation canceled due to new request.') {
        if (fetching) setFetching(false);
        dispatchJudgementKeywordsToastError(message.value);
      }
    };

    useEffect(() => {
      fetchKeywordsList();

      return () => {
        if (cancelToken.current) {
          cancelToken.current.cancel('AxiosCancel');
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeValue = (name: string) => {
      const findKeyword = keywordsListWithoutDuplicates.find((keyword) => keyword.name === name);
      if (findKeyword) onKeywordChange(findKeyword.id);
      else if (name === '') onKeywordChange(null);
    };

    const clearList = () => {
      if (cancelToken.current) {
        cancelToken.current.cancel('Operation canceled due to new request.');
      }
    };

    const filterCategoryList = keywordsListWithoutDuplicates.filter((el) => !keywordValueIncluded.includes(el.id));

    const [keywordValueName, setKeywordValueName] = useState('');
    useEffect(() => {
      if (keywordValue) {
        const currentValue = keywordsListWithoutDuplicates.find((el) => el.id === keywordValue)?.name ?? '';
        if (currentValue) {
          setKeywordValueName(currentValue);
        }
      } else if (keywordValueName) {
        setKeywordValueName('');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keywordValue]);

    return (
      <Autocomplete
        currentValue={keywordValueName}
        onChangeCurrentValue={onChangeValue}
        searchRequest={fetchKeywordsList}
        fetching={fetching}
        clearList={clearList}
        list={filterCategoryList as never}
        required
        label="Słowo kluczowe"
      />
    );
  }
);

export default JudgementKeywordAutocomplete;
