import React, { useEffect, useRef, useState } from 'react';
import { CustomInput, FormFeedback, FormGroup, Label, Input } from 'reactstrap';
import { isEqual } from 'lodash';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import {
  AGREEMENT_TYPE_OUTSIDE_ORDER,
  AGREEMENT_TYPE_SUPPLEMENT,
  AgreementCheckboxes,
  AgreementStepProps
} from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_DEADLINE, AGREEMENT_STEP_INDEXATION_CLAUSES } from 'modules/Agreements/step';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import { getError, hasError } from 'modules/Shared/helper/validation';
import Part from 'modules/Agreements/model/Part';
import Accordion from 'modules/Layout/component/Accordion';
import Agreement from 'modules/Agreements/model/Agreement';

export interface AgreementStepIndexationClausesValues {
  indexation_clauses: {
    part_id: number;
    indexation_clauses_checked: boolean;
    indexation_base_checked: boolean;
    indexation_base_content: string | null;
    indexation_start_checked: boolean;
    indexation_start_content: string | null;
    indexation_threshold_checked: boolean;
    indexation_threshold_content: string | null;
    indexation_based_on_checked: boolean;
    indexation_based_on_content: string | null;
    indexation_based_on_other_checked: boolean;
    indexation_based_on_other_content: string | null;
    indexation_obligation_to_notify_checked: boolean;
    indexation_obligation_to_notify_content: string | null;
    indexation_obligation_to_notify_max_checked: boolean;
    indexation_obligation_to_notify_max_content: string | null;
    indexation_maximum_checked: boolean;
    indexation_maximum_content: string | null;
    contract_provisions_checked: boolean;
    contract_provisions_content: string | null;
    reward_change_checked: boolean;
    reward_change_content: string | null;
    duration_months_over: number | null;
  }[];
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const INDEXATION_BASE_CONTENT = 'kosztorysie oraz przedmiarze';
export const INDEXATION_START_CONTENT =
  'Zamawiający przewiduje, że wynagrodzenie będzie podlegało waloryzacji począwszy od ... pełnego miesiąca kalendarzowego od zawarcia Umowy.';
export const INDEXATION_THRESHOLD_CONTENT =
  'Zamawiający przewiduję waloryzację gdy wartość zmiany cen materiałów lub kosztów przekroczy ...%';
export const INDEXATION_BASED_ON_OPTION_1 =
  'wskaźnik wzrostu cen produkcji budowlano – montażowej, publikowany przez Prezesa Głównego Urzędu Statystycznego w Biuletynie Statystycznym';
const INDEXATION_BASED_ON_OPTION_2 =
  'wskazanie innej podstawy, w szczególności wykazu rodzajów materiałów lub kosztów, w przypadku których zmiana ceny uprawnia strony umowy do żądania zmiany wynagrodzenia';
const INDEXATION_BASED_ON_CONTENT = INDEXATION_BASED_ON_OPTION_1;
export const INDEXATION_OBLIGATION_TO_NOTIFY_CONTENT =
  'Wykonawca jest obowiązany powiadomić zamawiającego o podstawie do dokonania waloryzacji maksymalnie w terminie ... dni od daty zaistnienia przesłanek uprawniających do waloryzacji. W tym terminie, wykonawca ma obowiązek wykazać  okoliczności potwierdzające zmianę i przedłożyć kalkulację nowej wysokości wynagrodzenia z zastrzeżeniem pozostałych terminów umownych';
export const INDEXATION_OBLIGATION_TO_NOTIFY_MAX_CONTENT =
  'Wykonawca jest obowiązany zgłosić żądanie waloryzacji nie później niż ... dni przed terminem końcowym realizacji Umowy';
export const INDEXATION_MAXIMUM_CONTENT =
  'Wynagrodzenie będzie podlegało waloryzacji maksymalnie do ...% wynagrodzenia';
const CONTRACT_PROVISIONS_CONTENT =
  'Postanowień umownych w zakresie waloryzacji nie stosuje się od chwili osiągnięcia limitu, o którym mowa powyżej.';
const REWARD_CHANGE_CONTENT =
  'Zmiana wysokości wynagrodzenia opisana w niniejszym ustępie następuje w przypadku ziszczenia się powyższych warunków.';

const initContentValues = {
  indexation_base_content: INDEXATION_BASE_CONTENT,
  indexation_based_on_content: INDEXATION_BASED_ON_CONTENT,
  contract_provisions_content: CONTRACT_PROVISIONS_CONTENT,
  reward_change_content: REWARD_CHANGE_CONTENT
};

const mapState = (
  step: AgreementStepIndexationClausesValues,
  agreement?: Agreement
): AgreementStepIndexationClausesValues => {
  const { indexation_clauses, checkboxes, ...rest } = step;

  return {
    ...rest,
    indexation_clauses: indexation_clauses.map((part) => {
      const {
        duration_months_over,
        indexation_clauses_checked,
        indexation_base_content,
        indexation_based_on_content,
        contract_provisions_content,
        reward_change_content
      } = part;

      const getIndexationClausesCheckedStatus = () =>
        duration_months_over !== 0 && agreement?.type !== AGREEMENT_TYPE_OUTSIDE_ORDER;

      return {
        ...part,
        indexation_clauses_checked: indexation_clauses_checked || getIndexationClausesCheckedStatus(),
        indexation_base_content: indexation_base_content || INDEXATION_BASE_CONTENT,
        indexation_based_on_content: indexation_based_on_content || INDEXATION_BASED_ON_CONTENT,
        contract_provisions_content: contract_provisions_content || CONTRACT_PROVISIONS_CONTENT,
        reward_change_content: reward_change_content || REWARD_CHANGE_CONTENT
      };
    }),
    checkboxes: checkboxes || {}
  };
};

const AgreementStepIndexationClauses = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, onSubmit, onChange, errors } = props;
  const initState = useRef<AgreementStepIndexationClausesValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_INDEXATION_CLAUSES), agreement)
  );
  const [stepValues, setStepValues] = useState<AgreementStepIndexationClausesValues>({ ...initState.current });
  const { same_deadline_for_parts } = getStepValues(steps, AGREEMENT_STEP_DEADLINE);
  const { parts_ids, few_parts } = agreement;
  const isMultiple = few_parts && !same_deadline_for_parts;

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_INDEXATION_CLAUSES), agreement);
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderPartSwitch, renderPartCheckbox, renderCustomCheckboxes, renderPartCheckboxWithNumberInput } =
    useStepFields({
      stepValues,
      setStepValues,
      mapState: (values) => mapState(values, agreement),
      errors,
      initContentValues,
      partSlug: 'indexation_clauses'
    });

  const renderPart = (partObj: Part, index: number) => {
    const { indexation_clauses } = stepValues;

    const part = isMultiple ? indexation_clauses[index] : indexation_clauses[0];

    const { duration_months_over } = part;

    const body = (
      <FormGroup>
        {(duration_months_over === 0 || agreement.type === AGREEMENT_TYPE_OUTSIDE_ORDER) && (
          <p className="h4">Umowa nie musi zawierać klauzul waloryzacyjnych</p>
        )}
        {duration_months_over === 0 || agreement.type === AGREEMENT_TYPE_OUTSIDE_ORDER ? (
          <p>
            Zgodnie z art. 439 Ustawy, Umowa, której przedmiotem są roboty budowlane, dostawy lub usługi, zawarta na
            okres dłuższy niż 6 miesięcy, zawiera postanowienia dotyczące zasad wprowadzania zmian wysokości
            wynagrodzenia należnego wykonawcy w przypadku zmiany ceny materiałów lub kosztów związanych z realizacją
            zamówienia. Jednocześnie w związku z treścią kroku Termin Wykonania umowy Asystent Postępowania nie ma
            pewności, że zachodzą przesłanki wskazane w treści art. 439 Ustawy
          </p>
        ) : (
          <p>
            Zgodnie z art. 439 Ustawy, Umowa, której przedmiotem są roboty budowlane, dostawy lub usługi, zawarta na
            okres dłuższy niż 6 miesięcy, zawiera postanowienia dotyczące zasad wprowadzania zmian wysokości
            wynagrodzenia należnego wykonawcy w przypadku zmiany ceny materiałów lub kosztów związanych z realizacją
            zamówienia. W związku z treścią kroku Termin Wykonania umowy zostały spełnione przesłanki, o których mowa w
            art. 439 Ustawy do wprowadzenia obligatoryjnych zasad waloryzacji wynagrodzenia
          </p>
        )}
        {renderPartSwitch(
          'indexation_clauses_checked',
          'Czy chcesz wprowadzić do Umowy klauzule waloryzacyjne?',
          index,
          part,
          (duration_months_over !== 0 && agreement.type !== AGREEMENT_TYPE_OUTSIDE_ORDER) ||
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {part.indexation_clauses_checked && (
          <>
            <FormGroup key="indexation_base_content_radio">
              <Label>
                Podstawą waloryzacji kosztowo – materiałowej jest wykaz kosztów, materiałów, cen określonych w:
              </Label>
              <CustomInput
                key={`kosztorysie_oraz_przedmiarze_part_${index}`}
                id={`kosztorysie_oraz_przedmiarze_part_${index}`}
                label="kosztorysie oraz przedmiarze"
                value="kosztorysie oraz przedmiarze"
                type="radio"
                required
                disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
                checked={part.indexation_base_content === 'kosztorysie oraz przedmiarze'}
                onChange={(event) => {
                  part['indexation_base_content'] = event.target.value;
                  setStepValues((values) => mapState(values));
                }}
              />
              <CustomInput
                key={`wykazie_kosztów_oraz_materiałów_part_${index}`}
                id={`wykazie_kosztów_oraz_materiałów_part_${index}`}
                label="wykazie kosztów oraz materiałów"
                value="wykazie kosztów oraz materiałów"
                type="radio"
                required
                disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
                checked={part.indexation_base_content === 'wykazie kosztów oraz materiałów'}
                onChange={(event) => {
                  part['indexation_base_content'] = event.target.value;
                  setStepValues((values) => mapState(values));
                }}
              />
            </FormGroup>
            <hr />
            {renderPartCheckboxWithNumberInput(
              'indexation_start',
              INDEXATION_START_CONTENT,
              index,
              part,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT
            )}
            {renderPartCheckboxWithNumberInput(
              'indexation_threshold',
              INDEXATION_THRESHOLD_CONTENT,
              index,
              part,
              false,
              true
            )}
            <hr />
            <>
              <FormGroup key="indexation_based_on_content_radio">
                <Label>Waloryzacja będzie odbywać się w oparciu o:</Label>
                <CustomInput
                  key={`indexation_based_on_option_1_part_${index}`}
                  id={`indexation_based_on_option_1_part_${index}`}
                  label={INDEXATION_BASED_ON_OPTION_1}
                  type="radio"
                  required
                  disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
                  checked={part.indexation_based_on_checked}
                  onChange={(event) => {
                    const { checked } = event.target;

                    part['indexation_based_on_checked'] = checked;
                    part['indexation_based_on_other_checked'] = !checked;

                    setStepValues((values) => mapState(values));
                  }}
                  invalid={
                    hasError(errors, `indexation_clauses.${index}.indexation_based_on_checked`) ||
                    hasError(errors, `indexation_clauses.${index}.indexation_based_on_content`)
                  }
                />
                {(hasError(errors, `indexation_clauses.${index}.indexation_based_on_checked`) ||
                  hasError(errors, `indexation_clauses.${index}.indexation_based_on_content`)) && (
                  <FormFeedback className="d-block">
                    {getError(errors, `indexation_clauses.${index}.indexation_based_on_checked`) ||
                      getError(errors, `indexation_clauses.${index}.indexation_based_on_content`)}
                  </FormFeedback>
                )}
                <CustomInput
                  key={`indexation_based_on_option_2_part_${index}`}
                  id={`indexation_based_on_option_2_part_${index}`}
                  label={INDEXATION_BASED_ON_OPTION_2}
                  type="radio"
                  required
                  disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
                  checked={part.indexation_based_on_other_checked}
                  onChange={(event) => {
                    const { checked } = event.target;

                    part['indexation_based_on_other_checked'] = checked;
                    part['indexation_based_on_checked'] = !checked;

                    setStepValues((values) => mapState(values));
                  }}
                  invalid={
                    hasError(errors, `indexation_clauses.${index}.indexation_based_on_other_checked`) ||
                    hasError(errors, `indexation_clauses.${index}.indexation_based_on_other_content`)
                  }
                />
                {(hasError(errors, `indexation_clauses.${index}.indexation_based_on_other_checked`) ||
                  hasError(errors, `indexation_clauses.${index}.indexation_based_on_other_content`)) && (
                  <FormFeedback className="d-block">
                    {getError(errors, `indexation_clauses.${index}.indexation_based_on_other_checked`) ||
                      getError(errors, `indexation_clauses.${index}.indexation_based_on_other_content`)}
                  </FormFeedback>
                )}
                {part.indexation_based_on_other_checked && (
                  <FormGroup key="indexation_based_on_other_content" className="ml-4 mt-2">
                    <Input
                      key={`indexation_based_on_other_content_part_${index}`}
                      id={`indexation_based_on_other_content_part_${index}`}
                      name="indexation_based_on_other_content"
                      type="textarea"
                      disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
                      value={part.indexation_based_on_other_content}
                      onChange={(event) => {
                        part['indexation_based_on_other_content'] = event.target.value;

                        setStepValues((values) => mapState(values));
                      }}
                      invalid={hasError(errors, `indexation_clauses.${index}.indexation_based_on_other_content`)}
                    />
                    {hasError(errors, `indexation_clauses.${index}.indexation_based_on_other_content`) && (
                      <FormFeedback className="d-block">
                        {getError(errors, `indexation_clauses.${index}.indexation_based_on_other_content`)}
                      </FormFeedback>
                    )}
                  </FormGroup>
                )}
              </FormGroup>
            </>
            <hr />
            {renderPartCheckboxWithNumberInput(
              'indexation_obligation_to_notify',
              INDEXATION_OBLIGATION_TO_NOTIFY_CONTENT,
              index,
              part,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT
            )}
            {renderPartCheckboxWithNumberInput(
              'indexation_obligation_to_notify_max',
              INDEXATION_OBLIGATION_TO_NOTIFY_MAX_CONTENT,
              index,
              part,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT
            )}
            {renderPartCheckboxWithNumberInput(
              'indexation_maximum',
              INDEXATION_MAXIMUM_CONTENT,
              index,
              part,
              false,
              true
            )}
            {renderPartCheckbox(
              'contract_provisions_checked',
              part.contract_provisions_content,
              index,
              part,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT
            )}
            {renderPartCheckbox(
              'reward_change_checked',
              part.reward_change_content,
              index,
              part,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT
            )}
          </>
        )}
      </FormGroup>
    );

    if (isMultiple) {
      return (
        <Accordion
          key={`renderPartAccordion-${partObj.id}`}
          isInvalid={hasError(errors, `indexation_clauses.${index}`)}
          accordionContentStyle={{ padding: '1rem' }}
          entity={{
            title: partObj.getName(index),
            content: body
          }}
        />
      );
    }

    return body;
  };

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      {(isMultiple ? parts_ids : [null]).map(renderPart)}
      {isMultiple && (
        <>
          <p className="h4">Ponadto dla każdego z zadań:</p>
          {renderCustomCheckboxes(
            null,
            null,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )}
        </>
      )}
    </AgreementFormWrapper>
  );
};

export default AgreementStepIndexationClauses;
