import { RootState } from 'app/reducer';
import { setJudgementListParamsAction } from 'modules/Judgement/action/JudgementList';
import Judgement, { JudgementEntity } from 'modules/Judgement/model/Judgement';
import Table, { TableCol } from 'modules/Layout/component/Table/AccordionTable';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { PartialSearchingProps } from 'modules/Shared/type';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

type Props = { onSelectedJudgmentClick?: (judgement: JudgementEntity) => void; selectedJudgment?: number };

const ConnectedJudgementAddListTable: React.FC<Props> = ({ onSelectedJudgmentClick, selectedJudgment }) => {
  const dispatch = useDispatch();
  const { list, sort } = useSelector((state: RootState) => state.judgement.judgementList);

  const setParams = (payload: PartialSearchingProps) => dispatch(setJudgementListParamsAction(payload));

  const cols: TableCol<Judgement>[] = [
    {
      property: 'id',
      label: 'ID',
      sortable: true
    },
    {
      property: 'identifier',
      label: 'Identyfikator publikacji',
      sortable: true
    },
    {
      property: 'authority_name',
      label: 'Nazwa organu publikującego dokument',
      sortable: true
    },
    {
      property: 'authority_type',
      label: 'Rodzaj organu publikującego dokument',
      sortable: true
    },
    {
      property: 'document_type',
      label: 'Rodzaj dokumentu',
      sortable: true
    },
    {
      property: 'published_at ',
      label: 'Termin publikacji',
      sortable: true,
      value: (row) => ifContainsDateFormat(row.published_at, formatDate)
    },
    {
      property: 'place',
      label: 'Oryginalne miejsce publikacji',
      sortable: true
    }
  ];

  return (
    <Table
      cols={cols}
      rows={list}
      sort={sort}
      onSort={(params) => setParams({ sort: params })}
      displayErrorRow={(row) => (selectedJudgment === row?.id ? 'success-table-field' : '')}
      onRowClick={onSelectedJudgmentClick}
    />
  );
};

export default ConnectedJudgementAddListTable;
