import React, { useEffect, useRef, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { isEqual, isEmpty } from 'lodash';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_CONTRACT_CHANGES } from 'modules/Agreements/step';
import StepImportDataButton from 'modules/Agreements/components/StepImportDataButton';
import Accordion from 'modules/Layout/component/Accordion';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import { hasError } from 'modules/Shared/helper/validation';
import { IMPORT_ORDERS_ACCESS } from '../../../../../Auth/container/Guard/permissions';
import Authorize from '../../../../../Auth/container/Authorize';

export interface AgreementStepContractChangesValues {
  contract_changes_checked: boolean;
  executor_reward_range_checked: boolean;
  executor_reward_range_content: string | null;
  executor_reward_benefit_checked: boolean;
  executor_reward_benefit_content: string | null;
  executor_reward_payment_terms_checked: boolean;
  executor_reward_payment_terms_content: string | null;
  deadlines_force_majeure_checked: boolean;
  deadlines_force_majeure_content: string | null;
  deadlines_documentation_changes_checked: boolean;
  deadlines_documentation_changes_content: string | null;
  technology_manufacturing_changes_checked: boolean;
  technology_manufacturing_changes_content: string | null;
  technology_law_changes_checked: boolean;
  technology_law_changes_content: string | null;
  technology_no_materials_changes_checked: boolean;
  technology_no_materials_changes_content: string | null;
  technology_better_materials_changes_checked: boolean;
  technology_better_materials_changes_content: string | null;
  executor_give_reason_checked: boolean;
  executor_give_reason_content: string | null;
  executor_not_able_to_realize_checked: boolean;
  executor_not_able_to_realize_content: string | null;
  employee_change_checked: boolean;
  employee_change_content: string | null;
  force_majeure_checked: boolean;
  force_majeure_no_responsibility_checked: boolean;
  force_majeure_no_responsibility_content: string | null;
  force_majeure_immediate_information_checked: boolean;
  force_majeure_immediate_information_content: string | null;
  force_majeure_deadline_changes_checked: boolean;
  force_majeure_deadline_changes_content: string | null;
  force_majeure_no_penalties_checked: boolean;
  force_majeure_no_penalties_content: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

export interface AgreementStepContractChangesImportValues extends AgreementStepContractChangesValues {}

const EXECUTOR_REWARD_RANGE_CONTENT =
  'ograniczenie zakresu rzeczowego i zmianę wynagrodzenia maksymalnie o ........, w sytuacji gdy zamawiający oświadczy o rezygnacji z części Umowy';
const EXECUTOR_REWARD_BENEFIT_CONTENT =
  'zmiana wynagrodzenia w związku ze zmianą opisu przedmiotu zamówienia, w szczególności z powodu braku rozwiązań projektowych, konieczności usunięcia błędów lub wprowadzenia zmian w dokumentacji projektowej lub technicznej, na podstawie której realizowany jest Przedmiot Umowy';
const EXECUTOR_REWARD_PAYMENT_TERMS_CONTENT =
  'zmiana warunków płatności (poprzez które rozumie się zmianę częstotliwości płatności lub zmianę zasad wypłaty wynagrodzenia – bez jego zwiększania), poprzez zmianę przyjętego w Umowie harmonogramu płatności, w szczególności w sytuacji, w której zmianie ulega harmonogram realizacji Umowy lub ze względu na uzasadnioną sytuację finansową wykonawcy konieczna jest zmiana częstotliwości płatności wynagrodzenia lub wprowadzenie płatności częściowych. Jeżeli wykonawca wnioskuje o zmianę warunków płatności, musi udowodnić, iż występują okoliczności uzasadniające zmianę warunków płatności';
const DEADLINES_FORCE_MAJEURE_CONTENT =
  'wystąpienie siły wyższej pod pojęciem której rozumie się wszystkie zdarzenia zewnętrzne i niemożliwe do przewidzenia i niemożliwe do zapobieżenia przez stronę lub strony Umowy, a zaistniałe po zawarciu Umowy, w szczególności takie jak: wojny, działania wojenne, inwazje, terroryzm, rewolucje, powstania, wojny domowe, rozruchy, z wyjątkiem tych, które są ograniczone wyłącznie do pracowników wykonawcy lub jego podwykonawców lub zamawiającego, zanieczyszczenie i inne podobne niebezpieczne skutki spowodowane przez substancje toksyczne, z wyjątkiem tych, które mogą być przypisane użyciu przez wykonawcę, działania sił przyrody, w tym huragany lub powodzie, a którym Strona Umowy nie mogła zapobiec, stan nadzwyczajny (np. stan klęski żywiołowej, stan wojenny, stan wyjątkowy). Zamawiający dopuszcza zmianę terminu zakończenia realizacji Przedmiotu umowy, poprzez przedłużenie o okres takiego opóźnienia';
const DEADLINES_DOCUMENTATION_CHANGES_CONTENT =
  'zmiana terminu wykonania zamówienia w związku z brakiem rozwiązań projektowych, konieczności wprowadzenia zmian w dokumentacji projektowej lub technicznej, na podstawie której realizowany jest Przedmiot Umowy, zmian powodujących wstrzymanie lub przerwanie dostaw stanowiących Przedmiot Umowy, przy czym przedłużenie terminu realizacji zamówienia nastąpi o liczbę dni niezbędną do wprowadzenia zmian w dokumentacji projektowej lub technicznej oraz do przeprowadzenia uzgodnień (ustaleń) z właściwymi organami, uzyskania opinii właściwych organów oraz wydania decyzji przez właściwe organy';
const TECHNOLOGY_MANUFACTURING_CHANGES_CONTENT =
  'zmiana parametrów określających Przedmiot Umowy, w szczególności poprzez zastosowanie innych rozwiązań technicznych lub materiałowych powodujących: osiągnięcie wymaganego efektu przy niższych kosztach wykonania Umowy, zwiększenie jakości, parametrów technicznych lub eksploatacyjnych Przedmiotu Umowy, skrócenie terminu realizacji zamówienia, uniknięcie niewykonania lub wadliwego wykonania Przedmiotu Umowy';
const TECHNOLOGY_LAW_CHANGES_CONTENT =
  'konieczność zrealizowania Przedmiotu Umowy przy zastosowaniu innych rozwiązań technicznych, organizacyjnych lub materiałowych ze względu na zmiany obowiązującego prawa';
const TECHNOLOGY_NO_MATERIALS_CHANGES_CONTENT =
  'zamiany w zakresie dostarczanych urządzeń, materiałów, w przypadku gdy wykonawca nie jest w stanie nabyć na rynku zaoferowanego w ofercie wykonawcy urządzenia, materiału np.: w związku z wycofaniem urządzenia lub elementu urządzenia, materiału z produkcji lub wprowadzeniem nowej wersji zamawianych urządzeń lub ich elementów, materiałów. Zaoferowane przez wykonawcę urządzenie, materiał musi charakteryzować się wyższymi lub lepszymi parametrami niż wymagane były przez zamawiającego i zamawiający musi wyrazić na nie zgodę';
const TECHNOLOGY_BETTER_MATERIALS_CHANGES_CONTENT =
  'zmiany materiałów i urządzeń pod warunkiem, że zmiany te nie spowodują obniżenia parametrów tych materiałów lub urządzeń, będą korzystne dla zamawiającego, a w szczególności spowodują obniżenie kosztu ponoszonego przez zamawiającego na eksploatację i konserwację wykonanego Przedmiotu Umowy, poprawią  parametry techniczne, wynikają z aktualizacji rozwiązań z uwagi na postęp technologiczny lub zmiany  obowiązujących  przepisów';
const EXECUTOR_GIVE_REASON_CONTENT =
  'zmiana wykonawcy, któremu zamawiający udzielił zamówienia na nowego wykonawcę, w sytuacji gdy dotychczasowy wykonawca ........, a nowy wykonawca spełnia wszystkie wymagania określone w dokumentach zamówienia oraz zobowiąże się przejąć wszelkie zobowiązania ciążące na dotychczasowym wykonawcy';
const EXECUTOR_NOT_ABLE_TO_REALIZE_CONTENT =
  'zmiana wykonawcy realizacji zamówienia publicznego w przypadku gdy wykonawca z którym została zawarta Umowa wykaże i wyjaśni, że nie jest w stanie zrealizować zamówienia zgodnie z umową. Umowa może być wtedy zawarta z innym wykonawcą na tych samych warunkach. Nowy wykonawca musi wykazać spełnienie warunków udziału w postępowaniu oraz kryteriów oceny ofert określonych w dokumentach zamówienia w zakresie nie mniejszym niż dotychczasowy wykonawca. Nowy wykonawca musi także wykazać brak podstaw do wykluczenia w zakresie określonym w dokumentach zamówienia. Nowy wykonawca odpowiada solidarnie z dotychczasowym wykonawcą za zakres umowy dotychczas zrealizowany';
const EMPLOYEE_CHANGE_CONTENT =
  'zmiana osób wskazanych przez wykonawcę do realizacji zamówienia. Zmiana osoby (w trakcie realizacji Przedmiotu Umowy) możliwa jest wyłącznie za zgodą zamawiającego, w sytuacji gdy nowa, wskazana przez wykonawcę osoba posiada kompetencje i doświadczenie nie gorsze niż te określone w dokumentach zamówienia oraz ofercie wykonawcy. Zamawiający może w każdym czasie zażądać od wykonawcy zmiany osoby, w sytuacji gdy powstaną uzasadnione wątpliwości co do kwalifikacji i kompetencji tej osoby. Wykonawca zobowiązany będzie dokonać zmiany osoby na żądanie zamawiającego w terminie nie dłuższym niż 30 dni od daty otrzymania żądania. Nowa, wskazana przez wykonawcę osoba, musi posiadać kompetencje i doświadczenie nie gorsze niż te określone w dokumentach zamówienia oraz ofercie wykonawcy. Zamawiający w terminie nie dłuższym niż 14 dni od daty otrzymania od Wykonawcy propozycji nowej osoby, dokona weryfikacji jej kompetencji i doświadczenia. Zgoda zamawiającego lub odmowa akceptacji proponowanej przez wykonawcę osoby musi zostać wyrażona w formie pisemnej w terminie nie dłuższym niż 14 dni od daty otrzymania przez zamawiającego kompletnej propozycji zaangażowania nowej osoby od wykonawcy. Zmiana ta nie wymaga zawarcia aneksu do Umowy i wejdzie w życie z chwilą otrzymania przez wykonawcę pisemnej zgody zamawiającego na nową osobę';
const FORCE_MAJEURE_NO_RESPONSIBILITY_CONTENT =
  'Strony zgodnie postanawiają, że nie są odpowiedzialne za skutki wynikające z działania siły wyżej. Przez siłę wyższą strony rozumieją zdarzenie o charakterze przypadkowym lub naturalnym, niezależne od woli człowieka i niemożliwe do uniknięcia, w szczególności zdarzenia takie jak pożar, powódź, atak terrorystyczny, klęski żywiołowe, epidemie, pandemie, w tym pandemia COVID-19';
const FORCE_MAJEURE_IMMEDIATE_INFORMATION_CONTENT =
  'Strona, która nie może prawidłowo wykonywać umowy wskutek działania siły wyższej jest obowiązana do bezzwłocznego poinformowania drugiej Strony o niemożliwości prawidłowego wykonywania umowy wskutek działania siły wyższej, pod rygorem utraty uprawnienia do powoływania się na tę okoliczność';
const FORCE_MAJEURE_DEADLINE_CHANGES_CONTENT =
  'W przypadku wystąpienia siły wyższej termin realizacji Umowy może zostać przedłużony o liczbę dni odpowiadającą okresowi opóźnienia wywołanego działaniem siły wyższej';
const FORCE_MAJEURE_NO_PENALTIES_CONTENT =
  'W przypadku wystąpienia siły wyższej Strony mogą odstąpić od naliczania kar umownych, których podstawa naliczania powstała w związku z działaniem siły wyższej';

const initContentValues = {
  executor_reward_range_content: EXECUTOR_REWARD_RANGE_CONTENT,
  executor_reward_benefit_content: EXECUTOR_REWARD_BENEFIT_CONTENT,
  executor_reward_payment_terms_content: EXECUTOR_REWARD_PAYMENT_TERMS_CONTENT,
  deadlines_force_majeure_content: DEADLINES_FORCE_MAJEURE_CONTENT,
  deadlines_documentation_changes_content: DEADLINES_DOCUMENTATION_CHANGES_CONTENT,
  technology_manufacturing_changes_content: TECHNOLOGY_MANUFACTURING_CHANGES_CONTENT,
  technology_law_changes_content: TECHNOLOGY_LAW_CHANGES_CONTENT,
  technology_no_materials_changes_content: TECHNOLOGY_NO_MATERIALS_CHANGES_CONTENT,
  technology_better_materials_changes_content: TECHNOLOGY_BETTER_MATERIALS_CHANGES_CONTENT,
  executor_give_reason_content: EXECUTOR_GIVE_REASON_CONTENT,
  executor_not_able_to_realize_content: EXECUTOR_NOT_ABLE_TO_REALIZE_CONTENT,
  employee_change_content: EMPLOYEE_CHANGE_CONTENT,
  force_majeure_no_responsibility_content: FORCE_MAJEURE_NO_RESPONSIBILITY_CONTENT,
  force_majeure_immediate_information_content: FORCE_MAJEURE_IMMEDIATE_INFORMATION_CONTENT,
  force_majeure_deadline_changes_content: FORCE_MAJEURE_DEADLINE_CHANGES_CONTENT,
  force_majeure_no_penalties_content: FORCE_MAJEURE_NO_PENALTIES_CONTENT
};

const mapState = (step: AgreementStepContractChangesValues): AgreementStepContractChangesValues => {
  const {
    executor_reward_range_content,
    executor_reward_benefit_content,
    executor_reward_payment_terms_content,
    deadlines_force_majeure_content,
    deadlines_documentation_changes_content,
    technology_manufacturing_changes_content,
    technology_law_changes_content,
    technology_no_materials_changes_content,
    technology_better_materials_changes_content,
    executor_give_reason_content,
    executor_not_able_to_realize_content,
    employee_change_content,
    force_majeure_no_responsibility_content,
    force_majeure_immediate_information_content,
    force_majeure_deadline_changes_content,
    force_majeure_no_penalties_content,
    checkboxes,
    ...rest
  } = step;

  return {
    ...rest,
    executor_reward_range_content: executor_reward_range_content || EXECUTOR_REWARD_RANGE_CONTENT,
    executor_reward_benefit_content: executor_reward_benefit_content || EXECUTOR_REWARD_BENEFIT_CONTENT,
    executor_reward_payment_terms_content:
      executor_reward_payment_terms_content || EXECUTOR_REWARD_PAYMENT_TERMS_CONTENT,
    deadlines_force_majeure_content: deadlines_force_majeure_content || DEADLINES_FORCE_MAJEURE_CONTENT,
    deadlines_documentation_changes_content:
      deadlines_documentation_changes_content || DEADLINES_DOCUMENTATION_CHANGES_CONTENT,
    technology_manufacturing_changes_content:
      technology_manufacturing_changes_content || TECHNOLOGY_MANUFACTURING_CHANGES_CONTENT,
    technology_law_changes_content: technology_law_changes_content || TECHNOLOGY_LAW_CHANGES_CONTENT,
    technology_no_materials_changes_content:
      technology_no_materials_changes_content || TECHNOLOGY_NO_MATERIALS_CHANGES_CONTENT,
    technology_better_materials_changes_content:
      technology_better_materials_changes_content || TECHNOLOGY_BETTER_MATERIALS_CHANGES_CONTENT,
    executor_give_reason_content: executor_give_reason_content || EXECUTOR_GIVE_REASON_CONTENT,
    executor_not_able_to_realize_content: executor_not_able_to_realize_content || EXECUTOR_NOT_ABLE_TO_REALIZE_CONTENT,
    employee_change_content: employee_change_content || EMPLOYEE_CHANGE_CONTENT,
    force_majeure_no_responsibility_content:
      force_majeure_no_responsibility_content || FORCE_MAJEURE_NO_RESPONSIBILITY_CONTENT,
    force_majeure_immediate_information_content:
      force_majeure_immediate_information_content || FORCE_MAJEURE_IMMEDIATE_INFORMATION_CONTENT,
    force_majeure_deadline_changes_content:
      force_majeure_deadline_changes_content || FORCE_MAJEURE_DEADLINE_CHANGES_CONTENT,
    force_majeure_no_penalties_content: force_majeure_no_penalties_content || FORCE_MAJEURE_NO_PENALTIES_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepContractChanges = (props: AgreementStepProps): JSX.Element => {
  const { steps, stepsImportData, onSubmit, onChange, errors } = props;
  const initState = useRef<AgreementStepContractChangesValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_CONTRACT_CHANGES))
  );
  const [stepValues, setStepValues] = useState<AgreementStepContractChangesValues>({ ...initState.current });
  const stepDataImportValues: AgreementStepContractChangesImportValues = getStepValues(
    stepsImportData,
    AGREEMENT_STEP_CONTRACT_CHANGES
  );

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_CONTRACT_CHANGES));
    setStepValues({ ...initState.current });
  }, [steps]);

  const onStepDataImportClick = () => {
    setStepValues((values) => {
      return {
        ...values,
        ...stepDataImportValues
      };
    });
  };

  const { renderCheckbox, renderEditableCheckbox, renderSwitch, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    initContentValues
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <Authorize permissions={[IMPORT_ORDERS_ACCESS]}>
        <StepImportDataButton onSubmit={onStepDataImportClick} stepDataImportValues={stepDataImportValues} />
      </Authorize>
      <FormGroup>
        {renderSwitch(
          'contract_changes_checked',
          'Czy w umowie mają zostać wskazane warunki istotnych zmian postanowień zawartej umowy?'
        )}
        {!isEmpty(stepValues.checkboxes.proceeding_content) &&
          'Zamawiający zastrzega możliwość wprowadzenia istotnych zmian postanowień zawartej umowy. W szczególności postanowienia umowy mogą ulec zmianie w następującym zakresie oraz na następujących warunkach:'}
        {renderCustomCheckboxes('proceeding_content', null, true, true)}
        {stepValues.contract_changes_checked && (
          <>
            <Accordion
              isInvalid={
                hasError(errors, 'executor_reward_range_checked') ||
                hasError(errors, 'executor_reward_range_content') ||
                hasError(errors, 'executor_reward_benefit_checked') ||
                hasError(errors, 'executor_reward_benefit_content') ||
                hasError(errors, 'executor_reward_payment_terms_checked') ||
                hasError(errors, 'executor_reward_payment_terms_content')
              }
              entity={{
                title: 'Wynagrodzenie wykonawcy',
                content: (
                  <>
                    {renderEditableCheckbox('executor_reward_range')}
                    {renderCheckbox('executor_reward_benefit_checked', stepValues.executor_reward_benefit_content)}
                    {renderCheckbox(
                      'executor_reward_payment_terms_checked',
                      stepValues.executor_reward_payment_terms_content
                    )}
                  </>
                )
              }}
            />
            <Accordion
              isInvalid={
                hasError(errors, 'deadlines_force_majeure_checked') ||
                hasError(errors, 'deadlines_force_majeure_content') ||
                hasError(errors, 'deadlines_documentation_changes_checked') ||
                hasError(errors, 'deadlines_documentation_changes_content')
              }
              entity={{
                title: 'Termin wykonania zamówienia',
                content: (
                  <>
                    {renderCheckbox('deadlines_force_majeure_checked', stepValues.deadlines_force_majeure_content)}
                    {renderCheckbox(
                      'deadlines_documentation_changes_checked',
                      stepValues.deadlines_documentation_changes_content
                    )}
                  </>
                )
              }}
            />
            <Accordion
              isInvalid={
                hasError(errors, 'technology_manufacturing_changes_checked') ||
                hasError(errors, 'technology_manufacturing_changes_content') ||
                hasError(errors, 'technology_law_changes_checked') ||
                hasError(errors, 'technology_law_changes_content') ||
                hasError(errors, 'technology_no_materials_changes_checked') ||
                hasError(errors, 'technology_no_materials_changes_content') ||
                hasError(errors, 'technology_better_materials_changes_checked') ||
                hasError(errors, 'technology_better_materials_changes_content')
              }
              entity={{
                title: 'Zmiany technologiczne lub organizacyjne',
                content: (
                  <>
                    {renderCheckbox(
                      'technology_manufacturing_changes_checked',
                      stepValues.technology_manufacturing_changes_content
                    )}
                    {renderCheckbox('technology_law_changes_checked', stepValues.technology_law_changes_content)}
                    {renderCheckbox(
                      'technology_no_materials_changes_checked',
                      stepValues.technology_no_materials_changes_content
                    )}
                    {renderCheckbox(
                      'technology_better_materials_changes_checked',
                      stepValues.technology_better_materials_changes_content
                    )}
                  </>
                )
              }}
            />
            <Accordion
              isInvalid={
                hasError(errors, 'executor_give_reason_checked') ||
                hasError(errors, 'executor_give_reason_content') ||
                hasError(errors, 'executor_not_able_to_realize_checked') ||
                hasError(errors, 'executor_not_able_to_realize_content')
              }
              entity={{
                title: 'Zmiana wykonawcy',
                content: (
                  <>
                    {renderEditableCheckbox('executor_give_reason')}
                    {renderCheckbox(
                      'executor_not_able_to_realize_checked',
                      stepValues.executor_not_able_to_realize_content
                    )}
                  </>
                )
              }}
            />
            <Accordion
              isInvalid={hasError(errors, 'employee_change_checked') || hasError(errors, 'employee_change_content')}
              entity={{
                title: 'Zmiana osób',
                content: <>{renderCheckbox('employee_change_checked', stepValues.employee_change_content)}</>
              }}
            />
            {renderCustomCheckboxes('change_conditions')}
          </>
        )}
        <hr />
        <>
          {renderSwitch('force_majeure_checked', 'Czy dodać postanowienia dotyczące działania siły wyższej?')}
          {stepValues.force_majeure_checked && (
            <>
              <Label>Proszę o wskazanie w jakim zakresie mogą ulec zmianie postanowienia umowy?</Label>
              <div className="mb-2">{renderCustomCheckboxes('force_majeure_additional')}</div>
              {renderCheckbox(
                'force_majeure_no_responsibility_checked',
                stepValues.force_majeure_no_responsibility_content
              )}
              {renderCheckbox(
                'force_majeure_immediate_information_checked',
                stepValues.force_majeure_immediate_information_content
              )}
              {renderCheckbox(
                'force_majeure_deadline_changes_checked',
                stepValues.force_majeure_deadline_changes_content
              )}
              {renderCheckbox('force_majeure_no_penalties_checked', stepValues.force_majeure_no_penalties_content)}
              {renderCustomCheckboxes('force_majeure')}
            </>
          )}
          <hr />
        </>
        {renderCustomCheckboxes()}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepContractChanges;
