import { Routes } from 'app/routes';
import Authorize from 'modules/Auth/container/Authorize';
import ClauseCategoriesListView from 'modules/Clause/view/Category';
import ClauseListView from 'modules/Clause/view';
import ClauseCreateView from 'modules/Clause/view/Create';
import ClauseUpdateView from 'modules/Clause/view/Update';
import ClauseDetailsView from 'modules/Clause/view/Details';
import ConnectedJudgementMarkContentView from 'modules/Clause/view/ConnectedJudgementMarkContent';
import React from 'react';
import { Route } from 'react-router-dom';
import {
  CLAUSE_ADD,
  CLAUSE_CATEGORIES_INDEX,
  CLAUSE_DETAILS,
  CLAUSE_EDIT,
  CLAUSE_INDEX
} from 'modules/Clause/permissions';
import ConnectedJudgementListView from 'modules/Clause/component/Details/ConnectedJudgements/List';
import { JUDGMENT_KEYWORDS_INDEX } from 'modules/Judgement/permissions';
import KeywordsWithCategoriesList from 'modules/Judgement/view/KeywordsWithCategoriesList';

export const ROUTE_CLAUSE_CATEGORIES = '/clause/category';
export const ROUTE_CLAUSE_KEYWORDS = '/clause/keyword/';
export const ROUTE_CLAUSE_CREATE = '/clause/create';
export const ROUTE_CLAUSE = '/clause/:id(\\d+)';
export const ROUTE_CLAUSE_UPDATE = '/clause/update/:id(\\d+)';
export const ROUTE_CLAUSE_LIST = '/clause';
export const ROUTE_CLAUSE_JUDGEMENT_ADD_LIST = '/clause/:id(\\d+)/list';
export const ROUTE_CLAUSE_JUDGEMENT_ADD_CONTENT = '/clause/:id(\\d+)/content-included';

export const createClauseRoutes = (): Routes => ({
  wrapped: [
    <Authorize key="clause-categories" permissions={[CLAUSE_CATEGORIES_INDEX]}>
      <Route path={ROUTE_CLAUSE_CATEGORIES} exact component={ClauseCategoriesListView} />
    </Authorize>,
    <Authorize key="clause-keywords" permissions={[JUDGMENT_KEYWORDS_INDEX]}>
      <Route path={ROUTE_CLAUSE_KEYWORDS} exact component={KeywordsWithCategoriesList} />
    </Authorize>,
    <Authorize key="clause-create" permissions={[CLAUSE_ADD]}>
      <Route path={ROUTE_CLAUSE_CREATE} exact component={ClauseCreateView} />
    </Authorize>,
    <Authorize key="clause-update" permissions={[CLAUSE_EDIT]}>
      <Route path={ROUTE_CLAUSE_UPDATE} exact component={ClauseUpdateView} />
    </Authorize>,
    <Authorize key="clause-details" permissions={[CLAUSE_DETAILS]}>
      <Route path={ROUTE_CLAUSE} exact component={ClauseDetailsView} />
    </Authorize>,
    <Authorize key="clause-list" permissions={[CLAUSE_INDEX]}>
      <Route path={ROUTE_CLAUSE_LIST} exact component={ClauseListView} />
    </Authorize>,
    <Authorize key="clause-judgement-add-list" permissions={[CLAUSE_ADD]}>
      <Route path={ROUTE_CLAUSE_JUDGEMENT_ADD_LIST} exact component={ConnectedJudgementListView} />
    </Authorize>,
    <Authorize key="clause-judgement-add-content" permissions={[CLAUSE_ADD]}>
      <Route path={ROUTE_CLAUSE_JUDGEMENT_ADD_CONTENT} exact component={ConnectedJudgementMarkContentView} />
    </Authorize>
  ]
});
