import { addToastAction, managePageAction } from 'modules/Layout/action';
import Loader from 'modules/Layout/component/Loader';
import Form from 'modules/ForeignProceedings/component/Form';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Alert from 'modules/Layout/component/Alert';
import { Message, ValidationErrors } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { push } from 'connected-react-router';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import updateRequest from 'modules/Shared/helper/APIRequests/updateRequest';
import CardBox from 'modules/Layout/component/CardBox';
import {
  createForeignProceedings,
  ForeignProceedingSubmitData
} from 'modules/ForeignProceedings/model/ForeignProceedings';
import { breadcrumbRouteForeignProceedingsUpdate } from 'modules/ForeignProceedings/breadcrumbs';
import ForeignProceedings from '../../model/ForeignProceedings';
import { updateForeignProceedingsToastSuccess } from '../../toasts';
import { fetchForeignProceedingsDetailsAdmin, updateForeignProceedingsAdmin } from '../../repository';
import { ROUTE_FOREIGN_PROCEEDINGS_OPEN_ADMIN } from '../../routes';

export interface Props {
  params?: any;
}

const ForeignProceedingsUpdateView: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<{ foreignProceedings: ForeignProceedings }>();
  const [foreignProceeding, setForeignProceeding] = useState<ForeignProceedings>(null);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  const scrollToTopOnce = useRef<boolean>(true);

  const dispatchUpdateForeignProceedingsToast = () => dispatch(addToastAction(updateForeignProceedingsToastSuccess()));

  const cancelToken = useCancelToken();
  useEffect(() => {
    if (state?.foreignProceedings) {
      setForeignProceeding(createForeignProceedings(state.foreignProceedings));
    } else {
      (async () => {
        setFetching(true);
        const {
          data: reqData,
          cancelled,
          message: messageResponse
        } = await fetchRequest(fetchForeignProceedingsDetailsAdmin, id, cancelToken);

        if (cancelled) return;
        const { data } = reqData;
        if (data) setForeignProceeding(createForeignProceedings(data));
        if (messageResponse) setMessage(messageResponse);
        setFetching(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (foreignProceeding) {
      const title = `Edycja zagranicznogo postępowania${foreignProceeding.name ? ` - ${foreignProceeding.name}` : ''}`;
      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteForeignProceedingsUpdate(foreignProceeding.name, foreignProceeding.id)
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foreignProceeding]);

  const updateForeignProceedingsAction = async (data: ForeignProceedingSubmitData) => {
    if (loading) return;

    if (errors) setErrors(null);
    setLoading(true);
    const {
      cancelled,
      message: messageRes,
      success,
      error
    } = await updateRequest(updateForeignProceedingsAdmin, id, data, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchUpdateForeignProceedingsToast();
      dispatch(push(ROUTE_FOREIGN_PROCEEDINGS_OPEN_ADMIN));
    } else {
      if (messageRes) setMessage(messageRes);
      if (error) setErrors(error);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  if (fetching) return <Loader />;
  if (!fetching && !foreignProceeding && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );

  if (!fetching && foreignProceeding) {
    return (
      <div className="profile-update-view d-flex justify-content-center">
        <CardBox heading="Edycja postępowania">
          {loading && <Loader />}
          <Alert message={message} />
          {foreignProceeding && (
            <Form
              forPrData={foreignProceeding}
              errors={errors}
              submit={updateForeignProceedingsAction}
              disabled={loading}
              cancelRedirect={ROUTE_FOREIGN_PROCEEDINGS_OPEN_ADMIN}
              isEditing
            />
          )}
        </CardBox>
      </div>
    );
  }

  return null;
};

export default ForeignProceedingsUpdateView;
