import React from 'react';
import { Menu } from 'app/menu';
import User from 'modules/User/model/User';
import { ROLE_ADMIN } from 'modules/User/model/UserRole';
import { ROUTE_FEEDBACK } from './routes';
import ContactIcon from '../Layout/component/Icon/Contact';

const FeedbackProfileShared: Menu[] = [
  {
    key: 'feedback',
    type: 'link',
    title: 'Kontakt',
    icon: <ContactIcon />,
    href: ROUTE_FEEDBACK,
    hasAccess: (user: User): boolean => user?.role?.slug !== ROLE_ADMIN
  }
];

export default FeedbackProfileShared;
