import { SettableAuthState } from 'modules/Auth/state';
import { Credentials, RegisterValues, ResetPasswordPayload } from 'modules/Auth/type';
import { ColorVariants, Message } from 'modules/Shared/type';
import { UserEntity } from 'modules/User/model/User';
import { Action, Dispatch } from 'redux';
import { clearToken, loadToken, saveToken, setAuthHeader } from 'modules/Auth/service';
import {
  authenticate,
  confirmAccountActivation,
  confirmEmailChange,
  recoverPassword,
  refreshActivation,
  register,
  requestToken,
  resetPassword
} from 'modules/Auth/repository';
import ApiError from 'modules/Shared/exception/ApiError';
import { push } from 'connected-react-router';
import { ROUTE_DASHBOARD } from 'modules/Layout/routes';
import { createRequestTokenPayload } from 'modules/Auth/helper';
import { ROUTE_LOGIN, ROUTE_REGISTER_SUCCESS } from 'modules/Auth/routes';
import { ROUTE_REGULATION } from 'modules/Regulation/routes';
import resendTokenMessage from 'modules/Shared/helper/resendTokenMessage';

export const AUTHENTICATE = 'AUTH/AUTHENTICATE';
export const AUTHENTICATED = 'AUTH/AUTHENTICATED';
export const LOGOUT = 'AUTH/LOGOUT';
export const SET_AUTH_USER = 'AUTH/USER/SET';
export const SET_AUTH_STATE = 'AUTH/STATE/SET';
export const SET_AUTH_BUSY = 'AUTH/BUSY/SET';
export const SET_AUTH_UNACCEPTED = 'AUTH/BUSY/UNACCEPTED';

export type AuthAction =
  | AuthenticateAction
  | AuthenticatedAction
  | SetAuthUserAction
  | LogoutAction
  | SetAuthStateAction
  | SetBusyAction
  | SetAutUaccepted;

export type AuthenticatedAction = Action<typeof AUTHENTICATED>;

export type AuthenticateAction = Action<typeof AUTHENTICATE>;
export interface SetAuthUserAction extends Action<typeof SET_AUTH_USER> {
  payload: UserEntity;
}

export interface SetAutUaccepted extends Action<typeof SET_AUTH_UNACCEPTED> {
  payload: UserEntity;
}

export const authenticateAction = (conditionaly?: boolean) => async (dispatch: Dispatch) => {
  const token = loadToken();

  if (conditionaly && !token) {
    return;
  }

  dispatch<AuthenticateAction>({
    type: AUTHENTICATE
  });

  if (token) {
    setAuthHeader(token.access_token);
  }

  try {
    const response = await authenticate();
    const { data } = response?.data;

    const { regulations_accepted } = data;
    if (!regulations_accepted) {
      dispatch<SetAutUaccepted>({
        type: SET_AUTH_UNACCEPTED,
        payload: data
      });
      dispatch(push(ROUTE_REGULATION));
      return;
    }

    dispatch<SetAuthUserAction>({
      type: SET_AUTH_USER,
      payload: data
    });
  } catch (error) {
    if (!(error instanceof ApiError)) {
      dispatch<LogoutAction>({
        type: LOGOUT
      });
    }
  }
};

export interface SetAuthStateAction extends Action<typeof SET_AUTH_STATE> {
  payload: SettableAuthState;
}

export const loginAction = (payload: Credentials) => async (dispatch: Dispatch) => {
  dispatch<SetBusyAction>({
    type: SET_AUTH_BUSY
  });

  let state: SettableAuthState = { busy: false };

  try {
    const { data } = await requestToken(createRequestTokenPayload(payload));

    saveToken(data);

    dispatch(push(ROUTE_DASHBOARD));
  } catch (error) {
    if (error?.response?.status === 406 && error?.response?.data?.message === 'Twoje konto nie zostało aktywowane.') {
      state = { ...state, message: resendTokenMessage() as Message };
    }

    if (error instanceof ApiError) {
      state = { ...state, ...error.getPayload() };
    }
  }

  dispatch<SetAuthStateAction>({
    type: SET_AUTH_STATE,
    payload: state
  });
};

export interface LogoutPayload {
  message?: Message;
}
export interface LogoutAction extends Action<typeof LOGOUT> {
  payload?: LogoutPayload;
}

export const logoutAction =
  (payload: LogoutPayload = {}) =>
  (dispatch: Dispatch) => {
    clearToken();

    dispatch(push(ROUTE_LOGIN));

    dispatch<LogoutAction>({
      type: LOGOUT,
      payload
    });
  };

export const registerAction = (payload: RegisterValues) => async (dispatch: Dispatch) => {
  dispatch<SetBusyAction>({
    type: SET_AUTH_BUSY
  });

  let state: SettableAuthState = { busy: false };

  try {
    await register(payload);

    dispatch(push(ROUTE_REGISTER_SUCCESS));
  } catch (error) {
    if (error instanceof ApiError) {
      state = { ...state, ...error.getPayload() };
    }
  }

  dispatch<SetAuthStateAction>({
    type: SET_AUTH_STATE,
    payload: state
  });
};

export interface SetBusyAction {
  type: typeof SET_AUTH_BUSY;
}

export const recoverPasswordAction = (payload: { email: string }) => async (dispatch: Dispatch) => {
  dispatch<SetBusyAction>({
    type: SET_AUTH_BUSY
  });

  let state: SettableAuthState = { busy: false };

  try {
    const { data } = await recoverPassword(payload.email);

    state.message = {
      value: data.message,
      variant: ColorVariants.Success
    };

    dispatch(push(ROUTE_LOGIN));
  } catch (error) {
    if (error instanceof ApiError) {
      state = { ...state, ...error.getPayload() };
    }
  }

  dispatch<SetAuthStateAction>({
    type: SET_AUTH_STATE,
    payload: state
  });
};

export const resetPasswordAction = (payload: ResetPasswordPayload) => async (dispatch: Dispatch) => {
  dispatch<SetBusyAction>({
    type: SET_AUTH_BUSY
  });
  let state: SettableAuthState = { busy: false };
  try {
    const { data } = await resetPassword(payload);

    state.message = {
      value: data.message,
      variant: ColorVariants.Success
    };

    dispatch(push(ROUTE_LOGIN));
  } catch (error) {
    if (error instanceof ApiError) {
      state = { ...state, ...error.getPayload() };
    }
  }

  dispatch<SetAuthStateAction>({
    type: SET_AUTH_STATE,
    payload: state
  });
};

export const confirmEmailChangeAction = (token: string) => async (dispatch: Dispatch) => {
  dispatch<SetBusyAction>({
    type: SET_AUTH_BUSY
  });
  let state: SettableAuthState = { busy: false };
  let message: Message = null;

  try {
    await confirmEmailChange(token);

    message = {
      value: 'Pomyślnie zmieniono adres email.',
      variant: ColorVariants.Success
    };
  } catch (error) {
    if (error instanceof ApiError) {
      state = { ...state, ...error.getPayload() };
    }
  }

  dispatch(logoutAction({ message }) as any);
  dispatch<SetAuthStateAction>({
    type: SET_AUTH_STATE,
    payload: state
  });
};

export const confirmAccountActivationAction = (token: string) => async (dispatch: Dispatch) => {
  dispatch<SetBusyAction>({
    type: SET_AUTH_BUSY
  });
  let state: SettableAuthState = { busy: false };
  let message: Message = null;

  try {
    await confirmAccountActivation(token);

    message = {
      value: 'Pomyślnie aktywowano konto. Możesz się teraz na nie zalogować.',
      variant: ColorVariants.Success
    };
  } catch (error) {
    if (error instanceof ApiError) {
      state = { ...state, ...error.getPayload() };
    }
  }

  dispatch(logoutAction({ message }) as any);
  dispatch<SetAuthStateAction>({
    type: SET_AUTH_STATE,
    payload: state
  });
};

export const refreshActivationAction = (email: string) => async (dispatch: Dispatch) => {
  dispatch<SetBusyAction>({
    type: SET_AUTH_BUSY
  });
  let state: SettableAuthState = { busy: false };
  try {
    const { data } = await refreshActivation(email);

    state.message = {
      value: data?.message ?? '',
      variant: ColorVariants.Success
    };

    dispatch(push(ROUTE_LOGIN));
  } catch (error) {
    if (error instanceof ApiError) {
      state = { ...state, ...error.getPayload() };
    }
  }

  dispatch<SetAuthStateAction>({
    type: SET_AUTH_STATE,
    payload: state
  });
};
