import { ModuleEntity } from 'modules/Module/model/Module';
import React from 'react';
import { ModuleEditModalObj } from 'modules/Module/component/ListDetails';
import { calculateDiffInDays, formatDate } from 'modules/Shared/helper/utils';
import InformationList, { InformationField } from 'modules/Layout/component/InformationList';
import { Button } from 'reactstrap';

type Props = {
  module: ModuleEntity;
  setEditDisplayModal?: React.Dispatch<React.SetStateAction<ModuleEditModalObj>>;
  setCreateDisplayModal?: React.Dispatch<React.SetStateAction<ModuleEditModalObj>>;
  subscriptionDateTo?: string;
  subscriptionDateFrom?: string;
  creditsByDates?: { all_credits_count: number; available_credits: number; date_to: string }[];
  isPublic?: boolean;
  isAdmin?: boolean;
  isUserDeleted?: boolean;
};

const ModuleDataset: React.FC<Props> = ({
  module,
  setEditDisplayModal,
  setCreateDisplayModal,
  subscriptionDateTo,
  subscriptionDateFrom,
  creditsByDates,
  isAdmin,
  isPublic,
  isUserDeleted
}) => {
  const displayCreditsByDates = () => {
    const fields: InformationField[] = [];

    creditsByDates.forEach((credit, index, arr) => {
      const lastElement = arr.length - 1 === index;

      fields.push({
        label: lastElement ? 'Wykupione kredyty' : '',
        listItemClassName: lastElement ? '' : 'border-0',
        value: (
          <span className="text-success">
            Wykupione kredyty{' '}
            <span className="font-weight-bold">{`${credit.available_credits}/${credit.all_credits_count}`}</span> ważne
            do <span className="font-weight-bold">{formatDate(credit.date_to)}</span>
            {setEditDisplayModal && !isUserDeleted && isAdmin && (
              <Button
                className="link-with-border ml-2 p-0"
                color="link"
                onClick={() => setEditDisplayModal({ module, values: credit })}
              >
                EDYTUJ DOSTĘP
              </Button>
            )}
          </span>
        )
      });
    });

    return <InformationList fields={fields} />;
  };

  const displayNotPublicModule = () => {
    const fields: InformationField[] = [];

    const editButton = setEditDisplayModal && !isUserDeleted && isAdmin && (
      <Button
        color="link"
        className="link-with-border ml-2 p-0"
        onClick={() => setEditDisplayModal({ module, values: { date_to: subscriptionDateTo } })}
      >
        {subscriptionDateTo ? 'EDYTUJ DOSTĘP' : 'NOWY DOSTĘP'}
      </Button>
    );

    if (subscriptionDateTo) {
      const days = calculateDiffInDays(subscriptionDateFrom, subscriptionDateTo);

      fields.push({
        label: 'Abonament',
        value: (
          <span className="text-success">
            Pełny dostęp <span className="font-weight-bold">{Math.round(days)} dni</span> ważny do{' '}
            <span className="font-weight-bold">{formatDate(subscriptionDateTo)}</span>
            {editButton}
          </span>
        )
      });
    } else {
      fields.push({
        label: 'Abonament',
        value: (
          <span>
            Nieaktywny
            {editButton}
          </span>
        )
      });
    }

    return (
      <>
        <InformationList fields={fields} />
        {module.has_credits && (
          <>
            {creditsByDates ? (
              displayCreditsByDates()
            ) : (
              <InformationList
                fields={[
                  {
                    label: 'Wykupione kredyty',
                    value: 'Brak'
                  }
                ]}
              />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className="module-wrapper">
        <div className="module-name">{module?.name}</div>
        <div className="module-content">
          {isPublic ? (
            <InformationList
              fields={[
                {
                  label: 'Abonament',
                  value: <span className="text-success">Dostęp publiczny / bezterminowo</span>
                }
              ]}
            />
          ) : (
            displayNotPublicModule()
          )}
          {!isPublic && module.has_credits && setCreateDisplayModal && !isUserDeleted && isAdmin && (
            <Button
              color="link"
              className="link-with-border p-0 ml-3 mt-3 mb-3"
              onClick={() => setCreateDisplayModal({ module })}
            >
              NOWY DOSTĘP
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default ModuleDataset;
