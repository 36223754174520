import React from 'react';
import { Menu } from 'app/menu';
import JudgementsIcon from 'modules/Layout/component/Icon/Judgements';
import { ROUTE_JUDGEMENT_CLIENT_LIST } from 'modules/JudgementClient/routes';
import User from 'modules/User/model/User';
import { ROUTE_CLIENT_CLAUSES } from 'modules/ClauseClient/routes';
import { JUDGMENTS_MODULE_ADMIN_ACCESS } from '../Judgement/permissions';
import { SUBSCRIPTIONS_MODULE_ACCESS } from '../Auth/container/Guard/permissions';
import { hasModule, MODULE_CLAUSE } from '../Module/model/Module';

const MenuJudgmentClientShared: Menu[] = [
  {
    key: 'judgment-client-nested',
    type: 'nested',
    title: process.env.THEME_TEMPLATE === 'bs' ? 'Rejestr orzeczeń, komentarze' : 'Rejestr klauzul',
    icon: <JudgementsIcon />,
    navLevel: 'second',
    href: '#',
    hasAccess: (user: User): boolean =>
      !user?.hasPermissions([JUDGMENTS_MODULE_ADMIN_ACCESS]) &&
      (hasModule(MODULE_CLAUSE, user) || user?.hasPermissions([SUBSCRIPTIONS_MODULE_ACCESS])),
    isActive: (_match: null) => false,
    children: [
      {
        key: 'clauses-client-index',
        type: 'link',
        title: 'Rejestr klauzul niedozwolonych',
        href: ROUTE_CLIENT_CLAUSES
      },
      {
        key: 'judgment-client-index',
        type: 'link',
        title: 'Orzeczenia sądowe',
        href: ROUTE_JUDGEMENT_CLIENT_LIST
      }
    ]
  }
];

export default MenuJudgmentClientShared;
