import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import { breadcrumbRouteExecutorsExtendedRequestDetails } from 'modules/User/breadcrumbs';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { fetchExecutorExtendedRequestDetails } from 'modules/User/repository';
import CardBox from 'modules/Layout/component/CardBox';
import ExecutorExtendedRequestDetailsComponent from '../../../component/ExecutorExtendedRequestDetails';
import { ExecutorExtendedRequest } from '../../../../Executor/model/Violation';
import fetchFileRequest from '../../../../Shared/helper/APIRequests/fetchFileRequest';
import { fetchExecutorExtendedRequestFile } from '../../../../Office/repository';
import { executorRequestFileDownloadToastSuccess } from '../../../../Office/toasts';

const ExecutorExtendedRequestDetails = () => {
  const dispatch = useDispatch();
  const { claim_id, request_id } = useParams<{ claim_id: string; request_id: string }>();
  const [executorExtendedRequest, setExecutorExtendedRequest] = useState<ExecutorExtendedRequest>(null);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);

  const dispatchExecutorRequestFileDownloadToast = () =>
    dispatch(addToastAction(executorRequestFileDownloadToastSuccess()));

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const {
        data,
        cancelled,
        message: messageResponse
      } = await fetchRequest(fetchExecutorExtendedRequestDetails, claim_id, request_id, cancelToken);

      if (cancelled) return;
      if (data) setExecutorExtendedRequest(data.data);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (executorExtendedRequest) {
      const breadcrumb = breadcrumbRouteExecutorsExtendedRequestDetails();

      dispatch(
        managePageAction({
          breadcrumb,
          title: 'Wniosky wykonawcy - szczegóły'
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executorExtendedRequest]);

  const executorRequestDownloadFileRequest = async (fileId: number | string) => {
    const {
      cancelled,
      message: messageRes,
      success
    } = await fetchFileRequest(fetchExecutorExtendedRequestFile, claim_id, request_id, fileId, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchExecutorRequestFileDownloadToast();
    } else if (messageRes) setMessage(messageRes);
  };

  if (fetching) return <Loader />;
  if (!fetching && !executorExtendedRequest && message)
    return (
      <div className="user-create-view d-flex justify-content-center">
        <CardBox>
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </CardBox>
      </div>
    );
  if (!fetching && executorExtendedRequest) {
    return (
      <div className="row user-details-view position-relative">
        <ExecutorExtendedRequestDetailsComponent
          downloadFileRequest={executorRequestDownloadFileRequest}
          executorExtendedRequest={executorExtendedRequest}
        />
      </div>
    );
  }

  return null;
};

export default ExecutorExtendedRequestDetails;
