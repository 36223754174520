import React from 'react';
import { RootState } from 'app/reducer';
import ListingPerPage from 'modules/Layout/component/Listing/PerPage';
import { PartialSearchingProps } from 'modules/Shared/type';
import { useDispatch, useSelector } from 'react-redux';
import { setForeignProceedingsApiImportsListParamsAction } from '../../../action/ForeignProceedingsApiImportsList';

const ForeignProceedingsApiImportsPerPage = () => {
  const dispatch = useDispatch();
  const { meta } = useSelector(
    (state: RootState) => state.foreignProceedingsApiImports.foreignProceedingsApiImportsList
  );
  const setParams = (payload: PartialSearchingProps) =>
    dispatch(setForeignProceedingsApiImportsListParamsAction(payload));

  return <ListingPerPage meta={meta} setParams={setParams} />;
};

export default ForeignProceedingsApiImportsPerPage;
