import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import Order, { OrderEntity } from 'modules/ExecutorOrder/model/Order';
import { PaginationMeta, SearchParams } from 'modules/Shared/type';

export const API_ORDER_DETAILS_CRUD = '/api/orders/:executor_id/:order_id';
export const API_ORDER_CRUD = '/api/orders/:executor_id';
export const API_ORDER_FILE = '/api/orders/files/:section_id/:file_id';
export const API_EXECUTOR_FINANCIAL_REPORT_FILE =
  '/api/admin/executors/:executor_id/financial_reports/:financial_report_id';
export const API_VIOLATION_FILE = '/api/violations/files/:section_id/:file_id';
export const API_ORDER_FILE_ADD = '/api/orders/files/:section_id';
export const API_VIOLATION_FILE_ADD = '/api/violations/files/:section_id';

export type FetchOrdersResponse = {
  data: OrderEntity[];
  meta: PaginationMeta;
  links: any;
};

export const fetchOrders = (
  executor_id: number | string,
  params: SearchParams = {}
): AxiosPromise<FetchOrdersResponse> => axios.get(getAPIUrl(API_ORDER_CRUD, { executor_id }, params));

export type FetchOrderResponse = {
  data: Order;
};

export const fetchOrder = (
  executor_id: string | number,
  order_id: string | number,
  cancelToken?: any
): AxiosPromise<FetchOrderResponse> =>
  axios.get(getAPIUrl(API_ORDER_DETAILS_CRUD, { executor_id, order_id }), { cancelToken });

export const createOrder = (
  executor_id: string | number,
  data: OrderEntity,
  cancelToken?: any
): AxiosPromise<FetchOrderResponse> => axios.post(getAPIUrl(API_ORDER_CRUD, { executor_id }), data, { cancelToken });

export const updateOrder = (
  executor_id: string | number,
  order_id: string | number,
  data: OrderEntity,
  cancelToken?: any
): AxiosPromise<FetchOrderResponse> =>
  axios.put(getAPIUrl(API_ORDER_DETAILS_CRUD, { executor_id, order_id }), data, { cancelToken });

export const deleteOrder = (executor_id: string | number, order_id: string | number, cancelToken?: any): AxiosPromise =>
  axios.delete(getAPIUrl(API_ORDER_DETAILS_CRUD, { executor_id, order_id }), { cancelToken });

export const deleteOrderFile = (
  section_id: string | number,
  file_id: string | number,
  cancelToken?: any
): AxiosPromise<FetchOrderResponse> =>
  axios.delete(getAPIUrl(API_ORDER_FILE, { section_id, file_id }), { cancelToken });

export const deleteExecutorFinancialReportFile = (
  executor_id: string | number,
  financial_report_id: string | number,
  cancelToken?: any
): AxiosPromise<FetchOrderResponse> =>
  axios.delete(getAPIUrl(API_EXECUTOR_FINANCIAL_REPORT_FILE, { executor_id, financial_report_id }), { cancelToken });

export const fetchOrderFile = (
  section_id: string | number,
  file_id: string | number,
  cancelToken?: any
): AxiosPromise =>
  axios.get(getAPIUrl(API_ORDER_FILE, { section_id, file_id }), {
    responseType: 'blob',
    cancelToken
  });

export const fetchExecutorFinancialReportFile = (
  executor_id: string | number,
  financial_report_id: string | number,
  cancelToken?: any
): AxiosPromise =>
  axios.get(getAPIUrl(API_EXECUTOR_FINANCIAL_REPORT_FILE, { executor_id, financial_report_id }), {
    responseType: 'blob',
    cancelToken
  });

export const fetchViolationFile = (
  section_id: string | number,
  file_id: string | number,
  cancelToken?: any
): AxiosPromise =>
  axios.get(getAPIUrl(API_VIOLATION_FILE, { section_id, file_id }), {
    responseType: 'blob',
    cancelToken
  });

export const deleteViolationFile = (
  section_id: string | number,
  file_id: string | number,
  cancelToken?: any
): AxiosPromise =>
  axios.delete(getAPIUrl(API_VIOLATION_FILE, { section_id, file_id }), {
    cancelToken
  });

export const addOrderFile = (
  section_id: number | string,
  data: FormData,
  onUploadProgress: (progressEvent: any) => void,
  cancelToken?: any
): AxiosPromise<FetchOrderResponse> =>
  axios.post(getAPIUrl(API_ORDER_FILE_ADD, { section_id }), data, {
    onUploadProgress,
    cancelToken
  });

export const addExecutorFinancialReportFile = (
  executor_id: number | string,
  financial_report_id: number | string,
  data: FormData,
  cancelToken?: any
): AxiosPromise<FetchOrderResponse> =>
  axios.post(getAPIUrl(API_EXECUTOR_FINANCIAL_REPORT_FILE, { executor_id, financial_report_id }), data, {
    cancelToken
  });

export const addViolationFile = (
  section_id: number | string,
  data: FormData,
  onUploadProgress: (progressEvent: any) => void,
  cancelToken?: any
): AxiosPromise<FetchOrderResponse> =>
  axios.post(getAPIUrl(API_VIOLATION_FILE_ADD, { section_id }), data, {
    onUploadProgress,
    cancelToken
  });
