import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { AlertFormData } from '../../../Form/AlertForm';
import ForeignProceedingsAlertInputsFieldset from '../AlertInputs';

export interface Props {
  values: AlertFormData;
  errors?: ValidationErrors;
  disabled?: boolean;
  required?: boolean;
  setValues: (value: AlertFormData) => void;
  placeholder?: boolean;
  isEditing?: boolean;
}

const ForeignProceedingsAlertFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { disabled } = props;

  return (
    <fieldset className="m-0" disabled={disabled}>
      <div className="row col-12">
        <div className="col-12">
          <ForeignProceedingsAlertInputsFieldset {...props} />
        </div>
      </div>
    </fieldset>
  );
};

export default ForeignProceedingsAlertFieldset;
