import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function JudgementsIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 23.551 23.552">
      <g id="Group_376" data-name="Group 376" transform="translate(-887.114 -384.297)">
        <path
          id="Path_1281"
          data-name="Path 1281"
          d="M903.74,402.418a1.475,1.475,0,0,1-1.051-.436l-.156-.156a1.478,1.478,0,0,1-.377-1.456l-7.563-7.562a1.485,1.485,0,0,1-1.454-.376l-.158-.157a1.486,1.486,0,0,1,0-2.1l5.439-5.439a1.486,1.486,0,0,1,2.1,0l.157.156a1.5,1.5,0,0,1,.376,1.462l7.558,7.558a1.491,1.491,0,0,1,.413-.058,1.473,1.473,0,0,1,1.049.434l.157.158a1.486,1.486,0,0,1,0,2.1l-5.439,5.439A1.474,1.474,0,0,1,903.74,402.418Zm-9.033-10.7a.5.5,0,0,1,.354.146l8.041,8.04a.5.5,0,0,1,.072.617.484.484,0,0,0,.066.6l.157.157a.484.484,0,0,0,.686,0l5.439-5.439a.486.486,0,0,0,0-.686l-.158-.159a.479.479,0,0,0-.34-.14.488.488,0,0,0-.26.075.5.5,0,0,1-.62-.07l-8.038-8.038a.5.5,0,0,1-.069-.621.486.486,0,0,0-.066-.6l-.157-.156a.486.486,0,0,0-.688,0l-5.439,5.439a.486.486,0,0,0,0,.686l.157.157a.484.484,0,0,0,.6.068A.5.5,0,0,1,894.707,391.714Z"
          fill={fill}
        />
        <path
          id="Line_41"
          data-name="Line 41"
          d="M0,10.276a.5.5,0,0,1-.354-.146.5.5,0,0,1,0-.707L9.422-.354a.5.5,0,0,1,.707,0,.5.5,0,0,1,0,.707L.354,10.129A.5.5,0,0,1,0,10.276Z"
          transform="translate(887.614 397.573)"
          fill={fill}
        />
        <path
          id="Line_42"
          data-name="Line 42"
          d="M0,6.252a.5.5,0,0,1-.353-.146.5.5,0,0,1,0-.707L5.4-.353a.5.5,0,0,1,.707,0,.5.5,0,0,1,0,.707L.354,6.106A.5.5,0,0,1,0,6.252Z"
          transform="translate(902.748 394.502)"
          fill={fill}
        />
        <path
          id="Line_43"
          data-name="Line 43"
          d="M0,6.25A.5.5,0,0,1-.354,6.1a.5.5,0,0,1,0-.707L5.4-.354a.5.5,0,0,1,.707,0,.5.5,0,0,1,0,.707L.353,6.1A.5.5,0,0,1,0,6.25Z"
          transform="translate(894.707 386.464)"
          fill={fill}
        />
      </g>
    </svg>
  );
}
