import { JudgementListAction, SET_JUDGEMENT_LIST_PARAMS, JUDGEMENT_LIST } from 'modules/Judgement/action/JudgementList';
import { createJudgement } from 'modules/Judgement/model/Judgement';
import { initJudgementListState, JudgementListState } from 'modules/Judgement/state/judgementList';

const initState = initJudgementListState();

const reducer = (state: JudgementListState = initState, action: JudgementListAction): JudgementListState => {
  switch (action.type) {
    case SET_JUDGEMENT_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case JUDGEMENT_LIST: {
      const { list = [], meta } = action.payload;

      return { ...state, fetching: false, list: list.map(createJudgement), meta };
    }

    default:
      return state;
  }
};

export default reducer;
