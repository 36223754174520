import React from 'react';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import PreviewFieldCheckboxWithNumberInput from 'modules/Agreements/components/PreviewField/CheckboxWithNumberInput';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_PENALTIES } from 'modules/Agreements/step';
import {
  AgreementStepPenaltiesValues,
  getExecutorPenaltiesPossibilityText,
  getContractorPenaltiesPossibilityText,
  EXECUTOR_DELAY_DELIVERY_CONTENT,
  EXECUTOR_DELAY_REMOVE_CONTENT,
  EXECUTOR_CONTRACTOR_RENOUNCEMENT_CONTENT,
  EXECUTOR_EXECUTOR_RENOUNCEMENT_CONTENT,
  EXECUTOR_SECONDMENT_CONTENT,
  EXECUTOR_INSURANCE_POLICY_CONTENT,
  EXECUTOR_SAFEGUARD_CONTENT,
  EXECUTOR_SCHEDULE_CONTENT,
  CONTRACTOR_RENOUNCEMENT_CONTENT,
  CONTRACTOR_PROJECT_CONTENT,
  getMaxPenaltiesContent
} from 'modules/Agreements/components/Steps/Default/Penalties';
import PreviewFieldContent from 'modules/Agreements/components/PreviewField/Content';

const AgreementStepPreviewPenalties = (props: AgreementStepPreviewProps<AgreementStepPenaltiesValues>): JSX.Element => {
  const { steps, modifiedStep } = props;
  const penalties = getStepValues<AgreementStepPenaltiesValues>(steps, AGREEMENT_STEP_PENALTIES);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={penalties}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const renderContent = (contentKey: string, className = 'preview-point') => {
    return (
      <PreviewFieldContent step={penalties} modifiedStep={modifiedStep} contentKey={contentKey} className={className} />
    );
  };

  const renderCheckboxWithNumberInput = (checkbox: string, label: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckboxWithNumberInput
        step={penalties}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        label={label}
        className={className}
      />
    );
  };

  const { value: sameSubjectForParts } = getFieldForPreview(penalties, modifiedStep, 'same_subject_for_parts');

  const { value: penaltiesPossibility } = getFieldForPreview(penalties, modifiedStep, `penalties_possibility`);

  const { value: executorPenaltiesPossibility, modified: executorPenaltiesPossibilityModified } = getFieldForPreview(
    penalties,
    modifiedStep,
    `executor_penalties_possibility`
  );

  const { value: contractorPenaltiesPossibility, modified: contractorPenaltiesPossibilityModified } =
    getFieldForPreview(penalties, modifiedStep, `contractor_penalties_possibility`);

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    penalties,
    modifiedStep,
    `checkboxes.general`
  );

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_PENALTIES)}</div>
      <div className="agreement-step-preview-content reset-preview-point">
        {penaltiesPossibility && [
          executorPenaltiesPossibility && [
            <p
              className={classNames('preview-point', {
                modified: executorPenaltiesPossibilityModified
              })}
            >
              {getExecutorPenaltiesPossibilityText(penalties.same_subject_for_parts)}
            </p>,
            renderCheckboxWithNumberInput(
              'executor_delay_delivery',
              EXECUTOR_DELAY_DELIVERY_CONTENT,
              'preview-nested-decimal-point'
            ),
            renderCheckboxWithNumberInput(
              'executor_delay_remove',
              EXECUTOR_DELAY_REMOVE_CONTENT,
              'preview-nested-decimal-point'
            ),
            renderCheckboxWithNumberInput(
              'executor_contractor_renouncement',
              EXECUTOR_CONTRACTOR_RENOUNCEMENT_CONTENT,
              'preview-nested-decimal-point'
            ),
            renderCheckboxWithNumberInput(
              'executor_executor_renouncement',
              EXECUTOR_EXECUTOR_RENOUNCEMENT_CONTENT,
              'preview-nested-decimal-point'
            ),
            renderCheckboxWithNumberInput(
              'executor_secondment',
              EXECUTOR_SECONDMENT_CONTENT,
              'preview-nested-decimal-point'
            ),
            renderCheckboxWithNumberInput(
              'executor_insurance_policy',
              EXECUTOR_INSURANCE_POLICY_CONTENT,
              'preview-nested-decimal-point'
            ),
            renderCheckboxWithNumberInput(
              'executor_safeguard',
              EXECUTOR_SAFEGUARD_CONTENT,
              'preview-nested-decimal-point'
            ),
            renderCheckboxWithNumberInput(
              'executor_schedule',
              EXECUTOR_SCHEDULE_CONTENT,
              'preview-nested-decimal-point'
            ),
            renderCheckbox('executor_others', 'preview-nested-decimal-point')
          ],
          contractorPenaltiesPossibility && [
            <p
              className={classNames('preview-point', {
                modified: contractorPenaltiesPossibilityModified
              })}
            >
              {getContractorPenaltiesPossibilityText(penalties.same_subject_for_parts)}
            </p>,
            renderCheckboxWithNumberInput(
              'contractor_renouncement',
              CONTRACTOR_RENOUNCEMENT_CONTENT,
              'preview-nested-decimal-point'
            ),
            renderCheckboxWithNumberInput(
              'contractor_project',
              CONTRACTOR_PROJECT_CONTENT,
              'preview-nested-decimal-point'
            ),
            renderCheckbox('contractor_others', 'preview-nested-decimal-point')
          ],
          renderCheckbox('compensation'),
          renderContent('penalties_to_date_content'),
          renderCheckbox('payment_delay'),
          renderCheckbox('finish_works'),
          renderCheckboxWithNumberInput('max_penalties', getMaxPenaltiesContent(sameSubjectForParts))
        ]}
        {generalCheckboxes &&
          generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-point wysiwyg-preview', {
                  modified: generalCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AgreementStepPreviewPenalties;
