import React from 'react';
import { Menu } from 'app/menu';
import { CLAUSE_ADD, CLAUSE_CATEGORIES_INDEX, CLAUSE_INDEX } from 'modules/Clause/permissions';
import { ROUTE_CLAUSE_CATEGORIES, ROUTE_CLAUSE_LIST, ROUTE_CLAUSE_CREATE } from 'modules/Clause/routes';
import EnclosureIcon from 'modules/Layout/component/Icon/Enclosure';
import { ROLE_ADMIN } from 'modules/User/model/UserRole';
import User from 'modules/User/model/User';
import { ROUTE_CLAUSE_KEYWORDS } from 'modules/Clause/routes';
import { JUDGMENT_KEYWORDS_INDEX } from 'modules/Judgement/permissions';

const MenuOfficesShared: Menu[] = [
  {
    key: 'clause-register-section',
    type: 'section',
    title: 'Rejestr klauzul',
    hasAccess: (user: User): boolean => user?.role?.slug === ROLE_ADMIN
  },
  {
    key: 'clause-nested',
    type: 'nested',
    title: 'Klauzule',
    icon: <EnclosureIcon />,
    navLevel: 'second',
    href: '',
    permissions: [CLAUSE_CATEGORIES_INDEX],
    isActive: (_match: null) => false,
    children: [
      {
        key: 'clause-list-index',
        type: 'link',
        title: 'Lista klauzul',
        href: ROUTE_CLAUSE_LIST,
        permissions: [CLAUSE_INDEX]
      },
      {
        key: 'clause-add',
        type: 'link',
        title: 'Dodaj',
        href: ROUTE_CLAUSE_CREATE,
        permissions: [CLAUSE_ADD]
      },
      {
        key: 'clause-category-index',
        type: 'link',
        title: 'Kategorie',
        href: ROUTE_CLAUSE_CATEGORIES,
        permissions: [CLAUSE_CATEGORIES_INDEX]
      },
      {
        key: 'clause-keyword-list',
        type: 'link',
        title: 'Słowa kluczowe',
        href: ROUTE_CLAUSE_KEYWORDS,
        permissions: [JUDGMENT_KEYWORDS_INDEX]
      }
    ]
  }
];

export default MenuOfficesShared;
