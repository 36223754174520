import axios, { AxiosPromise } from 'axios';
import {
  RefreshTokenPayload,
  RegisterValues,
  RequestTokenPayload,
  Token,
  ResetPasswordPayload
} from 'modules/Auth/type';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { UserEntity } from 'modules/User/model/User';

export const API_TOKEN = '/api/oauth/token';
export const API_REFRESH_TOKEN = '/api/oauth/token';
export const API_AUTHENTICATE = '/api/user';
export const API_REGISTER = '/api/register';
export const API_PASSWORD_RECOVER = '/api/auth/password/email';
export const API_PASSWORD_RESET = '/api/auth/password/reset';
export const API_CONFIRM_EMAIL_CHANGE = '/api/auth/email/verify/:token';
export const API_ACCOUNT_ACTIVATION = '/api/account/activate/:token';
export const API_REFRESH_ACTIVATION = '/api/refresh_activation';

export const requestToken = (payload: RequestTokenPayload | RefreshTokenPayload): AxiosPromise<Token> =>
  axios.post(getAPIUrl(API_TOKEN), payload);

export type AuthenticateResponse = {
  data: UserEntity;
};

export const authenticate = (): AxiosPromise<AuthenticateResponse> => axios.get(getAPIUrl(API_AUTHENTICATE));

export const refreshToken = (payload: RefreshTokenPayload): AxiosPromise<Token> =>
  axios.post(getAPIUrl(API_REFRESH_TOKEN), payload);

export const register = (values: RegisterValues): AxiosPromise => axios.post(getAPIUrl(API_REGISTER), values);

export const recoverPassword = (email: string): AxiosPromise<{ message: string }> =>
  axios.post(getAPIUrl(API_PASSWORD_RECOVER), { email });

export const resetPassword = (payload: ResetPasswordPayload): AxiosPromise<{ message: string }> =>
  axios.post(getAPIUrl(API_PASSWORD_RESET), payload);

export const confirmEmailChange = (token: string): AxiosPromise<Record<string, unknown>> =>
  axios.get(getAPIUrl(API_CONFIRM_EMAIL_CHANGE, { token }));

export const confirmAccountActivation = (token: string): AxiosPromise<Record<string, unknown>> =>
  axios.get(getAPIUrl(API_ACCOUNT_ACTIVATION, { token }));

export const refreshActivation = (email: string, cancelToken?: any): AxiosPromise<{ message: string }> =>
  axios.post(getAPIUrl(API_REFRESH_ACTIVATION), { email }, { cancelToken });
