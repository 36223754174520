import React from 'react';
import { IconProps } from '../index';

export default function SearchIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 23.448 23.447">
      <g id="Group_378" data-name="Group 378" transform="translate(-239.309 -480.248)">
        <path
          id="Path_1283"
          data-name="Path 1283"
          d="M261.063,503.7a1.69,1.69,0,0,1-1.2-.5l-6.417-6.416.707-.707,6.417,6.416a.7.7,0,1,0,.983-.983l-6.417-6.416.707-.707,6.417,6.416a1.694,1.694,0,0,1-1.2,2.893Z"
          fill={fill}
        />
        <path
          id="Path_1284"
          data-name="Path 1284"
          d="M248.535,498.7a9.226,9.226,0,1,1,9.225-9.225A9.236,9.236,0,0,1,248.535,498.7Zm0-17.451a8.226,8.226,0,1,0,8.225,8.226A8.235,8.235,0,0,0,248.535,481.248Z"
          fill={fill}
        />
        <path
          id="Path_1285"
          data-name="Path 1285"
          d="M242.6,490.468a.5.5,0,0,1-.5-.484,6.825,6.825,0,0,1,1.982-5.034,6.736,6.736,0,0,1,5.034-1.982.5.5,0,1,1-.033,1,5.782,5.782,0,0,0-5.984,5.984.5.5,0,0,1-.483.516Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
