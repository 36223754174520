import Authorize from 'modules/Auth/container/Authorize';
import CardBox from 'modules/Layout/component/CardBox';
import actionDeleteButtonDetails from 'modules/Shared/helper/actionDeleteButtonDetails';
import { getPathUrl } from 'modules/Shared/helper/api';
import BasicDataset from 'modules/User/component/Dataset/Basic';
import User from 'modules/User/model/User';
import { USERS_DELETE, USERS_EDIT } from 'modules/User/permissions';
import { ROUTE_USER_BASIC_UPDATE } from 'modules/User/routes';
import React from 'react';
import { Link } from 'react-router-dom';

export interface Props {
  user: User;
  onDeleteClick?: () => void;
  acceptUserNipAction?: () => void;
}

const DetailsBasic: React.FC<Props> = ({ user, onDeleteClick, acceptUserNipAction }) => {
  const themeTemplate = process.env.THEME_TEMPLATE as 'pzp' | 'bs';

  return (
    <CardBox
      heading="Dane użytkownika"
      paddingLeft="30px"
      paddingTop="25px"
      paddingRight="30px"
      paddingBottom="0"
      actions={
        !!user?.status && (
          <>
            <Authorize permissions={[USERS_EDIT]}>
              <Link
                className="link-with-border"
                to={{
                  pathname: getPathUrl(ROUTE_USER_BASIC_UPDATE, { id: user.id }),
                  state: { user }
                }}
              >
                EDYCJA
              </Link>
            </Authorize>
            {user?.nip && themeTemplate === 'pzp' && (
              <Authorize permissions={[USERS_EDIT]}>
                <Link className="link-with-border" to="#" onClick={() => acceptUserNipAction()}>
                  POTWIERDŹ NIP Z ASYSTENTA POSTĘPOWAŃ
                </Link>
              </Authorize>
            )}
            {onDeleteClick && (
              <Authorize permissions={[USERS_DELETE]}>
                {actionDeleteButtonDetails(user, {
                  value: 'USUŃ UŻYTKOWNIKA',
                  onDeleteClick,
                  tooltipContent: 'Nie możesz usunąć ostatniego administratora w systemie.',
                  targetIdName: 'DeleteUserId'
                })}
              </Authorize>
            )}
          </>
        )
      }
    >
      <BasicDataset user={user} hideRole displayStatus />
    </CardBox>
  );
};

export default DetailsBasic;
