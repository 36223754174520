import React from 'react';
import Table, { TableCol } from 'modules/Layout/component/Table/AccordionTable';
import { ExecutorFinancialReport } from '../../model/Violation';
import { formatDate, ifContainsDateFormat } from '../../../Shared/helper/utils';
import ActionDelete from '../../../Layout/component/Action/Delete';
import TooltipPlusIcon from '../../../Layout/component/Icon/TooltipPlus';
import ActionUpdate from '../../../Layout/component/Action/Update';
import DownloadIcon from '../../../Layout/component/Icon/Download';

type Props = {
  financialReports: ExecutorFinancialReport[];
  executor_id: string | number;
  showModal?: (id: number | string) => void;
  downloadFile?: (id: number | string) => void;
  onDeleteClick?: (row: ExecutorFinancialReport) => void;
};

const FinancialReportsExecutorTable: React.FC<Props> = ({
  financialReports,
  onDeleteClick,
  showModal,
  downloadFile
}) => {
  const cols: TableCol<ExecutorFinancialReport>[] = [
    {
      property: 'submission_date',
      label: 'Data złożenia',
      value: (row) => ifContainsDateFormat(row?.submission_date, formatDate)
    },
    {
      property: 'report_period',
      label: 'Za okres',
      value: (row) => ifContainsDateFormat(row?.report_period, formatDate)
    },
    {
      property: 'file_name',
      label: 'Nazwa pliku',
      value: (row) => row?.file_name
    },
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            {row?.file_name ? (
              <>
                <ActionUpdate
                  className="mx-1"
                  title="Pobierz plik"
                  label={<DownloadIcon height="20px" />}
                  onClick={() => downloadFile(row.id)}
                />
                <ActionDelete className="mx-1" title="Usuń plik" onClick={() => onDeleteClick(row)} />
              </>
            ) : (
              <ActionUpdate
                title="Dodaj dokument"
                label={<TooltipPlusIcon height="20px" />}
                onClick={() => showModal(row.id)}
              />
            )}
          </div>
        );
      }
    }
  ];

  return <Table cols={cols} rows={financialReports} />;
};

export default FinancialReportsExecutorTable;
