import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';

const useIsMobile = (width = 768) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= width);
  const stateRef = useRef(null);

  stateRef.current = isMobile;
  useEffect(() => {
    const updateSize = debounce(() => {
      if (window.innerWidth <= width && !stateRef.current) setIsMobile(true);
      else if (window.innerWidth > width && stateRef.current) setIsMobile(false);
    }, 500);

    window.addEventListener('resize', updateSize);
    return () => {
      window.addEventListener('resize', updateSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isMobile
  };
};

export default useIsMobile;
