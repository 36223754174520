import { AGREEMENT_MODE_DELIVERIES, AgreementFormConfiguration } from 'modules/Agreements/type';
import AgreementForm from 'modules/Agreements/components/Form';
import React from 'react';
import AgreementStepType from 'modules/Agreements/components/Steps/Default/Type';
import {
  AGREEMENT_STAGE_AGREEMENT,
  AGREEMENT_STAGE_PREPARING,
  AGREEMENT_STEP_TYPE,
  AGREEMENT_STEP_AGREEMENT_DATE,
  AGREEMENT_STEP_CONTRACTOR_EXECUTOR,
  AGREEMENT_STEP_PREAMBLE,
  AGREEMENT_STEP_SUBJECT,
  AGREEMENT_STEP_DELIVERY_REQUIREMENTS,
  AGREEMENT_STEP_SUPPLEMENTARY_ORDERS,
  AGREEMENT_STEP_OPTION_RIGHT,
  AGREEMENT_STEP_DEADLINE,
  AGREEMENT_STEP_PERFORMANCE_SECURITY,
  AGREEMENT_STEP_EXECUTOR_DUTIES,
  AGREEMENT_STEP_REWARD,
  AGREEMENT_STEP_SUBEXECUTORS,
  AGREEMENT_STEP_RECEIPT,
  AGREEMENT_STEP_INSURANCE,
  AGREEMENT_STEP_QUALITY_GUARANTEE,
  AGREEMENT_STEP_PENALTIES,
  AGREEMENT_STEP_RENOUNCEMENT,
  AGREEMENT_STEP_CONTRACT_CHANGES,
  AGREEMENT_STEP_REPRESENTATIVES,
  AGREEMENT_STEP_LICENSE,
  AGREEMENT_STEP_PERSONAL_DATA_PROTECTION,
  AGREEMENT_STEP_DISPUTES,
  AGREEMENT_STEP_ADDITIONAL_PROVISIONS,
  AGREEMENT_STEP_FINAL_PROVISIONS,
  AGREEMENT_STEP_INDEXATION_CLAUSES,
  AGREEMENT_STEP_PURPOSE,
  AGREEMENT_STEP_CO_FINANCING,
  AGREEMENT_STEP_CONTRACT_SING
} from 'modules/Agreements/step';
import AgreementStepAgreementDate from 'modules/Agreements/components/Steps/Default/AgreementDate';
import AgreementStepPreviewAgreementDate from 'modules/Agreements/components/Preview/Default/AgreementDate';
import AgreementStepContractorExecutor from 'modules/Agreements/components/Steps/Default/ContractorExecutor';
import AgreementStepPreviewContractorExecutor from 'modules/Agreements/components/Preview/Default/ContractorExecutor';
import AgreementStepPreamble from 'modules/Agreements/components/Steps/Default/Preamble';
import AgreementStepPreviewPreamble from 'modules/Agreements/components/Preview/Default/Preamble';
import AgreementStepSubject from 'modules/Agreements/components/Steps/Deliveries/Subject';
import AgreementStepPreviewSubject from 'modules/Agreements/components/Preview/Deliveries/Subject';
import AgreementStepDeliveryRequirements from 'modules/Agreements/components/Steps/Deliveries/DeliveryRequirements';
import AgreementStepPreviewDeliveryRequirements from 'modules/Agreements/components/Preview/Deliveries/DeliveryRequirements';
import AgreementStepSupplementaryOrders from 'modules/Agreements/components/Steps/Deliveries/SupplementaryOrders';
import AgreementStepPreviewSupplementaryOrders from 'modules/Agreements/components/Preview/Default/SupplementaryOrders';
import AgreementStepOptionRight from 'modules/Agreements/components/Steps/Default/OptionRight';
import AgreementStepPreviewOptionRight from 'modules/Agreements/components/Preview/Default/OptionRight';
import AgreementStepDeadline from 'modules/Agreements/components/Steps/Deliveries/Deadline';
import AgreementStepPreviewDeadline from 'modules/Agreements/components/Preview/Deliveries/Deadline';
import AgreementStepExecutorDuties from 'modules/Agreements/components/Steps/Deliveries/ExecutorDuties';
import AgreementStepPreviewExecutorDuties from 'modules/Agreements/components/Preview/Deliveries/ExecutorDuties';
import AgreementStepReward from 'modules/Agreements/components/Steps/Deliveries/Reward';
import AgreementStepPreviewReward from 'modules/Agreements/components/Preview/Deliveries/Reward';
import AgreementStepSubexecutors from 'modules/Agreements/components/Steps/Deliveries/Subexecutors';
import AgreementStepPreviewSubexecutors from 'modules/Agreements/components/Preview/Deliveries/Subexecutors';
import AgreementStepReceipt from 'modules/Agreements/components/Steps/Deliveries/Receipt';
import AgreementStepPreviewReceipt from 'modules/Agreements/components/Preview/Deliveries/Receipt';
import AgreementStepPerformanceSecurity from 'modules/Agreements/components/Steps/Default/PerformanceSecurity';
import AgreementStepPreviewPerformanceSecurity from 'modules/Agreements/components/Preview/Default/PerformanceSecurity';
import AgreementStepInsurance from 'modules/Agreements/components/Steps/Deliveries/Insurance';
import AgreementStepPreviewInsurance from 'modules/Agreements/components/Preview/Deliveries/Insurance';
import AgreementStepQualityGuarantee from 'modules/Agreements/components/Steps/Deliveries/QualityGuarantee';
import AgreementStepPreviewQualityGuarantee from 'modules/Agreements/components/Preview/Deliveries/QualityGuarantee';
import AgreementStepPenalties from 'modules/Agreements/components/Steps/Deliveries/Penalties';
import AgreementStepPreviewPenalties from 'modules/Agreements/components/Preview/Deliveries/Penalties';
import AgreementStepRenouncement from 'modules/Agreements/components/Steps/Deliveries/Renouncement';
import AgreementStepPreviewRenouncement from 'modules/Agreements/components/Preview/Deliveries/Renouncement';
import AgreementStepContractChanges from 'modules/Agreements/components/Steps/Deliveries/ContractChanges';
import AgreementStepPreviewContractChanges from 'modules/Agreements/components/Preview/Deliveries/ContractChanges';
import AgreementStepRepresentatives from 'modules/Agreements/components/Steps/Default/Representatives';
import AgreementStepPreviewRepresentatives from 'modules/Agreements/components/Preview/Default/Representatives';
import AgreementStepLicense from 'modules/Agreements/components/Steps/Deliveries/License';
import AgreementStepPreviewLicense from 'modules/Agreements/components/Preview/Deliveries/License';
import AgreementStepPersonalDataProtection from 'modules/Agreements/components/Steps/Deliveries/PersonalDataProtection';
import AgreementStepPreviewPersonalDataProtection from 'modules/Agreements/components/Preview/Deliveries/PersonalDataProtection';
import AgreementStepDisputes from 'modules/Agreements/components/Steps/Default/Disputes';
import AgreementStepPreviewDisputes from 'modules/Agreements/components/Preview/Default/Disputes';
import AgreementStepAdditionalProvisions from 'modules/Agreements/components/Steps/Default/AdditionalProvisions';
import AgreementStepPreviewAdditionalProvisions from 'modules/Agreements/components/Preview/Default/AdditionalProvisions';
import AgreementStepFinalProvisions from 'modules/Agreements/components/Steps/Default/FinalProvisions';
import AgreementStepPreviewFinalProvisions from 'modules/Agreements/components/Preview/Default/FinalProvisions';
import AgreementStepIndexationClauses from 'modules/Agreements/components/Steps/Default/IndexationClauses';
import AgreementStepPreviewIndexationClauses from 'modules/Agreements/components/Preview/Default/IndexationClauses';
import AgreementStepPurpose from 'modules/Agreements/components/Steps/Default/Purpose';
import AgreementStepCoFinancing from 'modules/Agreements/components/Steps/Default/CoFinancing';
import AgreementStepContractSing from '../../../components/Steps/Default/ContractSing';

const configuration: AgreementFormConfiguration = {
  stages: [
    {
      slug: AGREEMENT_STAGE_PREPARING,
      steps: [
        {
          slug: AGREEMENT_STEP_TYPE,
          component: AgreementStepType
        }
      ],
      preview: false
    },
    {
      slug: AGREEMENT_STAGE_AGREEMENT,
      steps: [
        {
          slug: AGREEMENT_STEP_PURPOSE,
          component: AgreementStepPurpose
        },
        {
          slug: AGREEMENT_STEP_AGREEMENT_DATE,
          component: AgreementStepAgreementDate,
          previewComponent: AgreementStepPreviewAgreementDate
        },
        {
          slug: AGREEMENT_STEP_CONTRACTOR_EXECUTOR,
          component: AgreementStepContractorExecutor,
          previewComponent: AgreementStepPreviewContractorExecutor
        },
        {
          slug: AGREEMENT_STEP_CO_FINANCING,
          component: AgreementStepCoFinancing
        },
        {
          slug: AGREEMENT_STEP_PREAMBLE,
          component: AgreementStepPreamble,
          previewComponent: AgreementStepPreviewPreamble
        },
        {
          slug: AGREEMENT_STEP_SUBJECT,
          component: AgreementStepSubject,
          previewComponent: AgreementStepPreviewSubject
        },
        {
          slug: AGREEMENT_STEP_DELIVERY_REQUIREMENTS,
          component: AgreementStepDeliveryRequirements,
          previewComponent: AgreementStepPreviewDeliveryRequirements
        },
        {
          slug: AGREEMENT_STEP_SUPPLEMENTARY_ORDERS,
          component: AgreementStepSupplementaryOrders,
          previewComponent: AgreementStepPreviewSupplementaryOrders
        },
        {
          slug: AGREEMENT_STEP_OPTION_RIGHT,
          component: AgreementStepOptionRight,
          previewComponent: AgreementStepPreviewOptionRight
        },
        {
          slug: AGREEMENT_STEP_DEADLINE,
          component: AgreementStepDeadline,
          previewComponent: AgreementStepPreviewDeadline
        },
        {
          slug: AGREEMENT_STEP_EXECUTOR_DUTIES,
          component: AgreementStepExecutorDuties,
          previewComponent: AgreementStepPreviewExecutorDuties
        },
        {
          slug: AGREEMENT_STEP_REWARD,
          component: AgreementStepReward,
          previewComponent: AgreementStepPreviewReward
        },
        {
          slug: AGREEMENT_STEP_SUBEXECUTORS,
          component: AgreementStepSubexecutors,
          previewComponent: AgreementStepPreviewSubexecutors
        },
        {
          slug: AGREEMENT_STEP_RECEIPT,
          component: AgreementStepReceipt,
          previewComponent: AgreementStepPreviewReceipt
        },
        {
          slug: AGREEMENT_STEP_PERFORMANCE_SECURITY,
          component: AgreementStepPerformanceSecurity,
          previewComponent: AgreementStepPreviewPerformanceSecurity
        },
        {
          slug: AGREEMENT_STEP_INSURANCE,
          component: AgreementStepInsurance,
          previewComponent: AgreementStepPreviewInsurance
        },
        {
          slug: AGREEMENT_STEP_QUALITY_GUARANTEE,
          component: AgreementStepQualityGuarantee,
          previewComponent: AgreementStepPreviewQualityGuarantee
        },
        {
          slug: AGREEMENT_STEP_PENALTIES,
          component: AgreementStepPenalties,
          previewComponent: AgreementStepPreviewPenalties
        },
        {
          slug: AGREEMENT_STEP_RENOUNCEMENT,
          component: AgreementStepRenouncement,
          previewComponent: AgreementStepPreviewRenouncement
        },
        {
          slug: AGREEMENT_STEP_CONTRACT_CHANGES,
          component: AgreementStepContractChanges,
          previewComponent: AgreementStepPreviewContractChanges
        },
        {
          slug: AGREEMENT_STEP_INDEXATION_CLAUSES,
          component: AgreementStepIndexationClauses,
          previewComponent: AgreementStepPreviewIndexationClauses
        },
        {
          slug: AGREEMENT_STEP_REPRESENTATIVES,
          component: AgreementStepRepresentatives,
          previewComponent: AgreementStepPreviewRepresentatives
        },
        {
          slug: AGREEMENT_STEP_LICENSE,
          component: AgreementStepLicense,
          previewComponent: AgreementStepPreviewLicense
        },
        {
          slug: AGREEMENT_STEP_PERSONAL_DATA_PROTECTION,
          component: AgreementStepPersonalDataProtection,
          previewComponent: AgreementStepPreviewPersonalDataProtection
        },
        {
          slug: AGREEMENT_STEP_DISPUTES,
          component: AgreementStepDisputes,
          previewComponent: AgreementStepPreviewDisputes
        },
        {
          slug: AGREEMENT_STEP_ADDITIONAL_PROVISIONS,
          component: AgreementStepAdditionalProvisions,
          previewComponent: AgreementStepPreviewAdditionalProvisions
        },
        {
          slug: AGREEMENT_STEP_FINAL_PROVISIONS,
          component: AgreementStepFinalProvisions,
          previewComponent: AgreementStepPreviewFinalProvisions
        },
        {
          slug: AGREEMENT_STEP_CONTRACT_SING,
          component: AgreementStepContractSing,
          previewComponent: null
        }
      ]
    }
  ]
};

const AgreementUpdateDeliveriesView = (): JSX.Element => {
  return <AgreementForm configuration={configuration} mode={AGREEMENT_MODE_DELIVERIES} />;
};

export default AgreementUpdateDeliveriesView;
