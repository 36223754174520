import { RootState } from 'app/reducer';
import { PermissionSlug } from 'modules/Auth/type';
import User from 'modules/User/model/User';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

export interface AuthorizeProps {
  fallback?: ReactNode;
  hasAccess?: (user: User) => boolean;
  permissions?: PermissionSlug[];
  anyPermissions?: boolean;
}

const Authorize: React.FC<AuthorizeProps> = ({
  fallback,
  hasAccess,
  children,
  permissions = [],
  anyPermissions = true
}) => {
  const { user } = useSelector((state: RootState) => state.auth);

  if (user) {
    if (hasAccess) {
      if (hasAccess(user)) {
        return <>{children}</>;
      }

      return <>{fallback}</>;
    }

    if (user.hasPermissions(permissions, anyPermissions)) {
      return <>{children}</>;
    }
  }

  return <>{fallback}</>;
};

export default Authorize;
