import React, { ReactNode } from 'react';
import './index.scss';

export interface Props {
  title: ReactNode;
  fields: ReactNode[];
}

const InformationListCollapsed: React.FC<Props> = ({ title, fields }) => {
  return (
    <div className="information-list-collapsed">
      <div className="information-list-collapsed-title">{title}</div>
      {fields.map(
        (field, index) =>
          Boolean(field) && (
            <div key={typeof field === 'string' ? field : index} className="information-list-collapsed-field">
              {field}
            </div>
          )
      )}
    </div>
  );
};

export default InformationListCollapsed;
