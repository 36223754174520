import React from 'react';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_QUALITY_GUARANTEE } from 'modules/Agreements/step';
import { AgreementStepQualityGuaranteeValues } from 'modules/Agreements/components/Steps/Default/QualityGuarantee';
import PreviewFieldContent from 'modules/Agreements/components/PreviewField/Content';

const AgreementStepPreviewQualityGuarantee = (
  props: AgreementStepPreviewProps<AgreementStepQualityGuaranteeValues>
): JSX.Element => {
  const { steps, modifiedStep } = props;
  const qualityGuarantee = getStepValues<AgreementStepQualityGuaranteeValues>(steps, AGREEMENT_STEP_QUALITY_GUARANTEE);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={qualityGuarantee}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const renderContent = (contentKey: string, className = 'preview-point') => {
    return (
      <PreviewFieldContent
        step={qualityGuarantee}
        modifiedStep={modifiedStep}
        contentKey={contentKey}
        className={className}
      />
    );
  };

  const { value: responsibilityPossibility, modified: responsibilityPossibilityModified } = getFieldForPreview(
    qualityGuarantee,
    modifiedStep,
    'responsibility_possibility'
  );

  const { value: longerGuaranteePossibility, modified: longerGuaranteePossibilityModified } = getFieldForPreview(
    qualityGuarantee,
    modifiedStep,
    'longer_guarantee_possibility'
  );

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    qualityGuarantee,
    modifiedStep,
    `checkboxes.general`
  );

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_QUALITY_GUARANTEE)}</div>
      <div className="agreement-step-preview-content reset-preview-point">
        {renderContent('guarantee_content')}
        {renderCheckbox('equal_warranty')}
        {renderCheckbox('longer_guarantee')}
        {responsibilityPossibility && [
          <p
            className={classNames('preview-point', {
              modified: responsibilityPossibilityModified
            })}
          >
            Wykonawca ponosi odpowiedzialność z tytułu gwarancji za:
          </p>,
          renderCheckbox('responsibility_disadvantages', 'preview-nested-decimal-point'),
          renderCheckbox('responsibility_remove', 'preview-nested-decimal-point'),
          renderCheckbox('responsibility_others', 'preview-nested-decimal-point')
        ]}
        {renderCheckbox('own_cost_disadvantages')}
        {renderCheckbox('no_emergency')}
        {renderCheckbox('guarantee_cost')}
        {longerGuaranteePossibility && [
          <p
            className={classNames('preview-point', {
              modified: longerGuaranteePossibilityModified
            })}
          >
            Okres Gwarancji jakości będzie przedłużony w następujących przypadkach:
          </p>,
          renderCheckbox('longer_guarantee_duties', 'preview-nested-decimal-point'),
          renderCheckbox('longer_guarantee_repairs', 'preview-nested-decimal-point'),
          renderCheckbox('longer_guarantee_others', 'preview-nested-decimal-point')
        ]}
        {renderCheckbox('elongation')}
        {renderCheckbox('impossibility')}
        {renderCheckbox('prev_resolutions')}
        {renderCheckbox('unregulated')}
        {generalCheckboxes &&
          generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-point wysiwyg-preview', {
                  modified: generalCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AgreementStepPreviewQualityGuarantee;
