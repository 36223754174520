import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const deleteForeignProceedingsCategoryToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań zagranicznych',
  body: 'Postępowanie zostało pomyślnie usunięte.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createForeignProceedingsToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań zagranicznych',
  body: 'Postępowanie zostało pomyślnie stworzone.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createForeignProceedingsAlertToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja alertu postępowańia zagranicznego',
  body: 'Alert postępowania został pomyślnie stworzony.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateForeignProceedingsToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań zagranicznych',
  body: 'Postępowanie zostało pomyślnie zaktualizowana.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});
export const updateForeignProceedingsAlertToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja alertu postępowań zagranicznych',
  body: 'Postępowanie zostało pomyślnie zaktualizowana.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteForeignProceedingsAlertToastSuccess = (): AddToastPayload => ({
  header: 'Alert postępowania',
  body: 'Alert postępowania został usunięta',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});
export const sendRequestForeignProceedingsToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań zagranicznych',
  body: 'Pomyślnie wysłano zapytanie.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const downloadRequestForeignProceedingsToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań zagranicznych',
  body: 'Rozpoczęto pobieranie pliku.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const addForeignProceedingsToFavouriteToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań zagranicznych',
  body: 'Pomyślnie dodano postępowanie do ulubionych.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createForeignProceedingsCPVToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań zagranicznych',
  body: 'Kod CPV został pomyślnie dodany.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateForeignProceedingsCPVToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań zagranicznych',
  body: 'Kod CPV został pomyślnie zaktualizowany.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const removeForeignProceedingsFromFavouriteToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań zagranicznych',
  body: 'Pomyślnie usunięto postępowanie z ulubionych.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteForeignProceedingsCPVToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań zagranicznych',
  body: 'Kod CPV został pomyślnie usunięty.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const foreignProceedingsToastServerError = (): AddToastPayload => ({
  header: 'Aktualizacja postępowań zagranicznych',
  body: 'Wystąpił błąd servera.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const foreignProceedingExcelFileImportToastSuccess = (): AddToastPayload => ({
  header: 'Importowanie pliku excel',
  body: 'Pomyślnie rozpoczęto importowanie pliku.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});
