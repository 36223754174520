import { AxiosPromise } from 'axios';
import { ColorVariants, Message } from 'modules/Shared/type';
import blobFileReader from '../blobFileReader';

type Response = {
  success?: boolean;
  message?: Message;
  cancelled?: boolean;
  error?: any;
};

const fetchFileRequest = async (request: (...args: any[]) => AxiosPromise<any>, ...args: any[]): Promise<Response> => {
  let message: Message = null;

  try {
    const response = await request(...args);

    const resFileName = response.headers['x-filename'];
    const finalFileName = resFileName || 'plik';

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', finalFileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    return { success: true };
  } catch (error) {
    if (error?.message === 'AxiosCancel') {
      return { cancelled: true };
    }

    const blobFile = await blobFileReader(error?.error?.response?.data);
    const { message: messageValue, errors } = JSON.parse(blobFile as any);

    message = {
      value: messageValue || 'Something went wrong.',
      variant: ColorVariants.Danger
    };

    return { message, success: false, error: errors || {} };
  }
};

export default fetchFileRequest;
