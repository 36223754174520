import React from 'react';
import { AGREEMENT_TYPE_PROJECT, AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementStepAgreementDateValues } from 'modules/Agreements/components/Steps/Default/AgreementDate';
import { getFieldForPreview, getStepValues, PREVIEW_PLACEHOLDER } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_AGREEMENT_DATE } from 'modules/Agreements/step';
import classNames from 'classnames';

const AgreementStepPreviewAgreementDate = (
  props: AgreementStepPreviewProps<AgreementStepAgreementDateValues>
): JSX.Element => {
  const { agreement, steps, modifiedStep } = props;
  const agreementDate = getStepValues<AgreementStepAgreementDateValues>(steps, AGREEMENT_STEP_AGREEMENT_DATE);

  const { value: dateVaule, modified: dateModified } = getFieldForPreview(
    agreementDate,
    modifiedStep,
    'agreement_date'
  );
  const { value: numberChecked, modified: numberCheckedModified } = getFieldForPreview(
    agreementDate,
    modifiedStep,
    'number_checked'
  );
  const { value: numberContent, modified: numberContentModified } = getFieldForPreview(
    agreementDate,
    modifiedStep,
    'number_content'
  );
  const { value: placeChecked, modified: placeCheckedModified } = getFieldForPreview(
    agreementDate,
    modifiedStep,
    'place_checked'
  );
  const { value: placeContent, modified: placeContentModified } = getFieldForPreview(
    agreementDate,
    modifiedStep,
    'place_content'
  );

  const getText = (): string => {
    let content;

    if (agreement.type === AGREEMENT_TYPE_PROJECT) {
      content = `zawarta w dniu ${PREVIEW_PLACEHOLDER} w ${PREVIEW_PLACEHOLDER} pomiędzy:`;
    } else {
      const getPlace = (): string => {
        if (placeChecked) return placeContent || PREVIEW_PLACEHOLDER;

        return 'siedzibie zamawiającego';
      };

      content = `zawarta w dniu ${dateVaule || PREVIEW_PLACEHOLDER} w ${getPlace()} pomiędzy:`;
    }

    // @ts-ignore
    return content.replaceAll('....', `<span style='background-color:#d4d4d4'>....</span>`);
  };

  return (
    <div className="agreement-step-preview-wrapper">
      {numberChecked && (
        <p className={classNames('text-center', { modified: numberCheckedModified || numberContentModified })}>
          nr {numberContent}
        </p>
      )}
      <div className={classNames({ modified: dateModified || placeCheckedModified || placeContentModified })}>
        <p className={classNames('wysiwyg-preview')} dangerouslySetInnerHTML={{ __html: String(getText()) }} />
      </div>
    </div>
  );
};

export default AgreementStepPreviewAgreementDate;
