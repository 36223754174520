import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const startTraditionalPaymentSuccess = (): AddToastPayload => ({
  header: null,
  body: 'W ciągu 24 godzin wyślemy na podany adres e-mail fakturę do opłaty wybranych produktów. Dostęp uzyskasz niezwłocznie po dokonaniu płatności.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const approveAccessTraditionalPaymentSuccess = (): AddToastPayload => ({
  header: null,
  body: 'Udzielono dostęp do modułów.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const cancelTraditionalPaymentSuccess = (): AddToastPayload => ({
  header: null,
  body: 'Płatność została anulowana.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});
