import * as H from 'history';
import { MenuLayout, MenuAccessibility } from 'modules/Layout/menu';
import MenuProfileShared from 'modules/Profile/menu';
import MenuUserShared from 'modules/User/menu';
import MenuSubscriptionsShared from 'modules/Subscriptions/menu';
import FeedbackProfileShared from 'modules/Feedback/menu';
import React, { ReactNode } from 'react';
import User from 'modules/User/model/User';
import { ModuleSlug, PermissionSlug } from 'modules/Auth/type';
import MenuExecutorShared from 'modules/Executor/menu';
import MenuOfficeShared from 'modules/Office/menu';
import MenuJudgementShared from 'modules/Judgement/menu';
import MenuClauseShared from 'modules/Clause/menu';
import MenuJudgmentClientShared from 'modules/JudgementClient/menu';
import MenuProceedingsShared from 'modules/Proceedings/menu';
import MenuForeignProceedingsShared from 'modules/ForeignProceedings/menu';
import MenuAgreementsShared from 'modules/Agreements/menu';

export type MenuKey = string;

export interface MenuItem<T extends string> {
  key: MenuKey;
  type: T;
  title: React.ReactNode;
  icon?: ReactNode;
  hasAccess?: (user: User) => boolean;
  permissions?: PermissionSlug[];
  modules?: ModuleSlug[];
}

export interface MenuLink extends MenuItem<'link'> {
  href: string;
  externalUrl?: boolean;
}

export interface MenuNested extends MenuItem<'nested'> {
  href: string;
  children: Menu[];
  navLevel: 'second' | 'third';
  isActive?: (_match: null, location: H.Location) => boolean;
}

export type MenuSection = MenuItem<'section'>;

export interface MenuButton extends MenuItem<'button'> {
  onClick: () => void;
}

export type Menu = MenuLink | MenuNested | MenuSection | MenuButton;

export const MenuItems: Menu[] = [
  ...MenuLayout,
  ...MenuProfileShared,
  ...MenuProceedingsShared,
  ...MenuForeignProceedingsShared,
  ...MenuUserShared,
  ...MenuOfficeShared,
  ...MenuExecutorShared,
  ...MenuAgreementsShared,
  ...MenuClauseShared,
  ...MenuJudgementShared,
  ...MenuJudgmentClientShared,
  ...MenuSubscriptionsShared,
  ...FeedbackProfileShared,
  ...MenuAccessibility
];
