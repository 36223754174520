import { RodoValues } from 'modules/Regulation/type';

const templateTheme = process.env.THEME_TEMPLATE as 'pzp' | 'bs';

const rodoContent = {
  rodo1_accepted: `Wyrażam zgodę na przetwarzanie moich danych osobowych w zakresie wyżej podanym w formularzu on-line w celach marketingowych przez
  ${
    templateTheme === 'pzp' ? 'Pawła Sendrowskiego' : 'Błażeja Sarnowskiego'
  } prowadzącego działalność gospodarczą pod nazwą ${
    templateTheme === 'pzp'
      ? 'Centrum Zamówień Publicznych Paweł Sendrowski'
      : 'Kancelaria Radcy Prawnego Błażej Sarnowski'
  }.`,
  rodo2_accepted: `Wyrażam zgodę na przetwarzanie moich danych osobowych w zakresie wyżej podanym w formularzu on-line w celach statystycznych przez
  ${
    templateTheme === 'pzp' ? 'Pawła Sendrowskiego' : 'Błażeja Sarnowskiego'
  } prowadzącego działalność gospodarczą pod nazwą ${
    templateTheme === 'pzp'
      ? 'Centrum Zamówień Publicznych Paweł Sendrowski'
      : 'Kancelaria Radcy Prawnego Błażej Sarnowski'
  }.`,
  rodo3_accepted: `Wyrażam zgodę na używanie przez ${
    templateTheme === 'pzp' ? 'Pawła Sendrowskiego' : 'Błażeja Sarnowskiego'
  } prowadzącego działalność gospodarczą pod nazwą ${
    templateTheme === 'pzp'
      ? 'Centrum Zamówień Publicznych Paweł Sendrowski'
      : 'Kancelaria Radcy Prawnego Błażej Sarnowski'
  } telekomunikacyjnych urządzeń końcowych, których jest użytkownikiem, dla celów marketingu bezpośredniego, zgodnie z art. 172 Prawa
  telekomunikacyjnego (Dz.U. Nr 171, poz. 1800 ze zm.).`,
  rodo4_accepted: `Wyrażam zgodę na przesyłanie przez ${
    templateTheme === 'pzp' ? 'Pawła Sendrowskiego' : 'Błażeja Sarnowskiego'
  } prowadzącego działalność gospodarczą pod nazwą ${
    templateTheme === 'pzp'
      ? 'Centrum Zamówień Publicznych Paweł Sendrowski'
      : 'Kancelaria Radcy Prawnego Błażej Sarnowski'
  } za pomocą środków komunikacji elektronicznej informacji handlowej, zgodnie z art. 10 ust. 2 ustawy z dnia 18 lipca 2002 r. o
  świadczeniu usług drogą elektroniczną (Dz.U. Nr 144, poz. 1204 ze zm.) oraz informacji o organizowanych promocjach na podany w formularzu on-line
  adres elektroniczny, w tym newslettera i informacji o funkcjonalnościach Serwisu.`
};

export default rodoContent;

export const initRodoValues = (objWithRodoKeys?: Partial<RodoValues>) =>
  Object.keys(rodoContent).reduce((prev, curr) => ({ ...prev, [curr]: (objWithRodoKeys as any)?.[curr] ?? false }), {});
