import _get from 'lodash/get';
import { SearchParams, SelectOption } from 'modules/Shared/type';
import { FilterInputType } from 'modules/Layout/type';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchForeignProceedingsSourcesAdmin } from '../../../../repository';
import { RootState } from '../../../../../../app/reducer';

export interface Props {
  values: SearchParams;
}

type SlugType = { slug: string; name: string };

const useFilterValues = ({ values = {} }: Props) => {
  const { filter } = useSelector(
    (state: RootState) => state.foreignProceedingsApiImports.foreignProceedingsApiImportsList
  );
  const [sourcesList, setSourcesList] = useState<SlugType[]>([]);
  const sourcesOptions: SelectOption[] = sourcesList.map((sourceItem) => {
    return {
      value: sourceItem.slug,
      label: sourceItem.name
    };
  });

  useEffect(() => {
    const getValues = async () => {
      const responseSources = await fetchForeignProceedingsSourcesAdmin();

      const newSources = responseSources.data;
      setSourcesList(newSources);
    };
    getValues().then((r) => r);
  }, []);

  const getInputs = (): FilterInputType[] => {
    const lastDateFrom = _get(filter, 'last_date_from') as string;
    const lastDateTo = _get(filter, 'last_date_to') as string;

    return [
      {
        type: 'date',
        property: 'last_date_from',
        label: 'Dane importu z dnia od',
        value: lastDateFrom,
        className: 'col'
      },
      {
        type: 'date',
        property: 'last_date_to',
        label: 'Data importu z dnia do',
        value: lastDateTo,
        className: 'col'
      },
      {
        type: 'select',
        property: 'source',
        label: 'Źródło',
        acceptValueAsString: true,
        options: [
          {
            value: '',
            label: ''
          },
          ...sourcesOptions
        ],
        value: _get(values, 'slug_source')
      },
      {
        type: 'select',
        property: 'success',
        label: 'Wynik importu',
        acceptValueAsString: true,
        value: _get(values, 'success'),
        options: [
          {
            value: '',
            label: 'Wszystkie'
          },
          {
            value: 0,
            label: 'Tylko z błędami'
          },
          {
            value: 1,
            label: 'Tylko poprawne'
          }
        ]
      }
    ];
  };

  return { inputs: getInputs() };
};

export default useFilterValues;
