import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function OfficesIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 23.746 23.977">
      <g id="Group_373" data-name="Group 373" transform="translate(-935.112 -600.016)">
        <path
          id="Rectangle_65"
          data-name="Rectangle 65"
          d="M0-.5H1.958a.5.5,0,0,1,.5.5V11.979a.5.5,0,0,1-.5.5H0a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5ZM1.458.5H.5V11.479h.958Z"
          transform="translate(943.507 609.485)"
          fill={fill}
        />
        <path
          id="Rectangle_66"
          data-name="Rectangle 66"
          d="M0-.5H1.958a.5.5,0,0,1,.5.5V11.979a.5.5,0,0,1-.5.5H0a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5ZM1.458.5H.5V11.479h.958Z"
          transform="translate(938.507 609.485)"
          fill={fill}
        />
        <path
          id="Rectangle_67"
          data-name="Rectangle 67"
          d="M0-.5H1.958a.5.5,0,0,1,.5.5V11.979a.5.5,0,0,1-.5.5H0a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5ZM1.458.5H.5V11.479h.958Z"
          transform="translate(948.508 609.485)"
          fill={fill}
        />
        <path
          id="Rectangle_68"
          data-name="Rectangle 68"
          d="M0-.5H1.958a.5.5,0,0,1,.5.5V11.979a.5.5,0,0,1-.5.5H0a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5ZM1.458.5H.5V11.479h.958Z"
          transform="translate(953.508 609.485)"
          fill={fill}
        />
        <path
          id="Path_1276"
          data-name="Path 1276"
          d="M958.358,608.05H935.612a.5.5,0,0,1-.263-.925l11.384-7.034a.5.5,0,0,1,.526,0l11.362,7.034a.5.5,0,0,1-.263.925Zm-20.986-1H956.6L947,601.1Z"
          fill={fill}
        />
        <path
          id="Line_40"
          data-name="Line 40"
          d="M22.746.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H22.746a.5.5,0,0,1,.5.5A.5.5,0,0,1,22.746.5Z"
          transform="translate(935.612 623.493)"
          fill={fill}
        />
        <path
          id="Path_1277"
          data-name="Path 1277"
          d="M946.985,606.019a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,946.985,606.019Zm0-1.995a.5.5,0,1,0,.5.5A.5.5,0,0,0,946.985,604.024Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
