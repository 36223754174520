import React from 'react';
import Agreement from 'modules/Agreements/model/Agreement';
import { ValidationErrors } from 'modules/Shared/type';
import Proceedings from 'modules/Proceedings/model/Proceedings';
import { AgreementStageSlug, AgreementStepSlug } from './step';

export enum AgreementsModeValues {
  CONSTRUCTION_WORKS = 'construction_works',
  DELIVERIES = 'deliveries',
  SERVICES = 'services',
  ORDER_FORM = 'order_form'
}

export enum AgreementsMode {
  CONSTRUCTION_WORKS = 'umowa o roboty budowlane',
  DELIVERIES = 'umowa o dostawy',
  SERVICES = 'umowa o świadczenie usług',
  ORDER_FORM = 'formularz zamówienia'
}

export enum AgreementsTypeValues {
  PROJECT = 'project',
  OUTSIDE_ORDER = 'outside_order',
  SUPPLEMENT = 'supplement'
}

export enum AgreementsType {
  PROJECT = 'Praca nad projektem umowy',
  OUTSIDE_ORDER = 'Praca nad umową',
  SUPPLEMENT = 'Uzupełnienie opracowanego projektu umowy'
}

export interface AgreementStepProps {
  onSubmit: (stepData: any) => Promise<boolean>;
  onChange: (stepData: any, isModified?: boolean) => void;
  agreement?: Agreement | null;
  AgreementTypeChange?: (agreementType: string) => void;
  proceeding?: Proceedings | null;
  errors?: ValidationErrors | null;
  missing_steps?: AgreementStepSlug[];
  downloadAgreement?: () => void;
  steps?: {
    [step: string]: any;
  };
  stepsImportData?: {
    [stepImportData: string]: any;
  };
  initFetch?: () => void;
}

export interface AgreementStepPreviewProps<V> {
  agreement?: Agreement | null;
  proceeding?: Proceedings | null;
  steps?: {
    [step: string]: any;
  };
  modifiedStep?: null | V;
}

export type AgreementFormStep = {
  slug: AgreementStepSlug;
  component: React.FunctionComponent<AgreementStepProps>;
  previewComponent?: React.FunctionComponent<AgreementStepPreviewProps<unknown>>;
};

export interface AgreementFormStage {
  slug: AgreementStageSlug;
  disabled?: boolean;
  preview?: boolean;
  steps: AgreementFormStep[];
}

export interface AgreementFormConfiguration {
  stages: AgreementFormStage[];
}

export interface AgreementCheckboxes {
  [tag: string]: {
    checked: boolean;
    content: string | null;
    part_id?: number | null;
  }[];
}

export type AgreementMode = string;

export const AGREEMENT_MODE_CONSTRUCTION_WORKS: AgreementMode = 'construction_works';
export const AGREEMENT_MODE_DELIVERIES: AgreementMode = 'deliveries';
export const AGREEMENT_MODE_SERVICES: AgreementMode = 'services';
export const AGREEMENT_MODE_ORDER_FORM: AgreementMode = 'order_form';

export type AgreementType = string;

export const AGREEMENT_TYPE_PROJECT: AgreementType = 'project';
export const AGREEMENT_TYPE_OUTSIDE_ORDER: AgreementType = 'outside_order';
export const AGREEMENT_TYPE_SUPPLEMENT: AgreementType = 'supplement';
