import BaseModal, { Props } from 'modules/Layout/component/Modal';
import React, { useEffect, useState } from 'react';
import { Button, FormGroup } from 'reactstrap';
import Alert from 'modules/Layout/component/Alert';
import FieldsetModules, { IModuleValues } from 'modules/Module/component/Form/ListDetails';
import { ColorVariants, Message, ValidationErrors } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import { ModuleEntity } from 'modules/Module/model/Module';
import { DataUpdateUserSubscriptions } from 'modules/Module/repository';

interface IProps extends Props {
  module: ModuleEntity;
  createNewCredit?: boolean;
  message?: Message;
  setErrors?: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  setMessage?: (value: React.SetStateAction<Message>) => void;
  errors?: ValidationErrors;
  onSubmit?: (isSubscriptionEdit: boolean, requestData: DataUpdateUserSubscriptions) => void;
  loading?: boolean;
}

const ModuleCreateModal: React.FC<IProps> = (props) => {
  const { toggle, module, setMessage, message, onSubmit, loading, setErrors, errors } = props;

  const isValue = React.useRef(false);
  useEffect(() => {
    return () => {
      if (isValue.current) {
        setErrors(null);
        setMessage(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!isValue.current) isValue.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const [moduleValues, setModuleValues] = useState<IModuleValues>({
    date_to: '',
    credits: 0
  });

  const title = `Pakiety dla modułu "${module.name}"`;

  const dispalBody = () => {
    return (
      <FormGroup id="add-module-form">
        <Alert message={message} />
        {loading && <Loader />}
        <div className="modal-body-content">
          <FieldsetModules
            moduleValues={moduleValues}
            setModuleValues={setModuleValues}
            minCreditsValue={0}
            errors={errors}
          />
        </div>
      </FormGroup>
    );
  };

  const convertValues = () => {
    const { date_to, credits } = moduleValues;

    if (!date_to) {
      setMessage({
        value: 'Data nie może być pusta.',
        variant: ColorVariants.Danger
      });
      return;
    }

    if (credits !== null) {
      if (credits < 0) {
        setMessage({
          value: 'Ilość wejść nie może być mniejsza od zera.',
          variant: ColorVariants.Danger
        });
        return;
      }
      onSubmit(false, { module_id: module.id, date_to, original_date_to: '', credits });
    }
  };

  const displayFooter = () => {
    return (
      <div className="modal-actions">
        <Button color="primary" className="waves-effect waves-light" onClick={convertValues}>
          Dodaj
        </Button>
        <Button type="button" className="cancel waves-effect waves-light" onClick={toggle}>
          Anuluj
        </Button>
      </div>
    );
  };

  if (module) {
    return <BaseModal {...props} title={title} body={dispalBody()} footer={displayFooter()} />;
  }
  return null;
};

export default ModuleCreateModal;
