import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';
import './index.scss';

export default function LoaderIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="loader-icon" height={height} viewBox="0 0 99.999 100">
      <g id="Group_397" data-name="Group 397" transform="translate(-909.999 -490)">
        <path
          id="Union_1"
          data-name="Union 1"
          d="M-60.344,671.56V645.309a2.88,2.88,0,0,1,2.881-2.881,2.88,2.88,0,0,1,2.881,2.881V671.56a2.881,2.881,0,0,1-2.881,2.881A2.881,2.881,0,0,1-60.344,671.56Zm23.946-4.873-13.126-22.735a2.88,2.88,0,0,1,1.054-3.935,2.883,2.883,0,0,1,3.937,1.054l13.126,22.735a2.881,2.881,0,0,1-1.055,3.936,2.864,2.864,0,0,1-1.437.387A2.881,2.881,0,0,1-36.4,666.688Zm-46.064,1.055a2.88,2.88,0,0,1-1.056-3.936l13.127-22.735a2.879,2.879,0,0,1,3.935-1.054,2.879,2.879,0,0,1,1.055,3.935l-13.126,22.735a2.882,2.882,0,0,1-2.5,1.442A2.861,2.861,0,0,1-82.462,667.742ZM-18.1,650.5l-22.735-13.127a2.881,2.881,0,0,1-1.055-3.935,2.883,2.883,0,0,1,3.936-1.056l22.735,13.127a2.879,2.879,0,0,1,1.054,3.935,2.878,2.878,0,0,1-2.5,1.442A2.869,2.869,0,0,1-18.1,650.5Zm-82.667-1.055a2.881,2.881,0,0,1,1.055-3.935l22.734-13.127a2.885,2.885,0,0,1,3.937,1.056,2.88,2.88,0,0,1-1.056,3.935L-96.828,650.5a2.867,2.867,0,0,1-1.437.387A2.881,2.881,0,0,1-100.764,649.441ZM-36.6,627.322a2.881,2.881,0,0,1-2.881-2.881A2.882,2.882,0,0,1-36.6,621.56h26.252a2.882,2.882,0,0,1,2.881,2.881,2.881,2.881,0,0,1-2.881,2.881Zm-67.986,0a2.88,2.88,0,0,1-2.88-2.881,2.881,2.881,0,0,1,2.88-2.881H-78.33a2.882,2.882,0,0,1,2.881,2.881,2.881,2.881,0,0,1-2.881,2.881Zm62.695-11.874a2.881,2.881,0,0,1,1.055-3.935L-18.1,598.387a2.882,2.882,0,0,1,3.935,1.055,2.881,2.881,0,0,1-1.054,3.936L-37.951,616.5a2.873,2.873,0,0,1-1.438.386A2.88,2.88,0,0,1-41.887,615.448ZM-76.975,616.5l-22.734-13.126a2.882,2.882,0,0,1-1.055-3.936,2.883,2.883,0,0,1,3.936-1.055l22.734,13.127a2.88,2.88,0,0,1,1.056,3.935,2.881,2.881,0,0,1-2.5,1.441A2.876,2.876,0,0,1-76.975,616.5Zm28.5-7.637a2.881,2.881,0,0,1-1.054-3.936L-36.4,582.194a2.881,2.881,0,0,1,3.935-1.054,2.879,2.879,0,0,1,1.055,3.935L-44.534,607.81a2.883,2.883,0,0,1-2.5,1.443A2.873,2.873,0,0,1-48.47,608.865Zm-21.92-1.056-13.127-22.735a2.879,2.879,0,0,1,1.056-3.935,2.881,2.881,0,0,1,3.935,1.054L-65.4,604.93a2.88,2.88,0,0,1-1.055,3.936,2.862,2.862,0,0,1-1.437.387A2.882,2.882,0,0,1-70.391,607.81Z"
          transform="translate(1017.461 -84.441)"
          fill={fill}
        />
        <path
          id="progress-line"
          className="progress-line"
          data-name="Path 1306"
          d="M-57.463,606.455a2.881,2.881,0,0,1-2.881-2.881V577.322a2.881,2.881,0,0,1,2.881-2.881,2.881,2.881,0,0,1,2.881,2.881v26.252A2.881,2.881,0,0,1-57.463,606.455Z"
          transform="translate(1017.461 -84.441)"
          fill="#B7332D"
        />
      </g>
    </svg>
  );
}
