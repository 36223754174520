import React, { useEffect } from 'react';
import { RootState } from 'app/reducer';
import { resetPasswordAction } from 'modules/Auth/action';
import PasswordResetForm from 'modules/Auth/component/Password/Reset/Form';
import { View } from 'modules/Auth/component/View';
import { ResetPasswordValues } from 'modules/Auth/type';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
import PublicWrapper from 'modules/Layout/component/Wrapper/Public';

export interface OwnProps {
  params: { token: string };
}

const PasswordReset: React.FC<OwnProps> = ({ params }) => {
  const dispatch = useDispatch();
  const { busy, errors, message } = useSelector((state: RootState) => state.auth);
  const { token } = params;
  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const reset = (values: ResetPasswordValues) => dispatch(resetPasswordAction({ ...values, token }));

  useEffect(() => {
    managePage({
      title: 'Reset hasła'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PublicWrapper>
      <View busy={busy} message={message}>
        <span>Wpisz swój adres e-mail oraz nowe hasło.</span>
        <PasswordResetForm busy={busy} errors={errors} submit={reset} />
      </View>
    </PublicWrapper>
  );
};

export default PasswordReset;
