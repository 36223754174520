import React, { useEffect, useMemo } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { createSearchingProps } from 'modules/Shared/helper/params';
import Office from 'modules/Office/model/Office';
import { ROUTE_OFFICES_DOT } from 'modules/Office/routes';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
import { breadcrumbRouteOfficesDot } from 'modules/Office/breadcrumbs';
import { PartialSearchingProps } from 'modules/Shared/type';
import { setOfficeDotListParamsAction } from 'modules/Office/action/list';
import OfficeDotTable from '../../container/OfficeDotList/Table';
import OfficeDotFilter from '../../container/OfficeDotList/Filter';
import OfficeDotListingPagination from '../../container/OfficeDotList/Pagination';
import OfficeDotPerPage from '../../container/OfficeDotList/PerPage';

const OfficesDot = () => {
  const dispatch = useDispatch();
  const { fetching, meta } = useSelector((state: RootState) => state.officeDot.list);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const setParams = (payload: PartialSearchingProps) => dispatch(setOfficeDotListParamsAction(payload));

  const searchProps = useMemo(() => {
    const defaultSearchProps = createSearchingProps(
      '',
      Office.getFilterableAttributes(),
      Office.getSortableAttributes()
    );
    const storedFilter = localStorage.getItem('localFilterParamsoffice_extended');

    if (storedFilter) {
      const parsedFilter = JSON.parse(storedFilter);
      return { ...defaultSearchProps, ...parsedFilter };
    }

    return defaultSearchProps;
  }, []);

  useEffect(() => {
    managePage({
      title: 'Urzędy - dot. PA',
      breadcrumb: breadcrumbRouteOfficesDot()
    });

    setParams({ ...searchProps });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row offices-view">
      <div className="col-12">
        <Listing
          table={<OfficeDotTable />}
          filter={<OfficeDotFilter values={searchProps.filter} />}
          pagination={<OfficeDotListingPagination path={ROUTE_OFFICES_DOT} />}
          perPage={<OfficeDotPerPage />}
          loading={fetching}
          meta={meta}
        />
      </div>
    </div>
  );
};

export default OfficesDot;
