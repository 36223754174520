import categoryListReducer from 'modules/Judgement/reducer/categoryList';
import keywordListReducer from 'modules/Judgement/reducer/keywordsWithCategoriesList';
import judgementListReducer from 'modules/Judgement/reducer/judgementList';
import { combineReducers, Reducer } from 'redux';
import { JudgementState } from 'modules/Judgement/state';

const reducer = (): Reducer<JudgementState> => {
  const reducers = {
    categoryList: categoryListReducer,
    keywordList: keywordListReducer,
    judgementList: judgementListReducer
  };

  return combineReducers<JudgementState>(reducers);
};

export default reducer;
