import { PaginationMeta, SearchingProps } from 'modules/Shared/type';
import Executor from 'modules/Executor/model/Executor';

export interface ExecutorsExtendedListState extends SearchingProps {
  executorsExtended: Executor[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initExecutorExtendedListState = (): ExecutorsExtendedListState => ({
  executorsExtended: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
