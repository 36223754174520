import React, { useEffect, useRef } from 'react';
import { RootState } from 'app/reducer';
import { managePageAction } from 'modules/Layout/action';
import Loader from 'modules/Layout/component/Loader';
import { PageProps } from 'modules/Layout/type';
import { setProfileStateAction, updateCompanyProfileAction } from 'modules/Profile/action';
import { breadcrumbRouteProfileCompanyUpdate } from 'modules/Profile/breadcrumbs';
import CompanyForm from 'modules/Profile/component/CompanyForm';
import { PartialProfileState } from 'modules/Profile/state';
import { ProfileValues } from 'modules/Profile/type';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'modules/Layout/component/Alert';
import CardBox from 'modules/Layout/component/CardBox';

const ProfileCompanyUpdate = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const { updating, errors, message } = useSelector((state: RootState) => state.profile);
  const scrollToTopOnce = useRef<boolean>(true);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const updateProfile = (values: ProfileValues) => dispatch(updateCompanyProfileAction(values, user));
  const setProfileState = (state: PartialProfileState) => dispatch(setProfileStateAction(state));

  useEffect(() => {
    managePage({
      title: 'Profil - edycja danych rozliczeniowych',
      breadcrumb: breadcrumbRouteProfileCompanyUpdate()
    });

    return () => {
      setProfileState({ updating: false, message: null, errors: null });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const updateProfileFnc = (values: ProfileValues) => {
    if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
    return updateProfile(values);
  };

  return (
    <div className="profile-update-view d-flex justify-content-center">
      <CardBox form="small" heading="Dane rozliczeniowe">
        {updating && <Loader />}
        <Alert message={message} />
        {user && (
          <CompanyForm
            profile={user?.getCompany() ?? {}}
            user={user}
            errors={errors}
            submit={updateProfileFnc}
            disabled={updating}
            required
            placeholder={false}
          />
        )}
      </CardBox>
    </div>
  );
};

export default ProfileCompanyUpdate;
