import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_RECEIPT, AGREEMENT_STEP_SUBJECT } from 'modules/Agreements/step';
import {
  getSinglePartsLabel,
  DEL_PERIODICITY_CYCLICAL,
  AgreementStepReceiptValues
} from 'modules/Agreements/components/Steps/Deliveries/Receipt';
import { AgreementStepSubjectValues } from 'modules/Agreements/components/Steps/Deliveries/Subject';

const AgreementStepPreviewReceipt = (props: AgreementStepPreviewProps<AgreementStepReceiptValues>): JSX.Element => {
  const { agreement, steps, modifiedStep } = props;
  const { parts_ids } = agreement;
  const receipt_requirements = getStepValues<AgreementStepReceiptValues>(steps, AGREEMENT_STEP_RECEIPT);
  const { same_subject_for_parts } = getStepValues<AgreementStepSubjectValues>(steps, AGREEMENT_STEP_SUBJECT);

  const { value: parts } = getFieldForPreview<AgreementStepReceiptValues['receipt_requirements']>(
    receipt_requirements,
    modifiedStep,
    'receipt_requirements'
  );

  const { value: sameParts } = getFieldForPreview<AgreementStepReceiptValues['del_same_receipt_requirements']>(
    receipt_requirements,
    modifiedStep,
    'del_same_receipt_requirements'
  );

  const { value: delPeriodicity } = getFieldForPreview<AgreementStepReceiptValues['del_periodicity']>(
    steps,
    modifiedStep,
    'del_periodicity'
  );

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    receipt_requirements,
    modifiedStep,
    `checkboxes.general`
  );

  const renderCheckbox = (checkbox: string, className = 'preview-nested-decimal-point') => {
    return (
      <PreviewFieldCheckbox
        step={receipt_requirements}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const renderCyclicalParts = () => {
    const isMultiple = parts.length > 1 && !same_subject_for_parts;

    return [
      <p className={classNames({ 'preview-point': !isEmpty(generalCheckboxes) })}>
        Zamawiający ustala poniższe wymagania w zakresie odbioru, warunków i miejsca spełnienia świadczenia:
      </p>,
      parts?.map((part, index: number) => {
        const partObj = parts_ids.find((item) => item.id === part.part_id);

        const { value: delCommunication, modified: delCommunicationModified } = getFieldForPreview(
          receipt_requirements,
          modifiedStep,
          `receipt_requirements.${index}.del_communication_checked`
        );

        return (
          <div key={`preview_cyclical_parts_${index}`}>
            {isMultiple && <p>{`- dla ${partObj.getName(index, 'zadania')}`}</p>}
            {[
              renderCheckbox(`receipt_requirements.${index}.del_date`),
              renderCheckbox(`receipt_requirements.${index}.del_authorized_persons`),
              delCommunication && [
                <p className={classNames('preview-nested-decimal-point', { modified: delCommunicationModified })}>
                  Strony zastrzegają, że zamówienie może być złożone przez pracowników zamawiającego:
                </p>,
                renderCheckbox(
                  `receipt_requirements.${index}.del_communication_personal`,
                  'preview-nested-alpha-point pl-4'
                ),
                renderCheckbox(
                  `receipt_requirements.${index}.del_communication_phone`,
                  'preview-nested-alpha-point pl-4'
                ),
                renderCheckbox(
                  `receipt_requirements.${index}.del_communication_email`,
                  'preview-nested-alpha-point pl-4'
                ),
                renderCheckbox(
                  `receipt_requirements.${index}.del_communication_other`,
                  'preview-nested-alpha-point pl-4'
                )
              ],
              renderCheckbox(`receipt_requirements.${index}.del_arrangements`),
              renderCheckbox(`receipt_requirements.${index}.del_executor`),
              renderCheckbox(`receipt_requirements.${index}.del_contractor`),
              renderCheckbox(`receipt_requirements.${index}.del_max_time`),
              renderCheckbox(`receipt_requirements.${index}.del_confirmation`),
              renderCheckbox(`receipt_requirements.${index}.del_rejection`),
              renderCheckbox(`receipt_requirements.${index}.del_rejection_delay`),
              renderCheckbox(`receipt_requirements.${index}.del_rejection_costs`),
              renderCheckbox(`receipt_requirements.${index}.del_unload`),
              renderCheckbox(`receipt_requirements.${index}.del_new_date`),
              renderCheckbox(`receipt_requirements.${index}.del_description`),
              renderCheckbox(`receipt_requirements.${index}.del_transport`),
              renderCheckbox(`receipt_requirements.${index}.del_responsibility`),
              renderCheckbox(`receipt_requirements.${index}.del_guarantee`),
              renderCheckbox(`receipt_requirements.${index}.representation`),
              renderCheckbox(`receipt_requirements.${index}.representation_executor`)
            ]}
          </div>
        );
      })
    ];
  };

  const renderSingleParts = () => {
    const isMultiple = parts.length > 1 && !sameParts;

    let label = getSinglePartsLabel(same_subject_for_parts, isMultiple);

    return [
      <p className={classNames({ 'preview-point': !isEmpty(generalCheckboxes) })}>{label}</p>,
      parts?.map((part, index: number) => {
        const partObj = parts_ids.find((item) => item.id === part.part_id);

        return (
          <div key={`preview_single_parts_${index}`}>
            {isMultiple && <p>{`- dla ${partObj.getName(index, 'zadania')}`}</p>}
            {renderCheckbox(`receipt_requirements.${index}.del_date`)}
            {renderCheckbox(`receipt_requirements.${index}.del_receipt_rules`)}
            {renderCheckbox(`receipt_requirements.${index}.del_documents`)}
            {renderCheckbox(`receipt_requirements.${index}.del_documents_check`)}
            {renderCheckbox(`receipt_requirements.${index}.del_defects`)}
            {renderCheckbox(`receipt_requirements.${index}.del_risk`)}
            {renderCheckbox(`receipt_requirements.${index}.del_transport`)}
            {renderCheckbox(`receipt_requirements.${index}.del_responsibility`)}
            {renderCheckbox(`receipt_requirements.${index}.del_guarantee`)}
            {renderCheckbox(`receipt_requirements.${index}.representation`)}
            {renderCheckbox(`receipt_requirements.${index}.representation_executor`)}
          </div>
        );
      })
    ];
  };

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_RECEIPT)}</div>
      <div className="agreement-step-preview-content reset-preview-point">
        {delPeriodicity && (delPeriodicity === DEL_PERIODICITY_CYCLICAL ? renderCyclicalParts() : renderSingleParts())}

        {generalCheckboxes &&
          generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-point wysiwyg-preview', {
                  modified: generalCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AgreementStepPreviewReceipt;
