import { Message, ValidationErrors } from 'modules/Shared/type';

export type ProfileState = {
  updating: boolean;
  message?: Message;
  errors?: ValidationErrors;
};

export type PartialProfileState = Partial<ProfileState>;

export const initProfileState = (): ProfileState => ({
  updating: false
});
