import React from 'react';
import Authorize from 'modules/Auth/container/Authorize';
import CardBox from 'modules/Layout/component/CardBox';
import { getPathUrl } from 'modules/Shared/helper/api';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import Proceedings from 'modules/Proceedings/model/Proceedings';
import { PROCEEDINGS_DELETE, PROCEEDINGS_EDIT } from 'modules/Proceedings/permissions';
import ProceedingsBasicDataset from 'modules/Proceedings/component/Dataset/Basic';
import { ROUTE_PROCEEDINGS_UPDATE } from 'modules/Proceedings/routes';
import ProceedingsDetailsFileListTable from 'modules/Proceedings/container/ProceedingsDetailsFileList';
import { PROCEEDINGS_INIT_TYPES_VALUES } from 'modules/Proceedings/type';
import ProceedingsNewBoxDataset from 'modules/Proceedings/component/Dataset/NewBox';
import Listing from 'modules/Layout/component/Listing';

type Props = {
  proceedings: Proceedings;
  setDisplayDeleteModal?: () => void;
  isAdmin?: boolean;
  aditionalActions: () => JSX.Element;
  proceedingsType: number;
};

const ProceedingsBasicDetails: React.FC<Props> = ({
  proceedings,
  setDisplayDeleteModal,
  isAdmin,
  aditionalActions,
  proceedingsType
}) => {
  const displayBox = (
    <div className="col-12 executor-details-admin">
      <CardBox
        heading="Szczegóły postępowania"
        paddingRight="30px"
        paddingBottom="0"
        actions={
          <>
            <Authorize permissions={[PROCEEDINGS_EDIT]}>
              <Link
                className="link-with-border"
                to={{
                  pathname: getPathUrl(ROUTE_PROCEEDINGS_UPDATE, { proceedingsType, id: proceedings?.id }),
                  state: { proceedings }
                }}
              >
                EDYTUJ
              </Link>
            </Authorize>
            {setDisplayDeleteModal && (
              <Authorize permissions={[PROCEEDINGS_DELETE]}>
                <Button color="link" className="link-with-border p-0" onClick={setDisplayDeleteModal}>
                  USUŃ
                </Button>
              </Authorize>
            )}
            {aditionalActions()}
          </>
        }
      >
        {proceedingsType === PROCEEDINGS_INIT_TYPES_VALUES.PLANNING && (
          <div className="information-list-item" style={{ marginTop: '-0.5rem' }}>
            <p className="information-list-paragraph text-danger">
              <strong style={{ marginRight: '0.25rem' }}>
                Ogłoszenie pochodzi z planu zamówień, postępowanie nie jest jeszcze wszczęte.
              </strong>
            </p>
          </div>
        )}
        <ProceedingsBasicDataset element={proceedings} isAdmin={isAdmin} />
      </CardBox>

      <ProceedingsNewBoxDataset element={proceedings} />

      {!proceedings.from_bzp && (
        <div className="col-12 p-0">
          <CardBox heading="Załączniki" paddingRight="30px">
            <div className="row users-view">
              <div className="col-12">
                <div className="pzpeu-table-list inside-card-box" style={{ marginRight: '-30px' }}>
                  <Listing
                    table={
                      proceedings?.files?.length ? (
                        <ProceedingsDetailsFileListTable filesList={proceedings?.files} />
                      ) : null
                    }
                  />
                </div>
                {proceedings?.files?.length === 0 && <div style={{ marginBottom: '-20px' }}>brak załączników</div>}
              </div>
            </div>
          </CardBox>
        </div>
      )}
    </div>
  );

  return isAdmin ? (
    displayBox
  ) : (
    <div className="row justify-content-center user-details-view position-relative flex-1">
      <div className="col-lg-9">{displayBox}</div>
    </div>
  );
};

export default ProceedingsBasicDetails;
