import React from 'react';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_DELIVERY_REQUIREMENTS } from 'modules/Agreements/step';
import { AgreementStepDeliveryRequirementsValues } from 'modules/Agreements/components/Steps/Deliveries/DeliveryRequirements';

const AgreementStepPreviewDeliveryRequirements = (
  props: AgreementStepPreviewProps<AgreementStepDeliveryRequirementsValues>
): JSX.Element => {
  const { agreement, steps, modifiedStep } = props;
  const delivery_requirements = getStepValues<AgreementStepDeliveryRequirementsValues>(
    steps,
    AGREEMENT_STEP_DELIVERY_REQUIREMENTS
  );

  const { value: parts } = getFieldForPreview<AgreementStepDeliveryRequirementsValues['delivery_requirements']>(
    delivery_requirements,
    modifiedStep,
    'delivery_requirements'
  );

  const { value: sameParts } = getFieldForPreview<
    AgreementStepDeliveryRequirementsValues['same_delivery_requirements_for_parts']
  >(delivery_requirements, modifiedStep, 'same_delivery_requirements_for_parts');

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    delivery_requirements,
    modifiedStep,
    `checkboxes.general`
  );

  const isMultiple = agreement.few_parts && !sameParts;

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={delivery_requirements}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_DELIVERY_REQUIREMENTS)}</div>
      <div className="reset-preview-point">
        {parts?.map((part, index: number) => {
          const keyName = `delivery_requirements.${index}`;

          const { value: delRightToControlPossibility, modified: delRightToControlPossibilityModified } =
            getFieldForPreview(delivery_requirements, modifiedStep, `${keyName}.del_right_to_control_possibility`);
          const { value: delNoDefectsPossibility, modified: delNoDefectsPossibilityModified } = getFieldForPreview(
            delivery_requirements,
            modifiedStep,
            `${keyName}.del_no_defects_possibility`
          );
          const { value: delNoDefectsLawChecked } = getFieldForPreview(
            delivery_requirements,
            modifiedStep,
            `${keyName}.del_no_defects_law_checked`
          );
          const { value: delNoDefectsConstructionChecked } = getFieldForPreview(
            delivery_requirements,
            modifiedStep,
            `${keyName}.del_no_defects_construction_checked`
          );
          const { value: delNoDefectsMaterialChecked } = getFieldForPreview(
            delivery_requirements,
            modifiedStep,
            `${keyName}.del_no_defects_material_checked`
          );
          const { value: delNoDefectsManufacturingChecked } = getFieldForPreview(
            delivery_requirements,
            modifiedStep,
            `${keyName}.del_no_defects_manufacturing_checked`
          );
          const { value: delNoDefectsOtherChecked } = getFieldForPreview(
            delivery_requirements,
            modifiedStep,
            `${keyName}.del_no_defects_other_checked`
          );
          const { value: delNoDefects2RightsChecked } = getFieldForPreview(
            delivery_requirements,
            modifiedStep,
            `${keyName}.del_no_defects_2_rights_checked`
          );
          const { value: delNoDefects2ProcedureChecked } = getFieldForPreview(
            delivery_requirements,
            modifiedStep,
            `${keyName}.del_no_defects_2_procedure_checked`
          );
          const { value: delNoDefects2CollateralChecked } = getFieldForPreview(
            delivery_requirements,
            modifiedStep,
            `${keyName}.del_no_defects_2_collateral_checked`
          );
          const { value: delNoDefects2OtherChecked } = getFieldForPreview(
            delivery_requirements,
            modifiedStep,
            `${keyName}.del_no_defects_2_other_checked`
          );

          const delNoDefectsFirstPartStatus =
            delNoDefectsLawChecked ||
            delNoDefectsConstructionChecked ||
            delNoDefectsMaterialChecked ||
            delNoDefectsManufacturingChecked ||
            delNoDefectsOtherChecked;

          const delNoDefectsSecondPartStatus =
            delNoDefects2RightsChecked ||
            delNoDefects2ProcedureChecked ||
            delNoDefects2CollateralChecked ||
            delNoDefects2OtherChecked;

          const partObj = agreement.parts_ids.find((item) => item.id === part.part_id);

          return (
            <div key={index} className="agreement-step-preview-content reset-preview-point">
              {isMultiple && partObj && <div className="h5">{partObj.getName(index)}:</div>}
              {renderCheckbox(`delivery_requirements.${index}.del_quality_requirements`)}
              {delNoDefectsPossibility && [
                (delNoDefectsFirstPartStatus || delNoDefectsSecondPartStatus) && (
                  <p
                    className={classNames('preview-point', {
                      modified: delNoDefectsPossibilityModified
                    })}
                  >
                    {delNoDefectsFirstPartStatus
                      ? 'Wykonawca oświadcza, że Przedmiot Umowy jest wolny od:'
                      : 'Wykonawca oświadcza, że Przedmiot Umowy:'}
                  </p>
                ),
                renderCheckbox(`delivery_requirements.${index}.del_no_defects_law`, 'preview-nested-alpha-point'),
                renderCheckbox(
                  `delivery_requirements.${index}.del_no_defects_construction`,
                  'preview-nested-alpha-point'
                ),
                renderCheckbox(`delivery_requirements.${index}.del_no_defects_material`, 'preview-nested-alpha-point'),
                renderCheckbox(
                  `delivery_requirements.${index}.del_no_defects_manufacturing`,
                  'preview-nested-alpha-point'
                ),
                renderCheckbox(`delivery_requirements.${index}.del_no_defects_other`, 'preview-nested-alpha-point'),
                delNoDefectsSecondPartStatus && delNoDefectsFirstPartStatus && (
                  <p
                    className={classNames('reset-nested-preview-point', {
                      modified: delNoDefectsPossibilityModified
                    })}
                  >
                    oraz, że Przedmiot Umowy:
                  </p>
                ),
                renderCheckbox(`delivery_requirements.${index}.del_no_defects_2_rights`, 'preview-nested-alpha-point'),
                renderCheckbox(
                  `delivery_requirements.${index}.del_no_defects_2_procedure`,
                  'preview-nested-alpha-point'
                ),
                renderCheckbox(
                  `delivery_requirements.${index}.del_no_defects_2_collateral`,
                  'preview-nested-alpha-point'
                ),
                renderCheckbox(`delivery_requirements.${index}.del_no_defects_2_other`, 'preview-nested-alpha-point')
              ]}
              {renderCheckbox(`delivery_requirements.${index}.del_brand_new`)}
              {renderCheckbox(`delivery_requirements.${index}.del_proper_transportation`)}
              {renderCheckbox(`delivery_requirements.${index}.del_right_conditions`)}
              {delRightToControlPossibility && [
                <p
                  className={classNames('preview-point', {
                    modified: delRightToControlPossibilityModified
                  })}
                >
                  W trakcie realizacji umowy zamawiający ma prawo sprawdzić odpowiednio i stosownie warunki:
                </p>,
                renderCheckbox(
                  `delivery_requirements.${index}.del_right_to_control_breeding`,
                  'preview-nested-alpha-point'
                ),
                renderCheckbox(
                  `delivery_requirements.${index}.del_right_to_control_storage`,
                  'preview-nested-alpha-point'
                ),
                renderCheckbox(
                  `delivery_requirements.${index}.del_right_to_control_transport`,
                  'preview-nested-alpha-point'
                ),
                <p
                  className={classNames({
                    modified: delRightToControlPossibilityModified
                  })}
                >
                  Przedmiotu Umowy, pod kątem zgodności z opisem przedmiotu zamówienia
                </p>
              ]}
              {renderCheckbox(`delivery_requirements.${index}.del_registry_entry`)}
              {renderCheckbox(`delivery_requirements.${index}.del_registry_entry_current`)}
              {renderCheckbox(`delivery_requirements.${index}.del_certificate`)}
              {renderCheckbox(`delivery_requirements.${index}.del_certificate_current`)}
              {generalCheckboxes &&
                generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
                  if (!checkbox.checked) return null;

                  if (checkbox.part_id != part.part_id) return null;

                  return (
                    <p
                      className={classNames('preview-point wysiwyg-preview', {
                        modified: generalCheckboxesModified
                      })}
                      key={index}
                      dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                    />
                  );
                })}
            </div>
          );
        })}
        {isMultiple && generalCheckboxes && (
          <div className="agreement-step-preview-content">
            <p className="h5 reset-preview-point">Ponadto dla każdego z zadań:</p>
            {generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
              if (!checkbox.checked) return null;

              if (checkbox.part_id) return null;

              return (
                <p
                  className={classNames('preview-point wysiwyg-preview', {
                    modified: generalCheckboxesModified
                  })}
                  key={index}
                  dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AgreementStepPreviewDeliveryRequirements;
