import React from 'react';
import EditRODO from 'modules/Regulation/component/RodoForm';
import { updateProfileRodo } from 'modules/Regulation/repository';
import { UserEntity } from 'modules/User/model/User';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { SetAuthUserAction, SET_AUTH_USER } from 'modules/Auth/action';
import { addToastAction } from 'modules/Layout/action';
import { ROUTE_PROFILE } from 'modules/Profile/routes';
import { updateProfileRodoToastSuccess } from 'modules/Profile/toasts';

function withProfileRodoRequest<P>(WrappedComponent: React.ComponentType<P>) {
  const Component = (props: P) => {
    const dispatch = useDispatch();
    const dispatchUpdateProfileRodoToast = () => dispatch(addToastAction(updateProfileRodoToastSuccess()));

    const onSuccessRodoUpdate = (user: UserEntity) => {
      dispatchUpdateProfileRodoToast();
      dispatch<SetAuthUserAction>({
        type: SET_AUTH_USER,
        payload: user
      });
      dispatch(push(ROUTE_PROFILE));
    };

    return (
      <WrappedComponent {...props} updateRodoRequest={updateProfileRodo} onSuccessRodoUpdate={onSuccessRodoUpdate} />
    );
  };
  return Component;
}

// eslint-disable-next-line import/prefer-default-export
export const ProfileRodo = withProfileRodoRequest(EditRODO);
