import { RootState } from 'app/reducer';
import Authorize from 'modules/Auth/container/Authorize';
import { setJudgementListParamsAction } from 'modules/Judgement/action/JudgementList';
import Judgement from 'modules/Judgement/model/Judgement';
import { JUDGMENTS_DELETE, JUDGMENTS_DETAILS, JUDGMENTS_EDIT } from 'modules/Judgement/permissions';
import {
  ROUTE_JUDGEMENT,
  ROUTE_JUDGEMENT_UPDATE_BASIC,
  ROUTE_JUDGEMENT_UPDATE_CONTENT
} from 'modules/Judgement/routes';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import EditIcon from 'modules/Layout/component/Icon/Edit';
import EditJudgmentContentIcon from 'modules/Layout/component/Icon/EditJudgmentContent';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { getPathUrl } from 'modules/Shared/helper/api';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { PartialSearchingProps } from 'modules/Shared/type';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

type Props = { onDeleteClick?: (judgement: Judgement) => void };

const JudgementTable: React.FC<Props> = ({ onDeleteClick }) => {
  const dispatch = useDispatch();
  const { list, sort } = useSelector((state: RootState) => state.judgement.judgementList);

  const setParams = (payload: PartialSearchingProps) => dispatch(setJudgementListParamsAction(payload));

  const redirectLink = (value: string, id: number) => (
    <Authorize permissions={[JUDGMENTS_DETAILS]} fallback={value}>
      <Link className="fill-table-td" to={getPathUrl(ROUTE_JUDGEMENT, { id })}>
        {value}
      </Link>
    </Authorize>
  );

  const cols: TableCol<Judgement>[] = [
    {
      property: 'id',
      label: 'ID',
      sortable: true,
      value: (row) => redirectLink(`${row.id}`, row.id)
    },
    {
      property: 'identifier',
      label: 'Identyfikator publikacji',
      sortable: true,
      value: (row) => redirectLink(row.identifier, row.id)
    },
    {
      property: 'authority_name',
      label: 'Nazwa organu publikującego dokument',
      sortable: true
    },
    {
      property: 'authority_type',
      label: 'Rodzaj organu publikującego dokument',
      sortable: true
    },
    {
      property: 'document_type',
      label: 'Rodzaj dokumentu',
      sortable: true
    },
    {
      property: 'published_at ',
      label: 'Termin publikacji',
      sortable: true,
      value: (row) => ifContainsDateFormat(row.published_at, formatDate)
    },
    {
      property: 'place',
      label: 'Oryginalne miejsce publikacji',
      sortable: true
    },
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            <Authorize permissions={[JUDGMENTS_EDIT]}>
              <ActionUpdate
                title="Edytuj orzeczenie"
                label={<EditIcon height="20px" />}
                to={getPathUrl(ROUTE_JUDGEMENT_UPDATE_BASIC, { id: row.id })}
              />
              <ActionUpdate
                title="Edytuj treść orzeczenia"
                className="ml-1"
                label={<EditJudgmentContentIcon height="20px" />}
                to={getPathUrl(ROUTE_JUDGEMENT_UPDATE_CONTENT, { id: row.id })}
              />
            </Authorize>
            {onDeleteClick && (
              <Authorize permissions={[JUDGMENTS_DELETE]}>
                <ActionDelete title="Usuń orzeczenie" onClick={() => onDeleteClick(row)} />
              </Authorize>
            )}
          </div>
        );
      }
    }
  ];

  return <Table cols={cols} rows={list} sort={sort} onSort={(params) => setParams({ sort: params })} />;
};

export default JudgementTable;
