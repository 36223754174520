import foreignProceedingsListReducer from 'modules/ForeignProceedings/reducer/foreignProceedingsList';
import { combineReducers, Reducer } from 'redux';
import { ForeignProceedingsState } from '../../state/foreignProceedings';

const reducer = (): Reducer<ForeignProceedingsState> => {
  const reducers = {
    foreignProceedingsList: foreignProceedingsListReducer
  };

  return combineReducers<ForeignProceedingsState>(reducers);
};

export default reducer;
