import { RootState } from 'app/reducer';
import { setClauseListParamsAction } from 'modules/ClauseClient/action/ClauseList';
import Clause from 'modules/Clause/model/Clause';
import { ROUTE_CLIENT_CLAUSE } from 'modules/ClauseClient/routes';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { getPathUrl } from 'modules/Shared/helper/api';
import { PartialSearchingProps } from 'modules/Shared/type';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import trimmedString from 'modules/Shared/helper/trimmedString';

const ClauseTable = () => {
  const dispatch = useDispatch();
  const { list, sort } = useSelector((state: RootState) => state.clause.clauseList);

  const setParams = (payload: PartialSearchingProps) => dispatch(setClauseListParamsAction(payload));

  const redirectLink = (value: string, id: number) => (
    <Link className="fill-table-td" to={getPathUrl(ROUTE_CLIENT_CLAUSE, { id })}>
      {value}
    </Link>
  );

  const cols: TableCol<Clause>[] = [
    {
      property: 'name',
      label: 'Nazwa klauzuli',
      sortable: true,
      value: (row) => redirectLink(row.name, row.id)
    },
    {
      property: 'description',
      label: 'Wyjaśnienie klauzuli',
      sortable: true,
      value: function Description(row) {
        return <div dangerouslySetInnerHTML={{ __html: trimmedString(row.description ?? '', 100) }} />;
      }
    },
    {
      property: 'category_name',
      label: 'Nazwa kategorii klauzuli',
      sortable: true,
      value: (row) => row.category?.name
    },
    {
      property: 'keywords_names',
      label: 'Słowa kluczowe'
    }
  ];

  return <Table cols={cols} rows={list} sort={sort} onSort={(params) => setParams({ sort: params })} />;
};

export default ClauseTable;
