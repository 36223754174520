import firebase from 'firebase/app';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: `${process.env.FIREBASE_KEY}`,
  authDomain: `${process.env.FIREBASE_DOMAIN}.firebaseapp.com`,
  projectId: `${process.env.FIREBASE_DOMAIN}`,
  storageBucket: `${process.env.FIREBASE_DOMAIN}.appspot.com`,
  messagingSenderId: `${process.env.FIREBASE_MESSAGING_SENDER_ID}`,
  appId: `${process.env.FIREBASE_APP_ID}`,
  measurementId: `${process.env.FIREBASE_MEASUREMENT_ID}`
};

/*
const firebaseConfig = {
  apiKey: 'AIzaSyBxnKc4fJySVCfevIFDMplhNBHIHUYF5GI',
  authDomain: 'pzpue-677f6.firebaseapp.com',
  projectId: 'pzpue-677f6',
  storageBucket: 'pzpue-677f6.appspot.com',
  messagingSenderId: '772897139154',
  appId: '1:772897139154:web:7054d6ec1f0b966924800b',
  measurementId: 'G-4R7TQCBPYF'
};
*/

const app = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();

export const analytics = firebase.analytics();
export default app;
