export const paymentLogStatusValue = (status: number) => {
  switch (status) {
    case 2:
      return 'Zakończono';
    case 1:
      return 'W trakcie';
    case 0:
      return 'Anulowano';
    default:
      return status;
  }
};

export const logStatusValue = (status: string) => {
  switch (status) {
    case 'succeeded':
      return 'Zakończono';
    case 'started':
      return 'Rozpoczęto';
    case 'cancelled':
      return 'Anulowano';
    default:
      return status;
  }
};
