import _get from 'lodash/get';
import { SearchParams } from 'modules/Shared/type';
import { FilterInputType } from 'modules/Layout/type';
import { agreementsModeOptions, agreementsTypeOptions } from 'modules/Agreements/model/Agreements';

export interface Props {
  values?: SearchParams;
}

const useFilterValues = ({ values = {} }: Props) => {
  const staticInputs: FilterInputType[] = [
    {
      type: 'select',
      property: 'mode',
      label: 'Tryb umowy ',
      options: [
        {
          value: '',
          label: ''
        },
        ...agreementsModeOptions()
      ],
      value: _get(values, 'mode'),
      acceptValueAsString: true
    },
    {
      type: 'select',
      property: 'type',
      label: 'Cel umowy',
      options: [
        {
          value: '',
          label: ''
        },
        ...agreementsTypeOptions()
      ],
      value: _get(values, 'type'),
      acceptValueAsString: true
    },
    {
      type: 'text',
      property: 'name',
      label: 'Robocza nazwa umowy',
      value: _get(values, 'name')
    },
    {
      type: 'text',
      property: 'agreement_subject',
      label: 'Opis Przedmiotu zamówienia',
      value: _get(values, 'agreement_subject')
    }
  ];

  const dynamicInputs = (): FilterInputType[] => {
    const createdAtFrom = _get(values, 'created_at_from') as string;
    const createdAtTo = _get(values, 'created_at_to') as string;

    return [
      {
        beforeLineBreak: true,
        type: 'datetime',
        property: 'created_at_from',
        label: 'Data utworzenia od',
        value: createdAtFrom,
        max: String(createdAtTo)
      },
      {
        type: 'datetime',
        property: 'created_at_to',
        label: 'Data utworzenia do',
        value: createdAtTo,
        min: String(createdAtFrom)
      }
    ];
  };

  return { inputs: [...staticInputs, ...dynamicInputs()] };
};

export default useFilterValues;
