import _ from 'lodash';
import { Message, ValidationErrors } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { useState } from 'react';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import { addToastAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import { updateProceedingsCPV } from 'modules/Proceedings/repository';
import { ProceedingsCPVValues } from 'modules/Proceedings/type';
import { updateProceedingsCPVToastSuccess } from 'modules/Proceedings/toasts';

const useCPVUpdateModal = (proceeding_id: string | number, onSuccess?: (data?: ProceedingsCPVValues) => void) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [displayEditModal, setDisplayEditModal] = useState<ProceedingsCPVValues>(null);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);

  const cancelToken = useCancelToken();
  const dispatchUpdateCPVToast = () => dispatch(addToastAction(updateProceedingsCPVToastSuccess()));

  const updateCPVAction = async (data: { name: string; code: string }) => {
    setLoading(true);

    const { id } = displayEditModal;

    const {
      cancelled,
      data: resData,
      message: messageRequest,
      error
    } = await updateRequestWithData(updateProceedingsCPV, id, proceeding_id, data, cancelToken);

    if (cancelled) return;
    if (resData?.data) {
      dispatchUpdateCPVToast();
      setDisplayEditModal(null);
      if (onSuccess) onSuccess(resData.data);
    }
    if (error) setErrors(error);
    if (messageRequest) setMessage(messageRequest);
    setLoading(false);
  };

  const clearErrors = () => {
    setErrors(null);
    setMessage(null);
  };

  return {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updateCPVAction,
    displayEditModal,
    setDisplayEditModal,
    loading,
    message,
    errors,
    clearErrors
  };
};

export default useCPVUpdateModal;
