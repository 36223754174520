import { SelectOption } from 'modules/Shared/type';

export interface UserRoleEntity {
  id: number;
  slug: string;
  name: string;
}

export const ROLE_ADMIN = 'admin';
export const ROLE_ADMIN_BS = 'admin_BS';
export const ROLE_CLIENT = 'client';
export const ROLE_CLIENT_BS = 'client_BS';
export const CLIENT_ROLES = [ROLE_CLIENT, ROLE_CLIENT_BS];
export const ADMIN_ROLES = [ROLE_ADMIN, ROLE_ADMIN_BS];
export const ROLE_GUEST = 'guest';

export default class UserRole implements UserRoleEntity {
  readonly id: number;
  readonly name: string;
  readonly slug: string;

  constructor(entity: UserRoleEntity) {
    this.id = entity.id;
    this.slug = entity.slug;
    this.name = entity.name;
  }

  getEntity(): UserRoleEntity {
    return {
      id: this.id,
      name: this.name,
      slug: this.slug
    };
  }

  hasSlug(slug: string): boolean {
    return this.slug === slug;
  }

  toOption(): SelectOption {
    return {
      value: this.id,
      label: this.name
    };
  }
}

export const createUserRole = (entity: UserRoleEntity): UserRole => new UserRole(entity);
