import { addToastAction, managePageAction } from 'modules/Layout/action';
import Form from 'modules/Proceedings/component/Form';
import React, { useEffect, useState, useRef } from 'react';
import Loader from 'modules/Layout/component/Loader';
import { useDispatch } from 'react-redux';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import CardBox from 'modules/Layout/component/CardBox';
import { breadcrumbRouteProceedingsCreate } from 'modules/Proceedings/breadcrumbs';
import { createProceedingsToastSuccess } from 'modules/Proceedings/toasts';
import Proceedings from 'modules/Proceedings/model/Proceedings';
import { createNewProceedings } from 'modules/Proceedings/repository';
import { ROUTE_PROCEEDING, ROUTE_PROCEEDINGS_PLANNING, ROUTE_PROCEEDINGS_OPEN } from 'modules/Proceedings/routes';
import { useParams } from 'react-router-dom';
import { PROCEEDINGS_INIT_TYPES_VALUES } from 'modules/Proceedings/type';

const ProceedingsCreateView = () => {
  const dispatch = useDispatch();
  const { proceedingsType } = useParams<{ proceedingsType: string }>();
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState(null);
  const scrollToTopOnce = useRef<boolean>(true);

  const proceedingsTypeInt = parseInt(proceedingsType, 10) || PROCEEDINGS_INIT_TYPES_VALUES.PLANNING;

  const cancelToken = useCancelToken();
  useEffect(() => {
    dispatch(
      managePageAction({
        title: 'Tworzenie postępowania',
        breadcrumb: breadcrumbRouteProceedingsCreate(proceedingsTypeInt)
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchCreateProceedingsToast = () => dispatch(addToastAction(createProceedingsToastSuccess()));
  const setCreateProceedingsAction = async (data: Proceedings) => {
    setFetching(true);

    if (message) setMessage(null);
    if (errors) setErrors(null);

    const {
      message: messageResponse,
      error,
      cancelled,
      data: proceedingsData
    } = await updateRequestWithData(createNewProceedings, data, cancelToken);

    if (cancelled) return;

    if (proceedingsData) {
      dispatchCreateProceedingsToast();
      const { id } = proceedingsData?.data;
      if (id) dispatch(push(getPathUrl(ROUTE_PROCEEDING, { proceedingsType, id })));
      else
        dispatch(
          push(
            proceedingsTypeInt === PROCEEDINGS_INIT_TYPES_VALUES.PLANNING
              ? ROUTE_PROCEEDINGS_PLANNING
              : ROUTE_PROCEEDINGS_OPEN
          )
        );
    } else {
      if (error) setErrors(error);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
  }, [errors]);

  return (
    <div className="user-create-view d-flex justify-content-center">
      <CardBox
        heading={`Dodaj postępowanie${
          proceedingsTypeInt === PROCEEDINGS_INIT_TYPES_VALUES.PLANNING ? ' planowane' : ' prowadzone'
        }`}
      >
        {fetching && <Loader />}
        <Alert message={message} />
        <Form
          errors={errors}
          submit={(data) => setCreateProceedingsAction(data)}
          proceedings_type={proceedingsTypeInt}
          key={proceedingsTypeInt}
        />
      </CardBox>
    </div>
  );
};

export default ProceedingsCreateView;
