import { AgreementsModeValues, AgreementsMode, AgreementsTypeValues, AgreementsType } from 'modules/Agreements/type';
import Part, { PartEntity } from 'modules/Agreements/model/Part';

export type AgreementEntity = {
  id: number;
  mode: string;
  type: string;
  few_parts: boolean;
  parts_ids: PartEntity[];
  parts: number;
  proceeding_id: number;
  name: string;
  created_at: string;
  updated_at: string;
  last_change_at: string;
};

export default class Agreement {
  readonly id?: number;
  readonly mode?: string;
  readonly type?: string;
  readonly parts_ids?: Part[];
  readonly few_parts?: boolean;
  readonly parts?: number;
  readonly proceeding_id?: number;
  readonly name?: string;
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly last_change_at?: string;

  constructor(entity: AgreementEntity) {
    this.id = entity.id;
    this.mode = entity.mode;
    this.type = entity.type;
    this.parts_ids = entity.parts_ids.map((part) => new Part(part));
    this.few_parts = entity.few_parts;
    this.parts = entity.parts;
    this.proceeding_id = entity.proceeding_id;
    this.name = entity.name;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
    this.last_change_at = entity.last_change_at;
  }

  static getFilterableAttributes(): string[] {
    return ['mode', 'type', 'name', 'created_at_from', 'created_at_to'];
  }

  static getSortableAttributes(): string[] {
    return ['mode', 'type', 'name', 'created_at', 'last_change_at'];
  }
}

export const createAgreement = (entity: AgreementEntity): Agreement => new Agreement(entity);

export const agreementsTypeValue = (type?: string) => {
  switch (type) {
    case AgreementsTypeValues.PROJECT:
      return AgreementsType.PROJECT;
    case AgreementsTypeValues.OUTSIDE_ORDER:
      return AgreementsType.OUTSIDE_ORDER;
    case AgreementsTypeValues.SUPPLEMENT:
      return AgreementsType.SUPPLEMENT;
    default:
      return '';
  }
};

export const agreementsTypeOptions = () => {
  return [
    { value: AgreementsTypeValues.PROJECT, label: AgreementsType.PROJECT },
    { value: AgreementsTypeValues.OUTSIDE_ORDER, label: AgreementsType.OUTSIDE_ORDER },
    { value: AgreementsTypeValues.SUPPLEMENT, label: AgreementsType.SUPPLEMENT }
  ];
};

export const agreementsModeValue = (mode?: string) => {
  switch (mode) {
    case AgreementsModeValues.CONSTRUCTION_WORKS:
      return AgreementsMode.CONSTRUCTION_WORKS;
    case AgreementsModeValues.DELIVERIES:
      return AgreementsMode.DELIVERIES;
    case AgreementsModeValues.SERVICES:
      return AgreementsMode.SERVICES;
    case AgreementsModeValues.ORDER_FORM:
      return AgreementsMode.ORDER_FORM;
    default:
      return '';
  }
};

export const agreementsModeOptions = () => {
  return [
    { value: AgreementsModeValues.CONSTRUCTION_WORKS, label: AgreementsMode.CONSTRUCTION_WORKS },
    { value: AgreementsModeValues.DELIVERIES, label: AgreementsMode.DELIVERIES },
    { value: AgreementsModeValues.SERVICES, label: AgreementsMode.SERVICES },
    { value: AgreementsModeValues.ORDER_FORM, label: AgreementsMode.ORDER_FORM }
  ];
};
