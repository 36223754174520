import { Routes } from 'app/routes';
import Authorize from 'modules/Auth/container/Authorize';
import AgreementsListView from 'modules/Agreements/view';
import React from 'react';
import { Route } from 'react-router-dom';
import ModuleGuard from 'modules/Auth/container/Guard/ModuleGuard';
import { MODULE_AGREEMENTS } from 'modules/Module/model/Module';
import AgreementCreateView from 'modules/Agreements/view/Create';
import AgreementUpdateConstructionWorksView from 'modules/Agreements/view/Update/ConstructionWorks';
import AgreementUpdateServicesView from 'modules/Agreements/view/Update/Services';
import AgreementUpdateDeliveriesView from 'modules/Agreements/view/Update/Deliveries';
import AgreementUpdateOrderFormView from 'modules/Agreements/view/Update/OrderForm';

export const ROUTE_AGREEMENTS = '/agreements';
export const ROUTE_AGREEMENT_CREATE = '/agreement';
export const ROUTE_AGREEMENT_UPDATE = '/agreement/:mode/:id(\\d+)/:stage?/:step?';
export const ROUTE_AGREEMENT_UPDATE_CONSTRUCTION_WORKS = '/agreement/construction_works/:id(\\d+)/:stage?/:step?';
export const ROUTE_AGREEMENT_UPDATE_SERVICES = '/agreement/services/:id(\\d+)/:stage?/:step?';
export const ROUTE_AGREEMENT_UPDATE_DELIVERIES = '/agreement/deliveries/:id(\\d+)/:stage?/:step?';
export const ROUTE_AGREEMENT_UPDATE_ORDER_FORM = '/agreement/order_form/:id(\\d+)/:stage?/:step?';

export const createAgreementsRoutes = (): Routes => ({
  wrapped: [
    <Authorize key="agreements">
      <Route
        path={ROUTE_AGREEMENTS}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_AGREEMENTS}>
            <AgreementsListView />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize key="agreement-update-construction-works">
      <Route
        path={ROUTE_AGREEMENT_UPDATE_CONSTRUCTION_WORKS}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_AGREEMENTS}>
            <AgreementUpdateConstructionWorksView />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize key="agreement-update-services">
      <Route
        path={ROUTE_AGREEMENT_UPDATE_SERVICES}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_AGREEMENTS}>
            <AgreementUpdateServicesView />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize key="agreement-update-deliveries">
      <Route
        path={ROUTE_AGREEMENT_UPDATE_DELIVERIES}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_AGREEMENTS}>
            <AgreementUpdateDeliveriesView />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize key="agreement-update-order-form">
      <Route
        path={ROUTE_AGREEMENT_UPDATE_ORDER_FORM}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_AGREEMENTS}>
            <AgreementUpdateOrderFormView />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize key="agreement-create">
      <Route
        path={ROUTE_AGREEMENT_CREATE}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_AGREEMENTS}>
            <AgreementCreateView />
          </ModuleGuard>
        )}
      />
    </Authorize>
  ]
});
