import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import Clause, { createClause } from 'modules/Clause/model/Clause';
import { fetchClientClause } from 'modules/ClauseClient/repository';
import { breadcrumbRouteClientClauseDetails } from 'modules/ClauseClient/breadcrumbs';
import ClauseDisplayDetails from 'modules/ClauseClient/component/Details';
import { analytics } from 'firebase';

const ClauseDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [clause, setClause] = useState<Clause>(null);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const { data, cancelled, message: messageResponse } = await fetchRequest(fetchClientClause, id, cancelToken);

      if (cancelled) return;
      if (data) setClause(createClause(data.data));
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();

    analytics.logEvent('clause_activation');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clause)
      dispatch(
        managePageAction({
          title: 'Szczegóły klauzuli',
          breadcrumb: breadcrumbRouteClientClauseDetails(clause.name, clause.id)
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clause]);

  if (fetching) return <Loader />;
  if (!fetching && !clause && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  if (!fetching && clause) {
    return (
      <div className="row user-details-view position-relative">
        <ClauseDisplayDetails clause={clause} />
      </div>
    );
  }

  return null;
};

export default ClauseDetails;
