import Table, { TableCol } from 'modules/Layout/component/Table/AccordionTable';
import React from 'react';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { ExecutorViolation, getViolationWysiwygTitle } from '../../../model/Violation';
import ActionUpdate from '../../../../Layout/component/Action/Update';
import EditIcon from '../../../../Layout/component/Icon/Edit';
import { getPathUrl } from '../../../../Shared/helper/api';
import ActionDelete from '../../../../Layout/component/Action/Delete';
import { ROUTE_EXECUTOR_ADMINS_VIOLATION_DETAILS, ROUTE_EXECUTOR_ADMINS_VIOLATION_UPDATE } from '../../../routes';
import { Link } from 'react-router-dom';

type Props = {
  violations: ExecutorViolation[];
  executor_id: string | number;
  onDeleteClick?: (row: ExecutorViolation) => void;
};

const ViolationsExecutorTable: React.FC<Props> = ({ violations, executor_id, onDeleteClick }) => {
  const redirectLink = (value: string, section_id: string | number) => (
    <Link
      className="fill-table-td"
      to={{
        pathname: getPathUrl(ROUTE_EXECUTOR_ADMINS_VIOLATION_DETAILS, { executor_id, section_id })
      }}
    >
      {value}
    </Link>
  );

  const cols: TableCol<ExecutorViolation>[] = [
    {
      property: 'slug',
      label: 'Rodzaj naruszenia',
      value: (row) => redirectLink(getViolationWysiwygTitle(row?.slug), row.id)
    },
    {
      property: 'authority_name',
      label: 'Nazwa organu',
      value: (row) => row?.authority_name
    },
    {
      property: 'violation_date',
      label: 'Data naruszenia',
      value: (row) => ifContainsDateFormat(row?.violation_date, formatDate)
    },
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            <ActionUpdate
              className="mx-1"
              title="Edycja postępowania"
              label={<EditIcon height="20px" />}
              to={{
                pathname: getPathUrl(ROUTE_EXECUTOR_ADMINS_VIOLATION_UPDATE, {
                  slug: row.slug,
                  executor_id,
                  section_id: row.id
                })
              }}
            />
            {onDeleteClick && (
              <ActionDelete className="mx-1" title="Usuń postępowanie" onClick={() => onDeleteClick(row)} />
            )}
          </div>
        );
      }
    }
  ];

  return <Table cols={cols} rows={violations} />;
};

export default ViolationsExecutorTable;
