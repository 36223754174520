import { FeedbackPublicValues, FeedbackValues } from 'modules/Feedback/view';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React, { ReactNode } from 'react';
import { CustomInput, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { suffixLabel } from 'modules/Layout/helper/misc';
import AddFiles from 'modules/Shared/helper/Files/AddFiles';
import './index.scss';
import Select from '../../../Layout/component/Input/Select';

export interface Props {
  values: FeedbackValues;
  categories: { id: number; name: string }[];
  setValues: React.Dispatch<React.SetStateAction<FeedbackValues>>;
  displayWysiwyg?: () => JSX.Element;
  publicValues?: FeedbackPublicValues;
  setPublicValues?: React.Dispatch<React.SetStateAction<FeedbackPublicValues>>;
  hiddenInput?: boolean;
  isPublic?: boolean;
  required?: boolean;
  errors?: ValidationErrors;
  filesChildren?: ReactNode;
}

const Form: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    values,
    setValues,
    categories,
    required,
    errors,
    isPublic,
    publicValues,
    displayWysiwyg,
    setPublicValues,
    filesChildren
  } = props;

  const findCategoryId = (value: string) => {
    // eslint-disable-next-line no-shadow
    const category = categories.find((category) => category.name === value);

    return category?.id || null;
  };

  const findCategoryName = (id: number) => {
    // eslint-disable-next-line no-shadow
    const category = categories.find((category) => category.id === id);

    return category?.name || '';
  };

  const privacyPolicyUrl = process.env.PRIVACY_POLICY_URL || '';

  return (
    <fieldset className="m-0 feedback-fieldset">
      {isPublic && (
        <FormGroup className="form-group-medium-margins">
          <Label for="email">{suffixLabel('Email kontaktowy', required)}</Label>
          <Input
            type="email"
            name="email"
            id="email"
            value={publicValues.email}
            onChange={(event) => setPublicValues({ ...publicValues, email: event.target.value })}
            invalid={hasError(errors, 'email')}
            required={required}
          />
          {hasError(errors, 'email') && <FormFeedback>{getError(errors, 'email')}</FormFeedback>}
        </FormGroup>
      )}
      <FormGroup className="form-group-medium-margins">
        <Label for="category">{suffixLabel('Temat zapytania', required)}</Label>
        <Select
          value={{
            value: findCategoryName(values.category_id),
            label: findCategoryName(values.category_id)
          }}
          options={categories.map((obj) => ({ label: obj.name, value: obj.name }))}
          onChange={(option) => setValues({ ...values, category_id: findCategoryId(option.value) || 1 })}
          name="category_id"
          invalid={hasError(errors, 'category_id')}
          id="category"
          required={required}
        />
        {hasError(errors, 'category_id') && <FormFeedback>{getError(errors, 'category_id')}</FormFeedback>}
      </FormGroup>
      <FormGroup className="form-group-medium-margins">
        <Label for="title">{suffixLabel('Tytuł', required)}</Label>
        <Input
          type="text"
          name="title"
          id="title"
          value={values.title}
          onChange={(event) => setValues({ ...values, title: event.target.value })}
          invalid={hasError(errors, 'title')}
          required={required}
        />
        {hasError(errors, 'title') && <FormFeedback>{getError(errors, 'title')}</FormFeedback>}
      </FormGroup>
      <FormGroup className="form-group-large-margins">
        {displayWysiwyg && displayWysiwyg()}
        {/* <Wysiwyg
          toolbar={orderSendMsgConfig(suffixLabel('Treść', required))}
          required={wysiwygRequired}
          onChange={(newContent) => setValues({ ...values, content: newContent })}
        /> */}
        {hasError(errors, 'content') && <FormFeedback className="d-block">{getError(errors, 'content')}</FormFeedback>}
      </FormGroup>
      {isPublic && (
        <FormGroup className="form-group-large-margins">
          <div className="d-flex justify-content-end">
            <CustomInput
              type="checkbox"
              id="input-agreement"
              label={
                <>
                  Oświadczam, że zapoznałem się z <a href={privacyPolicyUrl}>Polityką prywatności</a>.
                  <span className="text-primary font-14" style={{ verticalAlign: 'text-top' }}>
                    *
                  </span>
                </>
              }
              checked={publicValues.agreement ?? false}
              onChange={() => setPublicValues({ ...publicValues, agreement: !publicValues.agreement })}
              required
            />
          </div>
        </FormGroup>
      )}
      <FormGroup className="form-group-last files-form-group">
        <div className="files-form-group-wrapper">
          <AddFiles values={values as any} setValues={setValues} maxSizeInMb={2} />
        </div>
        {filesChildren}
      </FormGroup>
    </fieldset>
  );
};

export default Form;
