import axios, { CancelTokenSource } from 'axios';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { fetchJudgementKeywordsToastError } from 'modules/Judgement/toasts';
import { addToastAction } from 'modules/Layout/action';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { createSearchParams } from 'modules/Shared/helper/params';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import AutocompleteWithChips from 'modules/Layout/component/Autocomplete/WithChips';
import { fetchJudgmentKeywords } from 'modules/Judgement/repository';
import renameDuplicateEntries from 'modules/Shared/helper/renameDuplicateEntries';

type Props = {
  keywords: { name: string; id: number }[];
  onKeywordsChange: (keywordsValue: { name: string; id: number }) => void;
  alwaysClearInput?: boolean;
  required?: boolean;

  chipContentElements?: { content: string; id: number }[];
  onDeleteChipElement?: (id: number) => void;
};

// eslint-disable-next-line react/display-name
const JudgmentInputKeywordsAutocomplete: React.FC<Props> = React.memo(({ keywords, onKeywordsChange, ...props }) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [keywordsList, setKeywordsList] = useState<JudgementElementEntity[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const keywordsListWithoutDuplicates = React.useMemo(
    () => renameDuplicateEntries(keywordsList),
    [keywordsList.length]
  );

  const dispatchClauseKeywordsToastError = (messageValue: string) =>
    dispatch(addToastAction(fetchJudgementKeywordsToastError(messageValue)));

  const cancelToken: React.MutableRefObject<CancelTokenSource> = useRef(null);

  const fetchKeywordsList = async (name?: string) => {
    if (cancelToken.current) {
      cancelToken.current.cancel('Operation canceled due to new request.');
    }
    cancelToken.current = axios.CancelToken.source();

    const filter = name ? { name } : {};
    const searchParams = createSearchParams({ filter });

    setFetching(true);

    const { data, cancelled, message } = await fetchRequest(
      fetchJudgmentKeywords,
      searchParams,
      cancelToken.current.token
    );

    if (cancelled) return;
    if (data) {
      setKeywordsList(data.data);
      setFetching(false);
    }
    if (message && message.value !== 'Operation canceled due to new request.') {
      if (fetching) setFetching(false);
      dispatchClauseKeywordsToastError(message.value);
    }
  };

  useEffect(() => {
    return () => {
      if (cancelToken.current) {
        cancelToken.current.cancel('AxiosCancel');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeValue = (name: string) => {
    const findKeyword = keywordsListWithoutDuplicates.find((keyword) => keyword.name === name);
    if (findKeyword) onKeywordsChange({ id: findKeyword?.id, name: findKeyword?.name });
  };

  const clearList = () => {
    if (cancelToken.current) {
      cancelToken.current.cancel('Operation canceled due to new request.');
    }
  };

  const filterKeywordsList = keywordsListWithoutDuplicates.filter(
    (el) => !keywords.find((keywordEl) => keywordEl.id === el.id)
  );

  return (
    <>
      <AutocompleteWithChips
        currentValue=""
        onChangeCurrentValue={onChangeValue}
        searchRequest={fetchKeywordsList}
        fetching={fetching}
        clearList={clearList}
        list={filterKeywordsList as never}
        label="Słowa kluczowe"
        {...props}
      />
    </>
  );
});

export default JudgmentInputKeywordsAutocomplete;
