import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { PaginationMeta, SearchingProps } from 'modules/Shared/type';

export interface JudgementCategoryListState extends SearchingProps {
  list: JudgementElementEntity[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initJudgementCategoryListState = (): JudgementCategoryListState => ({
  list: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
