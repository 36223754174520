import _debounce from 'lodash/debounce';
import { FilterSearchSelectProps } from 'modules/Layout/type';
import { isValueDirty } from 'modules/Layout/helper/utils';
import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from '../../Input/Select';
import { SelectOption } from '../../../../Shared/type';

export type Props = FilterSearchSelectProps & { disabled?: boolean };

export interface State {
  value: string | number;
}

export class FilterInputSearchSelect extends React.Component<Props, State> {
  protected debounceOnChange: (value: string) => void;

  constructor(props: Props) {
    super(props);

    this.state = { value: String(props?.value ?? '') };

    this.onChange = this.onChange.bind(this);
    this.debounceOnChange = _debounce(props.onChange, 500);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (isValueDirty(this.props, prevProps, ['value'])) {
      if (isValueDirty(this.props, this.state, ['value'])) {
        const { value } = this.props;

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ value: String(value ?? '') });
      }
    }
  }

  onChange(newValue: SelectOption): void {
    this.setState({ value: newValue.value }, () => this.debounceOnChange(String(newValue.value)));
  }

  render(): React.ReactNode {
    const { property, label, className, disabled, acceptValueAsString, options } = this.props;
    // eslint-disable-next-line prefer-const
    let { value } = this.state;

    if (!acceptValueAsString && typeof value === 'string') {
      value = parseInt(value, 10) || '';
    }

    return (
      <FormGroup className={className}>
        <Label for={property}>{label}</Label>
        <Select
          id={property}
          key={value}
          searchable
          value={options.find((obj) => obj.value === value)}
          options={options}
          onChange={this.onChange}
          isDisabled={disabled}
        />
      </FormGroup>
    );
  }
}

export default FilterInputSearchSelect;
