import { ClauseListAction, SET_CLAUSE_LIST_PARAMS, CLAUSE_LIST } from 'modules/Clause/action/ClauseList';
import { createClause } from 'modules/Clause/model/Clause';
import { initClauseListState, ClauseListState } from 'modules/Clause/state/clauseList';

const initState = initClauseListState();

const reducer = (state: ClauseListState = initState, action: ClauseListAction): ClauseListState => {
  switch (action.type) {
    case SET_CLAUSE_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case CLAUSE_LIST: {
      const { list = [], meta } = action.payload;

      return { ...state, fetching: false, list: list.map(createClause), meta };
    }

    default:
      return state;
  }
};

export default reducer;
