import { formatDateValue } from 'modules/Shared/helper/utils';
import React from 'react';
import './style.scss';
import Datepicker from 'modules/Layout/component/Datepicker';
import moment from 'moment';

export interface DatetimeProps {
  name?: string;
  id?: string;
  value?: string;
  min?: string;
  max?: string;
  required?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  onChange: (value: string) => void;
  hardcodedSeconds?: number;
}

const Date: React.FC<DatetimeProps> = ({
  name,
  id,
  value,
  min,
  max,
  required,
  disabled,
  invalid,
  onChange
}: DatetimeProps): JSX.Element => {
  const date = value ? formatDateValue(value) : '';

  const rules = {
    min: {
      date: ''
    },
    max: {
      date: ''
    }
  };

  if (date) {
    if (min) {
      rules.min.date = formatDateValue(min);
    }

    if (max) {
      rules.max.date = formatDateValue(max);
    }
  }

  const onChangeVerify = (newDate: string) => {
    if (moment(newDate).isValid()) onChange(newDate);
    else onChange('');
  };

  return (
    <div className="datetime-wrapper">
      <Datepicker
        id={`${id ?? name}.date`}
        name={`${name}.date`}
        onChange={onChangeVerify}
        invalid={invalid}
        min={rules.min.date}
        max={rules.max.date}
        disabled={disabled}
        required={required}
        value={date}
      />
    </div>
  );
};

export default Date;
