import React, { useEffect, useRef, useState } from 'react';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_EXECUTOR_DUTIES } from 'modules/Agreements/step';
import { FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import useStepFields from 'modules/Agreements/hook/useStepFields';

export interface AgreementStepExecutorDutiesValues {
  del_all_costs_checked: boolean;
  del_all_costs_content: string | null;
  del_own_transport_checked: boolean;
  del_own_transport_content: string | null;
  del_risk_checked: boolean;
  del_risk_content: string | null;
  del_documents_checked: boolean;
  del_documents_content: string | null;
  del_documents_on_demand_checked: boolean;
  del_documents_on_demand_content: string | null;
  del_quality_checked: boolean;
  del_quality_content: string | null;
  schedule_checked: boolean;
  schedule_content: string | null;
  shortcomings_checked: boolean;
  shortcomings_content: string | null;
  no_realization_checked: boolean;
  no_realization_content: string | null;
  checkboxes: AgreementCheckboxes | null;
}

const DEL_ALL_COSTS_CONTENT =
  'Wykonawca ponosi wszelkie koszty niezbędne do wykonania Przedmiotu Umowy, zgodnie z obowiązującymi przepisami';
const DEL_OWN_TRANSPORT_CONTENT =
  'Wykonawca dostarczy Przedmiot Umowy własnym transportem, na własny koszt i na własne ryzyko';
const DEL_RISK_CONTENT =
  'Do czasu odbioru Przedmiotu Umowy przez zamawiającego, ryzyko wszelkich niebezpieczeństw związanych z ewentualnym uszkodzeniem, utratą lub szkodami powstałymi podczas transportu ponosi wykonawca';
const DEL_DOCUMENTS_CONTENT =
  'Każdorazowo wraz z wydaniem Przedmiotu Umowy, wykonawca przekaże zamawiającemu wymagane normy, świadectwa jakości, certyfikaty, atesty, deklaracje, aprobaty, pozostałe dokumenty jakościowe wymagane Umową lub/i wymagane prawem';
const DEL_DOCUMENTS_ON_DEMAND_CONTENT =
  'Wykonawca zobowiązuje się do dostarczenia zamawiającemu na jego żądanie wszelkich dokumentów, materiałów oraz informacji potrzebnych do oceny przez zamawiającego prawidłowości wykonywania Umowy oraz zgodności Przedmiotu Umowy z wymaganiami zamawiającego';
const DEL_QUALITY_CONTENT =
  'Wykonawca ponosi pełną odpowiedzialność za spełnienie warunków jakościowych dostarczonego Przedmiotu Umowy i jego zgodności z wymaganiami zamawiającego';
const SCHEDULE_CONTENT =
  '<p>Do obowiązków Wykonawcy należy wykonanie i przedłożenie do akceptacji zamawiającego przed przystąpieniem do prac budowlanych w terminie do ... od daty podpisania Umowy szczegółowego Harmonogramu rzeczowo - terminowo – finansowego, obejmującego harmonogram robót oraz terminy uzyskania pozwoleń, opracowany przez wykonawcę i zatwierdzony przez zamawiającego.  W szczególności, przedmiotowy Harmonogram musi uwzględniać zasady płatności części wynagrodzenia. Przedmiotowy Harmonogram stanowi jako załącznik integralną część Umowy.</p>';
const SHORTCOMINGS_CONTENT =
  'W przypadku uchybienia przez wykonawcę któremukolwiek obowiązkowi określonemu w tym paragrafie, zamawiający ma prawo zawrzeć niezbędne umowy na koszt i ryzyko wykonawcy, co nie zwalnia wykonawcy z obowiązku zapłaty kar umownych.';
const NO_REALIZATION_CONTENT =
  '<p>W przypadku braku realizacji lub nienależytej realizacji obowiązków wykonawcy ujętych w Umowie, po uprzednim wezwaniu wykonawcy do wykonania lub należytego wykonania Umowy oraz wyznaczenia dodatkowego co najmniej ........ terminu, zamawiający uprawniony jest do zlecenia wykonania tych prac, lub obowiązków wykonawcy ujętych w Umowie osobie trzeciej na koszt i ryzyko wykonawcy.</p>';

const initContentValues = {
  del_all_costs_content: DEL_ALL_COSTS_CONTENT,
  del_own_transport_content: DEL_OWN_TRANSPORT_CONTENT,
  del_risk_content: DEL_RISK_CONTENT,
  del_documents_content: DEL_DOCUMENTS_CONTENT,
  del_documents_on_demand_content: DEL_DOCUMENTS_ON_DEMAND_CONTENT,
  del_quality_content: DEL_QUALITY_CONTENT,
  schedule_content: SCHEDULE_CONTENT,
  shortcomings_content: SHORTCOMINGS_CONTENT,
  no_realization_content: NO_REALIZATION_CONTENT
};

const mapState = (step: AgreementStepExecutorDutiesValues): AgreementStepExecutorDutiesValues => {
  const {
    del_all_costs_content,
    del_own_transport_content,
    del_risk_content,
    del_documents_content,
    del_documents_on_demand_content,
    del_quality_content,
    schedule_content,
    shortcomings_content,
    no_realization_content,
    checkboxes,
    ...rest
  } = step;

  return {
    ...rest,
    del_all_costs_content: del_all_costs_content || DEL_ALL_COSTS_CONTENT,
    del_own_transport_content: del_own_transport_content || DEL_OWN_TRANSPORT_CONTENT,
    del_risk_content: del_risk_content || DEL_RISK_CONTENT,
    del_documents_content: del_documents_content || DEL_DOCUMENTS_CONTENT,
    del_documents_on_demand_content: del_documents_on_demand_content || DEL_DOCUMENTS_ON_DEMAND_CONTENT,
    del_quality_content: del_quality_content || DEL_QUALITY_CONTENT,
    schedule_content: schedule_content || SCHEDULE_CONTENT,
    shortcomings_content: shortcomings_content || SHORTCOMINGS_CONTENT,
    no_realization_content: no_realization_content || NO_REALIZATION_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepExecutorDuties = (props: AgreementStepProps): JSX.Element => {
  const { steps, onSubmit, onChange, errors } = props;

  const initState = useRef<AgreementStepExecutorDutiesValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_EXECUTOR_DUTIES))
  );

  const [stepValues, setStepValues] = useState<AgreementStepExecutorDutiesValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_EXECUTOR_DUTIES));
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderCheckbox, renderEditableCheckbox, renderSwitch, renderContent, renderCustomCheckboxes } = useStepFields(
    {
      stepValues,
      setStepValues,
      mapState,
      errors,
      initContentValues
    }
  );

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        {renderCheckbox('del_all_costs_checked', stepValues.del_all_costs_content)}
        {renderCheckbox('del_own_transport_checked', stepValues.del_own_transport_content)}
        {renderCheckbox('del_risk_checked', stepValues.del_risk_content)}
        {renderCheckbox('del_documents_checked', stepValues.del_documents_content)}
        {renderCheckbox('del_documents_on_demand_checked', stepValues.del_documents_on_demand_content)}
        {renderCheckbox('del_quality_checked', stepValues.del_quality_content)}
        {renderSwitch(
          'schedule_checked',
          'Czy zamawiający wymaga aby wykonawca opracował i przedłożył harmonogram dostaw?'
        )}
        {stepValues.schedule_checked && renderContent('schedule_content')}
        {renderCheckbox('shortcomings_checked', stepValues.shortcomings_content)}
        {renderEditableCheckbox('no_realization')}
        {renderCustomCheckboxes()}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepExecutorDuties;
