import React from 'react';
import { ROUTE_PROFILE } from 'modules/Profile/routes';
import { Menu } from 'app/menu';
import ProfileIcon from '../Layout/component/Icon/Profile';

const MenuProfileShared: Menu[] = [
  {
    key: 'profile',
    type: 'link',
    title: 'Profil użytkownika',
    icon: <ProfileIcon />,
    href: ROUTE_PROFILE
  }
];

export default MenuProfileShared;
