import { Button, FormGroup, Form as FormStrap } from 'reactstrap';
import React, { useState } from 'react';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { updateRegulations } from 'modules/Regulation/repository';
import { ColorVariants, Message } from 'modules/Shared/type';
import { initRodoValues } from 'modules/Regulation/component/Fieldset/Rodo/rodoContent';
import FieldsetRodo from 'modules/Regulation/component/Fieldset/Rodo';

type Props = {
  setMessage: React.Dispatch<React.SetStateAction<Message>>;
  toggleModal: () => void;
};

const Form: React.FC<Props> = ({ toggleModal, setMessage }) => {
  const [rodoVal, setRodoVal] = useState(initRodoValues());

  const cancelToken = useCancelToken();
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    updateRegulations(rodoVal, cancelToken)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        if (error?.message === 'AxiosCancel') {
          return;
        }
        setMessage({
          value: error?.error?.response?.data?.message || error?.message || 'Something went wrong.',
          variant: ColorVariants.Danger
        });
      });
  };

  return (
    <FormStrap onSubmit={onSubmit}>
      <FieldsetRodo values={rodoVal} setChange={setRodoVal} />
      <FormGroup className="form-actions form-group-last">
        <Button id="save" type="submit" className="mt-0" color="primary">
          Zapisz
        </Button>
        <Button id="cancel" type="button" color="link" className="cancel" onClick={toggleModal}>
          Anuluj
        </Button>
      </FormGroup>
    </FormStrap>
  );
};

export default Form;
