import React from 'react';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_REPRESENTATIVES } from 'modules/Agreements/step';
import classNames from 'classnames';
import {
  AgreementStepRepresentativesValues,
  Representative
} from 'modules/Agreements/components/Steps/Default/Representatives';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';

const AgreementStepPreviewRepresentatives = (
  props: AgreementStepPreviewProps<AgreementStepRepresentativesValues>
): JSX.Element => {
  const { steps, modifiedStep } = props;
  const representatives = getStepValues<AgreementStepRepresentativesValues>(steps, AGREEMENT_STEP_REPRESENTATIVES);

  const { value: representativesPossibility, modified: representativesPossibilityModified } = getFieldForPreview(
    representatives,
    modifiedStep,
    'representatives_possibility'
  );

  const { value: contractorChecked, modified: contractorCheckedModified } = getFieldForPreview(
    representatives,
    modifiedStep,
    'contractor_checked'
  );

  const { value: executorChecked, modified: executorCheckedModified } = getFieldForPreview(
    representatives,
    modifiedStep,
    'executor_checked'
  );

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    representatives,
    modifiedStep,
    `checkboxes.general`
  );

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={representatives}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const renderRepresentative = (representative: Representative, index: number) => {
    if (!representative.checked) return null;

    const { modified: itemModified } = getFieldForPreview(
      representatives,
      modifiedStep,
      `${representative.type}_representatives[${index}]`
    );

    return (
      <div
        key={`${representative.type}_${index}_preview`}
        className={classNames('preview-nested-alpha-point', {
          modified: itemModified
        })}
      >
        Imię i nazwisko oraz pełniona u zamawiającego funkcja/stanowisko - {representative.name}
        <p className="mb-1">Adres do korespondencji - {representative.address}</p>
        <p className="mb-1">Numer telefonu - {representative.phone}</p>
        <p className="mb-1">Adres e-mail - {representative.email}</p>
      </div>
    );
  };

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_REPRESENTATIVES)}</div>
      {[
        representativesPossibility && [
          <p
            className={classNames('preview-point', {
              modified: representativesPossibilityModified
            })}
          >
            Strony ustalają niżej wymienionych przedstawicieli oraz postanawiają, że wszelka korespondencja będzie
            kierowana na adresy do doręczeń wskazane niżej:
          </p>,
          contractorChecked && [
            <p
              className={classNames('preview-point', {
                modified: contractorCheckedModified
              })}
            >
              Zamawiającego:
            </p>,
            modifiedStep?.contractor_representatives.map(renderRepresentative)
          ],
          executorChecked && [
            <p
              className={classNames('preview-point', {
                modified: executorCheckedModified
              })}
            >
              Wykonawcy:
            </p>,
            modifiedStep?.executor_representatives.map(renderRepresentative)
          ]
        ],
        renderCheckbox('change'),
        renderCheckbox('no_notification')
      ]}
      {generalCheckboxes &&
        generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
          if (!checkbox.checked) return null;

          return (
            <p
              className={classNames('preview-point wysiwyg-preview', {
                modified: generalCheckboxesModified
              })}
              key={index}
              dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
            />
          );
        })}
    </div>
  );
};

export default AgreementStepPreviewRepresentatives;
