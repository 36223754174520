import { JudgementEntity } from 'modules/Judgement/model/Judgement';
import { PaginationMeta, SearchingProps } from 'modules/Shared/type';

export interface JudgementListState extends SearchingProps {
  list: JudgementEntity[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initJudgementListState = (): JudgementListState => ({
  list: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
