import React from 'react';

const Disclaimer = () => {
  const disclaimerArr = [
    `Informacje  zawarte  w  Raporcie  pochodzą  z  urzędowych  rejestrów,  publikatorów  i  ogłoszeń  podmiotów,  
    a  także  innych  ogólnodostępnych  źródeł  informacji.  Centrum  Zamówień  Publicznych  Paweł  Sendrowski  dostarcza  
    informacje  uzyskane  zgodnie z prawem, ale nie jest ich wytwórcą i nie odpowiada za ich prawdziwość, rzetelność, kompletność oraz aktualność.`,
    `Centrum Zamówień Publicznych Paweł Sendrowski zastrzega, że dane podane w Raporcie mają tylko i wyłącznie charakter informacyjny służący organizacji 
    postępowań w sposób przejrzysty, jawny, rzetelny oraz mający wesprzeć proces wyboru wykonawcy danego zamówienia, gwarantującego wykonanie zamówienia w 
    sposób pełny i zgodny z jego treścią i nie mają charakteru oceniającego czy kategoryzującego poszczególnych wykonawców, których dane są zamieszczane w Raporcie.`,
    `Centrum Zamówień Publicznych Paweł Sendrowski zastrzega, że zamieszczenie w Serwisie danych pozyskanych przez niego, nie sugeruje ani nie przesądza obowiązku 
    zastosowania w stosunku do podmiotów objętych uzyskanymi informacjami, zastosowania jakichkolwiek środków prawnych. Zastosowanie środków prawnych przez Użytkownika, 
    jest wyłączną decyzją Użytkownika.`,
    `Centrum Zamówień Publicznych Paweł Sendrowski nie odpowiada za decyzje podjęte na podstawie informacji zawartych w Raporcie.`,
    `Brak danych w Raporcie, odnoszących się do danego przedsiębiorcy nie przesądza o braku wystąpienia okoliczności mogących być przedmiotem danych umieszczanych w 
    Raporcie i nie przesądza o braku podstaw do podjęcia w stosunku do niego środków prawnych przez Użytkownika.`
  ];

  return (
    <ol>
      {disclaimerArr.map((disc, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className="mb-1" key={index}>
          {disc}
        </li>
      ))}
    </ol>
  );
};

export default Disclaimer;
