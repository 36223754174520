import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_CONTRACT_CHANGES } from 'modules/Agreements/step';
import { AgreementStepContractChangesValues } from 'modules/Agreements/components/Steps/Default/ContractChanges';

const AgreementStepPreviewContractChanges = (
  props: AgreementStepPreviewProps<AgreementStepContractChangesValues>
): JSX.Element => {
  const { steps, modifiedStep } = props;
  const contractChanges = getStepValues<AgreementStepContractChangesValues>(steps, AGREEMENT_STEP_CONTRACT_CHANGES);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={contractChanges}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const { value: proceedingContentCheckboxes, modified: proceedingContentCheckboxesModified } = getFieldForPreview(
    contractChanges,
    modifiedStep,
    `checkboxes.proceeding_content`
  );

  const { value: changeConditionsCheckboxes, modified: changeConditionsCheckboxesModified } = getFieldForPreview(
    contractChanges,
    modifiedStep,
    `checkboxes.change_conditions`
  );

  const { value: forceMajeureCheckboxes, modified: forceMajeureCheckboxesModified } = getFieldForPreview(
    contractChanges,
    modifiedStep,
    `checkboxes.force_majeure`
  );

  const { value: forceMajeureAdditionalCheckboxes, modified: forceMajeureAdditionalCheckboxesModified } =
    getFieldForPreview(contractChanges, modifiedStep, `checkboxes.force_majeure_additional`);

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    contractChanges,
    modifiedStep,
    `checkboxes.general`
  );

  const { value: contractChangesChecked, modified: contractChangesCheckedModified } = getFieldForPreview(
    contractChanges,
    modifiedStep,
    `contract_changes_checked`
  );

  const renderForceMajeureStatus = (): boolean => {
    const { value: forceMajeureChecked } = getFieldForPreview(contractChanges, modifiedStep, `force_majeure_checked`);

    const { value: forceMajeureNoResponsibilityChecked } = getFieldForPreview(
      contractChanges,
      modifiedStep,
      `force_majeure_no_responsibility_checked`
    );
    const { value: forceMajeureImmediateInformationChecked } = getFieldForPreview(
      contractChanges,
      modifiedStep,
      `force_majeure_immediate_information_checked`
    );
    const { value: forceMajeureDeadlineChangesChecked } = getFieldForPreview(
      contractChanges,
      modifiedStep,
      `force_majeure_deadline_changes_checked`
    );
    const { value: forceMajeureNoPenaltiesChecked } = getFieldForPreview(
      contractChanges,
      modifiedStep,
      `force_majeure_no_penalties_checked`
    );

    return (
      forceMajeureChecked &&
      (forceMajeureNoResponsibilityChecked ||
        forceMajeureImmediateInformationChecked ||
        forceMajeureDeadlineChangesChecked ||
        forceMajeureNoPenaltiesChecked ||
        !_.isEmpty(forceMajeureAdditionalCheckboxes) ||
        !_.isEmpty(forceMajeureCheckboxes))
    );
  };

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_CONTRACT_CHANGES)}</div>
      <div className="agreement-step-preview-content reset-preview-point">
        {proceedingContentCheckboxes &&
          proceedingContentCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-nested-alpha-point wysiwyg-preview', {
                  modified: proceedingContentCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
        {contractChangesChecked && (
          <>
            <p
              className={classNames('preview-point', {
                modified: contractChangesCheckedModified
              })}
            >
              Zamawiający zastrzega możliwość wprowadzenia istotnych zmian postanowień zawartej umowy. W szczególności
              postanowienia umowy mogą ulec zmianie w następującym zakresie oraz na następujących warunkach:
            </p>
            {renderCheckbox('executor_reward_range', 'preview-nested-alpha-point')}
            {renderCheckbox('executor_reward_benefit', 'preview-nested-alpha-point')}
            {renderCheckbox('executor_reward_additional_work', 'preview-nested-alpha-point')}
            {renderCheckbox('executor_reward_description_change', 'preview-nested-alpha-point')}
            {renderCheckbox('executor_reward_payment_terms', 'preview-nested-alpha-point')}
            {renderCheckbox('deadlines_force_majeure', 'preview-nested-alpha-point')}
            {renderCheckbox('deadlines_documentation_changes', 'preview-nested-alpha-point')}
            {renderCheckbox('deadlines_necessary_decisions', 'preview-nested-alpha-point')}
            {renderCheckbox('deadlines_work_site', 'preview-nested-alpha-point')}
            {renderCheckbox('deadlines_atmospheric_conditions', 'preview-nested-alpha-point')}
            {renderCheckbox('deadlines_terrain_conditions', 'preview-nested-alpha-point')}
            {renderCheckbox('deadlines_additional_work', 'preview-nested-alpha-point')}
            {renderCheckbox('technology_manufacturing_changes', 'preview-nested-alpha-point')}
            {renderCheckbox('technology_law_changes', 'preview-nested-alpha-point')}
            {renderCheckbox('technology_no_materials_changes', 'preview-nested-alpha-point')}
            {renderCheckbox('technology_better_materials_changes', 'preview-nested-alpha-point')}
            {renderCheckbox('executor_give_reason', 'preview-nested-alpha-point')}
            {renderCheckbox('executor_not_able_to_realize', 'preview-nested-alpha-point')}
            {renderCheckbox('employee_change', 'preview-nested-alpha-point')}
            {changeConditionsCheckboxes &&
              changeConditionsCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
                if (!checkbox.checked) return null;

                return (
                  <p
                    className={classNames('preview-nested-alpha-point wysiwyg-preview', {
                      modified: changeConditionsCheckboxesModified
                    })}
                    key={index}
                    dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                  />
                );
              })}
          </>
        )}
        {renderForceMajeureStatus() && (
          <>
            <p className={classNames('preview-point')}>
              Zamawiający przewiduje następujące postanowienia dotyczące działania „Siły wyższej:”
            </p>
            {!_.isEmpty(forceMajeureAdditionalCheckboxes) && [
              <p className={classNames('preview-nested-alpha-point')}>
                Zamawiający zastrzega możliwość wprowadzenia istotnych zmian postanowień zawartej umowy. W szczególności
                postanowienia umowy mogą ulec zmianie w następującym zakresie oraz na następujących warunkach:
              </p>,
              forceMajeureAdditionalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
                if (!checkbox.checked) return null;

                const content = '- ' + checkbox.content;

                return (
                  <p
                    className={classNames('wysiwyg-preview ml-3', {
                      modified: forceMajeureAdditionalCheckboxesModified
                    })}
                    key={index}
                    dangerouslySetInnerHTML={{ __html: String(content) }}
                  />
                );
              })
            ]}
            {renderCheckbox('force_majeure_no_responsibility', 'preview-nested-alpha-point')}
            {renderCheckbox('force_majeure_immediate_information', 'preview-nested-alpha-point')}
            {renderCheckbox('force_majeure_deadline_changes', 'preview-nested-alpha-point')}
            {renderCheckbox('force_majeure_no_penalties', 'preview-nested-alpha-point')}
            {forceMajeureCheckboxes &&
              forceMajeureCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
                if (!checkbox.checked) return null;

                return (
                  <p
                    className={classNames('preview-nested-alpha-point wysiwyg-preview', {
                      modified: forceMajeureCheckboxesModified
                    })}
                    key={index}
                    dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                  />
                );
              })}
          </>
        )}

        {generalCheckboxes &&
          generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-point wysiwyg-preview', {
                  modified: generalCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AgreementStepPreviewContractChanges;
