import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import { PartialSearchingProps } from 'modules/Shared/type';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClauseListParamsAction } from 'modules/ClauseClient/action/ClauseList';
import ClauseCategoriesAutocomplete from 'modules/Clause/container/ClauseAutocomplete/Categories';
import _ from 'lodash';

type Props = {
  initCategories?: { name: string; id: number }[];
};

const ClauseFilterCategoriesAutocomplete: React.FC<Props> = ({ initCategories }) => {
  const dispatch = useDispatch();
  const { filter } = useSelector((state: RootState) => state.clause.clauseList);
  const [categories, setCategories] = useState<{ name: string; id: number }[]>([]);

  useEffect(() => {
    if (!_.isEqual(initCategories, categories)) {
      setCategories(initCategories);
    }

    // eslint-disable-next-line
  }, [initCategories]);

  const setParams = (payload: PartialSearchingProps) => dispatch(setClauseListParamsAction(payload));

  const saveCategory = (newCategory: { name: string; id: number }) => {
    if (!categories.find((value) => value.id === newCategory.id)) {
      setCategories([...categories, newCategory]);
      filter['categories[]'] = [...categories.map((value) => value.id), newCategory.id] as any;
      setParams({ ...filter });
    }
  };

  const deleteCategory = (id: number) => {
    const newCategories = categories.filter((el) => el.id !== id);
    setCategories(newCategories);
    // eslint-disable-next-line
    if (filter.hasOwnProperty('categories[]')) {
      const { 'categories[]': _ignore, ...rest } = filter;
      if (newCategories.length > 0) {
        rest['categories[]'] = newCategories.map((value) => value.id) as any;
      }
      setParams({ filter: rest });
    }
  };

  return (
    <div className="col-12 col-lg-6 col-xl-4">
      <ClauseCategoriesAutocomplete
        selectedCategories={categories}
        onSelectedCategoriesChange={saveCategory}
        alwaysClearInput
        chipContentElements={categories.map(({ name, id }) => ({ content: name, id }))}
        onDeleteChipElement={deleteCategory}
      />
    </div>
  );
};

export default ClauseFilterCategoriesAutocomplete;
