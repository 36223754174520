import CardBox from 'modules/Layout/component/CardBox';
import { ROUTE_SUBSCRIPTIONS } from 'modules/Subscriptions/routes';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'modules/Layout/component/Loader';
import { fetchModuleDeniedClause, ModuleDeniedClauseResponse } from 'modules/Layout/repository';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { ColorVariants, Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import { PageProps } from 'modules/Layout/type';
import { useDispatch } from 'react-redux';
import { managePageAction } from 'modules/Layout/action';
import '../ExecutorsAccessDenied/index.scss';
import { breadcrumbRouteJudgementClientList } from 'modules/JudgementClient/breadcrumbs';

const JudgementsAccessDenied = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState<ModuleDeniedClauseResponse>(null);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  const cancelToken = useCancelToken();
  useEffect(() => {
    managePage({
      title: 'Orzeczenia sądowe',
      breadcrumb: breadcrumbRouteJudgementClientList()
    });

    (async () => {
      setFetching(true);
      try {
        const response = await fetchModuleDeniedClause(cancelToken);

        if (response?.data) {
          setData(response.data);
        }
        setFetching(false);
      } catch (error) {
        if (error?.message === 'AxiosCancel') {
          return;
        }

        setMessage({
          value: error?.error?.response?.data?.message || error?.message || 'Something went wrong.',
          variant: ColorVariants.Danger
        });
        setFetching(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayCorrectForm = () => {
    const judgmentCount = data?.data?.judgments_count ?? 0;

    const judgmentCorrectForm = () => {
      if (judgmentCount === 1) return 'orzeczenie sądowe';
      if (judgmentCount > 1 && judgmentCount < 5) return 'orzeczenia sądowe';
      return 'orzeczeń sądowych';
    };
    return (
      <>
        <span className="text-primary counter">{judgmentCount}</span> {judgmentCorrectForm()}
      </>
    );
  };

  return (
    <div className="d-flex justify-content-center executors-access-denied">
      <CardBox
        heading="Brak dostępu"
        paddingLeft="30px"
        paddingRight="30px"
        paddingTop="25px"
        paddingBottom="40px"
        childrenPaddingTop="144px"
      >
        {fetching && <Loader />}
        <Alert message={message} />
        <div className="medium-margins">
          <div className="denied-title font-poppins">
            Aby zobaczyć orzeczenia sądowe
            <br />
            musisz mieć wykupiony abonament.
          </div>
        </div>
        <div className="big-margins">
          <div className="d-flex">
            <Link to={ROUTE_SUBSCRIPTIONS} className="btn btn-primary buy-subscription w-100">
              Zakup subskrypcji
            </Link>
          </div>
        </div>
        <div className="medium-margins">
          <div className="denied-description">Obecnie posiadamy {displayCorrectForm()}.</div>
        </div>
      </CardBox>
    </div>
  );
};

export default JudgementsAccessDenied;
