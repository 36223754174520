import React from 'react';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_CONTRACTOR_DUTIES } from 'modules/Agreements/step';
import { AgreementStepContractorDutiesValues } from 'modules/Agreements/components/Steps/Default/ContractorDuties';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';

const AgreementStepPreviewContractorDuties = (
  props: AgreementStepPreviewProps<AgreementStepContractorDutiesValues>
): JSX.Element => {
  const { steps, modifiedStep } = props;
  const contractorDuties = getStepValues<AgreementStepContractorDutiesValues>(steps, AGREEMENT_STEP_CONTRACTOR_DUTIES);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={contractorDuties}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const { value: dutiesCheckboxes, modified: dutiesCheckboxesModified } = getFieldForPreview(
    contractorDuties,
    modifiedStep,
    `checkboxes.duties`
  );

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_CONTRACTOR_DUTIES)}</div>
      <div className="agreement-step-preview-content reset-preview-point">
        <p className="h5">Do obowiązków zamawiającego należy:</p>
        {renderCheckbox('acceptance', 'preview-nested-alpha-point')}
        {renderCheckbox('payment', 'preview-nested-alpha-point')}
        {renderCheckbox('share_data', 'preview-nested-alpha-point')}
        {renderCheckbox('grant_access', 'preview-nested-alpha-point')}
        {renderCheckbox('ser_cooperation', 'preview-nested-alpha-point')}
        {dutiesCheckboxes &&
          dutiesCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-nested-alpha-point wysiwyg-preview', {
                  modified: dutiesCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AgreementStepPreviewContractorDuties;
