import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { fetchAlerts } from 'modules/ForeignProceedings/repository';
import CardBox from 'modules/Layout/component/CardBox';
import { breadcrumbRouteForeignProceedingsAlertDetails } from '../../../breadcrumbs';
import AlertsDetails from '../../../component/Details/Alerts';
import { AlertFormData } from '../../../component/Form/AlertForm';

const AlertDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [alertData, setAlertData] = useState<AlertFormData>(null);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const {
        data: { data },
        cancelled,
        message: messageResponse
      } = await fetchRequest(fetchAlerts, cancelToken);

      if (cancelled) return;
      if (data) {
        const alertDetails = data.find((alertItem) => alertItem.id === Number(id));
        setAlertData(alertDetails);
      }
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (alertData) {
      const title = `Alert postępowania${alertData.name ? ` - ${alertData.name}` : ''}`;
      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteForeignProceedingsAlertDetails(alertData.name, alertData.id)
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertData]);

  if (fetching) return <Loader />;
  if (!fetching && !alertData && message)
    return (
      <div className="executor-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <CardBox>{message && <Alert message={message} />}</CardBox>
        </div>
      </div>
    );
  if (!fetching && alertData) {
    return (
      <div className="row executor-details-view position-relative justify-content-center">
        <div className="col-12 col-lg-7 user-details">
          <AlertsDetails alertDetails={alertData} />
        </div>
      </div>
    );
  }

  return null;
};

export default AlertDetails;
