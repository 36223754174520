import { Message } from 'modules/Shared/type';
import Subscription from 'modules/Subscriptions/model/Subscription';

export interface SubscriptionsListState {
  subscriptions: Subscription[];
  fetching: boolean;
  errorMessage: Message;
}

export const initSubscriptionState = (): SubscriptionsListState => ({
  subscriptions: [],
  fetching: false,
  errorMessage: null
});
