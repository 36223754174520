import { Credentials } from 'modules/Auth/type';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React, { useState } from 'react';
import { Button, Form as FormStrap, FormFeedback, FormGroup } from 'reactstrap';
import { ROUTE_PASSWORD_RECOVER } from 'modules/Auth/routes';
import LoginLoginIcon from '../../../../Layout/component/Icon/LoginLogin';
import WithIconInput from '../../../../Layout/component/Input/WithIcon/input';
import LoginPassIcon from '../../../../Layout/component/Icon/LoginPass';

export type Props = {
  errors: ValidationErrors;
  submit: (credentials: Credentials) => void;
  busy: boolean;
};

const Form: React.FC<Props> = ({ errors, submit, busy }) => {
  const [credentials, setCredentials] = useState({
    username: '',
    password: ''
  });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, key: keyof Credentials) => {
    const { value } = event.currentTarget;
    setCredentials({ ...credentials, [key]: value });
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submit(credentials);
  };

  return (
    <FormStrap onSubmit={onSubmit}>
      <FormGroup className="form-group-large-margins">
        <WithIconInput
          type="text"
          name="username"
          id="username-input"
          placeholder="Login"
          value={credentials.username}
          disabled={busy}
          onChange={(event) => onChange(event, 'username')}
          invalid={hasError(errors, 'username')}
          required
          icon={<LoginLoginIcon />}
        />
        {hasError(errors, 'username') && (
          <FormFeedback className="d-block">{getError(errors, 'username')}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup className="form-group-last">
        <WithIconInput
          type="password"
          name="password"
          id="password-input"
          placeholder="Hasło"
          value={credentials.password}
          disabled={busy}
          onChange={(event) => onChange(event, 'password')}
          invalid={hasError(errors, 'password')}
          required
          iconHeight="30px"
          icon={<LoginPassIcon />}
        />
        {hasError(errors, 'password') && (
          <FormFeedback className="d-block">{getError(errors, 'password')}</FormFeedback>
        )}
      </FormGroup>
      <div className="text-right forgot-password-wrapper">
        <a href={ROUTE_PASSWORD_RECOVER} className="forgot-password pzpeu-link">
          Zapomniałem hasła
        </a>
      </div>
      <Button className="login-in-button w-100 waves-effect waves-light" type="submit" color="primary" disabled={busy}>
        Zaloguj się
      </Button>
    </FormStrap>
  );
};

export default Form;
