import Table, { TableCol } from 'modules/Layout/component/Table/AccordionTable';
import React from 'react';
import { PaymentLogsValueLog } from 'modules/Profile/type';
import { logStatusValue } from 'modules/Shared/components/PaymentLog/helper';
import { formatDate, formatTimeValue } from 'modules/Shared/helper/utils';

type Props = { logs: PaymentLogsValueLog[] };

const PaymentLogsTable: React.FC<Props> = ({ logs }) => {
  if (!logs) return null;

  const cols: TableCol<PaymentLogsValueLog>[] = [
    {
      property: 'created_at',
      label: 'Data',
      // eslint-disable-next-line react/display-name
      value: (row) => (
        <>
          <span>{formatDate(row.created_at)}</span>{' '}
          <span className="payment-time">{formatTimeValue(row.created_at)}</span>
        </>
      )
    },
    {
      property: 'status',
      label: 'Status',
      value: (row) => logStatusValue(row.status)
    }
    // {
    //   property: 'payment',
    //   label: 'Identyfikator',
    //   // eslint-disable-next-line react/display-name
    //   value: (row) => <span className="font-weight-bold">{row.payment.payment_number}</span>
    // }
  ];

  return (
    <div className="pzpeu-table-list">
      <Table cols={cols} rows={logs} />
    </div>
  );
};

export default PaymentLogsTable;
