import { RootState } from 'app/reducer';
import { setSearchingParamsAction, SetSearchingParamsAction } from 'modules/Shared/action';
import Validation from 'modules/Shared/exception/Validation';
import { createSearchParams } from 'modules/Shared/helper/params';
import { PaginationMeta, PartialSearchingProps } from 'modules/Shared/type';
import { Action, Dispatch } from 'redux';
import _ from 'lodash';
import { fetchProceedings, manageProceedingsFav } from 'modules/Proceedings/repository';
import Proceedings from 'modules/Proceedings/model/Proceedings';

export const SET_PROCEEDINGS_LIST_PARAMS = 'PROCEEDINGS/LIST/PARAMS/SET';
export const PROCEEDINGS_LIST = 'PROCEEDINGS/LIST';
export const PROCEEDINGS_CLEAR_LIST = 'PROCEEDINGS/CLEAR/LIST';

export type ProceedingsListAction =
  | SetProceedingsListParamsAction
  | ProceedingsListFetchedAction
  | ClearProceedingsListAction;

export type SetProceedingsListParamsAction = SetSearchingParamsAction<typeof SET_PROCEEDINGS_LIST_PARAMS>;

export type ClearProceedingsListAction = {
  type: typeof PROCEEDINGS_CLEAR_LIST;
};

export type ProceedingsListFetchedPayload = {
  list: Proceedings[];
  meta: PaginationMeta;
};
export interface ProceedingsListFetchedAction extends Action<typeof PROCEEDINGS_LIST> {
  payload: ProceedingsListFetchedPayload;
}
export const setProceedingsListParamsAction =
  (payload: PartialSearchingProps) => async (dispatch: Dispatch, getState: () => RootState) => {
    const currentState = getState()?.proceedings?.proceedingsList;

    if (
      payload?.filter &&
      !payload?.pagination &&
      typeof currentState?.pagination?.page === 'number' &&
      currentState.pagination.page !== 1 &&
      !_.isEqual(currentState.filter, payload.filter)
    ) {
      currentState.pagination.page = 1;
    }

    if (currentState?.filter?.requires_deposit === '1' && payload?.filter?.requires_deposit !== '1') {
      if (currentState?.filter?.deposit_min) delete payload.filter.deposit_min; // eslint-disable-line no-param-reassign
      if (currentState?.filter?.deposit_max) delete payload.filter.deposit_max; // eslint-disable-line no-param-reassign
    }

    const state = { ...currentState, ...payload };

    dispatch(setSearchingParamsAction(SET_PROCEEDINGS_LIST_PARAMS, payload));
    try {
      const { pagination, filter, sort } = state;

      const searchParams = createSearchParams({ pagination, filter, sort });

      const response = await fetchProceedings(searchParams);

      const { data, meta } = response.data;

      dispatch<ProceedingsListFetchedAction>({
        type: PROCEEDINGS_LIST,
        payload: { list: data, meta }
      });
    } catch (error) {
      if (error instanceof Validation) {
        dispatch<ProceedingsListFetchedAction>({
          type: PROCEEDINGS_LIST,
          payload: { list: [], meta: null }
        });
      } else {
        throw error;
      }
    }
  };

export const clearProceedingsList = () => async (dispatch: Dispatch) => {
  dispatch<ClearProceedingsListAction>({
    type: PROCEEDINGS_CLEAR_LIST
  });
};

export const updateProceedingsFavouriteAction =
  (proceeding_id: number, toastFnc?: () => void) => async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()?.proceedings?.proceedingsList;

    try {
      const response = await manageProceedingsFav(proceeding_id);

      if (response.status === 204) {
        const { list, meta } = state;

        const findIndex = list.findIndex((value) => value.id === proceeding_id);
        if (findIndex !== -1) list[findIndex].is_saved = !list[findIndex].is_saved;

        dispatch<ProceedingsListFetchedAction>({
          type: PROCEEDINGS_LIST,
          payload: { list, meta }
        });
      }

      if (toastFnc) toastFnc();
    } catch (error) {
      if (error instanceof Validation) {
        dispatch<ProceedingsListFetchedAction>({
          type: PROCEEDINGS_LIST,
          payload: { list: [], meta: null }
        });
      } else {
        throw error;
      }
    }
  };

export const removeProceedingsFavouriteAction =
  (proceeding_id: number, toastFnc?: () => void) => async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()?.proceedings?.proceedingsList;

    dispatch(setSearchingParamsAction(SET_PROCEEDINGS_LIST_PARAMS, {}));

    manageProceedingsFav(proceeding_id)
      .then(async () => {
        const { pagination, filter, sort, meta } = state;

        const searchParams = createSearchParams({ pagination, filter, sort });
        const response = await fetchProceedings(searchParams);

        const { data } = response.data;

        dispatch<ProceedingsListFetchedAction>({
          type: PROCEEDINGS_LIST,
          payload: { list: data, meta }
        });

        if (toastFnc) toastFnc();
      })
      .catch((error) => {
        if (error instanceof Validation) {
          dispatch<ProceedingsListFetchedAction>({
            type: PROCEEDINGS_LIST,
            payload: { list: [], meta: null }
          });
        } else {
          throw error;
        }
      });
  };
