import _ from 'lodash';
import { Message, ValidationErrors } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { useState } from 'react';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import { createJudgmentSAOSKeyword } from 'modules/Judgement/repository';
import { createJudgementSAOSKeywordToastSuccess } from 'modules/Judgement/toasts';
import { addToastAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';

const useCategoryAddModal = (category_id: string | number, onSuccess?: () => void) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);

  const cancelToken = useCancelToken();
  const dispatchAddSAOSKeywordToast = () => dispatch(addToastAction(createJudgementSAOSKeywordToastSuccess()));

  const addSAOSKeywordAction = async (data: { name: string }) => {
    setLoading(true);

    const {
      cancelled,
      data: resData,
      message: messageRequest,
      error
    } = await updateRequestWithData(createJudgmentSAOSKeyword, category_id, data, cancelToken);

    if (cancelled) return;
    if (resData?.data) {
      dispatchAddSAOSKeywordToast();
      setDisplayAddModal(false);
      if (onSuccess) onSuccess();
    }
    if (error) setErrors(error);
    if (messageRequest) setMessage(messageRequest);
    setLoading(false);
  };

  const clearErrors = () => {
    setErrors(null);
    setMessage(null);
  };

  return {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addSAOSKeywordAction,
    loading,
    displayAddModal,
    setDisplayAddModal,
    message,
    errors,
    clearErrors
  };
};

export default useCategoryAddModal;
