import Table, { TableCol } from 'modules/Layout/component/Table/AccordionTable';
import React from 'react';
import { PaymentEndSubscriptionValues } from 'modules/Subscriptions/type';
import { formatDate } from 'modules/Shared/helper/utils';

type Props = { subscriptions: PaymentEndSubscriptionValues[] };

const PaymentReceivedTable: React.FC<Props> = ({ subscriptions }) => {
  if (!subscriptions) return null;

  const subscriptionProductValue = (credits: number, days: number) => {
    const creditCorrectDayForm = credits > 1 && credits < 5 ? 'kredyty' : 'kredytów';
    const creditsString = credits === 1 ? 'kredyt' : creditCorrectDayForm;
    const creditValue = credits ? (
      <>
        <strong>{credits}</strong> {creditsString}
      </>
    ) : (
      'pełny dostęp'
    );

    return (
      <span className="text-primary">
        {creditValue} na {days} {days === 1 ? 'dzień' : 'dni'}
      </span>
    );
  };

  const cols: TableCol<PaymentEndSubscriptionValues>[] = [
    {
      property: 'products',
      label: 'Produkt',
      value: function RowDescription(row) {
        const { credits, date_to, name, days } = row.modules[0];
        return (
          <div>
            {name}, {subscriptionProductValue(credits, days)}
            {date_to && (
              <>
                , ważne do <span className="text-dark">{formatDate(date_to)}</span>
              </>
            )}
          </div>
        );
      }
    },
    {
      property: 'net_value',
      label: 'Netto',
      // eslint-disable-next-line react/display-name
      value: (row) => <span className="font-weight-bold">{row.net_value.toFixed(2).replace('.', ',')}</span>
    },
    {
      property: 'value',
      label: 'Brutto',
      // eslint-disable-next-line react/display-name
      value: (row) => <span className="font-weight-bold">{row.value.toFixed(2).replace('.', ',')}</span>
    },
    {
      property: 'tax_percentage',
      label: 'VAT',
      value: (row) => `${row.tax_percentage}%`
    }
  ];

  return (
    <div className="pzpeu-table-list">
      <Table cols={cols} rows={subscriptions} />
    </div>
  );
};

export default PaymentReceivedTable;
