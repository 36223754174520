import foreignProceedingsApiImportsListReducer from 'modules/ForeignProceedings/reducer/foreignProceedingsApiImportsList';
import { combineReducers, Reducer } from 'redux';
import { ForeignProceedingsApiImportsState } from '../../state/foreignProceedingsApiImports';

const reducer = (): Reducer<ForeignProceedingsApiImportsState> => {
  const reducers = {
    foreignProceedingsApiImportsList: foreignProceedingsApiImportsListReducer
  };

  return combineReducers<ForeignProceedingsApiImportsState>(reducers);
};

export default reducer;
