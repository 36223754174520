import BaseModal, { Props } from 'modules/Layout/component/Modal';
import React, { useEffect, useState } from 'react';
import { Button, Form as FormStrap } from 'reactstrap';
import Alert from 'modules/Layout/component/Alert';
import FieldsetModules from 'modules/Proceedings/component/Fieldset/CPV';
import { Message, ValidationErrors } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import { ProceedingsCPVValues } from 'modules/Proceedings/type';

interface IProps extends Props {
  element?: ProceedingsCPVValues;
  message: Message;
  clearErrorsOnUnmount: () => void;
  errors: ValidationErrors;
  onSubmit: (param: ProceedingsCPVValues) => void;
  loading?: boolean;
  title: string;
}

const ManageCPVModal: React.FC<IProps> = (props) => {
  const { toggle, element, clearErrorsOnUnmount, message, onSubmit, loading, errors, title } = props;

  const [name, setName] = useState(element?.name ?? '');
  const [code, setCode] = useState(element?.code ?? '');

  const isValue = React.useRef(false);
  useEffect(() => {
    return () => {
      if (isValue.current) {
        clearErrorsOnUnmount();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (message && !isValue.current) isValue.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const onSubmitRequest = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit({ name, code });
  };

  const dispalBody = () => {
    return (
      <FormStrap id="manage-element-modal" onSubmit={onSubmitRequest}>
        <Alert message={message} />
        {loading && <Loader />}
        <div className="modal-body-content">
          <FieldsetModules
            moduleValues={code}
            setModuleValues={setCode}
            propertyName="code"
            title="Kod"
            errors={errors}
            required
          />
          <FieldsetModules
            moduleValues={name}
            setModuleValues={setName}
            propertyName="name"
            title="Nazwa"
            errors={errors}
            required
          />
        </div>
      </FormStrap>
    );
  };

  const displayFooter = () => {
    return (
      <div className="modal-actions">
        <Button
          type="submit"
          form="manage-element-modal"
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light"
          disabled={loading}
        >
          Zapisz
        </Button>
        <Button type="button" className="cancel waves-effect waves-light" onClick={toggle} disabled={loading}>
          Anuluj
        </Button>
      </div>
    );
  };

  return <BaseModal {...props} title={title} body={dispalBody()} footer={displayFooter()} />;
};

export default ManageCPVModal;
