/* eslint-disable */
import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function EditIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px', className = 'icon-svg-table' } = props;

  return (
    <svg className={className} height={height} id="icon_info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        id="Icon_awesome-info-circle"
        data-name="Icon awesome-info-circle"
        d="M8.563.563a8,8,0,1,0,8,8A8,8,0,0,0,8.563.563Zm0,3.548A1.355,1.355,0,1,1,7.208,5.466,1.355,1.355,0,0,1,8.563,4.111ZM10.369,12.3a.387.387,0,0,1-.387.387H7.143a.387.387,0,0,1-.387-.387V11.53a.387.387,0,0,1,.387-.387H7.53V9.079H7.143a.387.387,0,0,1-.387-.387V7.917a.387.387,0,0,1,.387-.387H9.208a.387.387,0,0,1,.387.387v3.226h.387a.387.387,0,0,1,.387.387Z"
        transform="translate(-0.563 -0.563)"
        stroke="transparent"
        fill={fill}
      />
    </svg>
  );
}
