import { Routes } from 'app/routes';
import Authorize from 'modules/Auth/container/Authorize';
import ExecutorsAdminListView from 'modules/Executor/view/admin';
import ExecutorAdminDetailsView from 'modules/Executor/view/admin/Details';
import ExecutorAdminCreateView from 'modules/Executor/view/admin/Create';
import ExecutorAdminUpdateView from 'modules/Executor/view/admin/Update';
import ExecutorsListView from 'modules/Executor/view/client/List';
import ExecutorsDetailsView from 'modules/Executor/view/client/List/Details';
import React from 'react';
import { Route } from 'react-router-dom';
import {
  EXECUTORS_INDEX,
  EXECUTORS_DETAILS,
  EXECUTORS_ADD,
  EXECUTORS_EDIT,
  EXECUTOR_EXPLANATION_EDIT
} from 'modules/Executor/permissions';
import { ROLE_CLIENT, ROLE_CLIENT_BS } from 'modules/User/model/UserRole';
import ModuleGuard from 'modules/Auth/container/Guard/ModuleGuard';
import { MODULE_EXECUTOR, MODULE_EXECUTOR_EXTENDED } from 'modules/Module/model/Module';
import ExplanationSectionView from 'modules/Executor/view/admin/Update/ExplanationSections';
import ExecutorsReportClaimsListView from 'modules/Executor/view/client/ReportClaims';

export const ROUTE_EXECUTORS = '/executors';
export const ROUTE_EXECUTORS_REPORT_CLAIMS = '/executors/report_claims';
export const ROUTE_EXECUTOR = '/executors/:id(\\d+)';
export const ROUTE_EXECUTORS_ADMINS = '/executors/admins';
export const ROUTE_EXECUTOR_ADMINS = '/executors/admins/:id(\\d+)';
export const ROUTE_EXECUTOR_ADMINS_CREATE = '/executors/admins/create';
export const ROUTE_EXECUTOR_ADMINS_UPDATE = '/executors/admins/update/:id(\\d+)';
export const ROUTE_EXECUTOR_ADMINS_SECTION_EXPLANATION = '/executors/admins/explanation';

export const createExecutorRoutes = (): Routes => ({
  wrapped: [
    <Authorize
      key="executors"
      hasAccess={(user) => user?.role?.slug === ROLE_CLIENT || user?.role?.slug === ROLE_CLIENT_BS}
    >
      <Route
        path={ROUTE_EXECUTORS}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_EXECUTOR}>
            <ExecutorsListView />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize
      key="executors-report-claims"
      hasAccess={(user) => user?.role?.slug === ROLE_CLIENT || user?.role?.slug === ROLE_CLIENT_BS}
    >
      <Route
        path={ROUTE_EXECUTORS_REPORT_CLAIMS}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_EXECUTOR_EXTENDED}>
            <ExecutorsReportClaimsListView />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize
      key="executor"
      hasAccess={(user) => user?.role?.slug === ROLE_CLIENT || user?.role?.slug === ROLE_CLIENT_BS}
    >
      <Route
        path={ROUTE_EXECUTOR}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_EXECUTOR}>
            <ExecutorsDetailsView />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize key="executors-admins" permissions={[EXECUTORS_INDEX]}>
      <Route path={ROUTE_EXECUTORS_ADMINS} exact component={ExecutorsAdminListView} />
    </Authorize>,
    <Authorize key="executor-admins" permissions={[EXECUTORS_DETAILS]}>
      <Route path={ROUTE_EXECUTOR_ADMINS} exact component={ExecutorAdminDetailsView} />
    </Authorize>,
    <Authorize key="executor-admins-create" permissions={[EXECUTORS_ADD]}>
      <Route path={ROUTE_EXECUTOR_ADMINS_CREATE} exact component={ExecutorAdminCreateView} />
    </Authorize>,
    <Authorize key="executor-admins-update" permissions={[EXECUTORS_EDIT]}>
      <Route path={ROUTE_EXECUTOR_ADMINS_UPDATE} exact component={ExecutorAdminUpdateView} />
    </Authorize>,
    <Authorize key="executor-explanation-sections" permissions={[EXECUTOR_EXPLANATION_EDIT]}>
      <Route path={ROUTE_EXECUTOR_ADMINS_SECTION_EXPLANATION} exact component={ExplanationSectionView} />
    </Authorize>
  ]
});
