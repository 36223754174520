import {
  SubscriptionsSelectedActionTypes,
  SUBSCRIPTIONS_SELECTED,
  SUBSCRIPTIONS_WANT_INVOICE
} from 'modules/Subscriptions/action';
import { initSubscriptionSelectedState, SubscriptionsSelectedState } from 'modules/Subscriptions/state/selected';

const initState = initSubscriptionSelectedState();

const reducer = (
  state: SubscriptionsSelectedState = initState,
  action: SubscriptionsSelectedActionTypes
): SubscriptionsSelectedState => {
  switch (action.type) {
    case SUBSCRIPTIONS_SELECTED: {
      return { ...state, subscriptions_id: action.payload };
    }

    case SUBSCRIPTIONS_WANT_INVOICE: {
      return { ...state, wants_invoice: action.payload };
    }

    default:
      return state;
  }
};

export default reducer;
