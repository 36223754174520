import React from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { useDispatch, useSelector } from 'react-redux';
import { addToastAction } from 'modules/Layout/action';
import logo from 'modules/Layout/component/ProceedingsListLogo/baner-desktop.jpg';
import { removeForeignProceedingsFromFavouriteToastSuccess } from '../../../toasts';
import { removeForeignProceedingsFavouriteAction } from '../../../action/ForeignProceedingsList';
import ForeignProceedingsTable from '../../../container/ForeignProceedingsList/Table';
import ForeignProceedingsFavouriteFilter from '../../../container/ForeignProceedingsList/Filter/favouriteFilter';
import ForeignProceedingsListingPagination from '../../../container/ForeignProceedingsList/Pagination';
import ForeignProceedingsPerPage from '../../../container/ForeignProceedingsList/PerPage';

const useFavouriteForeignProceedingsList = (foreignProceedingsRoute: string, isAdmin?: boolean) => {
  const dispatch = useDispatch();
  const { fetching } = useSelector((state: RootState) => state.foreignProceedings.foreignProceedingsList);

  const dispatchRemoveForeignProceedingsFavToast = () =>
    dispatch(addToastAction(removeForeignProceedingsFromFavouriteToastSuccess()));

  const manageForeignProceedingsFavouriteAction = (proceeding_id: number) => {
    dispatch(removeForeignProceedingsFavouriteAction(proceeding_id, dispatchRemoveForeignProceedingsFavToast));
  };

  const templateTheme = process.env.THEME_TEMPLATE as 'pzp' | 'bs';

  const jsxFnc = () => (
    <div className="row users-view">
      <div className="col-12">
        {templateTheme === 'pzp' && (
          <div className="mb-3">
            <a
              href="https://postepowania.pl/baza-wykonawcow-w-stosunku-do-ktorych-moga-wystapic-podstawy-do-wykluczenia/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="mw-100" src={logo} alt="Baner Sprawdź swoją konkurencię w przetargach!" />
            </a>
          </div>
        )}
        <Listing
          table={
            <ForeignProceedingsTable
              manageFavouriteAction={manageForeignProceedingsFavouriteAction}
              paramsUrl={{ list: 'favourite' }}
              isAdmin={isAdmin}
            />
          }
          filter={<ForeignProceedingsFavouriteFilter isAdmin={isAdmin} />}
          pagination={<ForeignProceedingsListingPagination isAdmin={isAdmin} path={foreignProceedingsRoute} />}
          perPage={<ForeignProceedingsPerPage isAdmin={isAdmin} />}
          loading={fetching}
        />
      </div>
    </div>
  );

  return { jsxFnc };
};

export default useFavouriteForeignProceedingsList;
