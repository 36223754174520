import React from 'react';
import classNames from 'classnames';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_OPTION_RIGHT } from 'modules/Agreements/step';
import { AgreementStepOptionRightValues } from 'modules/Agreements/components/Steps/Default/OptionRight';
import PreviewFieldContent from 'modules/Agreements/components/PreviewField/Content';

const AgreementStepPreviewSubject = (props: AgreementStepPreviewProps<AgreementStepOptionRightValues>): JSX.Element => {
  const { agreement, steps, modifiedStep } = props;
  const option_rights = getStepValues<AgreementStepOptionRightValues>(steps, AGREEMENT_STEP_OPTION_RIGHT);

  const { value: parts } = getFieldForPreview<AgreementStepOptionRightValues['option_rights']>(
    option_rights,
    modifiedStep,
    'option_rights'
  );
  const { value: sameParts } = getFieldForPreview<AgreementStepOptionRightValues['same_option_rights_for_parts']>(
    option_rights,
    modifiedStep,
    'same_option_rights_for_parts'
  );

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    option_rights,
    modifiedStep,
    `checkboxes.general`
  );

  const isMultiple = agreement.few_parts && !sameParts;

  const renderContent = (contentKey: string, className = 'preview-point') => {
    return (
      <PreviewFieldContent
        step={option_rights}
        modifiedStep={modifiedStep}
        contentKey={contentKey}
        className={className}
      />
    );
  };

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_OPTION_RIGHT)}</div>
      <div className="reset-preview-point">
        {parts?.map((part, index: number) => {
          const { value: optionRightPossibility } = getFieldForPreview(
            option_rights,
            modifiedStep,
            `option_rights.${index}.option_right_possibility`
          );

          const partObj = agreement.parts_ids.find((item) => item.id === part.part_id);

          return (
            <div key={index} className="agreement-step-preview-content reset-preview-point">
              {isMultiple && partObj && <div className="h5">{partObj.getName(index)}:</div>}
              {!optionRightPossibility && (
                <p key={`option_rights.${index}.option_right_possibility`} className={classNames('preview-point')}>
                  Zamawiający nie przewiduje opcji w ramach zamówienia.
                </p>
              )}
              {optionRightPossibility && [
                renderContent(`option_rights.${index}.option_right_content`),
                renderContent(`option_rights.${index}.option_right_type`),
                renderContent(`option_rights.${index}.option_right_circumstances`)
              ]}
              {generalCheckboxes &&
                generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
                  if (!checkbox.checked) return null;

                  if (checkbox.part_id !== part.part_id) return null;

                  return (
                    <p
                      className={classNames('preview-point wysiwyg-preview', {
                        modified: generalCheckboxesModified
                      })}
                      key={index}
                      dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                    />
                  );
                })}
            </div>
          );
        })}
        {isMultiple && generalCheckboxes && (
          <div className="agreement-step-preview-content">
            <p className="h5 reset-preview-point">Ponadto dla każdego z zadań:</p>
            {generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
              if (!checkbox.checked) return null;

              if (checkbox.part_id) return null;

              return (
                <p
                  className={classNames('preview-point wysiwyg-preview', {
                    modified: generalCheckboxesModified
                  })}
                  key={index}
                  dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AgreementStepPreviewSubject;
