import { suffixLabel } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import React, { useEffect, useState } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { Props } from 'modules/ForeignProceedings/component/Fieldset/Main/Alert';
import {
  DictionaryCountiesType,
  IndustrySubType,
  IndustryType,
  SlugType
} from 'modules/ForeignProceedings/container/ForeignProceedingsList/Filter/FilterValues/useFilterValues';
import Select from 'modules/Layout/component/Input/Select';
import { SelectOption } from '../../../../../Shared/type';
// eslint-disable-next-line import/no-cycle
import {
  fetchDictionariesCountries,
  fetchForeignProceedingsIndustryTypes,
  fetchForeignProceedingsProcedures,
  fetchForeignProceedingsSources
} from '../../../../repository';
import { foreignProceedingStatusOptions } from '../../../../model/ForeignProceedings';
import FilterCheckboxSelect from '../../../../../Layout/component/Filter/CheckboxSelect';

const ForeignProceedingsAlertInputsFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, setValues, placeholder = false, isEditing } = props;
  const { name = '', filters } = values;
  const [sourcesList, setSourcesList] = useState<SlugType[]>([]);
  const [proceduresList, setProceduresList] = useState<SlugType[]>([]);
  const [industryTypesList, setIndustryTypesList] = useState<IndustryType[]>([]);
  const [dictionaryCountiesList, setDictionaryCountiesList] = useState<DictionaryCountiesType[]>([]);
  const [industrySubTypesList, setIndustrySubTypesList] = useState<IndustrySubType[]>([]);
  const sourcesOptions: SelectOption[] = sourcesList.map((sourceItem) => {
    return {
      value: sourceItem.slug,
      label: sourceItem.name
    };
  });
  const proceduresOptions: SelectOption[] = proceduresList.map((procedureItem) => {
    return {
      value: procedureItem.slug,
      label: procedureItem.name
    };
  });
  const industryTypesOptions: SelectOption[] = industryTypesList.map((industryTypeItem) => {
    return {
      value: String(industryTypeItem.id),
      label: industryTypeItem.name
    };
  });
  const industrySubTypesOptions: SelectOption[] = industrySubTypesList.map((industrySubTypeItem) => {
    return {
      value: industrySubTypeItem.id,
      label: industrySubTypeItem.name
    };
  });
  const dictionaryCountiesOptions: SelectOption[] = Object.entries(dictionaryCountiesList).map(([key, value]) => {
    return {
      value: key,
      label: String(value)
    };
  });

  useEffect(() => {
    const getValues = async () => {
      const responseSources = await fetchForeignProceedingsSources();
      const responseProcedures = await fetchForeignProceedingsProcedures();
      const responseIndustryTypes = await fetchForeignProceedingsIndustryTypes();
      const responseDictionaryCounties = await fetchDictionariesCountries();
      const newSources = responseSources.data;
      const newProcedures = responseProcedures.data;
      const newIndustryTypes = responseIndustryTypes.data;
      const newDictionaryCounties = responseDictionaryCounties.data.data;
      setSourcesList(newSources);
      setProceduresList(newProcedures);
      setIndustryTypesList(newIndustryTypes);
      setDictionaryCountiesList(newDictionaryCounties);
    };
    getValues().then((r) => r);
  }, []);

  useEffect(() => {
    const selectedIndustryType = industryTypesList.find((type) => type.id === Number(filters?.industry_type_id));
    if (selectedIndustryType?.subtypes.length > 0) {
      setIndustrySubTypesList(selectedIndustryType.subtypes);
    } else {
      setIndustrySubTypesList([]);
    }
  }, [industryTypesList, filters]);

  return (
    <>
      <FormGroup>
        {!placeholder && <Label for="input-name">{suffixLabel('Nazwa filtru')}</Label>}
        <Input
          type="text"
          name="name"
          id="input-name"
          placeholder={placeholder ? 'Nazwa filtru' : null}
          value={name}
          onChange={(event) => setValues({ ...values, name: event.target.value })}
          invalid={hasError(errors, 'name')}
          disabled={isEditing}
        />
        {hasError(errors, 'name') && <FormFeedback>{getError(errors, 'name')}</FormFeedback>}
      </FormGroup>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '1.5rem' }}>
        <FormGroup>
          {!placeholder && <Label for="input-filters-name">{suffixLabel('Nazwa postępowania')}</Label>}
          <Input
            type="text"
            name="filters.name"
            id="input-filters-name"
            placeholder={placeholder ? 'Nazwa postępowania' : null}
            value={filters.name}
            onChange={(event) =>
              setValues({
                ...values,
                filters: {
                  ...filters,
                  name: event.target.value
                }
              })
            }
            invalid={hasError(errors, 'filters.name')}
          />
          {hasError(errors, 'filters.name') && <FormFeedback>{getError(errors, 'filters.name')}</FormFeedback>}
        </FormGroup>
        <FormGroup>
          {!placeholder && <Label for="input-filters-ordered_by">{suffixLabel('Zamawiający')}</Label>}
          <Input
            type="text"
            name="filters.ordered_by"
            id="input-filters-ordered_by"
            placeholder={placeholder ? 'Zamawiający' : null}
            value={filters.ordered_by}
            onChange={(event) =>
              setValues({
                ...values,
                filters: {
                  ...filters,
                  ordered_by: event.target.value
                }
              })
            }
            invalid={hasError(errors, 'filters.ordered_by')}
          />
          {hasError(errors, 'filters.ordered_by') && (
            <FormFeedback>{getError(errors, 'filters.ordered_by')}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          {!placeholder && <Label for="input-filters-ref_nr">{suffixLabel('Numer referencyjny')}</Label>}
          <Input
            type="text"
            name="filters.ref_nr"
            id="input-filters-ref_nr"
            placeholder={placeholder ? 'Numer referencyjny' : null}
            value={filters.ref_nr}
            onChange={(event) =>
              setValues({
                ...values,
                filters: {
                  ...filters,
                  ref_nr: event.target.value
                }
              })
            }
            invalid={hasError(errors, 'filters.ref_nr')}
          />
          {hasError(errors, 'filters.ref_nr') && <FormFeedback>{getError(errors, 'filters.ref_nr')}</FormFeedback>}
        </FormGroup>
        <FilterCheckboxSelect
          property="slug_source"
          label="Rynek"
          options={sourcesOptions}
          selectedValues={(filters.slug_source as any) || []}
          onChange={(val: any) => {
            setValues({
              ...values,
              filters: { ...filters, slug_source: val } // Store as an array if necessary
            });
          }}
        />
        <FormGroup>
          <Label for="filters.country_code">{suffixLabel('Kraj')}</Label>
          <Select
            name="filters.country_code"
            id="filters.country_code"
            value={dictionaryCountiesOptions.find((option) => option.value === filters.country_code)}
            onChange={(event) => {
              setValues({
                ...values,
                filters: {
                  ...filters,
                  country_code: event.value
                }
              });
            }}
            required={false}
            options={[
              {
                value: '',
                label: ''
              },
              ...dictionaryCountiesOptions
            ]}
          />
        </FormGroup>
        <FormGroup>
          <Label for="filters.procedure">{suffixLabel('Procedura')}</Label>
          <Select
            name="filters.procedure"
            id="filters.procedure"
            value={proceduresOptions.find((option) => option.value === filters.procedure)}
            onChange={(event) => {
              setValues({
                ...values,
                filters: {
                  ...filters,
                  procedure: event.value
                }
              });
            }}
            required={false}
            options={[
              {
                value: '',
                label: ''
              },
              ...proceduresOptions
            ]}
          />
        </FormGroup>
        <FormGroup>
          <Label for="filters.status">{suffixLabel('Status')}</Label>
          <Select
            name="filters.status"
            id="filters.status"
            value={foreignProceedingStatusOptions().find((option) => option.value === filters.status)}
            onChange={(event) => {
              setValues({
                ...values,
                filters: {
                  ...filters,
                  status: event.value
                }
              });
            }}
            required={false}
            options={foreignProceedingStatusOptions()}
          />
        </FormGroup>
        <FormGroup>
          <Label for="filters.industry_type_id">{suffixLabel('Branże')}</Label>
          <Select
            name="filters.industry_type_id"
            id="filters.industry_type_id"
            value={industryTypesOptions.find((option) => option.value === filters.industry_type_id)}
            onChange={(event) => {
              setValues({
                ...values,
                filters: {
                  ...filters,
                  industry_type_id: event.value
                }
              });
            }}
            required={false}
            options={[
              {
                value: '',
                label: ''
              },
              ...industryTypesOptions
            ]}
          />
        </FormGroup>
        <FormGroup>
          <Label for="filters.industry_sub_type_id">{suffixLabel('Branże podgrupa')}</Label>
          <Select
            name="filters.industry_sub_type_id"
            id="filters.industry_sub_type_id"
            value={industrySubTypesOptions.find((option) => option.value === filters.industry_sub_type_id)}
            onChange={(event) => {
              setValues({
                ...values,
                filters: {
                  ...filters,
                  industry_sub_type_id: event.value
                }
              });
            }}
            required={false}
            options={[
              {
                value: '',
                label: ''
              },
              ...industrySubTypesOptions
            ]}
            isDisabled={filters.industry_type_id !== '' && industrySubTypesOptions.length <= 1}
          />
        </FormGroup>
      </div>
      {hasError(errors, 'filters') && <FormFeedback>{getError(errors, 'filters')}</FormFeedback>}
    </>
  );
};

export default ForeignProceedingsAlertInputsFieldset;
