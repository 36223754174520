import { AxiosError } from 'axios';
import ApiError from 'modules/Shared/exception/ApiError';

export default class FileTooLarge extends ApiError {
  constructor(error: AxiosError) {
    super('Przesłany rozmiar plików jest za duży.', error);
  }

  getMessageValue(): string {
    return 'Przesłany rozmiar plików jest za duży.';
  }
}
