import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { fetchImport } from 'modules/Office/repository';
import { OfficeImportsListValues } from 'modules/Office/model/Imports';
import { breadcrumbRouteOfficeImport } from 'modules/Office/breadcrumbs';
import ImportsDetails from 'modules/Office/component/Details/Imports';
import CardBox from 'modules/Layout/component/CardBox';

const Details = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [officeImport, setOfficeImport] = useState<OfficeImportsListValues>(null);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const { data, cancelled, message: messageResponse } = await fetchRequest(fetchImport, id, cancelToken);

      if (cancelled) return;
      if (data) setOfficeImport(data.data);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (officeImport) {
      const title = `Importowany plik${officeImport.file_name ? ` - ${officeImport.file_name}` : ''}`;
      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteOfficeImport(officeImport.file_name)
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeImport]);

  if (fetching) return <Loader />;
  if (!fetching && !officeImport && message)
    return (
      <div className="executor-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <CardBox>{message && <Alert message={message} />}</CardBox>
        </div>
      </div>
    );
  if (!fetching && officeImport) {
    return (
      <div className="row executor-details-view position-relative justify-content-center">
        <div className="col-12 col-lg-7 user-details">
          <ImportsDetails officeImport={officeImport} />
        </div>
      </div>
    );
  }

  return null;
};

export default Details;
