import React, { useEffect, useRef, useState } from 'react';
import { FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AgreementCheckboxes, AgreementStepProps, AGREEMENT_TYPE_OUTSIDE_ORDER } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_PENALTIES } from 'modules/Agreements/step';
import useStepFields from 'modules/Agreements/hook/useStepFields';

export interface AgreementStepPenaltiesValues {
  same_subject_for_parts: boolean;
  penalties_possibility: boolean;
  executor_penalties_possibility: boolean;
  executor_delay_delivery_checked: boolean;
  executor_delay_delivery_content: string | null;
  executor_delay_remove_checked: boolean;
  executor_delay_remove_content: string | null;
  executor_contractor_renouncement_checked: boolean;
  executor_contractor_renouncement_content: string | null;
  executor_executor_renouncement_checked: boolean;
  executor_executor_renouncement_content: string | null;
  executor_secondment_checked: boolean;
  executor_secondment_content: string | null;
  executor_insurance_policy_checked: boolean;
  executor_insurance_policy_content: string | null;
  executor_safeguard_checked: boolean;
  executor_safeguard_content: string | null;
  executor_schedule_checked: boolean;
  executor_schedule_content: string | null;
  executor_others_checked: boolean;
  executor_others_content: string | null;
  contractor_penalties_possibility: boolean;
  contractor_renouncement_checked: boolean;
  contractor_renouncement_content: string | null;
  contractor_project_checked: boolean;
  contractor_project_content: string | null;
  contractor_others_checked: boolean;
  contractor_others_content: string | null;
  compensation_checked: boolean;
  compensation_content: string | null;
  penalties_to_date_checked: boolean;
  penalties_to_date_content: string | null;
  payment_delay_checked: boolean;
  payment_delay_content: string | null;
  finish_works_checked: boolean;
  finish_works_content: string | null;
  max_penalties_checked: boolean;
  max_penalties_content: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

export const EXECUTOR_DELAY_DELIVERY_CONTENT =
  'za zwłokę w oddaniu Przedmiotu Umowy, lub jego części lub realizacji danego etapu Przedmiotu Umowy, względem terminu określonego w Umowie  w wysokości ...% wynagrodzenia, za każdy.';
export const EXECUTOR_DELAY_REMOVE_CONTENT =
  'za zwłokę w usunięciu wad Przedmiotu Umowy, jego części lub danego etapu stwierdzonych przy odbiorze częściowym lub ostatecznym lub w okresie gwarancji bądź rękojmi w wysokości ...% wynagrodzenia, za każdy dzień zwłoki, liczony od dnia wyznaczonego na usunięcie wad.';
export const EXECUTOR_CONTRACTOR_RENOUNCEMENT_CONTENT =
  'za odstąpienie od Umowy przez zamawiającego z przyczyn, za które winę ponosi wykonawca w wysokości ...% wynagrodzenia.';
export const EXECUTOR_EXECUTOR_RENOUNCEMENT_CONTENT =
  'za odstąpienie od Umowy przez wykonawcę z przyczyn leżących po stronie wykonawcy w wysokości ...% wynagrodzenia.';
export const EXECUTOR_SECONDMENT_CONTENT =
  'za oddelegowanie do wykonywania Przedmiotu Umowy osób niezatrudnionych na podstawie stosunku pracy, w wysokości ...% wynagrodzenia, za każdy stwierdzony przypadek (kara może być nakładana wielokrotnie, jeżeli zamawiający podczas kontroli po raz kolejny stwierdzi wobec tej samej osoby, że nie jest ona zatrudniona na podstawie stosunku pracy).';
export const EXECUTOR_INSURANCE_POLICY_CONTENT =
  'za nieterminowe przedłożenie polisy ubezpieczeniowej w wysokości ...% wynagrodzenia, za każdy dzień zwłoki.';
export const EXECUTOR_SAFEGUARD_CONTENT =
  'za niedostarczenie przedłużonego zabezpieczenia należytego wykonania umowy w terminie umożliwiającym zachowanie ciągłości w wysokości ...%  wynagrodzenia, za każdy dzień zwłoki.';
export const EXECUTOR_SCHEDULE_CONTENT =
  'za nieterminowe przedłożenie Harmonogramu w wysokości ...% wynagrodzenia, za każdy dzień zwłoki.';
const EXECUTOR_OTHERS_CONTENT = 'inne:';
export const CONTRACTOR_RENOUNCEMENT_CONTENT =
  'odstąpienie od Umowy z przyczyn zależnych od zamawiającego w wysokości ...% wynagrodzenia brutto objętego Umową.';
export const CONTRACTOR_PROJECT_CONTENT =
  'nieterminowe przekazanie wykonawcy dokumentacji projektowej w wysokości ... wynagrodzenia brutto objętego Umową';
const CONTRACTOR_OTHERS_CONTENT = 'inne:';
const COMPENSATION_CONTENT =
  'Strony zastrzegają sobie prawo do dochodzenia odszkodowania uzupełniającego przewyższającego wysokość kar umownych do wysokości rzeczywiście poniesionej szkody.';
const PENALTIES_TO_DATE_CONTENT =
  'Kary umowne strony zapłacą na wskazany przez siebie rachunek, w terminie do ... dni kalendarzowych od dnia doręczenia żądania zapłaty kary umownej.';
const PAYMENT_DELAY_CONTENT =
  'W razie opóźnienia w zapłacie kary umownej, każda ze stron może potrącić należności z tytułu przewidzianych kar umownych z dowolnej należności drugiej strony.';
const FINISH_WORKS_CONTENT =
  'Zapłata kary umownej za zwłokę nie zwalnia wykonawcy z obowiązku dokończenia robót będących Przedmiotem Umowy.';

export const getExecutorPenaltiesPossibilityText = (sameSubjectForPartsStatus: boolean): string => {
  return sameSubjectForPartsStatus
    ? 'Wykonawca zapłaci zamawiającemu kary umowne za:'
    : 'W zakresie każdego zadania (osobno dla każdego zadania), wykonawca zapłaci zamawiającemu kary umowne, liczone od wynagrodzenia brutto objętego Umową dla danego zadania, w następujących przypadkach:';
};

export const getContractorPenaltiesPossibilityText = (sameSubjectForPartsStatus: boolean): string => {
  return sameSubjectForPartsStatus
    ? 'Zamawiający zapłaci wykonawcy kary umowne za:'
    : 'W zakresie każdego zadania (osobno dla każdego zadania), zamawiający zapłaci wykonawcy kary umowne, liczone od wynagrodzenia brutto objętego Umową dla danego zadania, w następujących przypadkach:';
};

export const getMaxPenaltiesContent = (sameSubjectForPartsStatus: boolean): string => {
  return sameSubjectForPartsStatus
    ? 'Maksymalna wysokość kar umownych naliczonych przez każdą ze stron Umowy nie może przekroczyć ...% wynagrodzenia umownego brutto'
    : 'Maksymalna wysokość kar umownych naliczonych przez każdą ze stron Umowy nie może przekroczyć ...% wynagrodzenia umownego brutto dla każdej z zadań';
};

const initContentValues = {
  executor_others_content: EXECUTOR_OTHERS_CONTENT,
  contractor_others_content: CONTRACTOR_OTHERS_CONTENT,
  compensation_content: COMPENSATION_CONTENT,
  penalties_to_date_content: PENALTIES_TO_DATE_CONTENT,
  payment_delay_content: PAYMENT_DELAY_CONTENT,
  finish_works_content: FINISH_WORKS_CONTENT
};

const mapState = (step: AgreementStepPenaltiesValues, defaultValueStatus?: boolean): AgreementStepPenaltiesValues => {
  const {
    same_subject_for_parts,
    penalties_possibility,
    executor_penalties_possibility,
    max_penalties_checked,
    executor_no_subexecutors_salary_checked,
    executor_after_date_salary_checked,
    executor_project_checked,
    executor_agreement_checked,
    executor_no_agreement_change_checked,
    executor_others_content,
    contractor_others_content,
    compensation_content,
    penalties_to_date_content,
    payment_delay_content,
    finish_works_content,
    checkboxes,
    ...rest
  } = step;

  return {
    ...rest,
    same_subject_for_parts,
    penalties_possibility: Boolean(penalties_possibility || defaultValueStatus),
    executor_penalties_possibility: Boolean(executor_penalties_possibility || defaultValueStatus),
    penalties_to_date_checked: true,
    max_penalties_checked: Boolean(max_penalties_checked || defaultValueStatus),
    executor_no_subexecutors_salary_checked: Boolean(executor_no_subexecutors_salary_checked || defaultValueStatus),
    executor_after_date_salary_checked: Boolean(executor_after_date_salary_checked || defaultValueStatus),
    executor_project_checked: Boolean(executor_project_checked || defaultValueStatus),
    executor_agreement_checked: Boolean(executor_agreement_checked || defaultValueStatus),
    executor_no_agreement_change_checked: Boolean(executor_no_agreement_change_checked || defaultValueStatus),
    executor_others_content: executor_others_content || EXECUTOR_OTHERS_CONTENT,
    contractor_others_content: contractor_others_content || CONTRACTOR_OTHERS_CONTENT,
    compensation_content: compensation_content || COMPENSATION_CONTENT,
    penalties_to_date_content: penalties_to_date_content || PENALTIES_TO_DATE_CONTENT,
    payment_delay_content: payment_delay_content || PAYMENT_DELAY_CONTENT,
    finish_works_content: finish_works_content || FINISH_WORKS_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepPenalties = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, onSubmit, onChange, errors } = props;
  const agreementFromPzp = agreement.type !== AGREEMENT_TYPE_OUTSIDE_ORDER;

  const initState = useRef<AgreementStepPenaltiesValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_PENALTIES), agreementFromPzp)
  );
  const [stepValues, setStepValues] = useState<AgreementStepPenaltiesValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_PENALTIES), agreementFromPzp);
    setStepValues({ ...initState.current });
  }, [steps]);

  const {
    renderCheckbox,
    renderEditableCheckbox,
    renderContent,
    renderCustomCheckboxes,
    renderCheckboxWithNumberInput,
    renderSwitch
  } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    initContentValues
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        {renderSwitch('penalties_possibility', 'Czy mają zostać zastosowane kary umowne?', agreementFromPzp)}
        {stepValues.penalties_possibility && [
          renderCheckbox(
            'executor_penalties_possibility',
            getExecutorPenaltiesPossibilityText(stepValues.same_subject_for_parts),
            agreementFromPzp
          ),
          stepValues.executor_penalties_possibility && (
            <div className="pl-3">
              {[
                renderCheckboxWithNumberInput('executor_delay_delivery', EXECUTOR_DELAY_DELIVERY_CONTENT, false, true),
                renderCheckboxWithNumberInput('executor_delay_remove', EXECUTOR_DELAY_REMOVE_CONTENT, false, true),
                renderCheckboxWithNumberInput(
                  'executor_contractor_renouncement',
                  EXECUTOR_CONTRACTOR_RENOUNCEMENT_CONTENT,
                  false,
                  true
                ),
                renderCheckboxWithNumberInput(
                  'executor_executor_renouncement',
                  EXECUTOR_EXECUTOR_RENOUNCEMENT_CONTENT,
                  false,
                  true
                ),
                renderCheckboxWithNumberInput('executor_secondment', EXECUTOR_SECONDMENT_CONTENT, false, true),
                renderCheckboxWithNumberInput(
                  'executor_insurance_policy',
                  EXECUTOR_INSURANCE_POLICY_CONTENT,
                  false,
                  true
                ),
                renderCheckboxWithNumberInput('executor_safeguard', EXECUTOR_SAFEGUARD_CONTENT, false, true),
                renderCheckboxWithNumberInput('executor_schedule', EXECUTOR_SCHEDULE_CONTENT, false, true),
                renderEditableCheckbox('executor_others')
              ]}
            </div>
          ),
          <hr />,
          renderCheckbox(
            'contractor_penalties_possibility',
            getContractorPenaltiesPossibilityText(stepValues.same_subject_for_parts)
          ),
          stepValues.contractor_penalties_possibility && (
            <div className="pl-3">
              {[
                renderCheckboxWithNumberInput('contractor_renouncement', CONTRACTOR_RENOUNCEMENT_CONTENT, false, true),
                renderCheckboxWithNumberInput('contractor_project', CONTRACTOR_PROJECT_CONTENT, false, true),
                renderEditableCheckbox('contractor_others')
              ]}
            </div>
          ),
          <hr />,
          renderCheckbox('compensation_checked', stepValues.compensation_content),
          renderContent('penalties_to_date_content'),
          renderCheckbox('payment_delay_checked', stepValues.payment_delay_content),
          renderCheckbox('finish_works_checked', stepValues.finish_works_content),
          renderCheckboxWithNumberInput(
            'max_penalties',
            getMaxPenaltiesContent(stepValues.same_subject_for_parts),
            agreementFromPzp,
            true
          )
        ]}
        {renderCustomCheckboxes()}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepPenalties;
