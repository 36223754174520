import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function BaseIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 21 21.567">
      <g id="Group_318" data-name="Group 318" transform="translate(-1656.478 -1488.032)">
        <g id="Group_317" data-name="Group 317" transform="translate(1656.978 1488.532)">
          <path
            id="Path_1251"
            data-name="Path 1251"
            d="M1666.978,1488.032a.5.5,0,0,1,.237.06l10,5.373a.5.5,0,0,1,.014.873l-10,5.793a.5.5,0,0,1-.5,0l-10-5.793a.5.5,0,0,1,.014-.873l10-5.373A.5.5,0,0,1,1666.978,1488.032Zm8.974,5.889-8.974-4.822-8.974,4.822,8.974,5.2Z"
            transform="translate(-1656.978 -1488.532)"
            fill={fill}
          />
          <path
            id="Path_1252"
            data-name="Path 1252"
            d="M1666.978,1505.7a.5.5,0,0,1-.251-.067l-10-5.793a.5.5,0,0,1,0-.865l2.5-1.446a.5.5,0,1,1,.5.865l-1.749,1.013,9,5.216,9-5.216-1.749-1.013a.5.5,0,0,1,.5-.865l2.5,1.446a.5.5,0,0,1,0,.865l-10,5.793A.5.5,0,0,1,1666.978,1505.7Z"
            transform="translate(-1656.978 -1489.407)"
            fill={fill}
          />
          <path
            id="Path_1253"
            data-name="Path 1253"
            d="M1666.978,1510.962a.5.5,0,0,1-.251-.067l-10-5.793a.5.5,0,0,1,0-.865l2.5-1.446a.5.5,0,0,1,.5.865l-1.749,1.013,9,5.216,9-5.216-1.749-1.013a.5.5,0,1,1,.5-.865l2.5,1.446a.5.5,0,0,1,0,.865l-10,5.793A.5.5,0,0,1,1666.978,1510.962Z"
            transform="translate(-1656.978 -1489.895)"
            fill={fill}
          />
        </g>
        <path
          id="Line_9"
          data-name="Line 9"
          d="M0,3.307A.5.5,0,0,1-.44,3.044a.5.5,0,0,1,.2-.677L4.988-.44a.5.5,0,0,1,.677.2.5.5,0,0,1-.2.677L.237,3.248A.5.5,0,0,1,0,3.307Z"
          transform="translate(1662.209 1491.111)"
          fill={fill}
        />
        <path
          id="Line_10"
          data-name="Line 10"
          d="M0,3.355A.5.5,0,0,1-.44,3.092a.5.5,0,0,1,.2-.677L5.078-.44a.5.5,0,0,1,.677.2.5.5,0,0,1-.2.677L.237,3.3A.5.5,0,0,1,0,3.355Z"
          transform="translate(1664.499 1492.39)"
          fill={fill}
        />
        <path
          id="Line_11"
          data-name="Line 11"
          d="M0,1.8A.5.5,0,0,1-.44,1.533a.5.5,0,0,1,.2-.677L2.176-.44a.5.5,0,0,1,.677.2.5.5,0,0,1-.2.677L.237,1.737A.5.5,0,0,1,0,1.8Z"
          transform="translate(1666.789 1495.276)"
          fill={fill}
        />
      </g>
    </svg>
  );
}
