import React, { useEffect, useRef, useState } from 'react';
import { CustomInput, FormFeedback, FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import { getError, hasError } from 'modules/Shared/helper/validation';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AGREEMENT_TYPE_OUTSIDE_ORDER, AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_SUBJECT } from 'modules/Agreements/step';
import StepImportDataButton from 'modules/Agreements/components/StepImportDataButton';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import Part from 'modules/Agreements/model/Part';
import Accordion from 'modules/Layout/component/Accordion';
import { IMPORT_ORDERS_ACCESS } from '../../../../../Auth/container/Guard/permissions';
import Authorize from '../../../../../Auth/container/Authorize';

export interface AgreementStepSubjectValues {
  same_subject_for_parts: boolean;
  subjects: {
    part_id: number | null;
    agreement_subject: string | null;
    personal_duty_checked: boolean;
    personal_duty: string | null;
    agreement_subject_details: string | null;
    del_guaranteed_execution_checked: boolean;
    del_guaranteed_execution_content: string | null;
  }[];
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

export interface AgreementStepSubjectImportValues {
  same_subject_for_parts: boolean;
  subjects: {
    agreement_subject: string | null;
    personal_duty_checked: boolean;
    personal_duty: string | null;
    agreement_subject_details: string | null;
    del_guaranteed_execution_checked: boolean;
    del_guaranteed_execution_content: string | null;
  }[];
}

const AGREEMENT_SUBJECT =
  'Wykonawca zobowiązuje się dostarczyć za wynagrodzeniem Przedmiot Umowy, to jest ........ zgodnie z wymaganiami zawartymi w ........';
const PERSONAL_DUTY =
  'Zamawiający przewiduje obowiązek osobistego wykonania przez wykonawcę kluczowych zadań (zgodnie z art. 60 p.z.p. i art. 120 p.z.p.) na następujących warunkach: ........';
const AGREEMENT_SUBJECT_DETAILS =
  '<p>Szczegółowy opis Przedmiotu Umowy oraz wymagania zamawiającego względem Przedmiotu Umowy zostały określone w następujących dokumentach:<ol><li>...</li></ol></p>';
const DEL_GUARANTEED_EXECUTION_CONTENT =
  'Zamawiający gwarantuje realizację zamówienia w ilości ........ % zadania. Z tego tytułu wykonawcy nie przysługuje względem Zamawiającego jakiegokolwiek roszczenie majątkowe, w tym możliwość dochodzenia od Zamawiającego odszkodowania tytułem utraconych korzyści.';

const initContentValues = {
  agreement_subject: AGREEMENT_SUBJECT,
  personal_duty: PERSONAL_DUTY,
  agreement_subject_details: AGREEMENT_SUBJECT_DETAILS,
  del_guaranteed_execution_content: DEL_GUARANTEED_EXECUTION_CONTENT
};

const mapState = (step: AgreementStepSubjectValues): AgreementStepSubjectValues => {
  const { subjects, checkboxes, ...rest } = step;

  return {
    ...rest,
    subjects: subjects.map((part) => {
      const { agreement_subject, personal_duty, agreement_subject_details, del_guaranteed_execution_content } = part;

      return {
        ...part,
        agreement_subject: agreement_subject || AGREEMENT_SUBJECT,
        personal_duty: personal_duty || PERSONAL_DUTY,
        agreement_subject_details: agreement_subject_details || AGREEMENT_SUBJECT_DETAILS,
        del_guaranteed_execution_content: del_guaranteed_execution_content || DEL_GUARANTEED_EXECUTION_CONTENT
      };
    }),
    checkboxes: checkboxes || {}
  };
};

const AgreementStepSubject = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, stepsImportData, onSubmit, onChange, errors } = props;
  const initState = useRef<AgreementStepSubjectValues>(mapState(getStepValues(steps, AGREEMENT_STEP_SUBJECT)));
  const [stepValues, setStepValues] = useState<AgreementStepSubjectValues>({ ...initState.current });
  const stepDataImportValues: AgreementStepSubjectImportValues = getStepValues(stepsImportData, AGREEMENT_STEP_SUBJECT);
  const { parts_ids, few_parts } = agreement;
  const { subjects, same_subject_for_parts } = stepValues;

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_SUBJECT));
    setStepValues({ ...initState.current });
  }, [steps]);

  const isMultiple = few_parts && !same_subject_for_parts;

  const { renderPartEditableCheckbox, renderPartContent, renderPartSwitch, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    partSlug: 'subjects',
    initContentValues
  });

  const renderPart = (partObj: Part, index: number) => {
    const part = isMultiple ? subjects[index] : subjects[0];

    const body = (
      <FormGroup>
        {renderPartContent('agreement_subject', index, part)}
        {agreement.type === AGREEMENT_TYPE_OUTSIDE_ORDER && [
          renderPartSwitch(
            'personal_duty_checked',
            'Czy zamawiający przewiduje obowiązek osobistego wykonania przez wykonawcę kluczowych zadań (zgodnie z art. 60 p.z.p. i art. 120 p.z.p.)?',
            index,
            part
          ),
          part.personal_duty_checked && renderPartContent('personal_duty', index, part)
        ]}
        {renderPartContent('agreement_subject_details', index, part)}
        {renderPartEditableCheckbox('del_guaranteed_execution', index, part)}
        {renderCustomCheckboxes('general', partObj?.id)}
      </FormGroup>
    );

    if (isMultiple) {
      return (
        <Accordion
          key={`renderPartAccordion-${partObj.id}`}
          isInvalid={hasError(errors, `subjects.${index}`)}
          accordionContentStyle={{ padding: '1rem' }}
          entity={{
            title: partObj.getName(index),
            content: body
          }}
        />
      );
    }

    return body;
  };

  const samePartsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setStepValues((values) =>
      mapState({
        ...values,
        same_subject_for_parts: checked,
        subjects: checked
          ? [
              {
                ...subjects[0],
                part_id: null
              }
            ]
          : parts_ids.map((part) => ({
              ...subjects[0],
              part_id: part.id
            }))
      })
    );
  };

  const onStepDataImportClick = () => {
    if (stepDataImportValues.same_subject_for_parts !== undefined) {
      samePartsChange({ target: { checked: stepDataImportValues.same_subject_for_parts } } as any);
    }

    setStepValues((values) => {
      return {
        ...values,
        subjects: stepDataImportValues.subjects
          ? values.subjects.map((subject, index) => ({ ...subject, ...stepDataImportValues.subjects[index] }))
          : values.subjects
      };
    });
  };

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <Authorize permissions={[IMPORT_ORDERS_ACCESS]}>
        <StepImportDataButton onSubmit={onStepDataImportClick} stepDataImportValues={stepDataImportValues} />
      </Authorize>
      {few_parts && (
        <FormGroup>
          <CustomInput
            id="same_subject_for_parts"
            name="same_subject_for_parts"
            type="switch"
            checked={same_subject_for_parts}
            onChange={samePartsChange}
            invalid={hasError(errors, 'same_subject_for_parts')}
            label="Czy przedmiot zamówienia jest wspólny dla wszystkich zadań?"
          />
          {hasError(errors, 'same_subject_for_parts') && (
            <FormFeedback className="d-block">{getError(errors, 'same_subject_for_parts')}</FormFeedback>
          )}
        </FormGroup>
      )}
      {(isMultiple ? parts_ids : [null]).map(renderPart)}
      {isMultiple && (
        <>
          <p className="h4">Ponadto dla każdego z zadań:</p>
          {renderCustomCheckboxes()}
        </>
      )}
    </AgreementFormWrapper>
  );
};

export default AgreementStepSubject;
