import axios, { CancelTokenSource } from 'axios';
import { fetchPreambleProceedingsToastError } from 'modules/Agreements/toasts';
import { addToastAction } from 'modules/Layout/action';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import Proceedings from 'modules/Proceedings/model/Proceedings';
import { fetchProceedings } from 'modules/Proceedings/repository';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { createSearchParams } from 'modules/Shared/helper/params';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AGREEMENT_TYPE_OUTSIDE_ORDER } from 'modules/Agreements/type';

type Props = {
  proceedingValue: { name: string; id: number };
  onProceedingChange: (proceedingValue: { name: string; id: number }) => void;
  required?: boolean;
  mode?: string;
};

// eslint-disable-next-line react/display-name
const PreamblePublishedProceedingAutocomplete: React.FC<Props> = React.memo(
  ({ proceedingValue, onProceedingChange, required, mode }) => {
    const dispatch = useDispatch();
    const [fetching, setFetching] = useState(false);
    const [proceedingsList, setProceedingsList] = useState<Proceedings[]>([]);

    const dispatchFetchProceedingsToastError = (messageValue: string) =>
      dispatch(addToastAction(fetchPreambleProceedingsToastError(messageValue)));

    const cancelToken: React.MutableRefObject<CancelTokenSource> = useRef(null);

    const fetchProceedingsList = async (advance_name?: string) => {
      if (cancelToken.current) {
        cancelToken.current.cancel('Operation canceled due to new request.');
      }
      cancelToken.current = axios.CancelToken.source();

      const filter = advance_name ? { advance_name } : {};
      const searchParams = createSearchParams({
        filter: { ...filter, from_bzp: 0, only_projects: 1, mode: mode === AGREEMENT_TYPE_OUTSIDE_ORDER ? 11 : 10 },
        pagination: { per_page: 9999 }
      });

      setFetching(true);

      const { data, cancelled, message } = await fetchRequest(
        fetchProceedings,
        searchParams,
        cancelToken.current.token
      );

      if (cancelled) return;
      if (data) {
        setProceedingsList(data.data);
        setFetching(false);
      }
      if (message && message.value !== 'Operation canceled due to new request.') {
        if (fetching) setFetching(false);
        dispatchFetchProceedingsToastError(message.value);
      }
    };

    useEffect(() => {
      return () => {
        if (cancelToken.current) {
          cancelToken.current.cancel('AxiosCancel');
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeValue = (name: string) => {
      const findProceeding = proceedingsList.find((proceeding) => proceeding.name === name);
      if (findProceeding) onProceedingChange({ name: findProceeding.name, id: findProceeding.id });
      else onProceedingChange({ name: '', id: null });
    };

    const clearList = () => {
      if (cancelToken.current) {
        cancelToken.current.cancel('Operation canceled due to new request.');
      }
    };

    const filterProceedingList = proceedingsList.filter((el) => el.id !== proceedingValue.id);

    return (
      <Autocomplete
        currentValue={proceedingValue.name}
        onChangeCurrentValue={onChangeValue}
        searchRequest={fetchProceedingsList}
        fetching={fetching}
        clearList={clearList}
        list={filterProceedingList as never}
        label=""
        required={required}
      />
    );
  }
);

export default PreamblePublishedProceedingAutocomplete;
