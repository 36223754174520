import React, { useEffect, useRef, useState } from 'react';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_LICENSE } from 'modules/Agreements/step';
import { FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import useStepFields from 'modules/Agreements/hook/useStepFields';

export interface AgreementStepLicenseValues {
  license_possibility: boolean;
  tracks_possibility: boolean;
  track_multiplication_checked: boolean;
  track_multiplication_content: string | null;
  track_placing_checked: boolean;
  track_placing_content: string | null;
  track_dissemination_checked: boolean;
  track_dissemination_content: string | null;
  track_dependent_checked: boolean;
  track_dependent_content: string | null;
  track_further_checked: boolean;
  track_further_content: string | null;
  track_others_checked: boolean;
  track_others_content: string | null;
  without_restrictions_checked: boolean;
  without_restrictions_content: string | null;
  rights_transfer_checked: boolean;
  rights_transfer_content: string | null;
  absolutely_checked: boolean;
  absolutely_content: string | null;
  time_unlimited_checked: boolean;
  time_unlimited_content: string | null;
  devices_usage_checked: boolean;
  devices_usage_content: string | null;
  devices_ownership_checked: boolean;
  devices_ownership_content: string | null;
  creating_people_checked: boolean;
  creating_people_content: string | null;
  executor_no_cost_checked: boolean;
  executor_no_cost_content: string | null;
  granting_license_possibility: boolean;
  salary_possibility: boolean;
  salary_non_exclusive_checked: boolean;
  salary_non_exclusive_content: string | null;
  salary_exclusive_checked: boolean;
  salary_exclusive_content: string | null;
  salary_move_checked: boolean;
  salary_move_content: string | null;
  salary_others_checked: boolean;
  salary_others_content: string | null;
  protocol_possibility: boolean;
  protocol_multiplication_checked: boolean;
  protocol_multiplication_content: string | null;
  protocol_copy_checked: boolean;
  protocol_copy_content: string | null;
  protocol_translate_checked: boolean;
  protocol_translate_content: string | null;
  protocol_share_checked: boolean;
  protocol_share_content: string | null;
  protocol_copy_documentation_checked: boolean;
  protocol_copy_documentation_content: string | null;
  protocol_modify_checked: boolean;
  protocol_modify_content: string | null;
  protocol_execute_checked: boolean;
  protocol_execute_content: string | null;
  protocol_others_checked: boolean;
  protocol_others_content: string | null;
  period_content: string | null;
  systems_checked: boolean;
  systems_content: string | null;
  full_realization_checked: boolean;
  full_realization_content: string | null;
  manufacturer_consent_checked: boolean;
  manufacturer_consent_content: string | null;
  handover_checked: boolean;
  handover_content: string | null;
  cannot_terminated_checked: boolean;
  cannot_terminated_content: string | null;
  not_worse_checked: boolean;
  not_worse_content: string | null;
  new_license_checked: boolean;
  new_license_content: string | null;
  create_tracks_checked: boolean;
  create_tracks_content: string | null;
  importance_checked: boolean;
  importance_content: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const TRACK_MULTIPLICATION_CONTENT =
  'w zakresie utrwalania utworu i zwielokrotniania utworu wszelkimi znanymi w chwili zawarcia Umowy technikami, a w szczególności: drukarską, cyfrową, optyczną, zapisu magnetycznego, reprograficzną, w ramach pamięci komputera (w tym serwera) oraz sieci multimedialnych (m.in. Internet), na każdym znanym nośniku, w tym: papierze, nośniku optycznym, magnetycznym, cyfrowym (m.in. płyty CD, DVD, dyskietki komputerowe).';
const TRACK_PLACING_CONTENT =
  'wprowadzenie do obrotu, użyczenie, najem oryginału lub egzemplarzy, na których utwór utrwalono.';
const TRACK_DISSEMINATION_CONTENT =
  'rozpowszechnianie utworu w dowolny sposób w całości lub części w tym publiczne prezentacje, wyświetlenie, odtworzenie, nadawanie i reemitowanie, publikowanie utworów oraz udostępnienie utworów w taki sposób, aby każdy miał do niego dostęp w miejscu i czasie przez siebie wybranym w szczególności przez wprowadzania do sieci Internet, w środkach masowego przekazu, tablicach ogłoszeń, wykorzystywania utworów w celach związanych z konsultacjami społecznymi oraz związanych z ubieganiem się o dofinansowanie z jakichkolwiek źródeł zewnętrznych.';
const TRACK_DEPENDENT_CONTENT =
  'w zakresie prawa do opracowania utworów polegającego na sporządzaniu utworów zależnych.';
const TRACK_FURTHER_CONTENT =
  'w zakresie prawa do dalszego przetwarzania i wykorzystywania elementów utworów, prawa do wykorzystania każdej odrębnej części, jak i całości materiałów dla potrzeb wszelkich dalszych materiałów wykonywanych na zlecenie zamawiającego.';
const TRACK_OTHERS_CONTENT = 'inne.';
const WITHOUT_RESTRICTIONS_CONTENT =
  'Wykonawca w ramach wynagrodzenia zezwala bez ograniczeń zamawiającemu na wykonywanie zależnych praw autorskich do wszelkich utworów, wytworzonych przez wykonawcę w związku z wykonaniem Przedmiotu Umowy z chwilą podpisania przez niego Protokołu odbioru ostatecznego oraz upoważnia zamawiającego do zezwalania bez ograniczeń osobom trzecim, na wykonywanie tych zależnych praw autorskich.';
const RIGHTS_TRANSFER_CONTENT =
  'Przeniesienie autorskich praw majątkowych do utworów na zamawiającego oraz zezwolenie bez ograniczeń zamawiającemu na wykonywanie zależnych praw autorskich nastąpi w ramach wynagrodzenia z chwilą podpisania przez niego Protokołu odbioru ostatecznego.';
const ABSOLUTELY_CONTENT =
  'Wykonawca bezwarunkowo wyraża zgodę na dalsze przeniesienia praw autorskich do wszystkich elementów Przedmiotu Umowy będących na dowolny podmiot trzeci, na polach eksploatacji wskazanych w ust. 1 powyżej, bez prawa wykonawcy do jakiegokolwiek dodatkowego wynagrodzenia.';
const TIME_UNLIMITED_CONTENT =
  'Nabyte przez zamawiającego prawa nie są ograniczone w czasie, jak również terytorialnie.';
const DEVICES_USAGE_CONTENT =
  'Zamawiający może wykonywać prawa, o których mowa w niniejszym paragrafie przy użyciu wszelkich istniejących lub powstałych w przyszłości nośników i technologii, bez żadnych ograniczeń w tym zakresie.';
const DEVICES_OWNERSHIP_CONTENT =
  'Wraz z przeniesieniem autorskich praw majątkowych do utworów wytworzonych w ramach Umowy. Wykonawca przenosi na zamawiającego własność wszystkich nośników, na których utwory zostały utrwalone.';
const CREATING_PEOPLE_CONTENT =
  'Wykonawca oświadcza, iż osoby, które opracują określony utwór będący elementem Przedmiotu Umowy, a którym przysługują osobiste prawa autorskie, nie będą podnosić w stosunku do zamawiającego oraz jego następców prawnych żadnych roszczeń w przypadku dokonywania jakichkolwiek ewentualnych zmian, adaptacji i przeróbek danego utworu, stanowiącego element przedmiotu Umowy.';
const EXECUTOR_NO_COST_CONTENT =
  'Wykonawca podejmie wszelkie działania, aby zamawiający mógł bez ponoszenia dodatkowych kosztów korzystać z utworów wytworzonych w ramach Umowy bez naruszenia czyichkolwiek praw w zakresie niezbędnym do wykonania Przedmiotu Umowy. Wykonawca zwolni zamawiającego z obowiązku naprawienia wszelkich szkód, a także ponoszenia wydatków, jakie zamawiający mógłby ponieść w związku z naruszeniem praw autorskich osób trzecich do utworów wytworzonych w ramach Umowy. Wykonawca będzie odpowiedzialny za zwolnienie zamawiającego od odpowiedzialności wobec osób poszkodowanych za wszelkie domniemane lub udowodnione naruszenia i zobowiązuje się naprawić powstałą z tego tytułu szkodę w pełnej wysokości.';
const SALARY_NON_EXCLUSIVE_CONTENT = 'udzieli zamawiającemu niewyłącznej licencji przez producenta oprogramowania.';
const SALARY_EXCLUSIVE_CONTENT = 'udzieli zamawiającemu wyłącznej licencji przez producenta  oprogramowania.';
const SALARY_MOVE_CONTENT =
  'przeniesie na zamawiającego niewyłączne uprawnienia licencyjne na czas wynikający z zasad licencjonowania określonych przez producenta danego rodzaju oprogramowania, a jeśli ten nie jest ograniczony czasowo – na czas nieoznaczony.';
const SALARY_OTHERS_CONTENT = 'inne.';
const PROTOCOL_MULTIPLICATION_CONTENT =
  'trwałego lub czasowego zwielokrotniania tego programu w całości lub części jakimikolwiek środkami i w jakiejkolwiek formie, w zakresie, w którym jest to niezbędne dla wprowadzania, wyświetlania, stosowania, przekazywania, przechowywania i korzystania z dostarczonego oprogramowania, w zakresie wymaganym w Opisie przedmiotu zamówienia oraz dopuszczalnym przepisami prawa autorskiego, jak również dokumentacji dostarczonego oprogramowania.';
const PROTOCOL_COPY_CONTENT =
  'kopiowania oprogramowania standardowego, z zastrzeżeniem, że może to nastąpić wyłącznie w celu utworzenia kopii archiwalnych lub uzyskania kopii zapasowych oprogramowania w ramach standardowych procesów backup-owych zamawiającego.';
const PROTOCOL_TRANSLATE_CONTENT =
  'tłumaczenia, przystosowywania, zmiany układu lub jakichkolwiek innych zmian w programie komputerowym, z zachowaniem praw osoby, która tych zmian dokonała.';
const PROTOCOL_SHARE_CONTENT = 'rozpowszechniania, w tym użyczenia lub najmu, programu komputerowego lub jego kopii.';
const PROTOCOL_COPY_DOCUMENTATION_CONTENT =
  'kopiowania niezbędnej i wymaganej przez Zamawiającego ilości dokumentacji technicznej i użytkowej.';
const PROTOCOL_MODIFY_CONTENT =
  'modyfikowania oprogramowania objętego Przedmiotem Umowy lub łączenia go z innym programem lub programami komputerowymi w wymaganym zakresie, z zastrzeżeniem, że tego rodzaju czynności mogą być dokonywane wyłącznie w zakresie dopuszczalnym przepisami prawa autorskiego oraz dokumentacji dostarczonego oprogramowania.';
const PROTOCOL_EXECUTE_CONTENT = 'wykonywania praw zależnych w zakresie Przedmiotu Umowy.';
const PROTOCOL_OTHERS_CONTENT = 'inne.';
const PERIOD_CONTENT =
  'Wykonawca oświadcza i gwarantuje, że w ramach wynagrodzenia udziela licencji od momentu podpisania Protokołu odbioru na okres ........';
const SYSTEMS_CONTENT =
  'Licencje uprawniają do korzystania z przedmiotu licencji i jego aktualizacji zarówno przez zamawiającego jak i podmioty przez niego  upoważnione do administrowania i utrzymywania systemów teleinformatycznych. Żadne postanowienia Umowy nie mogę być interpretowane jako ograniczenie wykonawcy do udostępnienia przedmiotu licencji do korzystania podmiotom trzecim wskazanym w zdaniu poprzednim.';
const FULL_REALIZATION_CONTENT =
  'Wykonawca oświadcza, że licencje dostarczone w ramach Umowy zapewniają pełną realizację funkcjonalności zgodnie z wymaganiami zamawiającego. W przypadku, gdy w trakcie trwania Umowy okaże się, że licencje nie zapewniają pełnej funkcjonalności zgodnie z wymaganiami, wykonawca jest zobowiązany uzupełnić brakujące licencje bez dodatkowego wynagrodzenia.';
const MANUFACTURER_CONSENT_CONTENT =
  'Wykonawca oświadcza i gwarantuje, że uzyskał zgodę producenta przedmiotu objętego licencją lub podmiotu upoważnionego przez producenta na korzystanie z przedmiotu licencji, w tym jego a aktualizacji, na zasadach określonych w Umowie, w tym na przekazywanie dokumentów zawierających warunki licencji.';
const HANDOVER_CONTENT =
  'Z chwilą przekazania przedmiotu objętego licencją na nośniku, własność nośników, na których utrwalono dane przedmiotu objętego licencją, jego aktualizacji przechodzi na zamawiającego.';
const CANNOT_TERMINATED_CONTENT =
  'Udzielona licencja, o której mowa w niniejszym paragrafie nie podlega wypowiedzeniu.';
const NOT_WORSE_CONTENT =
  'Warunki korzystania z oprogramowania w ramach udzielonej licencji o której mowa w niniejszym paragrafie nie mogą być gorsze od warunków oferowanych przez danego producenta oprogramowania innym podmiotom.';
const NEW_LICENSE_CONTENT =
  'Zamawiający dopuszcza możliwość dostarczenia oprogramowania stanowiącego Przedmiot Umowy pod warunkiem, że nowa licencja oprogramowania spełnia wszystkie wymagania określone dla licencji, która podlega wymianie, a zamawiający wyraził na piśmie zgodę na dokonanie tej zmiany i zmiana ta nie pociąga za sobą dodatkowych kosztów po stronie zamawiającego, w szczególności nie powoduje zwiększenia wynagrodzenia należnego wykonawcy.';
const CREATE_TRACKS_CONTENT =
  'W przypadku, w którym wykonawca sam lub za pośrednictwem producenta przedmiotu objętego licencją, w wykonaniu zobowiązań wynikających z Umowy, dostarczy lub stworzy utwory, przeniesienie autorskich praw majątkowych oraz autorskich praw zależnych do tych utworów następuje zgodnie z postanowieniami wskazanymi w niniejszym paragrafie dla przeniesienia praw autorskich majątkowych oraz praw autorskich zależnych.';
const IMPORTANCE_CONTENT =
  'Postanowienia niniejszego paragrafu zachowają ważność po zakończeniu wykonywania Przedmiotu Umowy lub po rozwiązaniu, odstąpieniu albo wygaśnięciu Umowy z jakiegokolwiek powodu.';

const initContentValues = {
  track_multiplication_content: TRACK_MULTIPLICATION_CONTENT,
  track_placing_content: TRACK_PLACING_CONTENT,
  track_dissemination_content: TRACK_DISSEMINATION_CONTENT,
  track_dependent_content: TRACK_DEPENDENT_CONTENT,
  track_further_content: TRACK_FURTHER_CONTENT,
  track_others_content: TRACK_OTHERS_CONTENT,
  without_restrictions_content: WITHOUT_RESTRICTIONS_CONTENT,
  rights_transfer_content: RIGHTS_TRANSFER_CONTENT,
  absolutely_content: ABSOLUTELY_CONTENT,
  time_unlimited_content: TIME_UNLIMITED_CONTENT,
  devices_usage_content: DEVICES_USAGE_CONTENT,
  devices_ownership_content: DEVICES_OWNERSHIP_CONTENT,
  creating_people_content: CREATING_PEOPLE_CONTENT,
  executor_no_cost_content: EXECUTOR_NO_COST_CONTENT,
  salary_non_exclusive_content: SALARY_NON_EXCLUSIVE_CONTENT,
  salary_exclusive_content: SALARY_EXCLUSIVE_CONTENT,
  salary_move_content: SALARY_MOVE_CONTENT,
  salary_others_content: SALARY_OTHERS_CONTENT,
  protocol_multiplication_content: PROTOCOL_MULTIPLICATION_CONTENT,
  protocol_copy_content: PROTOCOL_COPY_CONTENT,
  protocol_translate_content: PROTOCOL_TRANSLATE_CONTENT,
  protocol_share_content: PROTOCOL_SHARE_CONTENT,
  protocol_copy_documentation_content: PROTOCOL_COPY_DOCUMENTATION_CONTENT,
  protocol_modify_content: PROTOCOL_MODIFY_CONTENT,
  protocol_execute_content: PROTOCOL_EXECUTE_CONTENT,
  protocol_others_content: PROTOCOL_OTHERS_CONTENT,
  period_content: PERIOD_CONTENT,
  systems_content: SYSTEMS_CONTENT,
  full_realization_content: FULL_REALIZATION_CONTENT,
  manufacturer_consent_content: MANUFACTURER_CONSENT_CONTENT,
  handover_content: HANDOVER_CONTENT,
  cannot_terminated_content: CANNOT_TERMINATED_CONTENT,
  not_worse_content: NOT_WORSE_CONTENT,
  new_license_content: NEW_LICENSE_CONTENT,
  create_tracks_content: CREATE_TRACKS_CONTENT,
  importance_content: IMPORTANCE_CONTENT
};

const mapState = (step: AgreementStepLicenseValues): AgreementStepLicenseValues => {
  const {
    track_multiplication_content,
    track_placing_content,
    track_dissemination_content,
    track_dependent_content,
    track_further_content,
    track_others_content,
    without_restrictions_content,
    rights_transfer_content,
    absolutely_content,
    time_unlimited_content,
    devices_usage_content,
    devices_ownership_content,
    creating_people_content,
    executor_no_cost_content,
    salary_non_exclusive_content,
    salary_exclusive_content,
    salary_move_content,
    salary_others_content,
    protocol_multiplication_content,
    protocol_copy_content,
    protocol_translate_content,
    protocol_share_content,
    protocol_copy_documentation_content,
    protocol_modify_content,
    protocol_execute_content,
    protocol_others_content,
    period_content,
    systems_content,
    full_realization_content,
    manufacturer_consent_content,
    handover_content,
    cannot_terminated_content,
    not_worse_content,
    new_license_content,
    create_tracks_content,
    importance_content,
    checkboxes,
    ...rest
  } = step;

  return {
    ...rest,
    track_multiplication_content: track_multiplication_content || TRACK_MULTIPLICATION_CONTENT,
    track_placing_content: track_placing_content || TRACK_PLACING_CONTENT,
    track_dissemination_content: track_dissemination_content || TRACK_DISSEMINATION_CONTENT,
    track_dependent_content: track_dependent_content || TRACK_DEPENDENT_CONTENT,
    track_further_content: track_further_content || TRACK_FURTHER_CONTENT,
    track_others_content: track_others_content || TRACK_OTHERS_CONTENT,
    without_restrictions_content: without_restrictions_content || WITHOUT_RESTRICTIONS_CONTENT,
    rights_transfer_content: rights_transfer_content || RIGHTS_TRANSFER_CONTENT,
    absolutely_content: absolutely_content || ABSOLUTELY_CONTENT,
    time_unlimited_content: time_unlimited_content || TIME_UNLIMITED_CONTENT,
    devices_usage_content: devices_usage_content || DEVICES_USAGE_CONTENT,
    devices_ownership_content: devices_ownership_content || DEVICES_OWNERSHIP_CONTENT,
    creating_people_content: creating_people_content || CREATING_PEOPLE_CONTENT,
    executor_no_cost_content: executor_no_cost_content || EXECUTOR_NO_COST_CONTENT,
    salary_non_exclusive_content: salary_non_exclusive_content || SALARY_NON_EXCLUSIVE_CONTENT,
    salary_exclusive_content: salary_exclusive_content || SALARY_EXCLUSIVE_CONTENT,
    salary_move_content: salary_move_content || SALARY_MOVE_CONTENT,
    salary_others_content: salary_others_content || SALARY_OTHERS_CONTENT,
    protocol_multiplication_content: protocol_multiplication_content || PROTOCOL_MULTIPLICATION_CONTENT,
    protocol_copy_content: protocol_copy_content || PROTOCOL_COPY_CONTENT,
    protocol_translate_content: protocol_translate_content || PROTOCOL_TRANSLATE_CONTENT,
    protocol_share_content: protocol_share_content || PROTOCOL_SHARE_CONTENT,
    protocol_copy_documentation_content: protocol_copy_documentation_content || PROTOCOL_COPY_DOCUMENTATION_CONTENT,
    protocol_modify_content: protocol_modify_content || PROTOCOL_MODIFY_CONTENT,
    protocol_execute_content: protocol_execute_content || PROTOCOL_EXECUTE_CONTENT,
    protocol_others_content: protocol_others_content || PROTOCOL_OTHERS_CONTENT,
    period_content: period_content || PERIOD_CONTENT,
    systems_content: systems_content || SYSTEMS_CONTENT,
    full_realization_content: full_realization_content || FULL_REALIZATION_CONTENT,
    manufacturer_consent_content: manufacturer_consent_content || MANUFACTURER_CONSENT_CONTENT,
    handover_content: handover_content || HANDOVER_CONTENT,
    cannot_terminated_content: cannot_terminated_content || CANNOT_TERMINATED_CONTENT,
    not_worse_content: not_worse_content || NOT_WORSE_CONTENT,
    new_license_content: new_license_content || NEW_LICENSE_CONTENT,
    create_tracks_content: create_tracks_content || CREATE_TRACKS_CONTENT,
    importance_content: importance_content || IMPORTANCE_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepLicense = (props: AgreementStepProps): JSX.Element => {
  const { steps, onSubmit, onChange, errors } = props;

  const initState = useRef<AgreementStepLicenseValues>(mapState(getStepValues(steps, AGREEMENT_STEP_LICENSE)));

  const [stepValues, setStepValues] = useState<AgreementStepLicenseValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_LICENSE));
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderCheckbox, renderEditableCheckbox, renderSwitch, renderContent, renderCustomCheckboxes } = useStepFields(
    {
      stepValues,
      setStepValues,
      mapState,
      errors,
      initContentValues
    }
  );

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        {renderSwitch(
          'license_possibility',
          'Czy Zamawiający przewiduje postanowienia dotyczące Praw autorskich i pokrewnych?'
        )}
        {stepValues.license_possibility && (
          <>
            {renderCheckbox(
              'tracks_possibility',
              'Wykonawca oświadcza, że będą mu przysługiwały wszelkie prawa autorskie majątkowe do wszelkich utworów, wytworzonych przez wykonawcę w związku z wykonaniem Przedmiotu Umowy, oraz, że w ramach wynagrodzenia, przenosi na zamawiającego autorskie prawa majątkowe do wszelkich utworów będących przedmiotem Umowy i do wszelkich egzemplarzy dokumentacji tych utworów na wszystkich polach eksploatacji, w szczególności tych wymienionych w art. 50 ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych oraz:'
            )}
            {stepValues.tracks_possibility && (
              <div className="pl-3">
                {renderEditableCheckbox('track_multiplication')}
                {renderEditableCheckbox('track_placing')}
                {renderEditableCheckbox('track_dissemination')}
                {renderEditableCheckbox('track_dependent')}
                {renderEditableCheckbox('track_further')}
                {renderEditableCheckbox('track_others')}
              </div>
            )}
            <hr />
            {renderCheckbox('without_restrictions_checked', stepValues.without_restrictions_content)}
            {renderCheckbox('rights_transfer_checked', stepValues.rights_transfer_content)}
            {renderCheckbox('absolutely_checked', stepValues.absolutely_content)}
            {renderCheckbox('time_unlimited_checked', stepValues.time_unlimited_content)}
            {renderCheckbox('devices_usage_checked', stepValues.devices_usage_content)}
            {renderCheckbox('devices_ownership_checked', stepValues.devices_ownership_content)}
            {renderCheckbox('creating_people_checked', stepValues.creating_people_content)}
            {renderCheckbox('executor_no_cost_checked', stepValues.executor_no_cost_content)}
          </>
        )}
        {renderSwitch(
          'granting_license_possibility',
          'Czy zamawiający przewiduje udzielenie licencji przez wykonawcę?'
        )}
        {stepValues.granting_license_possibility && (
          <>
            {renderCheckbox('salary_possibility', 'Wykonawca w ramach wynagrodzenia:')}
            {stepValues.salary_possibility && (
              <div className="pl-3">
                {renderEditableCheckbox('salary_non_exclusive')}
                {renderEditableCheckbox('salary_exclusive')}
                {renderEditableCheckbox('salary_move')}
                {renderEditableCheckbox('salary_others')}
              </div>
            )}
            <hr />
            {renderCheckbox(
              'protocol_possibility',
              'Wykonawca oświadcza i gwarantuje, że w ramach wynagrodzenia udziela licencji od momentu podpisania protokołu odbioru na następujących polach eksploatacji:'
            )}
            {stepValues.protocol_possibility && (
              <div className="pl-3">
                {renderEditableCheckbox('protocol_multiplication')}
                {renderEditableCheckbox('protocol_copy')}
                {renderEditableCheckbox('protocol_translate')}
                {renderEditableCheckbox('protocol_share')}
                {renderEditableCheckbox('protocol_copy_documentation')}
                {renderEditableCheckbox('protocol_modify')}
                {renderEditableCheckbox('protocol_execute')}
                {renderEditableCheckbox('protocol_others')}
              </div>
            )}
            <hr />
            {renderContent('period_content')}
            {renderCheckbox('systems_checked', stepValues.systems_content)}
            {renderCheckbox('full_realization_checked', stepValues.full_realization_content)}
            {renderCheckbox('manufacturer_consent_checked', stepValues.manufacturer_consent_content)}
            {renderCheckbox('handover_checked', stepValues.handover_content)}
            {renderCheckbox('cannot_terminated_checked', stepValues.cannot_terminated_content)}
            {renderCheckbox('not_worse_checked', stepValues.not_worse_content)}
            {renderCheckbox('new_license_checked', stepValues.new_license_content)}
            {renderCheckbox('create_tracks_checked', stepValues.create_tracks_content)}
            {renderCheckbox('importance_checked', stepValues.importance_content)}
          </>
        )}
        {renderCustomCheckboxes()}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepLicense;
