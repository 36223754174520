import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import { PenaltyDataItem } from 'modules/ExecutorOrder/model/Order';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';

export interface Props {
  penalty?: PenaltyDataItem;
}

const PenaltiesProblem: React.FC<Props> = ({ penalty = {} }) => {
  const fields = [
    {
      label: 'Przyczyny naliczenia kary umownej',
      value: penalty?.violation_description ?? 'nie dotyczy',
      html: true
    },
    {
      label: 'Data naliczenia kary umownej',
      value: ifContainsDateFormat(penalty.violation_date, formatDate) ?? 'nie dotyczy'
    },
    {
      label: 'Wartość wynagrodzenia wykonawcy',
      value: penalty?.executor_salary ? `${penalty.executor_salary.toFixed(2).replace('.', ',')} PLN` : 'nie dotyczy'
    },
    {
      label: 'Wysokość kary umownej',
      value: penalty?.penalty_amount ? `${penalty.penalty_amount.toFixed(2).replace('.', ',')} PLN` : 'nie dotyczy'
    }
  ];

  return <InformationList fields={fields} />;
};

export default PenaltiesProblem;
