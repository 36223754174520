import React, { useEffect, useState } from 'react';
import { Message, PaginationMeta, SearchParams } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import Listing from 'modules/Layout/component/Listing';
import User from 'modules/User/model/User';
import CardBox from 'modules/Layout/component/CardBox';
import { UserChangesValues } from 'modules/Profile/type';
import Alert from 'modules/Layout/component/Alert';
import PerPageTable from 'modules/Layout/component/Pagination/WithProps/PerPage';
import PaginationTable from 'modules/Layout/component/Pagination/WithProps';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { fetchChangesList } from 'modules/User/repository';
import ChangesTable from 'modules/User/container/ChangesTable';

export interface Props {
  user?: User;
}

const DetailsChanges: React.FC<Props> = ({ user }) => {
  const [changes, setChanges] = useState<UserChangesValues[]>([]);
  const [meta, setMeta] = useState<PaginationMeta>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);

  const cancelToken = useCancelToken();
  const fetchChanges = async (payload?: SearchParams) => {
    setLoading(true);
    const {
      data,
      cancelled,
      message: messageResponse
    } = await fetchRequest(fetchChangesList, user?.id, payload, cancelToken);

    if (cancelled) return;
    if (data) {
      setChanges(data.data);
      setMeta(data.meta);
    }
    if (messageResponse) setMessage(messageResponse);
    setLoading(false);
  };

  useEffect(() => {
    if (user) {
      fetchChanges();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <CardBox heading="Historia zmian">
      <Alert message={message} />
      <div className="pzpeu-table-list">
        <Listing
          table={<ChangesTable changes={changes} />}
          pagination={<PaginationTable fetchList={fetchChanges} meta={meta} />}
          perPage={<PerPageTable fetchList={fetchChanges} meta={meta} />}
          loading={loading}
        />
      </div>
    </CardBox>
  );
};

export default DetailsChanges;
