import useIsMobile from 'modules/Shared/helper/hooks/useIsMobile';
import React, { ReactNode } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './index.scss';

export type Props = {
  isOpen: boolean;
  title?: ReactNode;
  toggle: () => void;
  body?: ReactNode;
  footer?: ReactNode;
  onClosed?: () => void;
  modalClassName?: string;
  size?: string;
  paddingX?: string;
  paddingTop?: string;
  paddingBottom?: string;
};

export const BaseModal = (props: Props): JSX.Element => {
  const {
    isOpen,
    paddingX = '86px',
    paddingTop = '60px',
    paddingBottom = '66px',
    size = 'lg',
    toggle,
    title = '',
    body,
    footer,
    onClosed,
    modalClassName = ''
  } = props;

  const { isMobile } = useIsMobile(500);

  const paddingLeft = isMobile ? '10px' : paddingX;
  const paddingRight = isMobile ? '10px' : paddingX;

  return (
    <Modal size={size} isOpen={isOpen} onClosed={onClosed} className={`base-modal ${modalClassName}`} centered>
      <ModalHeader
        toggle={toggle}
        close={
          <Button color="link" className="link-with-border modal-close p-0" onClick={toggle}>
            ZAMKNIJ
          </Button>
        }
        tag="div"
      >
        {title}
      </ModalHeader>
      <ModalBody
        style={{
          paddingLeft,
          paddingRight,
          paddingBottom,
          paddingTop
        }}
      >
        {body}
      </ModalBody>
      <ModalFooter className={isMobile ? 'padding-x-50' : null}>{footer}</ModalFooter>
    </Modal>
  );
};

export default BaseModal;
