import { managePageAction } from 'modules/Layout/action';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTE_DASHBOARD } from 'modules/Layout/routes';
import { push } from 'connected-react-router';
import { RootState } from 'app/reducer';
import { cleanSubscriptionsPaymentAction, endPaymentAction } from 'modules/Subscriptions/action/payments';
import PaymentPage from 'modules/Subscriptions/component/PaymentPage';
import { useLocation } from 'react-router';
import { authenticateAction } from 'modules/Auth/action';
import { breadcrumbRoutePaymentDetails } from 'modules/Subscriptions/breadcrumbs';

type ILocatonState = {
  isSuccess: boolean;
  isMobile: boolean;
};

const PaymentReceived = () => {
  const dispatch = useDispatch();
  const { state } = useLocation<ILocatonState>();
  const { details, session_id, message, errorMessage } = useSelector((store: RootState) => store.subscriptions.payment);

  useEffect(() => {
    dispatch(
      managePageAction({
        title: 'Szczegóły transakcji',
        breadcrumb: breadcrumbRoutePaymentDetails()
      })
    );

    if (session_id && state?.hasOwnProperty('isSuccess')) {
      dispatch(endPaymentAction(session_id, state.isSuccess));
    } else {
      dispatch(push(ROUTE_DASHBOARD));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (details) {
      dispatch(
        managePageAction({
          title: `Szczegóły transakcji - ${details.payment_number}`,
          breadcrumb: breadcrumbRoutePaymentDetails(details.payment_number)
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  useEffect(() => {
    if (details && session_id) {
      if (state?.isSuccess) dispatch(authenticateAction());
      dispatch(cleanSubscriptionsPaymentAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  return (
    <PaymentPage message={message || errorMessage} isMobile={state?.isMobile} isMobileSuccess={state?.isSuccess} />
  );
};

export default PaymentReceived;
