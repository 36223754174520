import React from 'react';
import { RootState } from 'app/reducer';
import ListingPerPage from 'modules/Layout/component/Listing/PerPage';
import { PartialSearchingProps } from 'modules/Shared/type';
import { useDispatch, useSelector } from 'react-redux';
import { setClauseListParamsAction } from 'modules/Clause/action/ClauseList';

const ClausePerPage = () => {
  const dispatch = useDispatch();
  const { meta } = useSelector((state: RootState) => state.clause.clauseList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setClauseListParamsAction(payload));

  return <ListingPerPage meta={meta} setParams={setParams} />;
};

export default ClausePerPage;
