import React from 'react';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_EMPLOYMENT_RELATIONSHIP } from 'modules/Agreements/step';
import { AgreementStepEmploymentRelationshipValues } from 'modules/Agreements/components/Steps/Default/EmploymentRelationship';

const AgreementStepPreviewEmploymentRelationship = (
  props: AgreementStepPreviewProps<AgreementStepEmploymentRelationshipValues>
): JSX.Element => {
  const { agreement, steps, modifiedStep } = props;
  const employmentRelationship = getStepValues<AgreementStepEmploymentRelationshipValues>(
    steps,
    AGREEMENT_STEP_EMPLOYMENT_RELATIONSHIP
  );

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        key={`${checkbox}_checked-${checkbox}_content`}
        step={employmentRelationship}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const { value: parts } = getFieldForPreview<AgreementStepEmploymentRelationshipValues['employment_relationship']>(
    employmentRelationship,
    modifiedStep,
    'employment_relationship'
  );

  const { value: sameParts } = getFieldForPreview<
    AgreementStepEmploymentRelationshipValues['same_employment_relationship_for_parts']
  >(employmentRelationship, modifiedStep, 'same_employment_relationship_for_parts');

  const { value: actWorkPossibility, modified: actWorkPossibilityModified } = getFieldForPreview(
    employmentRelationship,
    modifiedStep,
    'act_work_possibility'
  );

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    employmentRelationship,
    modifiedStep,
    `checkboxes.general`
  );

  const isMultiple = agreement.few_parts && !sameParts;

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_EMPLOYMENT_RELATIONSHIP)}</div>
      <div className="agreement-step-preview-content reset-preview-point">
        {!actWorkPossibility ? (
          <p
            className={classNames('preview-point', {
              modified: actWorkPossibilityModified
            })}
          >
            Wykonanie czynności w zakresie realizacji zamówienia nie polega na wykonywaniu pracy w sposób określony w
            ustawie kodeks pracy.
          </p>
        ) : (
          [
            parts?.map((part, index: number) => {
              const { value: verificationPossibility, modified: verificationPossibilityModified } = getFieldForPreview(
                employmentRelationship,
                modifiedStep,
                `employment_relationship.${index}.verification_possibility`
              );

              const partObj = agreement.parts_ids.find((item) => item.id === part.part_id);

              return (
                <div key={index} className="agreement-step-preview-content reset-preview-point">
                  {isMultiple && partObj && <div className="h5">{partObj.getName(index)}:</div>}
                  {renderCheckbox(`employment_relationship.${index}.contractor_obliges`)}
                  {renderCheckbox(`employment_relationship.${index}.activities_type`)}
                  {verificationPossibility && [
                    <p
                      className={classNames('preview-point', {
                        modified: verificationPossibilityModified
                      })}
                    >
                      W celu weryfikacji zatrudniania, przez wykonawcę oraz podwykonawcę, na podstawie umowy o pracę,
                      osób wykonujących wskazane przez zamawiającego czynności w zakresie realizacji zamówienia, Umowa
                      przewiduje możliwość żądania przez zamawiającego:
                    </p>,
                    renderCheckbox(`employment_relationship.${index}.worker_statement`, 'preview-nested-alpha-point'),
                    renderCheckbox(`employment_relationship.${index}.executor_statement`, 'preview-nested-alpha-point'),
                    renderCheckbox(`employment_relationship.${index}.agreement_copy`, 'preview-nested-alpha-point'),
                    renderCheckbox(`employment_relationship.${index}.other_documents`, 'preview-nested-alpha-point')
                  ]}
                  {renderCheckbox(`employment_relationship.${index}.contains_data`)}
                  {renderCheckbox(`employment_relationship.${index}.personal_verification`)}
                  {renderCheckbox(`employment_relationship.${index}.state_verification`)}
                  {renderCheckbox(`employment_relationship.${index}.executor_undertake`)}
                  {generalCheckboxes &&
                    generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
                      if (!checkbox.checked) return null;

                      if (checkbox.part_id != part.part_id) return null;

                      return (
                        <p
                          className={classNames('preview-point wysiwyg-preview', {
                            modified: generalCheckboxesModified
                          })}
                          key={index}
                          dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                        />
                      );
                    })}
                </div>
              );
            }),
            isMultiple && generalCheckboxes && (
              <div className="agreement-step-preview-content">
                <p className="h5 reset-preview-point">Ponadto dla każdego z zadań:</p>
                {generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
                  if (!checkbox.checked) return null;

                  if (checkbox.part_id) return null;

                  return (
                    <p
                      className={classNames('preview-point wysiwyg-preview', {
                        modified: generalCheckboxesModified
                      })}
                      key={index}
                      dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                    />
                  );
                })}
              </div>
            )
          ]
        )}
      </div>
    </div>
  );
};

export default AgreementStepPreviewEmploymentRelationship;
