import CardBox from 'modules/Layout/component/CardBox';
import React from 'react';
import { OfficeImportsListValues } from 'modules/Office/model/Imports';
import ImportsDataset from 'modules/Office/component/Dataset/Imports';
import OfficeImportErrorTable from 'modules/Office/container/ImportErrorList/Table';
import { FormGroup } from 'reactstrap';
import './index.scss';

export interface Props {
  officeImport: OfficeImportsListValues;
}

const ImportsDetails: React.FC<Props> = ({ officeImport }) => {
  return (
    <CardBox
      heading={officeImport?.file_name}
      paddingLeft="30px"
      paddingRight="30px"
      paddingBottom="30px"
      className="imports-details"
    >
      <FormGroup className="form-group-large-margins">
        <ImportsDataset officeImport={officeImport} />
      </FormGroup>
      <div className="card-box-heading">Lista błędów</div>
      {officeImport?.errors?.length ? <OfficeImportErrorTable errorList={officeImport?.errors} /> : 'brak'}
    </CardBox>
  );
};

export default ImportsDetails;
