import React from 'react';
import { calculateDiffInDays } from 'modules/Shared/helper/utils';
import { DashboardAdminOffice } from 'modules/Layout/type';
import { Link } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_OFFICE } from 'modules/Office/routes';
import InformationListCollapsed from '../InformationList/Collapsed';

type Props = { office: DashboardAdminOffice };

const OfficeResponseAwaiting: React.FC<Props> = ({ office }) => {
  const daysLate = office?.sent_at ? calculateDiffInDays(office.sent_at, null, { amount: 2 }) : null;

  const daysCorrectForm = () => {
    const roundedDays = Math.ceil(daysLate);

    switch (roundedDays) {
      case 0:
        return 'dzisiaj';
      case 1:
        return (
          <>
            <span className="highlighted">1</span> dzień temu
          </>
        );
      default:
        return (
          <>
            <span className="highlighted">{roundedDays}</span> dni temu
          </>
        );
    }
  };

  return (
    <div className="office-response-row">
      <InformationListCollapsed
        title={
          <Link className="pzpeu-link" to={getPathUrl(ROUTE_OFFICE, { id: office?.id })}>
            {office?.name}
          </Link>
        }
        fields={[
          Boolean(office?.email) && (
            <a key="email" className="pzpeu-link" href={`mailto:${office?.email}`}>
              {`E-mail: ${office?.email}`}
            </a>
          ),
          Boolean(office?.phone) && (
            <a key="phone" className="pzpeu-link" href={`tel:${office?.phone}`}>
              {`Tel.: ${office?.phone}`}
            </a>
          ),
          <>Utworzono: {daysCorrectForm()}</>
        ]}
      />
    </div>
  );
};

export default OfficeResponseAwaiting;
