import React from 'react';
import { RootState } from 'app/reducer';
import ListingPagination from 'modules/Layout/component/Listing/Pagination';
import { PartialSearchingProps } from 'modules/Shared/type';
import { useDispatch, useSelector } from 'react-redux';
import { setInvoiceListParamsAction } from 'modules/User/action/listInvoiceRequired';

type Props = { path?: string };

const InvoicesRequiredPagination: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { meta, pagination, filter, sort } = useSelector((state: RootState) => state.user.invoiceList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setInvoiceListParamsAction(payload));

  return (
    <ListingPagination
      meta={meta}
      pagination={pagination}
      sort={sort}
      setParams={setParams}
      filter={filter}
      {...props}
    />
  );
};

export default InvoicesRequiredPagination;
