import { JudgementEntity } from 'modules/Judgement/model/Judgement';
import { PaginationMeta, SearchingProps } from 'modules/Shared/type';

export interface JudgementClientListState extends SearchingProps {
  list: JudgementEntity[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initJudgementClientListState = (): JudgementClientListState => ({
  list: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
