import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';
import PasswordFieldset from 'modules/Layout/component/Password/Fieldset';
import { CreateUserValues } from 'modules/Profile/type';
import { ValidationErrors } from 'modules/Shared/type';
import UserFieldset from 'modules/User/component/Fieldset';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form as FormStrap } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CompanyEntity } from 'modules/User/model/User';
import { ROUTE_USERS, ROUTE_USERS_ADMINS } from 'modules/User/routes';
import CompanyFormWrapper, { initCompanyValue } from 'modules/User/component/Fieldset/UserCompany';
import UserRole from 'modules/User/model/UserRole';

export interface Props {
  errors?: ValidationErrors;
  disabled?: boolean;
  submit: (data: CreateUserValues) => void;
  isAdminCreate?: boolean;
  role?: UserRole;
}

const Form: React.FC<Props> = ({ errors, disabled, submit, isAdminCreate, role }) => {
  const [values, setValue] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    password_confirmation: '',
    nip: ''
  });
  const [activeCompany, setActiveCompany] = useState(false);
  const [companyVal, setCompanyVal] = useState<CompanyEntity>(initCompanyValue);
  const [executorFromGus, setExecutorFromGus] = useState<Record<any, any>>(null);

  const firstNameAutofill = useRef(!isAdminCreate);
  const lastNameAutofill = useRef(!isAdminCreate);
  const emailAutofill = useRef(!isAdminCreate);

  useEffect(() => {
    let compValues: CompanyEntity = {};
    if (firstNameAutofill.current) {
      compValues = { ...compValues, first_name: values.first_name };
    }
    if (lastNameAutofill.current) {
      compValues = { ...compValues, last_name: values.last_name };
    }
    if (emailAutofill.current) {
      compValues = { ...compValues, email: values.email };
    }

    if (!_isEmpty(compValues)) setCompanyVal({ ...companyVal, ...compValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const setAutofills = (key: string) => {
    if (key === 'first_name' && firstNameAutofill.current) {
      firstNameAutofill.current = false;
    }
    if (key === 'last_name' && lastNameAutofill.current) {
      lastNameAutofill.current = false;
    }
    if (key === 'email' && emailAutofill.current) {
      emailAutofill.current = false;
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = event.target;

    if (_has(values, key)) {
      setValue({ ...values, [key]: value });
    }
  };

  const setMultipleValues = (data: Record<any, any>) => {
    setCompanyVal({ ...companyVal, ...data });
  };

  const clearFields = () => {
    setCompanyVal({
      ...companyVal,
      name: '',
      first_name: '',
      last_name: '',
      street: '',
      postal_code: '',
      city: '',
      post_office: '',
      email: ''
    });

    setExecutorFromGus(null);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let company: CompanyEntity = null;
    if (activeCompany) {
      if (companyVal?.client_type === 1) {
        const { nip: _q, name: _, ...rest } = companyVal;

        company = { ...rest, nip: '', name: '' };
      } else {
        company = companyVal;
      }
    }

    submit({ ...values, role_id: role?.id, company });
  };

  const hasValue = () => !Object.values(values).some(Boolean);

  return (
    <FormStrap onSubmit={onSubmit}>
      <UserFieldset
        disabled={disabled}
        errors={errors}
        values={values}
        onChange={onChange}
        isAdmin={isAdminCreate}
        required
      />
      <PasswordFieldset disabled={disabled} errors={errors} onChange={onChange} required />

      {!isAdminCreate && (
        <CompanyFormWrapper
          values={companyVal}
          setValues={setCompanyVal}
          displayCompany={activeCompany}
          setDisplayCompany={setActiveCompany}
          setAutofills={setAutofills}
          errors={errors}
          placeholder={false}
          setMultipleValues={setMultipleValues}
          executorFromGus={executorFromGus}
          setExecutorFromGus={setExecutorFromGus}
        />
      )}

      <div className="form-actions">
        {companyVal.client_type === 2 && executorFromGus !== null && (
          <Button
            type="button"
            color="secondary"
            outline
            className="pzpeu-btn-disabled waves-effect waves-light mb-3"
            onClick={clearFields}
          >
            Wyczyść
          </Button>
        )}
        <Button
          type="submit"
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light"
          disabled={hasValue() || disabled}
        >
          Zapisz
        </Button>
        <Link className="cancel" to={isAdminCreate ? ROUTE_USERS_ADMINS : ROUTE_USERS}>
          Anuluj
        </Link>
      </div>
    </FormStrap>
  );
};

export default Form;
