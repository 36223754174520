import React from 'react';
import { RootState } from 'app/reducer';
import ListingPerPage from 'modules/Layout/component/Listing/PerPage';
import { PartialSearchingProps } from 'modules/Shared/type';
import { setExecutorExtendedListParamsAction } from 'modules/User/action/list';
import { useDispatch, useSelector } from 'react-redux';

const ExecutorExtendedPerPage = () => {
  const dispatch = useDispatch();
  const { meta } = useSelector((state: RootState) => state.user.executorsExtendedList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setExecutorExtendedListParamsAction(payload));

  return <ListingPerPage meta={meta} setParams={setParams} />;
};

export default ExecutorExtendedPerPage;
