import React, { Dispatch, SetStateAction } from 'react';
import { CustomInput, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { ValidationErrors } from 'modules/Shared/type';
import { getError, hasError } from 'modules/Shared/helper/validation';

export interface StepFieldPartCheckboxWithNumberInputProps<T> {
  checkbox: string;
  label: string;
  index: number;
  part: Record<any, any>;
  setStepValues: Dispatch<SetStateAction<T>>;
  mapState: (values: T) => T;
  errors?: ValidationErrors | null;
  partSlug?: string;
  disabled?: boolean;
  decimalPlaces?: boolean;
}

const StepFieldPartCheckboxWithNumberInput = <T,>(props: StepFieldPartCheckboxWithNumberInputProps<T>): JSX.Element => {
  const {
    checkbox,
    label,
    index,
    part,
    setStepValues,
    mapState,
    errors,
    partSlug,
    disabled = false,
    decimalPlaces = false
  } = props;

  const isInvalid =
    hasError(errors, `${partSlug}.${index}.${checkbox}_checked`) ||
    hasError(errors, `${partSlug}.${index}.${checkbox}_content`);

  // @ts-ignore
  return (
    <FormGroup key={`${partSlug}.${index}.${checkbox}`}>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-start">
          <CustomInput
            id={`${checkbox}.${index}`}
            type="checkbox"
            // @ts-ignore
            checked={part[`${checkbox}_checked`]}
            onChange={(event) => {
              // @ts-ignore
              part[`${checkbox}_checked`] = event.target.checked;
              // @ts-ignore
              setStepValues((values: T) => mapState(values));
            }}
            invalid={isInvalid}
            disabled={disabled}
          />
          <Input
            type="number"
            min={0}
            step={decimalPlaces ? '0.01' : null}
            style={{ width: '100px' }}
            name={`${checkbox}-${index}_number`}
            id={`${checkbox}-${index}_number`}
            // @ts-ignore
            value={part[`${checkbox}_content`]}
            invalid={isInvalid}
            onChange={(event) => {
              // @ts-ignore
              part[`${checkbox}_content`] = event.target.value;
              // @ts-ignore
              setStepValues((values: T) => mapState(values));
            }}
          />
          <Label className="pl-3" style={{ color: isInvalid && '#B7332D' }}>
            {
              // @ts-ignore
              label.replace('...', part[`${checkbox}_content`] || '...')
            }
          </Label>
        </div>
        {isInvalid && (
          <FormFeedback className="d-block">
            {getError(errors, `${partSlug}.${index}.${checkbox}_checked`) ||
              getError(errors, `${partSlug}.${index}.${checkbox}_content`)}
          </FormFeedback>
        )}
      </div>
    </FormGroup>
  );
};

export default StepFieldPartCheckboxWithNumberInput;
