import React from 'react';
import { getPathUrl } from 'modules/Shared/helper/api';
import { calculateDiffInDays, formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { DashboardClientProceedings } from 'modules/Layout/type';
import { Link } from 'react-router-dom';
import InformationList from 'modules/Layout/component/InformationList';
import { ROUTE_PROCEEDING } from 'modules/Proceedings/routes';
import './lastVisitedExecutor.scss';
import { proceedingsStartDate } from 'modules/Proceedings/model/Proceedings';
import { PROCEEDINGS_INIT_TYPES_VALUES } from 'modules/Proceedings/type';
import Tooltip from 'modules/Layout/component/Tooltip';

type Props = { proceeding: DashboardClientProceedings };

const LastVisitedProceedings: React.FC<Props> = ({ proceeding }) => {
  const daysLate = (date: string) => calculateDiffInDays(date);

  const daysCorrectForm = (days: number) => {
    switch (days) {
      case 1:
        return 'dzień';
      default:
        return 'dni';
    }
  };

  const redirectLink = (value: string, id: number, proceedingsType: number, showTooltip: boolean = false) => (
    <>
      <Link
        id={`dashboard-proceedings-link-${id}`}
        className="pzpeu-link"
        to={getPathUrl(ROUTE_PROCEEDING, { id, proceedingsType })}
      >
        {value}
      </Link>
      {showTooltip && value?.length >= 20 && (
        <Tooltip targetId={`dashboard-proceedings-link-${id}`} tooltipContent={value} tooltipPlacement="bottom-start" />
      )}
    </>
  );

  const calculateLastVisit = () => {
    if (!proceeding?.visited_at) return '-';

    const lastVisitDays = daysLate(proceeding.visited_at);
    const lastVisitDaysRounded = Math.ceil(lastVisitDays);

    if (lastVisitDaysRounded === 0) return 'dzisiaj';
    return `${lastVisitDaysRounded} ${daysCorrectForm(lastVisitDaysRounded)} temu`;
  };

  const isPlanning = proceeding?.proceeding_type === PROCEEDINGS_INIT_TYPES_VALUES.PLANNING;

  return (
    <div className="last-visited-executor">
      <div className="executor-title">
        {redirectLink(proceeding?.name, proceeding?.id, proceeding?.proceeding_type || 1, true)}
      </div>
      <InformationList
        fields={[
          {
            label: 'Ostatnia weryfikacja',
            value: calculateLastVisit()
          },
          {
            label: 'Numer postępowania',
            value: proceeding?.identifier || '-'
          },
          {
            label: 'Nazwa zamawiającego',
            value: proceeding?.contractor_name || '-'
          },
          {
            label: 'Data rozpoczęcia postępowania',
            value: proceedingsStartDate(proceeding?.start_date_type, proceeding?.start_date) || '-'
          },
          !isPlanning && {
            label: 'Termin składania ofert',
            value: ifContainsDateFormat(proceeding?.offers_send_date, formatDatetimeSeconds) || 'brak danych'
          }
        ]}
      />
    </div>
  );
};

export default LastVisitedProceedings;
