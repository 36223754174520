import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import BaseModal, { Props } from 'modules/Layout/component/Modal';
import { Message } from 'modules/Shared/type';
import React, { useEffect } from 'react';
import { Button } from 'reactstrap';

interface IProps<T> extends Props {
  value: T;
  title: string;
  content: (value?: T) => React.ReactNode;
  submitButtonLabel?: String;
  onDeleteClick: (value?: T) => Promise<void>;
  loading: boolean;
  message: Message;
  resetMessage: () => void;
}

const GenericModalDelete = <T extends { id?: number | string }>(props: IProps<T>) => {
  const { value, title, content, submitButtonLabel, onDeleteClick, loading, toggle, message, resetMessage } = props;

  const isValue = React.useRef(false);
  useEffect(() => {
    return () => {
      if (isValue.current) resetMessage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!isValue.current) isValue.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const dispalBody = () => {
    return (
      <>
        {loading && <Loader />}
        <Alert message={message} />
        <div className="modal-body-title">{content(value)}</div>
      </>
    );
  };

  const displayFooter = () => {
    return (
      <div className="modal-actions">
        <Button
          type="submit"
          form="user-delete-form"
          color="primary"
          className="waves-effect waves-light"
          disabled={loading}
          onClick={() => onDeleteClick(value)}
        >
          {submitButtonLabel || 'Usuń'}
        </Button>
        <Button type="button" className="cancel waves-effect waves-light" disabled={loading} onClick={toggle}>
          Anuluj
        </Button>
      </div>
    );
  };

  if (value) return <BaseModal {...props} title={title} body={dispalBody()} footer={displayFooter()} />;

  return null;
};

export default GenericModalDelete;
