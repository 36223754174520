import React from 'react';
import { PaymentLogsValues } from 'modules/Profile/type';
import PaymentLogCompanyDataset from 'modules/Shared/components/PaymentLog/Dataset/ClientData';
import PaymentLogDataset from 'modules/Shared/components/PaymentLog/Dataset/Basic';
import CardBox from 'modules/Layout/component/CardBox';
import PaymentReceivedTable from 'modules/Subscriptions/container/PaymentReceivedTable';
import { useLocation } from 'react-router';
import PaymentLogsTable from 'modules/Subscriptions/container/PaymentLogs';
import './index.scss';

type Props = {
  paymentLog: PaymentLogsValues;
  redirectUrl: string;
};

const DetailsComponent: React.FC<Props> = ({ paymentLog, redirectUrl }) => {
  const location = useLocation<{ previousLocation?: string }>();
  const prevLocation = location?.state?.previousLocation;
  const btnRedirectUrl = prevLocation ?? redirectUrl;

  return (
    <div className="payment-details-log">
      <CardBox
        className="payment-page-card"
        heading="Dane transakcji"
        paddingRight="30px"
        paddingBottom="0"
        redirectReturnBtn={btnRedirectUrl}
      >
        <div className="d-flex">
          <div className="col-12 p-0 col-lg-6 transaction-data">
            <PaymentLogDataset paymentLog={paymentLog} />
          </div>
        </div>
      </CardBox>
      {paymentLog?.wants_invoice && (
        <CardBox
          className="company-log-data-card"
          heading="Dane użyte do wystawienia faktury"
          paddingRight="30px"
          paddingBottom="0"
        >
          <div className="d-flex">
            <div className="col-12 p-0 col-lg-6 company-log-data">
              <PaymentLogCompanyDataset clientData={paymentLog?.client_data} userData={paymentLog?.user} />
            </div>
          </div>
        </CardBox>
      )}
      <CardBox heading="Wybrane produkty" className="choosen-products-card" paddingRight="0" paddingBottom="30px">
        <div className="payment-received-table">
          <PaymentReceivedTable subscriptions={paymentLog?.subscriptions} />
        </div>
      </CardBox>
      <CardBox
        heading="Dokładny przebieg transakcji"
        redirectReturnBtnBottom={redirectUrl}
        paddingRight="0"
        paddingBottom="30px"
      >
        <div className="payment-history-table">
          <PaymentLogsTable logs={paymentLog?.payment_logs} />
        </div>
      </CardBox>
    </div>
  );
};

export default DetailsComponent;
