import { addToastAction, managePageAction } from 'modules/Layout/action';
import Loader from 'modules/Layout/component/Loader';
import Form from 'modules/Proceedings/component/Form';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Alert from 'modules/Layout/component/Alert';
import { Message, ValidationErrors } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import updateRequest from 'modules/Shared/helper/APIRequests/updateRequest';
import CardBox from 'modules/Layout/component/CardBox';
import Proceedings, { createProceedings } from 'modules/Proceedings/model/Proceedings';
import { fetchProceedingsDetails, updateProceedings } from 'modules/Proceedings/repository';
import { breadcrumbRouteProceedingsUpdate } from 'modules/Proceedings/breadcrumbs';
import { ROUTE_PROCEEDING } from 'modules/Proceedings/routes';
import { updateProceedingsToastSuccess } from 'modules/Proceedings/toasts';

export interface Props {
  params?: any;
}

const ProceedingsUpdateView: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { id, proceedingsType } = useParams<{ id: string; proceedingsType: string }>();
  const { state } = useLocation<{ proceedings: Proceedings }>();
  const [proceedings, setProceedings] = useState<Proceedings>(null);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  const scrollToTopOnce = useRef<boolean>(true);

  const dispatchUpdateProceedingsToast = () => dispatch(addToastAction(updateProceedingsToastSuccess()));

  const cancelToken = useCancelToken();
  useEffect(() => {
    if (state?.proceedings) {
      setProceedings(createProceedings(state.proceedings));
    } else {
      (async () => {
        setFetching(true);
        const {
          data: reqData,
          cancelled,
          message: messageResponse
        } = await fetchRequest(fetchProceedingsDetails, id, cancelToken);

        if (cancelled) return;
        const { data } = reqData;
        if (data) setProceedings(createProceedings(data));
        if (messageResponse) setMessage(messageResponse);
        setFetching(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const proceedingsTypeInt = parseInt(proceedingsType, 10) || 2;

  useEffect(() => {
    if (proceedings) {
      const title = `Edycja postępowania${proceedings.name ? ` - ${proceedings.name}` : ''}`;
      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteProceedingsUpdate(proceedingsTypeInt, proceedings.name, proceedings.id)
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proceedings]);

  const updateProceedingsAction = async (data: Proceedings) => {
    if (loading) return;

    if (errors) setErrors(null);
    setLoading(true);
    const {
      cancelled,
      message: messageRes,
      success,
      error
    } = await updateRequest(updateProceedings, id, data, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchUpdateProceedingsToast();
      dispatch(push(getPathUrl(ROUTE_PROCEEDING, { proceedingsType, id })));
    } else {
      if (messageRes) setMessage(messageRes);
      if (error) setErrors(error);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  if (fetching) return <Loader />;
  if (!fetching && !proceedings && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );

  if (!fetching && proceedings) {
    const { keywords, ...rest } = proceedings;
    const formatKeywords = keywords.map((el: any) => el.name);

    return (
      <div className="profile-update-view d-flex justify-content-center">
        <CardBox heading="Edycja postępowania">
          {loading && <Loader />}
          <Alert message={message} />
          {proceedings && (
            <Form
              proceedingsData={{
                keywords: formatKeywords,
                ...rest
              }}
              errors={errors}
              submit={updateProceedingsAction}
              disabled={loading}
              cancelRedirect={getPathUrl(ROUTE_PROCEEDING, { proceedingsType, id })}
              isEditing
            />
          )}
        </CardBox>
      </div>
    );
  }

  return null;
};

export default ProceedingsUpdateView;
