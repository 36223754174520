import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import BaseModal, { Props } from 'modules/Layout/component/Modal';
import { Message } from 'modules/Shared/type';
import React from 'react';
import { Button } from 'reactstrap';
import TraditionalPayment from 'modules/Subscriptions/model/TraditionalPayment';

interface IProps extends Props {
  traditionalPayment: TraditionalPayment;
  onClick: Function;
  loading: boolean;
  message?: Message;
}

const AcceptAccessModal: React.FC<IProps> = (props) => {
  const { traditionalPayment, onClick, loading, toggle, message } = props;

  const title = 'Udzielanie dostępu do modułów';

  const body = (
    <>
      {loading && <Loader />}
      <Alert message={message} />
      <div>
        Czy na pewno chcesz udzielić dostępu do modułów {traditionalPayment.subscriptions}, klientowi{' '}
        {traditionalPayment.user_first_name}?
      </div>
    </>
  );

  const footer = (
    <div className="modal-actions">
      <Button
        type="button"
        color="primary"
        className="waves-effect waves-light"
        disabled={loading}
        onClick={() => onClick(traditionalPayment.id)}
      >
        Nadaj dostęp
      </Button>
      <Button type="button" className="cancel waves-effect waves-light" disabled={loading} onClick={toggle}>
        Anuluj
      </Button>
    </div>
  );

  return <BaseModal {...props} title={title} body={body} footer={footer} />;
};

export default AcceptAccessModal;
