import { AddToastPayload } from 'modules/Layout/action';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';
import { ColorVariants } from 'modules/Shared/type';

const serverErrorToast = (errorMessage: string, errorHeader?: string): AddToastPayload => ({
  header: errorHeader ?? 'Błąd',
  body: errorMessage,
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export default serverErrorToast;
