import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { ModuleAccessHistoryCredit, ModuleAccessHistoryExecutor } from 'modules/Module/model/ModuleAccessHistory';
import sharedCols from 'modules/Module/container/Details/sharedDetailsTableCol';
import { RootState } from 'app/reducer';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';

type Props = {
  credits: ModuleAccessHistoryCredit[];
};

const ModulesAccessHistoryCreditTable: React.FC<Props> = ({ credits }) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { id: user_id } = useParams<{ id: string }>();

  const displayExecutorInfo = (executor: ModuleAccessHistoryExecutor, addComa?: boolean) => (
    <div>
      {executor.name ?? `NIP: ${executor.nip}`} ({ifContainsDateFormat(executor.used_at, formatDate) ?? '-'})
      {addComa && ', '}
    </div>
  );

  const cols: TableCol<ModuleAccessHistoryCredit>[] = [
    ...sharedCols(user, user_id, true),
    {
      property: 'executors',
      label: 'Zużyto na',
      value: function Executors(row) {
        return (
          <>
            {row.executors?.map((executor, index, arr) =>
              arr.length - 1 === index ? displayExecutorInfo(executor) : displayExecutorInfo(executor, true)
            )}
          </>
        );
      }
    }
    // {
    //   property: 'entries',
    //   label: 'Wejścia'
    // },
  ];

  return <Table cols={cols} rows={credits} />;
};

export default ModulesAccessHistoryCreditTable;
