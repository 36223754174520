import Authorize from 'modules/Auth/container/Authorize';
/*  import ActionDelete from 'modules/Layout/component/Action/Delete';  */
import CardBox from 'modules/Layout/component/CardBox';
import { OFFICES_EDIT } from 'modules/Office/permissions';
/*  import { OFFICES_EDIT, OFFICES_DELETE } from 'modules/Office/permissions';  */
import { getPathUrl } from 'modules/Shared/helper/api';
import React from 'react';
import Order from 'modules/ExecutorOrder/model/Order';
import BasicOrderDataset from 'modules/ExecutorOrder/component/Dataset/Basic';
import { ROUTE_ORDER_UPDATE } from 'modules/ExecutorOrder/routes';
import { ORDERS_DELETE } from 'modules/ExecutorOrder/permissions';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import './index.scss';

export interface Props {
  order: Order;
  executor_id: string;
  onDeleteClick?: () => void;
  redirectUrl: string;
}

const DetailsBasic: React.FC<Props> = ({ order, executor_id = 0, onDeleteClick, redirectUrl }) => {
  return (
    <CardBox
      heading="Dane postępowania"
      paddingBottom="0"
      paddingRight="30px"
      className="executor-order-details-basic"
      redirectReturnBtn={redirectUrl}
      actions={
        <>
          <Authorize permissions={[OFFICES_EDIT]}>
            <Link className="link-with-border" to={getPathUrl(ROUTE_ORDER_UPDATE, { executor_id, id: order.id })}>
              EDYCJA
            </Link>
          </Authorize>
          {onDeleteClick && (
            <Authorize permissions={[ORDERS_DELETE]}>
              <Button color="link" className="link-with-border p-0" onClick={onDeleteClick}>
                USUŃ POSTĘPOWANIE
              </Button>
            </Authorize>
          )}
        </>
      }
    >
      <BasicOrderDataset order={order} />
    </CardBox>
  );
};

export default DetailsBasic;
