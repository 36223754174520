import React from 'react';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import _uniqueId from 'lodash/uniqueId';
import DragDropInput from 'modules/Layout/component/DragDropInput';

export type IFile = {
  file?: File;
  id?: string;
};

type Props = {
  values: unknown & { [objKey: string]: IFile[] };
  setValues: (value: React.SetStateAction<any>) => void;
  objKey?: string;
};

const AddFilesDragDrop: React.FC<Props> = ({ values, setValues, objKey = 'files' }) => {
  const removeFile = (fileId: string) => {
    const files: IFile[] = values[objKey] ?? [];

    setValues({
      ...values,
      [objKey]: files.filter((file) => file?.id !== fileId)
    });
  };

  const onDrop = (files: File[]) => {
    setValues({
      ...values,
      [objKey]: [...values[objKey], ...files.map((newFile) => ({ file: newFile, id: _uniqueId(newFile?.name ?? '') }))]
    });
  };

  const displayFiles = () => {
    return values[objKey].map((file) => (
      <div key={file?.id} className="my-2 d-flex flex-wrap">
        <span className="mr-2">{file?.file?.name}</span>
        <ActionDelete title="Usuń plik" onClick={() => removeFile(file?.id)} />
      </div>
    ));
  };

  return (
    <>
      <DragDropInput options={{ onDrop }} />
      <div className="cursor-pointer mb-0">{displayFiles()}</div>
    </>
  );
};

export default AddFilesDragDrop;
