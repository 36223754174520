import {
  initSubscriptionsTraditionalPaymentState,
  SubscriptionsTraditionalPaymentState
} from 'modules/Subscriptions/state/traditionalPayments';
import {
  SET_TRADITIONAL_PAYMENT_LIST_PARAMS,
  SET_TRADITIONAL_PAYMENT_LIST,
  TraditionalPaymentListAction
} from 'modules/Subscriptions/action/traditionalPaymentList';
import { createTraditionalPayment } from 'modules/Subscriptions/model/TraditionalPayment';

const initState = initSubscriptionsTraditionalPaymentState();

const reducer = (
  state: SubscriptionsTraditionalPaymentState = initState,
  action: TraditionalPaymentListAction
): SubscriptionsTraditionalPaymentState => {
  switch (action.type) {
    case SET_TRADITIONAL_PAYMENT_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case SET_TRADITIONAL_PAYMENT_LIST: {
      const { payments = [], meta } = action.payload;

      return { ...state, fetching: false, list: payments.map(createTraditionalPayment), meta };
    }

    default:
      return state;
  }
};

export default reducer;
