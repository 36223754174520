import { RootState } from 'app/reducer';
import React from 'react';
import { useSelector } from 'react-redux';
import './index.scss';

const NavbarUserInfo = () => {
  const user = useSelector((state: RootState) => state.auth.user);

  if (!user) return null;

  const companyName = user.getCompanyName();

  return (
    <>
      <li className="d-none d-xl-block">
        <span className="nav-link text-primary">{user.getName()}</span>
      </li>
      {user.company && companyName && (
        <li className="d-none d-xl-block">
          <span className="nav-link text-primary company-name-wrapper">Firma: {companyName || '-'}</span>
        </li>
      )}
    </>
  );
};

export default NavbarUserInfo;
