import Table, { TableCol } from 'modules/Layout/component/Table/AccordionTable';
import Office from 'modules/Office/model/Office';
import React from 'react';

type Props = { offices: Office[]; onOfficeClick?: (office: Office) => void };

const ResendOfficeTable: React.FC<Props> = ({ offices, onOfficeClick }) => {
  const cols: TableCol<Office>[] = [
    {
      property: 'name',
      label: 'Nazwa'
    },
    {
      property: 'email',
      label: 'Email'
    }
  ];

  return <Table cols={cols} rows={offices} onRowClick={onOfficeClick} />;
};

export default ResendOfficeTable;
