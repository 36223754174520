import BaseModal, { Props } from 'modules/Layout/component/Modal';
import React, { useState } from 'react';
import { Button, Form as FormStrap } from 'reactstrap';
import Alert from 'modules/Layout/component/Alert';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import SAOSKeywordAutocomplete from 'modules/Judgement/container/JudgementAutocomplete/SAOSKeyword';

interface IProps extends Props {
  message: Message;
  loading?: boolean;
  toggle: () => void;
  onSubmit: (param: { name: string }) => void;
}

const AddKeywordModal: React.FC<IProps> = (props) => {
  const { message, loading, toggle, onSubmit } = props;

  const [keywordName, setKeywordName] = useState('');

  const onKeywordChange = (name: string) => setKeywordName(name);

  const onSubmitRequest = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit({ name: keywordName });
  };

  const renderBody = () => {
    return (
      <FormStrap id="manage-keyword-modal" onSubmit={onSubmitRequest}>
        <Alert message={message} />
        {loading && <Loader />}
        <div className="modal-body-content">
          <SAOSKeywordAutocomplete keywordValue={keywordName} onKeywordChange={onKeywordChange} />
        </div>
      </FormStrap>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal-actions">
        <Button
          type="submit"
          form="manage-keyword-modal"
          color="primary"
          className="waves-effect waves-light"
          disabled={loading}
        >
          Zapisz
        </Button>
        <Button type="button" className="cancel waves-effect waves-light" onClick={toggle} disabled={loading}>
          Anuluj
        </Button>
      </div>
    );
  };

  return <BaseModal {...props} title="Dodaj słowo kluczowe" body={renderBody()} footer={renderFooter()} />;
};

export default AddKeywordModal;
