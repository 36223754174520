import { ProfileAction, SET_PROFILE_STATE, UPDATE_PROFILE } from 'modules/Profile/action';
import { initProfileState, ProfileState } from 'modules/Profile/state';

const initialState = initProfileState();

const reducer = (state: ProfileState = initialState, action: ProfileAction): ProfileState => {
  switch (action.type) {
    case UPDATE_PROFILE: {
      return {
        updating: true,
        message: null,
        errors: null
      };
    }

    case SET_PROFILE_STATE: {
      return {
        ...state,
        ...action.payload
      };
    }

    default:
      return state;
  }
};

export default reducer;
