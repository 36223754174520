import React from 'react';
import { Message } from 'modules/Shared/type';
import Listing from 'modules/Layout/component/Listing';
import CardBox from 'modules/Layout/component/CardBox';
import Alert from 'modules/Layout/component/Alert';
import Authorize from 'modules/Auth/container/Authorize';
import { Button } from 'reactstrap';
import { ProceedingsCPVValues } from 'modules/Proceedings/type';
import CPVTable from 'modules/Proceedings/container/CPVTable';
import { PROCEEDINGS_ADD } from 'modules/Proceedings/permissions';

export interface Props {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CPVList: ProceedingsCPVValues[];
  message: Message;
  fetching: boolean;
  setDisplayAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  onDeleteClick: (cpv: ProceedingsCPVValues) => void;
  onEditClick: (cpv: ProceedingsCPVValues) => void;
  isAdmin?: boolean;
}

const ProceedingsCPVDetailsDisplay: React.FC<Props> = ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CPVList,
  message,
  fetching,
  setDisplayAddModal,
  onDeleteClick,
  onEditClick,
  isAdmin
}) => {
  const displayBox = (
    <CardBox
      heading="Kody CPV"
      message={message}
      actions={
        <Authorize permissions={[PROCEEDINGS_ADD]}>
          <Button color="link" className="link-with-border p-0" onClick={() => setDisplayAddModal(true)}>
            DODAJ KOD CPV
          </Button>
        </Authorize>
      }
    >
      <div className="row users-view">
        <div className="col-12">
          <Alert message={message} />
          <div className="pzpeu-table-list inside-card-box">
            <Listing
              table={
                <CPVTable
                  SAOSKeywords={CPVList}
                  onDeleteClick={onDeleteClick}
                  onEditClick={onEditClick}
                  isAdmin={isAdmin}
                />
              }
              loading={fetching}
            />
          </div>
        </div>
      </div>
    </CardBox>
  );

  return isAdmin ? (
    displayBox
  ) : (
    <div className="w-100 row justify-content-center user-details-view position-relative">
      <div className="col-lg-9">{displayBox}</div>
    </div>
  );
};

export default ProceedingsCPVDetailsDisplay;
