import listReducer from 'modules/Office/reducer/list';
import listDotReducer from 'modules/Office/reducer/dotsList';
import { combineReducers, Reducer } from 'redux';
import { OfficeDotState, OfficeState } from 'modules/Office/state';

export const officesReducer = (): Reducer<OfficeState> => {
  const reducers = {
    list: listReducer
  };

  return combineReducers<OfficeState>(reducers);
};

export const officesDotReducer = (): Reducer<OfficeDotState> => {
  const reducers = {
    list: listDotReducer
  };

  return combineReducers<OfficeDotState>(reducers);
};
