import React from 'react';
import InformationList, { InformationField } from 'modules/Layout/component/InformationList';
import { ExecutorEntity } from '../../../../Executor/model/Executor';
import { formatDate, ifContainsDateFormat } from '../../../../Shared/helper/utils';

export interface Props {
  executorExtended: ExecutorEntity;
}

const ExecutorExtendedBasicDataset: React.FC<Props> = ({ executorExtended }) => {
  const fields: InformationField[] = [
    {
      label: 'Numer zlecenia',
      value: executorExtended?.id
    },
    {
      label: 'Imię klienta',
      value: executorExtended?.user_first_name
    },
    {
      label: 'Nazwisko klienta',
      value: executorExtended?.user_last_name
    },
    {
      label: 'Firma klienta',
      value: executorExtended?.name
    },
    {
      label: 'NIP weryfikowanego wykonawcy',
      value: executorExtended?.nip
    },
    {
      label: 'Status wykonania raportu',
      value: executorExtended?.status.name
    },
    {
      label: 'Data zlecenia raportu',
      value: ifContainsDateFormat(executorExtended?.created_at, formatDate)
    },
    {
      label: 'Data ostatniej aktualizacji raportu',
      value: ifContainsDateFormat(executorExtended?.updated_at, formatDate)
    }
  ];

  return <InformationList fields={fields} />;
};

export default ExecutorExtendedBasicDataset;
