import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { FilterEntity } from 'modules/Layout/model/Filters';
import { DashboardUserValues, DashboardAdminValues } from 'modules/Layout/type';

export const API_MODULE_DENIED_EXECUTORS = '/api/executors/counters';
export const API_MODULE_DENIED_CLAUSE = '/api/client/clauses/counters';
export const API_FILTERS_LIST = '/api/saved_filters/:slug';
export const API_FILTERS_SAVE = '/api/saved_filters';
export const API_FILTERS_DELETE = '/api/saved_filters/:id';
export const API_DASHBOARD_CLIENT = '/api/dashboard/client';
export const API_DASHBOARD_ADMIN = '/api/dashboard/admin';

export type ModuleDeniedExecutorResponse = {
  data: {
    executors_count: number;
    applications_count: number;
  };
};

export type ModuleDeniedClauseResponse = {
  data: {
    clauses_count: number;
    judgments_count: number;
  };
};

export const fetchModuleDeniedExecutors = (cancelToken?: any): AxiosPromise<ModuleDeniedExecutorResponse> =>
  axios.get(getAPIUrl(API_MODULE_DENIED_EXECUTORS), { cancelToken });

export const fetchModuleDeniedClause = (cancelToken?: any): AxiosPromise<ModuleDeniedClauseResponse> =>
  axios.get(getAPIUrl(API_MODULE_DENIED_CLAUSE), { cancelToken });

export type FetchFilterListResponse = {
  data: FilterEntity[];
};

export const fetchFilterList = (slug: string, cancelToken?: any): AxiosPromise<FetchFilterListResponse> =>
  axios.get(getAPIUrl(API_FILTERS_LIST, { slug }), { cancelToken });

export const saveFilter = (data: FilterEntity, cancelToken?: any): AxiosPromise<{ data: FilterEntity }> =>
  axios.post(getAPIUrl(API_FILTERS_SAVE), { ...data }, { cancelToken });

export const deleteFilter = (id: number | string, cancelToken?: any): AxiosPromise<FilterEntity> =>
  axios.delete(getAPIUrl(API_FILTERS_DELETE, { id }), { cancelToken });

export const fetchDashboardClientData = (cancelToken?: any): AxiosPromise<{ data: DashboardUserValues }> =>
  axios.get(getAPIUrl(API_DASHBOARD_CLIENT), { cancelToken });

export const fetchDashboardAdminData = (cancelToken?: any): AxiosPromise<{ data: DashboardAdminValues }> =>
  axios.get(getAPIUrl(API_DASHBOARD_ADMIN), { cancelToken });
