/* eslint-disable */
import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function TrashIcon(props: IconProps): JSX.Element {
  const { fill = '#B7332D', height = '40px', className = 'icon-svg-table' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} height={height} viewBox="0 0 13.379 13.623">
      <g id="Group_338" data-name="Group 338" transform="translate(-383.68 -576.992)">
        <path
          id="Path_1269"
          data-name="Path 1269"
          d="M395.474,591.414h-8.459l-.789-10.883h10.037Z"
          transform="translate(-0.874 -1.299)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_20"
          data-name="Line 20"
          x2="12.379"
          transform="translate(384.18 579.232)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1270"
          data-name="Path 1270"
          d="M394.891,577.492h-2.56l-.568,1.74h3.7Z"
          transform="translate(-3.241)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_21"
          data-name="Line 21"
          y2="7.345"
          transform="translate(390.375 580.98)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_22"
          data-name="Line 22"
          x1="0.489"
          y2="7.345"
          transform="translate(392.494 580.98)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_23"
          data-name="Line 23"
          x2="0.489"
          y2="7.345"
          transform="translate(387.768 580.98)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
