import { RootState } from 'app/reducer';
import { refreshActivationAction, SET_AUTH_STATE } from 'modules/Auth/action';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PublicWrapper from 'modules/Layout/component/Wrapper/Public';
import ResendTokenForm from 'modules/Auth/component/ResendToken/Form';
import { View } from 'modules/Auth/component/View';

const ResendToken = () => {
  const dispatch = useDispatch();
  const { busy, message, errors } = useSelector((state: RootState) => state.auth);

  const refreshToken = (email: string) => dispatch(refreshActivationAction(email));

  useEffect(() => {
    if (message) {
      dispatch({
        type: SET_AUTH_STATE,
        payload: { message: null }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PublicWrapper hideHeader hideFooter>
      <View busy={busy} message={message}>
        <span>
          Ponowne wysłanie
          <br />
          tokenu aktywacyjnego.
        </span>
        <ResendTokenForm busy={busy} errors={errors} submit={refreshToken} />
      </View>
    </PublicWrapper>
  );
};

export default ResendToken;
