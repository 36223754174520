import { Routes } from 'app/routes';
import Authorize from 'modules/Auth/container/Authorize';
import FeedbackView from 'modules/Feedback/view';
import ThanksGivingView from 'modules/Feedback/view/ThanksGiving';
import PublicWrapper from 'modules/Layout/component/Wrapper/Public';
import React from 'react';
import { Link, Route } from 'react-router-dom';

export const displayHeaderElement = (
  <div className="ml-auto d-flex align-items-center" style={{ paddingRight: '65px' }}>
    <Link to="/login" className="nav-link d-flex align-items-center btn btn-link header-link">
      Zaloguj się
    </Link>
  </div>
);

export const ROUTE_PUBLIC_FEEDBACK = '/kontakt';
export const ROUTE_PUBLIC_FEEDBACK_AFTER = '/feedback/thanks';
export const ROUTE_FEEDBACK = '/feedback';
export const ROUTE_FEEDBACK_AFTER = '/feedback/after';

export const createFeedbackRoutes = (): Routes => ({
  public: [
    <Route
      key="public-feedback"
      path={ROUTE_PUBLIC_FEEDBACK}
      exact
      render={() => (
        <PublicWrapper displayHeaderElement={displayHeaderElement}>
          <FeedbackView isPublic />
        </PublicWrapper>
      )}
    />,
    <Route
      key="public-feedback"
      path={ROUTE_PUBLIC_FEEDBACK_AFTER}
      exact
      render={() => (
        <PublicWrapper displayHeaderElement={displayHeaderElement}>
          <ThanksGivingView isPublic />
        </PublicWrapper>
      )}
    />
  ],
  wrapped: [
    <Authorize key="feedback">
      <Route path={ROUTE_FEEDBACK} exact component={FeedbackView} />
    </Authorize>,
    <Authorize key="feedback-thanks-giving">
      <Route path={ROUTE_FEEDBACK_AFTER} exact component={ThanksGivingView} />
    </Authorize>
  ]
});
