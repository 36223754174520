import { SET_OFFICE_LIST_PARAMS, OFFICE_LIST, OfficeListAction } from 'modules/Office/action/list';
import { createOffice } from 'modules/Office/model/Office';
import { initOfficeListState, OfficeListState } from 'modules/Office/state/list';

const initState = initOfficeListState();

const reducer = (state: OfficeListState = initState, action: OfficeListAction): OfficeListState => {
  switch (action.type) {
    case SET_OFFICE_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case OFFICE_LIST: {
      const { offices = [], meta } = action.payload;

      return { ...state, fetching: false, offices: offices.map(createOffice), meta };
    }

    default:
      return state;
  }
};

export default reducer;
