import React, { useEffect, useState } from 'react';
import Judgement from 'modules/Judgement/model/Judgement';
import JudgementBasicDetails from 'modules/Judgement/component/Details/Judgement/Basic';
import KeywordListIncluded from 'modules/Judgement/component/Details/Judgement/KeywordListIncluded';
import NavTabs from 'modules/Layout/component/NavTabs';
import ConnectedClauses from 'modules/Judgement/component/Details/Judgement/ConnectedClauses';

type Props = {
  judgement: Judgement;
  setDisplayDeleteModal: () => void;
};

const navElements = ['Dane podstawowe', 'Słowa kluczowe', 'Połączone klauzule'];

const JudgementDisplayDetails: React.FC<Props> = (props) => {
  const { judgement } = props;
  const [nav, setNav] = useState([]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('t');

    switch (tab) {
      case 'clause':
        return setNav(navElements.map((_, index) => index === 2));
      case 'keywords':
        return setNav(navElements.map((_, index) => index === 1));
      default:
        return setNav(navElements.map((_, index) => index === 0));
    }
  }, []);

  return (
    <>
      <div className="details-top-nav mb-3 px-2">
        <NavTabs valueArr={navElements} booleanArr={nav} setBooleanArr={setNav} changeUrlParams />
      </div>
      {nav[0] && (
        <div className="col-12">
          <JudgementBasicDetails {...props} />
        </div>
      )}
      {nav[1] && (
        <div className="col-12">
          <KeywordListIncluded judgment={judgement} />
        </div>
      )}
      {nav[2] && (
        <div className="col-12">
          <ConnectedClauses judgment={judgement} />
        </div>
      )}
    </>
  );
};

export default JudgementDisplayDetails;
