import React from 'react';
import { AgreementStepProps } from 'modules/Agreements/type';
import { Button } from 'reactstrap';

const AgreementStepContractSing = (props: AgreementStepProps): JSX.Element => {
  const { downloadAgreement } = props;
  return (
    <div>
      <p>Pobierz dokument do podpisania</p>
      <div style={{ marginBottom: '1rem' }} className="agreement-preview-action">
        <Button
          onClick={downloadAgreement}
          type="submit"
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light btn btn-primary"
        >
          POBIERZ UMOWĘ
        </Button>
      </div>
      <p>Przejdź do portalu mObywatel w celu podpisania umowy</p>
      <div style={{ marginBottom: '1rem' }} className="agreement-preview-action">
        <a
          rel="noreferrer"
          target="_blank"
          href="https://moj.gov.pl/nforms/signer/upload?xFormsAppName=SIGNER"
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light btn btn-primary"
        >
          mObywatel
        </a>
      </div>
    </div>
  );
};

export default AgreementStepContractSing;
