import { Routes } from 'app/routes';
import Authorize from 'modules/Auth/container/Authorize';
import React from 'react';
import { Route } from 'react-router-dom';
import SubscriptionsView from 'modules/Subscriptions/view';
import SummaryView from 'modules/Subscriptions/view/Summary';
import TraditionalPaymentWaitingListView from 'modules/Subscriptions/view/TraditionalPaymentList';
import PaymentReceivedView from 'modules/Subscriptions/view/PaymentReceived';
import PaymentPublicHandlerView from 'modules/Subscriptions/view/PaymentReceived/PublicHandler';
import { USERS_INDEX } from 'modules/User/permissions';
import TraditionalPaymentView from './view/TraditionalPayment';
import { SUBSCRIPTIONS_MODULE_ACCESS } from '../Auth/container/Guard/permissions';

export const ROUTE_SUBSCRIPTIONS = '/subscriptions';
export const ROUTE_SUBSCRIPTION_SUMMARY = '/subscriptions/summary';
export const ROUTE_SUBSCRIPTION_TRADITIONAL_PAYMENT = '/subscriptions/summary/traditional-payment';
export const ROUTE_TRADITIONAL_PAYMENTS_WAITING = '/traditional-payments-waiting';
export const ROUTE_SUBSCRIPTION_PAYMENT_SUCCESS = '/payment/success';
export const ROUTE_SUBSCRIPTION_PAYMENT_CANCEL = '/payment/cancel';
export const ROUTE_SUBSCRIPTION_PAYMENT = '/payment';

export const createSubscriptionsRoutes = (): Routes => ({
  wrapped: [
    <Authorize
      key="subscriptions"
      permissions={[SUBSCRIPTIONS_MODULE_ACCESS]}
      hasAccess={(user) => user?.hasPermissions([SUBSCRIPTIONS_MODULE_ACCESS])}
    >
      <Route path={ROUTE_SUBSCRIPTIONS} exact component={SubscriptionsView} />
    </Authorize>,
    <Authorize
      key="subscription-selected"
      permissions={[SUBSCRIPTIONS_MODULE_ACCESS]}
      hasAccess={(user) => user?.hasPermissions([SUBSCRIPTIONS_MODULE_ACCESS])}
    >
      <Route path={ROUTE_SUBSCRIPTION_SUMMARY} exact component={SummaryView} />
    </Authorize>,
    <Authorize
      key="subscription-traditional-payment"
      hasAccess={(user) => user?.hasPermissions([SUBSCRIPTIONS_MODULE_ACCESS])}
      permissions={[SUBSCRIPTIONS_MODULE_ACCESS]}
    >
      <Route path={ROUTE_SUBSCRIPTION_TRADITIONAL_PAYMENT} exact component={TraditionalPaymentView} />
    </Authorize>,
    <Authorize key="traditional-payments-waiting" permissions={[USERS_INDEX]}>
      <Route path={ROUTE_TRADITIONAL_PAYMENTS_WAITING} exact component={TraditionalPaymentWaitingListView} />
    </Authorize>,
    <Route key="subscription-payment" path={ROUTE_SUBSCRIPTION_PAYMENT} exact component={PaymentReceivedView} />
  ],
  public: [
    <Route
      key="subscription-payment-success"
      path={ROUTE_SUBSCRIPTION_PAYMENT_SUCCESS}
      exact
      render={() => <PaymentPublicHandlerView isSuccess />}
    />,
    <Route
      key="subscription-payment-cancel"
      path={ROUTE_SUBSCRIPTION_PAYMENT_CANCEL}
      exact
      component={PaymentPublicHandlerView}
    />
  ]
});
