import { RootState } from 'app/reducer';
import _ from 'lodash';
import { setSearchingParamsAction, SetSearchingParamsAction } from 'modules/Shared/action';
import Validation from 'modules/Shared/exception/Validation';
import { createSearchParams } from 'modules/Shared/helper/params';
import { PaginationMeta, PartialSearchingProps } from 'modules/Shared/type';
import { UserEntity } from 'modules/User/model/User';
import { fetchUsers, fetchUsersExecutorsExtended } from 'modules/User/repository';
import { Action, Dispatch } from 'redux';
import { ExecutorEntity } from '../../../Executor/model/Executor';
import { ExecutorExtendedRequest } from '../../../Executor/model/Violation';
import { fetchExecutorExtendedRequestsList } from '../../../Executor/repository';

export const SET_USER_LIST_PARAMS = 'USER/LIST/PARAMS/SET';
export const SET_USER_EXECUTORS_EXTENDED_LIST_PARAMS = 'USER/EXECUTORS/EXTENDED/LIST/PARAMS/SET';
export const SET_USER_EXECUTORS_EXTENDED_REQUESTS_LIST_PARAMS = 'USER/EXECUTORS/EXTENDED/REQUESTS/LIST/PARAMS/SET';
export const USER_LIST = 'USER/LIST';
export const USER_EXECUTORS_EXTENDED_LIST = 'USER/EXECUTORS/EXTENDED/LIST';
export const USER_EXECUTORS_EXTENDED_REQUESTS_LIST = 'USER/EXECUTORS/EXTENDED/REQUESTS/LIST';

export type UserListAction = SetUserListParamsAction | UserListFetchedAction;
export type ExecutorExtendedListAction = SetExecutorsExtendedListParamsAction | ExecutorExtendedListFetchedAction;
export type ExecutorExtendedRequestsListAction =
  | SetExecutorsExtendedRequestsListParamsAction
  | ExecutorExtendedRequestsListFetchedAction;

export type SetUserListParamsAction = SetSearchingParamsAction<typeof SET_USER_LIST_PARAMS>;
export type SetExecutorsExtendedListParamsAction = SetSearchingParamsAction<
  typeof SET_USER_EXECUTORS_EXTENDED_LIST_PARAMS
>;

export type SetExecutorsExtendedRequestsListParamsAction = SetSearchingParamsAction<
  typeof SET_USER_EXECUTORS_EXTENDED_REQUESTS_LIST_PARAMS
>;

export type UserListFetchedPayload = {
  users: UserEntity[];
  meta: PaginationMeta;
};

export type ExecutorExtendedListFetchedPayload = {
  executors: ExecutorEntity[];
  meta: PaginationMeta;
};

export type ExecutorExtendedRequestsListFetchedPayload = {
  executorsExtendedRequests: ExecutorExtendedRequest[];
  meta: PaginationMeta;
};

export interface UserListFetchedAction extends Action<typeof USER_LIST> {
  payload: UserListFetchedPayload;
}
export interface ExecutorExtendedListFetchedAction extends Action<typeof USER_EXECUTORS_EXTENDED_LIST> {
  payload: ExecutorExtendedListFetchedPayload;
}

export interface ExecutorExtendedRequestsListFetchedAction
  extends Action<typeof USER_EXECUTORS_EXTENDED_REQUESTS_LIST> {
  payload: ExecutorExtendedRequestsListFetchedPayload;
}

export const setUserListParamsAction =
  (payload: PartialSearchingProps) => async (dispatch: Dispatch, getState: () => RootState) => {
    const currentState = getState()?.user?.list;

    if (
      payload?.filter &&
      !payload?.pagination &&
      typeof currentState?.pagination?.page === 'number' &&
      currentState.pagination.page !== 1 &&
      !_.isEqual(currentState.filter, payload.filter)
    ) {
      currentState.pagination.page = 1;
    }

    const state = { ...currentState, ...payload };

    dispatch(setSearchingParamsAction(SET_USER_LIST_PARAMS, payload));
    try {
      const { pagination, filter, sort } = state;

      const searchParams = createSearchParams({ pagination, filter, sort });

      const response = await fetchUsers(searchParams);

      const { data, meta } = response.data;

      dispatch<UserListFetchedAction>({
        type: USER_LIST,
        payload: { users: data, meta }
      });
    } catch (error) {
      if (error instanceof Validation) {
        dispatch<UserListFetchedAction>({
          type: USER_LIST,
          payload: { users: [], meta: null }
        });
      } else {
        throw error;
      }
    }
  };

export const setExecutorExtendedListParamsAction =
  (payload: PartialSearchingProps) => async (dispatch: Dispatch, getState: () => RootState) => {
    const currentState = getState()?.user?.executorsExtendedList;

    if (
      payload?.filter &&
      !payload?.pagination &&
      typeof currentState?.pagination?.page === 'number' &&
      currentState.pagination.page !== 1 &&
      !_.isEqual(currentState.filter, payload.filter)
    ) {
      currentState.pagination.page = 1;
    }

    const state = { ...currentState, ...payload };

    dispatch(setSearchingParamsAction(SET_USER_EXECUTORS_EXTENDED_LIST_PARAMS, payload));
    try {
      const { pagination, filter, sort } = state;

      const searchParams = createSearchParams({ pagination, filter, sort });

      const response = await fetchUsersExecutorsExtended(searchParams);

      const { data, meta } = response.data;

      dispatch<ExecutorExtendedListFetchedAction>({
        type: USER_EXECUTORS_EXTENDED_LIST,
        payload: { executors: data, meta }
      });
    } catch (error) {
      if (error instanceof Validation) {
        dispatch<ExecutorExtendedListFetchedAction>({
          type: USER_EXECUTORS_EXTENDED_LIST,
          payload: { executors: [], meta: null }
        });
      } else {
        throw error;
      }
    }
  };

export const setExecutorExtendedRequestsListParamsAction =
  (payload: PartialSearchingProps, claim_id: number | string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const currentState = getState()?.user?.executorsExtendedRequestsList;

    if (
      payload?.filter &&
      !payload?.pagination &&
      typeof currentState?.pagination?.page === 'number' &&
      currentState.pagination.page !== 1 &&
      !_.isEqual(currentState.filter, payload.filter)
    ) {
      currentState.pagination.page = 1;
    }

    const state = { ...currentState, ...payload };

    dispatch(setSearchingParamsAction(SET_USER_EXECUTORS_EXTENDED_REQUESTS_LIST_PARAMS, payload));
    try {
      const { pagination, filter, sort } = state;

      const searchParams = createSearchParams({ pagination, filter, sort });

      const response = await fetchExecutorExtendedRequestsList(claim_id, searchParams);

      const { data, meta } = response.data;

      dispatch<ExecutorExtendedRequestsListFetchedAction>({
        type: USER_EXECUTORS_EXTENDED_REQUESTS_LIST,
        payload: { executorsExtendedRequests: data, meta }
      });
    } catch (error) {
      if (error instanceof Validation) {
        dispatch<ExecutorExtendedRequestsListFetchedAction>({
          type: USER_EXECUTORS_EXTENDED_REQUESTS_LIST,
          payload: { executorsExtendedRequests: [], meta: null }
        });
      } else {
        throw error;
      }
    }
  };
