import { ValidationErrors } from 'modules/Shared/type';
import React, { useState } from 'react';
import { Form as FormStrap } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  BigRiggingItem,
  CommonDataItem,
  ComplaintDataItem,
  MisrepresentationDataItem,
  OrderEntity,
  PenaltyDataItem
} from 'modules/ExecutorOrder/model/Order';
import OrderFieldset from 'modules/ExecutorOrder/component/Fieldset';
import { serialize } from 'object-to-formdata';
import TooltipWithBtn from 'modules/Layout/component/Tooltip/WithButton';
import { DATE_FORMAT_SLASH, DATE_FORMAT_VALUE } from 'modules/Shared/helper/utils';
import moment from 'moment';
import { IFile } from '../../../Shared/helper/Files/AddFiles';

export interface Props {
  errors?: ValidationErrors;
  disabled?: boolean;
  officeData?: OrderEntity;
  submit: (data: OrderEntity) => void;
  cancelRedirect: string;
  isEditForm?: boolean;
}

const Form: React.FC<Props> = ({ errors, disabled, submit, officeData, cancelRedirect, isEditForm }) => {
  type ViolationDataItem =
    | PenaltyDataItem
    | CommonDataItem
    | ComplaintDataItem
    | MisrepresentationDataItem
    | BigRiggingItem;

  const [values, setValues] = useState({
    name: officeData?.name ?? '',
    identifier: officeData?.identifier ?? '',
    principal_name: officeData?.principal_name ?? '',
    contract_number: officeData?.contract_number ?? '',
    contract_place: officeData?.contract_place ?? '',
    contract_value: officeData?.contract_value ?? '',
    contract_date: officeData?.contract_date ?? '',
    notice_number: officeData?.notice_number ?? '',
    penalties_checked: officeData?.penalties_checked ?? false,
    penalties: officeData?.penalties ?? [],
    termination_checked: officeData?.termination_checked ?? false,
    termination: officeData?.termination ?? [],
    complaint_checked: officeData?.complaint_checked ?? false,
    complaint: officeData?.complaint ?? [],
    misrepresentation_checked: officeData?.misrepresentation_checked ?? false,
    misrepresentation: officeData?.misrepresentation ?? [],
    bid_rigging_checked: officeData?.bid_rigging_checked ?? false,
    bid_rigging: officeData?.bid_rigging ?? [],
    unfair_competition_checked: officeData?.unfair_competition_checked ?? false,
    unfair_competition: officeData?.unfair_competition ?? []
  });

  const formatViolationDate = (date: string | undefined, toFormat: string): string | null => {
    if (!date) return null;
    const momentObj = moment(date, toFormat, true);
    return momentObj.isValid()
      ? momentObj.format(toFormat === DATE_FORMAT_VALUE ? DATE_FORMAT_SLASH : DATE_FORMAT_VALUE)
      : null;
  };

  const initializeValues = <T extends ViolationDataItem>(
    items: T[],
    formatFn: (date: string | undefined, toFormat: string) => string | null
  ): T[] => items.map((item) => ({ ...item, violation_date: formatFn(item.violation_date, DATE_FORMAT_VALUE) }));

  const [penaltiesValues, setPenaltiesValues] = useState(
    initializeValues(officeData?.penalties ?? [], formatViolationDate)
  );
  const [terminationValues, setTerminationValues] = useState(
    initializeValues(officeData?.termination ?? [], formatViolationDate)
  );
  const [complaintValues, setComplaintValues] = useState(
    initializeValues(officeData?.complaint ?? [], formatViolationDate)
  );
  const [misrepresentationValues, setMisrepresentationValues] = useState(
    initializeValues(officeData?.misrepresentation ?? [], formatViolationDate)
  );
  const [bidRiggingValues, setBidRiggingValues] = useState(
    initializeValues(officeData?.bid_rigging ?? [], formatViolationDate)
  );
  const [unfairCompetitionValues, setUnfairCompetitionValues] = useState(
    initializeValues(officeData?.unfair_competition ?? [], formatViolationDate)
  );

  const processCheckedValues = <T extends ViolationDataItem>(
    checked: boolean,
    value: T[],
    formatFn: (date: string | undefined, toFormat: string) => string | null
  ): T[] =>
    checked ? value.map((item) => ({ ...item, violation_date: formatFn(item.violation_date, DATE_FORMAT_SLASH) })) : [];

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const submitData = { ...values } as any;
    submitData.penalties = processCheckedValues(values.penalties_checked, penaltiesValues, formatViolationDate);
    submitData.termination = processCheckedValues(values.termination_checked, terminationValues, formatViolationDate);
    submitData.complaint = processCheckedValues(values.complaint_checked, complaintValues, formatViolationDate);
    submitData.misrepresentation = processCheckedValues(
      values.misrepresentation_checked,
      misrepresentationValues,
      formatViolationDate
    );
    submitData.bid_rigging = processCheckedValues(values.bid_rigging_checked, bidRiggingValues, formatViolationDate);
    submitData.unfair_competition = processCheckedValues(
      values.unfair_competition_checked,
      unfairCompetitionValues,
      formatViolationDate
    );

    if (isEditForm) {
      submit(submitData);
    } else {
      const dataWithFiles = {
        ...submitData,
        penalties:
          submitData.penalties.length > 0
            ? submitData.penalties.map((penalty: PenaltyDataItem) => ({
                ...penalty,
                files: penalty.files?.map((fileItem: IFile) => fileItem?.file)
              }))
            : [],
        complaint:
          submitData.complaint.length > 0
            ? submitData.complaint.map((complaintItem: ComplaintDataItem) => ({
                ...complaintItem,
                files: complaintItem.files?.map((fileItem: IFile) => fileItem?.file)
              }))
            : [],
        termination:
          submitData.termination.length > 0
            ? submitData.termination.map((terminationItem: CommonDataItem) => ({
                ...terminationItem,
                files: terminationItem.files?.map((fileItem: IFile) => fileItem?.file)
              }))
            : [],
        misrepresentation:
          submitData.misrepresentation.length > 0
            ? submitData.misrepresentation.map((misrepresentationItem: MisrepresentationDataItem) => ({
                ...misrepresentationItem,
                files: misrepresentationItem.files?.map((fileItem: IFile) => fileItem?.file)
              }))
            : [],
        bid_rigging:
          submitData.bid_rigging.length > 0
            ? submitData.bid_rigging.map((bidRiggingItem: BigRiggingItem) => ({
                ...bidRiggingItem,
                files: bidRiggingItem.files?.map((fileItem: IFile) => fileItem?.file)
              }))
            : [],
        unfair_competition:
          submitData.unfair_competition.length > 0
            ? submitData.unfair_competition.map((unfairCompetitionItem: ComplaintDataItem) => ({
                ...unfairCompetitionItem,
                files: unfairCompetitionItem.files?.map((fileItem: IFile) => fileItem?.file)
              }))
            : []
      };
      const data = serialize(dataWithFiles, {
        indices: true,
        booleansAsIntegers: true,
        nullsAsUndefineds: false,
        allowEmptyArrays: false
      });
      submit(data as any);
    }
  };

  const hasValue = () => !Object.values(values).some((v) => v);
  return (
    <FormStrap onSubmit={onSubmit}>
      <OrderFieldset
        disabled={disabled}
        errors={errors}
        values={values}
        penaltiesValues={penaltiesValues}
        terminationValues={terminationValues}
        complaintValues={complaintValues}
        misrepresentationValues={misrepresentationValues}
        bidRiggingValues={bidRiggingValues}
        unfairCompetitionValues={unfairCompetitionValues}
        setPenaltiesValues={setPenaltiesValues}
        setTerminationValues={setTerminationValues}
        setComplaintValues={setComplaintValues}
        setMisrepresentationValues={setMisrepresentationValues}
        setBidRiggingValues={setBidRiggingValues}
        setUnfairCompetitionValues={setUnfairCompetitionValues}
        setValues={setValues}
        isEditForm={isEditForm}
      />

      <div className="d-flex justify-content-center">
        <div className="form-actions pzpeu-btn-flex">
          <TooltipWithBtn
            targetId="ExecutorOrderFormBtn"
            btnContent="Zapisz"
            tooltipContent='Wypełnij pole "Nazwa zamawiającego"'
            className="pzpeu-btn-disabled"
            wrapperClassName="pzpeu-btn-flex"
            disabled={hasValue() || disabled}
          />
          <Link to={cancelRedirect} className="btn cancel pzpeu-btn-flex waves-effect waves-light">
            Anuluj
          </Link>
        </div>
      </div>
    </FormStrap>
  );
};

export default Form;

export type IOrderFormValues = {
  name: string;
  identifier: string;
  principal_name: string;
  contract_number: string;
  contract_place: string;
  contract_value: string | number;
  contract_date: string;
  notice_number: string;
  penalties_checked: boolean;
  penalties: PenaltyDataItem[];
  termination_checked: boolean;
  termination: CommonDataItem[];
  complaint_checked: boolean;
  complaint: ComplaintDataItem[];
  misrepresentation_checked: boolean;
  misrepresentation: MisrepresentationDataItem[];
  bid_rigging_checked: boolean;
  bid_rigging: BigRiggingItem[];
  unfair_competition_checked: boolean;
  unfair_competition: ComplaintDataItem[];
};
