import Action, { ActionProps } from 'modules/Layout/component/Action/index';
import React from 'react';
import TrashIcon from 'modules/Layout/component/Icon/Trash';
import { ColorVariants } from 'modules/Shared/type';

const ActionDelete: React.FC<ActionProps> = ({
  label = <TrashIcon height="20px" />,
  color = ColorVariants.Primary,
  ...props
}: ActionProps): JSX.Element => {
  return (
    <Action
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      label={label}
      color={color}
    />
  );
};

export default ActionDelete;
