import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps } from 'modules/Shared/type';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterInputType } from 'modules/Layout/type';
import { setKeywordsWithCategoriesListParamsAction } from 'modules/Judgement/action/KeywordsWithCategoriesList';
import JudgmentFilterCategoryAutocomplete from 'modules/Judgement/container/JudgementList/Filter/customFields/FilterCategoriesAutocomplete';

const KeywordsWithCategoriesListFilter: React.FC = () => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.judgement.keywordList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setKeywordsWithCategoriesListParamsAction(payload));

  const staticInputs: FilterInputType[] = [
    {
      type: 'text',
      property: 'name',
      label: 'Nazwa',
      value: _get(filter, 'name')
    }
  ];

  return (
    <ListingFilter
      inputs={staticInputs}
      filter={filter}
      pagination={pagination}
      setParams={setParams}
      customInput={<JudgmentFilterCategoryAutocomplete reduxAction={setKeywordsWithCategoriesListParamsAction} />}
    />
  );
};

export default KeywordsWithCategoriesListFilter;
