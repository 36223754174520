import _ from 'lodash';
import { Message, ValidationErrors } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { useState } from 'react';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import JudgementCategory, { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { FetchJudgementCategory } from 'modules/Judgement/repository';
import { addToastAction, AddToastPayload } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import { AxiosPromise } from 'axios';

const useCategoryUpdateModal = (
  updateCategory: (id: number, data: { name: string }, cancelToken?: any) => AxiosPromise<FetchJudgementCategory>,
  toastFnc: () => AddToastPayload,
  onSuccess?: (data?: JudgementElementEntity) => void,
  cancelLoadingOnSuccess?: boolean
) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [displayEditModal, setDisplayEditModal] = useState<JudgementCategory>(null);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);

  const cancelToken = useCancelToken();
  const dispatchUpdateCategoryToast = () => dispatch(addToastAction(toastFnc()));

  const updateCategoryAction = async (data: { name: string }) => {
    setLoading(true);

    const { id } = displayEditModal;

    const {
      cancelled,
      data: resData,
      message: messageRequest,
      error
    } = await updateRequestWithData(updateCategory, id, data, cancelToken);

    if (cancelled) return;
    if (resData?.data) {
      dispatchUpdateCategoryToast();

      if (cancelLoadingOnSuccess) setLoading(false);
      if (onSuccess) onSuccess(resData.data);
    }
    if (error) setErrors(error);
    if (messageRequest) setMessage(messageRequest);
    setLoading(false);
  };

  const clearErrors = () => {
    setErrors(null);
    setMessage(null);
  };

  return {
    updateCategoryAction,
    loading,
    displayEditModal,
    setDisplayEditModal,
    message,
    errors,
    clearErrors
  };
};

export default useCategoryUpdateModal;
