import listReducer from 'modules/ExecutorOrder/reducer/list';
import { combineReducers, Reducer } from 'redux';
import { OrderState } from 'modules/ExecutorOrder/state';

const reducer = (): Reducer<OrderState> => {
  const reducers = {
    list: listReducer
  };

  return combineReducers<OrderState>(reducers);
};

export default reducer;
