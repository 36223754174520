import { suffixLabel } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React, { useEffect, useState } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input';

type ErrorTypes = {
  errors?: ValidationErrors;
  errorName?: string;
};

type InputTypes = {
  type?: InputType;
  required?: boolean;
};

const initInput: InputTypes = {
  type: 'text',
  required: false
};

const useBasicInput = (label: string, errorObj: ErrorTypes, initValue?: string, inputTypes = initInput) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (initValue) {
      setValue(initValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { type, required } = inputTypes;
  const { errors, errorName } = errorObj;

  const inputJsx = () => (
    <>
      <FormGroup>
        <Label for={`input-${label}`}>{suffixLabel(label, required)}</Label>
        <Input
          type={type}
          id={`input-${label}`}
          value={value}
          onChange={(event) => setValue(event.target.value)}
          required={required}
          invalid={hasError(errors, errorName)}
        />
        {hasError(errors, errorName) && <FormFeedback>{getError(errors, errorName)}</FormFeedback>}
      </FormGroup>
    </>
  );

  return {
    value,
    inputJsx
  };
};

export default useBasicInput;
