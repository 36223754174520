import { RootState } from 'app/reducer';
import { push } from 'connected-react-router';
import { authenticateAction } from 'modules/Auth/action';
import { ROUTE_LOGIN } from 'modules/Auth/routes';
import { hasToken } from 'modules/Auth/service';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applyAccessibility } from 'modules/Layout/helper/accessibility';
import { useLocation } from 'react-router-dom';

const Guard: React.FC<Record<string, unknown>> = ({ children }) => {
  const dispatch = useDispatch();
  const authenticated = useSelector((state: RootState) => state.auth);
  const isToken = hasToken();

  const location = useLocation();

  useEffect(() => {
    applyAccessibility();
    setTimeout(applyAccessibility, 2000);
  }, [location]);

  useEffect(() => {
    if (isToken) {
      dispatch(authenticateAction());
    } else {
      dispatch(push(ROUTE_LOGIN));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (authenticated) {
    return <>{children}</>;
  }

  return null;
};

export default Guard;
