import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { suffixLabel } from 'modules/Layout/helper/misc';
import { FetchEmailRequest } from 'modules/Office/type';
import useWysiwyg from '../../../Layout/component/Wysiwyg';
import useManageFileAttachments from './manageFileAttachmentsHook';
import Accordion from '../../../Layout/component/Accordion';

export interface Props {
  values: FetchEmailRequest;
  setValues: React.Dispatch<React.SetStateAction<FetchEmailRequest[]>>;
  required?: boolean;
  submited?: boolean;
  errors?: ValidationErrors;
}

const Form: React.FC<Props> = (props): JSX.Element => {
  const { values, setValues, required, errors, submited } = props;
  const { subject } = values;

  const { jsxAttachmentsDropbox } = useManageFileAttachments({ values, setValues, submited });

  const { displayWysiwyg } = useWysiwyg({
    title: 'Treść',
    content: values?.content,
    tooltip: { specialSign: true },
    onChange: (content) =>
      setValues((prevState) => prevState.map((item) => (item.group === values.group ? { ...item, content } : item)))
  });

  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSubject = event.target.value;
    setValues((prevState) =>
      prevState.map((item) => (item.group === values.group ? { ...item, subject: newSubject } : item))
    );
  };

  return (
    <Accordion
      entity={{
        content: (
          <fieldset className="m-0 position-relative">
            <FormGroup className="form-group-large-margins">
              <Label for="subject">{suffixLabel('Tytuł', required)}</Label>
              <Input
                type="text"
                id="subject"
                value={subject}
                onChange={handleSubjectChange}
                invalid={hasError(errors, 'subject')}
                required={required}
              />
              {hasError(errors, 'subject') && <FormFeedback>{getError(errors, 'subject')}</FormFeedback>}
            </FormGroup>
            <FormGroup className="form-group-last">
              {displayWysiwyg && displayWysiwyg()}

              {hasError(errors, 'content') && (
                <FormFeedback className="d-block">{getError(errors, 'content')}</FormFeedback>
              )}
            </FormGroup>
            {jsxAttachmentsDropbox}
          </fieldset>
        ),
        title: values.group_name
      }}
    />
  );
};

export default Form;
