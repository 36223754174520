import axios, { CancelTokenSource } from 'axios';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { fetchClauseCategories } from 'modules/Clause/repository';
import { fetchJudgementCategoryToastError } from 'modules/Judgement/toasts';
import { addToastAction } from 'modules/Layout/action';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { createSearchParams } from 'modules/Shared/helper/params';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import renameDuplicateEntries from 'modules/Shared/helper/renameDuplicateEntries';

type Props = {
  category: { name: string; id: number };
  onCategoryChange: (categoryValue: { name: string; id: number }) => void;
  required?: boolean;
};

// eslint-disable-next-line react/display-name
const ClauseCategoryAutocomplete: React.FC<Props> = React.memo(({ category, onCategoryChange, required }) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [categoryList, setCategoryList] = useState<JudgementElementEntity[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const categoriesListWithoutDuplicates = React.useMemo(
    () => renameDuplicateEntries(categoryList),
    [categoryList.length]
  );

  const dispatchClauseCategoryToastError = (messageValue: string) =>
    dispatch(addToastAction(fetchJudgementCategoryToastError(messageValue)));

  const cancelToken: React.MutableRefObject<CancelTokenSource> = useRef(null);

  const fetchCategoryList = async (name?: string) => {
    if (cancelToken.current) {
      cancelToken.current.cancel('Operation canceled due to new request.');
    }
    cancelToken.current = axios.CancelToken.source();

    const filter = name ? { name } : {};
    const searchParams = createSearchParams({ pagination: { per_page: 9999 }, filter });

    setFetching(true);

    const { data, cancelled, message } = await fetchRequest(
      fetchClauseCategories,
      searchParams,
      cancelToken.current.token
    );

    if (cancelled) return;
    if (data) {
      setCategoryList(data.data);
      setFetching(false);
    }
    if (message && message.value !== 'Operation canceled due to new request.') {
      if (fetching) setFetching(false);
      dispatchClauseCategoryToastError(message.value);
    }
  };

  useEffect(() => {
    return () => {
      if (cancelToken.current) {
        cancelToken.current.cancel('AxiosCancel');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeValue = (name: string) => {
    onCategoryChange({ name, id: categoriesListWithoutDuplicates.find((value) => value.name === name)?.id ?? null });
  };

  const clearList = () => {
    if (cancelToken.current) {
      cancelToken.current.cancel('Operation canceled due to new request.');
    }
  };

  return (
    <Autocomplete
      currentValue={category.name}
      onChangeCurrentValue={onChangeValue}
      searchRequest={fetchCategoryList}
      fetching={fetching}
      clearList={clearList}
      list={categoriesListWithoutDuplicates as never}
      required={required}
      label="Kategoria"
    />
  );
});

export default ClauseCategoryAutocomplete;
