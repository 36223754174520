import { createSubscription } from 'modules/Subscriptions/model/Subscription';
import { initSubscriptionState, SubscriptionsListState } from 'modules/Subscriptions/state/list';
import { SUBSCRIPTIONS_FETCHING, SUBSCRIPTIONS_FETCHED, SubscriptionsAction } from 'modules/Subscriptions/action';

const initState = initSubscriptionState();

const reducer = (state: SubscriptionsListState = initState, action: SubscriptionsAction): SubscriptionsListState => {
  switch (action.type) {
    case SUBSCRIPTIONS_FETCHING: {
      return { ...state, fetching: true };
    }

    case SUBSCRIPTIONS_FETCHED: {
      const { subscriptions = [], errorMessage = null } = action.payload;
      return { ...state, fetching: false, subscriptions: subscriptions.map(createSubscription), errorMessage };
    }

    default:
      return state;
  }
};

export default reducer;
