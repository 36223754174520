import React, { ReactNode } from 'react';

export const suffixValue = (value: string, condition = false, suffix = '*'): string =>
  condition ? `${value}${suffix}` : value;

export const suffixLabel = (value: string, condition = false, suffix = '*'): ReactNode =>
  condition ? (
    <>
      {value}
      <span className="text-primary suffix-label">{suffix}</span>
    </>
  ) : (
    value
  );
