import React from 'react';
import { TableCol } from 'modules/Layout/component/Table';
import { ModuleAccessHistoryAdminChange, ModuleAccessHistoryCredit } from 'modules/Module/model/ModuleAccessHistory';
import { Link } from 'react-router-dom';
import Authorize from 'modules/Auth/container/Authorize';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_USER_PAYMENT_LOG } from 'modules/User/routes';
import { ROUTE_PROFILE_PAYMENT_LOG } from 'modules/Profile/routes';
import { ROLE_ADMIN } from 'modules/User/model/UserRole';
import User from 'modules/User/model/User';
import { ROUTE_ACCESS_HISTORY } from 'modules/Module/routes';
import { formatDate, formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';

//    const displayTransactionLink = (transaction: string) => <Link to="#">{transaction}</Link>;

const adminChangesDescription = (admin_change: ModuleAccessHistoryAdminChange) => {
  const { created_at, credits, date_to, is_first_change } = admin_change;
  let description = '';
  let creditGrammar = '';

  if (credits === 1) creditGrammar = 'kredyt';
  else if (credits > 1 && credits < 5) creditGrammar = 'kredyty';
  else creditGrammar = 'kredytów';

  const createdAtArray = created_at?.split(' ');
  const formatedDate = createdAtArray?.[0] ? formatDate(createdAtArray[0]) : '-';

  if (created_at) description = `W dniu ${formatedDate} o godzinie ${createdAtArray?.[1]}`;
  if (credits !== null)
    description += `${credits !== null ? ', ' : ''}${
      is_first_change ? 'ustawiono' : 'zmieniono'
    } ilość na ${credits} ${creditGrammar}${!date_to ? '.' : ''}`;
  if (date_to)
    description += `${created_at || credits ? ', ' : ''}${
      is_first_change ? 'ustawiono' : 'zmieniono'
    } ważność dostępu do ${formatDate(date_to)}.`;

  return description;
};

const sharedCols = (user: User, user_id?: string, isCredit = false) => {
  let cols: TableCol<ModuleAccessHistoryCredit>[] = [
    {
      property: 'bought_at',
      label: 'Wykupiono dnia',
      value: (row) => ifContainsDateFormat(row?.bought_at, formatDatetimeSeconds)
    },
    {
      property: 'date_to',
      label: 'Data ważności',
      value: (row) => ifContainsDateFormat(row?.date_to, formatDate)
    },
    {
      property: 'original_date_to',
      label: 'Data ważności oryginalnie',
      value: (row) => ifContainsDateFormat(row?.original_date_to, formatDate)
    }
  ];

  if (isCredit) {
    cols = [
      ...cols,
      {
        property: 'credits',
        label: 'Aktualna ilość kredytów'
      },
      {
        property: 'original_credits',
        label: 'Ilość kredytów oryginalnie'
      }
    ];
  }

  const redirectLink = (transaction: string | number) => {
    const url =
      user?.role?.slug === ROLE_ADMIN
        ? {
            pathname: getPathUrl(ROUTE_USER_PAYMENT_LOG, { user_id, payment_id: transaction }),
            state: {
              previousLocation: getPathUrl(ROUTE_ACCESS_HISTORY, { id: user_id })
            }
          }
        : {
            pathname: getPathUrl(ROUTE_PROFILE_PAYMENT_LOG, { id: transaction }),
            state: {
              previousLocation: getPathUrl(ROUTE_ACCESS_HISTORY, { id: user_id })
            }
          };

    return (
      <Authorize fallback={transaction}>
        <Link to={url}>{transaction}</Link>
      </Authorize>
    );
  };

  cols = [
    ...cols,
    {
      property: 'transactions',
      label: 'Powiązane transakcje',
      value: (row) =>
        row.transactions?.map((transaction, index, arr) =>
          arr.length - 1 === index ? redirectLink(transaction) : <>{redirectLink(transaction)}, </>
        )
    },
    {
      property: 'admin_changes',
      label: 'Wprowadzone zmiany przez administratora',
      value: (row) =>
        // eslint-disable-next-line react/no-array-index-key
        row.admin_changes.map((admin_change, index) => <div key={index}>{adminChangesDescription(admin_change)}</div>)
    }
  ];

  return cols;
};

export default sharedCols;
