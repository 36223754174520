import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_PROCEEDINGS_PUBLIC_OPEN } from 'modules/Proceedings/routes';
import './index.scss';

export const landingPageExecutorsContent = {
  title: 'Baza wykonawców w stosunku do których mogą wystąpić podstawy do wykluczenia',
  content: (
    <div>
      <div className="accordion-content-title">
        Prawo zamówień publicznych wprowadza możliwość wykluczania z postępowania wykonawców:
      </div>
      <ul className="items">
        <li>którzy w związku z realizacją umów o udzielenie zamówienia otrzymali kary umowne</li>
        <li>z którymi rozwiązano umowę</li>
        <li>których pozwano lub </li>
        <li>którzy zostali wykluczeni z postępowania w związku z wprowadzeniem zamawiającego w błąd</li>
      </ul>
      <div className="accordion-content-mid-text">
        Taką właśnie bazę dotyczącą wykonawców biorących udział w postępowaniu tworzymy w ramach Asystenta Postępowania.
      </div>
      <div className="accordion-content-title">Jak to działa w Asystencie Postępowania?</div>
      <ul className="items">
        <li>
          przede wszystkim do kilkunastu tysięcy jednostek publicznych w Polsce wysyłamy wnioski o udostępnienie tego
          typu informacji
        </li>
        <li>na podstawie otrzymanych informacji tworzymy bazę wykonawców</li>
        <li>
          jeżeli nasz klient (np. oceniając oferty wykonawców w postępowaniu, czy weryfikując oferty konkurencji)
          chciałby sprawdzić konkretnego przedsiębiorcę, czy w toku realizacji zamówień otrzymał jakieś kary umowne, czy
          być może rozwiązano z nim umowę lub pozwano za nienależytą realizację umowy, czy może ten wykonawca wprowadził
          jakiegoś zamawiającego w błąd - może takie informacje uzyskać w naszej bazie
        </li>
        <li>
          dostęp do informacji możliwy jest w aplikacji mobilnej, na naszej stronie internetowej lub w postaci raportu –
          wszystko w łatwy i przystępny sposób
        </li>
        <li>baza jest uaktualniana o nowe informacje każdego dnia</li>
        <li>
          dzięki uzyskanym informacjom nasz klient może dokonać pogłębionej oceny wykonawców biorących udział w
          postępowaniach
        </li>
      </ul>
    </div>
  )
};

export const landingPageClauseContent = {
  title: 'Rejestr klauzul niedozwolonych ',
  content: (
    <div>
      Jedną z istotniejszych zmian, w zakresie realizacji zamówień publicznych, wprowadzonych na gruncie nowego prawa
      zamówień publicznych, odgrywają tzw.{' '}
      <span className="accordion-content-title">klauzule niedozwolone (abuzywne)</span>. Klauzule abuzywne w umowach
      (również zawieranych w ramach zamówień publicznych) to postanowienia umowne kształtujące obowiązki wykonawcy w
      sposób rażąco nieproporcjonalny do rodzaju zamówienia oraz ryzyk związanych z jego realizacją i z tego tytułu
      niedopuszczalne.
      <div className="accordion-content-mid-text">
        Zgodnie z art. 433 p.z.p., projektowane postanowienia umowy nie mogą przewidywać:
      </div>
      <ul className="items">
        <li>
          odpowiedzialności wykonawcy za opóźnienie, chyba że jest to uzasadnione okolicznościami lub zakresem
          zamówienia;
        </li>
        <li>
          naliczania kar umownych za zachowanie wykonawcy niezwiązane bezpośrednio lub pośrednio z przedmiotem umowy lub
          jej prawidłowym wykonaniem;
        </li>
        <li>odpowiedzialności wykonawcy za okoliczności, za które wyłączną odpowiedzialność ponosi zamawiający;</li>
        <li>
          możliwości ograniczenia zakresu zamówienia przez zamawiającego bez wskazania minimalnej wartości lub wielkości
          świadczenia stron.
        </li>
      </ul>
      <div className="accordion-content-mid-text accordion-content-title">
        Dzięki rejestrowi klauzul niedozwolonych użytkownicy otrzymują informacje o tym, jakiego rodzaju postanowienia
        mogą zostać uznane za niedozwolone, jakich postanowień unikać, jakie elementy umowy mogą stwarzać zagrożenie dla
        zamawiającego.
      </div>
      <div className="accordion-content-mid-text">
        Dostęp do informacji możliwy jest w aplikacji mobilnej, na stronie internetowej lub w postaci raportu
        wygenerowanego z systemu (w formie PDF). Baza jest aktualizowana każdego dnia.
      </div>
      <div className="accordion-content-mid-text">
        Przygotuj prawidłową umowę o udzielenie zamówienia z rejestrem klauzul niedozwolonych Asystenta Postępowania!
      </div>
    </div>
  )
};

export const landingPageProceedingsContent = {
  title: 'Wyszukiwarka postępowań o udzielenie zamówienia z możliwością zadawania pytań zamawiającym',
  content: (
    <div>
      Asystent Postępowania to kompleksowy program, który dostarcza zamawiającym i wykonawcom wszelkich potrzebnych
      informacji i narzędzi w prowadzeniu postępowań i udzielaniu zamówień.
      <div className="accordion-content-mid-text">
        Dzięki modułowi „<span className="accordion-content-title">Wyszukiwarka postępowań</span>” wykonawcy mogą
        wyszukać postępowania przygotowane i opublikowane za pomocą platformy do komunikacji działającej w ramach
        Asystenta Postępowania, a także opublikowane w Biuletynie Zamówień Publicznych.
      </div>
      <div className="accordion-content-mid-text">
        Wyszukiwarka pozwoli także na wyszukiwanie postępowania, gdzie zastosowano klauzule społeczne.
      </div>
      <div className="accordion-content-mid-text">
        Unikatową funkcjonalnością wyszukiwarki będzie możliwość przygotowania i wysłania bezpośrednio do zamawiającego
        pytania / wniosku o zmianę treści Specyfikacji Warunków Zamówienia na podstawie automatycznie pozyskanych przez
        aplikację danych.
      </div>
      <div className="accordion-content-mid-text">
        Użytkownik może skorzystać z przygotowanego przez nasz zespół „Wniosku o zmianę treści SWZ”. Nasza wyszukiwarka
        wskazywać będzie użytkownik na termin, w którym może wysłać wniosek zgodnie z wymaganiami ustawy prawo zamówień
        publicznych.
      </div>
      <div className="accordion-content-title">
        Jakie korzyści daje praca z wyszukiwarką postępowań w ramach Asystenta Postępowania:
      </div>
      <ul className="items">
        <li>intuicyjne filtry do wyszukiwania postępowań</li>
        <li>możliwość wyszukiwania postępowań, gdzie występują klauzule społeczne</li>
        <li>możliwość wysyłania wniosku o zmianę treści SWZ za pomocą przygotowanego przez nasz zespół formularza</li>
        <li>określenie terminu, w którym można w danym postępowaniu wysyłać wniosku o zmianę treści SWZ</li>
      </ul>
      <div className="accordion-content-mid-text accordion-content-title">
        Wyszukiwarka postępowań jest usługą bezpłatną!
      </div>
      <div className="d-flex justify-content-center links">
        <Link to={ROUTE_PROCEEDINGS_PUBLIC_OPEN} className="btn btn-dark waves-effect waves-light">
          Przejdź do wyszukiwarki
        </Link>
      </div>
    </div>
  )
};

export const landingPageAgreementsContent = {
  title: 'Kreator umów',
  content: (
    <div>
      <div className="accordion-content-title">
        Kreator umów to nowa funkcjonalność Asystenta Postępowania, która umożliwia przygotowanie (a nie wygenerowanie!)
        kompletnej:
      </div>
      <ul className="items" style={{ listStyle: 'none', paddingLeft: '20px' }}>
        <li>
          <span className="mr-2">&#10003;</span>umowy o roboty budowlane
        </li>
        <li>
          <span className="mr-2">&#10003;</span>umowy dostawy
        </li>
        <li>
          <span className="mr-2">&#10003;</span>umowy o świadczenie usług
        </li>
      </ul>
      <div className="accordion-content-mid-text">Wszystkie umowy mogą być dostosowane do wymogów p.z.p.!</div>
      <div className="accordion-content-mid-text">
        Dla prostych zadań przygotować można również formularz zamówienia.
      </div>
      <div className="accordion-content-title">Jak działa kreator umów i jakie daje korzyści:</div>
      <ul className="items">
        <li>
          system zawsze podpowiada, jakie elementy muszą znaleźć się w projektach umów o udzielenie zamówienia i
          jednocześnie proponuje ich treść
        </li>
        <li>
          użytkownik sam wybiera, nad którymi elementami umowy chce pracować (np. wynagrodzenie wykonawcy, odbiory, czy
          klauzule waloryzacyjne)
        </li>
        <li>
          nasze narzędzie podpowiada, które postanowienia umowne są obowiązkowe – dzięki temu użytkownik przygotowuje
          zawsze kompletną umowę
        </li>
        <li>
          program automatyczne pobieranie danych z GUS o zamawiających i wykonawcach (tych danych użytkownik nie musi
          wpisywać ręcznie!)
        </li>
        <li>możliwość zawarcia umowy w formie elektronicznej</li>
        <li>możliwość eksportu wygenerowanej umowy do PDF lub Word</li>
      </ul>
      <div className="accordion-content-mid-text">
        Uwaga! Pracując w Asystencie Postępowania nad SWZ, możemy automatycznie zintegrować umowę z danymi z SWZ (dane z
        SWZ wpisują się do odpowiednich postanowień umownych w sposób automatyczny).
      </div>
      <div className="accordion-content-mid-text">
        Dzięki praccy z kreatorem umów, użytkownik otrzyma gotową umowę, w tym w ramach p.z.p.!
      </div>
      <div className="accordion-content-mid-text">
        Kreator umów to jest narzędzie dla każdego, kto potrzebuje umowy, a nasi prawnicy czuwają nad tym, że
        postanowienia umowy były zgodne z przepisami i aktualnym orzecznictwem.
      </div>
    </div>
  )
};
