import { ReactNode } from 'react';
import { createAuthRoutes } from 'modules/Auth/routes';
import { createLayoutRoutes } from 'modules/Layout/routes';
import { createUserRoutes } from 'modules/User/routes';
import { createProfileRoutes } from 'modules/Profile/routes';
import { createSubscriptionsRoutes } from 'modules/Subscriptions/routes';
import { createFeedbackRoutes } from 'modules/Feedback/routes';
import { createRegulationRoutes } from 'modules/Regulation/routes';
import { createExecutorRoutes } from 'modules/Executor/routes';
import { createOfficeRoutes } from 'modules/Office/routes';
import { createOrderRoutes } from 'modules/ExecutorOrder/routes';
import { createModuleRoutes } from 'modules/Module/routes';
import { createJudgementRoutes } from 'modules/Judgement/routes';
import { createClauseRoutes } from 'modules/Clause/routes';
import { createJudgementClientRoutes } from 'modules/JudgementClient/routes';
import { createClauseClientRoutes } from 'modules/ClauseClient/routes';
import { createProceedingsRoutes } from 'modules/Proceedings/routes';
import { createAgreementsRoutes } from 'modules/Agreements/routes';
import { createForeignProceedingsRoutes } from '../modules/ForeignProceedings/routes';

export type RouteType = 'public' | 'guarded' | 'wrapped';

export type Routes = {
  [type in RouteType]?: ReactNode[];
};

export const registerRoutes = (...routes: Routes[]): Routes => {
  return routes.reduce(
    (previousValue, currentValue) => ({
      public: [...previousValue.public, ...(currentValue.public ?? [])],
      guarded: [...previousValue.guarded, ...(currentValue.guarded ?? [])],
      wrapped: [...previousValue.wrapped, ...(currentValue.wrapped ?? [])]
    }),
    {
      public: [],
      guarded: [],
      wrapped: []
    }
  );
};

export const createRoutes = (): Routes => {
  return registerRoutes(
    createLayoutRoutes(),
    createAuthRoutes(),
    createUserRoutes(),
    createProfileRoutes(),
    createSubscriptionsRoutes(),
    createRegulationRoutes(),
    createFeedbackRoutes(),
    createExecutorRoutes(),
    createOfficeRoutes(),
    createOrderRoutes(),
    createModuleRoutes(),
    createJudgementRoutes(),
    createClauseRoutes(),
    createJudgementClientRoutes(),
    createClauseClientRoutes(),
    createProceedingsRoutes(),
    createForeignProceedingsRoutes(),
    createAgreementsRoutes()
  );
};
