import React from 'react';
import { IFile } from '../../../Shared/helper/Files/AddFiles';
import { ExecutorViolation } from '../../../Executor/model/Violation';
import ExecutorViolationsFiles from '../Dataset/ExecutorViolation/ExecutorViolationsFiles';
// eslint-disable-next-line import/no-cycle
import ExecutorViolationsDetailsBasic from './Basic';

type Props = {
  violation?: ExecutorViolation;
  executor_id?: string;
  violationDownloadFileRequest: (fileId: number | string) => Promise<void>;
  onDeleteViolation: () => void;
  onDeleteFile: (file: IFile) => void;
  loadingFile: boolean;
  onAddFilesRequest: (
    data: FormData,
    changeLoading: (curr: boolean) => void,
    setProgress: (value: React.SetStateAction<number>) => void
  ) => Promise<void>;
  redirectUrl: string;
};

const ExecutorViolationsDetailsComponent: React.FC<Props> = ({
  violation,
  executor_id,
  onDeleteViolation,
  redirectUrl,
  violationDownloadFileRequest,
  onAddFilesRequest,
  onDeleteFile
}) => {
  return (
    <div className="col-12">
      <div className="row justify-content-center">
        <div className="col-xl-7">
          <ExecutorViolationsDetailsBasic
            violation={violation}
            executor_id={executor_id}
            onDeleteClick={onDeleteViolation}
            redirectUrl={redirectUrl}
          />
        </div>
        <div className="col-xl-7">
          <ExecutorViolationsFiles
            onDeleteFile={onDeleteFile}
            onAddFilesRequest={onAddFilesRequest}
            violationFiles={violation.files}
            violationDownloadFileRequest={violationDownloadFileRequest}
          />
        </div>
      </div>
    </div>
  );
};

export default ExecutorViolationsDetailsComponent;
