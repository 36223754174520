import { RootState } from 'app/reducer';
import Authorize from 'modules/Auth/container/Authorize';
import { setJudgementClientListParamsAction } from 'modules/JudgementClient/action/JudgmentList';
import Judgement from 'modules/Judgement/model/Judgement';
import { ROUTE_JUDGEMENT_CLIENT } from 'modules/JudgementClient/routes';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { getPathUrl } from 'modules/Shared/helper/api';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { PartialSearchingProps } from 'modules/Shared/type';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const JudgmentClientTable = () => {
  const dispatch = useDispatch();
  const { list, sort } = useSelector((state: RootState) => state.judgmentClient.judgementClientList);

  const setParams = (payload: PartialSearchingProps) => dispatch(setJudgementClientListParamsAction(payload));

  const redirectLink = (value: string, id: number) => (
    <Authorize fallback={value}>
      <Link className="fill-table-td" to={getPathUrl(ROUTE_JUDGEMENT_CLIENT, { id })}>
        {value}
      </Link>
    </Authorize>
  );

  const cols: TableCol<Judgement>[] = [
    {
      property: 'identifier',
      label: 'Identyfikator publikacji',
      sortable: true,
      value: (row) => redirectLink(row.identifier, row.id)
    },
    {
      property: 'authority_name',
      label: 'Nazwa organu publikującego dokument',
      sortable: true
    },
    {
      property: 'authority_type',
      label: 'Rodzaj organu publikującego dokument',
      sortable: true
    },
    {
      property: 'document_type',
      label: 'Rodzaj dokumentu',
      sortable: true
    },
    {
      property: 'place',
      label: 'Oryginalne miejsce publikacji',
      sortable: true
    },
    {
      property: 'category_name',
      label: 'Nazwa przypisanych kategorii',
      sortable: true,
      value: (row) => row?.categories_names
    },
    {
      property: 'published_at ',
      label: 'Termin publikacji',
      sortable: true,
      value: (row) => ifContainsDateFormat(row.published_at, formatDate)
    }
  ];

  return <Table cols={cols} rows={list} sort={sort} onSort={(params) => setParams({ sort: params })} />;
};

export default JudgmentClientTable;
