import Table, { TableCol } from 'modules/Layout/component/Table/AccordionTable';
import React from 'react';
import Subscription from 'modules/Subscriptions/model/Subscription';
import { ModuleEntity } from 'modules/Module/model/Module';
import moment, { Moment } from 'moment';
import { getCurrentDate } from 'modules/Shared/helper/utils';

type Props = { selectedProducts: Subscription[]; userModules: ModuleEntity[] };

const SummaryTable: React.FC<Props> = ({ selectedProducts, userModules }) => {
  if (!selectedProducts) return null;

  const calculateDate = (row: Subscription) => {
    const moduleDate = row?.credits
      ? null
      : userModules.find((module) => module?.slug === row?.modules?.[0]?.slug)?.date_to;

    let newDate: Moment = null;
    if (moduleDate) {
      newDate = moment(moduleDate).add(row?.days ?? 0, 'days');
    } else {
      newDate = moment(getCurrentDate()).add(row?.days ?? 0, 'days');
    }
    return newDate;
  };

  const subscriptionProductValue = (row: Subscription) => {
    const { credits, days } = row;

    const creditCorrectDayForm = credits > 1 && credits < 5 ? 'kredyty' : 'kredytów';
    const creditsString = credits === 1 ? 'kredyt' : creditCorrectDayForm;
    const creditValue = credits ? (
      <>
        <strong>{credits}</strong> {creditsString}
      </>
    ) : (
      'pełny dostęp'
    );

    return (
      <span className="text-primary">
        {creditValue} na {days} {days === 1 ? 'dzień' : 'dni'}
      </span>
    );
  };

  const cols: TableCol<Subscription>[] = [
    {
      property: 'products',
      label: 'Produkt',
      // eslint-disable-next-line react/display-name
      value: (row) => (
        <div>
          {row?.modules?.[0]?.name}, {subscriptionProductValue(row)}, ważne do{' '}
          <span className="text-dark">{calculateDate(row)?.format('DD.MM.YYYY')}</span>
        </div>
      )
    },
    {
      property: 'net_value',
      label: 'Netto',
      // eslint-disable-next-line react/display-name
      value: (row) => <span className="font-weight-bold">{row.net_value.toFixed(2).replace('.', ',')}</span>
    },
    {
      property: 'value',
      label: 'Brutto',
      // eslint-disable-next-line react/display-name
      value: (row) => <span className="font-weight-bold">{row.value.toFixed(2).replace('.', ',')}</span>
    },
    {
      property: 'tax_percentage',
      label: 'VAT',
      value: (row) => `${row.tax_percentage}%`
    }
  ];

  const totalBruttoSum = selectedProducts
    .reduce((amount, item) => item.value + amount, 0)
    .toFixed(2)
    .replace('.', ',');
  const totalNettoSum = selectedProducts
    .reduce((amount, item) => item.net_value + amount, 0)
    .toFixed(2)
    .replace('.', ',');

  return (
    <div className="pzpeu-table-list">
      <Table
        cols={cols}
        rows={selectedProducts}
        customLastRow={
          <tr className="summary-row">
            <td>Do zapłaty</td>
            <td className="font-weight-bold">{totalNettoSum}</td>
            <td className="font-weight-bold">{totalBruttoSum}</td>
            <td>23%</td>
          </tr>
        }
      />
    </div>
  );
};

export default SummaryTable;
