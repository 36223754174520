import { Regulations } from 'modules/Regulation/type';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { CustomInput, FormFeedback, FormGroup } from 'reactstrap';

export interface Props {
  errors: ValidationErrors;
  values: Regulations;
  className?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, key: keyof Regulations) => void;
  disabled?: boolean;
}

export const FieldsetRegulation: React.FC<Props> = ({
  values,
  onChange,
  errors,
  className,
  disabled
}: Props): JSX.Element => {
  const regulationsUrl = process.env.REGULATIONS_URL || '';
  const privacyPolicyUrl = process.env.PRIVACY_POLICY_URL || '';
  const label = (
    <>
      Oświadczam, że zapoznałem/am się z{' '}
      <a href={regulationsUrl} target="_blank" rel="noreferrer">
        regulaminem
      </a>
      ,{' '}
      <a href={privacyPolicyUrl} target="_blank" rel="noreferrer">
        polityką prywatności
      </a>{' '}
      i akceptuję wszystkie zawarte w nich warunki.*
    </>
  );

  return (
    <div className="fieldset-regulation">
      <FormGroup className={className} tag="fieldset">
        <CustomInput
          type="checkbox"
          name="regulations_accepted"
          id="regulations"
          label={label}
          className="regulation-field"
          disabled={disabled}
          checked={values.regulations_accepted}
          onChange={(event) => onChange(event, 'regulations_accepted')}
          invalid={hasError(errors, 'regulations_accepted')}
          required
        />
        {hasError(errors, 'regulations_accepted') && (
          <FormFeedback>{getError(errors, 'regulations_accepted')}</FormFeedback>
        )}
      </FormGroup>
    </div>
  );
};

export default FieldsetRegulation;
