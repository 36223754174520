import React from 'react';
import './style.scss';
import LoaderIcon from 'modules/Layout/component/Icon/Loader';

export interface LoaderProps {
  size?: string;
}

const Loader: React.FC<LoaderProps> = (props: LoaderProps): JSX.Element => {
  const { size = '100px' } = props;

  return (
    <div className="loader">
      <LoaderIcon height={size} />
    </div>
  );
};

export default Loader;
