import React from 'react';
import User from 'modules/User/model/User';
import InformationList, { InformationField } from 'modules/Layout/component/InformationList';

export interface Props {
  user: User;
  displayStatus?: boolean;
  displayCompany?: boolean;
  hideRole?: boolean;
}

const BasicDataset: React.FC<Props> = ({ user, displayStatus = false, displayCompany = false, hideRole = false }) => {
  const themeTemplate = process.env.THEME_TEMPLATE as 'pzp' | 'bs';
  const fields: InformationField[] = [
    {
      label: 'Imię',
      value: user?.first_name
    },
    {
      label: 'Nazwisko',
      value: user?.last_name
    },
    {
      label: 'Adres e-mail',
      value: user?.email,
      className: 'd-flex',
      newLineValue: user?.new_email ? (
        <div className="mb-1 text-muted" style={{ fontSize: '12px' }}>
          Email oczekujący na potwierdzenie: {user?.new_email}
        </div>
      ) : null
    },
    {
      label: 'Telefon',
      value: user?.phone
    }
  ];

  if (themeTemplate === 'pzp') {
    fields.push({
      label: 'NIP organizacji w Asystencie postępowań',
      value: user?.nip ? user.nip : 'brak danych',
      afterValue: user?.nip ? (
        <div className="mb-1 text-muted" style={{ fontSize: '12px' }}>
          {user?.nip_acceptance ? 'Potwierdzone przez administratora' : 'Niepotwierdzone przez administratora'}
        </div>
      ) : null
    });
  }

  if (!hideRole) {
    fields.push({
      label: 'Rola',
      value: user?.role.name
    });
  }

  if (displayStatus) {
    fields.push({
      label: 'Status',
      value: user?.getStatusLabel()
    });
  }

  if (displayCompany) {
    fields.push({
      label: 'Firma',
      value: user?.getCompanyName()
    });
  }

  return <InformationList fields={fields} />;
};

export default BasicDataset;
