import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const createJudgementCategoryToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja orzeczeń sądowych',
  body: 'Kategoria została pomyślnie dodana.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateJudgementCategoryToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja orzeczeń sądowych',
  body: 'Kategoria została pomyślnie zaktualizowana.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteJudgementCategoryToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja orzeczeń sądowych',
  body: 'Kategoria została pomyślnie usunięta.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createJudgementKeywordToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja orzeczeń sądowych',
  body: 'Słowo kluczowe zostało pomyślnie dodane.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateJudgementKeywordToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja orzeczeń sądowych',
  body: 'Słowo kluczowe zostało pomyślnie zaktualizowane.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteJudgementKeywordToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja orzeczeń sądowych',
  body: 'Słowo kluczowe zostało pomyślnie usunięte.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createJudgementSAOSKeywordToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja orzeczeń sądowych',
  body: 'Słowo kluczowe zostało pomyślnie dodane.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateJudgementSAOSKeywordToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja orzeczeń sądowych',
  body: 'Słowo kluczowe zostało pomyślnie zaktualizowane.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteJudgementSAOSKeywordToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja orzeczeń sądowych',
  body: 'Słowo kluczowe zostało pomyślnie usunięte.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createJudgementToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja orzeczeń sądowych',
  body: 'Orzeczenie zostało stworzone pomyślnie.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateJudgementToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja orzeczeń sądowych',
  body: 'Orzeczenie zostało pomyślnie zaktualizowane.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteJudgementToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja orzeczeń sądowych',
  body: 'Orzeczenie zostało pomyślnie usunięte.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const fetchJudgementCategoryToastError = (body: string): AddToastPayload => ({
  header: 'Błąd pobierania listy kategorii',
  body,
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const fetchJudgementKeywordsToastError = (body: string): AddToastPayload => ({
  header: 'Błąd pobierania listy słów kluczowych',
  body,
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const fetchJudgementListToastError = (body: string): AddToastPayload => ({
  header: 'Błąd pobierania listy orzeczeń',
  body,
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const addJudgementConnectClauseToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja orzeczeń sądowych',
  body: 'Pomyślnie utworzono połączenie z klauzulą.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateJudgementConnectClauseToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja orzeczeń sądowych',
  body: 'Pomyślnie zaktualizowano połączenie z klauzulą.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteJudgementConnectClauseToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja orzeczeń sądowych',
  body: 'Pomyślnie usunięto połączenie z klauzulą.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});
