import { formatDate } from 'modules/Shared/helper/utils';
import React, { useState } from 'react';
import { Button, CardBody, Collapse } from 'reactstrap';
import './index.scss';

const CollapseCreditAccessList: React.FC<{ subscriptionTime?: string }> = ({ subscriptionTime, children }) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div className="listing-wrapper collapse-credit-access-list">
      <div className="filter-wrapper">
        <Button
          type="button"
          color="link"
          className="d-inline-flex align-items-center p-0 justify-content-between filter-header text-primary font-poppins"
          block
          onClick={() => setCollapsed(!collapsed)}
        >
          Twoje dostępy
          <i className={`mdi mdi-chevron-${collapsed ? 'up' : 'down'} text-dark`} />
        </Button>
        <Collapse isOpen={collapsed}>
          <CardBody className="row">
            {subscriptionTime && (
              <div className="col-12 subscription-time">
                Posiadasz wykupiony abonament do dnia <strong>{formatDate(subscriptionTime)}</strong> na pełny dostęp do
                całej bazy wykonawców, w stosunku do których mogą wystąpić podstawy do wykluczenia!
              </div>
            )}
            {children}
          </CardBody>
        </Collapse>
      </div>
    </div>
  );
};

export default CollapseCreditAccessList;
