import {
  ExecutorExtendedListAction,
  SET_USER_EXECUTORS_EXTENDED_LIST_PARAMS,
  USER_EXECUTORS_EXTENDED_LIST
} from 'modules/User/action/list';
import { ExecutorsExtendedListState, initExecutorExtendedListState } from '../../state/executorsExtended';
import { createExecutor } from '../../../Executor/model/Executor';

const initState = initExecutorExtendedListState();

const reducer = (
  state: ExecutorsExtendedListState = initState,
  action: ExecutorExtendedListAction
): ExecutorsExtendedListState => {
  switch (action.type) {
    case SET_USER_EXECUTORS_EXTENDED_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case USER_EXECUTORS_EXTENDED_LIST: {
      const { executors = [], meta } = action.payload;

      return { ...state, fetching: false, executorsExtended: executors.map(createExecutor), meta };
    }

    default:
      return state;
  }
};

export default reducer;
