/* eslint-disable */
import React from 'react';
import { IconProps } from '../index';

export default function LoginPassIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 23.1 30.315">
      <g id="Group_54" data-name="Group 54" transform="translate(0.5 0.5)">
        <path
          id="Path_53"
          data-name="Path 53"
          d="M701.2,2553.5a2.72,2.72,0,0,0-2.757,2.709l1.221,12.682a2.723,2.723,0,0,0,2.757,2.715h14.144a2.744,2.744,0,0,0,2.759-2.738l1.22-12.669a2.712,2.712,0,0,0-2.759-2.7Z"
          transform="translate(-698.441 -2542.292)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_54"
          data-name="Path 54"
          d="M708.879,2564.952h-2.885l-.543-4.471a1.986,1.986,0,0,1,3.97-.011Z"
          transform="translate(-696.385 -2540.81)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_55"
          data-name="Path 55"
          d="M702.486,2556.042v-5.772a5.834,5.834,0,0,1,11.64,0v5.772"
          transform="translate(-697.254 -2544.834)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
