import React, { useEffect, useRef, useState } from 'react';
import { AGREEMENT_TYPE_SUPPLEMENT, AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_DISPUTES } from 'modules/Agreements/step';
import { FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import useStepFields from 'modules/Agreements/hook/useStepFields';

export interface AgreementStepDisputesValues {
  realization_checked: boolean;
  realization_content: string | null;
  agreement_possibility: boolean;
  agreement_mediation_checked: boolean;
  agreement_mediation_content: string | null;
  agreement_others_checked: boolean;
  agreement_others_content: string | null;
  no_deal_checked: boolean;
  no_deal_content: string | null;
  arbitrary_checked: boolean;
  arbitrary_content: string | null;
  checkboxes: AgreementCheckboxes | null;
}

const REALIZATION_CONTENT =
  'Wszelkie spory mogące wynikać w związku z realizacją niniejszej Umowy będą rozstrzygane polubownie';
const AGREEMENT_MEDIATION_CONTENT =
  'mediacji lub koncyliacji bądź innej polubownej metodzie rozwiązania sporu. Mediacja, koncyliacja lub inna polubowna metoda rozwiązywania sporów prowadzona będzie odpowiednio przez Mediatorów, Koncyliatorów Stałych Sądu Polubownego przy Prokuratorii Generalnej Rzeczypospolitej Polskiej zgodnie z Regulaminem tego Sądu lub arbitrów wyznaczonych zgodnie z tym Regulaminem, wybranym mediatorem albo osobą prowadzącą inne polubowne rozwiązanie sporu';
const AGREEMENT_OTHERS_CONTENT = 'inne ........';
const NO_DEAL_CONTENT =
  'W przypadku braku porozumienia Stron, właściwym do rozstrzygnięcia spraw spornych będzie sąd właściwy dla siedziby zamawiającego';
const ARBITRARY_CONTENT =
  'Strony postanawiają, że polubowne rozstrzygnięcie sporu zgodnie z niniejszą Umową nastąpi w terminie ........ Strony przewidują możliwość przedłużenia tego terminu o konieczny czas dla polubownego rozwiązania sporu';

const initContentValues = {
  realization_content: REALIZATION_CONTENT,
  agreement_mediation_content: AGREEMENT_MEDIATION_CONTENT,
  agreement_others_content: AGREEMENT_OTHERS_CONTENT,
  no_deal_content: NO_DEAL_CONTENT,
  arbitrary_content: ARBITRARY_CONTENT
};

const mapState = (step: AgreementStepDisputesValues): AgreementStepDisputesValues => {
  const {
    realization_content,
    agreement_mediation_content,
    agreement_others_content,
    no_deal_content,
    arbitrary_content,
    checkboxes,
    ...rest
  } = step;

  return {
    ...rest,
    realization_content: realization_content || REALIZATION_CONTENT,
    agreement_mediation_content: agreement_mediation_content || AGREEMENT_MEDIATION_CONTENT,
    agreement_others_content: agreement_others_content || AGREEMENT_OTHERS_CONTENT,
    no_deal_content: no_deal_content || NO_DEAL_CONTENT,
    arbitrary_content: arbitrary_content || ARBITRARY_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepDisputes = (props: AgreementStepProps): JSX.Element => {
  const { steps, onSubmit, onChange, errors, agreement } = props;

  const initState = useRef<AgreementStepDisputesValues>(mapState(getStepValues(steps, AGREEMENT_STEP_DISPUTES)));

  const [stepValues, setStepValues] = useState<AgreementStepDisputesValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_DISPUTES));
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderCheckbox, renderEditableCheckbox, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    initContentValues
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        {renderCheckbox(
          'realization_checked',
          stepValues.realization_content,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        <hr />
        {renderCheckbox(
          'agreement_possibility',
          'W przypadku zaistnienia pomiędzy stronami sporu wynikającego z Umowy lub pozostającego w związku z Umową, strony zobowiązują się do jego rozwiązania w drodze:',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {stepValues.agreement_possibility && (
          <div className="pl-3">
            {[
              renderEditableCheckbox(
                'agreement_mediation',
                null,
                agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                agreement.type === AGREEMENT_TYPE_SUPPLEMENT
              ),
              renderEditableCheckbox(
                'agreement_others',
                null,
                agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                agreement.type === AGREEMENT_TYPE_SUPPLEMENT
              )
            ]}
          </div>
        )}
        <hr />
        {renderCheckbox('no_deal_checked', stepValues.no_deal_content, agreement.type === AGREEMENT_TYPE_SUPPLEMENT)}
        {renderEditableCheckbox(
          'arbitrary',
          null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderCustomCheckboxes(
          null,
          null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepDisputes;
