import React, { useEffect, useRef, useState } from 'react';
import {
  AGREEMENT_TYPE_OUTSIDE_ORDER,
  AGREEMENT_TYPE_SUPPLEMENT,
  AgreementCheckboxes,
  AgreementStepProps
} from 'modules/Agreements/type';
import { getStepValues, PREVIEW_PLACEHOLDER } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_SUBEXECUTORS } from 'modules/Agreements/step';
import { isEqual } from 'lodash';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import useStepFields from 'modules/Agreements/hook/useStepFields';

export interface AgreementStepSubexecutorsValues {
  agreement_copy_checked: boolean;
  agreement_copy_content: string | null;
  agreement_fails_requirements_checked: boolean;
  agreement_fails_requirements_content: string | null;
  agreement_incompatible_agreement_checked: boolean;
  agreement_incompatible_agreement_content: string | null;
  agreement_incompatible_pzp_checked: boolean;
  agreement_incompatible_pzp_content: string | null;
  agreement_longer_payment_date_checked: boolean;
  agreement_longer_payment_date_content: string | null;
  agreement_objections_others_checked: boolean;
  agreement_objections_others_content: string | null;
  agreement_objections_possibility: boolean;
  agreement_project_checked: boolean;
  agreement_project_content: string | null;
  copies_checked: boolean;
  copies_content: string | null;
  decision_checked: boolean;
  decision_content: string | null;
  objections_date_checked: boolean;
  objections_date_content: string | null;
  payment_date_checked: boolean;
  payment_date_content: string | null;
  project_fails_requirements_checked: boolean;
  project_fails_requirements_content: string | null;
  project_incompatible_agreement_checked: boolean;
  project_incompatible_agreement_content: string | null;
  project_incompatible_pzp_checked: boolean;
  project_incompatible_pzp_content: string | null;
  project_longer_payment_date_checked: boolean;
  project_longer_payment_date_content: string | null;
  project_objections_others_checked: boolean;
  project_objections_others_content: string | null;
  project_objections_possibility: boolean;
  quality_checked: boolean;
  quality_content: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const AGREEMENT_PROJECT_CONTENT =
  'Wykonawca, podwykonawca lub dalszy podwykonawca zamówienia na roboty budowlane zamierzający zawrzeć umowę o podwykonawstwo, której przedmiotem są roboty budowlane, jest obowiązany, w trakcie realizacji Umowy, do przedłożenia zamawiającemu projektu tej umowy, lub projektu zmiany umowy już zawartej, przy czym podwykonawca lub dalszy podwykonawca jest obowiązany dołączyć zgodę wykonawcy na zawarcie umowy o podwykonawstwo o treści zgodnej z projektem umowy';
const PAYMENT_DATE_CONTENT =
  'Termin zapłaty wynagrodzenia podwykonawcy lub dalszemu podwykonawcy przewidziany w umowie o podwykonawstwo nie może być dłuższy niż 30 dni od dnia doręczenia wykonawcy, podwykonawcy lub dalszemu podwykonawcy faktury lub rachunku';
const OBJECTIONS_DATE_CONTENT = `Zamawiający w terminie ${PREVIEW_PLACEHOLDER} dni od przedłożenia projektu umowy zgłasza w formie pisemnej, pod rygorem nieważności, zastrzeżenia do projektu umowy o podwykonawstwo, której przedmiotem są roboty budowlane. Wskazany termin uważa się za zachowany także wtedy, gdy przed jego upływem w formie pisemnej zastrzeżenia do projektu umowy o podwykonawstwo wysłano przesyłką poleconą. Niezgłoszenie w formie pisemnej zastrzeżeń do przedłożonego projektu umowy o podwykonawstwo, której przedmiotem są roboty budowlane we wskazanym terminie uważa się za akceptację projektu umowy przez zamawiającego`;
const PROJECT_FAILS_REQUIREMENTS_CONTENT = 'nie spełnia ona wymagań określonych w dokumentach zamówienia';
const PROJECT_LONGER_PAYMENT_DATE_CONTENT = 'przewiduje ona termin zapłaty wynagrodzenia dłuższy niż 30 dni';
const PROJECT_INCOMPATIBLE_PZP_CONTENT = 'zawiera ona postanowienia niezgodne z art. 463 p.z.p.';
const PROJECT_INCOMPATIBLE_AGREEMENT_CONTENT = 'zawiera postanowienia niezgodne z Umową zamawiającego z wykonawcą';
const PROJECT_OBJECTIONS_OTHERS_CONTENT = 'inne:';
const AGREEMENT_COPY_CONTENT = `Wykonawca, podwykonawca lub dalszy podwykonawca zamówienia na roboty budowlane przedkłada zamawiającemu poświadczoną za zgodność z oryginałem kopię zawartej umowy o podwykonawstwo, której przedmiotem są dostawy lub usługi, lub zmianę tej umowy, w terminie ${PREVIEW_PLACEHOLDER} dni od dnia jej zawarcia`;
const AGREEMENT_FAILS_REQUIREMENTS_CONTENT = 'nie spełnia ona wymagań określonych w dokumentach zamówienia';
const AGREEMENT_LONGER_PAYMENT_DATE_CONTENT = 'przewiduje ona termin zapłaty wynagrodzenia dłuższy niż 30 dni';
const AGREEMENT_INCOMPATIBLE_PZP_CONTENT = 'zawiera ona postanowienia niezgodne z art. 463 p.z.p.';
const AGREEMENT_INCOMPATIBLE_AGREEMENT_CONTENT = 'zawiera postanowienia niezgodne z Umową zamawiającego z wykonawcą';
const AGREEMENT_OBJECTIONS_OTHERS_CONTENT = 'inne:';
const DECISION_CONTENT =
  'Postanowienia Umowy dotyczące zawarcia umowy z podwykonawcą stosuje się odpowiednio do zmian projektu oraz umowy o podwykonawstwo';
const QUALITY_CONTENT =
  'Wykonawca w pełni odpowiada za jakość i terminowość wykonywanych robót i dostaw siłami własnymi i przez  podwykonawców lub dalszych podwykonawców, w tym wykonawca jest odpowiedzialny za działania, zaniechanie działań, uchybienia i zaniedbania dostawców oraz podwykonawców lub dalszych podwykonawców i ich pracowników, w takim stopniu jakby to były działania względnie uchybienia jego własne. Na roboty i dostawy wykonywane przez podwykonawców lub dalszych podwykonawców gwarancji udziela wykonawca. Wykonawca we własnym zakresie i na swój koszt pełni funkcję koordynacyjną w stosunku do robót realizowanych przez podwykonawców lub dalszych podwykonawców';
const COPIES_CONTENT =
  'Przedkładane zamawiającemu kopie umów o podwykonawstwo, o których mowa w art. 437 ust. 1 pkt 1 i 3 p.z.p. mogą być poświadczone za zgodność z oryginałem przez przedkładającego';

const initContentValues = {
  agreement_project_content: AGREEMENT_PROJECT_CONTENT,
  payment_date_content: PAYMENT_DATE_CONTENT,
  objections_date_content: OBJECTIONS_DATE_CONTENT,
  project_fails_requirements_content: PROJECT_FAILS_REQUIREMENTS_CONTENT,
  project_longer_payment_date_content: PROJECT_LONGER_PAYMENT_DATE_CONTENT,
  project_incompatible_pzp_content: PROJECT_INCOMPATIBLE_PZP_CONTENT,
  project_incompatible_agreement_content: PROJECT_INCOMPATIBLE_AGREEMENT_CONTENT,
  project_objections_others_content: PROJECT_OBJECTIONS_OTHERS_CONTENT,
  agreement_copy_content: AGREEMENT_COPY_CONTENT,
  agreement_fails_requirements_content: AGREEMENT_FAILS_REQUIREMENTS_CONTENT,
  agreement_longer_payment_date_content: AGREEMENT_LONGER_PAYMENT_DATE_CONTENT,
  agreement_incompatible_pzp_content: AGREEMENT_INCOMPATIBLE_PZP_CONTENT,
  agreement_incompatible_agreement_content: AGREEMENT_INCOMPATIBLE_AGREEMENT_CONTENT,
  agreement_objections_others_content: AGREEMENT_OBJECTIONS_OTHERS_CONTENT,
  decision_content: DECISION_CONTENT,
  quality_content: QUALITY_CONTENT,
  copies_content: COPIES_CONTENT
};

const mapState = (
  step: AgreementStepSubexecutorsValues,
  defaultValueStatus: boolean
): AgreementStepSubexecutorsValues => {
  const {
    agreement_project_checked,
    agreement_project_content,
    payment_date_checked,
    payment_date_content,
    objections_date_checked,
    objections_date_content,
    project_fails_requirements_content,
    project_longer_payment_date_content,
    project_incompatible_pzp_content,
    project_incompatible_agreement_content,
    project_objections_others_content,
    agreement_copy_checked,
    agreement_copy_content,
    agreement_fails_requirements_content,
    agreement_longer_payment_date_content,
    agreement_incompatible_pzp_content,
    agreement_incompatible_agreement_content,
    agreement_objections_others_content,
    decision_checked,
    decision_content,
    quality_content,
    copies_content,
    checkboxes,
    ...rest
  } = step;

  return {
    ...rest,
    agreement_project_checked: Boolean(agreement_project_checked || defaultValueStatus),
    agreement_project_content: agreement_project_content || AGREEMENT_PROJECT_CONTENT,
    payment_date_checked: Boolean(payment_date_checked || defaultValueStatus),
    payment_date_content: payment_date_content || PAYMENT_DATE_CONTENT,
    objections_date_checked: Boolean(objections_date_checked || defaultValueStatus),
    objections_date_content: objections_date_content || OBJECTIONS_DATE_CONTENT,
    project_fails_requirements_content: project_fails_requirements_content || PROJECT_FAILS_REQUIREMENTS_CONTENT,
    project_longer_payment_date_content: project_longer_payment_date_content || PROJECT_LONGER_PAYMENT_DATE_CONTENT,
    project_incompatible_pzp_content: project_incompatible_pzp_content || PROJECT_INCOMPATIBLE_PZP_CONTENT,
    project_incompatible_agreement_content:
      project_incompatible_agreement_content || PROJECT_INCOMPATIBLE_AGREEMENT_CONTENT,
    project_objections_others_content: project_objections_others_content || PROJECT_OBJECTIONS_OTHERS_CONTENT,
    agreement_copy_checked: Boolean(agreement_copy_checked || defaultValueStatus),
    agreement_copy_content: agreement_copy_content || AGREEMENT_COPY_CONTENT,
    agreement_fails_requirements_content: agreement_fails_requirements_content || AGREEMENT_FAILS_REQUIREMENTS_CONTENT,
    agreement_longer_payment_date_content:
      agreement_longer_payment_date_content || AGREEMENT_LONGER_PAYMENT_DATE_CONTENT,
    agreement_incompatible_pzp_content: agreement_incompatible_pzp_content || AGREEMENT_INCOMPATIBLE_PZP_CONTENT,
    agreement_incompatible_agreement_content:
      agreement_incompatible_agreement_content || AGREEMENT_INCOMPATIBLE_AGREEMENT_CONTENT,
    agreement_objections_others_content: agreement_objections_others_content || AGREEMENT_OBJECTIONS_OTHERS_CONTENT,
    decision_checked: Boolean(decision_checked || defaultValueStatus),
    decision_content: decision_content || DECISION_CONTENT,
    quality_content: quality_content || QUALITY_CONTENT,
    copies_content: copies_content || COPIES_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepSubexecutors = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, onSubmit, onChange, errors } = props;
  const agreementFromPzp = agreement.type !== AGREEMENT_TYPE_OUTSIDE_ORDER;

  const initState = useRef<AgreementStepSubexecutorsValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_SUBEXECUTORS), agreementFromPzp)
  );
  const [stepValues, setStepValues] = useState<AgreementStepSubexecutorsValues>(
    mapState(initState.current, agreementFromPzp)
  );

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_SUBEXECUTORS), agreementFromPzp);
    setStepValues(mapState(initState.current, agreementFromPzp));
  }, [steps]);

  const { renderEditableCheckbox, renderSwitch, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState: (values) => mapState(values, agreementFromPzp),
    errors,
    initContentValues
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      {renderEditableCheckbox(
        'agreement_project',
        null,
        agreementFromPzp || agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
        agreement.type === AGREEMENT_TYPE_SUPPLEMENT
      )}
      {renderEditableCheckbox(
        'payment_date',
        null,
        agreementFromPzp || agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
        agreement.type === AGREEMENT_TYPE_SUPPLEMENT
      )}
      {renderEditableCheckbox(
        'objections_date',
        null,
        agreementFromPzp || agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
        agreement.type === AGREEMENT_TYPE_SUPPLEMENT
      )}
      <hr />
      {renderSwitch(
        'project_objections_possibility',
        'Czy zamawiający zgłasza w formie pisemnej, pod rygorem nieważności, zastrzeżenia do projektu umowy o podwykonawstwo, której przedmiotem są roboty budowlane, w przypadku gdy:',
        agreement.type === AGREEMENT_TYPE_SUPPLEMENT
      )}
      {stepValues.project_objections_possibility && (
        <div className="pl-3">
          {renderEditableCheckbox(
            'project_fails_requirements',
            null,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )}
          {renderEditableCheckbox(
            'project_longer_payment_date',
            null,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )}
          {agreementFromPzp &&
            renderEditableCheckbox(
              'project_incompatible_pzp',
              null,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT
            )}
          {renderEditableCheckbox(
            'project_incompatible_agreement',
            null,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )}
          {renderEditableCheckbox(
            'project_objections_others',
            null,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )}
        </div>
      )}
      <hr />
      {renderEditableCheckbox(
        'agreement_copy',
        null,
        agreementFromPzp || agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
        agreement.type === AGREEMENT_TYPE_SUPPLEMENT
      )}
      <hr />
      {renderSwitch(
        'agreement_objections_possibility',
        'Czy zamawiający zgłasza w formie pisemnej, pod rygorem nieważności, zastrzeżenia do umowy o podwykonawstwo, której przedmiotem są dostawy lub usługi, w przypadku gdy:',
        agreement.type === AGREEMENT_TYPE_SUPPLEMENT
      )}
      {stepValues.agreement_objections_possibility && (
        <div className="pl-3">
          {renderEditableCheckbox(
            'agreement_fails_requirements',
            null,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )}
          {renderEditableCheckbox(
            'agreement_longer_payment_date',
            null,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )}
          {agreementFromPzp &&
            renderEditableCheckbox(
              'agreement_incompatible_pzp',
              null,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT
            )}
          {renderEditableCheckbox(
            'agreement_incompatible_agreement',
            null,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )}
          {renderEditableCheckbox(
            'agreement_objections_others',
            null,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )}
        </div>
      )}
      <hr />
      {renderEditableCheckbox(
        'decision',
        null,
        agreementFromPzp || agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
        agreement.type === AGREEMENT_TYPE_SUPPLEMENT
      )}
      {renderEditableCheckbox(
        'quality',
        null,
        agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
        agreement.type === AGREEMENT_TYPE_SUPPLEMENT
      )}
      {renderEditableCheckbox(
        'copies',
        null,
        agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
        agreement.type === AGREEMENT_TYPE_SUPPLEMENT
      )}
      {renderCustomCheckboxes(
        null,
        null,
        agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
        agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
        agreement.type === AGREEMENT_TYPE_SUPPLEMENT
      )}
    </AgreementFormWrapper>
  );
};

export default AgreementStepSubexecutors;
