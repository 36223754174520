import _has from 'lodash/has';
import { ValidationErrors } from 'modules/Shared/type';
import React, { useEffect, useState } from 'react';
import { Button, Form as FormStrap } from 'reactstrap';
import { Link } from 'react-router-dom';
import Proceedings from 'modules/Proceedings/model/Proceedings';
import ProceedingsFieldset from 'modules/Proceedings/component/Fieldset/Main';
import { EditorState } from 'react-draft-wysiwyg';
import { PROCEEDINGS_INIT_TYPES_VALUES, START_DATE_TYPES_VALUES } from 'modules/Proceedings/type';
import { ROUTE_PROCEEDINGS_PLANNING } from 'modules/Proceedings/routes';
import useWysiwyg from 'modules/Layout/component/Wysiwyg';

export type WysiwygValuesType = {
  socialClausesObj: EditorState;
  orderObjectObj: EditorState;
  otherContactContentObj: EditorState;
  answerPlaceObj: EditorState;
  answerWayObj: EditorState;
};

export interface Props {
  errors?: ValidationErrors;
  disabled?: boolean;
  proceedingsData?: Proceedings & { keywords: string[] };
  submit: (data: Proceedings) => void;
  isEditing?: boolean;
  cancelRedirect?: string;
  proceedings_type?: number;
}

const Form: React.FC<Props> = ({
  errors,
  disabled,
  submit,
  proceedingsData,
  isEditing,
  cancelRedirect,
  proceedings_type
}) => {
  const [values, setValues] = useState<Proceedings & { keywords: string[] }>({
    proceeding_type: proceedingsData?.proceeding_type ?? proceedings_type,
    status: proceedingsData?.status ?? 1,
    name: proceedingsData?.name ?? '',
    identifier: proceedingsData?.identifier ?? '',
    bzp_identifier: proceedingsData?.bzp_identifier ?? '',
    bzp_date: proceedingsData?.bzp_date ?? '',
    contractor_name: proceedingsData?.contractor_name ?? '',
    contractor_address: proceedingsData?.contractor_address ?? '',
    contractor_email: proceedingsData?.contractor_email ?? '',
    contractor_nip: proceedingsData?.contractor_nip ?? '',
    start_date_type: proceedingsData?.start_date_type ?? 1,
    start_date: proceedingsData?.start_date ?? '',
    offers_send_date: proceedingsData?.offers_send_date ?? '',
    value: proceedingsData?.value ?? 0,
    requires_deposit: proceedingsData?.requires_deposit ?? false,
    deposit: proceedingsData?.deposit ?? 1,
    type: proceedingsData?.type ?? 1,
    mode: proceedingsData?.mode ?? 1,
    email_contact_checked: proceedingsData?.email_contact_checked ?? false,
    epd2_contact_checked: proceedingsData?.epd2_contact_checked ?? false,
    post_contact_checked: proceedingsData?.post_contact_checked ?? false,
    other_contact_checked: proceedingsData?.other_contact_checked ?? false,
    announcement_link: proceedingsData?.announcement_link ?? '',
    shopping_platform: proceedingsData?.shopping_platform ?? '',
    keywords: proceedingsData?.keywords ?? []
  });

  const { displayWysiwyg: socialClausesWysiwyg, getContent: socialClausesContent } = useWysiwyg({
    title: 'Klauzule społeczne',
    content: proceedingsData?.social_clauses,
    tooltip: { specialSign: true }
  });
  const { displayWysiwyg: orderObjectWysiwyg, getContent: orderObjectContent } = useWysiwyg({
    title: 'Opis przedmiotu zamówienia',
    content: proceedingsData?.order_object,
    tooltip: { specialSign: true }
  });
  const { displayWysiwyg: otherContactWysiwyg, getContent: otherContactContent } = useWysiwyg({
    title: 'Treść innej formy kontaktu',
    content: proceedingsData?.other_contact_content,
    tooltip: { specialSign: true }
  });
  const { displayWysiwyg: answerPlaceWysiwyg, getContent: answerPlaceContent } = useWysiwyg({
    title: 'Miejsce publikacji odpowiedzi na pytanie',
    content: proceedingsData?.answer_place,
    tooltip: { specialSign: true }
  });
  const { displayWysiwyg: answerWayWysiwyg, getContent: answerWayContent } = useWysiwyg({
    title: 'Forma odpowiedzi na pytanie',
    content: proceedingsData?.answer_way,
    tooltip: { specialSign: true }
  });

  const firstTimeRender = React.useRef(false);

  useEffect(() => {
    const { start_date, start_date_type } = values;
    if (
      firstTimeRender.current &&
      (start_date_type === START_DATE_TYPES_VALUES.QUARTER || start_date_type === START_DATE_TYPES_VALUES.MONTH) &&
      start_date !== 1
    ) {
      setValues({ ...values, start_date: 1 });
    } else if (start_date_type === START_DATE_TYPES_VALUES.DATE && typeof start_date === 'number') {
      setValues({ ...values, start_date: '' });
    }
    if (!firstTimeRender.current) firstTimeRender.current = true;
    // eslint-disable-next-line
  }, [values.start_date_type]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const social_clauses = socialClausesContent();
    const order_object = orderObjectContent();
    const other_contact_content = otherContactContent();

    const {
      proceeding_type,
      status,
      offers_send_date,
      requires_deposit,
      deposit,
      answer_place,
      answer_way,
      start_date_type,
      ...rest
    } = values;

    if (proceeding_type === PROCEEDINGS_INIT_TYPES_VALUES.PLANNING) {
      submit({ proceeding_type, social_clauses, order_object, other_contact_content, start_date_type, ...rest });
    } else if (proceeding_type === PROCEEDINGS_INIT_TYPES_VALUES.OPEN) {
      const answer_place_content = answerPlaceContent();
      const answer_way_content = answerWayContent();

      submit({
        proceeding_type,
        status,
        social_clauses,
        order_object,
        other_contact_content,
        offers_send_date,
        requires_deposit,
        deposit: requires_deposit ? deposit : null,
        answer_place: answer_place_content,
        answer_way: answer_way_content,
        ...rest
      });
    }
  };

  const hasValue = () => !Object.values(values).some(Boolean);
  return (
    <FormStrap onSubmit={onSubmit}>
      <ProceedingsFieldset
        disabled={disabled}
        socialClausesWysiwyg={socialClausesWysiwyg}
        orderObjectWysiwyg={orderObjectWysiwyg}
        otherContactWysiwyg={otherContactWysiwyg}
        answerPlaceWysiwyg={answerPlaceWysiwyg}
        answerWayWysiwyg={answerWayWysiwyg}
        errors={errors}
        values={values}
        setValues={setValues as any}
        isEditing={isEditing}
        required
      />

      <div className="d-flex justify-content-center">
        <div className="form-actions pzpeu-btn-flex">
          <Button
            type="submit"
            color="primary"
            className="pzpeu-btn-disabled waves-effect waves-light"
            disabled={hasValue() || disabled}
          >
            Zapisz
          </Button>
          <Link to={cancelRedirect ?? ROUTE_PROCEEDINGS_PLANNING} className="cancel waves-effect waves-light">
            Anuluj
          </Link>
        </div>
      </div>
    </FormStrap>
  );
};

export default Form;
