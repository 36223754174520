export enum FOREIGN_PROCEEDINGS_INIT_TYPES {
  PLANNING = 'planowane',
  OPEN = 'opublikowane',
  SERVICES = 'usługi',
  CONSTRUCTIONS = 'roboty budowlane',
  DELIVERY = 'dostawy'
}

export enum FOREIGN_PROCEEDINGS_INIT_TYPES_VALUES {
  PLANNING = 1,
  OPEN = 2,
  SERVICES = 1,
  CONSTRUCTIONS = 2,
  DELIVERY = 3
}

export enum FOREIGN_PROCEEDINGS_STATUS {
  IN_PROCESS = 'w trakcie',
  ENDED = 'zakończone',
  PUBLISHED = 'aktywne',
  EXAMINING_OFFERS = 'badanie ofert',
  CLOSED = 'zamknięte'
}

export enum FOREIGN_PROCEEDINGS_STATUS_VALUES {
  IN_PROCESS = 9,
  ENDED = 8,
  PUBLISHED = '0',
  EXAMINING_OFFERS = '1',
  CLOSED = '2'
}

export enum FOREIGN_PROCEEDINGS_TYPES {
  OPEN_AUCTION = 'przetarg nieograniczony',
  RESTRICTED_AUCTION = 'przetarg ograniczony',
  NEGOTIATION_WITH_ANNOUNCEMENT = 'negocjacje z ogłoszeniem',
  DIALOGUE = 'dialog konkurencyjny',
  PARTNERSHIP = 'partnerstwo innowacyjne',
  NEGOTIATION_WITHOUT_ANNOUNCEMENT = 'negocjacje bez ogłoszenia',
  FREE_HAND = 'zamówienie z wolnej ręki',
  SOCIAL_SERVICES = 'usługi społeczne i inne szczególne zamówienia',
  BELOW_13K = 'zamówienie poniżej 130000 zł netto',
  BASE_MODE = 'tryb podstawowy',
  PRINCIPLE_COMPETITIVENESS = 'zasada konkurencyjności'
}

export enum FOREIGN_PROCEEDINGS_TYPES_VALUES {
  OPEN_AUCTION = 1,
  RESTRICTED_AUCTION = 2,
  NEGOTIATION_WITH_ANNOUNCEMENT = 3,
  DIALOGUE = 4,
  PARTNERSHIP = 5,
  NEGOTIATION_WITHOUT_ANNOUNCEMENT = 6,
  FREE_HAND = 7,
  SOCIAL_SERVICES = 8,
  BELOW_13K = 9,
  BASE_MODE = 10,
  PRINCIPLE_COMPETITIVENESS = 11
}

export enum START_DATE_TYPES {
  QUARTER = 'kwartał',
  MONTH = 'miesiąc',
  DATE = 'data'
}

export enum START_DATE_TYPES_VALUES {
  QUARTER = 1,
  MONTH = 2,
  DATE = 3
}

export enum FOREIGN_PROCEEDINGS_FILES_TYPES {
  MAIN = 'plik główny',
  DESCRIPTION = 'plik dotyczący opisu przedmiotu zamówienia',
  ORDINARY = 'zwykły plik'
}

export enum FOREIGN_PROCEEDINGS_FILES_VALUES {
  MAIN = 1,
  DESCRIPTION = 2,
  ORDINARY = 3
}

export enum FOREIGN_PROCEEDINGS_VERIFICATION_VALUES {
  OK = 0,
  CHECK = 1,
  ERROR = 2
}

export enum FOREIGN_PROCEEDINGS_VERIFICATION_LABELS {
  OK = 'OK',
  CHECK = 'sprawdzać',
  ERROR = 'błąd'
}

export type ForeignProceedingSendRequestValues = {
  send_place: string;
  send_date: string;
  executor_name: string;
  executor_email: string;
  scope: string;
  justification: string;
  longer_offers_send_date_checked: boolean;
  longer_offers_send_date_content?: string;
  response_to_executor_email_checked: boolean;
  response_to_executor_email_content?: string;
};

export type ForeignProceedingsCPVValues = {
  id?: number;
  code?: string;
  name?: string;
  created_at?: string;
  updated_at?: string;
};

export enum FOREIGN_PROCEEDINGS_QUESTIONS_DATE_MESSAGES {
  EXPIRATION = 'W związku z upływem terminu zamawiający nie jest zobowiązany do udzielenia wyjaśnień.',
  EMPTY = 'Zamawiający nie jest zobowiązany do udzielenia wyjaśnień.'
}
