import { addToastAction, managePageAction } from 'modules/Layout/action';
import Loader from 'modules/Layout/component/Loader';
import { breadcrumbRouteExecutorUpdate } from 'modules/Executor/breadcrumbs';
import Form from 'modules/Executor/component/ExecutorForm/Update';
import Executor, { createExecutor } from 'modules/Executor/model/Executor';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Alert from 'modules/Layout/component/Alert';
import { Message, ValidationErrors } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { ProfileValues } from 'modules/Profile/type';
import { updateExecutorToastSuccess } from 'modules/Executor/toasts';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_EXECUTOR_ADMINS } from 'modules/Executor/routes';
import { fetchAdminExecutor, updateAdminExecutor } from 'modules/Executor/repository';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import updateRequest from 'modules/Shared/helper/APIRequests/updateRequest';
import CardBox from 'modules/Layout/component/CardBox';

export interface Props {
  params?: any;
}

const ExecutorBasicUpdateView: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<{ executor: Executor }>();
  const [executor, setExecutor] = useState<Executor>(null);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatchDeleteExecutorToast = () => dispatch(addToastAction(updateExecutorToastSuccess()));

  const cancelToken = useCancelToken();
  useEffect(() => {
    if (state?.executor) {
      setExecutor(createExecutor(state.executor));
    } else {
      (async () => {
        setFetching(true);
        const { data, cancelled, message: messageResponse } = await fetchRequest(fetchAdminExecutor, id, cancelToken);

        if (cancelled) return;
        if (data) setExecutor(createExecutor(data.data));
        if (messageResponse) setMessage(messageResponse);
        setFetching(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (executor) {
      const executorName = executor.name?.trim() || `NIP: ${executor.nip}`;
      const title = `${executorName} - edycja danych`;
      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteExecutorUpdate(parseInt(id, 10) || 0, true, executorName)
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executor]);

  const updateExecutorAction = async (profile: ProfileValues) => {
    setLoading(true);
    if (message) setMessage(null);
    if (errors) setErrors(null);

    const {
      cancelled,
      message: messageRes,
      success,
      error
    } = await updateRequest(updateAdminExecutor, id, profile, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteExecutorToast();
      dispatch(push(getPathUrl(ROUTE_EXECUTOR_ADMINS, { id })));
    } else {
      if (messageRes) setMessage(messageRes);
      if (error) setErrors(error);
      setLoading(false);
    }
  };

  if (fetching) return <Loader />;
  if (!fetching && !executor && message)
    return (
      <div className="executor-create-view d-flex justify-content-center">
        <CardBox form="small">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </CardBox>
      </div>
    );
  if (!fetching && executor) {
    return (
      <div className="profile-update-view d-flex justify-content-center">
        <CardBox form="small" heading="Dane wykonawcy, w stosunku do którego mogą wystąpić podstawy do wykluczenia">
          {loading && <Loader />}
          <Alert message={message} />
          {executor && <Form executor={executor} errors={errors} submit={updateExecutorAction} disabled={loading} />}
        </CardBox>
      </div>
    );
  }

  return null;
};

export default ExecutorBasicUpdateView;
