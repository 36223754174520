import { BreadcrumbItem } from 'modules/Layout/type';
import { ROUTE_CLAUSE, ROUTE_CLAUSE_CATEGORIES, ROUTE_CLAUSE_LIST } from 'modules/Clause/routes';
import { CLAUSE_CATEGORIES_INDEX, CLAUSE_DETAILS, CLAUSE_INDEX } from 'modules/Clause/permissions';
import { getPathUrl } from 'modules/Shared/helper/api';

export const breadcrumbRouteClauseCategory = (): BreadcrumbItem[] => [
  {
    title: 'Klauzule - kategorie',
    link: ROUTE_CLAUSE_CATEGORIES,
    permissions: [CLAUSE_CATEGORIES_INDEX]
  }
];

export const breadcrumbRouteClauseCategoryDetails = (categoryName: string): BreadcrumbItem[] => [
  ...breadcrumbRouteClauseCategory(),
  {
    title: `${categoryName} - szczegóły`
  }
];

export const breadcrumbRouteClauseKeywords = (): BreadcrumbItem[] => [
  {
    title: 'Klauzule - słowa kluczowe'
  }
];

export const breadcrumbRouteClauseList = (): BreadcrumbItem[] => [
  {
    title: 'Klauzule - lista',
    link: ROUTE_CLAUSE_LIST,
    permissions: [CLAUSE_INDEX]
  }
];

export const breadcrumbRouteClauseCreate = (): BreadcrumbItem[] => [
  ...breadcrumbRouteClauseList(),
  {
    title: 'Tworzenie klauzul'
  }
];

export const breadcrumbRouteClauseDetails = (name: string, id: number): BreadcrumbItem[] => [
  ...breadcrumbRouteClauseList(),
  {
    title: `Szczegóły klauzuli - ${name}`,
    link: getPathUrl(ROUTE_CLAUSE, { id }),
    permissions: [CLAUSE_DETAILS]
  }
];

export const breadcrumbRouteClauseUpdate = (name: string, id: number): BreadcrumbItem[] => [
  ...breadcrumbRouteClauseDetails(name, id),
  {
    title: `Edycja klauzuli - ${name}`
  }
];

export const breadcrumbRouteClauseJudgementContentMark = (
  identifier: string,
  name: string,
  id: number
): BreadcrumbItem[] => [
  ...breadcrumbRouteClauseDetails(name, id),
  {
    title: `Zaznaczenie treści orzeczenia - ${identifier}`
  }
];

export const breadcrumbRouteClauseAddJudgementList = (name: string, id: number): BreadcrumbItem[] => [
  ...breadcrumbRouteClauseDetails(name, id),
  {
    title: `Wybierz orzeczenie`
  }
];
