import React, { useEffect } from 'react';
import CardBox from '../../../../Layout/component/CardBox';
import Listing from '../../../../Layout/component/Listing';
import ExecutorExtendedRequestsTable from '../../../container/Table/ExecutorExtendedRequests';
import ExecutorExtendedRequestsFilter from '../../../container/Filter/ExecutorExtendedRequests';
import { createSearchingProps } from '../../../../Shared/helper/params';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../app/reducer';
import { PartialSearchingProps } from '../../../../Shared/type';
import { setExecutorExtendedRequestsListParamsAction } from '../../../action/list';
import ExecutorsExtendedRequestsPagination from '../../../container/Pagination/ExecutorsExtendedRequests';
import { ROUTE_USERS_EXECUTORS_EXTENDED } from '../../../routes';

export interface Props {
  id?: string | number;
}

const ExecutorExtendedRequests: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch();
  const setParams = (payload: PartialSearchingProps) =>
    dispatch(setExecutorExtendedRequestsListParamsAction(payload, id));
  const { fetching, meta } = useSelector((state: RootState) => state.user.executorsExtendedList);

  const searchProps = createSearchingProps(
    '',
    ['name', 'category_slug', 'status', 'sent_at_from', 'sent_at_to', 'received_at_from', 'received_at_to'],
    ['name', 'category_slug', 'status', 'sent_at', 'received_at']
  );

  useEffect(() => {
    setParams({ ...searchProps });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CardBox className="col-12" heading="Wniosky wykonawcy">
      <div className="row users-view">
        <div className="col-12">
          <div className="pzpeu-table-list inside-card-box">
            <Listing
              table={<ExecutorExtendedRequestsTable id={id} />}
              filter={<ExecutorExtendedRequestsFilter id={id} values={searchProps.filter} />}
              pagination={<ExecutorsExtendedRequestsPagination id={id} path={ROUTE_USERS_EXECUTORS_EXTENDED} />}
              loading={fetching}
              meta={meta}
            />
          </div>
        </div>
      </div>
    </CardBox>
  );
};

export default ExecutorExtendedRequests;
