import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import Authorize from 'modules/Auth/container/Authorize';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import { CLAUSE_DELETE, CLAUSE_EDIT } from 'modules/Clause/permissions';
import { JudgmentConnectedClausesValues } from 'modules/Judgement/type';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_JUDGEMENT_CONNECT_CLAUSE } from 'modules/Judgement/routes';
import Judgement from 'modules/Judgement/model/Judgement';
import EditIcon from 'modules/Layout/component/Icon/Edit';

type Props = {
  judgementClauses: JudgmentConnectedClausesValues[];
  onDeleteClick: (obj: { id: number }) => void;
  setShowContent?: (clauseId: number) => void;
  judgment?: Judgement;
};

const JudgmentConnectedClausesTable: React.FC<Props> = ({
  judgementClauses,
  onDeleteClick,
  setShowContent,
  judgment
}) => {
  const dispatch = useDispatch();

  const onDeleteClicked = (e: any, row: JudgmentConnectedClausesValues) => {
    e?.stopPropagation?.();

    const { id } = row.clause;
    onDeleteClick({ id });
  };

  const showContent = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: number) => {
    e.preventDefault();
    setShowContent(id);
  };

  const onItemClicked = (value: string, id: number) => (
    // eslint-disable-next-line
    <a className="fill-table-td" href="#" onClick={(e) => showContent(e, id)}>
      {value}
    </a>
  );

  const cols: TableCol<JudgmentConnectedClausesValues>[] = [
    {
      property: 'name',
      label: 'Nazwa',
      classname: 'col-11',
      value: (row) => onItemClicked(row.clause.name, row.clause.id)
    },
    {
      property: 'actions',
      label: 'Akcje',
      classname: 'col-1',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            <Authorize permissions={[CLAUSE_EDIT]}>
              <ActionUpdate
                title="Edytuj połączone orzeczenie"
                label={<EditIcon height="20px" />}
                onClick={() =>
                  dispatch(
                    push({
                      pathname: getPathUrl(ROUTE_JUDGEMENT_CONNECT_CLAUSE, { id: judgment.id }),
                      state: { judgment, clauseId: row.clause.id, prevContent: row.content }
                    })
                  )
                }
              />
            </Authorize>
            {onDeleteClick && (
              <Authorize permissions={[CLAUSE_DELETE]}>
                <ActionDelete
                  className="ml-1"
                  title="Usuń połączone orzeczenie"
                  onClick={((e: any) => onDeleteClicked(e, row)) as any}
                />
              </Authorize>
            )}
          </div>
        );
      }
    }
  ];

  return <Table cols={cols} rows={judgementClauses} />;
};

export default JudgmentConnectedClausesTable;
