import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';

export const styleEmptyNewLine = (content: string) => {
  const search = '<p></p>';
  if (content === search) return content;
  const searchRegExp = new RegExp(search, 'g');
  const replaceWith = '<p class="height-mb-1"></p>\n';
  return content.replace(searchRegExp, replaceWith);
};

export const saveContentWysiwyg = (value?: string) => {
  const contentBlock = htmlToDraft(value ?? '');

  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }

  return null;
};

export const convertContent = (contentWysiwyg: EditorState) =>
  styleEmptyNewLine(draftToHtml(convertToRaw(contentWysiwyg.getCurrentContent())));
