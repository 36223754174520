import { PaginationMeta } from 'modules/Shared/type';
import React from 'react';
import { PaginationItem, PaginationLink, Popover, PopoverBody } from 'reactstrap';
import './style.scss';

let increment = 0;

export type PaginationItemType = 'next' | 'previous' | 'first' | 'last';

export interface PaginationProps {
  meta: PaginationMeta;
  onChange: (page: number) => void;
  getPath: (page: number) => string;
}

export interface PaginationState {
  isOpen: boolean;
  page: number | null;
}

class Pagination extends React.PureComponent<PaginationProps, PaginationState> {
  protected readonly id: string;

  constructor(props: PaginationProps) {
    super(props);

    // eslint-disable-next-line no-plusplus
    this.id = `pagination-${++increment}`;

    this.state = {
      isOpen: false,
      page: null
    };

    this.onMousedown = this.onMousedown.bind(this);
    this.gotoPage = this.gotoPage.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.renderFirstItem = this.renderFirstItem.bind(this);
    this.renderPrevItem = this.renderPrevItem.bind(this);
    this.renderItems = this.renderItems.bind(this);
    this.renderNextItem = this.renderNextItem.bind(this);
    this.renderLastItem = this.renderLastItem.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.renderStaticItem = this.renderStaticItem.bind(this);
    this.renderPopover = this.renderPopover.bind(this);
  }

  componentDidMount(): void {
    document.addEventListener('mousedown', this.onMousedown, false);
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.onMousedown, false);
  }

  onMousedown({ target }: MouseEvent): void {
    const { isOpen } = this.state;

    if (isOpen && target instanceof Node) {
      const pagination = document.getElementById(this.id);

      if (pagination && !pagination.contains(target)) {
        this.onToggle();
      }
    }
  }

  gotoPage(page: number): void {
    const { onChange } = this.props;

    this.setState(
      {
        isOpen: false,
        page: null
      },
      () => onChange(page)
    );
  }

  onClick(event: React.MouseEvent, page: number): void {
    event.preventDefault();

    this.gotoPage(page);
  }

  onToggle(): void {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen, page: null });
  }

  renderFirstItem(): React.ReactNode {
    const {
      meta: { current_page }
    } = this.props;

    return this.renderItem(1, false, current_page === 1, 'first');
  }

  renderPrevItem(): React.ReactNode {
    const {
      meta: { current_page }
    } = this.props;

    const prevPage = current_page > 1 ? current_page - 1 : current_page;

    return this.renderItem(prevPage, false, current_page === prevPage, 'previous');
  }

  renderItems(): React.ReactNode[] {
    const {
      meta: { current_page, last_page }
    } = this.props;

    const items: React.ReactNode[] = [];

    for (let page = current_page - 4; page <= current_page + 4; page += 1) {
      if (page === current_page) {
        items.push(this.renderItem(page, current_page === page, false));
      } else if (page > 0 && page <= last_page && Math.abs(current_page - page) <= 2) {
        items.push(this.renderItem(page, false, false));
      }
    }

    return items;
  }

  renderNextItem(): React.ReactNode {
    const {
      meta: { current_page, last_page }
    } = this.props;

    const nextPage = current_page < last_page ? current_page + 1 : current_page;

    return this.renderItem(nextPage, false, current_page === nextPage, 'next');
  }

  renderLastItem(): React.ReactNode {
    const {
      meta: { current_page, last_page }
    } = this.props;

    return this.renderItem(last_page, false, current_page === last_page, 'last');
  }

  renderItem(page: number, active: boolean, disabled: boolean, type?: PaginationItemType): React.ReactNode {
    const { getPath } = this.props;

    const key = type ? `page-${type}` : `page-${page}`;

    const ariaLabel = (() => {
      switch (type) {
        case 'next':
          return 'Następna strona';
        case 'previous':
          return 'Poprzednia strona';
        case 'first':
          return 'Pierwsza strona';
        case 'last':
          return 'Ostatnia strona';
      }
    })();

    return (
      <PaginationItem key={key} active={active} disabled={disabled} className={key}>
        <PaginationLink
          next={type === 'next'}
          previous={type === 'previous'}
          first={type === 'first'}
          last={type === 'last'}
          href={getPath(page)}
          onClick={(event) => this.onClick(event, page)}
          aria-label={ariaLabel}
        >
          {type ? null : page}
        </PaginationLink>
      </PaginationItem>
    );
  }

  renderStaticItem(): React.ReactNode {
    return (
      <PaginationItem className="page-static">
        <PaginationLink onClick={this.onToggle} aria-label="Przejdź do konkretnej strony">
          ...
        </PaginationLink>
      </PaginationItem>
    );
  }

  renderPopover(): React.ReactNode {
    const {
      meta: { current_page, last_page }
    } = this.props;
    const { isOpen, page } = this.state;

    return (
      <Popover
        key={current_page}
        target={this.id}
        container={this.id}
        isOpen={isOpen}
        placement="top"
        delay={0}
        fade={false}
      >
        <PopoverBody className="pagination-go-to">
          <input
            type="number"
            className="form-control form-control-sm"
            value={page || ''}
            placeholder={`(1-${last_page})`}
            onChange={(event) => {
              const value = Number(event.target.value);

              if (value > 0 && value <= last_page) {
                this.setState({ page: value });
              }
            }}
          />
          <button
            type="button"
            className="btn btn-primary btn-sm btn-block waves-effect waves-light mt-2"
            onClick={() => this.gotoPage(page)}
          >
            Przejdź do strony
          </button>
        </PopoverBody>
      </Popover>
    );
  }

  render(): React.ReactNode {
    const {
      meta: { current_page, last_page }
    } = this.props;

    return (
      <div className="pagination-wrapper" id={this.id}>
        <ul className="pagination pagination-nav m-0" aria-label="pagination">
          {this.renderFirstItem()}
          {this.renderPrevItem()}
          {current_page > 3 && this.renderStaticItem()}
          {this.renderItems()}
          {current_page + 2 < last_page && this.renderStaticItem()}
          {this.renderNextItem()}
          {this.renderLastItem()}
        </ul>
        {this.renderPopover()}
      </div>
    );
  }
}

export default Pagination;
