import BaseModal, { Props } from 'modules/Layout/component/Modal';
import React, { useEffect } from 'react';
import { Button, Form as FormStrap } from 'reactstrap';
import Alert from 'modules/Layout/component/Alert';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import { Link } from 'react-router-dom';
import Clause from 'modules/Clause/model/Clause';
import { ROUTE_CLAUSE_JUDGEMENT_ADD_CONTENT } from 'modules/Clause/routes';
import { getPathUrl } from 'modules/Shared/helper/api';

interface IProps extends Props {
  message: Message;
  clearErrorsOnUnmount: () => void;
  onSubmit: (param: { judgment_id: number; content?: string }) => void;
  loading?: boolean;
  title: string;
  judgementId: number;
  clause: Clause;
}

const ClauseAddConnectedJudgements: React.FC<IProps> = (props) => {
  const { toggle, clearErrorsOnUnmount, message, onSubmit, loading, title, judgementId, clause } = props;

  const isValue = React.useRef(false);
  useEffect(() => {
    return () => {
      if (isValue.current) {
        clearErrorsOnUnmount();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (message && !isValue.current) isValue.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const onSubmitRequest = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit({ judgment_id: judgementId });
  };

  const dispalBody = () => {
    return (
      <FormStrap id="manage-element-modal" onSubmit={onSubmitRequest}>
        <Alert message={message} />
        {loading && <Loader />}
        {!!judgementId && (
          <>
            <div className="modal-body-title mb-2">Czy chcesz zaznaczyć odpowiednie fragmenty w orzeczeniu?</div>
            <div className="modal-body-content">Zaznaczenie jest opcjonalne. Możesz utworzyć połączenie bez niego.</div>
          </>
        )}
      </FormStrap>
    );
  };

  const displayFooter = () => {
    return (
      <div className="modal-actions">
        {Boolean(judgementId) && (
          <Link
            to={{
              pathname: getPathUrl(ROUTE_CLAUSE_JUDGEMENT_ADD_CONTENT, { id: clause.id }),
              state: { judgementId, clause }
            }}
            className="btn btn-primary waves-effect waves-light"
          >
            Dodaj zaznaczenie
          </Link>
        )}
        <Button
          type="submit"
          form="manage-element-modal"
          color="primary"
          className="waves-effect waves-light"
          disabled={loading}
        >
          Zapisz
        </Button>
        <Button type="button" className="cancel waves-effect waves-light" onClick={toggle} disabled={loading}>
          Anuluj
        </Button>
      </div>
    );
  };

  return <BaseModal {...props} title={title} body={dispalBody()} footer={displayFooter()} />;
};

export default ClauseAddConnectedJudgements;
