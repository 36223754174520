import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { PaginationMeta, SearchParams } from 'modules/Shared/type';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { ClauseEntity } from 'modules/Clause/model/Clause';
import {
  API_JUDGEMENT_CATEGORIES,
  API_JUDGEMENT_CATEGORY,
  FetchJudgementCategories
} from 'modules/Judgement/repository';
import { ClauseConnectedJudgementValues, ClauseFieldValues } from 'modules/Clause/type';

// w związku z taskiem PZP2UE-1073 kategorie orzeczeń i klauzul są wspóle
// export const API_CLAUSE_CATEGORIES = '/api/clauses/categories';
export const API_CLAUSE_CATEGORIES = API_JUDGEMENT_CATEGORIES;
// export const API_CLAUSE_CATEGORY = '/api/clauses/categories/:id';
export const API_CLAUSE_CATEGORY = API_JUDGEMENT_CATEGORY;
// w związku z taskiem PZP2UE-1177 słowa kluczowe są wspólne i mają zmienione endpointy
// export const API_CLAUSE_KEYWORDS = '/api/clauses/keywords';
// export const API_CLAUSE_ALL_KEYWORDS = '/api/clauses/keywords/all';
export const API_CLAUSE_KEYWORDS = '/api/judgments/keywords/all';
export const API_CLAUSE_ALL_KEYWORDS = '/api/judgments/keywords/all';
export const API_CLAUSES = '/api/clauses';
export const API_CLAUSE = '/api/clauses/:id';
export const API_CLAUSE_JUDGEMENTS = '/api/clauses/:clause_id/judgments';
export const API_CLAUSE_JUDGEMENT = '/api/clauses/:clause_id/judgments/:id';

// w związku z taskiem PZP2UE-1073 kategorie orzeczeń i klauzul są wspóle
export const API_CLAUSE_ALL_CATEGORIES = `${API_JUDGEMENT_CATEGORIES}/all`;

export type FetchClauseCategories = {
  data: JudgementElementEntity[];
  meta: PaginationMeta;
  links: any;
};

export const fetchClauseCategories = (
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<FetchClauseCategories> => axios.get(getAPIUrl(API_CLAUSE_CATEGORIES, null, params), { cancelToken });

export type FetchClauseCategory = {
  data: JudgementElementEntity;
};

export const fetchClauseCategory = (id: string | number, cancelToken?: any): AxiosPromise<FetchClauseCategory> =>
  axios.get(getAPIUrl(API_CLAUSE_CATEGORY, { id }), { cancelToken });

export const deleteClauseCategory = (id: string | number, cancelToken?: any): AxiosPromise =>
  axios.delete(getAPIUrl(API_CLAUSE_CATEGORY, { id }), { cancelToken });

export const createClauseCategory = (data: { name: string }, cancelToken?: any): AxiosPromise<FetchClauseCategory> =>
  axios.post(getAPIUrl(API_CLAUSE_CATEGORIES), data, { cancelToken });

export const updateClauseCategory = (
  id: number,
  data: { name: string },
  cancelToken?: any
): AxiosPromise<FetchClauseCategory> => axios.put(getAPIUrl(API_CLAUSE_CATEGORY, { id }), data, { cancelToken });

export const fetchClauseKeywords = (
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<FetchJudgementCategories> => axios.get(getAPIUrl(API_CLAUSE_KEYWORDS, null, params), { cancelToken });

export type FetchClauses = {
  data: ClauseEntity[];
  meta: PaginationMeta;
  links: any;
};

export type FetchClause = {
  data: ClauseEntity;
};

export const fetchClauses = (params: SearchParams = {}, cancelToken?: any): AxiosPromise<FetchClauses> =>
  axios.get(getAPIUrl(API_CLAUSES, null, params), { cancelToken });

export const deleteClause = (id: string | number, cancelToken?: any): AxiosPromise =>
  axios.delete(getAPIUrl(API_CLAUSE, { id }), { cancelToken });

export const createClause = (data: ClauseFieldValues, cancelToken?: any): AxiosPromise<FetchClause> =>
  axios.post(getAPIUrl(API_CLAUSES), data, { cancelToken });

export const updateClause = (
  id: string | number,
  data: ClauseFieldValues,
  cancelToken?: any
): AxiosPromise<FetchClause> => axios.put(getAPIUrl(API_CLAUSE, { id }), data, { cancelToken });

export const fetchClause = (id: string | number, cancelToken?: any): AxiosPromise<FetchClause> =>
  axios.get(getAPIUrl(API_CLAUSE, { id }), { cancelToken });

export const fetchClauseConnectedJudgements = (
  clause_id: string | number,
  cancelToken?: any
): AxiosPromise<{ data: ClauseConnectedJudgementValues[] }> =>
  axios.get(getAPIUrl(API_CLAUSE_JUDGEMENTS, { clause_id }), { cancelToken });

export const addClauseConnectedJudgements = (
  clause_id: string | number,
  data: { judgment_id: number; content: string },
  cancelToken?: any
): AxiosPromise<{ data: ClauseConnectedJudgementValues }> =>
  axios.post(getAPIUrl(API_CLAUSE_JUDGEMENTS, { clause_id }), data, { cancelToken });

export const deleteClauseConnectedJudgements = (
  clause_id: string | number,
  id: string | number,
  cancelToken?: any
): AxiosPromise => axios.delete(getAPIUrl(API_CLAUSE_JUDGEMENT, { clause_id, id }), { cancelToken });

export const fetchClauseAllKeywords = (
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<{ data: JudgementElementEntity[] }> =>
  axios.get(getAPIUrl(API_CLAUSE_ALL_KEYWORDS, null, params), { cancelToken });

// wspólne
export const fetchClauseAllCategories = (
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<{ data: JudgementElementEntity[] }> =>
  axios.get(getAPIUrl(API_CLAUSE_ALL_CATEGORIES, null, params), { cancelToken });
