import { addToastAction, managePageAction } from 'modules/Layout/action';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import Loader from 'modules/Layout/component/Loader';
import { useDispatch } from 'react-redux';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { push } from 'connected-react-router';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import CardBox from 'modules/Layout/component/CardBox';
import { breadcrumbRouteForeignProceedingsAlertCreate } from '../../../breadcrumbs';
import { createForeignProceedingsAlertToastSuccess } from '../../../toasts';
import { saveFilter as saveFilterRequest } from '../../../../Layout/repository';
import { ROUTE_FOREIGN_PROCEEDINGS_ALERTS } from '../../../routes';
import { removeBracersFromKeys } from '../../../../Shared/helper/hooks/useSaveFilter';
import { FiltersEntity } from '../../../../Layout/model/Filters';
import AlertForm, { AlertFormData } from '../../../component/Form/AlertForm';
import fetchRequest from '../../../../Shared/helper/APIRequests/fetchRequest';
import { fetchForeignProceedings } from '../../../repository';
import ForeignProceedings from '../../../model/ForeignProceedings';
import ForeignProceedingsTable from '../../../container/ForeignProceedingsList/Table';

const ForeignProceedingsAlertCreateView = () => {
  const dispatch = useDispatch();
  const [alert, setAlert] = useState<AlertFormData>({
    name: '',
    filters: {},
    created_at: '',
    updated_at: ''
  });
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState(null);
  const [foreignProceedingsList, setForeignProceedingsList] = useState<ForeignProceedings[]>(null);
  const [fetchingList, setFetchingList] = useState(false);
  const scrollToTopOnce = useRef<boolean>(true);

  const cancelToken = useCancelToken();
  useEffect(() => {
    dispatch(
      managePageAction({
        title: 'Tworzenie alertu postępowania',
        breadcrumb: breadcrumbRouteForeignProceedingsAlertCreate()
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchCreateForeignProceedingsAlertToast = () =>
    dispatch(addToastAction(createForeignProceedingsAlertToastSuccess()));

  const fetchProceedings = useCallback(async () => {
    if (!alert?.filters) return;

    setFetchingList(true);

    const { slug_source, ...otherFilters } = alert?.filters;

    const filtersAsParams = {
      ...otherFilters,
      industry_type_id: alert.filters.industry_sub_type_id
        ? alert.filters.industry_sub_type_id
        : alert.filters.industry_type_id,
      ...(Array.isArray(slug_source)
        ? slug_source.reduce((acc, slug, index) => {
            acc[`slug_source[${index}]`] = String(slug);
            return acc;
          }, {} as Record<string, string>)
        : {})
    };

    const {
      data: reqData,
      cancelled,
      message: messageResponse
    } = await fetchRequest(fetchForeignProceedings, filtersAsParams, cancelToken);

    if (cancelled) return;
    if (reqData) setForeignProceedingsList(reqData.data);
    if (messageResponse) setMessage(messageResponse);

    setFetchingList(false);
  }, [alert?.filters, cancelToken]);

  useEffect(() => {
    // Call fetchProceedings directly in useEffect
    fetchProceedings().then((r) => r);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCreateForeignProceedingsAlertAction = async (data: FiltersEntity, name: string, isDefault?: boolean) => {
    setFetching(true);

    if (message) setMessage(null);
    if (errors) setErrors(null);

    const {
      message: messageResponse,
      error,
      cancelled,
      data: proceedingsData
    } = await updateRequestWithData(
      saveFilterRequest,
      { name, slug: 'foreign_proceedings_alerts', filters: removeBracersFromKeys(data), default: isDefault },
      cancelToken
    );

    if (cancelled) return;

    if (proceedingsData) {
      dispatchCreateForeignProceedingsAlertToast();
      dispatch(push(ROUTE_FOREIGN_PROCEEDINGS_ALERTS));
    } else {
      if (error) setErrors(error);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
  }, [errors]);

  return (
    <>
      <div className="user-create-view">
        <CardBox heading="Dodaj alert">
          {fetching && <Loader />}
          <Alert message={message} />
          <AlertForm
            setValues={setAlert}
            alertData={alert}
            errors={errors}
            fetchForeignProceedings={fetchProceedings}
            submit={(data, name) => setCreateForeignProceedingsAlertAction(data, name)}
          />
        </CardBox>
      </div>
      {fetchingList ? (
        <Loader />
      ) : (
        <ForeignProceedingsTable foreignProceedingsList={foreignProceedingsList} isAdmin={false} />
      )}
    </>
  );
};

export default ForeignProceedingsAlertCreateView;
