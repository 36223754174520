import React, { useEffect, useRef, useState } from 'react';
import { AGREEMENT_TYPE_SUPPLEMENT, AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { getStepValues, PREVIEW_PLACEHOLDER } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_WORK_REQUIREMENTS } from 'modules/Agreements/step';
import { CustomInput, FormFeedback, FormGroup } from 'reactstrap';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { isEqual } from 'lodash';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import Part from 'modules/Agreements/model/Part';
import Accordion from 'modules/Layout/component/Accordion';

export interface AgreementStepWorkRequirementsValues {
  same_work_requirements_for_parts: boolean;
  work_requirements: {
    works_checked: boolean;
    works_content: string | null;
    contractor_requests_checked: boolean;
    contractor_requests_content: string | null;
    devices_checked: boolean;
    devices_content: string | null;
    executor_will_provide_checked: boolean;
    executor_will_provide_content: string | null;
    installations_checked: boolean;
    installations_content: string | null;
    materials_checked: boolean;
    materials_content: string | null;
    part_id: number | null;
    performance_properties_checked: boolean;
    performance_properties_content: string | null;
  }[];
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const WORKS_CONTENT = `Zamawiający określa poniższe wymagania dla robót: ${PREVIEW_PLACEHOLDER}`;
const MATERIALS_CONTENT = `Zamawiający określa poniższe wymagania dla materiałów: ${PREVIEW_PLACEHOLDER}`;
const DEVICES_CONTENT = `Zamawiający określa poniższe wymagania dla urządzeń: ${PREVIEW_PLACEHOLDER}`;
const INSTALLATIONS_CONTENT = `Zamawiający określa poniższe wymagania dla instalacji: ${PREVIEW_PLACEHOLDER}`;
const PERFORMANCE_PROPERTIES_CONTENT =
  'Zgodnie z art. 10 ustawy Prawo budowlane przy wykonywaniu robót budowlanych należy stosować wyroby budowlane o właściwościach użytkowych umożliwiających prawidłowo zaprojektowanym i wykonanym obiektom budowlanym spełnienie wymagań podstawowych, dopuszczone do obrotu i powszechnego lub jednostkowego stosowania w budownictwie';
const EXECUTOR_WILL_PROVIDE_CONTENT =
  'Wykonawca zapewni, na żądanie zamawiającego, przeprowadzenie przez wyspecjalizowane jednostki badań konstrukcyjnych, jakości robót wykonywanych z materiałów wykonawcy, a także ciężaru i ilości zużytych materiałów. Koszty tych badań obciążą wykonawcę, jeżeli wyniki takich badań wykażą rozbieżności w stosunku do oświadczeń wykonawcy w tym zakresie';
const CONTRACTOR_REQUESTS_CONTENT =
  'Jeżeli zamawiający zażąda badań, które nie były przewidziane Umową, wykonawca jest zobowiązany przeprowadzić te badania, a ich koszty obciążą jedną ze stron Umowy - stosownie do wyniku badań';

const initContentValues = {
  works_content: WORKS_CONTENT,
  materials_content: MATERIALS_CONTENT,
  devices_content: DEVICES_CONTENT,
  installations_content: INSTALLATIONS_CONTENT,
  performance_properties_content: PERFORMANCE_PROPERTIES_CONTENT,
  executor_will_provide_content: EXECUTOR_WILL_PROVIDE_CONTENT,
  contractor_requests_content: CONTRACTOR_REQUESTS_CONTENT
};

const mapState = (step: AgreementStepWorkRequirementsValues): AgreementStepWorkRequirementsValues => {
  const { work_requirements, checkboxes, ...rest } = step;

  return {
    ...rest,
    checkboxes: checkboxes || {},
    work_requirements: work_requirements.map((part) => {
      const {
        works_content,
        materials_content,
        devices_content,
        installations_content,
        performance_properties_content,
        executor_will_provide_content,
        contractor_requests_content
      } = part;

      return {
        ...part,
        works_content: works_content || WORKS_CONTENT,
        materials_content: materials_content || MATERIALS_CONTENT,
        devices_content: devices_content || DEVICES_CONTENT,
        installations_content: installations_content || INSTALLATIONS_CONTENT,
        performance_properties_content: performance_properties_content || PERFORMANCE_PROPERTIES_CONTENT,
        executor_will_provide_content: executor_will_provide_content || EXECUTOR_WILL_PROVIDE_CONTENT,
        contractor_requests_content: contractor_requests_content || CONTRACTOR_REQUESTS_CONTENT
      };
    })
  };
};

const AgreementStepWorkRequirements = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, onSubmit, onChange, errors } = props;
  const initState = useRef<AgreementStepWorkRequirementsValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_WORK_REQUIREMENTS))
  );
  const [stepValues, setStepValues] = useState<AgreementStepWorkRequirementsValues>(mapState(initState.current));
  const { parts_ids, few_parts } = agreement;
  const { work_requirements, same_work_requirements_for_parts } = stepValues;

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_WORK_REQUIREMENTS));
    setStepValues(mapState(initState.current));
  }, [steps]);

  const isMultiple = few_parts && !same_work_requirements_for_parts;

  const { renderPartSwitch, renderPartContent, renderPartEditableCheckbox, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    partSlug: 'work_requirements',
    initContentValues
  });

  const renderPart = (partObj: Part, index: number) => {
    const part = isMultiple ? work_requirements[index] : work_requirements[0];
    const { works_checked, materials_checked, devices_checked, installations_checked } = part;

    const body = (
      <FormGroup>
        {renderPartSwitch(
          'works_checked',
          'Czy zamawiający chce określić w Umowie wymagania dotyczące robót?',
          index,
          part,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {works_checked && renderPartContent('works_content', index, part, agreement.type === AGREEMENT_TYPE_SUPPLEMENT)}
        {renderPartSwitch(
          'materials_checked',
          'Czy zamawiający chce określić w Umowie wymagania dotyczące materiałów?',
          index,
          part,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {materials_checked &&
          renderPartContent('materials_content', index, part, agreement.type === AGREEMENT_TYPE_SUPPLEMENT)}
        {renderPartSwitch(
          'devices_checked',
          'Czy zamawiający chce określić w Umowie wymagania dotyczących urządzeń?',
          index,
          part,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {devices_checked &&
          renderPartContent('devices_content', index, part, agreement.type === AGREEMENT_TYPE_SUPPLEMENT)}
        {renderPartSwitch(
          'installations_checked',
          'Czy zamawiający chce określić w Umowie wymagania dotyczące instalacji?',
          index,
          part,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {installations_checked &&
          renderPartContent('installations_content', index, part, agreement.type === AGREEMENT_TYPE_SUPPLEMENT)}
        {renderPartEditableCheckbox(
          'performance_properties',
          index,
          part,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderPartEditableCheckbox(
          'executor_will_provide',
          index,
          part,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderPartEditableCheckbox(
          'contractor_requests',
          index,
          part,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderCustomCheckboxes(
          'general',
          partObj?.id,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
      </FormGroup>
    );

    if (isMultiple) {
      return (
        <Accordion
          key={`renderPartAccordion-${partObj.id}`}
          isInvalid={hasError(errors, `work_requirements.${index}`)}
          accordionContentStyle={{ padding: '1rem' }}
          entity={{
            title: partObj.getName(index),
            content: body
          }}
        />
      );
    }

    return body;
  };

  const samePartsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setStepValues((values) =>
      mapState({
        ...values,
        same_work_requirements_for_parts: checked,
        work_requirements: checked
          ? [
              {
                ...work_requirements[0],
                part_id: null
              }
            ]
          : parts_ids.map((part) => ({
              ...work_requirements[0],
              part_id: part.id
            }))
      })
    );
  };

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      {few_parts && (
        <FormGroup>
          <CustomInput
            id="same_work_requirements_for_parts"
            name="same_work_requirements_for_parts"
            type="switch"
            checked={same_work_requirements_for_parts}
            onChange={samePartsChange}
            disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
            invalid={hasError(errors, 'same_work_requirements_for_parts')}
            label="Czy wymagania dotyczące robót, materiałów, urządzeń i instalacji są wspólne dla wszystkich zadań?"
          />
          {hasError(errors, 'same_work_requirements_for_parts') && (
            <FormFeedback className="d-block">{getError(errors, 'same_work_requirements_for_parts')}</FormFeedback>
          )}
        </FormGroup>
      )}
      {(isMultiple ? parts_ids : [null]).map(renderPart)}
      {isMultiple && (
        <>
          <p className="h4">Ponadto dla każdego z zadań:</p>
          {renderCustomCheckboxes(
            null,
            null,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )}
        </>
      )}
    </AgreementFormWrapper>
  );
};

export default AgreementStepWorkRequirements;
