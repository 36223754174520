import { ValidationErrors } from 'modules/Shared/type';
import React, { useState } from 'react';
import { Button, Form as FormStrap, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { suffixLabel } from 'modules/Layout/helper/misc';

export interface Props {
  busy: boolean;
  errors?: ValidationErrors;
  submit: (email: string) => void;
}

const ResendTokenForm: React.FC<Props> = ({ busy, errors, submit }) => {
  const [email, setEmail] = useState('');

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    submit(email);
  };

  return (
    <FormStrap onSubmit={onSubmit}>
      <FormGroup className="form-group-large-margins">
        <Label>{suffixLabel('Adres email', true)}</Label>
        <Input
          type="email"
          name="email"
          id="input-email"
          placeholder="Adres email*"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          invalid={hasError(errors, 'email')}
          required
        />
        {hasError(errors, 'email') && <FormFeedback>{getError(errors, 'email')}</FormFeedback>}
      </FormGroup>
      <FormGroup className="form-actions form-group-last">
        <Button type="submit" color="primary" disabled={busy} className="pzpeu-btn-disabled waves-effect waves-light">
          Wyślij
        </Button>
      </FormGroup>
    </FormStrap>
  );
};

export default ResendTokenForm;
