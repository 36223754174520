import { Action, Dispatch } from 'redux';
import { SubscriptionEntity } from 'modules/Subscriptions/model/Subscription';
import { fetchSubscription } from 'modules/Subscriptions/repository';
import { ColorVariants, Message } from 'modules/Shared/type';

export const SUBSCRIPTIONS_FETCHING = 'SUBSCRIPTIONS/FETCHING';
export const SUBSCRIPTIONS_FETCHED = 'SUBSCRIPTIONS/FETCHED';

export type SubscriptionsAction = FetchingSubscriptionsAction | SubscriptionsFetchedAction;

export type FetchingSubscriptionsAction = Action<typeof SUBSCRIPTIONS_FETCHING>;

export const fetchingSubscriptionsAction = (): FetchingSubscriptionsAction => ({
  type: SUBSCRIPTIONS_FETCHING
});

export interface SubscriptionsFetchedAction extends Action<typeof SUBSCRIPTIONS_FETCHED> {
  payload: { subscriptions: SubscriptionEntity[]; errorMessage?: Message };
}

export const subscriptionsFetchedAction = (
  subscriptions: SubscriptionEntity[],
  errorMessage?: Message
): SubscriptionsFetchedAction => ({
  type: SUBSCRIPTIONS_FETCHED,
  payload: { subscriptions, errorMessage }
});

export const fetchSubscriptionsAction = () => async (dispatch: Dispatch) => {
  dispatch(fetchingSubscriptionsAction());

  try {
    const response = await fetchSubscription();

    dispatch(subscriptionsFetchedAction(response.data.data));
  } catch (error) {
    const errorMessage = {
      value: error?.error?.response?.data?.message || error?.message || 'Something went wrong.',
      variant: ColorVariants.Danger
    };

    dispatch(subscriptionsFetchedAction([], errorMessage));
  }
};

export type SubscriptionsSelectedActionTypes = SubscriptionsSelectedAction | SubscriptionsWantInvoiceAction;

export const SUBSCRIPTIONS_SELECTED = 'SUBSCRIPTIONS/SELECTED';
export const SUBSCRIPTIONS_WANT_INVOICE = 'SUBSCRIPTIONS/WANT_INVOICE';
export const SUBSCRIPTIONS_SELECTED_CLEAR = 'SUBSCRIPTIONS/SELECTED/CLEAR';

export interface SubscriptionsSelectedAction extends Action<typeof SUBSCRIPTIONS_SELECTED> {
  payload: number[];
}

export interface SubscriptionsWantInvoiceAction extends Action<typeof SUBSCRIPTIONS_WANT_INVOICE> {
  payload: boolean;
}

export const subscriptionsSelectedAction = (subscription_id: number[]): SubscriptionsSelectedAction => ({
  type: SUBSCRIPTIONS_SELECTED,
  payload: subscription_id
});

export const subscriptionsWantInvoiceAction = (wants_invoice: boolean): SubscriptionsWantInvoiceAction => ({
  type: SUBSCRIPTIONS_WANT_INVOICE,
  payload: wants_invoice
});
