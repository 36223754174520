import _has from 'lodash/has';
import { ValidationErrors } from 'modules/Shared/type';
import React, { useState } from 'react';
import { Button, Form as FormStrap } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_JUDGEMENT, ROUTE_JUDGEMENT_LIST } from 'modules/Judgement/routes';
import Judgement from 'modules/Judgement/model/Judgement';
import { JudgementFieldValues } from 'modules/Judgement/type';
import { EditorState } from 'draft-js';
import { DATE_FORMAT_SLASH, DATE_FORMAT_VALUE, formatDateIfExist } from 'modules/Shared/helper/utils';
import JudgementBasicFieldset from 'modules/Judgement/component/Fieldset/Judgement/Basic';
import moment from 'moment';

export interface Props {
  errors?: ValidationErrors;
  disabled?: boolean;
  judgement?: Judgement;
  submit: (data: JudgementFieldValues) => void;
}

const createValues = (judgement?: Judgement) => {
  let valueObj: Omit<JudgementFieldValues, 'categories'> & {
    contentWysiwyg?: EditorState;
    categories: { id: number; name: string }[];
  } = {
    authority_name: judgement?.authority_name ?? '',
    authority_type: judgement?.authority_type ?? '',
    document_type: judgement?.document_type ?? '',
    identifier: judgement?.identifier ?? '',
    place: judgement?.place ?? '',
    published_at: formatDateIfExist(judgement?.published_at),
    categories: judgement?.categories ?? []
  };

  if (!judgement) {
    valueObj = {
      ...valueObj,
      keywords: []
    };
  }

  return valueObj;
};

const JudgementForm: React.FC<Props> = ({ errors, disabled, submit, judgement }) => {
  const [values, setValue] = useState(createValues(judgement));

  const onChange = (event: React.ChangeEvent<HTMLInputElement> | string, key: string) => {
    let value = '';
    if (typeof event === 'string') {
      value = event;
    } else {
      value = event.target.value;
    }

    if (_has(values, key)) {
      setValue({ ...values, [key]: value });
    }
  };

  const onCategoriesChange = (categoriesValue: { id: number; name: string }[]) =>
    setValue({ ...values, categories: categoriesValue });
  const onKeywordsChange = (keywordsValue: number[]) => setValue({ ...values, keywords: keywordsValue });

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { categories, published_at, ...rest } = values;

    const momentObj = moment(published_at, DATE_FORMAT_SLASH, true);
    if (!momentObj.isValid()) return;

    submit({
      ...rest,
      categories: categories.map((el) => el.id),
      published_at: published_at ? moment(published_at, DATE_FORMAT_SLASH).format(DATE_FORMAT_VALUE) : ''
    });
  };

  return (
    <FormStrap onSubmit={onSubmit}>
      <JudgementBasicFieldset
        disabled={disabled}
        errors={errors}
        values={values}
        onChange={onChange}
        onCategoriesChange={onCategoriesChange}
        onKeywordsChange={onKeywordsChange}
        required
      />

      <div className="form-actions">
        <Button type="submit" color="primary" className="waves-effect waves-light">
          Zapisz
        </Button>
        <Link
          to={judgement ? getPathUrl(ROUTE_JUDGEMENT, { id: judgement.id }) : ROUTE_JUDGEMENT_LIST}
          className="cancel waves-effect waves-light"
        >
          Anuluj
        </Link>
      </div>
    </FormStrap>
  );
};

export default JudgementForm;
