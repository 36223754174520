import React, { useState } from 'react';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import ActionDownload from 'modules/Layout/component/Action/Download';
import FileButton from 'modules/Layout/component/Button/File';
import _uniqueId from 'lodash/uniqueId';
import { FormFeedback } from 'reactstrap';
import './index.scss';
import fetchFileRequest from 'modules/Shared/helper/APIRequests/fetchFileRequest';
import { fetchFileFromUrl } from './repository';

export type IFile = {
  file?: File;
  id?: string;
  name?: string;
  url?: string;
};

type Props = {
  files: IFile[];
  onChange: (files: IFile[]) => void;
  maxSizeInMb?: number;
};

const ManageFiles: React.FC<Props> = ({ files = [], onChange, maxSizeInMb }) => {
  const [displayError, setDisplayError] = useState(false);

  const removeFile = (fileId: string) => {
    onChange(files.filter((file) => file?.id !== fileId));
  };

  const addFile = (newFiles: FileList) => {
    const fileArr = [];
    let combinedSize = 0;

    for (let i = 0; i < newFiles.length; i += 1) {
      if (maxSizeInMb) {
        const sizeInMb = newFiles[i].size / (1024 * 1024);

        if (maxSizeInMb < sizeInMb) {
          if (!displayError) setDisplayError(true);
          return;
        }

        fileArr.push({ file: newFiles[i], id: _uniqueId(newFiles[i]?.name ?? ''), name: newFiles[i]?.name ?? '' });
        combinedSize += newFiles[i]?.size;
      }
    }

    if (maxSizeInMb) {
      // eslint-disable-next-line
      const sizeInMb = combinedSize / (1024 * 1024);

      if (maxSizeInMb < sizeInMb) {
        if (!displayError) setDisplayError(true);
        return;
      }

      if (displayError) setDisplayError(false);
    }

    onChange([...files, ...fileArr]);
  };

  const odDownload = async (file: IFile) => {
    await fetchFileRequest(fetchFileFromUrl, file.url);
  };

  return (
    <>
      <FileButton label="DODAJ ZAŁĄCZNIK" onChange={addFile} multiple />
      {displayError && (
        <FormFeedback className="d-block">Rozmiar plików nie może przekroczyć {maxSizeInMb} MB.</FormFeedback>
      )}
      <div className="files-wrapper">
        {files.map((file) => (
          <div key={file?.id} className="file-entry">
            <ActionDelete className="mr-2" title="Usuń plik" onClick={() => removeFile(file?.id)} />
            {file.url && <ActionDownload className="mr-2" title="Pobierz plik" onClick={() => odDownload(file)} />}
            <span>{file?.name}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default ManageFiles;
