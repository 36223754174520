import React, { useState } from 'react';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_CONTRACT_SING } from 'modules/Agreements/step';
import { ContractSingValues } from 'modules/Agreements/components/Steps/Default/ContractSing';
import { AgreementStepProps } from 'modules/Agreements/type';
import { Button } from 'reactstrap';

const AgreementStepSignatureVerification = (props: AgreementStepProps): JSX.Element => {
  const { steps } = props;
  const [contractSingValues] = useState<ContractSingValues>(getStepValues(steps, AGREEMENT_STEP_CONTRACT_SING));
  const [showCredentials, setShowCredentials] = useState(false);
  return (
    <div>
      <p>
        W celu weryfikacji podpisu należy nacisnąć przycisk poniżej. Zostaniesz przekierowany na stronę
        weryfikacjapodpisu.pl
      </p>
      <p>Użyj następujących danych do logowania w celu sprawdzenia podpisu:</p>
      <div style={{ marginBottom: '1rem' }} className="agreement-preview-action">
        <Button onClick={() => setShowCredentials(!showCredentials)}>Ujawnij dane do konta</Button>
      </div>
      <p>{`Login: ${showCredentials ? contractSingValues.signing_verify_account.user : '*****'}`}</p>
      <p>{`Hasło: ${showCredentials ? contractSingValues.signing_verify_account.password : '*****'}`}</p>
      <div style={{ marginBottom: '1rem' }} className="agreement-preview-action">
        <a
          href="https://weryfikacjapodpisu.pl"
          target="_blank"
          rel="noreferrer"
          className="pzpeu-btn-disabled waves-effect waves-light btn btn-primary"
        >
          weryfikacjapodpisu.pl
        </a>
      </div>
    </div>
  );
};

export default AgreementStepSignatureVerification;
