import React, { useState } from 'react';
import { API_PAYMENT_CANCEL_PAYMENT_ERROR_CODE, startTraditionalPayment } from 'modules/Subscriptions/repository';
import { Button, Form as FormStrap } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/reducer';
import Alert from 'modules/Layout/component/Alert';
import Subscription from 'modules/Subscriptions/model/Subscription';
import { ColorVariants, Message } from 'modules/Shared/type';
import CardBox from 'modules/Layout/component/CardBox';
import Loader from 'modules/Layout/component/Loader';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Link } from 'react-router-dom';
import { ROUTE_SUBSCRIPTIONS } from 'modules/Subscriptions/routes';
import { ROUTE_PROFILE_PAYMENT_LOG } from 'modules/Profile/routes';
import SummaryTable from 'modules/Subscriptions/container/SummaryTable';
import CompanyForSubscriptionSummaryDataset from 'modules/User/component/Dataset/CompanyForSubscriptionSummary';
import { getPathUrl } from 'modules/Shared/helper/api';
import { addToastAction } from 'modules/Layout/action';
import { startTraditionalPaymentSuccess } from 'modules/Subscriptions/toasts';
import { push } from 'connected-react-router';
import DisplayPopovers from 'modules/Layout/component/Popover/DisplayWrapper';
import CancelPaymentModal from 'modules/Subscriptions/component/CancelPaymentModal';
import 'modules/Subscriptions/component/SummaryForm/index.scss';
import { PaymentHistoryValues } from 'modules/Profile/type';

type Props = {
  subscriptions: Subscription[];
  subscriptionsId: number[];
};

const TraditionalPaymentForm: React.FC<Props> = ({ subscriptionsId, subscriptions }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const [cancelPaymentModalStatus, setCancelPaymentModalStatus] = useState(false);
  const [cancelPaymentModalTransactions, setCancelPaymentModalTransactions] = useState([]);

  const setError = (error: string) =>
    setMessage({
      value: error,
      variant: ColorVariants.Danger
    });

  const cancelToken = useCancelToken();

  const showCancelPaymentModal = (payments: PaymentHistoryValues[]) => {
    setCancelPaymentModalTransactions(payments);
    return setCancelPaymentModalStatus(true);
  };

  const hideCancelPaymentModal = () => {
    setCancelPaymentModalTransactions([]);
    return setCancelPaymentModalStatus(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await makeApiCall();
  };

  const makeApiCall = async (cancelPaymentStatus: boolean = null) => {
    setLoading(true);

    startTraditionalPayment(
      { subscriptions: subscriptionsId, cancel_previous_payments: cancelPaymentStatus },
      cancelToken
    )
      .then(async (response) => {
        setLoading(false);

        dispatch(addToastAction(startTraditionalPaymentSuccess()));

        const { payment_number: paymentNumber } = response.data;

        dispatch(push(getPathUrl(ROUTE_PROFILE_PAYMENT_LOG, { id: paymentNumber })));
      })
      .catch((error) => {
        setLoading(false);

        if (error.error.response.data.status === API_PAYMENT_CANCEL_PAYMENT_ERROR_CODE) {
          return showCancelPaymentModal(error.response.data.data);
        }

        setError(error?.error?.response?.data?.message || error?.message || 'Something went wrong.');
      });
  };

  const selectedProducts = subscriptionsId.map((id) => subscriptions.find((subscription) => subscription.id === id));

  return (
    <>
      <CardBox
        className="mb-0"
        heading="Płatność po otrzymaniu faktury"
        paddingRight="30px"
        paddingBottom="0"
        actions={
          <Link to={{ pathname: getPathUrl(ROUTE_SUBSCRIPTIONS), state: { doNotClearData: true } }}>
            <span className="link-with-border">ZMIEŃ SWÓJ WYBÓR</span>
          </Link>
        }
      >
        {loading && <Loader />}
        <Alert message={message} />
        <FormStrap id="summary-form" onSubmit={handleSubmit}>
          <SummaryTable selectedProducts={selectedProducts} userModules={user.modules} />
          <div className="billing-details-wrapper">
            <div className="billing-details-title">Dane rozliczeniowe</div>
            <DisplayPopovers
              popoverId="headlinePopover"
              popoverContent="Dane używane do wystawienia i wysłania faktury"
              className="tooltip-light subscriptions-tooltip-description"
            />
          </div>
          <div className="company-summary-dataset">
            <CompanyForSubscriptionSummaryDataset user={user} />
          </div>
        </FormStrap>
        <div className="mt-4 mb-3 w-50 billing-details-title">
          W ciągu 24 godzin wyślemy na podany adres e-mail fakturę do opłaty wybranych produktów. Dostęp uzyskasz
          niezwłocznie po dokonaniu płatności.
        </div>
      </CardBox>
      <div className="summary-form-actions d-flex justify-content-center justify-content-lg-around flex-wrap ">
        <Button
          color="primary"
          type="submit"
          form="summary-form"
          className="waves-effect summary-form-action waves-light pzpeu-btn-flex"
          disabled={loading}
        >
          Zamawiam
        </Button>
      </div>
      <CancelPaymentModal
        isOpen={cancelPaymentModalStatus}
        payments={cancelPaymentModalTransactions}
        toggle={hideCancelPaymentModal}
        submit={() => {
          hideCancelPaymentModal();
          makeApiCall(true);
        }}
      />
    </>
  );
};

export default TraditionalPaymentForm;
