import React from 'react';
import { getPathUrl } from 'modules/Shared/helper/api';
import { calculateDiffInDays, formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { DashboardClientJudgement } from 'modules/Layout/type';
import { Link } from 'react-router-dom';
import InformationList from 'modules/Layout/component/InformationList';
import { ROUTE_JUDGEMENT_CLIENT } from 'modules/JudgementClient/routes';
import './lastVisitedExecutor.scss';
import Tooltip from '../Tooltip';

type Props = { judgment: DashboardClientJudgement };

const LastVisitedJudgments: React.FC<Props> = ({ judgment }) => {
  const daysLate = (date: string) => calculateDiffInDays(date);

  const daysCorrectForm = (days: number) => {
    switch (days) {
      case 1:
        return 'dzień';
      default:
        return 'dni';
    }
  };

  const redirectLink = (value: string, id: number, showTooltip: boolean = false) => (
    <>
      <Link
        id={`dashboard-judgments-link-${id}`}
        className="pzpeu-link"
        to={getPathUrl(ROUTE_JUDGEMENT_CLIENT, { id })}
      >
        {value}
      </Link>
      {showTooltip && value?.length >= 20 && (
        <Tooltip targetId={`dashboard-judgments-link-${id}`} tooltipContent={value} tooltipPlacement="bottom-start" />
      )}
    </>
  );

  const calculateLastVisit = () => {
    if (!judgment?.visited_at) return '-';

    const lastVisitDays = daysLate(judgment.visited_at);
    const lastVisitDaysRounded = Math.ceil(lastVisitDays);

    if (lastVisitDaysRounded === 0) return 'dzisiaj';
    return `${lastVisitDaysRounded} ${daysCorrectForm(lastVisitDaysRounded)} temu`;
  };

  return (
    <div className="last-visited-executor">
      <div className="executor-title">{redirectLink(judgment?.identifier, judgment?.id, true)}</div>
      <InformationList
        fields={[
          {
            label: 'Ostatnia weryfikacja',
            value: calculateLastVisit()
          },
          {
            label: 'Nazwa organu publikującego dokument',
            value: judgment?.authority_name || '-'
          },
          {
            label: 'Kategorie przypisane do orzeczenia',
            value: judgment?.categories_names || '-'
          },
          {
            label: 'Termin publikacji',
            value: ifContainsDateFormat(judgment?.published_at, formatDate)
          }
        ]}
      />
    </div>
  );
};

export default LastVisitedJudgments;
