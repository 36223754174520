import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { AGREEMENT_TYPE_PROJECT, AgreementStepProps } from 'modules/Agreements/type';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_CONTRACTOR_EXECUTOR } from 'modules/Agreements/step';
import { Button, CustomInput, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { suffixLabel } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/reducer';
import { isEqual } from 'lodash';
import User from 'modules/User/model/User';
import Accordion from 'modules/Layout/component/Accordion';
import _set from 'lodash/set';
import {
  CONTRACTOR_CLEAR_OBJ,
  EXECUTOR_CLEAR_OBJ,
  executorGroupTypeOptions,
  getValuePath
} from 'modules/Agreements/components/Steps/Default/ContractorExecutor';
import { fetchExecutorFromGus } from 'modules/Executor/repository';
import _isEmpty from 'lodash/isEmpty';
import { addToastAction } from 'modules/Layout/action';
import { executorFromGusToastSuccess, executorFromGusToastError } from 'modules/Executor/toasts';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import Select from 'modules/Layout/component/Input/Select';

export interface AgreementStepContractorExecutorValues {
  contractor: {
    address: string | null;
    countersignature: string | null;
    name: string | null;
    nip: string | null;
    representative: string | null;
    of_representative: string | null;
    group: boolean;
    companies: {
      address: string | null;
      countersignature: string | null;
      name: string | null;
      nip: string | null;
      representative: string | null;
    }[];
  };
  executor: {
    address: string | null;
    krs: string | null;
    name: string | null;
    nip: string | null;
    representative: string | null;
    share_capital: number | null;
    of_representative: string | null;
    group_other: string | null;
    group_type: number | null;
    companies: {
      address: string | null;
      krs: string | null;
      name: string | null;
      nip: string | null;
      representative: string | null;
      share_capital: number | null;
    }[];
  };
}

const mapState = (step: AgreementStepContractorExecutorValues, user: User): AgreementStepContractorExecutorValues => {
  const { contractor, executor } = step;
  const { name, address, nip, ...rest } = contractor;

  return {
    executor,
    contractor: {
      name: name || user.getCompanyName(),
      address: address || user.getCompanyAddress(),
      nip: nip || user.company?.nip,
      ...rest
    }
  };
};

const AgreementStepContractorExecutor = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, onSubmit, onChange, errors } = props;
  const { user } = useSelector((state: RootState) => state.auth);
  const initState = useRef<AgreementStepContractorExecutorValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_CONTRACTOR_EXECUTOR), user)
  );
  const [stepValues, setStepValues] = useState<AgreementStepContractorExecutorValues>({ ...initState.current });

  const dispatch = useDispatch();

  const [contractorFromGus, setContractorFromGus] = useState(null);
  const [contractorFillByHandStatus, setContractorFillByHandStatus] = useState(false);
  const [contractorFilledCompany, setContractorFilledCompany] = useState([]);
  const [contractorFilledByHandCompany, setContractorFilledByHandCompany] = useState([]);

  const [executorFromGus, setExecutorFromGus] = useState(null);
  const [executorFillByHandStatus, setExecutorFillByHandStatus] = useState(false);
  const [executorFilledCompany, setExecutorFilledCompany] = useState([]);
  const [executorFilledByHandCompany, setExecutorFilledByHandCompany] = useState([]);

  useEffect(() => {
    if (stepValues.contractor.nip) {
      setContractorFromGus({});
    }
    if (stepValues.executor.nip) {
      setExecutorFromGus({});
    }

    const contractorFilledCompanyDart = stepValues.contractor.companies.reduce(
      (acc, item, index) => (item.nip ? [...acc, index] : acc),
      []
    );

    setContractorFilledCompany(contractorFilledCompanyDart);

    const executorFilledCompanyDart = stepValues.executor.companies.reduce(
      (acc, item, index) => (item.nip ? [...acc, index] : acc),
      []
    );

    setExecutorFilledCompany(executorFilledCompanyDart);
  }, []);

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_CONTRACTOR_EXECUTOR), user);
    setStepValues({ ...initState.current });
  }, [steps]);

  const contractorFields = [
    {
      type: 'contractor',
      field: 'name',
      label: suffixLabel('Nazwa zamawiającego', true)
    },
    {
      type: 'contractor',
      field: 'address',
      label: suffixLabel('Siedziba zamawiającego', true)
    },
    {
      type: 'contractor',
      field: 'nip',
      label: suffixLabel('NIP', true)
    },
    {
      type: 'contractor',
      field: 'representative',
      label: suffixLabel('Reprezentowana przez')
    },
    {
      type: 'contractor',
      field: 'countersignature',
      label: suffixLabel('Kontrasygnata')
    }
  ];

  const executorFields = [
    {
      type: 'executor',
      field: 'name',
      label: suffixLabel('Nazwa wykonawcy', true)
    },
    {
      type: 'executor',
      field: 'address',
      label: suffixLabel('Siedziba wykonawcy', true)
    },
    {
      type: 'executor',
      field: 'nip',
      label: suffixLabel('NIP', true)
    },
    {
      type: 'executor',
      field: 'share_capital',
      label: suffixLabel('Wysokość kapitału zakładowego'),
      maxLength: 200
    },
    {
      type: 'executor',
      field: 'representative',
      label: suffixLabel('Reprezentowany / reprezentowana / reprezentowane przez')
    },
    {
      type: 'executor',
      field: 'of_representative',
      label: suffixLabel('Osoba odpowiedzialna za zamówienie po stronie wykonawcy')
    }
  ];

  const renderField = (
    obj: {
      type: string;
      field: string;
      label: ReactNode;
      required?: boolean;
      maxLength?: number;
      disabled?: boolean;
    },
    index?: number
  ) => {
    const { type, field, label, required, maxLength = null, disabled = false } = obj;

    const valuePath = getValuePath(type, field, index);

    const onChange = (value: any) => {
      const newValue = (() => {
        if (field === 'share_capital') {
          const parsed = parseInt(value, 10);

          return Number.isNaN(parsed) ? null : parsed;
        }

        return value;
      })();

      setStepValues((values) => {
        const newValues = _set({ ...values }, valuePath, newValue);
        return { ...newValues };
      });
    };

    return (
      <FormGroup key={valuePath}>
        <Label for={valuePath}>{label}</Label>
        <Input
          id={valuePath}
          name={valuePath}
          type="text"
          value={_get(stepValues, valuePath, '') || ''}
          invalid={hasError(errors, valuePath)}
          maxLength={maxLength}
          disabled={disabled}
          onChange={(event) => {
            event.persist();
            onChange(event.target.value);
          }}
          required={required}
        />
        {hasError(errors, valuePath) && <FormFeedback className="d-block">{getError(errors, valuePath)}</FormFeedback>}
      </FormGroup>
    );
  };

  const fetchContractorExecutorData = async (type: string, nip: string, index?: number): Promise<any> => {
    try {
      const {
        data: { data }
      } = await fetchExecutorFromGus(nip);

      if (_isEmpty(data)) {
        dispatch(addToastAction(executorFromGusToastError()));
        return null;
      }

      dispatch(addToastAction(executorFromGusToastSuccess()));

      const getAddress = () => {
        const { street, postal_code, city } = data[0];

        return [street, postal_code, city].join(', ').trim();
      };

      // @ts-ignore
      await setStepValues((values) => {
        if (index !== undefined) {
          return {
            ...values,
            [type]: {
              // @ts-ignore
              ...values[type],
              // @ts-ignore
              companies: values[type].companies.map((c, i) => {
                if (i === index) {
                  return {
                    ...c,
                    nip: data[0].nip,
                    name: data[0].name,
                    address: getAddress()
                  };
                } else {
                  return c;
                }
              })
            }
          };
        } else {
          return {
            ...values,
            [type]: {
              // @ts-ignore
              ...values[type],
              nip: data[0].nip,
              name: data[0].name,
              address: getAddress()
            }
          };
        }
      });

      if (type === 'contractor') {
        if (index !== undefined) {
          setContractorFilledCompany((v) => [...v, index]);
        } else {
          setContractorFromGus(data[0]);
        }
      } else {
        if (index !== undefined) {
          setExecutorFilledCompany((v) => [...v, index]);
        } else {
          setExecutorFromGus(data[0]);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const clearData = (type: string, index?: number) => {
    if (index === undefined) {
      type === 'contractor' ? setContractorFromGus(null) : setExecutorFromGus(null);
      type === 'contractor' ? setContractorFillByHandStatus(false) : setExecutorFillByHandStatus(false);

      setStepValues((values) => ({
        ...values,
        [type]: {
          // @ts-ignore
          ...values[type],
          ...Object.assign({}, type === 'contractor' ? CONTRACTOR_CLEAR_OBJ : EXECUTOR_CLEAR_OBJ)
        }
      }));
    } else {
      type === 'contractor'
        ? setContractorFilledCompany((v) => v.filter((i) => i !== index))
        : setExecutorFilledCompany((v) => v.filter((i) => i !== index));

      setStepValues((values) => ({
        ...values,
        [type]: {
          // @ts-ignore
          ...values[type],
          // @ts-ignore
          companies: values[type].companies.map((c, i) =>
            i === index ? Object.assign({}, type === 'contractor' ? CONTRACTOR_CLEAR_OBJ : EXECUTOR_CLEAR_OBJ) : c
          )
        }
      }));
    }
  };

  const renderFillByHandCheckbox = (type: string, index?: number) => {
    const fillByHandCheckedStatus = (() => {
      if (index === undefined) {
        return type === 'contractor' ? contractorFillByHandStatus : executorFillByHandStatus;
      }
      return type === 'contractor'
        ? contractorFilledByHandCompany.includes(index)
        : executorFilledByHandCompany.includes(index);
    })();

    const valuePath = getValuePath(type, 'fill_by_hand', index);

    return (
      <CustomInput
        id={valuePath}
        name={valuePath}
        type="checkbox"
        label="Wprowadź dane ręcznie."
        className="mt-3"
        checked={fillByHandCheckedStatus}
        onChange={(event) => {
          const { checked } = event.target;

          clearData(type, index);

          if (index === undefined) {
            type === 'contractor' ? setContractorFillByHandStatus(checked) : setExecutorFillByHandStatus(checked);
          } else {
            if (type === 'contractor') {
              setContractorFilledByHandCompany((values) => {
                return checked ? [...values, index] : values.filter((v) => v !== index);
              });
            } else {
              setExecutorFilledByHandCompany((values) => {
                return checked ? [...values, index] : values.filter((v) => v !== index);
              });
            }
          }
        }}
      />
    );
  };

  const renderSearchBody = (type: string, index?: number) => {
    const value = _get(stepValues, getValuePath(type, 'nip', index));

    return (
      <div>
        {renderField(
          {
            type,
            field: 'nip',
            label: suffixLabel('NIP / REGON', true)
          },
          index
        )}
        <Button
          type="button"
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light w-100"
          // @ts-ignore
          onClick={() => fetchContractorExecutorData(type, value, index)}
        >
          Wyszukaj
        </Button>
        {renderFillByHandCheckbox(type, index)}
      </div>
    );
  };

  const renderInputsBody = (type: string, index?: number) => {
    const resetButtonRenderStatus = (() => {
      if (index === undefined) {
        return type === 'contractor' ? !contractorFillByHandStatus : !executorFillByHandStatus;
      }
      return type === 'contractor'
        ? !contractorFilledByHandCompany.includes(index)
        : !executorFilledByHandCompany.includes(index);
    })();

    return (
      <>
        {type === 'contractor'
          ? contractorFields.map((obj) => renderField(obj, index))
          : executorFields.map((obj) => renderField(obj, index))}
        {resetButtonRenderStatus && (
          <Button
            type="button"
            color="secondary"
            outline
            className="pzpeu-btn-disabled waves-effect waves-light mt-3 w-100"
            onClick={() => clearData(type, index)}
          >
            Wyczyść dane i wyszukaj ponownie
          </Button>
        )}
        {!resetButtonRenderStatus && renderFillByHandCheckbox(type, index)}
      </>
    );
  };

  const renderContractorContent = () => {
    const addContractor = () => {
      setStepValues((values) => ({
        ...values,
        contractor: {
          ...values.contractor,
          companies: [...values.contractor.companies, Object.assign({}, CONTRACTOR_CLEAR_OBJ)]
        }
      }));
    };

    const removeContractor = (index: number) => {
      setStepValues((values) => ({
        ...values,
        contractor: {
          ...values.contractor,
          companies: values.contractor.companies.filter((_, i) => i !== index)
        }
      }));
    };

    return (
      <>
        {renderField({
          type: 'contractor',
          field: 'of_representative',
          label: suffixLabel('Osoba odpowiedzialna za zamówienie po stronie zamawiającego')
        })}
        <CustomInput
          className="mb-3"
          id="contractor.group"
          type="switch"
          label="Czy zamówienie jest udzielone wspólnie?"
          checked={stepValues.contractor.group}
          onChange={(event) => {
            event.persist();
            clearData('contractor');
            setStepValues((values) => ({
              ...values,
              contractor: {
                ...values.contractor,
                group: event.target.checked,
                companies: []
              }
            }));
          }}
        />
        {stepValues.contractor.group && (
          <div>
            <Label>Firmy wchodzące w skład Zamawiającego: </Label>
            {stepValues.contractor.companies.map((company, index) => {
              return (
                <div
                  className="d-flex align-items-start"
                  id={`contractor_company_${index}`}
                  key={`contractor_company_${index}`}
                >
                  <div className="w-100">
                    <Accordion
                      accordionContentStyle={{ padding: '1rem' }}
                      isInvalid={hasError(errors, 'contractor')}
                      entity={{
                        title: company.name || `${index + 1}. Zamawiający`,
                        content: (
                          <>
                            {contractorFilledCompany.includes(index) || contractorFilledByHandCompany.includes(index)
                              ? renderInputsBody('contractor', index)
                              : renderSearchBody('contractor', index)}
                          </>
                        )
                      }}
                    />
                  </div>
                  <div style={{ margin: '20px 0 0 10px' }}>
                    <ActionDelete
                      title="Usuń pozycje"
                      label={<i className="fa fa-times font-22" />}
                      onClick={() => removeContractor(index)}
                    />
                  </div>
                </div>
              );
            })}
            <div className="w-100 d-flex justify-content-end mb-2">
              <Button type="button" color="primary" onClick={addContractor}>
                Dodaj pozycję
              </Button>
            </div>
            {hasError(errors, 'contractor.companies') && (
              <FormFeedback className="d-block">{getError(errors, 'contractor.companies')}</FormFeedback>
            )}
          </div>
        )}
        {!stepValues.contractor.group && (
          <>
            {contractorFromGus || contractorFillByHandStatus
              ? renderInputsBody('contractor')
              : renderSearchBody('contractor')}
          </>
        )}
      </>
    );
  };

  const renderExecutorContent = () => {
    const addExecutor = () => {
      // @ts-ignore
      setStepValues((values) => ({
        ...values,
        executor: {
          ...values.executor,
          companies: [...values.executor.companies, Object.assign({}, EXECUTOR_CLEAR_OBJ)]
        }
      }));
    };

    const removeExecutor = (index: number) => {
      setStepValues((values) => ({
        ...values,
        executor: {
          ...values.executor,
          companies: values.executor.companies.filter((_, i) => i !== index)
        }
      }));
    };

    const executorGroupTypeValue = executorGroupTypeOptions.find((obj) => obj.id === stepValues.executor.group_type);

    if (agreement.type === AGREEMENT_TYPE_PROJECT) return <>W projekcie umowy nie można wybrać wykonawcy</>;

    return (
      <>
        {renderField({
          type: 'executor',
          field: 'of_representative',
          label: suffixLabel('Osoba odpowiedzialna za zamówienie po stronie wykonawcy')
        })}
        <Label for="executor_group_type">Grupa wykonawców</Label>
        <Select
          id="executor_group_type"
          name="executor_group_type"
          className="mb-3"
          value={{
            value: executorGroupTypeValue?.id,
            label: executorGroupTypeValue?.value
          }}
          options={executorGroupTypeOptions.map((obj) => ({
            label: obj.value,
            value: obj.id
          }))}
          onChange={(option) => {
            setStepValues((values) => ({
              ...values,
              executor: { ...values.executor, group_type: option.value }
            }));
          }}
          required={false}
          invalid={hasError(errors, `executor.group_type`)}
        />
        {stepValues.executor.group_type === 3 && (
          <FormGroup className="mb-3">
            <Label for="executor_group_other">Inna forma zgrupowania wykonawców</Label>
            <Input
              id="executor_group_other"
              name="executor_group_other"
              type="text"
              value={_get(stepValues, 'executor.group_other', '') || ''}
              invalid={hasError(errors, 'executor.group_other')}
              onChange={(event) => {
                event.persist();
                setStepValues((values) => ({
                  ...values,
                  executor: { ...values.executor, group_other: event.target.value }
                }));
              }}
            />
            {hasError(errors, 'executor.group_other') && (
              <FormFeedback className="d-block">{getError(errors, 'executor.group_other')}</FormFeedback>
            )}
          </FormGroup>
        )}
        {stepValues.executor.group_type && (
          <>
            <Label>Firmy wchodzące w skład Wykonawcy: </Label>
            {stepValues.executor.companies.map((company, index) => {
              return (
                <div
                  className="d-flex align-items-start"
                  id={`executor_company_${index}`}
                  key={`executor_company_${index}`}
                >
                  <div className="w-100">
                    <Accordion
                      accordionContentStyle={{ padding: '1rem' }}
                      isInvalid={hasError(errors, 'executor')}
                      entity={{
                        title: company.name || `${index + 1}. Wykonawca`,
                        content: (
                          <>
                            {executorFilledCompany.includes(index) || executorFilledByHandCompany.includes(index)
                              ? renderInputsBody('executor', index)
                              : renderSearchBody('executor', index)}
                          </>
                        )
                      }}
                    />
                  </div>
                  <div style={{ margin: '20px 0 0 10px' }}>
                    <ActionDelete
                      title="Usuń pozycje"
                      label={<i className="fa fa-times font-22" />}
                      onClick={() => removeExecutor(index)}
                    />
                  </div>
                </div>
              );
            })}
            <div className="w-100 d-flex justify-content-end mb-2">
              <Button type="button" color="primary" onClick={addExecutor}>
                Dodaj pozycję
              </Button>
            </div>
            {hasError(errors, 'executor.companies') && (
              <FormFeedback className="d-block">{getError(errors, 'executor.companies')}</FormFeedback>
            )}
          </>
        )}
        {!stepValues.executor.group_type && (
          <>
            {executorFromGus || executorFillByHandStatus ? renderInputsBody('executor') : renderSearchBody('executor')}
          </>
        )}
      </>
    );
  };

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <Accordion
        accordionContentStyle={{ padding: '1rem' }}
        isInvalid={hasError(errors, 'contractor')}
        entity={{
          title: 'Zamawiający',
          content: renderContractorContent()
        }}
      />
      <Accordion
        accordionContentStyle={{ padding: '1rem' }}
        isInvalid={hasError(errors, 'executor')}
        entity={{
          title: 'Wykonawca',
          content: renderExecutorContent()
        }}
      />
    </AgreementFormWrapper>
  );
};

export default AgreementStepContractorExecutor;
