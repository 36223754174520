import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function ArrowIcon(props: IconProps): JSX.Element {
  const { fill = '#666666', height = '10px' } = props;

  return (
    <svg className="arrow-icon" xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 2 4 4">
      <path fill={fill} d="M2 5L0 2h4z" />
    </svg>
  );
}
