import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import { OfficeExecutorRequestValue } from 'modules/Office/type';
import { formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';

export interface Props {
  executorRequest: OfficeExecutorRequestValue;
}

const ExecutorRequestDetailsDataset: React.FC<Props> = ({ executorRequest }) => {
  const fields = [
    {
      label: 'Data wysłania zapytania',
      value: ifContainsDateFormat(executorRequest?.sent_at, formatDatetimeSeconds)
    },
    {
      label: 'Data otrzymania odpowiedzi',
      value: ifContainsDateFormat(executorRequest?.received_at, formatDatetimeSeconds)
    },
    {
      label: 'Kanał dostarczenia odpowiedzi',
      value: executorRequest?.channel?.name
    },
    {
      label: 'Odpowiedź wprowadzona przez',
      value: executorRequest?.received_auth?.name
    },
    {
      label: 'Treść odpowiedzi',
      value: executorRequest?.answer_content || 'brak',
      html: true
    }
  ];

  return <InformationList fields={fields} />;
};

export default ExecutorRequestDetailsDataset;
