import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { ForeignProceedingsImportsListValues } from '../../../model/imports';

type Props = {
  foreignProceedingsImportsList?: ForeignProceedingsImportsListValues[];
};

const ForeignProceedingsImportsTable: React.FC<Props> = ({ foreignProceedingsImportsList }) => {
  const cols: TableCol<ForeignProceedingsImportsListValues>[] = [
    {
      property: 'file_name',
      label: 'Plik',
      value: (row) => row?.file_name
    },
    {
      property: 'source_api',
      label: 'Źródło API',
      value: (row) => row?.source_api
    },
    {
      property: 'new_proceedings',
      label: 'Wpisy nowe',
      value: (row) => row?.new_proceedings
    },
    {
      property: 'errors_count',
      label: 'Liczba błędów',
      value: (row) => (row?.errors_count > 0 ? row.errors_count : 'Brak')
    },
    {
      property: 'created_at',
      label: 'Rozpoczęto',
      value: (row) => ifContainsDateFormat(row?.created_at, formatDatetimeSeconds)
    },
    {
      property: 'user',
      label: 'Użytkownik',
      value: (row) => row?.user.name
    }
  ];

  return <Table cols={cols} rows={foreignProceedingsImportsList} />;
};

export default ForeignProceedingsImportsTable;
