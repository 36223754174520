import { SET_EXECUTOR_LIST_PARAMS, EXECUTOR_LIST, ExecutorListAction } from 'modules/Executor/action/list';
import { createExecutorClient } from 'modules/Executor/model/ExecutorClient';
import { initExecutorListState, ExecutorListState } from 'modules/Executor/state/list';

const initState = initExecutorListState();

const reducer = (state: ExecutorListState = initState, action: ExecutorListAction): ExecutorListState => {
  switch (action.type) {
    case SET_EXECUTOR_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case EXECUTOR_LIST: {
      const { executors = [], meta } = action.payload;

      return { ...state, fetching: false, executors: executors.map(createExecutorClient), meta };
    }

    default:
      return state;
  }
};

export default reducer;
