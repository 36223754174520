import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const deleteOfficeToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja urzedów',
  body: 'Urząd został usunięty',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateOfficeToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja urzędów',
  body: 'Urząd został zaktualizowany.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createOfficeToastSuccess = (): AddToastPayload => ({
  header: 'Tworzenie urzędów',
  body: 'Urząd został pomyślnie utworzony.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const officeExcelFileImportToastSuccess = (): AddToastPayload => ({
  header: 'Importowanie pliku excel',
  body: 'Pomyślnie rozpoczęto importowanie pliku.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const officeEmailUpdateToastSuccess = (): AddToastPayload => ({
  header: 'Komunikacja z urzędami',
  body: 'Pomyślnie zaktualizano email.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const officeEmailResendToastSuccess = (): AddToastPayload => ({
  header: 'Komunikacja z urzędami',
  body: 'Pomyślnie rozpoczęto wysyłanie wiadomości.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const executorRequestReceivedToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja urzedów',
  body: 'Zapytanie zostało pomyślnie zaktualizowane.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const executorRequestFileDownloadToastSuccess = (): AddToastPayload => ({
  header: 'Pobieranie',
  body: 'Pomyślnie pobrano załącznik.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const officeFromRegonToastSuccess = (): AddToastPayload => ({
  header: null,
  body: 'REGON znaleziono. Dane zostały uzupełnione.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const officeFromRegonToastError = (): AddToastPayload => ({
  header: null,
  body: `Nie udało się pobrać danych z rejestru GUS. Proszę uzupełnić dane ręcznie lub skontaktować się z pomocą techniczną.`,
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});
