import Action, { ActionProps } from 'modules/Layout/component/Action/index';
import { ColorVariants } from 'modules/Shared/type';
import React from 'react';
import GoodCommunicateIcon from 'modules/Layout/component/Icon/GoodCommunicate';

const ActionAccept: React.FC<ActionProps> = ({
  label = <GoodCommunicateIcon height="20px" strokeWidth="2" className="icon-svg-table-accept" />,
  color = ColorVariants.Primary,
  ...props
}: ActionProps): JSX.Element => {
  return (
    <Action
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      label={label}
      color={color}
    />
  );
};

export default ActionAccept;
