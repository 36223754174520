import classNames from 'classnames';
import Authorize from 'modules/Auth/container/Authorize';
import { ROUTE_DASHBOARD } from 'modules/Layout/routes';
import { BreadcrumbItem } from 'modules/Layout/type';
import React from 'react';
import { Link } from 'react-router-dom';

export type Props = {
  items: BreadcrumbItem[];
  defaultItem?: BreadcrumbItem;
};

const Breadcrumb: React.FC<Props> = ({
  items,
  defaultItem = {
    title: 'Strona główna',
    link: ROUTE_DASHBOARD
  }
}: Props): JSX.Element => {
  const isDefaultArray = Array.isArray(defaultItem);

  const elements = (breadcrumbItems: BreadcrumbItem[]) =>
    breadcrumbItems.map(({ title, link }, index) => {
      const isActive = items.length === index + 1;

      const classes = classNames('breadcrumb-item', { active: isActive });

      const content = (
        <>
          {/* eslint-disable-next-line no-nested-ternary */}
          {link ? (
            isActive ? (
              <li className={classes}>{title}</li>
            ) : (
              <li className={classes}>
                <Link to={link}>{title}</Link>
              </li>
            )
          ) : (
            <li className={classes}>{title}</li>
          )}
        </>
      );

      return isDefaultArray ? (
        <React.Fragment key={title}>{content}</React.Fragment>
      ) : (
        <Authorize key={title}>{content}</Authorize>
      );
    });

  return (
    <ol className="breadcrumb m-0 p-0">
      {!isDefaultArray && (
        <li
          className={classNames('breadcrumb-item', {
            active: items.length === 0
          })}
        >
          <Link to={defaultItem.link}>{defaultItem.title}</Link>
        </li>
      )}
      {elements(isDefaultArray ? (defaultItem as any) : items)}
    </ol>
  );
};

export default Breadcrumb;
