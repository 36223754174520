import { addToastAction, managePageAction } from 'modules/Layout/action';
import Form from 'modules/User/component/UserForm/Create';
import React, { useEffect, useState, useRef } from 'react';
import Loader from 'modules/Layout/component/Loader';
import { useDispatch } from 'react-redux';
import { breadcrumbRouteUserCreate } from 'modules/User/breadcrumbs';
import { CreateUserValues } from 'modules/Profile/type';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { push } from 'connected-react-router';
import { ROUTE_USER, ROUTE_USERS } from 'modules/User/routes';
import { getPathUrl } from 'modules/Shared/helper/api';
import { createUserToastSuccess } from 'modules/User/toasts';
import UserRole, {
  createUserRole,
  ROLE_ADMIN,
  ROLE_ADMIN_BS,
  ROLE_CLIENT,
  ROLE_CLIENT_BS
} from 'modules/User/model/UserRole';
import { createNewUser, fetchRoleList } from 'modules/User/repository';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import CardBox from 'modules/Layout/component/CardBox';

type Props = { isAdminCreate?: boolean };

const UserCreateView: React.FC<Props> = ({ isAdminCreate }) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState(null);
  const [roleList, setRoleList] = useState<UserRole[]>([]);
  const scrollToTopOnce = useRef<boolean>(true);

  const themeTemplate = process.env.THEME_TEMPLATE as 'pzp' | 'bs';

  const handleRoleSlug = () => {
    if (isAdminCreate && themeTemplate === 'pzp') {
      return ROLE_ADMIN;
    }
    if (!isAdminCreate && themeTemplate === 'pzp') {
      return ROLE_CLIENT;
    }
    if (isAdminCreate && themeTemplate === 'bs') {
      return ROLE_ADMIN_BS;
    }
    if (!isAdminCreate && themeTemplate === 'bs') {
      return ROLE_CLIENT_BS;
    }
    return ROLE_CLIENT;
  };

  const cancelToken = useCancelToken();
  useEffect(() => {
    const title = isAdminCreate ? 'Tworzenie administratora' : 'Tworzenie klienta';
    dispatch(
      managePageAction({
        title,
        breadcrumb: breadcrumbRouteUserCreate(isAdminCreate)
      })
    );

    (async () => {
      setFetching(true);
      const { data: requestData, cancelled, message: messageResponse } = await fetchRequest(fetchRoleList, cancelToken);

      if (cancelled) return;
      const { data } = requestData;
      if (data) setRoleList(data.map((userRole) => createUserRole(userRole)));
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchCreateUserToast = () => dispatch(addToastAction(createUserToastSuccess()));
  const setCreateUserAction = async (data: CreateUserValues) => {
    if (fetching) return;

    if (errors) setErrors(null);
    setFetching(true);

    const {
      message: messageResponse,
      error,
      cancelled,
      data: createdData
    } = await updateRequestWithData(createNewUser, data, cancelToken);

    if (cancelled) return;

    if (createdData) {
      dispatchCreateUserToast();
      const { id } = createdData.data;
      if (id) dispatch(push(getPathUrl(ROUTE_USER, { id })));
      else dispatch(push(ROUTE_USERS));
    } else {
      if (error) setErrors(error);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <div className="user-create-view d-flex justify-content-center">
      <CardBox form="small" heading={`Dodaj ${isAdminCreate ? 'administratora' : 'klienta'}`}>
        {fetching && <Loader />}
        <Alert message={message} />
        <Form
          errors={errors}
          isAdminCreate={isAdminCreate}
          submit={(data) => setCreateUserAction(data)}
          role={roleList.find((userRole) => userRole.hasSlug(handleRoleSlug()))}
        />
      </CardBox>
    </div>
  );
};

export default UserCreateView;
