import { RootState } from 'app/reducer';
import { ModuleSlug } from 'modules/Auth/type';
import {
  hasCreditModule,
  hasModule,
  MODULE_CLAUSE,
  MODULE_EXECUTOR,
  MODULE_JUDGEMENTS,
  MODULE_AGREEMENTS,
  MODULE_FOREIGN_PROCEEDINGS,
  MODULE_EXECUTOR_EXTENDED
} from 'modules/Module/model/Module';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExecutorsAccessDenied from 'modules/Auth/container/Guard/ModuleGuard/ExecutorsAccessDenied';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { authenticate } from 'modules/Auth/repository';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { SetAuthUserAction, SET_AUTH_USER } from 'modules/Auth/action';
import Loader from 'modules/Layout/component/Loader';
import ClauseAccessDenied from 'modules/Auth/container/Guard/ModuleGuard/ClauseAccessDenied';
import JudgementsAccessDenied from 'modules/Auth/container/Guard/ModuleGuard/JudgementsAccessDenied';
import AgreementsAccessDenied from 'modules/Auth/container/Guard/ModuleGuard/AgreementsAccessDenied';
import { UserEntity } from '../../../../User/model/User';
import { SUBSCRIPTIONS_MODULE_ACCESS } from '../permissions';
import ForeignProceedingsAccessDenied from './ForeignProceedingsAccessDenied';

const hasPermission = (user: UserEntity, permission: string) => {
  return user?.permissions?.includes(permission);
};

const displayRestrictedModule = (moduleSlug: ModuleSlug) => {
  switch (moduleSlug) {
    case MODULE_EXECUTOR:
      return <ExecutorsAccessDenied />;
    case MODULE_EXECUTOR_EXTENDED:
      return <ExecutorsAccessDenied />;
    case MODULE_CLAUSE:
      return <ClauseAccessDenied />;
    case MODULE_JUDGEMENTS:
      return <JudgementsAccessDenied />;
    case MODULE_AGREEMENTS:
      return <AgreementsAccessDenied />;
    case MODULE_FOREIGN_PROCEEDINGS:
      return <ForeignProceedingsAccessDenied />;
    default:
      return null;
  }
};

const ModuleGuard: React.FC<{ moduleSlug: ModuleSlug }> = ({ children, moduleSlug }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const { action } = useSelector((state: RootState) => state.router);

  const [fetching, setFetching] = useState(false);
  const [displayRestrictedComponent, setDisplayRestrictedComponent] = useState(false);

  const cancelToken = useCancelToken();
  useEffect(() => {
    const checkUserAccess = async () => {
      const hasAccess =
        user &&
        (hasModule(moduleSlug, user) ||
          hasCreditModule(moduleSlug, user) ||
          !hasPermission(user, SUBSCRIPTIONS_MODULE_ACCESS));
      if (!hasAccess) {
        if (action === 'PUSH') {
          setFetching(true);
          const { data, cancelled } = await fetchRequest(authenticate, cancelToken);

          if (cancelled) return;

          if (data?.data) {
            const userData = data.data;
            if (
              hasModule(moduleSlug, userData) ||
              hasCreditModule(moduleSlug, userData) ||
              !hasPermission(userData, SUBSCRIPTIONS_MODULE_ACCESS)
            ) {
              dispatch<SetAuthUserAction>({
                type: SET_AUTH_USER,
                payload: userData
              });
            } else {
              setDisplayRestrictedComponent(true);
            }
          }
          setFetching(false);
        } else {
          setDisplayRestrictedComponent(true);
        }
      }
    };

    checkUserAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (fetching) return <Loader />;
  if (displayRestrictedComponent) return displayRestrictedModule(moduleSlug);

  if (
    user &&
    (hasModule(moduleSlug, user) ||
      hasCreditModule(moduleSlug, user) ||
      !hasPermission(user, SUBSCRIPTIONS_MODULE_ACCESS))
  ) {
    return <>{children}</>;
  }

  return null;
};

export default ModuleGuard;

/*
version with reverse available

import { RootState } from 'app/reducer';
import { push } from 'connected-react-router';
import { ModuleSlug } from 'modules/Auth/type';
import { ROUTE_MODULE_ACCESS_DENIED } from 'modules/Layout/routes';
import { hasCreditModule, hasModule } from 'modules/Module/model/Module';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ModuleGuard: React.FC<{ reverse?: boolean; moduleSlug: ModuleSlug }> = ({ children, reverse, moduleSlug }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (reverse && user && (hasModule(moduleSlug, user) || hasCreditModule(moduleSlug, user))) {
      dispatch(push(ROUTE_MODULE_ACCESS_DENIED, { moduleSlug }));
    }

    if (!reverse && user && !(hasModule(moduleSlug, user) || hasCreditModule(moduleSlug, user))) {
      dispatch(push(ROUTE_MODULE_ACCESS_DENIED, { moduleSlug }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (reverse && user && !(hasModule(moduleSlug, user) || hasCreditModule(moduleSlug, user))) {
    return <>{children}</>;
  }

  if (!reverse && user && (hasModule(moduleSlug, user) || hasCreditModule(moduleSlug, user))) {
    return <>{children}</>;
  }

  return null;
};

export default ModuleGuard;



*/
