/* eslint-disable */
import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function InfoCommunicateIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 28.919 28.92">
      <g id="Group_399" data-name="Group 399" transform="translate(0.5 0.5)">
        <path
          id="Path_1307"
          data-name="Path 1307"
          d="M853.524,1728.565a13.96,13.96,0,1,0,13.96,13.96A13.976,13.976,0,0,0,853.524,1728.565Z"
          transform="translate(-839.564 -1728.565)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1308"
          data-name="Path 1308"
          d="M851.539,1731.522a3.028,3.028,0,1,1-3.028,3.029A3.032,3.032,0,0,1,851.539,1731.522Z"
          transform="translate(-837.575 -1727.908)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1309"
          data-name="Path 1309"
          d="M856.034,1749.489h-7.422c-1.368,0-1.318-2.373,0-2.373h1.467v-6.274h-1.467c-1.357,0-1.327-2.373,0-2.373h5.916a.365.365,0,0,1,.363.368v8.278h1.143C857.35,1747.116,857.4,1749.489,856.034,1749.489Z"
          transform="translate(-837.777 -1726.363)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
