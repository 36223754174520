import React, { useEffect, useRef, useState } from 'react';
import { AGREEMENT_TYPE_OUTSIDE_ORDER, AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_SUBEXECUTORS } from 'modules/Agreements/step';
import { isEqual } from 'lodash';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import { CustomInput, FormFeedback, FormGroup } from 'reactstrap';
import { getError, hasError } from 'modules/Shared/helper/validation';

export interface AgreementStepSubexecutorsValues {
  ser_subexecutors_allowed_checked: boolean;
  ser_coordination_checked: boolean;
  ser_coordination_content: string | null;
  quality_checked: boolean;
  quality_content: string | null;
  decision_checked: boolean;
  decision_content: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const QUALITY_CONTENT =
  'Wykonawca w pełni odpowiada za jakość i terminowość wykonywanych przez podwykonawców usług lub dalszych podwykonawców, w tym wykonawca jest odpowiedzialny za działania, zaniechanie działań, uchybienia i zaniedbania dostawców oraz podwykonawców lub dalszych podwykonawców i ich pracowników, w takim stopniu jakby to były działania względnie uchybienia jego własne. Na prace i usługi wykonywane przez podwykonawców lub dalszych podwykonawców gwarancji udziela wykonawca';
const DECISION_CONTENT =
  'Postanowienia Umowy dotyczące zawarcia umowy z podwykonawcą stosuje się odpowiednio do zmian projektu oraz umowy o podwykonawstwo';
const SER_COORDINATION_CONTENT =
  'Wykonawca we własnym zakresie i na swój koszt pełni funkcję koordynacyjną w stosunku do usług i prac realizowanych przez podwykonawców lub dalszych podwykonawców';

const initContentValues = {
  decision_content: DECISION_CONTENT,
  quality_content: QUALITY_CONTENT
};

const mapState = (
  step: AgreementStepSubexecutorsValues,
  defaultValueStatus?: boolean
): AgreementStepSubexecutorsValues => {
  const { decision_checked, decision_content, quality_content, ser_coordination_content, checkboxes, ...rest } = step;

  return {
    ...rest,
    quality_content: quality_content || QUALITY_CONTENT,
    decision_checked: Boolean(decision_checked || defaultValueStatus),
    decision_content: decision_content || DECISION_CONTENT,
    ser_coordination_content: ser_coordination_content || SER_COORDINATION_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepSubexecutors = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, onSubmit, onChange, errors } = props;
  const agreementFromPzp = agreement.type !== AGREEMENT_TYPE_OUTSIDE_ORDER;

  const initState = useRef<AgreementStepSubexecutorsValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_SUBEXECUTORS), agreementFromPzp)
  );
  const [stepValues, setStepValues] = useState<AgreementStepSubexecutorsValues>(
    mapState(initState.current, agreementFromPzp)
  );

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_SUBEXECUTORS), agreementFromPzp);
    setStepValues(mapState(initState.current, agreementFromPzp));
  }, [steps]);

  const { renderCheckbox, renderEditableCheckbox, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState: (values) => mapState(values, agreementFromPzp),
    errors,
    initContentValues
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        <CustomInput
          id="ser_subexecutors_allowed_checked_true"
          label="Wykonawca zobowiązuje się do osobistego świadczenia usług z wyłączeniem korzystania z podwykonawców"
          value={1}
          type="radio"
          checked={stepValues.ser_subexecutors_allowed_checked === false}
          onChange={() => {
            stepValues.ser_subexecutors_allowed_checked = false;
            setStepValues((values) => mapState(values));
          }}
          invalid={hasError(errors, 'ser_subexecutors_allowed_checked')}
        />
        <CustomInput
          id="ser_subexecutors_allowed_checked_false"
          label="Wykonawca uprawniony jest do świadczenia usług z wykorzystaniem podwykonawców"
          value={2}
          type="radio"
          checked={stepValues.ser_subexecutors_allowed_checked === true}
          onChange={() => {
            stepValues.ser_subexecutors_allowed_checked = true;
            setStepValues((values) => mapState(values));
          }}
          invalid={hasError(errors, 'ser_subexecutors_allowed_checked')}
        />
        {hasError(errors, 'ser_subexecutors_allowed_checked') && (
          <FormFeedback className="d-block">{getError(errors, 'ser_subexecutors_allowed_checked')}</FormFeedback>
        )}
      </FormGroup>
      {stepValues.ser_subexecutors_allowed_checked && [
        renderCheckbox('ser_coordination_checked', stepValues.ser_coordination_content),
        renderEditableCheckbox('quality'),
        renderEditableCheckbox('decision', null, agreementFromPzp)
      ]}
      {renderCustomCheckboxes()}
    </AgreementFormWrapper>
  );
};

export default AgreementStepSubexecutors;
