import React, { useState } from 'react';
import PopoverComponent from 'modules/Layout/component/Popover';
import QuestionMarkIcon from 'modules/Layout/component/Icon/QuestionMark';
import './index.scss';

type IProps = {
  popoverId: string;
  popoverContent: string;
  className?: string;
};

const DisplayPopovers: React.FC<IProps> = ({ popoverId, popoverContent, className }) => {
  const [showPopover, setShowPopover] = useState(false);

  const togglePopover = () => setShowPopover(!showPopover);

  const popoverIconOnHover = (bool: boolean) => setShowPopover(bool);

  const iconWrapperStyle = { padding: '3px' };
  return (
    <>
      <div
        className="d-inline cursor-pointer tooltip-icon-wrapper"
        style={iconWrapperStyle}
        id={popoverId}
        onMouseEnter={() => popoverIconOnHover(true)}
        onMouseLeave={() => popoverIconOnHover(false)}
      >
        <QuestionMarkIcon height="18px" />
      </div>
      <PopoverComponent
        className={className}
        isOpen={showPopover}
        toggle={togglePopover}
        content={popoverContent}
        target={popoverId}
        placement="right-start"
      />
    </>
  );
};

export default DisplayPopovers;
