import React, { useEffect } from 'react';
import { View } from 'modules/Auth/component/View';
import { confirmAccountActivationAction, confirmEmailChangeAction } from 'modules/Auth/action';
import { RootState } from 'app/reducer';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
import PublicWrapper from 'modules/Layout/component/Wrapper/Public';

export interface IParams {
  token: string;
}

export interface Props {
  isAccountActivation?: boolean;
}

const EmailChangeConfirm: React.FC<Props> = ({ isAccountActivation }) => {
  const dispatch = useDispatch();
  const { busy } = useSelector((rootState: RootState) => rootState.auth);
  const { token } = useParams<IParams>();

  const confirmEmailChange = (tokenFromUrl: string) => dispatch(confirmEmailChangeAction(tokenFromUrl));
  const confirmAccountActivation = (tokenFromUrl: string) => dispatch(confirmAccountActivationAction(tokenFromUrl));
  const managePage = (tokenFromUrl: PageProps) => dispatch(managePageAction(tokenFromUrl));

  useEffect(() => {
    if (isAccountActivation) confirmAccountActivation(token);
    else confirmEmailChange(token);

    managePage({
      title: isAccountActivation ? 'Potwierdzenie aktywacji konta' : 'Potwierdzenie zmiany adresu e-mail'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PublicWrapper>
      <View busy={busy}>
        <span>{isAccountActivation ? 'Potwierdzenie aktywacji konta' : 'Potwierdzenie zmiany adresu e-mail'}</span>
        {busy && <span>Przetważanie żądania...</span>}
      </View>
    </PublicWrapper>
  );
};

export default EmailChangeConfirm;
