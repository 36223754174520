import React, { Dispatch, SetStateAction } from 'react';
import { CustomInput, FormFeedback, FormGroup } from 'reactstrap';
import { ValidationErrors } from 'modules/Shared/type';
import { getError, hasError } from 'modules/Shared/helper/validation';

export interface StepFieldSwitchProps<T> {
  switchKey: string;
  label: string;
  stepValues: T;
  setStepValues: Dispatch<SetStateAction<T>>;
  mapState: (values: T) => T;
  errors?: ValidationErrors | null;
  disabled?: boolean;
}

const StepFieldSwitch = <T,>(props: StepFieldSwitchProps<T>): JSX.Element => {
  const { switchKey, label, stepValues, setStepValues, mapState, errors, disabled } = props;

  return (
    <FormGroup key={switchKey}>
      <CustomInput
        id={switchKey}
        type="switch"
        label={label}
        // @ts-ignore
        checked={stepValues[switchKey]}
        onChange={(event) => {
          event.persist();
          // @ts-ignore
          stepValues[switchKey] = event.currentTarget.checked;
          setStepValues((values) => mapState(values));
        }}
        disabled={disabled}
        invalid={hasError(errors, switchKey)}
      />
      {hasError(errors, switchKey) && <FormFeedback className="d-block">{getError(errors, switchKey)}</FormFeedback>}
    </FormGroup>
  );
};

export default StepFieldSwitch;
