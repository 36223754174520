import React, { useEffect } from 'react';
import { createSearchingProps } from 'modules/Shared/helper/params';
import { useDispatch } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
import { breadcrumbRouteExecutorsExtended } from 'modules/User/breadcrumbs';
import { PartialSearchingProps } from 'modules/Shared/type';
import { setExecutorExtendedListParamsAction } from 'modules/User/action/list';
import ExecutorsExtendedList from '../../../component/List/ExecutorsExtended';
import Executor from '../../../../Executor/model/Executor';

function withExecutorsExtendedAdminListPageManage<P>(WrappedComponent: React.ComponentType<P>) {
  const Component = (props: P) => {
    const dispatch = useDispatch();
    const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
    const setParams = (payload: PartialSearchingProps) => dispatch(setExecutorExtendedListParamsAction(payload));

    const searchProps = createSearchingProps('', Executor.getFilterableAttributes(), Executor.getSortableAttributes());

    useEffect(() => {
      const title = 'Zlecenia z PA - lista';

      managePage({
        title,
        breadcrumb: breadcrumbRouteExecutorsExtended()
      });

      setParams({ ...searchProps });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <WrappedComponent {...props} isAdminList />;
  };
  return Component;
}

const ExecutorsExtendedAdmin = withExecutorsExtendedAdminListPageManage(ExecutorsExtendedList);

export default ExecutorsExtendedAdmin;
