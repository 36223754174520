import { ValidationErrors } from 'modules/Shared/type';
import React, { Fragment } from 'react';
import { FormGroup, CustomInput, Button, FormFeedback } from 'reactstrap';
import { IOrderFormValues } from 'modules/ExecutorOrder/component/OrderForm';
import OrderBaseFieldset from 'modules/ExecutorOrder/component/Fieldset/BaseOrder';
import OrderPenaltiesFieldset from 'modules/ExecutorOrder/component/Fieldset/PenaltiesOrder';
import OrderTerminationFieldset from 'modules/ExecutorOrder/component/Fieldset/TerminationOrder';
import OrderComplainFieldset from 'modules/ExecutorOrder/component/Fieldset/ComplainOrder';
import OrderMisrepresentationFieldset from 'modules/ExecutorOrder/component/Fieldset/MisrepresentationOrder';
import {
  BigRiggingItem,
  CommonDataItem,
  ComplaintDataItem,
  MisrepresentationDataItem,
  PenaltyDataItem
} from '../../model/Order';
import OrderBidGiggingFieldset from './BidRiggingOrder';
import OrderUnfairCompetitionFieldset from './UnfairCompetitionOrder';
import { getError, hasError } from '../../../Shared/helper/validation';

export interface Props {
  values: IOrderFormValues;
  penaltiesValues: PenaltyDataItem[];
  terminationValues: CommonDataItem[];
  complaintValues: ComplaintDataItem[];
  misrepresentationValues: MisrepresentationDataItem[];
  bidRiggingValues: BigRiggingItem[];
  unfairCompetitionValues: ComplaintDataItem[];
  setPenaltiesValues: React.Dispatch<React.SetStateAction<PenaltyDataItem[]>>;
  setTerminationValues: React.Dispatch<React.SetStateAction<CommonDataItem[]>>;
  setComplaintValues: React.Dispatch<React.SetStateAction<CommonDataItem[]>>;
  setMisrepresentationValues: React.Dispatch<React.SetStateAction<MisrepresentationDataItem[]>>;
  setBidRiggingValues: React.Dispatch<React.SetStateAction<BigRiggingItem[]>>;
  setUnfairCompetitionValues: React.Dispatch<React.SetStateAction<CommonDataItem[]>>;
  setValues: React.Dispatch<React.SetStateAction<IOrderFormValues>>;
  isEditForm: boolean;
  errors?: ValidationErrors;
  disabled?: boolean;
  required?: boolean;
}

const OrderFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    values,
    setValues,
    penaltiesValues,
    setPenaltiesValues,
    setComplaintValues,
    complaintValues,
    setMisrepresentationValues,
    setUnfairCompetitionValues,
    setBidRiggingValues,
    bidRiggingValues,
    misrepresentationValues,
    unfairCompetitionValues,
    setTerminationValues,
    terminationValues,
    errors,
    ...restProps
  } = props;

  const {
    penalties_checked,
    termination_checked,
    complaint_checked,
    misrepresentation_checked,
    bid_rigging_checked,
    unfair_competition_checked
  } = values;

  const formattedDate = new Date().toLocaleDateString('en-GB');

  const handleAddPenalty = () => {
    setPenaltiesValues([
      ...penaltiesValues,
      {
        id: null,
        penalty_amount: 0,
        executor_salary: 0,
        violation_date: formattedDate,
        violation_description: '',
        files: []
      }
    ]);
  };

  const handleAddTermination = () => {
    setTerminationValues([
      ...terminationValues,
      {
        id: null,
        violation_date: formattedDate,
        violation_description: '',
        files: []
      }
    ]);
  };
  const handleAddComplaint = () => {
    setComplaintValues([
      ...complaintValues,
      {
        id: null,
        violation_date: formattedDate,
        violation_description: '',
        files: []
      }
    ]);
  };
  const handleAddMisrepresentation = () => {
    setMisrepresentationValues([
      ...misrepresentationValues,
      {
        id: null,
        violation_date: formattedDate,
        violation_description: '',
        files: [],
        exclusion_identifier: ''
      }
    ]);
  };
  const handleAddBidRigging = () => {
    setBidRiggingValues([
      ...bidRiggingValues,
      {
        id: null,
        violation_date: formattedDate,
        violation_description: '',
        files: []
      }
    ]);
  };
  const handleAddUnfairCompetition = () => {
    setUnfairCompetitionValues([
      ...unfairCompetitionValues,
      {
        id: null,
        violation_date: formattedDate,
        violation_description: '',
        files: []
      }
    ]);
  };

  const handleDeletePenalty = (id: string | number | null, index: number) => {
    if (id !== null && penaltiesValues.some((penalty) => penalty.id === id)) {
      setPenaltiesValues(penaltiesValues.filter((penalty) => penalty.id !== id));
    } else {
      setPenaltiesValues(penaltiesValues.filter((_, i) => i !== index));
    }
  };

  const handleDeleteTermination = (id: string | number | null, index: number) => {
    if (id !== null && terminationValues.some((termination) => termination.id === id)) {
      setTerminationValues(terminationValues.filter((termination) => termination.id !== id));
    } else {
      setTerminationValues(terminationValues.filter((_, i) => i !== index));
    }
  };

  const handleDeleteComplaint = (id: string | number | null, index: number) => {
    if (id !== null && complaintValues.some((complaint) => complaint.id === id)) {
      setComplaintValues(complaintValues.filter((complaint) => complaint.id !== id));
    } else {
      setComplaintValues(complaintValues.filter((_, i) => i !== index));
    }
  };

  const handleDeleteMisrepresentation = (id: string | number | null, index: number) => {
    if (id !== null && misrepresentationValues.some((misrepresentation) => misrepresentation.id === id)) {
      setMisrepresentationValues(misrepresentationValues.filter((misrepresentation) => misrepresentation.id !== id));
    } else {
      setMisrepresentationValues(misrepresentationValues.filter((_, i) => i !== index));
    }
  };

  const handleDeleteBidRigging = (id: string | number | null, index: number) => {
    if (id !== null && bidRiggingValues.some((bidRigging) => bidRigging.id === id)) {
      setBidRiggingValues(bidRiggingValues.filter((bidRigging) => bidRigging.id !== id));
    } else {
      setBidRiggingValues(bidRiggingValues.filter((_, i) => i !== index));
    }
  };

  const handleDeleteUnfairCompetition = (id: string | number | null, index: number) => {
    if (id !== null && unfairCompetitionValues.some((unfairCompetition) => unfairCompetition.id === id)) {
      setUnfairCompetitionValues(unfairCompetitionValues.filter((unfairCompetition) => unfairCompetition.id !== id));
    } else {
      setUnfairCompetitionValues(unfairCompetitionValues.filter((_, i) => i !== index));
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-lg-6">
        <OrderBaseFieldset values={values} setValues={setValues} {...restProps} required />
      </div>
      <div className="col-12 col-lg-6">
        <FormGroup>
          <CustomInput
            type="switch"
            id="checkox-penalties_checked"
            label="Czy w postępowaniu są informacje o karze umownej?"
            checked={penalties_checked}
            onChange={(event) => setValues({ ...values, penalties_checked: event.target.checked })}
          />
        </FormGroup>
        {hasError(errors, 'penalties') && (
          <FormFeedback className="d-block">{getError(errors, 'penalties')}</FormFeedback>
        )}
        {penalties_checked && (
          <>
            {penaltiesValues.map((penalty, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`${penalty.id}-${index}`}>
                <OrderPenaltiesFieldset
                  i={index}
                  handleDeletePenalty={handleDeletePenalty}
                  values={penalty}
                  setValues={setPenaltiesValues}
                  {...restProps}
                />
                <div className={`my-2${penaltiesValues.length > 1 ? ' border' : ''}`} />
              </Fragment>
            ))}
            <Button
              type="button"
              color="primary"
              className="pzpeu-btn-disabled waves-effect waves-light my-2"
              onClick={handleAddPenalty}
            >
              Dodaj
            </Button>
          </>
        )}
        <div className={`mb-2${penalties_checked ? ' border' : ''}`} />
        <FormGroup>
          <CustomInput
            type="switch"
            id="checkox-termination_checked"
            label="Czy w postępowaniu są informacje o rozwiązaniu umowy?"
            checked={termination_checked}
            onChange={(event) => setValues({ ...values, termination_checked: event.target.checked })}
          />
        </FormGroup>
        {hasError(errors, 'termination') && (
          <FormFeedback className="d-block">{getError(errors, 'termination')}</FormFeedback>
        )}
        {termination_checked && (
          <>
            {terminationValues.map((termination, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`${termination.id}-${index}`}>
                <OrderTerminationFieldset
                  i={index}
                  handleDeleteTermination={handleDeleteTermination}
                  values={termination}
                  setValues={setTerminationValues}
                  {...restProps}
                />
                <div className={`my-2${terminationValues.length > 1 ? ' border' : ''}`} />
              </Fragment>
            ))}
            <Button
              type="button"
              color="primary"
              className="pzpeu-btn-disabled waves-effect waves-light my-2"
              onClick={handleAddTermination}
            >
              Dodaj
            </Button>
          </>
        )}
        <div className={`mb-2${termination_checked ? ' border' : ''}`} />
        <FormGroup>
          <CustomInput
            type="switch"
            id="checkox-complaint_checked"
            label="Czy w postępowaniu są informacje o wytoczeniu powództwa w związku z realizacją umowy?"
            checked={complaint_checked}
            onChange={(event) => setValues({ ...values, complaint_checked: event.target.checked })}
          />
        </FormGroup>
        {hasError(errors, 'complaint') && (
          <FormFeedback className="d-block">{getError(errors, 'complaint')}</FormFeedback>
        )}
        {complaint_checked && (
          <>
            {complaintValues.map((complaint, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`${complaint.id}-${index}`}>
                <OrderComplainFieldset
                  handleDeleteComplaint={handleDeleteComplaint}
                  values={complaint}
                  i={index}
                  setValues={setComplaintValues}
                  {...restProps}
                />
                <div className={`my-2${complaintValues.length > 1 ? ' border' : ''}`} />
              </Fragment>
            ))}
            <Button
              type="button"
              color="primary"
              className="pzpeu-btn-disabled waves-effect waves-light my-2"
              onClick={handleAddComplaint}
            >
              Dodaj
            </Button>
          </>
        )}
        <div className={`mb-2${complaint_checked ? ' border' : ''}`} />
        <FormGroup>
          <CustomInput
            type="switch"
            id="checkox-misrepresentation_checked"
            label="Czy w postępowaniu są informacje o wprowadzeniu zamawiającego w błąd?"
            checked={misrepresentation_checked}
            onChange={(event) => setValues({ ...values, misrepresentation_checked: event.target.checked })}
          />
        </FormGroup>
        {hasError(errors, 'misrepresentation') && (
          <FormFeedback className="d-block">{getError(errors, 'misrepresentation')}</FormFeedback>
        )}
        {misrepresentation_checked && (
          <>
            {misrepresentationValues.map((misrepresentation, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`${misrepresentation.id}-${index}`}>
                <OrderMisrepresentationFieldset
                  values={misrepresentation}
                  i={index}
                  setValues={setMisrepresentationValues}
                  handleDeleteMisrepresentation={handleDeleteMisrepresentation}
                  {...restProps}
                />
                <div className={`my-2${misrepresentationValues.length > 1 ? ' border' : ''}`} />
              </Fragment>
            ))}
            <Button
              type="button"
              color="primary"
              className="pzpeu-btn-disabled waves-effect waves-light my-2"
              onClick={handleAddMisrepresentation}
            >
              Dodaj
            </Button>
          </>
        )}
        <div className={`mb-2${misrepresentation_checked ? ' border' : ''}`} />
        <FormGroup>
          <CustomInput
            type="switch"
            id="checkox-bid_rigging_checked"
            label="Czy w postępowaniu są informacje o zmowach przetargowych?"
            checked={bid_rigging_checked}
            onChange={(event) => setValues({ ...values, bid_rigging_checked: event.target.checked })}
          />
        </FormGroup>
        {hasError(errors, 'bid_rigging') && (
          <FormFeedback className="d-block">{getError(errors, 'bid_rigging')}</FormFeedback>
        )}
        {bid_rigging_checked && (
          <>
            {bidRiggingValues.map((bidRigging, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`${bidRigging.id}-${index}`}>
                <OrderBidGiggingFieldset
                  values={bidRigging}
                  i={index}
                  setValues={setBidRiggingValues}
                  handleDeleteBidRigging={handleDeleteBidRigging}
                  {...restProps}
                />
                <div className={`my-2${bidRiggingValues.length > 1 ? ' border' : ''}`} />
              </Fragment>
            ))}
            <Button
              type="button"
              color="primary"
              className="pzpeu-btn-disabled waves-effect waves-light my-2"
              onClick={handleAddBidRigging}
            >
              Dodaj
            </Button>
          </>
        )}
        <div className={`mb-2${bid_rigging_checked ? ' border' : ''}`} />
        <FormGroup>
          <CustomInput
            type="switch"
            id="checkox-unfair_competition_checked"
            label="Czy w postępowaniu są informacje o czynach nieuczciwej konkurencji?"
            checked={unfair_competition_checked}
            onChange={(event) => setValues({ ...values, unfair_competition_checked: event.target.checked })}
          />
        </FormGroup>
        {hasError(errors, 'unfair_competition') && (
          <FormFeedback className="d-block">{getError(errors, 'unfair_competition')}</FormFeedback>
        )}
        {unfair_competition_checked && (
          <>
            {unfairCompetitionValues.map((unfairCompetition, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`${unfairCompetition.id}-${index}`}>
                <OrderUnfairCompetitionFieldset
                  values={unfairCompetition}
                  i={index}
                  setValues={setUnfairCompetitionValues}
                  handleDeleteUnfairCompetition={handleDeleteUnfairCompetition}
                  {...restProps}
                />
                <div className={`my-2${unfairCompetitionValues.length > 1 ? ' border' : ''}`} />
              </Fragment>
            ))}
            <Button
              type="button"
              color="primary"
              className="pzpeu-btn-disabled waves-effect waves-light my-2"
              onClick={handleAddUnfairCompetition}
            >
              Dodaj
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default OrderFieldset;
