import React from 'react';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { getFieldForPreview, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_DEADLINE } from 'modules/Agreements/step';
import { AgreementStepDeadlineValues } from 'modules/Agreements/components/Steps/Default/Deadline';

const AgreementStepPreviewAttachments = (props: AgreementStepPreviewProps<any>): JSX.Element => {
  const { steps, modifiedStep } = props;
  const deadline = getStepValues<AgreementStepDeadlineValues>(steps, AGREEMENT_STEP_DEADLINE);

  const { value: deadlines } = getFieldForPreview<AgreementStepDeadlineValues['deadlines']>(
    deadline,
    modifiedStep,
    'deadlines'
  );

  if (!deadlines) return null;

  const files = deadlines
    .reduce(
      (acc, _, index) => [...acc, ...getFieldForPreview(deadline, modifiedStep, `deadlines[${index}].files`).value],
      []
    )
    .map(({ name }) => name);

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="h5 mt-4">Załączniki:</div>
      {files.map((name, index) => (
        <p key={`${index}-${name}`} className="mb-1">
          - {name}
        </p>
      ))}
    </div>
  );
};

export default AgreementStepPreviewAttachments;
