import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import JudgementElement, { createJudgementElement } from 'modules/Judgement/model/JudgementElement';
import { deleteJudgmentsCategory, fetchJudgmentsCategory, updateJudgmentsCategory } from 'modules/Judgement/repository';
import { breadcrumbRouteJudgementsCategoryDetails } from 'modules/Judgement/breadcrumbs';
import useCategoryDeleteModal from 'modules/Judgement/component/Modal/JudgementElement/useCategoryDeleteModal';
import useCategoryUpdateModal from 'modules/Judgement/component/Modal/JudgementElement/useCategoryUpdateModal';
import ManageCategoryModal from 'modules/Judgement/component/Modal/JudgementElement';
import { push } from 'connected-react-router';
import { ROUTE_JUDGEMENT_CATEGORIES } from 'modules/Judgement/routes';
import CategoryBaseDetails from 'modules/Judgement/component/Details/Category/Basic';
import CategorySAOSKeywordsDetails from 'modules/Judgement/component/Details/Category/SAOSKeywords';
import { deleteJudgementCategoryToastSuccess, updateJudgementCategoryToastSuccess } from 'modules/Judgement/toasts';

const Details = () => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState<JudgementElement>(null);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);
  const { id } = useParams<{ id: string }>();

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const { data, cancelled, message: messageResponse } = await fetchRequest(fetchJudgmentsCategory, id, cancelToken);

      if (cancelled) return;
      if (data?.data) setCategory(createJudgementElement(data.data));
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (category) {
      const { name } = category;
      const title = `${name} - szczegóły`;

      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteJudgementsCategoryDetails(name)
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const {
    updateCategoryAction,
    loading: updateLoading,
    displayEditModal,
    setDisplayEditModal,
    message: updateMessage,
    errors,
    clearErrors: cleanUpdate
  } = useCategoryUpdateModal(
    updateJudgmentsCategory,
    updateJudgementCategoryToastSuccess,
    (successData) => {
      setCategory(createJudgementElement(successData));
      setDisplayEditModal(null);
    },
    true
  );

  const onDeleteSuccess = () => dispatch(push(ROUTE_JUDGEMENT_CATEGORIES));

  const {
    deleteCategoryAction,
    loading: deleteLoading,
    displayDeleteModal,
    setDisplayDeleteModal,
    message: deleteMessage,
    clearErrors
  } = useCategoryDeleteModal(deleteJudgmentsCategory, deleteJudgementCategoryToastSuccess, onDeleteSuccess);

  const toggleEditModal = () => {
    if (!updateLoading) setDisplayEditModal(null);
  };

  const toggleDeleteModal = () => {
    if (!deleteLoading) setDisplayDeleteModal(null);
  };

  if (fetching) return <Loader />;
  if (!fetching && !category && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  if (!fetching && category) {
    return (
      <div className="row user-details-view position-relative justify-content-center">
        {displayEditModal && (
          <ManageCategoryModal
            title="Edytuj kategorię"
            isOpen={!!displayEditModal}
            element={category}
            toggle={toggleEditModal}
            loading={updateLoading}
            message={updateMessage}
            errors={errors}
            clearErrorsOnUnmount={cleanUpdate}
            onSubmit={updateCategoryAction}
          />
        )}
        {displayDeleteModal && (
          <GenericModalDelete
            value={displayDeleteModal}
            title="Usuń kategorię"
            content={(value) => (
              <div>
                Czy na pewno chcesz usunąć kategorie o nazwie <strong>{value?.name}</strong>?
              </div>
            )}
            onDeleteClick={(value) => deleteCategoryAction(value.id)}
            isOpen={!!displayDeleteModal}
            toggle={toggleDeleteModal}
            loading={deleteLoading}
            message={deleteMessage}
            resetMessage={clearErrors}
          />
        )}
        <div className="col-12 col-lg-7">
          <CategoryBaseDetails
            category={category}
            setDisplayEditModal={setDisplayEditModal}
            setDisplayDeleteModal={setDisplayDeleteModal}
          />
          <CategorySAOSKeywordsDetails />
        </div>
      </div>
    );
  }

  return null;
};

export default Details;
