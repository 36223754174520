import { FilterEntity, FiltersEntity } from 'modules/Layout/model/Filters';
import { Message, PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import React, { useEffect, useState } from 'react';
import SavedFiltersList from 'modules/Layout/component/ListFilter/SavedFiltersList';
import { saveFilter as saveFilterRequest, deleteFilter, fetchFilterList } from 'modules/Layout/repository';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';

export const removeBracersFromKeys = (filter: SearchParams = {}) => {
  Object.keys(filter).forEach((key) => {
    if (key.includes('[]')) {
      const newKey = key.split('[]').join('');
      // eslint-disable-next-line
      filter[newKey] = filter[key];
      // eslint-disable-next-line
      delete filter[key];
    }
  });

  return filter;
};

const addBracersToKey = (filters: FiltersEntity, keyNameToAddBracers: string[]) => {
  keyNameToAddBracers.forEach((keyName) => {
    // eslint-disable-next-line
    if (filters.hasOwnProperty(keyName)) {
      // eslint-disable-next-line
      filters[`${keyName}[]`] = filters[keyName];
      // eslint-disable-next-line
      delete filters[keyName];
    }
  });

  return filters;
};

const useSaveFilter = (
  filter: SearchParams,
  setParams: (payload: PartialSearchingProps) => void,
  slug: string,
  keyNameToAddBracers?: string[],
  config?: {
    onFilterSelectedClickCustomFnc?: (filter: FiltersEntity) => void;
  }
) => {
  const [saveModal, setSaveModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [filtersList, setFiltersList] = useState<FilterEntity[]>([]);
  const [filterList1stFetchFinished, setFinishedFetch] = useState(false);

  const cancelToken = useCancelToken();
  const fetchList = async () => {
    setLoading(true);
    const { data, cancelled, message: messageResponse } = await fetchRequest(fetchFilterList, slug, cancelToken);

    if (cancelled) return;
    if (data) setFiltersList(data.data);
    if (messageResponse) setMessage(messageResponse);
    setLoading(false);
    setFinishedFetch(true);
  };

  useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveFilter = async (name: string, isDefault = 0, shouldRefetchList?: boolean) => {
    if (loading) return;
    setLoading(true);

    if (message) setMessage(null);

    const {
      data: saveData,
      cancelled,
      message: messageResponse
    } = await updateRequestWithData(
      saveFilterRequest,
      { name, slug, filters: removeBracersFromKeys(filter), default: isDefault },
      cancelToken
    );

    if (cancelled) return;
    if (saveData) {
      const { data: responseData } = saveData;
      setSaveModal(false);

      if (shouldRefetchList) fetchList();
      else {
        const filterIndex = filtersList.findIndex((value) => value.name === responseData.name);
        if (filterIndex === -1) {
          setFiltersList([...filtersList, responseData]);
        } else {
          setFiltersList([...filtersList.slice(0, filterIndex), responseData, ...filtersList.slice(filterIndex + 1)]);
        }
      }
    }
    if (messageResponse) setMessage(messageResponse);
    setLoading(false);
  };

  const onDeleteFilterClick = async (id: number) => {
    if (loading) return;
    setLoading(true);
    const { success, cancelled, message: messageResponse } = await deleteRequest(deleteFilter, id, cancelToken);

    if (cancelled) return;
    if (success) {
      setFiltersList([...filtersList.filter((value) => value.id !== id)]);
    }
    if (messageResponse) setMessage(messageResponse);
    setLoading(false);
  };

  const onFilterSelectedClick = async (id: number) => {
    const selectedFilter = filtersList.find((obj) => obj.id === id);
    if (selectedFilter) {
      const filterValues = keyNameToAddBracers
        ? addBracersToKey(selectedFilter.filters, keyNameToAddBracers)
        : selectedFilter.filters;

      if (config?.onFilterSelectedClickCustomFnc) {
        config.onFilterSelectedClickCustomFnc(filterValues);
      } else setParams({ filter: filterValues });
    }
  };

  return {
    saveModal,
    setSaveModal,
    cleanMessage: () => setMessage(null),
    loading,
    message,
    filtersList,
    saveFilter,
    onDeleteFilterClick,
    onFilterSelectedClick,
    filterList1stFetchFinished
  };
};

export default useSaveFilter;

type Props = {
  filtersList: FilterEntity[];
  onDeleteClick: (id: number) => Promise<void>;
  onSelectedClick: (id: number) => Promise<void>;
  loading: boolean;
};

export const FiltersListDisplay: React.FC<Props> = ({ filtersList, onDeleteClick, onSelectedClick }) => {
  if (filtersList.length)
    return (
      <SavedFiltersList
        filtersList={filtersList}
        onDeleteClick={(id: number) => onDeleteClick(id)}
        onItemClick={(id: number) => onSelectedClick(id)}
      />
    );

  return null;
};
