import { addToastAction, managePageAction } from 'modules/Layout/action';
import Form from 'modules/Executor/component/ExecutorForm/Create';
import React, { useEffect, useState, useRef } from 'react';
import Loader from 'modules/Layout/component/Loader';
import { useDispatch } from 'react-redux';
import { breadcrumbRouteExecutorCreate } from 'modules/Executor/breadcrumbs';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { push } from 'connected-react-router';
import { ROUTE_EXECUTOR_ADMINS, ROUTE_EXECUTORS_ADMINS } from 'modules/Executor/routes';
import { getPathUrl } from 'modules/Shared/helper/api';
import { createExecutorToastSuccess } from 'modules/Executor/toasts';
import { ExecutorEntity } from 'modules/Executor/model/Executor';
import { createAdminExecutor } from 'modules/Executor/repository';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import CardBox from 'modules/Layout/component/CardBox';

const ExeccutorCreateView = () => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState(null);
  const scrollToTopOnce = useRef<boolean>(true);

  const cancelToken = useCancelToken();
  useEffect(() => {
    dispatch(
      managePageAction({
        title: 'Tworzenie wykonawcy, w stosunku do którego mogą wystąpić podstawy do wykluczenia',
        breadcrumb: breadcrumbRouteExecutorCreate(true)
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchCreateExecutorToast = () => dispatch(addToastAction(createExecutorToastSuccess()));
  const setCreateExecutorAction = async (data: ExecutorEntity) => {
    setFetching(true);

    if (message) setMessage(null);
    if (errors) setErrors(null);

    const {
      message: messageResponse,
      error,
      cancelled,
      data: executorData
    } = await updateRequestWithData(createAdminExecutor, data, cancelToken);

    if (cancelled) return;

    if (executorData) {
      dispatchCreateExecutorToast();
      const { id } = executorData?.data;
      if (id) dispatch(push(getPathUrl(ROUTE_EXECUTOR_ADMINS, { id })));
      else dispatch(push(ROUTE_EXECUTORS_ADMINS));
    } else {
      if (error) setErrors(error);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
  }, [errors]);

  return (
    <div className="user-create-view d-flex justify-content-center">
      <CardBox form="small" heading="Dodaj wykonawcę">
        {fetching && <Loader />}
        <Alert message={message} />
        <Form errors={errors} submit={(data) => setCreateExecutorAction(data)} />
      </CardBox>
    </div>
  );
};

export default ExeccutorCreateView;
