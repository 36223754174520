import { RootState } from 'app/reducer';
import { CallHistoryMethodAction, replace } from 'connected-react-router';
import { Location, LocationDescriptorObject } from 'history';
import { managePageAction, ManagePageAction } from 'modules/Layout/action';
import AdminDashboard from 'modules/Layout/component/DashboardDetails/admin';
import ClientDashboard from 'modules/Layout/component/DashboardDetails/client';
import { PageProps } from 'modules/Layout/type';
import User from 'modules/User/model/User';
import { ROLE_ADMIN, ROLE_CLIENT } from 'modules/User/model/UserRole';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

export interface LocationState {
  reload?: boolean;
}

export interface DashboardStateProps {
  user: User;
  locationState: Location<LocationState>['state'] | null;
}

export interface DashboardDispatchProps {
  routerReplace: (location: LocationDescriptorObject<LocationState>) => CallHistoryMethodAction;
  managePage: (payload: PageProps) => ManagePageAction;
}

export type DashboardProps = DashboardDispatchProps & DashboardStateProps;

export const mapDashboardDispatch = (dispatch: Dispatch): DashboardDispatchProps => ({
  routerReplace: (location) => dispatch(replace(location)),
  managePage: (payload: PageProps) => dispatch(managePageAction(payload))
});

export const mapDashboardState = (state: RootState): DashboardStateProps => {
  const { user } = state.auth;
  const { state: locationState } = state.router.location;

  return {
    user,
    locationState
  };
};

export class Dashboard extends React.Component<DashboardProps> {
  componentDidMount(): void {
    const { managePage, locationState, routerReplace } = this.props;

    if (locationState?.reload === true) {
      routerReplace({ state: { reload: false } });

      window.location.reload();
    } else {
      managePage({ title: 'Strona główna', breadcrumb: [] });
    }
  }

  shouldComponentUpdate(nextProps: Readonly<DashboardProps>): boolean {
    const { locationState } = this.props;

    return !(locationState?.reload === true && nextProps.locationState?.reload === false);
  }

  render(): React.ReactNode {
    const { user, locationState } = this.props;

    if (locationState?.reload === true) {
      return null;
    }

    const isClient = user?.role?.slug === ROLE_CLIENT;
    const isAdmin = user?.role?.slug === ROLE_ADMIN;

    const displayDashboard = () => {
      if (isClient) return <ClientDashboard user={user} />;
      if (isAdmin) return <AdminDashboard user={user} />;
      return null;
    };

    return <div className="dashboard-view row">{user && <div className="col-12">{displayDashboard()}</div>}</div>;
  }
}

export default connect<DashboardStateProps, DashboardDispatchProps>(mapDashboardState, mapDashboardDispatch)(Dashboard);
