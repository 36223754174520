import React, { useEffect, useRef, useState } from 'react';
import Judgement from 'modules/Judgement/model/Judgement';
import JudgementBasicDetails from 'modules/JudgementClient/component/Details/Judgement/Basic';
import KeywordListIncluded from 'modules/JudgementClient/component/Details/Judgement/KeywordListIncluded';
import ClauseListIncluded from 'modules/JudgementClient/component/Details/Judgement/ConnectedClauses';
import JudgementContentDetails from 'modules/JudgementClient/component/Details/Judgement/Content';
import { RootState } from 'app/reducer';
import { useSelector } from 'react-redux';
import useIsMobile from 'modules/Shared/helper/hooks/useIsMobile';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';

type Props = {
  judgement: Judgement;
};

export type ContentObjType = { content: string; headingInfo: string; id?: number; type?: 'keyword' | 'clause' };

const JudgmentClientDisplayDetails: React.FC<Props> = (props) => {
  const { judgement } = props;
  const { filter } = useSelector((state: RootState) => state.judgmentClient.judgementClientList);

  const [contentObj, setContentObj] = useState<ContentObjType>(null);
  const [keywordsIncluded, setKeywordsIncluded] = useState<JudgementElementEntity[]>([]);
  const [clausesIncluded, setClausesIncluded] = useState<JudgmentDetailsConnectedClausesValue[]>([]);

  const contentFieldRef = useRef<HTMLDivElement>(null);
  const keywordListRef = useRef<HTMLDivElement>(null);
  const clauseListRef = useRef<HTMLDivElement>(null);

  const { isMobile } = useIsMobile();

  useEffect(() => {
    if (isMobile && contentObj && contentFieldRef.current)
      contentFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    if (!isMobile && keywordsIncluded.length && keywordListRef.current)
      keywordListRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    if (!isMobile && clausesIncluded.length && clauseListRef.current)
      clauseListRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentObj]);

  const selectedKeyword = (filter?.keyword_id as number) ?? null;

  const [initClauseId, setInitClauseId] = useState(null);
  useEffect(() => {
    if (!selectedKeyword) {
      const urlParams = new URLSearchParams(window.location.search);
      const clauseId = urlParams.get('clause');
      const parsedClauseId = parseInt(clauseId, 10) || 0;

      if (parsedClauseId) {
        setInitClauseId(parsedClauseId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chipStyle = {
    paddingRight: '12px',
    cursor: 'pointer',
    fontSize: '16px'
  };

  return (
    <>
      <div className="col-12">
        <JudgementBasicDetails {...props} />
      </div>
      <div className="col-6">
        <div ref={keywordListRef} />

        <KeywordListIncluded
          activeKeyword={contentObj}
          judgment={judgement}
          keywordsIncluded={keywordsIncluded}
          setKeywordsIncluded={setKeywordsIncluded}
          setContentObj={setContentObj}
          selectedKeyword={selectedKeyword}
          chipStyle={chipStyle}
        />
      </div>
      <div className="col-6">
        <div ref={clauseListRef} />

        <ClauseListIncluded
          activeKeyword={contentObj}
          judgment={judgement}
          clausesIncluded={clausesIncluded}
          setClausesIncluded={setClausesIncluded}
          setContentObj={setContentObj}
          selectedClause={initClauseId}
          chipStyle={chipStyle}
        />
      </div>
      <div className="col-12">
        <JudgementContentDetails
          content={contentObj?.content ?? judgement.content}
          headingInfo={contentObj?.headingInfo}
          contentFieldRef={contentFieldRef}
        />
      </div>
    </>
  );
};

export default JudgmentClientDisplayDetails;
