import React, { useEffect, useRef, useState } from 'react';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_EXECUTOR_DUTIES } from 'modules/Agreements/step';
import { FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import useStepFields from 'modules/Agreements/hook/useStepFields';

export interface AgreementStepExecutorDutiesValues {
  ser_recommendations_checked: boolean;
  ser_recommendations_content: string | null;
  ser_proper_execution_checked: boolean;
  ser_proper_execution_content: string | null;
  schedule_checked: boolean;
  schedule_content: string | null;
  ser_consulting_checked: boolean;
  ser_consulting_content: string | null;
  ser_informing_checked: boolean;
  ser_informing_content: string | null;
  contracting_authority_checked: boolean;
  contracting_authority_content: string | null;
  ser_no_disturbing_checked: boolean;
  ser_no_disturbing_content: string | null;
  subexecutors_works_checked: boolean;
  subexecutors_works_content: string | null;
  technical_equipment_checked: boolean;
  technical_equipment_content: string | null;
  ser_responsibility_checked: boolean;
  ser_responsibility_content: string | null;
  shortcomings_checked: boolean;
  shortcomings_content: string | null;
  no_realization_checked: boolean;
  no_realization_content: string | null;
  checkboxes: AgreementCheckboxes | null;
}

const SER_RECOMMENDATIONS_CONTENT =
  'Wykonanie Przedmiotu Umowy zgodnie z zaleceniami zamawiającego, zasadami aktualnej wiedzy technicznej, obowiązującymi w tym zakresie przepisami oraz ze starannością właściwą dla profesjonalnego charakteru działalności jaką prowadzi wykonawca';
const SER_PROPER_EXECUTION_CONTENT =
  'Prawidłowe wykonanie wszystkich usług związanych z realizacją Przedmiotu Umowy, zgodnie z treścią Umowy i wszelkimi pozostałymi załącznikami';
const SCHEDULE_CONTENT =
  'Wykonanie i przedłożenie do akceptacji zamawiającego przed przystąpieniem do realizacji usług w terminie do .... od daty podpisania Umowy szczegółowego Harmonogramu rzeczowo - terminowo – finansowego, obejmującego Harmonogram usług opracowany przez wykonawcę i zatwierdzony przez zamawiającego.  W szczególności, przedmiotowy Harmonogram musi uwzględniać zasady płatności części wynagrodzenia. Przedmiotowy Harmonogram stanowi jako załącznik integralną część Umowy';
const SER_CONSULTING_CONTENT =
  'Konsultowanie z zamawiającym wszelkich odstępstw, sugestii i zmian co do Przedmiotu Umowy, w szczególności konieczności dokonania zmiany sposobu wykonywania, terminów realizacji, warunków realizacji Przedmiotu Umowy, wynikających zarówno z okoliczności zależnych jak i niezależnych od stron, a także w przypadku powzięcia informacji o możliwości wykonania Przedmiotu Umowy mniejszym nakładem sił, kosztów lub w sposób zapewniający efektywniejsze wykonanie Przedmiotu Umowy';
const SER_INFORMING_CONTENT =
  'Informowanie zamawiającego o wszelkich możliwych przeszkodach, utrudnieniach, uchybieniach i innych okolicznościach mogących wpłynąć na niewykonanie bądź nienależyte wykonanie Umowy';
const CONTRACTING_AUTHORITY_CONTENT =
  'Współpraca ze służbami o organami administracji publicznej władzy publicznej, nadzorujących działalność zamawiającego';
const SER_NO_DISTURBING_CONTENT = 'Realizacje usług w sposób nie zakłócający funkcjonowania zamawiającego';
const SUBEXECUTORS_WORKS_CONTENT = 'Koordynacja czynności realizowanych przez podwykonawców';
const TECHNICAL_EQUIPMENT_CONTENT =
  'Zapewnienie sprzętu technicznego spełniającego wymagania norm technicznych,  SWZ oraz Umowy';
const SER_RESPONSIBILITY_CONTENT =
  'Ponoszenie odpowiedzialności wobec zamawiającego i osób trzecich za szkody powstałe na skutek czynów niedozwolonych jakie wykonawca dopuścił się w ramach wykonywania Przedmiotu Umowy';
const SHORTCOMINGS_CONTENT =
  'W przypadku uchybienia przez wykonawcę któremukolwiek obowiązkowi określonemu w tym paragrafie, zamawiający ma prawo zawrzeć niezbędne umowy na koszt i ryzyko wykonawcy, co nie zwalnia wykonawcy z obowiązku zapłaty kar umownych';
const NO_REALIZATION_CONTENT =
  'W przypadku braku realizacji lub nienależytej realizacji obowiązków wykonawcy ujętych w Umowie oraz w przypadku braku realizacji lub nienależytej realizacji usług, po uprzednim wezwaniu wykonawcy do wykonania lub należytego wykonania Umowy oraz wyznaczenia dodatkowego co najmniej .... terminu, zamawiający uprawniony jest do zlecenia wykonania tych usług, lub obowiązków wykonawcy ujętych w Umowie osobie trzeciej na koszt i ryzyko wykonawcy';

const initContentValues = {
  ser_recommendations_content: SER_RECOMMENDATIONS_CONTENT,
  ser_proper_execution_content: SER_PROPER_EXECUTION_CONTENT,
  schedule_content: SCHEDULE_CONTENT,
  ser_consulting_content: SER_CONSULTING_CONTENT,
  ser_informing_content: SER_INFORMING_CONTENT,
  contracting_authority_content: CONTRACTING_AUTHORITY_CONTENT,
  ser_no_disturbing_content: SER_NO_DISTURBING_CONTENT,
  subexecutors_works_content: SUBEXECUTORS_WORKS_CONTENT,
  technical_equipment_content: TECHNICAL_EQUIPMENT_CONTENT,
  ser_responsibility_content: SER_RESPONSIBILITY_CONTENT,
  shortcomings_content: SHORTCOMINGS_CONTENT,
  no_realization_content: NO_REALIZATION_CONTENT
};

const mapState = (step: AgreementStepExecutorDutiesValues): AgreementStepExecutorDutiesValues => {
  const {
    ser_recommendations_content,
    ser_proper_execution_content,
    schedule_content,
    ser_consulting_content,
    ser_informing_content,
    contracting_authority_content,
    ser_no_disturbing_content,
    subexecutors_works_content,
    technical_equipment_content,
    ser_responsibility_content,
    shortcomings_content,
    no_realization_content,
    checkboxes,
    ...rest
  } = step;

  return {
    ...rest,
    ser_recommendations_content: ser_recommendations_content || SER_RECOMMENDATIONS_CONTENT,
    ser_proper_execution_content: ser_proper_execution_content || SER_PROPER_EXECUTION_CONTENT,
    schedule_content: schedule_content || SCHEDULE_CONTENT,
    ser_consulting_content: ser_consulting_content || SER_CONSULTING_CONTENT,
    ser_informing_content: ser_informing_content || SER_INFORMING_CONTENT,
    contracting_authority_content: contracting_authority_content || CONTRACTING_AUTHORITY_CONTENT,
    ser_no_disturbing_content: ser_no_disturbing_content || SER_NO_DISTURBING_CONTENT,
    subexecutors_works_content: subexecutors_works_content || SUBEXECUTORS_WORKS_CONTENT,
    technical_equipment_content: technical_equipment_content || TECHNICAL_EQUIPMENT_CONTENT,
    ser_responsibility_content: ser_responsibility_content || SER_RESPONSIBILITY_CONTENT,
    shortcomings_content: shortcomings_content || SHORTCOMINGS_CONTENT,
    no_realization_content: no_realization_content || NO_REALIZATION_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepExecutorDuties = (props: AgreementStepProps): JSX.Element => {
  const { steps, onSubmit, onChange, errors } = props;

  const initState = useRef<AgreementStepExecutorDutiesValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_EXECUTOR_DUTIES))
  );

  const [stepValues, setStepValues] = useState<AgreementStepExecutorDutiesValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_EXECUTOR_DUTIES));
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderEditableCheckbox, renderSwitch, renderContent, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    initContentValues
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        {renderEditableCheckbox('ser_recommendations')}
        {renderEditableCheckbox('ser_proper_execution')}
        {renderSwitch('schedule_checked', 'Czy zamawiający wymaga aby wykonawca opracował i przedłożył harmonogram?')}
        {stepValues.schedule_checked && renderContent('schedule_content')}
        {renderEditableCheckbox('ser_consulting')}
        {renderEditableCheckbox('ser_informing')}
        {renderEditableCheckbox('contracting_authority')}
        {renderEditableCheckbox('ser_no_disturbing')}
        {renderEditableCheckbox('subexecutors_works')}
        {renderEditableCheckbox('technical_equipment')}
        {renderEditableCheckbox('ser_responsibility')}
        {renderEditableCheckbox('shortcomings')}
        {renderEditableCheckbox('no_realization')}
        {renderCustomCheckboxes()}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepExecutorDuties;
