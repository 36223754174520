import { Action, Dispatch } from 'redux';
import { cancelPayment, successPayment } from 'modules/Subscriptions/repository';
import { ColorVariants, Message } from 'modules/Shared/type';
import { PaymentEndValues } from 'modules/Subscriptions/type';

export const SUBSCRIPTIONS_PAYMENT_SESSION = 'SUBSCRIPTIONS/PAYMENT/SESSION';
export const SUBSCRIPTIONS_PAYMENT_FETCHING = 'SUBSCRIPTIONS/PAYMENT/FETCHING';
export const SUBSCRIPTIONS_PAYMENT_FETCHED = 'SUBSCRIPTIONS/PAYMENT/FETCHED';
export const SUBSCRIPTIONS_PAYMENT_CLEAN = 'SUBSCRIPTIONS/PAYMENT/CLEAN';

export type SubscriptionsPaymentAction =
  | SaveSessionIdAction
  | FetchingSubscriptionsPaymentAction
  | SubscriptionsPaymentFetchedAction
  | CleanSubscriptionsPaymentAction;

export interface SaveSessionIdAction extends Action<typeof SUBSCRIPTIONS_PAYMENT_SESSION> {
  payload: string;
}

export type FetchingSubscriptionsPaymentAction = Action<typeof SUBSCRIPTIONS_PAYMENT_FETCHING>;

export interface SubscriptionsPaymentFetchedAction extends Action<typeof SUBSCRIPTIONS_PAYMENT_FETCHED> {
  payload: { details: PaymentEndValues; message: Message; errorMessage?: Message };
}

export const subscriptionsPaymentFetchedAction = (
  details: PaymentEndValues,
  message: Message,
  errorMessage?: Message
): SubscriptionsPaymentFetchedAction => ({
  type: SUBSCRIPTIONS_PAYMENT_FETCHED,
  payload: { details, message, errorMessage }
});

export const saveSessionIdAction = (session_id: string): SaveSessionIdAction => ({
  type: SUBSCRIPTIONS_PAYMENT_SESSION,
  payload: session_id
});

export const fetchingSubscriptionsPaymentAction = (): FetchingSubscriptionsPaymentAction => ({
  type: SUBSCRIPTIONS_PAYMENT_FETCHING
});

export const endPaymentAction =
  (session_id: string, isSuccess = false) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchingSubscriptionsPaymentAction());

    try {
      let response;
      if (isSuccess) {
        response = await successPayment(session_id);
      } else {
        response = await cancelPayment(session_id);
      }

      const { message, payment } = response?.data;

      dispatch(
        subscriptionsPaymentFetchedAction(payment, {
          value: message,
          variant: isSuccess ? ColorVariants.Success : ColorVariants.Danger
        })
      );
    } catch (error) {
      const errorMessage = {
        value: error?.error?.response?.data?.message || error?.message || 'Something went wrong.',
        variant: ColorVariants.Danger
      };

      dispatch(subscriptionsPaymentFetchedAction(null, null, errorMessage));
    }
  };

export type CleanSubscriptionsPaymentAction = Action<typeof SUBSCRIPTIONS_PAYMENT_CLEAN>;

export const cleanSubscriptionsPaymentAction = (): CleanSubscriptionsPaymentAction => ({
  type: SUBSCRIPTIONS_PAYMENT_CLEAN
});
