import React from 'react';
import { AGREEMENT_TYPE_PROJECT, AGREEMENT_TYPE_SUPPLEMENT, AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementStepSubexecutorsValues } from 'modules/Agreements/components/Steps/Default/Subexecutors';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_SUBEXECUTORS } from 'modules/Agreements/step';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import classNames from 'classnames';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';

const AgreementStepPreviewSubexecutors = (
  props: AgreementStepPreviewProps<AgreementStepSubexecutorsValues>
): JSX.Element => {
  const { steps, modifiedStep, agreement } = props;
  const subexecutors = getStepValues<AgreementStepSubexecutorsValues>(steps, AGREEMENT_STEP_SUBEXECUTORS);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={subexecutors}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const { value: projectObjectionsPossibility, modified: projectObjectionsModified } = getFieldForPreview(
    subexecutors,
    modifiedStep,
    'project_objections_possibility'
  );

  const { value: agreementObjectionsPossibility, modified: agreementObjectionsModified } = getFieldForPreview(
    subexecutors,
    modifiedStep,
    'agreement_objections_possibility'
  );

  const incompatiblePzp = [AGREEMENT_TYPE_PROJECT, AGREEMENT_TYPE_SUPPLEMENT].includes(agreement.type);

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    subexecutors,
    modifiedStep,
    `checkboxes.general`
  );

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_SUBEXECUTORS)}</div>
      {renderCheckbox('agreement_project')}
      {renderCheckbox('payment_date')}
      {renderCheckbox('objections_date')}
      {projectObjectionsPossibility && (
        <div className={classNames({ modified: projectObjectionsModified })}>
          <p className={classNames('preview-point')}>
            Zamawiający zgłasza w formie pisemnej, pod rygorem nieważności, zastrzeżenia do projektu umowy o
            podwykonawstwo, której przedmiotem są roboty budowlane, w przypadku gdy:
          </p>
          {renderCheckbox('project_fails_requirements', 'preview-nested-decimal-point')}
          {renderCheckbox('project_longer_payment_date', 'preview-nested-decimal-point')}
          {incompatiblePzp && renderCheckbox('project_incompatible_pzp', 'preview-nested-decimal-point')}
          {renderCheckbox('project_incompatible_agreement', 'preview-nested-decimal-point')}
          {renderCheckbox('project_objections_others', 'preview-nested-decimal-point')}
        </div>
      )}
      {renderCheckbox('agreement_copy')}
      {agreementObjectionsPossibility && (
        <div className={classNames({ modified: agreementObjectionsModified })}>
          <p className={classNames('preview-point')}>
            Zamawiający zgłasza w formie pisemnej, pod rygorem nieważności, zastrzeżenia do umowy o podwykonawstwo,
            której przedmiotem są dostawy lub usługi, w przypadku gdy:
          </p>
          {renderCheckbox('agreement_fails_requirements', 'preview-nested-decimal-point')}
          {renderCheckbox('agreement_longer_payment_date', 'preview-nested-decimal-point')}
          {incompatiblePzp && renderCheckbox('agreement_incompatible_pzp', 'preview-nested-decimal-point')}
          {renderCheckbox('agreement_incompatible_agreement', 'preview-nested-decimal-point')}
          {renderCheckbox('agreement_objections_others', 'preview-nested-decimal-point')}
        </div>
      )}
      {renderCheckbox('decision')}
      {renderCheckbox('quality')}
      {renderCheckbox('copies')}
      {generalCheckboxes &&
        generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
          if (!checkbox.checked) return null;

          return (
            <p
              className={classNames('preview-point wysiwyg-preview', {
                modified: generalCheckboxesModified
              })}
              key={index}
              dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
            />
          );
        })}
    </div>
  );
};

export default AgreementStepPreviewSubexecutors;
