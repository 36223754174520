import { hasToken } from 'modules/Auth/service';
import React from 'react';
import { Redirect } from 'react-router-dom';

export type Props = {
  redirect?: string;
};

const Unguarded: React.FC<Props> = ({ redirect = '/dashboard', children }) => {
  const isToken = hasToken();

  if (isToken) {
    return <Redirect to={redirect} />;
  }

  return <>{children}</>;
};

export default Unguarded;
