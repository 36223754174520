import React from 'react';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_DEADLINE } from 'modules/Agreements/step';
import {
  AgreementStepDeadlineValues,
  deadlineUnitOptions,
  PartialDeadline
} from 'modules/Agreements/components/Steps/Default/Deadline';

const AgreementStepPreviewDeadline = (props: AgreementStepPreviewProps<AgreementStepDeadlineValues>): JSX.Element => {
  const { agreement, steps, modifiedStep } = props;
  const deadline = getStepValues<AgreementStepDeadlineValues>(steps, AGREEMENT_STEP_DEADLINE);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        key={`${checkbox}_checked-${checkbox}_content`}
        step={deadline}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const renderDeadlineText = (index: number) => {
    const { value: deadlineTypeOption, modified: deadlineTypeOptionModified } = getFieldForPreview(
      deadline,
      modifiedStep,
      `deadlines.${index}.deadline_type_option`
    );

    if (!deadlineTypeOption) return null;

    const { value: deadlineDate, modified: deadlineDateModified } = getFieldForPreview(
      deadline,
      modifiedStep,
      `deadlines.${index}.deadline_date`
    );

    const { value: deadlineUnit, modified: deadlineUnitModified } = getFieldForPreview(
      deadline,
      modifiedStep,
      `deadlines.${index}.deadline_unit`
    );

    const { value: deadlineValue, modified: deadlineValueModified } = getFieldForPreview(
      deadline,
      modifiedStep,
      `deadlines.${index}.deadline_value`
    );

    const { value: startDateOption, modified: startDateOptionModified } = getFieldForPreview(
      deadline,
      modifiedStep,
      `deadlines.${index}.start_date_option`
    );

    const { value: startDate, modified: startDateModified } = getFieldForPreview(
      deadline,
      modifiedStep,
      `deadlines.${index}.start_date`
    );

    let text = '';
    let startDateText = '........';
    if (startDateOption) startDateText = startDateOption === 'date' ? startDate || '........' : 'udzielenie zamówienia';

    if (deadlineTypeOption === 'date') {
      const deadlineDateText = deadlineDate || '........';

      text = `Termin wykonania zamówienia wynosi począwszy od ${startDateText} do dnia ${deadlineDateText}`;
    }

    if (deadlineTypeOption === 'unit') {
      const deadlineValueText = deadlineValue || '........';
      const deadlineUnitText = deadlineUnitOptions.find((option) => option.id == deadlineUnit)?.value || '........';

      text = `Termin wykonania zamówienia wynosi: ${deadlineValueText} ${deadlineUnitText} począwszy od ${startDateText}`;
    }

    return (
      <p
        key={`deadline.${index}.deadline_type_option`}
        className={classNames('preview-point', {
          modified:
            deadlineTypeOptionModified ||
            startDateOptionModified ||
            deadlineDateModified ||
            startDateModified ||
            deadlineUnitModified ||
            deadlineValueModified
        })}
      >
        {text}
      </p>
    );
  };

  const renderPartialDeadline = (partialDeadline: PartialDeadline, index: number) => {
    const { modified: itemModified } = getFieldForPreview(
      deadline,
      modifiedStep,
      `deadlines.${index}.partial_deadlines.${index}`
    );

    const deadlineValue = (() => {
      const { deadline_type_option, deadline_unit, deadline_value, deadline } = partialDeadline;

      if (!deadline_type_option) return null;

      if (deadline_type_option === 'date') return deadline;

      const deadlineUnitText = deadlineUnitOptions.find((option) => option.id == deadline_unit)?.value || '....';

      return `${deadline_value} ${deadlineUnitText}`;
    })();

    return (
      <div
        key={`deadline.${index}.partial_deadlines.${index}`}
        className={classNames({
          modified: itemModified
        })}
      >
        <p className="mb-1">Termin częściowy {index + 1}</p>
        <p className="mb-1">Termin - {deadlineValue}</p>
        <p className="mb-1">Zakres dostaw - {partialDeadline.scope}</p>
      </div>
    );
  };

  const { value: parts } = getFieldForPreview<AgreementStepDeadlineValues['deadlines']>(
    deadline,
    modifiedStep,
    'deadlines'
  );

  const { value: sameParts } = getFieldForPreview<AgreementStepDeadlineValues['same_deadline_for_parts']>(
    deadline,
    modifiedStep,
    'same_deadline_for_parts'
  );

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    deadline,
    modifiedStep,
    `checkboxes.general`
  );

  const isMultiple = agreement.few_parts && !sameParts;

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_DEADLINE)}</div>
      <div className="reset-preview-point">
        {parts?.map((part, index: number) => {
          const { value: deadlineScheduleChecked, modified: deadlineScheduleCheckedModified } = getFieldForPreview(
            deadline,
            modifiedStep,
            `deadlines.${index}.deadline_schedule_checked`
          );

          const { value: deadlinePartialChecked, modified: deadlinePartialCheckedModified } = getFieldForPreview(
            deadline,
            modifiedStep,
            `deadlines.${index}.deadline_partial_checked`
          );

          const { value: delOrderWaySwitch, modified: delOrderWaySwitchModified } = getFieldForPreview(
            deadline,
            modifiedStep,
            `deadlines.${index}.del_order_way_switch`
          );

          const partObj = agreement.parts_ids.find((item) => item.id === part.part_id);

          return (
            <div key={index} className="agreement-step-preview-content reset-preview-point">
              {isMultiple && partObj && <div className="h5">{partObj.getName(index)}:</div>}
              {renderCheckbox(`deadlines.${index}.del_in_batches`)}
              {renderDeadlineText(index)}
              {deadlineScheduleChecked && (
                <p
                  key={`deadlines.${index}.deadline_schedule_checked`}
                  className={classNames('preview-point', {
                    modified: deadlineScheduleCheckedModified
                  })}
                >
                  Szczegółowe terminy wykonania poszczególnych części Przedmiotu Umowy określa Harmonogram dostaw
                  stanowiący załącznik do Umowy
                </p>
              )}
              {deadlinePartialChecked && (
                <>
                  <p
                    key={`deadlines.${index}.deadline_partial_checked`}
                    className={classNames('preview-point', {
                      modified: deadlinePartialCheckedModified
                    })}
                  >
                    W Umowie określono następujące terminy częściowe:
                  </p>
                  {part.partial_deadlines.map(renderPartialDeadline)}
                </>
              )}
              {renderCheckbox(`deadlines.${index}.del_partial`)}
              {renderCheckbox(`deadlines.${index}.documentation`)}
              {delOrderWaySwitch && [
                <p
                  key={`deadlines.${index}.del_order_way_switch`}
                  className={classNames('preview-point', {
                    modified: delOrderWaySwitchModified
                  })}
                >
                  Zamówienie może być złożone:
                </p>,
                renderCheckbox(`deadlines.${index}.del_order_way_personal`, 'preview-nested-alpha-point'),
                renderCheckbox(`deadlines.${index}.del_order_way_phone`, 'preview-nested-alpha-point'),
                renderCheckbox(`deadlines.${index}.del_order_way_fax`, 'preview-nested-alpha-point'),
                renderCheckbox(`deadlines.${index}.del_order_way_mail`, 'preview-nested-alpha-point'),
                renderCheckbox(`deadlines.${index}.del_order_way_other`, 'preview-nested-alpha-point')
              ]}
              {renderCheckbox(`deadlines.${index}.del_parts_arrangements`)}
              {renderCheckbox(`deadlines.${index}.del_max_realization`)}
              {generalCheckboxes &&
                generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
                  if (!checkbox.checked) return null;

                  if (checkbox.part_id != part.part_id) return null;

                  return (
                    <p
                      className={classNames('preview-point wysiwyg-preview', {
                        modified: generalCheckboxesModified
                      })}
                      key={index}
                      dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                    />
                  );
                })}
            </div>
          );
        })}
        {isMultiple && generalCheckboxes && (
          <div className="agreement-step-preview-content">
            <p className="h5 reset-preview-point">Ponadto dla każdego z zadań:</p>
            {generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
              if (!checkbox.checked) return null;

              if (checkbox.part_id) return null;

              return (
                <p
                  className={classNames('preview-point wysiwyg-preview', {
                    modified: generalCheckboxesModified
                  })}
                  key={index}
                  dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AgreementStepPreviewDeadline;
