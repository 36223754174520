import CardBox from 'modules/Layout/component/CardBox';
import React from 'react';
import { FormGroup } from 'reactstrap';
import './index.scss';
import AlertsDataset from '../../Dataset/Alerts';
import { AlertFormData } from '../../Form/AlertForm';

export interface Props {
  alertDetails: AlertFormData;
}

const AlertsDetails: React.FC<Props> = ({ alertDetails }) => {
  return (
    <CardBox
      heading={alertDetails?.name}
      paddingLeft="30px"
      paddingRight="30px"
      paddingBottom="30px"
      className="imports-details"
    >
      <FormGroup className="form-group-large-margins">
        <AlertsDataset alertDetails={alertDetails} />
      </FormGroup>
    </CardBox>
  );
};

export default AlertsDetails;
