import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import { OrderEntity, PenaltyDataItem } from 'modules/ExecutorOrder/model/Order';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';

export interface Props {
  penalty?: PenaltyDataItem;
  order?: OrderEntity;
}

const ExecutorDataClientPenaltiesDataset: React.FC<Props> = ({ penalty = {}, order = {} }) => {
  const fields = [
    {
      label: 'Podmiot udzielający informacji publicznej (zamawiający)',
      value: <strong>{order.principal_name || 'nie dotyczy'}</strong>
    },
    {
      label: 'Nr umowy o udzielenie zamówienia',
      value: order.contract_number || 'nie dotyczy'
    },
    {
      label: 'Wysokość wynagrodzenia wykonawcy',
      value: penalty.executor_salary ? `${penalty.executor_salary.toFixed(2).replace('.', ',')} PLN` : 'nie dotyczy'
    },
    {
      label: 'Wysokość kary umownej',
      value: penalty.penalty_amount ? `${penalty.penalty_amount.toFixed(2).replace('.', ',')} PLN` : 'nie dotyczy'
    },
    {
      label: 'Data naliczenia kary umownej',
      value: ifContainsDateFormat(penalty.violation_date, formatDate) || 'nie dotyczy'
    },
    {
      label: 'Nazwa oraz numer postępowania',
      value:
        order.name || order.identifier
          ? `${order.name || '-'}; numer postępowania: ${order.identifier || '-'}`
          : 'nie dotyczy'
    },
    {
      label: 'Przyczyny naliczenia kar umownych',
      value: penalty.violation_description || 'nie dotyczy',
      html: true
    }
  ];

  return <InformationList fields={fields} />;
};

export default ExecutorDataClientPenaltiesDataset;
