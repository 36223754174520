import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
// eslint-disable-next-line import/no-cycle
import useFavouriteForeignProceedingsList from 'modules/ForeignProceedings/component/Lists/FavouriteListHooks';
// eslint-disable-next-line import/no-cycle
import { breadcrumbRouteForeignProceedingsOpenFav } from '../../../breadcrumbs';
// eslint-disable-next-line import/no-cycle
import { ROUTE_FOREIGN_PROCEEDINGS_ADMIN_OPEN_FAV } from '../../../routes';

const ForeignProceedingsFavouritesOpenListAdminView = () => {
  const dispatch = useDispatch();

  const { jsxFnc } = useFavouriteForeignProceedingsList(ROUTE_FOREIGN_PROCEEDINGS_ADMIN_OPEN_FAV, true);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  useEffect(() => {
    managePage({
      title: 'Ulubione zagraniczne postępowania - lista',
      breadcrumb: breadcrumbRouteForeignProceedingsOpenFav()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return jsxFnc();
};

export default ForeignProceedingsFavouritesOpenListAdminView;
