import _get from 'lodash/get';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import React from 'react';
import { FilterInputType } from 'modules/Layout/type';
import FilterTable from 'modules/Layout/component/Listing/Filter';
import { RootState } from 'app/reducer';
import { setOrderListParamsAction } from 'modules/ExecutorOrder/action/list';
import { useDispatch, useSelector } from 'react-redux';

export interface Props {
  values?: SearchParams;
  executor_id?: number | string;
}

const OrdersFilter: React.FC<Props> = ({ values = {}, executor_id = 0 }) => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.order.list);
  const setParams = (payload: PartialSearchingProps) => dispatch(setOrderListParamsAction(payload, executor_id));

  const staticInputs: FilterInputType[] = [
    {
      type: 'text',
      property: 'name',
      label: 'Nazwa',
      className: 'col-12 col-sm-6 col-md-5 col-xl-3',
      value: _get(values, 'name')
    },
    {
      type: 'text',
      property: 'principal_name',
      label: 'Nazwa zleceniodawcy',
      className: 'col-12 col-sm-6 col-md-5 col-xl-3',
      value: _get(values, 'principal_name')
    },
    {
      type: 'text',
      property: 'identifier',
      label: 'Numer postępowania',
      className: 'col-12 col-sm-6 col-md-5 col-xl-3',
      value: _get(values, 'identifier')
    },
    {
      type: 'text',
      property: 'contract_number',
      label: 'Numer umowy',
      className: 'col-12 col-sm-6 col-md-5 col-xl-3',
      value: _get(values, 'contract_number')
    },
    {
      type: 'date',
      property: 'contract_date_from',
      label: 'Data zawarcia umowy od',
      className: 'col-auto form-group',
      beforeLineBreak: true,
      value: _get(values, 'contract_date_from')
    },
    {
      type: 'date',
      property: 'contract_date_to',
      label: 'Data zawarcia umowy do',
      className: 'col-auto form-group',
      value: _get(values, 'contract_date_to')
    }
  ];

  return <FilterTable inputs={staticInputs} filter={filter} pagination={pagination} setParams={setParams} />;
};

export default OrdersFilter;
