import { getError, hasError } from 'modules/Shared/helper/validation';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import _uniqueId from 'lodash/uniqueId';
import { PROCEEDINGS_INIT_TYPES_VALUES } from 'modules/Proceedings/type';
import Datetime from 'modules/Layout/component/Input/Datetime';
import { Props } from 'modules/Proceedings/component/Fieldset/Main';

const ProceedingsFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, setValues, placeholder = false } = props;

  const { proceeding_type, bzp_date, identifier = '', bzp_identifier = '' } = values;

  return (
    <>
      {proceeding_type === PROCEEDINGS_INIT_TYPES_VALUES.OPEN && (
        <>
          <FormGroup>
            {!placeholder && <Label for="input-identifier">Numer postępowania</Label>}
            <Input
              type="text"
              name="identifier"
              id="input-identifier"
              placeholder={placeholder ? 'Numer postępowania' : null}
              value={identifier}
              onChange={(event) => setValues({ ...values, identifier: event.target.value })}
              invalid={hasError(errors, 'identifier')}
              maxLength={255}
            />
            {hasError(errors, 'identifier') && <FormFeedback>{getError(errors, 'identifier')}</FormFeedback>}
          </FormGroup>
          <FormGroup>
            {!placeholder && <Label for="input-bzp_identifier">Numer ogłoszenia o zamówieniu w BZP</Label>}
            <Input
              type="text"
              name="bzp_identifier"
              id="input-bzp_identifier"
              placeholder={placeholder ? 'Numer ogłoszenia o zamówieniu w BZP' : null}
              value={bzp_identifier}
              onChange={(event) => setValues({ ...values, bzp_identifier: event.target.value })}
              invalid={hasError(errors, 'bzp_identifier')}
              maxLength={255}
            />
            {hasError(errors, 'bzp_identifier') && <FormFeedback>{getError(errors, 'bzp_identifier')}</FormFeedback>}
          </FormGroup>
          <FormGroup className="mb-0">
            {!placeholder && <Label for="input-bzp_date">Data zamieszczenia ogłoszenia o zamówieniu w BZP</Label>}
            <Datetime
              name="bzp_date"
              id="input-bzp_date"
              value={bzp_date ?? ''}
              min=""
              max=""
              onChange={(val) => setValues({ ...values, bzp_date: val })}
              invalid={hasError(errors, 'bzp_date')}
            />
            {hasError(errors, 'bzp_date') && (
              <FormFeedback className="d-block">{getError(errors, 'bzp_date')}</FormFeedback>
            )}
          </FormGroup>
        </>
      )}
    </>
  );
};

export default ProceedingsFieldset;
