import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import { CommonDataItem } from 'modules/ExecutorOrder/model/Order';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';

export interface Props {
  unfairCompetitionItem?: CommonDataItem;
}

const UnfairCompetitionProblem: React.FC<Props> = ({ unfairCompetitionItem = {} }) => {
  const fields = [
    {
      label: 'Przyczyny rozwiązania umowy',
      value: unfairCompetitionItem.violation_description ?? 'nie dotyczy',
      html: true
    },
    {
      label: 'Data rozwiązania umowy',
      value: ifContainsDateFormat(unfairCompetitionItem.violation_date, formatDate) ?? 'nie dotyczy'
    }
  ];

  return <InformationList fields={fields} />;
};

export default UnfairCompetitionProblem;
