import React from 'react';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_SPECIFIC_REQUIREMENTS } from 'modules/Agreements/step';
import { AgreementStepSpecificRequirementsValues } from 'modules/Agreements/components/Steps/Services/SpecificRequirements';
import PreviewFieldContent from 'modules/Agreements/components/PreviewField/Content';

const AgreementStepPreviewSpecificRequirements = (
  props: AgreementStepPreviewProps<AgreementStepSpecificRequirementsValues>
): JSX.Element => {
  const { agreement, steps, modifiedStep } = props;
  const specific_requirements = getStepValues<AgreementStepSpecificRequirementsValues>(
    steps,
    AGREEMENT_STEP_SPECIFIC_REQUIREMENTS
  );

  const { value: parts } = getFieldForPreview<AgreementStepSpecificRequirementsValues['specific_requirements']>(
    specific_requirements,
    modifiedStep,
    'specific_requirements'
  );
  const { value: sameParts } = getFieldForPreview<
    AgreementStepSpecificRequirementsValues['ser_same_specific_requirements']
  >(specific_requirements, modifiedStep, 'ser_same_specific_requirements');

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    specific_requirements,
    modifiedStep,
    `checkboxes.general`
  );

  const isMultiple = agreement.few_parts && !sameParts;

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={specific_requirements}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const renderContent = (contentKey: string, className = 'preview-point') => {
    return (
      <PreviewFieldContent
        step={specific_requirements}
        modifiedStep={modifiedStep}
        contentKey={contentKey}
        className={className}
      />
    );
  };

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_SPECIFIC_REQUIREMENTS)}</div>
      <div className="reset-preview-point">
        {parts?.map((part, index: number) => {
          const { value: specificRequirementsChecked, modified: specificRequirementsCheckedModified } =
            getFieldForPreview(
              specific_requirements,
              modifiedStep,
              `specific_requirements.${index}.specific_requirements_checked`
            );

          const { value: standardsChecked, modified: standardsCheckedModified } = getFieldForPreview(
            specific_requirements,
            modifiedStep,
            `specific_requirements.${index}.standards_checked`
          );

          const { value: needsPossibility, modified: needsPossibilityModified } = getFieldForPreview(
            specific_requirements,
            modifiedStep,
            `specific_requirements.${index}.needs_possibility`
          );

          const partObj = agreement.parts_ids.find((item) => item.id === part.part_id);

          return (
            <div key={index} className="agreement-step-preview-content reset-preview-point">
              {isMultiple && partObj && <div className="h5">{partObj.getName(index)}:</div>}
              {specificRequirementsChecked ? (
                renderContent(`specific_requirements.${index}.specific_requirements_content`)
              ) : (
                <p
                  className={classNames('preview-point wysiwyg-preview', {
                    modified: specificRequirementsCheckedModified
                  })}
                >
                  Zamawiający nie określa szczegółowych wymogów, według których powinien być realizowany przedmiot umowy
                </p>
              )}
              {needsPossibility && [
                <p
                  className={classNames('preview-point wysiwyg-preview', {
                    modified: needsPossibilityModified
                  })}
                >
                  Zamawiający określa poniższe wymagania dla przedmiotu umowy z ustawą z dnia 19 lipca 2019 r. o
                  zapewnianiu dostępności osobom ze szczególnymi potrzebami:
                </p>,
                renderCheckbox(
                  `specific_requirements.${index}.needs_architecture_no_barrier`,
                  'preview-nested-alpha-point'
                ),
                renderCheckbox(
                  `specific_requirements.${index}.needs_architecture_devices`,
                  'preview-nested-alpha-point'
                ),
                renderCheckbox(
                  `specific_requirements.${index}.needs_architecture_information`,
                  'preview-nested-alpha-point'
                ),
                renderCheckbox(
                  `specific_requirements.${index}.needs_architecture_access`,
                  'preview-nested-alpha-point'
                ),
                renderCheckbox(`specific_requirements.${index}.needs_architecture_other`, 'preview-nested-alpha-point'),
                renderCheckbox(
                  `specific_requirements.${index}.needs_accessibility_pl_law`,
                  'preview-nested-alpha-point'
                ),
                renderCheckbox(
                  `specific_requirements.${index}.needs_accessibility_functionality`,
                  'preview-nested-alpha-point'
                ),
                renderCheckbox(
                  `specific_requirements.${index}.needs_accessibility_eu_norm`,
                  'preview-nested-alpha-point'
                ),
                renderCheckbox(
                  `specific_requirements.${index}.needs_communication_support`,
                  'preview-nested-alpha-point'
                ),
                renderCheckbox(
                  `specific_requirements.${index}.needs_communication_devices`,
                  'preview-nested-alpha-point'
                ),
                renderCheckbox(`specific_requirements.${index}.needs_communication_web`, 'preview-nested-alpha-point'),
                renderCheckbox(
                  `specific_requirements.${index}.needs_communication_application`,
                  'preview-nested-alpha-point'
                )
              ]}
              {renderCheckbox(`specific_requirements.${index}.legal_acts`)}
              {standardsChecked ? (
                renderContent(`specific_requirements.${index}.standards_content`)
              ) : (
                <p
                  className={classNames('preview-point wysiwyg-preview', {
                    modified: standardsCheckedModified
                  })}
                >
                  Zamawiający nie określa standardów, według których powinien być realizowany przedmiot umowy
                </p>
              )}
              {generalCheckboxes &&
                generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
                  if (!checkbox.checked) return null;
                  if (checkbox.part_id != part.part_id) return null;
                  return (
                    <p
                      className={classNames('preview-point wysiwyg-preview', {
                        modified: generalCheckboxesModified
                      })}
                      key={index}
                      dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                    />
                  );
                })}
            </div>
          );
        })}
        {isMultiple && generalCheckboxes && (
          <div className="agreement-step-preview-content">
            <p className="h5 reset-preview-point">Ponadto dla każdego z zadań:</p>
            {generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
              if (!checkbox.checked) return null;

              if (checkbox.part_id) return null;

              return (
                <p
                  className={classNames('preview-point wysiwyg-preview', {
                    modified: generalCheckboxesModified
                  })}
                  key={index}
                  dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AgreementStepPreviewSpecificRequirements;
