import Action, { ActionProps } from 'modules/Layout/component/Action/index';
import React from 'react';
import DownloadIcon from 'modules/Layout/component/Icon/Download';
import { ColorVariants } from 'modules/Shared/type';

const ActionDownload: React.FC<ActionProps> = ({
  label = <DownloadIcon height="20px" />,
  color = ColorVariants.Primary,
  ...props
}: ActionProps): JSX.Element => {
  return (
    <Action
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      label={label}
      color={color}
    />
  );
};

export default ActionDownload;
