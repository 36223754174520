import React, { RefAttributes } from 'react';
import classNames from 'classnames';
import ReactSelect, { GroupBase } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import SelectDeclaration from 'react-select/dist/declarations/src/Select';
import { SelectOption } from 'modules/Shared/type';
import './index.scss';
import ExpandIcon from 'modules/Layout/component/Icon/Expand';

export interface Props<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends StateManagerProps<Option, IsMulti, Group>,
    RefAttributes<SelectDeclaration<Option, IsMulti, Group>> {
  required?: boolean;
  invalid?: boolean;
  searchable?: boolean;
}

function findParent(el: any) {
  // eslint-disable-next-line no-empty,no-cond-assign,no-param-reassign
  while ((el = el.parentNode) && el.className.indexOf('pzpue-select-container') < 0) {}
  return el;
}

function Select<
  Option = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>): JSX.Element {
  const { className, isDisabled, value, invalid, required = true, searchable } = props;

  const DropdownIndicator = () => (
    <span className="pzpue-select__indicator-dropdown">
      <ExpandIcon height="15" fill="#666666" />
    </span>
  );

  const hasValue = Boolean((value as unknown as SelectOption)?.value);

  return (
    <>
      <ReactSelect
        {...props}
        onFocus={(event) => {
          const { target } = event;

          const parent = findParent(target);

          parent.classList.remove('is-invalid');
        }}
        components={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          DropdownIndicator,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          IndicatorSeparator: null
        }}
        isSearchable={searchable}
        className={classNames({ 'is-invalid': invalid }, `${className} pzpue-select-container`)}
        classNamePrefix="pzpue-select"
      />
      {!isDisabled && (
        <input
          key={hasValue ? 'filled' : ''}
          defaultValue={hasValue ? 'filled' : ''}
          className="sr-only"
          autoComplete="off"
          required={required}
        />
      )}
    </>
  );
}

export default Select;
