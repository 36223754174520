import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterInputType } from 'modules/Layout/type';
import { setJudgementListParamsAction } from 'modules/Judgement/action/JudgementList';
import JudgmentFilterCategoryAutocomplete from 'modules/Judgement/container/JudgementList/Filter/customFields/FilterCategoriesAutocomplete';
import JudgmentFilterKeywordsAutocomplete from 'modules/Judgement/container/JudgementList/Filter/customFields/FilterKeywordsAutocomplete';

export interface Props {
  values?: SearchParams;
}

const ConnectedJudgementAddListFilter: React.FC<Props> = ({ values = {} }) => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.judgement.judgementList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setJudgementListParamsAction(payload));

  const staticInputs: FilterInputType[] = [
    {
      type: 'number',
      property: 'id',
      label: 'ID',
      value: _get(values, 'id')
    },
    {
      type: 'text',
      property: 'identifier',
      label: 'Identyfikator publikacji',
      value: _get(values, 'identifier')
    }
  ];

  return (
    <ListingFilter
      inputs={staticInputs}
      filter={filter}
      pagination={pagination}
      setParams={setParams}
      customInput={
        <>
          <div className="w-100 mt-1" />
          <JudgmentFilterCategoryAutocomplete />
          <JudgmentFilterKeywordsAutocomplete />
        </>
      }
    />
  );
};

export default ConnectedJudgementAddListFilter;
