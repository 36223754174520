import React, { useState } from 'react';
import CardBox from 'modules/Layout/component/CardBox';
import { ROUTE_PROFILE } from 'modules/Profile/routes';
import FieldsetRodo from 'modules/Regulation/component/Fieldset/Rodo';
import { initRodoValues } from 'modules/Regulation/component/Fieldset/Rodo/rodoContent';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import User, { UserEntity } from 'modules/User/model/User';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import Loader from 'modules/Layout/component/Loader';
import { RodoValues } from 'modules/Regulation/type';
import { AxiosPromise } from 'axios';

type Props = {
  user: User;
  updateRodoRequest?: (data: RodoValues, cancelToken?: any) => AxiosPromise<{ data: UserEntity }>;
  onSuccessRodoUpdate?: (user?: UserEntity) => void;
  aditionalArgs?: any[];
};

const EditRODO: React.FC<Props> = ({ user, updateRodoRequest, onSuccessRodoUpdate, aditionalArgs }) => {
  const [rodoVal, setRodoVal] = useState(initRodoValues(user));
  const [message, setMessage] = useState<Message>(null);
  const [loading, setLoading] = useState(false);

  const cancelToken = useCancelToken();
  const updateProfileRodoAction = async () => {
    if (loading) return;

    if (message) setMessage(null);
    setLoading(true);

    const requestArgs = aditionalArgs ? [...aditionalArgs, rodoVal, cancelToken] : [rodoVal, cancelToken];
    const { cancelled, message: messageRes, data } = await updateRequestWithData(updateRodoRequest, ...requestArgs);

    if (cancelled) return;
    if (data?.data) {
      onSuccessRodoUpdate(data?.data);
    }
    if (messageRes) {
      setMessage(messageRes);
      setLoading(false);
    }
  };

  return (
    <div className="profile-update-view d-flex justify-content-center">
      <CardBox heading="RODO" form="small" message={message}>
        {loading && <Loader />}
        <FieldsetRodo values={rodoVal} setChange={setRodoVal} />
        <div className="form-actions">
          <Button
            color="primary"
            className="pzpeu-btn-disabled waves-effect waves-light"
            onClick={updateProfileRodoAction}
          >
            Zapisz
          </Button>
          <Link to={ROUTE_PROFILE} className="cancel waves-effect waves-light">
            Anuluj
          </Link>
        </div>
      </CardBox>
    </div>
  );
};

export default EditRODO;
