import { addToastAction, managePageAction } from 'modules/Layout/action';
import Form from 'modules/Clause/component/Form';
import React, { useEffect, useState, useRef } from 'react';
import Loader from 'modules/Layout/component/Loader';
import { useDispatch } from 'react-redux';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import { breadcrumbRouteClauseUpdate } from 'modules/Clause/breadcrumbs';
import { updateClauseToastSuccess } from 'modules/Clause/toasts';
import { ClauseFieldValues } from 'modules/Clause/type';
import { fetchClause, updateClause } from 'modules/Clause/repository';
import { ROUTE_CLAUSE } from 'modules/Clause/routes';
import { useParams } from 'react-router-dom';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { createClause, ClauseEntity } from 'modules/Clause/model/Clause';

const ClauseUpdateView = () => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const { id } = useParams<{ id: string }>();
  const [clause, setClause] = useState<ClauseEntity>(null);
  const [errors, setErrors] = useState(null);
  const scrollToTopOnce = useRef<boolean>(true);

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const { data, cancelled, message: messageResponse } = await fetchRequest(fetchClause, id, cancelToken);

      if (cancelled) return;
      if (data) setClause(createClause(data.data));
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clause)
      dispatch(
        managePageAction({
          title: `Edycja klauzuli o nazwie: ${clause.name}`,
          breadcrumb: breadcrumbRouteClauseUpdate(clause.name, clause.id)
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clause]);

  const dispatchUpdateClauseToast = () => dispatch(addToastAction(updateClauseToastSuccess()));
  const setUpdateClauseAction = async (data: ClauseFieldValues) => {
    setFetching(true);

    if (message) setMessage(null);
    if (errors) setErrors(null);

    const {
      message: messageResponse,
      error,
      cancelled,
      data: responseData
    } = await updateRequestWithData(updateClause, id, data, cancelToken);

    if (cancelled) return;

    if (responseData) {
      dispatchUpdateClauseToast();
      dispatch(push(getPathUrl(ROUTE_CLAUSE, { id: responseData.data.id })));
      // else dispatch(push(ROUTE_OFFICES));
    } else {
      if (error) setErrors(error);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <div className="user-create-view row justify-content-center">
      {fetching && <Loader />}
      <div className="col-lg-7 col-xl-5">
        <div className="card-box position-relative">
          <Alert message={message} />
          {clause && (
            <>
              <p className="h4 mb-2">Edycja klauzuli</p>
              <Form clause={clause} errors={errors} submit={(data) => setUpdateClauseAction(data)} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClauseUpdateView;

/*


import { addToastAction, managePageAction } from 'modules/Layout/action';
import Loader from 'modules/Layout/component/Loader';
import { breadcrumbRouteUserBasicUpdate } from 'modules/User/breadcrumbs';
import Form from 'modules/Profile/component/Form';
import User, { createUser } from 'modules/User/model/User';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Alert from 'modules/Layout/component/Alert';
import { Message, ValidationErrors } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { ProfileValues } from 'modules/Profile/type';
import { updateUserToastSuccess } from 'modules/User/toasts';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_USER } from 'modules/User/routes';
import { ROLE_ADMIN } from 'modules/User/model/UserRole';
import { fetchUser, updateUserBase } from 'modules/User/repository';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import updateRequest from 'modules/Shared/helper/APIRequests/updateRequest';

export interface Props {
  params?: any;
}

const UserBasicUpdateView: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<{ user: User }>();
  const [user, setUser] = useState<User>(null);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  const scrollToTopOnce = useRef<boolean>(true);

  const dispatchDeleteUserToast = () => dispatch(addToastAction(updateUserToastSuccess()));

  const cancelToken = useCancelToken();
  useEffect(() => {
    if (state?.user) {
      setUser(createUser(state.user));
    } else {
      (async () => {
        setFetching(true);
        const { data: reqData, cancelled, message: messageResponse } = await fetchRequest(fetchUser, id, cancelToken);

        if (cancelled) return;
        const { data } = reqData;
        if (data) setUser(createUser(data));
        if (messageResponse) setMessage(messageResponse);
        setFetching(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      const roleAdmin = user?.role?.slug === ROLE_ADMIN;
      const title = `Edycja podstawowych danych${user.getName() ? ` - ${user.getName()}` : ''}`;
      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteUserBasicUpdate(parseInt(id, 10) || 0, roleAdmin, user.getName())
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const updateUserAction = async (profile: ProfileValues) => {
    if (loading) return;

    if (errors) setErrors(null);
    setLoading(true);
    const { cancelled, message: messageRes, success, error } = await updateRequest(
      updateUserBase,
      id,
      profile,
      cancelToken
    );

    if (cancelled) return;
    if (success) {
      dispatchDeleteUserToast();
      dispatch(push(getPathUrl(ROUTE_USER, { id })));
    } else {
      if (messageRes) setMessage(messageRes);
      if (error) setErrors(error);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  if (fetching) return <Loader />;
  if (!fetching && !user && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  if (!fetching && user) {
    return (
      <div className="profile-update-view row justify-content-center">
        <div className="col-lg-6 col-xl-5">
          <div className="card-box position-relative">
            <h3 className="header-title mb-2">Podstawowe dane użytkownika</h3>
            {loading && <Loader />}
            <Alert message={message} />
            {user && (
              <Form
                profile={user?.getIdentity()}
                errors={errors}
                submit={updateUserAction}
                disabled={loading}
                cancelRedirect={getPathUrl(ROUTE_USER, { id })}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default UserBasicUpdateView;


*/
