import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import { BigRiggingItem, OrderEntity } from 'modules/ExecutorOrder/model/Order';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';

export interface Props {
  bidRiggingItem?: BigRiggingItem;
  order?: OrderEntity;
}

const ExecutorDataClientBidRigginDataset: React.FC<Props> = ({ bidRiggingItem = {}, order = {} }) => {
  const fields = [
    {
      label: 'Podmiot udzielający informacji publicznej (zamawiający)',
      value: <strong>{order.principal_name || 'nie dotyczy'}</strong>
    },
    {
      label: 'Nr umowy o udzielenie zamówienia',
      value: order.contract_number || 'nie dotyczy'
    },
    {
      label: 'Nazwa oraz numer postępowania',
      value:
        order.name || order.identifier
          ? `${order.name || '-'}; numer postępowania: ${order.identifier || '-'}`
          : 'nie dotyczy'
    },
    {
      label: 'Data rozwiązania umów',
      value: ifContainsDateFormat(bidRiggingItem.violation_date, formatDate) || 'nie dotyczy'
    },
    {
      label: 'Przyczyny rozwiązania umów',
      value: bidRiggingItem.violation_description || 'nie dotyczy',
      html: true
    }
  ];

  return <InformationList fields={fields} />;
};

export default ExecutorDataClientBidRigginDataset;

// import React from 'react';
// import InformationList from 'modules/Layout/component/InformationList';
// import { OrderEntity } from 'modules/ExecutorOrder/model/Order';

// export interface Props {
//   terminations?: OrderEntity[];
// }

// const ExecutorDataClientTerminationDataset: React.FC<Props> = ({ terminations = [] }) => {
//   const fields = (terminationValue: OrderEntity) => [
//     {
//       label: 'Podmiot udzielający informacji publicznej (zamawiający):',
//       value: terminationValue.principal_name || 'nie dotyczy'
//     },
//     {
//       label: 'Nr umowy o udzielenie zamówienia:',
//       value: terminationValue.contract_number || 'nie dotyczy'
//     },
//     {
//       label: 'Nazwa oraz numer postępowania:',
//       value: terminationValue.identifier || 'nie dotyczy'
//     },
//     {
//       label: 'Data rozwiązania umów:',
//       value: terminationValue.termination_date || 'nie dotyczy'
//     },
//     {
//       label: 'Przyczyny rozwiązania umów:',
//       value: terminationValue.termination_description || 'nie dotyczy',
//       html: true
//     }
//   ];

//   if (!terminations.length) return <p>Nie dotyczy</p>;

//   return (
//     <>
//       {terminations.map((termination) => (
//         <div className="border-except-last" key={termination.identifier}>
//           <div className="h4 mb-2">Nazwa postępowania - {termination.name}</div>
//           <InformationList fields={fields(termination)} />
//         </div>
//       ))}
//     </>
//   );
// };

// export default ExecutorDataClientTerminationDataset;
