import { PaginationMeta, SearchingProps } from 'modules/Shared/type';
import ReportClaimClient from 'modules/Executor/model/ReportClaim';

export interface ReportClaimsListState extends SearchingProps {
  reportClaims: ReportClaimClient[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initReportClaimsListState = (): ReportClaimsListState => ({
  reportClaims: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
