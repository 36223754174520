import { ModuleEntity } from 'modules/Module/model/Module';

export const STATUS_DELETED = 0;
export const STATUS_ACTIVE = 1;
export const STATUS_NOT_VERIFIED = 2;

export type SubscriptionEntity = {
  id?: number;
  name?: string;
  slug?: string;
  value?: number;
  tax_value?: number;
  tax_percentage?: number;
  net_value?: number;
  days?: number;
  credits?: number;
  modules?: ModuleEntity[];
};

export default class Subscription {
  readonly id?: number;
  readonly name?: string;
  readonly slug?: string;
  readonly value?: number;
  readonly tax_value?: number;
  readonly tax_percentage?: number;
  readonly net_value?: number;
  readonly days?: number;
  readonly credits?: number;
  readonly modules?: ModuleEntity[];

  constructor(entity: SubscriptionEntity) {
    this.id = entity.id;
    this.slug = entity.slug;
    this.name = entity.name;
    this.value = entity.value;
    this.tax_value = entity.tax_value;
    this.tax_percentage = entity.tax_percentage;
    this.net_value = entity.net_value;
    this.days = entity.days;
    this.credits = entity.credits;
    this.modules = entity.modules;
  }
}

export const createSubscription = (entity: SubscriptionEntity): Subscription => new Subscription(entity);
