import React from 'react';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_PERFORMANCE_SECURITY } from 'modules/Agreements/step';
import {
  AgreementStepPerformanceSecurityValues,
  CLAIMS_CONTENT,
  CLAIMS_PART_CONTENT,
  REFUND_DATE_CONTENT
} from 'modules/Agreements/components/Steps/Default/PerformanceSecurity';
import PreviewFieldCheckboxWithNumberInput from 'modules/Agreements/components/PreviewField/CheckboxWithNumberInput';

const AgreementStepPreviewPerformanceSecurity = (
  props: AgreementStepPreviewProps<AgreementStepPerformanceSecurityValues>
): JSX.Element => {
  const { agreement, steps, modifiedStep } = props;
  const performanceSecurity = getStepValues<AgreementStepPerformanceSecurityValues>(
    steps,
    AGREEMENT_STEP_PERFORMANCE_SECURITY
  );

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={performanceSecurity}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const renderCheckboxWithNumberInput = (checkbox: string, label: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckboxWithNumberInput
        step={performanceSecurity}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        label={label}
        className={className}
      />
    );
  };

  const renderParts = () => {
    return performanceSecurity.parts.map(({ id, value }, index) => {
      const modifiedValue: boolean = modifiedStep?.parts.find((item) => item.id === id).value;
      const modifiedStatus = modifiedValue ? value !== modifiedValue : false;

      const { parts_ids, few_parts } = agreement;
      const partObj = parts_ids.find((item) => item.id === id);

      return (
        <p className={classNames('', { modified: modifiedStatus })} key={`part-preview-${id}-${index}`}>
          - {modifiedValue || value}% ceny całkowitej podanej w ofercie albo maksymalnej wartości nominalnej
          zobowiązania zamawiającego wynikającego z Umowy
          {few_parts ? ` w zakresie ${partObj.getName(index, 'zadania')}` : ''}
        </p>
      );
    });
  };

  const renderClaimsText = () => {
    const { value: claimsChecked, modified: claimsCheckedModified } = getFieldForPreview(
      performanceSecurity,
      modifiedStep,
      'claims_checked'
    );
    const { value: claimsContent, modified: claimsContentModified } = getFieldForPreview(
      performanceSecurity,
      modifiedStep,
      'claims_content'
    );

    const { value: claimsPartChecked, modified: claimsPartCheckedModified } = getFieldForPreview(
      performanceSecurity,
      modifiedStep,
      'claims_part_checked'
    );
    const { value: claimsPartContent, modified: claimsPartContentModified } = getFieldForPreview(
      performanceSecurity,
      modifiedStep,
      'claims_part_content'
    );

    if (!claimsChecked && !claimsPartChecked) return null;

    const string = `${CLAIMS_PART_CONTENT.replace('...', claimsPartContent || '...')} ${CLAIMS_CONTENT.replace(
      '...',
      claimsContent || '...'
    )}`;

    return (
      <p
        key="claims_checked-claims_content"
        className={classNames('preview-point wysiwyg-preview', {
          modified:
            claimsCheckedModified || claimsContentModified || claimsPartCheckedModified || claimsPartContentModified
        })}
        dangerouslySetInnerHTML={{
          __html: String(string)
        }}
      />
    );
  };

  const { value: safeguardPossibility, modified: safeguardPossibilityModified } = getFieldForPreview(
    performanceSecurity,
    modifiedStep,
    'safeguard_possibility'
  );

  const { value: partialRefundChecked, modified: partialRefundCheckedModified } = getFieldForPreview(
    performanceSecurity,
    modifiedStep,
    'partial_refund_checked'
  );

  const { value: safeguardWaysPossibility, modified: safeguardWaysPossibilityModified } = getFieldForPreview(
    performanceSecurity,
    modifiedStep,
    'safeguard_ways_possibility'
  );

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    performanceSecurity,
    modifiedStep,
    `checkboxes.general`
  );

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_PERFORMANCE_SECURITY)}</div>
      <div className="agreement-step-preview-content reset-preview-point">
        {!safeguardPossibility ? (
          <p
            className={classNames('preview-point', {
              modified: safeguardPossibilityModified
            })}
          >
            Zamawiający nie przewiduje wniesienia zabezpieczenie należytego wykonania umowy.
          </p>
        ) : (
          [
            <p
              className={classNames('preview-point', {
                modified: safeguardPossibilityModified
              })}
            >
              Ustala się Zabezpieczenie należytego wykonania Umowy w wysokości:
            </p>,
            renderParts(),
            partialRefundChecked ? (
              renderCheckbox('partial_refund')
            ) : (
              <p
                className={classNames('preview-point', {
                  modified: partialRefundCheckedModified
                })}
              >
                Zamawiający nie dopuszcza dokonania częściowego zwrotu zabezpieczenia po wykonaniu części zamówienia.
              </p>
            ),
            safeguardWaysPossibility && [
              <p
                className={classNames('preview-point', {
                  modified: safeguardWaysPossibilityModified
                })}
              >
                Zabezpieczenie może być wnoszone, według wyboru wykonawcy, w jednej lub w kilku następujących formach:
              </p>,
              renderCheckbox('money', 'preview-nested-decimal-point'),
              renderCheckbox('bank_surety', 'preview-nested-decimal-point'),
              renderCheckbox('bank_guarantee', 'preview-nested-decimal-point'),
              renderCheckbox('insurance_guarantee', 'preview-nested-decimal-point'),
              renderCheckbox('parp_surety', 'preview-nested-decimal-point'),
              renderCheckbox('exchange_bills', 'preview-nested-decimal-point'),
              renderCheckbox('securities', 'preview-nested-decimal-point'),
              renderCheckbox('register_pledge', 'preview-nested-decimal-point'),
              renderCheckbox('others', 'preview-nested-decimal-point')
            ],
            renderCheckbox('bank_account'),
            renderCheckbox('over_year'),
            renderCheckboxWithNumberInput('refund_date', REFUND_DATE_CONTENT),
            renderClaimsText(),
            renderCheckbox('postponement'),
            renderCheckbox('request'),
            generalCheckboxes &&
              generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
                if (!checkbox.checked) return null;

                return (
                  <p
                    className={classNames('preview-point wysiwyg-preview', {
                      modified: generalCheckboxesModified
                    })}
                    key={index}
                    dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                  />
                );
              })
          ]
        )}
      </div>
    </div>
  );
};

export default AgreementStepPreviewPerformanceSecurity;
