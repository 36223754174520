import React from 'react';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_DISPUTES } from 'modules/Agreements/step';
import { AgreementStepDisputesValues } from 'modules/Agreements/components/Steps/Default/Disputes';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';

const AgreementStepPreviewDisputes = (props: AgreementStepPreviewProps<AgreementStepDisputesValues>): JSX.Element => {
  const { steps, modifiedStep } = props;
  const disputes = getStepValues<AgreementStepDisputesValues>(steps, AGREEMENT_STEP_DISPUTES);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={disputes}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const { value: agreementPossibility, modified: agreementPossibilityModified } = getFieldForPreview(
    disputes,
    modifiedStep,
    `agreement_possibility`
  );

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    disputes,
    modifiedStep,
    `checkboxes.general`
  );

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_DISPUTES)}</div>
      <div className="agreement-step-preview-content reset-preview-point">
        {renderCheckbox('realization')}
        {agreementPossibility && [
          <p
            className={classNames('preview-point', {
              modified: agreementPossibilityModified
            })}
          >
            W przypadku zaistnienia pomiędzy stronami sporu wynikającego z Umowy lub pozostającego w związku z Umową,
            strony zobowiązują się do jego rozwiązania w drodze:
          </p>,
          renderCheckbox('agreement_mediation', 'preview-nested-alpha-point'),
          renderCheckbox('agreement_others', 'preview-nested-alpha-point')
        ]}
        {renderCheckbox('no_deal')}
        {renderCheckbox('arbitrary')}
        {generalCheckboxes &&
          generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-point wysiwyg-preview', {
                  modified: generalCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AgreementStepPreviewDisputes;
