import React, { useEffect, useRef, useState } from 'react';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_PERSONAL_DATA_PROTECTION } from 'modules/Agreements/step';
import { FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import useStepFields from 'modules/Agreements/hook/useStepFields';

export interface AgreementStepPersonalDataProtectionValues {
  rodo_technical_measures_checked: boolean;
  rodo_technical_measures_content: string | null;
  rodo_agreement_checked: boolean;
  rodo_agreement_content: string | null;
  checkboxes: AgreementCheckboxes | null;
}

const RODO_TECHNICAL_MEASURES_CONTENT =
  'Wykonawca oświadcza, że wdraża i stosuje adekwatne środki techniczne i organizacyjne, w celu zapewnienia stopnia bezpieczeństwa odpowiedniego do ryzyka naruszenia praw lub wolności osób fizycznych, których dane osobowe są przetwarzane na podstawie Umowy, w tym zapewniające możliwość ciągłego zapewnienia poufności, integralności, dostępności i odporności systemów służących do przetwarzania danych osobowych oraz usług przetwarzania oraz zapewniające możliwość szybkiego przywrócenia dostępności danych osobowych i dostępu do nich w razie incydentu fizycznego lub technicznego. Naruszenie tak określonego obowiązku przez wykonawcę uprawniać będzie zamawiającego do odstąpienia od Umowy z przyczyn leżących po stronie wykonawcy.';
const RODO_AGREEMENT_CONTENT =
  'W przypadku, gdy w trakcie realizacji Umowy konieczne będzie powierzenie wykonawcy przez zamawiającego przetwarzania danych osobowych, strony zobowiązują się zawrzeć umowę  o powierzeniu przetwarzania danych osobowych, zgodnie z wymogami artykułu 28 RODO (rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE - ogólne rozporządzenie o ochronie danych (Dz. Urz. UE L 119 z 04.05.2016, str. 1). Uchylanie się wykonawcy od zawarcia tej umowy, uznaje się za przerwę w realizacji Umowy leżącą po stronie wykonawcy.';

const mapState = (step: AgreementStepPersonalDataProtectionValues): AgreementStepPersonalDataProtectionValues => {
  const { rodo_technical_measures_content, rodo_agreement_content, checkboxes, ...rest } = step;

  return {
    ...rest,
    rodo_technical_measures_content: rodo_technical_measures_content || RODO_TECHNICAL_MEASURES_CONTENT,
    rodo_agreement_content: rodo_agreement_content || RODO_AGREEMENT_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepPersonalDataProtection = (props: AgreementStepProps): JSX.Element => {
  const { steps, onSubmit, onChange, errors } = props;

  const initState = useRef<AgreementStepPersonalDataProtectionValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_PERSONAL_DATA_PROTECTION))
  );

  const [stepValues, setStepValues] = useState<AgreementStepPersonalDataProtectionValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_PERSONAL_DATA_PROTECTION));
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderCheckbox, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        {renderCheckbox('rodo_technical_measures_checked', stepValues.rodo_technical_measures_content)}
        {renderCheckbox('rodo_agreement_checked', stepValues.rodo_agreement_content)}
        {renderCustomCheckboxes()}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepPersonalDataProtection;
