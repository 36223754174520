import { LocalPagination } from 'modules/Layout/component/Pagination/WithProps/LocalPaginationTable';
import React, { useEffect, useState } from 'react';

type Array = { [k: string]: any }[];

const useLocalPagination = (array: Array) => {
  const [currentPage, setCurrentPage] = useState(1);

  const lastPage = Math.ceil(array.length / 10) || 1;

  useEffect(() => {
    if (lastPage !== 0 && currentPage > lastPage) {
      setCurrentPage(lastPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [array]);

  const paginationComponent = () => (
    <>
      <LocalPagination currentPage={currentPage} setCurrentPage={setCurrentPage} lastPage={lastPage} />
    </>
  );

  const beginning = (currentPage - 1) * 10;
  const end = currentPage * 10;

  const selectedItemsFromArray = array.slice(beginning, end);

  return {
    paginationComponent,
    selectedItemsFromArray
  };
};

export default useLocalPagination;
