import React from 'react';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_FINAL_PROVISIONS } from 'modules/Agreements/step';
import { AgreementStepFinalProvisionsValues } from 'modules/Agreements/components/Steps/Default/FinalProvisions';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';

const AgreementStepPreviewFinalProvisions = (
  props: AgreementStepPreviewProps<AgreementStepFinalProvisionsValues>
): JSX.Element => {
  const { steps, modifiedStep } = props;
  const finalProvisions = getStepValues<AgreementStepFinalProvisionsValues>(steps, AGREEMENT_STEP_FINAL_PROVISIONS);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={finalProvisions}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const { value: casePossibility, modified: casePossibilityModified } = getFieldForPreview(
    finalProvisions,
    modifiedStep,
    `case_possibility`
  );
  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    finalProvisions,
    modifiedStep,
    `checkboxes.general`
  );

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_FINAL_PROVISIONS)}</div>
      <div className="agreement-step-preview-content reset-preview-point">
        {casePossibility && [
          <p
            className={classNames('preview-point', {
              modified: casePossibilityModified
            })}
          >
            W sprawach, których nie reguluje Umowa, będą miały zastosowanie odpowiednie przepisy:
          </p>,
          renderCheckbox('case_order_law', 'preview-nested-alpha-point'),
          renderCheckbox('case_build_law', 'preview-nested-alpha-point'),
          renderCheckbox('case_civil_code', 'preview-nested-alpha-point'),
          renderCheckbox('case_other', 'preview-nested-alpha-point')
        ]}
        {renderCheckbox('invalid_provisions')}
        {renderCheckbox('replaced_provisions')}
        {renderCheckbox('annexes')}
        {renderCheckbox('applicable_law')}
        {renderCheckbox('jurisdiction')}
        {renderCheckbox('any_changes')}
        {renderCheckbox('identical_copies')}
        {generalCheckboxes &&
          generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-point wysiwyg-preview', {
                  modified: generalCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AgreementStepPreviewFinalProvisions;
