import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function ProfileIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 21 15.216">
      <g id="Group_316" data-name="Group 316" transform="translate(0.5 0.5)">
        <path
          id="Path_51"
          data-name="Path 51"
          d="M1500.486,353.871H1488a1,1,0,0,1-.976-1.011v-1.8a1.006,1.006,0,0,1,.616-.94c3.648-1.492,4.33-2.134,4.457-2.315v-.42a5.156,5.156,0,0,1-.953-1.863,1.712,1.712,0,0,1-.271-2.122V341.95a3.063,3.063,0,0,1,3.371-3.3,3.364,3.364,0,0,1,2.546.931,3.3,3.3,0,0,1,.824,2.364V343.4a1.71,1.71,0,0,1-.271,2.122,5.151,5.151,0,0,1-.953,1.863v.42c.127.181.809.824,4.457,2.314a1,1,0,0,1,.617.94v1.8A1,1,0,0,1,1500.486,353.871Zm-12.462-1h12.436a.047.047,0,0,0,0-.011v-1.8a.043.043,0,0,0,0-.018c-4.166-1.7-4.889-2.441-5.049-2.985a.5.5,0,0,1-.021-.142V347.2a.5.5,0,0,1,.133-.339,4.109,4.109,0,0,0,.9-1.745.5.5,0,0,1,.185-.274.669.669,0,0,0,.254-.446.709.709,0,0,0-.14-.534.5.5,0,0,1-.1-.3V341.95a2.372,2.372,0,0,0-4.741,0v1.608a.5.5,0,0,1-.1.3.71.71,0,0,0-.14.535.669.669,0,0,0,.254.446.5.5,0,0,1,.185.274,4.111,4.111,0,0,0,.9,1.745.5.5,0,0,1,.133.339v.718a.5.5,0,0,1-.02.141c-.16.543-.883,1.28-5.049,2.986a.039.039,0,0,0,0,.018v1.8A.047.047,0,0,0,1488.024,352.871Z"
          transform="translate(-1487.523 -339.155)"
          fill={fill}
        />
        <path
          id="Path_52"
          data-name="Path 52"
          d="M1509.364,353.953h-4.1a.5.5,0,0,1,0-1h4.1V351.4a18.622,18.622,0,0,0-2.928-.687c-1.359-.238-2.179-.382-2.391-1a.5.5,0,0,1-.027-.162v-.934a.5.5,0,0,1,.5-.5,7.251,7.251,0,0,0,2.353-.338,6.213,6.213,0,0,1-.644-1.881,17.23,17.23,0,0,1-.278-1.838c0-.014,0-.029,0-.044a3,3,0,0,0-1.734-2.686,3.1,3.1,0,0,0-3.259.216.5.5,0,0,1-.6-.8,4.094,4.094,0,0,1,4.3-.306,4.03,4.03,0,0,1,2.284,3.556,16.614,16.614,0,0,0,.26,1.707c.267,1.263.579,1.724.72,1.8a.5.5,0,0,1,.068.846,5.593,5.593,0,0,1-2.978.763v.258a8.725,8.725,0,0,0,1.59.364,19.366,19.366,0,0,1,3.1.732.969.969,0,0,1,.656.905v1.629A.984.984,0,0,1,1509.364,353.953Z"
          transform="translate(-1489.865 -339.237)"
          fill={fill}
        />
      </g>
    </svg>
  );
}
