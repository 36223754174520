import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import Order from 'modules/ExecutorOrder/model/Order';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';
// import { ROLE_ADMIN } from 'modules/User/model/UserRole';

export interface Props {
  order: Order;
}

const BasicOrderDataset: React.FC<Props> = ({ order }) => {
  const fields = [
    {
      label: 'Nazwa postępowania',
      value: order?.name
    },
    {
      label: 'Numer postępowania',
      value: order?.identifier
    },
    {
      label: 'Nazwa zleceniodawcy',
      value: order?.principal_name
    },
    {
      label: 'Adres zleceniodawcy',
      value: order?.principal_address
    },
    {
      label: 'Numer umowy o udzielenie zamówienia',
      value: order?.contract_number
    },
    {
      label: 'Miejsce realizacji umowy',
      value: order?.contract_place
    },
    {
      label: 'Wartość zawartej umowy',
      value: order?.contract_value ? `${order.contract_value.toFixed(2).replace('.', ',')} PLN` : '-'
    },
    {
      label: 'Data zawarcia umowy',
      value: ifContainsDateFormat(order?.contract_date, formatDate)
    },
    {
      label: 'Numer ogłoszenia w Biuletynie Zamówień Publicznych',
      value: order?.notice_number
    }
  ];

  return <InformationList fields={fields} />;
};

export default BasicOrderDataset;
