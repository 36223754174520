import React from 'react';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_EXECUTOR_DUTIES } from 'modules/Agreements/step';
import { AgreementStepExecutorDutiesValues } from 'modules/Agreements/components/Steps/Default/ExecutorDuties';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';

const AgreementStepPreviewExecutorDuties = (
  props: AgreementStepPreviewProps<AgreementStepExecutorDutiesValues>
): JSX.Element => {
  const { steps, modifiedStep } = props;
  const executorDuties = getStepValues<AgreementStepExecutorDutiesValues>(steps, AGREEMENT_STEP_EXECUTOR_DUTIES);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={executorDuties}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    executorDuties,
    modifiedStep,
    `checkboxes.general`
  );

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_EXECUTOR_DUTIES)}</div>
      <div className="agreement-step-preview-content reset-preview-point reset-nested-preview-point">
        {renderCheckbox('del_all_costs')}
        {renderCheckbox('del_own_transport')}
        {renderCheckbox('del_risk')}
        {renderCheckbox('del_documents')}
        {renderCheckbox('del_documents_on_demand')}
        {renderCheckbox('del_quality')}
        {renderCheckbox('schedule')}
        {renderCheckbox('shortcomings')}
        {renderCheckbox('no_realization')}
        {generalCheckboxes &&
          generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-point wysiwyg-preview', {
                  modified: generalCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AgreementStepPreviewExecutorDuties;
