import React from 'react';
import ListingPagination from 'modules/Layout/component/Listing/Pagination';
import { PaginationMeta, PaginationParams, PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import { createSearchParams } from 'modules/Shared/helper/params';

type Props = { meta?: PaginationMeta; fetchList?: (payload?: SearchParams) => Promise<void> };

const PaginationTable: React.FC<Props> = (props) => {
  const { meta, fetchList } = props;

  const pagination: PaginationParams = { per_page: meta?.per_page, page: meta?.current_page };

  const setParams = (payload?: PartialSearchingProps) => fetchList(createSearchParams(payload));

  return <ListingPagination meta={meta} pagination={pagination} setParams={setParams} {...props} />;
};

export default PaginationTable;
