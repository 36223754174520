import React from 'react';
import { RootState } from 'app/reducer';
import ListingPagination from 'modules/Layout/component/Listing/Pagination';
import { PartialSearchingProps } from 'modules/Shared/type';
import { useDispatch, useSelector } from 'react-redux';
import { setForeignProceedingsListParamsAction } from '../../../action/ForeignProceedingsList';

type Props = { path?: string; isAdmin?: boolean };

const ForeignProceedingsListingPagination: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { meta, pagination, filter, sort } = useSelector(
    (state: RootState) => state.foreignProceedings.foreignProceedingsList
  );
  const { isAdmin } = props;
  const setParams = (payload: PartialSearchingProps) =>
    dispatch(setForeignProceedingsListParamsAction(payload, isAdmin));

  return (
    <ListingPagination
      meta={meta}
      pagination={pagination}
      sort={sort}
      setParams={setParams}
      filter={filter}
      {...props}
    />
  );
};

export default ForeignProceedingsListingPagination;
