import React, { useEffect, useState } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { createSearchingProps } from 'modules/Shared/helper/params';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { Message, PartialSearchingProps, ValidationErrors } from 'modules/Shared/type';
import { setJudgementCategoryListParamsAction } from 'modules/Judgement/action/CategoryList';
import { breadcrumbRouteJudgementsCategory } from 'modules/Judgement/breadcrumbs';
import JudgementElementTable from 'modules/Judgement/container/JudgementElementList/Table';
import JudgementElementPerPage from 'modules/Judgement/container/JudgementElementList/PerPage';
import JudgementElementPagination from 'modules/Judgement/container/JudgementElementList/Pagination';
import { ROUTE_JUDGEMENT_CATEGORIES, ROUTE_JUDGEMENT_CATEGORY } from 'modules/Judgement/routes';
import JudgementElementFilter from 'modules/Judgement/container/JudgementElementList/Filter';
import { Button } from 'reactstrap';
import ManageElementModal from 'modules/Judgement/component/Modal/JudgementElement';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import {
  createJudgmentsCategory,
  deleteJudgmentsCategory,
  updateJudgmentsCategory
} from 'modules/Judgement/repository';
import {
  createJudgementCategoryToastSuccess,
  deleteJudgementCategoryToastSuccess,
  updateJudgementCategoryToastSuccess
} from 'modules/Judgement/toasts';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import useCategoryUpdateModal from 'modules/Judgement/component/Modal/JudgementElement/useCategoryUpdateModal';
import useCategoryDeleteModal from 'modules/Judgement/component/Modal/JudgementElement/useCategoryDeleteModal';
import Authorize from 'modules/Auth/container/Authorize';
import {
  JUDGMENT_CATEGORIES_ADD,
  JUDGMENT_CATEGORIES_DELETE,
  JUDGMENT_CATEGORIES_DETAILS,
  JUDGMENT_CATEGORIES_EDIT
} from 'modules/Judgement/permissions';
import { Link } from 'react-router-dom';
import { TableCol } from 'modules/Layout/component/Table';
import JudgementElement, { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import EditIcon from 'modules/Layout/component/Icon/Edit';

const JudgementCategories = () => {
  const dispatch = useDispatch();
  const { fetching, list, sort, filter, pagination, meta } = useSelector(
    (state: RootState) => state.judgement.categoryList
  );
  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const setParams = (payload: PartialSearchingProps) => dispatch(setJudgementCategoryListParamsAction(payload));

  const searchProps = createSearchingProps(
    '',
    JudgementElement.getFilterableAttributes(),
    JudgementElement.getSortableAttributes()
  );

  useEffect(() => {
    managePage({
      title: 'Orzeczenia sądowe - kategorie',
      breadcrumb: breadcrumbRouteJudgementsCategory()
    });

    setParams({ ...searchProps });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchCreateCategoryToast = () => dispatch(addToastAction(createJudgementCategoryToastSuccess()));

  const cancelToken = useCancelToken();
  const createCategoryAction = async (data: { name: string }) => {
    setLoading(true);
    const {
      cancelled,
      data: resData,
      message: messageRequest,
      error
    } = await updateRequestWithData(createJudgmentsCategory, data, cancelToken);

    if (cancelled) return;
    if (resData?.data) {
      dispatchCreateCategoryToast();
      dispatch(push(getPathUrl(ROUTE_JUDGEMENT_CATEGORY, { id: resData.data.id })));
    } else {
      if (error) setErrors(error);
      if (messageRequest) setMessage(messageRequest);
      setLoading(false);
    }
  };

  const onUpdateSuccess = (successData?: JudgementElementEntity) =>
    dispatch(push(getPathUrl(ROUTE_JUDGEMENT_CATEGORY, { id: successData.id })));

  const {
    updateCategoryAction,
    loading: updateLoading,
    displayEditModal,
    setDisplayEditModal,
    message: updateMessage,
    errors: updateErrors,
    clearErrors: cleanUpdate
  } = useCategoryUpdateModal(updateJudgmentsCategory, updateJudgementCategoryToastSuccess, onUpdateSuccess);

  const {
    deleteCategoryAction,
    loading: deleteLoading,
    displayDeleteModal,
    setDisplayDeleteModal,
    message: deleteMessage,
    clearErrors: cleanDelete
  } = useCategoryDeleteModal(
    deleteJudgmentsCategory,
    deleteJudgementCategoryToastSuccess,
    () => {
      setDisplayDeleteModal(null);
      setParams({});
    },
    true
  );

  const toggleCreateModal = () => {
    if (!loading) setDisplayAddModal(false);
  };

  const toggleEditModal = () => {
    if (!updateLoading) setDisplayEditModal(null);
  };

  const toggleDeleteModal = () => {
    if (!deleteLoading) setDisplayDeleteModal(null);
  };

  const clearErrors = () => {
    setMessage(null);
    setErrors(null);
  };

  const onEditClicked = (category: JudgementElement) => setDisplayEditModal(category);
  const onDeleteClicked = (category: JudgementElement) => setDisplayDeleteModal(category);

  const tableRedirectLink = (value: string, id: number) => (
    <Authorize permissions={[JUDGMENT_CATEGORIES_DETAILS]} fallback={value}>
      <Link className="fill-table-td" to={getPathUrl(ROUTE_JUDGEMENT_CATEGORY, { id })}>
        {value}
      </Link>
    </Authorize>
  );

  const tableActions: TableCol<JudgementElement> = {
    property: 'actions',
    label: 'Akcje',
    value: function Actions(row) {
      return (
        <div className="actions-wrapper">
          {onEditClicked && (
            <Authorize permissions={[JUDGMENT_CATEGORIES_EDIT]}>
              <ActionUpdate
                className="mx-1"
                title="Edytuj kategorię"
                label={<EditIcon height="20px" />}
                onClick={() => onEditClicked(row)}
              />
            </Authorize>
          )}
          {onDeleteClicked && (
            <Authorize permissions={[JUDGMENT_CATEGORIES_DELETE]}>
              <ActionDelete className="mx-1" title="Usuń kategorię" onClick={() => onDeleteClicked(row)} />
            </Authorize>
          )}
        </div>
      );
    }
  };

  return (
    <div className="row offices-view">
      <div className="col-12">
        {displayAddModal && (
          <ManageElementModal
            title="Dodaj kategorię"
            bodyTitle="Nazwa kategorii"
            isOpen={displayAddModal}
            toggle={toggleCreateModal}
            loading={loading}
            message={message}
            errors={errors}
            clearErrorsOnUnmount={clearErrors}
            onSubmit={createCategoryAction}
          />
        )}
        {displayEditModal && (
          <ManageElementModal
            element={displayEditModal}
            title="Edytuj kategorię"
            bodyTitle="Nazwa kategorii"
            isOpen={!!displayEditModal}
            toggle={toggleEditModal}
            loading={updateLoading}
            message={updateMessage}
            errors={updateErrors}
            clearErrorsOnUnmount={cleanUpdate}
            onSubmit={updateCategoryAction}
          />
        )}
        {displayDeleteModal && (
          <GenericModalDelete
            value={displayDeleteModal}
            title="Usuń kategorię"
            content={(value) => (
              <div>
                Czy na pewno chcesz usunąć kategorię o nazwie <strong>{value?.name}</strong>?
              </div>
            )}
            onDeleteClick={(value) => deleteCategoryAction(value.id)}
            isOpen={!!displayDeleteModal}
            toggle={toggleDeleteModal}
            loading={deleteLoading}
            message={deleteMessage}
            resetMessage={cleanDelete}
          />
        )}
        <Listing
          table={
            <JudgementElementTable
              categoryList={{ list, sort }}
              actions={tableActions}
              redirectLink={tableRedirectLink}
              reduxAction={setJudgementCategoryListParamsAction}
              nameSortable
            />
          }
          filter={
            <JudgementElementFilter
              values={searchProps.filter}
              reduxAction={setJudgementCategoryListParamsAction}
              categoryList={{ filter, pagination }}
            />
          }
          pagination={
            <JudgementElementPagination
              path={ROUTE_JUDGEMENT_CATEGORIES}
              listObj={{ sort, filter, pagination, meta }}
              reduxAction={setJudgementCategoryListParamsAction}
            />
          }
          perPage={<JudgementElementPerPage meta={meta} reduxAction={setJudgementCategoryListParamsAction} />}
          loading={fetching}
          childrenNextToPagination={
            <Authorize permissions={[JUDGMENT_CATEGORIES_ADD]}>
              <Button
                color="link"
                className="link-with-border p-0 table-list-action"
                onClick={() => setDisplayAddModal(true)}
              >
                Dodaj kategorię
              </Button>
            </Authorize>
          }
        />
      </div>
    </div>
  );
};

export default JudgementCategories;
