import CardBox from 'modules/Layout/component/CardBox';
import React from 'react';
import ExecutorRequestDetailsDataset from 'modules/Office/component/Dataset/ExecutorRequestDetails';
import { OfficeExecutorRequestValue } from 'modules/Office/type';
import { OFFICES_DETAILS } from 'modules/Office/permissions';
import Authorize from 'modules/Auth/container/Authorize';
import ExecutorRequestFileListTable from 'modules/Office/container/ExecutorRequestFileList';
import './index.scss';
import { Button } from 'reactstrap';

export type Props = {
  executorRequest?: OfficeExecutorRequestValue;
  showModal?: () => void;
  downloadFileRequest?: (fileId: number | string) => Promise<void>;
  redirectUrl: string;
};

const DetailsExecutorsRequestBasic: React.FC<Props> = ({
  executorRequest,
  showModal,
  downloadFileRequest,
  redirectUrl
}) => {
  const { files } = executorRequest;
  return (
    <CardBox
      heading="Szczegóły zapytania"
      className="executor-request-basic"
      paddingLeft="30px"
      paddingRight="30px"
      actions={
        !executorRequest.received_at ? (
          <Authorize permissions={[OFFICES_DETAILS]}>
            <Button color="link" onClick={showModal} className="link-with-border p-0">
              Zatwierdź zapytanie
            </Button>
          </Authorize>
        ) : null
      }
      redirectReturnBtn={redirectUrl}
    >
      <ExecutorRequestDetailsDataset executorRequest={executorRequest} />
      <div className="information-list-item mt-2">
        <p className="information-list-paragraph">
          <strong style={{ marginRight: '0.25rem' }}>Załączniki:</strong>
          {!files?.length ? <span className="value">brak</span> : null}
        </p>
        {files?.length ? (
          <ExecutorRequestFileListTable filesList={files} downloadFileRequest={downloadFileRequest} />
        ) : null}
      </div>
    </CardBox>
  );
};

export default DetailsExecutorsRequestBasic;
