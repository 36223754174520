import React, { useEffect, useRef, useState } from 'react';
import { AGREEMENT_TYPE_PROJECT, AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { getStepValues, PREVIEW_PLACEHOLDER } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_AGREEMENT_DATE } from 'modules/Agreements/step';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import Date from 'modules/Layout/component/Input/Date';
import { getError, hasError } from 'modules/Shared/helper/validation';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import { isEqual } from 'lodash';

export interface AgreementStepAgreementDateValues {
  agreement_date: string | null;
  number_checked: boolean;
  number_content: string | null;
  place_checked: boolean;
  place_content: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const mapState = (step: AgreementStepAgreementDateValues): AgreementStepAgreementDateValues => {
  const { checkboxes, ...rest } = step;

  return {
    ...rest,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepAgreementDate = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, onSubmit, onChange, errors } = props;
  const initState = useRef<AgreementStepAgreementDateValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_AGREEMENT_DATE))
  );
  const [stepValues, setStepValues] = useState<AgreementStepAgreementDateValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_AGREEMENT_DATE));
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderCheckbox } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      {agreement.type === AGREEMENT_TYPE_PROJECT ? (
        <FormGroup>
          zawarta w dniu {PREVIEW_PLACEHOLDER} w {PREVIEW_PLACEHOLDER} pomiędzy:
        </FormGroup>
      ) : (
        <>
          <FormGroup>
            <Label>
              Data zawarcia umowy:
              <Date
                id="agreement-date"
                name="agreement_date"
                value={stepValues.agreement_date}
                invalid={hasError(errors, 'agreement_date')}
                required
                onChange={(date) => setStepValues((values) => ({ ...values, agreement_date: date }))}
              />
              {hasError(errors, 'agreement_date') && (
                <FormFeedback className="d-block">{getError(errors, 'agreement_date')}</FormFeedback>
              )}
            </Label>
          </FormGroup>
          {renderCheckbox('number_checked', 'Numer umowy')}
          {stepValues.number_checked && (
            <FormGroup>
              <Input
                type="text"
                min={0}
                id="number_content"
                name="number_content"
                value={stepValues.number_content || ''}
                required
                onChange={(event) => {
                  setStepValues((values) => ({ ...values, number_content: event.target.value }));
                }}
                invalid={hasError(errors, 'number_content')}
              />
            </FormGroup>
          )}
          {hasError(errors, 'number_content') && (
            <FormFeedback className="d-block">{getError(errors, 'number_content')}</FormFeedback>
          )}
          {renderCheckbox('place_checked', 'Miejsce zawarcia umowy')}
          {stepValues.place_checked && (
            <FormGroup>
              <Input
                id="place_content"
                name="place_content"
                value={stepValues.place_content || ''}
                required
                onChange={(event) => {
                  setStepValues((values) => ({ ...values, place_content: event.target.value }));
                }}
                invalid={hasError(errors, 'place_content')}
              />
            </FormGroup>
          )}
          {hasError(errors, 'place_content') && (
            <FormFeedback className="d-block">{getError(errors, 'place_content')}</FormFeedback>
          )}
        </>
      )}
    </AgreementFormWrapper>
  );
};

export default AgreementStepAgreementDate;
