import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { createSearchingProps } from 'modules/Shared/helper/params';
import User from 'modules/User/model/User';
import { breadcrumbTraditionalPaymentWaiting } from 'modules/Subscriptions/breadcrumbs';
import TraditionalPaymentWaitingList from 'modules/Subscriptions/component/TraditionalPaymentWaitingList';
import { setTraditionalPaymentListParamsAction } from 'modules/Subscriptions/action/traditionalPaymentList';

const TraditionalPaymentWaitingListView = () => {
  const dispatch = useDispatch();
  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const setParams = (payload: PartialSearchingProps) => dispatch(setTraditionalPaymentListParamsAction(payload));

  const searchProps = createSearchingProps('', User.getFilterableAttributes(), User.getSortableAttributes());

  useEffect(() => {
    const title = 'Oczekujący klienci - lista';

    managePage({
      title,
      breadcrumb: breadcrumbTraditionalPaymentWaiting()
    });

    setParams({ ...searchProps });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <TraditionalPaymentWaitingList />;
};

export default TraditionalPaymentWaitingListView;
