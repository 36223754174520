import { Routes } from 'app/routes';
import Authorize from 'modules/Auth/container/Authorize';
import JudgmentsCategoriesListView from 'modules/Judgement/view/Category';
import JudgmentsCategoryDetailsView from 'modules/Judgement/view/Category/Details';
import KeywordsWithCategoriesList from 'modules/Judgement/view/KeywordsWithCategoriesList';
import React from 'react';
import { Route } from 'react-router-dom';
import {
  JUDGMENT_CATEGORIES_INDEX,
  JUDGMENT_CATEGORIES_DETAILS,
  JUDGMENT_KEYWORDS_INDEX,
  JUDGMENTS_ADD,
  JUDGMENTS_EDIT,
  JUDGMENTS_DETAILS,
  JUDGMENTS_INDEX
} from 'modules/Judgement/permissions';
import JudgementCreateView from 'modules/Judgement/view/Create';
import JudgementDetailsView from 'modules/Judgement/view/Details';
import JudgementUpdateBasicView from 'modules/Judgement/view/Update/Basic';
import JudgementUpdateContentView from 'modules/Judgement/view/Update/Content';
import JudgementListView from 'modules/Judgement/view';
import JudgementKeywordIncludedMarkView from 'modules/Judgement/view/Details/KeywordIncludedMark';
import JudgementConnectedClausesMarkView from 'modules/Judgement/view/Details/ConnectedClausesMark';
import ConnectedClausesListView from 'modules/Judgement/component/Details/Judgement/ConnectedClauses/List';

export const ROUTE_JUDGEMENT_CATEGORIES = '/judgement/category';
export const ROUTE_JUDGEMENT_CATEGORY = '/judgement/category/:id(\\d+)';
export const ROUTE_KEYWORDS_WITH_CATEGORIES = '/judgement/keyword/';
export const ROUTE_JUDGEMENT_CREATE = '/judgement/create';
export const ROUTE_JUDGEMENT = '/judgement/:id(\\d+)';
export const ROUTE_JUDGEMENT_UPDATE_BASIC = '/judgement/update/:id(\\d+)/basic';
export const ROUTE_JUDGEMENT_UPDATE_CONTENT = '/judgement/update/:id(\\d+)/content';
export const ROUTE_JUDGEMENT_LIST = '/judgments';
export const ROUTE_JUDGEMENT_INCLUDED_KEYWORD = '/judgement/:id(\\d+)/keyword-included';
export const ROUTE_JUDGEMENT_CONNECT_CLAUSE = '/judgement/:id(\\d+)/connect-clause';
export const ROUTE_JUDGEMENT_CLAUSE_ADD_LIST = '/judgement/:id(\\d+)/list';

export const createJudgementRoutes = (): Routes => ({
  wrapped: [
    <Authorize key="judgement-categories" permissions={[JUDGMENT_CATEGORIES_INDEX]}>
      <Route path={ROUTE_JUDGEMENT_CATEGORIES} exact component={JudgmentsCategoriesListView} />
    </Authorize>,
    <Authorize key="judgement-category" permissions={[JUDGMENT_CATEGORIES_DETAILS]}>
      <Route path={ROUTE_JUDGEMENT_CATEGORY} exact component={JudgmentsCategoryDetailsView} />
    </Authorize>,
    <Authorize key="judgement-keywords" permissions={[JUDGMENT_KEYWORDS_INDEX]}>
      <Route path={ROUTE_KEYWORDS_WITH_CATEGORIES} exact component={KeywordsWithCategoriesList} />
    </Authorize>,
    <Authorize key="judgement-create" permissions={[JUDGMENTS_ADD]}>
      <Route path={ROUTE_JUDGEMENT_CREATE} exact component={JudgementCreateView} />
    </Authorize>,
    <Authorize key="judgement-update-basic" permissions={[JUDGMENTS_EDIT]}>
      <Route path={ROUTE_JUDGEMENT_UPDATE_BASIC} exact component={JudgementUpdateBasicView} />
    </Authorize>,
    <Authorize key="judgement-update-content" permissions={[JUDGMENTS_EDIT]}>
      <Route path={ROUTE_JUDGEMENT_UPDATE_CONTENT} exact component={JudgementUpdateContentView} />
    </Authorize>,
    <Authorize key="judgement-details" permissions={[JUDGMENTS_DETAILS]}>
      <Route path={ROUTE_JUDGEMENT} exact component={JudgementDetailsView} />
    </Authorize>,
    <Authorize key="judgement-list" permissions={[JUDGMENTS_INDEX]}>
      <Route path={ROUTE_JUDGEMENT_LIST} exact component={JudgementListView} />
    </Authorize>,
    <Authorize key="judgement-details-keyword-create" permissions={[JUDGMENTS_DETAILS]}>
      <Route path={ROUTE_JUDGEMENT_INCLUDED_KEYWORD} exact component={JudgementKeywordIncludedMarkView} />
    </Authorize>,
    <Authorize key="judgement-clause-add-list" permissions={[JUDGMENTS_DETAILS]}>
      <Route path={ROUTE_JUDGEMENT_CLAUSE_ADD_LIST} exact component={ConnectedClausesListView} />
    </Authorize>,
    <Authorize key="judgement-connect-clause-content" permissions={[JUDGMENTS_DETAILS]}>
      <Route path={ROUTE_JUDGEMENT_CONNECT_CLAUSE} exact component={JudgementConnectedClausesMarkView} />
    </Authorize>
  ]
});
