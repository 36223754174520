import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { SubscriptionEntity } from 'modules/Subscriptions/model/Subscription';
import { PaymentData, PaymentEndResponse } from 'modules/Subscriptions/type';
import { PaginationMeta, SearchParams } from 'modules/Shared/type';
import { TraditionalPaymentEntity } from 'modules/Subscriptions/model/TraditionalPayment';

export const API_SUBSCRIPTIONS = '/api/subscriptions';
export const API_PAYMENT_START = '/api/payments/begin';
export const API_TRADITIONAL_PAYMENT_START = '/api/payments/begin_traditional';
export const API_TRADITIONAL_PAYMENT_WAITING = '/api/payments/traditional/waiting';
export const API_FINISH_TRADITIONAL_PAYMENT = '/api/payments/finish_traditional/:payment_number';
export const API_PAYMENT_SUCCESS = '/api/payments/finish/:payment_id';
export const API_PAYMENT_CANCEL = '/api/payments/cancel/:payment_id';
export const API_TRADITIONAL_PAYMENT_CANCEL = '/api/payments/cancel_traditional/:payment_id';

export const API_PAYMENT_CANCEL_PAYMENT_ERROR_CODE = 470;

export type SubscriptionsResponse = {
  data: SubscriptionEntity[];
};

export type TraditionalPaymentsResponse = {
  data: TraditionalPaymentEntity[];
  meta: PaginationMeta;
};

export const fetchSubscription = (): AxiosPromise<SubscriptionsResponse> => axios.get(getAPIUrl(API_SUBSCRIPTIONS));

export const fetchTraditionalPaymentsWaiting = (params: SearchParams = {}): AxiosPromise<TraditionalPaymentsResponse> =>
  axios.get(getAPIUrl(API_TRADITIONAL_PAYMENT_WAITING, null, params));

export const finishTraditionalPayment = (paymentNumber: string): AxiosPromise =>
  axios.post(getAPIUrl(API_FINISH_TRADITIONAL_PAYMENT, { payment_number: paymentNumber }));

export type StartPaymentResponse = {
  data: PaymentData;
};

export type StartTraditionalPaymentResponse = {
  payment_number: string;
};

export type EndPaymentResponse = {
  data: PaymentData;
};

export const startPayment = (
  data: { subscriptions: number[]; cancel_previous_payments: boolean },
  cancelToken?: any
): AxiosPromise<StartPaymentResponse> => axios.post(getAPIUrl(API_PAYMENT_START), { ...data }, { cancelToken });

export const startTraditionalPayment = (
  data: { subscriptions: number[]; cancel_previous_payments: boolean },
  cancelToken?: any
): AxiosPromise<StartTraditionalPaymentResponse> =>
  axios.post(getAPIUrl(API_TRADITIONAL_PAYMENT_START), { ...data }, { cancelToken });

export const successPayment = (payment_id: number | string, cancelToken?: any): AxiosPromise<PaymentEndResponse> =>
  axios.post(getAPIUrl(API_PAYMENT_SUCCESS, { payment_id }), {}, { cancelToken });

export const cancelPayment = (payment_id: number | string, cancelToken?: any): AxiosPromise<PaymentEndResponse> =>
  axios.post(getAPIUrl(API_PAYMENT_CANCEL, { payment_id }), {}, { cancelToken });

export const cancelTraditionalPayment = (
  payment_id: number | string,
  cancelToken?: any
): AxiosPromise<PaymentEndResponse> =>
  axios.post(getAPIUrl(API_TRADITIONAL_PAYMENT_CANCEL, { payment_id }), {}, { cancelToken });
