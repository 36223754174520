/* eslint-disable */
import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function GoodCommunicateIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px', className, strokeWidth = '1' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} className={className} viewBox="0 0 28.826 28.825">
      <g id="Group_409" data-name="Group 409" transform="translate(0.5 0.5)">
        <path
          id="Path_1312"
          data-name="Path 1312"
          d="M301.542,1296.62a13.913,13.913,0,1,0,13.914,13.911A13.91,13.91,0,0,0,301.542,1296.62Z"
          transform="translate(-287.63 -1296.62)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
        <path
          id="Path_1313"
          data-name="Path 1313"
          d="M294.074,1309.409l4.056,5.071,8.734-10.919"
          transform="translate(-286.198 -1295.077)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  );
}
