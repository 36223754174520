import _has from 'lodash/has';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { Button, Form as FormStrap } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_JUDGEMENT, ROUTE_JUDGEMENT_LIST } from 'modules/Judgement/routes';
import Judgement from 'modules/Judgement/model/Judgement';
import { JudgementFieldValues } from 'modules/Judgement/type';
import JudgementFieldset from 'modules/Judgement/component/Fieldset/Judgement/Content';
import useWysiwyg from 'modules/Layout/component/Wysiwyg';

export interface Props {
  errors?: ValidationErrors;
  disabled?: boolean;
  judgement?: Judgement;
  submit: (data: JudgementFieldValues) => void;
}

const JudgementForm: React.FC<Props> = ({ errors, submit, judgement }) => {
  const { displayWysiwyg, getContent } = useWysiwyg({
    title: 'Treść publikacji',
    content: judgement?.content,
    tooltip: { specialSign: true, tables: true }
  });

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    submit({ content: getContent() });
  };

  return (
    <FormStrap onSubmit={onSubmit}>
      <JudgementFieldset errors={errors} displayWysiwyg={displayWysiwyg} />

      <div className="d-flex justify-content-center">
        <div className="form-actions pzpeu-btn-flex">
          <Button type="submit" color="primary" className="waves-effect waves-light">
            Zapisz
          </Button>
          <Link
            to={judgement ? getPathUrl(ROUTE_JUDGEMENT, { id: judgement.id }) : ROUTE_JUDGEMENT_LIST}
            className="cancel waves-effect waves-light"
          >
            Anuluj
          </Link>
        </div>
      </div>
    </FormStrap>
  );
};

export default JudgementForm;
