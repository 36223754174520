import { RootState } from 'app/reducer';
import Authorize from 'modules/Auth/container/Authorize';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { PartialSearchingProps } from 'modules/Shared/type';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { setAgreementsListParamsAction } from 'modules/Agreements/action/list';
import Agreement, { agreementsModeValue, agreementsTypeValue } from 'modules/Agreements/model/Agreements';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_AGREEMENT_UPDATE } from 'modules/Agreements/routes';
import { analytics } from 'firebase';
import { AGREEMENT_STAGE_AGREEMENT } from 'modules/Agreements/step';

type Props = { onDeleteClick?: (agreements: Agreement) => void };

const AgreementsTable: React.FC<Props> = ({ onDeleteClick }) => {
  const dispatch = useDispatch();
  const { data, sort } = useSelector((state: RootState) => state.agreements.list);

  const setParams = (payload: PartialSearchingProps) => dispatch(setAgreementsListParamsAction(payload));

  const redirectLink = (value: string, agreement: any) => (
    <Authorize>
      <Link
        to={getPathUrl(ROUTE_AGREEMENT_UPDATE, {
          id: agreement.id,
          mode: agreement.mode,
          stage: AGREEMENT_STAGE_AGREEMENT
        })}
        onClick={() => analytics.logEvent('agreements_activation')}
      >
        {value}
      </Link>
    </Authorize>
  );

  const cols: TableCol<Agreement>[] = [
    {
      property: 'mode',
      label: 'Tryb umowy',
      sortable: true,
      value: (row) => redirectLink(agreementsModeValue(row.mode), row)
    },
    {
      property: 'type',
      label: 'Cel umowy',
      sortable: true,
      value: (row) => redirectLink(agreementsTypeValue(row.type), row)
    },
    {
      property: 'name',
      label: 'Robocza nazwa umowy',
      sortable: true,
      value: (row) => redirectLink(row.name, row)
    },
    {
      property: 'created_at',
      label: 'Data utworzenia',
      sortable: true,
      value: (row) => ifContainsDateFormat(row.created_at, formatDatetimeSeconds)
    },
    {
      property: 'last_change_at',
      label: 'Data ostatniej aktualizacji',
      sortable: true,
      value: (row) => ifContainsDateFormat(row.last_change_at, formatDatetimeSeconds)
    },
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            {onDeleteClick && (
              <Authorize>
                <ActionDelete className="ml-1" title="Usuń umowę" onClick={() => onDeleteClick(row)} />
              </Authorize>
            )}
          </div>
        );
      }
    }
  ];

  return <Table cols={cols} rows={data} sort={sort} onSort={(params) => setParams({ sort: params })} />;
};

export default AgreementsTable;
