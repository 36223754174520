import { Routes } from 'app/routes';
import Authorize from 'modules/Auth/container/Authorize';
import React from 'react';
import { Route } from 'react-router-dom';
import ForeignProceedingsImports from 'modules/ForeignProceedings/view/Imports';
import ForeignProceedingsDetails from 'modules/ForeignProceedings/view/Details';
import ForeignProceedingsFavouritesOpenListView from 'modules/ForeignProceedings/view/Favourites/Client';
import ForeignProceedingsFavouritesOpenListAdminView from 'modules/ForeignProceedings/view/Favourites/Admin';
import { ForeignProceedingsOpenListView, ForeignProceedingsPlanningListView } from 'modules/ForeignProceedings/view';
import { FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS } from 'modules/ForeignProceedings/permissions';
import ModuleGuard from 'modules/Auth/container/Guard/ModuleGuard';
import ForeignProceedingsUpdateView from 'modules/ForeignProceedings/view/Update';
import { MODULE_FOREIGN_PROCEEDINGS } from 'modules/Module/model/Module';
import ForeignProceedingsApiImports from 'modules/ForeignProceedings/view/ApiImports';
import ForeignProceedingsAlerts from 'modules/ForeignProceedings/view/Alerts/index';
import ForeignProceedingsAlertCreateView from 'modules/ForeignProceedings/view/Alerts/Create/index';
import ForeignProceedingsAlertUpdateView from 'modules/ForeignProceedings/view/Alerts/Update';
import AlertDetails from 'modules/ForeignProceedings/view/Alerts/Details';
import ForeignProceedingsReports from './view/Reports';

export const ROUTE_FOREIGN_PROCEEDING = '/foreign-proceedings/:id(\\d+)';
export const ROUTE_FOREIGN_PROCEEDING_ADMIN = '/foreign-proceedings/admin/:id(\\d+)';
export const ROUTE_FOREIGN_PROCEEDINGS_PLANNING = '/foreign-proceedings/planning';
export const ROUTE_FOREIGN_PROCEEDINGS_OPEN = '/foreign-proceedings/open';
export const ROUTE_FOREIGN_PROCEEDINGS_REPORTS = '/foreign-proceedings/reports';
export const ROUTE_FOREIGN_PROCEEDINGS_ALERTS = '/foreign-proceedings/alerts';
export const ROUTE_FOREIGN_PROCEEDINGS_ALERT = '/foreign-proceedings/alerts/:id(\\d+)';
export const ROUTE_FOREIGN_PROCEEDINGS_OPEN_ADMIN = '/foreign-proceedings/admin/open';
export const ROUTE_FOREIGN_PROCEEDINGS_IMPORTS = '/foreign-proceedings/imports';
export const ROUTE_FOREIGN_PROCEEDINGS_API_IMPORTS = '/foreign-proceedings/api-imports';
export const ROUTE_FOREIGN_PROCEEDINGS_PLANNING_FAV = '/foreign-proceedings/fav/planning';
export const ROUTE_FOREIGN_PROCEEDINGS_OPEN_FAV = '/foreign-proceedings/fav/open';
export const ROUTE_FOREIGN_PROCEEDINGS_ADMIN_OPEN_FAV = '/foreign-proceedings/admin/fav/open';
export const ROUTE_FOREIGN_PROCEEDINGS_ALERT_CREATE = '/foreign-proceedings/alerts/create';
export const ROUTE_FOREIGN_PROCEEDINGS_UPDATE = '/foreign-proceedings/update/:id(\\d+)';
export const ROUTE_FOREIGN_PROCEEDINGS_ALERT_UPDATE = '/foreign-proceedings/alert/update/:id(\\d+)';
export const ROUTE_FOREIGN_PROCEEDINGS_PUBLIC_PLANNING = '/foreign-proceedings/public/planning';
export const ROUTE_FOREIGN_PROCEEDINGS_PUBLIC_OPEN = '/foreign-proceedings/public/open';
export const ROUTE_FOREIGN_PROCEEDING_PUBLIC = '/foreign-proceedings/public/:id(\\d+)';
export const ROUTE_FOREIGN_PROCEEDING_IMPORT = '/foreign-proceedings/imports/:id(\\d+)';

export const createForeignProceedingsRoutes = (): Routes => ({
  wrapped: [
    <Authorize key="foreign-proceedings-planning-list">
      <Route path={ROUTE_FOREIGN_PROCEEDINGS_PLANNING} exact component={ForeignProceedingsPlanningListView} />
    </Authorize>,
    <Authorize permissions={[FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS]} key="foreign-proceedings-imports">
      <Route path={ROUTE_FOREIGN_PROCEEDINGS_IMPORTS} exact component={ForeignProceedingsImports} />
    </Authorize>,
    <Authorize permissions={[FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS]} key="foreign-proceedings-api-imports">
      <Route path={ROUTE_FOREIGN_PROCEEDINGS_API_IMPORTS} exact component={ForeignProceedingsApiImports} />
    </Authorize>,
    <Authorize key="foreign-proceedings-open-list-index">
      <Route
        path={ROUTE_FOREIGN_PROCEEDINGS_OPEN}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_FOREIGN_PROCEEDINGS}>
            <ForeignProceedingsOpenListView />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize key="foreign-proceedings-alerts-open-index">
      <Route
        path={ROUTE_FOREIGN_PROCEEDINGS_ALERTS}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_FOREIGN_PROCEEDINGS}>
            <ForeignProceedingsAlerts />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize key="foreign-proceedings-alerts-create">
      <Route
        path={ROUTE_FOREIGN_PROCEEDINGS_ALERT_CREATE}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_FOREIGN_PROCEEDINGS}>
            <ForeignProceedingsAlertCreateView />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize key="foreign-proceedings-alerts-update">
      <Route
        path={ROUTE_FOREIGN_PROCEEDINGS_ALERT_UPDATE}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_FOREIGN_PROCEEDINGS}>
            <ForeignProceedingsAlertUpdateView />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize key="foreign-proceedings-alerts-details">
      <Route
        path={ROUTE_FOREIGN_PROCEEDINGS_ALERT}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_FOREIGN_PROCEEDINGS}>
            <AlertDetails />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize permissions={[FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS]} key="foreign-proceedings-open-list-admin-index">
      <Route path={ROUTE_FOREIGN_PROCEEDINGS_OPEN_ADMIN} exact component={ForeignProceedingsOpenListView} />
    </Authorize>,
    <Authorize permissions={[FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS]} key="foreign-proceedings-open-details-admin">
      <Route path={ROUTE_FOREIGN_PROCEEDINGS_UPDATE} exact component={ForeignProceedingsUpdateView} />
    </Authorize>,
    <Authorize key="foreign-proceedings-client-reports-index">
      <Route path={ROUTE_FOREIGN_PROCEEDINGS_REPORTS} exact component={ForeignProceedingsReports} />
    </Authorize>,
    <Authorize key="foreign-proceedings-open-favourite">
      <Route
        path={ROUTE_FOREIGN_PROCEEDINGS_OPEN_FAV}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_FOREIGN_PROCEEDINGS}>
            <ForeignProceedingsFavouritesOpenListView />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize permissions={[FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS]} key="foreign-proceedings-open-favourite-admin">
      <Route
        path={ROUTE_FOREIGN_PROCEEDINGS_ADMIN_OPEN_FAV}
        exact
        component={ForeignProceedingsFavouritesOpenListAdminView}
      />
    </Authorize>,
    <Authorize key="foreign-proceedings-details">
      <Route
        path={ROUTE_FOREIGN_PROCEEDING}
        exact
        render={() => (
          <ModuleGuard moduleSlug={MODULE_FOREIGN_PROCEEDINGS}>
            <ForeignProceedingsDetails />
          </ModuleGuard>
        )}
      />
    </Authorize>,
    <Authorize permissions={[FOREIGN_PROCEEDINGS_MODULE_ADMIN_ACCESS]} key="foreign-proceedings-admin-details">
      <Route path={ROUTE_FOREIGN_PROCEEDING_ADMIN} exact component={ForeignProceedingsDetails} />
    </Authorize>
  ]
});
