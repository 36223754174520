import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import { setExecutorListParamsAction } from 'modules/Executor/action/list';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterInputType } from 'modules/Layout/type';
import _ from 'lodash';
import SaveFilterModal from 'modules/Layout/component/ListFilter/SaveFilterModal';
import useSaveFilter from 'modules/Shared/helper/hooks/useSaveFilter';
import { EXECUTOR_SLUG_FILTER } from 'modules/Layout/model/Filters';
import { hasFullAccess } from 'modules/Shared/helper/utils';
import { createSearchingProps } from 'modules/Shared/helper/params';
import ExecutorClient from 'modules/Executor/model/ExecutorClient';

const ExecutorFilter = () => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.executor.list);
  const user = useSelector((state: RootState) => state.auth.user);

  const setParams = (payload: PartialSearchingProps) => {
    dispatch(setExecutorListParamsAction(payload));
  };

  const [filterParams, setFilterParams] = useState<SearchParams>({});

  useEffect(() => {
    if (Object.keys(filter || {}).length && !_.isEqual(filter, filterParams)) {
      setFilterParams(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const onSearchButtonClicked = () => setParams({ filter: filterParams });

  const {
    saveModal,
    setSaveModal,
    cleanMessage,
    loading,
    message,
    filtersList,
    saveFilter,
    onDeleteFilterClick,
    filterList1stFetchFinished,
    onFilterSelectedClick: onFilterSelectedClickFn
  } = useSaveFilter(filter, setParams, EXECUTOR_SLUG_FILTER);

  const searchProps = createSearchingProps(
    '',
    ExecutorClient.getFilterableAttributes(),
    ExecutorClient.getSortableAttributes()
  );

  useEffect(() => {
    if (filterList1stFetchFinished) {
      const findDefault = filtersList.find((el) => el.default);
      if (findDefault) {
        onFilterSelectedClickFn(findDefault.id);
      } else {
        const localStorageParams = localStorage.getItem('localFilterParamsexecutor');

        if (localStorageParams) {
          const parsedParams = JSON.parse(localStorageParams);

          setParams({ ...parsedParams });
        } else {
          setParams({ ...searchProps });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterList1stFetchFinished]);

  const getStaticInputs = (): FilterInputType[] => {
    const inputs: FilterInputType[] = [
      {
        type: 'text',
        property: 'name',
        label: 'Nazwa',
        value: _get(filter, 'name')
      },
      {
        type: 'text',
        property: 'first_name',
        label: 'Imię',
        value: _get(filter, 'first_name')
      },
      {
        type: 'text',
        property: 'last_name',
        label: 'Nazwisko',
        value: _get(filter, 'last_name')
      },
      {
        beforeLineBreak: true,
        type: 'text',
        property: 'nip',
        label: 'NIP',
        value: _get(filter, 'nip')
      },
      {
        type: 'text',
        property: 'krs',
        label: 'KRS',
        value: _get(filter, 'krs')
      },
      {
        beforeLineBreak: true,
        type: 'text',
        property: 'city',
        label: 'Miasto',
        value: _get(filter, 'city')
      },
      {
        type: 'text',
        property: 'street',
        label: 'Ulica',
        value: _get(filter, 'street')
      },
      {
        type: 'text',
        property: 'postal_code',
        label: 'Kod pocztowy',
        value: _get(filter, 'postal_code')
      }
    ];

    if (!hasFullAccess(user)) {
      inputs.push(
        {
          type: 'number',
          property: 'penalties_total_value_min',
          label: 'Sumaryczna wartość kar od',
          value: _get(filter, 'penalties_total_value_min')
        },
        {
          type: 'number',
          property: 'penalties_total_value_max',
          label: 'Sumaryczna wartość kar do',
          value: _get(filter, 'penalties_total_value_max')
        }
      );
    }

    return inputs;
  };

  let disableBtn = false;
  if (_.isEmpty(filter)) {
    disableBtn = true;
  }

  const setMessage = (value: boolean) => {
    if (message) cleanMessage();
    setSaveModal(value);
  };

  return (
    <>
      <ListingFilter
        inputs={getStaticInputs()}
        filter={filterParams}
        pagination={pagination}
        filtersName={'executor'}
        setParams={hasFullAccess(user) ? (params) => setFilterParams(params.filter) : setParams}
        saveFilterBtn={hasFullAccess(user) ? () => setMessage(true) : null}
        filterName={hasFullAccess(user) ? 'Wyszukiwanie' : null}
        disableBtn={disableBtn}
        searchButtonObj={hasFullAccess(user) && { onClick: onSearchButtonClicked }}
        filterObj={{ filtersList, onDeleteFilterClick, onFilterSelectedClick: onFilterSelectedClickFn, loading }}
      />
      {hasFullAccess(user) && saveModal && (
        <SaveFilterModal
          onSaveClick={saveFilter}
          isOpen={saveModal}
          toggle={() => setMessage(false)}
          message={message}
          loading={loading}
          displayDefaultCheckbox
          shouldRefetchList
        />
      )}
    </>
  );
};

export default ExecutorFilter;
