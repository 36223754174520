import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import BaseModal, { Props } from 'modules/Layout/component/Modal';
import { Message, ValidationErrors } from 'modules/Shared/type';
import React, { useEffect, useState } from 'react';
import { Button, CustomInput, FormFeedback, Input } from 'reactstrap';
import TextNumberInput from 'modules/Layout/component/Input/TextNumber/input';
import { getError, hasError } from 'modules/Shared/helper/validation';
import nipValidate from 'modules/Shared/helper/nipValidation';
import './style.scss';
import _isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import { fetchExecutorExtendedFromGus, fetchExecutorFromGus } from '../../../repository';
import { addToastAction } from '../../../../Layout/action';
import { executorFromGusToastError, executorFromGusToastSuccess } from '../../../toasts';

interface IProps extends Props {
  onUseCreditClick?: (data: { nip: string; is_construction_company?: boolean }) => Promise<void>;
  loading?: boolean;
  message?: Message;
  cleanMessage?: () => void;
  errors?: ValidationErrors;
}

const ModalUseCreditAdmin: React.FC<IProps> = (props) => {
  const { onUseCreditClick, loading, toggle, message, cleanMessage, errors } = props;

  const [nip, setNip] = useState('');
  const [isConstructionCompany, setIsConstructionCompany] = useState(false);
  const [nipError, setNipError] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [reportClaimFromGus, setReportClaimFromGus] = useState<{ [key: string]: any }>(null);
  const dispatch = useDispatch();
  const title = 'Przyznanie dostępu do wykonawcy';

  const isValue = React.useRef(false);
  useEffect(() => {
    return () => {
      if (isValue.current) cleanMessage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isValue.current) isValue.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, errors]);

  useEffect(() => {
    if (nipError) setNipError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nip]);

  const nipInput = !isConstructionCompany ? (
    <TextNumberInput
      placeholder="NIP"
      value={nip}
      setValue={(value) => setNip(value)}
      hasError={hasError(errors, 'nip') || nipError}
    />
  ) : (
    <Input
      type="text"
      placeholder="NIP"
      value={nip}
      onChange={(value) => setNip(value.target.value)}
      invalid={hasError(errors, 'nip') || nipError}
    />
  );

  const onIsConstructionCompanyCheckboxChange = () => {
    if (isConstructionCompany) {
      setNip('');
    }
    setIsConstructionCompany(!isConstructionCompany);
  };

  const dispalBody = () => {
    return (
      <>
        {loading && <Loader />}
        <Alert message={message} />
        <div className="modal-body-title">Wpisz numer NIP wykonawcy, do którego chcesz otrzymać dostęp:</div>
        <div className="modal-body-content">
          {nipInput}
          <div className="d-flex flex-wrap align-items-center mt-2">
            <CustomInput
              id="is_construction_company"
              type="checkbox"
              className="executor-checkbox-use_credit"
              label={<div className="mt-1">Czy podmiot jest wykonawcą robót budowlanych?</div>}
              checked={isConstructionCompany}
              onChange={onIsConstructionCompanyCheckboxChange}
            />
          </div>
        </div>

        {hasError(errors, 'nip') && <FormFeedback className="d-block">{getError(errors, 'nip')}</FormFeedback>}
        {nipError && <div className="error-message">Niepoprawny format NIP-u.</div>}
      </>
    );
  };

  const requestBasedOnIsConstruction = () => {
    const obj = { nip };
    onUseCreditClick({ ...obj, is_construction_company: isConstructionCompany });
  };

  // eslint-disable-next-line consistent-return
  const fetchExecutor = async (): Promise<any> => {
    // eslint-disable-next-line no-useless-catch
    try {
      const {
        data: { data }
      } = isConstructionCompany ? await fetchExecutorExtendedFromGus(nip) : await fetchExecutorFromGus(nip);

      if (_isEmpty(data)) {
        dispatch(addToastAction(executorFromGusToastError()));
        return null;
      }

      dispatch(addToastAction(executorFromGusToastSuccess()));

      setReportClaimFromGus(data[0]);
    } catch (error) {
      throw error;
    }
  };

  const onSubmitClick = () => {
    setConfirmation(false);
    requestBasedOnIsConstruction();
  };

  const displayConfirmationBody = () => {
    return (
      <>
        {loading && <Loader />}
        <Alert message={message} />

        <div className="modal-body-title">Posiadasz aktywny dostęp dla tego wykonawcy</div>
        <div className="modal-body-text">Czy na pewno chcesz wykupić dostęp do wykonawcy o NIP-ie</div>
        <div className="modal-body-text-large">
          <strong>{nip}</strong>
        </div>
        {reportClaimFromGus && (
          <div className="modal-body-text mt-0">
            Firma: <strong>{reportClaimFromGus?.name}</strong>
          </div>
        )}
      </>
    );
  };

  const onBtnClick = () => {
    if (isValue.current) cleanMessage();

    const validation = nipValidate(nip);
    if (nipError && validation) {
      setNipError(false);
    } else if (!nipError && !validation) {
      setNipError(true);
    }

    if (validation) {
      fetchExecutor();
      setConfirmation(true);
    }
  };

  const displayFooter = () => {
    return (
      <div className="modal-actions">
        <Button
          type="submit"
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light"
          disabled={!nip || confirmation || loading}
          onClick={onBtnClick}
        >
          Uzyskaj dostęp
        </Button>
        <Button type="button" className="cancel waves-effect waves-light" disabled={loading} onClick={toggle}>
          Anuluj
        </Button>
      </div>
    );
  };

  const displayConfirmationFooter = () => {
    return (
      <div className="modal-actions">
        <Button
          type="submit"
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light"
          disabled={loading}
          onClick={() => (reportClaimFromGus ? onSubmitClick() : toggle())}
        >
          Uzyskaj dostęp
        </Button>
        <Button type="button" className="cancel waves-effect waves-light" disabled={loading} onClick={toggle}>
          Anuluj
        </Button>
      </div>
    );
  };

  return (
    <BaseModal
      {...props}
      title={title}
      body={confirmation ? displayConfirmationBody() : dispalBody()}
      footer={confirmation ? displayConfirmationFooter() : displayFooter()}
    />
  );
};

export default ModalUseCreditAdmin;
