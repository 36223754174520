import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import {
  getFieldForPreview,
  getStepName,
  getStepValues,
  PREVIEW_PLACEHOLDER
} from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_CO_FINANCING, AGREEMENT_STEP_PREAMBLE } from 'modules/Agreements/step';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementStepPreambleValues } from 'modules/Agreements/components/Steps/Default/Preamble';
import { fetchCoFinancingProjects } from 'modules/Agreements/repository';
import { addToastAction } from 'modules/Layout/action';
import { fetchCoFinancingProjectsToastError } from 'modules/Agreements/toasts';
import Loader from 'modules/Layout/component/Loader';
import {
  AgreementStepCoFinancingValues,
  CoFinancingProject
} from 'modules/Agreements/components/Steps/Default/CoFinancing';

const AgreementStepPreviewPreamble = (props: AgreementStepPreviewProps<AgreementStepPreambleValues>): JSX.Element => {
  const dispatch = useDispatch();
  const [coFinancingPrograms, setCoFinancingPrograms] = useState<{ id: number; name: string }[]>([]);
  const [coFinancingProjectsLoading, setCoFinancingProjectsLoading] = useState<boolean>(true);
  const { steps, modifiedStep } = props;
  const preamble = getStepValues<AgreementStepPreambleValues>(steps, AGREEMENT_STEP_PREAMBLE);
  const co_financing = getStepValues<AgreementStepCoFinancingValues>(steps, AGREEMENT_STEP_CO_FINANCING);

  const { value: proceedingName, modified: proceedingNameModified } = getFieldForPreview(
    preamble,
    modifiedStep,
    'proceeding_name'
  );

  const { value: euCoFinancing, modified: euCoFinancingModified } = getFieldForPreview(
    co_financing,
    modifiedStep,
    'co_financing_projects_allowed'
  );

  const { value: coFinancingProjects, modified: coFinancingProjectsModified } = getFieldForPreview(
    co_financing,
    modifiedStep,
    'co_financing_projects'
  );

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await fetchCoFinancingProjects();
      // @ts-ignore
      setCoFinancingPrograms(response.data.data);
    } catch (error) {
      dispatch(addToastAction(fetchCoFinancingProjectsToastError()));
      throw error;
    } finally {
      setCoFinancingProjectsLoading(false);
    }
  };

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_PREAMBLE)}</div>
      {proceedingName && (
        <p
          className={classNames('preview-point', {
            modified: proceedingNameModified
          })}
        >{`W związku z przeprowadzeniem postępowania o udzielenie zamówienia publicznego pn.: ${
          proceedingName || PREVIEW_PLACEHOLDER
        } została zawarta Umowa.`}</p>
      )}
      {euCoFinancing && (
        <>
          {coFinancingProjectsLoading ? (
            <Loader />
          ) : (
            <>
              {coFinancingProjects.map((co_project: CoFinancingProject) => (
                <div
                  className={classNames('preview-point', {
                    modified: euCoFinancingModified || coFinancingProjectsModified
                  })}
                  key={co_project.project_name}
                >
                  {co_project.co_financing_project_id
                    ? `Zamówienie jest współfinansowane ze środków Unii Europejskiej, w ramach Programu Operacyjnego${' '}
                      ${
                        coFinancingPrograms.find((project) => project.id === co_project.co_financing_project_id)
                          ?.name || ''
                      }
                      ${co_project.project_name && `, w ramach projektu o nazwie ${co_project.project_name}`}
                      ${co_project.task_name && `, zadania pn.: ${co_project.task_name}`}
                      ${co_project.agreement_nr && `, numer umowy dofinansowania: ${co_project.agreement_nr}`}.`
                    : `Zamówienie jest współfinansowane${
                        co_project.source_name?.length > 0 ? ` ze środków ${co_project.source_name}` : ''
                      } w ramach Programu ${co_project.program_name}
                      ${co_project.project_name && `, w ramach projektu o nazwie ${co_project.project_name}`}
                      ${co_project.task_name && `, zadania pn.: ${co_project.task_name}`}
                      ${co_project.agreement_nr && `, numer umowy dofinansowania: ${co_project.agreement_nr}`}.`}
                </div>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AgreementStepPreviewPreamble;
