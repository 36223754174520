import Table, { TableCol } from 'modules/Layout/component/Table/AccordionTable';
import React from 'react';
import { UserChangesValues } from 'modules/Profile/type';
import { formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';

type Props = {
  changes: UserChangesValues[];
};

const ChangesTable: React.FC<Props> = ({ changes }) => {
  const changesType = (type: number, slug: string) => {
    const slugName = slug === 'company' ? 'danych rozliczeniowych' : 'konta';

    switch (type) {
      case 1:
        return `Dodanie ${slugName}`;
      case 2:
        return `Edycja ${slugName}`;
      case 3:
        return `Usunięcie ${slugName}`;
      default:
        return '-';
    }
  };
  const cols: TableCol<UserChangesValues>[] = [
    {
      property: 'created_at',
      label: 'Data wprowadzenia zmian',
      value: (row) => ifContainsDateFormat(row?.created_at, formatDatetimeSeconds)
    },
    {
      property: 'type',
      label: 'Typ zmiany',
      value: (row) => changesType(row?.type, row?.model?.slug)
    },
    {
      property: 'user-name',
      label: 'Zmiany wprowadzone przez',
      value: (row) => row?.user?.name ?? '-'
    }
  ];

  const displayData = (changesRow: UserChangesValues) => {
    if (!changesRow?.data) return <div className="text-dark">Konto zostało usunięte.</div>;

    return (
      <div>
        <p className="h5">Wprowadzone zmiany:</p>
        <pre>
          <code>{JSON.stringify(changesRow.data, null, 2)}</code>
        </pre>
      </div>
    );
  };

  return <Table cols={cols} rows={changes} nestedTable={displayData} />;
};

export default ChangesTable;
