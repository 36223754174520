import { ClauseEntity } from 'modules/Clause/model/Clause';
import { PaginationMeta, SearchingProps } from 'modules/Shared/type';

export interface ClauseListState extends SearchingProps {
  list: ClauseEntity[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initClauseListState = (): ClauseListState => ({
  list: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
