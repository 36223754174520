import React, { useEffect, useState } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { Message, PartialSearchingProps } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { createJudgement, JudgementEntity } from 'modules/Judgement/model/Judgement';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import { push } from 'connected-react-router';
import { ROUTE_JUDGEMENT, ROUTE_JUDGEMENT_CLAUSE_ADD_LIST } from 'modules/Judgement/routes';
import { useLocation, useParams } from 'react-router-dom';
import { ClauseEntity } from 'modules/Clause/model/Clause';
import { getPathUrl } from 'modules/Shared/helper/api';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { Button } from 'reactstrap';
import JudgementAddConnectedClauses from 'modules/Judgement/component/Modal/AddConnectedClauses';
import { setClauseListParamsAction } from 'modules/Clause/action/ClauseList';
import { addJudgementConnectClauseToastSuccess } from 'modules/Judgement/toasts';
import { addJudgementConnectedClause, fetchJudgment } from 'modules/Judgement/repository';
import { breadcrumbRouteJudgementAddClauseList } from 'modules/Judgement/breadcrumbs';
import ConnectedClauseAddListFilter from 'modules/Judgement/container/ConnectedClauseAddList/Filter';
import ClauseListingPagination from 'modules/Clause/container/AdminClauseList/Pagination';
import ClausePerPage from 'modules/Clause/container/AdminClauseList/PerPage';
import ConnectedClauseAddListTable from 'modules/Judgement/container/ConnectedClauseAddList/Table';

const ConnectedClausesListView = () => {
  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [addModalLoading, setAddModalLoading] = useState(false);
  const [addModalMessage, setAddModalMessage] = useState<Message>(null);
  const [judgmentFetching, setJudgmentFetching] = useState(false);
  const [selectedClauseId, setSelectedClauseId] = useState<number>(null);
  const dispatch = useDispatch();
  const { fetching } = useSelector((state: RootState) => state.clause.clauseList);
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<{
    judgment?: JudgementEntity;
  }>();
  const [judgment, setJudgment] = useState<JudgementEntity>(null);

  const cancelToken = useCancelToken();

  const fetchJudgmentAction = async () => {
    setJudgmentFetching(true);
    const { data, cancelled, message: messageResponse } = await fetchRequest(fetchJudgment, id, cancelToken);

    if (cancelled || messageResponse || !data?.data) dispatch(push(`${getPathUrl(ROUTE_JUDGEMENT, { id })}?t=clause`));
    else {
      setJudgment(createJudgement(data.data));
      setJudgmentFetching(false);
    }
  };

  useEffect(() => {
    if (!judgment) {
      if (state?.judgment) setJudgment(state.judgment);
      else fetchJudgmentAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const setParams = (payload: PartialSearchingProps) => dispatch(setClauseListParamsAction(payload));

  useEffect(() => {
    if (judgment) {
      managePage({
        title: 'Wybierz klauzulę',
        breadcrumb: breadcrumbRouteJudgementAddClauseList(judgment.identifier, judgment.id)
      });

      setParams({ filter: { judgment_id: id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [judgment]);

  const dispatchAddJudgmentClausesToast = () => dispatch(addToastAction(addJudgementConnectClauseToastSuccess()));

  const addJudgementClauseAction = async (data: { clause_id: number; content?: string }) => {
    setAddModalLoading(true);
    const {
      cancelled,
      message: messageRes,
      success
    } = await deleteRequest(addJudgementConnectedClause, judgment.id, data, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchAddJudgmentClausesToast();
      setDisplayAddModal(false);
      dispatch(push(`${getPathUrl(ROUTE_JUDGEMENT, { id })}?t=clause`));
    } else {
      if (messageRes) setAddModalMessage(messageRes);
      setAddModalLoading(false);
    }
  };

  const onSelectedClauseClick = (clause: ClauseEntity = {}) => {
    const { id: clause_id } = clause;
    if (clause_id) {
      if (selectedClauseId && clause_id === selectedClauseId) setSelectedClauseId(null);
      else setSelectedClauseId(clause_id);
    }
  };

  return (
    <div className="row users-view">
      <div className="col-12">
        {displayAddModal && (
          <JudgementAddConnectedClauses
            isOpen={displayAddModal && !!selectedClauseId}
            toggle={() => setDisplayAddModal(false)}
            onSubmit={addJudgementClauseAction}
            message={addModalMessage}
            loading={addModalLoading}
            clearErrorsOnUnmount={() => setAddModalMessage(null)}
            title="Dodaj wybraną klauzulę"
            clauseId={selectedClauseId}
            judgment={judgment}
          />
        )}

        <Listing
          table={
            <ConnectedClauseAddListTable
              onSelectedClauseClick={onSelectedClauseClick}
              selectedClause={selectedClauseId}
            />
          }
          filter={<ConnectedClauseAddListFilter />}
          pagination={<ClauseListingPagination path={getPathUrl(ROUTE_JUDGEMENT_CLAUSE_ADD_LIST, { id })} />}
          perPage={<ClausePerPage />}
          loading={fetching || judgmentFetching}
          insertBetweenFilterTable={
            <div className="w-100 mt-3 d-flex justify-content-end">
              <Button
                color="primary"
                className={`pzpeu-btn-disabled waves-effect waves-light ml-auto${selectedClauseId ? '' : ' disabled'}`}
                onClick={() => selectedClauseId && setDisplayAddModal(true)}
              >
                Zapisz wybraną klauzulę
              </Button>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ConnectedClausesListView;
