import React from 'react';
import BaseModal from 'modules/Layout/component/Modal';
import { Button } from 'reactstrap';
import { PaymentHistoryValues } from 'modules/Profile/type';
import { Link } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_PROFILE_PAYMENT_LOG } from 'modules/Profile/routes';

export interface CancelPaymentModalProps {
  isOpen: boolean;
  payments: PaymentHistoryValues[];
  toggle: (confirmed?: boolean) => void;
  submit: () => void;
}

const CancelPaymentModal = (props: CancelPaymentModalProps) => {
  const { isOpen, payments, toggle, submit } = props;

  const renderBody = () => {
    return (
      <div>
        <div>Już masz rozpoczętą transakcje która zawieraja ten produkt.</div>
        <div className="mt-2 mb-2">
          {payments.map(({ payment_number }) => {
            const url = getPathUrl(ROUTE_PROFILE_PAYMENT_LOG, { id: payment_number });

            return (
              <div key={payment_number}>
                <Link className="p-0" to={url} target="_blank">
                  {payment_number},
                </Link>
              </div>
            );
          })}
        </div>
        <div>Czy na pewno chcesz anulować te transakcje i przejść do nowej?</div>
      </div>
    );
  };

  return (
    <BaseModal
      title="Wykryto niesfinalizowaną transakcje"
      isOpen={isOpen}
      toggle={toggle}
      onClosed={toggle}
      body={renderBody()}
      footer={
        <div className="modal-actions">
          <Button type="button" color="secondary" className="waves-effect waves-light" onClick={() => toggle()}>
            Nie
          </Button>
          <Button type="button" color="danger" className="pzpeu-btn-disabled waves-effect waves-light" onClick={submit}>
            Tak
          </Button>
        </div>
      }
    />
  );
};

export default CancelPaymentModal;
