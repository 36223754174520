import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { FormFeedback, FormGroup } from 'reactstrap';

export interface Props {
  displayWysiwyg: () => JSX.Element;
  errors?: ValidationErrors;
}

const JudgementFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { displayWysiwyg, errors } = props;

  return (
    <fieldset className="m-0">
      <FormGroup>
        {displayWysiwyg && displayWysiwyg()}

        {hasError(errors, 'content') && <FormFeedback className="d-block">{getError(errors, 'content')}</FormFeedback>}
      </FormGroup>
    </fieldset>
  );
};

export default JudgementFieldset;
