import { addToastAction, managePageAction } from 'modules/Layout/action';
import Loader from 'modules/Layout/component/Loader';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Alert from 'modules/Layout/component/Alert';
import { Message, ValidationErrors } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { push } from 'connected-react-router';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import updateRequest from 'modules/Shared/helper/APIRequests/updateRequest';
import CardBox from 'modules/Layout/component/CardBox';
import { breadcrumbRouteForeignProceedingsAlertUpdate } from 'modules/ForeignProceedings/breadcrumbs';
import AlertForm, { AlertFormData } from '../../../component/Form/AlertForm';
import { fetchAlerts, fetchForeignProceedings } from '../../../repository';
import AlertClient from '../../../model/Alert';
import { updateForeignProceedingsAlertToastSuccess } from '../../../toasts';
import { ROUTE_FOREIGN_PROCEEDINGS_ALERTS } from '../../../routes';
import { saveFilter } from '../../../../Layout/repository';
import { FiltersEntity } from '../../../../Layout/model/Filters';
import ForeignProceedings from '../../../model/ForeignProceedings';
import ForeignProceedingsTable from '../../../container/ForeignProceedingsList/Table';

export interface Props {
  params?: any;
}

const ForeignProceedingsAlertUpdateView: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<{ alert: AlertClient }>();
  const [alert, setAlert] = useState<AlertFormData>(null);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [foreignProceedingsList, setForeignProceedingsList] = useState<ForeignProceedings[]>(null);
  const [fetchingList, setFetchingList] = useState(false);

  const scrollToTopOnce = useRef<boolean>(true);

  const dispatchUpdateForeignProceedingsToast = () =>
    dispatch(addToastAction(updateForeignProceedingsAlertToastSuccess()));

  const cancelToken = useCancelToken();
  useEffect(() => {
    if (state?.alert) {
      setAlert(alert);
    } else {
      (async () => {
        setFetching(true);
        const { data: reqData, cancelled, message: messageResponse } = await fetchRequest(fetchAlerts, cancelToken);
        const alertToUpdate = reqData.data.find((alertData) => alertData.id === Number(id));

        if (cancelled) return;
        if (alertToUpdate) {
          setAlert(alertToUpdate);
        }
        if (messageResponse) setMessage(messageResponse);
        setFetching(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProceedings = useCallback(async () => {
    if (!alert?.filters) return;

    setFetchingList(true);

    const { slug_source, ...otherFilters } = alert?.filters;

    const filtersAsParams = {
      ...otherFilters,
      industry_type_id: alert.filters.industry_sub_type_id
        ? alert.filters.industry_sub_type_id
        : alert.filters.industry_type_id,
      ...(Array.isArray(slug_source)
        ? slug_source.reduce((acc, slug, index) => {
            acc[`slug_source[${index}]`] = String(slug);
            return acc;
          }, {} as Record<string, string>)
        : {})
    };

    const {
      data: reqData,
      cancelled,
      message: messageResponse
    } = await fetchRequest(fetchForeignProceedings, filtersAsParams, cancelToken);

    if (cancelled) return;
    if (reqData) setForeignProceedingsList(reqData.data);
    if (messageResponse) setMessage(messageResponse);

    setFetchingList(false);
  }, [alert?.filters, cancelToken]);

  useEffect(() => {
    // Call fetchProceedings directly in useEffect
    fetchProceedings().then((r) => r);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching]);

  useEffect(() => {
    if (alert) {
      const title = `Edycja alertu postępowania ${alert.name ? ` - ${alert.name}` : ''}`;
      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteForeignProceedingsAlertUpdate(alert.name, alert.id)
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert]);

  const updateForeignProceedingsAlertAction = async (data: FiltersEntity, name: string) => {
    if (loading) return;

    if (errors) setErrors(null);
    setLoading(true);
    const {
      cancelled,
      message: messageRes,
      success,
      error
    } = await updateRequest(saveFilter, { filters: data, name, slug: 'foreign_proceedings_alerts' }, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchUpdateForeignProceedingsToast();
      dispatch(push(ROUTE_FOREIGN_PROCEEDINGS_ALERTS));
    } else {
      if (messageRes) setMessage(messageRes);
      if (error) setErrors(error);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  if (fetching) return <Loader />;
  if (!fetching && !alert && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );

  if (!fetching && alert) {
    return (
      <>
        <div className="profile-update-view d-flex justify-content-center">
          <CardBox className="w-100" heading="Edycja alertu postępowania">
            {loading && <Loader />}
            <Alert message={message} />
            {alert && (
              <AlertForm
                alertData={alert}
                errors={errors}
                fetchForeignProceedings={fetchProceedings}
                submit={updateForeignProceedingsAlertAction}
                disabled={loading}
                setValues={setAlert}
                cancelRedirect={ROUTE_FOREIGN_PROCEEDINGS_ALERTS}
                isEditing
              />
            )}
          </CardBox>
        </div>
        {fetchingList ? (
          <Loader />
        ) : (
          <ForeignProceedingsTable foreignProceedingsList={foreignProceedingsList} isAdmin={false} />
        )}
      </>
    );
  }

  return null;
};

export default ForeignProceedingsAlertUpdateView;
