import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
import { ROUTE_PROCEEDINGS_PLANNING_FAV } from 'modules/Proceedings/routes';
import { breadcrumbRouteProceedingsFav } from 'modules/Proceedings/breadcrumbs';
import useFavouriteProceedingsList from 'modules/Proceedings/component/Lists/FavouriteListHooks';
import { PROCEEDINGS_INIT_TYPES_VALUES } from 'modules/Proceedings/type';

export const ProceedingsFavouritesPlanningListView = () => {
  const dispatch = useDispatch();

  const { jsxFnc } = useFavouriteProceedingsList(
    ROUTE_PROCEEDINGS_PLANNING_FAV,
    PROCEEDINGS_INIT_TYPES_VALUES.PLANNING
  );

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  useEffect(() => {
    managePage({
      title: 'Ulubione planowane postępowania - lista',
      breadcrumb: breadcrumbRouteProceedingsFav(PROCEEDINGS_INIT_TYPES_VALUES.PLANNING)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return jsxFnc();
};

export const ProceedingsFavouritesOpenListView = () => {
  const dispatch = useDispatch();

  const { jsxFnc } = useFavouriteProceedingsList(ROUTE_PROCEEDINGS_PLANNING_FAV, PROCEEDINGS_INIT_TYPES_VALUES.OPEN);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  useEffect(() => {
    managePage({
      title: 'Ulubione prowadzone postępowania - lista',
      breadcrumb: breadcrumbRouteProceedingsFav(PROCEEDINGS_INIT_TYPES_VALUES.OPEN)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return jsxFnc();
};
