import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';

import { ExecutorCRBR } from 'modules/Executor/model/ExecutorClient';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';

const ExecutorCRBRTable = ({ crbr }: { crbr: ExecutorCRBR[] }) => {
  const cols: TableCol<ExecutorCRBR>[] = [
    {
      property: 'lastname',
      label: 'Imię',
      value: (row) => row.lastname || 'nie dotyczy'
    },
    {
      property: 'firstname',
      label: 'Nazwisko',
      value: (row) => row.firstname || 'nie dotyczy'
    },
    {
      property: 'birth_date',
      label: 'Data urodzenia',
      value: (row) => ifContainsDateFormat(row?.birth_date, formatDate)
    }
  ];

  return <Table cols={cols} rows={crbr} />;
};

export default ExecutorCRBRTable;
