import React from 'react';
import CardBox from 'modules/Layout/component/CardBox';
import Clause from 'modules/Clause/model/Clause';
import ClauseDetailsConnectedJudgements from 'modules/ClauseClient/component/Details/ConnectedJudgements';
import InformationList from 'modules/Layout/component/InformationList';
import ClauseClientBasicDataset from 'modules/ClauseClient/component/Dataset';

type Props = {
  clause: Clause;
};

const ClauseBasicDetails: React.FC<Props> = ({ clause }) => {
  return (
    <>
      <div className="col-12">
        <CardBox heading="Szczegóły klauzuli" className="judgement-basic-details" paddingRight="30px" paddingBottom="0">
          <ClauseClientBasicDataset element={clause} />
        </CardBox>
        <CardBox
          heading="Wyjaśnienie klauzuli"
          paddingRight="30px"
          paddingBottom="0"
          className="judgement-basic-details"
        >
          <InformationList
            fields={[
              {
                label: ' ',
                value: clause?.description,
                html: true,
                htmlFieldInsertedStyle: { maxHeight: '700px', overflowY: 'auto' }
              }
            ]}
          />
        </CardBox>
      </div>
      <ClauseDetailsConnectedJudgements clause={clause} />
    </>
  );
};

export default ClauseBasicDetails;
