import React, { Dispatch, SetStateAction } from 'react';
import { CustomInput, FormGroup, FormFeedback } from 'reactstrap';
import { ValidationErrors } from 'modules/Shared/type';
import { getError, hasError } from 'modules/Shared/helper/validation';

export interface StepFieldPartCheckboxProps<T> {
  checkbox: string;
  label: string;
  index: number;
  part: Record<any, any>;
  setStepValues: Dispatch<SetStateAction<T>>;
  mapState: (values: T) => T;
  errors?: ValidationErrors | null;
  disabled: boolean;
  partSlug?: string;
}

const StepFieldPartCheckbox = <T,>(props: StepFieldPartCheckboxProps<T>): JSX.Element => {
  const { checkbox, label, index, part, setStepValues, mapState, errors, disabled = false, partSlug } = props;

  return (
    <FormGroup key={`${partSlug}.${index}.${checkbox}`}>
      <CustomInput
        id={`${partSlug}.${index}.${checkbox}`}
        type="checkbox"
        // @ts-ignore
        checked={part[checkbox]}
        label={<div dangerouslySetInnerHTML={{ __html: String(label) }} />}
        onChange={(event) => {
          // @ts-ignore
          part[checkbox] = event.target.checked;
          // @ts-ignore
          setStepValues((values: T) => mapState(values));
        }}
        invalid={hasError(errors, `${partSlug}.${index}.${checkbox}`)}
        disabled={disabled}
      />
      {hasError(errors, `${partSlug}.${index}.${checkbox}`) && (
        <FormFeedback className="d-block">{getError(errors, `${partSlug}.${index}.${checkbox}`)}</FormFeedback>
      )}
    </FormGroup>
  );
};

export default StepFieldPartCheckbox;
