import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import { setUserListParamsAction } from 'modules/User/action/list';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterInputType } from 'modules/Layout/type';
import { formatDatetimeLocalForInput } from 'modules/Shared/helper/utils';

export interface Props {
  values?: SearchParams;
  isAdminList?: boolean;
}

const UserFilter: React.FC<Props> = ({ values = {}, isAdminList }) => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.user.list);
  const setParams = (payload: PartialSearchingProps) => dispatch(setUserListParamsAction(payload));

  let staticInputsBase: FilterInputType[] = [
    {
      type: 'text',
      property: 'first_name',
      label: 'Imię',
      value: _get(values, 'first_name')
    },
    {
      type: 'text',
      property: 'last_name',
      label: 'Nazwisko',
      value: _get(values, 'last_name')
    },
    {
      type: 'text',
      property: 'email',
      label: 'Email',
      value: _get(values, 'email')
    }
  ];

  if (!isAdminList) {
    staticInputsBase = [
      ...staticInputsBase,
      {
        type: 'text',
        property: 'name',
        label: 'Firma',
        value: _get(values, 'name')
      }
    ];
  }

  const staticCheckboxBase: FilterInputType[] = [
    {
      type: 'checkbox',
      property: 'never_logged',
      label: 'Nigdy nie logowano',
      value: _get(values, 'never_logged')
    }
  ];

  const inputsTime = (): FilterInputType[] => {
    let dynamicInputs: FilterInputType[] = [];

    const neverLogged = _get(filter, 'never_logged');

    if (neverLogged !== '1') {
      const lastLoginAtFrom = _get(filter, 'last_login_at_from') as string;
      const lastLoginAtTo = _get(filter, 'last_login_at_to') as string;

      dynamicInputs = [
        {
          beforeLineBreak: true,
          type: 'datetime',
          property: 'last_login_at_from',
          label: 'Ostatnie logowanie od',
          value: lastLoginAtFrom ? formatDatetimeLocalForInput(lastLoginAtFrom) : lastLoginAtFrom,
          max: String(lastLoginAtTo)
        },
        {
          type: 'datetime',
          property: 'last_login_at_to',
          label: 'Ostatnie logowanie do',
          value: lastLoginAtTo ? formatDatetimeLocalForInput(lastLoginAtTo) : lastLoginAtTo,
          min: String(lastLoginAtFrom)
        }
      ];
    }

    return dynamicInputs;
  };

  const staticInputs = [...staticInputsBase, ...staticCheckboxBase, ...inputsTime()];

  return <ListingFilter inputs={staticInputs} filter={filter} pagination={pagination} setParams={setParams} />;
};

export default UserFilter;
