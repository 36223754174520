/* eslint-disable */
import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function RestoreIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px', className = 'icon-svg-table', strokeWidth = '1' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} className={className} viewBox="0 0 19.717 17.705">
      <g id="Group_450" data-name="Group 450" transform="translate(-287.276 -1202.465)">
        <path
          id="Path_1330"
          data-name="Path 1330"
          d="M287.978,1209.636l1.823,2.271,2.272-1.823"
          transform="translate(0 -0.517)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
        <path
          id="Path_1331"
          data-name="Path 1331"
          d="M298.294,1219.67a8.352,8.352,0,1,0-8.339-8.28"
          transform="translate(-0.153)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  );
}
