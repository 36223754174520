import { Routes } from 'app/routes';
import React from 'react';
import { Route } from 'react-router-dom';
import Regulations from 'modules/Regulation/view';

export const ROUTE_REGULATION = '/regulation';

export const createRegulationRoutes = (): Routes => ({
  public: [
    <Route key="regulation" path={ROUTE_REGULATION} exact>
      <Regulations />
    </Route>
  ]
});
