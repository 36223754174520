import Filter, { IFilterObj } from 'modules/Layout/component/Filter';
import { FilterInputType } from 'modules/Layout/type';
import { PaginationParams, PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import React, { ReactNode } from 'react';

export interface ListingFilterProps {
  inputs: FilterInputType[];
  filter: SearchParams;
  pagination?: PaginationParams;
  setParams: (params: PartialSearchingProps) => void;
  initFilterOpen?: boolean;
  saveFilterBtn?: () => void;
  disableBtn?: boolean;
  filterObj?: IFilterObj;
  searchButtonObj?: { onClick: () => void; disabled?: boolean; label?: string };
  customInput?: JSX.Element;
  filterName?: string;
  filtersName?: string;
}

class ListingFilter extends React.Component<ListingFilterProps> {
  constructor(props: ListingFilterProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(property: string, value: string | (string | number)[]): void {
    const { filter, pagination, setParams, filtersName } = this.props;

    const params: PartialSearchingProps = {
      filter: { ...filter }
    };

    if (value) {
      params.filter[property] = value;
    } else {
      delete params.filter[property];
    }

    if (pagination?.per_page) {
      params.pagination = { per_page: pagination.per_page };
    }

    // Save the input value to localStorage
    localStorage.setItem(`localFilterParams${filtersName}`, JSON.stringify(params));
    const localStorageObject: PartialSearchingProps = JSON.parse(
      localStorage.getItem(`localFilterParams${filtersName}`) || '{}'
    );

    if (!localStorageObject.filter) {
      setParams(params);
    } else {
      setParams(localStorageObject);
    }
  }

  render(): ReactNode {
    const {
      inputs,
      initFilterOpen,
      saveFilterBtn,
      disableBtn,
      filterObj,
      searchButtonObj,
      filtersName,
      customInput,
      filterName
    } = this.props;

    const localStorageObject: PartialSearchingProps = JSON.parse(
      localStorage.getItem(`localFilterParams${filtersName}`) || '{}'
    );

    return (
      <Filter
        inputs={inputs}
        initFilterOpen={initFilterOpen}
        onChange={this.onChange}
        saveFilterBtn={saveFilterBtn}
        localStorageObject={localStorageObject}
        disableBtn={disableBtn}
        filterObj={filterObj}
        searchButtonObj={searchButtonObj}
        customInput={customInput}
        filterName={filterName}
      />
    );
  }
}

export default ListingFilter;
