import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setProceedingsListParamsAction } from 'modules/Proceedings/action/ProceedingsList';
import useFilterValues from './FilterValues/useFilterValues';
import useSaveFilter from '../../../../Shared/helper/hooks/useSaveFilter';
import { FiltersEntity, PROCEEDINGS_SLUG_FILTER } from '../../../../Layout/model/Filters';
import SaveFilterModal from '../../../../Layout/component/ListFilter/SaveFilterModal';

export interface Props {
  proceedingsType: number;
  values?: SearchParams;
}

const ProceedingsFilter: React.FC<Props> = ({ proceedingsType, values = {} }) => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.proceedings.proceedingsList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setProceedingsListParamsAction(payload));
  const onSavedFilterClick = async (saveFilter: FiltersEntity) => {
    setParams({ filter: saveFilter });
  };
  const [filterParams, setFilterParams] = useState<SearchParams>({});

  const {
    saveModal,
    setSaveModal,
    cleanMessage,
    loading,
    message,
    filtersList,
    saveFilter,
    onDeleteFilterClick,
    onFilterSelectedClick: onFilterSelectedClickFn
  } = useSaveFilter(filter, setParams, PROCEEDINGS_SLUG_FILTER, ['keywords'], {
    onFilterSelectedClickCustomFnc: onSavedFilterClick
  });

  const { inputs } = useFilterValues({ proceedingsType, values });

  const filteredFilterList = filtersList.filter(
    (el) => !el.filters.only_saved && el.filters.proceeding_type === proceedingsType
  );

  const onSearchButtonClicked = () => setParams({ filter: filterParams });

  useEffect(() => {
    const localStorageParams = localStorage.getItem('localFilterParamsproceedings');

    if (localStorageParams) {
      const parsedParams = JSON.parse(localStorageParams);

      setParams({ ...parsedParams });
    } else {
      setParams({ ...filterParams });
    }
  }, []);

  const setMessage = (value: boolean) => {
    if (message) cleanMessage();
    setSaveModal(value);
  };

  return (
    <div className="position-relative">
      <ListingFilter
        inputs={inputs}
        filter={filter}
        filtersName="proceedings"
        pagination={pagination}
        setParams={(params) => setFilterParams(params.filter)}
        saveFilterBtn={() => setMessage(true)}
        filterObj={{
          filtersList: filteredFilterList,
          onDeleteFilterClick,
          onFilterSelectedClick: onFilterSelectedClickFn,
          loading
        }}
        filterName="Wyszukiwanie"
        searchButtonObj={{ onClick: onSearchButtonClicked }}
      />
      {saveModal && (
        <SaveFilterModal
          onSaveClick={saveFilter}
          isOpen={saveModal}
          toggle={() => setMessage(false)}
          message={message}
          loading={loading}
          displayDefaultCheckbox
          shouldRefetchList
        />
      )}
    </div>
  );
};

export default ProceedingsFilter;
