import React, { useState, useEffect } from 'react';
import { RootState } from 'app/reducer';
import { logoutAction } from 'modules/Auth/action';
import { managePageAction } from 'modules/Layout/action';
import CardBox from 'modules/Layout/component/CardBox';
import { PageProps } from 'modules/Layout/type';
import { breadcrumbRouteProfile } from 'modules/Profile/breadcrumbs';
import { Link } from 'react-router-dom';
import {
  ROUTE_PROFILE_PASSWORD_UPDATE,
  ROUTE_PROFILE_RODO,
  ROUTE_PROFILE_UPDATE,
  ROUTE_PROFILE_UPDATE_COMPANY
} from 'modules/Profile/routes';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import BasicDataset from 'modules/User/component/Dataset/Basic';
import CompanyDataset from 'modules/User/component/Dataset/Company';
import { PAYMENY_HISTORY_OWN, UPDATE_OWN_COMPANY } from 'modules/User/permissions';
import { useDispatch, useSelector } from 'react-redux';
import DetailsModules from 'modules/Module/component/ListDetails';
import { setProfileStateAction } from 'modules/Profile/action';
import { CLIENT_ROLES } from 'modules/User/model/UserRole';
import DetailsPaymentLogs from 'modules/Profile/component/PaymentLogs';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import { deleteOwnAccount } from 'modules/Profile/repository';
import RodoDetailsWrapper from 'modules/Regulation/container/RodoDetailsWrapper';
import './index.scss';
import actionDeleteButtonDetails from 'modules/Shared/helper/actionDeleteButtonDetails';

const Profile = () => {
  const dispatch = useDispatch();
  const { message } = useSelector((state: RootState) => state.profile);
  const { user } = useSelector((state: RootState) => state.auth);
  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const [deleteMessage, setDeleteMessage] = useState<Message>(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    managePage({
      title: 'Profil',
      breadcrumb: breadcrumbRouteProfile()
    });

    return () => {
      dispatch(setProfileStateAction({ message: null }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelToken = useCancelToken();
  const deleteUserAction = async () => {
    if (loading) return;

    setLoading(true);
    const { cancelled, message: messageResponse, success } = await deleteRequest(deleteOwnAccount, cancelToken);

    if (cancelled) return;
    if (success) {
      if (success) dispatch(logoutAction());
    } else {
      if (messageResponse) setDeleteMessage(messageResponse);
      setLoading(false);
    }
  };

  const shouldDisplay = user?.hasPermissions([UPDATE_OWN_COMPANY]);
  const isClient = CLIENT_ROLES.includes(user?.role?.slug);

  return (
    <div className="profile-view row position-relative">
      {displayModal && (
        <GenericModalDelete
          value={user}
          title="Usuwanie konta"
          content={() => <div>Czy na pewno chcesz usunąć własne konto?</div>}
          onDeleteClick={() => deleteUserAction()}
          isOpen={displayModal}
          toggle={() => setDisplayModal(false)}
          loading={loading}
          message={deleteMessage}
          resetMessage={() => setDeleteMessage(null)}
        />
      )}
      <div className="col-lg-6 user-data">
        <CardBox
          heading="Dane użytkownika"
          paddingBottom="0"
          paddingLeft="30px"
          paddingRight="30px"
          actions={
            <>
              <Link className="link-with-border" to={ROUTE_PROFILE_UPDATE}>
                EDYTUJ DANE UŻYTKOWNIKA
              </Link>
              <Link className="link-with-border" to={ROUTE_PROFILE_PASSWORD_UPDATE}>
                ZMIEŃ HASŁO
              </Link>
              {actionDeleteButtonDetails(user, {
                value: 'USUŃ UŻYTKOWNIKA',
                onDeleteClick: () => setDisplayModal(true),
                tooltipContent: 'Nie możesz usunąć ostatniego administratora w systemie.',
                targetIdName: 'DeleteUserProfileId'
              })}
            </>
          }
          message={message}
        >
          <div className="card-box-information-list">
            <BasicDataset user={user} hideRole />
          </div>
        </CardBox>

        {isClient && <RodoDetailsWrapper redirectUrl={ROUTE_PROFILE_RODO} user={user} />}
      </div>

      {shouldDisplay && (
        <div className="col-lg-6 user-company-data">
          <CardBox
            heading="Dane rozliczeniowe"
            paddingBottom="0"
            paddingLeft="30px"
            paddingRight="30px"
            actions={
              <Link className="link-with-border" to={ROUTE_PROFILE_UPDATE_COMPANY}>
                EDYTUJ DANE ROZLICZENIOWE
              </Link>
            }
          >
            {user?.company?.client_type ? (
              <div className="card-box-information-list">
                <CompanyDataset user={user} />
              </div>
            ) : (
              'Brak danych rozliczeniowych - prosimy o uzupełnienie danych przed zakupem dostępów do poszczególnych modułów systemu.'
            )}
          </CardBox>
        </div>
      )}

      {isClient && (
        <>
          <div className="col-12">
            <DetailsModules user={user} />
          </div>

          {user?.hasPermissions([PAYMENY_HISTORY_OWN]) && (
            <div className="col-12 payment-logs">
              <DetailsPaymentLogs user={user} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Profile;
