import React, { useState } from 'react';
import { CustomInput, FormFeedback, Label } from 'reactstrap';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import ActionAccept from 'modules/Layout/component/Action/Accept';
import useWysiwyg from 'modules/Layout/component/Wysiwyg';
import { ColorVariants } from 'modules/Shared/type';

export interface Props {
  // eslint-disable-next-line react/require-default-props
  id?: string;
  // eslint-disable-next-line react/require-default-props
  checked?: boolean;
  content: string | null;
  // eslint-disable-next-line react/require-default-props
  fallbackTitle?: string;
  onChange: (checked: boolean, content: string | null) => void;
  // eslint-disable-next-line react/require-default-props
  onDelete?: () => void;
  // eslint-disable-next-line react/require-default-props
  onRevert?: () => void;
  // eslint-disable-next-line react/require-default-props
  error?: string | null;
  // eslint-disable-next-line react/require-default-props
  isEdit?: boolean;
  // eslint-disable-next-line react/require-default-props
  hideCheckbox?: boolean;
  hideDelete?: boolean;
  hideRevert?: boolean;
  disableCheckbox?: boolean;
  disableEdit?: boolean;
}

const CheckboxInput = (props: Props): JSX.Element => {
  const {
    id = 'checkbox',
    checked = false,
    content,
    onChange,
    error,
    fallbackTitle = '',
    onDelete = null,
    onRevert = null,
    isEdit = false,
    hideCheckbox = false,
    hideDelete = false,
    hideRevert = false,
    disableCheckbox = false,
    disableEdit = false
  } = props;
  const [edit, setEdit] = useState(isEdit);

  const { displayWysiwyg, getContent } = useWysiwyg({
    title: null,
    content: content || ''
  });

  // @ts-ignore
  const formattedContent = content?.replaceAll('....', `<span style='background-color:#d4d4d4'>....</span>`);

  return (
    <>
      <div className="d-flex align-items-start">
        {hideCheckbox ? (
          <Label className="pl-4" style={{ color: Boolean(error) && '#B7332D' }}>
            {edit
              ? ''
              : (
                  <div
                    className="information-list-html"
                    dangerouslySetInnerHTML={{ __html: String(formattedContent) }}
                  />
                ) || fallbackTitle}
          </Label>
        ) : (
          <CustomInput
            id={`${id}_checked`}
            name={`${id}_checked`}
            type="checkbox"
            disabled={disableCheckbox}
            checked={checked}
            label={
              edit
                ? ''
                : (
                    <div
                      className="information-list-html"
                      style={{ color: Boolean(error) && '#B7332D' }}
                      dangerouslySetInnerHTML={{ __html: String(formattedContent) }}
                    />
                  ) || fallbackTitle
            }
            invalid={Boolean(error)}
            onChange={(event) => onChange(event.target.checked, getContent())}
          />
        )}
        {edit && displayWysiwyg()}
        <div className="mx-1">
          {edit ? (
            <div className="d-flex align-items-center mt-1">
              <ActionAccept
                title="Zapisz"
                label={<i className="fa fa-check font-18" />}
                className="mr-1"
                onClick={() => {
                  setEdit(false);
                  onChange(checked, getContent());
                }}
              />
              {!hideRevert && (
                <ActionUpdate
                  title="Przywróć domyślną wartość"
                  label={<i className="fa fa-redo fa-fw font-15" />}
                  color={ColorVariants.Warning}
                  className="mr-1"
                  onClick={() => {
                    setEdit(false);
                    onRevert();
                  }}
                />
              )}
              <ActionDelete
                title="Zamknij edycje"
                label={<i className="fa fa-times font-18" />}
                color={ColorVariants.Secondary}
                onClick={() => {
                  setEdit(false);
                }}
              />
            </div>
          ) : (
            !disableEdit && <ActionUpdate title="Edytuj" onClick={() => setEdit(true)} />
          )}
        </div>
        {!edit && !hideDelete && (
          <div className="mx-1">
            <ActionDelete title="Usuń" label={<i className="fa fa-times font-22" />} onClick={onDelete} />
          </div>
        )}
      </div>
      {Boolean(error) && <FormFeedback className="d-block">{error}</FormFeedback>}
    </>
  );
};

export default CheckboxInput;
