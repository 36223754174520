import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import JudgementElement from 'modules/Judgement/model/JudgementElement';
// import { ROLE_ADMIN } from 'modules/User/model/UserRole';

export type Props = {
  element?: JudgementElement;
};

const ElementBasicDataset: React.FC<Props> = ({ element }) => {
  const fields = [
    {
      label: 'Nazwa',
      value: element?.name
    }
  ];

  return <InformationList fields={fields} />;
};

export default ElementBasicDataset;
