import React, { useState, useEffect } from 'react';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { PageProps } from 'modules/Layout/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message, ValidationErrors } from 'modules/Shared/type';
import { useDispatch } from 'react-redux';
import { breadcrumbFeedback } from 'modules/Feedback/breadcrumbs';
import Form from 'modules/Feedback/component/Form';
import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import { sendFeedbackToastSuccess } from 'modules/Feedback/toasts';
import { Button, Form as FormStrap } from 'reactstrap';
import { serialize } from 'object-to-formdata';
import CardBox from 'modules/Layout/component/CardBox';
import ContactDetails from 'modules/Feedback/component/Details/ContactDetails';
import {
  ROUTE_FEEDBACK,
  ROUTE_FEEDBACK_AFTER,
  ROUTE_PUBLIC_FEEDBACK,
  ROUTE_PUBLIC_FEEDBACK_AFTER
} from 'modules/Feedback/routes';
import { push } from 'connected-react-router';
import { fetchFeedbackCategories, sendFeedback } from 'modules/Feedback/repository';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import updateRequest from 'modules/Shared/helper/APIRequests/updateRequest';
import { useRouteMatch } from 'react-router';
import { hasToken } from 'modules/Auth/service';
import useRecaptcha from 'modules/Shared/helper/hooks/useRecaptcha';
import useWysiwyg from 'modules/Layout/component/Wysiwyg';
import { hasError } from 'modules/Shared/helper/validation';

export type FeedbackValues = {
  category_id: number;
  title: string;
  content?: any;
  files: { file: File; id: string }[];
};

export type FeedbackPublicValues = {
  email: string;
  agreement: boolean;
};

export type Props = {
  isPublic?: boolean;
};

const Feedback: React.FC<Props> = ({ isPublic }) => {
  const dispatch = useDispatch();
  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const [message, setMessage] = useState<Message>(null);
  const [categories, setCategories] = useState<{ id: number; name: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [values, setValues] = useState<FeedbackValues>({
    category_id: 1,
    title: '',
    files: []
  });
  const [publicValues, setPublicValues] = useState<FeedbackPublicValues>({ email: '', agreement: false });
  // const [hiddenInput, setHiddenInput] = useState(true);
  const [feedbackAccepted, setFeedbackAccepted] = useState(false);
  const match = useRouteMatch();

  const captchaEnable = process.env.RECAPTCHA_ENABLE === (isPublic && 'true');
  const { recaptchaValue, repatchaJsx } = useRecaptcha(captchaEnable);

  if (hasToken() && match?.path === ROUTE_PUBLIC_FEEDBACK) {
    dispatch(push(ROUTE_FEEDBACK));
  }

  const { displayWysiwyg, getContent } = useWysiwyg({
    title: 'Treść',
    isInvalid: hasError(errors, 'content'),
    required: true
  });

  useEffect(() => {
    if (feedbackAccepted) setFeedbackAccepted(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const cancelToken = useCancelToken();
  const getFeedbackCategories = async () => {
    setLoading(true);
    const { cancelled, message: messageResponse, data } = await fetchRequest(fetchFeedbackCategories, cancelToken);

    if (cancelled) return;
    if (data) {
      setCategories(data.data);
      setLoading(false);
    } else {
      if (messageResponse) setMessage(messageResponse);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isPublic) {
      managePage({
        title: 'Kontakt',
        breadcrumb: [breadcrumbFeedback()]
      });
    }

    getFeedbackCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const wysiwygText = values?.content?.blocks?.[0]?.text;
  // useEffect(() => {
  //   if (hiddenInput && wysiwygText) setHiddenInput(false);
  //   if (!hiddenInput && !wysiwygText) setHiddenInput(true);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [wysiwygText]);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (loading) return;
    setLoading(true);

    const { content, files, ...restValues } = values;

    const obj = isPublic
      ? {
          ...restValues,
          email: publicValues.email,
          content: getContent(),
          files: files.map((file) => file.file),
          ...recaptchaValue
        }
      : { ...restValues, content: getContent(), files: files.map((file) => file.file) };

    const data = serialize(obj);

    const {
      cancelled,
      message: messageResponse,
      success,
      error
    } = await updateRequest(sendFeedback, data, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatch(addToastAction(sendFeedbackToastSuccess()));
      setLoading(false);
      setFeedbackAccepted(true);
      dispatch(push(isPublic ? ROUTE_PUBLIC_FEEDBACK_AFTER : ROUTE_FEEDBACK_AFTER));
    } else {
      if (messageResponse) setMessage(messageResponse);
      setLoading(false);
      if (error) {
        setErrors(error);
      }
    }
  };

  return (
    <div className={`contact-view row justify-content-center ${isPublic ? 'pl-md-5 pr-md-5' : ''}`}>
      <div className="col-lg-6 card-lg-wrapper-left">
        <ContactDetails />
      </div>
      <div className="col-lg-6 card-lg-wrapper-right">
        <CardBox
          paddingRight="30px"
          paddingLeft="30px"
          paddingBottom="30px"
          childrenPaddingTop="47px"
          heading="Formularz kontaktowy"
        >
          {loading && <Loader />}
          {/*{message && <Alert message={message} />}*/}
          <Alert message={message} />
          {repatchaJsx()}
          <FormStrap onSubmit={onSubmit}>
            <Form
              values={values}
              categories={categories?.filter((category) => category?.name !== 'Wyszukiwarka')}
              setValues={setValues}
              publicValues={publicValues}
              setPublicValues={setPublicValues}
              // hiddenInput={hiddenInput}
              isPublic={isPublic}
              displayWysiwyg={displayWysiwyg}
              errors={errors}
              required
              filesChildren={
                <Button type="submit" color="primary" className="mt-2 pzpeu-btn-medium-flex waves-effect waves-light">
                  Wyślij
                </Button>
              }
            />
          </FormStrap>
        </CardBox>
      </div>
    </div>
  );
};

export default Feedback;
