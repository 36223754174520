import React, { useEffect, useRef, useState } from 'react';
import {
  AGREEMENT_TYPE_OUTSIDE_ORDER,
  AGREEMENT_TYPE_SUPPLEMENT,
  AgreementCheckboxes,
  AgreementStepProps
} from 'modules/Agreements/type';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_FINAL_PROVISIONS } from 'modules/Agreements/step';
import { FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import useStepFields from 'modules/Agreements/hook/useStepFields';

export interface AgreementStepFinalProvisionsValues {
  case_possibility: boolean;
  case_order_law_checked: boolean;
  case_order_law_content: string | null;
  case_build_law_checked: boolean;
  case_build_law_content: string | null;
  case_civil_code_checked: boolean;
  case_civil_code_content: string | null;
  case_other_checked: boolean;
  case_other_content: string | null;
  invalid_provisions_checked: boolean;
  invalid_provisions_content: string | null;
  replaced_provisions_checked: boolean;
  replaced_provisions_content: string | null;
  annexes_checked: boolean;
  annexes_content: string | null;
  applicable_law_checked: boolean;
  applicable_law_content: string | null;
  jurisdiction_checked: boolean;
  jurisdiction_content: string | null;
  any_changes_checked: boolean;
  any_changes_content: string | null;
  identical_copies_checked: boolean;
  identical_copies_content: string | null;
  checkboxes: AgreementCheckboxes | null;
}

const CASE_ORDER_LAW_CONTENT = 'Prawa zamówień publicznych';
const CASE_BUILD_LAW_CONTENT = 'Prawa budowlanego';
const CASE_CIVIL_CODE_CONTENT = 'Kodeksu cywilnego';
const CASE_OTHER_CONTENT = 'inne ........';
const INVALID_PROVISIONS_CONTENT =
  'Jeżeli postanowienia Umowy są lub staną się nieważne, lub Umowa zawierać będzie lukę, nie narusza to ważności pozostałych postanowień Umowy. Zamiast nieważnych postanowień lub jako wypełnienie luki obowiązywać będzie odpowiednia regulacja, która, jeżeli tylko będzie to prawnie dopuszczalne, w sposób możliwie bliski odpowiadać będzie temu, co strony ustaliły, lub temu, co by ustaliły, gdyby zawarły takie postanowienie';
const REPLACED_PROVISIONS_CONTENT =
  'Postanowienia Umowy, które z mocy prawa bądź ostatecznego lub prawomocnego orzeczenia jakiegokolwiek organu administracyjnego lub sądu powszechnego, uznane zostanie nieważnym bądź bezskutecznym zostaną zastąpione postanowieniami ustalonymi przez strony w terminie ........ dni od dnia, w którym powzięły one wiadomość o bezskuteczności bądź nieważności postanowień';
const ANNEXES_CONTENT = 'Integralną część Umowy stanowią załączniki do Umowy';
const APPLICABLE_LAW_CONTENT =
  'Prawem właściwym dla oceny wzajemnych praw i obowiązków wynikających z niniejszej Umowy jest prawo polskie';
const JURISDICTION_CONTENT =
  'Jurysdykcja do rozstrzygania sporów wynikłych na tle stosowania Umowy jest po stronie sądów polskich';
const ANY_CHANGES_CONTENT = 'Wszelkie zmiany Umowy wymagają formy pisemnej pod rygorem nieważności';
const IDENTICAL_COPIES_CONTENT =
  'Umowę sporządzono w ........ jednobrzmiących egzemplarzach, w tym ........ egzemplarze dla zamawiającego i ........ egzemplarze dla wykonawcy';

const initContentValues = {
  case_order_law_content: CASE_ORDER_LAW_CONTENT,
  case_build_law_content: CASE_BUILD_LAW_CONTENT,
  case_civil_code_content: CASE_CIVIL_CODE_CONTENT,
  case_other_content: CASE_OTHER_CONTENT,
  invalid_provisions_content: INVALID_PROVISIONS_CONTENT,
  replaced_provisions_content: REPLACED_PROVISIONS_CONTENT,
  annexes_content: ANNEXES_CONTENT,
  applicable_law_content: APPLICABLE_LAW_CONTENT,
  jurisdiction_content: JURISDICTION_CONTENT,
  any_changes_content: ANY_CHANGES_CONTENT,
  identical_copies_content: IDENTICAL_COPIES_CONTENT
};

const mapState = (
  step: AgreementStepFinalProvisionsValues,
  defaultValueStatus: boolean
): AgreementStepFinalProvisionsValues => {
  const {
    case_order_law_content,
    case_build_law_content,
    case_civil_code_content,
    case_other_content,
    invalid_provisions_content,
    replaced_provisions_content,
    annexes_content,
    applicable_law_content,
    jurisdiction_content,
    any_changes_checked,
    any_changes_content,
    identical_copies_content,
    checkboxes,
    ...rest
  } = step;

  return {
    ...rest,
    case_order_law_content: case_order_law_content || CASE_ORDER_LAW_CONTENT,
    case_build_law_content: case_build_law_content || CASE_BUILD_LAW_CONTENT,
    case_civil_code_content: case_civil_code_content || CASE_CIVIL_CODE_CONTENT,
    case_other_content: case_other_content || CASE_OTHER_CONTENT,
    invalid_provisions_content: invalid_provisions_content || INVALID_PROVISIONS_CONTENT,
    replaced_provisions_content: replaced_provisions_content || REPLACED_PROVISIONS_CONTENT,
    annexes_content: annexes_content || ANNEXES_CONTENT,
    applicable_law_content: applicable_law_content || APPLICABLE_LAW_CONTENT,
    jurisdiction_content: jurisdiction_content || JURISDICTION_CONTENT,
    any_changes_checked: Boolean(any_changes_checked || defaultValueStatus),
    any_changes_content: any_changes_content || ANY_CHANGES_CONTENT,
    identical_copies_content: identical_copies_content || IDENTICAL_COPIES_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepFinalProvisions = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, onSubmit, onChange, errors } = props;
  const agreementFromPzp = agreement.type !== AGREEMENT_TYPE_OUTSIDE_ORDER;

  const initState = useRef<AgreementStepFinalProvisionsValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_FINAL_PROVISIONS), agreementFromPzp)
  );

  const [stepValues, setStepValues] = useState<AgreementStepFinalProvisionsValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_FINAL_PROVISIONS), agreementFromPzp);
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderCheckbox, renderEditableCheckbox, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState: (values) => mapState(values, agreementFromPzp),
    errors,
    initContentValues
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        {renderCheckbox(
          'case_possibility',
          'W sprawach, których nie reguluje Umowa, będą miały zastosowanie odpowiednie przepisy:',
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {stepValues.case_possibility && (
          <div className="pl-3">
            {[
              renderEditableCheckbox(
                'case_order_law',
                null,
                agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                agreement.type === AGREEMENT_TYPE_SUPPLEMENT
              ),
              renderEditableCheckbox(
                'case_build_law',
                null,
                agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                agreement.type === AGREEMENT_TYPE_SUPPLEMENT
              ),
              renderEditableCheckbox(
                'case_civil_code',
                null,
                agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                agreement.type === AGREEMENT_TYPE_SUPPLEMENT
              ),
              renderEditableCheckbox(
                'case_other',
                null,
                agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                agreement.type === AGREEMENT_TYPE_SUPPLEMENT
              )
            ]}
          </div>
        )}
        {<hr />}
        {renderEditableCheckbox(
          'invalid_provisions',
          null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'replaced_provisions',
          null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'annexes',
          null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'applicable_law',
          null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'jurisdiction',
          null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'any_changes',
          null,
          agreementFromPzp || agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderEditableCheckbox(
          'identical_copies',
          null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderCustomCheckboxes(
          null,
          null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepFinalProvisions;
