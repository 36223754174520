import { AgreementFormConfiguration } from 'modules/Agreements/type';
import AgreementForm from 'modules/Agreements/components/Form';
import React from 'react';
import AgreementStepType from 'modules/Agreements/components/Steps/Default/Type';
import { AGREEMENT_STAGE_PREPARING, AGREEMENT_STEP_TYPE, AGREEMENT_STAGE_AGREEMENT } from 'modules/Agreements/step';

const configuration: AgreementFormConfiguration = {
  stages: [
    {
      slug: AGREEMENT_STAGE_PREPARING,
      steps: [
        {
          slug: AGREEMENT_STEP_TYPE,
          component: AgreementStepType
        }
      ],
      preview: false
    },
    {
      disabled: true,
      slug: AGREEMENT_STAGE_AGREEMENT,
      steps: []
    }
  ]
};

const AgreementCreateView = (): JSX.Element => {
  return <AgreementForm configuration={configuration} />;
};

export default AgreementCreateView;
