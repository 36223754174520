import React, { useEffect, useRef, useState } from 'react';
import { FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_INSURANCE, AGREEMENT_STEP_SUBJECT } from 'modules/Agreements/step';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import Part from 'modules/Agreements/model/Part';

export interface AgreementStepInsuranceValues {
  insurance_checked: boolean;
  insurance_content: string | null;
  consortium_checked: boolean;
  consortium_content: string | null;
  document_checked: boolean;
  document_content: string | null;
  insurance_expires_checked: boolean;
  insurance_expires_content: string | null;
  no_insurance_checked: boolean;
  no_insurance_content: string | null;
  claims_checked: boolean;
  claims_content: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const CONSORTIUM_CONTENT =
  'W przypadku realizacji Przedmiotu Umowy przez konsorcjum wykonawców zamawiający może żądać przedstawienia dowodu ubezpieczenia od odpowiedzialności cywilnej OC przez wszystkich konsorcjantów na kwoty gwarancyjne wskazane powyżej.';
const DOCUMENT_CONTENT =
  'Poświadczony dokument ubezpieczenia stanowi załącznik do Umowy. Wykonawca zobowiązany jest dostarczyć kopie polis oraz potwierdzenia opłacenia składek najpóźniej w dniu podpisania Umowy. Ubezpieczenie musi zostać zawarte na warunkach przedstawionych w Umowie';
const INSURANCE_EXPIRES_CONTENT =
  'Jeżeli okres ubezpieczenia, wygasa w trakcie obowiązywania Umowy, wykonawca przedstawi zamawiającemu nowe ubezpieczenie w terminie nie później niż na ........ dni kalendarzowych przed wygaśnięciem dotychczasowego ubezpieczenia';
const NO_INSURANCE_CONTENT =
  'W przypadku braku ubezpieczenia OC, potwierdzonego polisą lub innym dokumentem, zamawiający może wstrzymać wykonywanie usług do czasu ich przedstawienia, bez możliwości przedłużenia terminu realizacji prac lub odstąpić od Umowy. Odstąpienie od Umowy z przyczyn, o których mowa w tym ustępie, stanowi rozwiązanie Umowy z przyczyn leżących po stronie wykonawcy.';
const CLAIMS_CONTENT =
  'Każda strona niezwłocznie powiadomi drugą stronę o wszelkich roszczeniach, jakie zostały wobec niej podniesione, a które podlegają ochronie z tytułu jakiejkolwiek umowy ubezpieczeniowej, którą wykonawca zobowiązany jest zawrzeć lub której zawarcie wykonawca ma spowodować. Zawiadomienie zostanie przesłane wraz ze wszystkimi szczegółami na temat zdarzenia skutkującego wniesieniem takich roszczeń. Każda strona udzieli wszelkiej pomocy, jaka może być w uzasadniony sposób wymagana przez drugą stronę w celu przygotowania, udokumentowania i negocjowania roszczeń ubezpieczeniowych.';

const initContentValues = {
  consortium_content: CONSORTIUM_CONTENT,
  document_content: DOCUMENT_CONTENT,
  insurance_expires_content: INSURANCE_EXPIRES_CONTENT,
  no_insurance_content: NO_INSURANCE_CONTENT,
  claims_content: CLAIMS_CONTENT
};

const mapState = (
  step: AgreementStepInsuranceValues,
  getInsuranceContentInitValue = (): any => null
): AgreementStepInsuranceValues => {
  const {
    insurance_content,
    consortium_content,
    document_content,
    insurance_expires_content,
    no_insurance_content,
    efforts_content,
    claims_content,
    checkboxes,
    ...rest
  } = step;

  return {
    ...rest,
    insurance_content: insurance_content || getInsuranceContentInitValue(),
    consortium_content: consortium_content || CONSORTIUM_CONTENT,
    document_content: document_content || DOCUMENT_CONTENT,
    insurance_expires_content: insurance_expires_content || INSURANCE_EXPIRES_CONTENT,
    no_insurance_content: no_insurance_content || NO_INSURANCE_CONTENT,
    claims_content: claims_content || CLAIMS_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepInsurance = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, onSubmit, onChange, errors } = props;

  const getInsuranceContentInitValue = (): string => {
    let result =
      'Wykonawca zobowiązuje się posiadać, a na wezwanie zamawiającego przedstawić dowód ubezpieczenia, od odpowiedzialności cywilnej OC na kwotę gwarancyjną nie mniejszą niż ........ zł';

    const { same_subject_for_parts: sameSubjectForParts } = getStepValues(steps, AGREEMENT_STEP_SUBJECT);

    const { parts_ids: partsIds } = agreement;

    if (!sameSubjectForParts) {
      result =
        'Wykonawca zobowiązuje się posiadać, a na wezwanie zamawiającego przedstawić dowód ubezpieczenia, od odpowiedzialności cywilnej OC na kwotę gwarancyjną nie mniejszą niż:';
      result += '<ol>';

      partsIds.forEach((partObj: Part, index: number) => {
        result += `<li> ........zł w zakresie ${partObj.getName(index, 'zadania')}</li>`;
      });

      result += '</ol>';
    }

    return result;
  };

  const initState = useRef<AgreementStepInsuranceValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_INSURANCE), getInsuranceContentInitValue)
  );
  const [stepValues, setStepValues] = useState<AgreementStepInsuranceValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_INSURANCE), getInsuranceContentInitValue);
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderCheckbox, renderEditableCheckbox, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    initContentValues
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        {[
          renderEditableCheckbox('insurance', getInsuranceContentInitValue),
          renderCheckbox('consortium_checked', stepValues.consortium_content),
          renderCheckbox('document_checked', stepValues.document_content),
          renderEditableCheckbox('insurance_expires'),
          renderCheckbox('no_insurance_checked', stepValues.no_insurance_content),
          renderCheckbox('claims_checked', stepValues.claims_content)
        ]}
        {renderCustomCheckboxes()}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepInsurance;
