import React from 'react';
import './index.scss';
import CardBox from 'modules/Layout/component/CardBox';
import AppIcon from 'modules/Layout/component/AppIcon';

const ContactDetails = () => {
  const templateTheme = process.env.THEME_TEMPLATE as 'pzp' | 'bs';

  const contactInfo = {
    pzp: [
      {
        title: 'Centrum Zamówień Publicznych',
        name: 'Paweł Sendrowski',
        position: '',
        address: 'Ul. Grudzieniec 64',
        postal_code: '60-601 Poznań',
        phone: '',
        email: 'biuro@postepowania.pl'
      },
      {
        title: 'Wsparcie merytoryczne',
        name: 'Nikita Mitar',
        position: 'Doradca ds. produktu',
        address: '',
        postal_code: '',
        phone: '798855799',
        email: 'n.mitar@postepowania.pl'
      },
      {
        title: 'Wsparcie techniczne',
        name: '',
        position: '',
        address: '',
        postal_code: '',
        phone: '',
        email: 'helpdesk@postepowania.pl'
      }
    ],
    bs: [
      {
        title: 'Kancelaria Radcy Prawnego Błażej Sarnowski',
        name: 'Błażej Sarnowski',
        position: '',
        address: 'ul. Słowackiego 14 A – I piętro',
        postal_code: 'Konin 62-500',
        phone: '+48632482175',
        email: 'kancelaria@sarnowski.info.pl'
      }
    ]
  };

  return (
    <div className="contact-details">
      {contactInfo[templateTheme].map((item) => (
        <CardBox
          key={item.title}
          heading={item.title}
          paddingTop="25px"
          paddingLeft="30px"
          paddingRight="30px"
          paddingBottom="30px"
          childrenPaddingTop="25px"
        >
          <div className="card-content">
            <AppIcon />
            <div className="information-wrapper flex-grow-1">
              {item.name && <div className="name">{item.name}</div>}
              {item.position && <div className="information">{item.position}</div>}
              {item.address && <div className="information">{item.address}</div>}
              {item.postal_code && <div className="information">{item.postal_code}</div>}
              {item.phone && (
                <div className="information">
                  tel: <a href={`tel:${item.phone}`}>{item.phone}</a>
                </div>
              )}
              {item.email && (
                <div className="information">
                  e-mail: <a href={`mailto:${item.email}`}>{item.email}</a>
                </div>
              )}
            </div>
          </div>
        </CardBox>
      ))}
    </div>
  );
};

export default ContactDetails;
