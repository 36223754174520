import React from 'react';
import { ROUTE_DASHBOARD, ROUTE_DECLARATION_OF_AVAILABILITY } from 'modules/Layout/routes';
import { Menu } from 'app/menu';
import DashboardIcon from './component/Icon/Dashboard';
import AccessibilityIcon from './component/Icon/Accessibility';
import {
  increaseFontSize,
  decreaseFontSize,
  toggleContrast,
  toggleGreyHues,
  toggleUnderlineLinks,
  increaseWordSpacing,
  decreaseWordSpacing,
  resetAll
} from './helper/accessibility';

const MenuLayout: Menu[] = [
  {
    key: 'dashboard',
    type: 'link',
    title: 'Strona główna',
    icon: <DashboardIcon />,
    href: ROUTE_DASHBOARD
  }
];

const MenuAccessibility: Menu[] = [
  {
    key: 'accessibility',
    type: 'nested',
    title: 'Dostępność',
    icon: <AccessibilityIcon />,
    navLevel: 'second',
    href: '',
    isActive: (_match: null) => false,
    children: [
      {
        key: 'increase-font-size',
        type: 'button',
        title: 'Zwiększenie czcionki',
        onClick: increaseFontSize
      },
      {
        key: 'decrease-font-size',
        type: 'button',
        title: 'Zmniejszenie czcionki',
        onClick: decreaseFontSize
      },
      {
        key: 'contrast',
        type: 'button',
        title: 'Zwiększenie kontrastu',
        onClick: toggleContrast
      },
      {
        key: 'grey-hues',
        type: 'button',
        title: 'Odcienie szarości',
        onClick: toggleGreyHues
      },
      {
        key: 'underline-links',
        type: 'button',
        title: 'Podkreślenie linków',
        onClick: toggleUnderlineLinks
      },
      {
        key: 'increase-word-spacing',
        type: 'button',
        title: 'Zwiększony odstęp między słowami',
        onClick: increaseWordSpacing
      },
      {
        key: 'decrease-word-spacing',
        type: 'button',
        title: 'Zmniejszony odstęp między słowami',
        onClick: decreaseWordSpacing
      },
      {
        key: 'reset-all',
        type: 'button',
        title: 'Przywróć domyślne',
        onClick: resetAll
      },
      {
        key: 'declaration-of-availability',
        type: 'link',
        title: 'Deklaracja dostępności',
        href: ROUTE_DECLARATION_OF_AVAILABILITY
      }
    ]
  }
];

export { MenuLayout, MenuAccessibility };
