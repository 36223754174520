import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { PaginationMeta, SearchParams } from 'modules/Shared/type';
import { AgreementEntity } from 'modules/Agreements/model/Agreements';
import { AgreementStepSlug } from 'modules/Agreements/step';

export const API_AGREEMENTS = '/api/agreements';
export const API_AGREEMENT = '/api/agreements/:id';
export const API_AGREEMENT_STEPS = '/api/agreements/:id/steps';
export const API_GENERATE_FORM = '/api/agreements/:id/generate';
export const API_AGREEMENT_STEP = '/api/agreements/:id/steps/:step';
export const API_AGREEMENT_DELETE_PART = '/api/agreements/:id/part/:part_id';
export const API_CO_FINANCING_PROJECTS = '/api/co_financing_projects';

export type FetchAgreementsResponse = {
  data: AgreementEntity[];
  meta: PaginationMeta;
  links: any;
};

export type FetchCoFinancingProjectsResponse = {
  data: { id: number; name: string, subgroup: number, perspective: number }[];
};

export const fetchAgreements = (params: SearchParams = {}): AxiosPromise<FetchAgreementsResponse> =>
  axios.get(getAPIUrl(API_AGREEMENTS, null, params));

export const deleteAgreement = (id: string | number, cancelToken?: any): AxiosPromise =>
  axios.delete(getAPIUrl(API_AGREEMENT, { id }), { cancelToken });

export const createAgreement = (data: AgreementEntity, cancelToken?: any): AxiosPromise<{ data: AgreementEntity }> =>
  axios.post(getAPIUrl(API_AGREEMENTS), { ...data }, { cancelToken });

export const updateAgreement = (
  id: string | number,
  data: AgreementEntity,
  cancelToken?: any
): AxiosPromise<{ data: AgreementEntity }> => axios.put(getAPIUrl(API_AGREEMENT, { id }), { ...data }, { cancelToken });

export const deleteAgreementPart = (id: string | number, partId: number, cancelToken?: any): AxiosPromise =>
  axios.delete(getAPIUrl(API_AGREEMENT_DELETE_PART, { id, part_id: partId }), { cancelToken });

export interface AgreementStepsResponse {
  agreement: AgreementEntity;
  data: {
    [step: string]: any;
  };
  data_proceedings: {
    [stepImportData: string]: any;
  };
  filled_steps: AgreementStepSlug[];
  hidden_steps: AgreementStepSlug[];
  not_filled_steps: AgreementStepSlug[];
  missing_steps: AgreementStepSlug[];
}

export const getAgreementSteps = (id: string | number, cancelToken?: any): AxiosPromise<AgreementStepsResponse> =>
  axios.get(getAPIUrl(API_AGREEMENT_STEPS, { id }), { cancelToken });

export const downloadGeneratedForm = (id: string | number, cancelToken?: any): AxiosPromise =>
  axios.get(getAPIUrl(API_GENERATE_FORM, { id }), { responseType: 'blob', cancelToken });

export const saveAgreementStep = (
  id: string | number,
  step: AgreementStepSlug,
  data: any
): AxiosPromise<AgreementStepsResponse> => axios.post(getAPIUrl(API_AGREEMENT_STEP, { id, step }), data);

export const fetchCoFinancingProjects = (): AxiosPromise<{ data: FetchCoFinancingProjectsResponse }> =>
  axios.get(getAPIUrl(API_CO_FINANCING_PROJECTS, null));
