/* eslint-disable */
import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function TooltipPlusIcon(props: IconProps): JSX.Element {
  const { height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 18.501 18.5">
      <g id="Component_33_7" data-name="Component 33 – 7" transform="translate(0.5 0.5)">
        <path
          id="Path_1293"
          data-name="Path 1293"
          d="M152.365,1296.62a8.75,8.75,0,1,0,8.752,8.749A8.747,8.747,0,0,0,152.365,1296.62Z"
          transform="translate(-143.616 -1296.62)"
          fill="#3D841A"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_56"
          data-name="Line 56"
          y2="10.147"
          transform="translate(8.751 3.676)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_57"
          data-name="Line 57"
          x2="10.147"
          transform="translate(3.677 8.75)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
