import _has from 'lodash/has';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { CompanyEntity } from 'modules/User/model/User';
import FieldsetCompanyUser from 'modules/User/component/Fieldset/UserCompany/BaseFields';
import { CustomInput } from 'reactstrap';

export const initCompanyValue: CompanyEntity = {
  client_type: 1,
  name: '',
  first_name: '',
  last_name: '',
  street: '',
  postal_code: '',
  city: '',
  post_office: '',
  nip: '',
  email: '',
  correspondence_address_same: true,
  correspondence_street: '',
  correspondence_postal_code: '',
  correspondence_city: '',
  correspondence_post_office: ''
};

export interface Props {
  errors?: ValidationErrors;
  values: CompanyEntity;
  setValues: React.Dispatch<React.SetStateAction<CompanyEntity>>;
  displayCompany: boolean;
  setDisplayCompany: (value: React.SetStateAction<boolean>) => void;
  setAutofills?: (key: string) => void;
  placeholder?: boolean;
  setMultipleValues: (data: Record<any, any>) => void;
  executorFromGus: Record<any, any>;
  setExecutorFromGus: (data: Record<any, any>) => void;
}

const CompanyFormWrapper: React.FC<Props> = ({
  values,
  setValues,
  displayCompany,
  setDisplayCompany,
  setAutofills,
  errors,
  placeholder = true,
  setMultipleValues,
  executorFromGus,
  setExecutorFromGus
}) => {
  const onCompanyChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = event.target;

    if (_has(values, key)) {
      setValues({ ...values, [key]: value });
    }
  };

  const companyErrors = () => {
    if (errors && errors?.company) {
      const { company } = errors;
      return company as ValidationErrors;
    }
    let companyErr = {};
    Object.keys(errors ?? {}).forEach((key) => {
      if (key.includes('company.')) {
        const value = errors[key];
        const newKey = key.replace('company.', '');
        companyErr = { ...companyErr, [newKey]: value };
      }
    });

    return companyErr;
  };

  return (
    <>
      <div className="d-flex flex-wrap align-items-center justify-content-between mb-2">
        <div className="h4">Dane rozliczeniowe</div>
        <CustomInput
          id="save-filter-checkbox"
          data-testid="filter-checkbox"
          type="checkbox"
          name="save-filter-checkbox"
          label=""
          className=""
          checked={displayCompany}
          onChange={(event) => setDisplayCompany(event.target.checked)}
        />
      </div>
      {displayCompany && (
        <div className="ml-2">
          <FieldsetCompanyUser
            data-testid="company-child"
            values={values}
            errors={errors && companyErrors()}
            setAutofills={setAutofills}
            onChange={onCompanyChange}
            placeholder={placeholder}
            required
            setMultipleValues={setMultipleValues}
            executorFromGus={executorFromGus}
            setExecutorFromGus={setExecutorFromGus}
          />
        </div>
      )}
    </>
  );
};

export default CompanyFormWrapper;
