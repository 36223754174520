import React from 'react';
import DetailsBasic from 'modules/ExecutorOrder/component/Details/Basic';
import Order from 'modules/ExecutorOrder/model/Order';
import ViolationsDetails from 'modules/ExecutorOrder/component/Details/Violations';
import { IFile } from '../../../Shared/helper/Files/AddFiles';

type Props = {
  order?: Order;
  executor_id?: string;
  orderDownloadFileRequest: (fileId: number | string, sectionId: string | number) => Promise<void>;
  onDeleteOrder: () => void;
  onDeleteFile: (file: IFile, sectionId: string | number) => void;
  loadingFile: boolean;
  onAddFilesRequest: (
    section_id: number | string,
    data: FormData,
    changeLoading: (curr: boolean) => void,
    setProgress: (value: React.SetStateAction<number>) => void
  ) => Promise<void>;
  redirectUrl: string;
};

const DetailsComponent: React.FC<Props> = ({ order, executor_id, onDeleteOrder, redirectUrl, ...rest }) => {
  return (
    <div className="col-12">
      <div className="row justify-content-center">
        <div className="col-xl-7">
          <DetailsBasic
            order={order}
            executor_id={executor_id}
            onDeleteClick={onDeleteOrder}
            redirectUrl={redirectUrl}
          />
        </div>
        <div className="col-xl-7">
          <ViolationsDetails order={order} {...rest} redirectUrl={redirectUrl} />
        </div>
      </div>
    </div>
  );
};

export default DetailsComponent;
