import { RootState } from 'app/reducer';
import Authorize from 'modules/Auth/container/Authorize';
import { setProceedingsListParamsAction } from 'modules/Proceedings/action/ProceedingsList';
import { PROCEEDINGS_DELETE, PROCEEDINGS_EDIT } from 'modules/Proceedings/permissions';
import { ROUTE_PROCEEDING, ROUTE_PROCEEDINGS_UPDATE } from 'modules/Proceedings/routes';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { getPathUrl } from 'modules/Shared/helper/api';
import { PartialSearchingProps } from 'modules/Shared/type';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import EditIcon from 'modules/Layout/component/Icon/Edit';
import Proceedings, { proceedingsStartDate } from 'modules/Proceedings/model/Proceedings';
import { formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { PROCEEDINGS_INIT_TYPES_VALUES } from 'modules/Proceedings/type';

type Props = {
  proceedingsType: number;
  onDeleteClick?: (proceedings: Proceedings) => void;
  isAdmin?: boolean;
  redirectUrl?: string;
  paramsUrl?: Record<string, string>;
  manageFavouriteAction?: (proceeding_id: number, isAdding?: boolean) => void;
};

const ProceedingsTable: React.FC<Props> = ({
  proceedingsType,
  onDeleteClick,
  isAdmin,
  redirectUrl,
  manageFavouriteAction,
  paramsUrl = {}
}) => {
  const dispatch = useDispatch();
  const { list, sort } = useSelector((state: RootState) => state.proceedings.proceedingsList);

  const setParams = (payload: PartialSearchingProps) => dispatch(setProceedingsListParamsAction(payload));

  const redirectLink = (value: string, id: number) => (
    <Link
      className="fill-table-td"
      to={getPathUrl(redirectUrl ?? ROUTE_PROCEEDING, { id, proceedingsType }, paramsUrl)}
    >
      {value}
    </Link>
  );

  const renderWadiumValue = (row: Proceedings) => {
    if (!row?.requires_deposit) return 'Zamawiający nie wymaga wniesienia wadium';

    if (row?.deposit === 0) {
      return 'Nie udało się zweryfikować prawidłowej wysokości wadium';
    }

    return `${row?.deposit.toFixed(2)} PLN`;
  };

  const cols: TableCol<Proceedings>[] = [
    {
      property: 'name',
      label: 'Nazwa',
      sortable: true,
      value: (row) => redirectLink(row.name, row.id)
    }
  ];

  const openCols: TableCol<Proceedings>[] = [
    {
      property: 'identifier',
      label: 'Numer postępowania',
      sortable: true,
      value: (row) => (row.identifier ? redirectLink(row.identifier, row.id) : 'Zamawiający nie nadał numeru')
    }
  ];

  const colsPart2: TableCol<Proceedings>[] = [
    {
      property: 'contractor_name',
      label: 'Nazwa zamawiającego',
      sortable: true,
      value: (row) => redirectLink(row.contractor_name, row.id)
    },
    {
      property: 'start_date',
      label: 'Data rozpoczęcia postępowania',
      sortable: true,
      value: (row) => proceedingsStartDate(row?.start_date_type, row?.start_date)
    }
  ];

  const openColsPart2: TableCol<Proceedings>[] = [
    {
      property: 'offers_send_date',
      label: 'Termin składania ofert',
      sortable: true,
      value: (row) => ifContainsDateFormat(row?.offers_send_date, formatDatetimeSeconds)
    },
    {
      property: 'deposit',
      label: 'Wadium',
      value: renderWadiumValue,
      sortable: true
    }
  ];

  const colsPart3: TableCol<Proceedings>[] = [
    {
      property: 'value',
      label: 'Wartość zamówienia',
      value: (row) => (row?.value ? `${row.value.toFixed(2).replace('.', ',')} PLN` : 'brak danych'),
      sortable: true
    }
  ];

  const actionAdminCol: TableCol<Proceedings>[] = [
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            <Authorize permissions={[PROCEEDINGS_EDIT]}>
              <ActionUpdate
                title="Edytuj postępowanie"
                label={<EditIcon height="20px" />}
                to={getPathUrl(ROUTE_PROCEEDINGS_UPDATE, { id: row.id, proceedingsType })}
              />
            </Authorize>
            {onDeleteClick && (
              <Authorize permissions={[PROCEEDINGS_DELETE]}>
                <ActionDelete className="ml-1" title="Usuń postępowanie" onClick={() => onDeleteClick(row)} />
              </Authorize>
            )}
          </div>
        );
      }
    }
  ];

  const actionSavedBtn = (id: number) => (
    <ActionUpdate
      title="Dodaj do ulubionych postępowań"
      label={<i className="far font-20 fa-heart text-danger" />}
      onClick={() => manageFavouriteAction(id, true)}
    />
  );

  const actionNotSavedBtn = (id: number) => (
    <ActionUpdate
      title="Usuń z ulubionych postępowań"
      label={<i className="fas font-20 fa-heart text-danger" />}
      onClick={() => manageFavouriteAction(id)}
    />
  );

  const actionClientCol: TableCol<Proceedings>[] = [
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">{row?.is_saved ? actionNotSavedBtn(row.id) : actionSavedBtn(row.id)}</div>
        );
      }
    }
  ];

  const tableCols =
    proceedingsType === PROCEEDINGS_INIT_TYPES_VALUES.PLANNING
      ? [...cols, ...colsPart2, ...colsPart3]
      : [...cols, ...openCols, ...colsPart2, ...openColsPart2, ...colsPart3];

  let finalCols;

  if (isAdmin) {
    finalCols = [...tableCols, ...actionAdminCol];
  } else if (manageFavouriteAction) {
    finalCols = [...tableCols, ...actionClientCol];
  } else {
    finalCols = [...tableCols];
  }

  return <Table cols={finalCols} rows={list} sort={sort} onSort={(params) => setParams({ sort: params })} />;
};

export default ProceedingsTable;
