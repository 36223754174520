import React from 'react';
import { calculateDiffInDays, formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { Link } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_JUDGEMENT } from 'modules/Judgement/routes';
import { JudgementEntity } from 'modules/Judgement/model/Judgement';
import InformationListCollapsed from 'modules/Layout/component/InformationList/Collapsed';

type Props = { judgment: JudgementEntity };

const LastNeededUpdateJudgment: React.FC<Props> = ({ judgment }) => {
  const daysLate = judgment?.created_at ? calculateDiffInDays(judgment.created_at) : null;

  const daysCorrectForm = () => {
    const roundedDays = Math.ceil(daysLate);

    switch (roundedDays) {
      case 0:
        return 'Utworzono dzisiaj';
      case 1:
        return 'Utworzono 1 dzień temu';
      default:
        return `Utworzono ${roundedDays} dni temu`;
    }
  };

  return (
    <div className="last-needed-update-executor-row">
      <InformationListCollapsed
        title={
          <Link className="pzpeu-link" to={getPathUrl(ROUTE_JUDGEMENT, { id: judgment?.id })}>
            {judgment?.identifier}
          </Link>
        }
        fields={[
          <>
            Nazwa organu publikującego dokument: <span className="highlighted">{judgment?.authority_name ?? '-'}</span>
          </>,
          <>
            Rodzaj organu publikującego dokument: <span className="highlighted">{judgment?.authority_type ?? '-'}</span>
          </>,
          <>
            Rodzaj dokumentu: <span className="highlighted">{judgment?.document_type ?? '-'}</span>
          </>,
          <>
            Termin publikacji:{' '}
            <span className="highlighted">{ifContainsDateFormat(judgment?.published_at, formatDate) ?? '-'}</span>
          </>,
          <>Utworzono: {daysCorrectForm()}</>
        ]}
      />
    </div>
  );
};

export default LastNeededUpdateJudgment;
