import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function AdminIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 23.904 23.308">
      <g id="Group_372" data-name="Group 372" transform="translate(-1535.057 -336.041)">
        <path
          id="Path_1274"
          data-name="Path 1274"
          d="M1546.757,359.349a.5.5,0,0,1-.46-.305l-.451-1.066a.5.5,0,0,1,.107-.548l6.006-6.006a3.562,3.562,0,1,1,5.961,1.608,3.53,3.53,0,0,1-2.514,1.042,3.6,3.6,0,0,1-1.143-.188l-1.244,1.243a.5.5,0,0,1-.353.146h-.012l-1.259-.03-.247,1.763a.5.5,0,0,1-.4.421l-1.419.277-.322,1.207a.5.5,0,0,1-.465.371l-1.767.065Zm.141-1.451.185.438,1.053-.039.307-1.152a.5.5,0,0,1,.387-.362l1.376-.269.26-1.851a.5.5,0,0,1,.495-.431h.012l1.49.036,1.324-1.323a.5.5,0,0,1,.559-.1,2.589,2.589,0,0,0,1.059.228,2.537,2.537,0,0,0,1.807-.749,2.563,2.563,0,1,0-4.217-.933.5.5,0,0,1-.116.525Z"
          fill={fill}
        />
        <path
          id="Path_1275"
          data-name="Path 1275"
          d="M1545.5,355.979h-9.27a1.18,1.18,0,0,1-1.175-1.182V352.4a1.188,1.188,0,0,1,.754-1.1c1.865-.716,3.3-1.322,4.377-1.854,1.614-.795,1.986-1.206,2.071-1.338v-.652a6.554,6.554,0,0,1-1.372-2.51,2.047,2.047,0,0,1-.7-1.279,2.023,2.023,0,0,1,.336-1.429v-1.976c0-2.644,1.71-4.223,4.575-4.223,2.907,0,4.574,1.539,4.574,4.223v1.975a2.025,2.025,0,0,1,.336,1.432,2.046,2.046,0,0,1-.7,1.277,6.575,6.575,0,0,1-1.371,2.509v.624a12.394,12.394,0,0,0,2.584,1.837.5.5,0,0,1-.466.885,15.694,15.694,0,0,1-1.824-1.165c-1.133-.835-1.226-1.13-1.271-1.272a.5.5,0,0,1-.023-.151v-.956a.5.5,0,0,1,.144-.351,5.538,5.538,0,0,0,1.312-2.392.5.5,0,0,1,.2-.275,1.042,1.042,0,0,0,.2-1.52.5.5,0,0,1-.113-.317v-2.141a3.145,3.145,0,0,0-.825-2.334,3.792,3.792,0,0,0-2.749-.889c-2.305,0-3.575,1.145-3.575,3.223v2.142a.5.5,0,0,1-.113.317,1.042,1.042,0,0,0,.2,1.519.5.5,0,0,1,.195.275,5.517,5.517,0,0,0,1.313,2.391.5.5,0,0,1,.144.351v.956a.5.5,0,0,1-.023.15c-.2.652-1.2,1.621-7.066,3.871a.179.179,0,0,0-.113.169V354.8a.179.179,0,0,0,.175.182h9.27a.5.5,0,0,1,0,1Z"
          fill={fill}
        />
        <path id="Line_39" data-name="Line 39" transform="translate(1555.812 350.101)" fill="#666666" />
      </g>
    </svg>
  );
}
