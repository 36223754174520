import React from 'react';
import Authorize from 'modules/Auth/container/Authorize';
import CardBox from 'modules/Layout/component/CardBox';
import { getPathUrl } from 'modules/Shared/helper/api';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import ForeignProceedings from '../../../model/ForeignProceedings';
import { FOREIGN_PROCEEDINGS_DELETE, FOREIGN_PROCEEDINGS_EDIT } from '../../../permissions';
// eslint-disable-next-line import/no-cycle
import { ROUTE_FOREIGN_PROCEEDINGS_UPDATE } from '../../../routes';
import ForeignProceedingsBasicDataset from '../../Dataset/Basic';
import ForeignProceedingsNewBoxDataset from '../../Dataset/NewBox';

type Props = {
  foreignProceedings: ForeignProceedings;
  setDisplayDeleteModal?: () => void;
  isAdmin?: boolean;
  aditionalActions: () => JSX.Element;
};

const ForeignProceedingsBasicDetails: React.FC<Props> = ({
  foreignProceedings,
  setDisplayDeleteModal,
  aditionalActions,
  isAdmin
}) => {
  const displayBox = (
    <div className="col-12 foreign-proceedings-details-admin">
      <CardBox
        heading={`${foreignProceedings.name} ${
          foreignProceedings.manually_modified && isAdmin ? '- Zmodyfikowany ręcznie' : ''
        }`}
        paddingRight="30px"
        paddingBottom="0"
        className="foreign-proceedings-main-details"
        actions={
          <>
            <Authorize permissions={[FOREIGN_PROCEEDINGS_EDIT]}>
              <Link
                className="link-with-border"
                to={{
                  pathname: getPathUrl(ROUTE_FOREIGN_PROCEEDINGS_UPDATE, {
                    id: foreignProceedings?.id
                  }),
                  state: { foreignProceedings }
                }}
              >
                EDYTUJ
              </Link>
            </Authorize>
            {setDisplayDeleteModal && (
              <Authorize permissions={[FOREIGN_PROCEEDINGS_DELETE]}>
                <Button color="link" className="link-with-border p-0" onClick={setDisplayDeleteModal}>
                  USUŃ
                </Button>
              </Authorize>
            )}
            {aditionalActions()}
          </>
        }
      >
        <ForeignProceedingsBasicDataset element={foreignProceedings} />
      </CardBox>

      <ForeignProceedingsNewBoxDataset element={foreignProceedings} />

      {foreignProceedings.description_additional && foreignProceedings.description_additional.length > 0 && (
        <div className="col-12 foreign-proceedings-description-details p-0">
          <CardBox heading="Informacje dodatkowe" paddingRight="30px">
            <div className="row users-view">
              <div className="col-12">
                {foreignProceedings.description_additional.map((desc) => (
                  <p key={desc.label}>
                    <b>{desc.translation}</b> {desc.value}
                  </p>
                ))}
              </div>
            </div>
          </CardBox>
        </div>
      )}

      {!foreignProceedings.from_bzp && (
        <div className="col-12 foreign-proceedings-attachments-details p-0">
          <CardBox heading="Załączniki" paddingRight="30px">
            <div className="row users-view">
              <div className="col-12">
                <div
                  className="pzpeu-table-list inside-card-box"
                  style={{ display: 'flex', flexDirection: 'column', gap: '8px', margin: '0' }}
                >
                  {foreignProceedings?.links?.length
                    ? foreignProceedings?.links.map((link) => (
                        <a key={link.id} href={link.link} target="_blank" rel="noreferrer">
                          {link.name}
                        </a>
                      ))
                    : null}
                </div>
                {foreignProceedings?.files?.length === 0 && (
                  <div style={{ marginBottom: '-20px' }}>brak załączników</div>
                )}
              </div>
            </div>
          </CardBox>
        </div>
      )}
      {foreignProceedings.industries.length > 0 && (
        <div className="col-12 foreign-proceedings-industries-details p-0">
          <CardBox heading="Branże" paddingRight="30px">
            <div
              className="pzpeu-table-list inside-card-box"
              style={{ display: 'flex', flexDirection: 'column', gap: '8px', margin: '0' }}
            >
              {foreignProceedings.industries?.map((industry) => (
                <div key={industry.id}>
                  {industry.parent_type_name} - {industry.industry_name}
                </div>
              ))}
            </div>
          </CardBox>
        </div>
      )}
      {foreignProceedings.codes && (
        <div className="col-12 foreign-proceedings-codes-details p-0">
          <CardBox heading="Kody" paddingRight="30px">
            <div className="row users-view">
              <div className="col-12">
                {foreignProceedings.codes.map((code) => (
                  <p key={code.id}>
                    <span style={{ fontWeight: 700, textTransform: 'uppercase' }}>{code.type}</span> - {code.code}
                  </p>
                ))}
              </div>
            </div>
          </CardBox>
        </div>
      )}
    </div>
  );

  return (
    <div className="row justify-content-center user-details-view position-relative flex-1">
      <div className="col-12">{displayBox}</div>
    </div>
  );
};

export default ForeignProceedingsBasicDetails;
