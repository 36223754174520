import { BreadcrumbItem } from 'modules/Layout/type';
import {
  ROUTE_PROCEEDING,
  ROUTE_PROCEEDINGS_PLANNING_FAV,
  ROUTE_PROCEEDINGS_OPEN_FAV,
  ROUTE_PROCEEDINGS_OPEN,
  ROUTE_PROCEEDINGS_PLANNING,
  ROUTE_PROCEEDINGS_PUBLIC_OPEN,
  ROUTE_PROCEEDINGS_PUBLIC_PLANNING
} from 'modules/Proceedings/routes';
import { getPathUrl } from 'modules/Shared/helper/api';
import { PROCEEDINGS_INIT_TYPES_VALUES } from 'modules/Proceedings/type';
// import { getPathUrl } from 'modules/Shared/helper/api';

const breadcrumbRouteProceedingsOpen = (): BreadcrumbItem[] => [
  {
    title: 'Wyszukiwarka prowadzonych postępowań - lista',
    link: ROUTE_PROCEEDINGS_OPEN
  }
];

const breadcrumbRouteProceedingsPlanning = (): BreadcrumbItem[] => [
  {
    title: 'Wyszukiwarka planowanych postępowań - lista',
    link: ROUTE_PROCEEDINGS_PLANNING
  }
];

export const breadcrumbRouteProceedings = (proceedingsType: number): BreadcrumbItem[] => {
  return proceedingsType === PROCEEDINGS_INIT_TYPES_VALUES.PLANNING
    ? breadcrumbRouteProceedingsPlanning()
    : breadcrumbRouteProceedingsOpen();
};

export const breadcrumbRouteProceedingsPlanningFav = (): BreadcrumbItem[] => [
  {
    title: 'Ulubione planowane postępowania - lista',
    link: ROUTE_PROCEEDINGS_PLANNING_FAV
  }
];

export const breadcrumbRouteProceedingsOpenFav = (): BreadcrumbItem[] => [
  {
    title: 'Ulubione prowadzone postępowania - lista',
    link: ROUTE_PROCEEDINGS_OPEN_FAV
  }
];

export const breadcrumbRouteProceedingsFav = (proceedingsType: number): BreadcrumbItem[] => {
  return proceedingsType === PROCEEDINGS_INIT_TYPES_VALUES.PLANNING
    ? breadcrumbRouteProceedingsPlanningFav()
    : breadcrumbRouteProceedingsOpenFav();
};

export const breadcrumbRouteProceedingsDetails = (
  proceedingsType: number,
  name?: string,
  id?: number,
  isFavourite?: boolean
): BreadcrumbItem[] => [
  ...(isFavourite ? breadcrumbRouteProceedingsFav(proceedingsType) : breadcrumbRouteProceedings(proceedingsType)),
  {
    title: `${name ?? 'Wyszukiwarka postępowań'} - szczegóły`,
    link: getPathUrl(ROUTE_PROCEEDING, { id, proceedingsType })
  }
];

export const breadcrumbRouteProceedingsCreate = (proceedingsType: number): BreadcrumbItem[] => [
  ...breadcrumbRouteProceedings(proceedingsType),
  {
    title: 'Tworzenie'
  }
];

export const breadcrumbRouteProceedingsUpdate = (
  proceedingsType: number,
  name: string,
  id: number
): BreadcrumbItem[] => [
  ...breadcrumbRouteProceedingsDetails(proceedingsType, name, id),
  {
    title: 'Edycja postępowania'
  }
];

const breadcrumbPublicRouteProceedingsOpen = (): BreadcrumbItem[] => [
  {
    title: 'Wyszukiwarka prowadzonych postępowań - lista',
    link: ROUTE_PROCEEDINGS_PUBLIC_OPEN
  }
];

const breadcrumbPublicRouteProceedingsPlanning = (): BreadcrumbItem[] => [
  {
    title: 'Wyszukiwarka planowanych postępowań - lista',
    link: ROUTE_PROCEEDINGS_PUBLIC_PLANNING
  }
];

export const breadcrumbPublicRouteProceedings = (proceedingsType: number): BreadcrumbItem[] => {
  return proceedingsType === PROCEEDINGS_INIT_TYPES_VALUES.PLANNING
    ? breadcrumbPublicRouteProceedingsPlanning()
    : breadcrumbPublicRouteProceedingsOpen();
};

export const breadcrumbPublicRouteProceedingsDetails = (proceedingsType: number, name?: string): BreadcrumbItem[] => [
  ...breadcrumbPublicRouteProceedings(proceedingsType),
  {
    title: `${name ?? 'Wyszukiwarka postępowań'} - szczegóły`
  }
];
