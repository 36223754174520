import _isEmpty from 'lodash/isEmpty';
import { RegisterValues } from 'modules/Auth/type';
import PasswordFieldset from 'modules/Layout/component/Password/Fieldset';
import { ValidationErrors } from 'modules/Shared/type';
import UserFieldset from 'modules/User/component/Fieldset';
import { UserFormValues } from 'modules/User/type';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form as FormStrap, FormGroup } from 'reactstrap';
import FieldsetRegulation from 'modules/Regulation/component/Fieldset/Regulation';
import CompanyFormWrapper, { initCompanyValue } from 'modules/User/component/Fieldset/UserCompany';
import { CompanyEntity } from 'modules/User/model/User';
import FieldsetRodo from 'modules/Regulation/component/Fieldset/Rodo';
import { initRodoValues } from 'modules/Regulation/component/Fieldset/Rodo/rodoContent';
import { RodoValues } from 'modules/Regulation/type';
import useRecaptcha from 'modules/Shared/helper/hooks/useRecaptcha';

export interface Props {
  busy: boolean;
  errors?: ValidationErrors;
  submit: (values: RegisterValues) => void;
}

const RegisterForm: React.FC<Props> = ({ busy, errors, submit }) => {
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    regulations_accepted: false,
    nip: ''
  });
  const [activeCompany, setActiveCompany] = useState(false);
  const [companyVal, setCompanyVal] = useState(initCompanyValue);
  const [rodoVal, setRodoVal] = useState(initRodoValues());
  const [executorFromGus, setExecutorFromGus] = useState<Record<any, any>>(null);
  const firstNameAutofill = useRef(true);
  const lastNameAutofill = useRef(true);
  const emailAutofill = useRef(true);

  const captchaEnable = process.env.RECAPTCHA_ENABLE === 'true';
  const { recaptchaValue, repatchaJsx } = useRecaptcha(captchaEnable);

  useEffect(() => {
    let compValues: CompanyEntity = {};
    if (firstNameAutofill.current) {
      compValues = { ...compValues, first_name: values.first_name };
    }
    if (lastNameAutofill.current) {
      compValues = { ...compValues, last_name: values.last_name };
    }
    if (emailAutofill.current) {
      compValues = { ...compValues, email: values.email };
    }

    if (!_isEmpty(compValues)) setCompanyVal({ ...companyVal, ...compValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const setAutofills = (key: string) => {
    if (key === 'first_name' && firstNameAutofill.current) {
      firstNameAutofill.current = false;
    }
    if (key === 'last_name' && lastNameAutofill.current) {
      lastNameAutofill.current = false;
    }
    if (key === 'email' && emailAutofill.current) {
      emailAutofill.current = false;
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let company: CompanyEntity = null;
    if (activeCompany) {
      if (companyVal?.client_type === 1) {
        const { nip: _q, name: _, ...rest } = companyVal;

        company = { ...rest, nip: '', name: '' };
      } else {
        company = companyVal;
      }
    }

    submit({ ...values, ...recaptchaValue, company, ...rodoVal });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, key: keyof UserFormValues & keyof RodoValues) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setValues({ ...values, [key]: value });
  };

  const setMultipleValues = (data: Record<any, any>) => {
    setCompanyVal({ ...companyVal, ...data });
  };

  const clearFields = () => {
    setCompanyVal({
      ...companyVal,
      name: '',
      first_name: '',
      last_name: '',
      street: '',
      postal_code: '',
      city: '',
      post_office: '',
      email: ''
    });

    setExecutorFromGus(null);
  };

  return (
    <FormStrap onSubmit={onSubmit}>
      <UserFieldset values={values} errors={errors} disabled={busy} onChange={onChange} placeholder required />
      <PasswordFieldset errors={errors} disabled={busy} onChange={onChange} required placeholder />

      <CompanyFormWrapper
        values={companyVal}
        setValues={setCompanyVal}
        displayCompany={activeCompany}
        setDisplayCompany={setActiveCompany}
        setAutofills={setAutofills}
        errors={errors}
        setMultipleValues={setMultipleValues}
        executorFromGus={executorFromGus}
        setExecutorFromGus={setExecutorFromGus}
      />
      <FormGroup className="w-100">
        {companyVal.client_type === 2 && executorFromGus !== null && (
          <Button
            type="button"
            color="secondary"
            outline
            className="pzpeu-btn-disabled waves-effect waves-light mb-3 w-100"
            onClick={clearFields}
          >
            Wyczyść
          </Button>
        )}
      </FormGroup>

      <FieldsetRegulation errors={errors} values={values} onChange={onChange} />
      <FieldsetRodo errors={errors} values={rodoVal} setChange={setRodoVal} />
      {repatchaJsx()}
      <FormGroup className="form-actions">
        <Button type="submit" color="primary" disabled={busy} className="waves-effect waves-light mt-1">
          ZAREJESTRUJ
        </Button>
      </FormGroup>
    </FormStrap>
  );
};

export default RegisterForm;
