import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function DashboardIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 21 19">
      <g id="Group_339" data-name="Group 339" transform="translate(-383.038 -1489)">
        <path
          id="Rectangle_59"
          data-name="Rectangle 59"
          d="M0-.5H20a.5.5,0,0,1,.5.5V18a.5.5,0,0,1-.5.5H0A.5.5,0,0,1-.5,18V0A.5.5,0,0,1,0-.5ZM19.5.5H.5v17h19Z"
          transform="translate(383.538 1489.5)"
          fill={fill}
        />
        <path
          id="Line_24"
          data-name="Line 24"
          d="M19.592.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H19.592a.5.5,0,0,1,.5.5A.5.5,0,0,1,19.592.5Z"
          transform="translate(383.579 1492.064)"
          fill={fill}
        />
        <path
          id="Rectangle_60"
          data-name="Rectangle 60"
          d="M0-.5H14.529a.5.5,0,0,1,.5.5V1.708a.5.5,0,0,1-.5.5H0a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5ZM14.029.5H.5v.708H14.029Z"
          transform="translate(386.078 1495.482)"
          fill={fill}
        />
        <path
          id="Rectangle_61"
          data-name="Rectangle 61"
          d="M0-.5H5.983a.5.5,0,0,1,.5.5V5.127a.5.5,0,0,1-.5.5H0a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5ZM5.483.5H.5V4.627H5.483Z"
          transform="translate(386.078 1499.755)"
          fill={fill}
        />
        <path
          id="Rectangle_62"
          data-name="Rectangle 62"
          d="M0-.5H5.983a.5.5,0,0,1,.5.5V5.127a.5.5,0,0,1-.5.5H0a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5ZM5.483.5H.5V4.627H5.483Z"
          transform="translate(394.624 1499.755)"
          fill={fill}
        />
      </g>
    </svg>
  );
}
