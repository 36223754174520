import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import Office, { lastExecutorRequestStatus } from 'modules/Office/model/Office';
import { formatDate, formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';
// import { ROLE_ADMIN } from 'modules/User/model/UserRole';

export interface Props {
  office: Office;
}

const ExecutorsRequestDataset: React.FC<Props> = ({ office }) => {
  const { can_send_email, last_executor_request, next_request_at } = office;

  const requestStatus = () => {
    if (last_executor_request?.status === 0 && last_executor_request?.sent_at) {
      return 'Zapytanie zostało wysłane, ale nie otrzymano jeszcze odpowiedzi.';
    }
    return lastExecutorRequestStatus(last_executor_request?.status);
  };

  const fields = [
    {
      label: 'Czy zapytania są wysyłane',
      value: can_send_email ? 'Tak' : 'Nie'
    },
    {
      label: 'Status zapytań',
      value: requestStatus()
    },
    {
      label: 'Data wysłania ostatniego zapytania',
      value: ifContainsDateFormat(last_executor_request?.sent_at, formatDatetimeSeconds)
    },
    {
      label: 'Data otrzymania ostatniej odpowiedzi',
      value: ifContainsDateFormat(last_executor_request?.received_at, formatDatetimeSeconds)
    },
    {
      label: 'Data następnej wysyłki zapytania',
      value: ifContainsDateFormat(next_request_at, formatDate)
    }
  ];

  return <InformationList fields={fields} />;
};

export default ExecutorsRequestDataset;
