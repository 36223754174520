import React from 'react';
import { AGREEMENT_TYPE_PROJECT, AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementStepBasicInformationsValues } from 'modules/Agreements/components/Steps/OrderForm/BasicInformations';
import { AgreementStepContractorExecutorValues } from 'modules/Agreements/components/Steps/OrderForm/ContractorExecutor';
import { AgreementStepSubjectValues } from 'modules/Agreements/components/Steps/OrderForm/Subject';
import { AgreementStepRewardValues } from 'modules/Agreements/components/Steps/OrderForm/Reward';
import { getFieldForPreview, getStepValues, PREVIEW_PLACEHOLDER } from 'modules/Agreements/helper/agreement';
import {
  AGREEMENT_STEP_BASIC_INFORMATIONS,
  AGREEMENT_STEP_CONTRACTOR_EXECUTOR,
  AGREEMENT_STEP_REWARD,
  AGREEMENT_STEP_SUBJECT_ORDER_FORM
} from 'modules/Agreements/step';
import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';

const contractorFields = [
  {
    field: 'name',
    data: ':field'
  },
  {
    field: 'address',
    data: ':field'
  },
  {
    field: 'nip',
    data: 'NIP: :field'
  },
  {
    field: 'representative',
    data: 'Reprezentowana przez: :field'
  },
  {
    field: 'countersignature',
    data: 'Kontrasygnata: :field'
  }
];

const executorFields = [
  {
    field: 'name',
    data: ':field'
  },
  {
    field: 'address',
    data: ':field'
  },
  {
    field: 'nip',
    data: 'NIP: :field'
  },
  {
    field: 'krs',
    data: 'KRS (jeśli dotyczy): :field'
  },
  {
    field: 'share_capital',
    data: 'Wysokość kapitału zakładowego (jeżeli dotyczy): :field'
  },
  {
    field: 'representative',
    data: 'Reprezentowany / reprezentowana / reprezentowane: :field'
  }
];

const AgreementStepPreviewBasicInformations = (
  props: AgreementStepPreviewProps<AgreementStepBasicInformationsValues>
): JSX.Element => {
  const { agreement, steps } = props;
  const agreementDate = getStepValues<AgreementStepBasicInformationsValues>(steps, AGREEMENT_STEP_BASIC_INFORMATIONS);
  const contractorExecutorDate = getStepValues<AgreementStepContractorExecutorValues>(
    steps,
    AGREEMENT_STEP_CONTRACTOR_EXECUTOR
  );
  const subjectDate = getStepValues<AgreementStepSubjectValues>(steps, AGREEMENT_STEP_SUBJECT_ORDER_FORM);
  const rewardDate = getStepValues<AgreementStepRewardValues>(steps, AGREEMENT_STEP_REWARD);

  const { value: placeContent } = getFieldForPreview(agreementDate, null, 'place_content');

  const { value: dateVaule } = getFieldForPreview(agreementDate, null, 'agreement_date');

  const { value: orderingPerson } = getFieldForPreview(agreementDate, null, 'ordering_person');

  const { value: proceedingNameContent } = getFieldForPreview(agreementDate, null, 'proceeding_name_content');

  const { value: numberContent } = getFieldForPreview(agreementDate, null, 'number_content');

  const { value: contractorName } = getFieldForPreview(contractorExecutorDate, null, 'contractor.name');

  const { value: contractorAddress } = getFieldForPreview(contractorExecutorDate, null, 'contractor.address');

  const { value: contractorNip } = getFieldForPreview(contractorExecutorDate, null, 'contractor.nip');

  const { value: contractorRepresentative } = getFieldForPreview(
    contractorExecutorDate,
    null,
    'contractor.representative'
  );

  const { value: contractorCountersignature } = getFieldForPreview(
    contractorExecutorDate,
    null,
    'contractor.countersignature'
  );

  const { value: contractorOfRepresentative } = getFieldForPreview(
    contractorExecutorDate,
    null,
    'contractor.of_representative'
  );

  const { value: executorName } = getFieldForPreview(contractorExecutorDate, null, 'executor.name');

  const { value: executorAddress } = getFieldForPreview(contractorExecutorDate, null, 'executor.address');

  const { value: executorNip } = getFieldForPreview(contractorExecutorDate, null, 'executor.nip');

  const { value: executorRepresentative } = getFieldForPreview(contractorExecutorDate, null, 'executor.representative');

  const { value: executorOfRepresentative } = getFieldForPreview(
    contractorExecutorDate,
    null,
    'executor.of_representative'
  );

  const { value: executorShareCapital } = getFieldForPreview(contractorExecutorDate, null, 'executor.share_capital');

  const { value: agreementSubject } = getFieldForPreview(subjectDate, null, 'agreement_subject');

  const { value: deadlineDate } = getFieldForPreview(subjectDate, null, 'deadline_date');

  const { value: rewardType } = getFieldForPreview(rewardDate, null, 'of_reward_type');

  const { value: ofNettoValue } = getFieldForPreview(rewardDate, null, 'of_netto_value');

  const { value: ofBruttoValue } = getFieldForPreview(rewardDate, null, 'of_brutto_value');

  const { value: contractorGroup } = getFieldForPreview(contractorExecutorDate, null, 'contractor.group');

  const { value: contractorCompanies } = getFieldForPreview(contractorExecutorDate, null, 'contractor.companies');

  const { value: executorGroupType } = getFieldForPreview(contractorExecutorDate, null, 'executor.group_type');

  const { value: executorGroupOther } = getFieldForPreview(contractorExecutorDate, null, 'executor.group_other');

  const { value: executorCompanies } = getFieldForPreview(contractorExecutorDate, null, 'executor.companies');

  const renderField = (field: { field: string; data: string; ifNotEmpty?: boolean }, type: string, index?: number) => {
    const formattedField =
      index === undefined ? `${type}.${field.field}` : `${type}.companies[${index}].${field.field}`;

    const { value } = getFieldForPreview(contractorExecutorDate, null, formattedField);

    if (!value && !(agreement.type === AGREEMENT_TYPE_PROJECT && type === 'executor')) {
      return null;
    }

    const content = field.data.replace(':field', value || PREVIEW_PLACEHOLDER);
    // @ts-ignore
    const formattedContent = content.replaceAll('....', `<span style='background-color:#d4d4d4'>....</span>`);

    return (
      <div key={field.field} className={classNames('mt-1')}>
        <p
          className={classNames('mb-0 wysiwyg-preview')}
          dangerouslySetInnerHTML={{ __html: String(formattedContent) }}
        />
      </div>
    );
  };

  const renderExecutorBeginText = () => {
    let beginning;
    if (executorGroupType === 1) beginning = 'Spółka cywilna';
    if (executorGroupType === 2) beginning = 'Konsorcjum';
    if (executorGroupType === 3) beginning = executorGroupOther || 'Inna forma zgrupowania';

    return `${beginning} w składzie:`;
  };

  const getBasicInformationText = (): string => {
    const content = `${placeContent || '....'}, dnia ${dateVaule || '....'} roku`;

    // @ts-ignore
    return content.replaceAll('....', `<span style='background-color:#d4d4d4'>....</span>`);
  };

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-content reset-preview-point p-2">
        <p
          className={classNames('wysiwyg-preview text-right')}
          dangerouslySetInnerHTML={{ __html: String(getBasicInformationText()) }}
        />
        {(contractorName ||
          contractorAddress ||
          contractorNip ||
          contractorRepresentative ||
          contractorCountersignature ||
          !_isEmpty(contractorCompanies)) && (
          <>
            <p className="h5">Zamawiający:</p>
            {contractorGroup &&
              contractorCompanies.map((_: any, index: number) => {
                return (
                  <>
                    <span>{index + 1}.</span>
                    {contractorFields.map((field) => renderField(field, 'contractor', index))}
                  </>
                );
              })}
            {!contractorGroup && (
              <div className="mt-3">{contractorFields.map((field) => renderField(field, 'contractor'))}</div>
            )}
          </>
        )}
        {proceedingNameContent && (
          <>
            <p className="h5">Nazwa postępowania:</p>
            <p>{proceedingNameContent}</p>
          </>
        )}
        {numberContent && (
          <>
            <p className="h5">Identyfikator:</p>
            <p>{numberContent}</p>
          </>
        )}
        <p className="h3 text-center mb-3">Formularz zamówienia</p>
        {agreementSubject && (
          <>
            <p className="h5 preview-point">Przedmiot zamówienia:</p>
            <p
              className={classNames('wysiwyg-preview')}
              dangerouslySetInnerHTML={{ __html: String(agreementSubject) }}
            />
          </>
        )}
        {contractorOfRepresentative && (
          <>
            <p className="h5 preview-point">Osoba odpowiedzialna za zamówienie po stronie zamawiającego:</p>
            <p>{contractorOfRepresentative}</p>
          </>
        )}
        {executorOfRepresentative && (
          <>
            <p className="h5 preview-point">Osoba odpowiedzialna za zamówienie po stronie wykonawcy:</p>
            <p>{executorOfRepresentative}</p>
          </>
        )}
        {deadlineDate && (
          <>
            <p className="h5 preview-point">Termin wykonania zamówienia:</p>
            <p>{deadlineDate}</p>
          </>
        )}
        {ofNettoValue && (
          <>
            <p className="h5 preview-point">Wynagrodzenie (netto):</p>
            <p>{ofNettoValue}</p>
          </>
        )}
        {ofBruttoValue && (
          <>
            <p className="h5 preview-point">Wynagrodzenie (brutto):</p>
            <p>{ofBruttoValue}</p>
          </>
        )}
        {rewardType && (
          <>
            <p className="h5 preview-point">Charakter wynagrodzenia:</p>
            <p>{rewardType === 1 ? 'Ryczałtowe' : 'Kosztorysowe'}</p>
          </>
        )}
        {(executorName ||
          executorAddress ||
          executorNip ||
          executorRepresentative ||
          executorShareCapital ||
          !_isEmpty(executorCompanies)) && (
          <>
            <p className="h5 preview-point">Wykonawca:</p>
            {executorGroupType && <div className="mt-2">{renderExecutorBeginText()}</div>}
            {executorGroupType &&
              executorCompanies.map((_: any, index: number) => {
                return (
                  <>
                    <span>{index + 1}.</span>
                    {executorFields.map((field) => renderField(field, 'executor', index))}
                  </>
                );
              })}
            {!executorGroupType && (
              <div className="mt-3">{executorFields.map((field) => renderField(field, 'executor'))}</div>
            )}
          </>
        )}
        <p className="text-right">{orderingPerson}</p>
      </div>
    </div>
  );
};

export default AgreementStepPreviewBasicInformations;
