import React from 'react';
import {
  AGREEMENT_MODE_ORDER_FORM,
  AgreementFormConfiguration,
  AgreementFormStage,
  AgreementFormStep,
  AgreementMode
} from 'modules/Agreements/type';
import { getAgreementModeName, getStageName, getStepName } from 'modules/Agreements/helper/agreement';
import classNames from 'classnames';
import './index.scss';
import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import { AGREEMENT_STEP_CONTRACT_SING, AgreementStageSlug, AgreementStepSlug } from 'modules/Agreements/step';
import { Button, UncontrolledTooltip } from 'reactstrap';
import StepModifiedModal from 'modules/Agreements/components/Modal/StepModified';
import DownloadMissingStepsModal from 'modules/Agreements/components/Modal/DownloadMissingSteps';
import useAgreementForm from 'modules/Agreements/hook/useAgreementForm';
import AgreementStepPreviewAttachments from '../Preview/Default/Attachments';

export interface PathParams {
  id?: string;
  stage?: AgreementStageSlug;
  step?: AgreementStepSlug;
}

export interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  configuration: AgreementFormConfiguration;
  // eslint-disable-next-line react/require-default-props,react/no-unused-prop-types
  mode?: AgreementMode;
}

const AgreementForm = (props: Props): JSX.Element => {
  const {
    initFetching,
    currentStage,
    currentStep,
    filledSteps,
    hiddenSteps,
    agreement,
    steps,
    stepsImportData,
    modifiedStep,
    modifiedModal,
    fetching,
    stages,
    fetchingDoc,
    validation,
    mode,
    proceeding,
    hidePreview,
    onSubmitStep,
    initFetch,
    onToggleModifiedModal,
    onSubmitModifiedModal,
    goTo,
    setIsModified,
    setModifiedStep,
    downloadAgreement,
    setHidePreview,
    downloadMissingSteps,
    downloadMissingStepsModal,
    setDownloadMissingStepsModal
  } = useAgreementForm(props);

  if (initFetching) {
    return (
      <div className="agreement">
        <Loader />
      </div>
    );
  }

  const renderStage = (stage: AgreementFormStage) => {
    const { slug, disabled, steps: stageSteps } = stage;

    if (disabled) {
      return (
        <div key={slug} className="agreement-stage disabled">
          {getStageName(slug)}
        </div>
      );
    }

    const active = slug === currentStage.slug;

    return (
      <Button
        key={slug}
        color="link"
        className={classNames('agreement-stage', {
          active
        })}
        onClick={() => {
          if (!active) {
            goTo(slug, stageSteps[0].slug);
          }
        }}
      >
        {getStageName(slug)}
      </Button>
    );
  };

  const renderStep = (step: AgreementFormStep) => {
    const { slug } = step;
    const active = slug === currentStep.slug;
    const hideContractSing = downloadMissingSteps.length > 0 && slug === AGREEMENT_STEP_CONTRACT_SING;

    if (hiddenSteps.includes(slug)) return null;

    return (
      !hideContractSing && (
        <Button
          key={slug}
          color="link"
          className={classNames('agreement-step', {
            active,
            filled: filledSteps.includes(slug)
          })}
          onClick={() => {
            if (!active) {
              goTo(currentStage.slug, slug);
            }
          }}
        >
          {getStepName(slug, mode)}
        </Button>
      )
    );
  };

  const renderPreview = (step: AgreementFormStep) => {
    const { slug } = step;
    const isFilled = filledSteps.includes(slug);
    const isOpen = currentStep.slug === slug;

    if ((isFilled || isOpen || mode == AGREEMENT_MODE_ORDER_FORM) && step.previewComponent) {
      return (
        <div
          key={slug}
          className={classNames(`agreement-step-${slug}`, {
            modified: !isFilled && mode !== AGREEMENT_MODE_ORDER_FORM
          })}
        >
          <step.previewComponent
            agreement={agreement}
            steps={steps}
            proceeding={proceeding}
            modifiedStep={isOpen ? modifiedStep : null}
          />
        </div>
      );
    }

    return null;
  };

  const handleAgreementTypeChange = (newType: string) => {
    agreement.type = newType;
  };

  return (
    <div className="agreement">
      {fetching && <Loader />}
      <StepModifiedModal
        isOpen={Boolean(modifiedModal)}
        toggle={onToggleModifiedModal}
        submit={onSubmitModifiedModal}
      />
      <DownloadMissingStepsModal
        isOpen={downloadMissingStepsModal}
        downloadMissingSteps={downloadMissingSteps}
        toggle={setDownloadMissingStepsModal}
        mode={mode}
      />
      <div className="agreement-stages">{stages.map(renderStage)}</div>
      <div className="agreement-content">
        <div className="agreement-steps">{currentStage.steps.map(renderStep)}</div>
        <div className="agreement-form">
          <div className="agreement-form-top">
            <div className="d-flex justify-content-between">
              <div className="agreement-step-name">{getStepName(currentStep.slug, mode)}</div>
              {(currentStage.preview === undefined || currentStage.preview) && (
                <div>
                  <Button
                    id="toggle-step-btn"
                    type="button"
                    color="link"
                    className="menu-toggle waves-effect waves-light p-0 m-0"
                    onClick={() => setHidePreview(!hidePreview)}
                  >
                    <i className="fe-menu font-24" />
                  </Button>
                  <UncontrolledTooltip
                    className="toggle-sidebar-tooltip"
                    placement="bottom-end"
                    target="#toggle-step-btn"
                  >
                    {hidePreview ? 'Pokaż' : 'Ukryj'} Podgląd umowy
                  </UncontrolledTooltip>
                </div>
              )}
            </div>
            {Boolean(validation?.message) && <Alert message={validation?.message} />}
          </div>
          <div className="agreement-form-bottom">
            <currentStep.component
              key={parseInt(agreement?.id, 10)}
              onSubmit={onSubmitStep}
              errors={validation?.errors}
              agreement={agreement}
              downloadAgreement={downloadAgreement}
              AgreementTypeChange={handleAgreementTypeChange}
              missing_steps={downloadMissingSteps}
              proceeding={proceeding}
              onChange={(step, modified = null) => {
                setIsModified(modified);
                setModifiedStep(step);
              }}
              steps={steps}
              stepsImportData={stepsImportData}
              initFetch={initFetch}
            />
          </div>
        </div>
        {(currentStage.preview === undefined || currentStage.preview) && !hidePreview && (
          <div className="agreement-preview">
            <div className="agreement-preview-action">
              <div className="agreement-preview-title">Podgląd umowy</div>
              <Button
                onClick={downloadAgreement}
                color="link"
                className="agreement-download p-0 link-with-border position-relative"
              >
                {fetchingDoc && <Loader />}
                POBIERZ UMOWĘ
              </Button>
            </div>
            <div className="agreement-preview-content">
              {mode !== AGREEMENT_MODE_ORDER_FORM && <p className="h4 agreement-mode">{getAgreementModeName(mode)}</p>}
              {currentStage.steps.map(renderPreview)}
              <AgreementStepPreviewAttachments
                agreement={agreement}
                steps={steps}
                proceeding={proceeding}
                modifiedStep={modifiedStep?.deadlines ? modifiedStep : null}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AgreementForm;
