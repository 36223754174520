import { getError, hasError } from 'modules/Shared/helper/validation';
import React from 'react';
import { CustomInput, FormFeedback, FormGroup } from 'reactstrap';
import _uniqueId from 'lodash/uniqueId';
import { Props } from 'modules/Proceedings/component/Fieldset/Main';

const ProceedingsFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, socialClausesWysiwyg, orderObjectWysiwyg, otherContactWysiwyg, errors, setValues } = props;

  const {
    email_contact_checked = false,
    epd2_contact_checked = false,
    post_contact_checked = false,
    other_contact_checked = false
  } = values;

  return (
    <>
      <FormGroup>
        <CustomInput
          type="switch"
          id="checkox-email_contact_checked"
          label="Czy w postępowaniu jest możliwa forma kontaktu poprzez email?"
          checked={email_contact_checked}
          onChange={(event) => setValues({ ...values, email_contact_checked: event.target.checked })}
        />
      </FormGroup>
      <FormGroup>
        <CustomInput
          type="switch"
          id="checkox-epd2_contact_checked"
          label="Czy w postępowaniu jest możliwa forma kontaktu poprzez system Asystent Postępowania?"
          checked={epd2_contact_checked}
          onChange={(event) => setValues({ ...values, epd2_contact_checked: event.target.checked })}
        />
      </FormGroup>
      <FormGroup>
        <CustomInput
          type="switch"
          id="checkox-post_contact_checked"
          label="Czy w postępowaniu jest możliwa forma kontaktu pocztą?"
          checked={post_contact_checked}
          onChange={(event) => setValues({ ...values, post_contact_checked: event.target.checked })}
        />
      </FormGroup>
      <FormGroup>
        <CustomInput
          type="switch"
          id="checkox-other_contact_checked"
          label="Czy w postępowaniu jest możliwa inna forma kontaktu?"
          checked={other_contact_checked}
          onChange={(event) => setValues({ ...values, other_contact_checked: event.target.checked })}
        />
      </FormGroup>
      {other_contact_checked && (
        <FormGroup>
          {otherContactWysiwyg && otherContactWysiwyg()}

          {hasError(errors, 'other_contact_content') && (
            <FormFeedback className="d-block">{getError(errors, 'other_contact_content')}</FormFeedback>
          )}
        </FormGroup>
      )}
      <FormGroup>
        {socialClausesWysiwyg && socialClausesWysiwyg()}

        {hasError(errors, 'social_clauses') && (
          <FormFeedback className="d-block">{getError(errors, 'social_clauses')}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        {orderObjectWysiwyg && orderObjectWysiwyg()}

        {hasError(errors, 'order_object') && (
          <FormFeedback className="d-block">{getError(errors, 'order_object')}</FormFeedback>
        )}
      </FormGroup>
    </>
  );
};

export default ProceedingsFieldset;
