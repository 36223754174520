import { ValidationErrors } from 'modules/Shared/type';
import React, { useState } from 'react';
import { Button, Form as FormStrap } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_CLAUSE, ROUTE_CLAUSE_LIST } from 'modules/Clause/routes';
import Clause from 'modules/Clause/model/Clause';
import ClauseFieldset from 'modules/Clause/component/Fieldset';
import { ClauseFieldValues, ClauseFieldValuesState } from 'modules/Clause/type';
import useWysiwyg from 'modules/Layout/component/Wysiwyg';

export interface Props {
  errors?: ValidationErrors;
  disabled?: boolean;
  clause?: Clause;
  submit: (data: ClauseFieldValues) => void;
}

const createValues = (clause?: Clause) => {
  const valueObj: ClauseFieldValuesState = {
    name: clause?.name ?? '',
    category: { name: clause?.category?.name ?? '', id: clause?.category?.id ?? null },
    keywords: clause?.keywords?.map((keyword) => keyword.id) ?? []
  };

  return valueObj;
};

const ClauseForm: React.FC<Props> = ({ errors, disabled, submit, clause }) => {
  const [values, setValue] = useState(createValues(clause));

  const { displayWysiwyg, getContent } = useWysiwyg({
    content: clause?.description,
    title: 'Tekst wyjaśniający klauzulę',
    tooltip: { specialSign: true }
  });

  const onChangeValue = (value: keyof ClauseFieldValuesState, key: string) => setValue({ ...values, [key]: value });

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { category, ...rest } = values;

    submit({ ...rest, description: getContent(), category_id: category.id });
  };

  const hasValue = () => !values.name;

  return (
    <FormStrap onSubmit={onSubmit}>
      <ClauseFieldset
        disabled={disabled}
        errors={errors}
        values={values}
        displayWysiwyg={displayWysiwyg}
        onChange={onChangeValue}
        required
      />

      <div className="form-actions">
        <Button
          type="submit"
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light btn btn-primary"
          disabled={hasValue() || disabled}
        >
          Zapisz
        </Button>
        <Link
          to={clause ? getPathUrl(ROUTE_CLAUSE, { id: clause.id }) : ROUTE_CLAUSE_LIST}
          className="cancel waves-effect waves-light"
        >
          Anuluj
        </Link>
      </div>
    </FormStrap>
  );
};

export default ClauseForm;
