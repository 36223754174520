import React, { useEffect } from 'react';
import { createSearchingProps } from 'modules/Shared/helper/params';
import { useDispatch } from 'react-redux';
import { managePageAction } from 'modules/Layout/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { setProceedingsListParamsAction } from 'modules/Proceedings/action/ProceedingsList';
import Proceedings from 'modules/Proceedings/model/Proceedings';
import { ROUTE_PROCEEDINGS_PUBLIC_OPEN, ROUTE_PROCEEDINGS_PUBLIC_PLANNING } from 'modules/Proceedings/routes';
import PublicWrapper from 'modules/Layout/component/Wrapper/Public';
import { breadcrumbPublicRouteProceedings } from 'modules/Proceedings/breadcrumbs';
import withPublicAccessHeader from 'modules/Layout/component/Wrapper/Public/withPublicAccessHeader';
import PublicWrapperWithMenu from 'modules/Layout/component/Wrapper/Public/PublicWrapperWithMenu';
import usePublicProceedingsList from 'modules/Proceedings/component/Lists/PublicListHooks';
import { PROCEEDINGS_INIT_TYPES_VALUES } from 'modules/Proceedings/type';

export const ProceedingsListPublicPlanningView = () => {
  const dispatch = useDispatch();

  const setParams = (payload: PartialSearchingProps) => dispatch(setProceedingsListParamsAction(payload));

  const searchProps = createSearchingProps(
    '',
    Proceedings.getFilterableAttributes(),
    Proceedings.getSortableAttributes()
  );

  useEffect(() => {
    dispatch(
      managePageAction({
        title: 'Wyszukiwarka planowanych postępowań - lista'
      })
    );

    setParams({ ...searchProps, filter: { proceeding_type: PROCEEDINGS_INIT_TYPES_VALUES.PLANNING } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { displayHeaderElementJsx } = withPublicAccessHeader();
  const { jsxFnc } = usePublicProceedingsList(
    ROUTE_PROCEEDINGS_PUBLIC_PLANNING,
    PROCEEDINGS_INIT_TYPES_VALUES.PLANNING
  );

  return (
    <PublicWrapper displayHeaderElement={displayHeaderElementJsx}>
      <div style={{ marginTop: '-157px' }}>
        <PublicWrapperWithMenu
          defaultBreadcrumbItem={breadcrumbPublicRouteProceedings(PROCEEDINGS_INIT_TYPES_VALUES.PLANNING) as any}
          noSideNavBar
        >
          {jsxFnc()}
        </PublicWrapperWithMenu>
      </div>
    </PublicWrapper>
  );
};

export const ProceedingsListPublicOpenView = () => {
  const dispatch = useDispatch();

  const setParams = (payload: PartialSearchingProps) => dispatch(setProceedingsListParamsAction(payload));

  const searchProps = createSearchingProps(
    '',
    Proceedings.getFilterableAttributes(),
    Proceedings.getSortableAttributes()
  );

  useEffect(() => {
    dispatch(
      managePageAction({
        title: 'Wyszukiwarka prowadzonych postępowań - lista'
      })
    );

    setParams({ ...searchProps, filter: { proceeding_type: PROCEEDINGS_INIT_TYPES_VALUES.OPEN } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { displayHeaderElementJsx } = withPublicAccessHeader();
  const { jsxFnc } = usePublicProceedingsList(ROUTE_PROCEEDINGS_PUBLIC_OPEN, PROCEEDINGS_INIT_TYPES_VALUES.OPEN);

  return (
    <PublicWrapper displayHeaderElement={displayHeaderElementJsx}>
      <div style={{ marginTop: '-157px' }}>
        <PublicWrapperWithMenu
          defaultBreadcrumbItem={breadcrumbPublicRouteProceedings(PROCEEDINGS_INIT_TYPES_VALUES.OPEN) as any}
          noSideNavBar
        >
          {jsxFnc()}
        </PublicWrapperWithMenu>
      </div>
    </PublicWrapper>
  );
};
