import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import Proceedings from 'modules/Proceedings/model/Proceedings';
import ProceedingType from 'modules/Proceedings/component/Fieldset/Main/ConditionalInputs/proceedingType';
import ProceedingTypeBzpDate from 'modules/Proceedings/component/Fieldset/Main/ConditionalInputs/proceedingTypeBzpDate';
import ProceedingTypeShoppingPlatform from 'modules/Proceedings/component/Fieldset/Main/ConditionalInputs/proceedingTypeShoppingPlatform';
import Selects from 'modules/Proceedings/component/Fieldset/Main/NormalInputs/selects';
import FirstPart from 'modules/Proceedings/component/Fieldset/Main/NormalInputs/firstPart';
import SecondPart from 'modules/Proceedings/component/Fieldset/Main/NormalInputs/secondPart';
import Custom from 'modules/Proceedings/component/Fieldset/Main/NormalInputs/custom';
import ThirdPart from 'modules/Proceedings/component/Fieldset/Main/NormalInputs/thirdPart';
import { PROCEEDINGS_INIT_TYPES_VALUES } from 'modules/Proceedings/type';

export interface Props {
  values: Proceedings & { keywords: string[] };
  errors?: ValidationErrors;
  disabled?: boolean;
  required?: boolean;
  setValues: (value: Proceedings & { keywords: string[] }) => void;
  socialClausesWysiwyg: () => JSX.Element;
  orderObjectWysiwyg: () => JSX.Element;
  otherContactWysiwyg: () => JSX.Element;
  answerPlaceWysiwyg: () => JSX.Element;
  answerWayWysiwyg: () => JSX.Element;
  placeholder?: boolean;
  isEditing?: boolean;
}

const ProceedingsFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { disabled, values } = props;

  const splitElements =
    values?.proceeding_type === PROCEEDINGS_INIT_TYPES_VALUES.OPEN ? (
      <div className="row col-12">
        <div className="col-12 col-lg-6">
          <ProceedingType {...props} />
          <Selects {...props} />
          <FirstPart {...props} />
          <ProceedingTypeBzpDate {...props} />
        </div>
        <div className="col-12 col-lg-6">
          <SecondPart {...props} />
          <Custom {...props} />
          <ThirdPart {...props} />
          <ProceedingTypeShoppingPlatform {...props} />
        </div>
      </div>
    ) : (
      <div className="row col-12">
        <div className="col-12 col-lg-6">
          <ProceedingType {...props} />
          <Selects {...props} />
          <FirstPart {...props} />
          <ProceedingTypeBzpDate {...props} />
          <SecondPart {...props} />
        </div>
        <div className="col-12 col-lg-6">
          <Custom {...props} />
          <ThirdPart {...props} />
          <ProceedingTypeShoppingPlatform {...props} />
        </div>
      </div>
    );

  return (
    <fieldset className="m-0" disabled={disabled}>
      {splitElements}
    </fieldset>
  );
};

export default ProceedingsFieldset;
