import { ROUTE_LOGIN } from 'modules/Auth/routes';
import PZPlogo from 'modules/Layout/component/Navbar/logo/pzp/pzp_logo.png';
import BSlogo from 'modules/Layout/component/Navbar/logo/bs/bs_logo.png';
import React from 'react';

export interface Props {
  route?: string;
  height?: number;
  target?: string;
}

const Logo: React.FC<Props> = (props: Props): JSX.Element => {
  const { route = ROUTE_LOGIN, height = 60, target = '_self' } = props;

  const logos = {
    pzp: PZPlogo,
    bs: BSlogo
  };

  const themeTemplate = process.env.THEME_TEMPLATE as 'pzp' | 'bs';
  const logo = logos[themeTemplate];

  return (
    <a target={target} href={route} className="auth-logo d-inline-flex">
      <img src={logo} alt="logo Asystent Postępowania" height={height} />
    </a>
  );
};

export default Logo;
