import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { PageProps } from '../../type';
import { managePageAction } from '../../action';
import { breadcrumbRouteDeclarationOfAvailability } from '../../breadcrumbs';
import CardBox from '../../component/CardBox';
import mobile1 from './1.1.jpg';
import mobile2 from './1.2.jpg';
import mobile3 from './1.3.jpg';
import desktop1 from './2.1.png';
import desktop2 from './2.2.png';
import desktop3 from './3.3.png';

const AccountDeletion: FC = () => {
  const dispatch = useDispatch();
  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const [currentView, setCurrentView] = useState<string>('desktop');
  const [overlayImage, setOverlayImage] = useState(desktop1);
  const [overlayOpen, setOverlayOpen] = useState<boolean>(false);

  const handleChangeView = () => {
    if (currentView === 'desktop') {
      setCurrentView('mobile');
    } else {
      setCurrentView('desktop');
    }
  };

  const handleOverlayImage = (image: any) => {
    setOverlayImage(image);
    setOverlayOpen(true);
  };

  const desktopList = [
    {
      text: 'wybrać w menu bocznym po lewej stronie funkcję "Profil użytkownika";',
      image: desktop1
    },
    {
      text: 'w danych użytkownika wybrać "Usuń użytkownika";',
      image: desktop2
    },
    {
      text: 'w pojawiającym się dodatkowym pytaniu "Czy na pewno chcesz usunąć konto?" wybrać "Usuń".',
      image: desktop3
    }
  ];
  const mobileList = [
    {
      text: 'wybrać w menu bocznym po lewej stronie funkcję "Profil użytkownika";',
      image: mobile1
    },
    {
      text: 'w danych użytkownika wybrać "Usuń użytkownika";',
      image: mobile2
    },
    {
      text: 'w pojawiającym się dodatkowym pytaniu "Czy na pewno chcesz usunąć konto?" wybrać "Usuń".',
      image: mobile3
    }
  ];
  useEffect(() => {
    const title = 'Usunięcie konta';

    managePage({
      title,
      breadcrumb: breadcrumbRouteDeclarationOfAvailability()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="contact-view row justify-content-center pl-md-5 pr-md-5">
        <div className="col-12">
          <CardBox paddingRight="30px" paddingLeft="30px" paddingBottom="30px" childrenPaddingTop="5px">
            <div className="row justify-content-between">
              <strong>W celu usunięcia konta użytkownika należy:</strong>
              <Button onClick={handleChangeView}>Wersja {currentView === 'desktop' ? 'mobilna' : 'webowa'}</Button>
            </div>
            <br />
            <br />
            <ol className="public-delete-acc-list">
              <li className="public-delete-acc-card">zalogować się na swoje konto w aplikacji;</li>
              {currentView === 'desktop'
                ? desktopList.map(({ text, image }) => (
                    <li key={text} className="public-delete-acc-card">
                      {text}
                      <Button onClick={() => handleOverlayImage(image)}>
                        <img src={image} alt={text} />
                      </Button>
                    </li>
                  ))
                : mobileList.map(({ text, image }) => (
                    <li key={text} className="public-delete-acc-card">
                      {text}
                      <Button onClick={() => handleOverlayImage(image)}>
                        <img src={image} alt={text} />
                      </Button>
                    </li>
                  ))}
            </ol>
            <br />W przypadku potrzeby zgłoszenia przez użytkownika prośby o usunięcie konta jeśli aplikacja została
            odinstalowana, użytkownik może zgłosić taką prośbę na adres e-mail:{' '}
            <a href="mailto:biuro@postepowania.pl">
              <span id="a11y-email">biuro@postepowania.pl</span>
            </a>
          </CardBox>
        </div>
      </div>
      {overlayOpen && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div onClick={() => setOverlayOpen(false)} className="overlay-image">
          <div className="overlay-image-wrapper">
            <img src={overlayImage} alt="overlay" />
          </div>
        </div>
      )}
    </>
  );
};

export default AccountDeletion;
