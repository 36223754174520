/* eslint-disable */
import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function DownloadIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px', className = 'icon-svg-table' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} height={height} viewBox="0 0 13.347 17.705">
      <g id="Group_449" data-name="Group 449" transform="translate(-530.034 -840.005)">
        <path
          id="Path_1327"
          data-name="Path 1327"
          d="M543.534,840.5v2.905h2.905"
          transform="translate(-3.558)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1328"
          data-name="Path 1328"
          d="M542.881,856.629a.58.58,0,0,1-.575.581h-11.2a.578.578,0,0,1-.572-.581V841.09a.581.581,0,0,1,.572-.585h8.87a.421.421,0,0,1,.328.171l2.406,2.373a.472.472,0,0,1,.172.361Z"
          transform="translate(0 0)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_85"
          data-name="Line 85"
          y1="9.399"
          transform="translate(536.358 844.9)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1329"
          data-name="Path 1329"
          d="M540.051,856.382l-2.4,2.262-2.4-2.262"
          transform="translate(-1.289 -4.346)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
