import { LocationDescriptor, Location } from 'history';
import CardBox from 'modules/Layout/component/CardBox';
import User from 'modules/User/model/User';
import React from 'react';
import FieldsetRodo from 'modules/Regulation/component/Fieldset/Rodo';
import { Link } from 'react-router-dom';

type Props = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  redirectUrl?: LocationDescriptor<{}> | ((location: Location<{}>) => LocationDescriptor<{}>);
  user: User;
};

const RodoDetailsWrapper: React.FC<Props> = ({ redirectUrl, user }) => {
  return (
    <CardBox
      heading="RODO"
      paddingLeft="30px"
      paddingRight="30px"
      actions={
        redirectUrl && (
          <Link className="link-with-border" to={redirectUrl}>
            EDYTUJ ZGODY
          </Link>
        )
      }
    >
      <FieldsetRodo values={user ?? {}} setChange={() => null} disabled />
    </CardBox>
  );
};

export default RodoDetailsWrapper;
