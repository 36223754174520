import { useEffect, useState } from 'react';
import { fetchPaymentLog } from 'modules/Profile/repository';
import { PaymentLogsValues } from 'modules/Profile/type';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';

const usePaymentLog = (user_id: string | number, payment_id: string | number) => {
  const [message, setMessage] = useState<Message>(null);
  const [paymentLog, setPaymentLog] = useState<PaymentLogsValues>(null);
  const [fetching, setFetching] = useState(false);

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const {
        data,
        cancelled,
        message: messageResponse
      } = await fetchRequest(fetchPaymentLog, user_id, payment_id, cancelToken);

      if (cancelled) return;
      if (data) setPaymentLog(data.data);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { message, paymentLog, fetching };
};

export default usePaymentLog;
