import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import { AlertFormData } from '../../Form/AlertForm';
import { foreignProceedingsStatus } from '../../../model/ForeignProceedings';

export interface Props {
  alertDetails: AlertFormData;
}

const AlertsDataset: React.FC<Props> = ({ alertDetails }): JSX.Element => {
  const fields = [
    {
      label: 'Nazwa aleru',
      value: alertDetails?.name
    },
    {
      label: 'Nazwa postępowania',
      value: alertDetails?.filters?.name || 'Nie dotyczy'
    },
    {
      label: 'Zamawiający',
      value: alertDetails?.filters?.ordered_by || 'Nie dotyczy'
    },
    {
      label: 'Numer referencyjny',
      value: alertDetails?.filters?.ref_nr || 'Nie dotyczy'
    },
    {
      label: 'Rynek',
      value: Array.isArray(alertDetails?.filters?.slug_source)
        ? alertDetails.filters.slug_source.join(', ')
        : 'Nie dotyczy'
    },
    {
      label: 'Kraj',
      value: alertDetails?.filters?.country_code || 'Nie dotyczy'
    },
    {
      label: 'Procedura',
      value: alertDetails?.filters?.procedure || 'Nie dotyczy'
    },
    {
      label: 'Status',
      value: foreignProceedingsStatus(Number(alertDetails?.filters?.status)) || 'Nie dotyczy'
    },
    {
      label: 'Branże',
      value: alertDetails?.filters?.industry_type_id || 'Nie dotyczy'
    },
    {
      label: 'Branże podgrupa',
      value: alertDetails?.filters?.industry_sub_type_id || 'Nie dotyczy'
    }
  ];

  return <InformationList fields={fields} />;
};

export default AlertsDataset;
