import axios, { CancelTokenSource } from 'axios';
import { fetchPreambleAgreementsToastError } from 'modules/Agreements/toasts';
import { addToastAction } from 'modules/Layout/action';
import Autocomplete from 'modules/Layout/component/Autocomplete';
import { AgreementEntity } from 'modules/Agreements/model/Agreements';
import { fetchAgreements } from 'modules/Agreements/repository';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { createSearchParams } from 'modules/Shared/helper/params';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAgreementModeName } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_TYPE_PROJECT } from 'modules/Agreements/type';

type Props = {
  agreementValue: { name: string; id: number };
  onAgreementChange: (newAgreement: any) => void;
  required?: boolean;
};

// eslint-disable-next-line react/display-name
const PreambleAgreementsAutocomplete: React.FC<Props> = React.memo(
  ({ agreementValue, onAgreementChange, required }) => {
    const dispatch = useDispatch();
    const [fetching, setFetching] = useState(false);
    const [AgreementsList, setAgreementsList] = useState<AgreementEntity[]>([]);

    const dispatchFetchAgreementsToastError = (messageValue: string) =>
      dispatch(addToastAction(fetchPreambleAgreementsToastError(messageValue)));

    const cancelToken: React.MutableRefObject<CancelTokenSource> = useRef(null);

    const fetchAgreementsList = async (name?: string) => {
      if (cancelToken.current) {
        cancelToken.current.cancel('Operation canceled due to new request.');
      }
      cancelToken.current = axios.CancelToken.source();

      const filter = name ? { name } : {};
      const searchParams = createSearchParams({
        filter: { ...filter, type: AGREEMENT_TYPE_PROJECT },
        pagination: { per_page: 9999 }
      });

      setFetching(true);

      const { data, cancelled, message } = await fetchRequest(fetchAgreements, searchParams, cancelToken.current.token);

      if (cancelled) return;
      if (data) {
        const formattedData = data.data.map((item) => {
          if (!item.name) {
            const { mode, updated_at } = item;
            return {
              ...item,
              name: `${getAgreementModeName(mode)} - ${updated_at}`
            };
          }

          return item;
        });

        setAgreementsList(formattedData);
        setFetching(false);
      }
      if (message && message.value !== 'Operation canceled due to new request.') {
        if (fetching) setFetching(false);
        dispatchFetchAgreementsToastError(message.value);
      }
    };

    useEffect(() => {
      return () => {
        if (cancelToken.current) {
          cancelToken.current.cancel('AxiosCancel');
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeValue = (name: string) => {
      const findAgreement = AgreementsList.find((agreement) => agreement.name === name);

      if (findAgreement) onAgreementChange(findAgreement);
      else onAgreementChange({ name: '', id: null });
    };

    const clearList = () => {
      if (cancelToken.current) {
        cancelToken.current.cancel('Operation canceled due to new request.');
      }
    };

    const filterAgreementsList = AgreementsList.filter((el) => el.id !== agreementValue.id);

    return (
      <Autocomplete
        currentValue={agreementValue.name}
        onChangeCurrentValue={onChangeValue}
        searchRequest={fetchAgreementsList}
        fetching={fetching}
        clearList={clearList}
        list={filterAgreementsList as never}
        label=""
        required={required}
      />
    );
  }
);

export default PreambleAgreementsAutocomplete;
