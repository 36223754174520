import React, { useEffect, useState } from 'react';
import { Message } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import CardBox from 'modules/Layout/component/CardBox';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import Judgement from 'modules/Judgement/model/Judgement';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { fetchJudgmentClientDetailsKeywords } from 'modules/JudgementClient/repository';
import BasicChip from 'modules/Layout/component/Chips/Basic';
import { ContentObjType } from 'modules/JudgementClient/component/Details/Judgement';
import { useDispatch } from 'react-redux';
import { resetJudgementClientListAction } from 'modules/JudgementClient/action/JudgmentList';

export interface Props {
  judgment?: Judgement;
  keywordsIncluded: JudgementElementEntity[];
  setKeywordsIncluded: (arr: JudgementElementEntity[]) => void;
  setContentObj: (obj: ContentObjType) => void;
  activeKeyword: ContentObjType;
  selectedKeyword?: number;
  chipStyle?: { [key: string]: string };
}

const KeywordListIncluded: React.FC<Props> = ({
  judgment = {},
  keywordsIncluded,
  setKeywordsIncluded,
  setContentObj,
  selectedKeyword,
  activeKeyword,
  chipStyle = {}
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);

  const { id: judgmentId } = judgment;

  const cancelToken = useCancelToken();

  const fetchKeywords = async () => {
    setLoading(true);
    const {
      data,
      cancelled,
      message: messageResponse
    } = await fetchRequest(fetchJudgmentClientDetailsKeywords, judgmentId, cancelToken);

    if (cancelled) return;
    if (data) {
      setKeywordsIncluded(data.data);
    }
    if (messageResponse) setMessage(messageResponse);
    setLoading(false);
  };

  useEffect(() => {
    if (judgment) {
      fetchKeywords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [judgment]);

  const setContent = (contentObj: { name: string; content: string; id?: number }) => {
    const { name, content, id } = contentObj;
    setContentObj({ content, headingInfo: `- słowo kluczowe: ${name}`, id, type: 'keyword' });
  };

  useEffect(() => {
    if (selectedKeyword && keywordsIncluded.length) {
      const findKeyword = keywordsIncluded.find((el) => el.id === selectedKeyword);
      if (findKeyword) {
        const { name, content, id } = findKeyword;
        setContent({ name, content, id });
      }
      dispatch(resetJudgementClientListAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordsIncluded]);

  const isActive = (elId: number) => activeKeyword && activeKeyword?.type === 'keyword' && activeKeyword?.id === elId;

  return (
    <>
      <CardBox heading="Słowa kluczowe">
        <div className="row users-view">
          <div className="col-12">
            <Alert message={message} />
            {loading && <Loader />}
            <div className="d-flex flex-wrap">
              {!keywordsIncluded.length && 'brak powiązanych słów kluczowych'}
              {keywordsIncluded.map((el) => {
                // eslint-disable-next-line no-shadow
                const { name, id, content } = el;

                const wrapperClassName = isActive(id) ? 'text-hover text-primary' : 'text-hover';
                const finalChipStype = isActive(id)
                  ? { ...chipStyle, boxShadow: '2px 2px 10px -4px black' }
                  : chipStyle;

                return (
                  <div className={wrapperClassName} key={id}>
                    <BasicChip
                      chipContentElement={{ id, content: name }}
                      chipStyle={finalChipStype}
                      onChipClicked={() => setContent({ name, content, id })}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </CardBox>
    </>
  );
};

export default KeywordListIncluded;

/*

1. ver.

const { paginationComponent, selectedItemsFromArray } = useLocalPagination(keywordsIncluded);

return (
    <>
      <CardBox heading="Słowa kluczowe">
        <div className="row users-view">
          <div className="col-12">
            <Alert message={message} />
            <div className="pzpeu-table-list inside-card-box">
              <Listing
                table={
                  <KeywordsIncludedTable keywordsIncluded={selectedItemsFromArray} onListElementClicked={setContent} />
                }
                pagination={paginationComponent()}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </CardBox>
    </>
  );

*/
