import React from 'react';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_PERSONAL_DATA_PROTECTION } from 'modules/Agreements/step';
import { AgreementStepPersonalDataProtectionValues } from 'modules/Agreements/components/Steps/Default/PersonalDataProtection';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';

const AgreementStepPreviewPersonalDataProtection = (
  props: AgreementStepPreviewProps<AgreementStepPersonalDataProtectionValues>
): JSX.Element => {
  const { steps, modifiedStep } = props;
  const personalDataProtection = getStepValues<AgreementStepPersonalDataProtectionValues>(
    steps,
    AGREEMENT_STEP_PERSONAL_DATA_PROTECTION
  );

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={personalDataProtection}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    personalDataProtection,
    modifiedStep,
    `checkboxes.general`
  );

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_PERSONAL_DATA_PROTECTION)}</div>
      <div className="agreement-step-preview-content reset-preview-point">
        {renderCheckbox('rodo_technical_measures')}
        {renderCheckbox('rodo_agreement')}
        {generalCheckboxes &&
          generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-point wysiwyg-preview', {
                  modified: generalCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AgreementStepPreviewPersonalDataProtection;
