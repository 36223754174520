import { PaginationMeta, SearchingProps } from 'modules/Shared/type';
import { ForeignProceedingsEntity } from '../../model/ForeignProceedings';

export interface ForeignProceedingsListState extends SearchingProps {
  list: ForeignProceedingsEntity[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initForeignProceedingsListState = (): ForeignProceedingsListState => ({
  list: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
