import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterInputType } from 'modules/Layout/type';
import _ from 'lodash';
import SaveFilterModal from 'modules/Layout/component/ListFilter/SaveFilterModal';
import useSaveFilter from 'modules/Shared/helper/hooks/useSaveFilter';
import { REPORT_CLAIMS_SLUG_FILTER } from 'modules/Layout/model/Filters';
import { formatDatetimeLocalForInput, hasFullAccess } from 'modules/Shared/helper/utils';
import { createSearchingProps } from 'modules/Shared/helper/params';
import { setReportClaimsListParamsAction } from '../../../action/reportClaimsList';
import ReportClaimClient from '../../../model/ReportClaim';

const ReportClaimsFilter = () => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.executor.reportClaimsList);
  const user = useSelector((state: RootState) => state.auth.user);

  const setParams = (payload: PartialSearchingProps) => {
    dispatch(setReportClaimsListParamsAction(payload));
  };

  const [filterParams, setFilterParams] = useState<SearchParams>({});

  useEffect(() => {
    if (Object.keys(filter || {}).length && !_.isEqual(filter, filterParams)) {
      setFilterParams(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const onSearchButtonClicked = () => setParams({ filter: filterParams });

  const {
    saveModal,
    setSaveModal,
    cleanMessage,
    loading,
    message,
    filtersList,
    saveFilter,
    onDeleteFilterClick,
    filterList1stFetchFinished,
    onFilterSelectedClick: onFilterSelectedClickFn
  } = useSaveFilter(filter, setParams, REPORT_CLAIMS_SLUG_FILTER);

  const searchProps = createSearchingProps(
    '',
    ReportClaimClient.getFilterableAttributes(),
    ReportClaimClient.getSortableAttributes()
  );

  useEffect(() => {
    if (filterList1stFetchFinished) {
      const findDefault = filtersList.find((el) => el.default);
      if (findDefault) {
        onFilterSelectedClickFn(findDefault.id);
      } else {
        const localStorageParams = localStorage.getItem('localFilterParamsreport_claims');

        if (localStorageParams) {
          const parsedParams = JSON.parse(localStorageParams);

          setParams({ ...parsedParams });
        } else {
          setParams({ ...searchProps });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterList1stFetchFinished]);

  const staticInputsBase: FilterInputType[] = [
    {
      type: 'text',
      property: 'id',
      label: 'Numer zlecenia',
      value: _get(filter, 'id')
    },
    {
      type: 'text',
      property: 'first_name',
      label: 'Imię',
      value: _get(filter, 'first_name')
    },
    {
      type: 'text',
      property: 'last_name',
      label: 'Nazwisko',
      value: _get(filter, 'last_name')
    },
    {
      type: 'text',
      property: 'name',
      label: 'Firma klienta',
      value: _get(filter, 'name')
    },
    {
      beforeLineBreak: true,
      type: 'text',
      property: 'nip',
      label: 'NIP weryfikowanego wykonawcy',
      value: _get(filter, 'nip')
    },
    {
      type: 'select',
      property: 'status',
      label: 'Status wykonania raportu',
      value: _get(filter, 'status'),
      options: [
        {
          label: '',
          value: 0
        },
        {
          label: 'w trakcie wykonania',
          value: 1
        },
        {
          label: 'wykonane',
          value: 2
        }
      ]
    }
  ];

  const getStaticInputs = (): FilterInputType[] => {
    let dynamicInputs: FilterInputType[] = [];

    const createdAtFrom = _get(filter, 'created_at_from') as string;
    const createdAtTo = _get(filter, 'created_at_to') as string;
    const updatedAtFrom = _get(filter, 'updated_at_from') as string;
    const updatedAtTo = _get(filter, 'updated_at_to') as string;

    dynamicInputs = [
      {
        beforeLineBreak: true,
        type: 'datetime',
        property: 'created_at_from',
        label: 'Data zlecenia raportu od',
        value: formatDatetimeLocalForInput(createdAtFrom)
      },
      {
        type: 'datetime',
        property: 'created_at_to',
        label: 'Data zlecenia raportu do',
        value: formatDatetimeLocalForInput(createdAtTo)
      },
      {
        beforeLineBreak: true,
        type: 'datetime',
        property: 'updated_at_from',
        label: 'Data ostatniej aktualizacji raportu od',
        value: formatDatetimeLocalForInput(updatedAtFrom)
      },
      {
        type: 'datetime',
        property: 'updated_at_to',
        label: 'Data ostatniej aktualizacji raportu do',
        value: formatDatetimeLocalForInput(updatedAtTo)
      }
    ];

    return dynamicInputs;
  };

  let disableBtn = false;
  if (_.isEmpty(filter)) {
    disableBtn = true;
  }

  const setMessage = (value: boolean) => {
    if (message) cleanMessage();
    setSaveModal(value);
  };

  const staticInputs = [...staticInputsBase, ...getStaticInputs()];

  return (
    <>
      <ListingFilter
        inputs={staticInputs}
        filter={filterParams}
        pagination={pagination}
        filtersName="report_claims"
        setParams={hasFullAccess(user) ? (params) => setFilterParams(params.filter) : setParams}
        saveFilterBtn={hasFullAccess(user) ? () => setMessage(true) : null}
        filterName={hasFullAccess(user) ? 'Wyszukiwanie' : null}
        disableBtn={disableBtn}
        searchButtonObj={hasFullAccess(user) && { onClick: onSearchButtonClicked }}
        filterObj={{ filtersList, onDeleteFilterClick, onFilterSelectedClick: onFilterSelectedClickFn, loading }}
      />
      {hasFullAccess(user) && saveModal && (
        <SaveFilterModal
          onSaveClick={saveFilter}
          isOpen={saveModal}
          toggle={() => setMessage(false)}
          message={message}
          loading={loading}
          displayDefaultCheckbox
          shouldRefetchList
        />
      )}
    </>
  );
};

export default ReportClaimsFilter;
