import Authorize from 'modules/Auth/container/Authorize';
import Executor from 'modules/Executor/model/Executor';
import CardBox from 'modules/Layout/component/CardBox';
import { getPathUrl } from 'modules/Shared/helper/api';
import ExecutorDataset from 'modules/Executor/component/Dataset/Admin';
import { EXECUTORS_DELETE, EXECUTORS_EDIT } from 'modules/Executor/permissions';
import { ROUTE_EXECUTOR_ADMINS_UPDATE } from 'modules/Executor/routes';
import React from 'react';
import { Link } from 'react-router-dom';
import actionDeleteButtonDetails from 'modules/Shared/helper/actionDeleteButtonDetails';
import './index.scss';

export interface Props {
  executor: Executor;
  onDeleteClick?: () => void;
}

const ExecutorDetails: React.FC<Props> = ({ executor, onDeleteClick }) => {
  return (
    <CardBox
      heading="Dane podstawowe"
      className="executor-details-admin"
      paddingBottom="0"
      paddingRight="30px"
      actions={
        <>
          <Authorize permissions={[EXECUTORS_EDIT]}>
            <Link
              to={{
                pathname: getPathUrl(ROUTE_EXECUTOR_ADMINS_UPDATE, { id: executor?.id }),
                state: { executor }
              }}
              className="link-with-border"
            >
              EDYTUJ
            </Link>
          </Authorize>
          {onDeleteClick && (
            <Authorize permissions={[EXECUTORS_DELETE]}>
              {actionDeleteButtonDetails(executor, {
                value: 'USUŃ WYKONAWCĘ',
                onDeleteClick,
                tooltipContent:
                  'Nie można usunąć, ponieważ co najmniej jeden klient posiada aktywny kredyt na dostęp do tego wykonawcy.',
                targetIdName: 'DeleteExecutorDetailsId'
              })}
            </Authorize>
          )}
        </>
      }
    >
      <ExecutorDataset executor={executor} />
    </CardBox>
  );
};

export default ExecutorDetails;
