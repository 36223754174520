import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import Judgement from 'modules/Judgement/model/Judgement';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';

export type Props = {
  element?: Judgement;
};

const JudgementClientBasicDataset: React.FC<Props> = ({ element }) => {
  const fields = [
    {
      label: 'Identyfikator',
      value: element?.identifier
    },
    {
      label: 'Nazwa organu publikującego dokument',
      value: element?.authority_name
    },
    {
      label: 'Rodzaj organu publikującego dokument',
      value: element?.authority_type
    },
    {
      label: 'Rodzaj dokumentu',
      value: element?.document_type
    },
    {
      label: 'Kategorie przypisane do orzeczenia',
      value: element?.categories_names
    },
    // {
    //   label: 'Słowa kluczowe przypisane do orzeczenia',
    //   value: element?.keywords_names
    // },
    {
      label: 'Oryginalne miejsce publikacji',
      value: element?.place
    },
    {
      label: 'Termin publikacji',
      value: ifContainsDateFormat(element?.published_at, formatDate)
    }
    // {
    //   label: 'Treść publikacji:',
    //   value: element?.content,
    //   html: true
    // }
  ];

  return <InformationList fields={fields} />;
};

export default JudgementClientBasicDataset;
