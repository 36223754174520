import { Button } from 'reactstrap';
import React, { useState } from 'react';
import _ from 'lodash';
import BaseModal from 'modules/Layout/component/Modal';
import { flattObject } from 'modules/Shared/helper/flatObject';
import TooltipWithBtn from 'modules/Layout/component/Tooltip/WithButton';

export interface Props {
  onSubmit: () => void;
  stepDataImportValues: Object;
}

const StepImportDataButton = (props: Props): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { onSubmit, stepDataImportValues } = props;

  const checkIfObjectIsEmpty = () => {
    const flatObject = flattObject(stepDataImportValues);

    return _.values(flatObject).every(_.isEmpty);
  };

  return (
    <div className="d-flex justify-content-end mb-3">
      <TooltipWithBtn
        targetId="StepImportDataButton"
        type="button"
        color="link"
        btnContent="ZAIMPORTUJ DANE Z POSTĘPOWANIA"
        tooltipContent="W tym kroku nie ma danych do importowania"
        className="p-0 link-with-border position-relative"
        disabled={checkIfObjectIsEmpty()}
        onClick={() => setIsModalOpen(true)}
      />

      <BaseModal
        title="Potwierdzenie importowania danych"
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
        onClosed={() => setIsModalOpen(false)}
        body="Czy napewno chcesz nadpisać dane w formularzu danymi z wybranego postępowania?"
        footer={
          <div className="modal-actions">
            <Button
              type="button"
              color="secondary"
              className="waves-effect waves-light"
              onClick={() => setIsModalOpen(false)}
            >
              Anuluj
            </Button>
            <Button
              type="button"
              color="success"
              className="waves-effect waves-light"
              onClick={() => {
                setIsModalOpen(false);
                onSubmit();
              }}
            >
              Importuj
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default StepImportDataButton;
