import React from 'react';
import { Input } from 'reactstrap';

type Props = {
  value: string;
  setValue: (value: string) => void;
  hasError?: boolean;
  maxLength?: number;
  className?: string;
  placeholder?: string;
  id?: string;
};

const TextNumberInput: React.FC<Props> = ({
  value,
  setValue,
  hasError,
  placeholder = null,
  maxLength = null,
  id = '',
  className = ''
}) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;

    const target = event.target.value?.trim();

    if (target === '' || re.test(target)) {
      setValue(target);
    }
  };

  return (
    <Input
      type="text"
      id={id}
      value={value}
      onChange={(event) => onChange(event)}
      maxLength={maxLength}
      invalid={hasError}
      placeholder={placeholder}
      className={className}
    />
  );
};

export default TextNumberInput;

export const setInputNumbers = <T,>(
  event: React.ChangeEvent<HTMLInputElement>,
  values: T,
  setValues: React.Dispatch<React.SetStateAction<T>>,
  key: keyof T
) => {
  if (event.target.value === '') setValues({ ...values, [key]: '' });
  else {
    const parsed = parseFloat(event.target.value);
    setValues({ ...values, [key]: Number.isNaN(parsed) ? values[key] : parsed });
  }
};

export const setInputNumber = (
  event: React.ChangeEvent<HTMLInputElement>,
  value: number,
  setValue: React.Dispatch<React.SetStateAction<number>>
) => {
  if (event.target.value === '') setValue(null);
  else {
    const parsed = parseFloat(event.target.value);
    setValue(Number.isNaN(parsed) ? value : parsed);
  }
};

export const checkIfIsNumber = (value: string) => /^-?\d+$/.test(value);
