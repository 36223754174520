export type PartEntity = {
  id: number;
  number: number;
  name: string;
};

export default class Part {
  readonly id?: number;
  readonly number?: number;
  readonly name?: string;

  constructor(entity: PartEntity) {
    this.id = entity.id;
    this.number = entity.number;
    this.name = entity.name;
  }

  getName(index: number, prefix: string = 'Zadanie'): string {
    let result = `${prefix} `;
    const { number, name } = this;

    result += number ? number : index + 1;

    if (name) {
      result += ` - ${name}`;
    }

    return result;
  }
}
