import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { ModuleEntity } from 'modules/Module/model/Module';
import { PaginationMeta } from 'modules/Shared/type';
import { UserCreditsValues } from 'modules/User/type';
import { ModuleAccessHistoryEntity } from 'modules/Module/model/ModuleAccessHistory';

export const API_MODULE_LIST = '/api/modules';
export const API_MODULE_USER_SUBSCRIPTIONS = '/api/users/:user_id/subscriptions';
export const API_MODULE_USER_CREDITS = '/api/users/:user_id/credits';
export const API_MODULE_LIST_CHANGES = '/api/modules/:id/logs';
export const API_MODULE_ACCESS_HISTORY = '/api/modules/:id/accesses_history';

export type FetchModuleListResponse = {
  data: ModuleEntity[];
};

export const fetchModuleList = (cancelToken?: any): AxiosPromise<FetchModuleListResponse> =>
  axios.get(getAPIUrl(API_MODULE_LIST), { cancelToken });

export type DataUpdateUserSubscriptions = {
  module_id: number;
  date_to: string;
  original_date_to?: string;
  credits?: number;
};

export const updateUserSubscriptions = (
  user_id: number | string,
  data: DataUpdateUserSubscriptions,
  cancelToken?: any
): AxiosPromise<{ data: ModuleEntity[] }> =>
  axios.put(getAPIUrl(API_MODULE_USER_SUBSCRIPTIONS, { user_id }), data, { cancelToken });

export const updateUserCredits = (
  user_id: number | string,
  data: DataUpdateUserSubscriptions,
  cancelToken?: any
): AxiosPromise<{ data: UserCreditsValues[] }> =>
  axios.put(getAPIUrl(API_MODULE_USER_CREDITS, { user_id }), data, { cancelToken });

export type FetchModuleListOfChangesResponse = {
  data: ModuleEntity[];
  meta: PaginationMeta;
  links: any;
};

export const fetchModuleListOfChanges = (
  id: number,
  cancelToken?: any
): AxiosPromise<FetchModuleListOfChangesResponse> =>
  axios.get(getAPIUrl(API_MODULE_LIST_CHANGES, { id }), { cancelToken });

export type FetchModuleAccessHistoryResponse = {
  data: ModuleAccessHistoryEntity[];
};

export const fetchModuleAccessHistory = (
  id: number,
  cancelToken?: any
): AxiosPromise<FetchModuleAccessHistoryResponse> =>
  axios.get(getAPIUrl(API_MODULE_ACCESS_HISTORY, { id }), { cancelToken });
