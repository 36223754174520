import { RootState } from 'app/reducer';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import EditCompanyIcon from 'modules/Layout/component/Icon/EditCompany';
import EditUserIcon from 'modules/Layout/component/Icon/EditUser';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { getPathUrl } from 'modules/Shared/helper/api';
import { formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { PartialSearchingProps } from 'modules/Shared/type';
import { setTraditionalPaymentListParamsAction } from 'modules/Subscriptions/action/traditionalPaymentList';
import {
  ROUTE_USER,
  ROUTE_USER_BASIC_UPDATE,
  ROUTE_USER_COMPANY_UPDATE,
  ROUTE_USER_PAYMENT_LOG
} from 'modules/User/routes';
import { ROUTE_TRADITIONAL_PAYMENTS_WAITING } from 'modules/Subscriptions/routes';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TraditionalPayment from 'modules/Subscriptions/model/TraditionalPayment';
import ActionAccept from 'modules/Layout/component/Action/Accept';
import { breadcrumbRouteUserWaitingListPaymentLog } from 'modules/Subscriptions/breadcrumbs';

type Props = {
  onDeleteClick: (payment: TraditionalPayment) => void;
  onApproveAccessClick: (payment: TraditionalPayment) => void;
  isAdminList?: boolean;
  isWaiting?: boolean;
};

const TraditionalPaymentWaitingListTable: React.FC<Props> = ({ onDeleteClick, onApproveAccessClick }) => {
  const dispatch = useDispatch();
  const { list, sort } = useSelector((state: RootState) => state.subscriptions.traditionalPayment);

  const setParams = (payload: PartialSearchingProps) => dispatch(setTraditionalPaymentListParamsAction(payload));

  const redirectLink = (value: string, id: number) => (
    <Link className="fill-table-td" to={getPathUrl(ROUTE_USER, { id }, { is_waiting: 1 })}>
      {value}
    </Link>
  );

  const paymentNumberLink = (payment_id: string, user_id: number) => (
    <Link
      className="fill-table-td"
      to={{
        pathname: getPathUrl(ROUTE_USER_PAYMENT_LOG, { user_id, payment_id }),
        state: {
          redirectToOtherUrl: {
            url: ROUTE_TRADITIONAL_PAYMENTS_WAITING,
            breadcrumpItem: breadcrumbRouteUserWaitingListPaymentLog(payment_id)
          }
        }
      }}
    >
      {payment_id}
    </Link>
  );

  let cols: TableCol<TraditionalPayment>[] = [
    {
      property: 'payment_number',
      label: 'Identyfikator transakcji',
      sortable: false,
      value: (row) => paymentNumberLink(row.payment_number, row.user_id)
    },
    {
      property: 'user_first_name',
      label: 'Imię',
      sortable: true,
      value: (row) => redirectLink(row.user_first_name, row.user_id)
    },
    {
      property: 'user_last_name',
      label: 'Nazwisko',
      sortable: true,
      value: (row) => redirectLink(row.user_last_name, row.user_id)
    },
    {
      property: 'user_email',
      label: 'Email',
      sortable: true,
      value: (row) => redirectLink(row.user_email, row.user_id)
    },
    {
      property: 'company_name',
      label: 'Firma',
      value: (row) => row.getCompanyName()
    },
    {
      property: 'last_login',
      label: 'Data ostatniego logowania',
      value: (row) => ifContainsDateFormat(row.getLastLogin(), formatDatetimeSeconds)
    },
    {
      property: 'wants_invoice',
      label: 'Data złożenia prośby o FV',
      value: (row) => ifContainsDateFormat(row.getFVRequestDate(), formatDatetimeSeconds)
    },
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            <ActionUpdate
              className="mx-1"
              title="Edycja danych podstawowych"
              label={<EditUserIcon height="20px" />}
              to={getPathUrl(ROUTE_USER_BASIC_UPDATE, { id: row.user_id })}
            />
            <ActionUpdate
              className="mx-1"
              title="Edycja danych rozliczeniowych"
              label={<EditCompanyIcon height="20px" />}
              to={getPathUrl(ROUTE_USER_COMPANY_UPDATE, { id: row.user_id })}
            />
            <ActionAccept className="mx-1" title="Dodaj dostęp dla klienta" onClick={() => onApproveAccessClick(row)} />
            <ActionDelete className="mx-1" title="Anuluj płatność" onClick={() => onDeleteClick(row)} />
          </div>
        );
      }
    }
  ];

  return <Table cols={cols} rows={list} sort={sort} onSort={(params) => setParams({ sort: params })} />;
};

export default TraditionalPaymentWaitingListTable;
