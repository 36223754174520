import { Routes } from 'app/routes';
import Unguarded from 'modules/Auth/component/Unguarded';
import React from 'react';
import { Route } from 'react-router-dom';
import LoginView from 'modules/Auth/view/Login';
import RegisterView from 'modules/Auth/view/Register';
import PasswordRecoverView from 'modules/Auth/view/Password/Recover';
import PasswordResetView from 'modules/Auth/view/Password/Reset';
import EmailChangeConfirmView from 'modules/Auth/view/Confirm';
import ResendTokenView from 'modules/Auth/view/ResendToken';
import RegisterMessagePage from 'modules/Layout/component/Page/Message/Register';

export const ROUTE_LOGIN = '/login';
export const ROUTE_PASSWORD_RECOVER = '/password/recover';
export const ROUTE_PASSWORD_RESET = '/password/reset/:token';
export const ROUTE_EMAIL_CHANGE_CONFIRM = '/email/change/confirm/:token';
export const ROUTE_ACCOUNT_ACTIVATION = '/account/activate/:token';
export const ROUTE_REGISTER = '/register';
export const ROUTE_REGISTER_SUCCESS = '/register/success';
export const ROUTE_RESEND_TOKEN = '/resend-token';

export const createAuthRoutes = (): Routes => ({
  public: [
    <Route key="login" path={ROUTE_LOGIN} exact>
      <Unguarded>
        <LoginView />
      </Unguarded>
    </Route>,
    <Route key="register" path={ROUTE_REGISTER} exact>
      <Unguarded>
        <RegisterView />
      </Unguarded>
    </Route>,
    <Route key="password-recover" path={ROUTE_PASSWORD_RECOVER} exact>
      <Unguarded>
        <PasswordRecoverView />
      </Unguarded>
    </Route>,
    <Route
      key="password-reset"
      path={ROUTE_PASSWORD_RESET}
      exact
      render={(props) => (
        <Unguarded>
          <PasswordResetView params={props.match.params} />
        </Unguarded>
      )}
    />,
    <Route key="email-change-confirm" path={ROUTE_EMAIL_CHANGE_CONFIRM} exact component={EmailChangeConfirmView} />,
    <Route
      key="account-activation"
      path={ROUTE_ACCOUNT_ACTIVATION}
      exact
      render={() => <EmailChangeConfirmView isAccountActivation />}
    />,
    <Route key="resend-token" path={ROUTE_RESEND_TOKEN} exact>
      <Unguarded>
        <ResendTokenView />
      </Unguarded>
    </Route>,
    <Route key="register-success" path={ROUTE_REGISTER_SUCCESS} exact>
      <Unguarded>
        <RegisterMessagePage />
      </Unguarded>
    </Route>
  ]
});
