import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import Proceedings, { createProceedings } from 'modules/Proceedings/model/Proceedings';
import { fetchProceedingsDetails } from 'modules/Proceedings/repository';
import ProceedingsBasicDetails from 'modules/Proceedings/component/Details';
import PublicWrapper from 'modules/Layout/component/Wrapper/Public';
import { breadcrumbPublicRouteProceedingsDetails } from 'modules/Proceedings/breadcrumbs';
import { useDispatch } from 'react-redux';
import { managePageAction } from 'modules/Layout/action';
import withPublicAccessHeader from 'modules/Layout/component/Wrapper/Public/withPublicAccessHeader';
import PublicWrapperWithMenu from 'modules/Layout/component/Wrapper/Public/PublicWrapperWithMenu';
import { PROCEEDINGS_INIT_TYPES_VALUES } from 'modules/Proceedings/type';
import { analytics } from 'firebase';

const ProceedingsDetailsPublic = () => {
  const dispatch = useDispatch();
  const { id, proceedingsType } = useParams<{ id: string; proceedingsType: string }>();
  const [proceedings, setProceedings] = useState<Proceedings>(null);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);

  const proceedingsTypeInt = parseInt(proceedingsType, 10) || PROCEEDINGS_INIT_TYPES_VALUES.PLANNING;

  const cancelToken = useCancelToken();
  useEffect(() => {
    dispatch(
      managePageAction({
        title: 'Szczegóły postępowania'
      })
    );

    (async () => {
      setFetching(true);
      const {
        data,
        cancelled,
        message: messageResponse
      } = await fetchRequest(fetchProceedingsDetails, id, cancelToken);

      if (cancelled) return;
      if (data) setProceedings(createProceedings(data.data));
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();

    analytics.logEvent('proceedings_activation');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { displayHeaderElementJsx } = withPublicAccessHeader();

  if (fetching) return <Loader />;
  if (!fetching && !proceedings && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  if (!fetching && proceedings) {
    return (
      <PublicWrapper displayHeaderElement={displayHeaderElementJsx}>
        <div style={{ marginTop: '-157px' }}>
          <PublicWrapperWithMenu
            defaultBreadcrumbItem={breadcrumbPublicRouteProceedingsDetails(proceedingsTypeInt, proceedings.name) as any}
            noSideNavBar
          >
            <div className="row user-details-view position-relative">
              <ProceedingsBasicDetails proceedings={proceedings} isPublic proceedingsType={proceedingsTypeInt} />
            </div>
          </PublicWrapperWithMenu>
        </div>
      </PublicWrapper>
    );
  }

  return null;
};

export default ProceedingsDetailsPublic;
