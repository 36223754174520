import React from 'react';
import CardBox from 'modules/Layout/component/CardBox';
import Loader from 'modules/Layout/component/Loader';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import { useSelector } from 'react-redux';
import { RootState } from 'app/reducer';
import PaymentReceivedTable from 'modules/Subscriptions/container/PaymentReceivedTable';
import { ROUTE_PROFILE } from 'modules/Profile/routes';
import InformationList, { InformationField } from 'modules/Layout/component/InformationList';
import { paymentLogStatusValue } from 'modules/Shared/components/PaymentLog/helper';
import { formatDate, formatTimeValue } from 'modules/Shared/helper/utils';
import { ROUTE_LOGIN } from 'modules/Auth/routes';
import PaymentLogsTable from 'modules/Subscriptions/container/PaymentLogs';
import './index.scss';

type Props = {
  loading?: boolean;
  message?: Message;
  isPublic?: boolean;
  withLogs?: boolean;
  isMobile?: boolean;
  isMobileSuccess?: boolean;
};

const PaymentPage: React.FC<Props> = ({ loading, message, isPublic, withLogs = false, isMobile, isMobileSuccess }) => {
  const { details } = useSelector((state: RootState) => state.subscriptions.payment);

  const fields: InformationField[] = [
    {
      label: 'Identyfikator transakcji',
      value: details?.payment_number
    },
    {
      label: 'Identyfikator Stripe',
      value: details?.payment_id
    },
    {
      label: 'Status',
      value: paymentLogStatusValue(details?.status)
    },
    {
      label: 'Data zakupu',
      value: details ? (
        <>
          <span>{formatDate(details.finished_at)}</span>{' '}
          <span className="payment-time">{formatTimeValue(details.finished_at)}</span>
        </>
      ) : (
        ''
      )
    },
    {
      label: 'Kwota brutto',
      value: `${details?.amount?.toFixed(2).replace('.', ',')} PLN`
    },
    {
      label: 'Wartość opodatkowania',
      value: `${details?.tax_value?.toFixed(2).replace('.', ',')} PLN`
    },
    {
      label: 'Faktura',
      value: details?.wants_invoice ? 'Tak' : 'Nie'
    }
  ];

  const redirectReturnBtn = () => {
    if (isMobile) return null;
    if (isPublic) return ROUTE_LOGIN;
    return ROUTE_PROFILE;
  };

  return (
    <>
      <CardBox
        className="payment-page-card"
        heading="Dane transakcji"
        paddingRight="30px"
        paddingBottom="0"
        redirectReturnBtn={redirectReturnBtn()}
      >
        {isMobile && (
          <div className="w-100 card-return-button">
            <a
              href={isMobileSuccess ? 'pzp://mobile.deeplink.pl/success' : 'pzp://mobile.deeplink.pl/cancel'}
              className="btn btn-primary waves-effect waves-light"
            >
              Wróć do aplikacji
            </a>
          </div>
        )}
        <div className="position-relative">
          <Alert message={message} />
          {!loading && Boolean(details) && (
            <div className="d-flex">
              <div className="col-12 p-0 col-lg-6 transaction-data">
                <InformationList fields={fields} />
              </div>
            </div>
          )}
          {loading && <Loader />}
          {loading && <span>Przetważanie żądania...</span>}
        </div>
      </CardBox>
      {!loading && Boolean(details) && (
        <>
          <CardBox className="choosen-products-card" heading="Wybrane produkty" paddingRight="0" paddingBottom="30px">
            <div className="payment-received-table">
              <PaymentReceivedTable subscriptions={details?.subscriptions} />
            </div>
          </CardBox>
          {withLogs && (
            <CardBox
              heading="Dokładny przebieg transakcji"
              redirectReturnBtnBottom={isPublic ? ROUTE_LOGIN : ROUTE_PROFILE}
              paddingRight="0"
              paddingBottom="30px"
            >
              <div className="payment-history-table">
                <PaymentLogsTable logs={details?.payment_logs} />
              </div>
            </CardBox>
          )}
        </>
      )}
    </>
  );
};

export default PaymentPage;
