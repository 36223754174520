import React, { useEffect } from 'react';
import { createSearchingProps } from 'modules/Shared/helper/params';
import User from 'modules/User/model/User';
import { useDispatch } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
import { breadcrumbRouteUsers, breadcrumbRouteUsersDeleted } from 'modules/User/breadcrumbs';
import { PartialSearchingProps } from 'modules/Shared/type';
import { setUserListParamsAction } from 'modules/User/action/list';
import { ROLE_ADMIN, ROLE_CLIENT } from 'modules/User/model/UserRole';
import UsersList from 'modules/User/component/List/Users';

function withUsersAdminListPageManage<P>(WrappedComponent: React.ComponentType<P>) {
  const Component = (props: P) => {
    const dispatch = useDispatch();
    const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
    const setParams = (payload: PartialSearchingProps) => dispatch(setUserListParamsAction(payload));

    const searchProps = createSearchingProps('', User.getFilterableAttributes(), User.getSortableAttributes());

    useEffect(() => {
      const title = 'Administratorzy - lista';

      managePage({
        title,
        breadcrumb: breadcrumbRouteUsers(true)
      });

      setParams({ ...searchProps, filter: { role_slug: ROLE_ADMIN } });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <WrappedComponent {...props} isAdminList />;
  };
  return Component;
}

export const UsersAdmin = withUsersAdminListPageManage(UsersList);

function withUsersListPageManage<P>(WrappedComponent: React.ComponentType<P>) {
  const Component = (props: P) => {
    const dispatch = useDispatch();
    const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
    const setParams = (payload: PartialSearchingProps) => dispatch(setUserListParamsAction(payload));

    const searchProps = createSearchingProps('', User.getFilterableAttributes(), User.getSortableAttributes());

    useEffect(() => {
      const title = 'Klienci - lista';

      managePage({
        title,
        breadcrumb: breadcrumbRouteUsers()
      });

      setParams({ ...searchProps, filter: { role_slug: ROLE_CLIENT } });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <WrappedComponent {...props} />;
  };
  return Component;
}

export const Users = withUsersListPageManage(UsersList);

function withUsersDeletedListPageManage<P>(WrappedComponent: React.ComponentType<P>) {
  const Component = (props: P) => {
    const dispatch = useDispatch();
    const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
    const setParams = (payload: PartialSearchingProps) => dispatch(setUserListParamsAction(payload));

    const searchProps = createSearchingProps('', User.getFilterableAttributes(), User.getSortableAttributes());

    useEffect(() => {
      const title = 'Usunięci klienci - lista';

      managePage({
        title,
        breadcrumb: breadcrumbRouteUsersDeleted()
      });

      setParams({ ...searchProps, filter: { role_slug: ROLE_CLIENT, status: 0 } });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <WrappedComponent {...props} />;
  };
  return Component;
}

export const UsersDeleted = withUsersDeletedListPageManage(UsersList);
