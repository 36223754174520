import Page from 'modules/Layout/container/Page';
import React, { ReactElement } from 'react';
import { BreadcrumbItem } from 'modules/Layout/type';

export interface Props {
  readonly navbar?: ReactElement;
  defaultBreadcrumbItem?: BreadcrumbItem;
  noSideNavBar?: true;
}

const PublicWrapperWithMenu: React.FC<Props> = (props) => {
  const { navbar, ...restProps } = props;

  return (
    <div id="wrapper">
      {navbar && React.cloneElement(navbar)}
      <Page {...restProps} />
    </div>
  );
};

export default PublicWrapperWithMenu;
