import { PaginationMeta, SearchingProps } from 'modules/Shared/type';
import { ForeignProceedingsApiImportsListValues } from '../../model/imports';

export interface ForeignProceedingsApiImportsListState extends SearchingProps {
  list: ForeignProceedingsApiImportsListValues[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initForeignProceedingsApiImportsListState = (): ForeignProceedingsApiImportsListState => ({
  list: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
