import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps } from 'modules/Shared/type';
import { setExecutorAdminListParamsAction } from 'modules/Executor/action/adminList';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterInputType } from 'modules/Layout/type';
import _ from 'lodash';
import { executorHasProblemsOptions } from 'modules/Executor/model/Executor';
// import SaveFilterModal from 'modules/Layout/component/ListFilter/SaveFilterModal';
// import useSaveFilter from 'modules/Shared/helper/hooks/useSaveFilter';
// import { EXECUTOR_SLUG_FILTER } from 'modules/Layout/model/Filters';

const ExecutorAdminFilter = () => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.executor.adminList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setExecutorAdminListParamsAction(payload));

  // const {
  //   saveModal,
  //   setSaveModal,
  //   cleanMessage,
  //   loading,
  //   message,
  //   filtersList,
  //   saveFilter,
  //   onDeleteFilterClick,
  //   onFilterSelectedClick
  // } = useSaveFilter(filter, setParams, EXECUTOR_SLUG_FILTER);

  const staticInputs: FilterInputType[] = [
    {
      type: 'text',
      property: 'name',
      label: 'Nazwa',
      value: _get(filter, 'name')
    },
    {
      type: 'text',
      property: 'first_name',
      label: 'Imię',
      value: _get(filter, 'first_name')
    },
    {
      type: 'text',
      property: 'last_name',
      label: 'Nazwisko',
      value: _get(filter, 'last_name')
    },
    {
      beforeLineBreak: true,
      type: 'text',
      property: 'nip',
      label: 'NIP',
      value: _get(filter, 'nip')
    },
    {
      type: 'text',
      property: 'krs',
      label: 'KRS',
      value: _get(filter, 'krs')
    },
    {
      type: 'checkbox',
      property: 'requires_action',
      label: 'Pokaż tylko wykonawców, którzy wymagają uzupełnienia danych',
      value: _get(filter, 'requires_action')
    },
    {
      type: 'select',
      property: 'has_problems',
      label: 'Pokaż wykonawców z naruszeniami',
      options: [
        {
          value: '',
          label: ''
        },
        ...executorHasProblemsOptions()
      ],
      value: _get(filter, 'requires_deposit')
    },
    {
      beforeLineBreak: true,
      type: 'text',
      property: 'city',
      label: 'Miasto',
      value: _get(filter, 'city')
    },
    {
      type: 'text',
      property: 'street',
      label: 'Ulica',
      value: _get(filter, 'street')
    },
    {
      type: 'text',
      property: 'postal_code',
      label: 'Kod pocztowy',
      value: _get(filter, 'postal_code')
    }
  ];

  // let disableBtn = false;
  // if (_.isEmpty(filter)) {
  //   disableBtn = true;
  // }

  // const setMessage = (value: boolean) => {
  //   if (message) cleanMessage();
  //   setSaveModal(value);
  // };

  return (
    // <>
    <ListingFilter
      inputs={staticInputs}
      filter={filter}
      pagination={pagination}
      setParams={setParams}
      // saveFilterBtn={() => setMessage(true)}
      // disableBtn={disableBtn}
      // filterObj={{ filtersList, onDeleteFilterClick, onFilterSelectedClick, loading }}
    />
    //   {saveModal && (
    //     <SaveFilterModal
    //       onSaveClick={saveFilter}
    //       isOpen={saveModal}
    //       toggle={() => setMessage(false)}
    //       message={message}
    //       loading={loading}
    //     />
    //   )}
    // </>
  );
};

export default ExecutorAdminFilter;
