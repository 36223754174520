import { BreadcrumbItem } from 'modules/Layout/type';
import { ROUTE_JUDGEMENT, ROUTE_JUDGEMENT_CATEGORIES, ROUTE_JUDGEMENT_LIST } from 'modules/Judgement/routes';
import { JUDGMENTS_DETAILS, JUDGMENTS_INDEX, JUDGMENT_CATEGORIES_INDEX } from 'modules/Judgement/permissions';
import { getPathUrl } from 'modules/Shared/helper/api';

export const breadcrumbRouteJudgementsCategory = (): BreadcrumbItem[] => [
  {
    title: 'Orzeczenia sądowe - kategorie',
    link: ROUTE_JUDGEMENT_CATEGORIES,
    permissions: [JUDGMENT_CATEGORIES_INDEX]
  }
];

export const breadcrumbRouteJudgementsCategoryDetails = (categoryName: string): BreadcrumbItem[] => [
  ...breadcrumbRouteJudgementsCategory(),
  {
    title: `${categoryName} - szczegóły`
  }
];

export const breadcrumbRouteJudgementKeywords = (): BreadcrumbItem[] => [
  {
    title: 'Orzeczenia sądowe - słowa kluczowe'
  }
];

export const breadcrumbRouteJudgementsList = (): BreadcrumbItem[] => [
  {
    title: 'Orzeczenia sądowe - lista',
    link: ROUTE_JUDGEMENT_LIST,
    permissions: [JUDGMENTS_INDEX]
  }
];

export const breadcrumbRouteJudgementCreate = (): BreadcrumbItem[] => [
  ...breadcrumbRouteJudgementsList(),
  {
    title: 'Tworzenie orzeczenia'
  }
];

export const breadcrumbRouteJudgementDetails = (identifier: string, id: number): BreadcrumbItem[] => [
  ...breadcrumbRouteJudgementsList(),
  {
    title: `Szczegóły orzeczenia - ${identifier}`,
    link: getPathUrl(ROUTE_JUDGEMENT, { id }),
    permissions: [JUDGMENTS_DETAILS]
  }
];

export const breadcrumbRouteJudgementsUpdate = (identifier: string, id: number): BreadcrumbItem[] => [
  ...breadcrumbRouteJudgementDetails(identifier, id),
  {
    title: `Edycja orzeczenia - ${identifier}`
  }
];

export const breadcrumbRouteJudgementKeywordIncludesMark = (identifier: string, id: number): BreadcrumbItem[] => [
  ...breadcrumbRouteJudgementDetails(identifier, id),
  {
    title: `Zaznaczenie treści orzeczenia - ${identifier}`
  }
];

export const breadcrumbRouteJudgementAddClauseList = (identifier: string, id: number): BreadcrumbItem[] => [
  ...breadcrumbRouteJudgementDetails(identifier, id),
  {
    title: `Wybierz klauzulę`
  }
];
