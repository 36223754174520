import CardBox from 'modules/Layout/component/CardBox';
import { getPathUrl } from 'modules/Shared/helper/api';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import './index.scss';
import { ExecutorViolation } from '../../../../Executor/model/Violation';
import { ROUTE_EXECUTOR_ADMINS_VIOLATION_UPDATE } from '../../../../Executor/routes';
import BasicViolationDataset from '../../Dataset/ExecutorViolation';

export interface Props {
  violation: ExecutorViolation;
  executor_id: string;
  onDeleteClick?: () => void;
  redirectUrl: string;
}

const ExecutorViolationsDetailsBasic: React.FC<Props> = ({
  violation,
  executor_id = 0,
  onDeleteClick,
  redirectUrl
}) => {
  return (
    <CardBox
      heading="Dane naruszenia"
      paddingBottom="0"
      paddingRight="30px"
      className="executor-order-details-basic"
      redirectReturnBtn={redirectUrl}
      actions={
        <>
          <Link
            className="link-with-border"
            to={getPathUrl(ROUTE_EXECUTOR_ADMINS_VIOLATION_UPDATE, {
              slug: violation.slug,
              executor_id,
              section_id: violation.id
            })}
          >
            EDYCJA
          </Link>
          {onDeleteClick && (
            <Button color="link" className="link-with-border p-0" onClick={onDeleteClick}>
              USUŃ NARUSZENIE
            </Button>
          )}
        </>
      }
    >
      <BasicViolationDataset violation={violation} />
    </CardBox>
  );
};

export default ExecutorViolationsDetailsBasic;
