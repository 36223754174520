import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_PURPOSE } from 'modules/Agreements/step';
import {
  AGREEMENT_TYPE_OUTSIDE_ORDER,
  AGREEMENT_TYPE_PROJECT,
  AGREEMENT_TYPE_SUPPLEMENT,
  AgreementStepProps,
  AgreementType
} from 'modules/Agreements/type';
import React, { useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { CustomInput, FormGroup } from 'reactstrap';

export interface AgreementStepPurposeValues {
  type: AgreementType;
}

const mapState = (step: AgreementStepPurposeValues): AgreementStepPurposeValues => {
  const { ...rest } = step;

  return {
    ...rest
  };
};

const AgreementStepPurpose = (props: AgreementStepProps): JSX.Element => {
  const { steps, onSubmit, onChange, missing_steps, AgreementTypeChange } = props;
  const initState = useRef<AgreementStepPurposeValues>(mapState(getStepValues(steps, AGREEMENT_STEP_PURPOSE)));
  const [stepValues, setStepValues] = useState<AgreementStepPurposeValues>({ ...initState.current });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    const updatedStepValues = { ...stepValues, type: value as AgreementType };

    if (checked) {
      setStepValues(updatedStepValues);
      AgreementTypeChange(updatedStepValues.type);
    } else {
      setStepValues({ ...updatedStepValues, type: '' });
    }
  };

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_PURPOSE));
    setStepValues({ ...initState.current });
  }, [steps]);

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        <CustomInput
          name="purpose"
          value={AGREEMENT_TYPE_PROJECT}
          id={AGREEMENT_TYPE_PROJECT}
          label="Praca nad projektem umowy"
          type="radio"
          checked={stepValues.type === AGREEMENT_TYPE_PROJECT}
          onChange={handleInputChange}
        />
        <p className="pl-4">
          Wybór opcji pozwoli na opracowanie wzoru umowy, który można wykorzystać w postępowaniu o udzieleniu zamówienia
        </p>
      </FormGroup>
      <FormGroup className="purpose-tooltip-hover">
        <CustomInput
          id={AGREEMENT_TYPE_SUPPLEMENT}
          value={AGREEMENT_TYPE_SUPPLEMENT}
          label="Uzupełnienie opracowanego projektu umowy (do podpisania umowy)"
          type="radio"
          disabled={missing_steps.length > 0}
          checked={stepValues.type === AGREEMENT_TYPE_SUPPLEMENT}
          onChange={handleInputChange}
        />
        <p className="pl-4">
          Możliwość uzupełnienia przygotowanej wcześniej umowy, po wybraniu wykonawcy w postępowaniu o udzielenie
          zamówienia
        </p>
        {missing_steps.length > 0 && (
          <div className="purpose-tooltip">Opcja możliwa po przygotowaniu projektu umowy.</div>
        )}
      </FormGroup>
      <FormGroup>
        <CustomInput
          id={AGREEMENT_TYPE_OUTSIDE_ORDER}
          value={AGREEMENT_TYPE_OUTSIDE_ORDER}
          label="Praca nad umową"
          type="radio"
          checked={stepValues.type === AGREEMENT_TYPE_OUTSIDE_ORDER}
          onChange={handleInputChange}
        />
        <p className="pl-4">
          Wybór opcji pozwoli na opracowanie całości umowy, bez ograniczeń związanych z wymogami opracowania wzoru umowy
          do postępowania o udzielenie zamówienia
        </p>
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepPurpose;
