import _has from 'lodash/has';
import { ValidationErrors } from 'modules/Shared/type';
import React, { useState } from 'react';
import { Button, Form as FormStrap } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ROUTE_PROFILE } from 'modules/Profile/routes';
import User, { CompanyEntity } from 'modules/User/model/User';
import FieldsetCompanyUser from 'modules/User/component/Fieldset/UserCompany/BaseFields';

export interface Props {
  profile: CompanyEntity;
  user?: User;
  errors?: ValidationErrors;
  disabled?: boolean;
  submit: (profile: CompanyEntity) => void;
  cancelRedirect?: string;
  required?: boolean;
  placeholder?: boolean;
}
const CompanyForm: React.FC<Props> = ({
  profile,
  user,
  disabled,
  submit,
  errors,
  cancelRedirect,
  required,
  placeholder = false
}) => {
  const [values, setValue] = useState<CompanyEntity>({
    client_type: profile?.client_type ?? 1,
    name: profile?.name ?? '',
    first_name: profile?.first_name || user?.first_name || '',
    last_name: profile?.last_name || user?.last_name || '',
    street: profile?.street ?? '',
    postal_code: profile?.postal_code ?? '',
    city: profile?.city ?? '',
    post_office: profile?.post_office ?? '',
    nip: profile?.nip ?? '',
    email: profile?.email || user?.email || '',
    correspondence_address_same: profile?.correspondence_address_same ?? true,
    correspondence_street: profile?.correspondence_street ?? '',
    correspondence_postal_code: profile?.correspondence_postal_code ?? '',
    correspondence_city: profile?.correspondence_city ?? '',
    correspondence_post_office: profile?.correspondence_post_office ?? ''
  });
  const [executorFromGus, setExecutorFromGus] = useState<Record<any, any>>(null);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = event.target;

    if (_has(values, key)) {
      setValue({ ...values, [key]: value });
    }
  };

  const setMultipleValues = (data: Record<any, any>) => {
    setValue({ ...values, ...data });
  };

  const clearFields = () => {
    setValue({
      ...values,
      name: '',
      first_name: '',
      last_name: '',
      street: '',
      postal_code: '',
      city: '',
      post_office: '',
      email: ''
    });

    setExecutorFromGus(null);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submit(values);
  };

  const hasValue = () => !Object.values(values).some(Boolean);

  return (
    <FormStrap onSubmit={onSubmit}>
      <FieldsetCompanyUser
        placeholder={placeholder}
        values={values}
        errors={errors}
        onChange={onChange}
        setMultipleValues={setMultipleValues}
        executorFromGus={executorFromGus}
        setExecutorFromGus={setExecutorFromGus}
        required={required}
      />
      <div className="form-actions">
        {values.client_type === 2 && executorFromGus !== null && (
          <Button
            type="button"
            color="secondary"
            outline
            className="pzpeu-btn-disabled waves-effect waves-light mb-3"
            onClick={clearFields}
          >
            Wyczyść
          </Button>
        )}
        <Button
          type="submit"
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light"
          disabled={hasValue() || disabled}
        >
          Zapisz
        </Button>
        <Link to={cancelRedirect || ROUTE_PROFILE} className="cancel waves-effect waves-light">
          Anuluj
        </Link>
      </div>
    </FormStrap>
  );
};

export default CompanyForm;
