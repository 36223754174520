import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { PaginationMeta, SearchParams } from 'modules/Shared/type';
import { ExecutorEntity } from 'modules/Executor/model/Executor';
import { ExecutorClientEntity } from 'modules/Executor/model/ExecutorClient';
import {
  ExecutorChangesValues,
  ExecutorOrderChangesValues,
  ExecutorExplanationSectionValues
} from 'modules/Executor/type';
import { ReportClaimEntity } from './model/ReportClaim';
import { ExecutorExtendedRequest, ExecutorFinancialReport, ExecutorViolation } from './model/Violation';

export const API_EXECUTOR_ADMIN = '/api/admin/executors';
export const API_EXECUTOR_ADMIN_CRUD = '/api/admin/executors/:id';
export const API_EXECUTOR = '/api/executors';
export const API_REPORT_CLAIMS = '/api/executors/report_claims/myReports';
export const API_REPORT_CLAIMS_ADMIN = '/api/executors/report_claims';
export const API_EXECUTOR_DETAILS = '/api/executors/:id';
export const API_EXECUTOR_VIOLATIONS = '/api/violations/:executor_id';
export const API_EXECUTOR_FINANCIAL_REPORTS = '/api/admin/executors/:executor_id/financial_reports';
export const API_EXECUTOR_EXTENDED_REQUESTS = '/api/executors/report_claims/:claim_id/offices_request';
export const API_EXECUTOR_VIOLATIONS_FILES = '/api/violations/files/:section_id';
export const API_EXECUTOR_VIOLATIONS_FILES_ACTION = '/api/violations/files/:section_id/file_id';
export const API_EXECUTOR_VIOLATION = '/api/violations/:executor_id/:section_id';
export const API_EXECUTOR_VIOLATIONS_UPDATE = '/api/violations/:executor_id/:section_id';
export const API_EXECUTOR_GUS = '/api/api-gus/:nip';
export const API_EXECUTOR_CONSTRUCTED_GUS = '/api/api-gus/:nip/checkWRB';
export const API_EXECUTOR_USE_CREDIT = '/api/executors/use_credit';
export const API_REPORT_CLAIMS_USE_CREDIT = '/api/executors/report_claims/use_credit';
export const API_REPORT_CLAIM = '/api/executors/report_claims/:id/get';
export const API_EXECUTOR_DOWNLOAD_PDF = '/api/executors/:id/report';
export const API_EXECUTOR_REPORT_CLAIM_DOWNLOAD_PDF = '/api/executors/report_claims/:id/pdf';
export const API_EXECUTOR_CHANGES = '/api/admin/executors/:id/audits';
export const API_EXECUTOR_ORDER_CHANGES = '/api/orders/:id/audits';
export const API_EXECUTOR_REPORT_SECTION = '/api/executors/report_sections';

export type FetchExecutorsResponse = {
  data: ExecutorEntity[];
  meta: PaginationMeta;
  links: any;
};

export const fetchAdminExecutors = (params: SearchParams = {}): AxiosPromise<FetchExecutorsResponse> =>
  axios.get(getAPIUrl(API_EXECUTOR_ADMIN, null, params));

export type FetchExecutorResponse = {
  data: ExecutorEntity;
};

export const fetchAdminExecutor = (id: number | string, cancelToken?: any): AxiosPromise<FetchExecutorResponse> =>
  axios.get(getAPIUrl(API_EXECUTOR_ADMIN_CRUD, { id }), { cancelToken });

export const createAdminExecutor = (data: ExecutorEntity, cancelToken?: any): AxiosPromise<FetchExecutorResponse> =>
  axios.post(getAPIUrl(API_EXECUTOR_ADMIN), data, { cancelToken });

export const updateAdminExecutor = (
  id: number | string,
  data: ExecutorEntity,
  cancelToken?: any
): AxiosPromise<FetchExecutorResponse> => axios.put(getAPIUrl(API_EXECUTOR_ADMIN_CRUD, { id }), data, { cancelToken });

export type FetchExecutorsClientResponse = {
  data: ExecutorClientEntity[];
  meta: PaginationMeta;
  links: any;
};

export type FetchReportClaimsClientResponse = {
  data: ReportClaimEntity[];
  meta: PaginationMeta;
  links: any;
};

export const deleteAdminExecutor = (id: number | string, cancelToken?: any): AxiosPromise<FetchExecutorResponse> =>
  axios.delete(getAPIUrl(API_EXECUTOR_ADMIN_CRUD, { id }), { cancelToken });

export type FetchExecutorClientResponse = {
  data: ExecutorClientEntity;
};

export const fetchExecutors = (params: SearchParams = {}): AxiosPromise<FetchExecutorsClientResponse> =>
  axios.get(getAPIUrl(API_EXECUTOR, null, params));

export const fetchReportClaims = (params: SearchParams = {}): AxiosPromise<FetchReportClaimsClientResponse> =>
  axios.get(getAPIUrl(API_REPORT_CLAIMS, null, params));

export const fetchExecutor = (id: number | string, cancelToken?: any): AxiosPromise<FetchExecutorClientResponse> =>
  axios.get(getAPIUrl(API_EXECUTOR_DETAILS, { id }), { cancelToken });

export const fetchExecutorReportClaim = (
  id: number | string,
  cancelToken?: any
): AxiosPromise<FetchExecutorClientResponse> => axios.get(getAPIUrl(API_REPORT_CLAIM, { id }), { cancelToken });

export const fetchExecutorFromGus = (nip: string): AxiosPromise<any> => axios.get(getAPIUrl(API_EXECUTOR_GUS, { nip }));
export const fetchExecutorExtendedFromGus = (nip: string): AxiosPromise<any> =>
  axios.get(getAPIUrl(API_EXECUTOR_CONSTRUCTED_GUS, { nip }));

export const useCreditExecutor = (
  data: { date_to: string; nip: string; foreign_nip?: boolean },
  cancelToken?: any
): AxiosPromise<{ data: { executor_id: number } }> =>
  axios.post(getAPIUrl(API_EXECUTOR_USE_CREDIT), data, { cancelToken });

export const useCreditReportClaim = (
  data: { date_to: string; nip: string; is_construction_company?: boolean },
  cancelToken?: any
): AxiosPromise<{ data: { claim_id: number } }> =>
  axios.post(getAPIUrl(API_REPORT_CLAIMS_USE_CREDIT), data, { cancelToken });

export const useCreditReportClaimAdmin = (
  data: { nip: string; is_construction_company?: boolean },
  cancelToken?: any
): AxiosPromise<{ data: { executor_id: number } }> =>
  axios.post(getAPIUrl(API_REPORT_CLAIMS_ADMIN), data, { cancelToken });

export const fetchExecutorRaport = (id: string | number, cancelToken?: any): AxiosPromise =>
  axios.get(getAPIUrl(API_EXECUTOR_DOWNLOAD_PDF, { id }), {
    responseType: 'blob',
    cancelToken
  });

export const fetchExecutorExtendedRaportClaim = (id: string | number, cancelToken?: any): AxiosPromise =>
  axios.get(getAPIUrl(API_EXECUTOR_REPORT_CLAIM_DOWNLOAD_PDF, { id }), {
    responseType: 'blob',
    cancelToken
  });

export type ChangesExecutorListResponse = {
  data: ExecutorChangesValues[];
  meta: PaginationMeta;
  links: any;
};

export type ViolationsExecutorListResponse = {
  data: ExecutorViolation[];
  meta: PaginationMeta;
  links: any;
};

export type FinancialReportsExecutorListResponse = {
  data: ExecutorFinancialReport[];
  meta: PaginationMeta;
  links: any;
};

export type ExecutorExtendedRequestsListResponse = {
  data: ExecutorExtendedRequest[];
  meta: PaginationMeta;
  links: any;
};

export type ViolationsExecutorResponse = {
  data: ExecutorViolation;
};

export const fetchChangesExecutorList = (
  id: string | number,
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<ChangesExecutorListResponse> =>
  axios.get(getAPIUrl(API_EXECUTOR_CHANGES, { id }, params), { cancelToken });

export const fetchChangesViolationsList = (
  executor_id: string | number,
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<ViolationsExecutorListResponse> =>
  axios.get(getAPIUrl(API_EXECUTOR_VIOLATIONS, { executor_id }, params), { cancelToken });

export const fetchFinancialReporstList = (
  executor_id: string | number,
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<FinancialReportsExecutorListResponse> =>
  axios.get(getAPIUrl(API_EXECUTOR_FINANCIAL_REPORTS, { executor_id }, params), { cancelToken });

export const fetchExecutorExtendedRequestsList = (
  claim_id: string | number,
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<ExecutorExtendedRequestsListResponse> =>
  axios.get(getAPIUrl(API_EXECUTOR_EXTENDED_REQUESTS, { claim_id }, params), { cancelToken });

export const deleteExecutorViolation = (
  executor_id: number | string,
  section_id: number | string,
  cancelToken?: any
): AxiosPromise<ViolationsExecutorResponse> =>
  axios.delete(getAPIUrl(API_EXECUTOR_VIOLATION, { executor_id, section_id }), { cancelToken });

export const fetchExecutorViolation = (
  executor_id: number | string,
  section_id: number | string,
  cancelToken?: any
): AxiosPromise<ViolationsExecutorResponse> =>
  axios.get(getAPIUrl(API_EXECUTOR_VIOLATION, { executor_id, section_id }), { cancelToken });

export const createViolation = (
  executor_id: string | number,
  data: ExecutorViolation,
  cancelToken?: any
): AxiosPromise<ViolationsExecutorResponse> =>
  axios.post(getAPIUrl(API_EXECUTOR_VIOLATIONS, { executor_id }), data, { cancelToken });

export const updateViolation = (
  executor_id: string | number,
  section_id: string | number,
  data: ExecutorViolation,
  cancelToken?: any
): AxiosPromise<ViolationsExecutorResponse> =>
  axios.put(getAPIUrl(API_EXECUTOR_VIOLATION, { executor_id, section_id }), data, { cancelToken });

export type ChangesExecutorOrderListResponse = {
  data: ExecutorOrderChangesValues[];
  meta: PaginationMeta;
  links: any;
};

export const fetchChangesExecutorOrderList = (
  id: string | number,
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<ChangesExecutorOrderListResponse> =>
  axios.get(getAPIUrl(API_EXECUTOR_ORDER_CHANGES, { id }, params), { cancelToken });

export const fetchExecutorExplanationSection = (
  cancelToken?: any
): AxiosPromise<{ data: ExecutorExplanationSectionValues[] }> =>
  axios.get(getAPIUrl(API_EXECUTOR_REPORT_SECTION), { cancelToken });

export const updateExecutorExplanationSection = (
  data: { sections: ExecutorExplanationSectionValues[] },
  cancelToken?: any
): AxiosPromise<{ data: ExecutorExplanationSectionValues[] }> =>
  axios.put(getAPIUrl(API_EXECUTOR_REPORT_SECTION), data, { cancelToken });
