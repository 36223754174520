import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { PartialSearchingProps, SearchParams, SelectOption } from '../../../../Shared/type';
import { RootState } from '../../../../../app/reducer';
import { setExecutorExtendedRequestsListParamsAction } from '../../../action/list';
import { FilterInputType } from '../../../../Layout/type';
import { formatDatetimeLocalForInput } from '../../../../Shared/helper/utils';
import ListingFilter from '../../../../Layout/component/Listing/Filter';
import { fetchOfficesExtendedCategories } from '../../../../Office/repository';
import { SlugType } from '../../../../ForeignProceedings/container/ForeignProceedingsList/Filter/FilterValues/useFilterValues';

export interface Props {
  id?: string | number;
  values?: SearchParams;
}

const ExecutorExtendedRequestsFilter: React.FC<Props> = ({ id, values }) => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.user.executorsExtendedRequestsList);
  const setParams = (payload: PartialSearchingProps) =>
    dispatch(setExecutorExtendedRequestsListParamsAction(payload, id));
  const [categoriesList, setCategoriesList] = useState<SlugType[]>([]);
  const categoriesOptions: SelectOption[] = categoriesList.map((category) => {
    return {
      value: category.slug,
      label: category.name
    };
  });

  useEffect(() => {
    const getValues = async () => {
      const responseCategories = await fetchOfficesExtendedCategories();
      const newCategories = responseCategories.data.data;
      setCategoriesList(newCategories);
    };
    getValues().then((r) => r);
  }, []);

  const staticInputsBase: FilterInputType[] = [
    {
      type: 'text',
      property: 'name',
      label: 'Nazwa organu',
      value: _get(values, 'name')
    },
    {
      type: 'select',
      property: 'category_slug',
      acceptValueAsString: true,
      label: 'Kategoria organu',
      options: [
        {
          value: '',
          label: ''
        },
        ...categoriesOptions
      ],
      value: _get(values, 'category_slug')
    },
    {
      type: 'select',
      property: 'status',
      label: 'Status odpowiedzi',
      value: _get(values, 'status'),
      options: [
        {
          label: '',
          value: ''
        },
        {
          label: 'Oczekiwanie na odpowiedź',
          value: 1
        },
        {
          label: 'Wydłużenie terminu na odpowiedź',
          value: 2
        },
        {
          label: 'Odmowa udostępnienia informacji',
          value: 3
        },
        {
          label: 'Brak naruszeń',
          value: 4
        },
        {
          label: 'Są naruszenia',
          value: 5
        }
      ]
    }
  ];

  const inputsTime = (): FilterInputType[] => {
    let dynamicInputs: FilterInputType[] = [];

    const sentAtFrom = _get(filter, 'sent_at_from') as string;
    const sentAtTo = _get(filter, 'sent_at_to') as string;
    const receivedAtFrom = _get(filter, 'received_at_from') as string;
    const receivedAtTo = _get(filter, 'received_at_to') as string;

    dynamicInputs = [
      {
        beforeLineBreak: true,
        type: 'datetime',
        property: 'sent_at_from',
        label: 'Data wysłania wniosku od',
        value: formatDatetimeLocalForInput(sentAtFrom)
      },
      {
        type: 'datetime',
        property: 'sent_at_to',
        label: 'Data wysłania wniosku do',
        value: formatDatetimeLocalForInput(sentAtTo)
      },
      {
        beforeLineBreak: true,
        type: 'datetime',
        property: 'received_at_from',
        label: 'Data otrzymania odpowiedzi od',
        value: formatDatetimeLocalForInput(receivedAtFrom)
      },
      {
        type: 'datetime',
        property: 'received_at_to',
        label: 'Data otrzymania odpowiedzi do',
        value: formatDatetimeLocalForInput(receivedAtTo)
      }
    ];

    return dynamicInputs;
  };

  const staticInputs = [...staticInputsBase, ...inputsTime()];

  return (
    <ListingFilter
      filtersName="executor_extended_requests"
      inputs={staticInputs}
      filter={filter}
      pagination={pagination}
      setParams={setParams}
    />
  );
};

export default ExecutorExtendedRequestsFilter;
