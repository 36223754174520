import proceedingsListReducer from 'modules/Proceedings/reducer/proceedingsList';
import { combineReducers, Reducer } from 'redux';
import { ProceedingsState } from 'modules/Proceedings/state';

const reducer = (): Reducer<ProceedingsState> => {
  const reducers = {
    proceedingsList: proceedingsListReducer
  };

  return combineReducers<ProceedingsState>(reducers);
};

export default reducer;
