import React, { useEffect, useRef, useState } from 'react';
import { CustomInput, FormFeedback, FormGroup } from 'reactstrap';
import Part from 'modules/Agreements/model/Part';
import { isEqual } from 'lodash';
import { getError, hasError } from 'modules/Shared/helper/validation';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import {
  AGREEMENT_TYPE_OUTSIDE_ORDER,
  AGREEMENT_TYPE_SUPPLEMENT,
  AgreementCheckboxes,
  AgreementStepProps
} from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_SUBJECT } from 'modules/Agreements/step';
import StepImportDataButton from 'modules/Agreements/components/StepImportDataButton';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import Accordion from 'modules/Layout/component/Accordion';
import { IMPORT_ORDERS_ACCESS } from '../../../../../Auth/container/Guard/permissions';
import Authorize from '../../../../../Auth/container/Authorize';

export interface AgreementStepSubjectValues {
  same_subject_for_parts: boolean;
  subjects: {
    part_id: number | null;
    agreement_subject: string | null;
    personal_duty_checked: boolean;
    personal_duty: string | null;
    agreement_subject_details: string | null;
    hierarchy_checked: boolean;
    hierarchy_content: string | null;
    decision_checked: boolean;
    decision_content: string | null;
    commitment_checked: boolean;
    commitment_content: string | null;
  }[];
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

export interface AgreementStepSubjectImportValues {
  same_subject_for_parts: boolean;
  subjects: {
    agreement_subject: string | null;
    personal_duty_checked: boolean;
    personal_duty: string | null;
    agreement_subject_details: string | null;
    hierarchy_checked: boolean;
    hierarchy_content: string | null;
    decision_checked: boolean;
    decision_content: string | null;
    commitment_checked: boolean;
    commitment_content: string | null;
  }[];
}

const AGREEMENT_SUBJECT =
  'Przedmiotem Umowy jest ........, wraz ze wszystkimi robotami przewidzianymi w Dokumentacji projektowej (dalej „Dokumentacja projektowa”)';
const PERSONAL_DUTY =
  'Zamawiający przewiduje obowiązek osobistego wykonania przez wykonawcę kluczowych zadań (zgodnie z art. 60 p.z.p. i art. 120 p.z.p.) na następujących warunkach: ........';
const AGREEMENT_SUBJECT_DETAILS =
  'Szczegółowy opis Przedmiotu Umowy oraz wymagania zamawiającego względem Przedmiotu Umowy zostały określone w Dokumentacji projektowej, tj.:';
const HIERARCHY_CONTENT =
  'W przypadku ewentualnych niezgodności rozwiązań w Dokumentacji projektowej, dokumentem nadrzędnym jest ........, następnie ........';
const DECISION_CONTENT = 'Zamawiający wymaga od Wykonawcy uzyskania Decyzji: 1) ........';
const COMMITMENT_CONTENT =
  'Wykonawca zobowiązany jest do wykonania Przedmiotu Umowy zgodnie z Dokumentacją projektową, normami branżowymi, zasadami wiedzy technicznej i sztuki budowlanej oraz z powszechnie obowiązującymi przepisami prawa.';

const initContentValues = {
  agreement_subject: AGREEMENT_SUBJECT,
  personal_duty: PERSONAL_DUTY,
  agreement_subject_details: AGREEMENT_SUBJECT_DETAILS,
  hierarchy_content: HIERARCHY_CONTENT,
  decision_content: DECISION_CONTENT,
  commitment_content: COMMITMENT_CONTENT
};

const mapState = (step: AgreementStepSubjectValues): AgreementStepSubjectValues => {
  const { subjects, checkboxes, ...rest } = step;

  return {
    ...rest,
    subjects: subjects.map((part) => {
      const {
        agreement_subject,
        personal_duty,
        agreement_subject_details,
        hierarchy_content,
        decision_content,
        commitment_content
      } = part;

      return {
        ...part,
        agreement_subject: agreement_subject || AGREEMENT_SUBJECT,
        personal_duty: personal_duty || PERSONAL_DUTY,
        agreement_subject_details: agreement_subject_details || AGREEMENT_SUBJECT_DETAILS,
        hierarchy_content: hierarchy_content || HIERARCHY_CONTENT,
        decision_content: decision_content || DECISION_CONTENT,
        commitment_content: commitment_content || COMMITMENT_CONTENT
      };
    }),
    checkboxes: checkboxes || {}
  };
};

const AgreementStepSubject = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, stepsImportData, onSubmit, onChange, errors } = props;
  const initState = useRef<AgreementStepSubjectValues>(mapState(getStepValues(steps, AGREEMENT_STEP_SUBJECT)));
  const [stepValues, setStepValues] = useState<AgreementStepSubjectValues>({ ...initState.current });
  const stepDataImportValues: AgreementStepSubjectImportValues = getStepValues(stepsImportData, AGREEMENT_STEP_SUBJECT);
  const { parts_ids, few_parts } = agreement;
  const { subjects, same_subject_for_parts } = stepValues;

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_SUBJECT));
    setStepValues({ ...initState.current });
  }, [steps]);

  const isMultiple = few_parts && !same_subject_for_parts;

  const { renderPartCheckbox, renderPartContent, renderPartSwitch, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    partSlug: 'subjects',
    initContentValues
  });

  const renderPart = (partObj: Part, index: number) => {
    const part = isMultiple ? subjects[index] : subjects[0];

    const body = (
      <FormGroup>
        {renderPartContent('agreement_subject', index, part, agreement.type === AGREEMENT_TYPE_SUPPLEMENT)}
        {agreement.type === AGREEMENT_TYPE_OUTSIDE_ORDER && [
          renderPartSwitch(
            'personal_duty_checked',
            'Czy zamawiający przewiduje obowiązek osobistego wykonania przez wykonawcę kluczowych zadań (zgodnie z art. 60 p.z.p. i art. 120 p.z.p.)?',
            index,
            part,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          part.personal_duty_checked &&
            renderPartContent('personal_duty', index, part, agreement.type === AGREEMENT_TYPE_SUPPLEMENT)
        ]}
        {renderPartContent('agreement_subject_details', index, part, agreement.type === AGREEMENT_TYPE_SUPPLEMENT)}
        {renderPartSwitch(
          'hierarchy_checked',
          'Czy zamawiający chce określić w Umowie hierarchię  poszczególnych części składowych dokumentacji projektowej?',
          index,
          part,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {part.hierarchy_checked &&
          renderPartContent('hierarchy_content', index, part, agreement.type === AGREEMENT_TYPE_SUPPLEMENT)}
        {renderPartSwitch(
          'decision_checked',
          'Przedmiot Umowy obejmuje uzyskanie przez Wykonawcę Decyzji (np. Decyzji o pozwoleniu na użytkowanie, Decyzji wodnoprawnej):',
          index,
          part,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {part.decision_checked &&
          renderPartContent('decision_content', index, part, agreement.type === AGREEMENT_TYPE_SUPPLEMENT)}
        {renderPartCheckbox(
          'commitment_checked',
          part.commitment_content,
          index,
          part,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {renderCustomCheckboxes(
          'general',
          partObj?.id,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
      </FormGroup>
    );

    if (isMultiple) {
      return (
        <Accordion
          key={`renderPartAccordion-${partObj.id}`}
          isInvalid={hasError(errors, `subjects.${index}`)}
          accordionContentStyle={{ padding: '1rem' }}
          entity={{
            title: partObj.getName(index),
            content: body
          }}
        />
      );
    }

    return body;
  };

  const samePartsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setStepValues((values) =>
      mapState({
        ...values,
        same_subject_for_parts: checked,
        subjects: checked
          ? [
              {
                ...subjects[0],
                part_id: null
              }
            ]
          : parts_ids.map((part) => ({
              ...subjects[0],
              part_id: part.id
            }))
      })
    );
  };

  const onStepDataImportClick = () => {
    if (stepDataImportValues.same_subject_for_parts !== undefined) {
      samePartsChange({ target: { checked: stepDataImportValues.same_subject_for_parts } } as any);
    }

    setStepValues((values) => {
      return {
        ...values,
        subjects: stepDataImportValues.subjects
          ? values.subjects.map((subject, index) => ({ ...subject, ...stepDataImportValues.subjects[index] }))
          : values.subjects
      };
    });
  };

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <Authorize permissions={[IMPORT_ORDERS_ACCESS]}>
        {agreement.type !== AGREEMENT_TYPE_SUPPLEMENT && (
          <StepImportDataButton onSubmit={onStepDataImportClick} stepDataImportValues={stepDataImportValues} />
        )}
      </Authorize>
      {few_parts && (
        <FormGroup>
          <CustomInput
            id="same_subject_for_parts"
            name="same_subject_for_parts"
            type="switch"
            checked={same_subject_for_parts}
            onChange={samePartsChange}
            invalid={hasError(errors, 'same_subject_for_parts')}
            disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
            label="Czy przedmiot zamówienia jest wspólny dla wszystkich zadań?"
          />
          {hasError(errors, 'same_subject_for_parts') && (
            <FormFeedback className="d-block">{getError(errors, 'same_subject_for_parts')}</FormFeedback>
          )}
        </FormGroup>
      )}
      {(isMultiple ? parts_ids : [null]).map(renderPart)}
      {isMultiple && (
        <>
          <p className="h4">Ponadto dla każdego z zadań:</p>
          {renderCustomCheckboxes(
            null,
            null,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )}
        </>
      )}
    </AgreementFormWrapper>
  );
};

export default AgreementStepSubject;
