import React from 'react';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import PreviewFieldCheckboxWithNumberInput from 'modules/Agreements/components/PreviewField/CheckboxWithNumberInput';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_RENOUNCEMENT } from 'modules/Agreements/step';
import {
  AgreementStepRenouncementValues,
  getContractorPossibilityText,
  getExecutorPossibilityText,
  RENOUNCEMENT_DATE_CONTENT
} from 'modules/Agreements/components/Steps/Default/Renouncement';

const AgreementStepPreviewRenouncement = (
  props: AgreementStepPreviewProps<AgreementStepRenouncementValues>
): JSX.Element => {
  const { steps, modifiedStep } = props;
  const renouncement = getStepValues<AgreementStepRenouncementValues>(steps, AGREEMENT_STEP_RENOUNCEMENT);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={renouncement}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const renderCheckboxWithNumberInput = (checkbox: string, label: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckboxWithNumberInput
        step={renouncement}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        label={label}
        className={className}
      />
    );
  };

  const { value: contractorPossibility, modified: contractorPossibilityModified } = getFieldForPreview(
    renouncement,
    modifiedStep,
    `contractor_possibility`
  );

  const { value: executorPossibility, modified: executorPossibilityModified } = getFieldForPreview(
    renouncement,
    modifiedStep,
    `executor_possibility`
  );

  const { value: notResponsiblePossibility, modified: notResponsiblePossibilityModified } = getFieldForPreview(
    renouncement,
    modifiedStep,
    `not_responsible_possibility`
  );

  const { value: salaryPossibility, modified: salaryPossibilityModified } = getFieldForPreview(
    renouncement,
    modifiedStep,
    `salary_possibility`
  );

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    renouncement,
    modifiedStep,
    `checkboxes.general`
  );

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_RENOUNCEMENT)}</div>
      <div className="agreement-step-preview-content reset-preview-point">
        {[
          contractorPossibility && [
            <p
              className={classNames('preview-point', {
                modified: contractorPossibilityModified
              })}
            >
              {getContractorPossibilityText(renouncement.same_subject_for_parts)}
            </p>,
            renderCheckbox('contractor_financing', 'preview-nested-decimal-point'),
            renderCheckbox('contractor_circumstances', 'preview-nested-decimal-point'),
            renderCheckbox('contractor_not_take_duties', 'preview-nested-decimal-point'),
            renderCheckbox('contractor_loss_right', 'preview-nested-decimal-point'),
            renderCheckbox('contractor_seizure_order', 'preview-nested-decimal-point'),
            renderCheckbox('contractor_faulty_way', 'preview-nested-decimal-point'),
            renderCheckbox('contractor_penalties_sum', 'preview-nested-decimal-point'),
            renderCheckbox('contractor_delay_insurance', 'preview-nested-decimal-point'),
            renderCheckbox('contractor_delay_safeguard', 'preview-nested-decimal-point'),
            renderCheckbox('contractor_others', 'preview-nested-decimal-point')
          ],
          executorPossibility && [
            <p
              className={classNames('preview-point', {
                modified: executorPossibilityModified
              })}
            >
              {getExecutorPossibilityText(renouncement.same_subject_for_parts)}
            </p>,
            renderCheckbox('executor_no_salary', 'preview-nested-decimal-point'),
            renderCheckbox('executor_no_accept', 'preview-nested-decimal-point'),
            renderCheckbox('executor_others', 'preview-nested-decimal-point')
          ],
          contractorPossibility && [
            renderCheckboxWithNumberInput('renouncement_date', RENOUNCEMENT_DATE_CONTENT),
            renderCheckbox('regardless_of'),
            notResponsiblePossibility && [
              <p
                className={classNames('preview-point', {
                  modified: notResponsiblePossibilityModified
                })}
              >
                Zamawiający w razie odstąpienia od Umowy z przyczyn, za które wykonawca nie ponosi odpowiedzialności
                zobowiązany jest do:
              </p>,
              renderCheckbox('not_responsible_accept', 'preview-nested-decimal-point'),
              renderCheckbox('not_responsible_payment', 'preview-nested-decimal-point'),
              renderCheckbox('not_responsible_construction_site', 'preview-nested-decimal-point'),
              renderCheckbox('not_responsible_others', 'preview-nested-decimal-point')
            ],
            salaryPossibility && [
              <p
                className={classNames('preview-point', {
                  modified: salaryPossibilityModified
                })}
              >
                Sposób obliczenia należnego wynagrodzenia wykonawcy z tytułu wykonania części Umowy będzie oparty o
                zasady:
              </p>,
              renderCheckbox('salary_deduction', 'preview-nested-decimal-point'),
              renderCheckbox('salary_schedule', 'preview-nested-decimal-point'),
              renderCheckbox('salary_others', 'preview-nested-decimal-point')
            ],
            renderCheckbox('permissions')
          ]
        ]}
        {generalCheckboxes &&
          generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-point wysiwyg-preview', {
                  modified: generalCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AgreementStepPreviewRenouncement;
