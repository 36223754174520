import React from 'react';
import { RootState } from 'app/reducer';
import ListingPerPage from 'modules/Layout/component/Listing/PerPage';
import { PaginationMeta, PartialSearchingProps } from 'modules/Shared/type';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

type Props = {
  meta: PaginationMeta;
  reduxAction: (payload: PartialSearchingProps) => (dispatch: Dispatch, getState: () => RootState) => Promise<void>;
};

const JudgementCategoryPerPage: React.FC<Props> = ({ meta, reduxAction }) => {
  const dispatch = useDispatch();
  const setParams = (payload: PartialSearchingProps) => dispatch(reduxAction(payload));

  return <ListingPerPage meta={meta} setParams={setParams} />;
};

export default JudgementCategoryPerPage;
