import { PaginationMeta, SearchingProps } from 'modules/Shared/type';
import User from 'modules/User/model/User';

export interface UserListState extends SearchingProps {
  users: User[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initUserListState = (): UserListState => ({
  users: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
