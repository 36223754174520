import React, { useEffect } from 'react';
import { createSearchingProps } from 'modules/Shared/helper/params';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { RootState } from 'app/reducer';
import { ROLE_ADMIN, ROLE_CLIENT } from 'modules/User/model/UserRole';
// eslint-disable-next-line import/no-cycle
import useClientForeignProceedingsList from '../component/Lists/ClientListHooks';
// eslint-disable-next-line import/no-cycle
import useAdminForeignProceedingsList from '../component/Lists/AdminListHooks';
// eslint-disable-next-line import/no-cycle
import {
  ROUTE_FOREIGN_PROCEEDINGS_OPEN,
  ROUTE_FOREIGN_PROCEEDINGS_OPEN_ADMIN,
  ROUTE_FOREIGN_PROCEEDINGS_PLANNING
} from '../routes';
// eslint-disable-next-line import/no-cycle
import { breadcrumbRouteForeignProceedings } from '../breadcrumbs';
import { clearForeignProceedingsList, setForeignProceedingsListParamsAction } from '../action/ForeignProceedingsList';
import ForeignProceedings from '../model/ForeignProceedings';

function withForeignProceedingsList<P>(
  AdminWrappedComponent: React.ComponentType<P>,
  ClientWrappedComponent: React.ComponentType<P>,
  title: string
) {
  const Component = (props: P) => {
    const dispatch = useDispatch();
    const { role } = useSelector((state: RootState) => state.auth.user);

    const isAdmin = role?.slug === ROLE_ADMIN;

    const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
    const setParams = (payload: PartialSearchingProps) =>
      dispatch(setForeignProceedingsListParamsAction(payload, isAdmin));

    const searchProps = createSearchingProps(
      '',
      ForeignProceedings.getFilterableAttributes(),
      ForeignProceedings.getSortableAttributes()
    );

    useEffect(() => {
      dispatch(clearForeignProceedingsList());

      managePage({
        title,
        breadcrumb: breadcrumbRouteForeignProceedings()
      });

      if (role?.slug !== ROLE_CLIENT) {
        setParams({ ...searchProps });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isAdmin ? <AdminWrappedComponent {...props} /> : <ClientWrappedComponent {...props} />;
  };
  return Component;
}

const AdminForeignProceedingsPlanningList = () => {
  const { jsxFnc } = useAdminForeignProceedingsList(ROUTE_FOREIGN_PROCEEDINGS_PLANNING);

  return jsxFnc();
};

const ClientForeignProceedingsPlanningList = () => {
  const { jsxFnc } = useClientForeignProceedingsList(ROUTE_FOREIGN_PROCEEDINGS_PLANNING);

  return jsxFnc();
};

export const ForeignProceedingsPlanningListView = withForeignProceedingsList(
  AdminForeignProceedingsPlanningList,
  ClientForeignProceedingsPlanningList,
  'Wyszukiwarka planowanych postępowań - lista'
);

const AdminForeignProceedingsOpenList = () => {
  const { jsxFnc } = useAdminForeignProceedingsList(ROUTE_FOREIGN_PROCEEDINGS_OPEN_ADMIN);

  return jsxFnc();
};

const ClientForeignProceedingsOpenList = () => {
  const { jsxFnc } = useClientForeignProceedingsList(ROUTE_FOREIGN_PROCEEDINGS_OPEN);

  return jsxFnc();
};

export const ForeignProceedingsOpenListView = withForeignProceedingsList(
  AdminForeignProceedingsOpenList,
  ClientForeignProceedingsOpenList,
  'Wyszukiwarka prowadzonych postępowań - lista'
);
