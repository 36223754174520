import React, { useEffect, useRef, useState } from 'react';
import { Button, CustomInput, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { isEqual } from 'lodash';
import { getError, hasError } from 'modules/Shared/helper/validation';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_DEADLINE } from 'modules/Agreements/step';
import StepImportDataButton from 'modules/Agreements/components/StepImportDataButton';
import Datepicker from 'modules/Layout/component/Datepicker';
import Select from 'modules/Layout/component/Input/Select';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import ManageFiles, { IFile } from 'modules/Shared/helper/Files/ManageFiles';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import Part from 'modules/Agreements/model/Part';
import Accordion from 'modules/Layout/component/Accordion';
import { IMPORT_ORDERS_ACCESS } from '../../../../../Auth/container/Guard/permissions';
import Authorize from '../../../../../Auth/container/Authorize';

export const deadlineUnitOptions = [
  { id: '', value: '' },
  { id: 1, value: 'dni' },
  { id: 2, value: 'tygodnie' },
  { id: 3, value: 'miesiące' },
  { id: 4, value: 'lata' }
];

export const DEL_PERIODICITY_CYCLICAL = 'cyclical';
export const DEL_PERIODICITY_SINGLE = 'single';

export interface PartialDeadline {
  deadline_type_option: string | null;
  deadline_unit: string | null;
  deadline_value: string | null;
  deadline: string | null;
  scope: string | null;
}

export interface AgreementStepDeadlineValues {
  same_deadline_for_parts: boolean;
  deadlines: {
    part_id: number | null;
    deadline_criterion_checked: boolean;
    deadline_criterion_content: string | null;
    del_periodicity: string | null;
    del_in_batches_checked: boolean;
    del_in_batches_content: string | null;
    deadline_type_option: string | null;
    deadline_date: string | null;
    deadline_unit: string | null;
    deadline_value: string | null;
    start_date_option: string | null;
    start_date: string | null;
    deadline_schedule_checked: boolean;
    deadline_partial_checked: boolean;
    partial_deadlines: PartialDeadline[];
    del_partial_checked: boolean;
    del_partial_content: string | null;
    documentation_checked: boolean;
    documentation_content: string | null;
    del_order_way_switch: boolean;
    del_order_way_personal_checked: boolean;
    del_order_way_personal_content: string | null;
    del_order_way_phone_checked: boolean;
    del_order_way_phone_content: string | null;
    del_order_way_fax_checked: boolean;
    del_order_way_fax_content: string | null;
    del_order_way_mail_checked: boolean;
    del_order_way_mail_content: string | null;
    del_order_way_other_checked: boolean;
    del_order_way_other_content: string | null;
    del_parts_arrangements_checked: boolean;
    del_parts_arrangements_content: string | null;
    del_max_realization_checked: boolean;
    del_max_realization_content: string | null;
    files: IFile[];
  }[];
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

export interface AgreementStepDeadlineImportValues extends AgreementStepDeadlineValues {}

const DEADLINE_CRITERION_CONTENT = 'Termin wykonania zamówienia wynosi: ........';
const DEL_IN_BATCHES_CONTENT =
  'Dostawy będące Przedmiotem Umowy realizowane będą sukcesywnie stosownie do zapotrzebowania zamawiającego od dnia ........ do ........ Dostawy realizowane są wyłącznie na podstawie każdorazowego zamówienia przez zamawiającego poszczególnych partii (dalej jako „partie”)';
const DOCUMENTATION_CONTENT =
  'Ustala się, że Przedmiot Umowy został wykonany w terminie określonym w Umowie, jeżeli do tej daty zostanie dostarczony Przedmiot Umowy Zamawiającemu oraz przekazana wszelka dokumentacja wymagana Umową';
const DEL_PARTIAL_CONTENT =
  '<p>Dostawy realizowane są wyłącznie na podstawie Zamówienia zamawiającego poszczególnych partii (<strong>Partie</strong>)</p>';
const DEL_ORDER_WAY_PERSONAL_CONTENT = 'osobiście przez pracowników zamawiającego';
const DEL_ORDER_WAY_PHONE_CONTENT = 'także telefonicznie';
const DEL_ORDER_WAY_FAX_CONTENT = 'faksem';
const DEL_ORDER_WAY_MAIL_CONTENT = 'za pośrednictwem poczty elektronicznej';
const DEL_ORDER_WAY_OTHER_CONTENT = 'inne: ........';
const DEL_PARTS_ARRANGEMENTS_CONTENT =
  'Terminy oraz wielkość dostaw częściowych będą uzgadniane z odpowiednimi pracownikami zamawiającego.';
const DEL_MAX_REALIZATION_CONTENT =
  'Poszczególne dostawy wykonawca  będzie realizował w terminie maksymalnie do .... dni kalendarzowych od otrzymania informacji od Zamawiającego o zamówieniu';

const initContentValues = {
  deadline_criterion_content: DEADLINE_CRITERION_CONTENT,
  del_in_batches_content: DEL_IN_BATCHES_CONTENT,
  documentation_content: DOCUMENTATION_CONTENT,
  del_partial_content: DEL_PARTIAL_CONTENT,
  del_order_way_personal_content: DEL_ORDER_WAY_PERSONAL_CONTENT,
  del_order_way_phone_content: DEL_ORDER_WAY_PHONE_CONTENT,
  del_order_way_fax_content: DEL_ORDER_WAY_FAX_CONTENT,
  del_order_way_mail_content: DEL_ORDER_WAY_MAIL_CONTENT,
  del_order_way_other_content: DEL_ORDER_WAY_OTHER_CONTENT,
  del_parts_arrangements_content: DEL_PARTS_ARRANGEMENTS_CONTENT,
  del_max_realization_content: DEL_MAX_REALIZATION_CONTENT
};

const mapState = (step: AgreementStepDeadlineValues): AgreementStepDeadlineValues => {
  const { deadlines, checkboxes, ...rest } = step;

  return {
    ...rest,
    deadlines: deadlines.map((part) => {
      const {
        deadline_criterion_content,
        del_in_batches_content,
        documentation_content,
        partial_deadlines,
        del_partial_content,
        del_order_way_personal_content,
        del_order_way_phone_content,
        del_order_way_fax_content,
        del_order_way_mail_content,
        del_order_way_other_content,
        del_parts_arrangements_content,
        del_max_realization_content,
        files
      } = part;

      return {
        ...part,
        deadline_criterion_content: deadline_criterion_content || DEADLINE_CRITERION_CONTENT,
        del_in_batches_content: del_in_batches_content || DEL_IN_BATCHES_CONTENT,
        documentation_content: documentation_content || DOCUMENTATION_CONTENT,
        partial_deadlines: partial_deadlines || [],
        del_partial_content: del_partial_content || DEL_PARTIAL_CONTENT,
        del_order_way_personal_content: del_order_way_personal_content || DEL_ORDER_WAY_PERSONAL_CONTENT,
        del_order_way_phone_content: del_order_way_phone_content || DEL_ORDER_WAY_PHONE_CONTENT,
        del_order_way_fax_content: del_order_way_fax_content || DEL_ORDER_WAY_FAX_CONTENT,
        del_order_way_mail_content: del_order_way_mail_content || DEL_ORDER_WAY_MAIL_CONTENT,
        del_order_way_other_content: del_order_way_other_content || DEL_ORDER_WAY_OTHER_CONTENT,
        del_parts_arrangements_content: del_parts_arrangements_content || DEL_PARTS_ARRANGEMENTS_CONTENT,
        del_max_realization_content: del_max_realization_content || DEL_MAX_REALIZATION_CONTENT,
        files: files || []
      };
    }),
    checkboxes: checkboxes || {}
  };
};

const AgreementStepDeadline = (props: AgreementStepProps): JSX.Element => {
  const { steps, stepsImportData, onSubmit, onChange, errors, agreement } = props;
  const initState = useRef<AgreementStepDeadlineValues>(mapState(getStepValues(steps, AGREEMENT_STEP_DEADLINE)));
  const [stepValues, setStepValues] = useState<AgreementStepDeadlineValues>({ ...initState.current });
  const stepDataImportValues: AgreementStepDeadlineImportValues = getStepValues(
    stepsImportData,
    AGREEMENT_STEP_DEADLINE
  );
  const { parts_ids, few_parts } = agreement;
  const { deadlines, same_deadline_for_parts } = stepValues;

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_DEADLINE));
    setStepValues({ ...initState.current });
  }, [steps]);

  const isMultiple = few_parts && !same_deadline_for_parts;

  const { renderPartCheckbox, renderPartEditableCheckbox, renderCustomCheckboxes, renderPartSwitch } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    partSlug: 'deadlines',
    initContentValues
  });

  const samePartsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setStepValues((values) =>
      mapState({
        ...values,
        same_deadline_for_parts: checked,
        deadlines: checked
          ? [
              {
                ...deadlines[0],
                part_id: null
              }
            ]
          : parts_ids.map((part) => ({
              ...deadlines[0],
              part_id: part.id
            }))
      })
    );
  };

  const onStepDataImportClick = () => {
    if (stepDataImportValues.same_deadline_for_parts !== undefined) {
      samePartsChange({ target: { checked: stepDataImportValues.same_deadline_for_parts } } as any);
    }

    setStepValues((values) => {
      return {
        ...values,
        deadlines: stepDataImportValues.deadlines
          ? values.deadlines.map((subject, index) => ({ ...subject, ...stepDataImportValues.deadlines[index] }))
          : values.deadlines
      };
    });
  };

  const addPartialDeadlines = (part: any) => {
    const partialDeadline: PartialDeadline = {
      deadline_type_option: '',
      deadline_unit: '',
      deadline_value: '',
      deadline: '',
      scope: ''
    };

    part['partial_deadlines'] = [...part['partial_deadlines'], partialDeadline];

    setStepValues((values) => mapState(values));
  };

  const removePartialDeadlines = (part: any, index: number) => {
    part['partial_deadlines'] = part['partial_deadlines'].filter((_: any, i: number) => i !== index);

    setStepValues((values) => mapState(values));
  };

  const renderPartialDeadlines = (partialDeadline: PartialDeadline, partIndex: number, index: number, part: any) => {
    const deadlineUnitValue = deadlineUnitOptions.find((obj) => obj.id === parseInt(partialDeadline.deadline_unit));

    return (
      <div key={`partial_deadline_${partIndex}_${index}_wrapper`} className="d-flex justify-content-between">
        <div>
          <div key={`partial_deadline_type_option_wrapper.${partIndex}.${index}`}>
            <Label key={`partial_deadline_type_option_label.${partIndex}.${index}`}>
              Proszę o wybranie jednej z dwóch opcji:
            </Label>
            <CustomInput
              key={`partial_deadline_type_option_unit.${partIndex}.${index}`}
              id={`partial_deadline_type_option_unit.${partIndex}.${index}`}
              label="Termin wykonania zamówienia został określony przez ilość dni/miesięcy"
              value="unit"
              type="radio"
              required
              checked={partialDeadline.deadline_type_option === 'unit'}
              invalid={hasError(errors, `deadlines.${partIndex}.partial_deadlines.${index}.deadline_type_option`)}
              onChange={(event) => {
                partialDeadline.deadline_type_option = event.target.value;
                partialDeadline.deadline = null;
                setStepValues((values) => mapState(values));
              }}
            />
            <CustomInput
              key={`partial_deadline_type_option_date.${partIndex}.${index}`}
              id={`partial_deadline_type_option_date.${partIndex}.${index}`}
              label="Termin wykonania zamówienia został określony datą."
              value="date"
              type="radio"
              required
              checked={partialDeadline.deadline_type_option === 'date'}
              invalid={hasError(errors, `deadlines.${partIndex}.partial_deadlines.${index}.deadline_type_option`)}
              onChange={(event) => {
                partialDeadline.deadline_type_option = event.target.value;
                partialDeadline.deadline = null;
                setStepValues((values) => mapState(values));
              }}
            />
            {hasError(errors, `deadlines.${partIndex}.partial_deadlines.${index}.deadline_type_option`) && (
              <FormFeedback className="d-block">
                {getError(errors, `deadlines.${partIndex}.partial_deadlines.${index}.deadline_type_option`)}
              </FormFeedback>
            )}
            {partialDeadline.deadline_type_option === 'date' && (
              <div className="mt-2">
                <Datepicker
                  id={`partial_deadline_date_datepicker.${partIndex}.${index}`}
                  value={partialDeadline.deadline}
                  onChange={(newDate) => {
                    partialDeadline['deadline'] = newDate;
                    setStepValues((values) => mapState(values));
                  }}
                  invalid={hasError(errors, `deadlines.${partIndex}.partial_deadlines.${index}.deadline`)}
                />
                {hasError(errors, `deadlines.${partIndex}.partial_deadlines.${index}.deadline`) && (
                  <FormFeedback className="d-block">
                    {getError(errors, `deadlines.${partIndex}.partial_deadlines.${index}.deadline`)}
                  </FormFeedback>
                )}
              </div>
            )}
            {partialDeadline.deadline_type_option === 'unit' && (
              <div key={`partial_deadline_unit_wrapper.${partIndex}.${index}`} className="d-flex mt-2">
                <FormGroup className="flex-1">
                  <Label for={`partial_deadline_value.${partIndex}.${index}`}>Liczba</Label>
                  <Input
                    type="number"
                    min={0}
                    id={`partial_deadline_value.${partIndex}.${index}`}
                    name={`partial_deadline_value.${partIndex}.${index}`}
                    value={partialDeadline.deadline_value || ''}
                    onChange={(event) => {
                      partialDeadline['deadline_value'] = event.target.value;
                      setStepValues((values) => mapState(values));
                    }}
                    invalid={hasError(errors, `deadlines.${partIndex}.partial_deadlines.${index}.deadline_value`)}
                  />
                  {hasError(errors, `deadlines.${partIndex}.partial_deadlines.${index}.deadline_value`) && (
                    <FormFeedback className="d-block">
                      {getError(errors, `deadlines.${partIndex}.partial_deadlines.${index}.deadline_value`)}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup className="flex-2 ml-2">
                  <Label for={`partial_deadline_unit.${partIndex}.${index}`}>Jednostka</Label>
                  <Select
                    id={`partial_deadline_unit.${partIndex}.${index}`}
                    name={`partial_deadline_unit.${partIndex}.${index}`}
                    value={{
                      value: deadlineUnitValue?.id,
                      label: deadlineUnitValue?.value
                    }}
                    options={deadlineUnitOptions.map((obj) => ({
                      label: obj.value,
                      value: obj.id
                    }))}
                    onChange={(option) => {
                      partialDeadline['deadline_unit'] = option.value.toString();
                      setStepValues((values) => mapState(values));
                    }}
                    required={false}
                    invalid={hasError(errors, `deadlines.${partIndex}.partial_deadlines.${index}.deadline_unit`)}
                  />
                  {hasError(errors, `deadlines.${partIndex}.partial_deadlines.${index}.deadline_unit`) && (
                    <FormFeedback className="d-block">
                      {getError(errors, `deadlines.${partIndex}.partial_deadlines.${index}.deadline_unit`)}
                    </FormFeedback>
                  )}
                </FormGroup>
              </div>
            )}
          </div>
          <FormGroup key={`partial_deadline_${partIndex}_${index}_scope_wrapper`}>
            <Label for={`partial_deadline_${partIndex}_${index}_scope`}>Zakres dostaw</Label>
            <Input
              name={`partial_deadline_${partIndex}_${index}_scope`}
              id={`partial_deadline_${partIndex}_${index}_scope`}
              type="textarea"
              value={partialDeadline.scope || ''}
              onChange={(event) => {
                partialDeadline.scope = event.target.value;
                setStepValues((values) => mapState(values));
              }}
              invalid={hasError(errors, `deadlines.${partIndex}.partial_deadlines.${index}.scope`)}
            />
            {hasError(errors, `deadlines.${partIndex}.partial_deadlines.${index}.scope`) && (
              <FormFeedback className="d-block">
                {getError(errors, `deadlines.${partIndex}.partial_deadlines.${index}.scope`)}
              </FormFeedback>
            )}
          </FormGroup>
          <hr />
        </div>
        <div>
          <ActionDelete
            title="Usuń pozycje"
            label={<i className="fa fa-times font-22" />}
            onClick={() => removePartialDeadlines(part, index)}
          />
        </div>
      </div>
    );
  };

  const renderPart = (partObj: Part, index: number) => {
    const part = isMultiple ? deadlines[index] : deadlines[0];

    const deadlineUnitValue = deadlineUnitOptions.find((obj) => obj.id === parseInt(part.deadline_unit));

    const body = (
      <FormGroup>
        {[
          <FormGroup key={`deadline_criterion_checked.${partObj?.id}`}>
            <CustomInput
              key={`deadline_criterion_checked.${partObj?.id}`}
              id={`deadline_criterion_checked.${partObj?.id}`}
              type="switch"
              label="Czy termin wykonania zamówienia stanowi kryterium oceny ofert?"
              checked={part.deadline_criterion_checked}
              onChange={(event) => {
                part['deadline_type_option'] = 'unit';
                part['deadline_date'] = null;
                part['deadline_unit'] = null;
                part['deadline_value'] = null;
                part['start_date_option'] = 'contract_award';
                part['start_date'] = null;
                part['deadline_criterion_checked'] = event.currentTarget.checked;
                setStepValues((values) => mapState(values));
              }}
            />
          </FormGroup>,
          <Label key={`del_periodicity_label.${partObj?.id}`}>Zamawiający przewiduje:</Label>,
          <CustomInput
            key={`del_periodicity_cyclical.${partObj?.id}`}
            id={`del_periodicity_cyclical.${partObj?.id}`}
            label="cykliczne dostawy określonych produktów/towarów według zapotrzebowania zmawiającego"
            type="radio"
            required
            checked={part.del_periodicity === DEL_PERIODICITY_CYCLICAL}
            onChange={(_) => {
              part.del_periodicity = DEL_PERIODICITY_CYCLICAL;
              part.del_in_batches_checked = false;
              setStepValues((values) => mapState(values));
            }}
          />,
          <CustomInput
            key={`del_periodicity_single.${partObj?.id}`}
            id={`del_periodicity_single.${partObj?.id}`}
            className="mb-2"
            label="dostarczenie jednego Przedmiotu dostawy/kilku Przedmiotów dostaw w ramach kilku zadań"
            type="radio"
            required
            checked={part.del_periodicity === DEL_PERIODICITY_SINGLE}
            onChange={(_) => {
              part.del_periodicity = DEL_PERIODICITY_SINGLE;
              part.del_in_batches_checked = false;
              setStepValues((values) => mapState(values));
            }}
          />,
          hasError(errors, `deadlines.${index}.del_periodicity`) && (
            <FormFeedback className="d-block">{getError(errors, `deadlines.${index}.del_periodicity`)}</FormFeedback>
          ),
          part.del_periodicity === DEL_PERIODICITY_CYCLICAL && renderPartEditableCheckbox('del_in_batches', index, part)
        ]}
        <div key={`deadline_type_option_wrapper.${partObj?.id}`}>
          <hr />
          <Label key={`deadline_type_option_label.${partObj?.id}`}>Proszę o wybranie jednej z dwóch opcji:</Label>
          <CustomInput
            key={`deadline_type_option_unit.${partObj?.id}`}
            id={`deadline_type_option_unit.${partObj?.id}`}
            label="Termin wykonania zamówienia został określony przez ilość dni/miesięcy"
            value="unit"
            type="radio"
            required
            checked={part.deadline_type_option === 'unit'}
            onChange={(event) => {
              part['deadline_type_option'] = event.target.value;
              part['deadline_date'] = null;
              setStepValues((values) => mapState(values));
            }}
          />
          <CustomInput
            key={`deadline_type_option_date.${partObj?.id}`}
            id={`deadline_type_option_date.${partObj?.id}`}
            label="Termin wykonania zamówienia został określony datą."
            value="date"
            type="radio"
            required
            checked={part.deadline_type_option === 'date'}
            onChange={(event) => {
              part['deadline_type_option'] = event.target.value;
              part['deadline_date'] = null;
              setStepValues((values) => mapState(values));
            }}
          />
          {!part.deadline_criterion_checked && part.deadline_type_option === 'date' && (
            <div className="mt-2">
              <Datepicker
                id={`deadline_date_datepicker.${partObj?.id}`}
                value={part.deadline_date}
                onChange={(newDate) => {
                  part['deadline_date'] = newDate;
                  setStepValues((values) => mapState(values));
                }}
                invalid={hasError(errors, `deadlines.${index}.deadline_date`)}
              />
              {hasError(errors, `deadlines.${index}.deadline_date`) && (
                <FormFeedback className="d-block">{getError(errors, `deadlines.${index}.deadline_date`)}</FormFeedback>
              )}
            </div>
          )}
          {part.deadline_type_option === 'unit' && (
            <div key={`deadline_unit_wrapper.${partObj?.id}`} className="d-flex mt-2">
              {!part.deadline_criterion_checked && (
                <FormGroup className="flex-1">
                  <Label for={`deadline_value.${partObj?.id}`}>Liczba</Label>
                  <Input
                    type="number"
                    min={0}
                    id={`deadline_value.${partObj?.id}`}
                    name={`deadline_value.${partObj?.id}`}
                    value={part.deadline_value || ''}
                    onChange={(event) => {
                      part['deadline_value'] = event.target.value;
                      setStepValues((values) => mapState(values));
                    }}
                    invalid={hasError(errors, `deadlines.${index}.deadline_value`)}
                  />
                  {hasError(errors, `deadlines.${index}.deadline_value`) && (
                    <FormFeedback className="d-block">
                      {getError(errors, `deadlines.${index}.deadline_value`)}
                    </FormFeedback>
                  )}
                </FormGroup>
              )}
              <FormGroup className="flex-2 ml-2">
                <Label for={`deadline_unit.${partObj?.id}`}>Jednostka</Label>
                <Select
                  id={`deadline_unit.${partObj?.id}`}
                  name={`deadline_unit.${partObj?.id}`}
                  value={{
                    value: deadlineUnitValue?.id,
                    label: deadlineUnitValue?.value
                  }}
                  options={deadlineUnitOptions.map((obj) => ({
                    label: obj.value,
                    value: obj.id
                  }))}
                  onChange={(option) => {
                    part['deadline_unit'] = option.value.toString();
                    setStepValues((values) => mapState(values));
                  }}
                  required={false}
                  invalid={hasError(errors, `deadlines.${index}.deadline_unit`)}
                />
                {hasError(errors, `deadlines.${index}.deadline_unit`) && (
                  <FormFeedback className="d-block">
                    {getError(errors, `deadlines.${index}.deadline_unit`)}
                  </FormFeedback>
                )}
              </FormGroup>
            </div>
          )}
        </div>
        <div key={`start_date_option_wrapper.${partObj?.id}`}>
          <hr />
          <Label key={`start_date_option_label.${partObj?.id}`}>Ustalenie daty początkowej:</Label>
          <CustomInput
            key={`start_date_option_contract_award.${partObj?.id}`}
            id={`start_date_option_contract_award.${partObj?.id}`}
            label="od udzielenie zamówienia"
            value="contract_award"
            type="radio"
            required
            checked={part.start_date_option === 'contract_award'}
            onChange={(event) => {
              part['start_date_option'] = event.target.value;
              setStepValues((values) => mapState(values));
            }}
          />
          <CustomInput
            key={`start_date_option_date.${partObj?.id}`}
            id={`start_date_option_date.${partObj?.id}`}
            label="data"
            value="date"
            type="radio"
            required
            checked={part.start_date_option === 'date'}
            onChange={(event) => {
              part['start_date_option'] = event.target.value;
              setStepValues((values) => mapState(values));
            }}
          />
          {part.start_date_option === 'date' && (
            <div className="mt-2">
              <Datepicker
                id={`deadline_start_date_datepicker.${partObj?.id}`}
                value={part.start_date}
                onChange={(newDate) => {
                  part['start_date'] = newDate;
                  setStepValues((values) => mapState(values));
                }}
                invalid={hasError(errors, `deadlines.${index}.start_date`)}
              />
              {hasError(errors, `deadlines.${index}.start_date`) && (
                <FormFeedback className="d-block">{getError(errors, `deadlines.${index}.start_date`)}</FormFeedback>
              )}
            </div>
          )}
          <hr />
        </div>
        {renderPartCheckbox(
          'deadline_schedule_checked',
          'Szczegółowe terminy wykonania poszczególnych części Przedmiotu Umowy określa Harmonogram dostaw stanowiący załącznik do Umowy',
          index,
          part
        )}
        {part.deadline_schedule_checked && (
          <ManageFiles
            files={part.files}
            maxSizeInMb={50}
            onChange={(files) => {
              part.files = files;
              setStepValues((values) => mapState(values));
            }}
          />
        )}
        <hr key={`separator-1.${partObj?.id}`} />
        {renderPartCheckbox('deadline_partial_checked', 'Określ termin częściowy', index, part)}
        {part.deadline_partial_checked && (
          <>
            {part.partial_deadlines.map((partialDeadlines, partialDeadlineIndex) =>
              renderPartialDeadlines(partialDeadlines, index, partialDeadlineIndex, part)
            )}
            {hasError(errors, `deadlines.${index}.partial_deadlines`) && (
              <FormFeedback className="d-block">
                {getError(errors, `deadlines.${index}.partial_deadlines`)}
              </FormFeedback>
            )}
            <div key={`deadline_partials_btn.${partObj?.id}`} className="agreement-form-actions mb-2">
              <Button type="button" color="primary" onClick={() => addPartialDeadlines(part)}>
                Dodaj pozycję
              </Button>
            </div>
          </>
        )}
        <hr key={`separator-2.${partObj?.id}`} />
        {renderPartCheckbox('del_partial_checked', part.del_partial_content, index, part)}
        {renderPartCheckbox('documentation_checked', part.documentation_content, index, part)}
        <hr />
        {renderPartSwitch('del_order_way_switch', 'Zamówienie może być złożone:', index, part)}
        {part.del_order_way_switch && (
          <div className="pl-3">
            {[
              renderPartCheckbox('del_order_way_personal_checked', part.del_order_way_personal_content, index, part),
              renderPartCheckbox('del_order_way_phone_checked', part.del_order_way_phone_content, index, part),
              renderPartCheckbox('del_order_way_fax_checked', part.del_order_way_fax_content, index, part),
              renderPartCheckbox('del_order_way_mail_checked', part.del_order_way_mail_content, index, part),
              renderPartEditableCheckbox('del_order_way_other', index, part)
            ]}
          </div>
        )}
        <hr />
        {renderPartCheckbox('del_parts_arrangements_checked', part.del_parts_arrangements_content, index, part)}
        {renderPartEditableCheckbox('del_max_realization', index, part)}
        {renderCustomCheckboxes('general', partObj?.id)}
      </FormGroup>
    );

    if (isMultiple) {
      return (
        <Accordion
          key={`renderPartAccordion-${partObj?.id}`}
          isInvalid={hasError(errors, `deadlines.${index}`)}
          accordionContentStyle={{ padding: '1rem' }}
          entity={{
            title: partObj.getName(index),
            content: body
          }}
        />
      );
    }

    return body;
  };

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <Authorize permissions={[IMPORT_ORDERS_ACCESS]}>
        <StepImportDataButton onSubmit={onStepDataImportClick} stepDataImportValues={stepDataImportValues} />
      </Authorize>
      {few_parts && (
        <FormGroup>
          <CustomInput
            id="same_deadline_for_parts"
            name="same_deadline_for_parts"
            type="switch"
            checked={same_deadline_for_parts}
            onChange={samePartsChange}
            invalid={hasError(errors, 'same_deadline_for_parts')}
            label="Czy termin jest wspólny dla wszystkich zadań?"
          />
          {hasError(errors, 'same_deadline_for_parts') && (
            <FormFeedback className="d-block">{getError(errors, 'same_deadline_for_parts')}</FormFeedback>
          )}
        </FormGroup>
      )}
      {(isMultiple ? parts_ids : [null]).map(renderPart)}
      {isMultiple && (
        <>
          <p className="h4">Ponadto dla każdego z zadań:</p>
          {renderCustomCheckboxes()}
        </>
      )}
    </AgreementFormWrapper>
  );
};

export default AgreementStepDeadline;
