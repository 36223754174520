import { logoutAction } from 'modules/Auth/action';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import LogoutIcon from '../../Icon/Logout';

export interface Props {
  className?: string;
}

export const NavbarLogout: React.FC<Props> = (props: Props): JSX.Element => {
  const { className = '' } = props;

  const dispatch = useDispatch();

  const logout = () => dispatch(logoutAction());

  return (
    <Button
      type="button"
      color="link"
      className={`nav-link ${className} d-flex align-items-center`}
      title="Wyloguj się"
      onClick={logout}
    >
      <LogoutIcon height="30px" />
      <span>Wyloguj się</span>
    </Button>
  );
};

export default NavbarLogout;
