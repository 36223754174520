import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { PaginationMeta, SearchParams } from 'modules/Shared/type';
import Proceedings from 'modules/Proceedings/model/Proceedings';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { ProceedingsCPVValues, ProceedingSendRequestValues } from 'modules/Proceedings/type';

export const API_PROCEEDINGS = '/api/proceedings';
export const API_PROCEEDINGS_KEYWORDS_ALL = '/api/proceedings/keywords/all';
export const API_PROCEEDINGS_DETAILS = '/api/proceedings/:id';
export const API_PROCEEDINGS_DETAILS_SEND_REQUEST = '/api/documents/proceedings/:id/main_document_explanation/send';
export const API_PROCEEDINGS_DETAILS_DOWNLOAD_REQUEST =
  '/api/documents/proceedings/:id/main_document_explanation/download';
export const API_PROCEEDINGS_FAVOURITES = '/api/proceedings/saved_proceedings';
export const API_PROCEEDINGS_FAVOURITE = '/api/proceedings/saved_proceedings/:proceeding_id';
export const API_PROCEEDINGS_CPVs = '/api/proceedings/:proceeding_id/cpv_codes ';
export const API_PROCEEDINGS_CPV = '/api/proceedings/:proceeding_id/cpv_codes/:id ';

export type FetchProceedings = {
  data: Proceedings[];
  meta: PaginationMeta;
  links: any;
};

export const fetchProceedings = (params: SearchParams = {}, cancelToken?: any): AxiosPromise<FetchProceedings> =>
  axios.get(getAPIUrl(API_PROCEEDINGS, null, params), { cancelToken });

export const fetchProceedingsAllKeywords = (
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<{ data: JudgementElementEntity[] }> =>
  axios.get(getAPIUrl(API_PROCEEDINGS_KEYWORDS_ALL, null, params), { cancelToken });

export type FetchProceedingsDetails = {
  data: Proceedings;
};

export const fetchProceedingsDetails = (
  id: string | number,
  cancelToken?: any
): AxiosPromise<FetchProceedingsDetails> => axios.get(getAPIUrl(API_PROCEEDINGS_DETAILS, { id }), { cancelToken });

export const deleteProceedings = (id: string | number, cancelToken?: any): AxiosPromise =>
  axios.delete(getAPIUrl(API_PROCEEDINGS_DETAILS, { id }), { cancelToken });

export const createNewProceedings = (data: Proceedings, cancelToken?: any): AxiosPromise<FetchProceedingsDetails> =>
  axios.post(getAPIUrl(API_PROCEEDINGS), { ...data }, { cancelToken });

export const updateProceedings = (
  id: string | number,
  data: Proceedings,
  cancelToken?: any
): AxiosPromise<FetchProceedingsDetails> =>
  axios.put(getAPIUrl(API_PROCEEDINGS_DETAILS, { id }), data, { cancelToken });

export const sendRequestProceedings = (
  id: string | number,
  data: ProceedingSendRequestValues,
  cancelToken?: any
): AxiosPromise => axios.post(getAPIUrl(API_PROCEEDINGS_DETAILS_SEND_REQUEST, { id }), data, { cancelToken });

export const downloadRequestProceedings = (
  id: string | number,
  data: ProceedingSendRequestValues,
  cancelToken?: any
): AxiosPromise =>
  axios.post(getAPIUrl(API_PROCEEDINGS_DETAILS_DOWNLOAD_REQUEST, { id }), data, { responseType: 'blob', cancelToken });

export const fetchProceedingsFav = (params: SearchParams = {}, cancelToken?: any): AxiosPromise<FetchProceedings> =>
  axios.get(getAPIUrl(API_PROCEEDINGS_FAVOURITES, null, params), { cancelToken });

export const manageProceedingsFav = (
  proceeding_id: string | number,
  cancelToken?: any
): AxiosPromise<FetchProceedingsDetails> =>
  axios.post(getAPIUrl(API_PROCEEDINGS_FAVOURITE, { proceeding_id }), {}, { cancelToken });

export const fetchProceedingsCPV = (
  proceeding_id: number | string,
  cancelToken?: any
): AxiosPromise<{ data: ProceedingsCPVValues[] }> =>
  axios.get(getAPIUrl(API_PROCEEDINGS_CPVs, { proceeding_id }), { cancelToken });

export const createProceedingsCPV = (
  proceeding_id: number | string,
  data: ProceedingsCPVValues,
  cancelToken?: any
): AxiosPromise<{ data: ProceedingsCPVValues }> =>
  axios.post(getAPIUrl(API_PROCEEDINGS_CPVs, { proceeding_id }), data, { cancelToken });

export const updateProceedingsCPV = (
  id: number | string,
  proceeding_id: number | string,
  data: ProceedingsCPVValues,
  cancelToken?: any
): AxiosPromise<{ data: ProceedingsCPVValues }> =>
  axios.put(getAPIUrl(API_PROCEEDINGS_CPV, { proceeding_id, id }), data, { cancelToken });

export const deleteProceedingsCPV = (
  id: number | string,
  proceeding_id: number | string,
  cancelToken?: any
): AxiosPromise => axios.delete(getAPIUrl(API_PROCEEDINGS_CPV, { proceeding_id, id }), { cancelToken });
