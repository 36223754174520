import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const sendFeedbackToastSuccess = (): AddToastPayload => ({
  header: 'Feedback',
  body: 'Feedback został wysłany.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const sendFeedbackToastError = (): AddToastPayload => ({
  header: 'Feedback',
  body: 'Feedback nie został się wysłany.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});
