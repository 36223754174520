import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { PaymentHistoryValues } from 'modules/Profile/type';
import { paymentLogStatusValue } from 'modules/Shared/components/PaymentLog/helper';
import Authorize from 'modules/Auth/container/Authorize';
import { Link } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_PROFILE_PAYMENT_LOG } from 'modules/Profile/routes';
import { ROUTE_USER_PAYMENT_LOG } from 'modules/User/routes';
import User from 'modules/User/model/User';
import { formatDate, formatTimeValue, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import './index.scss';

type Props = {
  paymentLog: PaymentHistoryValues[];
  user?: User;
};

const PaymentLogTable: React.FC<Props> = ({ paymentLog, user }) => {
  const redirectLink = (value: string | number, id: string | number) => {
    const url = user
      ? {
          pathname: getPathUrl(ROUTE_USER_PAYMENT_LOG, { user_id: user?.id, payment_id: id }),
          state: { userName: user?.name ?? null, isUserActive: !!user?.status }
        }
      : getPathUrl(ROUTE_PROFILE_PAYMENT_LOG, { id });

    return (
      <Authorize fallback={value}>
        <Link className="p-0" to={url}>
          {value}
        </Link>
      </Authorize>
    );
  };

  const cols: TableCol<PaymentHistoryValues>[] = [
    {
      property: 'subscriptions',
      label: 'Produkt',
      value: (row) => redirectLink(row?.subscriptions, row?.payment_number)
    },
    {
      property: 'payment_number',
      label: 'Identyfikator',
      value: (row) => redirectLink(row?.payment_number, row?.payment_number)
    },
    {
      property: 'status',
      label: 'Status',
      value: (row) => paymentLogStatusValue(row?.status)
    },
    {
      property: 'amount',
      label: 'Kwota',
      value: (row) => (row ? `${row.amount.toFixed(2).replace('.', ',')} PLN` : '')
    },
    {
      property: 'tax_value',
      label: 'Podatek',
      value: (row) => `${row?.tax_value?.toFixed(2).replace('.', ',')}`
    },
    {
      property: 'finished_at',
      label: 'Data zakupu',
      // eslint-disable-next-line react/display-name
      value: (row) => (
        <>
          {ifContainsDateFormat(row?.finished_at, formatDate)}
          <span className="time">{ifContainsDateFormat(row?.finished_at, formatTimeValue)}</span>
        </>
      )
    }
  ];

  return (
    <div className="pzpeu-table-list payment-logs-table">
      <Table cols={cols} rows={paymentLog} />
    </div>
  );
};

export default PaymentLogTable;
