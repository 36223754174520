import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { ClauseConnectedJudgementValues } from 'modules/Clause/type';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import Clause from 'modules/Clause/model/Clause';

type Props = {
  clauseJudgements: ClauseConnectedJudgementValues[];
  setShowContent?: (judgmentId: number) => void;
  clause?: Clause;
};

const ClauseConnectedJudgmentsTable: React.FC<Props> = ({ clauseJudgements, setShowContent }) => {
  const showContent = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: number) => {
    e.preventDefault();
    setShowContent(id);
  };

  const onItemClicked = (value: string, id: number) => (
    // eslint-disable-next-line
    <a className="fill-table-td" href="#" onClick={(e) => showContent(e, id)}>
      {value}
    </a>
  );

  const cols: TableCol<ClauseConnectedJudgementValues>[] = [
    {
      property: 'identifier',
      label: 'Identyfikator publikacji',
      value: (row) => onItemClicked(row.judgment.identifier, row.judgment.id)
    },
    {
      property: 'authority_name',
      label: 'Nazwa organu publikującego dokument',
      value: (row) => row.judgment.authority_name
    },
    {
      property: 'authority_type',
      label: 'Rodzaj dokumentu',
      value: (row) => row.judgment.authority_type
    },
    {
      property: 'published_at ',
      label: 'Termin publikacji',
      value: (row) => ifContainsDateFormat(row.judgment.published_at, formatDate)
    }
  ];

  return <Table cols={cols} rows={clauseJudgements} />;
};

export default ClauseConnectedJudgmentsTable;
