import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { getPathUrl } from '../../../../Shared/helper/api';
import { ROUTE_FOREIGN_PROCEEDINGS_ALERT, ROUTE_FOREIGN_PROCEEDINGS_ALERT_UPDATE } from '../../../routes';
import ActionUpdate from '../../../../Layout/component/Action/Update';
import EditIcon from '../../../../Layout/component/Icon/Edit';
import ActionDelete from '../../../../Layout/component/Action/Delete';
import { AlertFormData } from '../../../component/Form/AlertForm';

type Props = {
  foreignProceedingsAlertsList?: AlertFormData[];
  onDeleteClick?: (alert: AlertFormData) => void;
  editable?: boolean;
};

const ForeignProceedingsAlertsTable: React.FC<Props> = ({ foreignProceedingsAlertsList, onDeleteClick, editable }) => {
  const redirectLink = (value: string, id: number) => (
    <Link className="fill-table-td" to={getPathUrl(ROUTE_FOREIGN_PROCEEDINGS_ALERT, { id })}>
      {value}
    </Link>
  );

  const cols: TableCol<AlertFormData>[] = [
    {
      property: 'name',
      label: 'Nazwa',
      value: (row) => redirectLink(row?.name, row.id)
    },
    {
      property: 'created_at',
      label: 'Data utworzenia',
      value: (row) => ifContainsDateFormat(row?.created_at, formatDate)
    },
    {
      property: 'updated_at',
      label: 'Data ostatniej zmiany',
      value: (row) => ifContainsDateFormat(row?.updated_at, formatDate)
    }
  ];

  if (editable) {
    cols.push({
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            <ActionUpdate
              title="Edytuj postępowanie"
              label={<EditIcon height="20px" />}
              to={getPathUrl(ROUTE_FOREIGN_PROCEEDINGS_ALERT_UPDATE, { id: row.id })}
            />
            {onDeleteClick && <ActionDelete className="ml-1" title="Usuń" onClick={() => onDeleteClick(row)} />}
          </div>
        );
      }
    });
  }

  return <Table cols={cols} rows={foreignProceedingsAlertsList} />;
};

export default ForeignProceedingsAlertsTable;
