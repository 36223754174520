import {
  ForeignProceedingsApiImportsListState,
  initForeignProceedingsApiImportsListState
} from '../../state/foreignProceedingsApiImportsList';
import {
  FOREIGN_PROCEEDINGS_API_IMPORTS_LIST,
  FOREIGN_PROCEEDINGS_CLEAR_API_IMPORTS_LIST,
  ForeignProceedingsApiImportsListAction,
  SET_FOREIGN_PROCEEDINGS_API_IMPORTS_LIST_PARAMS
} from '../../action/ForeignProceedingsApiImportsList';

const initState = initForeignProceedingsApiImportsListState();

const reducer = (
  state: ForeignProceedingsApiImportsListState = initState,
  action: ForeignProceedingsApiImportsListAction
): ForeignProceedingsApiImportsListState => {
  switch (action.type) {
    case SET_FOREIGN_PROCEEDINGS_API_IMPORTS_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case FOREIGN_PROCEEDINGS_API_IMPORTS_LIST: {
      const { list = [], meta } = action.payload;

      return { ...state, fetching: false, list, meta };
    }
    case FOREIGN_PROCEEDINGS_CLEAR_API_IMPORTS_LIST: {
      return { ...state, fetching: false, list: [] };
    }

    default:
      return state;
  }
};

export default reducer;
