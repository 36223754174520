import { suffixLabel } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import AddFiles from 'modules/Shared/helper/Files/AddFiles';
import { DATE_FORMAT_SLASH } from 'modules/Shared/helper/utils';
import moment from 'moment';
import { PenaltyDataItem } from '../../../model/Order';
import useWysiwyg from '../../../../Layout/component/Wysiwyg';

export interface Props {
  values: PenaltyDataItem;
  setValues: React.Dispatch<React.SetStateAction<PenaltyDataItem[]>>;
  handleDeletePenalty: (id: string | number | null, index: number) => void;
  isEditForm: boolean;
  i: number;
  errors?: ValidationErrors;
  disabled?: boolean;
  required?: boolean;
}

const OrderPenaltiesFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, disabled, required, isEditForm, setValues, i, handleDeletePenalty } = props;

  const { executor_salary, penalty_amount, violation_date, violation_description, id } = values;

  const { displayWysiwyg: displayPenaltiesWysiwyg } = useWysiwyg({
    title: 'Przyczyny naliczenia kary umownej',
    content: violation_description,
    tooltip: { specialSign: true },
    onChange: (content) =>
      setValues((prevState) =>
        prevState.map((item, index) => (index === i ? { ...item, violation_description: content } : item))
      )
  });

  const handleViolationDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;
    setValues((prevState) =>
      prevState.map((item, index) => (index === i ? { ...item, violation_date: newDate } : item))
    );
  };

  const handleSalaryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSalary = event.target.value;
    setValues((prevState) =>
      prevState.map((item, index) => {
        return index === i ? { ...item, executor_salary: Number(newSalary) } : item;
      })
    );
  };

  const handlePenaltyAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAmountChange = event.target.value;
    setValues((prevState) =>
      prevState.map((item, index) => {
        return index === i ? { ...item, penalty_amount: Number(newAmountChange) } : item;
      })
    );
  };

  const setPenaltyFiles = (itemWithFiles: PenaltyDataItem) => {
    setValues((prevState) => prevState.map((item, index) => (index === i ? { ...item, ...itemWithFiles } : item)));
  };

  return (
    <div className="flex-row gap-2 d-flex justify-content-between align-items-start">
      <fieldset className="m-0 flex-grow-1" disabled={disabled}>
        <FormGroup>
          {displayPenaltiesWysiwyg && displayPenaltiesWysiwyg()}

          {hasError(errors, `penalties[${i}].violation_description`) && (
            <FormFeedback className="d-block">{getError(errors, `penalties[${i}].violation_description`)}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label for={`violation_date-${i}`}>{suffixLabel('Data naliczenia kary umownej', required)}</Label>
          <Input
            type="text"
            name={`violation_date-${i}`}
            id={`violation_date-${i}`}
            placeholder={DATE_FORMAT_SLASH}
            value={violation_date}
            onChange={handleViolationDateChange}
            invalid={hasError(errors, `penalties[${i}].violation_date`)}
          />
          {violation_date && !moment(violation_date, DATE_FORMAT_SLASH, true).isValid() && (
            <FormFeedback className="d-block">Obsługiwany format: {DATE_FORMAT_SLASH}.</FormFeedback>
          )}
          {hasError(errors, `penalties[${i}].violation_date`) && (
            <FormFeedback className="d-block">{getError(errors, `penalties[${i}].violation_date`)}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label for={`input-executor_salary-${i}`}>{suffixLabel('Wysokość wynagrodzenia wykonawcy', required)}</Label>
          <Input
            type="number"
            id={`input-executor_salary-${i}`}
            value={executor_salary}
            onChange={handleSalaryChange}
            min={0.01}
            max={999999999999.99}
            step={0.01}
            invalid={hasError(errors, `penalties[${i}].executor_salary`)}
            required={required}
          />
          {hasError(errors, `penalties[${i}].executor_salary`) && (
            <FormFeedback>{getError(errors, `penalties[${i}].executor_salary`)}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label for={`input-penalty_amount-${i}`}>{suffixLabel('Wysokość kary umownej', required)}</Label>
          <Input
            type="number"
            id={`input-penalty_amount-${i}`}
            value={penalty_amount}
            onChange={handlePenaltyAmountChange}
            min={0.01}
            max={999999999999.99}
            step={0.01}
            invalid={hasError(errors, `penalties[${i}].penalty_amount`)}
            required={required}
          />
          {hasError(errors, `penalties[${i}].penalty_amount`) && (
            <FormFeedback>{getError(errors, `penalties[${i}].penalty_amount`)}</FormFeedback>
          )}
        </FormGroup>
        {!isEditForm && (
          <FormGroup>
            <AddFiles values={values as any} setValues={setPenaltyFiles} objKey="files" maxSizeInMb={50} />
          </FormGroup>
        )}
      </fieldset>
      <button type="button" onClick={() => handleDeletePenalty(id, i)} className="btn btn-link p-0 text-danger">
        <i className="fa fa-times font-18" />
      </button>
    </div>
  );
};

export default OrderPenaltiesFieldset;
