import React, { useEffect, useRef, useState } from 'react';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_BASIC_INFORMATIONS } from 'modules/Agreements/step';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import Date from 'modules/Layout/component/Input/Date';
import { getError, hasError } from 'modules/Shared/helper/validation';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'app/reducer';
import User from 'modules/User/model/User';

export interface AgreementStepBasicInformationsValues {
  agreement_date: string | null;
  proceeding_name_checked: boolean;
  proceeding_name_content: string | null;
  number_checked: boolean;
  number_content: string | null;
  ordering_person: string | null;
  place_content: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const mapState = (step: AgreementStepBasicInformationsValues, user: User): AgreementStepBasicInformationsValues => {
  const { checkboxes, ordering_person, ...rest } = step;

  return {
    ...rest,
    ordering_person: ordering_person || user.getFullName(),
    checkboxes: checkboxes || {}
  };
};

const AgreementStepBasicInformations = (props: AgreementStepProps): JSX.Element => {
  const { steps, onSubmit, onChange, errors } = props;
  const { user } = useSelector((state: RootState) => state.auth);
  const initState = useRef<AgreementStepBasicInformationsValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_BASIC_INFORMATIONS), user)
  );
  const [stepValues, setStepValues] = useState<AgreementStepBasicInformationsValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_BASIC_INFORMATIONS), user);
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderCheckbox } = useStepFields({
    stepValues,
    setStepValues,
    mapState: (values) => mapState(values, user),
    errors
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        <Label>Data udzielenia zamówienia</Label>
        <Date
          id="agreement-date"
          name="agreement_date"
          value={stepValues.agreement_date}
          invalid={hasError(errors, 'agreement_date')}
          required
          onChange={(date) => setStepValues((values) => ({ ...values, agreement_date: date }))}
        />
        {hasError(errors, 'agreement_date') && (
          <FormFeedback className="d-block">{getError(errors, 'agreement_date')}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="place_content">Miejsce udzielenia zamówienia</Label>
        <Input
          id="place_content"
          name="place_content"
          value={stepValues.place_content || ''}
          required
          onChange={(event) => {
            setStepValues((values) => ({ ...values, place_content: event.target.value }));
          }}
          invalid={hasError(errors, 'place_content')}
        />
      </FormGroup>
      {hasError(errors, 'place_content') && (
        <FormFeedback className="d-block">{getError(errors, 'place_content')}</FormFeedback>
      )}
      <FormGroup>
        <Label for="ordering_person">Osoba składająca zamówienie</Label>
        <Input
          id="ordering_person"
          name="ordering_person"
          value={stepValues.ordering_person || ''}
          required
          onChange={(event) => {
            setStepValues((values) => ({ ...values, ordering_person: event.target.value }));
          }}
          invalid={hasError(errors, 'ordering_person')}
        />
      </FormGroup>
      {hasError(errors, 'ordering_person') && (
        <FormFeedback className="d-block">{getError(errors, 'ordering_person')}</FormFeedback>
      )}
      {renderCheckbox('proceeding_name_checked', 'Nazwa postępowania')}
      {stepValues.proceeding_name_checked && (
        <FormGroup>
          <Input
            type="text"
            min={0}
            id="proceeding_name_content"
            name="proceeding_name_content"
            value={stepValues.proceeding_name_content || ''}
            required
            onChange={(event) => {
              setStepValues((values) => ({ ...values, proceeding_name_content: event.target.value }));
            }}
            invalid={hasError(errors, 'proceeding_name_content')}
          />
        </FormGroup>
      )}
      {hasError(errors, 'proceeding_name_content') && (
        <FormFeedback className="d-block">{getError(errors, 'proceeding_name_content')}</FormFeedback>
      )}
      {renderCheckbox('number_checked', 'Identyfikator')}
      {stepValues.number_checked && (
        <FormGroup>
          <Input
            type="text"
            min={0}
            id="number_content"
            name="number_content"
            value={stepValues.number_content || ''}
            required
            onChange={(event) => {
              setStepValues((values) => ({ ...values, number_content: event.target.value }));
            }}
            invalid={hasError(errors, 'number_content')}
          />
        </FormGroup>
      )}
      {hasError(errors, 'number_content') && (
        <FormFeedback className="d-block">{getError(errors, 'number_content')}</FormFeedback>
      )}
    </AgreementFormWrapper>
  );
};

export default AgreementStepBasicInformations;
