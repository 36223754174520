import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import Judgement, { createJudgement } from 'modules/Judgement/model/Judgement';
import { fetchJudgmentClient } from 'modules/JudgementClient/repository';
import { breadcrumbRouteJudgementClientDetails } from 'modules/JudgementClient/breadcrumbs';
import JudgmentClientDisplayDetails from 'modules/JudgementClient/component/Details/Judgement';

const JudgementClientDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [judgement, setJudgement] = useState<Judgement>(null);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const { data, cancelled, message: messageResponse } = await fetchRequest(fetchJudgmentClient, id, cancelToken);

      if (cancelled) return;
      if (data) setJudgement(createJudgement(data.data));
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (judgement)
      dispatch(
        managePageAction({
          title: `Szczegóły orzeczenia o identyfikatorze: ${judgement.identifier}`,
          breadcrumb: breadcrumbRouteJudgementClientDetails(judgement.identifier, judgement.id)
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [judgement]);

  if (fetching) return <Loader />;
  if (!fetching && !judgement && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  if (!fetching && judgement) {
    return (
      <div className="row user-details-view position-relative">
        <JudgmentClientDisplayDetails judgement={judgement} />
      </div>
    );
  }

  return null;
};

export default JudgementClientDetails;
