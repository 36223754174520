import React from 'react';
import User from 'modules/User/model/User';
import InformationList from 'modules/Layout/component/InformationList';
// import { ROLE_ADMIN } from 'modules/User/model/UserRole';

export interface Props {
  user: User;
}

const CompanyDataset: React.FC<Props> = ({ user }: Props): JSX.Element => {
  const privateUser = user?.company?.client_type === 1;

  const clientTypeFields = () => {
    return privateUser
      ? [
          {
            label: 'Imię',
            value: user?.company?.first_name
          },
          {
            label: 'Nazwisko',
            value: user?.company?.last_name
          }
        ]
      : [
          {
            label: 'Imię właściciela',
            value: user?.company?.first_name
          },
          {
            label: 'Nazwisko właściciela',
            value: user?.company?.last_name
          },
          {
            label: 'NIP',
            value: user?.company?.nip
          }
        ];
  };

  const fields = [
    {
      label: 'Rodzaj klienta',
      value: user?.getCompanyClientType()
    },
    {
      label: 'Nazwa',
      value: user?.company?.name
    },
    ...clientTypeFields(),
    {
      label: 'Adres e-mail',
      value: user?.company?.email
    },
    {
      label: 'Miejscowość',
      value: user?.company?.city
    },
    {
      label: 'Ulica / numer',
      value: user?.company?.street
    },
    {
      label: 'Kod pocztowy',
      value: user?.getCompanyPostal()
    },
    {
      label: 'Adres korespondencyjny',
      value: user?.getCompanyCorrespondenceAddress()
    }
  ];

  return <InformationList fields={fields} />;
};

export default CompanyDataset;
