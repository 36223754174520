import { PaginationMeta, PartialSearchingProps } from 'modules/Shared/type';
import { Action, Dispatch } from 'redux';
import { RootState } from 'app/reducer';
import _ from 'lodash';
import Validation from 'modules/Shared/exception/Validation';
import { SetSearchingParamsAction, setSearchingParamsAction } from 'modules/Shared/action';
import { createSearchParams } from 'modules/Shared/helper/params';
import { fetchTraditionalPaymentsWaiting } from 'modules/Subscriptions/repository';
import { TraditionalPaymentEntity } from 'modules/Subscriptions/model/TraditionalPayment';

export const SET_TRADITIONAL_PAYMENT_LIST = 'TRADITIONAL_PAYMENT/LIST';
export const SET_TRADITIONAL_PAYMENT_LIST_PARAMS = 'TRADITIONAL_PAYMENT/LIST/PARAMS/SET';

export type TraditionalPaymentListFetchedPayload = {
  payments: TraditionalPaymentEntity[];
  meta: PaginationMeta;
};

export type TraditionalPaymentListAction = TraditionalPaymentListSetParamsAction | TraditionalPaymentListFetchedAction;

export type TraditionalPaymentListSetParamsAction = SetSearchingParamsAction<
  typeof SET_TRADITIONAL_PAYMENT_LIST_PARAMS
>;

export interface TraditionalPaymentListFetchedAction extends Action<typeof SET_TRADITIONAL_PAYMENT_LIST> {
  payload: TraditionalPaymentListFetchedPayload;
}

export const setTraditionalPaymentListParamsAction =
  (payload: PartialSearchingProps) => async (dispatch: Dispatch, getState: () => RootState) => {
    const currentState = getState()?.subscriptions?.traditionalPayment;

    if (
      payload?.filter &&
      !payload?.pagination &&
      typeof currentState?.pagination?.page === 'number' &&
      currentState.pagination.page !== 1 &&
      !_.isEqual(currentState.filter, payload.filter)
    ) {
      currentState.pagination.page = 1;
    }

    const state = { ...currentState, ...payload };

    dispatch(setSearchingParamsAction(SET_TRADITIONAL_PAYMENT_LIST_PARAMS, payload));

    try {
      const { pagination, filter, sort } = state;

      const searchParams = createSearchParams({ pagination, filter, sort });

      const response = await fetchTraditionalPaymentsWaiting(searchParams);

      const { data, meta } = response.data;

      dispatch<TraditionalPaymentListFetchedAction>({
        type: SET_TRADITIONAL_PAYMENT_LIST,
        payload: { payments: data, meta }
      });
    } catch (error) {
      if (error instanceof Validation) {
        dispatch<TraditionalPaymentListFetchedAction>({
          type: SET_TRADITIONAL_PAYMENT_LIST,
          payload: { payments: [], meta: null }
        });
      } else {
        throw error;
      }
    }
  };
