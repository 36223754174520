import React from 'react';
import { Menu } from 'app/menu';
import {
  JUDGMENTS_ADD,
  JUDGMENTS_INDEX,
  JUDGMENT_CATEGORIES_INDEX,
  JUDGMENT_KEYWORDS_INDEX,
  JUDGMENTS_MODULE_ADMIN_ACCESS
} from 'modules/Judgement/permissions';
import {
  ROUTE_JUDGEMENT_CATEGORIES,
  ROUTE_KEYWORDS_WITH_CATEGORIES,
  ROUTE_JUDGEMENT_CREATE,
  ROUTE_JUDGEMENT_LIST
} from 'modules/Judgement/routes';
import JudgementsIcon from 'modules/Layout/component/Icon/Judgements';

const MenuOfficesShared: Menu[] = [
  {
    key: 'judgement-nested',
    type: 'nested',
    title: 'Orzeczenia sądowe',
    icon: <JudgementsIcon />,
    navLevel: 'second',
    href: '',
    permissions: [JUDGMENTS_ADD, JUDGMENT_CATEGORIES_INDEX, JUDGMENT_KEYWORDS_INDEX, JUDGMENTS_MODULE_ADMIN_ACCESS],
    isActive: (_match: null) => false,
    children: [
      {
        key: 'judgement-list-index',
        type: 'link',
        title: 'Lista orzeczeń',
        href: ROUTE_JUDGEMENT_LIST,
        permissions: [JUDGMENTS_INDEX]
      },
      {
        key: 'judgement-add',
        type: 'link',
        title: 'Dodaj',
        href: ROUTE_JUDGEMENT_CREATE,
        permissions: [JUDGMENTS_ADD]
      },
      {
        key: 'judgement-category-index',
        type: 'link',
        title: 'Kategorie',
        href: ROUTE_JUDGEMENT_CATEGORIES,
        permissions: [JUDGMENT_CATEGORIES_INDEX]
      },
      {
        key: 'judgement-keyword-list',
        type: 'link',
        title: 'Słowa kluczowe',
        href: ROUTE_KEYWORDS_WITH_CATEGORIES,
        permissions: [JUDGMENT_KEYWORDS_INDEX]
      }
    ]
  }
];

export default MenuOfficesShared;
