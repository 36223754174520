import { addToastAction, managePageAction } from 'modules/Layout/action';
import Form from 'modules/Clause/component/Form';
import React, { useEffect, useState, useRef } from 'react';
import Loader from 'modules/Layout/component/Loader';
import { useDispatch } from 'react-redux';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import { createClauseToastSuccess } from 'modules/Clause/toasts';
import { ClauseFieldValues } from 'modules/Clause/type';
import { createClause } from 'modules/Clause/repository';
import { ROUTE_CLAUSE } from 'modules/Clause/routes';
import { breadcrumbRouteClauseCreate } from 'modules/Clause/breadcrumbs';
import CardBox from 'modules/Layout/component/CardBox';

const ClauseCreateView = () => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState(null);
  const scrollToTopOnce = useRef<boolean>(true);

  const cancelToken = useCancelToken();
  useEffect(() => {
    dispatch(
      managePageAction({
        title: 'Tworzenie klauzul',
        breadcrumb: breadcrumbRouteClauseCreate()
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchCreateClauseToast = () => dispatch(addToastAction(createClauseToastSuccess()));
  const setCreateClauseAction = async (data: ClauseFieldValues) => {
    setFetching(true);

    if (message) setMessage(null);
    if (errors) setErrors(null);

    const {
      message: messageResponse,
      error,
      cancelled,
      data: responseData
    } = await updateRequestWithData(createClause, data, cancelToken);

    if (cancelled) return;

    if (responseData) {
      dispatchCreateClauseToast();
      const { id } = responseData.data;
      if (id) dispatch(push(getPathUrl(ROUTE_CLAUSE, { id })));
      // else dispatch(push(ROUTE_OFFICES));
    } else {
      if (error) setErrors(error);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <div className="user-create-view d-flex justify-content-center">
      <CardBox form="small" heading="Dodaj klauzulę">
        {fetching && <Loader />}
        <Alert message={message} />
        <Form errors={errors} submit={(data) => setCreateClauseAction(data)} />
      </CardBox>
    </div>
  );
};

export default ClauseCreateView;
