import _isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import { ExecutorEntity, executorViesExistOptions } from 'modules/Executor/model/Executor';
import { suffixLabel } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React, { useState } from 'react';
import moment from 'moment';
import { Button, CustomInput, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { DATE_FORMAT_SLASH, DATE_FORMAT_VALUE } from 'modules/Shared/helper/utils';
import { fetchExecutorFromGus } from 'modules/Executor/repository';
import { addToastAction } from 'modules/Layout/action';
import { executorFromGusToastSuccess, executorFromGusToastError } from 'modules/Executor/toasts';
import DisplayPopovers from 'modules/Layout/component/Popover/DisplayWrapper';
import Select from 'modules/Layout/component/Input/Select';

export interface Props {
  values: ExecutorEntity;
  errors?: ValidationErrors;
  disabled?: boolean;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, key: keyof ExecutorEntity) => void;
  onSelectChange: (value: number | string, key: keyof ExecutorEntity) => void;
  onForeignNipCheckboxChange: () => void;
  placeholder?: boolean;
  setMultipleValues: (data: Record<any, any>) => void;
  executorFromGus: Record<any, any>;
  setExecutorFromGus: (data: Record<any, any>) => void;
  consumerBankruptcy: boolean;
  setConsumerBankruptcy: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExecutorFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const {
    values,
    errors,
    disabled,
    onChange,
    onSelectChange,
    onForeignNipCheckboxChange,
    placeholder = false,
    required,
    setMultipleValues,
    executorFromGus,
    setExecutorFromGus,
    consumerBankruptcy,
    setConsumerBankruptcy
  } = props;
  const [fillByHandStatus, setFillByHandStatus] = useState<boolean>(!!values.name);
  const [dataCorrectnessStatus, setDataCorrectnessStatus] = useState<boolean>(false);

  const {
    name,
    vies_exist = '',
    ZUS_name,
    ZUS_email,
    US_email,
    US_name,
    first_name = '',
    last_name = '',
    email = '',
    phone = '',
    nip = '',
    foreign_nip = false,
    city = '',
    voivodeship = '',
    street = '',
    business_start_date = '',
    krs = '',
    postal_code = '',
    post_office = '',
    consumer_bankruptcy_date = '',
    legal_form = null
  } = values;

  const fetchExecutor = async (): Promise<any> => {
    try {
      const {
        data: { data }
      } = await fetchExecutorFromGus(values.nip);

      if (_isEmpty(data)) {
        dispatch(addToastAction(executorFromGusToastError()));
        return null;
      }

      dispatch(addToastAction(executorFromGusToastSuccess()));

      const { business_start_date: businessStartDate } = data[0];

      if (businessStartDate) {
        const momentObj = moment(businessStartDate, DATE_FORMAT_VALUE, true);
        // eslint-disable-next-line consistent-return
        if (!momentObj.isValid()) return;

        data[0] = {
          ...data[0],
          business_start_date: momentObj.format(DATE_FORMAT_SLASH)
        };
      }

      setMultipleValues(data[0]);
      setExecutorFromGus(data[0]);
    } catch (error) {
      throw error;
    }
  };

  return (
    <fieldset className="m-0" disabled={disabled}>
      <FormGroup>
        {!placeholder && <Label for="input-nip">NIP</Label>}
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column w-100">
            <Input
              type={foreign_nip ? 'text' : 'number'}
              name="nip"
              id="input-nip"
              placeholder={placeholder ? 'NIP' : null}
              value={nip}
              onChange={(event) => onChange(event, 'nip')}
              invalid={hasError(errors, 'nip')}
              className="mr-1"
            />
            {hasError(errors, 'nip') && <FormFeedback>{getError(errors, 'nip')}</FormFeedback>}
          </div>
          <DisplayPopovers
            popoverId="popover_nip"
            popoverContent="Dane są automatycznie pobierane z rejestru GUS"
            className="tooltip-light"
          />
        </div>
      </FormGroup>
      <FormGroup>
        <div className="d-flex flex-wrap align-items-center">
          <CustomInput
            id="foreign_nip"
            type="checkbox"
            label="NIP firmy zagranicznej (zezwolenie użycia wartości alfabetycznych)"
            checked={foreign_nip}
            onChange={onForeignNipCheckboxChange}
          />
        </div>
      </FormGroup>
      <div className="w-100">
        {!fillByHandStatus && executorFromGus === null && (
          <Button
            type="button"
            color="primary"
            className="pzpeu-btn-disabled waves-effect waves-light w-100"
            disabled={!values.nip}
            onClick={fetchExecutor}
          >
            Wyszukaj
          </Button>
        )}
        {executorFromGus === null && (
          <FormGroup>
            <CustomInput
              id="fill_by_hand"
              type="checkbox"
              name="fill_by_hand"
              label="Wprowadź dane ręcznie."
              className="mt-4"
              checked={fillByHandStatus}
              onChange={(event) => setFillByHandStatus(event.target.checked)}
            />
          </FormGroup>
        )}
      </div>
      {(fillByHandStatus || executorFromGus) && [
        <FormGroup key="name">
          {!placeholder && <Label for="input-name">{suffixLabel('Nazwa', required)}</Label>}
          <Input
            type="text"
            name="name"
            id="input-name"
            placeholder={placeholder ? 'Nazwa*' : null}
            value={name}
            onChange={(event) => onChange(event, 'name')}
            invalid={hasError(errors, 'name')}
            required={required}
          />
          {hasError(errors, 'name') && <FormFeedback>{getError(errors, 'name')}</FormFeedback>}
        </FormGroup>,
        <FormGroup key="first_name">
          {!placeholder && <Label for="input-name">Imię</Label>}
          <Input
            type="text"
            name="first_name"
            id="input-first-name"
            placeholder={placeholder ? 'Imię*' : null}
            value={first_name}
            onChange={(event) => onChange(event, 'first_name')}
            invalid={hasError(errors, 'first_name')}
            maxLength={200}
          />
          {hasError(errors, 'first_name') && <FormFeedback>{getError(errors, 'first_name')}</FormFeedback>}
        </FormGroup>,
        <FormGroup key="last_name">
          {!placeholder && <Label for="input-last-name">Nazwisko</Label>}
          <Input
            type="text"
            name="last_name"
            id="input-last-name"
            placeholder={placeholder ? 'Nazwisko' : null}
            value={last_name}
            onChange={(event) => onChange(event, 'last_name')}
            invalid={hasError(errors, 'last_name')}
            maxLength={200}
          />
          {hasError(errors, 'last_name') && <FormFeedback>{getError(errors, 'last_name')}</FormFeedback>}
        </FormGroup>,
        <FormGroup key="email">
          {!placeholder && <Label for="input-email">Adres email</Label>}
          <Input
            type="email"
            name="email"
            id="input-email"
            placeholder={placeholder ? 'Adres email' : null}
            value={email}
            onChange={(event) => onChange(event, 'email')}
            invalid={hasError(errors, 'email')}
          />
          {hasError(errors, 'email') && <FormFeedback>{getError(errors, 'email')}</FormFeedback>}
        </FormGroup>,
        <FormGroup key="phone">
          {!placeholder && <Label for="input-phone">Telefon</Label>}
          <Input
            type="number"
            name="phone"
            id="input-phone"
            placeholder={placeholder ? 'Telefon' : null}
            value={phone}
            onChange={(event) => onChange(event, 'phone')}
            invalid={hasError(errors, 'phone')}
          />
          {hasError(errors, 'phone') && <FormFeedback>{getError(errors, 'phone')}</FormFeedback>}
        </FormGroup>,
        <FormGroup key="city">
          {!placeholder && <Label for="input-city">Miasto</Label>}
          <Input
            type="text"
            name="city"
            id="input-city"
            placeholder={placeholder ? 'Miasto' : null}
            value={city}
            onChange={(event) => onChange(event, 'city')}
            invalid={hasError(errors, 'city')}
          />
          {hasError(errors, 'city') && <FormFeedback>{getError(errors, 'city')}</FormFeedback>}
        </FormGroup>,
        <FormGroup key="voivodeship">
          {!placeholder && <Label for="input-voivodeship">Województwo</Label>}
          <Input
            type="text"
            name="voivodeship"
            id="input-voivodeship"
            placeholder={placeholder ? 'Województwo' : null}
            value={voivodeship}
            onChange={(event) => onChange(event, 'voivodeship')}
            invalid={hasError(errors, 'voivodeship')}
          />
          {hasError(errors, 'voivodeship') && <FormFeedback>{getError(errors, 'voivodeship')}</FormFeedback>}
        </FormGroup>,
        <FormGroup key="street">
          {!placeholder && <Label for="input-street">Ulica</Label>}
          <Input
            type="text"
            name="street"
            id="input-street"
            placeholder={placeholder ? 'Ulica' : null}
            value={street}
            onChange={(event) => onChange(event, 'street')}
            invalid={hasError(errors, 'street')}
          />
          {hasError(errors, 'street') && <FormFeedback>{getError(errors, 'street')}</FormFeedback>}
        </FormGroup>,
        <FormGroup key="postal_code">
          {!placeholder && <Label for="input-postal_code">Kod pocztowy</Label>}
          <Input
            type="text"
            name="postal_code"
            id="input-postal_code"
            placeholder={placeholder ? 'Kod pocztowy' : null}
            value={postal_code}
            onChange={(event) => onChange(event, 'postal_code')}
            invalid={hasError(errors, 'postal_code')}
          />
          {hasError(errors, 'postal_code') && <FormFeedback>{getError(errors, 'postal_code')}</FormFeedback>}
        </FormGroup>,
        <FormGroup key="post_office">
          {!placeholder && <Label for="input-post_office">Miejscowość poczty</Label>}
          <Input
            type="text"
            name="post_office"
            id="input-post_office"
            placeholder={placeholder ? 'Miejscowość poczty' : null}
            value={post_office}
            onChange={(event) => onChange(event, 'post_office')}
            invalid={hasError(errors, 'post_office')}
          />
          {hasError(errors, 'post_office') && <FormFeedback>{getError(errors, 'post_office')}</FormFeedback>}
        </FormGroup>,
        <FormGroup key="business_start_date" className="mb-0">
          {!placeholder && <Label for="input-business_start_date">Data wpisu do KRS lub CEIDG</Label>}
          <div id="wrapper-input-business_start_date">
            <Input
              type="text"
              name="business_start_date"
              id="input-business_start_date"
              placeholder={placeholder ? 'Data wpisu do KRS lub CEIDG' : DATE_FORMAT_SLASH}
              value={business_start_date}
              onChange={(event) => onChange(event, 'business_start_date')}
              invalid={hasError(errors, 'business_start_date')}
            />
          </div>
          {business_start_date && !moment(business_start_date, DATE_FORMAT_SLASH, true).isValid() && (
            <FormFeedback className="d-block">Obsługiwany format: {DATE_FORMAT_SLASH}.</FormFeedback>
          )}
          {hasError(errors, 'business_start_date') && (
            <FormFeedback className="d-block">{getError(errors, 'business_start_date')}</FormFeedback>
          )}
        </FormGroup>,
        <FormGroup key="business_krs">
          {!placeholder && <Label for="input-krs">KRS</Label>}
          <Input
            type="text"
            name="business_krs"
            id="input-krs"
            placeholder={placeholder ? 'KRS' : null}
            value={krs}
            onChange={(event) => onChange(event, 'krs')}
            invalid={hasError(errors, 'krs')}
          />
          {hasError(errors, 'krs') && <FormFeedback className="d-block">{getError(errors, 'krs')}</FormFeedback>}
        </FormGroup>,
        <div key="vies_exist" className="d-flex align-items-center">
          <div className="d-flex flex-column w-100">
            <FormGroup>
              {!placeholder && <Label for="input-vies_exist">Status wpisu w systemie VIES</Label>}
              <Select
                name="vies_exist"
                id="input-vies_exist"
                value={{
                  value: vies_exist,
                  label: executorViesExistOptions.find((item) => item.value === vies_exist).label
                }}
                options={executorViesExistOptions}
                required={false}
                onChange={(option) => onSelectChange(option.value, 'vies_exist')}
                invalid={hasError(errors, 'vies_exist')}
              />
              {hasError(errors, 'vies_exist') && (
                <FormFeedback className="d-block">{getError(errors, 'vies_exist')}</FormFeedback>
              )}
            </FormGroup>
          </div>
          <a target="_blank" href="https://ec.europa.eu/taxation_customs/vies/#/vat-validation" rel="noreferrer">
            <DisplayPopovers
              popoverId="popover_consumer_bankruptcy_date"
              popoverContent="Status wpisu w systemie VIES"
              className="tooltip-light"
            />
          </a>
        </div>,
        <FormGroup key="ZUS_name">
          {!placeholder && <Label for="input-ZUS_name">Nazwa ZUS</Label>}
          <Input
            type="text"
            name="ZUS_name"
            id="input-ZUS_name"
            placeholder={placeholder ? 'Nazwa ZUS' : null}
            value={ZUS_name}
            onChange={(event) => onChange(event, 'ZUS_name')}
            invalid={hasError(errors, 'ZUS_name')}
          />
          {hasError(errors, 'ZUS_name') && (
            <FormFeedback className="d-block">{getError(errors, 'ZUS_name')}</FormFeedback>
          )}
        </FormGroup>,
        <FormGroup key="ZUS_email">
          {!placeholder && <Label for="input-ZUS_name">E-mail ZUS</Label>}
          <Input
            type="text"
            name="email"
            id="input-email"
            placeholder={placeholder ? 'E-mail ZUS' : null}
            value={ZUS_email}
            onChange={(event) => onChange(event, 'ZUS_email')}
            invalid={hasError(errors, 'ZUS_email')}
          />
          {hasError(errors, 'ZUS_email') && (
            <FormFeedback className="d-block">{getError(errors, 'ZUS_email')}</FormFeedback>
          )}
        </FormGroup>,
        <FormGroup key="US_name">
          {!placeholder && <Label for="input-ZUS_name">Nazwa US</Label>}
          <Input
            type="text"
            name="US_name"
            id="input-US_name"
            placeholder={placeholder ? 'Nazwa US' : null}
            value={US_name}
            onChange={(event) => onChange(event, 'US_name')}
            invalid={hasError(errors, 'US_name')}
          />
          {hasError(errors, 'US_name') && (
            <FormFeedback className="d-block">{getError(errors, 'US_name')}</FormFeedback>
          )}
        </FormGroup>,
        <FormGroup key="US_email">
          {!placeholder && <Label for="input-ZUS_name">E-mail US</Label>}
          <Input
            type="text"
            name="email"
            id="input-email"
            placeholder={placeholder ? 'E-mail US' : null}
            value={US_email}
            onChange={(event) => onChange(event, 'US_email')}
            invalid={hasError(errors, 'US_email')}
          />
          {hasError(errors, 'US_email') && (
            <FormFeedback className="d-block">{getError(errors, 'US_email')}</FormFeedback>
          )}
        </FormGroup>,
        executorFromGus !== null && (
          <FormGroup>
            <Label for="data_correctness_status">
              <span className="h5 pb-1">Zgodność poprawnych danych</span>
            </Label>
            <div className="d-flex flex-wrap align-items-center">
              <CustomInput
                id="data_correctness_status"
                type="checkbox"
                name="data_correctness_status"
                label="Potwierdzam poprawność pobranch danych"
                checked={dataCorrectnessStatus}
                required
                onChange={(event) => setDataCorrectnessStatus(event.target.checked)}
              />
            </div>
          </FormGroup>
        ),
        legal_form === 1 && (
          <>
            <FormGroup key="consumerBankruptcy_no">
              <CustomInput
                id="consumerBankruptcy_no"
                name="consumerBankruptcy"
                label="Wykonawca nie ogłosił upadłości konsumenckiej"
                type="radio"
                checked={consumerBankruptcy === false || null}
                onChange={() => setConsumerBankruptcy(false)}
              />
            </FormGroup>
            <FormGroup key="consumerBankruptcy_yes">
              <CustomInput
                id="consumerBankruptcy_yes"
                name="consumerBankruptcy"
                label="Wykonawca ogłosił upadłość konsumencką"
                type="radio"
                checked={consumerBankruptcy === true}
                onChange={() => setConsumerBankruptcy(true)}
              />
            </FormGroup>
            {consumerBankruptcy === true && (
              <div className="d-flex align-items-center">
                <div className="d-flex flex-column w-100">
                  <FormGroup key="consumer_bankruptcy_date" className="mb-0">
                    <div id="wrapper-input-business_start_date">
                      <Input
                        type="text"
                        name="consumer_bankruptcy_date"
                        id="input-consumer_bankruptcy_date"
                        placeholder={DATE_FORMAT_SLASH}
                        value={consumer_bankruptcy_date}
                        onChange={(event) => onChange(event, 'consumer_bankruptcy_date')}
                        invalid={hasError(errors, 'consumer_bankruptcy_date')}
                      />
                    </div>
                    {consumer_bankruptcy_date &&
                      !moment(consumer_bankruptcy_date, DATE_FORMAT_SLASH, true).isValid() && (
                        <FormFeedback className="d-block">Obsługiwany format: {DATE_FORMAT_SLASH}.</FormFeedback>
                      )}
                    {hasError(errors, 'consumer_bankruptcy_date') && (
                      <FormFeedback className="d-block">{getError(errors, 'consumer_bankruptcy_date')}</FormFeedback>
                    )}
                  </FormGroup>
                </div>
                <a
                  target="_blank"
                  href="https://krz.ms.gov.pl/#!/application/KRZPortalPUB/1.9/KrzPortalPubGui.StronaGlowna?params=JTdCJTdE"
                  rel="noreferrer"
                >
                  <DisplayPopovers
                    popoverId="popover_consumer_bankruptcy_date"
                    popoverContent="Upadłość konsumencka"
                    className="tooltip-light"
                  />
                </a>
              </div>
            )}
          </>
        )
      ]}
    </fieldset>
  );
};

export default ExecutorFieldset;
