export const CLAUSE_CATEGORIES_INDEX = 'clause_categories_index';
export const CLAUSE_CATEGORIES_ADD = 'clause_categories_add';
export const CLAUSE_CATEGORIES_EDIT = 'clause_categories_edit';
export const CLAUSE_CATEGORIES_DELETE = 'clause_categories_delete';
export const CLAUSE_CATEGORIES_DETAILS = 'clause_categories_details';
export const CLAUSE_INDEX = 'clauses_index';
export const CLAUSE_ADD = 'clauses_add';
export const CLAUSE_EDIT = 'clauses_edit';
export const CLAUSE_DELETE = 'clauses_delete';
export const CLAUSE_DETAILS = 'clauses_details';
