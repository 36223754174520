import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import { breadcrumbRouteExecutorsExtendedDetails } from 'modules/User/breadcrumbs';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { fetchExecutorExtendedDetails } from 'modules/User/repository';
import CardBox from 'modules/Layout/component/CardBox';
import { ExecutorEntity } from '../../../../Executor/model/Executor';
import ExecutorExtendedDetailsComponent from '../../../component/ExecutorExtendedDetails';
import ExecutorExtendedRequests from '../../../component/List/ExecutorsExtendedRequests';

const ExecutorExtendedDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [executorExtended, setExecutorExtended] = useState<ExecutorEntity>(null);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const {
        data,
        cancelled,
        message: messageResponse
      } = await fetchRequest(fetchExecutorExtendedDetails, id, cancelToken);

      if (cancelled) return;
      if (data) setExecutorExtended(data.data);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (executorExtended) {
      const breadcrumb = breadcrumbRouteExecutorsExtendedDetails();

      dispatch(
        managePageAction({
          breadcrumb,
          title: 'Zlecenia z PA - szczegóły'
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executorExtended]);

  if (fetching) return <Loader />;
  if (!fetching && !executorExtended && message)
    return (
      <div className="user-create-view d-flex justify-content-center">
        <CardBox>
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </CardBox>
      </div>
    );
  if (!fetching && executorExtended) {
    return (
      <div className="row user-details-view position-relative">
        <ExecutorExtendedDetailsComponent executorExtended={executorExtended} />
        <ExecutorExtendedRequests id={id} />
      </div>
    );
  }

  return null;
};

export default ExecutorExtendedDetails;
