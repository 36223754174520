import React, { Dispatch, SetStateAction } from 'react';
import { CustomInput, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { ValidationErrors } from 'modules/Shared/type';
import { getError, hasError } from 'modules/Shared/helper/validation';

export interface StepFieldCheckboxWithNumberInputProps<T> {
  checkbox: string;
  label: string;
  stepValues: T;
  setStepValues: Dispatch<SetStateAction<T>>;
  mapState: (values: T) => T;
  errors?: ValidationErrors | null;
  disabled?: boolean;
  disabledInputField?: boolean;
  decimalPlaces?: boolean;
}

const StepFieldCheckboxWithNumberInput = <T,>(props: StepFieldCheckboxWithNumberInputProps<T>): JSX.Element => {
  const {
    checkbox,
    label,
    stepValues,
    setStepValues,
    mapState,
    errors,
    disabled = false,
    decimalPlaces = false,
    disabledInputField
  } = props;

  const isInvalid = hasError(errors, `${checkbox}_checked`) || hasError(errors, `${checkbox}_content`);

  const getValue = () => {
    // @ts-ignore
    const stepValue = stepValues[`${checkbox}_content`];

    return label.replace('...', stepValue || '...');
  };

  // @ts-ignore
  return (
    <FormGroup key={checkbox}>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-start">
          <CustomInput
            id={checkbox}
            type="checkbox"
            // @ts-ignore
            checked={stepValues[`${checkbox}_checked`]}
            onChange={(event) => {
              // @ts-ignore
              stepValues[`${checkbox}_checked`] = event.target.checked;
              // @ts-ignore
              setStepValues((values: T) => mapState(values));
            }}
            invalid={isInvalid}
            disabled={disabled}
          />
          <Input
            type="number"
            min={0}
            step={decimalPlaces ? '0.01' : null}
            style={{ width: '100px' }}
            name={`${checkbox}_number`}
            disabled={disabledInputField}
            id={`${checkbox}_number`}
            // @ts-ignore
            value={stepValues[`${checkbox}_content`]}
            invalid={isInvalid}
            onChange={(event) => {
              // @ts-ignore
              stepValues[`${checkbox}_content`] = event.target.value;
              // @ts-ignore
              setStepValues((values: T) => mapState(values));
            }}
          />
          <Label
            className="pl-3"
            style={{ color: isInvalid && '#B7332D' }}
            dangerouslySetInnerHTML={{
              __html: String(getValue())
            }}
          />
        </div>
        {isInvalid && (
          <FormFeedback className="d-block">
            {getError(errors, `${checkbox}_checked`) || getError(errors, `${checkbox}_content`)}
          </FormFeedback>
        )}
      </div>
    </FormGroup>
  );
};

export default StepFieldCheckboxWithNumberInput;
