import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { Button, CustomInput, FormFeedback, FormGroup } from 'reactstrap';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_CONTRACT_SING } from 'modules/Agreements/step';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import { suffixLabel } from 'modules/Layout/helper/misc';
import { uniqueId } from 'lodash';
import { getError, hasError } from 'modules/Shared/helper/validation';
import ManageFiles, { IFile } from 'modules/Shared/helper/Files/ManageFiles';

export type ContractSingValues = {
  signing_persons: {
    key: string;
    first_name: string;
    last_name: string;
    email: string;
    file: IFile[];
  }[];
  signing_verify_account: {
    user: string;
    password: string;
  };
  status: 0 | 1 | 2;
  checkboxes?: AgreementCheckboxes | null;
};

const mapState = (v: ContractSingValues) => ({
  ...v,
  signing_persons: v.signing_persons.map((sp) => ({
    ...sp,
    key: uniqueId()
  }))
});

const AgreementStepContractSing = (props: AgreementStepProps): JSX.Element => {
  const { steps, errors, onSubmit: propsOnSubmit } = props;
  const initState = useRef<ContractSingValues>(mapState(getStepValues(steps, AGREEMENT_STEP_CONTRACT_SING)));
  const [stepValues, setStepValues] = useState<ContractSingValues>({
    ...initState.current
  });
  const [sendInfo, setSendInfo] = useState(false);
  const [file, setFile] = useState<IFile | null>(null);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_CONTRACT_SING));
    setStepValues({ ...initState.current });
    setFile(null);
    setSendInfo(false);
  }, [steps]);

  const { renderTextField } = useStepFields<ContractSingValues>({
    stepValues,
    setStepValues,
    mapState,
    errors
  });

  const addPerson = useCallback(() => {
    setStepValues({
      ...stepValues,
      signing_persons: [
        ...stepValues.signing_persons,
        {
          key: uniqueId(),
          first_name: '',
          last_name: '',
          email: '',
          file: []
        }
      ]
    });
  }, [stepValues]);

  const onSubmit = useCallback(async () => {
    if (stepValues.status === 0) {
      await propsOnSubmit(stepValues);
    } else if (stepValues.status === 1) {
      await propsOnSubmit(
        {
          file: file?.file,
          send_info: sendInfo
        },
        true
      );
    }
  }, [propsOnSubmit, file, sendInfo, stepValues]);

  return (
    <AgreementFormWrapper onSubmit={onSubmit} hideSubmit={stepValues.status === 2}>
      <div>
        <p>Lista osób:</p>
        <p>
          {hasError(errors, 'signing_persons') && (
            <FormFeedback className="d-block">{getError(errors, 'signing_persons')}</FormFeedback>
          )}
        </p>
        {stepValues.signing_persons.map((p, index) => (
          <FormGroup key={p.key}>
            <p>{`Osoba #${index + 1}`}</p>
            <FormGroup>
              {renderTextField({
                type: 'text',
                field: `signing_persons.${index}.first_name`,
                label: suffixLabel('Imię', true),
                disabled: stepValues.status !== 0
              })}
            </FormGroup>
            <FormGroup>
              {renderTextField({
                type: 'text',
                field: `signing_persons.${index}.last_name`,
                label: suffixLabel('Nazwisko', true),
                disabled: stepValues.status !== 0
              })}
            </FormGroup>
            <FormGroup>
              {renderTextField({
                type: 'text',
                field: `signing_persons.${index}.email`,
                label: suffixLabel('E-mail', true),
                disabled: stepValues.status !== 0
              })}
            </FormGroup>
            <div style={{ marginBottom: '1rem' }}>
              <ManageFiles
                files={p.file}
                multiple={false}
                label=""
                accept="application/pdf"
                onChange={() => {}}
                canDelete={false}
                canAdd={false}
              />
            </div>
            {!p.file.length &&
              stepValues.status === 1 &&
              (stepValues.signing_persons[index - 1]?.file?.length || index === 0) &&
              (index === stepValues.signing_persons.length - 1 ||
                !stepValues.signing_persons[index + 1].file.length) && (
                <>
                  <div style={{ marginBottom: '1rem' }}>
                    <ManageFiles
                      files={file ? [file] : []}
                      multiple={false}
                      label="DODAJ PLIK Z PODPISEM"
                      accept="application/pdf"
                      onChange={(files) => {
                        setFile(files[0]);
                      }}
                      canDelete={false}
                      addAfterList
                    />
                  </div>
                  {hasError(errors, 'file') && (
                    <p>
                      <FormFeedback className="d-block">{getError(errors, 'file')}</FormFeedback>
                    </p>
                  )}
                  {index === stepValues.signing_persons.length - 1 && (
                    <>
                      <CustomInput
                        id="send-info"
                        name="send_info"
                        type="checkbox"
                        checked={sendInfo}
                        label="Czy chcesz wysłać powiadomienie do wszystkich osób o podpisaniu umowy?"
                        invalid={hasError(errors, 'send_info')}
                        onChange={(event) => setSendInfo(event.target.checked)}
                      />
                      {hasError(errors, 'send_info') && (
                        <FormFeedback className="d-block">{getError(errors, 'send_info')}</FormFeedback>
                      )}
                    </>
                  )}
                </>
              )}
          </FormGroup>
        ))}
      </div>
      {stepValues.status === 0 && <Button onClick={addPerson}>Dodaj osobę</Button>}
    </AgreementFormWrapper>
  );
};

export default AgreementStepContractSing;
