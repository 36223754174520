import { addToastAction, managePageAction } from 'modules/Layout/action';
import Loader from 'modules/Layout/component/Loader';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Alert from 'modules/Layout/component/Alert';
import { Message, ValidationErrors } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_EXECUTOR_ADMINS } from 'modules/Executor/routes';
import { fetchExecutorViolation, updateViolation } from 'modules/Executor/repository';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import updateRequest from 'modules/Shared/helper/APIRequests/updateRequest';
import CardBox from 'modules/Layout/component/CardBox';
import { ExecutorViolation, ExecutorViolationSlug, getViolationQuestion } from '../../../model/Violation';
import { updateViolationToastSuccess } from '../../../../ExecutorOrder/toasts';
import { breadcrumbRouteViolationUpdate } from '../../../../ExecutorOrder/breadcrumbs';
import ViolationForm from '../../../../ExecutorOrder/component/ViolationForm';

export interface Props {
  params?: any;
}

const ExecutorViolationUpdateView: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { executor_id, slug, section_id } = useParams<{
    executor_id: string;
    slug: ExecutorViolationSlug;
    section_id: string;
  }>();
  const [violation, setViolation] = useState<ExecutorViolation>(null);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatchDeleteViolationToast = () => dispatch(addToastAction(updateViolationToastSuccess()));

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const {
        data,
        cancelled,
        message: messageResponse
      } = await fetchRequest(fetchExecutorViolation, executor_id, section_id, cancelToken);

      if (cancelled) return;
      if (data) setViolation(data.data);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (violation) {
      const title = `Narusenie - edycja danych`;
      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteViolationUpdate(parseInt(executor_id, 10) || 0)
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [violation]);

  const updateViolationAction = async (data: ExecutorViolation) => {
    setLoading(true);
    if (message) setMessage(null);
    if (errors) setErrors(null);

    const {
      cancelled,
      message: messageRes,
      success,
      error
    } = await updateRequest(updateViolation, executor_id, section_id, data, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteViolationToast();
      dispatch(push(getPathUrl(ROUTE_EXECUTOR_ADMINS, { id: executor_id })));
    } else {
      if (messageRes) setMessage(messageRes);
      if (error) setErrors(error);
      setLoading(false);
    }
  };

  if (fetching) return <Loader />;
  if (!fetching && !violation && message)
    return (
      <div className="executor-create-view d-flex justify-content-center">
        <CardBox form="small">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </CardBox>
      </div>
    );
  if (!fetching && violation) {
    return (
      <div className="profile-update-view d-flex justify-content-center">
        <CardBox childrenClassName="p-0 mt-4" form="small" heading={getViolationQuestion(slug)}>
          {loading && <Loader />}
          <Alert message={message} />
          {violation && (
            <ViolationForm
              isEditForm
              slug={slug}
              cancelRedirect={getPathUrl(ROUTE_EXECUTOR_ADMINS, { id: executor_id })}
              violationData={violation}
              errors={errors}
              submit={updateViolationAction}
              disabled={loading}
            />
          )}
        </CardBox>
      </div>
    );
  }

  return null;
};

export default ExecutorViolationUpdateView;
