import React, { useState } from 'react';
import DragDropInput from 'modules/Layout/component/DragDropInput';
import { serialize } from 'object-to-formdata';
import { FormFeedback } from 'reactstrap';

export type IFile = {
  file?: File;
  id?: string;
};

type Props = {
  onAddFilesRequest: (data: FormData) => void;
  maxSizeInMb?: number;
};

const AddFilesDragDropAutoSend: React.FC<Props> = ({ onAddFilesRequest, maxSizeInMb }) => {
  const [displayError, setDisplayError] = useState(false);

  const onDrop = (files: File[]) => {
    if (maxSizeInMb) {
      // eslint-disable-next-line
      const combinedSize = files?.reduce((prev, curr) => (prev += curr?.size ?? 0), 0) ?? 0;

      const sizeInMb = combinedSize / (1024 * 1024);

      if (maxSizeInMb < sizeInMb) {
        if (!displayError) setDisplayError(true);
        return;
      }

      if (displayError) setDisplayError(false);
    }

    onAddFilesRequest(serialize({ files: files.map((file) => file) }));
  };

  return (
    <>
      <DragDropInput options={{ onDrop }} />
      {displayError && (
        <FormFeedback className="d-block">Rozmiar plików nie może przekroczyć {maxSizeInMb} MB.</FormFeedback>
      )}
    </>
  );
};

export default AddFilesDragDropAutoSend;
