import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import { officeImportsListStatuses, OfficeImportsListValues } from 'modules/Office/model/Imports';
import { formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';

export interface Props {
  officeImport: OfficeImportsListValues;
}

const ImportsDataset: React.FC<Props> = ({ officeImport }): JSX.Element => {
  const fields = [
    {
      label: 'Nazwa pliku',
      value: officeImport?.file_name
    },
    {
      label: 'Ścieżka pliku',
      value: officeImport?.path
    },
    {
      label: 'Plik dodany przez',
      value: officeImport?.user?.name
    },
    {
      label: 'Status',
      value: officeImportsListStatuses(officeImport?.status)
    },
    {
      label: 'Ilość dodanych wpisów',
      value: officeImport?.new_rows || '0'
    },
    {
      label: 'Ilość zaktualizowanych wpisów',
      value: officeImport?.updated_rows || '0'
    },
    {
      label: 'Liczba błędów',
      value: officeImport?.errors_count
    },
    {
      label: 'Rozpoczęto import pliku',
      value: ifContainsDateFormat(officeImport?.created_at, formatDatetimeSeconds)
    },
    {
      label: 'Zakończono import pliku',
      value: ifContainsDateFormat(officeImport?.finished_at, formatDatetimeSeconds)
    }
  ];

  return <InformationList fields={fields} />;
};

export default ImportsDataset;
