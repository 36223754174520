import CardBox from 'modules/Layout/component/CardBox';
import React, { useState } from 'react';
import Office from 'modules/Office/model/Office';
import ExecutorsRequestDataset from 'modules/Office/component/Dataset/ExecutorsRequest';
import updateRequest from 'modules/Shared/helper/APIRequests/updateRequest';
import { resendEmail } from 'modules/Office/repository';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { useDispatch } from 'react-redux';
import { addToastAction } from 'modules/Layout/action';
import { officeEmailResendToastSuccess } from 'modules/Office/toasts';
import { ColorVariants, Message } from 'modules/Shared/type';
import ResendEmailModal from 'modules/Office/component/Modal/ResendEmail';

export interface Props {
  office?: Office;
}

const DetailsExecutorsRequestInfo: React.FC<Props> = ({ office }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [modalMessage, setModalMessage] = useState<Message>(null);
  const [displayModal, setDisplayModal] = useState(false);

  const dispatchResendEmailOfficeToast = () => dispatch(addToastAction(officeEmailResendToastSuccess()));

  const cancelToken = useCancelToken();

  const resendEmailRequest = async () => {
    setLoading(true);
    if (message) setMessage(null);
    if (modalMessage) setModalMessage(null);

    const {
      cancelled,
      message: messageResponse,
      success
    } = await updateRequest(resendEmail, { offices: [office.id] }, cancelToken);
    if (cancelled) return;
    if (success) {
      setDisplayModal(false);
      dispatchResendEmailOfficeToast();
      setMessage({
        value:
          'Pomyślnie rozpoczęto wysyłanie wiadomości. Operacja zostanie przeprowadzona w tle. Można dalej korzystać z systemu.',
        variant: ColorVariants.Success
      });
    }
    if (messageResponse) setModalMessage(messageResponse);
    setLoading(false);
  };

  const onResendClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setDisplayModal(true);
  };
  return (
    <CardBox
      paddingBottom="0"
      paddingRight="30px"
      heading="Zapytania odnośnie bazy wykonawców, w stosunku do których mogą wystąpić podstawy do wykluczenia"
      message={message}
      actionsClassName="w-100"
      actions={
        office?.can_send_email ? (
          <div className="flex-1 d-flex justify-content-end">
            {/* eslint-disable-next-line */}
            <div className="link-with-border cursor-pointer" onClick={onResendClick}>
              WYŚLIJ ZAPYTANIE
            </div>
          </div>
        ) : null
      }
    >
      {displayModal && (
        <ResendEmailModal
          toggle={() => setDisplayModal(false)}
          onSubmit={resendEmailRequest}
          message={modalMessage}
          cleanMessage={() => setModalMessage(null)}
          isOpen={displayModal}
          loading={loading}
        />
      )}
      <ExecutorsRequestDataset office={office} />
    </CardBox>
  );
};

export default DetailsExecutorsRequestInfo;
/*



*/
