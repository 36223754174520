import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import Selects from 'modules/ForeignProceedings/component/Fieldset/Main/NormalInputs/selects';
import FirstPart from 'modules/ForeignProceedings/component/Fieldset/Main/NormalInputs/firstPart';
import Custom from 'modules/ForeignProceedings/component/Fieldset/Main/NormalInputs/custom';
import ForeignProceedings from 'modules/ForeignProceedings/model/ForeignProceedings';

export interface Props {
  values: ForeignProceedings;
  errors?: ValidationErrors;
  disabled?: boolean;
  required?: boolean;
  setValues: (value: ForeignProceedings) => void;
  orderObjectWysiwyg: () => JSX.Element;
  placeholder?: boolean;
  isEditing?: boolean;
}

const ForeignProceedingsFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { disabled } = props;

  return (
    <fieldset className="m-0" disabled={disabled}>
      <div className="row col-12">
        <div className="col-12 col-lg-6">
          <FirstPart {...props} />
          <Selects {...props} />
        </div>
        <div className="col-12 col-lg-6">
          <Custom {...props} />
        </div>
      </div>
    </fieldset>
  );
};

export default ForeignProceedingsFieldset;
