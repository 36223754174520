import Table, { TableCol } from 'modules/Layout/component/Table/AccordionTable';
import React from 'react';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ExecutorExtendedRequest } from '../../../../Executor/model/Violation';
import { RootState } from '../../../../../app/reducer';
import { PartialSearchingProps } from '../../../../Shared/type';
import { setExecutorExtendedRequestsListParamsAction } from '../../../action/list';
import ActionUpdate from '../../../../Layout/component/Action/Update';
import EditIcon from '../../../../Layout/component/Icon/Edit';
import { getPathUrl } from '../../../../Shared/helper/api';
// eslint-disable-next-line import/no-cycle
import { ROUTE_USER_EXECUTOR_EXTENDED_REQUEST, ROUTE_USER_EXECUTOR_EXTENDED_REQUEST_UPDATE } from '../../../routes';

const ExecutorExtendedRequestsTable: React.FC<{ id?: string | number }> = ({ id }) => {
  const dispatch = useDispatch();
  const { executorsExtendedRequests, sort } = useSelector(
    (state: RootState) => state.user.executorsExtendedRequestsList
  );

  const setParams = (payload: PartialSearchingProps) =>
    dispatch(setExecutorExtendedRequestsListParamsAction(payload, id));

  const redirectLink = (value: string, request_id: string | number) => (
    <Link className="fill-table-td" to={getPathUrl(ROUTE_USER_EXECUTOR_EXTENDED_REQUEST, { claim_id: id, request_id })}>
      {value}
    </Link>
  );

  const cols: TableCol<ExecutorExtendedRequest>[] = [
    {
      property: 'name',
      label: 'Nazwa organu',
      value: (row) => redirectLink(row?.name, row?.id)
    },
    {
      property: 'category_slug',
      label: 'Kategoria organu',
      value: (row) => redirectLink(row?.category?.name, row?.id)
    },
    {
      property: 'status',
      label: 'Status odpowiedzi',
      value: (row) => row?.status?.name
    },
    {
      property: 'sent_at',
      label: 'Data wysłania wniosku',
      value: (row) => ifContainsDateFormat(row?.sent_at, formatDate)
    },
    {
      property: 'received_at',
      label: 'Data otrzymania odpowiedzi',
      value: (row) => ifContainsDateFormat(row?.received_at, formatDate)
    },
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            <ActionUpdate
              className="mx-1"
              title="Edycja danych podstawowych"
              label={<EditIcon height="20px" />}
              to={getPathUrl(ROUTE_USER_EXECUTOR_EXTENDED_REQUEST_UPDATE, { claim_id: id, request_id: row.id })}
            />
          </div>
        );
      }
    }
  ];

  return (
    <Table cols={cols} rows={executorsExtendedRequests} sort={sort} onSort={(params) => setParams({ sort: params })} />
  );
};

export default ExecutorExtendedRequestsTable;
