import FilterCheckbox from 'modules/Layout/component/Filter/Checkbox';
import FilterDatetime from 'modules/Layout/component/Filter/Datetime';
import FilterInput from 'modules/Layout/component/Filter/Input';
import FilterInputRadio from 'modules/Layout/component/Filter/Radio';
import FilterInputSelect from 'modules/Layout/component/Filter/Select';
import { FilterInputType } from 'modules/Layout/type';
import { formatDateValue } from 'modules/Shared/helper/utils';
import React, { createRef, ReactNode } from 'react';
import { Button, CardBody, Collapse } from 'reactstrap';
import { FilterEntity } from 'modules/Layout/model/Filters';
import { FiltersListDisplay } from 'modules/Shared/helper/hooks/useSaveFilter';
import FilterData from 'modules/Layout/component/Filter/Date';
import TooltipWithBtn from 'modules/Layout/component/Tooltip/WithButton';
import ArrowIcon from 'modules/Layout/component/Icon/Arrow';
import classNames from 'classnames';
import './index.scss';
import { PartialSearchingProps } from 'modules/Shared/type';
import Accordion from '../Accordion';
// eslint-disable-next-line import/no-named-as-default
import FilterInputSearchSelect from './SearchingSelect';
import FilterCheckboxSelect from './CheckboxSelect';

export type IFilterObj = {
  filtersList: FilterEntity[];
  onDeleteFilterClick: (id: number) => Promise<void>;
  onFilterSelectedClick: (id: number) => Promise<void>;
  loading: boolean;
};

export interface Props {
  inputs: FilterInputType[];
  saveFilterBtn?: () => void;
  disableBtn?: boolean;
  onChange: (property: string, value: string | (string | number)[]) => void;
  initFilterOpen?: boolean;
  filterObj?: IFilterObj;
  searchButtonObj?: { onClick: () => void; disabled?: boolean; label?: string };
  customInput?: JSX.Element;
  filterName?: string;
  localStorageObject?: PartialSearchingProps;
}

export interface State {
  isCollapseOpen: boolean;
}

class Filter extends React.Component<Props, State> {
  filterRef = createRef<HTMLDivElement>();
  constructor(props: Props) {
    super(props);

    const { initFilterOpen = false } = props;

    this.state = {
      isCollapseOpen: initFilterOpen
    };

    this.renderInput = this.renderInput.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    if (this.filterRef.current) {
      this.filterRef.current.addEventListener('keydown', this.handleKeyDown);
    }
  }

  componentWillUnmount() {
    if (this.filterRef.current) {
      this.filterRef.current.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      const { searchButtonObj } = this.props;
      if (searchButtonObj && !searchButtonObj.disabled) {
        searchButtonObj.onClick();
      }
    }
  }

  toggleCollapse(): void {
    this.setState((prevState: State) => ({
      isCollapseOpen: !prevState.isCollapseOpen
    }));
  }

  renderInput(input: FilterInputType): ReactNode {
    const { onChange, localStorageObject } = this.props;

    const { property, label, disabled, value, className, beforeLineBreak, before_jsx_element } = input;

    let localValue;

    if (localStorageObject) {
      const savedValue = localStorageObject.filter && localStorageObject.filter[property];
      localValue = savedValue;
    } else {
      localValue = value;
    }

    switch (input.type) {
      case 'radio': {
        const { options, type } = input;

        return (
          <React.Fragment key={property}>
            {beforeLineBreak && <div className="w-100" />}
            {before_jsx_element}
            <FilterInputRadio
              className={className || 'col-12 col-lg-6 col-xl-4'}
              key={property}
              type={type}
              property={property}
              label={label}
              value={localValue}
              options={options}
              onChange={(val) => onChange(property, val)}
              disabled={disabled}
            />
          </React.Fragment>
        );
      }

      case 'select': {
        const { options, type, acceptValueAsString } = input;

        return (
          <React.Fragment key={property}>
            {beforeLineBreak && <div className="w-100" />}
            {before_jsx_element}
            <FilterInputSelect
              className={className || 'col-12 col-lg-6 col-xl-4'}
              type={type}
              property={property}
              label={label}
              value={localValue}
              acceptValueAsString={acceptValueAsString}
              options={options}
              onChange={(val) => onChange(property, val)}
              disabled={disabled}
            />
          </React.Fragment>
        );
      }

      case 'searchSelect': {
        const { options, type, acceptValueAsString } = input;

        return (
          <React.Fragment key={property}>
            {beforeLineBreak && <div className="w-100" />}
            {before_jsx_element}
            <FilterInputSearchSelect
              className={className || 'col-12 col-lg-6 col-xl-4'}
              type={type}
              property={property}
              label={label}
              value={localValue}
              acceptValueAsString={acceptValueAsString}
              options={options}
              onChange={(val) => onChange(property, val)}
              disabled={disabled}
            />
          </React.Fragment>
        );
      }

      case 'checkboxSelect': {
        // eslint-disable-next-line no-shadow
        const { options, className } = input;

        return (
          <React.Fragment key={property}>
            {beforeLineBreak && <div className="w-100" />}
            {before_jsx_element}
            <FilterCheckboxSelect
              property={property}
              label={label}
              options={options}
              className={className}
              selectedValues={localValue || []} // Ensure `localValue` is an array
              onChange={(val) => onChange(property, val)}
              disabled={disabled}
            />
          </React.Fragment>
        );
      }

      case 'text':
      case 'number': {
        const { type } = input;

        return (
          <React.Fragment key={property}>
            {beforeLineBreak && <div className="w-100" />}
            {before_jsx_element}
            <FilterInput
              className={className || 'col-12 col-lg-6 col-xl-4'}
              type={type}
              property={property}
              label={label}
              value={localValue}
              onChange={(val) => onChange(property, val)}
              disabled={disabled}
            />
          </React.Fragment>
        );
      }

      case 'date': {
        const { type, min, max } = input;

        return (
          <React.Fragment key={property}>
            {beforeLineBreak && <div className="w-100" />}
            {before_jsx_element}
            <FilterData
              className={className || 'col-12 col-lg-6 col-xl-4'}
              key={property}
              type={type}
              property={property}
              label={label}
              value={localValue}
              min={min ? formatDateValue(min) : min}
              max={max ? formatDateValue(max) : max}
              onChange={(val) => onChange(property, val ? formatDateValue(val) : val)}
              disabled={disabled}
            />
          </React.Fragment>
        );
      }

      case 'datetime': {
        const { type, min, max } = input;

        return (
          <React.Fragment key={property}>
            {beforeLineBreak && <div className="w-100" />}
            {before_jsx_element}
            <FilterDatetime
              className={className || 'col-12 col-lg-6 col-xl-4'}
              key={property}
              type={type}
              property={property}
              label={label}
              value={localValue}
              min={min}
              max={max}
              onChange={(val) => onChange(property, val)}
              disabled={disabled}
            />
          </React.Fragment>
        );
      }

      case 'checkbox': {
        const { type } = input;

        return (
          <FilterCheckbox
            className={className || 'col-12 col-lg-6 col-xl-4'}
            key={property}
            type={type}
            property={property}
            label={label}
            value={localValue}
            onChange={(val) => onChange(property, val)}
            disabled={disabled}
          />
        );
      }

      case 'accordion': {
        const { inputs } = input;

        const content = <CardBody className="row">{inputs.map(this.renderInput)}</CardBody>;

        return (
          <React.Fragment key={property}>
            <div className="w-100" />
            <Accordion
              entity={{ title: label, content }}
              style={{ width: '100%', margin: '0 15px' }}
              accordionContentStyle={{ padding: '0' }}
            />
          </React.Fragment>
        );
      }

      case 'specialCaseJsxElement': {
        return localValue;
      }

      default: {
        return null;
      }
    }
  }

  renderCollapseIcon(): ReactNode {
    const { isCollapseOpen } = this.state;

    return (
      <span className={classNames({ 'is-open': isCollapseOpen })}>
        <ArrowIcon />
      </span>
    );
  }

  render(): JSX.Element {
    const { inputs, saveFilterBtn, disableBtn, filterObj, searchButtonObj, filterName, customInput } = this.props;
    const { isCollapseOpen } = this.state;

    return (
      <div ref={this.filterRef} className="filter">
        <Button
          type="button"
          color="link"
          className="d-inline-flex align-items-center justify-content-between p-0 filter-header text-primary font-poppins"
          block
          onClick={this.toggleCollapse}
        >
          {filterName ?? 'Filtrowanie'}
          {this.renderCollapseIcon()}
        </Button>
        <Collapse isOpen={isCollapseOpen}>
          <CardBody className="row">
            {inputs.map(this.renderInput)}
            {customInput}

            {searchButtonObj && (
              <div className="col-12 d-flex flex-wrap justify-content-end mt-2 px-15px">
                <Button
                  type="button"
                  role="presentation"
                  color="primary"
                  className="pzpeu-btn-disabled waves-effect waves-light"
                  disabled={searchButtonObj?.disabled}
                  onClick={searchButtonObj.onClick}
                >
                  {searchButtonObj.label ?? 'Wyszukaj'}
                </Button>
              </div>
            )}

            {saveFilterBtn && (
              <div className="col-12 d-flex flex-wrap justify-content-between mt-25px px-15px">
                {filterObj && (
                  <FiltersListDisplay
                    filtersList={filterObj.filtersList}
                    onDeleteClick={filterObj.onDeleteFilterClick}
                    onSelectedClick={filterObj.onFilterSelectedClick}
                    loading={filterObj.loading}
                  />
                )}
                <TooltipWithBtn
                  targetId="FilterBtn"
                  title="Zapisz konfiguracje"
                  onClick={saveFilterBtn}
                  btnContent="ZAPISZ FILTR"
                  btnContentClassName="text-border-primary text-secondary"
                  tooltipContent="Aby zapisać filtr należy wypełnić pola i wykonać wyszukiwanie"
                  color="link"
                  className="p-0 save-filter-btn"
                  wrapperClassName="ml-auto"
                  disabled={disableBtn}
                />
              </div>
            )}
          </CardBody>
        </Collapse>
      </div>
    );
  }
}

export default Filter;
