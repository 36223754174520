import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import ExecutorClient from 'modules/Executor/model/ExecutorClient';
import { ifContainsDateFormat, formatDatetimeSeconds } from 'modules/Shared/helper/utils';

export interface Props {
  executor: ExecutorClient;
}

const MainDataClientDataset: React.FC<Props> = ({ executor }): JSX.Element => {
  const { main_data } = executor;
  const fields = [
    {
      label: 'Numer zlecenia',
      value: main_data?.payment_number || 'nie dotyczy'
    },
    {
      label: 'Nazwa klienta',
      value: main_data?.name || 'nie dotyczy'
    },
    {
      label: 'E-mail klienta',
      value: main_data?.email || 'nie dotyczy'
    },
    {
      label: 'Data pobrania',
      value: ifContainsDateFormat(main_data?.date, formatDatetimeSeconds) || 'nie dotyczy'
    },
    {
      label: 'Ostatnia aktualizacja danych',
      value: ifContainsDateFormat(main_data?.executor_updated_at, formatDatetimeSeconds) || 'nie dotyczy'
    },
    {
      label: 'Ilość wysłanych wniosków o udostępnienie informacji ogółem',
      value: main_data?.applications_count || '0'
    },
    {
      label: 'Ilość wprowadzonych danych',
      value: main_data?.executors_count || '0'
    },
    {
      label: 'Ilość wykonawców w bazie, którym naliczono karę umowną',
      value: main_data?.executors_with_penalties_count || '0'
    },
    {
      label: 'Ilość wykonawców w bazie, z którymi rozwiązano umowę',
      value: main_data?.executors_with_termination_count || '0'
    },
    {
      label: 'Ilość wykonawców w bazie, których pozwano',
      value: main_data?.executors_with_complaint_count || '0'
    },
    {
      label: 'Ilość wykonawców w bazie, którzy wprowadzili zamawiającego w błąd',
      value: main_data?.executors_with_misrepresentation_count || '0'
    },
    {
      label: 'Wersja językowa',
      value: main_data?.language || 'nie dotyczy'
    }
  ];

  return <InformationList fields={fields} />;
};

export default MainDataClientDataset;
