export type KeywordWithCategoriesEntity = {
  name?: string;
  categories?: string;
};

export default class KeywordWithCategories {
  readonly name?: string;
  readonly categories?: string;

  constructor(entity: KeywordWithCategoriesEntity) {
    this.name = entity.name;
    this.categories = entity.categories;
  }
}

export const createKeywordWithCategories = (entity: KeywordWithCategories): KeywordWithCategories =>
  new KeywordWithCategories(entity);
