import { suffixLabel } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { setInputNumbers } from 'modules/Layout/component/Input/TextNumber/input';
import _uniqueId from 'lodash/uniqueId';
import { Props } from 'modules/Proceedings/component/Fieldset/Main';

const ProceedingsFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, setValues, placeholder = false, required } = props;

  const {
    contractor_name = '',
    contractor_address = '',
    contractor_email = '',
    contractor_nip = '',
    value = 0
  } = values;

  return (
    <>
      <FormGroup>
        {!placeholder && <Label for="input-contractor_name">Nazwa zamawiającego</Label>}
        <Input
          type="text"
          name="contractor_name"
          id="input-contractor_name"
          placeholder={placeholder ? 'Nazwa zamawiającego' : null}
          value={contractor_name}
          onChange={(event) => setValues({ ...values, contractor_name: event.target.value })}
          invalid={hasError(errors, 'contractor_name')}
          maxLength={500}
        />
        {hasError(errors, 'contractor_name') && <FormFeedback>{getError(errors, 'contractor_name')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="input-contractor_address">Adres zamawiającego</Label>}
        <Input
          type="text"
          name="contractor_address"
          id="input-contractor_address"
          placeholder={placeholder ? 'Adres zamawiającego' : null}
          value={contractor_address}
          onChange={(event) => setValues({ ...values, contractor_address: event.target.value })}
          invalid={hasError(errors, 'contractor_address')}
          maxLength={500}
        />
        {hasError(errors, 'contractor_address') && (
          <FormFeedback>{getError(errors, 'contractor_address')}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="input-contractor_email">{suffixLabel('Email zamawiającego', required)}</Label>}
        <Input
          type="text"
          name="contractor_email"
          id="input-contractor_email"
          placeholder={placeholder ? 'Email zamawiającego' : null}
          value={contractor_email}
          onChange={(event) => setValues({ ...values, contractor_email: event.target.value })}
          invalid={hasError(errors, 'contractor_email')}
          maxLength={500}
          required={required}
        />
        {hasError(errors, 'contractor_email') && <FormFeedback>{getError(errors, 'contractor_email')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="input-contractor_nip">NIP zamawiającego</Label>}
        <Input
          type="number"
          name="contractor_nip"
          id="input-contractor_nip"
          placeholder={placeholder ? 'NIP zamawiającego' : null}
          value={contractor_nip}
          onChange={(event) => setValues({ ...values, contractor_nip: event.target.value })}
          invalid={hasError(errors, 'contractor_nip')}
          maxLength={10}
        />
        {hasError(errors, 'contractor_nip') && <FormFeedback>{getError(errors, 'contractor_nip')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="input-value">{suffixLabel('Wartość zamówienia', required)}</Label>}
        <Input
          type="number"
          name="value"
          id="input-value"
          placeholder={placeholder ? 'Wartość zamówienia' : null}
          value={value}
          onChange={(event) => setInputNumbers(event, values, setValues, 'value')}
          invalid={hasError(errors, 'value')}
          min={0}
          max={9999999999.99}
          step="0.01"
        />
        {hasError(errors, 'value') && <FormFeedback>{getError(errors, 'value')}</FormFeedback>}
      </FormGroup>
    </>
  );
};

export default ProceedingsFieldset;
