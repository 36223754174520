import axios, { CancelTokenSource } from 'axios';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { fetchJudgementCategoryToastError } from 'modules/Judgement/toasts';
import { addToastAction } from 'modules/Layout/action';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { createSearchParams } from 'modules/Shared/helper/params';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import AutocompleteWithChips from 'modules/Layout/component/Autocomplete/WithChips';
import { fetchJudgmentClientCategories } from 'modules/JudgementClient/repository';
import renameDuplicateEntries from 'modules/Shared/helper/renameDuplicateEntries';

type Props = {
  selectedCategories: { name: string; id: number }[];
  onSelectedCategoriesChange: (categoryValue: { name: string; id: number }) => void;
  alwaysClearInput?: boolean;
  required?: boolean;

  chipContentElements?: { content: string; id: number }[];
  onDeleteChipElement?: (id: number) => void;
};

// eslint-disable-next-line react/display-name
const JudgmentClientCategoriesAutocomplete: React.FC<Props> = React.memo(
  ({ selectedCategories, onSelectedCategoriesChange, ...props }) => {
    const dispatch = useDispatch();
    const [fetching, setFetching] = useState(false);
    const [categoryList, setCategoryList] = useState<JudgementElementEntity[]>([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const categoryListWithoutDuplicates = React.useMemo(
      () => renameDuplicateEntries(categoryList),
      [categoryList.length]
    );

    const dispatchClauseCategoryToastError = (messageValue: string) =>
      dispatch(addToastAction(fetchJudgementCategoryToastError(messageValue)));

    const cancelToken: React.MutableRefObject<CancelTokenSource> = useRef(null);

    const fetchCategoryList = async (name?: string) => {
      if (cancelToken.current) {
        cancelToken.current.cancel('Operation canceled due to new request.');
      }
      cancelToken.current = axios.CancelToken.source();

      const filter = name ? { name } : {};
      const searchParams = createSearchParams({ filter });

      setFetching(true);

      const { data, cancelled, message } = await fetchRequest(
        fetchJudgmentClientCategories,
        searchParams,
        cancelToken.current.token
      );

      if (cancelled) return;
      if (data) {
        setCategoryList(data.data);
        setFetching(false);
      }
      if (message && message.value !== 'Operation canceled due to new request.') {
        if (fetching) setFetching(false);
        dispatchClauseCategoryToastError(message.value);
      }
    };

    useEffect(() => {
      return () => {
        if (cancelToken.current) {
          cancelToken.current.cancel('AxiosCancel');
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeValue = (name: string) => {
      onSelectedCategoriesChange({
        name,
        id: categoryListWithoutDuplicates.find((value) => value.name === name)?.id ?? null
      });
    };

    const clearList = () => {
      if (cancelToken.current) {
        cancelToken.current.cancel('Operation canceled due to new request.');
      }
    };

    const filterCategoryList = categoryListWithoutDuplicates.filter(
      (el) => !selectedCategories.find((categoryEl) => categoryEl.id === el.id)
    );

    return (
      <AutocompleteWithChips
        currentValue=""
        onChangeCurrentValue={onChangeValue}
        searchRequest={fetchCategoryList}
        fetching={fetching}
        clearList={clearList}
        list={filterCategoryList as never}
        label="Kategorie"
        {...props}
      />
    );
  }
);

export default JudgmentClientCategoriesAutocomplete;
