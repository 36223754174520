import { DATE_FORMAT_VALUE, formatDateValue, formatTimeValue, TIME_FORMAT } from 'modules/Shared/helper/utils';
import moment from 'moment';
import React from 'react';
import { Input } from 'reactstrap';
import './style.scss';
import Datepicker from 'modules/Layout/component/Datepicker';

export interface DatetimeProps {
  name: string;
  id?: string;
  value?: string;
  min?: string;
  max?: string;
  required?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  onChange: (value: string) => void;
  hardcodedSeconds?: number;
}

const Datetime: React.FC<DatetimeProps> = ({
  name,
  id,
  value,
  min,
  max,
  required,
  disabled,
  invalid,
  onChange,
  hardcodedSeconds = null
}: DatetimeProps): JSX.Element => {
  const date = value ? formatDateValue(value) : '';
  const time = value ? formatTimeValue(value) : '';

  const rules = {
    min: {
      date: '',
      time: ''
    },
    max: {
      date: '',
      time: ''
    }
  };

  if (min && min !== 'undefined') {
    rules.min.date = formatDateValue(min);

    if (date) {
      const dateMoment = moment(value, DATE_FORMAT_VALUE);

      if (moment(min, DATE_FORMAT_VALUE).diff(dateMoment, 'days') === 0) {
        rules.min.time = formatTimeValue(min);
      }
    }
  }

  if (max && max !== 'undefined') {
    rules.max.date = formatDateValue(max);

    if (date) {
      const dateMoment = moment(value, DATE_FORMAT_VALUE);

      if (moment(max, DATE_FORMAT_VALUE).diff(dateMoment, 'days') === 0) {
        rules.max.time = formatTimeValue(max);
      }
    }
  }

  return (
    <div className="datetime-wrapper">
      <Datepicker
        id={`${id ?? name}.date`}
        value={date}
        min={rules.min.date}
        max={rules.max.date}
        required={required}
        disabled={disabled}
        invalid={invalid}
        onChange={(newDate) => {
          if (moment(newDate).isValid()) onChange(`${newDate} ${time || moment().format(TIME_FORMAT)}`);
          else onChange('');
        }}
      />
      <Input
        type="time"
        name={`${name}.time`}
        id={`${id ?? name}.time`}
        value={time}
        min={rules.min.time}
        max={rules.max.time}
        step="1"
        required={required}
        disabled={disabled || (min && min !== 'undefined' && !date) || (max && max !== 'undefined' && !date)}
        invalid={invalid}
        onChange={(event) =>
          onChange(event.target.value ? `${date || moment().format(DATE_FORMAT_VALUE)} ${event.target.value}` : '')
        }
      />
      {Boolean(hardcodedSeconds) && <div className="mb-2 ml-2">{hardcodedSeconds} sekund</div>}
    </div>
  );
};

export default Datetime;
