import React from 'react';
import { RootState } from 'app/reducer';
import ListingPagination from 'modules/Layout/component/Listing/Pagination';
import { PartialSearchingProps } from 'modules/Shared/type';
import { useDispatch, useSelector } from 'react-redux';
import { setProceedingsListParamsAction } from 'modules/Proceedings/action/ProceedingsList';

type Props = { path?: string };

const ProceedingsListingPagination: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { meta, pagination, filter, sort } = useSelector((state: RootState) => state.proceedings.proceedingsList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setProceedingsListParamsAction(payload));

  return (
    <ListingPagination
      meta={meta}
      pagination={pagination}
      sort={sort}
      setParams={setParams}
      filter={filter}
      {...props}
    />
  );
};

export default ProceedingsListingPagination;
