import Action, { ActionProps } from 'modules/Layout/component/Action/index';
import { ColorVariants } from 'modules/Shared/type';
import React from 'react';

const ActionUpdate: React.FC<ActionProps> = ({
  label = <i className="far font-20 fa-edit" />,
  color = ColorVariants.Primary,
  ...props
}: ActionProps): JSX.Element => {
  return (
    <Action
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      label={label}
      color={color}
    />
  );
};

export default ActionUpdate;
