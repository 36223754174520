import { managePageAction } from 'modules/Layout/action';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { useLocation, useParams } from 'react-router';
import { JudgementEntity } from 'modules/Judgement/model/Judgement';
import { breadcrumbRouteClauseJudgementContent } from 'modules/ClauseClient/breadcrumbs';
import { ClauseEntity } from 'modules/Clause/model/Clause';
import { ROUTE_CLIENT_CLAUSE } from 'modules/ClauseClient/routes';
import { ROUTE_DASHBOARD } from 'modules/Layout/routes';
import CardBox from 'modules/Layout/component/CardBox';

const ConnectedJudgementContent = () => {
  const dispatch = useDispatch();
  const { state = {} } = useLocation<{
    connectedJudgment?: JudgementEntity;
    clause?: ClauseEntity;
    content?: string;
  }>();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (state?.clause && state?.connectedJudgment) {
      const { clause, connectedJudgment } = state;
      dispatch(
        managePageAction({
          title: 'Treść orzeczenia',
          breadcrumb: breadcrumbRouteClauseJudgementContent(connectedJudgment.identifier, clause.name, clause.id)
        })
      );
      window.scrollTo();
    } else if (!state?.clause || !state?.connectedJudgment) {
      let pushUrl = '';
      if (id) pushUrl = getPathUrl(ROUTE_CLIENT_CLAUSE, { id }, { tab: 1 });
      else pushUrl = ROUTE_DASHBOARD;
      dispatch(push(pushUrl));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!state?.clause || !state?.connectedJudgment) return null;

  return (
    <div className="user-create-view row justify-content-center">
      <div className="col-12">
        <CardBox
          className="payment-page-card border-0"
          heading="Zaznaczona treść orzeczenia"
          paddingRight="30px"
          paddingBottom="30px"
          redirectReturnBtn={getPathUrl(ROUTE_CLIENT_CLAUSE, { id }, { tab: 1 })}
        >
          <div className="d-flex">
            <div className="col-12 p-0 transaction-data">
              <div className="information-list-wrapper">
                <div className="information-list-item" style={{ borderBottom: 'none' }}>
                  <div className="item-value w-100">
                    <div
                      className="information-list-html"
                      dangerouslySetInnerHTML={{ __html: String(state?.content) }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBox>
      </div>
    </div>
  );
};

export default ConnectedJudgementContent;
