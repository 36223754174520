import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { OfficeImportError } from 'modules/Office/model/Imports';

type Props = {
  errorList?: OfficeImportError[];
};

const OfficeImportErrorTable: React.FC<Props> = ({ errorList = [] }) => {
  // const extractedErrorsListReduce = errorList?.reduce((prev, curr) => {
  //   return [
  //     ...prev,
  //     ...Object?.values(curr?.errors ?? {}).reduce((nestedPrev, nestedCurr) => {
  //       return [
  //         ...nestedPrev,
  //         nestedCurr?.reduce(
  //           (deeperNestedPrev, deeperNestedCurr) => ({
  //             ...deeperNestedPrev,
  //             value: deeperNestedCurr,
  //             row: curr.row_index
  //           }),
  //           {}
  //         )
  //       ];
  //     }, [])
  //   ];
  // }, []);
  // const extractedErrorsListReduce = errorList?.reduce((prev, curr) => {
  //   return [
  //     ...prev,
  //     ...Object?.values(curr?.errors ?? {}).reduce((nestedPrev, nestedCurr) => {
  //       return [
  //         ...nestedPrev,
  //         nestedCurr?.reduce(
  //           (deeperNestedPrev, deeperNestedCurr) => ({
  //             ...deeperNestedPrev,
  //             value: deeperNestedCurr,
  //             row: curr.row_index
  //           }),
  //           {}
  //         )
  //       ];
  //     }, [])
  //   ];
  // }, []);

  const cols: TableCol<OfficeImportError>[] = [
    {
      property: 'errors',
      label: 'Opis błędu'
    },
    {
      property: 'column',
      label: 'Nazwa kolumny'
    },
    {
      property: 'row_index',
      label: 'Wiersz z błędem'
    }
  ];

  return <Table cols={cols} rows={errorList} />;
};

export default OfficeImportErrorTable;
