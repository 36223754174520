import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import User, { createUser } from 'modules/User/model/User';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { useDispatch, useSelector } from 'react-redux';
import DetailsComponent from 'modules/User/component/Details';
import { breadcrumbRouteUser, breadcrumbRouteUserDeleted } from 'modules/User/breadcrumbs';
import { breadcrumbRouteUserWaiting } from 'modules/Subscriptions/breadcrumbs';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import { acceptUserNipToastSuccess, deleteUserToastSuccess } from 'modules/User/toasts';
import { push } from 'connected-react-router';
import { ROUTE_USERS } from 'modules/User/routes';
import Alert from 'modules/Layout/component/Alert';
import { RootState } from 'app/reducer';
import { logoutAction } from 'modules/Auth/action';
import { ROLE_ADMIN } from 'modules/User/model/UserRole';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { acceptUserNip, deleteUser, fetchUser } from 'modules/User/repository';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import CardBox from 'modules/Layout/component/CardBox';

const Details = () => {
  const dispatch = useDispatch();
  const { id: current_user_id, permissions } = useSelector((state: RootState) => state.auth.user);
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<User>(null);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const locationSearchParam = useLocation().search;

  const dispatchDeleteUserToast = () => dispatch(addToastAction(deleteUserToastSuccess()));
  const dispatchAcceptUserNipToast = () => dispatch(addToastAction(acceptUserNipToastSuccess()));

  const displayDeleteModal = () => setDisplayModal(true);

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const { data, cancelled, message: messageResponse } = await fetchRequest(fetchUser, id, cancelToken);

      if (cancelled) return;
      if (data) setUser(createUser(data.data));
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      const roleAdmin = user?.role?.slug === ROLE_ADMIN;
      const title = `${roleAdmin ? 'Administrator' : 'Klient'}${user?.getName() ? ` - ${user.getName()}` : ''}`;

      const isWaiting = locationSearchParam.includes('is_waiting') && locationSearchParam.includes('1');
      const isDeletedUser = !user?.status;

      let breadcrumb = breadcrumbRouteUser(id, roleAdmin, user.getName());
      if (isDeletedUser) breadcrumb = breadcrumbRouteUserDeleted(id, user.getName());
      if (isWaiting) breadcrumb = breadcrumbRouteUserWaiting(id, user.getName());

      dispatch(
        managePageAction({
          title,
          breadcrumb
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const deleteUserAction = async (user_id: number | string) => {
    setLoading(true);
    const { cancelled, message: messageRes, success } = await deleteRequest(deleteUser, user_id, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteUserToast();
      if (user_id === current_user_id) {
        dispatch(logoutAction());
      } else {
        dispatch(push(ROUTE_USERS));
      }
    } else {
      if (messageRes) setMessage(messageRes);
      setLoading(false);
    }
  };

  const acceptUserNipAction = async () => {
    setLoading(true);

    const { cancelled, message: messageRes, data } = await fetchRequest(acceptUserNip, user.id, cancelToken);

    setLoading(false);

    if (cancelled) return;
    if (data) {
      dispatchAcceptUserNipToast();
      setUser(createUser(data.data));
    } else {
      if (messageRes) setMessage(messageRes);
    }
  };

  if (fetching) return <Loader />;
  if (!fetching && !user && message)
    return (
      <div className="user-create-view d-flex justify-content-center">
        <CardBox>
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </CardBox>
      </div>
    );
  if (!fetching && user) {
    const roleAdmin = user?.role?.slug === ROLE_ADMIN;

    return (
      <div className="row user-details-view position-relative">
        {displayModal && (
          <GenericModalDelete
            value={user}
            title={`Usuwanie ${roleAdmin ? 'administratora' : 'klienta'}`}
            content={(value) => (
              <div>
                Czy na pewno chcesz usunąć {roleAdmin ? 'administratora' : 'klienta'} - {value?.getName()}?
              </div>
            )}
            onDeleteClick={(value) => deleteUserAction(value.id)}
            isOpen={displayModal}
            toggle={() => setDisplayModal(null)}
            loading={loading}
            message={message}
            resetMessage={() => setMessage(null)}
          />
        )}
        <DetailsComponent
          user={user}
          updateUser={setUser}
          onDeleteClick={displayDeleteModal}
          acceptUserNipAction={acceptUserNipAction}
          permissions={permissions}
        />
      </div>
    );
  }

  return null;
};

export default Details;
