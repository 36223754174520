import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { ProceedingsFiles, proceedingsFileType } from 'modules/Proceedings/model/Proceedings';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import DownloadIcon from 'modules/Layout/component/Icon/Download';

type Props = {
  filesList?: ProceedingsFiles[];
};

const ProceedingsDetailsFileListTable: React.FC<Props> = ({ filesList }) => {
  const downloadFile = (url: string) => {
    window.location.href = url;
  };

  const cols: TableCol<ProceedingsFiles>[] = [
    {
      property: 'name',
      label: 'Nazwa'
    },
    {
      property: 'type',
      label: 'Typ',
      value: (row) => proceedingsFileType(row?.type)
    },
    {
      property: 'created_at',
      label: 'Dołączono',
      value: (row) => ifContainsDateFormat(row?.created_at, formatDatetimeSeconds)
    },
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            <ActionUpdate
              className="mx-1"
              title="Pobierz załącznik"
              label={<DownloadIcon height="20px" />}
              onClick={() => downloadFile(row?.href)}
            />
          </div>
        );
      }
    }
  ];

  return <Table cols={cols} rows={filesList} />;
};

export default ProceedingsDetailsFileListTable;
