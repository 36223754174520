import { RootState } from 'app/reducer';
import { setSearchingParamsAction, SetSearchingParamsAction } from 'modules/Shared/action';
import Validation from 'modules/Shared/exception/Validation';
import { createSearchParams } from 'modules/Shared/helper/params';
import { PaginationMeta, PartialSearchingProps } from 'modules/Shared/type';
import { Action, Dispatch } from 'redux';
import _ from 'lodash';
import { fetchApiImports } from '../../repository';
import { ForeignProceedingsApiImportsListValues } from '../../model/imports';

export const SET_FOREIGN_PROCEEDINGS_API_IMPORTS_LIST_PARAMS = 'FOREIGN/PROCEEDINGS/API/IMPORTS/LIST/PARAMS/SET';
export const FOREIGN_PROCEEDINGS_API_IMPORTS_LIST = 'FOREIGN/PROCEEDINGS/API/IMPORTS/LIST';
export const FOREIGN_PROCEEDINGS_CLEAR_API_IMPORTS_LIST = 'FOREIGN/PROCEEDINGS/CLEAR/API/IMPORTS/LIST';

export type ForeignProceedingsApiImportsListAction =
  | SetForeignProceedingsApiImportsListParamsAction
  | ForeignProceedingsApiImportsListFetchedAction
  | ClearForeignProceedingsApiImportsListAction;

export type SetForeignProceedingsApiImportsListParamsAction = SetSearchingParamsAction<
  typeof SET_FOREIGN_PROCEEDINGS_API_IMPORTS_LIST_PARAMS
>;

export type ClearForeignProceedingsApiImportsListAction = {
  type: typeof FOREIGN_PROCEEDINGS_CLEAR_API_IMPORTS_LIST;
};

export type ForeignProceedingsApiImportsListFetchedPayload = {
  list: ForeignProceedingsApiImportsListValues[];
  meta: PaginationMeta;
};

export interface ForeignProceedingsApiImportsListFetchedAction
  extends Action<typeof FOREIGN_PROCEEDINGS_API_IMPORTS_LIST> {
  payload: ForeignProceedingsApiImportsListFetchedPayload;
}

export const setForeignProceedingsApiImportsListParamsAction =
  (payload: PartialSearchingProps) => async (dispatch: Dispatch, getState: () => RootState) => {
    const currentState = getState()?.foreignProceedingsApiImports?.foreignProceedingsApiImportsList;

    if (
      payload?.filter &&
      !payload?.pagination &&
      typeof currentState?.pagination?.page === 'number' &&
      currentState.pagination.page !== 1 &&
      !_.isEqual(currentState.filter, payload.filter)
    ) {
      currentState.pagination.page = 1;
    }

    if (currentState?.filter?.requires_deposit === '1' && payload?.filter?.requires_deposit !== '1') {
      if (currentState?.filter?.deposit_min) delete payload.filter.deposit_min; // eslint-disable-line no-param-reassign
      if (currentState?.filter?.deposit_max) delete payload.filter.deposit_max; // eslint-disable-line no-param-reassign
    }

    const state = { ...currentState, ...payload };

    dispatch(setSearchingParamsAction(SET_FOREIGN_PROCEEDINGS_API_IMPORTS_LIST_PARAMS, payload));
    try {
      const { pagination, filter, sort } = state;

      const searchFilter = {
        ...filter
      };

      const searchParams = createSearchParams({ pagination, filter: searchFilter, sort });

      const response = await fetchApiImports(searchParams);

      const { data, meta } = response.data;

      dispatch<ForeignProceedingsApiImportsListFetchedAction>({
        type: FOREIGN_PROCEEDINGS_API_IMPORTS_LIST,
        payload: { list: data, meta }
      });
    } catch (error) {
      if (error instanceof Validation) {
        dispatch<ForeignProceedingsApiImportsListFetchedAction>({
          type: FOREIGN_PROCEEDINGS_API_IMPORTS_LIST,
          payload: { list: [], meta: null }
        });
      } else {
        throw error;
      }
    }
  };
