import {
  DATETIME_FORMAT_VALUE,
  DATETIME_SECONDS_FORMAT,
  findMonthName,
  formatDateIfExist,
  formatDatetimeSeconds,
  getCurrentDateInFormat
} from 'modules/Shared/helper/utils';
import {
  PROCEEDINGS_FILES_TYPES,
  PROCEEDINGS_FILES_VALUES,
  PROCEEDINGS_INIT_TYPES,
  PROCEEDINGS_INIT_TYPES_VALUES,
  PROCEEDINGS_QUESTIONS_DATE_MESSAGES,
  PROCEEDINGS_STATUS,
  PROCEEDINGS_STATUS_VALUES,
  PROCEEDINGS_TYPES,
  PROCEEDINGS_TYPES_VALUES,
  START_DATE_TYPES,
  START_DATE_TYPES_VALUES
} from 'modules/Proceedings/type';
import moment from 'moment';
import {
  AGREEMENT_MODE_CONSTRUCTION_WORKS,
  AGREEMENT_MODE_DELIVERIES,
  AGREEMENT_MODE_SERVICES,
  AgreementMode
} from 'modules/Agreements/type';

export type ProceedingsFiles = {
  id?: number;
  type?: number;
  name?: string;
  href?: string;
  created_at?: string;
  updated_at?: string;
};

export type ProceedingsEntity = {
  id?: number;
  proceeding_type?: number;
  status?: number;
  name?: string;
  identifier?: string;
  bzp_identifier?: string;
  bzp_date?: string;
  contractor_name?: string;
  contractor_address?: string;
  contractor_email?: string;
  contractor_nip?: string;
  contractor_regon?: string;
  value?: number;
  requires_deposit?: boolean;
  deposit?: number;
  type?: number;
  mode?: number;
  social_clauses?: string;
  order_object?: string;
  email_contact_checked?: boolean;
  epd2_contact_checked?: boolean;
  post_contact_checked?: boolean;
  other_contact_checked?: boolean;
  other_contact_content?: string;
  announcement_link?: string;
  start_date_type?: number;
  start_date?: string | number;
  offers_send_date?: string;
  answer_place?: string;
  answer_way?: string;
  shopping_platform?: string;
  keywords_names?: string;
  keywords?: { id: number; name: string }[];
  files?: ProceedingsFiles[];
  parts?: number | null;
  is_saved?: boolean;
  questions_date?: string;
  cpv_codes?: string[];
  cpv_codes_string?: string;
  from_bzp?: boolean;
  created_at?: string;
  updated_at?: string;
};

export default class Proceedings {
  readonly id?: number;
  readonly proceeding_type?: number;
  readonly status?: number;
  readonly name?: string;
  readonly identifier?: string;
  readonly bzp_identifier?: string;
  readonly bzp_date?: string;
  readonly contractor_name?: string;
  readonly contractor_address?: string;
  readonly contractor_email?: string;
  readonly contractor_nip?: string;
  readonly contractor_regon?: string;
  readonly value?: number;
  readonly requires_deposit?: boolean;
  readonly deposit?: number;
  readonly type?: number;
  readonly mode?: number;
  readonly social_clauses?: string;
  readonly order_object?: string;
  readonly email_contact_checked?: boolean;
  readonly epd2_contact_checked?: boolean;
  readonly post_contact_checked?: boolean;
  readonly other_contact_checked?: boolean;
  readonly other_contact_content?: string;
  readonly announcement_link?: string;
  readonly start_date_type?: number;
  readonly start_date?: string | number;
  readonly offers_send_date?: string;
  readonly answer_place?: string;
  readonly answer_way?: string;
  readonly shopping_platform?: string;
  readonly keywords_names?: string;
  readonly keywords?: { id: number; name: string }[];
  readonly files?: ProceedingsFiles[];
  readonly parts?: number | null;
  readonly is_saved?: boolean;
  readonly questions_date?: string;
  readonly cpv_codes?: string[];
  readonly cpv_codes_string?: string;
  readonly from_bzp?: boolean;
  readonly created_at?: string;
  readonly updated_at?: string;

  constructor(entity: ProceedingsEntity) {
    this.id = entity.id;
    this.proceeding_type = entity.proceeding_type;
    this.status = entity.status;
    this.name = entity.name;
    this.identifier = entity.identifier;
    this.bzp_identifier = entity.bzp_identifier;
    this.bzp_date = entity.bzp_date;
    this.contractor_name = entity.contractor_name;
    this.contractor_address = entity.contractor_address;
    this.contractor_email = entity.contractor_email;
    this.contractor_nip = entity.contractor_nip;
    this.contractor_regon = entity.contractor_regon;
    this.value = entity.value;
    this.requires_deposit = entity.requires_deposit;
    this.deposit = entity.deposit;
    this.type = entity.type;
    this.mode = entity.mode;
    this.social_clauses = entity.social_clauses;
    this.order_object = entity.order_object;
    this.email_contact_checked = entity.email_contact_checked;
    this.epd2_contact_checked = entity.epd2_contact_checked;
    this.post_contact_checked = entity.post_contact_checked;
    this.other_contact_checked = entity.other_contact_checked;
    this.other_contact_content = entity.other_contact_content;
    this.announcement_link = entity.announcement_link;
    this.start_date_type = entity.start_date_type;
    this.start_date = entity.start_date;
    this.offers_send_date = entity.offers_send_date;
    this.answer_place = entity.answer_place;
    this.answer_way = entity.answer_way;
    this.shopping_platform = entity.shopping_platform;
    this.keywords_names = entity.keywords_names;
    this.keywords = entity.keywords;
    this.files = entity.files;
    this.parts = entity.parts;
    this.is_saved = entity.is_saved;
    this.questions_date = entity.questions_date;
    this.cpv_codes = entity.cpv_codes;
    this.cpv_codes_string = entity.cpv_codes_string;
    this.from_bzp = entity.from_bzp;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
  }

  static getFilterableAttributes(): string[] {
    return [
      'proceeding_type',
      'status',
      'name',
      'identifier',
      'bzp_identifier ',
      'contractor_name',
      'contractor_address',
      'contractor_email',
      'contractor_nip',
      'start_date_from',
      'start_date_to',
      'offers_send_date_from',
      'offers_send_date_to',
      'value_min',
      'value_max',
      'deposit_min',
      'deposit_max',
      'type',
      'mode',
      'has_social_clauses',
      'keywords',
      'questions_date_to',
      'questions_date_from'
    ];
  }

  static getSortableAttributes(): string[] {
    return [
      'proceeding_type',
      'status',
      'name',
      'identifier',
      'contractor_name',
      'contractor_address',
      'contractor_regon',
      'contractor_email',
      'contractor_nip',
      'start_date',
      'offers_send_date',
      'value',
      'deposit',
      'type',
      'mode'
    ];
  }

  static getAgreementMode(type: number): AgreementMode {
    switch (type) {
      case PROCEEDINGS_INIT_TYPES_VALUES.SERVICES:
        return AGREEMENT_MODE_SERVICES;
      case PROCEEDINGS_INIT_TYPES_VALUES.CONSTRUCTIONS:
        return AGREEMENT_MODE_CONSTRUCTION_WORKS;
      case PROCEEDINGS_INIT_TYPES_VALUES.DELIVERY:
        return AGREEMENT_MODE_DELIVERIES;
      default: {
        return null;
      }
    }
  }
}

export const createProceedings = (entity: Proceedings): Proceedings => new Proceedings(entity);

const romanNumber = (num: number) => {
  switch (num) {
    case 1:
      return 'I';
    case 2:
      return 'II';
    case 3:
      return 'III';
    case 4:
      return 'IV';
    default:
      return '';
  }
};

export const proceedingsInitType = (type: number) => {
  switch (type) {
    case PROCEEDINGS_INIT_TYPES_VALUES.PLANNING:
      return PROCEEDINGS_INIT_TYPES.PLANNING;
    case PROCEEDINGS_INIT_TYPES_VALUES.OPEN:
      return PROCEEDINGS_INIT_TYPES.OPEN;
    default:
      return '';
  }
};

export const proceedingsStatus = (status: number) => {
  switch (status) {
    case PROCEEDINGS_STATUS_VALUES.IN_PROCESS:
      return PROCEEDINGS_STATUS.IN_PROCESS;
    case PROCEEDINGS_STATUS_VALUES.ENDED:
      return PROCEEDINGS_STATUS.ENDED;
    default:
      return '';
  }
};

export const proceedingsType = (proceeding_type: number) => {
  switch (proceeding_type) {
    case PROCEEDINGS_INIT_TYPES_VALUES.SERVICES:
      return PROCEEDINGS_INIT_TYPES.SERVICES;
    case PROCEEDINGS_INIT_TYPES_VALUES.CONSTRUCTIONS:
      return PROCEEDINGS_INIT_TYPES.CONSTRUCTIONS;
    case PROCEEDINGS_INIT_TYPES_VALUES.DELIVERY:
      return PROCEEDINGS_INIT_TYPES.DELIVERY;
    default:
      return '';
  }
};

export const proceedingsMode = (mode: number) => {
  switch (mode) {
    case PROCEEDINGS_TYPES_VALUES.OPEN_AUCTION:
      return PROCEEDINGS_TYPES.OPEN_AUCTION;
    case PROCEEDINGS_TYPES_VALUES.RESTRICTED_AUCTION:
      return PROCEEDINGS_TYPES.RESTRICTED_AUCTION;
    case PROCEEDINGS_TYPES_VALUES.NEGOTIATION_WITH_ANNOUNCEMENT:
      return PROCEEDINGS_TYPES.NEGOTIATION_WITH_ANNOUNCEMENT;
    case PROCEEDINGS_TYPES_VALUES.DIALOGUE:
      return PROCEEDINGS_TYPES.DIALOGUE;
    case PROCEEDINGS_TYPES_VALUES.PARTNERSHIP:
      return PROCEEDINGS_TYPES.PARTNERSHIP;
    case PROCEEDINGS_TYPES_VALUES.NEGOTIATION_WITHOUT_ANNOUNCEMENT:
      return PROCEEDINGS_TYPES.NEGOTIATION_WITHOUT_ANNOUNCEMENT;
    case PROCEEDINGS_TYPES_VALUES.FREE_HAND:
      return PROCEEDINGS_TYPES.FREE_HAND;
    case PROCEEDINGS_TYPES_VALUES.SOCIAL_SERVICES:
      return PROCEEDINGS_TYPES.SOCIAL_SERVICES;
    case PROCEEDINGS_TYPES_VALUES.BELOW_13K:
      return PROCEEDINGS_TYPES.BELOW_13K;
    case PROCEEDINGS_TYPES_VALUES.BASE_MODE:
      return PROCEEDINGS_TYPES.BASE_MODE;
    case PROCEEDINGS_TYPES_VALUES.PRINCIPLE_COMPETITIVENESS:
      return PROCEEDINGS_TYPES.PRINCIPLE_COMPETITIVENESS;
    default:
      return '';
  }
};

export const proceedingsStartDateType = (start_date_type: number) => {
  switch (start_date_type) {
    case START_DATE_TYPES_VALUES.QUARTER:
      return START_DATE_TYPES.QUARTER;
    case START_DATE_TYPES_VALUES.MONTH:
      return START_DATE_TYPES.MONTH;
    case START_DATE_TYPES_VALUES.DATE:
      return START_DATE_TYPES.DATE;
    default:
      return '';
  }
};

export const proceedingsStartDate = (start_date_type: number, start_date: string | number) => {
  switch (start_date_type) {
    case START_DATE_TYPES_VALUES.QUARTER:
      return `${START_DATE_TYPES.QUARTER} - ${romanNumber(start_date as number)}`;
    case START_DATE_TYPES_VALUES.MONTH:
      return `${START_DATE_TYPES.MONTH} - ${findMonthName(start_date as number)}`;
    case START_DATE_TYPES_VALUES.DATE:
      return formatDatetimeSeconds(start_date as string);
    default:
      return '';
  }
};

export const proceedingInitTypeOptions = () => {
  return [
    { value: PROCEEDINGS_INIT_TYPES_VALUES.PLANNING, label: PROCEEDINGS_INIT_TYPES.PLANNING },
    { value: PROCEEDINGS_INIT_TYPES_VALUES.OPEN, label: PROCEEDINGS_INIT_TYPES.OPEN }
  ];
};

export const proceedingStatusOptions = () => {
  return [
    { value: PROCEEDINGS_STATUS_VALUES.IN_PROCESS, label: PROCEEDINGS_STATUS.IN_PROCESS },
    { value: PROCEEDINGS_STATUS_VALUES.ENDED, label: PROCEEDINGS_STATUS.ENDED }
  ];
};

export const proceedingTypeOptions = () => {
  return [
    { value: PROCEEDINGS_INIT_TYPES_VALUES.SERVICES, label: PROCEEDINGS_INIT_TYPES.SERVICES },
    { value: PROCEEDINGS_INIT_TYPES_VALUES.CONSTRUCTIONS, label: PROCEEDINGS_INIT_TYPES.CONSTRUCTIONS },
    { value: PROCEEDINGS_INIT_TYPES_VALUES.DELIVERY, label: PROCEEDINGS_INIT_TYPES.DELIVERY }
  ];
};

export const proceedingModeOptions = () => {
  return [
    { value: PROCEEDINGS_TYPES_VALUES.OPEN_AUCTION, label: PROCEEDINGS_TYPES.OPEN_AUCTION },
    { value: PROCEEDINGS_TYPES_VALUES.RESTRICTED_AUCTION, label: PROCEEDINGS_TYPES.RESTRICTED_AUCTION },
    {
      value: PROCEEDINGS_TYPES_VALUES.NEGOTIATION_WITH_ANNOUNCEMENT,
      label: PROCEEDINGS_TYPES.NEGOTIATION_WITH_ANNOUNCEMENT
    },
    { value: PROCEEDINGS_TYPES_VALUES.DIALOGUE, label: PROCEEDINGS_TYPES.DIALOGUE },
    { value: PROCEEDINGS_TYPES_VALUES.PARTNERSHIP, label: PROCEEDINGS_TYPES.PARTNERSHIP },
    {
      value: PROCEEDINGS_TYPES_VALUES.NEGOTIATION_WITHOUT_ANNOUNCEMENT,
      label: PROCEEDINGS_TYPES.NEGOTIATION_WITHOUT_ANNOUNCEMENT
    },
    { value: PROCEEDINGS_TYPES_VALUES.FREE_HAND, label: PROCEEDINGS_TYPES.FREE_HAND },
    { value: PROCEEDINGS_TYPES_VALUES.SOCIAL_SERVICES, label: PROCEEDINGS_TYPES.SOCIAL_SERVICES },
    { value: PROCEEDINGS_TYPES_VALUES.BELOW_13K, label: PROCEEDINGS_TYPES.BELOW_13K },
    { value: PROCEEDINGS_TYPES_VALUES.BASE_MODE, label: PROCEEDINGS_TYPES.BASE_MODE },
    { value: PROCEEDINGS_TYPES_VALUES.PRINCIPLE_COMPETITIVENESS, label: PROCEEDINGS_TYPES.PRINCIPLE_COMPETITIVENESS }
  ];
};

export const proceedingStartDateTypeOptions = () => {
  return [
    { value: START_DATE_TYPES_VALUES.QUARTER, label: START_DATE_TYPES.QUARTER },
    { value: START_DATE_TYPES_VALUES.MONTH, label: START_DATE_TYPES.MONTH },
    {
      value: START_DATE_TYPES_VALUES.DATE,
      label: START_DATE_TYPES.DATE
    }
  ];
};

export const proceedingStartDateQuarterOptions = () => {
  return [
    { value: 1, label: 'kwartał - I' },
    { value: 2, label: 'kwartał - II' },
    {
      value: 3,
      label: 'kwartał - III'
    },
    {
      value: 4,
      label: 'kwartał - IV'
    }
  ];
};

export const proceedingsFileType = (fileType: number) => {
  switch (fileType) {
    case PROCEEDINGS_FILES_VALUES.MAIN:
      return PROCEEDINGS_FILES_TYPES.MAIN;
    case PROCEEDINGS_FILES_VALUES.DESCRIPTION:
      return PROCEEDINGS_FILES_TYPES.DESCRIPTION;
    case PROCEEDINGS_FILES_VALUES.ORDINARY:
      return PROCEEDINGS_FILES_TYPES.ORDINARY;
    default:
      return '';
  }
};

export const proceedingsQuestionsDateOptions = (questionsDate?: string) => {
  if (!questionsDate) {
    return PROCEEDINGS_QUESTIONS_DATE_MESSAGES.EMPTY;
  }

  const dateInfo = `do dnia ${formatDateIfExist(questionsDate, DATETIME_FORMAT_VALUE, DATETIME_SECONDS_FORMAT)}`;

  const isExpired = moment(questionsDate, DATETIME_FORMAT_VALUE).isBefore(getCurrentDateInFormat());
  if (isExpired) {
    return `${dateInfo}. ${PROCEEDINGS_QUESTIONS_DATE_MESSAGES.EXPIRATION}`;
  }

  return dateInfo;
};

export const proceedingsDepositRequiredOptions = () => {
  return [
    { value: 1, label: 'Tak' },
    { value: 0, label: 'Nie' }
  ];
};
