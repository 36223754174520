import { PaginationMeta, SearchingProps } from 'modules/Shared/type';
import InvoiceRequired from 'modules/User/model/Invoice';

export interface InvoiceListState extends SearchingProps {
  invoice: InvoiceRequired[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initInvoiceListState = (): InvoiceListState => ({
  invoice: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
