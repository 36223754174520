import React, { useEffect, useRef, useState } from 'react';
import { CustomInput, FormFeedback, FormGroup, Label } from 'reactstrap';
import Part from 'modules/Agreements/model/Part';
import { isEqual } from 'lodash';
import { getError, hasError } from 'modules/Shared/helper/validation';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_SPECIFIC_REQUIREMENTS } from 'modules/Agreements/step';
import StepImportDataButton from 'modules/Agreements/components/StepImportDataButton';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import Accordion from 'modules/Layout/component/Accordion';
import { IMPORT_ORDERS_ACCESS } from '../../../../../Auth/container/Guard/permissions';
import Authorize from '../../../../../Auth/container/Authorize';

export interface AgreementStepSpecificRequirementsValues {
  ser_same_specific_requirements: boolean;
  specific_requirements: {
    part_id: number | null;
    needs_possibility: boolean;
    needs_architecture_no_barrier_checked: boolean;
    needs_architecture_no_barrier_content: string | null;
    needs_architecture_devices_checked: boolean;
    needs_architecture_devices_content: string | null;
    needs_architecture_information_checked: boolean;
    needs_architecture_information_content: string | null;
    needs_architecture_access_checked: boolean;
    needs_architecture_access_content: string | null;
    needs_architecture_other_checked: boolean;
    needs_architecture_other_content: string | null;
    needs_accessibility_pl_law_checked: boolean;
    needs_accessibility_pl_law_content: string | null;
    needs_accessibility_functionality_checked: boolean;
    needs_accessibility_functionality_content: string | null;
    needs_accessibility_eu_norm_checked: boolean;
    needs_accessibility_eu_norm_content: string | null;
    needs_communication_support_checked: boolean;
    needs_communication_support_content: string | null;
    needs_communication_devices_checked: boolean;
    needs_communication_devices_content: string | null;
    needs_communication_web_checked: boolean;
    needs_communication_web_content: string | null;
    needs_communication_application_checked: boolean;
    needs_communication_application_content: string | null;
    specific_requirements_checked: boolean;
    specific_requirements_content: string | null;
    legal_acts_checked: boolean;
    legal_acts_content: string | null;
    standards_checked: boolean;
    standards_content: string | null;
  }[];
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

export interface AgreementStepSpecificRequirementsImportValues {
  ser_same_specific_requirements: boolean;
  specific_requirements: {
    needs_possibility: boolean;
    needs_architecture_no_barrier_checked: boolean;
    needs_architecture_no_barrier_content: string | null;
    needs_architecture_devices_checked: boolean;
    needs_architecture_devices_content: string | null;
    needs_architecture_information_checked: boolean;
    needs_architecture_information_content: string | null;
    needs_architecture_access_checked: boolean;
    needs_architecture_access_content: string | null;
    needs_architecture_other_checked: boolean;
    needs_architecture_other_content: string | null;
    needs_accessibility_pl_law_checked: boolean;
    needs_accessibility_pl_law_content: string | null;
    needs_accessibility_functionality_checked: boolean;
    needs_accessibility_functionality_content: string | null;
    needs_accessibility_eu_norm_checked: boolean;
    needs_accessibility_eu_norm_content: string | null;
    needs_communication_support_checked: boolean;
    needs_communication_support_content: string | null;
    needs_communication_devices_checked: boolean;
    needs_communication_devices_content: string | null;
    needs_communication_web_checked: boolean;
    needs_communication_web_content: string | null;
    needs_communication_application_checked: boolean;
    needs_communication_application_content: string | null;
    specific_requirements_checked: boolean;
    specific_requirements_content: string | null;
    legal_acts_checked: boolean;
    legal_acts_content: string | null;
    standards_checked: boolean;
    standards_content: string | null;
  }[];
}

const NEEDS_ARCHITECTURE_NO_BARRIER_CONTENT =
  'zapewnienie wolnych od barier poziomych i pionowych przestrzeni komunikacyjnych budynków';
const NEEDS_ARCHITECTURE_DEVICES_CONTENT =
  'instalację urządzeń lub zastosowanie środków technicznych i rozwiązań architektonicznych w budynku, które umożliwiają dostęp do wszystkich pomieszczeń, z wyłączeniem pomieszczeń technicznych';
const NEEDS_ARCHITECTURE_INFORMATION_CONTENT =
  'zapewnienie informacji na temat rozkładu pomieszczeń w budynku, co najmniej w sposób wizualny i dotykowy lub głosowy';
const NEEDS_ARCHITECTURE_ACCESS_CONTENT =
  'zapewnienie wstępu do budynku osobie korzystającej z psa asystującego, o którym mowa w art. 2 pkt 11 ustawy z dnia 27 sierpnia 1997 r. o rehabilitacji zawodowej i społecznej oraz zatrudnianiu osób niepełnosprawnych (Dz. U. z 2020 r. poz. 426, 568 i 875)';
const NEEDS_ARCHITECTURE_OTHER_CONTENT =
  'inny sposób zapewnienia osobom ze szczególnymi potrzebami możliwości ewakuacji lub ich uratowania w inny sposób: ....';
const NEEDS_ACCESSIBILITY_PL_LAW_CONTENT =
  'zgodność strony internetowej lub aplikacji mobilnej z wymaganiami określonymi w załączniku do u.d.c.s.i. ustawy z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych';
const NEEDS_ACCESSIBILITY_FUNCTIONALITY_CONTENT =
  'zapewnienie funkcjonalności, kompatybilności, postrzegalności i zrozumiałości strony internetowej lub aplikacji mobilnej';
const NEEDS_ACCESSIBILITY_EU_NORM_CONTENT =
  'zapewnienie dostępności cyfrowej z uwzględnieniem wymagań określonych w pkt 9, 10 i 11 normy EN 301 549 V2.1.2';
const NEEDS_COMMUNICATION_SUPPORT_CONTENT =
  'obsługę z wykorzystaniem środków wspierających komunikowanie się, o których mowa w art. 3 pkt 5 ustawy z dnia 19 sierpnia 2011 r. o języku migowym i innych środkach komunikowania się (Dz. U. z 2017 r. poz. 1824), lub przez wykorzystanie zdalnego dostępu online do usługi tłumacza przez strony internetowe i aplikacje';
const NEEDS_COMMUNICATION_DEVICES_CONTENT =
  'instalację urządzeń lub innych środków technicznych do obsługi osób słabosłyszących, w szczególności pętli indukcyjnych, systemów FM lub urządzeń opartych o inne technologie, których celem jest wspomaganie słyszenia';
const NEEDS_COMMUNICATION_WEB_CONTENT =
  'zapewnienie na stronie internetowej danego podmiotu informacji o zakresie jego działalności - w postaci elektronicznego pliku zawierającego tekst odczytywalny maszynowo, nagrania treści w polskim języku migowym oraz informacji w tekście łatwym do czytania';
const NEEDS_COMMUNICATION_APPLICATION_CONTENT =
  'zapewnienie, na wniosek osoby ze szczególnymi potrzebami, komunikacji z podmiotem publicznym w formie określonej w tym wniosku';
const SPECIFIC_REQUIREMENTS_CONTENT =
  'Zamawiający określa poniższe szczegółowe wymogi, według których powinien być realizowany przedmiot umowy: ....';
const LEGAL_ACTS_CONTENT =
  'Zamawiający określa, iż przedmiot umowy powinien być realizowany w oparciu o następujące akty prawne: ....';
const STANDARDS_CONTENT =
  'Zamawiający określa poniższe standardy, według których powinien być realizowany przedmiot umowy: ....';

const initContentValues = {
  needs_architecture_no_barrier_content: NEEDS_ARCHITECTURE_NO_BARRIER_CONTENT,
  needs_architecture_devices_content: NEEDS_ARCHITECTURE_DEVICES_CONTENT,
  needs_architecture_information_content: NEEDS_ARCHITECTURE_INFORMATION_CONTENT,
  needs_architecture_access_content: NEEDS_ARCHITECTURE_ACCESS_CONTENT,
  needs_architecture_other_content: NEEDS_ARCHITECTURE_OTHER_CONTENT,
  needs_accessibility_pl_law_content: NEEDS_ACCESSIBILITY_PL_LAW_CONTENT,
  needs_accessibility_functionality_content: NEEDS_ACCESSIBILITY_FUNCTIONALITY_CONTENT,
  needs_accessibility_eu_norm_content: NEEDS_ACCESSIBILITY_EU_NORM_CONTENT,
  needs_communication_support_content: NEEDS_COMMUNICATION_SUPPORT_CONTENT,
  needs_communication_devices_content: NEEDS_COMMUNICATION_DEVICES_CONTENT,
  needs_communication_web_content: NEEDS_COMMUNICATION_WEB_CONTENT,
  needs_communication_application_content: NEEDS_COMMUNICATION_APPLICATION_CONTENT,
  specific_requirements_content: SPECIFIC_REQUIREMENTS_CONTENT,
  legal_acts_content: LEGAL_ACTS_CONTENT,
  standards_content: STANDARDS_CONTENT
};

const mapState = (step: AgreementStepSpecificRequirementsValues): AgreementStepSpecificRequirementsValues => {
  const { specific_requirements, checkboxes, ...rest } = step;

  return {
    ...rest,
    specific_requirements: specific_requirements.map((part) => {
      const {
        needs_architecture_no_barrier_content,
        needs_architecture_devices_content,
        needs_architecture_information_content,
        needs_architecture_access_content,
        needs_architecture_other_content,
        needs_accessibility_pl_law_content,
        needs_accessibility_functionality_content,
        needs_accessibility_eu_norm_content,
        needs_communication_support_content,
        needs_communication_devices_content,
        needs_communication_web_content,
        needs_communication_application_content,
        specific_requirements_content,
        legal_acts_content,
        standards_content
      } = part;

      return {
        ...part,
        needs_architecture_no_barrier_content:
          needs_architecture_no_barrier_content || NEEDS_ARCHITECTURE_NO_BARRIER_CONTENT,
        needs_architecture_devices_content: needs_architecture_devices_content || NEEDS_ARCHITECTURE_DEVICES_CONTENT,
        needs_architecture_information_content:
          needs_architecture_information_content || NEEDS_ARCHITECTURE_INFORMATION_CONTENT,
        needs_architecture_access_content: needs_architecture_access_content || NEEDS_ARCHITECTURE_ACCESS_CONTENT,
        needs_architecture_other_content: needs_architecture_other_content || NEEDS_ARCHITECTURE_OTHER_CONTENT,
        needs_accessibility_pl_law_content: needs_accessibility_pl_law_content || NEEDS_ACCESSIBILITY_PL_LAW_CONTENT,
        needs_accessibility_functionality_content:
          needs_accessibility_functionality_content || NEEDS_ACCESSIBILITY_FUNCTIONALITY_CONTENT,
        needs_accessibility_eu_norm_content: needs_accessibility_eu_norm_content || NEEDS_ACCESSIBILITY_EU_NORM_CONTENT,
        needs_communication_support_content: needs_communication_support_content || NEEDS_COMMUNICATION_SUPPORT_CONTENT,
        needs_communication_devices_content: needs_communication_devices_content || NEEDS_COMMUNICATION_DEVICES_CONTENT,
        needs_communication_web_content: needs_communication_web_content || NEEDS_COMMUNICATION_WEB_CONTENT,
        needs_communication_application_content:
          needs_communication_application_content || NEEDS_COMMUNICATION_APPLICATION_CONTENT,
        specific_requirements_content: specific_requirements_content || SPECIFIC_REQUIREMENTS_CONTENT,
        legal_acts_content: legal_acts_content || LEGAL_ACTS_CONTENT,
        standards_content: standards_content || STANDARDS_CONTENT
      };
    }),
    checkboxes: checkboxes || {}
  };
};

const AgreementStepSpecificRequirements = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, stepsImportData, onSubmit, onChange, errors } = props;
  const initState = useRef<AgreementStepSpecificRequirementsValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_SPECIFIC_REQUIREMENTS))
  );
  const [stepValues, setStepValues] = useState<AgreementStepSpecificRequirementsValues>({ ...initState.current });
  const stepDataImportValues: AgreementStepSpecificRequirementsImportValues = getStepValues(
    stepsImportData,
    AGREEMENT_STEP_SPECIFIC_REQUIREMENTS
  );
  const { parts_ids, few_parts } = agreement;
  const { specific_requirements, ser_same_specific_requirements } = stepValues;

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_SPECIFIC_REQUIREMENTS));
    setStepValues({ ...initState.current });
  }, [steps]);

  const isMultiple = few_parts && !ser_same_specific_requirements;

  const { renderPartCheckbox, renderPartSwitch, renderPartEditableCheckbox, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    partSlug: 'specific_requirements',
    initContentValues
  });

  const renderPart = (partObj: Part, index: number) => {
    const part = isMultiple ? specific_requirements[index] : specific_requirements[0];

    const body = (
      <FormGroup>
        {renderPartEditableCheckbox('specific_requirements', index, part)}
        {renderPartSwitch(
          'needs_possibility',
          'Czy przedmiot umowy powinien być realizowany zgodnie z ustawą z dnia 19 lipca 2019 r. o zapewnianiu dostępności osobom ze szczególnymi potrzebami?',
          index,
          part
        )}
        {part.needs_possibility && [
          <Label className="mb-2">
            1) zamawiający określa poniższe wymagania w zakresie dostępności architektonicznej:
          </Label>,
          renderPartCheckbox(
            'needs_architecture_no_barrier_checked',
            part.needs_architecture_no_barrier_content,
            index,
            part
          ),
          renderPartCheckbox(
            'needs_architecture_devices_checked',
            part.needs_architecture_devices_content,
            index,
            part
          ),
          renderPartCheckbox(
            'needs_architecture_information_checked',
            part.needs_architecture_information_content,
            index,
            part
          ),
          renderPartCheckbox('needs_architecture_access_checked', part.needs_architecture_access_content, index, part),
          renderPartEditableCheckbox('needs_architecture_other', index, part),
          <Label className="mb-2">2) Zamawiający określa poniższe wymagania w zakresie dostępności cyfrowej:</Label>,
          renderPartCheckbox(
            'needs_accessibility_pl_law_checked',
            part.needs_accessibility_pl_law_content,
            index,
            part
          ),
          renderPartCheckbox(
            'needs_accessibility_functionality_checked',
            part.needs_accessibility_functionality_content,
            index,
            part
          ),
          renderPartCheckbox(
            'needs_accessibility_eu_norm_checked',
            part.needs_accessibility_eu_norm_content,
            index,
            part
          ),
          <Label className="mb-2">
            3) Zamawiający określa poniższe wymagania w zakresie dostępności informacyjno-komunikacyjnej:
          </Label>,
          renderPartCheckbox(
            'needs_communication_support_checked',
            part.needs_communication_support_content,
            index,
            part
          ),
          renderPartCheckbox(
            'needs_communication_devices_checked',
            part.needs_communication_devices_content,
            index,
            part
          ),
          renderPartCheckbox('needs_communication_web_checked', part.needs_communication_web_content, index, part),
          renderPartCheckbox(
            'needs_communication_application_checked',
            part.needs_communication_application_content,
            index,
            part
          ),
          <hr />
        ]}
        {renderPartEditableCheckbox('legal_acts', index, part)}
        {renderPartEditableCheckbox('standards', index, part)}
        {renderCustomCheckboxes('general', partObj?.id)}
      </FormGroup>
    );

    if (isMultiple) {
      return (
        <Accordion
          key={`renderPartAccordion-${partObj.id}`}
          isInvalid={hasError(errors, `specific_requirements.${index}`)}
          accordionContentStyle={{ padding: '1rem' }}
          entity={{
            title: partObj.getName(index),
            content: body
          }}
        />
      );
    }

    return body;
  };

  const samePartsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setStepValues((values) =>
      mapState({
        ...values,
        ser_same_specific_requirements: checked,
        specific_requirements: checked
          ? [
              {
                ...specific_requirements[0],
                part_id: null
              }
            ]
          : parts_ids.map((part) => ({
              ...specific_requirements[0],
              part_id: part.id
            }))
      })
    );
  };

  const onStepDataImportClick = () => {
    if (stepDataImportValues.ser_same_specific_requirements !== undefined) {
      samePartsChange({ target: { checked: stepDataImportValues.ser_same_specific_requirements } } as any);
    }

    setStepValues((values) => {
      return {
        ...values,
        specific_requirements: stepDataImportValues.specific_requirements
          ? values.specific_requirements.map((subject, index) => ({
              ...subject,
              ...stepDataImportValues.specific_requirements[index]
            }))
          : values.specific_requirements
      };
    });
  };

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <Authorize permissions={[IMPORT_ORDERS_ACCESS]}>
        <StepImportDataButton onSubmit={onStepDataImportClick} stepDataImportValues={stepDataImportValues} />
      </Authorize>
      {few_parts && (
        <FormGroup>
          <CustomInput
            id="ser_same_specific_requirements"
            name="ser_same_specific_requirements"
            type="switch"
            checked={ser_same_specific_requirements}
            onChange={samePartsChange}
            invalid={hasError(errors, 'ser_same_specific_requirements')}
            label="Czy wymogi szczegółowe usług są wspólne dla wszystkich zadań?"
          />
          {hasError(errors, 'ser_same_specific_requirements') && (
            <FormFeedback className="d-block">{getError(errors, 'ser_same_specific_requirements')}</FormFeedback>
          )}
        </FormGroup>
      )}
      {(isMultiple ? parts_ids : [null]).map(renderPart)}
      {isMultiple && (
        <>
          <p className="h4">Ponadto dla każdego z zadań:</p>
          {renderCustomCheckboxes()}
        </>
      )}
    </AgreementFormWrapper>
  );
};

export default AgreementStepSpecificRequirements;
