import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { PartialSearchingProps, SortParams } from 'modules/Shared/type';
import React from 'react';
import { useDispatch } from 'react-redux';
import JudgementElement from 'modules/Judgement/model/JudgementElement';
import { Dispatch } from 'redux';
import { formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';

type Props = {
  redirectLink?: (value: string, id: number) => JSX.Element;
  actions?: TableCol<JudgementElement>;
  categoryList: { list: JudgementElement[]; sort: SortParams };
  reduxAction: (payload: PartialSearchingProps) => (dispatch: Dispatch, getState: () => RootState) => Promise<void>;
  nameSortable?: boolean;
};

const JudgementCategoryTable: React.FC<Props> = ({
  redirectLink,
  actions,
  categoryList,
  reduxAction,
  nameSortable = false
}) => {
  const dispatch = useDispatch();
  const { list, sort } = categoryList;

  const setParams = (payload: PartialSearchingProps) => dispatch(reduxAction(payload));

  const getCols = () => {
    let cols: TableCol<JudgementElement>[] = [
      {
        property: 'name',
        label: 'Nazwa',
        sortable: nameSortable,
        value: (row) => (redirectLink ? redirectLink(row.name, row.id) : row.name)
      }
    ];

    cols = [
      ...cols,
      {
        property: 'created_at',
        label: 'Utworzono',
        value: (row) => ifContainsDateFormat(row.created_at, formatDatetimeSeconds)
      },
      {
        property: 'updated_at',
        label: 'Zaktualizowano',
        value: (row) => ifContainsDateFormat(row.updated_at, formatDatetimeSeconds)
      }
    ];

    if (actions) {
      cols = [...cols, actions];
    }

    return cols;
  };

  return <Table cols={getCols()} rows={list} sort={sort} onSort={(params) => setParams({ sort: params })} />;
};

export default JudgementCategoryTable;
