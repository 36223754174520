import React, { Dispatch, SetStateAction } from 'react';
import { CustomInput, FormFeedback, FormGroup } from 'reactstrap';
import { ValidationErrors } from 'modules/Shared/type';
import { getError, hasError } from 'modules/Shared/helper/validation';

export interface StepFieldPartSwitchProps<T> {
  switchKey: string;
  label: string;
  index: number;
  part: Record<any, any>;
  setStepValues: Dispatch<SetStateAction<T>>;
  mapState: (values: T) => T;
  errors?: ValidationErrors | null;
  partSlug?: string;
  disabled?: boolean;
}

const StepFieldPartSwitch = <T,>(props: StepFieldPartSwitchProps<T>): JSX.Element => {
  const { switchKey, label, index, part, setStepValues, mapState, errors, partSlug, disabled } = props;

  return (
    <FormGroup key={`${switchKey}.${index}`}>
      <CustomInput
        id={`${switchKey}.${index}`}
        type="switch"
        label={label}
        // @ts-ignore
        checked={part[switchKey]}
        disabled={disabled}
        onChange={(event) => {
          event.persist();
          // @ts-ignore
          part[switchKey] = event.currentTarget.checked;
          setStepValues((values) => mapState(values));
        }}
        invalid={hasError(errors, `${partSlug}.${index}.${switchKey}`)}
      />
      {hasError(errors, `${partSlug}.${index}.${switchKey}`) && (
        <FormFeedback className="d-block">{getError(errors, `${partSlug}.${index}.${switchKey}`)}</FormFeedback>
      )}
    </FormGroup>
  );
};

export default StepFieldPartSwitch;
