import React from 'react';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_EXECUTOR } from 'modules/Executor/routes';
import { calculateDiffInDays, formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { DashboardClientExecutor } from 'modules/Layout/type';
import { Link } from 'react-router-dom';
import './lastVisitedExecutor.scss';
import InformationList from 'modules/Layout/component/InformationList';
import Tooltip from '../Tooltip';

type Props = { executor: DashboardClientExecutor };

const LastVisitedExcutor: React.FC<Props> = ({ executor }) => {
  const daysLate = (date: string) => calculateDiffInDays(date);

  const daysCorrectForm = (days: number) => {
    switch (days) {
      case 1:
        return 'dzień';
      default:
        return 'dni';
    }
  };

  const redirectLink = (value: string, id: number, showTooltip: boolean = false) => {
    return (
      <>
        <Link id={`dashboard-excutor-link-${id}`} to={getPathUrl(ROUTE_EXECUTOR, { id })} className="pzpeu-link">
          {value}
        </Link>
        {showTooltip && value?.length >= 20 && (
          <Tooltip targetId={`dashboard-excutor-link-${id}`} tooltipContent={value} tooltipPlacement="bottom-start" />
        )}
      </>
    );
  };

  const calculateLastVisit = () => {
    if (!executor?.visited_at) return '-';

    const lastVisitDays = daysLate(executor.visited_at);
    const lastVisitDaysRounded = Math.ceil(lastVisitDays);

    if (lastVisitDaysRounded === 0) return 'dzisiaj';
    return `${lastVisitDaysRounded} ${daysCorrectForm(lastVisitDaysRounded)} temu`;
  };

  const calculateLastUpdate = () => {
    if (!executor?.executor_updated_at) return '-';

    const lastUpdateDays = daysLate(executor.executor_updated_at);
    const lastUpdateDaysRounded = Math.ceil(lastUpdateDays);

    if (lastUpdateDaysRounded === 0) return 'dzisiaj';
    return `${lastUpdateDaysRounded} ${daysCorrectForm(lastUpdateDaysRounded)} temu`;
  };

  return (
    <div className="last-visited-executor">
      <div className="executor-title">{redirectLink(executor?.nip, executor?.id, true)}</div>
      <InformationList
        fields={[
          {
            label: 'Nazwa',
            value: redirectLink(executor?.name || '-', executor?.id)
          },
          {
            label: 'Ostatnia weryfikacja',
            value: calculateLastVisit()
          },
          {
            label: 'Ostatnia aktualizacja danych',
            value: calculateLastUpdate()
          },
          {
            label: 'Masz dostęp do dnia',
            value: (
              <span className="text-success-dark">{ifContainsDateFormat(executor?.expires_at, formatDate) ?? '-'}</span>
            )
          }
        ]}
      />
    </div>
  );
};

export default LastVisitedExcutor;
