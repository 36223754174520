export const USERS_INDEX = 'users_index';
export const USER_DETAILS = 'users_details';
export const USERS_ADD = 'users_add';
export const USERS_EDIT = 'users_edit';
export const EXECUTORS_EXTENDED = 'executors_report_claims';
export const USERS_DELETE = 'users_delete';
export const UPDATE_OWN_COMPANY = 'update_own_company';
export const MODULE_USER_LOGS_ALL = 'module_user_logs_all';
export const MODULE_USER_LOGS_OWN = 'module_user_logs_own';
export const PAYMENY_HISTORY_ALL = 'payments_history_all';
export const PAYMENY_HISTORY_OWN = 'payments_history_own';
