import { addToastAction, managePageAction } from 'modules/Layout/action';
import Form from 'modules/Office/component/OfficeForm';
import React, { useEffect, useState, useRef } from 'react';
import Loader from 'modules/Layout/component/Loader';
import { useDispatch } from 'react-redux';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { breadcrumbRouteOfficeCreate } from 'modules/Office/breadcrumbs';
import { ROUTE_OFFICE, ROUTE_OFFICES } from 'modules/Office/routes';
import { createOfficeToastSuccess } from 'modules/Office/toasts';
import { OfficeFormValues } from 'modules/Office/type';
import { createNewOffice } from 'modules/Office/repository';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import CardBox from 'modules/Layout/component/CardBox';

const UserCreateView = () => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState(null);
  const scrollToTopOnce = useRef<boolean>(true);

  const cancelToken = useCancelToken();
  useEffect(() => {
    dispatch(
      managePageAction({
        title: 'Tworzenie urzędu',
        breadcrumb: breadcrumbRouteOfficeCreate()
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchCreateOfficeToast = () => dispatch(addToastAction(createOfficeToastSuccess()));
  const setCreateOfficeAction = async (data: OfficeFormValues) => {
    setFetching(true);

    if (message) setMessage(null);
    if (errors) setErrors(null);

    const {
      message: messageResponse,
      error,
      cancelled,
      data: responseData
    } = await updateRequestWithData(createNewOffice, data, cancelToken);

    if (cancelled) return;

    if (responseData) {
      dispatchCreateOfficeToast();
      const { id } = responseData.data;
      if (id) dispatch(push(getPathUrl(ROUTE_OFFICE, { id })));
      else dispatch(push(ROUTE_OFFICES));
    } else {
      if (error) setErrors(error);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <div className="user-create-view d-flex justify-content-center">
      <CardBox form="small" heading="Dodaj urząd">
        {fetching && <Loader />}
        <Alert message={message} />
        <Form errors={errors} submit={(data) => setCreateOfficeAction(data)} />
      </CardBox>
    </div>
  );
};

export default UserCreateView;
