import React, { useEffect, useRef, useState } from 'react';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_CONTRACTOR_DUTIES } from 'modules/Agreements/step';
import { FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import useStepFields from 'modules/Agreements/hook/useStepFields';

export interface AgreementStepContractorDutiesValues {
  acceptance_checked: boolean;
  acceptance_content: string | null;
  payment_checked: boolean;
  payment_content: string | null;
  share_data_checked: boolean;
  share_data_content: string | null;
  grant_access_checked: boolean;
  grant_access_content: string | null;
  ser_cooperation_checked: boolean;
  ser_cooperation_content: string | null;
  checkboxes: AgreementCheckboxes | null;
}

const ACCEPTANCE_CONTENT = 'Dokonanie odbiorów wykonanych usług';
const PAYMENT_CONTENT = 'Zapłata wykonawcy wynagrodzenia za wykonane i odebrane usługi';
const SHARE_DATA_CONTENT =
  'Udostępnienia niezbędnych do realizacji Przedmiotu Umowy danych, dokumentów i informacji znajdujących się w posiadaniu zamawiającego';
const GRANT_ACCESS_CONTENT =
  'Udzielenie niezbędnego dostępu do zasobów zamawiającego w celu wykonania Przedmiotu Umowy';
const SER_COOPERATION_CONTENT = 'Współdziałanie z wykonawcą w zakresie niezbędnym do wykonania Przedmiotu Umowy';

const initContentValues = {
  acceptance_content: ACCEPTANCE_CONTENT,
  payment_content: PAYMENT_CONTENT,
  share_data_content: SHARE_DATA_CONTENT,
  grant_access_content: GRANT_ACCESS_CONTENT,
  ser_cooperation_content: SER_COOPERATION_CONTENT
};

const mapState = (step: AgreementStepContractorDutiesValues): AgreementStepContractorDutiesValues => {
  const {
    acceptance_checked,
    payment_checked,
    acceptance_content,
    payment_content,
    share_data_content,
    grant_access_content,
    ser_cooperation_content,
    checkboxes,
    ...rest
  } = step;

  return {
    ...rest,
    acceptance_checked: acceptance_checked || true,
    payment_checked: payment_checked || true,
    acceptance_content: acceptance_content || ACCEPTANCE_CONTENT,
    payment_content: payment_content || PAYMENT_CONTENT,
    share_data_content: share_data_content || SHARE_DATA_CONTENT,
    grant_access_content: grant_access_content || GRANT_ACCESS_CONTENT,
    ser_cooperation_content: ser_cooperation_content || SER_COOPERATION_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepContractorDuties = (props: AgreementStepProps): JSX.Element => {
  const { steps, onSubmit, onChange, errors } = props;

  const initState = useRef<AgreementStepContractorDutiesValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_CONTRACTOR_DUTIES))
  );

  const [stepValues, setStepValues] = useState<AgreementStepContractorDutiesValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_CONTRACTOR_DUTIES));
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderCheckbox, renderEditableCheckbox, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    initContentValues
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        {renderCheckbox('acceptance_checked', stepValues.acceptance_content, true)}
        {renderCheckbox('payment_checked', stepValues.payment_content, true)}
        {renderEditableCheckbox('share_data')}
        {renderEditableCheckbox('grant_access')}
        {renderEditableCheckbox('ser_cooperation')}
        {renderCustomCheckboxes('duties')}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepContractorDuties;
