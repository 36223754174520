import React, { useEffect } from 'react';
import { RootState } from 'app/reducer';
import { managePageAction } from 'modules/Layout/action';
import Loader from 'modules/Layout/component/Loader';
import { PageProps } from 'modules/Layout/type';
import { setProfileStateAction, updateProfileAction } from 'modules/Profile/action';
import { breadcrumbRouteProfileBaseUpdate } from 'modules/Profile/breadcrumbs';
import Form from 'modules/Profile/component/Form';
import { PartialProfileState } from 'modules/Profile/state';
import { ProfileValues } from 'modules/Profile/type';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'modules/Layout/component/Alert';
import CardBox from 'modules/Layout/component/CardBox';
import { ROLE_ADMIN } from 'modules/User/model/UserRole';

const ProfileUpdate = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const { updating, errors, message } = useSelector((state: RootState) => state.profile);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const updateProfile = (values: ProfileValues) => dispatch(updateProfileAction(values));
  const setProfileState = (state: PartialProfileState) => dispatch(setProfileStateAction(state));

  useEffect(() => {
    managePage({
      title: 'Profil - edycja danych podstawowych',
      breadcrumb: breadcrumbRouteProfileBaseUpdate()
    });

    return () => {
      setProfileState({ updating: false, message: null, errors: null });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="profile-update-view d-flex justify-content-center">
      <CardBox form="small" heading="Profil">
        {updating && <Loader />}
        <Alert message={message} />
        {user && (
          <Form
            profile={user?.getIdentity()}
            awaitingEmail={user?.new_email}
            nipAcceptance={user?.nip_acceptance}
            errors={errors}
            submit={updateProfile}
            disabled={updating}
            isAdmin={user?.role?.slug === ROLE_ADMIN}
          />
        )}
      </CardBox>
    </div>
  );
};

export default ProfileUpdate;
