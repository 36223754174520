/* eslint-disable */
import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function EditJudgmentContentIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px', className = 'icon-svg-table' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} height={height} viewBox="0 0 17.485 16.411">
      <g id="Group_451" data-name="Group 451" transform="translate(-191.061 -336.015)">
        <path
          id="Path_1332"
          data-name="Path 1332"
          d="M193.325,351.906c-.05.05-1.716,4.751-1.716,4.751a.572.572,0,0,0,.723.724s4.726-1.642,4.776-1.692"
          transform="translate(0 -4.099)"
          fill="none"
          stroke="#666666"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_86"
          data-name="Line 86"
          x2="1.331"
          y2="1.331"
          transform="translate(191.948 351.618)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1333"
          data-name="Path 1333"
          d="M208.6,339.095l-2.139-2.139a1.164,1.164,0,0,0-1.645,0l-10.851,10.852,3.783,3.783L208.6,340.74A1.162,1.162,0,0,0,208.6,339.095Z"
          transform="translate(-0.642)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_87"
          data-name="Line 87"
          y1="8.857"
          x2="8.857"
          transform="translate(195.616 341.241)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
