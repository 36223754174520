import React from 'react';
import { CompanyEntity, getClientDataType, UserEntity } from 'modules/User/model/User';
import InformationList from 'modules/Layout/component/InformationList';

export interface Props {
  clientData: CompanyEntity;
  userData: UserEntity;
}

const PaymentLogCompanyDataset: React.FC<Props> = ({ clientData, userData }) => {
  const fields = [
    {
      label: 'Imię i nazwisko użytkownika w systemie',
      value: userData?.name
    },
    {
      label: 'Email użytkownika w systemie',
      value: userData?.email
    },
    {
      label: 'Rodzaj klienta',
      value: getClientDataType(clientData?.client_type)
    },
    {
      label: 'Nazwa firmy',
      value: clientData?.client_type === 2 ? clientData?.company_name : null
    },
    {
      label: 'Imię właściciela',
      value: clientData?.first_name
    },
    {
      label: 'Nazwisko właściciela',
      value: clientData?.last_name
    },
    {
      label: 'NIP',
      value: clientData?.nip
    },
    {
      label: 'Email',
      value: clientData?.email
    },
    {
      label: 'Miejscowość',
      value: clientData?.city
    },
    {
      label: 'Ulica',
      value: clientData?.street
    },
    {
      label: 'Kod pocztowy',
      value: clientData?.postal_code
    },
    {
      label: 'Miejscowość poczty',
      value: clientData?.post_office
    }
  ];

  return <InformationList fields={fields} />;
};

export default PaymentLogCompanyDataset;
