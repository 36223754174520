import React, { useEffect, useState } from 'react';
import { Message, PaginationMeta, SearchParams } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { useParams } from 'react-router-dom';
import { fetchJudgmentSAOSKeywords } from 'modules/Judgement/repository';
import CategorySAOSKeywordsDetailsDisplay from 'modules/Judgement/component/Details/Category/SAOSKeywords/Display';
import useCategoryAddModal from 'modules/Judgement/component/Modal/JudgementElement/useSAOSKeywordAddModal';
import useSAOSKeywordDeleteModal from 'modules/Judgement/component/Modal/JudgementElement/useSAOSKeywordDeleteModal';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import AddKeywordModal from 'modules/Judgement/component/Modal/AddKeyword';

export interface Props {
  redirectUrl?: string;
}

const CategorySAOSKeywordsDetails = (props: Props): JSX.Element => {
  const { redirectUrl } = props;

  const [SAOSKeywords, setSAOSKeywords] = useState<JudgementElementEntity[]>([]);
  const [meta, setMeta] = useState<PaginationMeta>(null);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);
  const { id } = useParams<{ id: string }>();

  const cancelToken = useCancelToken();
  const getJudgmentSAOSKeywords = async (params: SearchParams = {}) => {
    setFetching(true);
    const {
      data,
      cancelled,
      message: messageResponse
    } = await fetchRequest(fetchJudgmentSAOSKeywords, id, params, cancelToken);

    if (cancelled) return;
    if (data) {
      const { meta: listMeta, data: listData } = data;
      setSAOSKeywords(listData);
      setMeta(listMeta);
    }
    if (messageResponse) setMessage(messageResponse);
    setFetching(false);
  };

  useEffect(() => {
    getJudgmentSAOSKeywords();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onModalActionSuccess = () => getJudgmentSAOSKeywords({ pagination: meta.current_page });

  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addSAOSKeywordAction,
    loading: addLoading,
    displayAddModal,
    setDisplayAddModal,
    message: addMessage
  } = useCategoryAddModal(id, onModalActionSuccess);

  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleteSAOSKeywordAction,
    displayDeleteModal,
    setDisplayDeleteModal,
    loading: deleteLoading,
    message: deleteMessage,
    clearErrors: deleteCleanErrors
  } = useSAOSKeywordDeleteModal(id, getJudgmentSAOSKeywords);

  const toggleCreateModal = () => {
    if (!addLoading) setDisplayAddModal(false);
  };

  const toggleDeleteModal = () => {
    if (!deleteLoading) setDisplayDeleteModal(null);
  };

  const onDeleteClick = (SAOSKeyword: JudgementElementEntity) => setDisplayDeleteModal(SAOSKeyword);

  return (
    <>
      {displayAddModal && (
        <AddKeywordModal
          isOpen
          toggle={toggleCreateModal}
          loading={addLoading}
          message={addMessage}
          onSubmit={addSAOSKeywordAction}
        />
      )}
      {displayDeleteModal && (
        <GenericModalDelete
          value={displayDeleteModal}
          title="Usuń słowo kluczowe"
          content={(value) => <div>Czy na pewno chcesz usunąć słowo kluczowe o nazwie {value?.name}?</div>}
          onDeleteClick={(value) => deleteSAOSKeywordAction(value.id)}
          isOpen={!!displayDeleteModal}
          toggle={toggleDeleteModal}
          loading={deleteLoading}
          message={deleteMessage}
          resetMessage={deleteCleanErrors}
        />
      )}
      <CategorySAOSKeywordsDetailsDisplay
        SAOSKeywords={SAOSKeywords}
        meta={meta}
        fetching={fetching}
        message={message}
        getJudgmentSAOSKeywords={getJudgmentSAOSKeywords}
        setDisplayAddModal={setDisplayAddModal}
        onDeleteClick={onDeleteClick}
        redirectUrl={redirectUrl}
      />
    </>
  );
};

export default CategorySAOSKeywordsDetails;
