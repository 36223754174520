import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { FormFeedback, FormGroup } from 'reactstrap';
import Accordion from '../../../Layout/component/Accordion';
import { ExecutorExplanationSectionValues } from '../../type';
import useWysiwyg from '../../../Layout/component/Wysiwyg';

export interface Props {
  section: ExecutorExplanationSectionValues;
  onDescriptionChange: (slug: string, newContent: string) => void;
  errors?: ValidationErrors;
}

const ExplanationSectionForm: React.FC<Props> = (props: Props): JSX.Element => {
  const { section, errors, onDescriptionChange } = props;
  const { displayWysiwyg } = useWysiwyg({
    content: section.description,
    tooltip: { specialSign: true },
    onChange: (newContent: string) => onDescriptionChange(section.slug, newContent)
  });
  const { title, slug } = section;

  return (
    <fieldset className="m-0">
      <FormGroup className="form-group-large-margins">
        <Accordion entity={{ title, content: displayWysiwyg && displayWysiwyg() }} />

        {hasError(errors, slug) && <FormFeedback className="d-block">{getError(errors, slug)}</FormFeedback>}
      </FormGroup>
    </fieldset>
  );
};

export default ExplanationSectionForm;
