import React, { useEffect, useState } from 'react';
import { addToastAction } from 'modules/Layout/action';
import { useDispatch, useSelector } from 'react-redux';
import { Message, ValidationErrors } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import {
  DataUpdateUserSubscriptions,
  fetchModuleList,
  updateUserCredits,
  updateUserSubscriptions
} from 'modules/Module/repository';
import User, { createUser } from 'modules/User/model/User';
import { ModuleEntity } from 'modules/Module/model/Module';
import ModalEdit from 'modules/Module/component/Modal/Edit';
import { updateModuleToastError, updateModuleToastSuccess } from 'modules/Module/toasts';
import CardBox from 'modules/Layout/component/CardBox';
import { RootState } from 'app/reducer';
import ModuleDataset from 'modules/Module/component/Dataset/ListDetails';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import Loader from 'modules/Layout/component/Loader';
import ModuleCreateModal from 'modules/Module/component/Modal/Create';
import { MODULE_USER_LOGS_ALL, MODULE_USER_LOGS_OWN, USERS_EDIT } from 'modules/User/permissions';
import Authorize from 'modules/Auth/container/Authorize';
import { Link } from 'react-router-dom';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_ACCESS_HISTORY } from 'modules/Module/routes';
import { SUBSCRIPTIONS_MODULE_ACCESS } from '../../../Auth/container/Guard/permissions';

export interface Props {
  user: User;
  updateUser?: React.Dispatch<React.SetStateAction<User>>;
}

export type ModuleEditModalObj = {
  module?: ModuleEntity;
  values?: { all_credits_count?: number; available_credits?: number; date_to: string };
};

const ListDetailsModules: React.FC<Props> = ({ user, updateUser }) => {
  const dispatch = useDispatch();
  const { user: loggedUser } = useSelector((state: RootState) => state.auth);

  const [moduleList, setModuleList] = useState<ModuleEntity[]>([]);
  const [uploading, setUploading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [displayEditModal, setEditDisplayModal] = useState<ModuleEditModalObj>(null);
  const [displayCreateModal, setCreateDisplayModal] = useState<ModuleEditModalObj>(null);

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const { data, cancelled, message: messageResponse } = await fetchRequest(fetchModuleList, cancelToken);

      if (cancelled) return;
      if (data) setModuleList(data.data);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (isSubscriptionEdit: boolean, requestData: DataUpdateUserSubscriptions) => {
    // TODO make a wrapper (HOC or hook) with modal separately for creating/editing and submit fcn in container folder
    setUploading(true);

    const {
      data,
      message: resMessage,
      error,
      cancelled
    } = await updateRequestWithData(
      isSubscriptionEdit ? updateUserSubscriptions : (updateUserCredits as any),
      user.id,
      requestData,
      cancelToken
    );

    if (cancelled) return;
    if (data) {
      dispatch(addToastAction(updateModuleToastSuccess()));
      if (isSubscriptionEdit) updateUser(createUser({ ...user, modules: (data as any).data }));
      else updateUser(createUser({ ...user, credits: (data as any).data }));
      if (displayEditModal) setEditDisplayModal(null);
      if (displayCreateModal) setCreateDisplayModal(null);
    } else {
      dispatch(addToastAction(updateModuleToastError()));
    }
    if (error) setErrors(error);
    if (resMessage) setMessage(resMessage);
    setUploading(false);
  };

  const findSubscriptionModule = (slug: string) => user?.modules?.find((module) => module?.slug === slug);
  const findCreditModules = (slug: string) => user?.credits?.find((module) => module?.module_slug === slug);

  const isAdmin = loggedUser?.hasPermissions([USERS_EDIT]);
  const isUserDeleted = !user?.status;

  return (
    <CardBox
      heading="Zakupione moduły"
      actions={
        <Authorize permissions={[MODULE_USER_LOGS_OWN, MODULE_USER_LOGS_ALL]}>
          <Authorize permissions={[SUBSCRIPTIONS_MODULE_ACCESS]}>
            <Link to={getPathUrl(ROUTE_ACCESS_HISTORY, { id: user?.id })}>
              <span className="link-with-border">SPRAWDŹ HISTORIĘ DOSTĘPÓW</span>
            </Link>
          </Authorize>
        </Authorize>
      }
      paddingLeft="30px"
      paddingTop="25px"
      paddingRight="30px"
      paddingBottom="0"
      childrenPaddingTop="0"
    >
      {displayCreateModal && (
        <ModuleCreateModal
          module={displayCreateModal.module}
          message={message}
          setErrors={setErrors}
          setMessage={setMessage}
          errors={errors}
          isOpen={!!displayCreateModal}
          toggle={() => setCreateDisplayModal(null)}
          onSubmit={onSubmit}
          loading={uploading}
        />
      )}
      {displayEditModal && (
        <ModalEdit
          module={displayEditModal.module}
          message={message}
          setErrors={setErrors}
          setMessage={setMessage}
          isOpen={!!displayEditModal}
          toggle={() => setEditDisplayModal(null)}
          errors={errors}
          values={displayEditModal.values}
          onSubmit={onSubmit}
          loading={uploading}
        />
      )}
      {fetching && <Loader />}
      <div className="profile-modules flex-column flex-xl-row">
        {moduleList &&
          moduleList.map((moduleValue) => (
            <ModuleDataset
              key={moduleValue?.id}
              module={moduleValue}
              setEditDisplayModal={setEditDisplayModal}
              setCreateDisplayModal={setCreateDisplayModal}
              subscriptionDateTo={findSubscriptionModule(moduleValue?.slug)?.date_to}
              subscriptionDateFrom={findSubscriptionModule(moduleValue?.slug)?.date_from}
              creditsByDates={findCreditModules(moduleValue?.slug)?.by_dates}
              isPublic={moduleValue?.is_public}
              isAdmin={isAdmin}
              isUserDeleted={isUserDeleted}
            />
          ))}
      </div>
    </CardBox>
  );
};

export default ListDetailsModules;
