import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const updateModuleToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja modułów',
  body: 'Moduły został zaktualizowane',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateModuleToastError = (): AddToastPayload => ({
  header: 'Aktualizacja modułów',
  body: 'Nie udało się zaktualizować modułów',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});
