import Axios from 'axios';
import { useEffect, useRef } from 'react';

const useCancelToken = () => {
  const source = useRef(Axios.CancelToken.source());
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      source.current.cancel('AxiosCancel');
    };
  }, []);

  return source.current.token;
};

export default useCancelToken;
