import React, { useEffect } from 'react';
import { createSearchingProps } from 'modules/Shared/helper/params';
import Executor from 'modules/Executor/model/Executor';
import { useDispatch } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
import { breadcrumbExecutorsAdmin } from 'modules/Executor/breadcrumbs';
import { PartialSearchingProps } from 'modules/Shared/type';
import { setExecutorAdminListParamsAction } from 'modules/Executor/action/adminList';
import ExecutorsAdminList from 'modules/Executor/component/AdminList';

export function withAdminListPageManage<P>(WrappedComponent: React.ComponentType<P>) {
  const Component = (props: P) => {
    const dispatch = useDispatch();
    const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
    const setParams = (payload: PartialSearchingProps) => dispatch(setExecutorAdminListParamsAction(payload));

    const searchProps = createSearchingProps(
      '',
      Executor.getAdminFilterableAttributes(),
      Executor.getAdminSortableAttributes()
    );

    useEffect(() => {
      managePage({
        title: 'Baza wykonawców, w stosunku do których mogą wystąpić podstawy do wykluczenia - lista',
        breadcrumb: [breadcrumbExecutorsAdmin()]
      });

      setParams({ ...searchProps });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <WrappedComponent {...props} />;
  };
  return Component;
}

export default withAdminListPageManage(ExecutorsAdminList);
