import { RodoValues } from 'modules/Regulation/type';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { CustomInput, FormFeedback, FormGroup } from 'reactstrap';
import rodoContent from 'modules/Regulation/component/Fieldset/Rodo/rodoContent';

export interface Props {
  errors?: ValidationErrors;
  values: RodoValues;
  className?: string;
  setChange: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;
  disabled?: boolean;
}

export const FieldsetRodo: React.FC<Props> = ({ values, setChange, errors, className, disabled }) => {
  return (
    <div className="fieldset-rodo">
      {Object.entries(rodoContent ?? {}).map(([key, value], index) => (
        <FormGroup
          key={key}
          className={`${className} ${index === Object.entries(rodoContent ?? {}).length - 1 ? 'form-group-last' : ''}`}
          tag="fieldset"
        >
          <CustomInput
            type="checkbox"
            className="rodo-field"
            name={key}
            id={key}
            label={value}
            disabled={disabled}
            checked={values[key as keyof typeof rodoContent]}
            onChange={() => setChange({ ...values, [key]: !values[key as keyof typeof rodoContent] })}
            invalid={hasError(errors, key)}
          />
          {hasError(errors, key) && <FormFeedback>{getError(errors, key)}</FormFeedback>}
        </FormGroup>
      ))}
    </div>
  );
};

export default FieldsetRodo;
