import React, { useState } from 'react';
import Loader from 'modules/Layout/component/Loader';
import { DropdownMenu, FormGroup, Label } from 'reactstrap';
import _debounce from 'lodash/debounce';
import { suffixLabel, suffixValue } from 'modules/Layout/helper/misc';
import InputWithChips from 'modules/Layout/component/Chips/Input';
import '../index.scss';

type Props = {
  currentValue: string;
  onChangeCurrentValue: (valueParam: string) => void;
  searchRequest: (search?: string) => void;
  fetching: boolean;
  required?: boolean;
  disabled?: boolean;
  list: { id: number; name: string; disabled?: boolean }[];
  clearList?: () => void;
  label?: string;
  placeholder?: string;
  alwaysClearInput?: boolean;

  chipContentElements?: { content: string; id: number }[];
  onDeleteChipElement?: (id: number) => void;
};

const AutocompleteWithChips: React.FC<Props> = ({
  currentValue,
  onChangeCurrentValue,
  searchRequest,
  fetching,
  required = false,
  list,
  clearList,
  label,
  alwaysClearInput,
  placeholder,
  ...props
}) => {
  const [value, setValue] = useState(currentValue ?? '');
  const [showDropdown, setShowDropdown] = useState(false);

  // eslint-disable-next-line
  const searchRequestDebounce = React.useMemo(() => _debounce(searchRequest, 500), []);

  const renderSearch = () => {
    const onInputChange = (inputValue: string) => {
      setValue(inputValue);
      searchRequestDebounce(inputValue);
    };

    const onFocus = () => {
      setShowDropdown(true);
      searchRequest();
    };

    return (
      <FormGroup className="p-0">
        {Boolean(label) && <Label>{suffixLabel(label, required)}</Label>}
        <InputWithChips
          value={value}
          required={required}
          onChange={onInputChange}
          onFocus={onFocus}
          placeholder={placeholder ? suffixValue(placeholder, required) : null}
          {...props}
        />
        {/* {error && <FormFeedback>{error.getMessageValue()}</FormFeedback>} */}
      </FormGroup>
    );
  };

  const renderDropdown = () => {
    if (!showDropdown) return null;

    const setCurrentValue = (selectedName: string) => {
      setShowDropdown(false);
      onChangeCurrentValue(selectedName);
      if (alwaysClearInput) setValue('');
      else setValue(selectedName);
    };

    return (
      <DropdownMenu className="d-block w-100" style={{ overflowX: 'hidden', maxHeight: '300px', overflowY: 'auto' }}>
        {fetching && <Loader size="50px" />}
        {currentValue && (
          <button
            type="button"
            className="current-value dropdown-item value d-flex align-items-center justify-content-between"
            onClick={() => setCurrentValue('')}
          >
            <div>{currentValue}</div>
            <span className="hint">Usuń</span>
          </button>
        )}
        {list.length === 0 && <div className="dropdown-item no-results">Brak wyników.</div>}
        {list.map((option) =>
          option.name === currentValue ? null : (
            <button
              key={option.id || option.name}
              type="button"
              className="dropdown-item d-flex align-items-center justify-content-between"
              onClick={() => setCurrentValue(option.name)}
              disabled={false}
            >
              <div>{option.name}</div>
              <span className="hint">Wybierz</span>
            </button>
          )
        )}
      </DropdownMenu>
    );
  };

  const onBlur = (event: React.ChangeEvent) => {
    const { currentTarget } = event;

    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setValue(currentValue);
        setShowDropdown(false);
        if (clearList) clearList();
      }
    }, 0);
  };

  return (
    <div className="autocomplete position-relative" onBlur={onBlur}>
      {renderSearch()}
      {renderDropdown()}
    </div>
  );
};

export default AutocompleteWithChips;
