import { Message } from 'modules/Shared/type';
import { PaymentEndValues } from 'modules/Subscriptions/type';

export interface SubscriptionsPaymentState {
  session_id: string;
  loading: boolean;
  details: PaymentEndValues;
  message: Message;
  errorMessage?: Message;
}

export const initSubscriptionsPaymentState = (): SubscriptionsPaymentState => ({
  session_id: '',
  loading: false,
  details: null,
  message: null
});
