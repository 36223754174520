import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { breadcrumbRouteOfficeImports } from 'modules/Office/breadcrumbs';
import { Message, PaginationMeta, SearchParams, ValidationErrors } from 'modules/Shared/type';
import { OfficeImportsListValues } from 'modules/Office/model/Imports';
import { fetchImports, importOfficeExcelFile } from 'modules/Office/repository';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import updateRequest from 'modules/Shared/helper/APIRequests/updateRequest';
import { serialize } from 'object-to-formdata';
import { officeExcelFileImportToastSuccess } from 'modules/Office/toasts';
import _ from 'lodash';
import axios, { CancelTokenSource } from 'axios';
import useSendPeriodicallyRequest from 'modules/Shared/helper/hooks/useSendPeriodicallyRequest';
import Alert from 'modules/Layout/component/Alert';
import Listing from 'modules/Layout/component/Listing';
import PaginationTable from 'modules/Layout/component/Pagination/WithProps';
import PerPageTable from 'modules/Layout/component/Pagination/WithProps/PerPage';
import OfficeImportExcelModal from 'modules/Office/component/Modal/ImportExcel';
import OfficeImportsTable from 'modules/Office/container/ImportList/Table';
import { Button } from 'reactstrap';

const OfficeImports = () => {
  const dispatch = useDispatch();
  const [officeImportsList, setOfficeImportsList] = useState<OfficeImportsListValues[]>([]);
  const [meta, setMeta] = useState<PaginationMeta>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [error, setError] = useState<ValidationErrors>(null);
  const [displayImportModal, setDisplayImportModal] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [searchParams, setSearchParams] = useState<SearchParams>(null);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  const dispatchOrderFileImportToast = () => dispatch(addToastAction(officeExcelFileImportToastSuccess()));

  const cancelToken: React.MutableRefObject<CancelTokenSource> = useRef(null);

  const fetchOfficeImportsRequest = async (payload?: SearchParams) => {
    if (!_.isEqual(searchParams, payload)) {
      setSearchParams(payload);
    }

    if (cancelToken.current) {
      cancelToken.current.cancel('Operation canceled due to new request.');
    }

    cancelToken.current = axios.CancelToken.source();

    const {
      data,
      cancelled,
      message: messageResponse
    } = await fetchRequest(fetchImports, payload, cancelToken.current.token);

    if (cancelled) return;
    if (data) {
      setOfficeImportsList(data.data);
      setMeta(data.meta);
      setLoading(false);
    }
    if (messageResponse && messageResponse.value !== 'Operation canceled due to new request.') {
      if (loading) setLoading(false);
      setMessage(messageResponse);
    }
  };

  const fetchOfficeImports = async (payload?: SearchParams) => {
    setLoading(true);
    await fetchOfficeImportsRequest(payload);
  };

  useEffect(() => {
    managePage({
      title: 'Importuj pliki - lista',
      breadcrumb: breadcrumbRouteOfficeImports()
    });

    fetchOfficeImports();

    return () => {
      if (cancelToken.current) {
        cancelToken.current.cancel('AxiosCancel');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isStatusAwaiting = officeImportsList?.findIndex((officeImport) => officeImport?.status === 1) !== -1;

  useSendPeriodicallyRequest(
    fetchOfficeImportsRequest.bind(this, searchParams),
    isStatusAwaiting,
    cancelToken.current,
    officeImportsList
  );

  const importOfficeExcelFileRequest = async (file: File) => {
    setUploading(true);

    cancelToken.current = axios.CancelToken.source();

    const {
      message: resMessage,
      error: resError,
      cancelled,
      success
    } = await updateRequest(importOfficeExcelFile, serialize({ file }), cancelToken.current.token);

    if (cancelled) return;
    if (success) {
      dispatchOrderFileImportToast();
      setDisplayImportModal(false);
      fetchOfficeImports();
    }
    if (resMessage) setMessage(resMessage);
    if (resError) setError(resError);
    setUploading(false);
  };

  const resetErrors = () => {
    setMessage(null);
    setError(null);
  };

  const onSubmit = (file: File) => importOfficeExcelFileRequest(file);

  return (
    <>
      {displayImportModal && (
        <OfficeImportExcelModal
          onSubmit={onSubmit}
          fetching={uploading}
          isOpen={displayImportModal}
          errors={error}
          message={message}
          toggle={() => setDisplayImportModal(false)}
          resetErrors={resetErrors}
          maxSizeInMb={50}
        />
      )}
      <div className="row users-view">
        <div className="col-12">
          <Alert message={message} />
          <Listing
            table={<OfficeImportsTable officeImportsList={officeImportsList} />}
            pagination={<PaginationTable fetchList={fetchOfficeImports} meta={meta} />}
            perPage={<PerPageTable fetchList={fetchOfficeImports} meta={meta} />}
            loading={loading}
            meta={meta}
            childrenNextToPagination={
              <Button
                color="link"
                className="link-with-border table-list-action p-0"
                onClick={() => setDisplayImportModal(true)}
              >
                Importuj pliki
              </Button>
            }
          />
        </div>
      </div>
    </>
  );
};

export default OfficeImports;
