import React from 'react';
import { RootState } from 'app/reducer';
import ListingPagination from 'modules/Layout/component/Listing/Pagination';
import { PartialSearchingProps } from 'modules/Shared/type';
import { useDispatch, useSelector } from 'react-redux';
import { setKeywordsWithCategoriesListParamsAction } from 'modules/Judgement/action/KeywordsWithCategoriesList';
import { ROUTE_KEYWORDS_WITH_CATEGORIES } from 'modules/Judgement/routes';

const KeywordsWithCategoriesListPagination: React.FC = (props) => {
  const dispatch = useDispatch();
  const { meta, pagination, filter, sort } = useSelector((state: RootState) => state.judgement.keywordList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setKeywordsWithCategoriesListParamsAction(payload));

  return (
    <ListingPagination
      meta={meta}
      pagination={pagination}
      sort={sort}
      setParams={setParams}
      filter={filter}
      path={ROUTE_KEYWORDS_WITH_CATEGORIES}
      {...props}
    />
  );
};

export default KeywordsWithCategoriesListPagination;
