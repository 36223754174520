import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PaginationParams, PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import React from 'react';
import { useDispatch } from 'react-redux';
import { FilterInputType } from 'modules/Layout/type';
import { Dispatch } from 'redux';

export interface Props {
  values?: SearchParams;
  categoryList: { filter: SearchParams; pagination: PaginationParams };
  reduxAction: (payload: PartialSearchingProps) => (dispatch: Dispatch, getState: () => RootState) => Promise<void>;
}

const JudgementCategoryFilter: React.FC<Props> = ({ values = {}, categoryList, reduxAction }) => {
  const dispatch = useDispatch();
  const { filter, pagination } = categoryList;
  const setParams = (payload: PartialSearchingProps) => dispatch(reduxAction(payload));

  const inputsBase: FilterInputType[] = [
    {
      type: 'text',
      property: 'name',
      label: 'Nazwa',
      value: _get(values, 'name')
    }
  ];

  return <ListingFilter inputs={inputsBase} filter={filter} pagination={pagination} setParams={setParams} />;
};

export default JudgementCategoryFilter;
