import React, { Dispatch, SetStateAction } from 'react';
import StepFieldCheckbox from 'modules/Agreements/components/StepField/Checkbox';
import StepFieldEditableCheckbox from 'modules/Agreements/components/StepField/EditableCheckbox';
import StepFieldSwitch from 'modules/Agreements/components/StepField/Switch';
import StepFieldContent from 'modules/Agreements/components/StepField/Content';
import StepFieldPartCheckbox from 'modules/Agreements/components/StepField/PartCheckbox';
import StepFieldPartEditableCheckbox from 'modules/Agreements/components/StepField/PartEditableCheckbox';
import StepFieldPartSwitch from 'modules/Agreements/components/StepField/PartSwitch';
import StepFieldPartContent from 'modules/Agreements/components/StepField/PartContent';
import StepFieldCheckboxWithNumberInput from 'modules/Agreements/components/StepField/CheckboxWithNumberInput';
import StepFieldPartCheckboxWithNumberInput from 'modules/Agreements/components/StepField/PartCheckboxWithNumberInput';
import AgreementsCustomCheckboxes from 'modules/Agreements/components/CustomCheckboxes';
import { ValidationErrors } from 'modules/Shared/type';
import { AgreementCheckboxes } from 'modules/Agreements/type';
import StepFieldCheckboxWithBankNumberInput from '../components/StepField/CheckboxWithBankNumberInput';

export interface Props<T> {
  stepValues: T;
  setStepValues: Dispatch<SetStateAction<T>>;
  mapState: (values: T) => T;
  errors: ValidationErrors | null;
  partSlug?: string | null;
  initContentValues?: Record<string, string>;
}

const useStepFields = <T extends { checkboxes?: AgreementCheckboxes | null }>(props: Props<T>) => {
  const { stepValues, setStepValues, mapState, errors, partSlug, initContentValues } = props;

  const renderCheckbox = (checkbox: string, label: string, disabled?: boolean) => {
    return (
      <StepFieldCheckbox
        checkbox={checkbox}
        label={label}
        stepValues={stepValues}
        setStepValues={setStepValues}
        mapState={mapState}
        errors={errors}
        disabled={disabled}
      />
    );
  };

  const renderEditableCheckbox = (
    checkbox: string,
    getRevertValue?: () => string,
    disableCheckbox?: boolean,
    disableEditing?: boolean
  ) => {
    return (
      <StepFieldEditableCheckbox
        checkbox={checkbox}
        stepValues={stepValues}
        setStepValues={setStepValues}
        mapState={mapState}
        errors={errors}
        initContentValues={initContentValues}
        getRevertValue={getRevertValue}
        disableCheckbox={disableCheckbox}
        isEditDisabled={disableEditing}
      />
    );
  };

  const renderSwitch = (switchKey: string, label: string, disabled?: boolean) => {
    return (
      <StepFieldSwitch
        switchKey={switchKey}
        label={label}
        stepValues={stepValues}
        setStepValues={setStepValues}
        mapState={mapState}
        errors={errors}
        disabled={disabled}
      />
    );
  };

  const renderContent = (contentKey: string, getRevertValue?: () => string, disabled?: boolean) => {
    return (
      <StepFieldContent
        contentKey={contentKey}
        stepValues={stepValues}
        setStepValues={setStepValues}
        mapState={mapState}
        errors={errors}
        initContentValues={initContentValues}
        getRevertValue={getRevertValue}
        isEditDisabled={disabled}
      />
    );
  };

  const renderPartCheckbox = (
    checkbox: string,
    label: string,
    index: number,
    part: Record<any, any>,
    disabled?: boolean
  ) => {
    return (
      <StepFieldPartCheckbox
        checkbox={checkbox}
        label={label}
        index={index}
        part={part}
        setStepValues={setStepValues}
        mapState={mapState}
        errors={errors}
        disabled={disabled}
        partSlug={partSlug}
      />
    );
  };

  const renderPartEditableCheckbox = (
    checkbox: string,
    index: number,
    part: Record<any, any>,
    disableCheckbox?: boolean,
    disableEdit?: boolean
  ) => {
    return (
      <StepFieldPartEditableCheckbox
        checkbox={checkbox}
        index={index}
        part={part}
        setStepValues={setStepValues}
        mapState={mapState}
        errors={errors}
        partSlug={partSlug}
        initContentValues={initContentValues}
        disableCheckbox={disableCheckbox}
        disableEdit={disableEdit}
      />
    );
  };

  const renderPartSwitch = (
    switchKey: string,
    label: string,
    index: number,
    part: Record<any, any>,
    disabled?: boolean
  ) => {
    return (
      <StepFieldPartSwitch
        switchKey={switchKey}
        label={label}
        index={index}
        part={part}
        setStepValues={setStepValues}
        mapState={mapState}
        errors={errors}
        partSlug={partSlug}
        disabled={disabled}
      />
    );
  };

  const renderPartContent = (contentKey: string, index: number, part: Record<any, any>, disabled?: boolean) => {
    return (
      <StepFieldPartContent
        contentKey={contentKey}
        index={index}
        part={part}
        setStepValues={setStepValues}
        mapState={mapState}
        errors={errors}
        partSlug={partSlug}
        initContentValues={initContentValues}
        isEditDisabled={disabled}
      />
    );
  };

  const renderCheckboxWithNumberInput = (
    checkbox: string,
    label: string,
    disabled?: boolean,
    decimalPlaces?: boolean,
    disabledInputField?: boolean
  ) => {
    return (
      <StepFieldCheckboxWithNumberInput
        checkbox={checkbox}
        label={label}
        stepValues={stepValues}
        setStepValues={setStepValues}
        mapState={mapState}
        errors={errors}
        disabled={disabled}
        decimalPlaces={decimalPlaces}
        disabledInputField={disabledInputField}
      />
    );
  };

  const renderCheckboxWithBankNumberInput = (checkbox: string, label: string, disabled?: boolean) => {
    return (
      <StepFieldCheckboxWithBankNumberInput
        checkbox={checkbox}
        label={label}
        stepValues={stepValues}
        setStepValues={setStepValues}
        mapState={mapState}
        errors={errors}
        disabled={disabled}
      />
    );
  };

  const renderPartCheckboxWithNumberInput = (
    checkbox: string,
    label: string,
    index: number,
    part: Record<any, any>,
    disabled?: boolean,
    decimalPlaces?: boolean
  ) => {
    return (
      <StepFieldPartCheckboxWithNumberInput
        checkbox={checkbox}
        label={label}
        index={index}
        part={part}
        setStepValues={setStepValues}
        mapState={mapState}
        errors={errors}
        partSlug={partSlug}
        disabled={disabled}
        decimalPlaces={decimalPlaces}
      />
    );
  };

  const renderCustomCheckboxes = (
    tagName: string = 'general',
    partId?: number | null,
    hideAdd: boolean = false,
    hideDelete: boolean = false,
    disabled?: boolean
  ) => {
    return (
      <AgreementsCustomCheckboxes
        checkboxes={stepValues.checkboxes}
        tagName={tagName}
        partId={partId}
        onChange={(checkboxes: any) => {
          stepValues.checkboxes = checkboxes;
          setStepValues((values) => mapState(values));
        }}
        errors={errors}
        hideAdd={hideAdd}
        hideDelete={hideDelete}
        disabled={disabled}
      />
    );
  };

  return {
    renderCheckbox,
    renderEditableCheckbox,
    renderSwitch,
    renderContent,
    renderPartCheckbox,
    renderPartEditableCheckbox,
    renderPartSwitch,
    renderPartContent,
    renderCheckboxWithNumberInput,
    renderPartCheckboxWithNumberInput,
    renderCustomCheckboxes,
    renderCheckboxWithBankNumberInput
  };
};

export default useStepFields;
