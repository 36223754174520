import { ValidationErrors } from 'modules/Shared/type';
import React, { useCallback } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import moment from 'moment/moment';
import {
  ExecutorViolation,
  ExecutorViolationSlug,
  getViolationWysiwygTitle
} from '../../../../Executor/model/Violation';
import useWysiwyg from '../../../../Layout/component/Wysiwyg';
import { getError, hasError } from '../../../../Shared/helper/validation';
import { suffixLabel } from '../../../../Layout/helper/misc';
import { DATE_FORMAT_SLASH } from '../../../../Shared/helper/utils';
import AddFiles from '../../../../Shared/helper/Files/AddFiles';

export interface Props {
  values: ExecutorViolation;
  isEditForm: boolean;
  errors?: ValidationErrors;
  disabled?: boolean;
  slug?: ExecutorViolationSlug;
  required?: boolean;
  setValues?: React.Dispatch<React.SetStateAction<ExecutorViolation>>;
}

const ViolationFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, setValues, slug, errors, disabled, required, isEditForm } = props;

  const { authority_name, violation_date, violation_description } = values;

  const { displayWysiwyg: displayViolationWysiwyg } = useWysiwyg({
    title: `Przyczyny ${getViolationWysiwygTitle(slug)}`,
    content: violation_description,
    tooltip: { specialSign: true },
    onChange: (content) =>
      setValues((prevState) => {
        return {
          ...prevState,
          violation_description: content
        };
      })
  });

  const handleViolationDateChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newDate = event.target.value;
      if (setValues) {
        setValues((prevState) => ({ ...prevState, violation_date: newDate }));
      }
    },
    [setValues]
  );

  const handleAuthorityNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newName = event.target.value;
      if (setValues) {
        setValues((prevState) => ({ ...prevState, authority_name: newName }));
      }
    },
    [setValues]
  );

  return (
    <div className="row">
      <div className="col-12">
        <div className="flex-row gap-2 d-flex justify-content-between align-items-start">
          <fieldset className="m-0 flex-grow-1" disabled={disabled}>
            <FormGroup>
              {displayViolationWysiwyg && displayViolationWysiwyg()}

              {hasError(errors, `violation.violation_description`) && (
                <FormFeedback className="d-block">{getError(errors, `violation.violation_description`)}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="violation_date">{suffixLabel('Data naliczenia kary umownej', required)}</Label>
              <Input
                type="text"
                name="violation_date"
                id="violation_date"
                placeholder={DATE_FORMAT_SLASH}
                value={violation_date}
                onChange={handleViolationDateChange}
                invalid={hasError(errors, `violation.violation_date`)}
              />
              {violation_date && !moment(violation_date, DATE_FORMAT_SLASH, true).isValid() && (
                <FormFeedback className="d-block">Obsługiwany format: {DATE_FORMAT_SLASH}.</FormFeedback>
              )}
              {hasError(errors, `violation.violation_date`) && (
                <FormFeedback className="d-block">{getError(errors, `violation.violation_date`)}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for={`authority_name `}>{suffixLabel('Nazwa organu', required)}</Label>
              <Input
                type="text"
                name="authority_name"
                id="authority_name"
                placeholder="Nazwa organu"
                value={authority_name}
                onChange={handleAuthorityNameChange}
                invalid={hasError(errors, `violation.authority_name`)}
              />
              {hasError(errors, `violation.authority_name`) && (
                <FormFeedback className="d-block">{getError(errors, `violation.authority_name`)}</FormFeedback>
              )}
            </FormGroup>
            {!isEditForm && (
              <FormGroup>
                <AddFiles values={values as any} setValues={setValues} objKey="files" maxSizeInMb={50} />
              </FormGroup>
            )}
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default ViolationFieldset;
