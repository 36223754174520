import React from 'react';
import { Button, FormGroup } from 'reactstrap';
import CheckboxInput from 'modules/Layout/component/Input/Checkbox';
import { ValidationErrors } from 'modules/Shared/type';
import { getError } from 'modules/Shared/helper/validation';

export interface AgreementsCustomCheckbox {
  checked: boolean;
  content: string | null;
  isNewlyAdded: boolean | false;
  part_id: number | null;
}

export interface Props {
  // eslint-disable-next-line react/require-default-props
  tagName?: string;
  checkboxes: any;
  partId?: number | null;
  onChange: (checkboxes: any) => void;
  // eslint-disable-next-line react/require-default-props
  errors?: ValidationErrors | null;
  hideDelete?: boolean;
  hideAdd?: boolean;
  disabled?: boolean;
}

const AgreementsCustomCheckboxes = (props: Props): JSX.Element => {
  const {
    tagName = 'general',
    checkboxes,
    partId,
    onChange,
    errors,
    hideDelete = false,
    hideAdd = false,
    disabled
  } = props;

  const onAdd = () => {
    const newCheckboxObj = { checked: true, content: '', isNewlyAdded: true, part_id: partId };

    const checkboxesTagValues = checkboxes[tagName] ? checkboxes[tagName] : [];

    const newCheckboxes = {
      ...checkboxes,
      [tagName]: [...checkboxesTagValues, newCheckboxObj]
    };

    onChange(newCheckboxes);
  };

  const onEdit = (checked: boolean, content: string, index: number) => {
    const newCheckboxes = { ...checkboxes };

    newCheckboxes[tagName][index].checked = checked;
    newCheckboxes[tagName][index].content = content;

    onChange(newCheckboxes);
  };

  const onDelete = (index: number) => {
    const newCheckboxes = { ...checkboxes };

    newCheckboxes[tagName] = newCheckboxes[tagName].filter((_: any, i: number) => i !== index);

    onChange(newCheckboxes);
  };

  const renderCheckbox = (checkbox: AgreementsCustomCheckbox, index: number) => {
    const { checked, content, isNewlyAdded } = checkbox;

    const id = `${index}_${tagName}_custom_input`;

    return (
      <FormGroup key={id}>
        <CheckboxInput
          id={id}
          checked={checked}
          content={content}
          fallbackTitle="Wprowadź tekst"
          isEdit={isNewlyAdded}
          onChange={(newChecked, newContent) => onEdit(newChecked, newContent, index)}
          onDelete={() => onDelete(index)}
          hideDelete={hideDelete}
          hideRevert
          disableCheckbox={disabled}
          disableEdit={disabled}
          error={getError(errors, `checkboxes.${tagName}.${index}.content`) ? 'Pole jest wymagane.' : null}
        />
      </FormGroup>
    );
  };

  const renderCheckboxes = () => {
    if (!checkboxes || !checkboxes[tagName]) {
      return null;
    }

    return checkboxes[tagName].map((checkbox: AgreementsCustomCheckbox, index: number) => {
      if (partId !== checkbox.part_id) return null;

      return renderCheckbox(checkbox, index);
    });
  };

  const renderAddButton = () => {
    return (
      <div className="agreement-form-actions">
        <Button type="button" color="primary" onClick={onAdd}>
          Dodaj pozycję
        </Button>
      </div>
    );
  };

  return (
    <>
      {renderCheckboxes()}
      {!hideAdd && renderAddButton()}
    </>
  );
};

export default AgreementsCustomCheckboxes;
