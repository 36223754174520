import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import DetailsComponent from 'modules/Office/component/Details/ExecutorRequest';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message, ValidationErrors } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import { breadcrumbRouteExecutorRequest } from 'modules/Office/breadcrumbs';
import { executorRequestFileDownloadToastSuccess, executorRequestReceivedToastSuccess } from 'modules/Office/toasts';
import { OfficeExecutorRequestReceivedValueSend, OfficeExecutorRequestValue } from 'modules/Office/type';
import ExecutorRequestReceivedModal from 'modules/Office/component/Modal/ExecutorRequestReceived';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import {
  fetchExecutorRequestDetails,
  executorRequestReceived,
  fetchExecutorRequestFile
} from 'modules/Office/repository';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import fetchFileRequest from 'modules/Shared/helper/APIRequests/fetchFileRequest';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_OFFICE } from 'modules/Office/routes';

const ExecutorRequest = () => {
  const dispatch = useDispatch();
  const { id, request_id } = useParams<{ id: string; request_id: string }>();
  const [executorRequest, setExecutorRequest] = useState<OfficeExecutorRequestValue>(null);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatchExecutorRequestReceivedToast = () => dispatch(addToastAction(executorRequestReceivedToastSuccess()));
  const dispatchExecutorRequestFileDownloadToast = () =>
    dispatch(addToastAction(executorRequestFileDownloadToastSuccess()));

  const cancelToken = useCancelToken();
  useEffect(() => {
    dispatch(
      managePageAction({
        title: 'Zapytania - szczegóły',
        breadcrumb: breadcrumbRouteExecutorRequest(id)
      })
    );

    (async () => {
      setFetching(true);
      const {
        data,
        cancelled,
        message: messageResponse
      } = await fetchRequest(fetchExecutorRequestDetails, id, request_id, cancelToken);

      if (cancelled) return;
      if (data) setExecutorRequest(data.data);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (executorRequest)
      dispatch(
        managePageAction({
          title: 'Zapytania - szczegóły',
          breadcrumb: breadcrumbRouteExecutorRequest(id, executorRequest.office_name)
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executorRequest]);

  const executorRequestReceivedRequest = async (data: OfficeExecutorRequestReceivedValueSend) => {
    setLoading(true);

    if (message) setMessage(null);
    if (errors) setErrors(null);

    const {
      cancelled,
      message: messageRes,
      data: responseData,
      error
    } = await updateRequestWithData(executorRequestReceived, id, request_id, data, cancelToken);

    if (cancelled) return;
    if (responseData) {
      dispatchExecutorRequestReceivedToast();
      setExecutorRequest(responseData.data);
      setDisplayModal(false);
    }
    if (messageRes) setMessage(messageRes);
    if (error) setErrors(error);
    setLoading(false);
  };

  const executorRequestDownloadFileRequest = async (fileId: number | string) => {
    setLoading(true);

    const {
      cancelled,
      message: messageRes,
      success
    } = await fetchFileRequest(fetchExecutorRequestFile, id, request_id, fileId, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchExecutorRequestFileDownloadToast();
    } else {
      if (messageRes) setMessage(messageRes);
      setLoading(false);
    }
  };

  const redirectUrl = getPathUrl(ROUTE_OFFICE, { id });

  if (fetching) return <Loader />;
  if (!fetching && !executorRequest && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  if (!fetching && executorRequest) {
    return (
      <div className="row user-details-view position-relative">
        {displayModal && (
          <ExecutorRequestReceivedModal
            isOpen={displayModal}
            loading={loading}
            onExecutorRequestReceived={executorRequestReceivedRequest}
            toggle={() => setDisplayModal(false)}
            message={message}
            errors={errors}
          />
        )}
        <DetailsComponent
          executorRequest={executorRequest}
          showModal={() => setDisplayModal(true)}
          downloadFileRequest={executorRequestDownloadFileRequest}
          redirectUrl={redirectUrl}
        />
      </div>
    );
  }

  return null;
};

export default ExecutorRequest;
