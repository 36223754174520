import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import { ComplaintDataItem, OrderEntity } from 'modules/ExecutorOrder/model/Order';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';

export interface Props {
  order?: OrderEntity;
  complaint?: ComplaintDataItem;
}

const ExecutorDataClientComplaintDataset: React.FC<Props> = ({ complaint = {}, order }) => {
  const fields = [
    {
      label: 'Podmiot udzielający informacji publicznej (zamawiający)',
      value: <strong>{order.principal_name || 'nie dotyczy'}</strong>
    },
    {
      label: 'Przyczyny złożenia pozwu',
      value: complaint.violation_description || 'nie dotyczy',
      html: true
    },
    {
      label: 'Sygnatura sprawy sądowej',
      value: complaint.complaint_identifier || 'nie dotyczy'
    },
    {
      label: 'Data złożenia pozwu',
      value: ifContainsDateFormat(complaint.violation_date, formatDate) || 'nie dotyczy'
    },
    {
      label: 'Nr umowy o udzielenie zamówienia',
      value: order.contract_number || 'nie dotyczy'
    },
    {
      label: 'Informacja o wyniku postępowania sądowego',
      value: complaint.complaint_result || 'nie dotyczy',
      html: true
    }
  ];

  return <InformationList fields={fields} />;
};

export default ExecutorDataClientComplaintDataset;

// import React from 'react';
// import InformationList from 'modules/Layout/component/InformationList';
// import { OrderEntity } from 'modules/ExecutorOrder/model/Order';

// export interface Props {
//   complaints?: OrderEntity[];
// }

// const ExecutorDataClientComplaintDataset: React.FC<Props> = ({ complaints = [] }) => {
//   const fields = (complaint: OrderEntity) => [
//     {
//       label: 'Podmiot udzielający informacji publicznej (zamawiający):',
//       value: complaint.principal_name || 'nie dotyczy'
//     },
//     {
//       label: 'Przyczyny złożenia pozwu:',
//       value: complaint.complaint_description || 'nie dotyczy',
//       html: true
//     },
//     {
//       label: 'Sygnatura sprawy sądowej:',
//       value: complaint.complaint_identifier || 'nie dotyczy'
//     },
//     {
//       label: 'Data złożenia pozwu:',
//       value: complaint.complaint_date || 'nie dotyczy'
//     },
//     {
//       label: 'Nr umowy o udzielenie zamówienia:',
//       value: complaint.contract_number || 'nie dotyczy'
//     },
//     {
//       label: 'Informacja o wyniku postępowania sądowego:',
//       value: complaint.complaint_result || 'nie dotyczy',
//       html: true
//     }
//   ];

//   if (!complaints.length) return <p>Nie dotyczy</p>;
//   return (
//     <>
//       {complaints.map((complaint) => (
//         <div className="border-except-last" key={complaint.identifier}>
//           <div className="h4 mbnie dotyczy2">Nazwa postępowania - {complaint.name}</div>
//           <InformationList fields={fields(complaint)} />
//         </div>
//       ))}
//     </>
//   );
// };

// export default ExecutorDataClientComplaintDataset;
