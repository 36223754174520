import React from 'react';
import { getFieldForPreview } from 'modules/Agreements/helper/agreement';
import classNames from 'classnames';

export interface PreviewFieldCheckboxWithNumberInputProps {
  step: any;
  modifiedStep: any;
  checkedKey: string;
  contentKey: string;
  label: string;
  // eslint-disable-next-line react/require-default-props
  className?: string;
}

const PreviewFieldCheckboxWithNumberInput = (props: PreviewFieldCheckboxWithNumberInputProps): JSX.Element => {
  const { step, modifiedStep, checkedKey, contentKey, label, className } = props;
  const { value: checked, modified: checkedModified } = getFieldForPreview(step, modifiedStep, checkedKey);
  const { value: content, modified: contentModified } = getFieldForPreview(step, modifiedStep, contentKey);

  if (checked) {
    return (
      <p
        key={`${checkedKey}-${contentKey}`}
        className={classNames('wysiwyg-preview', className, { modified: checkedModified || contentModified })}
        dangerouslySetInnerHTML={{
          __html: String(label.replace('...', content || '...'))
        }}
      />
    );
  }

  return null;
};

export default PreviewFieldCheckboxWithNumberInput;
