import React from 'react';
import './index.scss';
import { ExecutorExtendedRequest } from '../../../Executor/model/Violation';
// eslint-disable-next-line import/no-duplicates
import ExecutorExtendedRequestDetailsBasic from './Basic';

type Props = {
  executorExtendedRequest: ExecutorExtendedRequest;
  downloadFileRequest?: (fileId: number | string) => Promise<void>;
};

const ExecutorExtendedRequestDetailsComponent: React.FC<Props> = ({ executorExtendedRequest, downloadFileRequest }) => {
  return (
    <div className="col-12 user-details-component">
      <div className="row">
        <div className="col-lg-6 user-data">
          <div className="user-data-card">
            <ExecutorExtendedRequestDetailsBasic
              downloadFileRequest={downloadFileRequest}
              executorExtendedRequest={executorExtendedRequest}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExecutorExtendedRequestDetailsComponent;
