import axios, { CancelTokenSource } from 'axios';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { fetchJudgementKeywordsToastError } from 'modules/Judgement/toasts';
import { addToastAction } from 'modules/Layout/action';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { createSearchParams } from 'modules/Shared/helper/params';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import AutocompleteWithChips from 'modules/Layout/component/Autocomplete/WithChips';
import { fetchJudgmentsCategories } from 'modules/Judgement/repository';
import renameDuplicateEntries from 'modules/Shared/helper/renameDuplicateEntries';

type Props = {
  categories: { name: string; id: number }[];
  onCategoriesChange: (keywordsValue: { name: string; id: number }) => void;
  alwaysClearInput?: boolean;
  required?: boolean;

  chipContentElements?: { content: string; id: number }[];
  onDeleteChipElement?: (id: number) => void;
};

// eslint-disable-next-line react/display-name
const JudgmentInputCategoriesAutocomplete: React.FC<Props> = React.memo(
  ({ categories, onCategoriesChange, ...props }) => {
    const dispatch = useDispatch();
    const [fetching, setFetching] = useState(false);
    const [categoriesList, setCategoryList] = useState<JudgementElementEntity[]>([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const categoriesListWithoutDuplicates = React.useMemo(
      () => renameDuplicateEntries(categoriesList),
      [categoriesList.length]
    );

    const dispatchClauseKeywordsToastError = (messageValue: string) =>
      dispatch(addToastAction(fetchJudgementKeywordsToastError(messageValue)));

    const cancelToken: React.MutableRefObject<CancelTokenSource> = useRef(null);

    const fetchCategoriesList = async (name?: string) => {
      if (cancelToken.current) {
        cancelToken.current.cancel('Operation canceled due to new request.');
      }
      cancelToken.current = axios.CancelToken.source();

      const filter = name ? { name } : {};
      const searchParams = createSearchParams({ filter });

      setFetching(true);

      const { data, cancelled, message } = await fetchRequest(
        fetchJudgmentsCategories,
        searchParams,
        cancelToken.current.token
      );

      if (cancelled) return;
      if (data) {
        setCategoryList(data.data);
        setFetching(false);
      }
      if (message && message.value !== 'Operation canceled due to new request.') {
        if (fetching) setFetching(false);
        dispatchClauseKeywordsToastError(message.value);
      }
    };

    useEffect(() => {
      return () => {
        if (cancelToken.current) {
          cancelToken.current.cancel('AxiosCancel');
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeValue = (name: string) => {
      const findCategory = categoriesListWithoutDuplicates.find((category) => category.name === name);
      if (findCategory) onCategoriesChange({ id: findCategory?.id, name: findCategory?.name });
    };

    const clearList = () => {
      if (cancelToken.current) {
        cancelToken.current.cancel('Operation canceled due to new request.');
      }
    };

    const filterCategoriesList = categoriesListWithoutDuplicates.filter(
      (el) => !categories.find((categoryEl) => categoryEl.id === el.id)
    );

    return (
      <>
        <AutocompleteWithChips
          currentValue=""
          onChangeCurrentValue={onChangeValue}
          searchRequest={fetchCategoriesList}
          fetching={fetching}
          clearList={clearList}
          list={filterCategoriesList as never}
          label="Kategorie"
          {...props}
        />
      </>
    );
  }
);

export default JudgmentInputCategoriesAutocomplete;
