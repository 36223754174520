import categoryListReducer from 'modules/Clause/reducer/categoryList';
import clauseListReducer from 'modules/Clause/reducer/clauseList';
import { combineReducers, Reducer } from 'redux';
import { ClauseState } from 'modules/Clause/state';

const reducer = (): Reducer<ClauseState> => {
  const reducers = {
    categoryList: categoryListReducer,
    clauseList: clauseListReducer
  };

  return combineReducers<ClauseState>(reducers);
};

export default reducer;
