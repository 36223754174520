import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { ExecutorViolation, getViolationWysiwygTitle } from '../../../../Executor/model/Violation';

export interface Props {
  violation: ExecutorViolation;
}

const BasicViolationDataset: React.FC<Props> = ({ violation }) => {
  const fields = [
    {
      label: 'Rodzaj naruszenia',
      value: getViolationWysiwygTitle(violation?.slug)
    },
    {
      label: 'Nazwa organu',
      value: violation?.authority_name
    },
    {
      label: 'Data naruszenia',
      value: ifContainsDateFormat(violation?.violation_date, formatDate)
    },
    {
      label: 'Opis',
      value: violation?.violation_description,
      html: true
    }
  ];

  return <InformationList fields={fields} />;
};

export default BasicViolationDataset;
