import React from 'react';
import { RootState } from 'app/reducer';
import ListingPerPage from 'modules/Layout/component/Listing/PerPage';
import { PartialSearchingProps } from 'modules/Shared/type';
import { setExecutorAdminListParamsAction } from 'modules/Executor/action/adminList';
import { useDispatch, useSelector } from 'react-redux';

const ExecutorAdminPerPage = () => {
  const dispatch = useDispatch();
  const { meta } = useSelector((state: RootState) => state.executor.adminList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setExecutorAdminListParamsAction(payload));

  return <ListingPerPage meta={meta} setParams={setParams} />;
};

export default ExecutorAdminPerPage;
