import React, { useEffect } from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { createSearchingProps } from 'modules/Shared/helper/params';
import { ROUTE_INVOICES } from 'modules/User/routes';
import { useDispatch, useSelector } from 'react-redux';
import { managePageAction } from 'modules/Layout/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { PageProps } from 'modules/Layout/type';
import { breadcrumbRouteInvoicesRequired } from 'modules/User/breadcrumbs';
import InvoiceRequired from 'modules/User/model/Invoice';
import { setInvoiceListParamsAction } from 'modules/User/action/listInvoiceRequired';
import InvoiceRequiredFilter from 'modules/User/container/InvoicesRequiredTable/Filter';
import InvoicesRequiredPagination from 'modules/User/container/InvoicesRequiredTable/Pagination';
import InvoicesRequiredPerPage from 'modules/User/container/InvoicesRequiredTable/PerPage';
import InvoicesRequiredTable from 'modules/User/container/InvoicesRequiredTable/Table';

const InvoicesRequiredList = () => {
  const dispatch = useDispatch();
  const { fetching } = useSelector((state: RootState) => state.user.list);

  const searchProps = createSearchingProps(
    '',
    InvoiceRequired.getFilterableAttributes(),
    InvoiceRequired.getSortableAttributes()
  );

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const setParams = (payload: PartialSearchingProps) => dispatch(setInvoiceListParamsAction(payload));

  useEffect(() => {
    const title = 'Lista transakcji wymagających faktury';

    managePage({
      title,
      breadcrumb: breadcrumbRouteInvoicesRequired()
    });

    setParams(searchProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row users-view">
      <div className="col-12">
        <Listing
          table={<InvoicesRequiredTable />}
          filter={<InvoiceRequiredFilter values={searchProps.filter} />}
          pagination={<InvoicesRequiredPagination path={ROUTE_INVOICES} />}
          perPage={<InvoicesRequiredPerPage />}
          loading={fetching}
        />
      </div>
    </div>
  );
};

export default InvoicesRequiredList;
