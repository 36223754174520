import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/reducer';
import Table, { TableCol } from 'modules/Layout/component/Table';
import KeywordWithCategories from 'modules/Judgement/model/KeywordWithCategories';

const KeywordsWithCategoriesListTable: React.FC = () => {
  const { list } = useSelector((state: RootState) => state.judgement.keywordList);

  const cols: TableCol<KeywordWithCategories>[] = [
    {
      property: 'name',
      label: 'Nazwa'
    },
    {
      property: 'categories',
      label: 'Kategorie'
    }
  ];

  return <Table cols={cols} rows={list} />;
};

export default KeywordsWithCategoriesListTable;
