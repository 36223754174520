import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import Authorize from 'modules/Auth/container/Authorize';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import DownloadIcon from 'modules/Layout/component/Icon/Download';
import { IFile } from '../../../Shared/helper/Files/AddFiles';

type Props = {
  violationFiles: IFile[];
  violationDownloadFileRequest: (fileId: number | string) => Promise<void>;
  onDeleteFile?: (file: IFile) => void;
};

const ExecutorViolationFilesTable: React.FC<Props> = ({
  violationFiles,
  violationDownloadFileRequest,
  onDeleteFile
}) => {
  const cols: TableCol<IFile>[] = [
    {
      property: 'name',
      label: 'Nazwa'
    },
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            <Authorize>
              <ActionUpdate
                className="mx-1"
                title="Pobierz plik"
                label={<DownloadIcon height="20px" />}
                onClick={() => violationDownloadFileRequest(row.id)}
              />
            </Authorize>
            {onDeleteFile && <ActionDelete className="mx-1" title="Usuń plik" onClick={() => onDeleteFile(row)} />}
          </div>
        );
      }
    }
  ];

  return <Table cols={cols} rows={violationFiles} />;
};

export default ExecutorViolationFilesTable;
