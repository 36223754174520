import foreignProceedingsAlertsListReducer from 'modules/ForeignProceedings/reducer/foreignProceedingsAlertsList';
import { combineReducers, Reducer } from 'redux';
import { ForeignProceedingsAlertsState } from '../../state/foreignProceedingsAlerts';

const reducer = (): Reducer<ForeignProceedingsAlertsState> => {
  const reducers = {
    foreignProceedingsAlertsList: foreignProceedingsAlertsListReducer
  };

  return combineReducers<ForeignProceedingsAlertsState>(reducers);
};

export default reducer;
