import React, { Dispatch, SetStateAction } from 'react';
import { CustomInput, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { ValidationErrors } from 'modules/Shared/type';
import { getError, hasError } from 'modules/Shared/helper/validation';

export interface StepFieldCheckboxWithBankNumberInputProps<T> {
  checkbox: string;
  label: string;
  stepValues: T;
  setStepValues: Dispatch<SetStateAction<T>>;
  mapState: (values: T) => T;
  errors?: ValidationErrors | null;
  disabled?: boolean;
}

const StepFieldCheckboxWithBankNumberInput = <T,>(props: StepFieldCheckboxWithBankNumberInputProps<T>): JSX.Element => {
  const { checkbox, label, stepValues, setStepValues, mapState, errors, disabled = false } = props;

  const isInvalid = hasError(errors, `${checkbox}_checked`) || hasError(errors, `${checkbox}_content`);

  const formatBankNumber = (bankNumber: string): string => {
    if (!bankNumber) return '';

    const alphanumeric = bankNumber.slice(0, 35).replace(/[^a-zA-Z0-9]/g, '');

    const groups = alphanumeric.match(/.{1,4}/g) || [];

    let firstGroup = groups.shift() || '';

    const firstTwoLetters = firstGroup.substr(0, 2).replace(/[^a-zA-Z]/g, '');

    const remainingDigits = firstGroup.substr(2).replace(/[^0-9]/g, '');

    const formattedFirstGroup = [firstTwoLetters, remainingDigits].join(' ');

    const formattedNumber = [formattedFirstGroup, ...groups].join(' ');

    return formattedNumber.toUpperCase().trim();
  };

  const getFormattedValue = () => {
    // @ts-ignore
    const bankNumber = stepValues[`${checkbox}_content`] || '';

    return label.replace('...', bankNumber || '...');
  };

  // @ts-ignore
  return (
    <FormGroup key={checkbox}>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-start">
          <CustomInput
            id={checkbox}
            type="checkbox"
            // @ts-ignore
            checked={stepValues[`${checkbox}_checked`]}
            onChange={(event) => {
              setStepValues((prevValues: T) => ({
                ...prevValues,
                [`${checkbox}_checked`]: event.target.checked
              }));
              // @ts-ignore
              setStepValues((values: T) => mapState(values));
            }}
            invalid={isInvalid}
            disabled={disabled}
          />
          <Input
            style={{ width: '200px' }}
            name={`${checkbox}_bank_number`}
            id={`${checkbox}_bank_number`}
            invalid={isInvalid}
            disabled={disabled}
            // @ts-ignore
            value={stepValues[`${checkbox}_content`]}
            onChange={(event) => {
              const inputValue = formatBankNumber(event.target.value);

              setStepValues((prevValues: T) => ({
                ...prevValues,
                [`${checkbox}_content`]: inputValue
              }));
              setStepValues((values: T) => mapState(values));
            }}
            maxLength={35}
          />
          <Label
            className="pl-3"
            style={{ color: isInvalid && '#B7332D' }}
            htmlFor={`${checkbox}_bank_number`}
            dangerouslySetInnerHTML={{
              __html: String(getFormattedValue())
            }}
          />
        </div>
        {isInvalid && (
          <FormFeedback className="d-block">
            {getError(errors, `${checkbox}_checked`) || getError(errors, `${checkbox}_content`)}
          </FormFeedback>
        )}
      </div>
    </FormGroup>
  );
};

export default StepFieldCheckboxWithBankNumberInput;
