import { managePageAction } from 'modules/Layout/action';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import PublicWrapper from 'modules/Layout/component/Wrapper/Public';
import { RootState } from 'app/reducer';
import { endPaymentAction, saveSessionIdAction } from 'modules/Subscriptions/action/payments';
import PaymentPage from 'modules/Subscriptions/component/PaymentPage';
import { ROUTE_SUBSCRIPTION_PAYMENT } from 'modules/Subscriptions/routes';
import { ROUTE_LOGIN } from 'modules/Auth/routes';
import { hasToken } from 'modules/Auth/service';

type Props = { isSuccess?: boolean };

const PaymentPublicHandler: React.FC<Props> = ({ isSuccess }) => {
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const session_id = urlParams.get('session_id');
  const mobile_payment = urlParams.get('mobile_payment');
  const { loading, message, errorMessage } = useSelector((state: RootState) => state.subscriptions.payment);
  const isToken = hasToken();

  // eslint-disable-next-line no-unneeded-ternary
  const isMobile = mobile_payment === '1' ? true : false;

  useEffect(() => {
    dispatch(
      managePageAction({
        title: 'Szczegóły transakcji'
      })
    );

    if (!isToken) {
      if (!session_id) dispatch(push(ROUTE_LOGIN));
      dispatch(endPaymentAction(session_id, isSuccess));
    } else {
      dispatch(saveSessionIdAction(session_id));
      dispatch(push(ROUTE_SUBSCRIPTION_PAYMENT, { isSuccess, isMobile }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PublicWrapper>
      <PaymentPage
        loading={loading}
        message={message || errorMessage}
        isPublic
        isMobile={isMobile}
        isMobileSuccess={isSuccess}
      />
    </PublicWrapper>
  );
};

export default PaymentPublicHandler;
