/* eslint-disable react/no-unused-prop-types */
import React, { forwardRef, useRef } from 'react';
import pl from 'date-fns/locale/pl';
import './index.scss';
import DatePickerLib, { registerLocale } from 'react-datepicker';
import { Button, Input } from 'reactstrap';
import DateIcon from 'modules/Layout/component/Icon/Date';
import { getMonth, getYear, parseISO } from 'date-fns';
import moment from 'moment';
import { DATE_FORMAT_SLASH, DATE_FORMAT_VALUE, formatMoment } from 'modules/Shared/helper/utils';

registerLocale('pl', pl);

export interface Props {
  id?: string;
  name?: string;
  onChange: (date: string) => void;
  value: string;
  required?: boolean;
  placeholder?: string;
  invalid?: boolean;
  disabled?: boolean;
  min?: string;
  max?: string;
}

export interface InputRefProps {
  onClick?: () => void;
  value?: string;
}

export interface CustomHeaderProps {
  date: Date;
  changeMonth: (month: number) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}

const Datepicker: React.FC<Props> = (props: Props): JSX.Element => {
  const { id, onChange, value, required, invalid, disabled, name, placeholder = 'dd/mm/yyyy', min, max } = props;
  const datePickerRef = useRef(null);

  const months = [
    'styczeń',
    'luty',
    'marzec',
    'kwiecień',
    'maj',
    'czerwiec',
    'lipiec',
    'sierpień',
    'wrzesień',
    'październik',
    'listopad',
    'grudzień'
  ];

  // eslint-disable-next-line react/display-name
  // @ts-ignore
  const CustomInput = forwardRef<Input<'text'>, InputRefProps>((inputProps, ref) => (
    <>
      <Input
        {...inputProps}
        autoComplete="off"
        id={id}
        invalid={invalid}
        ref={ref}
        name={name}
        type="text"
        required={required}
      />
      {!invalid && <DateIcon height="34" />}
    </>
  ));

  const renderCustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  }: CustomHeaderProps): JSX.Element => {
    const currentYear = getYear(date);
    const currentMonth = months[getMonth(date)];

    return (
      <div className="datepicker-custom-header d-flex align-items-center justify-content-between">
        <div className="current-year-month">{`${currentMonth} ${currentYear}`}</div>
        <div className="d-flex align-items-center justify-content-between">
          <Button
            color="link"
            className="p-0 change-month increase-month"
            type="button"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            <i className="ti-arrow-up" />
          </Button>
          <Button
            color="link"
            className="p-0 change-month decrease-month"
            type="button"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            <i className="ti-arrow-down" />
          </Button>
        </div>
      </div>
    );
  };

  const hasValue = Boolean(value);
  const hasMin = Boolean(min);
  const hasMax = Boolean(max);

  return (
    <DatePickerLib
      ref={datePickerRef}
      selected={hasValue ? parseISO(value) : null}
      placeholderText={placeholder}
      dateFormat="dd/MM/yyyy"
      locale="pl"
      customInput={<CustomInput />}
      renderCustomHeader={(headerProps: CustomHeaderProps) => renderCustomHeader(headerProps)}
      minDate={hasMin ? parseISO(min) : null}
      maxDate={hasMax ? parseISO(max) : null}
      disabled={disabled}
      // onChange={(date: Date) => onChange(formatMoment(moment(date), DATE_FORMAT_VALUE))}
      onSelect={(date: Date) => onChange(formatMoment(moment(date), DATE_FORMAT_VALUE))}
      onChangeRaw={(event: React.ChangeEvent<HTMLInputElement>) => {
        if (moment(event.target.value, DATE_FORMAT_SLASH, true).isValid())
          onChange(formatMoment(moment(event.target.value, DATE_FORMAT_SLASH), DATE_FORMAT_VALUE));
      }}
      // preventOpenOnFocus
    >
      <div className="additional-actions">
        <Button
          onClick={() => {
            onChange('');
            datePickerRef?.current?.setOpen(false);
          }}
          type="button"
          color="link"
          className="link-with-border p-0"
        >
          WYCZYŚĆ
        </Button>
        <Button
          onClick={() => {
            onChange(formatMoment(moment(), DATE_FORMAT_VALUE));
            datePickerRef?.current?.setOpen(false);
          }}
          type="button"
          color="link"
          className="link-with-border p-0"
        >
          DZISIAJ
        </Button>
      </div>
    </DatePickerLib>
  );
};

export default Datepicker;
