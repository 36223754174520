import React from 'react';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_REWARD, AGREEMENT_STEP_SUBJECT } from 'modules/Agreements/step';
import { AgreementStepRewardValues } from 'modules/Agreements/components/Steps/Default/Reward';
import { AgreementStepSubjectValues } from 'modules/Agreements/components/Steps/Default/Subject';
import {
  BILL_PART_CONTENT,
  BILL_PART_FINAL_CONTENT,
  DEL_REWARD_PAYMENT_TIME_CONTENT,
  DEL_REWARD_PAYMENT_TIME_PARTS_CONTENT,
  REWARD_BANK_ACCOUNT_CONTENT
} from 'modules/Agreements/components/Steps/Deliveries/Reward';
import PreviewFieldContent from 'modules/Agreements/components/PreviewField/Content';
import PreviewFieldCheckboxWithNumberInput from 'modules/Agreements/components/PreviewField/CheckboxWithNumberInput';

const AgreementStepPreviewReward = (props: AgreementStepPreviewProps<AgreementStepRewardValues>): JSX.Element => {
  const { agreement, steps, modifiedStep } = props;
  const reward = getStepValues<AgreementStepRewardValues>(steps, AGREEMENT_STEP_REWARD);
  const { same_subject_for_parts } = getStepValues<AgreementStepSubjectValues>(steps, AGREEMENT_STEP_SUBJECT);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        key={`${checkbox}_checked-${checkbox}_content`}
        step={reward}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const renderCheckboxWithNumberInput = (checkbox: string, label: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckboxWithNumberInput
        step={reward}
        key={`${checkbox}_checked-${checkbox}_content`}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        label={label}
        className={className}
      />
    );
  };

  const renderContent = (contentKey: string, className = 'preview-point') => {
    return (
      <PreviewFieldContent step={reward} modifiedStep={modifiedStep} contentKey={contentKey} className={className} />
    );
  };

  const { value: parts } = getFieldForPreview<AgreementStepRewardValues['reward_parts']>(
    reward,
    modifiedStep,
    'reward_parts'
  );

  const { value: paymentCheckboxes, modified: paymentCheckboxesModified } = getFieldForPreview(
    reward,
    modifiedStep,
    `checkboxes.payment`
  );

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    reward,
    modifiedStep,
    `checkboxes.general`
  );

  const { value: rewardDigitalInvoiceChecked } = getFieldForPreview(
    reward,
    modifiedStep,
    'reward_digital_invoice_checked'
  );

  const { value: invAttachmentsPossibility, modified: invAttachmentsPossibilityModified } = getFieldForPreview(
    reward,
    modifiedStep,
    'inv_attachments_possibility'
  );

  const isMultiple = agreement.few_parts && !same_subject_for_parts;

  const renderBillParts = (partId: number) => {
    const { value: billPartsCheckboxes, modified: billPartsCheckboxesModified } = getFieldForPreview(
      reward,
      modifiedStep,
      `checkboxes.bill_parts`
    );

    if (!billPartsCheckboxes) return null;

    return billPartsCheckboxes.map((item: AgreementsCustomCheckbox) => {
      const { part_id, checked, content } = item;

      if ((partId && part_id !== partId) || !checked) return null;

      return (
        <p
          className={classNames('preview-nested-alpha-point', {
            modified: billPartsCheckboxesModified
          })}
        >
          {BILL_PART_CONTENT.replace('...', content || '...')}
        </p>
      );
    });
  };

  const renderFinalBill = (partId: number) => {
    let { value: billPartsCheckboxes, modified: billPartsCheckboxesModified } = getFieldForPreview(
      reward,
      modifiedStep,
      `checkboxes.bill_parts`
    );

    if (!billPartsCheckboxes) billPartsCheckboxes = [];

    const sum = billPartsCheckboxes.reduce((acc: number, item: AgreementsCustomCheckbox) => {
      if (item.part_id == partId && item.checked && item.content) {
        return acc + parseFloat(item.content);
      }

      return acc;
    }, 0);

    const finalValue = 100 - sum > 0 ? 100 - sum : 0;

    return (
      <p
        className={classNames('preview-nested-alpha-point', {
          modified: billPartsCheckboxesModified
        })}
      >
        {BILL_PART_FINAL_CONTENT.replace('...', String(finalValue))}
      </p>
    );
  };

  const renderParts = () => {
    const formattedParts = parts?.map((part) => {
      const partsIdsWithParent = parts.filter((a) => a.parent_part_id === part.part_id).map((b) => b.part_id);

      return {
        ...part,
        child_parts: agreement.parts_ids.filter((c) => partsIdsWithParent.includes(c.id))
      };
    });

    return formattedParts?.map((part, index: number) => {
      const partObj = agreement.parts_ids.find((item) => item.id === part.part_id);

      if (part.parent_part_id) return null;

      const { value: rewardType, modified: rewardTypeModified } = getFieldForPreview(
        reward,
        modifiedStep,
        `reward_parts.${index}.reward_type`
      );

      const { value: billRulesChecked, modified: billRulesCheckedModified } = getFieldForPreview(
        reward,
        modifiedStep,
        `reward_parts.${index}.bill_rules_checked`
      );

      const { value: rewardCalculationTypeSwitch, modified: rewardCalculationTypeSwitchModified } = getFieldForPreview(
        reward,
        modifiedStep,
        `reward_parts.${index}.reward_calculation_type_switch`
      );

      const { value: rewardCalculationType, modified: rewardCalculationTypeModified } = getFieldForPreview(
        reward,
        modifiedStep,
        `reward_parts.${index}.reward_calculation_type`
      );

      const { value: rewardCalculationTypeEstimationContent } = getFieldForPreview(
        reward,
        modifiedStep,
        `reward_parts.${index}.reward_calculation_type_estimation_content`
      );

      const { value: rewardCalculationTypeTableContent } = getFieldForPreview(
        reward,
        modifiedStep,
        `reward_parts.${index}.reward_calculation_type_table_content`
      );

      const { value: rewardNoChangeTypeSwitch, modified: rewardNoChangeTypeSwitchModified } = getFieldForPreview(
        reward,
        modifiedStep,
        `reward_parts.${index}.reward_no_change_type_switch`
      );

      const { value: rewardNoChangeType, modified: rewardNoChangeTypeModified } = getFieldForPreview(
        reward,
        modifiedStep,
        `reward_parts.${index}.reward_no_change_type`
      );

      const { value: rewardNoChangeTypeEstimationContent } = getFieldForPreview(
        reward,
        modifiedStep,
        `reward_parts.${index}.reward_no_change_type_estimation_content`
      );

      const { value: rewardNoChangeTypeTableContent } = getFieldForPreview(
        reward,
        modifiedStep,
        `reward_parts.${index}.reward_no_change_type_table_content`
      );

      const { value: delRewardPaymentTime } = getFieldForPreview(
        reward,
        modifiedStep,
        `reward_parts.${index}.del_reward_payment_time_checked`
      );

      const { value: delRewardPaymentTimeParts } = getFieldForPreview(
        reward,
        modifiedStep,
        `reward_parts.${index}.del_reward_payment_time_parts_checked`
      );

      const { value: rewardNoChangeTypeOtherContent, modified: rewardNoChangeTypeOtherContentModified } =
        getFieldForPreview(reward, modifiedStep, `reward_parts.${index}.reward_no_change_type_other_content`);

      const { value: rewardCalculationTypeOtherContent, modified: rewardCalculationTypeOtherContentModified } =
        getFieldForPreview(reward, modifiedStep, `reward_parts.${index}.reward_calculation_type_other_content`);

      return (
        <div
          key={`preview_part_wrapper_${index}`}
          className={classNames('agreement-step-preview-content', { 'reset-preview-point': isMultiple })}
        >
          {part.child_parts?.map((childPart) => (
            <>
              {index === 0 && <p className="h5">Postanowienia dla każdego z zadań:</p>}
              <p className="h5">{childPart.getName(index)}:</p>
            </>
          ))}
          {isMultiple && partObj && <div className="h5">{partObj.getName(index)}:</div>}
          {!isMultiple && (
            <p className="h5">
              Postanowienia dla każdego z zadań:{' '}
              {agreement.parts_ids.map((p, i) => (
                <p className="mt-1 mb-0">{p.getName(i)}</p>
              ))}
            </p>
          )}
          {rewardType === 1 && [
            <p
              className={classNames('preview-point', {
                modified: rewardTypeModified
              })}
            >
              Za wykonanie Przedmiotu Umowy wykonawcy przysługuje wynagrodzenie ryczałtowe:
            </p>,
            renderContent(`reward_parts.${index}.reward_values_content`, null),
            renderCheckbox(`reward_parts.${index}.reward_risk_statement`)
          ]}
          {rewardType === 2 && [
            rewardCalculationTypeSwitch && [
              <p
                className={classNames('preview-point', {
                  modified:
                    rewardCalculationTypeSwitchModified ||
                    rewardCalculationTypeModified ||
                    (rewardCalculationType == 3 && rewardCalculationTypeOtherContentModified)
                })}
              >
                <span className="mr-1">
                  Maksymalna wysokość wynagrodzenia należnego wykonawcy zostanie obliczona na podstawie dostaw
                  rzeczywiście zrealizowanych na rzecz zamawiającego, w oparciu o ceny jednostkowe podane przez
                  wykonawcę w:{' '}
                </span>
                {rewardCalculationType === 1 && rewardCalculationTypeEstimationContent}
                {rewardCalculationType === 2 && rewardCalculationTypeTableContent}
                {rewardCalculationType === 3 && (
                  <p dangerouslySetInnerHTML={{ __html: String(rewardCalculationTypeOtherContent) }} />
                )}
                <span className="ml-1">
                  w oparciu o kosztorys zrealizowanych dostaw (wynagrodzenie kosztorysowe). Zamawiający zapłaci
                  wykonawcy wynagrodzenie wyłącznie za rzeczywistą ilość dostarczonych prawidłowo i odebranych partii
                  dostaw, sukcesywnie po prawidłowej dostawie każdej partii
                </span>
              </p>
            ],
            <p className={classNames('preview-point')}>
              Cena maksymalna wykonawcy za wykonanie Przedmiotu Umowy wynosi:
            </p>,
            renderContent(`reward_parts.${index}.reward_max_values_content`, null),
            renderContent(`reward_parts.${index}.reward_calculation_method_content`, null),
            rewardNoChangeTypeSwitch && [
              <p
                className={classNames('preview-point', {
                  modified:
                    rewardNoChangeTypeSwitchModified ||
                    rewardNoChangeTypeModified ||
                    (rewardNoChangeType == 3 && rewardNoChangeTypeOtherContentModified)
                })}
              >
                Nie przewiduje się możliwości wzrostu cen jednostkowych jak również składników cenotwórczych podanych w:
                {rewardNoChangeType === 1 && rewardNoChangeTypeEstimationContent}
                {rewardNoChangeType === 2 && rewardNoChangeTypeTableContent}
                {rewardNoChangeType === 3 && (
                  <p dangerouslySetInnerHTML={{ __html: String(rewardNoChangeTypeOtherContent) }} />
                )}
              </p>
            ]
          ]}
          {renderCheckbox(`reward_parts.${index}.reward_license_included`)}
          {billRulesChecked && [
            <p
              className={classNames('preview-point', {
                modified: billRulesCheckedModified
              })}
            >
              Wynagrodzenie wykonawcy rozliczone zostanie w sposób następujący:
            </p>,
            renderBillParts(partObj?.id),
            renderFinalBill(partObj?.id),
            renderCheckbox(`reward_parts.${index}.bill_periods`, 'preview-nested-alpha-point'),
            renderCheckbox(`reward_parts.${index}.bill_after_protocol`, 'preview-nested-alpha-point'),
            renderCheckbox(`reward_parts.${index}.bill_other`, 'preview-nested-alpha-point')
          ]}
          {(delRewardPaymentTime || delRewardPaymentTimeParts) && [
            <p className={classNames('preview-point')}>Wynagrodzenie wykonawcy:</p>,
            renderCheckboxWithNumberInput(
              `reward_parts.${index}.del_reward_payment_time`,
              DEL_REWARD_PAYMENT_TIME_CONTENT,
              'preview-nested-alpha-point'
            ),
            renderCheckboxWithNumberInput(
              `reward_parts.${index}.del_reward_payment_time_parts`,
              DEL_REWARD_PAYMENT_TIME_PARTS_CONTENT,
              'preview-nested-alpha-point'
            )
          ]}
        </div>
      );
    });
  };

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_REWARD)}</div>
      <div className="agreement-step-preview-content reset-preview-point">
        {agreement.few_parts && renderContent('total_content')}
        {renderParts()}
        {isMultiple && <p className="h5 reset-preview-point">Postanowienia wspólne do wszystkich zadań:</p>}
        {paymentCheckboxes &&
          paymentCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-point wysiwyg-preview', {
                  modified: paymentCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
        {renderCheckboxWithNumberInput('reward_bank_account', REWARD_BANK_ACCOUNT_CONTENT)}
        {rewardDigitalInvoiceChecked && [
          renderCheckbox('reward_digital_invoice'),
          renderCheckbox('reward_digit_inv_buyer'),
          renderCheckbox('reward_digit_inv_parts'),
          renderCheckbox('reward_digit_inv_delivery'),
          renderCheckbox('reward_digit_inv_delivery_time')
        ]}
        {invAttachmentsPossibility && [
          <p
            className={classNames('preview-point', {
              modified: invAttachmentsPossibilityModified
            })}
          >
            Wykonawca ma obowiązek załączenia do każdej wystawionej faktury dla zamawiającego:
          </p>,
          renderCheckbox('inv_attachments_payment_proof', 'preview-nested-alpha-point'),
          renderCheckbox('inv_attachments_subexec_statement', 'preview-nested-alpha-point'),
          renderCheckbox('inv_attachments_exec_statement', 'preview-nested-alpha-point')
        ]}
        {generalCheckboxes &&
          generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-point wysiwyg-preview', {
                  modified: generalCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AgreementStepPreviewReward;
