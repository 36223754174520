import React, { useEffect, useRef, useState } from 'react';
import { FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_QUALITY_GUARANTEE } from 'modules/Agreements/step';
import useStepFields from 'modules/Agreements/hook/useStepFields';

export interface AgreementStepQualityGuaranteeValues {
  guarantee_content: string | null;
  equal_warranty_checked: boolean;
  equal_warranty_content: string | null;
  longer_guarantee_checked: boolean;
  longer_guarantee_content: string | null;
  responsibility_possibility: boolean;
  responsibility_disadvantages_checked: boolean;
  responsibility_disadvantages_content: string | null;
  responsibility_remove_checked: boolean;
  responsibility_remove_content: string | null;
  responsibility_others_checked: boolean;
  responsibility_others_content: string | null;
  own_cost_disadvantages_checked: boolean;
  own_cost_disadvantages_content: string | null;
  no_emergency_checked: boolean;
  no_emergency_content: string | null;
  guarantee_cost_checked: boolean;
  guarantee_cost_content: string | null;
  longer_guarantee_possibility: boolean;
  longer_guarantee_duties_checked: boolean;
  longer_guarantee_duties_content: string | null;
  longer_guarantee_repairs_checked: boolean;
  longer_guarantee_repairs_content: string | null;
  longer_guarantee_others_checked: boolean;
  longer_guarantee_others_content: string | null;
  elongation_checked: boolean;
  elongation_content: string | null;
  impossibility_checked: boolean;
  impossibility_content: string | null;
  prev_resolutions_checked: boolean;
  prev_resolutions_content: string | null;
  unregulated_checked: boolean;
  unregulated_content: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const GUARANTEE_CONTENT =
  'Wykonawca udziela Gwarancji jakości na okres .... miesięcy na wykonane usługi, licząc od dnia Odbioru Ostatecznego Przedmiotu Umowy, to jest od dnia podpisania przez zamawiającego i wykonawcę Protokołu Odbioru Ostatecznego, zgodnie z Umową.';
const EQUAL_WARRANTY_CONTENT = 'Okres rękojmi jest równy okresowi Gwarancji jakości.';
const LONGER_GUARANTEE_CONTENT =
  'Jeżeli wykonawca na podstawie umów zawartych ze swoimi podwykonawcami uzyskał od nich warunki gwarancyjne na dłuższy okres gwarancyjny, to zobowiązany jest przenieść powyższe prawa na zamawiającego po upływie okresu gwarancji wykonawcy.';
const RESPONSIBILITY_DISADVANTAGES_CONTENT =
  'Wady, usterki, braki fizyczne zmniejszające wartość użytkową, techniczną i estetyczną wykonanych usług.';
const RESPONSIBILITY_REMOVE_CONTENT =
  'Usunięcie tych wad, usterek, braków stwierdzonych w toku czynności odbioru i ujawnionych w okresie gwarancyjnym.';
const RESPONSIBILITY_OTHERS_CONTENT = 'inne:';
const OWN_COST_DISADVANTAGES_CONTENT =
  'Wszelkie wady lub usterki ujawnione w okresie gwarancji wykonawca usunie na własny koszt w terminie uzgodnionym z zamawiającym nie dłuższym niż .... dni od dnia pisemnego zgłoszenia wady lub usterki. W razie zwłoki wykonawcy w usunięciu wad lub usterek w wyznaczonym terminie, zamawiający jest uprawniony do zlecenia napraw wad lub usterek innym podmiotom na koszt i ryzyko wykonawcy, na co wykonawca wyraża zgodę. Zamawiający jest uprawniony do skorzystania z wykonania zastępczego po uprzednim wezwaniu wykonawcy do prawidłowego wykonania Umowy, ze wskazaniem zakresu czynności, który będzie podlegał ewentualnemu wykonaniu zastępczemu. Wykonanie wyżej wymienionych  uprawnień przez zamawiającego nie powoduje wygaśnięcia gwarancji.';
const NO_EMERGENCY_CONTENT =
  'Powyższy termin nie dotyczy tzw. przypadków nagłych, wymagających natychmiastowego usunięcia wady lub usterki, w szczególności ze względu na konieczność zmniejszenia szkody. W takich przypadkach zamawiający może zażądać od wykonawcy natychmiastowego usunięcia wady lub usterki, a jeżeli wykonawca nie przystąpi do natychmiastowego usuwania wady lub usterki, do zlecenia napraw wad lub usterek innym podmiotom na koszt i ryzyko wykonawcy, na co wykonawca wyraża zgodę. Zamawiający jest uprawniony do skorzystania z wykonania zastępczego po uprzednim wezwaniu wykonawcy do prawidłowego wykonania Umowy, ze wskazaniem zakresu czynności, który będzie podlegał ewentualnemu wykonaniu zastępczemu. Wykonanie wyżej wymienionych  uprawnień przez zamawiającego nie powoduje wygaśnięcia gwarancji.';
const GUARANTEE_COST_CONTENT = 'Wykonawca ponosi wszelkie koszty wynikające z udzielonej Gwarancji jakości.';
const LONGER_GUARANTEE_DUTIES_CONTENT =
  'Jeżeli wykonując obowiązki z tytułu Gwarancji, wykonawca dostarczył zamiast rzeczy wadliwej rzecz wolną od wad albo dokonał istotnych napraw rzeczy, termin Gwarancji dla tych rzeczy biegnie na nowo od chwili dostarczenia rzeczy bądź jej części wolnej od wad lub zwrócenia naprawionej rzeczy bądź jej części.';
const LONGER_GUARANTEE_REPAIRS_CONTENT =
  'W przypadku dokonania napraw innych niż wymienione w pkt powyżej, termin gwarancji ulega przedłużeniu o czas, w którym zamawiający wskutek wady rzeczy nie mógł z niej korzystać.';
const LONGER_GUARANTEE_OTHERS_CONTENT = 'inne:';
const ELONGATION_CONTENT =
  'Okres Gwarancji jakości wydłuża się każdorazowo o czas odpowiadający okresowi od dnia zgłoszenia wady przez zamawiającego do dnia jej całkowitego usunięcia. Jeżeli jednak wykonawca w wykonaniu swoich zobowiązań gwarancyjnych wymieni jakiekolwiek materiały, instalacje, urządzenia lub ich części, to okres gwarancyjny w odniesieniu do tych materiałów, instalacji, urządzeń lub ich części zaczyna biec na nowo od chwili usunięcia wady.';
const IMPOSSIBILITY_CONTENT =
  'W przypadku niemożliwości usunięcia danej wady wykonawca będzie zobowiązany do ponownego wykonania stosownej części usług, które zostały wykonane wadliwie. Obowiązek ponownego wykonania usług obejmuje także te część usług która została wcześniej wykonana niewadliwie, jeśli zajdzie konieczność jej ponownego wykonania celem usunięcia wad całości.';
const PREV_RESOLUTIONS_CONTENT =
  'Powyższe postanowienia dotyczące Gwarancji jakości nie ograniczają uprawnień zamawiającego wynikających z rękojmi, a także możliwości dochodzenia roszczeń odszkodowawczych.';
const UNREGULATED_CONTENT =
  'W zakresie nieuregulowanym, zastosowanie znajdują przepisy ustawy z dnia 23 kwietnia 1964 roku - Kodeks cywilny.';

const initContentValues = {
  guarantee_content: GUARANTEE_CONTENT,
  equal_warranty_content: EQUAL_WARRANTY_CONTENT,
  longer_guarantee_content: LONGER_GUARANTEE_CONTENT,
  responsibility_disadvantages_content: RESPONSIBILITY_DISADVANTAGES_CONTENT,
  responsibility_remove_content: RESPONSIBILITY_REMOVE_CONTENT,
  responsibility_others_content: RESPONSIBILITY_OTHERS_CONTENT,
  own_cost_disadvantages_content: OWN_COST_DISADVANTAGES_CONTENT,
  no_emergency_content: NO_EMERGENCY_CONTENT,
  guarantee_cost_content: GUARANTEE_COST_CONTENT,
  longer_guarantee_duties_content: LONGER_GUARANTEE_DUTIES_CONTENT,
  longer_guarantee_repairs_content: LONGER_GUARANTEE_REPAIRS_CONTENT,
  longer_guarantee_others_content: LONGER_GUARANTEE_OTHERS_CONTENT,
  elongation_content: ELONGATION_CONTENT,
  impossibility_content: IMPOSSIBILITY_CONTENT,
  prev_resolutions_content: PREV_RESOLUTIONS_CONTENT,
  unregulated_content: UNREGULATED_CONTENT
};

const mapState = (step: AgreementStepQualityGuaranteeValues): AgreementStepQualityGuaranteeValues => {
  const {
    guarantee_content,
    equal_warranty_content,
    longer_guarantee_content,
    responsibility_disadvantages_content,
    responsibility_remove_content,
    responsibility_others_content,
    own_cost_disadvantages_content,
    no_emergency_content,
    guarantee_cost_content,
    longer_guarantee_duties_content,
    longer_guarantee_repairs_content,
    longer_guarantee_others_content,
    elongation_content,
    impossibility_content,
    prev_resolutions_content,
    unregulated_content,
    checkboxes,
    ...rest
  } = step;

  return {
    ...rest,
    guarantee_content: guarantee_content || GUARANTEE_CONTENT,
    equal_warranty_content: equal_warranty_content || EQUAL_WARRANTY_CONTENT,
    longer_guarantee_content: longer_guarantee_content || LONGER_GUARANTEE_CONTENT,
    responsibility_disadvantages_content: responsibility_disadvantages_content || RESPONSIBILITY_DISADVANTAGES_CONTENT,
    responsibility_remove_content: responsibility_remove_content || RESPONSIBILITY_REMOVE_CONTENT,
    responsibility_others_content: responsibility_others_content || RESPONSIBILITY_OTHERS_CONTENT,
    own_cost_disadvantages_content: own_cost_disadvantages_content || OWN_COST_DISADVANTAGES_CONTENT,
    no_emergency_content: no_emergency_content || NO_EMERGENCY_CONTENT,
    guarantee_cost_content: guarantee_cost_content || GUARANTEE_COST_CONTENT,
    longer_guarantee_duties_content: longer_guarantee_duties_content || LONGER_GUARANTEE_DUTIES_CONTENT,
    longer_guarantee_repairs_content: longer_guarantee_repairs_content || LONGER_GUARANTEE_REPAIRS_CONTENT,
    longer_guarantee_others_content: longer_guarantee_others_content || LONGER_GUARANTEE_OTHERS_CONTENT,
    elongation_content: elongation_content || ELONGATION_CONTENT,
    impossibility_content: impossibility_content || IMPOSSIBILITY_CONTENT,
    prev_resolutions_content: prev_resolutions_content || PREV_RESOLUTIONS_CONTENT,
    unregulated_content: unregulated_content || UNREGULATED_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepQualityGuarantee = (props: AgreementStepProps): JSX.Element => {
  const { steps, onSubmit, onChange, errors } = props;

  const initState = useRef<AgreementStepQualityGuaranteeValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_QUALITY_GUARANTEE))
  );

  const [stepValues, setStepValues] = useState<AgreementStepQualityGuaranteeValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_QUALITY_GUARANTEE));
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderCheckbox, renderEditableCheckbox, renderContent, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    initContentValues
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        {renderContent('guarantee_content')}
        {renderCheckbox('equal_warranty_checked', stepValues.equal_warranty_content)}
        {renderCheckbox('longer_guarantee_checked', stepValues.longer_guarantee_content)}
        {renderCheckbox('responsibility_possibility', 'Wykonawca ponosi odpowiedzialność z tytułu gwarancji za:')}
        {stepValues.responsibility_possibility && (
          <div className="pl-3">
            {[
              renderCheckbox('responsibility_disadvantages_checked', stepValues.responsibility_disadvantages_content),
              renderCheckbox('responsibility_remove_checked', stepValues.responsibility_remove_content),
              renderEditableCheckbox('responsibility_others')
            ]}
          </div>
        )}
        {<hr />}
        {renderEditableCheckbox('own_cost_disadvantages')}
        {renderCheckbox('no_emergency_checked', stepValues.no_emergency_content)}
        {renderCheckbox('guarantee_cost_checked', stepValues.guarantee_cost_content)}
        {renderCheckbox(
          'longer_guarantee_possibility',
          'Okres Gwarancji jakości będzie przedłużony w następujących przypadkach:'
        )}
        {stepValues.longer_guarantee_possibility && (
          <div className="pl-3">
            {[
              renderCheckbox('longer_guarantee_duties_checked', stepValues.longer_guarantee_duties_content),
              renderCheckbox('longer_guarantee_repairs_checked', stepValues.longer_guarantee_repairs_content),
              renderEditableCheckbox('longer_guarantee_others')
            ]}
          </div>
        )}
        {<hr />}
        {renderCheckbox('elongation_checked', stepValues.elongation_content)}
        {renderCheckbox('impossibility_checked', stepValues.impossibility_content)}
        {renderCheckbox('prev_resolutions_checked', stepValues.prev_resolutions_content)}
        {renderCheckbox('unregulated_checked', stepValues.unregulated_content)}
        {renderCustomCheckboxes()}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepQualityGuarantee;
