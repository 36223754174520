import React, { useEffect, useRef, useState } from 'react';
import { AGREEMENT_TYPE_SUPPLEMENT, AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_REPRESENTATIVES } from 'modules/Agreements/step';
import { Button, CustomInput, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { isEqual } from 'lodash';
import { suffixLabel } from 'modules/Layout/helper/misc';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import { getError, hasError } from 'modules/Shared/helper/validation';

export interface Representative {
  checked: boolean;
  type: string | null;
  name: string | null;
  email: string | null;
  phone: string | null;
  address: string | null;
}

export interface AgreementStepRepresentativesValues {
  representatives_possibility: boolean;
  contractor_checked: boolean;
  contractor_representatives: Representative[];
  executor_checked: boolean;
  executor_representatives: Representative[];
  change_checked: boolean;
  change_content: string | null;
  no_notification_checked: boolean;
  no_notification_content: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const CHANGE_CONTENT =
  'W przypadku zmiany wskazanych wyżej adresów, każda ze stron jest zobowiązana do poinformowania o takiej zmianie, w formie pisemnej pod rygorem nieważności.';
const NO_NOTIFICATION_CONTENT =
  'W przypadku braku zgłoszenia o zmianie adresu korespondencyjnego uznaje się, że wszelka korespondencja wysyłana na adres wskazany w Umowie będzie uważana za skutecznie doręczoną.';

const mapState = (step: AgreementStepRepresentativesValues): AgreementStepRepresentativesValues => {
  const {
    contractor_representatives,
    executor_representatives,
    change_content,
    no_notification_content,
    checkboxes,
    ...rest
  } = step;

  return {
    ...rest,
    contractor_representatives: contractor_representatives.map((item) => ({ ...item, type: 'contractor' })),
    executor_representatives: executor_representatives.map((item) => ({ ...item, type: 'executor' })),
    change_content: change_content || CHANGE_CONTENT,
    no_notification_content: no_notification_content || NO_NOTIFICATION_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepRepresentatives = (props: AgreementStepProps): JSX.Element => {
  const { steps, onSubmit, onChange, errors, agreement } = props;
  const initState = useRef<AgreementStepRepresentativesValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_REPRESENTATIVES))
  );

  const [stepValues, setStepValues] = useState<AgreementStepRepresentativesValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_REPRESENTATIVES));
    setStepValues({ ...initState.current });
  }, [steps]);

  const addRepresentatives = (type: string) => {
    const representatives: Representative = {
      checked: true,
      type,
      name: null,
      email: null,
      phone: null,
      address: null
    };

    if (type === 'contractor') {
      stepValues.contractor_representatives = [...stepValues.contractor_representatives, representatives];
    } else {
      stepValues.executor_representatives = [...stepValues.executor_representatives, representatives];
    }

    setStepValues((values) => mapState(values));
  };

  const removeRepresentatives = (type: string, index: number) => {
    if (type === 'contractor') {
      stepValues.contractor_representatives = stepValues.contractor_representatives.filter(
        (_, itemIndex) => itemIndex !== index
      );
    } else {
      stepValues.executor_representatives = stepValues.executor_representatives.filter(
        (_, itemIndex) => itemIndex !== index
      );
    }

    setStepValues((values) => mapState(values));
  };

  const renderRepresentative = (representative: Representative, index: number) => {
    const label = (
      <div className="d-flex" key={`${representative.type}_${index}_label`}>
        <div>
          <FormGroup key={`${representative.type}_${index}_name`}>
            <Label for={`${representative.type}_${index}_name`}>
              {suffixLabel('Imię i nazwisko oraz pełniona u zamawiającego funkcja/stanowisko')}
            </Label>
            <Input
              type="text"
              name={`${representative.type}_${index}_name`}
              id={`${representative.type}_${index}_name`}
              value={representative.name || ''}
              disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
              onChange={(event) => {
                representative.name = event.target.value;
                setStepValues((values) => mapState(values));
              }}
              invalid={hasError(errors, `${representative.type}_representatives.${index}.name`)}
            />
            {hasError(errors, `${representative.type}_representatives.${index}.name`) && (
              <FormFeedback className="d-block">
                {getError(errors, `${representative.type}_representatives.${index}.name`)}
              </FormFeedback>
            )}
          </FormGroup>
          <FormGroup key={`${representative.type}_${index}_address`}>
            <Label for={`${representative.type}_${index}_address`}>{suffixLabel('Adres do korespondencji')}</Label>
            <Input
              type="text"
              name={`${representative.type}_${index}_address`}
              id={`${representative.type}_${index}_address`}
              value={representative.address || ''}
              disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
              onChange={(event) => {
                representative.address = event.target.value;
                setStepValues((values) => mapState(values));
              }}
              invalid={hasError(errors, `${representative.type}_representatives.${index}.address`)}
            />
            {hasError(errors, `${representative.type}_representatives.${index}.address`) && (
              <FormFeedback className="d-block">
                {getError(errors, `${representative.type}_representatives.${index}.address`)}
              </FormFeedback>
            )}
          </FormGroup>
          <FormGroup key={`${representative.type}_${index}_phone`}>
            <Label for={`${representative.type}_${index}_phone`}>{suffixLabel('Numer telefonu')}</Label>
            <Input
              type="text"
              name={`${representative.type}_${index}_phone`}
              id={`${representative.type}_${index}_phone`}
              value={representative.phone || ''}
              disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
              onChange={(event) => {
                representative.phone = event.target.value;
                setStepValues((values) => mapState(values));
              }}
              invalid={hasError(errors, `${representative.type}_representatives.${index}.phone`)}
            />
            {hasError(errors, `${representative.type}_representatives.${index}.phone`) && (
              <FormFeedback className="d-block">
                {getError(errors, `${representative.type}_representatives.${index}.phone`)}
              </FormFeedback>
            )}
          </FormGroup>
          <FormGroup key={`${representative.type}_${index}_email`}>
            <Label for={`${representative.type}_${index}_email`}>{suffixLabel('Adres e-mail')}</Label>
            <Input
              type="text"
              name={`${representative.type}_${index}_email`}
              id={`${representative.type}_${index}_email`}
              value={representative.email || ''}
              disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
              onChange={(event) => {
                representative.email = event.target.value;
                setStepValues((values) => mapState(values));
              }}
              invalid={hasError(errors, `${representative.type}_representatives.${index}.email`)}
            />
            {hasError(errors, `${representative.type}_representatives.${index}.email`) && (
              <FormFeedback className="d-block">
                {getError(errors, `${representative.type}_representatives.${index}.email`)}
              </FormFeedback>
            )}
          </FormGroup>
        </div>
        <div>
          <ActionDelete
            title="Usuń pozycje"
            label={<i className="fa fa-times font-22" />}
            onClick={() => removeRepresentatives(representative.type, index)}
          />
        </div>
      </div>
    );

    return (
      <FormGroup key={`${representative.type}_${index}`}>
        <CustomInput
          id={`${representative.type}_${index}_checkbox`}
          type="checkbox"
          checked={representative.checked}
          label={label}
          disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
          onChange={(event) => {
            representative.checked = event.target.checked;
            setStepValues((values) => mapState(values));
          }}
        />
      </FormGroup>
    );
  };

  const { renderCheckbox, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        {[
          renderCheckbox(
            'representatives_possibility',
            'Strony ustalają niżej wymienionych przedstawicieli oraz postanawiają, że wszelka korespondencja będzie kierowana na adresy do doręczeń wskazane niżej:',
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          stepValues.representatives_possibility && [
            renderCheckbox('contractor_checked', 'Zamawiającego:', agreement.type === AGREEMENT_TYPE_SUPPLEMENT),
            hasError(errors, 'contractor_representatives') && (
              <FormFeedback className="d-block">{getError(errors, 'contractor_representatives')}</FormFeedback>
            ),
            stepValues.contractor_representatives.map(renderRepresentative),
            <div className="agreement-form-actions mb-2">
              <Button type="button" color="primary" onClick={() => addRepresentatives('contractor')}>
                Dodaj przedstawiciela
              </Button>
            </div>,
            renderCheckbox('executor_checked', 'Wykonawcy:', agreement.type === AGREEMENT_TYPE_SUPPLEMENT),
            hasError(errors, 'executor_representatives') && (
              <FormFeedback className="d-block">{getError(errors, 'executor_representatives')}</FormFeedback>
            ),
            stepValues.executor_representatives.map(renderRepresentative),
            <div className="agreement-form-actions mb-2">
              <Button type="button" color="primary" onClick={() => addRepresentatives('executor')}>
                Dodaj przedstawiciela
              </Button>
            </div>
          ],
          <hr />,
          renderCheckbox('change_checked', stepValues.change_content, agreement.type === AGREEMENT_TYPE_SUPPLEMENT),
          renderCheckbox(
            'no_notification_checked',
            stepValues.no_notification_content,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )
        ]}
        {renderCustomCheckboxes(
          null,
          null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepRepresentatives;
