import _ from 'lodash';
import { Message } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { useState } from 'react';
import JudgementCategory from 'modules/Judgement/model/JudgementElement';
import { addToastAction, AddToastPayload } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import { AxiosPromise } from 'axios';

const useCategoryDeleteModal = (
  deleteCategory: (id: string | number, cancelToken?: any) => AxiosPromise,
  toastFnc: () => AddToastPayload,
  onSuccess?: () => void,
  cancelLoadingOnSuccess?: boolean
) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState<JudgementCategory>(null);
  const [message, setMessage] = useState<Message>(null);

  const dispatchDeleteJudgementCategoryToast = () => dispatch(addToastAction(toastFnc()));

  const cancelToken = useCancelToken();
  const deleteCategoryAction = async (id: number) => {
    setLoading(true);
    const { cancelled, success, message: messageRequest } = await deleteRequest(deleteCategory, id, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteJudgementCategoryToast();

      if (cancelLoadingOnSuccess) setLoading(false);
      if (onSuccess) onSuccess();
    }
    if (messageRequest) setMessage(messageRequest);
    setLoading(false);
  };

  const clearErrors = () => {
    setMessage(null);
  };

  return {
    deleteCategoryAction,
    loading,
    displayDeleteModal,
    setDisplayDeleteModal,
    message,
    clearErrors
  };
};

export default useCategoryDeleteModal;
