import React from 'react';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_EXECUTOR_DUTIES } from 'modules/Agreements/step';
import { AgreementStepExecutorDutiesValues } from 'modules/Agreements/components/Steps/Default/ExecutorDuties';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';

const AgreementStepPreviewExecutorDuties = (
  props: AgreementStepPreviewProps<AgreementStepExecutorDutiesValues>
): JSX.Element => {
  const { steps, modifiedStep } = props;
  const executorDuties = getStepValues<AgreementStepExecutorDutiesValues>(steps, AGREEMENT_STEP_EXECUTOR_DUTIES);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={executorDuties}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    executorDuties,
    modifiedStep,
    `checkboxes.general`
  );

  const renderFirstPointStatus = (): boolean => {
    return (
      getFieldForPreview(executorDuties, modifiedStep, 'ser_recommendations_checked').value ||
      getFieldForPreview(executorDuties, modifiedStep, 'ser_proper_execution_checked').value ||
      getFieldForPreview(executorDuties, modifiedStep, 'schedule_checked').value ||
      getFieldForPreview(executorDuties, modifiedStep, 'ser_consulting_checked').value ||
      getFieldForPreview(executorDuties, modifiedStep, 'ser_informing_checked').value ||
      getFieldForPreview(executorDuties, modifiedStep, 'contracting_authority_checked').value ||
      getFieldForPreview(executorDuties, modifiedStep, 'ser_no_disturbing_checked').value ||
      getFieldForPreview(executorDuties, modifiedStep, 'subexecutors_works_checked').value ||
      getFieldForPreview(executorDuties, modifiedStep, 'technical_equipment_checked').value ||
      getFieldForPreview(executorDuties, modifiedStep, 'ser_responsibility_checked').value ||
      getFieldForPreview(executorDuties, modifiedStep, 'shortcomings_checked').value ||
      getFieldForPreview(executorDuties, modifiedStep, 'no_realization_checked').value
    );
  };

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_EXECUTOR_DUTIES)}</div>
      <div className="agreement-step-preview-content reset-preview-point reset-nested-preview-point">
        {renderFirstPointStatus() && <p className={classNames('preview-point')}>Do obowiązków wykonawcy należy:</p>}
        {renderCheckbox('ser_recommendations', 'preview-nested-alpha-point')}
        {renderCheckbox('ser_proper_execution', 'preview-nested-alpha-point')}
        {renderCheckbox('schedule', 'preview-nested-alpha-point')}
        {renderCheckbox('ser_consulting', 'preview-nested-alpha-point')}
        {renderCheckbox('ser_informing', 'preview-nested-alpha-point')}
        {renderCheckbox('contracting_authority', 'preview-nested-alpha-point')}
        {renderCheckbox('ser_no_disturbing', 'preview-nested-alpha-point')}
        {renderCheckbox('subexecutors_works', 'preview-nested-alpha-point')}
        {renderCheckbox('technical_equipment', 'preview-nested-alpha-point')}
        {renderCheckbox('ser_responsibility', 'preview-nested-alpha-point')}
        {renderCheckbox('shortcomings')}
        {renderCheckbox('no_realization')}
        {generalCheckboxes &&
          generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-point wysiwyg-preview', {
                  modified: generalCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AgreementStepPreviewExecutorDuties;
