import Executor from 'modules/Executor/model/Executor';
import { PaginationMeta, SearchingProps } from 'modules/Shared/type';

export interface ExecutorAdminListState extends SearchingProps {
  executors: Executor[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initExecutorAdminListState = (): ExecutorAdminListState => ({
  executors: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
