import React from 'react';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import {
  getFieldForPreview,
  getStepName,
  getStepValues,
  PREVIEW_PLACEHOLDER
} from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_WORK_REQUIREMENTS } from 'modules/Agreements/step';
import { AgreementStepWorkRequirementsValues } from 'modules/Agreements/components/Steps/Default/WorkRequirements';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';

const AgreementStepPreviewWorkRequirements = (
  props: AgreementStepPreviewProps<AgreementStepWorkRequirementsValues>
): JSX.Element => {
  const { agreement, steps, modifiedStep } = props;
  const workRequirements = getStepValues<AgreementStepWorkRequirementsValues>(steps, AGREEMENT_STEP_WORK_REQUIREMENTS);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={workRequirements}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const { value: parts } = getFieldForPreview<AgreementStepWorkRequirementsValues['work_requirements']>(
    workRequirements,
    modifiedStep,
    'work_requirements'
  );
  const { value: sameParts } = getFieldForPreview<
    AgreementStepWorkRequirementsValues['same_work_requirements_for_parts']
  >(workRequirements, modifiedStep, 'same_work_requirements_for_parts');

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    workRequirements,
    modifiedStep,
    `checkboxes.general`
  );

  const isMultiple = agreement.few_parts && !sameParts;

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_WORK_REQUIREMENTS)}</div>
      {parts?.map((part, index: number) => {
        const { part_id } = part;
        const { value: worksChecked, modified: worksCheckedModified } = getFieldForPreview(
          workRequirements,
          modifiedStep,
          `work_requirements.${index}.works_checked`
        );
        const { value: worksContent, modified: worksContentModified } = getFieldForPreview(
          workRequirements,
          modifiedStep,
          `work_requirements.${index}.works_content`
        );
        const { value: materialsChecked, modified: materialsCheckedModified } = getFieldForPreview(
          workRequirements,
          modifiedStep,
          `work_requirements.${index}.materials_checked`
        );
        const { value: materialsContent, modified: materialsContentModified } = getFieldForPreview(
          workRequirements,
          modifiedStep,
          `work_requirements.${index}.materials_content`
        );
        const { value: devicesChecked, modified: devicesCheckedModified } = getFieldForPreview(
          workRequirements,
          modifiedStep,
          `work_requirements.${index}.devices_checked`
        );
        const { value: devicesContent, modified: devicesContentModified } = getFieldForPreview(
          workRequirements,
          modifiedStep,
          `work_requirements.${index}.devices_content`
        );
        const { value: installationsChecked, modified: installationsCheckedModified } = getFieldForPreview(
          workRequirements,
          modifiedStep,
          `work_requirements.${index}.installations_checked`
        );
        const { value: installationsContent, modified: installationsContentModified } = getFieldForPreview(
          workRequirements,
          modifiedStep,
          `work_requirements.${index}.installations_content`
        );

        const partObj = agreement.parts_ids.find((item) => item.id === part.part_id);

        return (
          <div key={part_id} className="agreement-step-preview-content reset-preview-point">
            {isMultiple && partObj && <div className="h5">{partObj.getName(index)}:</div>}
            <p
              className={classNames('preview-point wysiwyg-preview', {
                modified: worksCheckedModified || worksContentModified
              })}
              dangerouslySetInnerHTML={{
                __html: String(
                  worksChecked
                    ? worksContent || PREVIEW_PLACEHOLDER
                    : 'Zamawiający nie określa wymagań dla robót.' || PREVIEW_PLACEHOLDER
                )
              }}
            />
            <p
              className={classNames('preview-point wysiwyg-preview', {
                modified: materialsCheckedModified || materialsContentModified
              })}
              dangerouslySetInnerHTML={{
                __html: String(
                  materialsChecked
                    ? materialsContent || PREVIEW_PLACEHOLDER
                    : 'Zamawiający nie określa wymagań dla materiałów.' || PREVIEW_PLACEHOLDER
                )
              }}
            />
            <p
              className={classNames('preview-point wysiwyg-preview', {
                modified: devicesCheckedModified || devicesContentModified
              })}
              dangerouslySetInnerHTML={{
                __html: String(
                  devicesChecked
                    ? devicesContent || PREVIEW_PLACEHOLDER
                    : 'Zamawiający nie określa wymagań dla urządzeń.' || PREVIEW_PLACEHOLDER
                )
              }}
            />
            <p
              className={classNames('preview-point wysiwyg-preview', {
                modified: installationsCheckedModified || installationsContentModified
              })}
              dangerouslySetInnerHTML={{
                __html: String(
                  installationsChecked
                    ? installationsContent || PREVIEW_PLACEHOLDER
                    : 'Zamawiający nie określa wymagań dla instalacji.' || PREVIEW_PLACEHOLDER
                )
              }}
            />

            {renderCheckbox(`work_requirements.${index}.performance_properties`)}
            {renderCheckbox(`work_requirements.${index}.executor_will_provide`)}
            {renderCheckbox(`work_requirements.${index}.contractor_requests`)}
            {generalCheckboxes &&
              generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
                if (!checkbox.checked) return null;

                if (checkbox.part_id != part.part_id) return null;

                return (
                  <p
                    className={classNames('preview-point wysiwyg-preview', {
                      modified: generalCheckboxesModified
                    })}
                    key={index}
                    dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                  />
                );
              })}
          </div>
        );
      })}
      {isMultiple && generalCheckboxes && (
        <div className="agreement-step-preview-content">
          <p className="h5 reset-preview-point">Ponadto dla każdego z zadań:</p>
          {generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            if (checkbox.part_id) return null;

            return (
              <p
                className={classNames('preview-point wysiwyg-preview', {
                  modified: generalCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AgreementStepPreviewWorkRequirements;
