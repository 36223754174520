import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function ShoppingIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 21.333 21.209">
      <g id="Group_315" data-name="Group 315" transform="translate(-216.004 -888.021)">
        <path
          id="Path_1249"
          data-name="Path 1249"
          d="M233.416,910.845H220.325a1.373,1.373,0,0,1-1.369-1.227l-.917-7.069a.5.5,0,0,1,.992-.129l.918,7.074,0,.013a.375.375,0,0,0,.375.337h13.091a.377.377,0,0,0,.375-.339v-.012l.918-7.108a.5.5,0,1,1,.992.128l-.917,7.1A1.375,1.375,0,0,1,233.416,910.845Z"
          transform="translate(-0.235 -1.615)"
          fill={fill}
        />
        <path
          id="Line_3"
          data-name="Line 3"
          d="M0,6.937a.5.5,0,0,1-.358-.151.5.5,0,0,1,.009-.707L6.259-.358a.5.5,0,0,1,.707.009.5.5,0,0,1-.009.707L.349,6.795A.5.5,0,0,1,0,6.937Z"
          transform="translate(219.472 889.978)"
          fill={fill}
        />
        <path
          id="Line_4"
          data-name="Line 4"
          d="M6.607,6.937a.5.5,0,0,1-.349-.142L-.349.358A.5.5,0,0,1-.358-.349.5.5,0,0,1,.349-.358L6.956,6.078a.5.5,0,0,1-.349.858Z"
          transform="translate(227.311 889.978)"
          fill={fill}
        />
        <path
          id="Line_5"
          data-name="Line 5"
          d="M0,5.753a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V5.253A.5.5,0,0,1,0,5.753Z"
          transform="translate(222.685 901.712)"
          fill={fill}
        />
        <path
          id="Line_6"
          data-name="Line 6"
          d="M0,5.753a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V5.253A.5.5,0,0,1,0,5.753Z"
          transform="translate(230.58 901.712)"
          fill={fill}
        />
        <path
          id="Line_7"
          data-name="Line 7"
          d="M0,5.753a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V5.253A.5.5,0,0,1,0,5.753Z"
          transform="translate(227.948 901.712)"
          fill={fill}
        />
        <path
          id="Line_8"
          data-name="Line 8"
          d="M0,5.753a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V5.253A.5.5,0,0,1,0,5.753Z"
          transform="translate(225.317 901.712)"
          fill={fill}
        />
        <path
          id="Rectangle_32"
          data-name="Rectangle 32"
          d="M1-.5H19.333a1.5,1.5,0,0,1,1.5,1.5v.652a1.5,1.5,0,0,1-1.5,1.5H1a1.5,1.5,0,0,1-1.5-1.5V1A1.5,1.5,0,0,1,1-.5ZM19.333,2.152a.5.5,0,0,0,.5-.5V1a.5.5,0,0,0-.5-.5H1A.5.5,0,0,0,.5,1v.652a.5.5,0,0,0,.5.5Z"
          transform="translate(216.504 896.415)"
          fill={fill}
        />
        <path
          id="Path_1250"
          data-name="Path 1250"
          d="M227.919,888.021a1.357,1.357,0,1,1-1.363,1.357A1.362,1.362,0,0,1,227.919,888.021Zm0,1.715a.358.358,0,1,0-.363-.357A.361.361,0,0,0,227.919,889.736Z"
          transform="translate(-1.223)"
          fill={fill}
        />
      </g>
    </svg>
  );
}
