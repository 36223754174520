import { RootState } from 'app/reducer';
import { PartialSearchingProps } from 'modules/Shared/type';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setJudgementListParamsAction } from 'modules/Judgement/action/JudgementList';
import JudgmentInputCategoriesAutocomplete from 'modules/Judgement/container/JudgementAutocomplete/FilterCategories';
import { Dispatch } from 'redux';

export interface Props {
  reduxAction: (payload: PartialSearchingProps) => (dispatch: Dispatch, getState: () => RootState) => Promise<void>;
}

const JudgmentFilterCategoryAutocomplete = ({ reduxAction = setJudgementListParamsAction }) => {
  const dispatch = useDispatch();
  const { filter } = useSelector((state: RootState) => state.judgement.judgementList);
  const [categories, setCategories] = useState<{ name: string; id: number }[]>([]);

  const setParams = (payload: PartialSearchingProps) => dispatch(reduxAction(payload));

  const saveCategory = (newCategory: { name: string; id: number }) => {
    if (!categories.find((value) => value.id === newCategory.id)) {
      setCategories([...categories, newCategory]);
      filter['categories[]'] = [...categories.map((value) => value.id), newCategory.id] as any;
      setParams({ filter: { ...filter } });
    }
  };

  const deleteCategory = (id: number) => {
    const newCategory = categories.filter((el) => el.id !== id);
    setCategories(newCategory);
    // eslint-disable-next-line
    if (filter.hasOwnProperty('categories[]')) {
      const { 'categories[]': _ignore, ...rest } = filter;
      if (newCategory.length > 0) {
        rest['categories[]'] = newCategory.map((value) => value.id) as any;
      }
      setParams({ filter: rest });
    }
  };

  return (
    <div className="col-12 col-lg-6 col-xl-4">
      <JudgmentInputCategoriesAutocomplete
        categories={categories}
        onCategoriesChange={saveCategory}
        alwaysClearInput
        chipContentElements={categories.map(({ name, id }) => ({ content: name, id }))}
        onDeleteChipElement={deleteCategory}
      />
    </div>
  );
};

export default JudgmentFilterCategoryAutocomplete;
