import { PaginationMeta, SearchingProps } from 'modules/Shared/type';
import { ExecutorExtendedRequest } from '../../../Executor/model/Violation';

export interface ExecutorsExtendedRequestsListState extends SearchingProps {
  executorsExtendedRequests: ExecutorExtendedRequest[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initExecutorExtendedRequestsListState = (): ExecutorsExtendedRequestsListState => ({
  executorsExtendedRequests: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
