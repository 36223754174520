import { OFFICE_DOT_LIST, OfficeDotListAction, SET_OFFICE_DOT_LIST_PARAMS } from 'modules/Office/action/list';
import { createOffice } from 'modules/Office/model/Office';
import { initOfficeDotListState, OfficeDotListState } from 'modules/Office/state/list';

const initState = initOfficeDotListState();

const reducer = (state: OfficeDotListState = initState, action: OfficeDotListAction): OfficeDotListState => {
  switch (action.type) {
    case SET_OFFICE_DOT_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case OFFICE_DOT_LIST: {
      const { offices = [], meta } = action.payload;

      return { ...state, fetching: false, officesDot: offices.map(createOffice), meta };
    }

    default:
      return state;
  }
};

export default reducer;
