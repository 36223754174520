import React from 'react';
import { RootState } from 'app/reducer';
import ListingPagination from 'modules/Layout/component/Listing/Pagination';
import { PartialSearchingProps } from 'modules/Shared/type';
import { useDispatch, useSelector } from 'react-redux';
import { setAgreementsListParamsAction } from 'modules/Agreements/action/list';

type Props = { path?: string };

const AgreementsPagination: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    meta = {
      current_page: 1,
      from: 1,
      last_page: 1,
      path: '',
      per_page: 0,
      to: 1,
      total: 0
    },
    pagination,
    filter,
    sort
  } = useSelector((state: RootState) => state.agreements.list);
  const setParams = (payload: PartialSearchingProps) => dispatch(setAgreementsListParamsAction(payload));

  return (
    <ListingPagination
      meta={meta}
      pagination={pagination}
      sort={sort}
      setParams={setParams}
      filter={filter}
      {...props}
    />
  );
};

export default AgreementsPagination;
