import React from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { useDispatch, useSelector } from 'react-redux';
import { addToastAction } from 'modules/Layout/action';
import logo from 'modules/Layout/component/ProceedingsListLogo/baner-desktop.jpg';
import ForeignProceedingsClientFilter from '../../../container/ForeignProceedingsList/Filter/clientFilter';
import ForeignProceedingsListingPagination from '../../../container/ForeignProceedingsList/Pagination';
import ForeignProceedingsPerPage from '../../../container/ForeignProceedingsList/PerPage';
import {
  addForeignProceedingsToFavouriteToastSuccess,
  removeForeignProceedingsFromFavouriteToastSuccess
} from '../../../toasts';
import { updateForeignProceedingsFavouriteAction } from '../../../action/ForeignProceedingsList';
// eslint-disable-next-line import/no-cycle
import ForeignProceedingsTable from '../../../container/ForeignProceedingsList/Table';

const useClientForeignProceedingsList = (foreignProceedingsRoute: string) => {
  const dispatch = useDispatch();
  const { fetching, meta } = useSelector((state: RootState) => state.foreignProceedings.foreignProceedingsList);

  const dispatchAddForeignProceedingsFavToast = () =>
    dispatch(addToastAction(addForeignProceedingsToFavouriteToastSuccess()));
  const dispatchRemoveForeignProceedingsFavToast = () =>
    dispatch(addToastAction(removeForeignProceedingsFromFavouriteToastSuccess()));

  const manageForeignProceedingsFavouriteAction = (proceeding_id: number, isAdding?: boolean) => {
    const toastFnc = isAdding ? dispatchAddForeignProceedingsFavToast : dispatchRemoveForeignProceedingsFavToast;
    dispatch(updateForeignProceedingsFavouriteAction(proceeding_id, toastFnc));
  };

  const templateTheme = process.env.THEME_TEMPLATE as 'pzp' | 'bs';

  const jsxFnc = () => (
    <div className="row users-view">
      <div className="col-12">
        {templateTheme === 'pzp' && (
          <div className="mb-3">
            <a
              href="https://postepowania.pl/baza-wykonawcow-w-stosunku-do-ktorych-moga-wystapic-podstawy-do-wykluczenia/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="mw-100" src={logo} alt="Baner Sprawdź swoją konkurencię w przetargach!" />
            </a>
          </div>
        )}
        <Listing
          table={
            <ForeignProceedingsTable isAdmin={false} manageFavouriteAction={manageForeignProceedingsFavouriteAction} />
          }
          filter={<ForeignProceedingsClientFilter />}
          pagination={<ForeignProceedingsListingPagination path={foreignProceedingsRoute} />}
          perPage={<ForeignProceedingsPerPage />}
          loading={fetching}
          meta={meta}
        />
      </div>
    </div>
  );

  return { jsxFnc };
};

export default useClientForeignProceedingsList;
