import { PaginationMeta, SearchingProps } from 'modules/Shared/type';
import TraditionalPayment from 'modules/Subscriptions/model/TraditionalPayment';

export interface SubscriptionsTraditionalPaymentState extends SearchingProps {
  list: TraditionalPayment[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initSubscriptionsTraditionalPaymentState = (): SubscriptionsTraditionalPaymentState => ({
  list: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
