import { ValidationErrors } from 'modules/Shared/type';
import React, { useEffect, useRef, useState } from 'react';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';

const useRecaptcha = (captchaEnable: boolean, errors?: ValidationErrors) => {
  const [recaptchaValue, setRecaptchaValue] = useState({ 'g-recaptcha-response': '' });
  const captchaRef = useRef<ReCaptcha>();
  // const captchaEnable = process.env.RECAPTCHA_ENABLE === 'true';
  const captchaSiteKey = process.env.RECAPTCHA_V3_SITE_KEY;

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (captchaEnable) {
      loadReCaptcha(captchaSiteKey);

      timer = setInterval(async () => {
        captchaRef?.current?.execute();
      }, 90000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (captchaEnable) {
      captchaRef?.current?.execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const onCaptchaChange = (token: string | null) => setRecaptchaValue({ 'g-recaptcha-response': token });

  const repatchaJsx = () => (
    <>
      {captchaEnable && (
        <ReCaptcha ref={captchaRef} sitekey={captchaSiteKey} action="submit" verifyCallback={onCaptchaChange} />
      )}
    </>
  );

  return {
    recaptchaValue,
    repatchaJsx
  };
};

export default useRecaptcha;
