import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const createClauseCategoryToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja klauzul',
  body: 'Kategoria została pomyślnie dodana.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateClauseCategoryToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja klauzul',
  body: 'Kategoria została pomyślnie zaktualizowana.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteClauseCategoryToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja klauzul',
  body: 'Kategoria została pomyślnie usunięta.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createClauseToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja klauzul',
  body: 'Klauzula została stworzona pomyślnie.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateClauseToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja klauzul',
  body: 'Klauzula została pomyślnie zaktualizowana.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteClauseToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja klauzul',
  body: 'Klauzula została pomyślnie usunięta.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteClauseJudgementToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja klauzul',
  body: 'Połączone orzeczenie zostało pomyślnie usunięte.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const addClauseJudgementToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja klauzul',
  body: 'Połączone orzeczenie zostało pomyślnie dodane.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateClauseJudgementToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja klauzul',
  body: 'Połączone orzeczenie zostało pomyślnie zaktualizowane.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const fetchClauseListToastError = (body: string): AddToastPayload => ({
  header: 'Błąd pobierania listy klauzul',
  body,
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});
