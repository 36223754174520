import React from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import Highlight from '@tiptap/extension-highlight';
import MenuBar from 'modules/Layout/component/Wysiwyg/CustomToolbarOptions/MenuBar';
import CustomTableCell from 'modules/Layout/component/Wysiwyg/CustomToolbarOptions/TableCell';
import './style.scss';
import { suffixLabel } from 'modules/Layout/helper/misc';

export type MenuBarOptions = {
  markPen?: boolean;
  specialSign?: boolean;
  tables?: boolean;
};

const menuBarOptions = {
  markPen: false,
  specialSign: false,
  tables: false
};

type Props = {
  title?: string;
  required?: boolean;
  content?: string;
  setContent: (content: string) => void;
  tooltip?: MenuBarOptions;
  style?: { maxHeight?: string; minHeight?: string };
  isInvalid?: boolean;
};

const WysiwygComponent: React.FC<Props> = (props) => {
  const { content, title, required, setContent, tooltip = menuBarOptions, style = {}, isInvalid } = props;

  const editor = useEditor({
    extensions: [
      StarterKit,
      Table.configure({
        resizable: true
      }),
      Highlight.configure({ multicolor: true }),
      TaskList,
      TaskItem,
      TableRow,
      TableHeader,
      CustomTableCell
    ],
    content,
    // eslint-disable-next-line
    onUpdate({ editor }) {
      setContent(editor.getHTML());
    }
  });

  return (
    <div className="w-100 rdw-editor-wrapper">
      <div className="rdw-editor-toolbar">
        <div className="wysiwyg-title" style={{ color: isInvalid ? '#B7332D' : null }}>
          {suffixLabel(title, required)}
        </div>
        <MenuBar editor={editor} barOptions={tooltip} />
      </div>
      {/* eslint-disable-next-line */}
      <div
        className="rdw-editor-main"
        style={{ borderColor: isInvalid ? '#B7332D' : null }}
        onClick={() => editor.commands.focus()}
      >
        <EditorContent
          aria-label={title}
          editor={editor}
          className="remove-default-class"
          style={{
            wordBreak: 'break-all',
            maxHeight: style.maxHeight ?? '600px',
            minHeight: style.minHeight ?? '200px',
            overflowY: 'auto',
            padding: '0 15px'
          }}
        />
      </div>
    </div>
  );
};

export default WysiwygComponent;
