import { suffixLabel } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import { UserIdentityValues } from 'modules/User/type';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import DisplayPopovers from 'modules/Layout/component/Popover/DisplayWrapper';

export interface Props {
  values: UserIdentityValues;
  errors?: ValidationErrors;
  disabled?: boolean;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, key: keyof UserIdentityValues) => void;
  placeholder?: boolean;
  awaitingEmail?: string;
  nipAcceptance?: boolean;
  isAdmin?: boolean;
}

const UserFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    values,
    errors,
    disabled,
    onChange,
    placeholder = false,
    awaitingEmail = '',
    nipAcceptance = false,
    required,
    isAdmin
  } = props;

  const { first_name = '', last_name = '', email = '', phone = '', nip = '' } = values;

  const themeTemplate = process.env.THEME_TEMPLATE as 'pzp' | 'bs';

  return (
    <fieldset className="m-0" disabled={disabled}>
      <FormGroup>
        {!placeholder && <Label for="input-first-name">{suffixLabel('Imię', required)}</Label>}
        <Input
          type="text"
          name="first_name"
          id="input-first-name"
          placeholder={placeholder ? 'Imię*' : null}
          value={first_name}
          onChange={(event) => onChange(event, 'first_name')}
          invalid={hasError(errors, 'first_name')}
          maxLength={200}
          required={required}
        />
        {hasError(errors, 'first_name') && <FormFeedback>{getError(errors, 'first_name')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="input-last-name">{suffixLabel('Nazwisko', required)}</Label>}
        <Input
          type="text"
          name="last_name"
          id="input-last-name"
          placeholder={placeholder ? 'Nazwisko*' : null}
          value={last_name}
          onChange={(event) => onChange(event, 'last_name')}
          invalid={hasError(errors, 'last_name')}
          maxLength={200}
          required={required}
        />
        {hasError(errors, 'last_name') && <FormFeedback>{getError(errors, 'last_name')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="phone">{suffixLabel('Telefon', required && !isAdmin)}</Label>}
        <Input
          type="text"
          name="phone"
          id="phone"
          placeholder={placeholder ? `Telefon${required && !isAdmin ? '*' : ''}` : null}
          value={phone}
          onChange={(event) => onChange(event, 'phone')}
          maxLength={200}
          invalid={hasError(errors, 'phone')}
          required={required && !isAdmin}
        />
        {hasError(errors, 'phone') && <FormFeedback>{getError(errors, 'phone')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="input-email">{suffixLabel('Adres email', required)}</Label>}
        <Input
          type="email"
          name="email"
          id="input-email"
          placeholder={placeholder ? 'Adres email*' : null}
          value={email}
          onChange={(event) => onChange(event, 'email')}
          invalid={hasError(errors, 'email')}
          required={required}
        />
        {hasError(errors, 'email') && <FormFeedback>{getError(errors, 'email')}</FormFeedback>}
        {awaitingEmail && (
          <div className="text-muted" style={{ fontSize: '12px' }}>
            Email oczekujący na potwierdzenie: {awaitingEmail}
          </div>
        )}
      </FormGroup>
      {!isAdmin && themeTemplate === 'pzp' && (
        <FormGroup>
          {!placeholder && <Label for="input-nip">{suffixLabel('NIP organizacji w Asystencie postępowań')}</Label>}
          <div className="d-flex flex-row align-items-center">
            <Input
              type="text"
              name="nip"
              id="input-nip"
              placeholder={placeholder ? 'NIP organizacji w Asystencie postępowań' : null}
              value={nip}
              onChange={(event) => onChange(event, 'nip')}
              invalid={hasError(errors, 'nip')}
              disabled={nipAcceptance && !isAdmin}
            />
            <div style={{ position: 'relative', left: '10px', width: '0' }}>
              <DisplayPopovers
                popoverId="popover_nip"
                popoverContent="NIP organizacji w Asystencie postępowań"
                className="tooltip-light"
              />
            </div>
          </div>
          {hasError(errors, 'nip') && <FormFeedback>{getError(errors, 'nip')}</FormFeedback>}
          {nipAcceptance && (
            <div className="text-muted mt-1" style={{ fontSize: '12px' }}>
              NIP organizacji w Asystencie postępowań został potwierdzony przez administratora
            </div>
          )}
        </FormGroup>
      )}
    </fieldset>
  );
};

export default UserFieldset;
