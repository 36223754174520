import React from 'react';
import DetailsExecutorsRequestBasic, { Props } from 'modules/Office/component/Details/ExecutorRequest/Basic';

const DetailsComponent: React.FC<Props & { redirectUrl: string }> = (props) => {
  return (
    <div className="col-12">
      <div className="row justify-content-center">
        <div className="col-lg-9">
          <DetailsExecutorsRequestBasic {...props} />
        </div>
      </div>
    </div>
  );
};

export default DetailsComponent;
