import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import { MODULE_EXECUTOR } from 'modules/Module/model/Module';
import User from 'modules/User/model/User';
import moment, { Moment } from 'moment';

export const DATE_FORMAT_VALUE = 'YYYY-MM-DD';
export const DATE_FORMAT_SLASH = 'DD/MM/YYYY';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATETIME_FORMAT_VALUE = 'YYYY-MM-DD HH:mm:ss';
export const DATETIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DATETIME_SECONDS_FORMAT = 'DD.MM.YYYY HH:mm:ss';
export const TIME_FORMAT = 'HH:mm:ss';

export const isValueDirty = (value: unknown, match: unknown, keys?: string[]): boolean => {
  if (keys) {
    return !_isEqual(_pick(value, keys), _pick(match, keys));
  }

  return !_isEqual(value, match);
};

export const formatMoment = (date: Moment, format: string): string => date.format(format);

export const formatDate = (date: string): string => formatMoment(moment(date), DATE_FORMAT);

export const formatDateValue = (date: string, addDays?: number): string => {
  if (addDays) {
    return formatMoment(moment(date).add(addDays, 'days'), DATE_FORMAT_VALUE);
  }

  return formatMoment(moment(date), DATE_FORMAT_VALUE);
};

export const formatDatetimeValue = (date: string): string => {
  return formatMoment(moment(date), DATETIME_FORMAT_VALUE);
};

export const ifContainsDateFormat = (validatedDate: string, formatFnc: (date: string) => string) => {
  // eslint-disable-next-line no-useless-escape
  const dateType = /(\d{4})([\/-])(\d{1,2})\2(\d{1,2})/;
  const isMatch = dateType.test(validatedDate);

  if (isMatch) return formatFnc(validatedDate);
  return validatedDate;
};

export const formatTimeValue = (date: string): string => formatMoment(moment(date), TIME_FORMAT);

export const formatDatetime = (date: string): string => formatMoment(moment(date), DATETIME_FORMAT);

export const formatDatetimeSeconds = (date: string): string => formatMoment(moment(date), DATETIME_SECONDS_FORMAT);

export const formatForInput = (date: Moment): string => date.format(DATE_FORMAT_VALUE);

export const formatDatetimeLocal = (datetimeLocal: string): string =>
  formatMoment(moment(datetimeLocal), 'Y-MM-DD HH:MM:SS');

export const formatDatetimeLocalForInput = (datetimeLocal: string): string =>
  moment(datetimeLocal).format('Y-MM-DDTHH:MM:SS');

export const formatDateLocalForInput = (datetimeLocal: string): string => moment(datetimeLocal).format('Y-MM-DD');

export const getCurrentDate = () => formatDateValue(`${new Date().toISOString()}`);

export const getCurrentDateInFormat = (format = DATETIME_FORMAT_VALUE) => moment(new Date()).format(format);

export const removeEmptyFromObject = (obj: { [key: string]: any }): any => {
  return Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
};

export const checkIfUserHasAccessToModule = (date_from: string, date_to: string) =>
  moment(getCurrentDate()).isBetween(date_from, date_to, undefined, '[]');

export const hasFullAccess = (user: User) => {
  const subscriptionModule = user?.modules?.find((module) => module?.slug === MODULE_EXECUTOR);
  const hasSubscriptionTo = checkIfUserHasAccessToModule(subscriptionModule?.date_from, subscriptionModule?.date_to);

  return hasSubscriptionTo;
};

export const calculateDiffInDays = (
  dateTo: string,
  dateFrom?: string,
  addObj?: { amount?: number; unit?: moment.unitOfTime.DurationConstructor }
) => {
  const amount = addObj?.amount ?? 0;
  const unit = addObj?.unit ?? 'days';
  return moment.duration(moment(dateFrom ?? getCurrentDate()).diff(moment(dateTo).add(amount, unit))).asDays();
};

export const getMonthName = (monthNum: string | number) => {
  let num = null;
  if (typeof monthNum === 'number') {
    num = monthNum - 1;
  }
  return moment().month(num ?? monthNum);
};

export const getMonthOptions = () => {
  return [
    { value: 1, label: 'Styczeń' },
    { value: 2, label: 'Luty' },
    {
      value: 3,
      label: 'Marzec'
    },
    {
      value: 4,
      label: 'Kwiecień'
    },
    { value: 5, label: 'Maj' },
    { value: 6, label: 'Czerwiec' },
    {
      value: 7,
      label: 'Lipiec'
    },
    {
      value: 8,
      label: 'Sierpień'
    },
    { value: 9, label: 'Wrzesień' },
    { value: 10, label: 'Październik' },
    {
      value: 11,
      label: 'Listopad'
    },
    {
      value: 12,
      label: 'Grudzień'
    }
  ];
};

export const findMonthName = (value: number) => {
  switch (value) {
    case 1:
      return 'Styczeń';
    case 2:
      return 'Luty';
    case 3:
      return 'Marzec';
    case 4:
      return 'Kwiecień';
    case 5:
      return 'Maj';
    case 6:
      return 'Czerwiec';
    case 7:
      return 'Lipiec';
    case 8:
      return 'Sierpień';
    case 9:
      return 'Wrzesień';
    case 10:
      return 'Październik';
    case 11:
      return 'Listopad';
    case 12:
      return 'Grudzień';
    default:
      return '';
  }
};

export const formatDateIfExist = (date: string, initFormat = DATE_FORMAT_VALUE, finalFormat = DATE_FORMAT_SLASH) =>
  date ? moment(date, initFormat).format(finalFormat) : '';
