import React from 'react';
import { Menu } from 'app/menu';
import {
  PROCEEDINGS_ADD,
  PROCEEDINGS_MODULE_ACCESS,
  PROCEEDINGS_MODULE_ADMIN_ACCESS
} from 'modules/Proceedings/permissions';
import {
  ROUTE_PROCEEDINGS_CREATE,
  ROUTE_PROCEEDINGS_OPEN,
  ROUTE_PROCEEDINGS_OPEN_FAV,
  ROUTE_PROCEEDINGS_PLANNING
} from 'modules/Proceedings/routes';
import SearchIcon from 'modules/Layout/component/Icon/Search';
import { getPathUrl } from 'modules/Shared/helper/api';
import { PROCEEDINGS_INIT_TYPES_VALUES } from 'modules/Proceedings/type';
import User from '../User/model/User';

const ProceedingsOfficesShared: Menu[] = [
  {
    key: 'proceedings-admin-section',
    type: 'section',
    title: 'Postępowania',
    permissions: [PROCEEDINGS_MODULE_ADMIN_ACCESS]
  },
  {
    key: 'proceedings-admin-planning-menu',
    type: 'nested',
    title: 'Planowane postępowania',
    icon: <SearchIcon />,
    navLevel: 'second',
    href: '',
    isActive: (_match: null) => false,
    permissions: [PROCEEDINGS_MODULE_ADMIN_ACCESS],
    children: [
      {
        key: 'proceedings-admin-planned-index',
        type: 'link',
        title: 'Lista',
        href: ROUTE_PROCEEDINGS_PLANNING
      },
      {
        key: 'proceedings-admin-planning-create',
        type: 'link',
        title: 'Dodaj',
        href: getPathUrl(ROUTE_PROCEEDINGS_CREATE, { proceedingsType: PROCEEDINGS_INIT_TYPES_VALUES.PLANNING }),
        permissions: [PROCEEDINGS_ADD]
      }
    ]
  },
  {
    key: 'proceedings-admin-open-menu',
    type: 'nested',
    title: 'Prowadzone postępowania',
    icon: <SearchIcon />,
    navLevel: 'second',
    href: '',
    permissions: [PROCEEDINGS_MODULE_ADMIN_ACCESS],
    isActive: (_match: null) => false,
    children: [
      {
        key: 'proceedings-admin-open-index',
        type: 'link',
        title: 'Lista',
        href: ROUTE_PROCEEDINGS_OPEN
      },
      {
        key: 'proceedings-admin-open-create',
        type: 'link',
        title: 'Dodaj',
        href: getPathUrl(ROUTE_PROCEEDINGS_CREATE, { proceedingsType: PROCEEDINGS_INIT_TYPES_VALUES.OPEN }),
        permissions: [PROCEEDINGS_ADD]
      }
    ]
  },
  {
    key: 'proceedings-client-menu',
    type: 'nested',
    title: 'Wyszukiwarka postępowań',
    icon: <SearchIcon />,
    navLevel: 'second',
    href: '',
    hasAccess: (user: User): boolean =>
      !user?.permissions.includes(PROCEEDINGS_MODULE_ADMIN_ACCESS) &&
      user?.permissions.includes(PROCEEDINGS_MODULE_ACCESS),
    permissions: [PROCEEDINGS_MODULE_ACCESS],
    isActive: (_match: null) => false,
    children: [
      {
        key: 'proceedings-client-open-index',
        type: 'link',
        title: 'Prowadzone postępowania',
        href: ROUTE_PROCEEDINGS_OPEN
      },
      {
        key: 'proceedings-open-favourite',
        type: 'link',
        title: 'Ulubione postępowania',
        href: ROUTE_PROCEEDINGS_OPEN_FAV
      }
    ]
  }
];

export default ProceedingsOfficesShared;
