import React, { useState } from 'react';
import { Tooltip as TooltipComponent, UncontrolledTooltipProps } from 'reactstrap';

interface Props {
  targetId: string;
  tooltipContent: string;
  tooltipPlacement?: UncontrolledTooltipProps['placement'];
  boundariesElement?: string;
}

const Tooltip: React.FC<Props> = ({ targetId, tooltipContent, tooltipPlacement = 'right', boundariesElement }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <TooltipComponent
      boundariesElement={boundariesElement}
      placement={tooltipPlacement}
      isOpen={tooltipOpen}
      target={targetId}
      toggle={toggle}
    >
      {tooltipContent}
    </TooltipComponent>
  );
};

export default Tooltip;
