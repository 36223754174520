import React, { useEffect, useRef, useState } from 'react';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { isEqual } from 'lodash';
import { getError, hasError } from 'modules/Shared/helper/validation';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_SUBJECT_ORDER_FORM } from 'modules/Agreements/step';
import Date from 'modules/Layout/component/Input/Date';
import useStepFields from 'modules/Agreements/hook/useStepFields';

export interface AgreementStepSubjectValues {
  agreement_subject: string | null;
  deadline_date: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const mapState = (step: AgreementStepSubjectValues): AgreementStepSubjectValues => {
  const { checkboxes, agreement_subject, ...rest } = step;

  return {
    ...rest,
    agreement_subject: agreement_subject || 'Przedmiotem zamówienia jest....',
    checkboxes: checkboxes || {}
  };
};

const AgreementStepSubject = (props: AgreementStepProps): JSX.Element => {
  const { steps, onSubmit, onChange, errors } = props;
  const initState = useRef<AgreementStepSubjectValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_SUBJECT_ORDER_FORM))
  );
  const [stepValues, setStepValues] = useState<AgreementStepSubjectValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_SUBJECT_ORDER_FORM));
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderContent } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    initContentValues: {}
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        <Label>Termin wykonania zamówienia:</Label>
        <Date
          id="deadline_date"
          name="deadline_date"
          value={stepValues.deadline_date}
          invalid={hasError(errors, 'deadline_date')}
          onChange={(date) => setStepValues((values) => ({ ...values, deadline_date: date }))}
        />
        {hasError(errors, 'deadline_date') && (
          <FormFeedback className="d-block">{getError(errors, 'deadline_date')}</FormFeedback>
        )}
      </FormGroup>
      <Label>Przedmiot zamówienia:</Label>
      {renderContent('agreement_subject')}
    </AgreementFormWrapper>
  );
};

export default AgreementStepSubject;
