import { Routes } from 'app/routes';
import Authorize from 'modules/Auth/container/Authorize';
import OrderCreateView from 'modules/ExecutorOrder/view/Create';
import OrderDetailsView from 'modules/ExecutorOrder/view/Details';
import OrderUpdateView from 'modules/ExecutorOrder/view/Update';
import React from 'react';
import { Route } from 'react-router-dom';
import { ORDERS_ADD, ORDERS_DETAILS, ORDERS_EDIT } from 'modules/ExecutorOrder/permissions';

export const ROUTE_ORDERS = '/orders/:executor_id(\\d+)';
export const ROUTE_ORDER = '/order/:executor_id(\\d+)/:id(\\d+)';
export const ROUTE_ORDER_CREATE = '/order/create/:executor_id(\\d+)';
export const ROUTE_ORDER_UPDATE = '/order/update/:executor_id(\\d+)/:id(\\d+)';

export const createOrderRoutes = (): Routes => ({
  wrapped: [
    <Authorize key="order-create" permissions={[ORDERS_ADD]}>
      <Route path={ROUTE_ORDER_CREATE} exact component={OrderCreateView} />
    </Authorize>,
    <Authorize key="order-edit" permissions={[ORDERS_EDIT]}>
      <Route path={ROUTE_ORDER_UPDATE} exact component={OrderUpdateView} />
    </Authorize>,
    <Authorize key="order-details" permissions={[ORDERS_DETAILS]}>
      <Route path={ROUTE_ORDER} exact component={OrderDetailsView} />
    </Authorize>
  ]
});
