export const JUDGMENT_CATEGORIES_INDEX = 'judgment_categories_index';
export const JUDGMENT_CATEGORIES_ADD = 'judgment_categories_add';
export const JUDGMENT_CATEGORIES_EDIT = 'judgment_categories_edit';
export const JUDGMENT_CATEGORIES_DELETE = 'judgment_categories_delete';
export const JUDGMENT_CATEGORIES_DETAILS = 'judgment_categories_details';
export const JUDGMENT_KEYWORDS_INDEX = 'judgment_keywords_index';
export const JUDGMENT_KEYWORDS_ADD = 'judgment_keywords_add';
export const JUDGMENT_KEYWORDS_EDIT = 'judgment_keywords_edit';
export const JUDGMENT_KEYWORDS_DELETE = 'judgment_keywords_delete';
export const JUDGMENT_KEYWORDS_DETAILS = 'judgment_keywords_details';
export const JUDGMENT_SAOS_KEYWORDS_INDEX = 'judgment_saos_keywords_index';
export const JUDGMENT_SAOS_KEYWORDS_ADD = 'judgment_saos_keywords_add';
export const JUDGMENT_SAOS_KEYWORDS_EDIT = 'judgment_saos_keywords_edit';
export const JUDGMENT_SAOS_KEYWORDS_DELETE = 'judgment_saos_keywords_delete';
export const JUDGMENTS_INDEX = 'judgments_index';
export const JUDGMENTS_ADD = 'judgments_add';
export const JUDGMENTS_EDIT = 'judgments_edit';
export const JUDGMENTS_DELETE = 'judgments_delete';
export const JUDGMENTS_DETAILS = 'judgments_details';
export const JUDGMENTS_MODULE_ADMIN_ACCESS = 'judgments_module_admin_access';
