import axios, { AxiosPromise } from 'axios';
import { PasswordValues } from 'modules/Layout/type';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { CompanyEntity, UserEntity } from 'modules/User/model/User';
import { UserIdentityValues } from 'modules/User/type';
import { PaymentHistoryValues, PaymentLogsValues } from 'modules/Profile/type';
import { PaginationMeta, SearchParams } from 'modules/Shared/type';

export const API_PROFILE_UPDATE = '/api/user';
export const API_PROFILE_PASSWORD_UPDATE = '/api/user/password';
export const API_PROFILE_COMPANY_UPDATE = '/api/user/company';
export const API_PROFILE_PAYMENT_LOGS = '/api/payments/history/:id';
export const API_PROFILE_PAYMENT_LOG = '/api/payments/history/:id/:payment_id';

export type ProfileUpdateResponse = {
  message: string;
  user: UserEntity;
};

export const updateProfile = (values: UserIdentityValues): AxiosPromise<ProfileUpdateResponse> =>
  axios.put(getAPIUrl(API_PROFILE_UPDATE), values);

export type ProfileCompanyUpdateResponse = {
  message: string;
  company: CompanyEntity;
};

export const updatePasswordProfile = (values: PasswordValues, cancelToken?: any): AxiosPromise =>
  axios.put(getAPIUrl(API_PROFILE_PASSWORD_UPDATE), values, { cancelToken });

export const updateCompanyProfile = (company: CompanyEntity): AxiosPromise<ProfileCompanyUpdateResponse> =>
  axios.put(getAPIUrl(API_PROFILE_COMPANY_UPDATE), company);

export const deleteOwnAccount = (cancelToken?: any): AxiosPromise =>
  axios.delete(getAPIUrl(API_PROFILE_UPDATE), { cancelToken });

export type PaymentLogsResponse = {
  data: PaymentHistoryValues[];
  meta: PaginationMeta;
  links: any;
};

export const fetchPaymentLogs = (
  id: string | number,
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<PaymentLogsResponse> => axios.get(getAPIUrl(API_PROFILE_PAYMENT_LOGS, { id }, params), { cancelToken });

export const fetchPaymentLog = (
  id: string | number,
  payment_id: string | number,
  cancelToken?: any
): AxiosPromise<{ data: PaymentLogsValues }> =>
  axios.get(getAPIUrl(API_PROFILE_PAYMENT_LOG, { id, payment_id }), { cancelToken });
