import React, { PropsWithChildren } from 'react';
import { Button, Form } from 'reactstrap';

export interface AgreementFormWrapperProps {
  onSubmit: () => void;
  // eslint-disable-next-line react/require-default-props
  hideSubmit?: boolean;
}

const AgreementFormWrapper = (props: PropsWithChildren<AgreementFormWrapperProps>): JSX.Element => {
  const { onSubmit, children, hideSubmit = false } = props;

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
    >
      <div className="agreement-form-content">{children}</div>
      {!hideSubmit && (
        <div className="agreement-form-actions" style={{ paddingRight: '10px' }}>
          <Button type="submit" color="primary">
            Zapisz
          </Button>
        </div>
      )}
    </Form>
  );
};

export default AgreementFormWrapper;
