import { createProceedings } from 'modules/Proceedings/model/Proceedings';
import {
  ProceedingsListAction,
  PROCEEDINGS_LIST,
  PROCEEDINGS_CLEAR_LIST,
  SET_PROCEEDINGS_LIST_PARAMS
} from 'modules/Proceedings/action/ProceedingsList';
import { initProceedingsListState, ProceedingsListState } from 'modules/Proceedings/state/proceedingsList';

const initState = initProceedingsListState();

const reducer = (state: ProceedingsListState = initState, action: ProceedingsListAction): ProceedingsListState => {
  switch (action.type) {
    case SET_PROCEEDINGS_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case PROCEEDINGS_LIST: {
      const { list = [], meta } = action.payload;

      return { ...state, fetching: false, list: list.map(createProceedings), meta };
    }
    case PROCEEDINGS_CLEAR_LIST: {
      return { ...state, fetching: false, list: [] };
    }

    default:
      return state;
  }
};

export default reducer;
