import React from 'react';
import { IconProps } from '../index';

export default function EnclosureIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 24.004 24.004">
      <g id="Group_377" data-name="Group 377" transform="translate(-526.997 -576.001)">
        <path
          id="Path_1282"
          data-name="Path 1282"
          d="M534.565,576h8.865a1.293,1.293,0,0,1,.921.381l6.269,6.266a1.316,1.316,0,0,1,.382.921v8.866a1.314,1.314,0,0,1-.379.919l-6.271,6.269a1.294,1.294,0,0,1-.921.381h-8.865a1.293,1.293,0,0,1-.921-.381l-6.267-6.268a1.293,1.293,0,0,1-.38-.921v-8.866a1.309,1.309,0,0,1,.38-.92l6.267-6.267A1.294,1.294,0,0,1,534.565,576Zm8.865,23a.3.3,0,0,0,.214-.089l6.27-6.268a.306.306,0,0,0,.087-.213v-8.866a.307.307,0,0,0-.09-.215l-6.268-6.265a.3.3,0,0,0-.214-.089h-8.865a.3.3,0,0,0-.213.089l-6.267,6.267a.3.3,0,0,0-.088.212v8.866a.3.3,0,0,0,.088.213l6.267,6.268a.3.3,0,0,0,.213.089Z"
          fill={fill}
        />
        <path
          id="Line_44"
          data-name="Line 44"
          d="M9.418,9.917a.5.5,0,0,1-.354-.146L-.354.354a.5.5,0,0,1,0-.707.5.5,0,0,1,.707,0L9.772,9.064a.5.5,0,0,1-.354.854Z"
          transform="translate(534.29 583.502)"
          fill={fill}
        />
        <path
          id="Line_45"
          data-name="Line 45"
          d="M0,9.917a.5.5,0,0,1-.354-.146.5.5,0,0,1,0-.707L9.064-.354a.5.5,0,0,1,.707,0,.5.5,0,0,1,0,.707L.354,9.771A.5.5,0,0,1,0,9.917Z"
          transform="translate(534.29 583.502)"
          fill={fill}
        />
      </g>
    </svg>
  );
}
