import React, { useEffect, useRef, useState } from 'react';
import { AGREEMENT_TYPE_SUPPLEMENT, AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_ADDITIONAL_PROVISIONS } from 'modules/Agreements/step';
import { FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import useStepFields from 'modules/Agreements/hook/useStepFields';

export interface AgreementStepAdditionalProvisionsValues {
  checkboxes: AgreementCheckboxes | null;
}

const mapState = (step: AgreementStepAdditionalProvisionsValues): AgreementStepAdditionalProvisionsValues => {
  const { checkboxes } = step;

  return {
    checkboxes: checkboxes || {}
  };
};

const AgreementStepAdditionalProvisions = (props: AgreementStepProps): JSX.Element => {
  const { steps, onSubmit, onChange, errors, agreement } = props;

  const initState = useRef<AgreementStepAdditionalProvisionsValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_ADDITIONAL_PROVISIONS))
  );

  const [stepValues, setStepValues] = useState<AgreementStepAdditionalProvisionsValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_ADDITIONAL_PROVISIONS));
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        {renderCustomCheckboxes(
          null,
          null,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepAdditionalProvisions;
