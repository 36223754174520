import { PaginationMeta, SearchingProps } from 'modules/Shared/type';
import Agreement from 'modules/Agreements/model/Agreements';

export interface AgreementListState extends SearchingProps {
  data: Agreement[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initAgreementListState = (): AgreementListState => ({
  data: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
