import Table, { TableCol } from 'modules/Layout/component/Table';
import React from 'react';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { ForeignProceedingsApiImportsListValues } from '../../../model/imports';

type Props = {
  foreignProceedingsApiImportsList?: ForeignProceedingsApiImportsListValues[];
};

const ForeignProceedingsApiImportsTable: React.FC<Props> = ({ foreignProceedingsApiImportsList }) => {
  const cols: TableCol<ForeignProceedingsApiImportsListValues>[] = [
    {
      property: 'source',
      label: 'Źródło',
      value: (row) => row?.source
    },
    {
      property: 'success',
      label: 'Wynik importu',
      value: (row) =>
        row?.success ? <i className="fa fa-check font-18" /> : <i className="fa fa-window-close font-18" />
    },
    {
      property: 'created_at',
      label: 'Rozpoczęto',
      value: (row) => ifContainsDateFormat(row?.last_date, formatDate)
    },
    {
      property: 'error_info',
      label: 'Informacje o błędzie',
      value: (row) => row?.error_info
    }
  ];

  return <Table cols={cols} rows={foreignProceedingsApiImportsList} />;
};

export default ForeignProceedingsApiImportsTable;
