import React, { useEffect, useRef, useState } from 'react';
import { FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_RENOUNCEMENT } from 'modules/Agreements/step';
import useStepFields from 'modules/Agreements/hook/useStepFields';

export interface AgreementStepRenouncementValues {
  same_subject_for_parts: boolean;
  contractor_possibility: boolean;
  contractor_financing_checked: boolean;
  contractor_financing_content: string | null;
  contractor_circumstances_checked: boolean;
  contractor_circumstances_content: string | null;
  contractor_not_take_duties_checked: boolean;
  contractor_not_take_duties_content: string | null;
  contractor_loss_right_checked: boolean;
  contractor_loss_right_content: string | null;
  contractor_seizure_order_checked: boolean;
  contractor_seizure_order_content: string | null;
  contractor_faulty_way_checked: boolean;
  contractor_faulty_way_content: string | null;
  contractor_penalties_sum_checked: boolean;
  contractor_penalties_sum_content: string | null;
  contractor_delay_insurance_checked: boolean;
  contractor_delay_insurance_content: string | null;
  contractor_delay_safeguard_checked: boolean;
  contractor_delay_safeguard_content: string | null;
  contractor_others_checked: boolean;
  contractor_others_content: string | null;
  executor_possibility: boolean;
  executor_no_salary_checked: boolean;
  executor_no_salary_content: string | null;
  executor_no_accept_checked: boolean;
  executor_no_accept_content: string | null;
  executor_others_checked: boolean;
  executor_others_content: string | null;
  renouncement_date_checked: boolean;
  renouncement_date_content: string | null;
  regardless_of_checked: boolean;
  regardless_of_content: string | null;
  not_responsible_possibility: boolean;
  not_responsible_accept_checked: boolean;
  not_responsible_accept_content: string | null;
  not_responsible_payment_checked: boolean;
  not_responsible_payment_content: string | null;
  not_responsible_construction_site_checked: boolean;
  not_responsible_construction_site_content: string | null;
  not_responsible_others_checked: boolean;
  not_responsible_others_content: string | null;
  salary_possibility: boolean;
  salary_deduction_checked: boolean;
  salary_deduction_content: string | null;
  salary_schedule_checked: boolean;
  salary_schedule_content: string | null;
  salary_others_checked: boolean;
  salary_others_content: string | null;
  permissions_checked: boolean;
  permissions_content: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const CONTRACTOR_FINANCING_CONTENT =
  'jeżeli środki, które zamawiający zamierzał przeznaczyć na sfinansowanie całości lub części zamówienia, nie zostały mu przyznane.';
const CONTRACTOR_CIRCUMSTANCES_CONTENT =
  'w razie zaistnienia istotnej zmiany okoliczności powodującej, że wykonanie Umowy nie leży w interesie publicznym lub społecznym, czego nie można było przewidzieć w chwili zawarcia Umowy, lub gdy dalsze wykonywanie Umowy może zagrozić bezpieczeństwu publicznemu lub społecznemu.';
const CONTRACTOR_NOT_TAKE_DUTIES_CONTENT =
  'jeżeli wykonawca nie podjął się wykonywania obowiązków wynikających z Umowy lub przerwał ich wykonywanie, przez czas dłuższy niż ........ dni.';
const CONTRACTOR_LOSS_RIGHT_CONTENT =
  'utraty przez wykonawcę prawa do wykonywania działalności będącej Przedmiotem Umowy.';
const CONTRACTOR_SEIZURE_ORDER_CONTENT =
  'gdy zostanie wydany nakaz zajęcia majątku wykonawcy, w stopniu uniemożliwiającym mu wykonanie zobowiązań wynikających z Umowy.';
const CONTRACTOR_FAULTY_WAY_CONTENT =
  'gdy wykonawca realizuje Przedmiot Umowy w sposób wadliwy, niezgodnie z warunkami Umowy i nie zmienia sposobu realizacji, pomimo wezwania go przez zamawiającego do zmiany w pisemnie wyznaczonym do tego .... dniowym terminie';
const CONTRACTOR_PENALTIES_SUM_CONTENT =
  'suma naliczonych wykonawcy kar umownych przekroczy ........% wynagrodzenia brutto.';
const CONTRACTOR_DELAY_INSURANCE_CONTENT = 'w przypadku .... dniowej zwłoki w przedłożeniu polisy ubezpieczeniowej.';
const CONTRACTOR_DELAY_SAFEGUARD_CONTENT =
  'w przypadku .... dniowej zwłoki w dostarczeniu przedłużonego zabezpieczenia należytego wykonania umowy  w terminie umożliwiającym zachowanie ciągłości.';
const CONTRACTOR_OTHERS_CONTENT = 'inne.';
const EXECUTOR_NO_SALARY_CONTENT =
  'Zamawiający nie wywiązuje się z obowiązku zapłaty faktury w terminie ........ od upływu terminu na zapłatę faktury określonej w Umowie, mimo dodatkowego ........ wezwania.';
const EXECUTOR_NO_ACCEPT_CONTENT =
  'Zamawiający odmawia bez uzasadnionej przyczyny odbioru Przedmiotu Umowy lub odmawia bez uzasadnionej przyczyny podpisania Protokołu odbioru ostatecznego';
const EXECUTOR_OTHERS_CONTENT = 'inne.';
export const RENOUNCEMENT_DATE_CONTENT =
  '<p>Odstąpienie od Umowy powinno nastąpić w terminie ... dni od daty powzięcia wiadomości o okolicznościach uzasadniających złożenie oświadczenia o odstąpieniu. Oświadczenie stron w tym zakresie winno nastąpić w formie pisemnej lub w formie elektronicznej w rozumieniu art. 78<sup>1</sup> k.c. pod rygorem nieważności.</p>';
const REGARDLESS_OF_CONTENT =
  'Niezależnie od wystąpienia okoliczności wskazanych w ust.  1 i 2 niniejszego paragrafu oraz równolegle w okresie o którym mowa powyżej, strony dopuszczają możliwość odstąpienia od Umowy na zasadach przewidzianych w przepisach k.c..';
const NOT_RESPONSIBLE_ACCEPT_CONTENT =
  'dokonania odbioru Przedmiotu Umowy w zakresie jaki został wykonany do momentu odstąpienia oraz zapłaty wynagrodzenia za wykonanie Przedmiotu Umowy, w zakresie jaki został wykonane do dnia odstąpienia.';
const NOT_RESPONSIBLE_PAYMENT_CONTENT =
  'rozliczenia się z wykonawcą z tytułu nierozliczonych w inny sposób kosztów realizacji Przedmiotu Umowy poniesionych przez wykonawcę w związku z realizacją Umowy.';
const NOT_RESPONSIBLE_CONSTRUCTION_SITE_CONTENT =
  'przejęcia od wykonawcy materiałów i dokumentacji wytworzonej w ramach realizacji Przedmiotu Umowy.';
const NOT_RESPONSIBLE_OTHERS_CONTENT = 'inne.';
const SALARY_DEDUCTION_CONTENT =
  'W przypadku odstąpienia od całego elementu Przedmiotu Umowy bądź jego etapu nastąpi odliczenie wartości tego elementu (wynikającej z Harmonogramu lub treści Umowy) od ogólnej wartości wynagrodzenia.';
const SALARY_SCHEDULE_CONTENT =
  'W przypadku odstąpienia od części Przedmiotu Umowy obliczenie wykonanej części tego elementu nastąpi na podstawie protokolarnego ustalenia przez strony Umowy procentowego zaawansowania wykonania tej części Przedmiotu Umowy w odniesieniu do wartości wynagrodzenia za całość Przedmiotu Umowy.';
const SALARY_OTHERS_CONTENT = 'inne.';
const PERMISSIONS_CONTENT =
  'W razie odstąpienia, zamawiającemu przysługiwać będą wszystkie uprawnienia z tytułu gwarancji i rękojmi w zakresie usług wykonanych do daty złożenia oświadczenia o odstąpieniu a okres gwarancji i rękojmi liczony będzie od daty odstąpienia od Umowy.';

export const getContractorPossibilityText = (sameSubjectForPartsStatus: boolean): string => {
  return sameSubjectForPartsStatus
    ? 'Zamawiający zastrzega sobie prawo do odstąpienia od Umowy w następujących przypadkach:'
    : 'Zamawiający zastrzega sobie prawo do odstąpienia od Umowy w zakresie każdego zadania w następujących przypadkach (dla każdego z zadań):';
};

export const getExecutorPossibilityText = (sameSubjectForPartsStatus: boolean): string => {
  return sameSubjectForPartsStatus
    ? 'Wykonawcy przysługuje prawo odstąpienia od Umowy, jeżeli:'
    : 'Wykonawcy przysługuje prawo odstąpienia od Umowy w zakresie każdego zadania (w sytuacji wystąpienia poniżej wskazanych przesłanek w ramach każdego zadania):';
};

const initContentValues = {
  contractor_financing_content: CONTRACTOR_FINANCING_CONTENT,
  contractor_circumstances_content: CONTRACTOR_CIRCUMSTANCES_CONTENT,
  contractor_not_take_duties_content: CONTRACTOR_NOT_TAKE_DUTIES_CONTENT,
  contractor_loss_right_content: CONTRACTOR_LOSS_RIGHT_CONTENT,
  contractor_seizure_order_content: CONTRACTOR_SEIZURE_ORDER_CONTENT,
  contractor_faulty_way_content: CONTRACTOR_FAULTY_WAY_CONTENT,
  contractor_penalties_sum_content: CONTRACTOR_PENALTIES_SUM_CONTENT,
  contractor_delay_insurance_content: CONTRACTOR_DELAY_INSURANCE_CONTENT,
  contractor_delay_safeguard_content: CONTRACTOR_DELAY_SAFEGUARD_CONTENT,
  contractor_others_content: CONTRACTOR_OTHERS_CONTENT,
  executor_no_salary_content: EXECUTOR_NO_SALARY_CONTENT,
  executor_no_accept_content: EXECUTOR_NO_ACCEPT_CONTENT,
  executor_others_content: EXECUTOR_OTHERS_CONTENT,
  regardless_of_content: REGARDLESS_OF_CONTENT,
  not_responsible_accept_content: NOT_RESPONSIBLE_ACCEPT_CONTENT,
  not_responsible_payment_content: NOT_RESPONSIBLE_PAYMENT_CONTENT,
  not_responsible_construction_site_content: NOT_RESPONSIBLE_CONSTRUCTION_SITE_CONTENT,
  not_responsible_others_content: NOT_RESPONSIBLE_OTHERS_CONTENT,
  salary_deduction_content: SALARY_DEDUCTION_CONTENT,
  salary_schedule_content: SALARY_SCHEDULE_CONTENT,
  salary_others_content: SALARY_OTHERS_CONTENT,
  permissions_content: PERMISSIONS_CONTENT
};

const mapState = (step: AgreementStepRenouncementValues): AgreementStepRenouncementValues => {
  const {
    same_subject_for_parts,
    contractor_financing_content,
    contractor_circumstances_content,
    contractor_not_take_duties_content,
    contractor_loss_right_content,
    contractor_seizure_order_content,
    contractor_faulty_way_content,
    contractor_penalties_sum_content,
    contractor_delay_insurance_content,
    contractor_delay_safeguard_content,
    contractor_others_content,
    executor_no_salary_content,
    executor_no_accept_content,
    executor_others_content,
    regardless_of_content,
    not_responsible_accept_content,
    not_responsible_payment_content,
    not_responsible_construction_site_content,
    not_responsible_others_content,
    salary_deduction_content,
    salary_schedule_content,
    salary_others_content,
    permissions_content,
    renouncement_date_checked,
    checkboxes,
    ...rest
  } = step;

  return {
    ...rest,
    same_subject_for_parts,
    renouncement_date_checked: true,
    contractor_financing_content: contractor_financing_content || CONTRACTOR_FINANCING_CONTENT,
    contractor_circumstances_content: contractor_circumstances_content || CONTRACTOR_CIRCUMSTANCES_CONTENT,
    contractor_not_take_duties_content: contractor_not_take_duties_content || CONTRACTOR_NOT_TAKE_DUTIES_CONTENT,
    contractor_loss_right_content: contractor_loss_right_content || CONTRACTOR_LOSS_RIGHT_CONTENT,
    contractor_seizure_order_content: contractor_seizure_order_content || CONTRACTOR_SEIZURE_ORDER_CONTENT,
    contractor_faulty_way_content: contractor_faulty_way_content || CONTRACTOR_FAULTY_WAY_CONTENT,
    contractor_penalties_sum_content: contractor_penalties_sum_content || CONTRACTOR_PENALTIES_SUM_CONTENT,
    contractor_delay_insurance_content: contractor_delay_insurance_content || CONTRACTOR_DELAY_INSURANCE_CONTENT,
    contractor_delay_safeguard_content: contractor_delay_safeguard_content || CONTRACTOR_DELAY_SAFEGUARD_CONTENT,
    contractor_others_content: contractor_others_content || CONTRACTOR_OTHERS_CONTENT,
    executor_no_salary_content: executor_no_salary_content || EXECUTOR_NO_SALARY_CONTENT,
    executor_no_accept_content: executor_no_accept_content || EXECUTOR_NO_ACCEPT_CONTENT,
    executor_others_content: executor_others_content || EXECUTOR_OTHERS_CONTENT,
    regardless_of_content: regardless_of_content || REGARDLESS_OF_CONTENT,
    not_responsible_accept_content: not_responsible_accept_content || NOT_RESPONSIBLE_ACCEPT_CONTENT,
    not_responsible_payment_content: not_responsible_payment_content || NOT_RESPONSIBLE_PAYMENT_CONTENT,
    not_responsible_construction_site_content:
      not_responsible_construction_site_content || NOT_RESPONSIBLE_CONSTRUCTION_SITE_CONTENT,
    not_responsible_others_content: not_responsible_others_content || NOT_RESPONSIBLE_OTHERS_CONTENT,
    salary_deduction_content: salary_deduction_content || SALARY_DEDUCTION_CONTENT,
    salary_schedule_content: salary_schedule_content || SALARY_SCHEDULE_CONTENT,
    salary_others_content: salary_others_content || SALARY_OTHERS_CONTENT,
    permissions_content: permissions_content || PERMISSIONS_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepRenouncement = (props: AgreementStepProps): JSX.Element => {
  const { steps, onSubmit, onChange, errors } = props;

  const initState = useRef<AgreementStepRenouncementValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_RENOUNCEMENT))
  );
  const [stepValues, setStepValues] = useState<AgreementStepRenouncementValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_RENOUNCEMENT));
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderCheckbox, renderEditableCheckbox, renderCustomCheckboxes, renderCheckboxWithNumberInput } =
    useStepFields({
      stepValues,
      setStepValues,
      mapState,
      errors,
      initContentValues
    });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        {[
          renderCheckbox('contractor_possibility', getContractorPossibilityText(stepValues.same_subject_for_parts)),
          stepValues.contractor_possibility && (
            <div className="pl-3">
              {[
                renderEditableCheckbox('contractor_financing'),
                renderEditableCheckbox('contractor_circumstances'),
                renderEditableCheckbox('contractor_not_take_duties'),
                renderEditableCheckbox('contractor_loss_right'),
                renderEditableCheckbox('contractor_seizure_order'),
                renderEditableCheckbox('contractor_faulty_way'),
                renderEditableCheckbox('contractor_penalties_sum'),
                renderEditableCheckbox('contractor_delay_insurance'),
                renderEditableCheckbox('contractor_delay_safeguard'),
                renderEditableCheckbox('contractor_others')
              ]}
            </div>
          ),
          <hr />,
          renderCheckbox('executor_possibility', getExecutorPossibilityText(stepValues.same_subject_for_parts)),
          stepValues.executor_possibility && (
            <div className="pl-3">
              {[
                renderEditableCheckbox('executor_no_salary'),
                renderEditableCheckbox('executor_no_accept'),
                renderEditableCheckbox('executor_others')
              ]}
            </div>
          ),
          <hr />,
          stepValues.contractor_possibility && [
            renderCheckboxWithNumberInput('renouncement_date', RENOUNCEMENT_DATE_CONTENT, true),
            renderCheckbox('regardless_of_checked', stepValues.regardless_of_content),
            <hr />,
            renderCheckbox(
              'not_responsible_possibility',
              'Zamawiający w razie odstąpienia od Umowy z przyczyn, za które wykonawca nie ponosi odpowiedzialności zobowiązany jest do:'
            ),
            stepValues.not_responsible_possibility && (
              <div className="pl-3">
                {[
                  renderEditableCheckbox('not_responsible_accept'),
                  renderEditableCheckbox('not_responsible_payment'),
                  renderEditableCheckbox('not_responsible_construction_site'),
                  renderEditableCheckbox('not_responsible_others')
                ]}
              </div>
            ),
            <hr />,
            renderCheckbox(
              'salary_possibility',
              'Sposób obliczenia należnego wynagrodzenia wykonawcy z tytułu wykonania części Umowy będzie oparty o zasady:'
            ),
            stepValues.salary_possibility && (
              <div className="pl-3">
                {[
                  renderCheckbox('salary_deduction_checked', stepValues.salary_deduction_content),
                  renderCheckbox('salary_schedule_checked', stepValues.salary_schedule_content),
                  renderEditableCheckbox('salary_others')
                ]}
              </div>
            ),
            <hr />,
            renderCheckbox('permissions_checked', stepValues.permissions_content)
          ],
          renderCustomCheckboxes()
        ]}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepRenouncement;
