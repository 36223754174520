import { suffixLabel } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import _uniqueId from 'lodash/uniqueId';
import { Props } from 'modules/Proceedings/component/Fieldset/Main';
import { PROCEEDINGS_INIT_TYPES_VALUES } from 'modules/Proceedings/type';

const ProceedingsFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, setValues, placeholder = false, required } = props;

  const { proceeding_type = 1, name = '' } = values;

  return (
    <>
      <FormGroup>
        {!placeholder && (
          <Label for="input-name">
            {suffixLabel('Nazwa postępowania', proceeding_type === PROCEEDINGS_INIT_TYPES_VALUES.OPEN && required)}
          </Label>
        )}
        <Input
          type="text"
          name="name"
          id="input-name"
          placeholder={placeholder ? 'Nazwa postępowania' : null}
          value={name}
          onChange={(event) => setValues({ ...values, name: event.target.value })}
          invalid={hasError(errors, 'name')}
          required={proceeding_type === PROCEEDINGS_INIT_TYPES_VALUES.OPEN && required}
        />
        {hasError(errors, 'name') && <FormFeedback>{getError(errors, 'name')}</FormFeedback>}
      </FormGroup>
    </>
  );
};

export default ProceedingsFieldset;
