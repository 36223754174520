import React from 'react';
import { RootState } from 'app/reducer';
import ListingPagination from 'modules/Layout/component/Listing/Pagination';
import { PaginationMeta, PaginationParams, PartialSearchingProps, SearchParams, SortParams } from 'modules/Shared/type';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

type Props = {
  path?: string;
  listObj: {
    meta: PaginationMeta;
    pagination: PaginationParams;
    filter: SearchParams;
    sort: SortParams;
  };
  reduxAction: (payload: PartialSearchingProps) => (dispatch: Dispatch, getState: () => RootState) => Promise<void>;
};

const JudgementCategoryPagination: React.FC<Props> = ({ listObj, reduxAction, ...props }) => {
  const dispatch = useDispatch();
  const { meta, pagination, filter, sort } = listObj;
  const setParams = (payload: PartialSearchingProps) => dispatch(reduxAction(payload));

  return (
    <ListingPagination
      meta={meta}
      pagination={pagination}
      sort={sort}
      setParams={setParams}
      filter={filter}
      {...props}
    />
  );
};

export default JudgementCategoryPagination;
