import { RootState } from 'app/reducer';
import Authorize from 'modules/Auth/container/Authorize';
import Table, { TableCol } from 'modules/Layout/component/Table/AccordionTable';
import { getPathUrl } from 'modules/Shared/helper/api';
import { PartialSearchingProps } from 'modules/Shared/type';
import { setOfficeDotListParamsAction } from 'modules/Office/action/list';
import Office, { LastExecutorRequestStatusesOptions } from 'modules/Office/model/Office';
import { OFFICES_EXTENDED } from 'modules/Office/permissions';
import { ROUTE_OFFICE_EXTENDED } from 'modules/Office/routes';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const OfficeDotTable: React.FC = () => {
  const dispatch = useDispatch();
  const { officesDot, sort } = useSelector((state: RootState) => state.officeDot.list);

  const setParams = (payload: PartialSearchingProps) => dispatch(setOfficeDotListParamsAction(payload));

  const redirectLink = (value: string, id: number) => (
    <Authorize permissions={[OFFICES_EXTENDED]} fallback={value}>
      <Link to={getPathUrl(ROUTE_OFFICE_EXTENDED, { id })}>{value}</Link>
    </Authorize>
  );

  const cols: TableCol<Office>[] = [
    {
      property: 'id',
      label: 'ID',
      sortable: true,
      value: (row) => redirectLink(`${row.id}`, row.id)
    },
    {
      property: 'name',
      label: 'Nazwa',
      sortable: true,
      value: (row) => redirectLink(row.name, row.id)
    },
    {
      property: 'regon',
      label: 'REGON',
      sortable: true
    },
    {
      property: 'category',
      label: 'Kategoria',
      sortable: false,
      value: (row) => row.categories.map((category) => `${category.name}${row.categories.length > 1 ? ', ' : ''}`)
    },
    {
      property: 'email',
      label: 'Email',
      sortable: true,
      value: (row) => redirectLink(row.email, row.id)
    },
    {
      property: 'city',
      label: 'Miasto',
      sortable: true
    },
    {
      property: 'district',
      label: 'Powiat',
      sortable: true
    },
    {
      property: 'voivodeship',
      label: 'Województwo',
      sortable: true
    }
  ];

  return (
    <Table
      cols={cols}
      rows={officesDot}
      sort={sort}
      onSort={(params) => setParams({ sort: params })}
      displayErrorRow={(row) =>
        row?.executor_request_status === LastExecutorRequestStatusesOptions.NOT_RECEIVED ? 'error-table-field' : ''
      }
    />
  );
};

export default OfficeDotTable;
