import { ValidationErrors } from 'modules/Shared/type';
import React, { useState } from 'react';
import { Form as FormStrap } from 'reactstrap';
import { Link } from 'react-router-dom';
import { serialize } from 'object-to-formdata';
import TooltipWithBtn from 'modules/Layout/component/Tooltip/WithButton';
import moment from 'moment/moment';
import { IFile } from '../../../Shared/helper/Files/AddFiles';
import { ExecutorViolation, ExecutorViolationSlug } from '../../../Executor/model/Violation';
import ViolationFieldset from '../Fieldset/Violation';
import { DATE_FORMAT_SLASH, DATE_FORMAT_VALUE } from '../../../Shared/helper/utils';

export interface Props {
  errors?: ValidationErrors;
  disabled?: boolean;
  slug?: ExecutorViolationSlug;
  violationData?: ExecutorViolation;
  submit: (data: ExecutorViolation) => void;
  cancelRedirect: string;
  isEditForm?: boolean;
}

const ViolationForm: React.FC<Props> = ({
  errors,
  disabled,
  submit,
  violationData,
  cancelRedirect,
  isEditForm,
  slug
}) => {
  const [values, setValues] = useState<ExecutorViolation>({
    authority_name: violationData?.authority_name ?? '',
    slug: slug ?? 'environmental',
    violation_description: violationData?.violation_description ?? '',
    violation_date: violationData?.violation_date ?? '',
    files: violationData?.files ?? undefined
  });

  const formatViolationDate = (date: string | undefined, toFormat: string): string | null => {
    if (!date) return null;
    const momentObj = moment(date, toFormat, true);
    return momentObj.isValid()
      ? momentObj.format(toFormat === DATE_FORMAT_VALUE ? DATE_FORMAT_SLASH : DATE_FORMAT_VALUE)
      : null;
  };

  const initializeValue = <T extends ExecutorViolation>(
    item: T,
    formatFn: (date: string | undefined, toFormat: string) => string | null
  ): T => ({ ...item, violation_date: formatFn(item.violation_date, DATE_FORMAT_VALUE) });

  const violationValue = initializeValue(values ?? {}, formatViolationDate);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const submitData = {
      ...violationValue,
      violation_date: formatViolationDate(values.violation_date, DATE_FORMAT_SLASH)
    } as any;

    if (isEditForm) {
      submit(submitData);
    } else {
      const dataWithFiles = {
        ...submitData,
        files: submitData.files?.map((fileItem: IFile) => fileItem?.file)
      };
      const data = serialize(dataWithFiles, {
        indices: true,
        booleansAsIntegers: true,
        nullsAsUndefineds: false,
        allowEmptyArrays: false
      });
      submit(data as any);
    }
  };

  const hasValue = () => !Object.values(values).some((v) => v);
  return (
    <FormStrap onSubmit={onSubmit}>
      <ViolationFieldset
        disabled={disabled}
        errors={errors}
        slug={slug}
        values={violationValue}
        setValues={setValues}
        isEditForm={isEditForm}
      />

      <div className="d-flex justify-content-center">
        <div className="form-actions pzpeu-btn-flex">
          <TooltipWithBtn
            targetId="ExecutorOrderFormBtn"
            btnContent="Zapisz"
            tooltipContent='Wypełnij pole "Nazwa zamawiającego"'
            className="pzpeu-btn-disabled"
            wrapperClassName="pzpeu-btn-flex"
            disabled={hasValue() || disabled}
          />
          <Link to={cancelRedirect} className="btn cancel pzpeu-btn-flex waves-effect waves-light">
            Anuluj
          </Link>
        </div>
      </div>
    </FormStrap>
  );
};

export default ViolationForm;
