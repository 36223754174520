import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addToastAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import CardBox from 'modules/Layout/component/CardBox';
import { Button } from 'reactstrap';
import Authorize from 'modules/Auth/container/Authorize';
import { CLAUSE_ADD } from 'modules/Clause/permissions';
import Listing from 'modules/Layout/component/Listing';
import { JudgmentConnectedClausesValues } from 'modules/Judgement/type';
import { deleteJudgementConnectClauseToastSuccess } from 'modules/Judgement/toasts';
import { deleteJudgementConnectedClause, fetchJudgmentConnectedClauses } from 'modules/Judgement/repository';
import Judgement from 'modules/Judgement/model/Judgement';
import JudgmentConnectedClausesTable from 'modules/Judgement/container/JudgementDetails/ConnectedClausesTable';
import useLocalPagination from 'modules/Shared/helper/hooks/useLocalPagination';
import InformationList from 'modules/Layout/component/InformationList';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_JUDGEMENT_CLAUSE_ADD_LIST } from 'modules/Judgement/routes';

const ConnectedClauses: React.FC<{ judgment: Judgement }> = ({ judgment }) => {
  const dispatch = useDispatch();
  const { id: judgment_id } = useParams<{ id: string }>();
  const [judgmentClauses, setJudgementClauses] = useState<JudgmentConnectedClausesValues[]>([]);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState<{ id: number }>(null);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [deleteModalMessage, setDeleteModalMessage] = useState<Message>(null);
  const [displayClauseIdContent, setDisplayClauseIdContent] = useState<number>(null);

  const dispatchDeleteJudgmentClausesToast = () => dispatch(addToastAction(deleteJudgementConnectClauseToastSuccess()));

  const cancelToken = useCancelToken();
  const fetchJudgementClausesAction = async () => {
    setFetching(true);
    const {
      data,
      cancelled,
      message: messageResponse
    } = await fetchRequest(fetchJudgmentConnectedClauses, judgment_id, cancelToken);

    if (cancelled) return;
    if (data) setJudgementClauses(data.data);
    if (messageResponse) setMessage(messageResponse);
    setFetching(false);
  };

  useEffect(() => {
    fetchJudgementClausesAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteJudgementClauseAction = async (clause_id: string | number) => {
    setDeleteModalLoading(true);
    const {
      cancelled,
      message: messageRes,
      success
    } = await deleteRequest(deleteJudgementConnectedClause, judgment_id, clause_id, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteJudgmentClausesToast();
      fetchJudgementClausesAction();
      setDisplayDeleteModal(null);
    } else {
      if (messageRes) setDeleteModalMessage(messageRes);
      setDeleteModalLoading(false);
    }
  };

  const { paginationComponent, selectedItemsFromArray } = useLocalPagination(judgmentClauses);

  const hideContent = () => setDisplayClauseIdContent(null);

  const displayContent = () => {
    if (displayClauseIdContent) {
      const selectedClause = judgmentClauses.find((el) => el.clause.id === displayClauseIdContent);

      if (selectedClause) {
        return (
          <div className="col-xl-8">
            <CardBox
              heading={`Treść zaznaczonej publikacji - ${selectedClause.clause.name}`}
              paddingBottom="0"
              actions={
                <Button color="link" className="d-xl-inline d-none link-with-border p-0" onClick={hideContent}>
                  Zamknij
                </Button>
              }
            >
              <InformationList
                fields={[
                  {
                    label: '',
                    value: selectedClause.content,
                    html: true,
                    htmlFieldInsertedStyle: { maxHeight: '800px', overflowY: 'auto', marginBottom: '20px' }
                  }
                ]}
              />
            </CardBox>
          </div>
        );
      }

      hideContent();
    }

    return null;
  };

  return (
    <div className="row">
      {displayDeleteModal && (
        <GenericModalDelete
          value={displayDeleteModal}
          title="Usunięcie połączonej klauzuli"
          content={() => <div>Czy na pewno chcesz usunąć połączenie z klauzulą?</div>}
          onDeleteClick={(obj) => deleteJudgementClauseAction(obj.id)}
          isOpen={!!displayDeleteModal}
          toggle={() => setDisplayDeleteModal(null)}
          loading={deleteModalLoading}
          message={deleteModalMessage}
          resetMessage={() => setDeleteModalMessage(null)}
        />
      )}

      <div className="flex-1 pr-2 pl-2">
        <CardBox
          heading="Połączone klauzule"
          actions={
            <Authorize permissions={[CLAUSE_ADD]}>
              <Button
                color="link"
                className="link-with-border p-0"
                onClick={() =>
                  dispatch(
                    push({
                      pathname: getPathUrl(ROUTE_JUDGEMENT_CLAUSE_ADD_LIST, { id: judgment_id }),
                      state: { judgment }
                    })
                  )
                }
              >
                Dodaj klauzulę
              </Button>
            </Authorize>
          }
        >
          <div className="row users-view">
            <div className="col-12">
              <Alert message={message} />
              <div className="pzpeu-table-list inside-card-box">
                <Listing
                  table={
                    <JudgmentConnectedClausesTable
                      judgementClauses={selectedItemsFromArray as any}
                      onDeleteClick={setDisplayDeleteModal}
                      setShowContent={setDisplayClauseIdContent}
                      judgment={judgment}
                    />
                  }
                  pagination={paginationComponent()}
                  loading={fetching}
                />
              </div>
            </div>
          </div>
        </CardBox>
      </div>
      {displayContent()}
    </div>
  );
};

export default ConnectedClauses;
