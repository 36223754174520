import { IFile } from '../../Shared/helper/Files/AddFiles';

export type PenaltiesFileEntity = {
  id?: number;
  section_slug?: string;
  name?: string;
  created_at?: string;
  updated_at?: string;
};

export type CommonDataItem = {
  id?: number | string;
  violation_date?: string;
  slug?: string;
  violation_description?: string;
  files: IFile[];
};

export type PenaltyDataItem = CommonDataItem & {
  executor_salary?: number;
  penalty_amount?: number;
};

export type BigRiggingItem = CommonDataItem;

export type ComplaintDataItem = CommonDataItem & {
  complaint_identifier?: string;
  complaint_result?: string;
};

export type MisrepresentationDataItem = CommonDataItem & {
  exclusion_identifier?: string;
};

export type OrderEntity = {
  id?: number;
  name?: string;
  display_name?: string;
  identifier?: string;
  principal_name?: string;
  principal_address?: string;
  contract_number?: string;
  contract_place?: string;
  contract_value?: number;
  contract_date?: string;
  notice_number?: string;
  penalties_checked?: boolean;
  penalties?: PenaltyDataItem[];
  termination_checked?: boolean;
  termination?: CommonDataItem[];
  complaint_checked?: boolean;
  complaint?: ComplaintDataItem[];
  misrepresentation_checked?: boolean;
  misrepresentation?: MisrepresentationDataItem[];
  bid_rigging_checked?: boolean;
  bid_rigging?: BigRiggingItem[];
  unfair_competition_checked?: boolean;
  unfair_competition?: ComplaintDataItem[];
  has_problems?: boolean;
};

export default class Order {
  readonly id?: number;
  readonly name?: string;
  readonly display_name?: string;
  readonly identifier?: string;
  readonly principal_name?: string;
  readonly principal_address?: string;
  readonly contract_number?: string;
  readonly contract_place?: string;
  readonly contract_value?: number;
  readonly contract_date?: string;
  readonly notice_number?: string;
  readonly penalties_checked?: boolean;
  readonly termination_checked?: boolean;
  readonly complaint_checked?: boolean;
  readonly misrepresentation_checked?: boolean;
  readonly has_problems?: boolean;
  readonly penalties?: PenaltyDataItem[];
  readonly termination?: CommonDataItem[];
  readonly complaint?: ComplaintDataItem[];
  readonly misrepresentation: MisrepresentationDataItem[];
  readonly bid_rigging_checked?: boolean;
  readonly bid_rigging: BigRiggingItem[];
  readonly unfair_competition_checked?: boolean;
  readonly unfair_competition?: ComplaintDataItem[];

  constructor(entity: OrderEntity) {
    this.id = entity.id;
    this.name = entity.name;
    this.display_name = entity.display_name;
    this.identifier = entity.identifier;
    this.principal_name = entity.principal_name;
    this.principal_address = entity.principal_address;
    this.contract_number = entity.contract_number;
    this.contract_place = entity.contract_place;
    this.contract_value = entity.contract_value;
    this.contract_date = entity.contract_date;
    this.notice_number = entity.notice_number;
    this.penalties_checked = entity.penalties_checked;
    this.termination_checked = entity.termination_checked;
    this.complaint_checked = entity.complaint_checked;
    this.misrepresentation_checked = entity.misrepresentation_checked;
    this.has_problems = entity.has_problems;
    this.penalties = entity.penalties;
    this.termination = entity.termination;
    this.complaint = entity.complaint;
    this.misrepresentation = entity.misrepresentation;
    this.bid_rigging_checked = entity.bid_rigging_checked;
    this.bid_rigging = entity.bid_rigging;
    this.unfair_competition_checked = entity.unfair_competition_checked;
    this.unfair_competition = entity.unfair_competition;
  }

  static getFilterableAttributes(): string[] {
    return ['name', 'pricipal_name', 'contract_date_from', 'contract_date_to '];
  }

  static getSortableAttributes(): string[] {
    return ['name', 'pricipal_name', 'contract_date'];
  }
}

export const createOrder = (entity?: OrderEntity): Order => new Order(entity);

export const PENALTIES_SLUG = 'penalties';
export const TERMINATION_SLUG = 'termination';
export const COMPLAINT_SLUG = 'complaint';
export const MISREPRESENTATION_SLUG = 'misrepresentation';
export const BID_RIGGING_SLUG = 'bid_rigging';
export const UNFAIR_COMPETITION_SLUG = 'unfair_competition';
