import React from 'react';
import User from 'modules/User/model/User';
import InformationList from 'modules/Layout/component/InformationList';

export interface Props {
  user: User;
}

const CompanyForSubscriptionSummaryDataset: React.FC<Props> = ({ user }: Props): JSX.Element => {
  const clientType = user?.company?.client_type;

  const privateUser = clientType === 1;
  const clientTypeFields = () => {
    return privateUser
      ? [
          {
            label: 'Rodzaj klienta',
            value: 'Osoba fizyczna'
          },
          {
            label: 'Imię',
            value: user?.company?.first_name || '-'
          },
          {
            label: 'Nazwisko',
            value: user?.company?.last_name || '-'
          }
        ]
      : [
          {
            label: 'Rodzaj klienta',
            value: 'Indywidualny przedsiębiorca lub osoba prawna'
          },
          {
            label: 'Nazwa',
            value: user?.company?.name || '-'
          },
          {
            label: 'Imię właściciela',
            value: user?.company?.first_name || '-'
          },
          {
            label: 'Nazwisko właściciela',
            value: user?.company?.last_name
          },
          {
            label: 'NIP',
            value: user?.company?.nip || '-'
          }
        ];
  };

  const fields = [
    {
      label: 'Adres e-mail',
      value: user?.company?.email
    },
    {
      label: 'Miejscowość',
      value: user?.company?.city || '-'
    },
    {
      label: 'Ulica / numer',
      value: user?.company?.street || '-'
    },
    {
      label: 'Kod pocztowy',
      value: user?.getCompanyPostal() || '-'
    },
    {
      label: 'Adres korespondencyjny',
      value: user?.getCompanyCorrespondenceAddress()
    }
  ];

  if (!clientType) return <div style={{ marginLeft: '30px', marginBottom: '30px' }}>Brak danych rozliczeniowych</div>;

  return (
    <div className="company-summary-for-subscription d-flex flex-wrap">
      <div className="col-12 col-md-6 p-0 pr-md-3">
        <InformationList fields={clientTypeFields()} />
      </div>
      <div className="col-12 col-md-6 p-0 pl-md-3">
        <InformationList fields={fields} />
      </div>
    </div>
  );
};

export default CompanyForSubscriptionSummaryDataset;
