import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { UserEntity } from 'modules/User/model/User';
import { RodoValues } from './type';

export const API_REGULATION_UPDATE = '/api/user/accept_regulations';
export const API_PROFILE_RODO_UPDATE = '/api/user/rodo';
export const API_PROFILE_RODO_ADMIN_UPDATE = '/api/users/:user_id/rodo';

export type UpdateRegulationsResponse = AxiosResponse<{
  message: string;
}>;

export const updateRegulations = (data: RodoValues = {}, cancelToken?: any): AxiosPromise<UpdateRegulationsResponse> =>
  axios.post(getAPIUrl(API_REGULATION_UPDATE), data, { cancelToken });

export const updateProfileRodo = (data: RodoValues, cancelToken?: any): AxiosPromise<{ data: UserEntity }> =>
  axios.put(getAPIUrl(API_PROFILE_RODO_UPDATE), data, { cancelToken });

export const updateAdminUserRodo = (
  user_id: number | string,
  data: RodoValues,
  cancelToken?: any
): AxiosPromise<{ data: UserEntity }> =>
  axios.put(getAPIUrl(API_PROFILE_RODO_ADMIN_UPDATE, { user_id }), data, { cancelToken });
