import React, { useState, useEffect } from 'react';
import { Button as ButtonStrap } from 'reactstrap';
import BaseModal from 'modules/Layout/component/Modal';
import { useDispatch } from 'react-redux';
import { logoutAction } from 'modules/Auth/action';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import User from 'modules/User/model/User';
import { deleteOwnAccount } from 'modules/Profile/repository';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';

export type Props = {
  isOpen: boolean;
  toggle: () => void;
  user?: User;
};

const Modal: React.FC<Props> = ({ isOpen, toggle, user }) => {
  const dispatch = useDispatch();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);

  const logout = () => dispatch(logoutAction());

  useEffect(() => {
    if (isOpen && deleteConfirm) setDeleteConfirm(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const cancelToken = useCancelToken();
  const deleteUserAction = async () => {
    setLoading(true);
    const { cancelled, message: messageResponse, success } = await deleteRequest(deleteOwnAccount, cancelToken);

    if (cancelled) return;
    if (success) {
      if (success) dispatch(logoutAction());
    } else {
      if (messageResponse) setMessage(messageResponse);
      setLoading(false);
    }
  };

  const changeConfirmation = (status?: boolean) => {
    setDeleteConfirm(!!status);
    if (message) setMessage(null);
  };

  let body: React.ReactNode = null;
  let footer: React.ReactNode = null;
  let title = '';
  if (!deleteConfirm) {
    title = 'Potwierdzenie';
    body = (
      <div className="modal-body-title">
        Jeśli regulamin nie zostanie zaakceptowany, to zostaniesz wylogowany/a. Możesz również usunąć konto.
      </div>
    );
    footer = (
      <div className="modal-actions">
        <ButtonStrap type="button" onClick={logout}>
          Wyloguj
        </ButtonStrap>
        <ButtonStrap type="button" color="primary" onClick={() => changeConfirmation(true)}>
          Usuń
        </ButtonStrap>
        <ButtonStrap type="button" className="cancel" onClick={toggle}>
          Wróć do formularza
        </ButtonStrap>
      </div>
    );
  } else {
    title = 'Usuwanie konta';
    body = (
      <>
        {loading && <Loader />}
        <Alert message={message} />
        <div className="modal-body-title">Czy na pewno chcesz usunąć swoje konto - {user?.getName()}?</div>
      </>
    );
    footer = (
      <div className="modal-actions">
        <ButtonStrap type="button" color="primary" onClick={deleteUserAction}>
          Usuń
        </ButtonStrap>
        <ButtonStrap type="button" className="cancel" onClick={() => changeConfirmation()}>
          Anuluj
        </ButtonStrap>
      </div>
    );
  }

  return <BaseModal isOpen={isOpen} title={title} toggle={toggle} body={body} footer={footer} />;
};

export default Modal;
