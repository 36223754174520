import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
import { PartialSearchingProps } from 'modules/Shared/type';
import { breadcrumbRouteJudgementKeywords } from 'modules/Judgement/breadcrumbs';
import { breadcrumbRouteClauseKeywords } from 'modules/Clause/breadcrumbs';
import KeywordsWithCategoriesListTable from 'modules/Judgement/container/KeywordsWithCategoriesList/Table';
import KeywordsWithCategoriesListPerPage from 'modules/Judgement/container/KeywordsWithCategoriesList/PerPage';
import KeywordsWithCategoriesListPagination from 'modules/Judgement/container/KeywordsWithCategoriesList/Pagination';
import KeywordsWithCategoriesListFilter from 'modules/Judgement/container/KeywordsWithCategoriesList/Filter';
import { setKeywordsWithCategoriesListParamsAction } from 'modules/Judgement/action/KeywordsWithCategoriesList';

const KeywordsWithCategoriesList = () => {
  const dispatch = useDispatch();
  const { fetching } = useSelector((state: RootState) => state.judgement.keywordList);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const setParams = (payload: PartialSearchingProps) => dispatch(setKeywordsWithCategoriesListParamsAction(payload));

  const { pathname } = useLocation();

  useEffect(() => {
    managePage({
      title: `${pathname.includes('judgement') ? 'Orzeczenia sądowe' : 'Klauzule'} - słowa kluczowe`,
      breadcrumb: pathname.includes('judgement') ? breadcrumbRouteJudgementKeywords() : breadcrumbRouteClauseKeywords()
    });

    setParams({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row offices-view">
      <div className="col-12">
        <Listing
          table={<KeywordsWithCategoriesListTable />}
          filter={<KeywordsWithCategoriesListFilter />}
          pagination={<KeywordsWithCategoriesListPagination />}
          perPage={<KeywordsWithCategoriesListPerPage />}
          loading={fetching}
        />
      </div>
    </div>
  );
};

export default KeywordsWithCategoriesList;
