export interface InvoiceRequiredEntity {
  id?: number;
  payment_number?: string;
  amount?: number;
  tax_value?: number;
  finished_at?: string;
  subscriptions?: string;
  user_id?: number;
  user_email?: string;
  company_name?: string;
}

export default class InvoiceRequired implements InvoiceRequiredEntity {
  readonly id: number;
  readonly payment_number: string;
  readonly amount: number;
  readonly tax_value: number;
  readonly finished_at: string;
  readonly subscriptions: string;
  readonly user_id: number;
  readonly user_email: string;
  readonly company_name: string;

  constructor(entity: InvoiceRequiredEntity) {
    this.id = entity.id;
    this.payment_number = entity.payment_number;
    this.amount = entity.amount;
    this.tax_value = entity.tax_value;
    this.finished_at = entity.finished_at;
    this.subscriptions = entity.subscriptions;
    this.user_id = entity.user_id;
    this.user_email = entity.user_email;
    this.company_name = entity.company_name;
  }

  static getFilterableAttributes(): string[] {
    return ['user_email', 'company_name', 'finished_at_from', 'finished_at_to'];
  }

  static getSortableAttributes(): string[] {
    return ['user_email', 'user_email', 'finished_at '];
  }
}

export const createInvoiceRequired = (entity: InvoiceRequiredEntity): InvoiceRequired => new InvoiceRequired(entity);
