import CardBox from 'modules/Layout/component/CardBox';
import actionDeleteButtonDetails from 'modules/Shared/helper/actionDeleteButtonDetails';
import React from 'react';
import { ExecutorEntity } from '../../../../Executor/model/Executor';
import ExecutorExtendedBasicDataset from '../../ExecutorExtendedDataset/Basic';

export interface Props {
  executorExtended: ExecutorEntity;
  onDeleteClick?: () => void;
}

const ExecutorExtendedDetailsBasic: React.FC<Props> = ({ executorExtended, onDeleteClick }) => {
  return (
    <CardBox
      heading="Dane użytkownika"
      paddingLeft="30px"
      paddingTop="25px"
      paddingRight="30px"
      paddingBottom="0"
      actions={
        onDeleteClick &&
        actionDeleteButtonDetails(executorExtended, {
          value: 'USUŃ Zlecenie z PA',
          onDeleteClick,
          tooltipContent: 'Zlecenia z PA',
          targetIdName: 'DeleteUserId'
        })
      }
    >
      <ExecutorExtendedBasicDataset executorExtended={executorExtended} />
    </CardBox>
  );
};

export default ExecutorExtendedDetailsBasic;
