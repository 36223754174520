import Loader from 'modules/Layout/component/Loader';
import React, { ReactNode } from 'react';
import { PaginationMeta } from '../../../Shared/type';

export interface ListingProps {
  table: ReactNode;
  filter?: ReactNode;
  pagination?: ReactNode;
  perPage?: ReactNode;
  loading?: boolean;
  childrenNextToPagination?: ReactNode;
  childrenNextToPaginationBottom?: ReactNode;
  insertBetweenFilterTable?: ReactNode;
  meta?: PaginationMeta;
}

const Listing: React.FC<ListingProps> = (props: ListingProps): JSX.Element => {
  const {
    table,
    filter,
    pagination,
    perPage,
    childrenNextToPagination,
    childrenNextToPaginationBottom,
    loading = false,
    insertBetweenFilterTable
  } = props;

  return (
    <div className="listing-wrapper">
      {filter && <div className="filter-wrapper">{filter}</div>}
      {insertBetweenFilterTable}
      {pagination && (
        <div className="listing-pagination-wrapper mt-44px d-flex align-items-center flex-column flex-sm-row">
          {childrenNextToPagination}
          {pagination && <div className="mx-auto">{pagination}</div>}
        </div>
      )}
      <div className="table-per-page-wrapper">
        <div className="table-wrapper mt-41px position-relative">
          {loading && <Loader />}
          {table}
        </div>
        {(pagination || perPage) && (
          <div className="per-page-wrapper">
            <div className="listing-pagination-wrapper position-relative per-page d-flex align-items-center justify-content-end flex-column flex-sm-row">
              {childrenNextToPaginationBottom}
              {perPage && (
                <>
                  <div
                    style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                    className="mx-auto position-absolute"
                  >
                    {pagination}
                  </div>
                  <div className="">{perPage}</div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Listing;
