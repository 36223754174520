import { Routes } from 'app/routes';
import Authorize from 'modules/Auth/container/Authorize';
import { ProceedingsPlanningListView, ProceedingsOpenListView } from 'modules/Proceedings/view';
import ProceedingsDetailsView from 'modules/Proceedings/view/Details';
import ProceedingsCreateView from 'modules/Proceedings/view/Create';
import ProceedingsUpdateView from 'modules/Proceedings/view/Update';
import React from 'react';
import { Route } from 'react-router-dom';
import Unguarded from 'modules/Auth/component/Unguarded';
import { ProceedingsListPublicOpenView, ProceedingsListPublicPlanningView } from 'modules/Proceedings/view/Public';
import ProceedingsDetailsPublic from 'modules/Proceedings/view/Public/Details';
import {
  ProceedingsFavouritesOpenListView,
  ProceedingsFavouritesPlanningListView
} from 'modules/Proceedings/view/Favourites';

export const ROUTE_PROCEEDING = '/proceedings/:id(\\d+)/:proceedingsType';
export const ROUTE_PROCEEDINGS_PLANNING = '/proceedings/planning';
export const ROUTE_PROCEEDINGS_OPEN = '/proceedings/open';
export const ROUTE_PROCEEDINGS_PLANNING_FAV = '/proceedings/fav/planning';
export const ROUTE_PROCEEDINGS_OPEN_FAV = '/proceedings/fav/open';
export const ROUTE_PROCEEDINGS_CREATE = '/proceedings/create/:proceedingsType';
export const ROUTE_PROCEEDINGS_UPDATE = '/proceedings/update/:id(\\d+)/:proceedingsType';
export const ROUTE_PROCEEDINGS_PUBLIC_PLANNING = '/proceedings/public/planning';
export const ROUTE_PROCEEDINGS_PUBLIC_OPEN = '/proceedings/public/open';
export const ROUTE_PROCEEDING_PUBLIC = '/proceedings/public/:id(\\d+)/:proceedingsType';

export const createProceedingsRoutes = (): Routes => ({
  public: [
    <Route key="proceedings-list-public-planning" path={ROUTE_PROCEEDINGS_PUBLIC_PLANNING} exact>
      <Unguarded>
        <ProceedingsListPublicPlanningView />
      </Unguarded>
    </Route>,
    <Route key="proceedings-list-public-open" path={ROUTE_PROCEEDINGS_PUBLIC_OPEN} exact>
      <Unguarded>
        <ProceedingsListPublicOpenView />
      </Unguarded>
    </Route>,
    <Route key="proceedings-details-public" path={ROUTE_PROCEEDING_PUBLIC} exact>
      <Unguarded>
        <ProceedingsDetailsPublic />
      </Unguarded>
    </Route>
  ],
  wrapped: [
    <Authorize key="proceedings-planning-list">
      <Route path={ROUTE_PROCEEDINGS_PLANNING} exact component={ProceedingsPlanningListView} />
    </Authorize>,
    <Authorize key="proceedings-open-list">
      <Route path={ROUTE_PROCEEDINGS_OPEN} exact component={ProceedingsOpenListView} />
    </Authorize>,
    <Authorize key="proceedings-details">
      <Route path={ROUTE_PROCEEDING} exact component={ProceedingsDetailsView} />
    </Authorize>,
    <Authorize key="proceedings-add">
      <Route path={ROUTE_PROCEEDINGS_CREATE} exact component={ProceedingsCreateView} />
    </Authorize>,
    <Authorize key="proceedings-update">
      <Route path={ROUTE_PROCEEDINGS_UPDATE} exact component={ProceedingsUpdateView} />
    </Authorize>,
    <Authorize key="proceedings-planning-list-fav">
      <Route path={ROUTE_PROCEEDINGS_PLANNING_FAV} exact component={ProceedingsFavouritesPlanningListView} />
    </Authorize>,
    <Authorize key="proceedings-open-list-fav">
      <Route path={ROUTE_PROCEEDINGS_OPEN_FAV} exact component={ProceedingsFavouritesOpenListView} />
    </Authorize>
  ]
});
