import { suffixValue } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { SelectOption, ValidationErrors } from 'modules/Shared/type';
import React, { useEffect, useState } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import moment from 'moment/moment';
import { ExecutorExtendedRequestState } from '../../../Executor/model/Violation';
import { OfficeExecutorRequestChannelsValue } from '../../../Office/type';
import Select from '../../../Layout/component/Input/Select';
import Date from '../../../Layout/component/Input/Date';
import AddFiles from '../../../Shared/helper/Files/AddFiles';
import { SlugType } from '../../../ForeignProceedings/container/ForeignProceedingsList/Filter/FilterValues/useFilterValues';
import { fetchOfficesExtendedCategories } from '../../../Office/repository';

export interface Props {
  values: ExecutorExtendedRequestState;
  errors?: ValidationErrors;
  channelValues?: OfficeExecutorRequestChannelsValue[];
  disabled?: boolean;
  required?: boolean;
  originalFiles?: number;
  setValues: React.Dispatch<React.SetStateAction<ExecutorExtendedRequestState>>;
  placeholder?: boolean;
}

const ExecutorExtendedRequestFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, disabled, setValues, placeholder = false, required, channelValues, originalFiles } = props;
  const [categoriesList, setCategoriesList] = useState<SlugType[]>([]);
  const categoriesOptions: SelectOption[] = categoriesList.map((category) => {
    return {
      value: category.slug,
      label: category.name
    };
  });

  const statusOptions: SelectOption[] = [
    {
      label: '',
      value: ''
    },
    {
      label: 'Oczekiwanie na odpowiedź',
      value: 1
    },
    {
      label: 'Wydłużenie terminu na odpowiedź',
      value: 2
    },
    {
      label: 'Odmowa udostępnienia informacji',
      value: 3
    },
    {
      label: 'Brak naruszeń',
      value: 4
    },
    {
      label: 'Są naruszenia',
      value: 5
    }
  ];

  useEffect(() => {
    const getValues = async () => {
      const responseCategories = await fetchOfficesExtendedCategories();
      const newCategories = responseCategories.data.data;
      setCategoriesList(newCategories);
    };
    getValues().then((r) => r);
  }, []);

  const {
    status: { id: statusId },
    category: { slug },
    channel: { id: channelId },
    received_at = '',
    answer_content = ''
  } = values;

  const currentValue = channelValues?.find((obj) => obj.id === channelId);
  const currentStatus = statusOptions?.find((obj) => obj.value === statusId);
  const currentCategory = categoriesOptions?.find((obj) => obj.value === slug);

  return (
    <fieldset className="m-0" disabled={disabled}>
      <FormGroup>
        <Label for="channel_id">Kanał dostarczenia odpowiedzi</Label>
        <Select
          id="channel_id"
          className="pagination-page-selector"
          value={{
            label: currentValue?.name,
            value: String(currentValue?.id)
          }}
          required
          options={channelValues?.map((obj) => ({
            label: obj.name,
            value: String(obj.id)
          }))}
          onChange={(option) =>
            setValues({
              ...values,
              channel: {
                id: Number(option.value),
                name: option.label
              }
            })
          }
        />
      </FormGroup>
      <FormGroup>
        <Label for="status">Status</Label>
        <Select
          id="status"
          className="pagination-page-selector"
          value={{
            label: currentStatus?.label,
            value: String(currentStatus?.value)
          }}
          required
          options={statusOptions?.map((obj) => ({
            label: obj.label,
            value: String(obj.value)
          }))}
          onChange={(option) =>
            setValues({
              ...values,
              status: {
                id: Number(option.value),
                name: option.label
              }
            })
          }
        />
      </FormGroup>
      <FormGroup>
        <Label for="category">Kategoria organu</Label>
        <Select
          id="status"
          className="pagination-page-selector"
          value={{
            label: currentCategory?.label,
            value: String(currentCategory?.value)
          }}
          required
          options={categoriesOptions?.map((obj) => ({
            label: obj.label,
            value: String(obj.value)
          }))}
          onChange={(option) =>
            setValues({
              ...values,
              category: {
                slug: option.value,
                name: option.label
              }
            })
          }
        />
      </FormGroup>
      <FormGroup>
        <Label for="received_at">{suffixValue('Data otrzymania odpowiedzi', required)}</Label>
        <div className="d-flex flex-wrap align-items-start">
          <Date
            name="received_at"
            value={received_at ?? ''}
            min=""
            max={moment().format('Y-MM-DD')}
            onChange={(val) => setValues({ ...values, received_at: val })}
            invalid={hasError(errors, 'received_at')}
            required
          />
        </div>
        {hasError(errors, 'received_at') && <FormFeedback>{getError(errors, 'received_at')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="answer_content">Treść otrzymania odpowiedzi</Label>}
        <Input
          type="textarea"
          style={{ height: '150px', resize: 'none' }}
          id="answer_content"
          placeholder={placeholder ? 'Treść' : null}
          value={answer_content}
          onChange={(event) => setValues({ ...values, answer_content: event.target.value })}
          invalid={hasError(errors, 'answer_content')}
        />
        {hasError(errors, 'answer_content') && <FormFeedback>{getError(errors, 'answer_content')}</FormFeedback>}
      </FormGroup>
      {originalFiles === 0 && (
        <FormGroup>
          <AddFiles objKey="files" values={values as any} setValues={setValues} maxSizeInMb={50} />
        </FormGroup>
      )}
    </fieldset>
  );
};

export default ExecutorExtendedRequestFieldset;
