import React, { useEffect, useRef, useState } from 'react';
import { PaginationItem, PaginationLink, Popover, PopoverBody } from 'reactstrap';
import '../style.scss';

export type PaginationItemType = 'next' | 'previous' | 'first' | 'last';

type Props = {
  currentPage: number;
  lastPage: number;
  setCurrentPage: (page: number) => void;
};

export const LocalPagination: React.FC<Props> = ({ currentPage, lastPage, setCurrentPage }) => {
  const [isOpen, setIsOpen] = useState(null);
  const [popoverPage, setPopoverPage] = useState(null);
  const incrementRef = useRef(0);

  const id = `pagination-${(incrementRef.current += incrementRef.current)}`;
  const onToggle = () => setIsOpen(!isOpen);

  const onMousedown = ({ target }: MouseEvent) => {
    if (isOpen && target instanceof Node) {
      const pagination = document.getElementById(id);

      if (pagination && !pagination.contains(target)) {
        onToggle();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', onMousedown, false);

    return () => {
      document.removeEventListener('mousedown', onMousedown, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNewPage = (page: number) => {
    if (isOpen) setIsOpen(false);
    if (popoverPage) setPopoverPage(null);

    setCurrentPage(page);
  };

  const onClick = (event: React.MouseEvent, page: number) => {
    event.preventDefault();

    setNewPage(page);
  };

  const renderItem = (page: number, active: boolean, disabled: boolean, type?: PaginationItemType) => {
    const key = type ? `page-${type}` : `page-${page}`;

    return (
      <PaginationItem key={key} active={active} disabled={disabled} className={key}>
        <PaginationLink
          next={type === 'next'}
          previous={type === 'previous'}
          first={type === 'first'}
          last={type === 'last'}
          onClick={(event) => onClick(event, page)}
        >
          {type ? null : page}
        </PaginationLink>
      </PaginationItem>
    );
  };

  const renderFirstItem = () => {
    return renderItem(1, false, currentPage === 1, 'first');
  };

  const renderPrevItem = () => {
    const prevPage = currentPage > 1 ? currentPage - 1 : currentPage;

    return renderItem(prevPage, false, currentPage === prevPage, 'previous');
  };

  const renderStaticItem = () => {
    return (
      <PaginationItem className="page-static">
        <PaginationLink onClick={onToggle}>...</PaginationLink>
      </PaginationItem>
    );
  };

  const renderItems = () => {
    const items: React.ReactNode[] = [];

    for (let page = currentPage - 4; page <= currentPage + 4; page += 1) {
      if (page === currentPage) {
        items.push(renderItem(page, currentPage === page, false));
      } else if (page > 0 && page <= lastPage && Math.abs(currentPage - page) <= 2) {
        items.push(renderItem(page, false, false));
      }
    }

    return items;
  };

  const renderNextItem = () => {
    const nextPage = currentPage < lastPage ? currentPage + 1 : currentPage;

    return renderItem(nextPage, false, currentPage === nextPage, 'next');
  };

  const renderLastItem = () => {
    return renderItem(lastPage, false, currentPage === lastPage, 'last');
  };

  const renderPopover = () => {
    return (
      <Popover key={currentPage} target={id} container={id} isOpen={isOpen} placement="top" delay={0} fade={false}>
        <PopoverBody className="pagination-go-to">
          <input
            type="number"
            className="form-control form-control-sm"
            value={popoverPage || ''} // page
            placeholder={`(1-${lastPage})`}
            onChange={(event) => {
              const value = Number(event.target.value);

              if (value > 0 && value <= lastPage) {
                setPopoverPage(value);
              }
            }}
          />
          <button
            type="button"
            className="btn btn-primary btn-sm btn-block waves-effect waves-light mt-2"
            onClick={() => setNewPage(popoverPage)}
          >
            Przejdź do strony
          </button>
        </PopoverBody>
      </Popover>
    );
  };

  return (
    <div className="pagination-wrapper" id={id}>
      <ul className="pagination pagination-nav m-0" aria-label="pagination">
        {renderFirstItem()}
        {renderPrevItem()}
        {currentPage > 3 && renderStaticItem()}
        {renderItems()}
        {currentPage + 2 < lastPage && renderStaticItem()}
        {renderNextItem()}
        {renderLastItem()}
      </ul>
      {renderPopover()}
    </div>
  );
};
