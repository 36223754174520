import { OfficeExecutorRequestChannelsValue, OfficeExecutorRequestReceivedValueInState } from 'modules/Office/type';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import Date from 'modules/Layout/component/Input/Date';
import { suffixValue } from 'modules/Layout/helper/misc';
import AddFiles from 'modules/Shared/helper/Files/AddFiles';
import moment from 'moment';
import Select from '../../../../Layout/component/Input/Select';

export interface Props {
  values: OfficeExecutorRequestReceivedValueInState;
  channelValues?: OfficeExecutorRequestChannelsValue[];
  errors?: ValidationErrors;
  disabled?: boolean;
  required?: boolean;
  setValues: React.Dispatch<React.SetStateAction<OfficeExecutorRequestReceivedValueInState>>;
  placeholder?: boolean;
}

const ExecutorRequestFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, disabled, setValues, channelValues, placeholder = false, required } = props;

  const { received_at, channel_id, answer_content } = values;

  const currentValue = channelValues?.find((obj) => obj.id === channel_id);

  return (
    <fieldset className="m-0" disabled={disabled}>
      <FormGroup>
        <Label for="channel_type">Kanał dostarczenia odpowiedzi</Label>
        <Select
          id="channel_type"
          className="pagination-page-selector"
          value={{
            label: currentValue?.name,
            value: String(currentValue?.id)
          }}
          required
          options={channelValues?.map((obj) => ({
            label: obj.name,
            value: String(obj.id)
          }))}
          onChange={(option) => setValues({ ...values, channel_id: parseInt(option.value, 10) || 1 })}
        />
      </FormGroup>
      <FormGroup>
        <Label for="received_at">{suffixValue('Data otrzymania odpowiedzi', required)}</Label>
        <div className="d-flex flex-wrap align-items-start">
          <Date
            name="received_at"
            value={received_at ?? ''}
            min=""
            max={moment().format('Y-MM-DD')}
            onChange={(val) => setValues({ ...values, received_at: val })}
            invalid={hasError(errors, 'received_at')}
            required
          />
        </div>
        {hasError(errors, 'received_at') && <FormFeedback>{getError(errors, 'received_at')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="answer_content">Treść otrzymania odpowiedzi</Label>}
        <Input
          type="textarea"
          style={{ height: '150px', resize: 'none' }}
          id="answer_content"
          placeholder={placeholder ? 'Treść' : null}
          value={answer_content}
          onChange={(event) => setValues({ ...values, answer_content: event.target.value })}
          invalid={hasError(errors, 'answer_content')}
        />
        {hasError(errors, 'answer_content') && <FormFeedback>{getError(errors, 'answer_content')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <AddFiles values={values as any} setValues={setValues} maxSizeInMb={50} />
      </FormGroup>
    </fieldset>
  );
};

export default ExecutorRequestFieldset;
