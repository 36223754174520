export type JudgementElementEntity = {
  id?: number;
  name?: string;
  category_id?: number;
  category_name?: string;
  content?: string;
  created_at?: string;
  updated_at?: string;
  is_deletable?: boolean;
};

export default class JudgementElement {
  readonly id?: number;
  readonly name?: string;
  readonly category_id?: number;
  readonly category_name?: string;
  readonly content?: string;
  readonly updated_at?: string;
  readonly created_at?: string;
  readonly is_deletable?: boolean;

  constructor(entity: JudgementElementEntity) {
    this.id = entity.id;
    this.name = entity.name;
    this.category_id = entity.category_id;
    this.category_name = entity.category_name;
    this.content = entity.content;
    this.updated_at = entity.updated_at;
    this.created_at = entity.created_at;
    this.is_deletable = entity.is_deletable;
  }

  static getFilterableAttributes(): string[] {
    return ['name'];
  }

  static getSortableAttributes(): string[] {
    return ['name'];
  }
}

export const createJudgementElement = (entity: JudgementElement): JudgementElement => new JudgementElement(entity);
