import { addToastAction, managePageAction } from 'modules/Layout/action';
import Form from 'modules/ExecutorOrder/component/OrderForm';
import React, { useEffect, useState, useRef } from 'react';
import Loader from 'modules/Layout/component/Loader';
import { useDispatch } from 'react-redux';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { updateRequestWithData } from 'modules/Shared/helper/APIRequests/updateRequest';
import { breadcrumbRouteOrderCreate } from 'modules/ExecutorOrder/breadcrumbs';
import { useLocation, useParams } from 'react-router-dom';
import { createOrderToastSuccess } from 'modules/ExecutorOrder/toasts';
import { createOrder } from 'modules/ExecutorOrder/repository';
import { ROUTE_ORDER } from 'modules/ExecutorOrder/routes';
import { OrderEntity } from 'modules/ExecutorOrder/model/Order';
import { ROUTE_EXECUTOR_ADMINS } from 'modules/Executor/routes';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { fetchAdminExecutor } from 'modules/Executor/repository';
import CardBox from 'modules/Layout/component/CardBox';

const OrderCreateView = () => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const { executor_id } = useParams<{ executor_id: string }>();
  const { state } = useLocation<{ executorName: string }>();
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState(null);
  const scrollToTopOnce = useRef<boolean>(true);

  const managePage = (executorName: string) =>
    dispatch(
      managePageAction({
        title: 'Dodawanie postępowania',
        breadcrumb: breadcrumbRouteOrderCreate(executor_id, executorName)
      })
    );

  const cancelToken = useCancelToken();
  useEffect(() => {
    if (state?.executorName) {
      managePage(state.executorName);
    } else {
      (async () => {
        const { data, cancelled } = await fetchRequest(fetchAdminExecutor, executor_id, cancelToken);
        if (cancelled) return;
        if (data) {
          managePage(data.data.name || data.data.nip);
        }
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchCreateOrderToast = () => dispatch(addToastAction(createOrderToastSuccess()));

  const setCreateOrderAction = async (data: OrderEntity) => {
    setFetching(true);

    if (message) setMessage(null);
    if (errors) setErrors(null);

    const {
      message: messageResponse,
      error,
      cancelled,
      data: responseData
    } = await updateRequestWithData(createOrder, executor_id, data, cancelToken);

    if (cancelled) return;

    if (responseData) {
      dispatchCreateOrderToast();
      const { id } = responseData.data;
      if (id) dispatch(push(getPathUrl(ROUTE_ORDER, { executor_id, id })));
      else setFetching(false);
    } else {
      if (error) setErrors(error);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <div className="user-create-view row justify-content-center">
      <div className="col-12">
        <CardBox heading="Dodaj naruszenie" form="small">
          {fetching && <Loader />}
          <Alert message={message} />
          <Form
            errors={errors}
            submit={(data) => setCreateOrderAction(data)}
            cancelRedirect={getPathUrl(ROUTE_EXECUTOR_ADMINS, { id: executor_id })}
          />
        </CardBox>
      </div>
    </div>
  );
};

export default OrderCreateView;
