import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';

// eslint-disable-next-line import/prefer-default-export
export const isValueDirty = (value: unknown, match: unknown, keys?: string[]): boolean => {
  if (keys) {
    return !_isEqual(_pick(value, keys), _pick(match, keys));
  }

  return !_isEqual(value, match);
};
