import React from 'react';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { suffixLabel, suffixValue } from 'modules/Layout/helper/misc';

export type Props = {
  moduleValues: string;
  setModuleValues?: React.Dispatch<React.SetStateAction<string>>;
  errors?: ValidationErrors;
  required?: boolean;
  placeholder?: boolean;
  title?: string;
};

const FieldsetCategoryBase: React.FC<Props> = (props: Props): JSX.Element => {
  const { moduleValues, setModuleValues, errors, required, placeholder = false, title = 'Nazwa' } = props;

  return (
    <FormGroup>
      {!placeholder && <Label for="name">{suffixLabel(title, required)}</Label>}
      <Input
        type="text"
        id="name"
        value={moduleValues}
        placeholder={placeholder ? suffixValue(title, required) : null}
        onChange={(event) => setModuleValues(event.target.value)}
        invalid={hasError(errors, 'name')}
        required={required}
      />
      {hasError(errors, 'name') && <FormFeedback>{getError(errors, 'name')}</FormFeedback>}
    </FormGroup>
  );
};

export default FieldsetCategoryBase;
