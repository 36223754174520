import React from 'react';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_ADDITIONAL_PROVISIONS } from 'modules/Agreements/step';
import { AgreementStepAdditionalProvisionsValues } from 'modules/Agreements/components/Steps/Default/AdditionalProvisions';
import classNames from 'classnames';

const AgreementStepPreviewAdditionalProvisions = (
  props: AgreementStepPreviewProps<AgreementStepAdditionalProvisionsValues>
): JSX.Element => {
  const { steps, modifiedStep } = props;
  const additionalProvisions = getStepValues<AgreementStepAdditionalProvisionsValues>(
    steps,
    AGREEMENT_STEP_ADDITIONAL_PROVISIONS
  );

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    additionalProvisions,
    modifiedStep,
    `checkboxes.general`
  );

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_ADDITIONAL_PROVISIONS)}</div>
      <div className="agreement-step-preview-content reset-preview-point">
        {generalCheckboxes &&
          generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-point wysiwyg-preview', {
                  modified: generalCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AgreementStepPreviewAdditionalProvisions;
