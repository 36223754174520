import React, { useState } from 'react';
import SendMessageForm from 'modules/ForeignProceedings/component/Details/SendMessage';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import { useDispatch } from 'react-redux';
import { addToastAction } from 'modules/Layout/action';
import ForeignProceedings from '../../model/ForeignProceedings';
import { FOREIGN_PROCEEDINGS_INIT_TYPES_VALUES } from '../../type';
import { manageForeignProceedingsFav } from '../../repository';
import {
  addForeignProceedingsToFavouriteToastSuccess,
  foreignProceedingsToastServerError,
  removeForeignProceedingsFromFavouriteToastSuccess
} from '../../toasts';
import ForeignProceedingsBasicDetails from './Basic';

type Props = {
  foreignProceedings: ForeignProceedings;
  setDisplayDeleteModal?: () => void;
  isAdmin?: boolean;
  isPublic?: boolean;
};

const ForeignProceedingsDetails: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { isAdmin, isPublic, foreignProceedings, setDisplayDeleteModal } = props;

  const displaySendNav =
    !isPublic && !isAdmin && foreignProceedings?.proceeding_type === FOREIGN_PROCEEDINGS_INIT_TYPES_VALUES.OPEN;

  const [isSaved, setIsSaved] = useState(foreignProceedings?.is_saved);
  const [isSaveLoading, setSaveLoading] = useState(false);

  const onFavouriteClick = async () => {
    if (isSaveLoading) return;
    setSaveLoading(true);

    try {
      const response = await manageForeignProceedingsFav(foreignProceedings.id);

      if (response.status === 204) {
        if (isSaved) dispatch(addToastAction(removeForeignProceedingsFromFavouriteToastSuccess()));
        else dispatch(addToastAction(addForeignProceedingsToFavouriteToastSuccess()));
        setIsSaved(!isSaved);
      }
    } catch (err) {
      dispatch(addToastAction(foreignProceedingsToastServerError()));
    }
    setSaveLoading(false);
  };

  const actionSavedBtn = () => (
    <ActionUpdate
      title="Dodaj do ulubionych postępowań"
      label={<i className="far font-20 fa-heart text-danger" />}
      onClick={onFavouriteClick}
    />
  );
  const actionNotSavedBtn = () => (
    <ActionUpdate
      title="Usuń z ulubionych postępowań"
      label={<i className="fas font-20 fa-heart text-danger" />}
      onClick={onFavouriteClick}
    />
  );
  const aditionalActions = () => {
    if (!isPublic) return <>{isSaved ? actionNotSavedBtn() : actionSavedBtn()}</>;
    return null;
  };

  return (
    <>
      <ForeignProceedingsBasicDetails
        foreignProceedings={foreignProceedings}
        setDisplayDeleteModal={setDisplayDeleteModal}
        isAdmin={isAdmin}
        aditionalActions={aditionalActions}
      />
      {displaySendNav && (
        <div className="user-details-view">
          <SendMessageForm foreignProceedings={foreignProceedings} />
        </div>
      )}
    </>
  );
};

export default ForeignProceedingsDetails;
