import React, { useEffect, useRef, useState } from 'react';
import { FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AGREEMENT_TYPE_SUPPLEMENT, AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_ACCEPT } from 'modules/Agreements/step';
import useStepFields from 'modules/Agreements/hook/useStepFields';

export interface AgreementStepAcceptValues {
  acceptance_possibility: boolean;
  partial_checked: boolean;
  final_checked: boolean;
  post_warranty_checked: boolean;
  others_checked: boolean;
  others_content: string | null;
  partial_schedule_checked: boolean;
  partial_schedule_content: string | null;
  partial_accept_checked: boolean;
  partial_accept_content: string | null;
  partial_procedure_possibility: boolean;
  partial_readiness_checked: boolean;
  partial_readiness_content: string | null;
  partial_participation_checked: boolean;
  partial_participation_content: string | null;
  partial_documents_checked: boolean;
  partial_documents_content: string | null;
  partial_protocol_checked: boolean;
  partial_protocol_content: string | null;
  partial_others_checked: boolean;
  partial_others_content: string | null;
  final_procedure_possibility: boolean;
  final_subject_checked: boolean;
  final_subject_content: string | null;
  final_readiness_checked: boolean;
  final_readiness_content: string | null;
  final_date_checked: boolean;
  final_date_content: string | null;
  final_participate_checked: boolean;
  final_participate_content: string | null;
  final_protocol_checked: boolean;
  final_protocol_content: string | null;
  final_protocol_place_checked: boolean;
  final_protocol_place_content: string | null;
  final_protocol_date_checked: boolean;
  final_protocol_date_content: string | null;
  final_protocol_people_checked: boolean;
  final_protocol_people_content: string | null;
  final_protocol_documents_checked: boolean;
  final_protocol_documents_content: string | null;
  final_protocol_compatibility_checked: boolean;
  final_protocol_compatibility_content: string | null;
  final_protocol_defects_checked: boolean;
  final_protocol_defects_content: string | null;
  final_protocol_decisions_checked: boolean;
  final_protocol_decisions_content: string | null;
  final_protocol_statements_checked: boolean;
  final_protocol_statements_content: string | null;
  final_protocol_signatures_checked: boolean;
  final_protocol_signatures_content: string | null;
  final_others_checked: boolean;
  final_others_content: string | null;
  documents_possibility: boolean;
  documents_log_building_checked: boolean;
  documents_log_building_content: string | null;
  documents_statements_checked: boolean;
  documents_statements_content: string | null;
  documents_drawings_checked: boolean;
  documents_drawings_content: string | null;
  documents_cleaning_checked: boolean;
  documents_cleaning_content: string | null;
  documents_documentation_checked: boolean;
  documents_documentation_content: string | null;
  documents_decision_use_checked: boolean;
  documents_decision_use_content: string | null;
  documents_decision_water_law_checked: boolean;
  documents_decision_water_law_content: string | null;
  documents_certificates_checked: boolean;
  documents_certificates_content: string | null;
  documents_post_warranty_checked: boolean;
  documents_post_warranty_content: string | null;
  documents_others_checked: boolean;
  documents_others_content: string | null;
  accept_refuse_checked: boolean;
  accept_refuse_content: string | null;
  not_remove_defects_possibility: boolean;
  salary_reduction_checked: boolean;
  salary_reduction_content: string | null;
  closest_date_checked: boolean;
  closest_date_content: string | null;
  second_request_checked: boolean;
  second_request_content: string | null;
  not_remove_defects_others_checked: boolean;
  not_remove_defects_others_content: string | null;
  small_defects_checked: boolean;
  small_defects_content: string | null;
  subject_take_over_checked: boolean;
  subject_take_over_content: string | null;
  representative_accept_checked: boolean;
  representative_accept_content: string | null;
  representation_checked: boolean;
  representation_content: string | null;
  representation_executor_checked: boolean;
  representation_executor_content: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const OTHERS_CONTENT = 'inne: ....';
const PARTIAL_SCHEDULE_CONTENT =
  'Odbiory częściowe robót dokonywane będą zgodnie z etapami wymienionymi w Harmonogramie. Podpisanie przez zamawiającego protokołów odbioru robót nie oznacza braku odpowiedzialności wykonawcy za wady, uszkodzenia lub usterki, które mogą się ujawnić w dalszym ciągu realizacji lub użytkowania jakiejkolwiek części lub całości inwestycji.';
const PARTIAL_ACCEPT_CONTENT =
  'Podpisanie przez zamawiającego protokołów odbioru robót częściowych zgodnie z Harmonogramem oraz zapłata związanego z tym wynagrodzenia, nie zwalnia wykonawcy z obowiązku należytego dbania, zabezpieczenia oraz ponoszenia wszelkich kosztów związanych z utrzymaniem tych robót, do momentu dokonania Odbioru Ostatecznego przez zamawiającego. Oznacza to pełną odpowiedzialność wykonawcy wobec zamawiającego za właściwe utrzymanie wykonanych robót do momentu Odbioru Ostatecznego.';
const PARTIAL_READINESS_CONTENT = 'Wykonawca zgłosi na piśmie zamawiającemu gotowość do obioru częściowego.';
const PARTIAL_PARTICIPATION_CONTENT =
  'Wykonawca zobowiązuje się brać czynny udział – poprzez osoby uprawnione w odbiorze, w terminie wyznaczonym przez zamawiającego.';
const PARTIAL_DOCUMENTS_CONTENT =
  'Wykonawca przedłoży zamawiającemu komplet dokumentów dotyczących odbieranych robót wymaganych przepisami prawa i niniejszą Umową.';
const PARTIAL_PROTOCOL_CONTENT =
  'Z czynności odbioru częściowego sporządza się stosowny protokół, który będzie podstawą do wystawienia faktury częściowej i zapłaty części wynagrodzenia na warunkach określonych w Umowie.';
const PARTIAL_OTHERS_CONTENT = 'inne: ....';
const FINAL_SUBJECT_CONTENT = 'Przedmiotem Odbioru Ostatecznego będzie Przedmiot Umowy.';
const FINAL_READINESS_CONTENT = 'Wykonawca zgłosi na piśmie zamawiającemu gotowość do Obioru Ostatecznego.';
const FINAL_DATE_CONTENT =
  'Zamawiający wyznaczy datę i rozpocznie czynności odbioru przy udziale uprawnionych przedstawicieli wykonawcy w ciągu ........ dni od daty zgłoszenia gotowości do odbioru.';
const FINAL_PARTICIPATE_CONTENT =
  'Wykonawca zobowiązuje się brać czynny udział poprzez osoby uprawnione w odbiorze, w terminie wyznaczonym przez zamawiającego.';
const FINAL_PROTOCOL_CONTENT =
  'Z czynności Odbioru Ostatecznego sporządzony będzie Protokół Odbioru Ostatecznego, który powinien zawierać ustalenia poczynione w toku odbioru, a w szczególności:';
const FINAL_PROTOCOL_PLACE_CONTENT = 'Oznaczenie miejsca sporządzenia.';
const FINAL_PROTOCOL_DATE_CONTENT = 'Datę rozpoczęcia i zakończenia odbioru.';
const FINAL_PROTOCOL_PEOPLE_CONTENT = 'Oznaczenie osób uczestniczących w odbiorze i charakteru, w jakim uczestniczą.';
const FINAL_PROTOCOL_DOCUMENTS_CONTENT =
  'Wymienienie dokumentów przygotowanych przez wykonawcę i przekazanych zamawiającemu.';
const FINAL_PROTOCOL_COMPATIBILITY_CONTENT =
  'Ustalenia co do zgodności wykonanych robót z Umową, przekazaną dokumentacją, zasadami wiedzy technicznej i powszechnie obowiązującymi przepisami prawa.';
const FINAL_PROTOCOL_DEFECTS_CONTENT = 'Wymienienie ujawnionych wad.';
const FINAL_PROTOCOL_DECISIONS_CONTENT =
  'Decyzje zamawiającego co do przyjęcia lub odmowy przyjęcia Przedmiotu Umowy, terminu usunięcia wad, propozycje obniżenia wynagrodzenia wykonawcy.';
const FINAL_PROTOCOL_STATEMENTS_CONTENT = 'Oświadczenia i wyjaśnienia wykonawcy i osób uczestniczących w odbiorze.';
const FINAL_PROTOCOL_SIGNATURES_CONTENT = 'Podpisy osób uczestniczących w odbiorze.';
const FINAL_OTHERS_CONTENT = 'inne: ....';
const DOCUMENTS_LOG_BUILDING_CONTENT = 'Dziennik budowy.';
const DOCUMENTS_STATEMENTS_CONTENT = 'Oświadczenia kierowników robót poszczególnych branż potwierdzające, że:';
const DOCUMENTS_DRAWINGS_CONTENT =
  'Roboty, w tym końcowe próby zostały wykonane zgodnie z Umową oraz końcowymi rysunkami wykonawczymi.';
const DOCUMENTS_CLEANING_CONTENT =
  'Teren Budowy (jak również wszystkie sąsiadujące drogi, budynki i nieruchomości używane przez wykonawcę) zostały należycie naprawione, posprzątane, uporządkowane i są czyste.';
const DOCUMENTS_DOCUMENTATION_CONTENT =
  'Pełną Dokumentację Powykonawczą - w szczególności, Projekt budowlany oraz Projekt wykonawczy ze zmianami dokonanymi w toku realizacji....';
const DOCUMENTS_DECISION_USE_CONTENT =
  'Decyzję o pozwoleniu na użytkowanie wraz z wszystkimi pozytywnymi stanowiskami i opiniami Inspekcji Ochrony Środowiska, Państwowej Inspekcji Pracy, Państwowej Inspekcji Sanitarnej, inspekcji przeciwpożarowej, itp. zgodnie z wymogami obowiązującego prawa.';
const DOCUMENTS_DECISION_WATER_LAW_CONTENT = 'Decyzję o pozwoleniu wodnoprawnym.';
const DOCUMENTS_CERTIFICATES_CONTENT =
  'Wszystkie certyfikaty zgodności oraz certyfikaty wykorzystywanych materiałów i urządzeń.';
const DOCUMENTS_POST_WARRANTY_CONTENT = 'Dokumenty gwarancyjne.';
const DOCUMENTS_OTHERS_CONTENT = 'inne: ....';
const ACCEPT_REFUSE_CONTENT =
  'Zamawiający może odmówić dokonania Odbioru Ostatecznego, jeżeli zawierają wady uniemożliwiające lub istotnie utrudniające korzystanie z Przedmiotu Umowy. W takim przypadku zamawiający określi termin, w jakim wykonawca usunie wady. Po upływie powyższego terminu, wykonane Roboty zostaną ponownie poddane kontroli i jeżeli zamawiający nie stwierdzi wad, nastąpi Odbiór Ostateczny.';
const SALARY_REDUCTION_CONTENT =
  'Odbioru Przedmiotu Umowy i obniżenia wynagrodzenia o kwotę, jaka zaniżyła wartość wykonanych robót na skutek wykrytych wad.';
const CLOSEST_DATE_CONTENT =
  'Odbioru Przedmiotu Umowy pod warunkiem, że usunięcie wad nastąpi w dalszym terminie wskazanym przez zamawiającego. W takim razie, Pousterkowy Protokół Odbioru zostanie podpisany po usunięciu takich wad.';
const SECOND_REQUEST_CONTENT =
  'zażądać wykonania Przedmiotu Umowy (lub poszczególnych elementów wchodzących w zakres całego Przedmiotu Umowy) po raz drugi na koszt wykonawcy, jeżeli wady uniemożliwiają użytkowanie obiektu zgodnie z jego przeznaczeniem.';
const NOT_REMOVE_DEFECTS_OTHERS_CONTENT = 'inne: ....';
const SMALL_DEFECTS_CONTENT =
  'Drobne wady, które są bez znaczenia dla użytkowania Przedmiotu Umowy, nie uprawniają zamawiającego do odmowy odbioru Przedmiotu Umowy ani jakiejkolwiek ich części. W razie wystąpienia takich wad, zostaną one ujęte w Protokole Odbioru i niezwłocznie usunięte przez wykonawcę. Jeśli wykonawca ich nie usunie, zamawiający będzie uprawniony do obniżenia wynagrodzenia o kwoty konieczne do ich usunięcia własnym staraniem zamawiającego.';
const SUBJECT_TAKE_OVER_CONTENT =
  'Z chwilą dokonania Odbioru Ostatecznego i podpisania Protokołu Odbioru zamawiający przejmie od wykonawcy Przedmiot Umowy.';
const REPRESENTATIVE_ACCEPT_CONTENT =
  'Odbioru pogwarancyjnego dokonuje przedstawiciel Zamawiającego w ciągu ........ dni od upływu terminu Gwarancji jakości i rękojmi wraz z przedstawicielem wykonawcy. Celem odbioru pogwarancyjnego jest pokwitowanie wypełnienia przez wykonawcę obowiązków z tytułu udzielonej Gwarancji jakości i rękojmi za Przedmiot Umowy.';
const REPRESENTATION_CONTENT = 'Zamawiającego przy odbiorach reprezentować będą: ........';
const REPRESENTATION_EXECUTOR_CONTENT = 'Wykonawcę przy odbiorach reprezentować będą: ........';

const initContentValues = {
  others_content: OTHERS_CONTENT,
  partial_schedule_content: PARTIAL_SCHEDULE_CONTENT,
  partial_accept_content: PARTIAL_ACCEPT_CONTENT,
  partial_readiness_content: PARTIAL_READINESS_CONTENT,
  partial_participation_content: PARTIAL_PARTICIPATION_CONTENT,
  partial_documents_content: PARTIAL_DOCUMENTS_CONTENT,
  partial_protocol_content: PARTIAL_PROTOCOL_CONTENT,
  partial_others_content: PARTIAL_OTHERS_CONTENT,
  final_subject_content: FINAL_SUBJECT_CONTENT,
  final_readiness_content: FINAL_READINESS_CONTENT,
  final_date_content: FINAL_DATE_CONTENT,
  final_participate_content: FINAL_PARTICIPATE_CONTENT,
  final_protocol_content: FINAL_PROTOCOL_CONTENT,
  final_protocol_place_content: FINAL_PROTOCOL_PLACE_CONTENT,
  final_protocol_date_content: FINAL_PROTOCOL_DATE_CONTENT,
  final_protocol_people_content: FINAL_PROTOCOL_PEOPLE_CONTENT,
  final_protocol_documents_content: FINAL_PROTOCOL_DOCUMENTS_CONTENT,
  final_protocol_compatibility_content: FINAL_PROTOCOL_COMPATIBILITY_CONTENT,
  final_protocol_defects_content: FINAL_PROTOCOL_DEFECTS_CONTENT,
  final_protocol_decisions_content: FINAL_PROTOCOL_DECISIONS_CONTENT,
  final_protocol_statements_content: FINAL_PROTOCOL_STATEMENTS_CONTENT,
  final_protocol_signatures_content: FINAL_PROTOCOL_SIGNATURES_CONTENT,
  final_others_content: FINAL_OTHERS_CONTENT,
  documents_log_building_content: DOCUMENTS_LOG_BUILDING_CONTENT,
  documents_statements_content: DOCUMENTS_STATEMENTS_CONTENT,
  documents_drawings_content: DOCUMENTS_DRAWINGS_CONTENT,
  documents_cleaning_content: DOCUMENTS_CLEANING_CONTENT,
  documents_documentation_content: DOCUMENTS_DOCUMENTATION_CONTENT,
  documents_decision_use_content: DOCUMENTS_DECISION_USE_CONTENT,
  documents_decision_water_law_content: DOCUMENTS_DECISION_WATER_LAW_CONTENT,
  documents_certificates_content: DOCUMENTS_CERTIFICATES_CONTENT,
  documents_post_warranty_content: DOCUMENTS_POST_WARRANTY_CONTENT,
  documents_others_content: DOCUMENTS_OTHERS_CONTENT,
  accept_refuse_content: ACCEPT_REFUSE_CONTENT,
  salary_reduction_content: SALARY_REDUCTION_CONTENT,
  closest_date_content: CLOSEST_DATE_CONTENT,
  second_request_content: SECOND_REQUEST_CONTENT,
  not_remove_defects_others_content: NOT_REMOVE_DEFECTS_OTHERS_CONTENT,
  small_defects_content: SMALL_DEFECTS_CONTENT,
  subject_take_over_content: SUBJECT_TAKE_OVER_CONTENT,
  representative_accept_content: REPRESENTATIVE_ACCEPT_CONTENT,
  representation_content: REPRESENTATION_CONTENT,
  representation_executor_content: REPRESENTATION_EXECUTOR_CONTENT
};

const mapState = (step: AgreementStepAcceptValues): AgreementStepAcceptValues => {
  const {
    acceptance_possibility,
    final_checked,
    final_procedure_possibility,
    final_subject_checked,
    final_readiness_checked,
    final_date_checked,
    others_content,
    partial_schedule_content,
    partial_accept_content,
    partial_readiness_content,
    partial_participation_content,
    partial_documents_content,
    partial_protocol_content,
    partial_others_content,
    final_subject_content,
    final_readiness_content,
    final_date_content,
    final_participate_content,
    final_protocol_content,
    final_protocol_place_content,
    final_protocol_date_content,
    final_protocol_people_content,
    final_protocol_documents_content,
    final_protocol_compatibility_content,
    final_protocol_defects_content,
    final_protocol_decisions_content,
    final_protocol_statements_content,
    final_protocol_signatures_content,
    final_others_content,
    documents_log_building_content,
    documents_statements_content,
    documents_drawings_content,
    documents_cleaning_content,
    documents_documentation_content,
    documents_decision_use_content,
    documents_decision_water_law_content,
    documents_certificates_content,
    documents_post_warranty_content,
    documents_others_content,
    accept_refuse_content,
    salary_reduction_content,
    closest_date_content,
    second_request_content,
    not_remove_defects_others_content,
    small_defects_content,
    subject_take_over_content,
    representative_accept_content,
    representation_content,
    representation_executor_content,
    checkboxes,
    ...rest
  } = step;

  return {
    ...rest,
    acceptance_possibility: acceptance_possibility || true,
    final_checked: final_checked || true,
    final_procedure_possibility: final_procedure_possibility || true,
    final_subject_checked: final_subject_checked || true,
    final_readiness_checked: final_readiness_checked || true,
    final_date_checked: final_date_checked || true,
    others_content: others_content || OTHERS_CONTENT,
    partial_schedule_content: partial_schedule_content || PARTIAL_SCHEDULE_CONTENT,
    partial_accept_content: partial_accept_content || PARTIAL_ACCEPT_CONTENT,
    partial_readiness_content: partial_readiness_content || PARTIAL_READINESS_CONTENT,
    partial_participation_content: partial_participation_content || PARTIAL_PARTICIPATION_CONTENT,
    partial_documents_content: partial_documents_content || PARTIAL_DOCUMENTS_CONTENT,
    partial_protocol_content: partial_protocol_content || PARTIAL_PROTOCOL_CONTENT,
    partial_others_content: partial_others_content || PARTIAL_OTHERS_CONTENT,
    final_subject_content: final_subject_content || FINAL_SUBJECT_CONTENT,
    final_readiness_content: final_readiness_content || FINAL_READINESS_CONTENT,
    final_date_content: final_date_content || FINAL_DATE_CONTENT,
    final_participate_content: final_participate_content || FINAL_PARTICIPATE_CONTENT,
    final_protocol_content: final_protocol_content || FINAL_PROTOCOL_CONTENT,
    final_protocol_place_content: final_protocol_place_content || FINAL_PROTOCOL_PLACE_CONTENT,
    final_protocol_date_content: final_protocol_date_content || FINAL_PROTOCOL_DATE_CONTENT,
    final_protocol_people_content: final_protocol_people_content || FINAL_PROTOCOL_PEOPLE_CONTENT,
    final_protocol_documents_content: final_protocol_documents_content || FINAL_PROTOCOL_DOCUMENTS_CONTENT,
    final_protocol_compatibility_content: final_protocol_compatibility_content || FINAL_PROTOCOL_COMPATIBILITY_CONTENT,
    final_protocol_defects_content: final_protocol_defects_content || FINAL_PROTOCOL_DEFECTS_CONTENT,
    final_protocol_decisions_content: final_protocol_decisions_content || FINAL_PROTOCOL_DECISIONS_CONTENT,
    final_protocol_statements_content: final_protocol_statements_content || FINAL_PROTOCOL_STATEMENTS_CONTENT,
    final_protocol_signatures_content: final_protocol_signatures_content || FINAL_PROTOCOL_SIGNATURES_CONTENT,
    final_others_content: final_others_content || FINAL_OTHERS_CONTENT,
    documents_log_building_content: documents_log_building_content || DOCUMENTS_LOG_BUILDING_CONTENT,
    documents_statements_content: documents_statements_content || DOCUMENTS_STATEMENTS_CONTENT,
    documents_drawings_content: documents_drawings_content || DOCUMENTS_DRAWINGS_CONTENT,
    documents_cleaning_content: documents_cleaning_content || DOCUMENTS_CLEANING_CONTENT,
    documents_documentation_content: documents_documentation_content || DOCUMENTS_DOCUMENTATION_CONTENT,
    documents_decision_use_content: documents_decision_use_content || DOCUMENTS_DECISION_USE_CONTENT,
    documents_decision_water_law_content: documents_decision_water_law_content || DOCUMENTS_DECISION_WATER_LAW_CONTENT,
    documents_certificates_content: documents_certificates_content || DOCUMENTS_CERTIFICATES_CONTENT,
    documents_post_warranty_content: documents_post_warranty_content || DOCUMENTS_POST_WARRANTY_CONTENT,
    documents_others_content: documents_others_content || DOCUMENTS_OTHERS_CONTENT,
    accept_refuse_content: accept_refuse_content || ACCEPT_REFUSE_CONTENT,
    salary_reduction_content: salary_reduction_content || SALARY_REDUCTION_CONTENT,
    closest_date_content: closest_date_content || CLOSEST_DATE_CONTENT,
    second_request_content: second_request_content || SECOND_REQUEST_CONTENT,
    not_remove_defects_others_content: not_remove_defects_others_content || NOT_REMOVE_DEFECTS_OTHERS_CONTENT,
    small_defects_content: small_defects_content || SMALL_DEFECTS_CONTENT,
    subject_take_over_content: subject_take_over_content || SUBJECT_TAKE_OVER_CONTENT,
    representative_accept_content: representative_accept_content || REPRESENTATIVE_ACCEPT_CONTENT,
    representation_content: representation_content || REPRESENTATION_CONTENT,
    representation_executor_content: representation_executor_content || REPRESENTATION_EXECUTOR_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepAccept = (props: AgreementStepProps): JSX.Element => {
  const { steps, onSubmit, onChange, errors, agreement } = props;
  const initState = useRef<AgreementStepAcceptValues>(mapState(getStepValues(steps, AGREEMENT_STEP_ACCEPT)));
  const [stepValues, setStepValues] = useState<AgreementStepAcceptValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_ACCEPT));
    setStepValues({ ...initState.current });
  }, [steps]);

  const { renderCheckbox, renderEditableCheckbox, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    initContentValues
  });

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        {[
          renderCheckbox(
            'acceptance_possibility',
            'W ramach realizacji niniejszej Umowy występować będą następujące odbiory:',
            true
          ),
          stepValues.acceptance_possibility && [
            <div className="pl-3">
              {[
                renderCheckbox('partial_checked', 'Odbiory częściowe', agreement.type === AGREEMENT_TYPE_SUPPLEMENT),
                renderCheckbox('final_checked', 'Odbiór Ostateczny Przedmiotu Umowy', true),
                renderCheckbox(
                  'post_warranty_checked',
                  'Odbiór pogwarancyjny',
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderEditableCheckbox(
                  'others',
                  null,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                )
              ]}
            </div>,
            stepValues.partial_checked && [
              renderCheckbox(
                'partial_schedule_checked',
                stepValues.partial_schedule_content,
                agreement.type === AGREEMENT_TYPE_SUPPLEMENT
              ),
              renderCheckbox(
                'partial_accept_checked',
                stepValues.partial_accept_content,
                agreement.type === AGREEMENT_TYPE_SUPPLEMENT
              ),
              renderCheckbox(
                'partial_procedure_possibility',
                'Procedura odbioru częściowego:',
                agreement.type === AGREEMENT_TYPE_SUPPLEMENT
              ),
              stepValues.partial_procedure_possibility && (
                <div className="pl-3">
                  {[
                    renderCheckbox(
                      'partial_readiness_checked',
                      stepValues.partial_readiness_content,
                      agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                    ),
                    renderCheckbox(
                      'partial_participation_checked',
                      stepValues.partial_participation_content,
                      agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                    ),
                    renderCheckbox(
                      'partial_documents_checked',
                      stepValues.partial_documents_content,
                      agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                    ),
                    renderCheckbox(
                      'partial_protocol_checked',
                      stepValues.partial_protocol_content,
                      agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                    ),
                    renderEditableCheckbox(
                      'partial_others',
                      null,
                      agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                      agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                    )
                  ]}
                </div>
              )
            ],
            stepValues.final_checked && [
              renderCheckbox('final_procedure_possibility', 'Procedura Odbioru Ostatecznego Przedmiotu Umowy:', true),
              stepValues.final_procedure_possibility && (
                <div className="pl-3">
                  {[
                    renderCheckbox('final_subject_checked', stepValues.final_subject_content, true),
                    renderCheckbox('final_readiness_checked', stepValues.final_readiness_content, true),
                    renderEditableCheckbox('final_date', null, true, agreement.type === AGREEMENT_TYPE_SUPPLEMENT),
                    renderCheckbox(
                      'final_participate_checked',
                      stepValues.final_participate_content,
                      agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                    ),
                    renderCheckbox(
                      'final_protocol_checked',
                      stepValues.final_protocol_content,
                      agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                    ),
                    stepValues.final_protocol_checked && (
                      <div className="pl-3">
                        {[
                          renderCheckbox(
                            'final_protocol_place_checked',
                            stepValues.final_protocol_place_content,
                            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                          ),
                          renderCheckbox(
                            'final_protocol_date_checked',
                            stepValues.final_protocol_date_content,
                            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                          ),
                          renderCheckbox(
                            'final_protocol_people_checked',
                            stepValues.final_protocol_people_content,
                            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                          ),
                          renderCheckbox(
                            'final_protocol_documents_checked',
                            stepValues.final_protocol_documents_content,
                            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                          ),
                          renderCheckbox(
                            'final_protocol_compatibility_checked',
                            stepValues.final_protocol_compatibility_content,
                            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                          ),
                          renderCheckbox(
                            'final_protocol_defects_checked',
                            stepValues.final_protocol_defects_content,
                            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                          ),
                          renderCheckbox(
                            'final_protocol_decisions_checked',
                            stepValues.final_protocol_decisions_content,
                            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                          ),
                          renderCheckbox(
                            'final_protocol_statements_checked',
                            stepValues.final_protocol_statements_content,
                            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                          ),
                          renderCheckbox(
                            'final_protocol_signatures_checked',
                            stepValues.final_protocol_signatures_content,
                            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                          )
                        ]}
                      </div>
                    ),
                    renderEditableCheckbox(
                      'final_others',
                      null,
                      agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                      agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                    )
                  ]}
                </div>
              )
            ]
          ],
          <hr />,
          renderCheckbox(
            'documents_possibility',
            'Wraz z zawiadomieniem o gotowości do przekazania robót do Odbioru Ostatecznego, wykonawca przedłoży następujące dokumenty:',
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          stepValues.documents_possibility && (
            <div className="pl-3">
              {[
                renderCheckbox(
                  'documents_log_building_checked',
                  stepValues.documents_log_building_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderCheckbox(
                  'documents_statements_checked',
                  stepValues.documents_statements_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                stepValues.documents_statements_checked && (
                  <div className="pl-3">
                    {[
                      renderCheckbox(
                        'documents_drawings_checked',
                        stepValues.documents_drawings_content,
                        agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                      ),
                      renderCheckbox(
                        'documents_cleaning_checked',
                        stepValues.documents_cleaning_content,
                        agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                      )
                    ]}
                  </div>
                ),
                renderCheckbox(
                  'documents_documentation_checked',
                  stepValues.documents_documentation_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderCheckbox(
                  'documents_decision_use_checked',
                  stepValues.documents_decision_use_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderCheckbox(
                  'documents_decision_water_law_checked',
                  stepValues.documents_decision_water_law_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderCheckbox(
                  'documents_certificates_checked',
                  stepValues.documents_certificates_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderCheckbox(
                  'documents_post_warranty_checked',
                  stepValues.documents_post_warranty_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderEditableCheckbox(
                  'documents_others',
                  null,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                )
              ]}
            </div>
          ),
          <hr />,
          renderCheckbox(
            'accept_refuse_checked',
            stepValues.accept_refuse_content,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          <hr />,
          renderCheckbox(
            'not_remove_defects_possibility',
            'Jeżeli w terminie wyznaczonym przez zamawiającego wykonawca nie usunie wad ujawnionych w toku procedury Odbioru Ostatecznego, zamawiający będzie uprawniony do:',
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          stepValues.not_remove_defects_possibility && (
            <div className="pl-3">
              {[
                renderCheckbox(
                  'salary_reduction_checked',
                  stepValues.salary_reduction_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderCheckbox(
                  'closest_date_checked',
                  stepValues.closest_date_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderCheckbox(
                  'second_request_checked',
                  stepValues.second_request_content,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderEditableCheckbox(
                  'not_remove_defects_others',
                  null,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                )
              ]}
            </div>
          ),
          <hr />,
          renderCheckbox(
            'small_defects_checked',
            stepValues.small_defects_content,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          renderCheckbox(
            'subject_take_over_checked',
            stepValues.subject_take_over_content,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          stepValues.post_warranty_checked &&
            renderEditableCheckbox(
              'representative_accept',
              null,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT
            ),
          renderEditableCheckbox(
            'representation',
            null,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          renderEditableCheckbox(
            'representation_executor',
            null,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          renderCustomCheckboxes(
            null,
            null,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )
        ]}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepAccept;
