import React, { useEffect, useRef, useState } from 'react';
import { AGREEMENT_TYPE_SUPPLEMENT, AgreementStepProps } from 'modules/Agreements/type';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import StepImportDataButton from 'modules/Agreements/components/StepImportDataButton';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_PREAMBLE } from 'modules/Agreements/step';
import { FormFeedback, FormGroup, Input } from 'reactstrap';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { isEqual } from 'lodash';
import { IMPORT_ORDERS_ACCESS } from '../../../../../Auth/container/Guard/permissions';
import Authorize from '../../../../../Auth/container/Authorize';

export interface AgreementStepPreambleValues {
  proceeding_id?: number;
  proceeding_name?: string;
  project_name?: string;
  eu_co_financing?: boolean;
  co_financing_project_ids: number[];
  co_financing_project_names: string[];
  proceeding?: {
    id: number;
    name: string;
    identifier: string;
  };
  [key: string]: any;
}

export interface AgreementStepPreambleImportValues extends AgreementStepPreambleValues {}

const mapState = (step: AgreementStepPreambleValues): AgreementStepPreambleValues => {
  const { co_financing_project_ids, co_financing_project_names, checkboxes, ...rest } = step;

  return {
    ...rest,
    co_financing_project_ids: co_financing_project_ids || [],
    co_financing_project_names: co_financing_project_names || [],
    checkboxes: checkboxes || {}
  };
};

const AgreementStepPreamble = (props: AgreementStepProps): JSX.Element => {
  const { steps, stepsImportData, onSubmit, onChange, errors, agreement } = props;
  const initState = useRef<AgreementStepPreambleValues>(mapState(getStepValues(steps, AGREEMENT_STEP_PREAMBLE)));
  const [stepValues, setStepValues] = useState<AgreementStepPreambleValues>({ ...initState.current });
  const { proceeding_name } = stepValues;
  const stepDataImportValues: AgreementStepPreambleImportValues = getStepValues(
    stepsImportData,
    AGREEMENT_STEP_PREAMBLE
  );

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_PREAMBLE));
    setStepValues({ ...initState.current });
  }, [steps]);

  const onStepDataImportClick = () => {
    setStepValues((values) => {
      return {
        ...values,
        ...stepDataImportValues
      };
    });
  };

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <Authorize permissions={[IMPORT_ORDERS_ACCESS]}>
        {agreement.type !== AGREEMENT_TYPE_SUPPLEMENT && (
          <StepImportDataButton onSubmit={onStepDataImportClick} stepDataImportValues={stepDataImportValues} />
        )}
      </Authorize>
      <p className="h4">Nazwa postępowania:</p>
      <FormGroup>
        <Input
          type="text"
          id="proceeding_name"
          value={proceeding_name || ''}
          invalid={hasError(errors, 'proceeding_name')}
          disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
          onChange={(event) => {
            event.persist();
            setStepValues((values) => ({
              ...values,
              proceeding_name: event.target.value
            }));
          }}
        />
        {hasError(errors, 'proceeding_name') && (
          <FormFeedback className="d-block">{getError(errors, 'proceeding_name')}</FormFeedback>
        )}
      </FormGroup>
    </AgreementFormWrapper>
  );
};

export default AgreementStepPreamble;
