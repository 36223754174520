export type ClauseEntity = {
  id?: number;
  category?: { id: number; name: string };
  name?: string;
  description?: string;
  keywords_names?: string;
  keywords?: { id: number; name: string }[];
  created_at?: string;
  updated_at?: string;
};

export default class Clause {
  readonly id?: number;
  readonly category?: { id: number; name: string };
  readonly name?: string;
  readonly description?: string;
  readonly keywords_names?: string;
  readonly keywords?: { id: number; name: string }[];
  readonly created_at?: string;
  readonly updated_at?: string;

  constructor(entity: ClauseEntity) {
    this.id = entity.id;
    this.category = entity.category;
    this.name = entity.name;
    this.description = entity.description;
    this.keywords_names = entity.keywords_names;
    this.keywords = entity.keywords;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
    this.keywords_names = entity.keywords_names;
  }

  static getFilterableAttributes(): string[] {
    return ['id', 'name', 'descritpion', 'category_id', 'keyword_id', 'categories[]', 'keywords[]'];
  }

  static getSortableAttributes(): string[] {
    return ['id', 'name', 'description', 'category_name'];
  }
}

export const createClause = (entity: Clause): Clause => new Clause(entity);
