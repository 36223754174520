import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';

export const deleteExecutorToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja bazy wykonawców',
  body: 'Wykonawca został usunięty',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteViolationToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja bazy naruszeń wykonawca',
  body: 'Naruszenie zostało usuniętę',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateExecutorToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja bazy wykonawców',
  body: 'Wykonawca został zaktualizowany.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createExecutorToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja bazy wykonawców',
  body: 'Wykonawca został pomyślnie utworzony.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const executorRaportDownloadToastSuccess = (): AddToastPayload => ({
  header: 'Pobieranie',
  body: 'Pomyślnie pobrano raport.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const executorRaportDownloadToastError = (message: string): AddToastPayload => ({
  header: 'Pobieranie',
  body: `Wystąpił błąd: ${message}`,
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const executorExplanationUpdateToastError = (): AddToastPayload => ({
  header: 'Aktualizacja bazy wykonawców',
  body: `Wykonawca został usunięty`,
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const executorExplanationSectionUpdateToastSuccess = (): AddToastPayload => ({
  header: 'Aktualizacja bazy wykonawców',
  body: 'Pomyślnie zaktualizano wyjaśnienia sekcji raportu.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const executorFromGusToastSuccess = (): AddToastPayload => ({
  header: null,
  body: 'NIP znaleziono w rejestrze GUS. Dane zostały uzupełnione.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const executorFromGusToastError = (): AddToastPayload => ({
  header: null,
  body: `Nie udało się pobrać danych z rejestru GUS. Proszę uzupełnić dane ręcznie lub skontaktować się z pomocą techniczną.`,
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});
