import CardBox from 'modules/Layout/component/CardBox';
import { ROUTE_SUBSCRIPTIONS } from 'modules/Subscriptions/routes';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'modules/Layout/component/Loader';
import { fetchModuleDeniedExecutors, ModuleDeniedExecutorResponse } from 'modules/Layout/repository';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { ColorVariants, Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import { PageProps } from 'modules/Layout/type';
import { useDispatch } from 'react-redux';
import { managePageAction } from 'modules/Layout/action';
import { breadcrumbRouteExecutors } from 'modules/Executor/breadcrumbs';
import './index.scss';

const ExecutorsAccessDenied = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState<ModuleDeniedExecutorResponse>(null);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  const cancelToken = useCancelToken();
  useEffect(() => {
    managePage({
      title: 'Baza wykonawców, w stosunku do których mogą wystąpić podstawy do wykluczenia',
      breadcrumb: breadcrumbRouteExecutors()
    });

    (async () => {
      setFetching(true);
      try {
        const response = await fetchModuleDeniedExecutors(cancelToken);

        if (response?.data) {
          setData(response.data);
        }
        setFetching(false);
      } catch (error) {
        if (error?.message === 'AxiosCancel') {
          return;
        }

        setMessage({
          value: error?.error?.response?.data?.message || error?.message || 'Something went wrong.',
          variant: ColorVariants.Danger
        });
        setFetching(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex justify-content-center executors-access-denied">
      <CardBox
        heading="Brak dostępu"
        paddingLeft="30px"
        paddingRight="30px"
        paddingTop="25px"
        paddingBottom="40px"
        childrenPaddingTop="144px"
      >
        {fetching && <Loader />}
        <Alert message={message} />
        <div className="medium-margins">
          <div className="denied-title font-poppins">
            Aby zobaczyć bazę musisz
            <br />
            mieć wykupiony abonament.
          </div>
        </div>
        <div className="big-margins">
          <div className="d-flex">
            <Link to={ROUTE_SUBSCRIPTIONS} className="btn btn-primary buy-subscription w-100">
              Zakup subskrypcji
            </Link>
          </div>
        </div>
        <div className="medium-margins">
          <div className="denied-description">
            Obecnie obsługujemy <span className="text-primary counter">{data?.data?.applications_count ?? 0}</span>{' '}
            złożonych wniosków o udostępnienie informacji publicznych oraz posiadamy{' '}
            <span className="text-primary counter">{data?.data?.executors_count ?? 0}</span> wykonawców, w stosunku do
            których mogą wystąpić podstawy do wykluczenia w rejestrze.
          </div>
        </div>
      </CardBox>
    </div>
  );
};

export default ExecutorsAccessDenied;
