export type JudgementEntity = {
  id?: number;
  categories?: { id: number; name: string }[];
  categories_names?: string;
  authority_name?: string;
  authority_type?: string;
  document_type?: string;
  identifier?: string;
  place?: string;
  published_at?: string;
  content?: string;
  created_at?: string;
  updated_at?: string;
  keywords_names?: string;
};

export default class Judgement {
  readonly id?: number;
  readonly categories?: { id: number; name: string }[];
  readonly categories_names?: string;
  readonly authority_name?: string;
  readonly authority_type?: string;
  readonly document_type?: string;
  readonly identifier?: string;
  readonly place?: string;
  readonly published_at?: string;
  readonly content?: string;
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly keywords_names?: string;

  constructor(entity: JudgementEntity) {
    this.id = entity.id;
    this.categories = entity.categories;
    this.categories_names = entity.categories_names;
    this.authority_name = entity.authority_name;
    this.authority_type = entity.authority_type;
    this.document_type = entity.document_type;
    this.identifier = entity.identifier;
    this.place = entity.place;
    this.published_at = entity.published_at;
    this.content = entity.content;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
    this.keywords_names = entity.keywords_names;
  }

  static getFilterableAttributes(): string[] {
    return [
      'id',
      'authority_name',
      'authority_type',
      'document_type',
      'identifier',
      'keywords',
      'place',
      'published_at_from',
      'published_at_to',
      'requires_action'
    ];
  }

  static getSortableAttributes(): string[] {
    return ['id', 'authority_name', 'authority_type', 'document_type', 'identifier', 'place', 'category_name'];
  }
}

export const createJudgement = (entity: Judgement): Judgement => new Judgement(entity);
