import _has from 'lodash/has';
import { ValidationErrors } from 'modules/Shared/type';
import ExecutorFieldset from 'modules/Executor/component/Fieldset';
import React, { useState } from 'react';
import { Button, Form as FormStrap } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ExecutorEntity } from 'modules/Executor/model/Executor';
import { ROUTE_EXECUTORS_ADMINS } from 'modules/Executor/routes';
import { DATE_FORMAT_SLASH, DATE_FORMAT_VALUE } from 'modules/Shared/helper/utils';
import moment from 'moment';

export interface Props {
  errors?: ValidationErrors;
  disabled?: boolean;
  submit: (data: ExecutorEntity) => void;
}

const Form: React.FC<Props> = ({ errors, disabled, submit }) => {
  const [values, setValue] = useState({
    name: '',
    vies_exist: '',
    ZUS_name: '',
    ZUS_email: '',
    US_name: '',
    US_email: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    nip: '',
    foreign_nip: false,
    city: '',
    voivodeship: '',
    street: '',
    business_start_date: '',
    krs: '',
    postal_code: '',
    post_office: '',
    consumer_bankruptcy_date: ''
  });
  const [executorFromGus, setExecutorFromGus] = useState<Record<any, any>>(null);
  const [consumerBankruptcy, setConsumerBankruptcy] = useState<boolean>(!!values?.consumer_bankruptcy_date);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = event.target;

    if (_has(values, key)) {
      setValue({ ...values, [key]: value });
    }
  };

  const onSelectChange = (value: string | number, key: string) => {
    if (_has(values, key)) {
      setValue({ ...values, [key]: value });
    }
  };

  const setMultipleValues = (data: Record<any, any>) => {
    setValue({ ...values, ...data });
  };

  const clearFields = () => {
    setValue({
      ...values,
      name: '',
      vies_exist: '',
      ZUS_name: '',
      ZUS_email: '',
      US_name: '',
      US_email: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      foreign_nip: false,
      city: '',
      voivodeship: '',
      street: '',
      business_start_date: '',
      krs: '',
      postal_code: '',
      post_office: '',
      consumer_bankruptcy_date: ''
    });

    setExecutorFromGus(null);
    setConsumerBankruptcy(false);
  };

  const onForeignNipCheckboxChange = () => {
    const { foreign_nip } = values;
    if (foreign_nip) setValue({ ...values, nip: '', foreign_nip: !foreign_nip });
    else setValue({ ...values, foreign_nip: !foreign_nip });
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let { business_start_date, consumer_bankruptcy_date } = values;
    if (business_start_date) {
      const momentObj = moment(business_start_date, DATE_FORMAT_SLASH, true);
      if (!momentObj.isValid()) return;
      business_start_date = momentObj.format(DATE_FORMAT_VALUE);
    }
    if (consumer_bankruptcy_date) {
      const momentObj = moment(consumer_bankruptcy_date, DATE_FORMAT_SLASH, true);
      if (!momentObj.isValid()) return;
      consumer_bankruptcy_date = momentObj.format(DATE_FORMAT_VALUE);
    }

    submit({ ...values, business_start_date, consumer_bankruptcy_date });
  };

  const hasValue = () => !Object.values(values).some(Boolean);

  return (
    <FormStrap onSubmit={onSubmit}>
      <ExecutorFieldset
        disabled={disabled}
        errors={errors}
        values={values}
        onChange={onChange}
        onSelectChange={onSelectChange}
        onForeignNipCheckboxChange={onForeignNipCheckboxChange}
        required
        setMultipleValues={setMultipleValues}
        executorFromGus={executorFromGus}
        setExecutorFromGus={setExecutorFromGus}
        consumerBankruptcy={consumerBankruptcy}
        setConsumerBankruptcy={setConsumerBankruptcy}
      />

      <div className="form-actions">
        {executorFromGus !== null && (
          <Button
            type="button"
            color="secondary"
            outline
            className="pzpeu-btn-disabled waves-effect waves-light mb-3"
            onClick={clearFields}
          >
            Wyczyść
          </Button>
        )}
        <Button
          type="submit"
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light"
          disabled={hasValue() || disabled}
        >
          Zapisz
        </Button>
        <Link to={ROUTE_EXECUTORS_ADMINS} className="cancel waves-effect waves-light">
          Anuluj
        </Link>
      </div>
    </FormStrap>
  );
};

export default Form;
