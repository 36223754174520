import {
  ForeignProceedingsAlertsListState,
  initForeignProceedingsAlertsListState
} from '../../state/foreignProceedingsAlertsList';
import {
  FOREIGN_PROCEEDINGS_ALERTS_LIST,
  FOREIGN_PROCEEDINGS_CLEAR_ALERTS_LIST,
  ForeignProceedingsAlertsListAction,
  SET_FOREIGN_PROCEEDINGS_ALERTS_LIST_PARAMS
} from '../../action/ForeignProceedingsAlertsList';

const initState = initForeignProceedingsAlertsListState();

const reducer = (
  state: ForeignProceedingsAlertsListState = initState,
  action: ForeignProceedingsAlertsListAction
): ForeignProceedingsAlertsListState => {
  switch (action.type) {
    case SET_FOREIGN_PROCEEDINGS_ALERTS_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case FOREIGN_PROCEEDINGS_ALERTS_LIST: {
      const { list = [], meta } = action.payload;

      return { ...state, fetching: false, list, meta };
    }
    case FOREIGN_PROCEEDINGS_CLEAR_ALERTS_LIST: {
      return { ...state, fetching: false, list: [] };
    }

    default:
      return state;
  }
};

export default reducer;
