import { AddToastPayload } from 'modules/Layout/action';
import { ColorVariants } from 'modules/Shared/type';
import { TOAST_CLOSE_IN } from 'modules/Layout/type';
import { AgreementStepSlug } from 'modules/Agreements/step';
import { getStepName } from 'modules/Agreements/helper/agreement';
import { AgreementMode } from 'modules/Agreements/type';

export const deleteAgreementToastSuccess = (): AddToastPayload => ({
  header: 'Umowa',
  body: 'Umowa została pomyślnie usunięta',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createAgreementToastSuccess = (): AddToastPayload => ({
  header: 'Umowa',
  body: 'Umowa została pomyślnie utworzona.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const updateAgreementToastSuccess = (): AddToastPayload => ({
  header: 'Umowa',
  body: 'Umowa została pomyślnie zmodyfikowana.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const createAgreementToastError = (): AddToastPayload => ({
  header: 'Umowa',
  body: 'Umowa nie została utworzona.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const updateAgreementToastError = (): AddToastPayload => ({
  header: 'Umowa',
  body: 'Umowa nie została zmodyfikowana.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const saveAgreementStepToastSuccess = (step: AgreementStepSlug, mode?: AgreementMode): AddToastPayload => ({
  header: `Krok "${getStepName(step, mode)}"`,
  body: `Krok "${getStepName(step, mode)}" został zapisany.`,
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const deleteAgreementPartToastError = (): AddToastPayload => ({
  header: `Usuwanie zadania`,
  body: `Zadanie nie zostało usunięte.`,
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const deleteAgreementPartToastSuccess = (): AddToastPayload => ({
  header: `Usuwanie zadania`,
  body: `Zadanie zostało pomyślnie usunięte.`,
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const saveAgreementStepToastError = (step: AgreementStepSlug, mode?: AgreementMode): AddToastPayload => ({
  header: `Krok "${getStepName(step, mode)}"`,
  body: `Krok "${getStepName(step, mode)}" nie został zapisany.`,
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const fetchPreambleProceedingsToastError = (body: string): AddToastPayload => ({
  header: 'Błąd pobierania listy postępowań',
  body,
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const fetchPreambleAgreementsToastError = (body: string): AddToastPayload => ({
  header: 'Błąd pobierania listy umów',
  body,
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const downloadAgreementToastSuccess = (): AddToastPayload => ({
  header: 'Pobieranie umowy',
  body: 'Pomyślnie pobrano umowę.',
  color: ColorVariants.Success,
  closeIn: TOAST_CLOSE_IN
});

export const downloadAgreementToastError = (): AddToastPayload => ({
  header: 'Pobieranie umowy',
  body: 'Nie udało się pobrać umowy.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});

export const fetchCoFinancingProjectsToastError = (): AddToastPayload => ({
  header: 'Pobieranie projektów unijnych',
  body: 'Nie udało się pobrać informacji o projektach unijnych.',
  color: ColorVariants.Danger,
  closeIn: TOAST_CLOSE_IN
});
