import React, { useEffect, useRef, useState } from 'react';
import { CustomInput, FormFeedback, FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AGREEMENT_TYPE_SUPPLEMENT, AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_REALIZATION_PEOPLE } from 'modules/Agreements/step';
import StepImportDataButton from 'modules/Agreements/components/StepImportDataButton';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import { getError, hasError } from 'modules/Shared/helper/validation';
import Part from 'modules/Agreements/model/Part';
import Accordion from 'modules/Layout/component/Accordion';
import { IMPORT_ORDERS_ACCESS } from '../../../../../Auth/container/Guard/permissions';
import Authorize from '../../../../../Auth/container/Authorize';

export interface AgreementStepRealizationPeopleValues {
  same_realization_people_for_parts: boolean;
  realization_people_possibility: boolean;
  realization_people: {
    part_id: number | null;
    people_commitment_content: string | null;
    disposition_checked: boolean;
    disposition_content: string | null;
  }[];
  replacement_checked: boolean;
  replacement_content: string | null;
  change_checked: boolean;
  change_content: string | null;
  polish_checked: boolean;
  polish_content: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

export interface AgreementStepRealizationPeopleImportValues {
  same_realization_people_for_parts: boolean;
  realization_people_possibility: boolean;
  realization_people: {
    part_id: number | null;
    people_commitment_content: string | null;
    disposition_checked: boolean;
    disposition_content: string | null;
  }[];
  replacement_checked: boolean;
  replacement_content: string | null;
  change_checked: boolean;
  change_content: string | null;
  polish_checked: boolean;
  polish_content: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const PEOPLE_COMMITMENT_CONTENT =
  'Wykonawca zobowiązany jest do zapewnienia do realizacji Umowy odpowiedniego potencjału, tj ........';
const DISPOSITION_CONTENT = 'Zamawiający wymaga, aby ww. osoby pozostawały do dyspozycji w zakresie ........';
const REPLACEMENT_CONTENT =
  'W uzasadnionych przypadkach np. choroby lub urlopu wynikającego z przepisów, dopuszcza się zastępstwo osób o takich samych uprawnieniach';
const CHANGE_CONTENT =
  'Każda zmiana ww. osób wymaga pisemnej zgody zamawiającego, przy czym zamawiający zastrzega, że osoba wskazana przez wykonawcę będzie spełniała wszystkie wymagania zamawiającego. Zamawiający udzieli zgody wykonawcy w terminie 5 dni roboczych od daty zgłoszenia pisemnego wniosku w tym zakresie. W razie nie zgłoszenia sprzeciwu przez zamawiającego w tym terminie, przyjmuje się, że zamawiający wyraził zgodę na proponowaną zmianę. Zamawiający nie odmówi zgody bez ważnych przyczyn (np. osoba wskazana przez wykonawcę nie spełnia warunków określonych w SWZ)';
const POLISH_CONTENT =
  'Wszystkie osoby skierowane do realizacji Umowy muszą posługiwać się językiem polskim. W przeciwnym wypadku wykonawca zobowiązany jest zapewnić dla każdej z osób niewładającym jeżykiem polskim tłumacza języka polskiego na czas wykonywania czynności w ramach realizacji Umowy';

const initContentValues = {
  people_commitment_content: PEOPLE_COMMITMENT_CONTENT,
  disposition_content: DISPOSITION_CONTENT,
  replacement_content: REPLACEMENT_CONTENT,
  change_content: CHANGE_CONTENT,
  polish_content: POLISH_CONTENT
};

const mapState = (step: AgreementStepRealizationPeopleValues): AgreementStepRealizationPeopleValues => {
  const { realization_people, replacement_content, change_content, polish_content, checkboxes, ...rest } = step;

  return {
    ...rest,
    realization_people: realization_people.map((part) => {
      const { people_commitment_content, disposition_content } = part;

      return {
        ...part,
        people_commitment_content: people_commitment_content || PEOPLE_COMMITMENT_CONTENT,
        disposition_content: disposition_content || DISPOSITION_CONTENT
      };
    }),
    replacement_content: replacement_content || REPLACEMENT_CONTENT,
    change_content: change_content || CHANGE_CONTENT,
    polish_content: polish_content || POLISH_CONTENT,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepRealizationPeople = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, stepsImportData, onSubmit, onChange, errors } = props;
  const initState = useRef<AgreementStepRealizationPeopleValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_REALIZATION_PEOPLE))
  );
  const [stepValues, setStepValues] = useState<AgreementStepRealizationPeopleValues>({ ...initState.current });
  const stepDataImportValues: AgreementStepRealizationPeopleImportValues = getStepValues(
    stepsImportData,
    AGREEMENT_STEP_REALIZATION_PEOPLE
  );
  const { parts_ids, few_parts } = agreement;
  const { realization_people, same_realization_people_for_parts } = stepValues;

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_REALIZATION_PEOPLE));
    setStepValues({ ...initState.current });
  }, [steps]);

  const isMultiple = few_parts && !same_realization_people_for_parts;

  const onStepDataImportClick = () => {
    setStepValues((values) => {
      return {
        ...stepDataImportValues,
        ...values
      };
    });
  };

  const { renderCheckbox, renderSwitch, renderPartSwitch, renderPartContent, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    partSlug: 'realization_people',
    initContentValues
  });

  const renderPart = (partObj: Part, index: number) => {
    const part = isMultiple ? realization_people[index] : realization_people[0];

    const body = (
      <FormGroup>
        {renderPartContent('people_commitment_content', index, part, agreement.type === AGREEMENT_TYPE_SUPPLEMENT)}
        {renderPartSwitch(
          'disposition_checked',
          'Czy zamawiający wymaga, aby ww. osoby pozostawały do dyspozycji zamawiającego w zakresie zadeklarowanym przez wykonawcę na etapie postępowania o udzielenie zamówienia?',
          index,
          part,
          agreement.type === AGREEMENT_TYPE_SUPPLEMENT
        )}
        {!part.disposition_checked &&
          renderPartContent('disposition_content', index, part, agreement.type === AGREEMENT_TYPE_SUPPLEMENT)}
        {isMultiple &&
          renderCustomCheckboxes(
            'general',
            partObj?.id,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )}
      </FormGroup>
    );

    if (isMultiple) {
      return (
        <Accordion
          key={`renderPartAccordion-${partObj.id}`}
          isInvalid={hasError(errors, `realization_people.${index}`)}
          accordionContentStyle={{ padding: '1rem' }}
          entity={{
            title: partObj.getName(index),
            content: body
          }}
        />
      );
    }

    return body;
  };

  const samePartsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setStepValues((values) =>
      mapState({
        ...values,
        same_realization_people_for_parts: checked,
        realization_people: checked
          ? [
              {
                ...realization_people[0],
                part_id: null
              }
            ]
          : parts_ids.map((part) => ({
              ...realization_people[0],
              part_id: part.id
            }))
      })
    );
  };

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <Authorize permissions={[IMPORT_ORDERS_ACCESS]}>
        {agreement.type !== AGREEMENT_TYPE_SUPPLEMENT && (
          <StepImportDataButton onSubmit={onStepDataImportClick} stepDataImportValues={stepDataImportValues} />
        )}
      </Authorize>
      {renderSwitch(
        'realization_people_possibility',
        'Czy wykonawca zobowiązany jest do zapewnienia do realizacji Umowy odpowiedniego potencjału osobowego?',
        agreement.type === AGREEMENT_TYPE_SUPPLEMENT
      )}
      {stepValues.realization_people_possibility && (
        <FormGroup>
          {few_parts && (
            <FormGroup>
              <CustomInput
                id="same_realization_people_for_parts"
                name="same_realization_people_for_parts"
                type="switch"
                checked={same_realization_people_for_parts}
                onChange={samePartsChange}
                disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
                invalid={hasError(errors, 'same_realization_people_for_parts')}
                label="Czy przedmiot zamówienia jest wspólny dla wszystkich zadań?"
              />
              {hasError(errors, 'same_realization_people_for_parts') && (
                <FormFeedback className="d-block">{getError(errors, 'same_realization_people_for_parts')}</FormFeedback>
              )}
            </FormGroup>
          )}
          {(isMultiple ? parts_ids : [null]).map(renderPart)}
          {isMultiple && (
            <>
              <p className="h4">Ponadto dla każdego z zadań:</p>
            </>
          )}
          {renderCheckbox(
            'replacement_checked',
            stepValues.replacement_content,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )}
          {renderCheckbox('change_checked', stepValues.change_content, agreement.type === AGREEMENT_TYPE_SUPPLEMENT)}
          {renderCheckbox('polish_checked', stepValues.polish_content, agreement.type === AGREEMENT_TYPE_SUPPLEMENT)}
          {renderCustomCheckboxes(
            null,
            null,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )}
        </FormGroup>
      )}
    </AgreementFormWrapper>
  );
};

export default AgreementStepRealizationPeople;
