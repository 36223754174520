import React from 'react';
import { Message, PaginationMeta } from 'modules/Shared/type';
import Listing from 'modules/Layout/component/Listing';
import CardBox from 'modules/Layout/component/CardBox';
import PerPageTable from 'modules/Layout/component/Pagination/WithProps/PerPage';
import PaginationTable from 'modules/Layout/component/Pagination/WithProps';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import SAOSKeywordTable from 'modules/Judgement/container/SAOSKeywordTable';
import Authorize from 'modules/Auth/container/Authorize';
import { JUDGMENT_CATEGORIES_ADD } from 'modules/Judgement/permissions';
import { Button } from 'reactstrap';

export interface Props {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SAOSKeywords: JudgementElementEntity[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  getJudgmentSAOSKeywords: () => Promise<void>;
  meta: PaginationMeta;
  message: Message;
  fetching: boolean;
  setDisplayAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  onDeleteClick: (SAOSKeyword: JudgementElementEntity) => void;
  redirectUrl?: string;
}

const CategorySAOSKeywordsDetailsDisplay: React.FC<Props> = ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  getJudgmentSAOSKeywords,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SAOSKeywords,
  meta,
  message,
  fetching,
  setDisplayAddModal,
  onDeleteClick,
  redirectUrl
}) => {
  return (
    <CardBox
      heading="Słowa kluczowe"
      message={message}
      redirectReturnBtnBottom={redirectUrl}
      actions={
        <Authorize permissions={[JUDGMENT_CATEGORIES_ADD]}>
          <Button color="link" className="link-with-border p-0" onClick={() => setDisplayAddModal(true)}>
            DODAJ SŁOWO KLUCZOWE
          </Button>
        </Authorize>
      }
    >
      <div className="row users-view">
        <div className="col-12">
          <div className="pzpeu-table-list inside-card-box">
            <Listing
              table={<SAOSKeywordTable SAOSKeywords={SAOSKeywords} onDeleteClick={onDeleteClick} />}
              pagination={<PaginationTable fetchList={getJudgmentSAOSKeywords} meta={meta} />}
              perPage={<PerPageTable fetchList={getJudgmentSAOSKeywords} meta={meta} />}
              loading={fetching}
            />
          </div>
        </div>
      </div>
    </CardBox>
  );
};

export default CategorySAOSKeywordsDetailsDisplay;
