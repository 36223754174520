/* eslint-disable */
import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function BadCommunicateIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px', className, strokeWidth = '1' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} className={className} viewBox="0 0 28.828 28.825">
      <g id="Group_403" data-name="Group 403" transform="translate(0.5 0.5)">
        <line
          id="Line_60"
          data-name="Line 60"
          x1="11.296"
          y2="11.295"
          transform="translate(8.266 8.265)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
        <line
          id="Line_61"
          data-name="Line 61"
          x2="11.296"
          y2="11.295"
          transform="translate(8.266 8.265)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
        <path
          id="Path_1311"
          data-name="Path 1311"
          d="M253.537,1296.62a13.913,13.913,0,1,0,13.916,13.911A13.91,13.91,0,0,0,253.537,1296.62Z"
          transform="translate(-239.625 -1296.62)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  );
}
