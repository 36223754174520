import { AxiosPromise } from 'axios';
import { ColorVariants, Message } from 'modules/Shared/type';

type Response<T> = {
  data?: T;
  message?: Message;
  cancelled?: boolean;
  status?: number;
};

const fetchRequest = async <T>(request: (...args: any[]) => AxiosPromise<T>, ...args: any[]): Promise<Response<T>> => {
  let message: Message = null;

  try {
    const response = await request(...args);
    const { data } = response;
    return { data };
  } catch (error) {
    if (error?.message === 'AxiosCancel') {
      return { cancelled: true };
    }

    const status = error?.error?.response?.status;

    message = {
      value: error?.error?.response?.data?.message || error?.message || 'Something went wrong.',
      variant: ColorVariants.Danger
    };

    return { message, status };
  }
};

export default fetchRequest;
