import { BreadcrumbItem } from 'modules/Layout/type';
import { breadcrumbProfile } from 'modules/Profile/breadcrumbs';
import { breadcrumbRouteUser } from 'modules/User/breadcrumbs';

const breadcrumbRouteModuleAccessHistory = (
  id?: string | number,
  isAdmin?: boolean,
  userName = 'Moduły'
): BreadcrumbItem[] =>
  isAdmin
    ? [
        ...breadcrumbRouteUser(id, false, userName),
        {
          title: `${userName} - historia dostępów`
        }
      ]
    : [
        breadcrumbProfile(),
        {
          title: `${userName} - historia dostępów`
        }
      ];

export default breadcrumbRouteModuleAccessHistory;
