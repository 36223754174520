import React from 'react';
import { IconProps } from '../index';

export default function AgreementIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px' } = props;

  return (
    <svg id="AgreementIcon" height={height} viewBox="0 0 24 24">
      <g>
        <g>
          <path
            fill={fill}
            d="M12,18H4c-0.2763672,0-0.5-0.2236328-0.5-0.5S3.7236328,17,4,17h8c0.2763672,0,0.5,0.2236328,0.5,0.5
  S12.2763672,18,12,18z"
          />
        </g>
        <g>
          <path
            fill={fill}
            d="M12,15H4c-0.2763672,0-0.5-0.2236328-0.5-0.5S3.7236328,14,4,14h8c0.2763672,0,0.5,0.2236328,0.5,0.5
			S12.2763672,15,12,15z"
          />
        </g>
        <g>
          <path
            fill={fill}
            d="M12,12H4c-0.2763672,0-0.5-0.2236328-0.5-0.5S3.7236328,11,4,11h8c0.2763672,0,0.5,0.2236328,0.5,0.5
			S12.2763672,12,12,12z"
          />
        </g>
        <g>
          <path
            fill={fill}
            d="M7,9H4C3.7236328,9,3.5,8.7763672,3.5,8.5S3.7236328,8,4,8h3c0.2763672,0,0.5,0.2236328,0.5,0.5
			S7.2763672,9,7,9z"
          />
        </g>
        <g>
          <path
            fill={fill}
            d="M20.9902344,23.9970703c0,0-0.0009766,0-0.0019531,0
			c-0.4335938-0.0009766-0.8193359-0.2958984-0.9599609-0.7333984C18,17.6728516,18,17.6152344,18,17.5
			c0-0.2763672,0.2236328-0.5,0.5-0.5c0.2392578,0,0.4394531,0.1679688,0.4882813,0.3925781
			c0.0966797,0.3183594,1.234375,3.4755859,1.9863281,5.5478516c0.7822266-2.0732422,1.9384766-5.2285156,2.0371094-5.5488281
			C23.0615234,17.1679688,23.2607422,17,23.5,17c0.2763672,0,0.5,0.2236328,0.5,0.5c0,0.1162109,0,0.1748047-2.0693359,5.7841797
			C21.7939453,23.7099609,21.421875,23.9970703,20.9902344,23.9970703z"
          />
        </g>
        <g>
          <path
            fill={fill}
            d="M21.9394531,22.1259766h-1.9511719c-0.2763672,0-0.5-0.2236328-0.5-0.5s0.2236328-0.5,0.5-0.5h1.9511719
			c0.2763672,0,0.5,0.2236328,0.5,0.5S22.2158203,22.1259766,21.9394531,22.1259766z"
          />
        </g>
        <g>
          <path
            fill={fill}
            d="M14.6992188,24H1.3847656C0.6469727,24,0,23.3955078,0,22.7060547V1.2939453
			C0,0.6044922,0.6469727,0,1.3847656,0h10.1816406c0.1337891,0,0.2607422,0.0532227,0.3554688,0.1479492l3.9335938,3.9663086
			C15.9482422,4.2080078,16,4.3344727,16,4.4663086v18.2397461C16,23.4199219,15.4160156,24,14.6992188,24z M1.3847656,1
			C1.2084961,1,1,1.1591797,1,1.2939453v21.4121094C1,22.84375,1.2050781,23,1.3847656,23h13.3144531
			C14.859375,23,15,22.8623047,15,22.7060547V4.6723633L11.3583984,1H1.3847656z"
          />
        </g>
        <g>
          <path
            fill={fill}
            d="M15.4824219,4.9663086h-3.9160156c-0.2763672,0-0.5-0.2236328-0.5-0.5V0.5004883
			c0-0.2763672,0.2236328-0.5,0.5-0.5s0.5,0.2236328,0.5,0.5v3.4658203h3.4160156c0.2763672,0,0.5,0.2236328,0.5,0.5
			S15.7587891,4.9663086,15.4824219,4.9663086z"
          />
        </g>
        <g>
          <path
            fill={fill}
            d="M23.5,18h-5c-0.2763672,0-0.5-0.2236328-0.5-0.5V2.5004883C18,1.121582,19.1103516,0,20.4746094,0h1.125
			C22.9453125,0,24,1.0981445,24,2.5004883V17.5C24,17.7763672,23.7763672,18,23.5,18z M19,17h4V2.5004883
			C23,1.6450195,22.3984375,1,21.5996094,1h-1.125C19.6474609,1,19,1.6591797,19,2.5004883V17z"
          />
        </g>
      </g>
    </svg>
  );
}
