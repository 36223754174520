import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { SearchParams } from 'modules/Shared/type';
import { ClauseConnectedJudgementValues } from 'modules/Clause/type';
import { FetchClause, FetchClauses } from 'modules/Clause/repository';

export const API_CLIENT_CLAUSE = '/api/client/clauses';
export const API_CLIENT_CLAUSE_DETAILS = '/api/client/clauses/:id';
export const API_CLIENT_CLAUSE_CONNECTED_JUDGMENTS = '/api/client/clauses/:id/judgments';

export const fetchClientClauses = (params: SearchParams = {}, cancelToken?: any): AxiosPromise<FetchClauses> =>
  axios.get(getAPIUrl(API_CLIENT_CLAUSE, null, params), { cancelToken });

export const fetchClientClause = (id: string | number, cancelToken?: any): AxiosPromise<FetchClause> =>
  axios.get(getAPIUrl(API_CLIENT_CLAUSE_DETAILS, { id }), { cancelToken });

export const fetchClientClauseConnectedJudgments = (
  id: string | number,
  cancelToken?: any
): AxiosPromise<{ data: ClauseConnectedJudgementValues[] }> =>
  axios.get(getAPIUrl(API_CLIENT_CLAUSE_CONNECTED_JUDGMENTS, { id }), { cancelToken });
