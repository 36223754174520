import React, { useEffect, useRef, useState } from 'react';
import { CustomInput, FormFeedback, FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import { getError, hasError } from 'modules/Shared/helper/validation';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AGREEMENT_TYPE_SUPPLEMENT, AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_OPTION_RIGHT } from 'modules/Agreements/step';
import StepImportDataButton from 'modules/Agreements/components/StepImportDataButton';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import Part from 'modules/Agreements/model/Part';
import Accordion from 'modules/Layout/component/Accordion';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import Authorize from '../../../../../Auth/container/Authorize';
import { IMPORT_ORDERS_ACCESS } from '../../../../../Auth/container/Guard/permissions';

export interface AgreementStepOptionRightValues {
  same_option_rights_for_parts: boolean;
  option_rights: {
    part_id: number | null;
    option_right_possibility: boolean;
    option_right_content: string | null;
    option_right_type: string | null;
    option_right_circumstances: string | null;
  }[];
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

export interface AgreementStepOptionRightImportValues {
  same_option_rights_for_parts: boolean;
  option_rights: {
    option_right_possibility: boolean;
    option_right_content: string | null;
    option_right_type: string | null;
    option_right_circumstances: string | null;
    part_id: number | null;
  }[];
  checkboxes: AgreementCheckboxes | null;
}

export function convertHtmlToPlainText(html: string): string {
  if (!html || /<[^>]+>/.test(html)) {
    return html;
  }

  const { contentBlocks } = convertFromHTML(html);
  const contentState: ContentState = ContentState.createFromBlockArray(contentBlocks);
  const editorState: EditorState = EditorState.createWithContent(contentState);

  return editorState
    .getCurrentContent()
    .getPlainText()
    .replace(/&nbsp;/g, ' ');
}

const OPTION_RIGHT_CONTENT = 'Zamawiający przewiduje opcję o wartości ........';
const OPTION_RIGHT_TYPE = 'Rodzaj opcji: ........';
const OPTION_RIGHT_CIRCUMSTANCES = 'Okoliczności skorzystania z opcji: ........';

const initContentValues = {
  option_right_content: OPTION_RIGHT_CONTENT,
  option_right_type: OPTION_RIGHT_TYPE,
  option_right_circumstances: OPTION_RIGHT_CIRCUMSTANCES
};

const mapState = (step: AgreementStepOptionRightValues): AgreementStepOptionRightValues => {
  const { option_rights, checkboxes, ...rest } = step;

  return {
    ...rest,
    option_rights: option_rights.map((part) => {
      const { option_right_content, option_right_type, option_right_circumstances } = part;

      return {
        ...part,
        option_right_content: option_right_content || OPTION_RIGHT_CONTENT,
        option_right_type: option_right_type || OPTION_RIGHT_TYPE,
        option_right_circumstances: option_right_circumstances || OPTION_RIGHT_CIRCUMSTANCES
      };
    }),
    checkboxes: checkboxes || {}
  };
};

const AgreementStepOptionRight = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, stepsImportData, onSubmit, onChange, errors } = props;
  const initState = useRef<AgreementStepOptionRightValues>(mapState(getStepValues(steps, AGREEMENT_STEP_OPTION_RIGHT)));
  const [stepValues, setStepValues] = useState<AgreementStepOptionRightValues>({ ...initState.current });
  const stepDataImportValues: AgreementStepOptionRightImportValues = getStepValues(
    stepsImportData,
    AGREEMENT_STEP_OPTION_RIGHT
  );
  const { parts_ids, few_parts } = agreement;
  const { option_rights, same_option_rights_for_parts, checkboxes } = stepValues;

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_OPTION_RIGHT));
    setStepValues({ ...initState.current });
  }, [steps]);

  const isMultiple = few_parts && !same_option_rights_for_parts;

  const { renderPartSwitch, renderPartContent, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    partSlug: 'option_rights',
    initContentValues
  });

  const renderPart = (partObj: Part, index: number) => {
    const part = isMultiple ? option_rights[index] : option_rights[0];

    const body = (
      <FormGroup key={partObj?.id ? partObj.id : part.part_id}>
        {[
          renderPartSwitch(
            'option_right_possibility',
            'Czy zamawiający przewiduje opcję w ramach zamówienia?',
            index,
            part,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          part.option_right_possibility && (
            <div className="pl-3">
              {[
                renderPartContent('option_right_content', index, part, agreement.type === AGREEMENT_TYPE_SUPPLEMENT),
                renderPartContent('option_right_type', index, part, agreement.type === AGREEMENT_TYPE_SUPPLEMENT),
                renderPartContent(
                  'option_right_circumstances',
                  index,
                  part,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                )
              ]}
            </div>
          ),
          renderCustomCheckboxes(
            'general',
            partObj?.id ? partObj.id : part.part_id,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )
        ]}
      </FormGroup>
    );

    if (isMultiple) {
      return (
        <Accordion
          key={`renderPartAccordion-${partObj.id}`}
          isInvalid={hasError(errors, `option_rights.${index}`)}
          accordionContentStyle={{ padding: '1rem' }}
          entity={{
            title: partObj.getName(index),
            content: body
          }}
        />
      );
    }

    return body;
  };

  const samePartsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setStepValues((values) =>
      mapState({
        ...values,
        same_option_rights_for_parts: checked,
        option_rights: checked
          ? [
              {
                ...option_rights[0],
                part_id: null
              }
            ]
          : parts_ids.map((part) => ({
              ...option_rights[0],
              part_id: part.id
            }))
      })
    );
  };

  const onStepDataImportClick = () => {
    if (stepDataImportValues.same_option_rights_for_parts !== undefined) {
      samePartsChange({ target: { checked: stepDataImportValues.same_option_rights_for_parts } } as any);
    }

    setStepValues((values) => {
      return {
        ...values,
        option_rights: stepDataImportValues.option_rights
          ? values.option_rights.map((optionRight, index) => ({
              ...optionRight,
              ...stepDataImportValues.option_rights[index],
              option_right_circumstances: convertHtmlToPlainText(
                stepDataImportValues.option_rights[index].option_right_circumstances
              ),
              option_right_content: convertHtmlToPlainText(
                stepDataImportValues.option_rights[index].option_right_content
              ),
              option_right_possibility: stepDataImportValues.option_rights[index].option_right_possibility,
              option_right_type: convertHtmlToPlainText(stepDataImportValues.option_rights[index].option_right_type),
              part_id: isMultiple ? stepDataImportValues.option_rights[index].part_id : null
            }))
          : values.option_rights,
        checkboxes: stepDataImportValues.checkboxes
          ? (Object.keys(stepDataImportValues.checkboxes) as (keyof typeof stepDataImportValues.checkboxes)[]).reduce(
              (acc, key) => {
                acc[key] = stepDataImportValues.checkboxes[key].map((checkbox) => ({
                  ...checkbox,
                  content: convertHtmlToPlainText(checkbox.content),
                  part_id: isMultiple ? checkbox.part_id : null
                }));
                return acc;
              },
              {} as Record<string, any>
            )
          : checkboxes,
        same_option_rights_for_parts: isMultiple ? stepDataImportValues.same_option_rights_for_parts : true
      };
    });
  };

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <Authorize permissions={[IMPORT_ORDERS_ACCESS]}>
        {agreement.type !== AGREEMENT_TYPE_SUPPLEMENT && (
          <StepImportDataButton onSubmit={onStepDataImportClick} stepDataImportValues={stepDataImportValues} />
        )}
      </Authorize>
      {few_parts && (
        <FormGroup>
          <CustomInput
            id="same_option_rights_for_parts"
            name="same_option_rights_for_parts"
            type="switch"
            checked={same_option_rights_for_parts}
            onChange={samePartsChange}
            disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
            invalid={hasError(errors, 'same_option_rights_for_parts')}
            label="Czy przedmiot zamówienia jest wspólny dla wszystkich zadań?"
          />
          {hasError(errors, 'same_option_rights_for_parts') && (
            <FormFeedback className="d-block">{getError(errors, 'same_option_rights_for_parts')}</FormFeedback>
          )}
        </FormGroup>
      )}
      {(isMultiple ? parts_ids : [null]).map(renderPart)}
      {isMultiple && (
        <>
          <p className="h4">Ponadto dla każdego z zadań:</p>
          {renderCustomCheckboxes(
            null,
            null,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )}
        </>
      )}
    </AgreementFormWrapper>
  );
};

export default AgreementStepOptionRight;
