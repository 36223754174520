export enum OfficeImportsListStatuses {
  IN_PROCESS = 1,
  DONE = 2
}

enum OfficeImportsListStatusesValues {
  IN_PROCESS = 'W trakcie',
  DONE = 'Zakończono'
}

export const officeImportsListStatuses = (status: number) => {
  switch (status) {
    case OfficeImportsListStatuses.IN_PROCESS:
      return OfficeImportsListStatusesValues.IN_PROCESS;
    case OfficeImportsListStatuses.DONE:
      return OfficeImportsListStatusesValues.DONE;
    default:
      return '';
  }
};

export type OfficeImportError = {
  column: string;
  errors: string;
  row_index: number;
};

export type OfficeImportsListValues = {
  id: number;
  user: {
    id: number;
    name: string;
  };
  file_name: string;
  path: string;
  status: OfficeImportsListStatuses;
  new_rows: number;
  updated_rows: number;
  errors_count: number;
  created_at: string;
  finished_at: string;
  errors?: OfficeImportError[];
};
