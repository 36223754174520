import React, { ReactNode } from 'react';
import CardBox from 'modules/Layout/component/CardBox';
import './index.scss';
import { Link } from 'react-router-dom';
import Logo from 'modules/Auth/component/Logo';
import { ROUTE_LOGIN } from 'modules/Auth/routes';

export interface Props {
  message: ReactNode;
}

export const MessagePage = (props: Props): JSX.Element => {
  const { message } = props;

  return (
    <div className="message-page d-flex justify-content-center">
      <CardBox paddingTop="57px" paddingLeft="86px" paddingRight="86px" paddingBottom="58px">
        <div className="d-flex justify-content-center">
          <Logo height={67} />
        </div>
        <div className="message-content">{message}</div>
        <div className="d-flex justify-content-center">
          <Link to={ROUTE_LOGIN} className="pzpeu-link">
            Powrót do strony logowania
          </Link>
        </div>
      </CardBox>
    </div>
  );
};
