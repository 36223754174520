import React, { useEffect, useState } from 'react';
import { Message, PartialSearchingProps } from 'modules/Shared/type';
import Listing from 'modules/Layout/component/Listing';
import CardBox from 'modules/Layout/component/CardBox';
import PerPageTable from 'modules/ExecutorOrder/container/ExecutorDetailsOrderList/PerPage';
import PaginationTable from 'modules/ExecutorOrder/container/ExecutorDetailsOrderList/Pagination';
import ExecutorOrdersTable from 'modules/ExecutorOrder/container/ExecutorDetailsOrderList/Table';
import OrdersFilter from 'modules/ExecutorOrder/container/ExecutorDetailsOrderList/Filter';
import { ORDERS_ADD } from 'modules/ExecutorOrder/permissions';
import Authorize from 'modules/Auth/container/Authorize';
import { Link } from 'react-router-dom';
import { ROUTE_ORDER_CREATE } from 'modules/ExecutorOrder/routes';
import { getPathUrl } from 'modules/Shared/helper/api';
import { RootState } from 'app/reducer';
import { setOrderListParamsAction } from 'modules/ExecutorOrder/action/list';
import { useDispatch, useSelector } from 'react-redux';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import Order from 'modules/ExecutorOrder/model/Order';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import { deleteOrder } from 'modules/ExecutorOrder/repository';
import { addToastAction } from 'modules/Layout/action';
import { deleteOrderToastSuccess } from 'modules/ExecutorOrder/toasts';

export interface Props {
  executor_id?: number | string;
  executorName?: string;
}

const DetailsOrderList: React.FC<Props> = ({ executor_id = 0, executorName }) => {
  const dispatch = useDispatch();
  const { fetching } = useSelector((state: RootState) => state.order.list);
  const [displayModal, setDisplayModal] = useState<Order>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);

  const setParams = (payload?: PartialSearchingProps) => dispatch(setOrderListParamsAction(payload, executor_id));

  const dispatchDeleteOrderToast = () => dispatch(addToastAction(deleteOrderToastSuccess()));

  useEffect(() => {
    if (executor_id) setParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executor_id]);

  const cancelToken = useCancelToken();
  const deleteOrderAction = async (order_id: number | string) => {
    setLoading(true);
    const {
      cancelled,
      message: messageRes,
      success
    } = await deleteRequest(deleteOrder, executor_id, order_id, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteOrderToast();
      setDisplayModal(null);
      setParams({});
    }
    if (messageRes) setMessage(messageRes);
    setLoading(false);
  };

  return (
    <CardBox
      heading="Postępowania"
      actions={
        <Authorize permissions={[ORDERS_ADD]}>
          <Link
            to={{
              pathname: getPathUrl(ROUTE_ORDER_CREATE, { executor_id }),
              state: { executorName }
            }}
            className="link-with-border waves-effect waves-light"
          >
            DODAJ NARUSZENIE
          </Link>
        </Authorize>
      }
    >
      {displayModal && (
        <GenericModalDelete
          value={displayModal}
          title="Usuwanie postępowania"
          content={(value) => `Czy na pewno chcesz usunąć postępowanie${value?.name ? ` - ${value?.name}` : ''}?`}
          onDeleteClick={(value) => deleteOrderAction(value.id)}
          isOpen={!!displayModal}
          toggle={() => setDisplayModal(null)}
          loading={loading}
          message={message}
          resetMessage={() => setMessage(null)}
        />
      )}
      <div className="row users-view">
        <div className="col-12">
          <div className="pzpeu-table-list inside-card-box">
            <Listing
              table={
                <ExecutorOrdersTable
                  executor_id={executor_id}
                  executorName={executorName}
                  onDeleteClick={(order) => setDisplayModal(order)}
                />
              }
              filter={<OrdersFilter executor_id={executor_id} />}
              pagination={<PaginationTable executor_id={executor_id} />}
              perPage={<PerPageTable executor_id={executor_id} />}
              loading={fetching}
            />
          </div>
        </div>
      </div>
    </CardBox>
  );
};

export default DetailsOrderList;
