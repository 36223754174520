import ExecutorClient from 'modules/Executor/model/ExecutorClient';
import { PaginationMeta, SearchingProps } from 'modules/Shared/type';

export interface ExecutorListState extends SearchingProps {
  executors: ExecutorClient[];
  meta?: PaginationMeta;
  fetching: boolean;
}

export const initExecutorListState = (): ExecutorListState => ({
  executors: [],
  pagination: {},
  filter: {},
  sort: {},
  fetching: false
});
