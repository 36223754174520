import { createJudgement } from 'modules/Judgement/model/Judgement';
import {
  JudgementClientListAction,
  JUDGEMENT_CLIENT_LIST,
  JUDGEMENT_RESET_LIST,
  SET_JUDGEMENT_CLIENT_LIST_PARAMS
} from 'modules/JudgementClient/action/JudgmentList';
import { initJudgementClientListState, JudgementClientListState } from 'modules/JudgementClient/state/judgementList';

const initState = initJudgementClientListState();

const reducer = (
  state: JudgementClientListState = initState,
  action: JudgementClientListAction
): JudgementClientListState => {
  switch (action.type) {
    case SET_JUDGEMENT_CLIENT_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case JUDGEMENT_CLIENT_LIST: {
      const { list = [], meta } = action.payload;

      return { ...state, fetching: false, list: list.map(createJudgement), meta };
    }

    case JUDGEMENT_RESET_LIST: {
      return initState;
    }

    default:
      return state;
  }
};

export default reducer;
