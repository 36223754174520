import React from 'react';
import { RootState } from 'app/reducer';
import Listing from 'modules/Layout/component/Listing';
import { useSelector } from 'react-redux';
import ProceedingsTable from 'modules/Proceedings/container/ProceedingsList/Table';
import ProceedingsPerPage from 'modules/Proceedings/container/ProceedingsList/PerPage';
import ProceedingsListingPagination from 'modules/Proceedings/container/ProceedingsList/Pagination';
import ProceedingsFilter from 'modules/Proceedings/container/ProceedingsList/Filter';
import { ROUTE_PROCEEDING_PUBLIC } from 'modules/Proceedings/routes';
import logo from 'modules/Layout/component/ProceedingsListLogo/baner-desktop.jpg';

const usePublicProceedingsList = (
  proceedingsRoute: string,
  proceedingsType: number,
  redirectButton?: React.ReactNode
) => {
  const { fetching } = useSelector((state: RootState) => state.proceedings.proceedingsList);

  const templateTheme = process.env.THEME_TEMPLATE as 'pzp' | 'bs';

  const jsxFnc = () => (
    <div className="row users-view">
      <div className="col-12">
        {templateTheme === 'pzp' && (
          <div className="mb-3">
            <a
              href="https://postepowania.pl/baza-wykonawcow-w-stosunku-do-ktorych-moga-wystapic-podstawy-do-wykluczenia/"
              target="_blank"
            >
              <img className="mw-100" src={logo} alt="Baner Sprawdź swoją konkurencię w przetargach!" />
            </a>
          </div>
        )}
        <Listing
          childrenNextToPagination={redirectButton ?? null}
          table={<ProceedingsTable proceedingsType={proceedingsType} redirectUrl={ROUTE_PROCEEDING_PUBLIC} />}
          filter={<ProceedingsFilter proceedingsType={proceedingsType} />}
          pagination={<ProceedingsListingPagination path={proceedingsRoute} />}
          perPage={<ProceedingsPerPage />}
          loading={fetching}
        />
      </div>
    </div>
  );

  return { jsxFnc };
};

export default usePublicProceedingsList;
