import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import { CommonDataItem } from 'modules/ExecutorOrder/model/Order';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';

export interface Props {
  bidRiggingItem?: CommonDataItem;
}

const BidRiggingProblem: React.FC<Props> = ({ bidRiggingItem = {} }) => {
  const fields = [
    {
      label: 'Przyczyny rozwiązania umowy',
      value: bidRiggingItem.violation_description ?? 'nie dotyczy',
      html: true
    },
    {
      label: 'Data rozwiązania umowy',
      value: ifContainsDateFormat(bidRiggingItem.violation_date, formatDate) ?? 'nie dotyczy'
    }
  ];

  return <InformationList fields={fields} />;
};

export default BidRiggingProblem;
