import { JudgementFieldValues } from 'modules/Judgement/type';
import { suffixLabel } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import JudgementCategoryAutocomplete from 'modules/Judgement/container/JudgementAutocomplete/CategoryInput';
import JudgementKeywordsInputAutocomplete from 'modules/Judgement/container/JudgementAutocomplete/KeywordsInput';
import { DATE_FORMAT_SLASH } from 'modules/Shared/helper/utils';
import moment from 'moment';

export interface Props {
  values: Omit<JudgementFieldValues, 'categories'> & { categories: { name: string; id: number }[] };
  displayWysiwyg: () => JSX.Element;
  errors?: ValidationErrors;
  disabled?: boolean;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement> | string, key: keyof JudgementFieldValues) => void;
  onCategoriesChange: (categoryValue: { name: string; id: number }[]) => void;
  onKeywordsChange: (keywordsValue: number[]) => void;
}

const JudgementFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, disabled, onChange, required, displayWysiwyg, onCategoriesChange, onKeywordsChange } = props;

  const { authority_name, authority_type, document_type, identifier, place, published_at, categories, keywords } =
    values;

  return (
    <fieldset className="m-0" disabled={disabled}>
      <FormGroup>
        <Label for="input-authority_name">{suffixLabel('Nazwa organu publikującego dokument', required)}</Label>
        <Input
          type="text"
          id="input-authority_name"
          value={authority_name}
          onChange={(event) => onChange(event, 'authority_name')}
          invalid={hasError(errors, 'authority_name')}
          maxLength={255}
          required={required}
        />
        {hasError(errors, 'authority_name') && <FormFeedback>{getError(errors, 'authority_name')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Label for="input-authority_type">Rodzaj organu publikującego dokument</Label>
        <Input
          type="text"
          id="input-authority_type"
          value={authority_type}
          onChange={(event) => onChange(event, 'authority_type')}
          invalid={hasError(errors, 'authority_type')}
          maxLength={255}
        />
        {hasError(errors, 'authority_type') && <FormFeedback>{getError(errors, 'authority_type')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Label for="input-document_type">Rodzaj dokumentu</Label>
        <Input
          type="text"
          id="input-document_type"
          value={document_type}
          onChange={(event) => onChange(event, 'document_type')}
          invalid={hasError(errors, 'document_type')}
          maxLength={255}
        />
        {hasError(errors, 'document_type') && <FormFeedback>{getError(errors, 'document_type')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Label for="input-identifier">{suffixLabel('Identyfikator publikacji', required)}</Label>
        <Input
          type="text"
          id="input-identifier"
          value={identifier}
          onChange={(event) => onChange(event, 'identifier')}
          invalid={hasError(errors, 'identifier')}
          maxLength={255}
          required={required}
        />
        {hasError(errors, 'identifier') && <FormFeedback>{getError(errors, 'identifier')}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Label for="input-place">Oryginalne miejsce publikacji</Label>
        <Input
          type="text"
          id="input-place"
          value={place}
          onChange={(event) => onChange(event, 'place')}
          invalid={hasError(errors, 'place')}
          maxLength={255}
        />
        {hasError(errors, 'place') && <FormFeedback>{getError(errors, 'place')}</FormFeedback>}
      </FormGroup>
      <FormGroup className="mb-0">
        <Label for="input-published_at">{suffixLabel('Termin publikacji', required)}</Label>
        <Input
          type="text"
          name="published_at"
          id="input-published_at"
          placeholder={DATE_FORMAT_SLASH}
          value={published_at}
          onChange={(event) => onChange(event, 'published_at')}
          invalid={hasError(errors, 'published_at')}
          required={required}
        />
        {published_at && !moment(published_at, DATE_FORMAT_SLASH, true).isValid() && (
          <FormFeedback className="d-block">Obsługiwany format: {DATE_FORMAT_SLASH}.</FormFeedback>
        )}
        {hasError(errors, 'published_at') && <FormFeedback>{getError(errors, 'published_at')}</FormFeedback>}
      </FormGroup>

      {categories && <JudgementCategoryAutocomplete categories={categories} onCategoriesChange={onCategoriesChange} />}

      {keywords && <JudgementKeywordsInputAutocomplete keywords={keywords} onKeywordsChange={onKeywordsChange} />}

      <FormGroup>
        {displayWysiwyg && displayWysiwyg()}

        {hasError(errors, 'content') && <FormFeedback className="d-block">{getError(errors, 'content')}</FormFeedback>}
      </FormGroup>
    </fieldset>
  );
};

export default JudgementFieldset;
