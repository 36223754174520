export type ExecutorStatus = {
  name: string;
  status: number;
};

export type ExecutorEntity = {
  id?: number;
  name?: string;
  ZUS_name?: string;
  ZUS_email?: string;
  US_name?: string;
  US_email?: string;
  vies_exist?: number | string;
  white_list_vat_exist?: number;
  first_name?: string;
  last_name?: string;
  user_first_name?: string;
  user_last_name?: string;
  email?: string;
  nip?: string;
  status?: ExecutorStatus;
  foreign_nip?: boolean;
  city?: string;
  voivodeship?: string;
  street?: string;
  postal_code?: string;
  post_office?: string;
  phone?: string;
  consumer_bankruptcy_date?: string;
  legal_form?: number;
  penalties_total_value?: number;
  business_start_date?: string;
  krs?: string;
  is_deletable?: boolean;
  updated_at?: string;
  created_at?: string;
  identifier?: string;
  contract_number?: string;
};

export default class Executor {
  readonly id?: number;
  readonly name?: string;
  readonly ZUS_name?: string;
  readonly ZUS_email?: string;
  readonly US_name?: string;
  readonly US_email?: string;
  readonly vies_exist?: number | string;
  readonly white_list_vat_exist?: number;
  readonly first_name?: string;
  readonly user_first_name?: string;
  readonly user_last_name?: string;
  readonly last_name?: string;
  readonly email?: string;
  readonly nip?: string;
  readonly status?: ExecutorStatus;
  readonly foreign_nip?: boolean;
  readonly krs?: string;
  readonly city?: string;
  readonly street?: string;
  readonly postal_code?: string;
  readonly post_office?: string;
  readonly phone?: string;
  readonly consumer_bankruptcy_date?: string;
  readonly penalties_total_value?: number;
  readonly business_start_date?: string;
  readonly voivodeship?: string;
  readonly is_deletable?: boolean;
  readonly updated_at?: string;
  readonly created_at?: string;
  readonly identifier?: string;
  readonly contract_number?: string;

  constructor(entity: ExecutorEntity) {
    this.id = entity.id;
    this.first_name = entity.first_name;
    this.user_first_name = entity.user_first_name;
    this.name = entity.name;
    this.ZUS_email = entity.ZUS_email;
    this.ZUS_name = entity.ZUS_name;
    this.US_email = entity.US_email;
    this.US_name = entity.US_name;
    this.name = entity.name;
    this.name = entity.name;
    this.vies_exist = entity.vies_exist;
    this.white_list_vat_exist = entity.white_list_vat_exist;
    this.last_name = entity.last_name;
    this.user_last_name = entity.user_last_name;
    this.email = entity.email;
    this.nip = entity.nip;
    this.status = entity.status;
    this.foreign_nip = entity.foreign_nip;
    this.krs = entity.krs;
    this.city = entity.city;
    this.street = entity.street;
    this.postal_code = entity.postal_code;
    this.post_office = entity.post_office;
    this.phone = entity.phone;
    this.consumer_bankruptcy_date = entity.consumer_bankruptcy_date;
    this.penalties_total_value = entity.penalties_total_value;
    this.voivodeship = entity.voivodeship;
    this.updated_at = entity.updated_at;
    this.business_start_date = entity.business_start_date;
    this.is_deletable = entity.is_deletable;
    this.created_at = entity.created_at;
    this.identifier = entity.identifier;
    this.contract_number = entity.contract_number;
  }

  static getAdminFilterableAttributes(): string[] {
    return [
      'name',
      'first_name',
      'last_name',
      'user_first_name',
      'user_last_name',
      'street',
      'status',
      'postal_code',
      'city',
      'post_office',
      'nip',
      'has_problems',
      'identifier',
      'contract_number'
    ];
  }

  static getFilterableAttributes(): string[] {
    return [...this.getAdminFilterableAttributes(), 'penalties_total_value_min', 'penalties_total_value_max'];
  }

  static getAdminSortableAttributes(): string[] {
    return [
      'name',
      'first_name',
      'last_name',
      'user_first_name',
      'user_last_name',
      'street',
      'status',
      'postal_code',
      'city',
      'post_office',
      'nip',
      'identifier',
      'contract_number'
    ];
  }

  static getSortableAttributes(): string[] {
    return [...this.getAdminSortableAttributes(), 'penalties_total_value'];
  }
}

export const createExecutor = (entity: ExecutorEntity): Executor => new Executor(entity);

export const executorHasProblemsOptions = () => {
  return [
    { value: 1, label: 'Tak' },
    { value: 0, label: 'Nie' }
  ];
};

export const executorViesExistOptions = [
  { value: 1, label: 'Figuruje' },
  { value: 2, label: 'Nie figuruje' },
  { value: '', label: 'Nie zweryfikowane' }
];

export const executorWhiteListVatExistOptions = () => {
  return [
    { value: 1, label: 'Figuruje' },
    { value: 2, label: 'Nie figuruje' },
    { value: '', label: 'Nie zweryfikowane' }
  ];
};
