import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps, SearchParams, SelectOption } from 'modules/Shared/type';
import { setOfficeDotListParamsAction } from 'modules/Office/action/list';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterInputType } from 'modules/Layout/type';
import { fetchOfficesExtendedCategories } from '../../../repository';

export interface Props {
  values?: SearchParams;
}

type SlugType = { slug: string; name: string };

const OfficeDotFilter: React.FC<Props> = ({ values = {} }) => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.officeDot.list);
  const setParams = (payload: PartialSearchingProps) => dispatch(setOfficeDotListParamsAction(payload));
  const [categoriesList, setCategoriesList] = useState<SlugType[]>([]);
  const categoriesOptions: SelectOption[] = categoriesList.map((category) => {
    return {
      value: category.slug,
      label: category.name
    };
  });

  useEffect(() => {
    const getValues = async () => {
      const responseCategories = await fetchOfficesExtendedCategories();
      const newCategories = responseCategories.data.data;
      setCategoriesList(newCategories);
    };
    getValues().then((r) => r);
  }, []);

  const staticInputs: FilterInputType[] = [
    {
      type: 'number',
      property: 'id',
      label: 'ID',
      value: _get(values, 'id')
    },
    {
      type: 'text',
      property: 'name',
      label: 'Nazwa',
      value: _get(values, 'name')
    },
    {
      type: 'text',
      property: 'regon',
      label: 'REGON',
      value: _get(values, 'regon')
    },
    {
      type: 'select',
      property: 'category',
      acceptValueAsString: true,
      label: 'Kategoria',
      options: [
        {
          value: '',
          label: ''
        },
        ...categoriesOptions
      ],
      value: _get(values, 'category')
    },
    {
      type: 'text',
      property: 'email',
      label: 'Email',
      value: _get(values, 'email')
    },
    {
      beforeLineBreak: true,
      type: 'text',
      property: 'city',
      label: 'Miasto',
      value: _get(values, 'city')
    },
    {
      type: 'text',
      property: 'district',
      label: 'Powiat',
      value: _get(values, 'district')
    },
    {
      type: 'text',
      property: 'voivodeship',
      label: 'Województwo',
      value: _get(values, 'voivodeship')
    }
  ];

  return (
    <ListingFilter
      filtersName="office_extended"
      inputs={[...staticInputs]}
      filter={filter}
      pagination={pagination}
      setParams={setParams}
    />
  );
};

export default OfficeDotFilter;
