import { initReportClaimsListState, ReportClaimsListState } from 'modules/Executor/state/reportClaimsList';
import {
  REPORT_CLAIMS_LIST,
  ReportClaimsListAction,
  SET_REPORT_CLAIMS_LIST_PARAMS
} from '../../action/reportClaimsList';
import { createReportClaimClient } from '../../model/ReportClaim';

const initState = initReportClaimsListState();

const reducer = (state: ReportClaimsListState = initState, action: ReportClaimsListAction): ReportClaimsListState => {
  switch (action.type) {
    case SET_REPORT_CLAIMS_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case REPORT_CLAIMS_LIST: {
      const { reportClaims = [], meta } = action.payload;

      return { ...state, fetching: false, reportClaims: reportClaims.map(createReportClaimClient), meta };
    }

    default:
      return state;
  }
};

export default reducer;
