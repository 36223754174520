import { Message } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { useEffect, useRef, useState } from 'react';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { fetchExecutorExplanationSection } from 'modules/Executor/repository';
import {
  PENALTIES_SLUG,
  TERMINATION_SLUG,
  COMPLAINT_SLUG,
  MISREPRESENTATION_SLUG
} from 'modules/ExecutorOrder/model/Order';

const useExecutorExplanationSectionWrapper = (renderCondition?: boolean) => {
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [explanationSection, setExplanationSection] = useState<{ [key: string]: string }>({
    [PENALTIES_SLUG]: null,
    [TERMINATION_SLUG]: null,
    [COMPLAINT_SLUG]: null,
    [MISREPRESENTATION_SLUG]: null
  });

  const notFetchedAlready = useRef(true);

  const cancelToken = useCancelToken();
  useEffect(() => {
    if ((renderCondition === undefined || renderCondition) && notFetchedAlready.current) {
      (async () => {
        setFetching(true);
        if (message) setMessage(null);

        const {
          cancelled,
          message: messageResponse,
          data
        } = await fetchRequest(fetchExecutorExplanationSection, cancelToken);

        if (cancelled) return;
        if (data) {
          const stateObj = data?.data?.reduce((prev, curr) => ({ ...prev, [curr.slug]: curr.description }), {});
          setExplanationSection(stateObj);
          notFetchedAlready.current = false;
        }
        if (messageResponse) setMessage(messageResponse);
        setFetching(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderCondition]);

  return {
    explanationSection,
    fetching,
    message
  };
};

export default useExecutorExplanationSectionWrapper;
