import { RootState } from 'app/reducer';
import Authorize from 'modules/Auth/container/Authorize';
import ActionDelete from 'modules/Layout/component/Action/Delete';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import Table, { TableCol } from 'modules/Layout/component/Table/AccordionTable';
import { getPathUrl } from 'modules/Shared/helper/api';
import { PartialSearchingProps } from 'modules/Shared/type';
import { setOfficeListParamsAction } from 'modules/Office/action/list';
import Office, { LastExecutorRequestStatusesOptions } from 'modules/Office/model/Office';
import { OFFICES_DELETE, OFFICES_EDIT, OFFICES_DETAILS } from 'modules/Office/permissions';
import { ROUTE_OFFICE, ROUTE_OFFICE_UPDATE } from 'modules/Office/routes';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import EditIcon from 'modules/Layout/component/Icon/Edit';
import { formatDate, formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';

type Props = { onDeleteClick?: (office: Office) => void };

const OfficeTable: React.FC<Props> = ({ onDeleteClick }) => {
  const dispatch = useDispatch();
  const { offices, sort } = useSelector((state: RootState) => state.office.list);

  const setParams = (payload: PartialSearchingProps) => dispatch(setOfficeListParamsAction(payload));

  const redirectLink = (value: string, id: number) => (
    <Authorize permissions={[OFFICES_DETAILS]} fallback={value}>
      <Link to={getPathUrl(ROUTE_OFFICE, { id })}>{value}</Link>
    </Authorize>
  );

  const cols: TableCol<Office>[] = [
    {
      property: 'id',
      label: 'ID',
      sortable: true,
      value: (row) => redirectLink(`${row.id}`, row.id)
    },
    {
      property: 'name',
      label: 'Nazwa',
      sortable: true,
      value: (row) => redirectLink(row.name, row.id)
    },
    {
      property: 'regon',
      label: 'REGON',
      sortable: true
    },
    {
      property: 'email',
      label: 'Email',
      sortable: true,
      value: (row) => redirectLink(row.email, row.id)
    },
    {
      property: 'phone',
      label: 'Telefon',
      sortable: true
    },
    {
      property: 'city',
      label: 'Miasto',
      sortable: true
    },
    {
      property: 'street',
      label: 'Ulica',
      sortable: true
    },
    {
      property: 'postal_code',
      label: 'Kod pocztowy',
      sortable: true
    },
    {
      property: 'post_office',
      label: 'Miejscowość poczty',
      sortable: true
    },
    {
      property: 'last_executor_request',
      sortable: true,
      label: 'Ostatnie zapytanie',
      value: (row) => ifContainsDateFormat(row?.last_executor_request?.sent_at, formatDatetimeSeconds)
    },
    {
      property: 'next_request_at',
      sortable: true,
      label: 'Następne zapytanie',
      value: (row) => ifContainsDateFormat(row?.next_request_at, formatDate)
    },
    {
      property: 'actions',
      label: 'Akcje',
      value: function Actions(row) {
        return (
          <div className="actions-wrapper">
            <Authorize permissions={[OFFICES_EDIT, OFFICES_DELETE]}>
              <ActionUpdate
                className="mx-1"
                title="Edycja danych urzędu"
                label={
                  <EditIcon
                    height="20px"
                    className={
                      row.executor_request_status === LastExecutorRequestStatusesOptions.NOT_RECEIVED
                        ? 'icon-svg-table-error'
                        : 'icon-svg-table'
                    }
                  />
                }
                to={getPathUrl(ROUTE_OFFICE_UPDATE, { id: row.id })}
              />
              <ActionDelete
                className={
                  row.executor_request_status === LastExecutorRequestStatusesOptions.NOT_RECEIVED
                    ? 'icon-svg-table-error'
                    : 'icon-svg-table'
                }
                title="Usuń urząd"
                onClick={() => onDeleteClick(row)}
              />
            </Authorize>
          </div>
        );
      }
    }
  ];

  return (
    <Table
      cols={cols}
      rows={offices}
      sort={sort}
      onSort={(params) => setParams({ sort: params })}
      displayErrorRow={(row) =>
        row?.executor_request_status === LastExecutorRequestStatusesOptions.NOT_RECEIVED ? 'error-table-field' : ''
      }
    />
  );
};

export default OfficeTable;
