import { RootState } from 'app/reducer';
import Authorize from 'modules/Auth/container/Authorize';
import Table, { TableCol } from 'modules/Layout/component/Table';
import { getPathUrl } from 'modules/Shared/helper/api';
import { formatDatetimeSeconds, ifContainsDateFormat } from 'modules/Shared/helper/utils';
import { PartialSearchingProps } from 'modules/Shared/type';
import { setInvoiceListParamsAction } from 'modules/User/action/listInvoiceRequired';
import { breadcrumbRouteInvoiceRequiredListPaymentLog } from 'modules/User/breadcrumbs';
import InvoiceRequired from 'modules/User/model/Invoice';
import { PAYMENY_HISTORY_ALL } from 'modules/User/permissions';
import { ROUTE_INVOICES, ROUTE_USER_PAYMENT_LOG } from 'modules/User/routes';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const InvoicesRequiredTable = () => {
  const dispatch = useDispatch();
  const { invoice, sort } = useSelector((state: RootState) => state.user.invoiceList);

  const setParams = (payload: PartialSearchingProps) => dispatch(setInvoiceListParamsAction(payload));

  const redirectLink = (payment_id: string, user_id: number) => (
    <Authorize permissions={[PAYMENY_HISTORY_ALL]} fallback={payment_id}>
      <Link
        className="fill-table-td"
        to={{
          pathname: getPathUrl(ROUTE_USER_PAYMENT_LOG, { user_id, payment_id }),
          state: {
            redirectToOtherUrl: {
              url: ROUTE_INVOICES,
              breadcrumpItem: breadcrumbRouteInvoiceRequiredListPaymentLog(payment_id)
            }
          }
        }}
      >
        {payment_id}
      </Link>
    </Authorize>
  );

  const cols: TableCol<InvoiceRequired>[] = [
    {
      property: 'payment_number',
      label: 'Identyfikator płatności',
      value: (row) => redirectLink(row.payment_number, row.user_id)
    },
    {
      property: 'user_email',
      sortable: true,
      label: 'Email klienta'
    },
    {
      property: 'company_name',
      sortable: true,
      label: 'Nazwa firmy'
    },
    {
      property: 'finished_at',
      label: 'Data zakupu',
      sortable: true,
      value: (row) => ifContainsDateFormat(row.finished_at, formatDatetimeSeconds)
    },
    {
      property: 'amount',
      label: 'Kwota',
      value: (row) => (row ? `${row.amount.toFixed(2).replace('.', ',')} PLN` : '')
    },
    {
      property: 'subscriptions',
      label: 'Produkty'
    }
  ];

  return <Table cols={cols} rows={invoice} sort={sort} onSort={(params) => setParams({ sort: params })} />;
};

export default InvoicesRequiredTable;
