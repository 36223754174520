/* eslint-disable */
import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function EditUserIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px', className = 'icon-svg-table' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} height={height} viewBox="0 0 17.485 16.411">
      <g id="Group_440" data-name="Group 440" transform="translate(-1342.981 -433.422)">
        <path
          id="Path_1321"
          data-name="Path 1321"
          d="M1353.07,443.657a1.522,1.522,0,0,1-.608-.635v-.746a4.749,4.749,0,0,0,1.087-2.031,1.225,1.225,0,0,0,.227-1.752v-1.669c0-1.722-.937-2.9-3.081-2.9-2.088,0-3.082,1.18-3.082,2.9v1.67a1.227,1.227,0,0,0,.227,1.751,4.743,4.743,0,0,0,1.088,2.031v.746c-.216.71-2.8,1.851-5.117,2.77a.531.531,0,0,0-.33.5v.785"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_1322"
          data-name="Path 1322"
          d="M1363.042,447.619a3.092,3.092,0,1,0,0,4.374A3.092,3.092,0,0,0,1363.042,447.619Z"
          transform="translate(-3.981 -3.566)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_67"
          data-name="Line 67"
          y1="2.121"
          x2="1.984"
          transform="translate(1356.342 445.369)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_68"
          data-name="Line 68"
          x1="0.921"
          y1="0.985"
          transform="translate(1355.421 446.506)"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
