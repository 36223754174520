import React, { useEffect, useRef, useState } from 'react';
import { CustomInput, FormFeedback, FormGroup, Label } from 'reactstrap';
import { isEqual } from 'lodash';
import { getError, hasError } from 'modules/Shared/helper/validation';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_DELIVERY_REQUIREMENTS } from 'modules/Agreements/step';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import Part from 'modules/Agreements/model/Part';
import Accordion from 'modules/Layout/component/Accordion';

export interface AgreementStepDeliveryRequirementsValues {
  same_delivery_requirements_for_parts: boolean;
  delivery_requirements: {
    part_id: number | null;
    del_quality_requirements_checked: boolean;
    del_quality_requirements_content: string | null;
    del_no_defects_possibility: boolean;
    del_no_defects_law_checked: boolean;
    del_no_defects_law_content: string | null;
    del_no_defects_construction_checked: boolean;
    del_no_defects_construction_content: string | null;
    del_no_defects_material_checked: boolean;
    del_no_defects_material_content: string | null;
    del_no_defects_manufacturing_checked: boolean;
    del_no_defects_manufacturing_content: string | null;
    del_no_defects_other_checked: boolean;
    del_no_defects_other_content: string | null;
    del_no_defects_2_rights_checked: boolean;
    del_no_defects_2_rights_content: string | null;
    del_no_defects_2_procedure_checked: boolean;
    del_no_defects_2_procedure_content: string | null;
    del_no_defects_2_collateral_checked: boolean;
    del_no_defects_2_collateral_content: string | null;
    del_no_defects_2_other_checked: boolean;
    del_no_defects_2_other_content: string | null;
    del_brand_new_checked: boolean;
    del_brand_new_content: string | null;
    del_proper_transportation_checked: boolean;
    del_proper_transportation_content: string | null;
    del_right_conditions_checked: boolean;
    del_right_conditions_content: string | null;
    del_right_to_control_possibility: boolean;
    del_right_to_control_breeding_checked: boolean;
    del_right_to_control_breeding_content: string | null;
    del_right_to_control_storage_checked: boolean;
    del_right_to_control_storage_content: string | null;
    del_right_to_control_transport_checked: boolean;
    del_right_to_control_transport_content: string | null;
    del_registry_entry_checked: boolean;
    del_registry_entry_content: string | null;
    del_registry_entry_current_checked: boolean;
    del_registry_entry_current_content: string | null;
    del_certificate_checked: boolean;
    del_certificate_content: string | null;
    del_certificate_current_checked: boolean;
    del_certificate_current_content: string | null;
  }[];
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const DEL_QUALITY_REQUIREMENTS_CONTENT =
  'Zamawiający określa poniższe wymagania jakościowe dla Przedmiotu Umowy: ........';
const DEL_NO_DEFECTS_LAW_CONTENT = 'wad prawnych';
const DEL_NO_DEFECTS_CONSTRUCTION_CONTENT = 'wad konstrukcyjnych';
const DEL_NO_DEFECTS_MATERIAL_CONTENT = 'wad materiałowych';
const DEL_NO_DEFECTS_MANUFACTURING_CONTENT = 'wad wykonawczych';
const DEL_NO_DEFECTS_OTHER_CONTENT = 'inne: ........';
const DEL_NO_DEFECTS_2_RIGHTS_CONTENT = 'nie jest obciążony prawami na rzecz osób trzecich';
const DEL_NO_DEFECTS_2_PROCEDURE_CONTENT = 'nie toczy się żadne postępowanie, którego przedmiotem jest Przedmiot Umowy';
const DEL_NO_DEFECTS_2_COLLATERAL_CONTENT = 'nie stanowi przedmiotu zabezpieczenia';
const DEL_NO_DEFECTS_2_OTHER_CONTENT = 'inne: ........';
const DEL_BRAND_NEW_CONTENT =
  'Wykonawca gwarantuje, że dostarczony Przedmiot Umowy będzie fabrycznie nowy, oryginalny, kompletny, zgodny z wymaganiami Zamawiającego, oraz będzie posiadać kompletny zestaw wymaganych prawem dokumentów';
const DEL_PROPER_TRANSPORTATION_CONTENT =
  'Wykonawca zapewnia właściwy dla utrzymania parametrów i jakości dostarczanego Przedmiotu Umowy sposób transportu. W trakcie transportu Przedmiot Umowy musi być zabezpieczony przed działaniem czynników zewnętrznych (wilgocią, zabrudzeniem, itp.)';
const DEL_RIGHT_CONDITIONS_CONTENT =
  'Wykonawca zapewnia zachowanie warunków sanitarno-higienicznych wymaganych dla przygotowania, magazynowania bądź transportu Przedmiotu Umowy';
const DEL_RIGHT_TO_CONTROL_BREEDING_CONTENT = 'hodowli';
const DEL_RIGHT_TO_CONTROL_STORAGE_CONTENT = 'magazynowania';
const DEL_RIGHT_TO_CONTROL_TRANSPORT_CONTENT = 'transportu';
const DEL_REGISTRY_ENTRY_CONTENT =
  'Wykonawca oświadcza, że posiada odpowiedni wpis do rejestru ........ Wykonawca zobowiązuje się posiadać ważny wpis do rejestru, przez cały okres obowiązywania Umowy';
const DEL_REGISTRY_ENTRY_CURRENT_CONTENT =
  'Wykonawca oświadcza, że posiada odpowiednie zaświadczenie ........ Wykonawca zobowiązuje się posiadać ważne zaświadczenie przez cały okres obowiązywania Umowy';
const DEL_CERTIFICATE_CONTENT =
  'Wykonawca zobowiązany jest na każde żądanie Zamawiającego przedłożyć oryginał ważnego zaświadczenia o wpisie do rejestru';
const DEL_CERTIFICATE_CURRENT_CONTENT =
  'Wykonawca zobowiązany jest na każde żądanie Zamawiającego przedłożyć oryginał ważnego zaświadczenia';

const initContentValues = {
  del_quality_requirements_content: DEL_QUALITY_REQUIREMENTS_CONTENT,
  del_no_defects_law_content: DEL_NO_DEFECTS_LAW_CONTENT,
  del_no_defects_construction_content: DEL_NO_DEFECTS_CONSTRUCTION_CONTENT,
  del_no_defects_material_content: DEL_NO_DEFECTS_MATERIAL_CONTENT,
  del_no_defects_manufacturing_content: DEL_NO_DEFECTS_MANUFACTURING_CONTENT,
  del_no_defects_other_content: DEL_NO_DEFECTS_OTHER_CONTENT,
  del_no_defects_2_rights_content: DEL_NO_DEFECTS_2_RIGHTS_CONTENT,
  del_no_defects_2_procedure_content: DEL_NO_DEFECTS_2_PROCEDURE_CONTENT,
  del_no_defects_2_collateral_content: DEL_NO_DEFECTS_2_COLLATERAL_CONTENT,
  del_no_defects_2_other_content: DEL_NO_DEFECTS_2_OTHER_CONTENT,
  del_brand_new_content: DEL_BRAND_NEW_CONTENT,
  del_proper_transportation_content: DEL_PROPER_TRANSPORTATION_CONTENT,
  del_right_conditions_content: DEL_RIGHT_CONDITIONS_CONTENT,
  del_right_to_control_breeding_content: DEL_RIGHT_TO_CONTROL_BREEDING_CONTENT,
  del_right_to_control_storage_content: DEL_RIGHT_TO_CONTROL_STORAGE_CONTENT,
  del_right_to_control_transport_content: DEL_RIGHT_TO_CONTROL_TRANSPORT_CONTENT,
  del_registry_entry_content: DEL_REGISTRY_ENTRY_CONTENT,
  del_registry_entry_current_content: DEL_REGISTRY_ENTRY_CURRENT_CONTENT,
  del_certificate_content: DEL_CERTIFICATE_CONTENT,
  del_certificate_current_content: DEL_CERTIFICATE_CURRENT_CONTENT
};

const mapState = (step: AgreementStepDeliveryRequirementsValues): AgreementStepDeliveryRequirementsValues => {
  const { delivery_requirements, checkboxes, ...rest } = step;

  return {
    ...rest,
    delivery_requirements: delivery_requirements.map((part) => {
      const {
        del_quality_requirements_content,
        del_no_defects_law_content,
        del_no_defects_construction_content,
        del_no_defects_material_content,
        del_no_defects_manufacturing_content,
        del_no_defects_other_content,
        del_no_defects_2_rights_content,
        del_no_defects_2_procedure_content,
        del_no_defects_2_collateral_content,
        del_no_defects_2_other_content,
        del_brand_new_content,
        del_proper_transportation_content,
        del_right_conditions_content,
        del_right_to_control_breeding_content,
        del_right_to_control_storage_content,
        del_right_to_control_transport_content,
        del_registry_entry_content,
        del_registry_entry_current_content,
        del_certificate_content,
        del_certificate_current_content
      } = part;

      return {
        ...part,
        del_quality_requirements_content: del_quality_requirements_content || DEL_QUALITY_REQUIREMENTS_CONTENT,
        del_no_defects_law_content: del_no_defects_law_content || DEL_NO_DEFECTS_LAW_CONTENT,
        del_no_defects_construction_content: del_no_defects_construction_content || DEL_NO_DEFECTS_CONSTRUCTION_CONTENT,
        del_no_defects_material_content: del_no_defects_material_content || DEL_NO_DEFECTS_MATERIAL_CONTENT,
        del_no_defects_manufacturing_content:
          del_no_defects_manufacturing_content || DEL_NO_DEFECTS_MANUFACTURING_CONTENT,
        del_no_defects_other_content: del_no_defects_other_content || DEL_NO_DEFECTS_OTHER_CONTENT,
        del_no_defects_2_rights_content: del_no_defects_2_rights_content || DEL_NO_DEFECTS_2_RIGHTS_CONTENT,
        del_no_defects_2_procedure_content: del_no_defects_2_procedure_content || DEL_NO_DEFECTS_2_PROCEDURE_CONTENT,
        del_no_defects_2_collateral_content: del_no_defects_2_collateral_content || DEL_NO_DEFECTS_2_COLLATERAL_CONTENT,
        del_no_defects_2_other_content: del_no_defects_2_other_content || DEL_NO_DEFECTS_2_OTHER_CONTENT,
        del_brand_new_content: del_brand_new_content || DEL_BRAND_NEW_CONTENT,
        del_proper_transportation_content: del_proper_transportation_content || DEL_PROPER_TRANSPORTATION_CONTENT,
        del_right_conditions_content: del_right_conditions_content || DEL_RIGHT_CONDITIONS_CONTENT,
        del_right_to_control_breeding_content:
          del_right_to_control_breeding_content || DEL_RIGHT_TO_CONTROL_BREEDING_CONTENT,
        del_right_to_control_storage_content:
          del_right_to_control_storage_content || DEL_RIGHT_TO_CONTROL_STORAGE_CONTENT,
        del_right_to_control_transport_content:
          del_right_to_control_transport_content || DEL_RIGHT_TO_CONTROL_TRANSPORT_CONTENT,
        del_registry_entry_content: del_registry_entry_content || DEL_REGISTRY_ENTRY_CONTENT,
        del_registry_entry_current_content: del_registry_entry_current_content || DEL_REGISTRY_ENTRY_CURRENT_CONTENT,
        del_certificate_content: del_certificate_content || DEL_CERTIFICATE_CONTENT,
        del_certificate_current_content: del_certificate_current_content || DEL_CERTIFICATE_CURRENT_CONTENT
      };
    }),
    checkboxes: checkboxes || {}
  };
};

const AgreementStepDeliveryRequirements = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, onSubmit, onChange, errors } = props;
  const initState = useRef<AgreementStepDeliveryRequirementsValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_DELIVERY_REQUIREMENTS))
  );
  const [stepValues, setStepValues] = useState<AgreementStepDeliveryRequirementsValues>({ ...initState.current });
  const { parts_ids, few_parts } = agreement;
  const { delivery_requirements, same_delivery_requirements_for_parts } = stepValues;

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_DELIVERY_REQUIREMENTS));
    setStepValues({ ...initState.current });
  }, [steps]);

  const isMultiple = few_parts && !same_delivery_requirements_for_parts;

  const samePartsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setStepValues((values) =>
      mapState({
        ...values,
        same_delivery_requirements_for_parts: checked,
        delivery_requirements: checked
          ? [
              {
                ...delivery_requirements[0],
                part_id: null
              }
            ]
          : parts_ids.map((part) => ({
              ...delivery_requirements[0],
              part_id: part.id
            }))
      })
    );
  };

  const {
    renderPartCheckbox,
    renderPartEditableCheckbox,
    renderPartContent,
    renderPartSwitch,
    renderCustomCheckboxes
  } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    partSlug: 'delivery_requirements',
    initContentValues
  });

  const renderPart = (partObj: Part, index: number) => {
    const part = isMultiple ? delivery_requirements[index] : delivery_requirements[0];

    const body = (
      <FormGroup>
        {[
          renderPartSwitch(
            'del_quality_requirements_checked',
            'Czy zamawiający chce określić w Umowie wymagania jakościowe dotyczące dostaw?',
            index,
            part
          ),
          part.del_quality_requirements_checked && renderPartContent('del_quality_requirements_content', index, part),
          <hr />,
          renderPartSwitch(
            'del_no_defects_possibility',
            'Wykonawca oświadcza, że Przedmiot Umowy  jest wolny od:',
            index,
            part
          ),
          part.del_no_defects_possibility && (
            <div className="pl-3">
              {[
                renderPartCheckbox('del_no_defects_law_checked', part.del_no_defects_law_content, index, part),
                renderPartCheckbox(
                  'del_no_defects_construction_checked',
                  part.del_no_defects_construction_content,
                  index,
                  part
                ),
                renderPartCheckbox(
                  'del_no_defects_material_checked',
                  part.del_no_defects_material_content,
                  index,
                  part
                ),
                renderPartCheckbox(
                  'del_no_defects_manufacturing_checked',
                  part.del_no_defects_manufacturing_content,
                  index,
                  part
                ),
                renderPartEditableCheckbox('del_no_defects_other', index, part),
                <Label>oraz, że Przedmiot Umowy:</Label>,
                renderPartCheckbox(
                  'del_no_defects_2_rights_checked',
                  part.del_no_defects_2_rights_content,
                  index,
                  part
                ),
                renderPartCheckbox(
                  'del_no_defects_2_procedure_checked',
                  part.del_no_defects_2_procedure_content,
                  index,
                  part
                ),
                renderPartCheckbox(
                  'del_no_defects_2_collateral_checked',
                  part.del_no_defects_2_collateral_content,
                  index,
                  part
                ),
                renderPartEditableCheckbox('del_no_defects_2_other', index, part)
              ]}
            </div>
          ),
          <hr />,
          renderPartCheckbox('del_brand_new_checked', part.del_brand_new_content, index, part),
          renderPartCheckbox('del_proper_transportation_checked', part.del_proper_transportation_content, index, part),
          renderPartCheckbox('del_right_conditions_checked', part.del_right_conditions_content, index, part),
          <hr />,
          renderPartSwitch(
            'del_right_to_control_possibility',
            'W trakcie realizacji umowy zamawiający ma prawo sprawdzić odpowiednio i stosownie warunki:',
            index,
            part
          ),
          part.del_right_to_control_possibility && (
            <div className="pl-3">
              {[
                renderPartCheckbox(
                  'del_right_to_control_breeding_checked',
                  part.del_right_to_control_breeding_content,
                  index,
                  part
                ),
                renderPartCheckbox(
                  'del_right_to_control_storage_checked',
                  part.del_right_to_control_storage_content,
                  index,
                  part
                ),
                renderPartCheckbox(
                  'del_right_to_control_transport_checked',
                  part.del_right_to_control_transport_content,
                  index,
                  part
                ),
                <Label>Przedmiotu Umowy, pod kątem zgodności z opisem przedmiotu zamówienia</Label>
              ]}
            </div>
          ),
          <hr />,
          renderPartEditableCheckbox('del_registry_entry', index, part),
          renderPartEditableCheckbox('del_registry_entry_current', index, part),
          renderPartCheckbox('del_certificate_checked', part.del_certificate_content, index, part),
          renderPartCheckbox('del_certificate_current_checked', part.del_certificate_current_content, index, part),
          renderCustomCheckboxes('general', partObj?.id)
        ]}
      </FormGroup>
    );

    if (isMultiple) {
      return (
        <Accordion
          key={`renderPartAccordion-${partObj.id}`}
          isInvalid={hasError(errors, `delivery_requirements.${index}`)}
          accordionContentStyle={{ padding: '1rem' }}
          entity={{
            title: partObj.getName(index),
            content: body
          }}
        />
      );
    }

    return body;
  };

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      {few_parts && (
        <FormGroup>
          <CustomInput
            id="same_delivery_requirements_for_parts"
            name="same_delivery_requirements_for_parts"
            type="switch"
            checked={same_delivery_requirements_for_parts}
            onChange={samePartsChange}
            invalid={hasError(errors, 'same_delivery_requirements_for_parts')}
            label="Czy wymagania dotyczące dostaw są wspólne dla wszystkich zadań?"
          />
          {hasError(errors, 'same_delivery_requirements_for_parts') && (
            <FormFeedback className="d-block">{getError(errors, 'same_delivery_requirements_for_parts')}</FormFeedback>
          )}
        </FormGroup>
      )}
      {(isMultiple ? parts_ids : [null]).map(renderPart)}
      {isMultiple && (
        <>
          <p className="h4">Ponadto dla każdego z zadań:</p>
          {renderCustomCheckboxes()}
        </>
      )}
    </AgreementFormWrapper>
  );
};

export default AgreementStepDeliveryRequirements;
