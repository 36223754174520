import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import BaseModal, { Props } from 'modules/Layout/component/Modal';
import { Message } from 'modules/Shared/type';
import React from 'react';
import { Button } from 'reactstrap';

interface IProps extends Props {
  sectionName: string;
  content: string;
  loading: boolean;
  message?: Message;
}

const ModalExecutorExplanantion: React.FC<IProps> = (props) => {
  const { sectionName, content, loading, toggle, message } = props;
  const title = `Wyjaśnienie sekcji "${sectionName}"`;

  const dispalBody = () => {
    return (
      <>
        {loading && (
          <div className="my-4">
            <Loader />
          </div>
        )}
        <Alert message={message} />

        <div className="information-content" dangerouslySetInnerHTML={{ __html: content }} />
      </>
    );
  };

  const displayFooter = () => {
    return (
      <div className="modal-actions">
        <Button
          type="button"
          color="primary"
          className="pzpeu-btn-disabled pzpeu-btn-flex waves-effect waves-light"
          disabled={loading}
          onClick={toggle}
        >
          Powrót
        </Button>
      </div>
    );
  };

  return (
    <BaseModal
      size="xl"
      paddingX="30px"
      paddingTop="10px"
      paddingBottom="40px"
      {...props}
      title={title}
      body={dispalBody()}
      footer={displayFooter()}
    />
  );
};

export default ModalExecutorExplanantion;
