import {
  SubscriptionsPaymentAction,
  SUBSCRIPTIONS_PAYMENT_CLEAN,
  SUBSCRIPTIONS_PAYMENT_FETCHED,
  SUBSCRIPTIONS_PAYMENT_FETCHING,
  SUBSCRIPTIONS_PAYMENT_SESSION
} from 'modules/Subscriptions/action/payments';
import { initSubscriptionsPaymentState, SubscriptionsPaymentState } from 'modules/Subscriptions/state/payments';

const initState = initSubscriptionsPaymentState();

const reducer = (
  state: SubscriptionsPaymentState = initState,
  action: SubscriptionsPaymentAction
): SubscriptionsPaymentState => {
  switch (action.type) {
    case SUBSCRIPTIONS_PAYMENT_SESSION: {
      return { ...state, session_id: action.payload };
    }

    case SUBSCRIPTIONS_PAYMENT_FETCHING: {
      return { ...state, loading: true };
    }

    case SUBSCRIPTIONS_PAYMENT_FETCHED: {
      return { ...state, loading: false, ...action.payload };
    }

    case SUBSCRIPTIONS_PAYMENT_CLEAN: {
      return { ...state, loading: false, session_id: '' };
    }

    default:
      return state;
  }
};

export default reducer;
