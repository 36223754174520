import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AGREEMENT_TYPE_SUPPLEMENT, AgreementStepProps } from 'modules/Agreements/type';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import StepImportDataButton from 'modules/Agreements/components/StepImportDataButton';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_CO_FINANCING } from 'modules/Agreements/step';
import { Button, Card, CardBody, CustomInput, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { getError, hasError } from 'modules/Shared/helper/validation';
import { suffixLabel } from 'modules/Layout/helper/misc';
import { fetchCoFinancingProjects } from 'modules/Agreements/repository';
import Loader from 'modules/Layout/component/Loader';
import { isEqual } from 'lodash';
import { addToastAction } from 'modules/Layout/action';
import { fetchCoFinancingProjectsToastError } from 'modules/Agreements/toasts';
import Select from 'modules/Layout/component/Input/Select';
import { perspectiveOptions } from 'modules/Agreements/model/Perspective';
import { SUBGROUP_INTERREGIONAL, SUBGROUP_REGIONAL } from 'modules/Agreements/model/Subgroup';
import Accordion from 'modules/Layout/component/Accordion';
import { IMPORT_ORDERS_ACCESS } from '../../../../../Auth/container/Guard/permissions';
import Authorize from '../../../../../Auth/container/Authorize';

export interface AgreementStepCoFinancingValues {
  proceeding_id?: number;
  proceeding_name?: string;
  project_name?: string;
  co_financing_projects?: CoFinancingProject[];
  perspective?: number | string;
  co_financing_projects_allowed?: boolean;
  co_financing_project_ids: number[];
  co_financing_project_names: string[];
  proceeding?: {
    id: number;
    name: string;
    identifier: string;
  };
  [key: string]: any;
}

export interface CoFinancingProject {
  id?: number;
  co_financing_project_id?: number | null;
  source_name?: string | null;
  program_name?: string | null;
  project_name?: string | null;
  task_name?: string;
  co_financing_internal?: boolean;
  co_financing_external?: boolean;
  agreement_nr?: string;
}

export type AgreementStepCoFinancingImportValues = AgreementStepCoFinancingValues;

const mapState = (step: AgreementStepCoFinancingValues): AgreementStepCoFinancingValues => {
  const { co_financing_project_ids, co_financing_project_names, co_financing_projects, checkboxes, ...rest } = step;

  return {
    ...rest,
    co_financing_project_ids: co_financing_project_ids || [],
    co_financing_project_names: co_financing_project_names || [],
    co_financing_projects,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepCoFinancing = (props: AgreementStepProps): JSX.Element => {
  const dispatch = useDispatch();
  const { steps, stepsImportData, onSubmit, onChange, errors, agreement } = props;
  const initState = useRef<AgreementStepCoFinancingValues>(mapState(getStepValues(steps, AGREEMENT_STEP_CO_FINANCING)));
  const [stepValues, setStepValues] = useState<AgreementStepCoFinancingValues>({ ...initState.current });
  const [coFinancingProjects, setCoFinancingProjects] = useState<
    { id: number; name: string; subgroup: number; perspective: number }[]
  >([]);
  const [coFinancingProjectsLoading, setCoFinancingProjectsLoading] = useState<boolean>(true);
  const stepDataImportValues: AgreementStepCoFinancingImportValues = getStepValues(
    stepsImportData,
    AGREEMENT_STEP_CO_FINANCING
  );

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_CO_FINANCING));
    setStepValues({
      ...initState.current,
      co_financing_projects: initState.current.co_financing_projects.map((project: CoFinancingProject, id) => {
        return {
          ...project,
          id,
          co_financing_internal: Boolean(project.co_financing_project_id),
          co_financing_external: project.co_financing_project_id === null
        };
      })
    });
  }, [steps]);

  const fetchProjects = async () => {
    try {
      const response = await fetchCoFinancingProjects();
      // @ts-ignore
      setCoFinancingProjects(response.data.data);
    } catch (error) {
      dispatch(addToastAction(fetchCoFinancingProjectsToastError()));
      throw error;
    } finally {
      setCoFinancingProjectsLoading(false);
    }
  };

  const renderSwitch = (switchKey: string, label: string) => {
    return (
      <FormGroup key={switchKey}>
        <CustomInput
          id={switchKey}
          type="switch"
          label={label}
          checked={stepValues[switchKey]}
          disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
          onChange={(event) => {
            event.persist();
            setStepValues((values) => ({
              ...values,
              [switchKey]: event.target.checked
            }));
          }}
        />
      </FormGroup>
    );
  };

  const renderPrograms = (coProjectIndex: number, co_project: CoFinancingProject) => {
    const renderCheckboxes = (
      projects: { id: number; name: string; subgroup: number; perspective: number }[],
      coProjectId: number
    ) => {
      return projects.map((project) => (
        <CustomInput
          id={`${project.id}-${co_project.id}`}
          key={project.id}
          type="checkbox"
          checked={coProjectId === project.id}
          label={project.name}
          disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
          invalid={hasError(errors, 'co_financing_project_ids')}
          onChange={(event) => handleCheckboxChange(project.id, event.target.checked, coProjectIndex)}
        />
      ));
    };

    const handleCheckboxChange = (projectId: number, checked: boolean, projectIndex: number) => {
      const updatedProjects = stepValues.co_financing_projects.map((obj, i) => {
        if (i === projectIndex) {
          const updatedObj = { ...obj };
          updatedObj.co_financing_project_id = checked ? projectId : null;
          return updatedObj;
        }
        return obj;
      });

      setStepValues((prevValues) => ({
        ...prevValues,
        co_financing_projects: updatedProjects
      }));
    };

    const perspectiveProjects = coFinancingProjects.filter((project) => project.perspective === stepValues.perspective);
    const regionalProjects = perspectiveProjects.filter((project) => project.subgroup === SUBGROUP_REGIONAL);
    const interregionalProjects = perspectiveProjects.filter((project) => project.subgroup === SUBGROUP_INTERREGIONAL);
    const notGroupProjects = perspectiveProjects.filter(
      (project) => project.subgroup !== SUBGROUP_REGIONAL && project.subgroup !== SUBGROUP_INTERREGIONAL
    );

    return (
      <>
        <div className="mb-2">{renderCheckboxes(notGroupProjects, co_project.co_financing_project_id)}</div>
        {interregionalProjects.length > 0 && (
          <Accordion
            entity={{
              title: 'Programy Interregionalne',
              content: <>{renderCheckboxes(interregionalProjects, co_project.co_financing_project_id)}</>
            }}
          />
        )}
        {regionalProjects.length > 0 && (
          <Accordion
            entity={{
              title: 'Programy Regionalne',
              content: <>{renderCheckboxes(regionalProjects, co_project.co_financing_project_id)}</>
            }}
          />
        )}
      </>
    );
  };

  const renderCoFinancingProjects = () => {
    const { co_financing_projects } = stepValues;

    const onProjectChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, key: string) => {
      const updatedProjects = stepValues.co_financing_projects.map((obj, i) => {
        if (i === index) {
          return {
            ...obj,
            [key]: event.target.value
          };
        }
        return obj;
      });

      setStepValues((prevValues) => ({
        ...prevValues,
        co_financing_projects: updatedProjects
      }));
    };

    const handleAddNewCoProject = () => {
      const newCoProject: CoFinancingProject = {
        co_financing_project_id: null,
        source_name: '',
        program_name: '',
        project_name: '',
        task_name: '',
        agreement_nr: ''
      };

      const updatedCoProjects = [...(stepValues.co_financing_projects || []), newCoProject];

      setStepValues((prevValues) => ({
        ...prevValues,
        co_financing_projects: updatedCoProjects
      }));
    };

    const handleDeleteCoProject = (coProjectIndex: number) => {
      const updatedCoProjects = [...stepValues.co_financing_projects];
      updatedCoProjects.splice(coProjectIndex, 1);

      setStepValues((prevValues) => ({
        ...prevValues,
        co_financing_projects: updatedCoProjects
      }));
    };

    return (
      <>
        {co_financing_projects.map((co_project, index) => (
          <Card key={`${co_project.id}`}>
            <CardBody>
              <div className="flex-row d-flex justify-content-between align-items-start">
                <div className="w-100">
                  <FormGroup>
                    <CustomInput
                      id={`internal_financing-${index}`}
                      name={`internal_financing-${index}`}
                      type="checkbox"
                      checked={co_project.co_financing_internal}
                      label="Dofinansowanie ze środków Unii Europejskiej"
                      disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
                      onChange={(event) => {
                        const updatedProjects = stepValues.co_financing_projects.map((obj, i) => {
                          if (i === index) {
                            const updatedObj = { ...obj };
                            updatedObj.co_financing_external = !event.target.checked;
                            updatedObj.co_financing_internal = event.target.checked;
                            return updatedObj;
                          }
                          return obj;
                        });

                        setStepValues((prevValues) => ({
                          ...prevValues,
                          co_financing_projects: updatedProjects
                        }));
                      }}
                    />
                    {hasError(errors, `co_financing_projects.${index}.co_financing_internal`) && (
                      <FormFeedback>
                        {getError(errors, `co_financing_projects.${index}.co_financing_internal`)}
                      </FormFeedback>
                    )}
                    <CustomInput
                      id={`external_financing-${index}`}
                      name={`external_financing-${index}`}
                      checked={co_project.co_financing_external}
                      type="checkbox"
                      label="Dofinansowanie z innych źródeł"
                      disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
                      onChange={(event) => {
                        const updatedProjects = stepValues.co_financing_projects.map((obj, i) => {
                          if (i === index) {
                            const updatedObj = { ...obj };
                            updatedObj.co_financing_external = event.target.checked;
                            updatedObj.co_financing_internal = !event.target.checked;
                            return updatedObj;
                          }
                          return obj;
                        });

                        setStepValues((prevValues) => ({
                          ...prevValues,
                          co_financing_projects: updatedProjects
                        }));
                      }}
                    />
                    {hasError(errors, `co_financing_projects.${index}.co_financing_external`) && (
                      <FormFeedback>
                        {getError(errors, `co_financing_projects.${index}.co_financing_external`)}
                      </FormFeedback>
                    )}
                  </FormGroup>
                  {co_project.co_financing_internal && (
                    <>
                      <FormGroup>
                        <Label>Proszę wybrać program w ramach, którego zamówienie jest współfinansowane</Label>
                        {renderPrograms(index, co_project)}
                      </FormGroup>
                      <FormGroup>
                        <Label for={`project-name-${index}`}>Nazwa projektu</Label>
                        <Input
                          type="text"
                          name={`project-name-${index}`}
                          id={`project-name-${index}`}
                          maxLength={255}
                          value={co_project.project_name || ''}
                          onChange={(event) => onProjectChange(event, index, 'project_name')}
                          invalid={hasError(errors, `co_financing_projects.${index}.project_name`)}
                        />
                        {hasError(errors, `co_financing_projects.${index}.project_name`) && (
                          <FormFeedback>{getError(errors, `co_financing_projects.${index}.project_name`)}</FormFeedback>
                        )}
                        <Label className="mt-2" for={`task-name-${index}`}>
                          Nazwa zadania
                        </Label>
                        <Input
                          type="text"
                          name={`task-name-${index}`}
                          id={`task-name-${index}`}
                          maxLength={255}
                          invalid={hasError(errors, `co_financing_projects.${index}.task_name`)}
                          value={co_project.task_name || ''}
                          onChange={(event) => onProjectChange(event, index, 'task_name')}
                        />
                        {hasError(errors, `co_financing_projects.${index}.task_name`) && (
                          <FormFeedback>{getError(errors, `co_financing_projects.${index}.task_name`)}</FormFeedback>
                        )}
                        <Label className="mt-2" for={`agreement-nr-${index}`}>
                          Numer umowy
                        </Label>
                        <Input
                          type="text"
                          name={`agreement-nr-${index}`}
                          id={`agreement-nr-${index}`}
                          maxLength={255}
                          invalid={hasError(errors, `co_financing_projects.${index}.agreement_nr`)}
                          value={co_project.agreement_nr || ''}
                          onChange={(event) => onProjectChange(event, index, 'agreement_nr')}
                        />
                        {hasError(errors, `co_financing_projects.${index}.agreement_nr`) && (
                          <FormFeedback>{getError(errors, `co_financing_projects.${index}.agreement_nr`)}</FormFeedback>
                        )}
                      </FormGroup>
                    </>
                  )}
                  {co_project.co_financing_external && (
                    <>
                      <div>
                        <FormGroup>
                          <Label for={`source-name-${index}`}>Określenie Źródła dofinansowania</Label>
                          <Input
                            type="text"
                            name={`source-name-${index}`}
                            id={`source-name-${index}`}
                            maxLength={255}
                            value={co_project.source_name || ''}
                            onChange={(event) => onProjectChange(event, index, 'source_name')}
                            invalid={hasError(errors, `co_financing_projects.${index}.source_name`)}
                          />
                          {hasError(errors, `co_financing_projects.${index}.source_name`) && (
                            <FormFeedback>
                              {getError(errors, `co_financing_projects.${index}.source_name`)}
                            </FormFeedback>
                          )}
                          <Label className="mt-2" for={`program-name-${index}`}>
                            Nazwa programu
                          </Label>
                          <Input
                            type="text"
                            name={`program-name-${index}`}
                            id={`program-name-${index}`}
                            maxLength={255}
                            invalid={hasError(errors, `co_financing_projects.${index}.program_name`)}
                            value={co_project.program_name || ''}
                            onChange={(event) => onProjectChange(event, index, 'program_name')}
                          />
                          {hasError(errors, `co_financing_projects.${index}.program_name`) && (
                            <FormFeedback>
                              {getError(errors, `co_financing_projects.${index}.program_name`)}
                            </FormFeedback>
                          )}
                          <Label className="mt-2" for={`project-name-${index}`}>
                            Nazwa projektu
                          </Label>
                          <Input
                            type="text"
                            name={`project-name-${index}`}
                            id={`project-name-${index}`}
                            maxLength={255}
                            value={co_project.project_name || ''}
                            onChange={(event) => onProjectChange(event, index, 'project_name')}
                            invalid={hasError(errors, `co_financing_projects.${index}.project_name`)}
                          />
                          {hasError(errors, `co_financing_projects.${index}.project_name`) && (
                            <FormFeedback>
                              {getError(errors, `co_financing_projects.${index}.project_name`)}
                            </FormFeedback>
                          )}
                          <Label className="mt-2" for={`task-name-${index}`}>
                            Nazwa zadania
                          </Label>
                          <Input
                            type="text"
                            name={`task-name-${index}`}
                            id={`task-name-${index}`}
                            maxLength={255}
                            invalid={hasError(errors, `co_financing_projects.${index}.task_name`)}
                            value={co_project.task_name || ''}
                            onChange={(event) => onProjectChange(event, index, 'task_name')}
                          />
                          {hasError(errors, `co_financing_projects.${index}.task_name`) && (
                            <FormFeedback>{getError(errors, `co_financing_projects.${index}.task_name`)}</FormFeedback>
                          )}
                          <Label className="mt-2" for={`agreement-nr-${index}`}>
                            Numer umowy
                          </Label>
                          <Input
                            type="text"
                            name={`agreement-nr-${index}`}
                            id={`agreement-nr-${index}`}
                            maxLength={255}
                            invalid={hasError(errors, `co_financing_projects.${index}.agreement_nr`)}
                            value={co_project.agreement_nr || ''}
                            onChange={(event) => onProjectChange(event, index, 'agreement_nr')}
                          />
                          {hasError(errors, `co_financing_projects.${index}.agreement_nr`) && (
                            <FormFeedback>
                              {getError(errors, `co_financing_projects.${index}.agreement_nr`)}
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </div>
                    </>
                  )}
                </div>
                <i onClick={() => handleDeleteCoProject(index)} className="fa cursor-pointer fa-times font-18" />
              </div>
            </CardBody>
          </Card>
        ))}
        {hasError(errors, `co_financing_projects`) && (
          <FormFeedback>{getError(errors, `co_financing_projects`)}</FormFeedback>
        )}
        {stepValues.perspective !== 0 && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button style={{ maxWidth: '100%' }} className="mt-2" onClick={handleAddNewCoProject}>
              Dodać kolejne źródło dofinansowania ze środków zewnętrznych?
            </Button>
          </div>
        )}
      </>
    );
  };

  const onStepDataImportClick = () => {
    setStepValues((values) => {
      return {
        ...values,
        ...stepDataImportValues
      };
    });
  };

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <Authorize permissions={[IMPORT_ORDERS_ACCESS]}>
        {agreement.type !== AGREEMENT_TYPE_SUPPLEMENT && (
          <StepImportDataButton onSubmit={onStepDataImportClick} stepDataImportValues={stepDataImportValues} />
        )}
      </Authorize>
      {renderSwitch('co_financing_projects_allowed', 'Czy postepowanie jest dofinansowane ze środków zewnętrznych?')}
      {stepValues.co_financing_projects_allowed && (
        <>
          <FormGroup>
            <Label for="perspective">{suffixLabel('Proszę wybrać perspektywę', true)}</Label>
            <Select
              name="perspective"
              id="perspective"
              value={perspectiveOptions.find((option) => option.value === stepValues.perspective)}
              onChange={(event) => {
                setStepValues((values) => ({
                  ...values,
                  perspective: event.value
                }));
              }}
              options={perspectiveOptions}
              isDisabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
              invalid={hasError(errors, 'perspective')}
              required
            />
          </FormGroup>
          {coFinancingProjectsLoading ? <Loader /> : [renderCoFinancingProjects()]}
        </>
      )}
    </AgreementFormWrapper>
  );
};

export default AgreementStepCoFinancing;
