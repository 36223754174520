import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  redirectUrl: string;
}

const ReturnButton: React.FC<Props> = ({ redirectUrl }) => {
  return (
    <Link to={redirectUrl} className="btn btn-primary waves-effect waves-light">
      Powrót
    </Link>
  );
};
export default ReturnButton;
