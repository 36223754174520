import React, { ReactNode } from 'react';
import Logo from 'modules/Auth/component/Logo';
import { ROUTE_PUBLIC_FEEDBACK } from 'modules/Feedback/routes';
import { ROUTE_MAIN, ROUTE_PUBLIC_DECLARATION_OF_AVAILABILITY } from 'modules/Layout/routes';
import { ROUTE_PROCEEDINGS_PUBLIC_OPEN } from 'modules/Proceedings/routes';
import './style.scss';

export interface Props {
  children: ReactNode;
  hideHeader?: boolean;
  displayHeaderElement?: React.ReactNode;
  hideFooter?: boolean;
  className?: string;
}

class PublicWrapper extends React.Component<Props> {
  renderHeader(): ReactNode {
    const { hideHeader, displayHeaderElement } = this.props;

    if (hideHeader) {
      return null;
    }

    return (
      <header id="header" className={displayHeaderElement ? 'd-flex' : ''}>
        <div className="hide-logo">
          <Logo target="_blank" route={ROUTE_MAIN} height={36} />
        </div>

        {!!displayHeaderElement && displayHeaderElement}
      </header>
    );
  }

  renderContent(): ReactNode {
    const { children } = this.props;

    return <div className="public-content">{children}</div>;
  }

  renderFooter(): ReactNode {
    const { hideFooter } = this.props;

    if (hideFooter) {
      return null;
    }

    const regulationsUrl = process.env.REGULATIONS_URL || '';
    const privacyPolicyUrl = process.env.PRIVACY_POLICY_URL || '';

    return (
      <footer id="footer">
        <div className="footer-content d-flex justify-content-center align-items-center flex-wrap">
          <ul className="m-0 p-0 text-center list-unstyled d-flex justify-content-center flex-wrap">
            {privacyPolicyUrl && (
              <li className="d-block d-md-inline m-0">
                <a href={privacyPolicyUrl} rel="noreferrer" target="_blank">
                  Polityka prywatności
                </a>
              </li>
            )}
            {regulationsUrl && (
              <li className="d-block d-md-inline m-0">
                <a href={regulationsUrl} rel="noreferrer" target="_blank">
                  Regulamin
                </a>
              </li>
            )}
            <li className="d-block d-md-inline m-0">
              <a href={ROUTE_PUBLIC_FEEDBACK}>Kontakt</a>
            </li>
            <li className="d-block d-md-inline m-0">
              <a href={ROUTE_PROCEEDINGS_PUBLIC_OPEN}>Wyszukiwarka prowadzonych postępowań</a>
            </li>
            <li className="d-block d-md-inline m-0">
              <a href={ROUTE_PUBLIC_DECLARATION_OF_AVAILABILITY}>Deklaracja dostępności</a>
            </li>
          </ul>
          <div className="mb-2" />
        </div>
      </footer>
    );
  }

  render(): ReactNode {
    const { className } = this.props;

    return (
      <div className={`public-wrapper h-100 ${className}`}>
        {this.renderHeader()}
        {this.renderContent()}
        {this.renderFooter()}
      </div>
    );
  }
}

export default PublicWrapper;
