import ActionDelete from 'modules/Layout/component/Action/Delete';
import TrashIcon from 'modules/Layout/component/Icon/Trash';
import Tooltip from 'modules/Layout/component/Tooltip';
import React from 'react';

type ConfigObj = {
  tooltipContent: string;
  onDeleteClick: (row: any) => void;
  targetIdName: string;
  title: string;
};

const actionDeleteTrashIconList = (row: Partial<{ is_deletable?: boolean; id: number }>, configObj: ConfigObj) => {
  const isDeletable = row?.is_deletable === undefined ? true : row.is_deletable;
  const fill = isDeletable ? '$primary' : '#666666';
  const className = isDeletable ? 'icon-svg-table' : '';

  const trashIcon = (
    <>
      <TrashIcon height="20px" fill={fill} className={className} />
      {!isDeletable && (
        <Tooltip
          boundariesElement="viewport"
          targetId={`${configObj.targetIdName}${row.id}`}
          tooltipContent={configObj.tooltipContent}
          tooltipPlacement="left"
        />
      )}
    </>
  );

  return (
    <ActionDelete
      className="mx-1 position-relative"
      title={isDeletable ? configObj.title : ''}
      onClick={isDeletable ? () => configObj.onDeleteClick(row) : () => null}
      label={trashIcon}
      id={`${configObj.targetIdName}${row.id}`}
    />
  );
};

export default actionDeleteTrashIconList;
