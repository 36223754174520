import React, { useEffect, useState } from 'react';
import { managePageAction } from 'modules/Layout/action';
import { BreadcrumbItem, PageProps } from 'modules/Layout/type';
import { useDispatch } from 'react-redux';
import usePaymentLog from 'modules/Shared/components/PaymentLog/usePaymentLog';
import FetchingWrapper from 'modules/Shared/helper/FetchingWrapper';
import { useLocation, useParams } from 'react-router';
import DetailsComponent from 'modules/Shared/components/PaymentLog/Details';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { fetchUser } from 'modules/User/repository';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { UserEntity } from 'modules/User/model/User';
import { breadcrumbRouteUserDeletedPaymentLog, breadcrumbRouteUserPaymentLog } from 'modules/User/breadcrumbs';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_USER } from 'modules/User/routes';

const PaymentLogsDetails = () => {
  const dispatch = useDispatch();
  const { user_id, payment_id } = useParams<{ user_id: string; payment_id: string }>();
  const [user, setUser] = useState<UserEntity>(null);
  const { state } = useLocation<{
    userName?: string;
    isUserActive?: boolean;
    redirectToOtherUrl?: { url?: string; breadcrumpItem?: BreadcrumbItem[] };
  }>();
  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  const { message, fetching, paymentLog } = usePaymentLog(user_id, payment_id);

  const cancelToken = useCancelToken();
  useEffect(() => {
    if (!state?.userName) {
      (async () => {
        const { data, cancelled } = await fetchRequest(fetchUser, user_id, cancelToken);

        if (cancelled) return;
        if (data) setUser(data.data);
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paymentLog && (state?.userName || user?.name)) {
      const isActive = state?.isUserActive ?? user?.status;

      const { payment_number } = paymentLog;
      const title = `Szczegóły transakcji${!!payment_number && ` - ${payment_number}`}`;

      let breadcrumb = null;
      if (state?.redirectToOtherUrl) {
        breadcrumb = state?.redirectToOtherUrl?.breadcrumpItem;
      } else {
        // eslint-disable-next-line no-lonely-if
        if (isActive)
          breadcrumb = breadcrumbRouteUserPaymentLog(user_id, state?.userName || user?.name, payment_number);
        else breadcrumb = breadcrumbRouteUserDeletedPaymentLog(user_id, state?.userName || user?.name, payment_number);
      }

      managePage({
        title,
        breadcrumb
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentLog, user]);

  const redirectUrl = state?.redirectToOtherUrl?.url ?? getPathUrl(ROUTE_USER, { id: user_id });

  return (
    <FetchingWrapper fetching={fetching} message={message} data={paymentLog}>
      <DetailsComponent paymentLog={paymentLog} redirectUrl={redirectUrl} />
    </FetchingWrapper>
  );
};

export default PaymentLogsDetails;
