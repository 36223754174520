import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import BaseModal, { Props } from 'modules/Layout/component/Modal';
import { Message } from 'modules/Shared/type';
import React, { useState } from 'react';
import { Button, Input, Form as FormStrap, Label, CustomInput } from 'reactstrap';
import './index.scss';

interface IProps extends Props {
  onSaveClick: (name: string, isDefault?: number, shouldRefetchList?: boolean) => Promise<void>;
  loading?: boolean;
  message?: Message;
  displayDefaultCheckbox?: boolean;
  shouldRefetchList?: boolean;
}

const SaveFilterModal: React.FC<IProps> = (props) => {
  const { onSaveClick, loading, toggle, message, displayDefaultCheckbox, shouldRefetchList = false } = props;
  const [name, setName] = useState('');
  const [isDefault, setIsDefault] = useState(0);
  const title = `Zapisz ustawienia filtru`;

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSaveClick(name, isDefault, shouldRefetchList);
  };

  const onIsDefaultChange = () => {
    if (isDefault) {
      setIsDefault(0);
    } else {
      setIsDefault(1);
    }
  };

  const dispalBody = () => {
    return (
      <>
        {loading && <Loader />}
        <Alert message={message} />
        <div className="modal-body-content">
          <FormStrap id="save-filter-form" onSubmit={onSubmit}>
            <Label className="modal-label">Nazwij swój filtr</Label>
            <Input
              type="text"
              name="first_name"
              id="input-first-name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              maxLength={200}
              required
            />
            {displayDefaultCheckbox && (
              <div className="d-flex flex-wrap align-items-center save-filter-checkbox">
                <CustomInput
                  id="save-filter-checkbox"
                  type="checkbox"
                  name="save-filter-checkbox"
                  label={<div className="mt-1">Ustaw jako domyślny</div>}
                  className="mt-3"
                  checked={!!isDefault}
                  onChange={onIsDefaultChange}
                />
              </div>
            )}
          </FormStrap>
        </div>
      </>
    );
  };

  const displayFooter = () => {
    return (
      <div className="modal-actions">
        <Button
          type="submit"
          form="save-filter-form"
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light"
          disabled={loading}
        >
          Zapisz
        </Button>
        <Button type="button" className="cancel waves-effect waves-light" disabled={loading} onClick={toggle}>
          Anuluj
        </Button>
      </div>
    );
  };

  return <BaseModal {...props} title={title} body={dispalBody()} footer={displayFooter()} />;
};

export default SaveFilterModal;
