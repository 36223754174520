import React from 'react';
import { RootState } from 'app/reducer';
import ListingPerPage from 'modules/Layout/component/Listing/PerPage';
import { PartialSearchingProps } from 'modules/Shared/type';
import { useDispatch, useSelector } from 'react-redux';
import { setJudgementClientListParamsAction } from 'modules/JudgementClient/action/JudgmentList';

const JudgementClientPerPage = () => {
  const dispatch = useDispatch();
  const { meta } = useSelector((state: RootState) => state.judgmentClient.judgementClientList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setJudgementClientListParamsAction(payload));

  return <ListingPerPage meta={meta} setParams={setParams} />;
};

export default JudgementClientPerPage;
