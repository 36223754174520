import React from 'react';
import appIconPZP from './app_icon_pzp.png';
import appIconBS from './app_icon_bs.png';

const AppIcon: React.FC = (): JSX.Element => {
  const icons = {
    pzp: appIconPZP,
    bs: appIconBS
  };

  const themeTemplate = process.env.THEME_TEMPLATE as 'pzp' | 'bs';
  const appIcon = icons[themeTemplate];

  return <img src={appIcon} alt="logo Asystent Postępowania" height="94px" />;
};

export default AppIcon;
