import React from 'react';
import Authorize from 'modules/Auth/container/Authorize';
import { JUDGMENT_CATEGORIES_EDIT, JUDGMENT_SAOS_KEYWORDS_INDEX } from 'modules/Judgement/permissions';
import { ROUTE_JUDGEMENT_CATEGORIES } from 'modules/Judgement/routes';
import CardBox from 'modules/Layout/component/CardBox';
import CategoryBasicDataset from 'modules/Judgement/component/Dataset/JudgementElement/Basic';
import JudgementElement from 'modules/Judgement/model/JudgementElement';
import { useSelector } from 'react-redux';
import { RootState } from 'app/reducer';
import { Button } from 'reactstrap';
import './index.scss';

type Props = {
  category: JudgementElement;
  setDisplayDeleteModal: React.Dispatch<React.SetStateAction<JudgementElement>>;
  setDisplayEditModal: React.Dispatch<React.SetStateAction<JudgementElement>>;
};

const CategoryBaseDetails: React.FC<Props> = ({ category, setDisplayDeleteModal, setDisplayEditModal }) => {
  const { permissions } = useSelector((state: RootState) => state.auth.user);

  return (
    <CardBox
      heading="Kategorie"
      className="category-basic-details"
      paddingBottom="0"
      redirectReturnBtn={ROUTE_JUDGEMENT_CATEGORIES}
      actions={
        <>
          <Authorize permissions={[JUDGMENT_CATEGORIES_EDIT]}>
            <Button color="link" onClick={() => setDisplayEditModal(category)} className="link-with-border p-0">
              EDYTUJ
            </Button>
          </Authorize>
          <Authorize permissions={[JUDGMENT_CATEGORIES_EDIT]}>
            <Button color="link" className="link-with-border p-0" onClick={() => setDisplayDeleteModal(category)}>
              USUŃ KATEGORIĘ
            </Button>
          </Authorize>
        </>
      }
    >
      {permissions?.includes(JUDGMENT_SAOS_KEYWORDS_INDEX) && <CategoryBasicDataset element={category} />}
    </CardBox>
  );
};

export default CategoryBaseDetails;
