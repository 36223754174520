import React from 'react';
import InformationList from 'modules/Layout/component/InformationList';
import Clause from 'modules/Clause/model/Clause';
import { formatDate, ifContainsDateFormat } from 'modules/Shared/helper/utils';

export type Props = {
  element?: Clause;
};

const ClauseBasicDataset: React.FC<Props> = ({ element }) => {
  const fields = [
    {
      label: 'ID',
      value: element?.id
    },
    {
      label: 'Nazwa klauzuli:',
      value: element?.name || 'brak danych'
    },
    {
      label: 'Słowa kluczowe',
      value: element?.keywords_names || 'brak danych'
    },
    {
      label: 'Kategoria',
      value: element?.category?.name || 'brak danych'
    },
    {
      label: 'Data utworzenia w systemie:',
      value: ifContainsDateFormat(element?.created_at, formatDate) || 'brak danych'
    },
    {
      label: 'Data ostatniej edycji w systemie:',
      value: ifContainsDateFormat(element?.updated_at, formatDate) || 'brak danych'
    }
  ];

  return <InformationList fields={fields} />;
};

export default ClauseBasicDataset;
