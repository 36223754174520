import axios, { AxiosPromise } from 'axios';
import { getAPIUrl } from 'modules/Shared/helper/api';
import { PaginationMeta, SearchParams } from 'modules/Shared/type';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';
import { JudgementFieldValues, JudgmentConnectedClausesValues } from 'modules/Judgement/type';
import { JudgementEntity } from 'modules/Judgement/model/Judgement';

// w związku z taskiem PZP2UE-1073 kategorie orzeczeń i klauzul są wspóle, ważne przy zmianie API_JUDGEMENT_CATEGORIES || API_JUDGEMENT_CATEGORY
export const API_JUDGEMENT_CATEGORIES = '/api/judgments/categories';
export const API_JUDGEMENT_CATEGORY = '/api/judgments/categories/:id';
// w związku z taskiem PZP2UE-1177 słowa kluczowe są wspólne i mają zmienione endpointy
// export const API_JUDGEMENT_KEYWORDS = '/api/judgments/keywords';
export const API_JUDGEMENT_KEYWORDS = '/api/judgments/keywords/all';
export const API_JUDGEMENT_KEYWORDS_WITH_CATEGORIES = '/api/judgments/keywords/with_categories';
export const API_JUDGEMENT_KEYWORD = '/api/judgments/keywords/:id';
// export const API_JUDGEMENT_SAOS_KEYWORDS = '/api/judgments/categories/:category_id/saos_keywords';
// export const API_JUDGEMENT_SAOS_KEYWORD = '/api/judgments/categories/:category_id/saos_keywords/:id';
export const API_JUDGEMENT_SAOS_KEYWORD_ALL = '/api/judgments/saos_keywords/all';
export const API_JUDGEMENT_SAOS_KEYWORDS = '/api/judgments/categories/:category_id/keywords';
export const API_JUDGEMENT_SAOS_KEYWORD = '/api/judgments/categories/:category_id/keywords/:id';
export const API_JUDGEMENTS = '/api/judgments';
export const API_JUDGEMENT = '/api/judgments/:id';
export const API_JUDGEMENT_BASIC = '/api/judgments/:id/basic';
export const API_JUDGEMENT_CONTENT = '/api/judgments/:id/content';
export const API_JUDGEMENT_KEYWORDS_INCLUDED = '/api/judgments/:judgement_id/keywords';
export const API_JUDGEMENT_KEYWORD_INCLUDED = '/api/judgments/:judgement_id/keywords/:id';
export const API_JUDGEMENT_CONNECTED_CLAUSES = '/api/judgments/:judgment_id/clauses';
export const API_JUDGEMENT_CONNECTED_CLAUSE = '/api/judgments/:judgment_id/clauses/:id';

export type FetchJudgementCategories = {
  data: JudgementElementEntity[];
  meta: PaginationMeta;
  links: any;
};

export const fetchJudgmentsCategories = (
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<FetchJudgementCategories> =>
  axios.get(getAPIUrl(API_JUDGEMENT_CATEGORIES, null, params), { cancelToken });

export type FetchJudgementCategory = {
  data: JudgementElementEntity;
};

export const fetchJudgmentsCategory = (id: string | number, cancelToken?: any): AxiosPromise<FetchJudgementCategory> =>
  axios.get(getAPIUrl(API_JUDGEMENT_CATEGORY, { id }), { cancelToken });

export const deleteJudgmentsCategory = (id: string | number, cancelToken?: any): AxiosPromise =>
  axios.delete(getAPIUrl(API_JUDGEMENT_CATEGORY, { id }), { cancelToken });

export const createJudgmentsCategory = (
  data: { name: string },
  cancelToken?: any
): AxiosPromise<FetchJudgementCategory> => axios.post(getAPIUrl(API_JUDGEMENT_CATEGORIES), data, { cancelToken });

export const updateJudgmentsCategory = (
  id: number,
  data: { name: string },
  cancelToken?: any
): AxiosPromise<FetchJudgementCategory> => axios.put(getAPIUrl(API_JUDGEMENT_CATEGORY, { id }), data, { cancelToken });

export const fetchJudgmentKeywords = (
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<FetchJudgementCategories> =>
  axios.get(getAPIUrl(API_JUDGEMENT_KEYWORDS, null, params), { cancelToken });

export const fetchJudgmentKeywordsWithCategories = (
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<FetchJudgementCategories> =>
  axios.get(getAPIUrl(API_JUDGEMENT_KEYWORDS_WITH_CATEGORIES, null, params), { cancelToken });

export const fetchJudgmentSAOSKeywordsAll = (
  params: SearchParams = {},
  cancelToken?: any
): AxiosPromise<FetchJudgementCategories> =>
  axios.get(getAPIUrl(API_JUDGEMENT_SAOS_KEYWORD_ALL, null, params), { cancelToken });

export const deleteJudgmentKeyword = (id: string | number, cancelToken?: any): AxiosPromise =>
  axios.delete(getAPIUrl(API_JUDGEMENT_KEYWORD, { id }), { cancelToken });

export const createJudgmentKeyword = (
  data: { name: string },
  cancelToken?: any
): AxiosPromise<FetchJudgementCategory> => axios.post(getAPIUrl(API_JUDGEMENT_KEYWORDS), data, { cancelToken });

export const updateJudgmentKeyword = (
  id: number,
  data: { name: string },
  cancelToken?: any
): AxiosPromise<FetchJudgementCategory> => axios.put(getAPIUrl(API_JUDGEMENT_KEYWORD, { id }), data, { cancelToken });

export const fetchJudgmentSAOSKeywords = (
  category_id: number,
  params: SearchParams = {}
): AxiosPromise<FetchJudgementCategories> => axios.get(getAPIUrl(API_JUDGEMENT_SAOS_KEYWORDS, { category_id }, params));

export const deleteJudgmentSAOSKeyword = (category_id: number, id: string | number, cancelToken?: any): AxiosPromise =>
  axios.delete(getAPIUrl(API_JUDGEMENT_SAOS_KEYWORD, { category_id, id }), { cancelToken });

export const createJudgmentSAOSKeyword = (
  category_id: number,
  data: { name: string },
  cancelToken?: any
): AxiosPromise<FetchJudgementCategory> =>
  axios.post(getAPIUrl(API_JUDGEMENT_SAOS_KEYWORDS, { category_id }), data, { cancelToken });

export const updateJudgmentSAOSKeyword = (
  category_id: number,
  id: number,
  data: { name: string },
  cancelToken?: any
): AxiosPromise<FetchJudgementCategory> =>
  axios.put(getAPIUrl(API_JUDGEMENT_SAOS_KEYWORD, { category_id, id }), data, { cancelToken });

export type FetchJudgement = {
  data: JudgementEntity;
};

export const createJudgment = (data: JudgementFieldValues, cancelToken?: any): AxiosPromise<FetchJudgement> =>
  axios.post(getAPIUrl(API_JUDGEMENTS), data, { cancelToken });

export const updateJudgmentBasic = (
  id: string | number,
  data: JudgementFieldValues,
  cancelToken?: any
): AxiosPromise<FetchJudgement> => axios.put(getAPIUrl(API_JUDGEMENT_BASIC, { id }), data, { cancelToken });

export const updateJudgmentContent = (
  id: string | number,
  data: JudgementFieldValues,
  cancelToken?: any
): AxiosPromise<FetchJudgement> => axios.put(getAPIUrl(API_JUDGEMENT_CONTENT, { id }), data, { cancelToken });

export const fetchJudgment = (id: string | number, cancelToken?: any): AxiosPromise<FetchJudgement> =>
  axios.get(getAPIUrl(API_JUDGEMENT, { id }), { cancelToken });

export const deleteJudgment = (id: string | number, cancelToken?: any): AxiosPromise =>
  axios.delete(getAPIUrl(API_JUDGEMENT, { id }), { cancelToken });

export type FetchJudgements = {
  data: JudgementEntity[];
  meta: PaginationMeta;
  links: any;
};

export const fetchJudgments = (params: SearchParams = {}): AxiosPromise<FetchJudgements> =>
  axios.get(getAPIUrl(API_JUDGEMENTS, null, params));

export const fetchJudgmentKeywordsIncluded = (
  judgement_id: string | number,
  cancelToken?: any
): AxiosPromise<{ data: JudgementElementEntity[] }> =>
  axios.get(getAPIUrl(API_JUDGEMENT_KEYWORDS_INCLUDED, { judgement_id }), { cancelToken });

export const addJudgmentKeywordsIncluded = (
  judgement_id: string | number,
  data: { keyword_id: number; content?: string },
  cancelToken?: any
): AxiosPromise<{ data: JudgementElementEntity }> =>
  axios.post(getAPIUrl(API_JUDGEMENT_KEYWORDS_INCLUDED, { judgement_id }), data, { cancelToken });

export const deleteJudgmentKeywordsIncluded = (
  judgement_id: string | number,
  id: string | number,
  cancelToken?: any
): AxiosPromise<{ data: JudgementElementEntity }> =>
  axios.delete(getAPIUrl(API_JUDGEMENT_KEYWORD_INCLUDED, { judgement_id, id }), { cancelToken });

export const fetchJudgmentConnectedClauses = (
  judgment_id: string | number,
  cancelToken?: any
): AxiosPromise<{ data: JudgmentConnectedClausesValues[] }> =>
  axios.get(getAPIUrl(API_JUDGEMENT_CONNECTED_CLAUSES, { judgment_id }), { cancelToken });

export const addJudgementConnectedClause = (
  judgment_id: string | number,
  data: { clause_id: number; content: string },
  cancelToken?: any
): AxiosPromise<{ data: JudgmentConnectedClausesValues }> =>
  axios.post(getAPIUrl(API_JUDGEMENT_CONNECTED_CLAUSES, { judgment_id }), data, { cancelToken });

export const deleteJudgementConnectedClause = (
  judgment_id: string | number,
  id: string | number,
  cancelToken?: any
): AxiosPromise => axios.delete(getAPIUrl(API_JUDGEMENT_CONNECTED_CLAUSE, { judgment_id, id }), { cancelToken });
