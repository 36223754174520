import React, { useEffect, useRef, useState } from 'react';
import { CustomInput, FormFeedback, FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import {
  AGREEMENT_TYPE_OUTSIDE_ORDER,
  AGREEMENT_TYPE_SUPPLEMENT,
  AgreementCheckboxes,
  AgreementStepProps
} from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_EMPLOYMENT_RELATIONSHIP } from 'modules/Agreements/step';
import StepImportDataButton from 'modules/Agreements/components/StepImportDataButton';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import { getError, hasError } from 'modules/Shared/helper/validation';
import Part from 'modules/Agreements/model/Part';
import Accordion from 'modules/Layout/component/Accordion';
import { IMPORT_ORDERS_ACCESS } from '../../../../../Auth/container/Guard/permissions';
import Authorize from '../../../../../Auth/container/Authorize';

export interface AgreementStepEmploymentRelationshipValues {
  act_work_possibility: boolean;
  same_employment_relationship_for_parts: boolean;
  employment_relationship: {
    part_id: number | null;
    contractor_obliges_checked: boolean;
    contractor_obliges_content: string | null;
    activities_type_checked: boolean;
    activities_type_content: string | null;
    verification_possibility: boolean;
    worker_statement_checked: boolean;
    worker_statement_content: string | null;
    executor_statement_checked: boolean;
    executor_statement_content: string | null;
    agreement_copy_checked: boolean;
    agreement_copy_content: string | null;
    other_documents_checked: boolean;
    other_documents_content: string | null;
    contains_data_checked: boolean;
    contains_data_content: string | null;
    personal_verification_checked: boolean;
    personal_verification_content: string | null;
    state_verification_checked: boolean;
    state_verification_content: string | null;
    executor_undertake_checked: boolean;
    executor_undertake_content: string | null;
  }[];
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

export interface AgreementStepEmploymentRelationshipImportValues extends AgreementStepEmploymentRelationshipValues {}

const CONTRACTOR_OBLIGES_CONTENT =
  'Zamawiający zobowiązuje wykonawcę oraz podwykonawców, aby osoby wykonujące wskazane przez zmawiającego czynności w zakresie realizacji zamówienia były zatrudnione na podstawie stosunku pracy.';
const ACTIVITIES_TYPE_CONTENT =
  'Rodzaj czynności niezbędnych do realizacji zamówienia, których dotyczą wymagania zatrudnienia na podstawie stosunku pracy przez wykonawcę lub podwykonawcę osób wykonujących czynności w trakcie realizacji zamówienia: ........';
const WORKER_STATEMENT_CONTENT = 'oświadczenia zatrudnionego pracownika.';
const EXECUTOR_STATEMENT_CONTENT =
  'oświadczenia wykonawcy oraz podwykonawcy o zatrudnieniu pracownika na podstawie umowy o pracę.';
const AGREEMENT_COPY_CONTENT = 'poświadczonej za zgodność z oryginałem kopii umowy o pracę zatrudnionego pracownika.';
const OTHER_DOCUMENTS_CONTENT = 'innych dokumentów.';
const CONTAINS_DATA_CONTENT =
  'Dokumenty żądane przez zamawiającego zawierają informacje, w tym dane osobowe, niezbędne do weryfikacji zatrudnienia na podstawie umowy o pracę, w szczególności imię i nazwisko zatrudnionego pracownika, datę zawarcia umowy o pracę, rodzaj umowy o pracę i zakres obowiązków pracownika.';
const PERSONAL_VERIFICATION_CONTENT =
  'Zamawiający zastrzega możliwość osobistej weryfikacji pracowników na miejscu przez osoby wyznaczone przez zamawiającego.';
const STATE_VERIFICATION_CONTENT =
  'Zamawiający zastrzega możliwość zwrócenia się o przeprowadzenie kontroli przez Państwową Inspekcję Pracy.';
const EXECUTOR_UNDERTAKE_CONTENT =
  'Wykonawca zobowiązuje się realizować zamówienie pracownikami zatrudnionymi na podstawie umowy o pracę w zakresie czynności opisanych przez zamawiającego. Brak realizacji tego obowiązku oznaczać będzie wymierzenie przez zamawiającego kary umownej.';

const initContentValues = {
  contractor_obliges_content: CONTRACTOR_OBLIGES_CONTENT,
  activities_type_content: ACTIVITIES_TYPE_CONTENT,
  worker_statement_content: WORKER_STATEMENT_CONTENT,
  executor_statement_content: EXECUTOR_STATEMENT_CONTENT,
  agreement_copy_content: AGREEMENT_COPY_CONTENT,
  other_documents_content: OTHER_DOCUMENTS_CONTENT,
  contains_data_content: CONTAINS_DATA_CONTENT,
  personal_verification_content: PERSONAL_VERIFICATION_CONTENT,
  state_verification_content: STATE_VERIFICATION_CONTENT,
  executor_undertake_content: EXECUTOR_UNDERTAKE_CONTENT
};

const mapState = (
  step: AgreementStepEmploymentRelationshipValues,
  defaultValueStatus: boolean
): AgreementStepEmploymentRelationshipValues => {
  const { employment_relationship, checkboxes, ...rest } = step;

  return {
    ...rest,
    employment_relationship: employment_relationship.map((part) => {
      const {
        contractor_obliges_content,
        activities_type_checked,
        activities_type_content,
        worker_statement_content,
        executor_statement_content,
        agreement_copy_content,
        other_documents_content,
        contains_data_content,
        personal_verification_content,
        state_verification_content,
        executor_undertake_content
      } = part;

      return {
        ...part,
        contractor_obliges_content: contractor_obliges_content || CONTRACTOR_OBLIGES_CONTENT,
        activities_type_checked: Boolean(activities_type_checked || defaultValueStatus),
        activities_type_content: activities_type_content || ACTIVITIES_TYPE_CONTENT,
        worker_statement_content: worker_statement_content || WORKER_STATEMENT_CONTENT,
        executor_statement_content: executor_statement_content || EXECUTOR_STATEMENT_CONTENT,
        agreement_copy_content: agreement_copy_content || AGREEMENT_COPY_CONTENT,
        other_documents_content: other_documents_content || OTHER_DOCUMENTS_CONTENT,
        contains_data_content: contains_data_content || CONTAINS_DATA_CONTENT,
        personal_verification_content: personal_verification_content || PERSONAL_VERIFICATION_CONTENT,
        state_verification_content: state_verification_content || STATE_VERIFICATION_CONTENT,
        executor_undertake_content: executor_undertake_content || EXECUTOR_UNDERTAKE_CONTENT
      };
    }),
    checkboxes: checkboxes || {}
  };
};

const AgreementStepEmploymentRelationship = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, stepsImportData, onSubmit, onChange, errors } = props;
  const agreementFromPzp = agreement.type !== AGREEMENT_TYPE_OUTSIDE_ORDER;

  const initState = useRef<AgreementStepEmploymentRelationshipValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_EMPLOYMENT_RELATIONSHIP), agreementFromPzp)
  );
  const [stepValues, setStepValues] = useState<AgreementStepEmploymentRelationshipValues>({ ...initState.current });
  const stepDataImportValues: AgreementStepEmploymentRelationshipImportValues = getStepValues(
    stepsImportData,
    AGREEMENT_STEP_EMPLOYMENT_RELATIONSHIP
  );
  const { parts_ids, few_parts } = agreement;
  const { employment_relationship, same_employment_relationship_for_parts, checkboxes } = stepValues;

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_EMPLOYMENT_RELATIONSHIP), agreementFromPzp);
    setStepValues({ ...initState.current });
  }, [steps]);

  const isMultiple = few_parts && !same_employment_relationship_for_parts;

  const samePartsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setStepValues((values) =>
      mapState(
        {
          ...values,
          same_employment_relationship_for_parts: checked,
          employment_relationship: checked
            ? [
                {
                  ...employment_relationship[0],
                  part_id: null
                }
              ]
            : parts_ids.map((part) => ({
                ...employment_relationship[0],
                part_id: part.id
              }))
        },
        agreementFromPzp
      )
    );
  };

  const onStepDataImportClick = () => {
    if (stepDataImportValues.same_employment_relationship_for_parts !== undefined) {
      samePartsChange({ target: { checked: stepDataImportValues.same_employment_relationship_for_parts } } as any);
    }

    setStepValues((values) => {
      return {
        ...values,
        employment_relationship: stepDataImportValues.employment_relationship
          ? values.employment_relationship.map((employmentRelationship, index) => ({
              ...employmentRelationship,
              ...stepDataImportValues.employment_relationship[index]
            }))
          : values.employment_relationship,
        checkboxes: stepDataImportValues.checkboxes ? stepDataImportValues.checkboxes : checkboxes
      };
    });
  };

  const { renderSwitch, renderPartCheckbox, renderPartEditableCheckbox, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState: (values) => mapState(values, agreementFromPzp),
    errors,
    partSlug: 'employment_relationship',
    initContentValues
  });

  const renderPart = (partObj: Part, index: number) => {
    const part = isMultiple ? employment_relationship[index] : employment_relationship[0];

    const body = (
      <FormGroup>
        {[
          renderPartEditableCheckbox(
            'contractor_obliges',
            index,
            part,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          renderPartEditableCheckbox(
            'activities_type',
            index,
            part,
            agreementFromPzp || agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          renderPartCheckbox(
            'verification_possibility',
            'W celu weryfikacji zatrudniania, przez wykonawcę oraz podwykonawcę, na podstawie umowy o pracę, osób wykonujących wskazane przez zamawiającego czynności w zakresie realizacji zamówienia, Umowa przewiduje możliwość żądania przez zamawiającego:',
            index,
            part,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          part.verification_possibility && (
            <div className="pl-3">
              {[
                renderPartCheckbox(
                  'worker_statement_checked',
                  part.worker_statement_content,
                  index,
                  part,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderPartCheckbox(
                  'executor_statement_checked',
                  part.executor_statement_content,
                  index,
                  part,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderPartCheckbox(
                  'agreement_copy_checked',
                  part.agreement_copy_content,
                  index,
                  part,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                ),
                renderPartEditableCheckbox(
                  'other_documents',
                  index,
                  part,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
                  agreement.type === AGREEMENT_TYPE_SUPPLEMENT
                )
              ]}
            </div>
          ),
          <hr />,
          renderPartCheckbox(
            'contains_data_checked',
            part.contains_data_content,
            index,
            part,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          renderPartCheckbox(
            'personal_verification_checked',
            part.personal_verification_content,
            index,
            part,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          renderPartCheckbox(
            'state_verification_checked',
            part.state_verification_content,
            index,
            part,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          renderPartCheckbox(
            'executor_undertake_checked',
            part.executor_undertake_content,
            index,
            part,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          ),
          renderCustomCheckboxes(
            'general',
            partObj?.id,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
            agreement.type === AGREEMENT_TYPE_SUPPLEMENT
          )
        ]}
      </FormGroup>
    );

    if (isMultiple) {
      return (
        <Accordion
          key={`renderPartAccordion-${partObj.id}`}
          isInvalid={hasError(errors, `employment_relationship.${index}`)}
          accordionContentStyle={{ padding: '1rem' }}
          entity={{
            title: partObj.getName(index),
            content: body
          }}
        />
      );
    }

    return body;
  };

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <Authorize permissions={[IMPORT_ORDERS_ACCESS]}>
        {agreement.type !== AGREEMENT_TYPE_SUPPLEMENT && (
          <StepImportDataButton onSubmit={onStepDataImportClick} stepDataImportValues={stepDataImportValues} />
        )}
      </Authorize>
      {renderSwitch(
        'act_work_possibility',
        'Czy wykonanie czynności w zakresie realizacji zamówienia polega na wykonywaniu pracy w sposób określony w ustawie kodeks pracy?',
        agreement.type === AGREEMENT_TYPE_SUPPLEMENT
      )}
      {stepValues.act_work_possibility && [
        few_parts && (
          <FormGroup>
            <CustomInput
              id="same_employment_relationship_for_parts"
              name="same_employment_relationship_for_parts"
              type="switch"
              checked={same_employment_relationship_for_parts}
              disabled={agreement.type === AGREEMENT_TYPE_SUPPLEMENT}
              onChange={samePartsChange}
              invalid={hasError(errors, 'same_employment_relationship_for_parts')}
              label="Czy wymagania są wspólne dla wszystkich zadań?"
            />
            {hasError(errors, 'same_employment_relationship_for_parts') && (
              <FormFeedback className="d-block">
                {getError(errors, 'same_employment_relationship_for_parts')}
              </FormFeedback>
            )}
          </FormGroup>
        ),
        (isMultiple ? parts_ids : [null]).map(renderPart),
        isMultiple && (
          <>
            <p className="h4">Ponadto dla każdego z zadań:</p>
            {renderCustomCheckboxes(
              null,
              null,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT,
              agreement.type === AGREEMENT_TYPE_SUPPLEMENT
            )}
          </>
        )
      ]}
    </AgreementFormWrapper>
  );
};

export default AgreementStepEmploymentRelationship;
