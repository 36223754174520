import { RootState } from 'app/reducer';
import { loginAction } from 'modules/Auth/action';
import Form from 'modules/Auth/component/Form/Login';
import { Credentials } from 'modules/Auth/type';
import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { managePageAction } from 'modules/Layout/action';
import { ROUTE_REGISTER } from 'modules/Auth/routes';
import './style.scss';
import Logo from 'modules/Auth/component/Logo';
import PublicWrapper from 'modules/Layout/component/Wrapper/Public';
import { ROUTE_BASE } from 'modules/Layout/routes';
import bsBanner from './bs-banner.jpg';

const LoginView = () => {
  const dispatch = useDispatch();
  const { busy, message, errors } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(managePageAction({ title: 'Log in' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = (credentials: Credentials) => dispatch(loginAction(credentials));

  return (
    <PublicWrapper hideHeader hideFooter>
      <div className="login-view">
        <div className="container">
          <div className="row justify-content-center">
            <div className="card-wrapper">
              <div className="card mb-0 position-relative">
                {process.env.THEME_TEMPLATE === 'bs' && (
                  <img
                    style={{ borderTopLeftRadius: '0.375rem', borderTopRightRadius: '0.375rem', width: '100%' }}
                    src={bsBanner}
                    alt="bs banner"
                  />
                )}
                <div className="card-body">
                  <div className="text-center">
                    <Logo route={ROUTE_BASE} height={67} />
                  </div>
                  {busy && <Loader />}
                  <div className="login-form">
                    <Alert message={message} />
                    <Form busy={busy} errors={errors} submit={login} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="register-wrapper">
              <div className="register-title font-poppins">Jesteś tu pierwszy raz?</div>
              <a href={ROUTE_REGISTER} className="w-100 btn btn-dark register-button">
                Załóż darmowe konto
              </a>
            </div>
          </div>
        </div>
      </div>
    </PublicWrapper>
  );
};

export default LoginView;
