import React, { useState } from 'react';
import { Button, Tooltip as TooltipComponent, ButtonProps, UncontrolledTooltipProps } from 'reactstrap';

interface Props extends ButtonProps {
  targetId: string;
  btnContent: string;
  tooltipContent: string;
  btnContentClassName?: string;
  wrapperClassName?: string;
  tooltipPlacement?: UncontrolledTooltipProps['placement'];
}

const selectableDivObj = { width: '100%', height: '100%', top: 0 };

const selectableDiv = (display?: boolean) =>
  display
    ? selectableDivObj
    : {
        ...selectableDivObj,
        display: 'none'
      };

const TooltipWithBtn: React.FC<Props> = ({
  targetId,
  type = 'submit',
  color = 'primary',
  className = 'waves-effect waves-light ',
  onClick,
  disabled,
  btnContent,
  title,
  tooltipPlacement = 'bottom-start',
  tooltipContent,
  btnContentClassName,
  wrapperClassName = ''
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div className={`position-relative ${wrapperClassName}`}>
      <Button
        type={type}
        color={color}
        className={`${className} pzpeu-btn-disabled`}
        title={title}
        onClick={onClick}
        disabled={disabled}
      >
        <span className={btnContentClassName}>{btnContent}</span>
      </Button>
      <div id={targetId} className="position-absolute cursor-pointer" style={selectableDiv(disabled)} />
      <TooltipComponent placement={tooltipPlacement} isOpen={tooltipOpen} target={targetId} toggle={toggle}>
        {tooltipContent}
      </TooltipComponent>
    </div>
  );
};

export default TooltipWithBtn;
