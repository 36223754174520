import React, { useEffect, useRef, useState } from 'react';
import { CustomInput, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { isEqual } from 'lodash';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_REWARD } from 'modules/Agreements/step';
import { getError, hasError } from 'modules/Shared/helper/validation';

export interface AgreementStepRewardValues {
  of_reward_type: number | null;
  of_netto_value: string | null;
  of_brutto_value: string | null;
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

const mapState = (step: AgreementStepRewardValues): AgreementStepRewardValues => {
  const { checkboxes, ...rest } = step;

  return {
    ...rest,
    checkboxes: checkboxes || {}
  };
};

const AgreementStepReward = (props: AgreementStepProps): JSX.Element => {
  const { steps, onSubmit, onChange, errors } = props;
  const initState = useRef<AgreementStepRewardValues>(mapState(getStepValues(steps, AGREEMENT_STEP_REWARD)));
  const [stepValues, setStepValues] = useState<AgreementStepRewardValues>({ ...initState.current });

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_REWARD));
    setStepValues({ ...initState.current });
  }, [steps]);

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <FormGroup>
        <Label key="reward_type_option_label">Jaki rodzaj wynagrodzenia przewiduje zamawiający?</Label>
        <CustomInput
          key="reward_type_option_label_1"
          id="reward_type_option_label_1"
          label="ryczałtowe"
          value={1}
          type="radio"
          checked={stepValues.of_reward_type === 1}
          onChange={() => {
            stepValues.of_reward_type = 1;
            setStepValues((values) => mapState(values));
          }}
          invalid={hasError(errors, 'of_reward_type')}
        />
        <CustomInput
          key="reward_type_option_label_2"
          id="reward_type_option_label_2"
          label="kosztorysowe"
          value={2}
          type="radio"
          checked={stepValues.of_reward_type === 2}
          onChange={() => {
            stepValues.of_reward_type = 2;
            setStepValues((values) => mapState(values));
          }}
          invalid={hasError(errors, 'of_reward_type')}
        />
        {hasError(errors, 'of_reward_type') && (
          <FormFeedback className="d-block">{getError(errors, 'of_reward_type')}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="of_netto_value">Wynagrodzenie netto</Label>
        <Input
          id="of_netto_value"
          name="of_netto_value"
          value={stepValues.of_netto_value || ''}
          type="number"
          min={0}
          step=".01"
          onChange={(event) => {
            setStepValues((values) => ({ ...values, of_netto_value: event.target.value }));
          }}
          invalid={hasError(errors, 'of_netto_value')}
        />
      </FormGroup>
      {hasError(errors, 'of_netto_value') && (
        <FormFeedback className="d-block">{getError(errors, 'of_netto_value')}</FormFeedback>
      )}
      <FormGroup>
        <Label for="of_brutto_value">Wynagrodzenie brutto</Label>
        <Input
          id="of_brutto_value"
          name="of_brutto_value"
          value={stepValues.of_brutto_value || ''}
          type="number"
          min={0}
          step=".01"
          onChange={(event) => {
            setStepValues((values) => ({ ...values, of_brutto_value: event.target.value }));
          }}
          invalid={hasError(errors, 'of_brutto_value')}
        />
      </FormGroup>
      {hasError(errors, 'of_brutto_value') && (
        <FormFeedback className="d-block">{getError(errors, 'of_brutto_value')}</FormFeedback>
      )}
    </AgreementFormWrapper>
  );
};

export default AgreementStepReward;
