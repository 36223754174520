import React from 'react';
import { ExecutorEntity, executorViesExistOptions } from 'modules/Executor/model/Executor';
import InformationList from 'modules/Layout/component/InformationList';
import { formatDate } from 'modules/Shared/helper/utils';
// import { ROLE_ADMIN } from 'modules/Executor/model/ExecutorRole';

export interface Props {
  executor: ExecutorEntity;
}

const ExecutorDataset: React.FC<Props> = ({ executor }): JSX.Element => {
  const fields = [
    {
      label: 'Nazwa',
      value: executor?.name
    },
    {
      label: 'Imię',
      value: executor?.first_name
    },
    {
      label: 'Nazwisko',
      value: executor?.last_name
    },
    {
      label: 'Email',
      value: executor?.email
    },
    {
      label: 'Telefon',
      value: executor?.phone
    },
    {
      label: 'NIP',
      value: executor?.nip
    },
    {
      label: 'Nazwa ZUS',
      value: executor?.ZUS_name
    },
    {
      label: 'E-mail ZUS',
      value: executor?.ZUS_email
    },
    {
      label: 'Nazwa US',
      value: executor?.US_name
    },
    {
      label: 'E-mail US',
      value: executor?.US_email
    },
    {
      label: 'Status wpisu w systemie VIES',
      value: executor?.vies_exist
        ? executorViesExistOptions.find((option) => option.value === executor?.vies_exist).label
        : 'Nie zweryfikowane'
    },
    {
      label: 'Status wpisu na białej liście podatników VAT',
      value: executor?.white_list_vat_exist
        ? executorViesExistOptions.find((option) => option.value === executor?.white_list_vat_exist).label
        : 'Nie zweryfikowane'
    },
    {
      label: 'Miasto',
      value: executor?.city
    },
    {
      label: 'Województwo',
      value: executor?.voivodeship
    },
    {
      label: 'Ulica',
      value: executor?.street
    },
    {
      label: 'Kod pocztowy',
      value: executor?.postal_code
    },
    {
      label: 'Miejscowość poczty',
      value: executor?.post_office
    },
    {
      label: 'Data wpisu do KRS lub CEIDG',
      value: !!executor?.business_start_date && formatDate(executor.business_start_date)
    },
    {
      label: 'KRS',
      value: executor?.krs
    },
    {
      label: 'Sumaryczna wartość kar',
      value: executor?.penalties_total_value ? `${executor.penalties_total_value.toFixed(2).replace('.', ',')} PLN` : ''
    }
  ];

  return <InformationList fields={fields} />;
};

export default ExecutorDataset;
