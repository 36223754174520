import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { managePageAction } from 'modules/Layout/action';
import { useDispatch, useSelector } from 'react-redux';
import DetailsComponent from 'modules/Module/component/Details';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import Alert from 'modules/Layout/component/Alert';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { fetchModuleAccessHistory } from 'modules/Module/repository';
import { ModuleAccessHistoryEntity } from 'modules/Module/model/ModuleAccessHistory';
import { RootState } from 'app/reducer';
import { ROLE_ADMIN } from 'modules/User/model/UserRole';
import breadcrumbRouteModuleAccessHistory from 'modules/Module/breadcrumbs';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_USER } from 'modules/User/routes';
import { ROUTE_PROFILE } from 'modules/Profile/routes';
import CardBox from 'modules/Layout/component/CardBox';

const ModuleAccessHistoryDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [accessHistory, setAccessHistory] = useState<ModuleAccessHistoryEntity[]>([]);
  const { user } = useSelector((state: RootState) => state.auth);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setFetching(true);
      const {
        data,
        cancelled,
        message: messageResponse
      } = await fetchRequest(fetchModuleAccessHistory, id, cancelToken);

      if (cancelled) return;
      if (data) setAccessHistory(data.data);
      if (messageResponse) setMessage(messageResponse);
      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const roleAdmin = user?.role?.slug === ROLE_ADMIN;
  useEffect(() => {
    if (accessHistory.length && user) {
      const username = accessHistory[0].user_name;
      const title = roleAdmin ? `Moduły - historia dostępów dla ${username}` : 'Moduły - historia dostępów';

      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteModuleAccessHistory(id, roleAdmin, username)
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessHistory, user]);

  const redirectUrl = roleAdmin ? getPathUrl(ROUTE_USER, { id }) : ROUTE_PROFILE;

  if (fetching) return <Loader />;

  if (!fetching && !accessHistory.length && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <CardBox>{message && <Alert message={message} />}</CardBox>
        </div>
      </div>
    );

  if (!fetching && accessHistory.length) {
    return (
      <div className="d-flex flex-column justify-content-center">
        <DetailsComponent accessHistory={accessHistory} redirectUrl={redirectUrl} />
      </div>
    );
  }

  return null;
};

export default ModuleAccessHistoryDetails;
