import { getError, hasError } from 'modules/Shared/helper/validation';
import React, { useEffect, useState } from 'react';
import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { Props } from 'modules/ForeignProceedings/component/Fieldset/Main';
import _uniqueId from 'lodash/uniqueId';
import { suffixLabel } from '../../../../../Layout/helper/misc';
import Datetime from '../../../../../Layout/component/Input/Datetime';

const ForeignProceedingsFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, orderObjectWysiwyg, errors, setValues, placeholder, required } = props;
  const [needAddLink, setNeedAddLink] = useState<boolean>();
  const [isValidLink, setIsValidLink] = useState<boolean>();
  const [linkToAdd, setLinkToAdd] = useState<string>();
  const [linkNameToAdd, setLinkNameToAdd] = useState<string>();

  const { deadline_date, published_date, links } = values;

  useEffect(() => {
    if (published_date && !/\d{4}-\d{2}-\d{2} \d{2}:\d{2}/.test(published_date)) {
      const updatedDate = `${published_date} 00:00`;
      setValues({ ...values, published_date: updatedDate });
    }
  }, [published_date, setValues, values]);

  const removeSeconds = (val: string) => {
    // Remove seconds if they exist
    return val.replace(/:\d{2}$/, '');
  };

  const handleDeadlineDateChange = (val: string) => {
    const updatedDate = removeSeconds(val);

    // Ensure time is added if missing (default to '23:59' for deadlines)
    const hasTime = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}/.test(updatedDate);
    const finalDate = hasTime ? updatedDate : `${updatedDate} 00:00`;

    setValues({ ...values, deadline_date: finalDate });
  };

  const handlePublishedDateChange = (val: string) => {
    const updatedDate = removeSeconds(val);

    // Ensure time is added if missing (default to '08:00' for publishing)
    const hasTime = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}/.test(updatedDate);
    const finalDate = hasTime ? updatedDate : `${updatedDate} 08:00`;

    setValues({ ...values, published_date: finalDate });
  };

  const isValidURL = (url: string) => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z0-9](?!-)[a-z0-9-]{0,61}[a-z0-9])?\\.)+[a-z]{2,6}|' + // domain name
        'localhost|' + // localhost
        '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|' + // IP address
        '\\[?[a-f0-9]*:[a-f0-9:]+\\]?)' + // IPv6
        '(\\:\\d+)?(\\/[-a-z0-9%_.~+]*)*' + // port and path
        '(\\?[;&a-z0-9%_.~+=-]*)?' + // query string
        '(\\#[-a-z0-9_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(url);
  };

  const handleRemoveLink = (id: number | string) => {
    setValues({
      ...values,
      links: values.links.filter((link) => link.id !== id)
    });
  };

  return (
    <>
      <FormGroup>
        {!placeholder && <Label for="input-deadline_date">{suffixLabel('Termin składania ofert', required)}</Label>}
        <Datetime
          name="deadline_date"
          id="input-deadline_date"
          value={deadline_date ?? ''}
          min=""
          max=""
          onChange={handleDeadlineDateChange}
          invalid={hasError(errors, 'deadline_date')}
          required={required}
          hardcodedSeconds={0}
        />
        {hasError(errors, 'deadline_date') && (
          <FormFeedback className="d-block">{getError(errors, 'deadline_date')}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        {!placeholder && <Label for="input-published_date">{suffixLabel('Data publikacji', required)}</Label>}
        <Datetime
          name="published_date"
          id="input-published_date"
          value={published_date ?? ''}
          min=""
          max=""
          onChange={handlePublishedDateChange}
          hardcodedSeconds={0}
          invalid={hasError(errors, 'published_date')}
          required={required}
        />
        {hasError(errors, 'published_date') && (
          <FormFeedback className="d-block">{getError(errors, 'published_date')}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        {orderObjectWysiwyg && orderObjectWysiwyg()}

        {hasError(errors, 'description') && (
          <FormFeedback className="d-block">{getError(errors, 'description')}</FormFeedback>
        )}
      </FormGroup>
      <div>
        {links.map((l) => (
          <div key={l.id}>
            <a rel="noreferrer" target="_blank" href={l.link}>
              {l.name}
            </a>
            <button
              type="button"
              style={{
                marginLeft: '6px',
                padding: '0',
                backgroundColor: 'transparent',
                border: 'none'
              }}
              onClick={() => handleRemoveLink(l.id)}
            >
              <i className="fa cursor-pointer fa-times font-18" />
            </button>
          </div>
        ))}
      </div>
      <Button onClick={() => setNeedAddLink(!needAddLink)}>Dodaj link</Button>
      {needAddLink && (
        <>
          <FormGroup>
            {!placeholder && <Label for="input-links">{suffixLabel('Link do postępowania', required)}</Label>}
            <Input
              id="input-links"
              searchable={false}
              type="url"
              className="default-text-color"
              onChange={(e) => setLinkToAdd(e.target.value)}
              value={linkToAdd}
            />
            {!isValidLink && <p className="text-warning">Musi być w formacie linku</p>}
            {hasError(errors, 'links') && <FormFeedback>{getError(errors, 'links')}</FormFeedback>}
          </FormGroup>
          <FormGroup>
            {!placeholder && <Label for="input-links">{suffixLabel('Treść linku')}</Label>}
            <Input
              id="input-link-name"
              searchable={false}
              type="text"
              className="default-text-color"
              onChange={(e) => setLinkNameToAdd(e.target.value)}
              value={linkNameToAdd}
            />
            {hasError(errors, 'links') && <FormFeedback>{getError(errors, 'links')}</FormFeedback>}
          </FormGroup>
          <Button
            onClick={() => {
              if (isValidURL(linkToAdd)) {
                setValues({
                  ...values,
                  links: [...values.links, { link: linkToAdd, name: linkNameToAdd, id: _uniqueId() }]
                });
                setNeedAddLink(false);
                setIsValidLink(true);
                setLinkToAdd('');
                setLinkNameToAdd('');
              } else {
                setIsValidLink(false);
              }
            }}
          >
            Dodaj
          </Button>
        </>
      )}
    </>
  );
};

export default ForeignProceedingsFieldset;
