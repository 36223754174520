import { RootState } from 'app/reducer';
import { subscriptionsSelectedAction } from 'modules/Subscriptions/action';
import Subscription from 'modules/Subscriptions/model/Subscription';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubscriptionDisplayButtons from './DisplayButtons.tsx';
import './index.scss';

type IProps = {
  subscriptionsList: Subscription[];
};

export type ISubscriptionObj = {
  title: string;
  description: string;
  subscription_description: string;
  credits_description: string;
  unlimitedOptions: { option: string; price: number; days: number; tax: number; id: number }[];
  limitedOptions: { option: string; price: number; days: number; tax: number; id: number }[];
};

const Subscriptions: React.FC<IProps> = ({ subscriptionsList }) => {
  const dispatch = useDispatch();
  const { subscriptions_id } = useSelector((state: RootState) => state.subscriptions.selected);

  const groupSubscriptionsId: { title: string; idIncluded: number[] }[] = [];

  const onOptionSelected = (id: number) => {
    if (typeof id === 'number' && id > 0) {
      const subscriptionsIdIndex = subscriptions_id.findIndex((subId) => subId === id);
      if (subscriptionsIdIndex === -1) {
        const groupId = groupSubscriptionsId.find((groupSubId) => groupSubId.idIncluded.includes(id));

        if (groupId) {
          const ids = subscriptions_id?.filter((value) =>
            groupId?.idIncluded.every((groupValue) => groupValue !== value)
          );
          dispatch(subscriptionsSelectedAction([...ids, id]));
        } else dispatch(subscriptionsSelectedAction([...subscriptions_id, id]));
      } else {
        dispatch(
          subscriptionsSelectedAction([
            ...subscriptions_id.slice(0, subscriptionsIdIndex),
            ...subscriptions_id.slice(subscriptionsIdIndex + 1)
          ])
        );
      }
    }
  };

  const segregateSubscriptionsList = () => {
    const subscriptionsArr: ISubscriptionObj[] = [];

    subscriptionsList?.forEach((subscription) => {
      const { id, modules, days, credits, value, tax_percentage } = subscription;

      const title = modules?.[0]?.name ?? '';
      const moduleDesc = modules?.[0]?.description ?? '';
      const moduleSubDesc = modules?.[0]?.subscription_description ?? '';
      const moduleCreditDesc = modules?.[0]?.credits_description ?? '';

      const creditCorrectDayForm = credits > 1 && credits < 5 ? 'kredyty' : 'kredytów';
      const creditsString = credits === 1 ? 'kredyt' : creditCorrectDayForm;

      const option = `${credits ? `${credits} ${creditsString}, ` : ''}${days} ${days === 1 ? 'dzień' : 'dni'}`;

      const subscriptionIndex = subscriptionsArr.findIndex((subscriptionArr) => subscriptionArr.title === title);
      if (subscriptionIndex === -1) {
        const createObj: ISubscriptionObj = credits
          ? {
              title,
              description: moduleDesc,
              subscription_description: moduleSubDesc,
              credits_description: moduleCreditDesc,
              unlimitedOptions: [],
              limitedOptions: [{ option, id, days, price: value, tax: tax_percentage }]
            }
          : {
              title,
              description: moduleDesc,
              subscription_description: moduleSubDesc,
              credits_description: moduleCreditDesc,
              unlimitedOptions: [{ option, id, days, price: value, tax: tax_percentage }],
              limitedOptions: []
            };
        subscriptionsArr.push(createObj);
        groupSubscriptionsId.push({ title, idIncluded: [id] });
      } else {
        let subscriptionsArrObj = subscriptionsArr[subscriptionIndex];
        if (credits) {
          subscriptionsArrObj = {
            ...subscriptionsArrObj,
            limitedOptions: [
              ...subscriptionsArrObj.limitedOptions,
              { option, id, days, price: value, tax: tax_percentage }
            ]
          };
        } else {
          subscriptionsArrObj = {
            ...subscriptionsArrObj,
            unlimitedOptions: [
              ...subscriptionsArrObj.unlimitedOptions,
              { option, id, price: value, tax: tax_percentage, days }
            ]
          };
        }
        subscriptionsArr[subscriptionIndex] = subscriptionsArrObj;

        let groupIdObj = groupSubscriptionsId[subscriptionIndex];
        if (groupIdObj) groupIdObj = { title, idIncluded: [...groupIdObj.idIncluded, id] };
        groupSubscriptionsId[subscriptionIndex] = groupIdObj;
      }
    });

    return subscriptionsArr;
  };

  return (
    <div className="subscriptions-summary-wrapper">
      <SubscriptionDisplayButtons
        subscriptionOptions={segregateSubscriptionsList()}
        subscriptions_id={subscriptions_id}
        onOptionSelected={onOptionSelected}
      />
    </div>
  );
};

export default Subscriptions;
