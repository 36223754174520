import { RootState } from 'app/reducer';
import _ from 'lodash';
import { fetchReportClaims } from 'modules/Executor/repository';
import { setSearchingParamsAction, SetSearchingParamsAction } from 'modules/Shared/action';
import Validation from 'modules/Shared/exception/Validation';
import { createSearchParams } from 'modules/Shared/helper/params';
import { PaginationMeta, PartialSearchingProps } from 'modules/Shared/type';
import { Action, Dispatch } from 'redux';
import { ReportClaimEntity } from '../../model/ReportClaim';

export const SET_REPORT_CLAIMS_LIST_PARAMS = 'REPORT/CLAIMS/LIST/PARAMS/SET';
export const REPORT_CLAIMS_LIST = 'REPORT/CLAIMS/LIST';

export type ReportClaimsListAction = SetReportClaimsListParamsAction | ReportClaimsListFetchedAction;

export type SetReportClaimsListParamsAction = SetSearchingParamsAction<typeof SET_REPORT_CLAIMS_LIST_PARAMS>;

export type ReportClaimsListFetchedPayload = {
  reportClaims: ReportClaimEntity[];
  meta: PaginationMeta;
};
export interface ReportClaimsListFetchedAction extends Action<typeof REPORT_CLAIMS_LIST> {
  payload: ReportClaimsListFetchedPayload;
}

export const setReportClaimsListParamsAction =
  (payload: PartialSearchingProps) => async (dispatch: Dispatch, getState: () => RootState) => {
    const currentState = getState()?.executor?.reportClaimsList;

    if (
      payload?.filter &&
      !payload?.pagination &&
      typeof currentState?.pagination?.page === 'number' &&
      currentState.pagination.page !== 1 &&
      !_.isEqual(currentState.filter, payload.filter)
    ) {
      currentState.pagination.page = 1;
    }

    const state = { ...currentState, ...payload };

    dispatch(setSearchingParamsAction(SET_REPORT_CLAIMS_LIST_PARAMS, payload));

    try {
      const { pagination, filter, sort } = state;

      const searchParams = createSearchParams({ pagination, filter, sort });

      const response = await fetchReportClaims(searchParams);

      const { data, meta } = response.data;

      dispatch<ReportClaimsListFetchedAction>({
        type: REPORT_CLAIMS_LIST,
        payload: { reportClaims: data, meta }
      });
    } catch (error) {
      if (error instanceof Validation) {
        dispatch<ReportClaimsListFetchedAction>({
          type: REPORT_CLAIMS_LIST,
          payload: { reportClaims: [], meta: null }
        });
      } else {
        throw error;
      }
    }
  };
