import React, { Dispatch, SetStateAction } from 'react';
import { FormGroup } from 'reactstrap';
import { getError } from 'modules/Shared/helper/validation';
import CheckboxInput from 'modules/Layout/component/Input/Checkbox';
import { ValidationErrors } from 'modules/Shared/type';

export interface StepFieldEditableCheckboxProps<T> {
  checkbox: string;
  stepValues: T;
  setStepValues: Dispatch<SetStateAction<T>>;
  mapState: (values: T) => T;
  errors?: ValidationErrors | null;
  initContentValues?: Record<string, string>;
  getRevertValue?: () => string;
  disableCheckbox?: boolean;
  isEditDisabled?: boolean;
}

const StepFieldEditableCheckbox = <T,>(props: StepFieldEditableCheckboxProps<T>): JSX.Element => {
  const {
    checkbox,
    stepValues,
    setStepValues,
    mapState,
    errors,
    initContentValues,
    getRevertValue,
    disableCheckbox,
    isEditDisabled
  } = props;

  return (
    <FormGroup key={checkbox}>
      <CheckboxInput
        id={checkbox}
        // @ts-ignore
        checked={stepValues[`${checkbox}_checked`]}
        // @ts-ignore
        content={stepValues[`${checkbox}_content`]}
        onChange={(checked, content) => {
          // @ts-ignore
          stepValues[`${checkbox}_checked`] = checked;
          // @ts-ignore
          stepValues[`${checkbox}_content`] = content;
          setStepValues((values) => mapState(values));
        }}
        onRevert={() => {
          // @ts-ignore
          stepValues[`${checkbox}_content`] = initContentValues[`${checkbox}_content`] || getRevertValue();
          setStepValues((values) => mapState(values));
        }}
        hideRevert={!initContentValues[`${checkbox}_content`] && !getRevertValue}
        disableCheckbox={disableCheckbox}
        disableEdit={isEditDisabled}
        error={getError(errors, `${checkbox}_checked`) || getError(errors, `${checkbox}_content`)}
      />
    </FormGroup>
  );
};

export default StepFieldEditableCheckbox;
