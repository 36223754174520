import Pagination from 'modules/Layout/component/Pagination';
import { getPathUrl } from 'modules/Shared/helper/api';
import { createSearchParams } from 'modules/Shared/helper/params';
import {
  PaginationMeta,
  PaginationParams,
  PartialSearchingProps,
  PathParams,
  SearchParams,
  SortParams
} from 'modules/Shared/type';
import React from 'react';

export interface Props {
  meta: PaginationMeta;
  pagination: PaginationParams;
  filter?: SearchParams;
  sort?: SortParams;
  path?: string;
  pathParams?: PathParams;
  setParams: (params: PartialSearchingProps) => void;
}

const ListingPagination: React.FC<Props> = (props) => {
  const { meta, pagination, filter = {}, sort = {}, path = null, pathParams, setParams } = props;

  const onChange = (page: number) => setParams({ pagination: { ...pagination, page } });

  const getPath = (page: number): string => {
    const params = createSearchParams({
      pagination: { ...pagination, page },
      filter,
      sort
    });

    if (path) {
      return getPathUrl(path, pathParams, params);
    }

    return null;
  };

  if (meta) {
    return <Pagination meta={meta} onChange={onChange} getPath={getPath} />;
  }

  return null;
};

export default ListingPagination;
