import React from 'react';
import { Menu } from 'app/menu';
import { ROUTE_AGREEMENTS, ROUTE_AGREEMENT_CREATE } from 'modules/Agreements/routes';
import AgreementIcon from 'modules/Layout/component/Icon/Agreement';
import { SUBSCRIPTIONS_MODULE_ACCESS } from '../Auth/container/Guard/permissions';
import User from '../User/model/User';
import { MODULE_AGREEMENTS } from '../Module/model/Module';

const MenuAgreementsShared: Menu[] = [
  {
    key: 'agreements-nested',
    type: 'nested',
    title: 'Kreator umów',
    icon: <AgreementIcon />,
    navLevel: 'second',
    href: '',
    hasAccess: (user: User) => user.hasPermissions([SUBSCRIPTIONS_MODULE_ACCESS]) || user.hasModule(MODULE_AGREEMENTS),
    isActive: (_match: null) => false,
    children: [
      {
        key: 'agreements-index',
        type: 'link',
        title: 'Lista umów',
        href: ROUTE_AGREEMENTS
      },
      {
        key: 'agreements-create',
        type: 'link',
        title: 'Dodaj umowę',
        href: ROUTE_AGREEMENT_CREATE
      }
    ]
  }
];

export default MenuAgreementsShared;
