import { PermissionSlug } from 'modules/Auth/type';
import { UserCreditsValues, UserIdentityValues } from 'modules/User/type';
import UserRole, { ROLE_ADMIN, ROLE_CLIENT } from 'modules/User/model/UserRole';
import { ModuleEntity } from 'modules/Module/model/Module';

export const getClientDataType = (client_type?: number | string) => {
  switch (client_type) {
    case 1:
      return 'Osoba fizyczna';
    case 2:
      return 'Indywidualny przedsiębiorca lub osoba prawna';
    default:
      return '';
  }
};

export const STATUS_DELETED = 0;
export const STATUS_ACTIVE = 1;
export const STATUS_NOT_VERIFIED = 2;

export type UserEntity = {
  id?: number;
  name?: string;
  credits?: UserCreditsValues[];
  first_name?: string;
  last_name?: string;
  email?: string;
  new_email?: string;
  company?: CompanyEntity;
  is_deletable?: boolean;
  status?: number;
  permissions?: string[];
  phone?: string;
  modules?: ModuleEntity[];
  regulations_accepted?: boolean;
  role?: UserRole;
  last_login_at?: string;
  rodo1_accepted?: boolean;
  rodo2_accepted?: boolean;
  rodo3_accepted?: boolean;
  rodo4_accepted?: boolean;
  updated_at?: string;
  created_at?: string;
  nip?: string;
  nip_acceptance?: boolean;
};

export type CompanyEntity = {
  client_type?: number | string;
  company_name?: number | string;
  name?: string;
  first_name?: string;
  last_name?: string;
  street?: string;
  postal_code?: string;
  city?: string;
  post_office?: string;
  nip?: string;
  email?: string;
  correspondence_address_same?: boolean;
  correspondence_street?: string;
  correspondence_postal_code?: string;
  correspondence_city?: string;
  correspondence_post_office?: string;
};

export default class User {
  readonly id?: number;
  readonly name?: string;
  readonly credits?: UserCreditsValues[];
  readonly first_name?: string;
  readonly last_name?: string;
  readonly email?: string;
  readonly new_email?: string;
  readonly company?: CompanyEntity;
  readonly is_deletable?: boolean;
  readonly status?: number;
  readonly modules?: ModuleEntity[];
  readonly permissions?: string[];
  readonly phone?: string;
  readonly regulations_accepted?: boolean;
  readonly role?: UserRole;
  readonly last_login_at?: string;
  readonly rodo1_accepted?: boolean;
  readonly rodo2_accepted?: boolean;
  readonly rodo3_accepted?: boolean;
  readonly rodo4_accepted?: boolean;
  readonly updated_at?: string;
  readonly created_at?: string;
  readonly nip?: string;
  readonly nip_acceptance?: boolean;

  constructor(entity: UserEntity) {
    this.id = entity.id;
    this.first_name = entity.first_name;
    this.credits = entity.credits;
    this.name = entity.name;
    this.last_name = entity.last_name;
    this.email = entity.email;
    this.new_email = entity.new_email;
    this.company = entity.company;
    this.is_deletable = entity.is_deletable;
    this.status = entity.status;
    this.modules = entity.modules;
    this.permissions = entity.permissions;
    this.regulations_accepted = entity.regulations_accepted;
    this.phone = entity.phone;
    this.role = entity.role;
    this.last_login_at = entity.last_login_at;
    this.rodo1_accepted = entity.rodo1_accepted;
    this.rodo2_accepted = entity.rodo2_accepted;
    this.rodo3_accepted = entity.rodo3_accepted;
    this.rodo4_accepted = entity.rodo4_accepted;
    this.updated_at = entity.updated_at;
    this.created_at = entity.created_at;
    this.nip = entity.nip;
    this.nip_acceptance = entity.nip_acceptance;
  }

  getStatusLabel(): string {
    switch (this.status) {
      case STATUS_DELETED:
        return 'Usunięty';
      case STATUS_ACTIVE:
        return 'Aktywny';
      case STATUS_NOT_VERIFIED:
        return 'Brak weryfikacji';
      default:
        return '';
    }
  }

  getRoleLabel(): string {
    switch (this.role?.slug) {
      case ROLE_ADMIN:
        return 'Administrator';
      case ROLE_CLIENT:
        return 'Klient';
      default:
        return '';
    }
  }

  getLastLogin(): string {
    return this.last_login_at || 'Nigdy';
  }

  getName(): string {
    if (this.name && this.name.trim()) {
      return this.name;
    }
    return this.email;
  }

  getFullName(): string {
    const values: string[] = [this.first_name, this.last_name];

    return values.join(' ').trim();
  }

  getIdentity(): UserIdentityValues {
    return {
      email: this.email,
      first_name: this.first_name,
      last_name: this.last_name,
      phone: this.phone,
      nip: this.nip
    };
  }

  hasPermissions(slugs: PermissionSlug[], any = true): boolean {
    if (slugs.length > 0) {
      if (any) {
        return slugs.some((slug) => this.permissions.includes(slug));
      }

      return slugs.every((slug) => this.permissions.includes(slug));
    }

    return true;
  }

  hasModule(md_slug: string): boolean {
    return this.modules.some((module) => module.slug === md_slug);
  }

  getCompany(): CompanyEntity {
    return {
      client_type: this.company?.client_type ?? null,
      name: this.company?.name ?? '',
      first_name: this.company?.first_name ?? '',
      last_name: this.company?.last_name ?? '',
      street: this.company?.street ?? '',
      postal_code: this.company?.postal_code ?? '',
      city: this.company?.city ?? '',
      post_office: this.company?.post_office ?? '',
      nip: this.company?.nip ?? '',
      email: this.company?.email ?? '',
      correspondence_address_same: this.company?.correspondence_address_same ?? false,
      correspondence_street: this.company?.correspondence_street ?? '',
      correspondence_postal_code: this.company?.correspondence_postal_code ?? '',
      correspondence_city: this.company?.correspondence_city ?? '',
      correspondence_post_office: this.company?.correspondence_post_office ?? ''
    };
  }

  getCompanyClientType(client_type?: number | string): string {
    const client = client_type ?? this.company?.client_type;
    return getClientDataType(client);
  }

  isClientTypeCompany(client_type?: number | string): boolean {
    const client = client_type ?? this.company?.client_type;
    switch (client) {
      case 2:
        return true;
      default:
        return false;
    }
  }

  getCompanyName(): string {
    if (this.company?.name && this.company.name.trim()) {
      return this.company.name;
    }
    return '';
  }

  getCompanyAddress(): string {
    if (!this.company) {
      return '';
    }

    const { street, postal_code, city } = this.company;

    return [street, postal_code, city].join(', ').trim();
  }

  getCompanyPostal(): string {
    if (this.company?.postal_code && this.company?.post_office)
      return `${this.company?.postal_code ?? ''} ${this.company?.post_office ?? ''}`;
    if (this.company?.postal_code) return this.company?.postal_code;
    if (this.company?.post_office) return this.company?.post_office;
    return null;
  }

  getCompanyCorrespondenceAddress(): string {
    const {
      correspondence_city: city,
      correspondence_street: street,
      correspondence_postal_code: code,
      correspondence_post_office: office
    } = this.company || {};

    if (this.company?.correspondence_address_same) {
      if (this.company?.client_type === 1) return 'Taki sam jak dla danych rozliczeniowych';
      return 'taki sam jak siedziba';
    }

    // eslint-disable-next-line prefer-template
    return `${city ?? ''}${city && (street || code || office) ? ', ' : ''}${street ? `ul. ${street}` : ''}${
      street && (code || office) ? ', ' : ''
    }${code ? `${code} ` : ''}${office ? `${office} ` : ''}`;
  }

  static getFilterableAttributes(): string[] {
    return [
      'first_name',
      'last_name',
      'email',
      'role_id',
      'role_slug',
      'last_login_at_from',
      'last_login_at_to',
      'never_logged'
    ];
  }

  static getSortableAttributes(): string[] {
    return ['first_name', 'last_name', 'email'];
  }
}

export const createUser = (entity: UserEntity): User => new User(entity);

export const companyClientArrOptions = () => {
  return [
    { id: 1, value: 'Osoba fizyczna' },
    { id: 2, value: 'Indywidualny przedsiębiorca lub osoba prawna' }
  ];
};

export const roleArrOptions = () => {
  return [
    { id: '', value: '' },
    { id: 1, value: 'Administrator' },
    { id: 2, value: 'Client' }
  ];
};

export const getRoleId = (role?: string) => {
  switch (role) {
    case ROLE_ADMIN:
      return 1;
    case ROLE_CLIENT:
      return 2;
    default:
      return 2;
  }
};

export const getRoleArray = () => {
  return [
    { value: ROLE_ADMIN, label: 'Administrator' },
    { value: ROLE_CLIENT, label: 'Client' }
  ];
};

export const getStatusArray = () => {
  return [
    { value: STATUS_DELETED, label: 'Usunięty' },
    { value: STATUS_ACTIVE, label: 'Aktywny' },
    { value: STATUS_NOT_VERIFIED, label: 'Nie zweryfikowany' }
  ];
};

export const getClientStatusLabel = (status: number) => {
  switch (status) {
    case STATUS_DELETED:
      return 'Usunięty';
    case STATUS_ACTIVE:
      return 'Aktywny';
    case STATUS_NOT_VERIFIED:
      return 'Niezweryfikowany';
    default:
      return '';
  }
};
