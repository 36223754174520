import React from 'react';
import CardBox from 'modules/Layout/component/CardBox';
import InformationList from 'modules/Layout/component/InformationList';

type Props = {
  content?: string;
  headingInfo?: string;
  contentFieldRef?: React.MutableRefObject<HTMLDivElement>;
};

const JudgementContentDetails: React.FC<Props> = ({ content = '', headingInfo = '', contentFieldRef }) => {
  return (
    <CardBox
      heading={`Treść publikacji ${headingInfo}`}
      paddingRight="30px"
      paddingBottom="0"
      className="judgement-basic-details"
    >
      <div ref={contentFieldRef} />
      <InformationList
        fields={[
          {
            label: ' ',
            value: content,
            html: true,
            htmlFieldInsertedStyle: { maxHeight: '700px', overflowY: 'auto' }
          }
        ]}
      />
    </CardBox>
  );
};

export default JudgementContentDetails;
