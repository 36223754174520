import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterInputType } from 'modules/Layout/type';
import { formatDateLocalForInput } from 'modules/Shared/helper/utils';
import { setJudgementClientListParamsAction } from 'modules/JudgementClient/action/JudgmentList';
import JudgmentClientFilterCategoriesAutocomplete from 'modules/JudgementClient/container/JudgementClientList/Filter/customFields/FilterCategoriesAutocomplete';
import JudgmentClientFilterKeywordsAutocomplete from 'modules/JudgementClient/container/JudgementClientList/Filter/customFields/FilterKeywordsAutocomplete';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { JUDGMENT_SLUG_FILTER, FiltersEntity } from 'modules/Layout/model/Filters';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { fetchJudgmentClientCategories, fetchJudgmentClientKeywords } from 'modules/JudgementClient/repository';
import { addToastAction } from 'modules/Layout/action';
import { fetchJudgementCategoryToastError, fetchJudgementKeywordsToastError } from 'modules/Judgement/toasts';
import useSaveFilter from 'modules/Shared/helper/hooks/useSaveFilter';
import SaveFilterModal from 'modules/Layout/component/ListFilter/SaveFilterModal';
import Loader from 'modules/Layout/component/Loader';
import _ from 'lodash';
import { createSearchingProps } from 'modules/Shared/helper/params';
import Judgement from 'modules/Judgement/model/Judgement';
import renameDuplicateEntries from 'modules/Shared/helper/renameDuplicateEntries';

export interface Props {
  values?: SearchParams;
}

const JudgementClientFilter: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.judgmentClient.judgementClientList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setJudgementClientListParamsAction(payload));
  const [fetching, setFetching] = useState(false);
  const [initCategories, setInitCategories] = useState<{ name: string; id: number }[]>([]);
  const [initKeywords, setInitKeywords] = useState<{ name: string; id: number }[]>([]);

  const cancelToken = useCancelToken();
  const onSavedFilterClick = async (saveFilter: FiltersEntity) => {
    if (
      // eslint-disable-next-line
      (saveFilter.hasOwnProperty('categories[]') && Array.isArray(saveFilter['categories[]'])) ||
      // eslint-disable-next-line
      (saveFilter.hasOwnProperty('keywords[]') && Array.isArray(saveFilter['keywords[]']))
    ) {
      setFetching(true);
    }

    // eslint-disable-next-line
    if (saveFilter.hasOwnProperty('categories[]') && Array.isArray(saveFilter['categories[]'])) {
      const { data, cancelled, message } = await fetchRequest(fetchJudgmentClientCategories, {}, cancelToken);

      if (cancelled) return;
      if (data) {
        const initCategoriesObj: { name: string; id: number }[] = [];

        saveFilter['categories[]'].forEach((num) => {
          const findElement = data.data.find((el) => el.id === num);
          if (findElement) {
            initCategoriesObj.push({ name: findElement.name, id: findElement.id });
          } else {
            const index = (saveFilter['categories[]'] as any).indexOf(num);
            if (index > -1) {
              (saveFilter['categories[]'] as any).splice(index, 1);
            }
          }
        });

        const categoryListWithoutDuplicates = renameDuplicateEntries(initCategoriesObj);
        setInitCategories(categoryListWithoutDuplicates);
      }
      if (message) {
        dispatch(addToastAction(fetchJudgementCategoryToastError(message.value)));
      }
    } else {
      setInitCategories([]);
    }

    // eslint-disable-next-line
    if (saveFilter.hasOwnProperty('keywords[]') && Array.isArray(saveFilter['keywords[]'])) {
      const { data, cancelled, message } = await fetchRequest(fetchJudgmentClientKeywords, {}, cancelToken);

      if (cancelled) return;
      if (data) {
        const initKeywordsObj: { name: string; id: number }[] = [];

        saveFilter['keywords[]'].forEach((num) => {
          const findElement = data.data.find((el) => el.id === num);
          if (findElement) {
            initKeywordsObj.push({ name: findElement.name, id: findElement.id });
          } else {
            const index = (saveFilter['keywords[]'] as any).indexOf(num);
            if (index > -1) {
              (saveFilter['keywords[]'] as any).splice(index, 1);
            }
          }
        });

        const keywordListWithoutDuplicates = renameDuplicateEntries(initKeywordsObj);
        setInitKeywords(keywordListWithoutDuplicates);
      }
      if (message) {
        dispatch(addToastAction(fetchJudgementKeywordsToastError(message.value)));
      }
    } else {
      setInitKeywords([]);
    }

    setFetching(false);

    setParams({ filter: saveFilter });
  };

  const {
    saveModal,
    setSaveModal,
    cleanMessage,
    loading,
    message,
    filtersList,
    saveFilter,
    onDeleteFilterClick,
    onFilterSelectedClick,
    filterList1stFetchFinished
  } = useSaveFilter(filter, setParams, JUDGMENT_SLUG_FILTER, ['categories', 'keywords'], {
    onFilterSelectedClickCustomFnc: onSavedFilterClick
  });

  const searchProps = createSearchingProps('', Judgement.getFilterableAttributes(), Judgement.getSortableAttributes());

  useEffect(() => {
    if (filterList1stFetchFinished) {
      const findDefault = filtersList.find((el) => el.default);
      if (findDefault) {
        onFilterSelectedClick(findDefault.id);
      } else {
        const localStorageParams = localStorage.getItem(`localFilterParamsjudgement`);

        if (localStorageParams) {
          const parsedParams = JSON.parse(localStorageParams);

          setParams({ ...parsedParams });
        } else {
          setParams({ ...searchProps });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterList1stFetchFinished]);

  const staticInputsBase: FilterInputType[] = [
    {
      type: 'text',
      property: 'identifier',
      label: 'Identyfikator publikacji',
      value: _get(filter, 'identifier')
    },
    {
      type: 'text',
      property: 'authority_name',
      label: 'Nazwa organu publikującego dokument',
      value: _get(filter, 'authority_name')
    },
    {
      type: 'text',
      property: 'authority_type',
      label: 'Rodzaj organu publikującego dokument',
      value: _get(filter, 'authority_type')
    },
    {
      type: 'text',
      property: 'document_type',
      label: 'Rodzaj dokumentu',
      value: _get(filter, 'document_type')
    },
    {
      type: 'text',
      property: 'place',
      label: 'Oryginalne miejsce publikacji',
      value: _get(filter, 'place')
    }
  ];

  const inputsTime = (): FilterInputType[] => {
    let dynamicInputs: FilterInputType[] = [];

    const lastPublishedAtFrom = _get(filter, 'published_at_from') as string;
    const lastPublishedAtTo = _get(filter, 'published_at_to') as string;

    dynamicInputs = [
      {
        beforeLineBreak: true,
        type: 'date',
        property: 'published_at_from',
        label: 'Termin publikacji od',
        value: lastPublishedAtFrom ? formatDateLocalForInput(lastPublishedAtFrom) : lastPublishedAtFrom,
        max: String(lastPublishedAtTo)
      },
      {
        type: 'date',
        property: 'published_at_to',
        label: 'Termin publikacji do',
        value: lastPublishedAtTo ? formatDateLocalForInput(lastPublishedAtTo) : lastPublishedAtTo,
        min: String(lastPublishedAtFrom)
      }
    ];

    return dynamicInputs;
  };

  const customInputs = () => (
    <>
      <div className="w-100 mt-1" />
      <JudgmentClientFilterCategoriesAutocomplete initCategories={initCategories} />
      <JudgmentClientFilterKeywordsAutocomplete initKeywords={initKeywords} />
    </>
  );

  const staticInputs = [...staticInputsBase, ...inputsTime()];

  let disableBtn = false;
  if (_.isEmpty(filter)) {
    disableBtn = true;
  }

  const setMessage = (value: boolean) => {
    if (message) cleanMessage();
    setSaveModal(value);
  };

  return (
    <div className="position-relative">
      {fetching && <Loader />}
      <ListingFilter
        inputs={staticInputs}
        filter={filter}
        pagination={pagination}
        filtersName={'judgement'}
        setParams={setParams}
        customInput={customInputs()}
        saveFilterBtn={() => setMessage(true)}
        disableBtn={disableBtn}
        filterName="Wyszukiwanie"
        filterObj={{ filtersList, onDeleteFilterClick, onFilterSelectedClick, loading }}
      />
      {saveModal && (
        <SaveFilterModal
          onSaveClick={saveFilter}
          isOpen={saveModal}
          toggle={() => setMessage(false)}
          message={message}
          loading={loading}
          displayDefaultCheckbox
          shouldRefetchList
        />
      )}
    </div>
  );
};

export default JudgementClientFilter;
