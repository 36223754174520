import React from 'react';
import { IconProps } from 'modules/Layout/component/Icon/index';

export default function LogoutIcon(props: IconProps): JSX.Element {
  const { fill = '#161933', height = '40px' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 30.695 30.989">
      <g id="Group_310" data-name="Group 310" transform="translate(-312.225 -1943.907)">
        <path
          id="Path_1245"
          data-name="Path 1245"
          d="M319.557,1951.818a.5.5,0,0,1-.5-.5v-3.911a.5.5,0,0,1,.5-.5h5.215a.5.5,0,0,1,0,1h-4.715v3.411A.5.5,0,0,1,319.557,1951.818Z"
          transform="translate(2.076 0.911)"
          fill={fill}
        />
        <path
          id="Path_1246"
          data-name="Path 1246"
          d="M324.772,1965.515h-5.215a.5.5,0,0,1-.5-.5v-2.608a.5.5,0,0,1,1,0v2.108h4.715a.5.5,0,0,1,0,1Z"
          transform="translate(2.076 5.469)"
          fill={fill}
        />
        <path
          id="Path_1247"
          data-name="Path 1247"
          d="M325.557,1974.9a.5.5,0,0,1-.5-.5v-29.989a.5.5,0,0,1,.644-.479l12.964,3.912a.5.5,0,0,1,.356.479v22.165a.5.5,0,0,1-.356.479l-12.964,3.912A.5.5,0,0,1,325.557,1974.9Zm.5-29.815v28.642l11.964-3.61V1948.69Z"
          transform="translate(3.899)"
          fill={fill}
        />
        <path
          id="Line_1"
          data-name="Line 1"
          d="M1.3.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H1.3a.5.5,0,0,1,.5.5A.5.5,0,0,1,1.3.5Z"
          transform="translate(332.064 1960.053)"
          fill={fill}
        />
        <path
          id="Line_2"
          data-name="Line 2"
          d="M11.515.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H11.515a.5.5,0,0,1,.5.5A.5.5,0,0,1,11.515.5Z"
          transform="translate(312.725 1960.053)"
          fill={fill}
        />
        <path
          id="Path_1248"
          data-name="Path 1248"
          d="M318.247,1962.228a.5.5,0,0,1-.354-.854l3.962-3.962-3.962-3.962a.5.5,0,1,1,.707-.707l4.315,4.315a.5.5,0,0,1,0,.707l-4.315,4.315A.5.5,0,0,1,318.247,1962.228Z"
          transform="translate(1.678 2.64)"
          fill={fill}
        />
      </g>
    </svg>
  );
}
