import classNames from 'classnames';
import { itemsPerPage } from 'modules/Shared/helper/params';
import { PaginationMeta } from 'modules/Shared/type';
import React, { ReactNode } from 'react';
import { CustomInput, Label } from 'reactstrap';

export type PerPageProps = {
  className?: string;
  meta: PaginationMeta;
  onChange: (per_page: string) => void;
};

class PerPage extends React.Component<PerPageProps> {
  constructor(props: PerPageProps) {
    super(props);

    this.renderOptions = this.renderOptions.bind(this);
    this.renderInfo = this.renderInfo.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  renderOptions(): ReactNode[] {
    return itemsPerPage.map((value) => (
      <option key={value} value={value}>
        {value}
      </option>
    ));
  }

  renderInfo(): string {
    const {
      meta: { from, to, total }
    } = this.props;

    return `${from ?? 0} - ${to ?? 0} z ${total ?? 0}`;
  }

  render(): ReactNode {
    const { className, meta, onChange } = this.props;

    return (
      <div className={classNames('d-inline-flex align-items-center', className)}>
        <div className="per-page-info mr-2">{this.renderInfo()}</div>
        <Label for="per_page" className="mb-0">
          <CustomInput
            id="per_page"
            type="select"
            onChange={(event) => onChange(event.target.value)}
            defaultValue={meta.per_page}
          >
            {this.renderOptions()}
          </CustomInput>
        </Label>
      </div>
    );
  }
}

export default PerPage;
