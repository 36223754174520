import React, { useState, useEffect } from 'react';
import { Button, Label, Input, FormGroup, FormFeedback } from 'reactstrap';
import BaseModal, { Props } from 'modules/Layout/component/Modal';
import Loader from 'modules/Layout/component/Loader';
import { Message, ValidationErrors } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import { getError, hasError } from 'modules/Shared/helper/validation';

export interface IProps extends Props {
  onSubmit: (file: File) => void;
  fetching: boolean;
  message?: Message;
  errors?: ValidationErrors;
  resetErrors?: () => void;
  maxSizeInMb?: number;
}

const ForeignProceedingImportExcelModal: React.FC<IProps> = (props) => {
  const { onSubmit, fetching, message, errors, toggle, resetErrors, maxSizeInMb } = props;
  const [file, setFile] = useState<File>(null);
  const [displaySizeError, setDisplaySizeError] = useState(false);

  const title = 'Importuj plik csv';

  const isValue = React.useRef(false);
  useEffect(() => {
    return () => {
      if (isValue.current) resetErrors();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!isValue.current) isValue.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message || errors]);

  const saveFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileTarget = event.target.files[0];
    if (maxSizeInMb) {
      const sizeInMb = fileTarget.size / (1024 * 1024);

      if (maxSizeInMb < sizeInMb) {
        if (!displaySizeError) setDisplaySizeError(true);
        if (file) setFile(null);
        return;
      }

      if (displaySizeError) setDisplaySizeError(false);
    }

    setFile(fileTarget);
  };

  const dispalBody = () => {
    return (
      <>
        <Alert message={message} />
        {fetching && <Loader />}
        <div className="modal-body-content">
          <fieldset>
            <FormGroup>
              <Label for="#new-import-file">Wybierz plik csv</Label>
              <Input
                id="new-import-file"
                type="file"
                required
                accept="text/csv"
                onChange={saveFile}
                invalid={hasError(errors, 'file')}
              />
              {displaySizeError && (
                <FormFeedback className="d-block">Rozmiar pliku nie może przekroczyć {maxSizeInMb} MB.</FormFeedback>
              )}
              {hasError(errors, 'file') && <FormFeedback>{getError(errors, 'file')}</FormFeedback>}
              {errors instanceof Array && (
                <div>
                  {errors.map((error) => (
                    <div className="error-message" key={error}>
                      {error}
                    </div>
                  ))}
                </div>
              )}
            </FormGroup>
          </fieldset>
        </div>
      </>
    );
  };

  const displayFooter = () => {
    return (
      <div className="modal-actions">
        <Button color="success" className="pzpeu-btn-disabled" onClick={() => onSubmit(file)} disabled={!file}>
          Rozpocznij
        </Button>
        <Button className="cancel" onClick={toggle}>
          Anuluj
        </Button>
      </div>
    );
  };

  return <BaseModal {...props} title={title} body={dispalBody()} footer={displayFooter()} />;
};

export default ForeignProceedingImportExcelModal;
