import { RootState } from 'app/reducer';
import { recoverPasswordAction } from 'modules/Auth/action';
import PasswordRecoverForm from 'modules/Auth/component/Password/Recover/Form';
import { View } from 'modules/Auth/component/View';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
import PublicWrapper from 'modules/Layout/component/Wrapper/Public';

const PasswordRecover = () => {
  const dispatch = useDispatch();
  const { busy, errors, message } = useSelector((state: RootState) => state.auth);

  const recover = (email: string) => dispatch(recoverPasswordAction({ email }));
  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  useEffect(() => {
    managePage({
      title: 'Reset hasła'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PublicWrapper hideHeader hideFooter>
      <View busy={busy} message={message}>
        <span>
          Wpisz swój adres e-mail,
          <br />
          a wyślemy Ci informacje,
          <br />
          jak zresetować hasło.
        </span>
        <PasswordRecoverForm busy={busy} errors={errors} submit={recover} />
      </View>
    </PublicWrapper>
  );
};

export default PasswordRecover;
