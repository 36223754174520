import React from 'react';
import classNames from 'classnames';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_SUPPLEMENTARY_ORDERS } from 'modules/Agreements/step';
import { AgreementStepSupplementaryOrdersValues } from 'modules/Agreements/components/Steps/Default/SupplementaryOrders';
import PreviewFieldContent from 'modules/Agreements/components/PreviewField/Content';

const AgreementStepPreviewSupplementaryOrders = (
  props: AgreementStepPreviewProps<AgreementStepSupplementaryOrdersValues>
): JSX.Element => {
  const { agreement, steps, modifiedStep } = props;
  const supplementary_orders = getStepValues<AgreementStepSupplementaryOrdersValues>(
    steps,
    AGREEMENT_STEP_SUPPLEMENTARY_ORDERS
  );

  const { value: parts } = getFieldForPreview<AgreementStepSupplementaryOrdersValues['supplementary_orders']>(
    supplementary_orders,
    modifiedStep,
    'supplementary_orders'
  );
  const { value: sameParts } = getFieldForPreview<
    AgreementStepSupplementaryOrdersValues['same_supplementary_orders_for_parts']
  >(supplementary_orders, modifiedStep, 'same_supplementary_orders_for_parts');

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    supplementary_orders,
    modifiedStep,
    `checkboxes.general`
  );

  const isMultiple = agreement.few_parts && !sameParts;

  const renderContent = (contentKey: string, className = 'preview-point') => {
    return (
      <PreviewFieldContent
        step={supplementary_orders}
        modifiedStep={modifiedStep}
        contentKey={contentKey}
        className={className}
      />
    );
  };

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">
        {getStepName(AGREEMENT_STEP_SUPPLEMENTARY_ORDERS, agreement.mode)}
      </div>
      <div className="reset-preview-point">
        {parts?.map((part, index: number) => {
          const { value: supplementaryPossibilityChecked } = getFieldForPreview(
            supplementary_orders,
            modifiedStep,
            `supplementary_orders.${index}.supplementary_possibility`
          );

          const partObj = agreement.parts_ids.find((item) => item.id === part.part_id);

          return (
            <div key={index} className="agreement-step-preview-content reset-preview-point">
              {isMultiple && partObj && <div className="h5">{partObj.getName(index)}:</div>}
              {supplementaryPossibilityChecked ? (
                renderContent(`supplementary_orders.${index}.supplementary_content`)
              ) : (
                <p key={`supplementary_orders.${index}.personal_duty`} className={classNames('preview-point')}>
                  Zamawiający nie przewiduje udzielania zamówień uzupełniających.
                </p>
              )}
              {supplementaryPossibilityChecked &&
                renderContent(`supplementary_orders.${index}.supplementary_repeat_content`)}
              {generalCheckboxes &&
                generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
                  if (!checkbox.checked) return null;

                  if (checkbox.part_id != part.part_id) return null;

                  return (
                    <p
                      className={classNames('preview-point wysiwyg-preview', {
                        modified: generalCheckboxesModified
                      })}
                      key={index}
                      dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                    />
                  );
                })}
            </div>
          );
        })}
        {isMultiple && generalCheckboxes && (
          <div className="agreement-step-preview-content">
            <p className="h5 reset-preview-point">Ponadto dla każdego z zadań:</p>
            {generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
              if (!checkbox.checked) return null;

              if (checkbox.part_id) return null;

              return (
                <p
                  className={classNames('preview-point wysiwyg-preview', {
                    modified: generalCheckboxesModified
                  })}
                  key={index}
                  dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AgreementStepPreviewSupplementaryOrders;
