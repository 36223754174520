import React from 'react';
import { RootState } from 'app/reducer';
import ListingPagination from 'modules/Layout/component/Listing/Pagination';
import { PartialSearchingProps } from 'modules/Shared/type';
import { useDispatch, useSelector } from 'react-redux';
import { setForeignProceedingsApiImportsListParamsAction } from '../../../action/ForeignProceedingsApiImportsList';

type Props = { path?: string };

const ForeignProceedingsApiImportsListingPagination: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { meta, pagination, filter, sort } = useSelector(
    (state: RootState) => state.foreignProceedingsApiImports.foreignProceedingsApiImportsList
  );
  const setParams = (payload: PartialSearchingProps) =>
    dispatch(setForeignProceedingsApiImportsListParamsAction(payload));

  return (
    <ListingPagination
      meta={meta}
      pagination={pagination}
      sort={sort}
      setParams={setParams}
      filter={filter}
      {...props}
    />
  );
};

export default ForeignProceedingsApiImportsListingPagination;
