import React, { useEffect, useRef, useState } from 'react';
import { push } from 'connected-react-router';
import { useParams } from 'react-router-dom';
import { addToastAction } from 'modules/Layout/action';
import { useDispatch } from 'react-redux';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { Message } from 'modules/Shared/type';
import Alert from 'modules/Layout/component/Alert';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import deleteRequest from 'modules/Shared/helper/APIRequests/deleteRequest';
import GenericModalDelete from 'modules/Layout/component/Modal/GenericDeleteModal';
import { deleteClauseConnectedJudgements, fetchClauseConnectedJudgements } from 'modules/Clause/repository';
import { deleteClauseJudgementToastSuccess } from 'modules/Clause/toasts';
import { ClauseConnectedJudgementValues } from 'modules/Clause/type';
import CardBox from 'modules/Layout/component/CardBox';
import { Button } from 'reactstrap';
import Authorize from 'modules/Auth/container/Authorize';
import { CLAUSE_ADD } from 'modules/Clause/permissions';
import Listing from 'modules/Layout/component/Listing';
import ClauseConnectedJudgementsTable from 'modules/Clause/container/ClauseConnectedJudgements/Table';
import Clause from 'modules/Clause/model/Clause';
import useLocalPagination from 'modules/Shared/helper/hooks/useLocalPagination';
import InformationList from 'modules/Layout/component/InformationList';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_CLAUSE_JUDGEMENT_ADD_LIST } from 'modules/Clause/routes';

const ClauseDetailsConnectedJudgements: React.FC<{ clause: Clause }> = ({ clause }) => {
  const dispatch = useDispatch();
  const { id: clause_id } = useParams<{ id: string }>();
  const [clauseJudgements, setClauseJudgements] = useState<ClauseConnectedJudgementValues[]>([]);
  const [message, setMessage] = useState<Message>(null);
  const [fetching, setFetching] = useState(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState<{ id: number }>(null);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [deleteModalMessage, setDeleteModalMessage] = useState<Message>(null);
  const [displayJudgmentIdContent, setDisplayJudgmentIdContent] = useState<number>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (displayJudgmentIdContent && contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayJudgmentIdContent]);

  const dispatchDeleteClauseJudgmentToast = () => dispatch(addToastAction(deleteClauseJudgementToastSuccess()));

  const cancelToken = useCancelToken();
  const fetchClauseJudgementAction = async () => {
    setFetching(true);
    const {
      data,
      cancelled,
      message: messageResponse
    } = await fetchRequest(fetchClauseConnectedJudgements, clause_id, cancelToken);

    if (cancelled) return;
    if (data) setClauseJudgements(data.data);
    if (messageResponse) setMessage(messageResponse);
    setFetching(false);
  };

  useEffect(() => {
    fetchClauseJudgementAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteClauseJudgementAction = async (judgment_id: string | number) => {
    setDeleteModalLoading(true);
    const {
      cancelled,
      message: messageRes,
      success
    } = await deleteRequest(deleteClauseConnectedJudgements, clause_id, judgment_id, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteClauseJudgmentToast();
      fetchClauseJudgementAction();
      setDisplayDeleteModal(null);
    }
    if (messageRes) setDeleteModalMessage(messageRes);
    setDeleteModalLoading(false);
  };

  const { paginationComponent, selectedItemsFromArray } = useLocalPagination(clauseJudgements);

  const hideContent = () => {
    contentRef.current = null;
    setDisplayJudgmentIdContent(null);
  };

  const displayContent = () => {
    if (displayJudgmentIdContent) {
      const selectedJudgment = clauseJudgements.find((el) => el.judgment.id === displayJudgmentIdContent);

      if (selectedJudgment) {
        return (
          <div className="col-12" ref={contentRef}>
            <CardBox
              heading={`Treść zaznaczonej publikacji - ${selectedJudgment.judgment.identifier}`}
              paddingBottom="0"
            >
              <InformationList
                fields={[
                  {
                    label: '',
                    value: selectedJudgment.content,
                    html: true,
                    htmlFieldInsertedStyle: { maxHeight: '700px', overflowY: 'auto', marginBottom: '20px' }
                  }
                ]}
              />
            </CardBox>
          </div>
        );
      }

      hideContent();
    }

    return null;
  };

  return (
    <div className="row">
      {displayDeleteModal && (
        <GenericModalDelete
          value={displayDeleteModal}
          title="Usunięcie połączonego orzeczenia"
          content={() => <div>Czy na pewno chcesz usunąć połączenie z tym orzeczeniem?</div>}
          onDeleteClick={(obj) => deleteClauseJudgementAction(obj.id)}
          isOpen={!!displayDeleteModal}
          toggle={() => setDisplayDeleteModal(null)}
          loading={deleteModalLoading}
          message={deleteModalMessage}
          resetMessage={() => setDeleteModalMessage(null)}
        />
      )}

      <div className="col-12">
        <CardBox
          heading="Połączone orzeczenia"
          actions={
            <Authorize permissions={[CLAUSE_ADD]}>
              <Button
                color="link"
                className="link-with-border p-0"
                onClick={() =>
                  dispatch(
                    push({
                      pathname: getPathUrl(ROUTE_CLAUSE_JUDGEMENT_ADD_LIST, { id: clause_id }),
                      state: { clause }
                    })
                  )
                }
              >
                Dodaj orzeczenie
              </Button>
            </Authorize>
          }
        >
          <div className="row users-view">
            <div className="col-12">
              <Alert message={message} />
              <div className="pzpeu-table-list inside-card-box">
                <Listing
                  table={
                    <ClauseConnectedJudgementsTable
                      clauseJudgements={selectedItemsFromArray as any}
                      onDeleteClick={setDisplayDeleteModal}
                      setShowContent={setDisplayJudgmentIdContent}
                      clause={clause}
                    />
                  }
                  pagination={paginationComponent()}
                  loading={fetching}
                />
              </div>
            </div>
          </div>
        </CardBox>
      </div>
      {displayContent()}
    </div>
  );
};

export default ClauseDetailsConnectedJudgements;
