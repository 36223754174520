import { suffixLabel } from 'modules/Layout/helper/misc';
import { getError, hasError } from 'modules/Shared/helper/validation';
import React from 'react';
import { CustomInput, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import {
  proceedingStartDateQuarterOptions,
  proceedingStartDateTypeOptions,
  proceedingStatusOptions
} from 'modules/Proceedings/model/Proceedings';
import Select from 'modules/Layout/component/Input/Select';
import { setInputNumbers } from 'modules/Layout/component/Input/TextNumber/input';
import _uniqueId from 'lodash/uniqueId';
import { PROCEEDINGS_INIT_TYPES_VALUES, START_DATE_TYPES_VALUES } from 'modules/Proceedings/type';
import Datetime from 'modules/Layout/component/Input/Datetime';
import { Props } from 'modules/Proceedings/component/Fieldset/Main';
import { getMonthOptions } from 'modules/Shared/helper/utils';

const ProceedingsFieldset: React.FC<Props> = (props: Props): JSX.Element => {
  const { values, errors, setValues, answerPlaceWysiwyg, answerWayWysiwyg, placeholder = false, required } = props;

  const {
    proceeding_type = 1,
    status = 1,
    start_date_type = 1,
    start_date = '',
    offers_send_date = '',
    requires_deposit = false,
    deposit = 0
  } = values;

  return (
    <>
      {proceeding_type === PROCEEDINGS_INIT_TYPES_VALUES.OPEN && (
        <>
          <FormGroup>
            {!placeholder && <Label for="input-status">{suffixLabel('Status postępowania', required)}</Label>}
            <Select
              id="input-status"
              className="default-text-color"
              onChange={(newValue) => setValues({ ...values, status: newValue.value })}
              value={proceedingStatusOptions().find((val) => val.value === status)}
              options={proceedingStatusOptions()}
            />
            {hasError(errors, 'status') && <FormFeedback>{getError(errors, 'status')}</FormFeedback>}
          </FormGroup>
          <FormGroup className="mb-0">
            {!placeholder && (
              <Label for="input-start_date">{suffixLabel('Data rozpoczęcia postępowania', required)}</Label>
            )}
            <Datetime
              name="start_date"
              id="input-start_date"
              value={typeof start_date === 'string' ? start_date : ''}
              min=""
              max=""
              onChange={(val) => setValues({ ...values, start_date: val })}
              invalid={hasError(errors, 'start_date')}
              required={required}
            />
            {hasError(errors, 'start_date') && (
              <FormFeedback className="d-block">{getError(errors, 'start_date')}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup className="mb-0">
            {!placeholder && (
              <Label for="input-offers_send_date">{suffixLabel('Termin składania ofert', required)}</Label>
            )}
            <Datetime
              name="offers_send_date"
              id="input-offers_send_date"
              value={offers_send_date ?? ''}
              min=""
              max=""
              onChange={(val) => setValues({ ...values, offers_send_date: val })}
              invalid={hasError(errors, 'offers_send_date')}
              required={required}
            />
            {hasError(errors, 'offers_send_date') && (
              <FormFeedback className="d-block">{getError(errors, 'offers_send_date')}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <CustomInput
              type="switch"
              id="checkox-requires_deposit"
              label="Czy w postępowaniu wadium jest wymagane?"
              checked={requires_deposit}
              onChange={(event) => setValues({ ...values, requires_deposit: event.target.checked })}
            />
          </FormGroup>
          {requires_deposit && (
            <FormGroup>
              {!placeholder && <Label for="input-deposit ">{suffixLabel('Wartość wadium', requires_deposit)}</Label>}
              <Input
                type="number"
                name="value"
                id="input-deposit"
                placeholder={placeholder ? 'Wartość wadium' : null}
                value={deposit}
                onChange={(event) => setInputNumbers(event, values, setValues, 'deposit')}
                invalid={hasError(errors, 'deposit')}
                min={1}
                step="0.01"
                required
              />
              {hasError(errors, 'deposit ') && <FormFeedback>{getError(errors, 'deposit')}</FormFeedback>}
            </FormGroup>
          )}
          <FormGroup>
            {answerPlaceWysiwyg && answerPlaceWysiwyg()}

            {hasError(errors, 'answer_place') && (
              <FormFeedback className="d-block">{getError(errors, 'answer_place')}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            {answerWayWysiwyg && answerWayWysiwyg()}

            {hasError(errors, 'answer_way') && (
              <FormFeedback className="d-block">{getError(errors, 'answer_way')}</FormFeedback>
            )}
          </FormGroup>
        </>
      )}
      {proceeding_type === PROCEEDINGS_INIT_TYPES_VALUES.PLANNING && (
        <>
          <FormGroup>
            {!placeholder && (
              <Label for="input-start_date_type">{suffixLabel('Data rozpoczęcia postępowania', required)}</Label>
            )}
            <Select
              id="input-start_date_type"
              className="default-text-color mb-2"
              onChange={(newValue) => setValues({ ...values, start_date_type: newValue.value })}
              value={proceedingStartDateTypeOptions().find((val) => val.value === start_date_type)}
              options={proceedingStartDateTypeOptions()}
            />
            {start_date_type === START_DATE_TYPES_VALUES.QUARTER && (
              <Select
                id="input-quarter-start_date_type"
                className="default-text-color"
                onChange={(newValue) => setValues({ ...values, start_date: newValue.value })}
                value={proceedingStartDateQuarterOptions().find((val) =>
                  typeof start_date === 'number' && start_date < 5 ? val.value === start_date : 1
                )}
                options={proceedingStartDateQuarterOptions()}
              />
            )}
            {start_date_type === START_DATE_TYPES_VALUES.MONTH && (
              <Select
                id="input-month-start_date_type"
                className="default-text-color"
                onChange={(newValue) => setValues({ ...values, start_date: newValue.value })}
                value={getMonthOptions().find((val) => (typeof start_date === 'number' ? val.value === start_date : 1))}
                options={getMonthOptions()}
              />
            )}
            {start_date_type === START_DATE_TYPES_VALUES.DATE && (
              <FormGroup className="mb-0">
                <Datetime
                  name="start_date_type"
                  id="input-date-start_date_type"
                  value={typeof start_date === 'string' ? start_date : ''}
                  min=""
                  max=""
                  onChange={(val) => setValues({ ...values, start_date: val })}
                  invalid={hasError(errors, 'start_date')}
                />
              </FormGroup>
            )}
            {hasError(errors, 'start_date') && (
              <FormFeedback className="d-block">{getError(errors, 'start_date')}</FormFeedback>
            )}
          </FormGroup>
        </>
      )}
    </>
  );
};

export default ProceedingsFieldset;
