import React, { useEffect, useState } from 'react';
import { RootState } from 'app/reducer';
import { createSearchingProps } from 'modules/Shared/helper/params';
import OfficeFilter from 'modules/Office/container/OfficeList/Filter';
import OfficePagination from 'modules/Office/container/OfficeList/Pagination';
import Office from 'modules/Office/model/Office';
import { ROUTE_OFFICES } from 'modules/Office/routes';
import { useDispatch, useSelector } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import { breadcrumbRouteOfficeEmailResend } from 'modules/Office/breadcrumbs';
import { ColorVariants, Message, PartialSearchingProps } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { setOfficeListParamsAction } from 'modules/Office/action/list';
import BaseOfficeTable from 'modules/Office/container/ResendOfficeList/BaseOfficeTable';
import ResendOfficeTable from 'modules/Office/container/ResendOfficeList/ResendOfficeTable';
import Loader from 'modules/Layout/component/Loader';
import { Button } from 'reactstrap';
import Alert from 'modules/Layout/component/Alert';
import useLocalPagination from 'modules/Shared/helper/hooks/useLocalPagination';
import updateRequest from 'modules/Shared/helper/APIRequests/updateRequest';
import { resendEmail } from 'modules/Office/repository';
import { officeEmailResendToastSuccess } from 'modules/Office/toasts';
import { ResendEmailRequest } from 'modules/Office/type';
import ResendEmailModal from 'modules/Office/component/Modal/ResendEmail';

const ResendOffices = () => {
  const dispatch = useDispatch();
  const { fetching } = useSelector((state: RootState) => state.office.list);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [modalMessage, setModalMessage] = useState<Message>(null);

  const [displayResendModal, setDisplayResendModal] = useState<ResendEmailRequest>(null);

  const [resendOffices, setResendOffices] = useState<Office[]>([]);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));
  const setParams = (payload: PartialSearchingProps) => dispatch(setOfficeListParamsAction(payload));

  const searchProps = createSearchingProps('', Office.getFilterableAttributes(), Office.getSortableAttributes());

  useEffect(() => {
    managePage({
      title: 'Wysyłanie email - wybierz urzędy',
      breadcrumb: breadcrumbRouteOfficeEmailResend()
    });

    setParams({ ...searchProps, filter: { can_send_email: 1 } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchResendEmailOfficeToast = () => dispatch(addToastAction(officeEmailResendToastSuccess()));

  const cancelToken = useCancelToken();
  const onResendEmailRequest = async (data: ResendEmailRequest) => {
    setLoading(true);
    if (message) setMessage(null);
    if (modalMessage) setModalMessage(null);

    const { cancelled, message: messageResponse, success } = await updateRequest(resendEmail, data, cancelToken);
    if (cancelled) return;
    if (success) {
      setDisplayResendModal(null);
      setResendOffices([]);
      dispatchResendEmailOfficeToast();
      setMessage({
        value:
          'Pomyślnie rozpoczęto wysyłanie wiadomości. Operacja zostanie przeprowadzona w tle. Można dalej korzystać z systemu.',
        variant: ColorVariants.Success
      });
    }
    if (messageResponse) setModalMessage(messageResponse);
    setLoading(false);
  };

  const manageResendOffice = (office: Office) => {
    const findOffice = resendOffices.find((val) => val.id === office.id);

    if (!findOffice) setResendOffices([office, ...resendOffices]);
    else setResendOffices(resendOffices.filter((val) => val.id !== office.id));
  };

  const { paginationComponent, selectedItemsFromArray } = useLocalPagination(resendOffices);

  return (
    <div className="row offices-view">
      <div className="col-12">
        {loading && <Loader />}
        <Alert message={message} />
        {displayResendModal && (
          <ResendEmailModal
            toggle={() => setDisplayResendModal(null)}
            options={displayResendModal}
            onSubmit={onResendEmailRequest}
            message={modalMessage}
            cleanMessage={() => setModalMessage(null)}
            isOpen={!!displayResendModal}
            loading={loading}
          />
        )}
        <div className="listing-wrapper">
          <div className="filter-wrapper">
            <OfficeFilter values={searchProps.filter} withoutCanSendEmail />
          </div>

          <div className="mt-4 d-xl-flex flex-wrap flex-wrap align-items-center justify-content-between">
            <Button
              color="primary"
              className="pzpeu-btn-disabled waves-effect waves-light m-2"
              onClick={() => setDisplayResendModal({ send_to_all: true })}
            >
              Wyślij email do wszystkich urzędów
            </Button>
            <Button
              color="primary"
              className="pzpeu-btn-disabled waves-effect waves-light m-2"
              onClick={() => setDisplayResendModal({ send_to_not_received_before: true })}
            >
              Wyślij email do urzędów, które nigdy nie dostały zapytania
            </Button>
            <Button
              color="primary"
              className={`pzpeu-btn-disabled waves-effect waves-light m-2${resendOffices.length ? '' : ' disabled'}`}
              onClick={() =>
                resendOffices.length && setDisplayResendModal({ offices: resendOffices.map((val) => val.id) })
              }
            >
              Wyślij email do wybranych urzędów
            </Button>
          </div>

          <div className="row">
            <div className="col-12 col-xl-8">
              <div className="listing-pagination-wrapper mt-44px d-flex align-items-center flex-column flex-sm-row">
                <div className="mx-auto">
                  <OfficePagination path={ROUTE_OFFICES} />
                </div>
              </div>
              <div className="table-per-page-wrapper">
                <div className="table-wrapper mt-41px position-relative">
                  {fetching && <Loader />}
                  <BaseOfficeTable onOfficeClick={manageResendOffice} selectedOffices={resendOffices} />
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-4">
              <div className="listing-pagination-wrapper mt-44px d-flex align-items-center flex-column flex-sm-row">
                <div className="mx-auto">{paginationComponent()}</div>
              </div>
              <div className="table-per-page-wrapper">
                <div className="table-wrapper mt-41px position-relative">
                  <ResendOfficeTable offices={selectedItemsFromArray as any} onOfficeClick={manageResendOffice} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResendOffices;
