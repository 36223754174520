import { BreadcrumbItem } from 'modules/Layout/type';
// import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_AGREEMENTS } from 'modules/Agreements/routes';
import { AgreementMode } from 'modules/Agreements/type';
import { getAgreementModeName } from 'modules/Agreements/helper/agreement';

export const breadcrumbAgreements = (): BreadcrumbItem => ({
  title: 'Kreator umów - lista',
  link: ROUTE_AGREEMENTS
});

export const breadcrumbUpdateAgreement = (mode: AgreementMode): BreadcrumbItem[] => [
  breadcrumbAgreements(),
  {
    title: `Umowa - ${getAgreementModeName(mode)}`
  }
];

export const breadcrumbCreateAgreement = (): BreadcrumbItem[] => [
  breadcrumbAgreements(),
  {
    title: 'Tworzenie umowy'
  }
];
