import { addToastAction, managePageAction } from 'modules/Layout/action';
import Loader from 'modules/Layout/component/Loader';
import { breadcrumbRouteUserCompanyUpdate } from 'modules/User/breadcrumbs';
import CompanyForm from 'modules/Profile/component/CompanyForm';
import User, { CompanyEntity, createUser } from 'modules/User/model/User';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Alert from 'modules/Layout/component/Alert';
import { ColorVariants, Message, ValidationErrors } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { updateUserToastSuccess } from 'modules/User/toasts';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_USER } from 'modules/User/routes';
import { ROLE_ADMIN } from 'modules/User/model/UserRole';
import { fetchUser, updateUserCompany } from 'modules/User/repository';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import updateRequest from 'modules/Shared/helper/APIRequests/updateRequest';
import CardBox from 'modules/Layout/component/CardBox';

export interface Props {
  params?: any;
}

const UserCompanyUpdateView: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<{ user: User }>();
  const [user, setUser] = useState<User>(null);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  const scrollToTopOnce = useRef<boolean>(true);

  const dispatchDeleteUserToast = () => dispatch(addToastAction(updateUserToastSuccess()));

  const cancelToken = useCancelToken();
  useEffect(() => {
    if (state?.user) {
      if (state.user.role?.slug === ROLE_ADMIN) {
        setMessage({
          value: 'Nie możesz zmieniać danych rozliczeniowych administratora.',
          variant: ColorVariants.Danger
        });
      } else {
        setUser(createUser(state.user));
      }
    } else {
      (async () => {
        setFetching(true);
        const { data: reqData, cancelled, message: messageResponse } = await fetchRequest(fetchUser, id, cancelToken);

        if (cancelled) return;
        const { data } = reqData;
        if (data) {
          if (data.role?.slug === ROLE_ADMIN) {
            setMessage({
              value: 'Nie możesz zmieniać danych rozliczeniowych administratora.',
              variant: ColorVariants.Danger
            });
          } else {
            setUser(createUser(data));
          }
        }
        if (messageResponse) setMessage(messageResponse);
        setFetching(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      const title = `Edycja danych rozliczeniowych${user.getName() ? ` - ${user.getName()}` : ''}`;
      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteUserCompanyUpdate(parseInt(id, 10) || 0, user?.name?.trim())
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const updateUserAction = async (data: CompanyEntity) => {
    if (loading) return;

    if (errors) setErrors(null);
    setLoading(true);
    const {
      cancelled,
      message: messageRes,
      success,
      error
    } = await updateRequest(updateUserCompany, id, data, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteUserToast();
      dispatch(push(getPathUrl(ROUTE_USER, { id })));
    } else {
      if (messageRes) setMessage(messageRes);
      if (error) setErrors(error);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  if (fetching) return <Loader />;

  if (!fetching && !user && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );

  if (!fetching && user) {
    return (
      <div className="profile-update-view d-flex justify-content-center">
        <CardBox form="small" heading="Dane rozliczeniowe">
          {loading && <Loader />}
          <Alert message={message} />
          {user && (
            <CompanyForm
              profile={user?.getCompany()}
              errors={errors}
              submit={updateUserAction}
              disabled={loading}
              cancelRedirect={getPathUrl(ROUTE_USER, { id })}
              user={user}
              required
            />
          )}
        </CardBox>
      </div>
    );
  }

  return null;
};

export default UserCompanyUpdateView;
