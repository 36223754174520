import React from 'react';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_DEADLINE, AGREEMENT_STEP_INDEXATION_CLAUSES } from 'modules/Agreements/step';
import {
  AgreementStepIndexationClausesValues,
  INDEXATION_BASED_ON_OPTION_1,
  INDEXATION_MAXIMUM_CONTENT,
  INDEXATION_OBLIGATION_TO_NOTIFY_CONTENT,
  INDEXATION_OBLIGATION_TO_NOTIFY_MAX_CONTENT,
  INDEXATION_START_CONTENT,
  INDEXATION_THRESHOLD_CONTENT
} from 'modules/Agreements/components/Steps/Default/IndexationClauses';
import { AgreementStepDeadlineValues } from 'modules/Agreements/components/Steps/Default/Deadline';
import PreviewFieldCheckboxWithNumberInput from 'modules/Agreements/components/PreviewField/CheckboxWithNumberInput';

const AgreementStepPreviewIndexationClauses = (
  props: AgreementStepPreviewProps<AgreementStepIndexationClausesValues>
): JSX.Element => {
  const { agreement, steps, modifiedStep } = props;
  const indexationClauses = getStepValues<AgreementStepIndexationClausesValues>(
    steps,
    AGREEMENT_STEP_INDEXATION_CLAUSES
  );
  const deadline = getStepValues<AgreementStepDeadlineValues>(steps, AGREEMENT_STEP_DEADLINE);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        key={`${checkbox}_checked-${checkbox}_content`}
        step={indexationClauses}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const renderCheckboxWithNumberInput = (checkbox: string, label: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckboxWithNumberInput
        step={indexationClauses}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        label={label}
        className={className}
      />
    );
  };

  const { value: parts } = getFieldForPreview<AgreementStepIndexationClausesValues['indexation_clauses']>(
    indexationClauses,
    modifiedStep,
    'indexation_clauses'
  );

  const { value: sameParts } = getFieldForPreview<AgreementStepDeadlineValues['same_deadline_for_parts']>(
    deadline,
    modifiedStep,
    'same_deadline_for_parts'
  );

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    indexationClauses,
    modifiedStep,
    `checkboxes.general`
  );

  const isMultiple = agreement.few_parts && !sameParts;

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_INDEXATION_CLAUSES)}</div>
      <div className="agreement-step-preview-content reset-preview-point">
        {parts?.map((part, index: number) => {
          const { value: indexationClausesChecked, modified: indexationClausesCheckedModified } = getFieldForPreview(
            indexationClauses,
            modifiedStep,
            `indexation_clauses.${index}.indexation_clauses_checked`
          );

          const { value: indexationBaseContent, modified: indexationBaseContentModified } = getFieldForPreview(
            indexationClauses,
            modifiedStep,
            `indexation_clauses.${index}.indexation_base_content`
          );

          const { value: indexationBasedOnChecked, modified: indexationBasedOnChechedModified } = getFieldForPreview(
            indexationClauses,
            modifiedStep,
            `indexation_clauses.${index}.indexation_based_on_checked`
          );

          const { value: indexationBasedOnOtherChecked, modified: indexationBasedOnOtherChechedModified } =
            getFieldForPreview(
              indexationClauses,
              modifiedStep,
              `indexation_clauses.${index}.indexation_based_on_other_checked`
            );

          const { value: indexationBasedOnOtherContent, modified: indexationBasedOnOtherContentModified } =
            getFieldForPreview(
              indexationClauses,
              modifiedStep,
              `indexation_clauses.${index}.indexation_based_on_other_content`
            );

          const { value: durationMonthsOver } = getFieldForPreview(
            indexationClauses,
            modifiedStep,
            `indexation_clauses.${index}.duration_months_over`
          );

          const getIndexationBasedOnContent = () => {
            if (indexationBasedOnChecked) return INDEXATION_BASED_ON_OPTION_1;

            if (indexationBasedOnOtherChecked) return indexationBasedOnOtherContent;
          };

          const partObj = agreement.parts_ids.find((item) => item.id === part.part_id);

          return (
            <div key={index} className="agreement-step-preview-content reset-preview-point">
              {isMultiple && partObj && <div className="h5">{partObj.getName(index)}:</div>}
              {[
                indexationClausesChecked && [
                  <p
                    className={classNames('preview-point', {
                      modified: indexationClausesCheckedModified
                    })}
                  >
                    Zamawiający przewiduje możliwość zmiany wysokości wynagrodzenia w następujących przypadkach:
                  </p>,
                  <>
                    {durationMonthsOver === 12 && [
                      <p className="preview-nested-alpha-dot-point">
                        zmiany stawki podatku od towarów i usług oraz podatku akcyzowego. Zmiana stawki VAT dotyczyć
                        będzie wynagrodzenia umownego za prace wykonane po dacie podpisania aneksu do Umowy
                      </p>,
                      <p className="preview-nested-alpha-dot-point">
                        zmiany wysokości minimalnego wynagrodzenia za pracę albo wysokości minimalnej stawki godzinowej,
                        ustalonych na podstawie przepisów ustawy z dnia 10 października 2002 r. o minimalnym
                        wynagrodzeniu za pracę
                      </p>,
                      <p className="preview-nested-alpha-dot-point">
                        zmiany zasad podlegania ubezpieczeniom społecznym lub ubezpieczeniu zdrowotnemu lub zmiany
                        wysokości stawki składki na ubezpieczenia społeczne lub ubezpieczenie zdrowotne
                      </p>,
                      <p className="preview-nested-alpha-dot-point">
                        zmiany zasad gromadzenia i wysokości wpłat do pracowniczych planów kapitałowych, o których mowa
                        w ustawie z dnia 4.10.2018 r. o pracowniczych planach kapitałowych
                      </p>
                    ]}
                  </>,
                  <p
                    className={classNames('preview-nested-alpha-dot-point', {
                      modified: indexationBaseContentModified
                    })}
                  >
                    zmiany cen materiałów lub kosztów związanych z realizacją Umowy, w stosunku do cen materiałów lub
                    kosztów określonych w {indexationBaseContent}
                  </p>,
                  <>
                    {durationMonthsOver === 12 && [
                      <p className="preview-point">
                        W sytuacji wystąpienia okoliczności opisanych w § <span className="get-step-symbol-counter" />{' '}
                        ust. 1 lit. a) Umowy, wykonawca jest uprawniony złożyć zamawiającemu pisemny wniosek o zmianę
                        Umowy w zakresie płatności po wejściu w życie przepisów zmieniających stawkę podatku od towarów
                        i usług. Wniosek powinien zawierać wyczerpujące uzasadnienie faktyczne i wskazanie podstaw
                        prawnych zmiany stawki podatku od towarów i usług oraz dokładne wyliczenie kwoty wynagrodzenia
                        należnego wykonawcy po zmianie Umowy
                      </p>,
                      <p className="preview-point">
                        W sytuacji wystąpienia okoliczności wskazanych § <span className="get-step-symbol-counter" />{' '}
                        ust. 1 lit. b) Umowy, wykonawca jest uprawniony złożyć zamawiającemu pisemny wniosek o zmianę
                        Umowy w zakresie płatności po wejściu w życie przepisów zmieniających wysokość minimalnego
                        wynagrodzenia za pracę albo wysokości minimalnej stawki godzinowej. Wniosek powinien zawierać
                        wyczerpujące uzasadnienie faktyczne i wskazanie podstaw prawnych oraz dokładne wyliczenie kwoty
                        wynagrodzenia należnego wykonawcy po zmianie Umowy, w szczególności wykonawca zobowiązuje się
                        wykazać związek pomiędzy wnioskowaną kwotą podwyższenia, a wpływem zmiany minimalnego
                        wynagrodzenia za pracę albo wysokości minimalnej stawki godzinowej na kalkulację wynagrodzenia.
                        Wniosek powinien obejmować jedynie dodatkowe koszty realizacji Umowy, które Wykonawca
                        obowiązkowo ponosi w związku z podwyższeniem płacy minimalnej. Zamawiający oświadcza, iż nie
                        będzie akceptował kosztów wynikających z podwyższenia wynagrodzeń pracownikom wykonawcy, które
                        nie są konieczne do ich dostosowania do wysokości minimalnego wynagrodzenia za pracę, w
                        szczególności koszty podwyższenia wynagrodzenia w kwocie przewyższającej wysokość płacy
                        minimalnej albo wysokości minimalnej stawki godzinowej
                      </p>,
                      <p className="preview-point">
                        W sytuacji wystąpienia okoliczności wskazanych § <span className="get-step-symbol-counter" />{' '}
                        ust. 1 lit. c) Umowy, Wykonawca jest uprawniony złożyć zamawiającemu pisemny wniosek o zmianę
                        Umowy w zakresie płatności po zmianie zasad podlegania ubezpieczeniom społecznym lub
                        ubezpieczeniu zdrowotnemu lub wysokości składki na ubezpieczenia społeczne lub zdrowotne.
                        Wniosek powinien zawierać wyczerpujące uzasadnienie faktyczne i wskazanie podstaw prawnych oraz
                        dokładne wyliczenie kwoty wynagrodzenia wykonawcy po zmianie Umowy, w szczególności wykonawca
                        zobowiązuje się wykazać związek pomiędzy wnioskowaną kwot podwyższenia wynagrodzenia, a wpływem
                        zmiany zasad o których mowa w ust. § 17 ust. 1 lit. c) Umowy na kalkulację wynagrodzenia.
                        Wniosek powinien obejmować jedynie dodatkowe koszty realizacji Umowy, które wykonawca
                        obowiązkowo ponosi, w związku ze zmianą zasad, o których mowa w § 17 ust. 1 lit. c) Umowy
                      </p>,
                      <p className="preview-point">
                        W sytuacji wystąpienia okoliczności wskazanych w § <span className="get-step-symbol-counter" />{' '}
                        ust. 1 lit. d) Umowy, wykonawca jest uprawniony złożyć zamawiającemu pisemny wniosek o zmianę
                        Umowy w zakresie płatności po zmianie przepisów zmieniających wysokość wpłat do pracowniczych
                        planów kapitałowych. Wniosek powinien zawierać wyczerpujące uzasadnienie faktyczne i wskazanie
                        podstaw prawnych oraz dokładne wyliczenie kwoty wynagrodzenia wykonawcy po zmianie Umowy, w
                        szczególności wykonawca zobowiązuje się wykazać związek pomiędzy wnioskowana kwot podwyższenia
                        wynagrodzenia a wpływem zmiany wysokości wpłaty do pracowniczych planów kapitałowych.
                        Zamawiający oświadcza, że nie będzie akceptował kosztów wynikających z podwyższenia wynagrodzeń
                        pracownikom wykonawcy, które nie są konieczne w celu ich dostosowania do wysokości obowiązkowej
                        wpłaty do pracowniczych planów kapitałowych
                      </p>,
                      <p className="preview-point">
                        Zmiana Umowy w zakresie zmiany wynagrodzenia z przyczyn określonych w §{' '}
                        <span className="get-step-symbol-counter" /> ust. 1 lit. a-d) Umowy obejmować będzie wyłącznie
                        płatności za prace, które w dniu zmiany odpowiednio stawki podatku VAT, wysokości minimalnego
                        wynagrodzenia za pracę, składki na ubezpieczenia społeczne lub zdrowotne i wysokości wpłaty do
                        pracowniczych planów kapitałowych, jeszcze nie wykonano
                      </p>
                    ]}
                  </>,
                  <p
                    className={classNames('preview-point', {
                      modified: indexationBaseContentModified
                    })}
                  >
                    W ramach zmiany o której mowa w § <span className="get-step-symbol-counter" /> ust. 1 lit.{' '}
                    {durationMonthsOver === 12 ? 'e' : 'a'}) Umowy (waloryzacja kosztowo – materiałowa), zamawiający
                    przewiduje możliwość zmiany wysokości wynagrodzenia w przypadku zmiany cen materiałów lub kosztów
                    związanych z realizacją Umowy, w stosunku do cen materiałów lub kosztów określonych w{' '}
                    {indexationBaseContent}
                  </p>,
                  renderCheckboxWithNumberInput(
                    `indexation_clauses.${index}.indexation_start`,
                    INDEXATION_START_CONTENT
                  ),
                  renderCheckboxWithNumberInput(
                    `indexation_clauses.${index}.indexation_threshold`,
                    INDEXATION_THRESHOLD_CONTENT
                  ),
                  <p
                    className={classNames('preview-point', {
                      modified:
                        indexationBasedOnChechedModified ||
                        indexationBasedOnOtherChechedModified ||
                        indexationBasedOnOtherContentModified
                    })}
                  >
                    Zamawiający określa, że:
                  </p>,
                  <p
                    className={classNames('preview-nested-alpha-dot-point', {
                      modified:
                        indexationBasedOnChechedModified ||
                        indexationBasedOnOtherChechedModified ||
                        indexationBasedOnOtherContentModified
                    })}
                  >
                    waloryzacja będzie odbywać się w oparciu o {getIndexationBasedOnContent()}. W przypadku, gdyby wyżej
                    wskazany wskaźnik przestał być dostępny, zastosowanie znajdzie wskazany przez zamawiającego inny,
                    najbardziej zbliżony wskaźnik
                  </p>,
                  <p
                    className={classNames('preview-nested-alpha-dot-point', {
                      modified:
                        indexationBasedOnChechedModified ||
                        indexationBasedOnOtherChechedModified ||
                        indexationBasedOnOtherContentModified
                    })}
                  >
                    przez zmianę ceny materiałów lub kosztów rozumie się wzrost odpowiednio cen lub kosztów, względem
                    ceny przyjętych w {indexationBaseContent}. Wykonawca będzie uprawniony do waloryzacji wynagrodzenia
                    wyłącznie w sytuacji wykazania zamawiającemu, że na dzień zaistnienia podstaw do waloryzacji, ceny
                    wskazane w{indexationBaseContent}, są niższe aniżeli ceny produkcji budowlano – montażowej,
                    publikowane przez {getIndexationBasedOnContent()}
                  </p>,
                  renderCheckboxWithNumberInput(
                    `indexation_clauses.${index}.indexation_obligation_to_notify`,
                    INDEXATION_OBLIGATION_TO_NOTIFY_CONTENT
                  ),
                  renderCheckboxWithNumberInput(
                    `indexation_clauses.${index}.indexation_obligation_to_notify_max`,
                    INDEXATION_OBLIGATION_TO_NOTIFY_MAX_CONTENT
                  ),
                  renderCheckboxWithNumberInput(
                    `indexation_clauses.${index}.indexation_maximum`,
                    INDEXATION_MAXIMUM_CONTENT
                  ),
                  renderCheckbox(`indexation_clauses.${index}.contract_provisions`),
                  renderCheckbox(`indexation_clauses.${index}.reward_change`)
                ]
              ]}
              {generalCheckboxes &&
                generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
                  if (!checkbox.checked) return null;

                  if (checkbox.part_id != part.part_id) return null;

                  return (
                    <p
                      className={classNames('preview-point wysiwyg-preview', {
                        modified: generalCheckboxesModified
                      })}
                      key={index}
                      dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                    />
                  );
                })}
            </div>
          );
        })}
        {isMultiple && generalCheckboxes && (
          <div className="agreement-step-preview-content">
            <p className="h5 reset-preview-point">Ponadto dla każdego z zadań:</p>
            {generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
              if (!checkbox.checked) return null;

              if (checkbox.part_id) return null;

              return (
                <p
                  className={classNames('preview-point wysiwyg-preview', {
                    modified: generalCheckboxesModified
                  })}
                  key={index}
                  dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AgreementStepPreviewIndexationClauses;
