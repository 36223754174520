import { ValidationErrors } from 'modules/Shared/type';
import React from 'react';
import { Button, Form as FormStrap, FormFeedback, FormGroup, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import Judgement from 'modules/Judgement/model/Judgement';
import { hasError, getError } from 'modules/Shared/helper/validation';
import ActionUpdate from 'modules/Layout/component/Action/Update';
import RestoreIcon from 'modules/Layout/component/Icon/Restore';
import useWysiwyg from 'modules/Layout/component/Wysiwyg';

export interface Props {
  errors?: ValidationErrors;
  disabled?: boolean;
  judgement: Judgement;
  contentUpdate?: string;
  submit: (content: string) => void;
  triggerRefresh?: () => void;
  cancelUrl: string;
}

const KeywordIncludedMarkForm: React.FC<Props> = (props) => {
  const { errors, submit, judgement, triggerRefresh, contentUpdate, cancelUrl } = props;

  const { displayWysiwyg, getContent } = useWysiwyg({
    content: contentUpdate ?? judgement?.content,
    tooltip: { specialSign: true, markPen: true, tables: true }
  });

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    submit(getContent());
  };

  return (
    <FormStrap onSubmit={onSubmit}>
      <FormGroup>
        <Label className="d-inline-flex align-items-center">
          Treść publikacji
          <ActionUpdate
            title="Przywróć obecny tekst orzeczenia"
            label={<RestoreIcon height="20px" className="icon-svg-table ml-2" />}
            onClick={() => triggerRefresh()}
          />
        </Label>

        {displayWysiwyg && displayWysiwyg()}

        {hasError(errors, 'content') && <FormFeedback className="d-block">{getError(errors, 'content')}</FormFeedback>}
      </FormGroup>

      <div className="d-flex justify-content-center">
        <div className="form-actions">
          <Button type="submit" color="primary" className="pzpeu-btn-disabled waves-effect waves-light btn btn-primary">
            Zapisz
          </Button>
          <Link to={cancelUrl} className="cancel waves-effect waves-light">
            Anuluj
          </Link>
        </div>
      </div>
    </FormStrap>
  );
};

export default KeywordIncludedMarkForm;
