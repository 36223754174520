import { Routes } from 'app/routes';
import Authorize from 'modules/Auth/container/Authorize';
import OfficeListView from 'modules/Office/view/OfficesList';
import OfficeDotListView from 'modules/Office/view/OfficesDotList';
import OfficeDetailView from 'modules/Office/view/Details/Office';
import OfficeExtendedDetailView from 'modules/Office/view/Details/OfficeExtended';
import OfficeCreateView from 'modules/Office/view/Create';
import OfficeUpdateView from 'modules/Office/view/Update';
import OfficeImportsView from 'modules/Office/view/Imports';
import OfficeImportView from 'modules/Office/view/Imports/Details';
import OfficeExecutorRequestView from 'modules/Office/view/ExecutorRequest';
import OfficeEmailView from 'modules/Office/view/Email';
import React from 'react';
import { Route } from 'react-router-dom';
import {
  OFFICES_ADD,
  OFFICES_DETAILS,
  OFFICES_EDIT,
  OFFICES_EXTENDED,
  OFFICES_IMPORT,
  OFFICES_INDEX
} from 'modules/Office/permissions';
import ResendOfficesView from 'modules/Office/view/Email/Resend';

export const ROUTE_OFFICES = '/office';
export const ROUTE_OFFICES_DOT = '/office_extended';
export const ROUTE_OFFICE = '/office/:id(\\d+)';
export const ROUTE_OFFICE_EXTENDED = '/office_extended/:id(\\d+)';
export const ROUTE_OFFICE_CREATE = '/office/create';
export const ROUTE_OFFICE_UPDATE = '/office/update/:id(\\d+)';
export const ROUTE_OFFICE_IMPORTS = '/office/imports';
export const ROUTE_OFFICE_IMPORT = '/office/imports/:id(\\d+)';
export const ROUTE_EXECUTOR_REQUEST = '/office/:id(\\d+)/request/:request_id(\\d+)';
export const ROUTE_OFFICES_EMAIL = '/office/email';
export const ROUTE_OFFICES_EMAIL_RESEND = '/office/email/resend';

export const createOfficeRoutes = (): Routes => ({
  wrapped: [
    <Authorize key="offices" permissions={[OFFICES_INDEX]}>
      <Route path={ROUTE_OFFICES} exact component={OfficeListView} />
    </Authorize>,
    <Authorize key="offices-extended" permissions={[OFFICES_EXTENDED]}>
      <Route path={ROUTE_OFFICES_DOT} exact component={OfficeDotListView} />
    </Authorize>,
    <Authorize key="office" permissions={[OFFICES_DETAILS]}>
      <Route path={ROUTE_OFFICE} exact component={OfficeDetailView} />
    </Authorize>,
    <Authorize key="office-extended" permissions={[OFFICES_EXTENDED]}>
      <Route path={ROUTE_OFFICE_EXTENDED} exact component={OfficeExtendedDetailView} />
    </Authorize>,
    <Authorize key="office-create" permissions={[OFFICES_ADD]}>
      <Route path={ROUTE_OFFICE_CREATE} exact component={OfficeCreateView} />
    </Authorize>,
    <Authorize key="office-update" permissions={[OFFICES_EDIT]}>
      <Route path={ROUTE_OFFICE_UPDATE} exact component={OfficeUpdateView} />
    </Authorize>,
    <Authorize key="office-imports" permissions={[OFFICES_IMPORT]}>
      <Route path={ROUTE_OFFICE_IMPORTS} exact component={OfficeImportsView} />
    </Authorize>,
    <Authorize key="office-import" permissions={[OFFICES_IMPORT]}>
      <Route path={ROUTE_OFFICE_IMPORT} exact component={OfficeImportView} />
    </Authorize>,
    <Authorize key="office-executor-request" permissions={[OFFICES_DETAILS]}>
      <Route path={ROUTE_EXECUTOR_REQUEST} exact component={OfficeExecutorRequestView} />
    </Authorize>,
    <Authorize key="office-email" permissions={[OFFICES_INDEX, OFFICES_EDIT]}>
      <Route path={ROUTE_OFFICES_EMAIL} exact component={OfficeEmailView} />
    </Authorize>,
    <Authorize key="office-email-resend" permissions={[OFFICES_INDEX, OFFICES_EDIT]}>
      <Route path={ROUTE_OFFICES_EMAIL_RESEND} exact component={ResendOfficesView} />
    </Authorize>
  ]
});
