import classNames from 'classnames';
import _get from 'lodash/get';
import { SortParams } from 'modules/Shared/type';
import React, { useState, useEffect } from 'react';
import { Table as Tablestrap, TableProps as TablestrapProps, Collapse, CardBody, Card } from 'reactstrap';
import '../style.scss';

type T = any;

export interface TableCol<T> {
  property: Extract<keyof T, string> | string;
  label: React.ReactNode;
  sortable?: boolean;
  value?: (row: T, index: number) => React.ReactNode;
  classname?: string;
}

export interface TableProps<T> {
  cols: TableCol<T>[];
  rows?: T[];
  selected?: React.ReactText[];
  sort?: SortParams;
  colKey?: (column: TableCol<T>, index: number) => React.ReactText;
  rowKey?: (row: T, index: number) => React.ReactText;
  onSort?: (params: SortParams) => void;
  displayErrorRow?: (row: T) => string;
  onRowClick?: (row: T) => void;
  tableProps?: TablestrapProps;
  nestedTable?: (row: T) => JSX.Element;
  customLastRow?: JSX.Element;
}

const Table: <T>(p: TableProps<T>) => React.ReactElement<TableProps<T>> = (props) => {
  const {
    colKey,
    rowKey,
    sort,
    cols,
    selected,
    onRowClick,
    onSort,
    displayErrorRow,
    rows,
    tableProps,
    nestedTable,
    customLastRow
  } = props;

  const [cards, setCards] = useState<boolean[]>([]);

  useEffect(() => {
    if (nestedTable) setCards(rows?.map(() => false) || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const getValue = (col: TableCol<T>, row: T, index: number) => {
    if (col?.value) {
      return col?.value(row, index);
    }

    return _get(row, col?.property, null);
  };

  const getColKey = (col: TableCol<T>, index: number) => {
    if (colKey) {
      return colKey(col, index);
    }

    return col?.property;
  };

  const getRowKey = (row: T, index: number) => {
    if (rowKey) {
      return rowKey(row, index);
    }

    return `row-${index}`;
  };

  const getSortIcon = (property: string) => {
    if (sort?.sort_by === property) {
      if (sort?.sort_order === 'desc') {
        return <i className="fa fa-sort-down ml-2" />;
      }

      return <i className="fa fa-sort-up ml-2" />;
    }

    return <i className="fa fa-sort ml-2" />;
  };

  const toggleSort = (property: string) => {
    const params: SortParams = {};

    if (sort?.sort_by === property) {
      if (sort?.sort_order === 'desc') {
        params.sort_by = property;
        params.sort_order = 'asc';
      }
    } else {
      params.sort_by = property;
      params.sort_order = 'desc';
    }

    return params;
  };

  const renderHeader = (col: TableCol<T>, index: number) => {
    return (
      <th key={getColKey(col, index)}>
        <div>{col?.label}</div>
      </th>
    );
  };

  const renderSortableHeader = (col: TableCol<T>, index: number) => {
    const params = toggleSort(col?.property);

    return (
      <th key={getColKey(col, index)} title={`Sortuj po ${col?.label}`} role="button" onClick={() => onSort(params)}>
        <div className="d-flex justify-content-between align-items-center">
          {col?.label}
          {getSortIcon(col?.property)}
        </div>
      </th>
    );
  };

  const renderHeaders = () => {
    return cols?.map((col, index) => {
      return col?.sortable ? renderSortableHeader(col, index) : renderHeader(col, index);
    });
  };

  const renderRow = (row: T, rowIndex: number) => {
    const key = getRowKey(row, rowIndex);

    return (
      <React.Fragment key={key}>
        <tr
          className={`${displayErrorRow ? `${displayErrorRow(row)} ` : ''}${
            nestedTable || onRowClick ? 'cursor-pointer ' : ''
          }${classNames({ active: selected?.includes(key) })}`}
          onClick={() => onRowClick && onRowClick(row)}
        >
          {cols.map((col, colIndex) => {
            const value = getValue(col, row, rowIndex);
            return (
              <td
                onClick={() =>
                  nestedTable && setCards([...cards.slice(0, rowIndex), !cards[rowIndex], ...cards.slice(rowIndex + 1)])
                }
                onKeyDown={() => null}
                role="presentation"
                className={`${col?.classname ? col.classname : ''}`}
                key={getColKey(col, colIndex)}
              >
                {value}
              </td>
            );
          })}
        </tr>
        {!nestedTable ? null : (
          <>
            <tr />
            <tr style={{ borderBottom: cards[rowIndex] ? null : 'none' }}>
              <td colSpan={100} className="p-0 accordition-table-td-nested">
                <Collapse isOpen={cards[rowIndex]}>
                  <Card className="border-0">
                    <CardBody>
                      {nestedTable(row)}
                      {/* <Table cols={nestedTable.cols} rows={row?.[nestedTable?.rows ?? '']} /> */}
                    </CardBody>
                  </Card>
                </Collapse>
              </td>
            </tr>
          </>
        )}
      </React.Fragment>
    );
  };

  return (
    <Tablestrap
      striped={tableProps?.striped ?? true}
      bordered={tableProps?.bordered ?? false}
      responsive={tableProps?.responsive ?? true}
      className={classNames('mb-0', tableProps?.className)}
      {...tableProps} /* eslint-disable-line react/jsx-props-no-spreading */
    >
      <thead>
        <tr>{renderHeaders()}</tr>
      </thead>
      {rows?.length > 0 ? (
        <tbody>
          <tr className="thead-space">
            {rows?.map((row, index) => (
              <td key={getRowKey(row, index)} />
            ))}
          </tr>
          {rows?.map(renderRow)}
          {customLastRow}
        </tbody>
      ) : (
        <tfoot>
          <tr>
            <td colSpan={cols?.length} className="text-center">
              Brak danych
            </td>
          </tr>
        </tfoot>
      )}
    </Tablestrap>
  );
};

export default Table;
