import { ModuleEntity } from 'modules/Module/model/Module';
import React from 'react';
import { calculateDiffInDays, DATE_FORMAT_VALUE, formatDate } from 'modules/Shared/helper/utils';
import './index.scss';
import InformationList, { InformationField } from 'modules/Layout/component/InformationList';
import moment from 'moment';

type Props = {
  module: ModuleEntity;
  subscriptionDateTo?: string;
  creditsByDates?: { all_credits_count: number; available_credits: number; date_to: string }[];
  isPublic?: boolean;
  linkToSubscription?: boolean;
};

const ModuleDatasetDashboard: React.FC<Props> = ({ module, subscriptionDateTo, creditsByDates, isPublic }) => {
  const displayCreditsByDates = () => {
    const fields: InformationField[] = [];

    creditsByDates.forEach((credit) => {
      fields.push({
        label: 'Wykupione kredyty',
        value: (
          <span className="text-success">
            Wykupione kredyty{' '}
            <span className="font-weight-bold">{`${credit.available_credits}/${credit.all_credits_count}`}</span> ważne
            do <span className="font-weight-bold">{formatDate(credit.date_to)}</span>
          </span>
        )
      });
    });

    return <InformationList fields={fields} />;
  };

  const displayNotPublicModule = () => {
    const fields: InformationField[] = [];

    if (subscriptionDateTo) {
      const days = calculateDiffInDays(moment().format(DATE_FORMAT_VALUE), subscriptionDateTo);

      fields.push({
        label: 'Abonament',
        value: (
          <span className="text-success">
            Pełny dostęp <span className="font-weight-bold">{Math.round(days)} dni</span> ważny do{' '}
            <span className="font-weight-bold">{formatDate(subscriptionDateTo)}</span>
          </span>
        )
      });
    } else {
      fields.push({
        label: 'Abonament',
        value: 'Nieaktywny'
      });
    }

    return (
      <>
        <InformationList fields={fields} />
        {module.has_credits && (
          <>
            {creditsByDates ? (
              displayCreditsByDates()
            ) : (
              <InformationList
                fields={[
                  {
                    label: 'Wykupione kredyty',
                    value: 'Brak'
                  }
                ]}
              />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div className="module-wrapper">
      <div className="module-name">{module?.name}</div>
      <div className="module-content">
        {isPublic ? (
          <InformationList
            fields={[
              {
                label: 'Abonament',
                value: <span className="text-success">Dostęp publiczny / bezterminowo</span>
              }
            ]}
          />
        ) : (
          displayNotPublicModule()
        )}
      </div>
    </div>
  );
};

export default ModuleDatasetDashboard;
