import React from 'react';
import classNames from 'classnames';
import PreviewFieldCheckbox from 'modules/Agreements/components/PreviewField/Checkbox';
import { AgreementStepPreviewProps } from 'modules/Agreements/type';
import { AgreementsCustomCheckbox } from 'modules/Agreements/components/CustomCheckboxes';
import { getFieldForPreview, getStepName, getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_REPLACEMENT } from 'modules/Agreements/step';
import { AgreementStepReplacementValues } from 'modules/Agreements/components/Steps/Default/Replacement';

const AgreementStepPreviewReplacement = (
  props: AgreementStepPreviewProps<AgreementStepReplacementValues>
): JSX.Element => {
  const { steps, modifiedStep } = props;
  const replacement = getStepValues<AgreementStepReplacementValues>(steps, AGREEMENT_STEP_REPLACEMENT);

  const renderCheckbox = (checkbox: string, className = 'preview-point') => {
    return (
      <PreviewFieldCheckbox
        step={replacement}
        modifiedStep={modifiedStep}
        checkedKey={`${checkbox}_checked`}
        contentKey={`${checkbox}_content`}
        className={className}
      />
    );
  };

  const { value: informationPossibility, modified: informationPossibilityModified } = getFieldForPreview(
    replacement,
    modifiedStep,
    `information_possibility`
  );

  const { value: representativesPossibility, modified: representativesPossibilityModified } = getFieldForPreview(
    replacement,
    modifiedStep,
    `representatives_possibility`
  );

  const { value: generalCheckboxes, modified: generalCheckboxesModified } = getFieldForPreview(
    replacement,
    modifiedStep,
    `checkboxes.general`
  );

  return (
    <div className="agreement-step-preview-wrapper">
      <div className="agreement-step-preview-title">{getStepName(AGREEMENT_STEP_REPLACEMENT)}</div>
      <div className="agreement-step-preview-content reset-preview-point">
        {[
          informationPossibility && [
            <p
              className={classNames('preview-point', {
                modified: informationPossibilityModified
              })}
            >
              Zamawiający informuje wykonawcę o podmiotach pełniących na rzecz zamawiającego funkcję Inspektorów nadzoru
              inwestorskiego w rozumieniu art. 17 pkt 2 ustawy z dnia 7 lipca 1994 r. Prawo budowlane (dalej pr. bud.)
              polegającą na świadczeniu usług nadzoru, kontroli i doradztwa technicznego, tj. w szczególności
              wykonywania czynności związanych z przygotowaniem, zarządzaniem, kontrolą, nadzorem, rozliczeniem
              finansowym budowy oraz nadzoru nad realizacją przez wykonawcę Przedmiotu Umowy, w zakresie branży:
            </p>,
            renderCheckbox('info_construction', 'preview-nested-decimal-point'),
            renderCheckbox('info_electric', 'preview-nested-decimal-point'),
            renderCheckbox('info_sanitary', 'preview-nested-decimal-point'),
            renderCheckbox('info_others', 'preview-nested-decimal-point')
          ],
          renderCheckbox('entities'),
          representativesPossibility && [
            <p
              className={classNames('preview-point', {
                modified: representativesPossibilityModified
              })}
            >
              Przedstawicielami podmiotów, o których mowa w ust. 1 niniejszego paragrafu są ze strony nadzoru
              inwestorskiego branży:
            </p>,
            renderCheckbox('rep_construction', 'preview-nested-decimal-point'),
            renderCheckbox('rep_electric', 'preview-nested-decimal-point'),
            renderCheckbox('rep_sanitary', 'preview-nested-decimal-point'),
            renderCheckbox('rep_others', 'preview-nested-decimal-point')
          ]
        ]}
        {generalCheckboxes &&
          generalCheckboxes.map((checkbox: AgreementsCustomCheckbox, index: number) => {
            if (!checkbox.checked) return null;

            return (
              <p
                className={classNames('preview-point wysiwyg-preview', {
                  modified: generalCheckboxesModified
                })}
                key={index}
                dangerouslySetInnerHTML={{ __html: String(checkbox.content) }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AgreementStepPreviewReplacement;
