import BaseModal, { Props } from 'modules/Layout/component/Modal';
import React, { useEffect, useState } from 'react';
import { Button, Form as FormStrap } from 'reactstrap';
import Alert from 'modules/Layout/component/Alert';
import { Message } from 'modules/Shared/type';
import Loader from 'modules/Layout/component/Loader';
import JudgementKeywordAutocomplete from 'modules/Judgement/container/JudgementAutocomplete/Keyword';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_JUDGEMENT_INCLUDED_KEYWORD } from 'modules/Judgement/routes';
import { Link } from 'react-router-dom';
import Judgement from 'modules/Judgement/model/Judgement';
import { JudgementElementEntity } from 'modules/Judgement/model/JudgementElement';

interface IProps extends Props {
  message: Message;
  clearErrorsOnUnmount: () => void;
  onSubmit: (param: number) => void;
  loading?: boolean;
  judgement: Judgement;
  title: string;
  keywordsIncluded: JudgementElementEntity[];
}

const JudgementAddKeywordIncludedModal: React.FC<IProps> = (props) => {
  const { toggle, clearErrorsOnUnmount, message, onSubmit, loading, title, judgement, keywordsIncluded } = props;

  const [keyword, setKeyword] = useState<number>(null);

  const isValue = React.useRef(false);
  useEffect(() => {
    return () => {
      if (isValue.current) {
        clearErrorsOnUnmount();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (message && !isValue.current) isValue.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const onKeywordChange = (keywordsValue: number) => setKeyword(keywordsValue);

  const onSubmitRequest = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit(keyword);
  };

  const dispalBody = () => {
    const keywordValueIncluded = [...keywordsIncluded.map((el) => el.id), keyword];

    return (
      <FormStrap id="manage-element-modal" onSubmit={onSubmitRequest}>
        <Alert message={message} />
        {loading && <Loader />}
        <JudgementKeywordAutocomplete
          keywordValue={keyword}
          keywordValueIncluded={keywordValueIncluded}
          onKeywordChange={onKeywordChange}
        />
        {!!keyword && (
          <>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <div className="modal-body-title mb-2">Czy chcesz zaznaczyć odpowiednie fragmenty w orzeczeniu?</div>
            <div className="modal-body-content">
              Zaznaczenie jest opcjonalne. Możesz dodać słowo kluczowe bez niego.
            </div>
          </>
        )}
      </FormStrap>
    );
  };

  const displayFooter = () => {
    return (
      <div className="modal-actions">
        {Boolean(keyword) && (
          <Link
            to={{
              pathname: getPathUrl(ROUTE_JUDGEMENT_INCLUDED_KEYWORD, { id: judgement.id }),
              state: { judgement, keyword }
            }}
            className="btn btn-primary waves-effect waves-light"
          >
            Dodaj zaznaczenie
          </Link>
        )}
        <Button
          type="submit"
          form="manage-element-modal"
          color="primary"
          className="waves-effect waves-light"
          disabled={loading}
        >
          Zapisz
        </Button>
        <Button type="button" className="cancel waves-effect waves-light" onClick={toggle} disabled={loading}>
          Anuluj
        </Button>
      </div>
    );
  };

  return <BaseModal {...props} title={title} body={dispalBody()} footer={displayFooter()} />;
};

export default JudgementAddKeywordIncludedModal;
