import React, { useEffect, useState } from 'react';
import { Message, PaginationMeta, SearchParams } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import Listing from 'modules/Layout/component/Listing';
import CardBox from 'modules/Layout/component/CardBox';
import Alert from 'modules/Layout/component/Alert';
import PerPageTable from 'modules/Layout/component/Pagination/WithProps/PerPage';
import PaginationTable from 'modules/Layout/component/Pagination/WithProps';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { deleteExecutorViolation, fetchChangesViolationsList } from 'modules/Executor/repository';
import { push } from 'connected-react-router';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { ExecutorViolation } from '../../../Executor/model/Violation';
import { getPathUrl } from '../../../Shared/helper/api';
import { ROUTE_EXECUTOR_ADMINS_VIOLATION_CREATE } from '../../../Executor/routes';
import ViolationsExecutorTable from '../../../Executor/container/ViolationsTable/Executor';
import GenericModalDelete from '../../../Layout/component/Modal/GenericDeleteModal';
import ModalAddExecutorViolation from '../../../Layout/component/Modal/AddExecutorViolationModal';
import { addToastAction } from '../../../Layout/action';
import { deleteViolationToastSuccess } from '../../../Executor/toasts';
import deleteRequest from '../../../Shared/helper/APIRequests/deleteRequest';

export interface Props {
  executor_id?: string | number;
}

const DetailsViolationsExecutor: React.FC<Props> = ({ executor_id }) => {
  const dispatch = useDispatch();
  const [violations, setViolations] = useState<ExecutorViolation[]>([]);
  const [meta, setMeta] = useState<PaginationMeta>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message>(null);
  const [displaySlugModal, setDisplaySlugModal] = useState<boolean>(false);
  const [displayModal, setDisplayModal] = useState<ExecutorViolation>(null);

  const cancelToken = useCancelToken();
  const fetchViolations = async (payload?: SearchParams) => {
    setLoading(true);
    const {
      data,
      cancelled,
      message: messageResponse
    } = await fetchRequest(fetchChangesViolationsList, executor_id, payload, cancelToken);

    if (cancelled) return;
    if (data) {
      setViolations(data.data);
      setMeta(data.meta);
    }
    if (messageResponse) setMessage(messageResponse);
    setLoading(false);
  };

  useEffect(() => {
    if (executor_id) {
      fetchViolations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executor_id]);
  const dispatchDeleteViolationToast = () => dispatch(addToastAction(deleteViolationToastSuccess()));
  const deleteViolation = async (section_id: number | string) => {
    setLoading(true);
    const {
      cancelled,
      success,
      message: messageRequest
    } = await deleteRequest(deleteExecutorViolation, executor_id, section_id, cancelToken);

    if (cancelled) return;
    if (success) {
      if (success) dispatchDeleteViolationToast();
      setDisplayModal(null);
      await fetchViolations();
      setLoading(false);
    } else {
      if (messageRequest) setMessage(messageRequest);
      setLoading(false);
    }
  };

  return (
    <CardBox
      actions={
        <Button
          type="button"
          className="waves-effect waves-light"
          color="primary"
          onClick={() => setDisplaySlugModal(true)}
        >
          DODAJ NARUSZENIE
        </Button>
      }
      heading="Naruszenia wykonawców"
    >
      {!!displayModal && (
        <GenericModalDelete
          value={displayModal}
          title="Usuwanie naruszenia"
          content={() => `Czy na pewno chcesz usunąć naruszenie?`}
          onDeleteClick={(value) => deleteViolation(value.id)}
          isOpen={!!displayModal}
          toggle={() => setDisplayModal(null)}
          loading={loading}
          message={message}
          resetMessage={() => setMessage(null)}
        />
      )}
      {displaySlugModal && (
        <ModalAddExecutorViolation
          onSlugSubmit={(slug) =>
            dispatch(push(getPathUrl(ROUTE_EXECUTOR_ADMINS_VIOLATION_CREATE, { slug, executor_id })))
          }
          title="Wybierz typ naruszenia"
          isOpen={displaySlugModal}
          toggle={() => setDisplaySlugModal(!displaySlugModal)}
        />
      )}
      <div className="row users-view">
        <div className="col-12">
          <Alert message={message} />
          <div className="pzpeu-table-list inside-card-box">
            <Listing
              table={
                <ViolationsExecutorTable
                  onDeleteClick={(order) => setDisplayModal(order)}
                  executor_id={executor_id}
                  violations={violations}
                />
              }
              pagination={<PaginationTable fetchList={fetchViolations} meta={meta} />}
              perPage={<PerPageTable fetchList={fetchViolations} meta={meta} />}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </CardBox>
  );
};

export default DetailsViolationsExecutor;
