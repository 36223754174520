import React, { useEffect, useRef, useState } from 'react';
import { CustomInput, FormFeedback, FormGroup } from 'reactstrap';
import { isEqual } from 'lodash';
import { getError, hasError } from 'modules/Shared/helper/validation';
import AgreementFormWrapper from 'modules/Agreements/components/Form/Wrapper';
import { AgreementCheckboxes, AgreementStepProps } from 'modules/Agreements/type';
import { getStepValues } from 'modules/Agreements/helper/agreement';
import { AGREEMENT_STEP_SUPPLEMENTARY_ORDERS } from 'modules/Agreements/step';
import StepImportDataButton from 'modules/Agreements/components/StepImportDataButton';
import useStepFields from 'modules/Agreements/hook/useStepFields';
import Part from 'modules/Agreements/model/Part';
import Accordion from 'modules/Layout/component/Accordion';
import { IMPORT_ORDERS_ACCESS } from '../../../../../Auth/container/Guard/permissions';
import Authorize from '../../../../../Auth/container/Authorize';

export interface AgreementStepSupplementaryOrdersValues {
  same_supplementary_orders_for_parts: boolean;
  supplementary_orders: {
    part_id: number | null;
    supplementary_possibility: boolean;
    supplementary_content: string | null;
    supplementary_repeat_content: string | null;
  }[];
  checkboxes: AgreementCheckboxes | null;
  [key: string]: any;
}

export interface AgreementSupplementaryOrdersImportValues {
  same_supplementary_orders_for_parts: boolean;
  supplementary_orders: {
    part_id: number | null;
    supplementary_possibility: boolean;
    supplementary_content: string | null;
    supplementary_repeat_content: string | null;
  }[];
  checkboxes: AgreementCheckboxes | null;
}

const SUPPLEMENTARY_CONTENT =
  'Zamawiający przewiduje udzielenie zamówień uzupełniających do ...% wartości zamówienia podstawowego.';
const SUPPLEMENTARY_REPEAT_CONTENT =
  'Zamówienia uzupełniające będą polegać na powtórzeniu tego samego rodzaju zamówienia i są zgodne z przedmiotem zamówienia podstawowego. Zamawiający przyjmie jako maksymalne składniki cenotwórcze te, które wykonawca podał w swojej ofercie do tego zamówienia.';

const initContentValues = {
  supplementary_content: SUPPLEMENTARY_CONTENT,
  supplementary_repeat_content: SUPPLEMENTARY_REPEAT_CONTENT
};

const mapState = (step: AgreementStepSupplementaryOrdersValues): AgreementStepSupplementaryOrdersValues => {
  const { supplementary_orders, checkboxes, ...rest } = step;

  return {
    ...rest,
    supplementary_orders: supplementary_orders.map((part) => {
      const { supplementary_content, supplementary_repeat_content } = part;

      return {
        ...part,
        supplementary_content: supplementary_content || SUPPLEMENTARY_CONTENT,
        supplementary_repeat_content: supplementary_repeat_content || SUPPLEMENTARY_REPEAT_CONTENT
      };
    }),
    checkboxes: checkboxes || {}
  };
};

const AgreementSupplementaryOrders = (props: AgreementStepProps): JSX.Element => {
  const { agreement, steps, stepsImportData, onSubmit, onChange, errors } = props;
  const initState = useRef<AgreementStepSupplementaryOrdersValues>(
    mapState(getStepValues(steps, AGREEMENT_STEP_SUPPLEMENTARY_ORDERS))
  );
  const [stepValues, setStepValues] = useState<AgreementStepSupplementaryOrdersValues>({ ...initState.current });
  const stepDataImportValues: AgreementSupplementaryOrdersImportValues = getStepValues(
    stepsImportData,
    AGREEMENT_STEP_SUPPLEMENTARY_ORDERS
  );
  const { parts_ids, few_parts } = agreement;
  const { supplementary_orders, same_supplementary_orders_for_parts, checkboxes } = stepValues;

  useEffect(() => {
    onChange(stepValues, !isEqual(initState.current, stepValues));
  }, [stepValues]);

  useEffect(() => {
    initState.current = mapState(getStepValues(steps, AGREEMENT_STEP_SUPPLEMENTARY_ORDERS));
    setStepValues({ ...initState.current });
  }, [steps]);

  const isMultiple = few_parts && !same_supplementary_orders_for_parts;

  const { renderPartContent, renderPartSwitch, renderCustomCheckboxes } = useStepFields({
    stepValues,
    setStepValues,
    mapState,
    errors,
    partSlug: 'supplementary_orders',
    initContentValues
  });

  const renderPart = (partObj: Part, index: number) => {
    const part = isMultiple ? supplementary_orders[index] : supplementary_orders[0];

    const body = (
      <FormGroup>
        {renderPartSwitch(
          'supplementary_possibility',
          'Czy zamawiający przewiduje udzielanie zamówień uzupełniających?',
          index,
          part
        )}
        {part.supplementary_possibility && (
          <div className="pl-3">
            {[
              renderPartContent('supplementary_content', index, part),
              renderPartContent('supplementary_repeat_content', index, part)
            ]}
          </div>
        )}
        {renderCustomCheckboxes('general', partObj?.id)}
      </FormGroup>
    );

    if (isMultiple) {
      return (
        <Accordion
          key={`renderPartAccordion-${partObj.id}`}
          isInvalid={hasError(errors, `supplementary_orders.${index}`)}
          accordionContentStyle={{ padding: '1rem' }}
          entity={{
            title: partObj.getName(index),
            content: body
          }}
        />
      );
    }

    return body;
  };

  const samePartsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setStepValues((values) =>
      mapState({
        ...values,
        same_supplementary_orders_for_parts: checked,
        supplementary_orders: checked
          ? [
              {
                ...supplementary_orders[0],
                part_id: null
              }
            ]
          : parts_ids.map((part) => ({
              ...supplementary_orders[0],
              part_id: part.id
            }))
      })
    );
  };

  const onStepDataImportClick = () => {
    if (stepDataImportValues.same_supplementary_orders_for_parts !== undefined) {
      samePartsChange({ target: { checked: stepDataImportValues.same_supplementary_orders_for_parts } } as any);
    }

    setStepValues((values) => {
      return {
        ...values,
        supplementary_orders: stepDataImportValues.supplementary_orders
          ? values.supplementary_orders.map((supplementaryOrder, index) => ({
              ...supplementaryOrder,
              ...stepDataImportValues.supplementary_orders[index]
            }))
          : values.supplementary_orders,
        checkboxes: stepDataImportValues.checkboxes ? stepDataImportValues.checkboxes : checkboxes
      };
    });
  };

  return (
    <AgreementFormWrapper onSubmit={() => onSubmit(stepValues)}>
      <Authorize permissions={[IMPORT_ORDERS_ACCESS]}>
        <StepImportDataButton onSubmit={onStepDataImportClick} stepDataImportValues={stepDataImportValues} />
      </Authorize>
      {few_parts && (
        <FormGroup>
          <CustomInput
            id="same_supplementary_orders_for_parts"
            name="same_supplementary_orders_for_parts"
            type="switch"
            checked={same_supplementary_orders_for_parts}
            onChange={samePartsChange}
            invalid={hasError(errors, 'same_supplementary_orders_for_parts')}
            label="Czy zamówienia uzupełniające są wspólne dla wszystkich zadań?"
          />
          {hasError(errors, 'same_supplementary_orders_for_parts') && (
            <FormFeedback className="d-block">{getError(errors, 'same_supplementary_orders_for_parts')}</FormFeedback>
          )}
        </FormGroup>
      )}
      {(isMultiple ? parts_ids : [null]).map(renderPart)}
      {isMultiple && (
        <>
          <p className="h4">Ponadto dla każdego z zadań:</p>
          {renderCustomCheckboxes()}
        </>
      )}
    </AgreementFormWrapper>
  );
};

export default AgreementSupplementaryOrders;
