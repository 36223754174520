import Alert from 'modules/Layout/component/Alert';
import Loader from 'modules/Layout/component/Loader';
import BaseModal, { Props } from 'modules/Layout/component/Modal';
import { Message, ValidationErrors } from 'modules/Shared/type';
import React, { useEffect, useState } from 'react';
import { Button, Form as FormStrap } from 'reactstrap';
import {
  OfficeExecutorRequestChannelsValue,
  OfficeExecutorRequestReceivedValueInState,
  OfficeExecutorRequestReceivedValueSend
} from 'modules/Office/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import ExecutorRequestFieldset from 'modules/Office/component/Fieldset/ExecutorsRequest';
import { fetchExecutorRequestChannels } from 'modules/Office/repository';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import { serialize } from 'object-to-formdata';
import { formatDateValue, formatTimeValue } from 'modules/Shared/helper/utils';
import moment from 'moment';

interface IProps extends Props {
  onExecutorRequestReceived: (data: OfficeExecutorRequestReceivedValueSend) => void;
  loading?: boolean;
  message?: Message;
  errors?: ValidationErrors;
}

const ExecutorRequestReceivedModal: React.FC<IProps> = (props) => {
  const momentFormat = moment().format();

  const { onExecutorRequestReceived, loading, toggle, message, errors } = props;
  const [channelValues, setChannelValues] = useState<OfficeExecutorRequestChannelsValue[]>([]);
  const [channelFetching, setChannelFetching] = useState(false);
  const [channelMessage, setChannelMessage] = useState<Message>(null);
  const [dataValues, setDataValues] = useState<OfficeExecutorRequestReceivedValueInState>({
    received_at: formatDateValue(momentFormat),
    channel_id: 1,
    answer_content: '',
    files: []
  });
  const title = `Wprowadź odpowiedź`;

  const cancelToken = useCancelToken();
  useEffect(() => {
    (async () => {
      setChannelFetching(true);
      const {
        data,
        cancelled,
        message: messageResponse
      } = await fetchRequest(fetchExecutorRequestChannels, cancelToken);

      if (cancelled) return;
      if (data) setChannelValues(data.data);
      if (messageResponse) setChannelMessage(messageResponse);
      setChannelFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { files, received_at, ...restData } = dataValues;

    const addTimeToReceivedAt = `${received_at} ${formatTimeValue(momentFormat)}`;

    const convertedData = serialize({
      ...restData,
      received_at: addTimeToReceivedAt,
      files: files.map((file) => file.file)
    });
    onExecutorRequestReceived(convertedData as any);
  };

  const dispalBody = () => {
    return (
      <>
        {(loading || channelFetching) && <Loader />}
        {(channelMessage || message) && <Alert message={channelMessage || message} />}
        <div className="modal-body-title">Uzupełnij szczegóły odpowiedzi</div>
        <div className="modal-body-content">
          <FormStrap id="executor-request-received-form" onSubmit={onSubmit}>
            <ExecutorRequestFieldset
              errors={errors}
              values={dataValues}
              setValues={setDataValues}
              channelValues={channelValues}
              required
            />
          </FormStrap>
        </div>
      </>
    );
  };

  const displayFooter = () => {
    return (
      <div className="modal-actions">
        <Button
          type="submit"
          form="executor-request-received-form"
          color="primary"
          className="pzpeu-btn-disabled waves-effect waves-light"
          disabled={loading || channelFetching}
        >
          Wprowadź odpowiedź
        </Button>
        <Button
          type="button"
          className="cancel waves-effect waves-light"
          disabled={loading || channelFetching}
          onClick={toggle}
        >
          Anuluj
        </Button>
      </div>
    );
  };

  return <BaseModal {...props} title={title} body={dispalBody()} footer={displayFooter()} />;
};

export default ExecutorRequestReceivedModal;
