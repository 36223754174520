import _get from 'lodash/get';
import { RootState } from 'app/reducer';
import ListingFilter from 'modules/Layout/component/Listing/Filter';
import { PartialSearchingProps, SearchParams } from 'modules/Shared/type';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterInputType } from 'modules/Layout/type';
import { formatDatetimeLocalForInput } from 'modules/Shared/helper/utils';
import { setInvoiceListParamsAction } from 'modules/User/action/listInvoiceRequired';

export interface Props {
  values?: SearchParams;
}

const InvoiceRequiredFilter: React.FC<Props> = ({ values = {} }) => {
  const dispatch = useDispatch();
  const { filter, pagination } = useSelector((state: RootState) => state.user.invoiceList);
  const setParams = (payload: PartialSearchingProps) => dispatch(setInvoiceListParamsAction(payload));

  const staticInputsBase: FilterInputType[] = [
    {
      type: 'text',
      property: 'user_email',
      label: 'Email klienta',
      value: _get(values, 'user_email')
    },
    {
      type: 'text',
      property: 'company_name',
      label: 'Nazwa firmy',
      value: _get(values, 'company_name')
    }
  ];

  const inputsTime = (): FilterInputType[] => {
    let dynamicInputs: FilterInputType[] = [];

    const neverLogged = _get(filter, 'finished_at');

    if (neverLogged !== '1') {
      const finishedAtFrom = _get(filter, 'finished_at_from') as string;
      const finishedAtTo = _get(filter, 'finished_at_to') as string;

      dynamicInputs = [
        {
          beforeLineBreak: true,
          type: 'datetime',
          property: 'finished_at_from',
          label: 'Płatność zakończona od',
          value: finishedAtFrom ? formatDatetimeLocalForInput(finishedAtFrom) : finishedAtFrom,
          max: String(finishedAtTo)
        },
        {
          type: 'datetime',
          property: 'finished_at_to',
          label: 'Płatność zakończona do',
          value: finishedAtTo ? formatDatetimeLocalForInput(finishedAtTo) : finishedAtTo,
          min: String(finishedAtFrom)
        }
      ];
    }

    return dynamicInputs;
  };

  const staticInputs = [...staticInputsBase, ...inputsTime()];

  return <ListingFilter inputs={staticInputs} filter={filter} pagination={pagination} setParams={setParams} />;
};

export default InvoiceRequiredFilter;
