import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { addToastAction, managePageAction } from 'modules/Layout/action';
import Listing from 'modules/Layout/component/Listing';
import { Link } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';
import _ from 'lodash';
import { breadcrumbRouteForeignProceedingsAlerts } from '../../breadcrumbs';
import { fetchAlerts } from '../../repository';
import ForeignProceedingsAlertsTable from '../../container/AlertsList/Table';
import { Message, PaginationMeta, SearchParams } from '../../../Shared/type';
import fetchRequest from '../../../Shared/helper/APIRequests/fetchRequest';
import PaginationTable from '../../../Layout/component/Pagination/WithProps';
import PerPageTable from '../../../Layout/component/Pagination/WithProps/PerPage';
import { getPathUrl } from '../../../Shared/helper/api';
import { ROUTE_FOREIGN_PROCEEDINGS_ALERT_CREATE } from '../../routes';
import { AlertFormData } from '../../component/Form/AlertForm';
import { deleteFilter } from '../../../Layout/repository';
import deleteRequest from '../../../Shared/helper/APIRequests/deleteRequest';
import { deleteForeignProceedingsAlertToastSuccess } from '../../toasts';
import GenericModalDelete from '../../../Layout/component/Modal/GenericDeleteModal';

const ForeignProceedingsAlerts = () => {
  const dispatch = useDispatch();
  const [foreignProceedingsAlertsList, setForeignProceedingsAlertsList] = useState<AlertFormData[]>([]);
  const [meta, setMeta] = useState<PaginationMeta>(null);
  const [displayModal, setDisplayModal] = useState<AlertFormData>(null);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useState<SearchParams>(null);
  const [message, setMessage] = useState<Message>(null);

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  const cancelToken: React.MutableRefObject<CancelTokenSource> = useRef(null);
  const fetchForeignProceedingsAlertsRequest = async (payload?: SearchParams) => {
    if (!_.isEqual(searchParams, payload)) {
      setSearchParams(payload);
    }

    if (cancelToken.current) {
      cancelToken.current.cancel('Operation canceled due to new request.');
    }

    cancelToken.current = axios.CancelToken.source();

    const {
      data,
      cancelled,
      message: messageResponse
    } = await fetchRequest(fetchAlerts, payload, cancelToken.current.token);

    if (cancelled) return;
    if (data) {
      setForeignProceedingsAlertsList(data.data);
      setMeta(data.meta);
      setLoading(false);
    }
    if (messageResponse && messageResponse.value !== 'Operation canceled due to new request.') {
      if (loading) setLoading(false);
    }
  };

  const fetchForeignProceedingsAlerts = async (payload?: SearchParams) => {
    setLoading(true);
    await fetchForeignProceedingsAlertsRequest(payload);
  };

  useEffect(() => {
    managePage({
      title: 'Alert postepowań - lista',
      breadcrumb: breadcrumbRouteForeignProceedingsAlerts()
    });
    fetchForeignProceedingsAlerts();

    return () => {
      if (cancelToken.current) {
        cancelToken.current.cancel('AxiosCancel');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatchDeleteAlertToast = () => dispatch(addToastAction(deleteForeignProceedingsAlertToastSuccess()));
  const deleteAlertAction = async (id: number | string) => {
    setLoading(true);
    const { cancelled, success } = await deleteRequest(deleteFilter, id, cancelToken.current?.token);

    if (cancelled) return;
    if (success) {
      dispatchDeleteAlertToast();
      setDisplayModal(null);
      setSearchParams({});
      await fetchForeignProceedingsAlerts(searchParams);
    }
    setLoading(false);
  };

  return (
    <div className="row users-view">
      <div className="col-12">
        {displayModal && (
          <GenericModalDelete
            value={displayModal}
            title="Usuwanie umowy"
            content={() => <div>Czy na pewno chcesz usunąć alert postęmpowania?</div>}
            onDeleteClick={(value) => deleteAlertAction(value.id)}
            isOpen={!!displayModal}
            toggle={() => setDisplayModal(null)}
            loading={loading}
            message={message}
            resetMessage={() => setMessage(null)}
          />
        )}
        <Listing
          table={
            <ForeignProceedingsAlertsTable
              editable
              onDeleteClick={(alertData) => setDisplayModal(alertData)}
              foreignProceedingsAlertsList={foreignProceedingsAlertsList}
            />
          }
          pagination={<PaginationTable fetchList={fetchForeignProceedingsAlerts} meta={meta} />}
          perPage={<PerPageTable fetchList={fetchForeignProceedingsAlerts} meta={meta} />}
          loading={loading}
          childrenNextToPagination={
            <Link
              className="link-with-border table-list-action p-0"
              to={getPathUrl(ROUTE_FOREIGN_PROCEEDINGS_ALERT_CREATE)}
            >
              Dodaj
            </Link>
          }
        />
      </div>
    </div>
  );
};

export default ForeignProceedingsAlerts;
