import { SCREEN_MOBILE_WIDTH } from 'modules/Layout/type';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import { Button, UncontrolledTooltip } from 'reactstrap';

const usePublicAccessHeader = () => {
  const isBig = window.innerWidth > SCREEN_MOBILE_WIDTH;
  const [displayMenu, setDisplayMenu] = useState(isBig);

  const toggleDisplayMenu = () => setDisplayMenu(!displayMenu);

  const displayHeaderElementJsx = (
    <>
      <div className="ml-auto d-flex align-items-center" style={{ paddingRight: '65px' }}>
        {/* <Link to="/" className="nav-link d-flex align-items-center btn btn-link header-link">
          Strona główna
        </Link> */}
        <Link to="/login" className="nav-link d-flex align-items-center btn btn-link header-link">
          Zaloguj się
        </Link>
        {/* <div className="nav-link header-link">
          <Button
            id="toggle-sidebar-btn"
            type="button"
            color="link"
            className="menu-toggle waves-effect waves-light p-0 m-0"
            onClick={toggleDisplayMenu}
          >
            <i className="fe-menu font-24" />
          </Button>
          <UncontrolledTooltip className="toggle-sidebar-tooltip" placement="bottom-end" target="#toggle-sidebar-btn">
            {displayMenu ? 'Ukryj' : 'Pokaż'} menu
          </UncontrolledTooltip>
        </div> */}
      </div>
    </>
  );

  return {
    displayHeaderElementJsx,
    displayMenu,
    toggleDisplayMenu
  };
};

export default usePublicAccessHeader;
