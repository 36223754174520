import {
  ClauseCategoryListAction,
  SET_CLAUSE_CATEGORY_LIST_PARAMS,
  CLAUSE_CATEGORY_LIST
} from 'modules/Clause/action/CategoryList';
import { createJudgementElement } from 'modules/Judgement/model/JudgementElement';
import { initClauseCategoryListState, ClauseCategoryListState } from 'modules/Clause/state/categoryList';

const initState = initClauseCategoryListState();

const reducer = (
  state: ClauseCategoryListState = initState,
  action: ClauseCategoryListAction
): ClauseCategoryListState => {
  switch (action.type) {
    case SET_CLAUSE_CATEGORY_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case CLAUSE_CATEGORY_LIST: {
      const { list = [], meta } = action.payload;

      return { ...state, fetching: false, list: list.map(createJudgementElement), meta };
    }

    default:
      return state;
  }
};

export default reducer;
