import {
  JudgementKeywordListAction,
  KEYWORDS_WITH_CATEGORIES_LIST,
  SET_KEYWORDS_WITH_CATEGORIES_LIST_PARAMS
} from 'modules/Judgement/action/KeywordsWithCategoriesList';
import { createKeywordWithCategories } from 'modules/Judgement/model/KeywordWithCategories';
import { initJudgementKeywordListState, JudgementKeywordListState } from 'modules/Judgement/state/keywordList';

const initState = initJudgementKeywordListState();

const reducer = (
  state: JudgementKeywordListState = initState,
  action: JudgementKeywordListAction
): JudgementKeywordListState => {
  switch (action.type) {
    case SET_KEYWORDS_WITH_CATEGORIES_LIST_PARAMS:
      return { ...state, ...action.payload, fetching: true };

    case KEYWORDS_WITH_CATEGORIES_LIST: {
      const { list = [], meta } = action.payload;

      return { ...state, fetching: false, list: list.map(createKeywordWithCategories), meta };
    }

    default:
      return state;
  }
};

export default reducer;
