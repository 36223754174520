import React from 'react';
import CardBox from 'modules/Layout/component/CardBox';
import InformationList from 'modules/Layout/component/InformationList';
import ForeignProceedings from '../../../model/ForeignProceedings';

type Props = {
  element?: ForeignProceedings;
};

const ForeignProceedingsNewBoxDataset: React.FC<Props> = ({ element = {} }) => {
  const otherContackField = element?.other_contact_checked
    ? [
        {
          title: 'Treść innej formy kontaktu',
          content: element?.other_contact_content || '<p>brak danych</p>'
        }
      ]
    : [];

  const fields = [
    {
      title: 'Opis przedmiotu zamówienia',
      content: element?.description || '<p>brak danych</p>'
    }
  ];

  const finalFields: { title: string; content: string }[] = [...otherContackField, ...fields];

  return (
    <div className="row">
      {finalFields.map((el) => (
        <>
          {el && (
            <div className="col-12" key={el.title}>
              <CardBox heading={el.title} paddingRight="30px" paddingBottom="0" className="judgement-basic-details">
                <InformationList
                  fields={[
                    {
                      label: ' ',
                      value: el.content,
                      html: true,
                      htmlFieldInsertedStyle: { maxHeight: '700px', overflowY: 'auto' }
                    }
                  ]}
                />
              </CardBox>
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default ForeignProceedingsNewBoxDataset;
