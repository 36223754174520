import { addToastAction, managePageAction } from 'modules/Layout/action';
import Loader from 'modules/Layout/component/Loader';
import { breadcrumbRouteUserBasicUpdate } from 'modules/User/breadcrumbs';
import Form from 'modules/Profile/component/Form';
import User, { createUser } from 'modules/User/model/User';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Alert from 'modules/Layout/component/Alert';
import { Message, ValidationErrors } from 'modules/Shared/type';
import useCancelToken from 'modules/Shared/helper/hooks/useCancelToken';
import { ProfileValues } from 'modules/Profile/type';
import { updateUserToastSuccess } from 'modules/User/toasts';
import { push } from 'connected-react-router';
import { getPathUrl } from 'modules/Shared/helper/api';
import { ROUTE_USER } from 'modules/User/routes';
import { ROLE_ADMIN } from 'modules/User/model/UserRole';
import { fetchUser, updateUserBase } from 'modules/User/repository';
import fetchRequest from 'modules/Shared/helper/APIRequests/fetchRequest';
import updateRequest from 'modules/Shared/helper/APIRequests/updateRequest';
import CardBox from 'modules/Layout/component/CardBox';
import { RootState } from 'app/reducer';

export interface Props {
  params?: any;
}

const UserBasicUpdateView: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<{ user: User }>();
  const { user: loggedUser } = useSelector((rootState: RootState) => rootState.auth);
  const [user, setUser] = useState<User>(null);
  const [message, setMessage] = useState<Message>(null);
  const [errors, setErrors] = useState<ValidationErrors>(null);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  const scrollToTopOnce = useRef<boolean>(true);

  const dispatchDeleteUserToast = () => dispatch(addToastAction(updateUserToastSuccess()));

  const cancelToken = useCancelToken();
  useEffect(() => {
    if (state?.user) {
      setUser(createUser(state.user));
    } else {
      (async () => {
        setFetching(true);
        const { data: reqData, cancelled, message: messageResponse } = await fetchRequest(fetchUser, id, cancelToken);

        if (cancelled) return;
        const { data } = reqData;
        if (data) setUser(createUser(data));
        if (messageResponse) setMessage(messageResponse);
        setFetching(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      const roleAdmin = user?.role?.slug === ROLE_ADMIN;
      const title = `Edycja podstawowych danych${user.getName() ? ` - ${user.getName()}` : ''}`;
      dispatch(
        managePageAction({
          title,
          breadcrumb: breadcrumbRouteUserBasicUpdate(parseInt(id, 10) || 0, roleAdmin, user.getName())
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const updateUserAction = async (profile: ProfileValues) => {
    if (loading) return;

    if (errors) setErrors(null);
    setLoading(true);
    const {
      cancelled,
      message: messageRes,
      success,
      error
    } = await updateRequest(updateUserBase, id, profile, cancelToken);

    if (cancelled) return;
    if (success) {
      dispatchDeleteUserToast();
      dispatch(push(getPathUrl(ROUTE_USER, { id })));
    } else {
      if (messageRes) setMessage(messageRes);
      if (error) setErrors(error);
      if (!scrollToTopOnce.current) scrollToTopOnce.current = true;
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errors && scrollToTopOnce.current) {
      window.scrollTo({ top: 70 });
      scrollToTopOnce.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  if (fetching) return <Loader />;
  if (!fetching && !user && message)
    return (
      <div className="user-create-view row justify-content-center">
        <div className="col-lg-7 col-xl-5">
          <div className="card-box position-relative">{message && <Alert message={message} />}</div>
        </div>
      </div>
    );
  if (!fetching && user) {
    return (
      <div className="profile-update-view d-flex justify-content-center">
        <CardBox form="small" heading="Podstawowe dane użytkownika">
          {loading && <Loader />}
          <Alert message={message} />
          {user && (
            <Form
              profile={user?.getIdentity()}
              errors={errors}
              submit={updateUserAction}
              disabled={loading}
              cancelRedirect={getPathUrl(ROUTE_USER, { id })}
              isAdmin={loggedUser?.role?.slug === ROLE_ADMIN && user?.role?.slug === ROLE_ADMIN}
            />
          )}
        </CardBox>
      </div>
    );
  }

  return null;
};

export default UserBasicUpdateView;
